export class IONote {

    public noteId:string;
    public activityId:string;
    public contactId:string;
    public accountId:string;
    public createdTime:Date;
    public noteText:string;
    public ownerName:string;
    public ownerId:string;
    public hasDocument:boolean;
    public documentDataURL:string;
    public documentName:string;
    public documentSize:number;
    public documentMimeType:string;
    public isDeleted = false;
    public pendingPushForDynamics = false;
    public updated = false;

    constructor(raw: IONoteDTO){
        this.noteId = (raw['annotationid']) ? raw['annotationid'] : raw['noteId'] ? raw['noteId'] : '';
        this.activityId = (raw['activityid'] ? raw['activityid'] : (raw['activityId'] ? raw['activityId'] : '' ) );
        this.contactId = (raw['contactid']) ? raw['contactid'] : raw['contactId'] ? raw['contactId'] : '';
        this.accountId = (raw['accountid']) ? raw['accountid'] : '';
        this.createdTime = (raw['createdon']) ? new Date(parseInt(raw['createdon'])) : raw['createdTime'] ? raw['createdTime'] : null;
        this.noteText = (raw['notetext']) ? raw['notetext'].replace(/<[^>]*>/g, '') : raw['noteText'] ? raw['noteText'].replace(/<[^>]*>/g, '') : '';
        this.ownerName = (raw['ownerName']) ? raw['ownerName'] : '';
        this.ownerId = (raw['ownerid']) ? raw['ownerid'] : raw['ownerId'] ? raw['ownerId'] : '';
        this.hasDocument = (raw['isdocument']) ? raw['isdocument'] : raw['hasDocument'] ? raw['hasDocument'] : false;
        this.documentDataURL = (raw['documentbody']) ? raw['documentbody'] : raw['documentDataURL'] ? raw['documentDataURL'] : '';
        this.documentName = (raw['filename']) ? raw['filename'] : raw['documentName'] ? raw['documentName'] : '';
        this.documentSize = (raw['filesize']) ? raw['filesize'] : raw['documentSize'] ? raw['documentSize'] : 0;
        this.documentMimeType = (raw['mimetype']) ? raw['mimetype'] : raw['documentMimeType'] ? raw['documentMimeType'] : '';
        this.pendingPushForDynamics = (raw['pendingPushForDynamics']) ? raw['pendingPushForDynamics'] : raw['isDeleted'] ? raw['isDeleted'] : false;
        this.isDeleted = raw['deleted'] ? raw['deleted'] : raw['isDeleted'] ? raw['isDeleted'] : false;
        this.updated = raw['updated'] ? raw['updated'] : false;
    }
}

export interface IONoteDTO {
  annotationid?: string;
  isdocument?: boolean;
  hasDocument?: boolean;
  notetext?: string;
  createdon?: any;
  ownerid?: string;
  ownerName?: string;
  filename?: string;
  filesize?: number;
  mimetype?: string;
  documentbody?: string;
  activityid?: string;
  accountid?: string;
  contactid?: string;
  pendingPushForDynamics?: boolean;
  deleted?: boolean;
  isDeleted?: boolean;
  updated?: boolean;
  objecttypecode?: string;
}
