export const ALLOCATION_INVENTORY_ENTITY_NAME = '	indskr_userallocationquantities';
export const ALLOCATION_INVENTORY_FETCH = `
<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
  <entity name="indskr_userallocationquantity">
    <attribute name="indskr_userallocationquantityid" />
    <attribute name="indskr_user" />
    <attribute name="indskr_lot" />
    <attribute name="indskr_totalquantityremaining" />
    <attribute name="indskr_totalquantityrecieved" />
    <attribute name="indskr_totalquantitydropped" />
    <attribute name="indskr_totalquantitytransferred" />
    <attribute name="indskr_quantityadjusted" />
    <filter type="and">
      <condition attribute="statecode" operator="eq" value="0" />
      <condition attribute="indskr_user" operator="eq-userid" />
      {deltaSyncCondition}
    </filter>
    <link-entity name="product" from="productid" to="indskr_lot" link-type="outer" alias="aa">
      <attribute name="productid" />
      <attribute name="name" />
      <attribute name="indskr_parentproductid" />
      <attribute name="validtodate" />
      <attribute name="validfromdate" />
      {deltaSyncCondition}
    </link-entity>
  </entity>
</fetch>`