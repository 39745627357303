import { Subject } from "rxjs";
import { CoachingListComponent } from './../../coaching/coaching-list/coaching-list';
import { CoachingReportService } from './../../../services/coaching/coaching.report.service';
import { CallPlanOfflineService } from './../../../services/call-plan/call-plan.offline.service';
import {AlertService} from './../../../services/alert/alert.service';
import {TranslateService} from '@ngx-translate/core';
import {PresentationView, UIService} from './../../../services/ui/ui.service';
import {SearchConfigDataService} from './../../../data-services/search-config/search-config-data-service';
import {ContactDataService} from './../../../data-services/contact/contact.data.service';
import {
  SelectedSuggestionPillDataModel,
  SuggestionPillType,
  UserSavedSearchTypes
} from './../../../models/search-config-data-model';
import {ContactOfflineService, UserTagForContact} from './../../../services/contact/contact.service';
import {NavigationService, PageName} from './../../../services/navigation/navigation.service';
import {IndSectionHeaderViewDataModel} from './../../../models/indSectionHeaderDataModel';
import {IndAdvancedSearchResultCardViewDataModel, MainCardViewDataModel} from './../../../models/MainCardViewDataModel';
import {Component, EventEmitter, Input, Output} from '@angular/core';
import _ from 'lodash';
import {SearchConfigService} from '../../../services/search/search-config.service';
import {AccountOfflineService} from '../../../services/account/account.offline.service';
import {ResourceService} from '../../../services/resource/resource.service';
import {EmailTemplateServices} from './../../../data-services/email-templates/email-template.service';
import {PresentationService} from './../../../services/presentation/presentation.service';
import {AuthenticationService} from '../../../services/authentication.service';
import {FeatureActionsMap} from '../../../classes/authentication/user.class';
import { TimeOffService } from './../../../services/time-off/time-off.service';
import { TagEntityType, TagStateCode, UserTag, UserTagService } from "@omni/services/user-tag/user-tag.service";

/**
 * Generated class for the SearchSuggestionPopoverComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'search-suggestion-popover',
  templateUrl: 'search-suggestion-popover.html',
  styleUrls:['search-suggestion-popover.scss']
})
export class SearchSuggestionPopoverComponent {
  recentSearchesCardModels: MainCardViewDataModel[] = [];
  assessmentSearchesCardModels: MainCardViewDataModel[] = [];
  savedSearchesCardModels: MainCardViewDataModel[] = [];
  sharedSearchesCardModels: MainCardViewDataModel[] = [];
  customerTagsSearchesCardModels: MainCardViewDataModel[] = [];
  presentationTagsSearchesCardModels: MainCardViewDataModel[] = [];
  userTagsSearchesCardModels: MainCardViewDataModel[] = [];
  hasShareSearchEnabled: boolean = false;
  text: string;
  @Input() suggestionsData: {
    header:IndSectionHeaderViewDataModel,
    listData: MainCardViewDataModel
  }[];
  @Input() from: PageName;
  @Input() listPeriodTags: string[];
  @Output() selectionMade = new EventEmitter<any>();
  @Output() asessmentSelectionMade = new EventEmitter<any>();
  @Output() selectSavedSearch = new EventEmitter<any>();
  @Output() selectTag = new EventEmitter<any>();
  @Output() selectListPeriodTag = new EventEmitter<any>();
  public suggestionPillType = SuggestionPillType;
  recentSearchesHeaderModel : any
  public asessmentHeaderModel : any
  public savedSearchesHeaderModel: any
  public sharedSearchesHeaderModel:any;
  public savedSuggestionsData: MainCardViewDataModel[] = [];
  public SharedSuggestionsData: MainCardViewDataModel[] = [];
  @Input() isFromConsentTool: boolean;
  headerModelShared: boolean;
  headerModelSaved: boolean;

  customerTagHeaderModel: any;
  userTagHeaderModel:any;
  presentationTagHeaderModel:any;
  contactTagList: UserTagForContact[];
  constructor(
    private navService: NavigationService,
    private contactService: ContactOfflineService,
    public contactDataService: ContactDataService,
    public searchConfigDataService: SearchConfigDataService,
    public searchConfigService: SearchConfigService,
    public uiService: UIService,
    public accountService: AccountOfflineService,
    public translate: TranslateService,
    public alertService: AlertService,
    public resourceService: ResourceService,
    public presentationService: PresentationService,
    public emailTemplateServices: EmailTemplateServices,
    public authService:AuthenticationService,
    public timeOffService: TimeOffService,
    public callPlanOfflineService: CallPlanOfflineService,
    public coachingReportService: CoachingReportService,
    private readonly userTagService: UserTagService,
  ) {
    this.text = 'Hello World';
    this.contactTagList = this.contactService.contactTags;

  }
  ngOnInit() {
    this.recentSearchesHeaderModel = { id:"recentSearchHeader",title:this.translate.instant("RECENT_SEARCHES").toUpperCase(), searchPopoverHeader: true}
    this.asessmentHeaderModel = { id:"assessmentSearchHeader",title:this.translate.instant("ASSESSMENTS").toUpperCase(), searchPopoverHeader: true}
    this.savedSearchesHeaderModel = { id:"savedSearchHeader",title:this.translate.instant("SAVED_SEARCHES").toUpperCase(), searchPopoverHeader: true}
    this.sharedSearchesHeaderModel = { id:"sharedSearchHeader",title:this.translate.instant("SHARED_SEARCHES").toUpperCase(), searchPopoverHeader: true}
    let masterPage = this.from ? this.from : this.navService.getCurrentPageName();
    this.customerTagHeaderModel = { id:"customertagHeader",title:this.translate.instant("CUSTOMER_TAGS").toUpperCase(), searchPopoverHeader: true}
    this.userTagHeaderModel = { id: "userTagHeader",title:this.translate.instant("ACCOUNT_TAGS").toUpperCase(),searchPopoverHeader: true}
    this.presentationTagHeaderModel = { id: "presentationTagHeader",title:this.translate.instant("PRESENTATION_TAGS").toUpperCase(),searchPopoverHeader: true}

    switch(masterPage){
      case PageName.ContactPageComponent:
        let recentSearches
        if(!this.uiService.isConsentFromToolDrawer) recentSearches = this.contactService.recentSearches
        else recentSearches  = this.contactService.consentRecentSearches;
        this.initRecentSearchDataModels(recentSearches);
        this.initAssessmentSearchDataModels();
        let toolSearchName;
        if(!this.uiService.isConsentFromToolDrawer) toolSearchName = this.searchConfigService.toolNames.find(o=>o.toolName=='Contacts')
        else toolSearchName = this.searchConfigService.toolNames.find(o=>o.toolName=='Consents')
        let contactsearchToolNameID = toolSearchName?toolSearchName.searchToolNameID:'';
        this.searchConfigService.searchToolID = contactsearchToolNameID;
        this.initSavedSearchDataModels(contactsearchToolNameID,true);
        if (this.contactTagList.length) {
          this.initSavedCustomerTag(this.contactTagList);
        }
        if(this.listPeriodTags) this.initContactListPeriodTags(this.listPeriodTags);
        break;

      case PageName.AccountPageComponent:
        this.initRecentSearchDataModels(this.accountService.recentSearches);
        this.initAssessmentSearchDataModels();
        let toolName = this.searchConfigService.toolNames.find(o=>o.toolName=='Accounts')
        let accountSearchToolNameID = toolName?toolName.searchToolNameID:'';
        this.searchConfigService.searchToolID = accountSearchToolNameID;
        this.initSavedSearchDataModels(accountSearchToolNameID,true);
        if(this.userTagService.accountTags.length){
          this.initSavedTags(this.userTagService.accountTags);
        }
        if(this.listPeriodTags) this.initAccountListPeriodTags(this.listPeriodTags);
        break;

      case PageName.ResourcePageComponent:
        this.initRecentSearchDataModels(this.resourceService.recentSearches)
        let toolNameforResource = this.searchConfigService.toolNames.find(o=>o.toolName=='Resources')
        let resourceSearchToolNameID = toolNameforResource?toolNameforResource.searchToolNameID:'';
        this.searchConfigService.searchToolID = resourceSearchToolNameID;
        this.initSavedSearchDataModels(resourceSearchToolNameID);
        break;

      case PageName.PresentationPageComponent:
        console.log(this.presentationService.viewMode);
        let searches = this.presentationService.viewMode == PresentationView.ADDTOMEETING ? this.presentationService.contentRecentSearches : this.presentationService.recentSearches;
        this.initRecentSearchDataModels(searches)
        let toolNameforPresentation = this.searchConfigService.toolNames.find(o=>o.toolName==(this.presentationService.viewMode == PresentationView.ADDTOMEETING ? 'Contents' : 'Presentations'))
        let presentationSearchToolNameID = toolNameforPresentation?toolNameforPresentation.searchToolNameID:'';
        this.searchConfigService.searchToolID = presentationSearchToolNameID;
        this.initSavedSearchDataModels(presentationSearchToolNameID,true);
        if (this.userTagService.PresentationTags.length) {
          this.initSavedPresentationTag(this.userTagService.PresentationTags);
        }
        break;

      case PageName.EmailTemplatePageComponent:
        this.initRecentSearchDataModels(this.emailTemplateServices.recentSearches)
        let toolNameforEmailTemplate = this.searchConfigService.toolNames.find(o=>o.toolName=='Email Templates')
        let emailTemplateSearchToolNameID = toolNameforEmailTemplate?toolNameforEmailTemplate.searchToolNameID:'';
        this.searchConfigService.searchToolID = emailTemplateSearchToolNameID;
        this.initSavedSearchDataModels(emailTemplateSearchToolNameID);
        break;

      case PageName.TimeOffComponent:
        let timeOffRecentSearches;
        if (this.timeOffService.totMode != "teamRequests") timeOffRecentSearches = this.timeOffService.recentSearches
        else timeOffRecentSearches = this.timeOffService.teamRecentSearches
        this.initRecentSearchDataModels(timeOffRecentSearches)
        let toolNameforTimeOff;
        if (this.timeOffService.totMode != "teamRequests") toolNameforTimeOff = this.searchConfigService.toolNames.find(o=>o.toolName=='My Time Off')
        else toolNameforTimeOff = this.searchConfigService.toolNames.find(o=>o.toolName=='Team Time Off')
        let timeOffSearchToolNameID = toolNameforTimeOff?toolNameforTimeOff.searchToolNameID:'';
        this.searchConfigService.searchToolID = timeOffSearchToolNameID;
        this.initSavedSearchDataModels(timeOffSearchToolNameID);
        break;

      case PageName.ActivitiesPageComponent:
        if(this.uiService.activitiesPagePlanTabSelectedPlan !== 'callPlans') break;
      case PageName.CallPlanComponent:
        let callPlansRecentSearches;
        callPlansRecentSearches = (this.callPlanOfflineService.callPlanMode=="mine")?this.callPlanOfflineService.myRecentSearches:this.callPlanOfflineService.teamRecentSearches;
        this.initRecentSearchDataModels(callPlansRecentSearches);
        let toolNameforCallPlans;
        toolNameforCallPlans = (this.callPlanOfflineService.callPlanMode=="mine")?this.searchConfigService.toolNames.find(o=>o.toolName=='My Call Plans'):toolNameforCallPlans = this.searchConfigService.toolNames.find(o=>o.toolName=='Team Call Plans');
        let callPlansSearchToolNameID = toolNameforCallPlans?toolNameforCallPlans.searchToolNameID:'';
        this.searchConfigService.searchToolID = callPlansSearchToolNameID;
        this.initSavedSearchDataModels(callPlansSearchToolNameID);
        break;

      case PageName.CoachingComponent:
        let coachingRecentSearches;
        coachingRecentSearches = (this.coachingReportService.activeTab=="myCoaching")?this.coachingReportService.recentSearches:this.coachingReportService.teamRecentSearches;
        this.initRecentSearchDataModels(coachingRecentSearches);
        let toolNameforCoaching;
        toolNameforCoaching = (this.coachingReportService.activeTab=="myCoaching")?this.searchConfigService.toolNames.find(o=>o.toolName=='My Coaching'):this.searchConfigService.toolNames.find(o=>o.toolName=='Team Coaching');
        let coachingSearchToolNameID = toolNameforCoaching?toolNameforCoaching.searchToolNameID:'';
        this.searchConfigService.searchToolID = coachingSearchToolNameID;
        this.initSavedSearchDataModels(coachingSearchToolNameID);
        break;
    }
  }

  /**
   * @description This method takes the search key and with the savedSearches (includes both saved and shared searches)
   *  and created 2 models to be viewed using main-card component
   *  the 2 different models are savedSuggestionsData / SharedSuggestionsData which is filter based on key
   * @param {string=} key from parent component to filter the saved and shared searches
   * @returns
   */
  @Input() set searchKey(key: string) {
    if(key.length < 2){
      this.suggestionsData = [];
      return;
    }

    let savedSharedSuggestions = this.searchConfigService.savedSearches.filter(search => search.searchName.toLowerCase().indexOf(key.toLowerCase()) > -1);

    savedSharedSuggestions.sort((a,b)=>{
      if(a.searchName>b.searchName) return 1;
      else return -1;
    });

    this.savedSuggestionsData = [];
    this.SharedSuggestionsData = [];

    savedSharedSuggestions.forEach(savedSearch => {
      if(this.searchConfigService.searchToolID && savedSearch.searchToolName == this.searchConfigService.searchToolID && !savedSearch.isDeleted){
        let sharedSearchFlag =  savedSearch['searchType'] == UserSavedSearchTypes.SHARED ? true : false;
        let sharedByCurrentUser = sharedSearchFlag && (savedSearch['createdbyValue'] == this.authService.user.systemUserID || savedSearch['createdbyValue'] == undefined) ? true : false;
        let selectedSearchFlag : boolean = (this.searchConfigService.selectedSavedSharedSuggestionId == savedSearch.searchID || this.searchConfigService.selectedSavedSharedSuggestionId == savedSearch.offlineID) ? true : false;
        let model: MainCardViewDataModel={
          id: savedSearch.searchID || savedSearch.offlineID,
          primaryTextLeft: savedSearch.searchName,
          categoryValuePairs: savedSearch.categoryValuePairs.slice(),
          showArrow: selectedSearchFlag,
          isSelected: selectedSearchFlag,
          arrowType: 'indegene-selectors-checkmark-icon',
          clickHandler:((id?:string, event?:any, specificTarget?:string, dataRef?:any)=>{
            if(specificTarget && specificTarget == 'mainItem'){
              this.handleSavedSearchSuggestionClick(dataRef);
              this.searchConfigService.selectedSavedSharedSuggestionId = dataRef.id;
            }
          })
        }
        sharedSearchFlag && !sharedByCurrentUser ? this.SharedSuggestionsData.push(model) : this.savedSuggestionsData.push(model);
      }
    });
    if(!this.SharedSuggestionsData.length ){
      this.headerModelShared = false;
      this.headerModelSaved  = true;
    }
    if(!this.savedSuggestionsData.length){
      this.headerModelSaved = false;
      this.headerModelShared = true;
    }
     if(!this.SharedSuggestionsData.length && !this.savedSuggestionsData.length){
      this.headerModelSaved = false;
      this.headerModelShared = false;
    }
  }

  initRecentSearchDataModels(recentSearches){
    recentSearches.sort((a,b)=>{
      if(a.createdOn>b.createdOn) return -1;
      else return 1;
    })
    recentSearches.forEach(option=>{
      let model: IndAdvancedSearchResultCardViewDataModel = {
        id: option.categoryPath,
        primaryTextLeft: option.selectedFacet,
        secondaryTextLeft:option.categoryDisplayName,
        logicalCategoryName:option.categoryName,
        sugestionType:SuggestionPillType.CATEGORY_VALUESEARCH,
        showArrow:true,
        arrowType: 'indegene-ios-close-icon',
        // isSrcAttribute: true,
        // iconName: 'assets/imgs/ios-close-button.svg',
        isAggregatedSection: !!option.isAggregatedSection,
        searchAttributeNames: option.searchAttributeNames,
        configuredFrom: option.configuredFrom,
        clickHandler:((id?:string, event?:any, specificTarget?:string, dataRef?:any)=>{
          if(specificTarget && specificTarget == 'endArrow'){
            this.deleteRecentSearch(dataRef);
          }
          if(specificTarget && specificTarget == 'mainItem'){
            this.handleRecentSearchSuggestionClick(event, dataRef);
          }
        })
      }
      if(option.type && option.type == SuggestionPillType.ENTITY_LEVEL_CHARACTERSEARCH){
        model.sugestionType = SuggestionPillType.ENTITY_LEVEL_CHARACTERSEARCH
        model.charSearchText = option.charSearchText
      }
      if(option.type && option.type == SuggestionPillType.CUSTOM_ENTITY_LEVEL_CHARACTERSEARCH){
        model.sugestionType = SuggestionPillType.CUSTOM_ENTITY_LEVEL_CHARACTERSEARCH
        model.charSearchText = option.charSearchText
      }
      if(option.type && option.type == SuggestionPillType.CATEGORY_CHARACTERSEARCH){
        model.sugestionType = SuggestionPillType.CATEGORY_CHARACTERSEARCH
        model.charSearchText = option.charSearchText
      }
      if(option.type && option.type == SuggestionPillType.BOOLEAN_FIELD){
        model.sugestionType = SuggestionPillType.BOOLEAN_FIELD
        model.charSearchText = option.charSearchText
      }
      this.recentSearchesCardModels.push(model);
    })
  }

  private initAssessmentSearchDataModels() {
    if (this.authService.hasFeatureAction(FeatureActionsMap.CUSTOMER_ASSESS)) {
      let assessedModel: IndAdvancedSearchResultCardViewDataModel = {
        id: "assessed",
        primaryTextLeft: this.translate.instant("ASSESSED"),
        sugestionType: SuggestionPillType.CATEGORY_VALUESEARCH,
        showArrow: false,
        isSrcAttribute: false
      }
      let unAssessedModel: IndAdvancedSearchResultCardViewDataModel = {
        id: "unAssessed",
        primaryTextLeft: this.translate.instant("UNASSESSED"),
        sugestionType: SuggestionPillType.CATEGORY_VALUESEARCH,
        showArrow: false,
        isSrcAttribute: false
      }
      this.assessmentSearchesCardModels = [assessedModel, unAssessedModel];
    }
  }

  /**
   * @ngdoc method
   * @name initSavedSearchDataModels
   * @description This method takes the savedSearches (includes both saved and shared searches)
   *  and created 2 models to be viewed using main-card component
   *  the 2 different models are savedSearchesCardModels / sharedSearchesCardModels
   * @param {string=} searchToolID to compare the recieved searches with searchToolName
   * @returns
   */
  initSavedSearchDataModels(searchToolID, hasToShowShare = false){
    this.hasShareSearchEnabled = this.authService.hasFeatureAction(FeatureActionsMap.SEARCH_SHARE)? hasToShowShare : false;
    this.searchConfigService.savedSearches.forEach(savedSearch=>{
      if(searchToolID && savedSearch.searchToolName == searchToolID && !savedSearch.isDeleted){
        let sharedSearchFlag =  savedSearch['searchType'] == UserSavedSearchTypes.SHARED ? true : false;
        let sharedByCurrentUser = sharedSearchFlag && (savedSearch['createdbyValue'] == this.authService.user.systemUserID || savedSearch['createdbyValue'] == undefined) ? true : false;
        let endIconType : string= 'indegene-search-share-default';
        endIconType = (sharedSearchFlag && sharedByCurrentUser) ? (savedSearch['pendingSync']? 'indegene-search-share-pending':'indegene-search-share-success') : endIconType;
        let endIconCssClass: string = 'share-search';
        endIconCssClass = (sharedSearchFlag && sharedByCurrentUser) ? (savedSearch['pendingSync']? 'share-search-sync-pending':'share-search-sync-success') : endIconType;
        let model: MainCardViewDataModel={
          id: savedSearch.searchID || savedSearch.offlineID,
          primaryTextLeft: savedSearch.searchName,
          showArrow: sharedByCurrentUser || !sharedSearchFlag ? true : false,
          arrowType: 'indegene-ios-close-icon',
          endIconType: endIconType,
          endIconCssClass: endIconCssClass,
          showEndIcon: this.hasShareSearchEnabled && (sharedByCurrentUser || !sharedSearchFlag),
          // isSrcAttribute: true,
          // iconName: 'assets/imgs/ios-close-button.svg',
          categoryValuePairs: savedSearch.categoryValuePairs.slice(),
          clickHandler:((id?:string, event?:any, specificTarget?:string, dataRef?:any)=>{
            if(specificTarget && specificTarget == 'endArrow'){
              this.deleteSavedSearch(dataRef.id);
            }
            if(specificTarget && specificTarget == 'mainItem'){
              this.handleSavedSearchSuggestionClick(dataRef);
            }
            if(specificTarget && specificTarget == 'endIcon'){
              if(!sharedSearchFlag) {
                this.shareSearch(dataRef);
              }
            }
          })
        }
        sharedSearchFlag && !sharedByCurrentUser ? this.sharedSearchesCardModels.push(model) : this.savedSearchesCardModels.push(model);
      }
    })
  }


  /**
   * @description This method is called by clicking the share button in the saved searches list.
   *  calls the service to update the search field from saved to shared and updating the UI icons
   * @param {MainCardViewDataModel=} dataRef from initSavedSearchDataModels methods clickHandler to get the id of search to be shared
   * @returns
   */
  async shareSearch(dataRef){
    await this.searchConfigDataService.updateSavedSearch(dataRef.id, UserSavedSearchTypes.SHARED).then((data) =>{
      dataRef.endIconType='indegene-search-share-pending';
      dataRef.endIconCssClass='share-search-sync-pending';
      let index = this.searchConfigService.savedSearches.findIndex(savedSearch => savedSearch.searchID == dataRef.id || savedSearch.offlineID == dataRef.id);
      if(index != -1) {
        this.searchConfigService.savedSearches[index].pendingSync = true;
      }
      this.searchConfigDataService.sharedSearchColorIndicator.subscribe((data) =>{
        if(data){
          dataRef.endIconType='indegene-search-share-success';
          dataRef.endIconCssClass='share-search-sync-success';
        }
      })
    })
  }

  initSavedCustomerTag(contactTags: UserTagForContact[]) {
    contactTags.forEach(contactTag => {
      let model: MainCardViewDataModel = {
        id: contactTag.indskr_usertagid || contactTag.indskr_externalid,
        primaryTextLeft: contactTag.indskr_name,
        showArrow: contactTag.visibility == '548910001' ? false : true,
        arrowType: 'indegene-ios-close-icon',
        tagDetails: contactTag,
        clickHandler: ((id?: string, event?: any, specificTarget?: string, tag?: any) => {
          if (specificTarget == 'endArrow') {
            this.deleteTag(tag.tagDetails);

          } else if (specificTarget == 'mainItem') {
            this.selectTag.emit(tag.tagDetails);
          }
        })
      }
      this.customerTagsSearchesCardModels.push(model);
    });

  }

  initSavedPresentationTag(PresentationTags: UserTag[]) {
    PresentationTags.forEach(presentationTag => {
      let model: MainCardViewDataModel = {
        id: presentationTag.indskr_usertagid || presentationTag.indskr_externalid,
        primaryTextLeft: presentationTag.indskr_name,
        showArrow: false,
        arrowType: 'indegene-ios-close-icon',
        tagDetails: presentationTag,
        clickHandler: ((id?: string, event?: any, specificTarget?: string, tag?: any) => {
          if (specificTarget == 'endArrow') {
            this.deleteTag(tag.tagDetails);

          } else if (specificTarget == 'mainItem') {
            this.selectTag.emit(tag.tagDetails);
          }
        })
      }
      this.presentationTagsSearchesCardModels.push(model);
    });

  }


  initSavedTags(userTags: UserTag[]) {
    userTags.forEach(tag => {
      let model: MainCardViewDataModel = {
        id: tag.indskr_usertagid || tag.indskr_externalid,
        primaryTextLeft: tag.indskr_name,
        showArrow: tag.visibility == '548910001' ? false : tag.visibility == '600000000' ? false : true,
        arrowType: 'indegene-ios-close-icon',
        tagDetails: tag,
        clickHandler: ((id?: string, event?: any, specificTarget?: string, tag?: any) => {
          if (specificTarget == 'endArrow') {
            this.deleteTag(tag.tagDetails);

          } else if (specificTarget == 'mainItem') {
            this.selectTag.emit(tag.tagDetails);
          }
        })
      }
      this.userTagsSearchesCardModels.push(model);
    });

  }

  private initAccountListPeriodTags(tags: string[]) {
    tags.forEach(tag => {
      let model: MainCardViewDataModel = this.getListPeriodTagModel(tag)
      this.userTagsSearchesCardModels.push(model);
    })
  }

  private initContactListPeriodTags(tags: string[]) {
    tags.forEach(tag => {
      let model: MainCardViewDataModel = this.getListPeriodTagModel(tag)
      this.customerTagsSearchesCardModels.push(model);
    })
  }

  private getListPeriodTagModel(tag: string): MainCardViewDataModel {
    return {
      id: '',
      primaryTextLeft: tag,
      clickHandler: ((id?: string, event?: any, specificTarget?: string, tag?: any) => {
        if (specificTarget == 'mainItem') {
          this.selectListPeriodTag.emit(tag.primaryTextLeft);
        }
      })
    };
  }

  private deleteTag(userTagDetails: any) {
    this.alertService.showAlert({
      title: this.translate.instant('DELETE_TAG'),
      cssClass: 'saveSearchAlert',
      message: this.translate.instant('TAG_DELETE_CONFIRMATION'),
    }, this.translate.instant('DELETE'), this.translate.instant('CANCEL'))
      .then(async (res) => {
        if (res.role == 'ok') {
          if(userTagDetails.entity && userTagDetails.entity == TagEntityType.ACCOUNT){
            let deleteTagPayload = new UserTag(userTagDetails.indskr_externalid, userTagDetails.indskr_usertagid, true, userTagDetails.indskr_name, [],true,TagStateCode.InActive,TagEntityType.ACCOUNT,'');
            this.userTagService.deleteTag(deleteTagPayload);
          }else{
            let deleteTagPayload = new UserTagForContact(userTagDetails.indskr_externalid, userTagDetails.indskr_usertagid, true, userTagDetails.indskr_name, [],true,TagStateCode.InActive,'');
            this.contactService.deleteContactTag(deleteTagPayload);
          }
        }
      });
  }

  handleSuggestionClick(ev, value, category){
    ev.stopPropagation();
    let now = new Date()
    if(value.isSelected) return;
    let selectionMade:SelectedSuggestionPillDataModel = {
      selectedFacet: value.primaryTextLeft,
      categoryPath: category.header?category.header.id :'',
      categoryName: category.header?category.header.logicalTitle : '',
      categoryDisplayName : category.header?category.header.title:'',
      type:SuggestionPillType.CATEGORY_VALUESEARCH,
      createdOn: now.getTime(),
      isAggregatedSection: !!category.header?.isAggregatedSection,
      searchAttributeNames: category.header?.searchAttributeNames,
      configuredFrom: category.header?.configuredFrom,
    }
    if(value.sugestionType && value.sugestionType == SuggestionPillType.ENTITY_LEVEL_CHARACTERSEARCH){
      selectionMade.type = SuggestionPillType.ENTITY_LEVEL_CHARACTERSEARCH;
      selectionMade.charSearchText = value.charSearchText
    }
    if(value.sugestionType && value.sugestionType == SuggestionPillType.CUSTOM_ENTITY_LEVEL_CHARACTERSEARCH){
      selectionMade.type = SuggestionPillType.CUSTOM_ENTITY_LEVEL_CHARACTERSEARCH;
      selectionMade.charSearchText = value.charSearchText
    }
    if(value.sugestionType && value.sugestionType == SuggestionPillType.CATEGORY_CHARACTERSEARCH){
      selectionMade.type = SuggestionPillType.CATEGORY_CHARACTERSEARCH;
      selectionMade.charSearchText = value.charSearchText
    }
    if(value.sugestionType && value.sugestionType == SuggestionPillType.BOOLEAN_FIELD){
      selectionMade.type = SuggestionPillType.BOOLEAN_FIELD;
      selectionMade.charSearchText = selectionMade.selectedFacet;
      selectionMade.selectedFacet = selectionMade.categoryDisplayName+' - '+ selectionMade.selectedFacet;
    }
    this.searchConfigService.selectedSavedSharedSuggestionId = '';
    this.selectionMade.emit(selectionMade);
  }

  handleRecentSearchSuggestionClick(ev, value){
    ev.stopPropagation();
    let now = new Date();
    let selectionMade:SelectedSuggestionPillDataModel = {
      selectedFacet: value.primaryTextLeft,
      categoryPath: value.id,
      categoryName: value.logicalCategoryName,
      categoryDisplayName : value.secondaryTextLeft,
      isComingFromRecentSearch: true,
      type:SuggestionPillType.CATEGORY_VALUESEARCH,
      createdOn: now.getTime(),
      isAggregatedSection: value.isAggregatedSection,
      searchAttributeNames: value.searchAttributeNames,
      configuredFrom: value.configuredFrom,
    }
    if(value.sugestionType && value.sugestionType == SuggestionPillType.ENTITY_LEVEL_CHARACTERSEARCH){
      selectionMade.type = SuggestionPillType.ENTITY_LEVEL_CHARACTERSEARCH
      selectionMade.charSearchText = value.charSearchText
    }
    if(value.sugestionType && value.sugestionType == SuggestionPillType.CUSTOM_ENTITY_LEVEL_CHARACTERSEARCH){
      selectionMade.type = SuggestionPillType.CUSTOM_ENTITY_LEVEL_CHARACTERSEARCH
      selectionMade.charSearchText = value.charSearchText
    }
    if(value.sugestionType && value.sugestionType == SuggestionPillType.CATEGORY_CHARACTERSEARCH){
      selectionMade.type = SuggestionPillType.CATEGORY_CHARACTERSEARCH
      selectionMade.charSearchText = value.charSearchText
    }
    if(value.sugestionType && value.sugestionType == SuggestionPillType.BOOLEAN_FIELD){
      selectionMade.type = SuggestionPillType.BOOLEAN_FIELD;
      selectionMade.charSearchText = value.charSearchText;
    }
    this.searchConfigService.selectedSavedSharedSuggestionId = '';
    this.selectionMade.emit(selectionMade);
  }

  handleAssessmentSearchClick(ev, value) {
    this.asessmentSelectionMade.emit(value);
  }

  handleSavedSearchSuggestionClick(value){
    this.selectSavedSearch.emit(value);
  }

  deleteRecentSearch(data){
    _.remove(this.recentSearchesCardModels,(model)=> model == data);
    let masterPage = this.navService.getCurrentMasterPageName();
    switch(masterPage){
      case PageName.ContactPageComponent:
        _.remove(this.uiService.isConsentFromToolDrawer?this.contactService.consentRecentSearches:this.contactService.recentSearches,(model)=> (model.categoryPath == data.id && model.selectedFacet == data.primaryTextLeft)
                                                          ||(data.sugestionType==SuggestionPillType.ENTITY_LEVEL_CHARACTERSEARCH
                                                              && model.charSearchText == data.charSearchText)
                                                          ||(data.sugestionType==SuggestionPillType.CATEGORY_CHARACTERSEARCH
                                                              && model.charSearchText == data.charSearchText));
        break;
      case PageName.AccountPageComponent:
        _.remove(this.accountService.recentSearches,(model)=> (model.categoryPath == data.id && model.selectedFacet == data.primaryTextLeft)
                                                          ||(data.sugestionType==SuggestionPillType.ENTITY_LEVEL_CHARACTERSEARCH
                                                              && model.charSearchText == data.charSearchText)
                                                          ||(data.sugestionType==SuggestionPillType.CATEGORY_CHARACTERSEARCH
                                                              && model.charSearchText == data.charSearchText));
        break;
      case PageName.ResourcePageComponent:
        _.remove(this.resourceService.recentSearches,(model)=> (model.categoryPath == data.id && model.selectedFacet == data.primaryTextLeft)
                                                            ||(data.sugestionType==SuggestionPillType.ENTITY_LEVEL_CHARACTERSEARCH
                                                                && model.charSearchText == data.charSearchText)
                                                            ||(data.sugestionType==SuggestionPillType.CATEGORY_CHARACTERSEARCH
                                                                && model.charSearchText == data.charSearchText));
        break;
      case PageName.PresentationPageComponent:
        _.remove(this.presentationService.viewMode == PresentationView.ADDTOMEETING ? this.presentationService.contentRecentSearches : this.presentationService.recentSearches,(model)=> (model.categoryPath == data.id && model.selectedFacet == data.primaryTextLeft)
                                                            ||(data.sugestionType==SuggestionPillType.ENTITY_LEVEL_CHARACTERSEARCH
                                                                && model.charSearchText == data.charSearchText)
                                                            ||(data.sugestionType==SuggestionPillType.CUSTOM_ENTITY_LEVEL_CHARACTERSEARCH
                                                                && model.charSearchText == data.charSearchText)
                                                            ||(data.sugestionType==SuggestionPillType.CATEGORY_CHARACTERSEARCH
                                                                && model.charSearchText == data.charSearchText));
        break;
      case PageName.EmailTemplatePageComponent:
        _.remove(this.emailTemplateServices.recentSearches,(model)=> (model.categoryPath == data.id && model.selectedFacet == data.primaryTextLeft)
                                                            ||(data.sugestionType==SuggestionPillType.ENTITY_LEVEL_CHARACTERSEARCH
                                                                && model.charSearchText == data.charSearchText)
                                                            ||(data.sugestionType==SuggestionPillType.CATEGORY_CHARACTERSEARCH
                                                                && model.charSearchText == data.charSearchText));
        break;
      case PageName.TimeOffComponent:
        _.remove(this.timeOffService.totMode == "teamRequests"? this.timeOffService.teamRecentSearches:this.timeOffService.recentSearches,(model)=> (model.categoryPath == data.id && model.selectedFacet == data.primaryTextLeft)
                                                              ||(data.sugestionType==SuggestionPillType.ENTITY_LEVEL_CHARACTERSEARCH
                                                                  && model.charSearchText == data.charSearchText)
                                                              ||(data.sugestionType==SuggestionPillType.CATEGORY_CHARACTERSEARCH
                                                                  && model.charSearchText == data.charSearchText));
      case PageName.ActivitiesPageComponent:
        if(this.uiService.activitiesPagePlanTabSelectedPlan !== 'callPlans') break;
      case PageName.CallPlanComponent:
        _.remove(this.callPlanOfflineService.callPlanMode == "mine"? this.callPlanOfflineService.myRecentSearches:this.callPlanOfflineService.teamRecentSearches,(model)=> (model.categoryPath == data.id && model.selectedFacet == data.primaryTextLeft)
                                                            ||(data.sugestionType==SuggestionPillType.ENTITY_LEVEL_CHARACTERSEARCH
                                                                && model.charSearchText == data.charSearchText)
                                                            ||(data.sugestionType==SuggestionPillType.CATEGORY_CHARACTERSEARCH
                                                                && model.charSearchText == data.charSearchText));
        break;
      case PageName.CoachingComponent:
        _.remove(this.coachingReportService.activeTab == "myCoaching"? this.coachingReportService.recentSearches:this.coachingReportService.teamRecentSearches,(model)=> (model.categoryPath == data.id && model.selectedFacet == data.primaryTextLeft)
                                                            ||(data.sugestionType==SuggestionPillType.ENTITY_LEVEL_CHARACTERSEARCH
                                                                && model.charSearchText == data.charSearchText)
                                                            ||(data.sugestionType==SuggestionPillType.CATEGORY_CHARACTERSEARCH
                                                                && model.charSearchText == data.charSearchText));
        break;
    }
  }

  deleteSavedSearch(savedSearchID){
    this.alertService.showAlert({ title:this.translate.instant('DELETE_SEARCH'),
                                  cssClass:'saveSearchAlert',
                                  message:this.translate.instant('SEARCH_DELETE_CONFIRMATION'),
                                }, this.translate.instant('DELETE'), this.translate.instant('CANCEL'))
    .then(async (res)=>{
      if(res.role == 'ok'){
        _.remove(this.savedSearchesCardModels,(model)=> model.id == savedSearchID);
        this.searchConfigDataService.deleteSavedSearch(savedSearchID);
      }
    })
  }
}
