import { Injectable } from "@angular/core";
import { Endpoints } from '../../../config/endpoints.config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CallPlanOfflineService } from '../../services/call-plan/call-plan.offline.service';
import { DiskService } from '../../services/disk/disk.service';
import { RepCallPlanState } from "../../classes/call-plan/rep-call-plan.class";
import { DeviceService } from '../../services/device/device.service';
import { AuthenticationService } from '../../services/authentication.service';
import { DB_SYNC_STATE_KEYS, DB_KEY_PREFIXES } from '../../config/pouch-db.config';
import { DeltaService, EntityNames, EntitySyncInfo } from "../delta/delta.service";
import { FeatureActionsMap } from "../../classes/authentication/user.class";
import { SyncFeatureCategory } from '../../enums/delta-service/delta-service.enum';
import { DynamicsClientService } from "../dynamics-client/dynamics-client.service";
import { fetchQueries } from "@omni/config/dynamics-fetchQueries";
import { differenceInHours, format } from "date-fns";
import { SearchConfigService } from '@omni/services/search/search-config.service';
import { NavigationService, PageName } from "@omni/services/navigation/navigation.service";

@Injectable({
  providedIn: 'root'
})
export class CallPlanDataService {

    constructor(
        private http: HttpClient,
        private disk: DiskService,
        private callPlanOffline: CallPlanOfflineService,
        private device: DeviceService,
        private authenticationService: AuthenticationService,
        private deltaService: DeltaService,
        private readonly dynamics: DynamicsClientService,
        private searchConfigService: SearchConfigService,
        private navService: NavigationService,
    ) {}

    // public async syncCallPlans(dataRange: { from: string, to: string }, forceFullSync: boolean, loadFromDbOnly = false): Promise<void> {
    //     if (!this.authenticationService.hasFeatureAction(FeatureActionsMap.CALL_PLANS)) {
    //         return;
    //     }
    //     this.deltaService.pushSyncEntityName(SyncFeatureCategory.plans);
    //     this.callPlanOffline.clear();

    //     if (loadFromDbOnly) {
    //         await this._loadMyCallPlans(dataRange);
    //     } else {
    //         let myCallPlanSyncState = await this.disk.getSyncState(DB_SYNC_STATE_KEYS.SYNC_MY_CALLPLAN);
    //         const isInitialSyncMyCallPlan = !myCallPlanSyncState || !myCallPlanSyncState.lastUpdatedTime;
    //         const doFullSyncMyCallPlan = forceFullSync || isInitialSyncMyCallPlan;

    //         const newLastUpdatedTime = new Date().getTime();
    //         const myCallPlanSyncInfo: EntitySyncInfo = {
    //             entityName: EntityNames.callPlan,
    //             totalFailed: 0,
    //             totalSynced: 0,
    //             errors: [],
    //             syncStatus: true
    //         };

    //         //If isInitialSync, we clearly got nothing, load it up sonny, standard old school method of replace everything
    //         if (isInitialSyncMyCallPlan || doFullSyncMyCallPlan) {
    //             await this._initialSyncCallPlans(dataRange, myCallPlanSyncInfo);
    //         } else {
    //             //Delta baby
    //             //await this._doDeltaSync(syncState.lastUpdatedTime, callPlanSyncInfo);
    //             await this._doDeltaSyncMyCallPlans(dataRange, myCallPlanSyncState.lastUpdatedTime, myCallPlanSyncInfo);
    //         }

    //         this.deltaService.addEntitySyncInfo(myCallPlanSyncInfo);

    //         // // Done sync. Update sync state.
    //         if (myCallPlanSyncInfo.syncStatus) {
    //             myCallPlanSyncState.lastUpdatedTime = newLastUpdatedTime;
    //             await this.disk.updateSyncState(myCallPlanSyncState);
    //         }
    //         // My Recent Searches
    //         this.disk.retrieve(DB_KEY_PREFIXES.MY_CALL_PLANS_RECENT_SEARCHES, true).then((doc)=>{
    //             if(doc && doc.raw){
    //               this.callPlanOffline.myRecentSearches = doc.raw
    //             }
    //             else {
    //               this.callPlanOffline.myRecentSearches = [];
    //             }
    //           })

    //         //console.log(`Sync status variables: ${myCallPlanSyncState} & ${isInitialSync} & ${doFullSync} & ${newLastUpdatedTime}`);

    //         return;
    //     }
    // }

    // public async syncTeamCallPlans(dataRange: { from: string, to: string }, forceFullSync: boolean, loadFromDbOnly = false): Promise<void> {
    //     if (!this.authenticationService.hasFeatureAction(FeatureActionsMap.TEAM_CALL_PLANS)) {
    //         return;
    //     }

    //     if (loadFromDbOnly) {
    //         await this._loadTeamCallPlans(dataRange);
    //     } else {
    //         let teamCallPlanSyncState = await this.disk.getSyncState(DB_SYNC_STATE_KEYS.SYNC_TEAM_CALLPLAN);
    //         const isInitialSyncTeamCallPlan = !teamCallPlanSyncState || !teamCallPlanSyncState.lastUpdatedTime;
    //         const doFullSyncTeamCallPlan = forceFullSync || isInitialSyncTeamCallPlan;
    //         const newLastUpdatedTime = new Date().getTime();

    //         // deltachanges service returns delta count for both my & team call plans
    //         // therefore the count could be off in case either of my & team call plan service call fails..
    //         // This entity sync info is just to log errors.
    //         const teamCallPlanSyncInfo: EntitySyncInfo = {
    //             entityName: EntityNames.teamCallPlan,
    //             totalFailed: 0,
    //             totalSynced: 0,
    //             errors: [],
    //             syncStatus: true
    //         };

    //         if (isInitialSyncTeamCallPlan || doFullSyncTeamCallPlan) {
    //             await this._initialSyncTeamCallPlans(dataRange, teamCallPlanSyncInfo);
    //         } else {
    //             await this._doDeltaSyncTeamCallPlans(dataRange, teamCallPlanSyncState.lastUpdatedTime, teamCallPlanSyncInfo);
    //         }

    //         this.deltaService.addEntitySyncInfo(teamCallPlanSyncInfo);

    //         if (teamCallPlanSyncInfo.syncStatus) {
    //             teamCallPlanSyncState.lastUpdatedTime = newLastUpdatedTime;
    //             await this.disk.updateSyncState(teamCallPlanSyncState);
    //         }

    //         // Team Recent Searches
    //         this.disk.retrieve(DB_KEY_PREFIXES.TEAM_CALL_PLANS_RECENT_SEARCHES, true).then((doc)=>{
    //             if(doc && doc.raw){
    //               this.callPlanOffline.teamRecentSearches = doc.raw
    //             }
    //             else {
    //               this.callPlanOffline.teamRecentSearches = [];
    //             }
    //           })

    //         return;
    //     }
    // }

    // private async _initialSyncCallPlans(dataRange: { from: string, to: string }, callPlanSyncInfo: EntitySyncInfo) {
    //     //We call BMS delta sync get call plans with lastUpdatedTime as 0
    //     const url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.delta.GET_CALL_PLANS.replace('lastUpdatedTime={lastUpdatedTime}&', '') +
    //                         `&startDate=${dataRange.from}&endDate=${dataRange.to}`;

    //     //I believe we can map this normally with existing fn?
    //     let response: any;
    //     try {
    //         response = await this.http.get(url, Endpoints.GLOBAL_SYNC_HEADER).toPromise();
    //     } catch (error) {
    //         console.error('syncCallPlans: _initialSyncCallPlans: ', error);
    //         //callPlanSyncInfo.errorMessage = '[myCallPlan][error]' + error ? error.errorMessage : '';
    //         this.deltaService.addSyncErrorToEntitySyncInfo(callPlanSyncInfo, url, error);
    //     }

    //     if (response && Array.isArray(response)) {
    //         this.callPlanOffline.mapCallPlans(response, false, false);
    //         callPlanSyncInfo.totalSynced = response.length;
    //     }
    // }

    // private async _initialSyncTeamCallPlans(dataRange: { from: string, to: string }, callPlanSyncInfo: EntitySyncInfo) {
    //     //We call BMS delta sync get call plans with lastUpdatedTime as 0
    //     const url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.delta.GET_TEAM_CALL_PLANS.replace('lastUpdatedTime={lastUpdatedTime}&', '') +
    //                         `&startDate=${dataRange.from}&endDate=${dataRange.to}`;

    //     //I believe we can map this normally with existing fn?
    //     let response: any;
    //     try {
    //         response = await this.http.get(url, Endpoints.GLOBAL_SYNC_HEADER).toPromise();
    //     } catch (error) {
    //         console.error('syncCallPlans: _initialSyncTeamCallPlans: ', error);
    //         // callPlanSyncInfo.errorMessage = '[teamCallPlan][error]' + error ? error.errorMessage : '';
    //         this.deltaService.addSyncErrorToEntitySyncInfo(callPlanSyncInfo, url, error);
    //     }

    //     if (response && Array.isArray(response)) {
    //         this.callPlanOffline.mapCallPlans(response, true, false);
    //         callPlanSyncInfo.totalSynced = response.length;
    //     }
    // }

    // private async _loadMyCallPlans(dataRange: { from: string, to: string }) {
    //     let rawResponse = await this.disk.retrieve(DB_KEY_PREFIXES.MY_CALLPLAN);
    //     if(rawResponse != undefined) {
    //         await this.callPlanOffline.mapCallPlans(rawResponse.raw, false, false, true, dataRange);
    //     }
    // }

    // private async _loadTeamCallPlans(dataRange: { from: string, to: string }) {
    //     let rawResponseTeam = await this.disk.retrieve(DB_KEY_PREFIXES.TEAM_CALLPLAN);
    //     if(rawResponseTeam != undefined) {
    //         await this.callPlanOffline.mapCallPlans(rawResponseTeam.raw, true, false, true, dataRange);
    //     }
    // }

    // private async _doDeltaSyncMyCallPlans(dataRange: { from: string, to: string }, lastUpdated: number, callPlanSyncInfo: EntitySyncInfo) {
    //     await this._loadMyCallPlans(dataRange);

    //     const url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl +
    //         Endpoints.callplans.GET_BULK_DELTA_CALLPLANS.replace('{lastUpdatedTime}', lastUpdated.toString()) +
    //         `&startDate=${dataRange.from}&endDate=${dataRange.to}`;

    //     //Retrieve json responses of replacements
    //     let callPlanResponse: any;
    //     try {
    //         callPlanResponse = await this.http.get(url, Endpoints.GLOBAL_SYNC_HEADER).toPromise();
    //     } catch (error) {
    //         console.error('syncCallPlans: _doDeltaSync: ', error);
    //         //callPlanSyncInfo.errorMessage = '[myCallPlan][error]' + error ? error.errorMessage : '';
    //         this.deltaService.addSyncErrorToEntitySyncInfo(callPlanSyncInfo, url, error);
    //     }

    //     await this.callPlanOffline.mapCallPlans(callPlanResponse, false, true);
    //     if (Array.isArray(callPlanResponse)) {
    //         callPlanSyncInfo.totalSynced = callPlanResponse.length;
    //     }
    // }

    // private async _doDeltaSyncTeamCallPlans(dataRange: { from: string, to: string }, lastUpdated: number, callPlanSyncInfo: EntitySyncInfo) {
    //     await this._loadTeamCallPlans(dataRange);

    //     const urlTeam: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.delta.GET_TEAM_CALL_PLANS.replace('{lastUpdatedTime}', lastUpdated.toString()) +
    //                             `&startDate=${dataRange.from}&endDate=${dataRange.to}`;

    //     let teamCallPlanResponse: any;
    //     try {
    //         teamCallPlanResponse = await this.http.get(urlTeam, Endpoints.GLOBAL_SYNC_HEADER).toPromise();
    //     } catch (error) {
    //         console.error('syncCallPlans: _doDeltaSync: ', error);
    //         //callPlanSyncInfo.errorMessage = '[teamCallPlan][error]' + error ? error.errorMessage : '';
    //         this.deltaService.addSyncErrorToEntitySyncInfo(callPlanSyncInfo, urlTeam, error);
    //     }

    //     await this.callPlanOffline.mapCallPlans(teamCallPlanResponse, true, true);
    //     if (Array.isArray(teamCallPlanResponse)) {
    //         callPlanSyncInfo.totalSynced = teamCallPlanResponse.length;
    //     }
    // }

    /**
     *  Used for updating a call plan goals and state, eg approve it and set goals for 30
     *
     * @param {number} state
     * @param {number} status
     * @param {number} suggestedEmails
     * @param {number} suggestedCalls
     * @param {number} suggestedF2FMeetings
     * @param {number} suggestedRemoteMeetings
     * @param {number} suggestedPhoneCallMeetings
     * @memberof CallPlanDataService
     */
    public async updateCallPlan(callPlan, state: number, status: number, suggestedEmails?: number, suggestedCalls?: number, suggestedF2FMeetings?:number,suggestedRemoteMeetings?:number,suggestedPhoneCallMeetings?:number, offlineUpdateReqd?:boolean, ) {
      let url: string
      if (this.navService.getCurrentMasterPageName() == PageName.CustomerCallPlanPage) {
        url = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.callplans.UPDATE_CUSTOMER_CALL_PLAN;
        url=url.replace('{callPlanId}', callPlan.indskr_customercallplanid);
      } else {
        url = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.callplans.UPDATE_CALL_PLAN;
      }
        if (!this.device.isOffline) {
            try {
                let response = await this.http.patch(url, {
                    indskr_customercallplanid: callPlan.indskr_customercallplanid,
                    statecode: state,
                    statuscode: status,
                    indskr_suggestedemails: suggestedEmails ? suggestedEmails : 0,
                    indskr_suggestedcalls: suggestedCalls ? suggestedCalls : 0,
                    indskr_isrepcallplan: true,
                    indskr_suggestedf2fmeetings:suggestedF2FMeetings ?suggestedF2FMeetings : 0,
                    indskr_suggestedremotemeetings:suggestedRemoteMeetings?suggestedRemoteMeetings:0,
                    indskr_suggestedphonecallmeetings:suggestedPhoneCallMeetings?suggestedPhoneCallMeetings:0,
                }).toPromise();
                console.log('Updated call plan', response);
            } catch (httpError) {
                console.error('Caught error trying to update a call plan status', httpError);
            }
        }
        //Mark call plan as for review if status was open
        if (callPlan.statuscode == RepCallPlanState.Open) {
            callPlan.statuscode = RepCallPlanState.ForReview;
            // this.disk.updateOfflineRepCallPlan(this.callPlanOffline.selectedCallPlan, this.callPlanOffline.selectedRepCallPlan);
        //Mark call plan as approved if status was for review and status passed was approval
        } else if (callPlan.statuscode == RepCallPlanState.ForReview && status == RepCallPlanState.Approved) {
            callPlan.indskr_hocalls = suggestedCalls;
            callPlan.indskr_hoemails = suggestedEmails;
            callPlan.indskr_f2fgoals =suggestedF2FMeetings;
            callPlan.indskr_remotemeetinggoals =suggestedRemoteMeetings
            callPlan.indskr_phonecallgoals=suggestedPhoneCallMeetings

            callPlan.statuscode = RepCallPlanState.Approved;
            // this.disk.updateOfflineRepCallPlan(this.callPlanOffline.selectedCallPlan, this.callPlanOffline.selectedRepCallPlan);
        //Mark call plan as open if status was reject and status passed was reject
        } else if (callPlan.statuscode == RepCallPlanState.ForReview && status == RepCallPlanState.NotApproved) {
            callPlan.statuscode = RepCallPlanState.Open;
            // this.disk.updateOfflineRepCallPlan(this.callPlanOffline.selectedCallPlan, this.callPlanOffline.selectedRepCallPlan);
        }
        if(offlineUpdateReqd) this.disk.updateOfflineRepCallPlan(this.callPlanOffline.selectedRepCallPlan);
        //Update local Segment call plan
        let isAllRepCallPlansApproved:boolean = true;
        this.callPlanOffline.selectedCallPlan.repPlans.map(repCP=>{
            if(repCP['indskr_customercallplanid'] === callPlan['indskr_customercallplanid']){
                repCP = callPlan;
            }
            isAllRepCallPlansApproved = isAllRepCallPlansApproved && (repCP.statuscode === RepCallPlanState.Approved);
        });
        //Enable allRepCallPlansApproved flag if all rep call plans approved
        this.callPlanOffline.selectedCallPlan.allRepCallPlansApproved = isAllRepCallPlansApproved; // !(this.callPlanOffline.selectedCallPlan.repCallPlans.some(cp=>cp.statusCode !== RepCallPlanState.Approved));
        await this.updateSegmentCallPlan(this.callPlanOffline.selectedCallPlan);
        return Promise.resolve();
    }

    public async updateSegmentCallPlan(segmentCallPlan):Promise<any>{
        let callPlanHandle = segmentCallPlan.isTeamCallPlan ? this.callPlanOffline.teamSegmentCallPlans : this.callPlanOffline.segmentCallPlans;
        if(callPlanHandle && Array.isArray(callPlanHandle)){
            let currentCallPlanIndex = callPlanHandle.findIndex(callPlan => callPlan.cycleplanid == segmentCallPlan['cycleplanid']);
            if (currentCallPlanIndex >= 0) {
              //We have the new data and the call plan in question, let's map it.
              callPlanHandle[currentCallPlanIndex] = segmentCallPlan;
            }
        }
        // try{
        //     let currentJsonDocument = await this.disk.retrieve((segmentCallPlan.isTeamCallPlan ? DB_KEY_PREFIXES.TEAM_CALLPLAN : DB_KEY_PREFIXES.MY_CALLPLAN));
        //     if(currentJsonDocument && Array.isArray(currentJsonDocument.raw)){
        //         let replacementEntityIdx = currentJsonDocument.raw.findIndex(rawCallPlan => rawCallPlan.indskr_cycleplanid === segmentCallPlan['cyclePlanID']);
        //         if (replacementEntityIdx >= 0) {
        //             currentJsonDocument.raw[replacementEntityIdx] = segmentCallPlan;
        //         }
        //     }
        // }catch(err){
        //     console.log("updateSegmentCallPlan",err);
        // }
    }

    /**
     * Network request for call plan details regardless if is team or not
     */
    /*public async getCallPlanDetails(callPlan: CallPlan) {

        //Load the offline detail document
        let callPlanDetailsDocument = await this.disk.retrieve(callPlan.ID + '_details');
        if (callPlanDetailsDocument && callPlanDetailsDocument.repCallPlans && Array.isArray(callPlanDetailsDocument.repCallPlans)) {
            //We have rep call plan details i hope, we do.
            this.callPlanOffline.mapRepCallPlans(callPlanDetailsDocument, callPlan);
        }

        //No online call if offline
        if (this.device.isOffline) return;



        let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl;
        if (callPlan.isTeamCallPlan) {
            url += Endpoints.callplans.GET_TEAM_CALL_PLANS_DETAILS.replace('{callPlanID}', callPlan.ID);
        } else {
            url += Endpoints.callplans.GET_CUSTOMERS_FOR_CALLPLAN.replace('{callPlanID}', callPlan.ID);
        }

        //no await, use observable for async
        this.http.get(url).subscribe(response => {
            this.callPlanOffline.mapRepCallPlans(response, callPlan);
            this.disk.updateOrInsert(callPlan.ID + '_details', doc => response);
        });
    } */

    /*public async getSegmentCallPlanById(callPlan:CallPlan):Promise<CallPlan>{

        let cycleplanid = callPlan['cyclePlanID'];
        let isTeamCallPlan = callPlan['isTeamCallPlan'];

        let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl

        if(isTeamCallPlan){
            url += Endpoints.callplans.GET_TEAM_CALL_PLAN_BY_ID;
        }else{
            url += Endpoints.callplans.GET_CALL_PLAN_BY_ID;
        }

        try{
            url = url.replace('{callPlanID}', cycleplanid);
            url = url.replace("{last_updated_time}", ''+ callPlan['lastUpdatedTime']);

            let headers: HttpHeaders = new HttpHeaders();
                headers = headers.set('Cache-Control', 'no-cache');
            let response = await this.http.get(url, {headers: headers}).toPromise();
            if(response && Array.isArray(response) && response.length>0){
                response = response.filter(cp=> cp.indskr_cycleplanid === cycleplanid);
                await this.callPlanOffline.mapCallPlans(response, isTeamCallPlan,true,false);
                let searchPlans;
                if(callPlan.isTeamCallPlan){
                    searchPlans = this.callPlanOffline.teamCallPlans;
                }else{
                    searchPlans = this.callPlanOffline.callPlans;
                }
                let tempCallPlan = searchPlans.find(cp=>cp['cyclePlanID'] === response[0]['indskr_cycleplanid']);
                return (tempCallPlan) ? tempCallPlan : new CallPlan(response[0]);
            }else{
                return callPlan;
            }
        }catch(err){
            console.error("getSegmentCallPlanById: ",err);
            return callPlan;
        }
    }*/

     private _escapeRegExp(string) {
      return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
    }
    
     replaceAll(str, find, replace) {
      return str.replace(new RegExp(this._escapeRegExp(find), 'g'), replace);
    }
    

    async getCallPlans(dataRange: { from: string, to: string },fullSync? : boolean, loadFromDBOnly?:boolean, segmentPlanID?:string){
      try {
        if(loadFromDBOnly) return
        let deletedSegmentPlans = [];
        let lastModifiedForDeltaSync;
        let now = new Date();
        let hourDifference;
        await this.disk.retrieve(DB_KEY_PREFIXES.MY_POSITON_CALL_PLANS).then(data=>{
          if(!data || !data.raw){
            fullSync = true
          }
          else if(!fullSync && !segmentPlanID){
            lastModifiedForDeltaSync = data.lastUpdated
          }
          else if(segmentPlanID){
            let applicablePlans = data.raw.filter(o=>o.cycleplanid == segmentPlanID);
            if(applicablePlans.length){
              let iterator = 0
              do {
                if(applicablePlans[iterator].lastUpdated){
                  lastModifiedForDeltaSync = applicablePlans[iterator].lastUpdated;
                }
                iterator++;
                } while (applicablePlans[iterator] && !lastModifiedForDeltaSync);
            }
          }
        })
        if(!fullSync && !segmentPlanID){
          //fetch reopened segmentplans for removing related replplans from offline db
          deletedSegmentPlans = await this.dynamics.retrieveAll('indskr_trackchanges',['createdon','indskr_entityid'],
                                                    `indskr_action eq 548910006
                                                        and indskr_entityname eq 'indskr_cycleplan'
                                                        and createdon ge ${new Date(lastModifiedForDeltaSync).toISOString()}`)
                                                    .then(res => res.value).catch(()=> []);
        }
        let fetchXML1 = fetchQueries.callPlans.myPositionCallPlans;
        let fetchXML2 = fetchQueries.callPlans.otherCoveragePositionCallPlans;
        let positionIDstring = '';
        //position filters
        fetchXML1 = fetchXML1.split('{positionAttributeIdentifier}').join('positionid');
        fetchXML2 = fetchXML2.split('{positionAttributeIdentifier}').join('positionid');
        this.authenticationService.user.positions.forEach((pos)=>{
          positionIDstring+= '<value>'+ pos.ID +'</value>'
        })
        fetchXML1 = fetchXML1.split('{positionIDs}').join(positionIDstring);
        fetchXML2 = fetchXML2.split('{positionIDs}').join(positionIDstring);
        //data range
        const startDate = format(parseInt(dataRange.from),'YYYY-MM-DD');
        const endDate = format(parseInt(dataRange.to),'YYYY-MM-DD');
        var re = new RegExp('{startDate}', 'g');
        fetchXML1 =  this.replaceAll(fetchXML1,'{startDate}', startDate) //fetchXML1.replaceA('/{startDate}/g',startDate);
        fetchXML2 = this.replaceAll(fetchXML2, '{startDate}', startDate)
         re = new RegExp('{endDate}', 'g');
        fetchXML1 = this.replaceAll(fetchXML1, '{endDate}', endDate)//fetchXML1.replace('/{endDate}/g',endDate);

         //fetchXML2.replace('/{startDate}/g',startDate);
         fetchXML2 = this.replaceAll(fetchXML2, '{endDate}', endDate)//fetchXML2.replace('/{endDate}/g',endDate);
         //delta sync filter
        let deltaSyncFilter;
        // let statusFilter = '<condition attribute="statecode" operator="ne" value="1"/>';
        if(lastModifiedForDeltaSync && !fullSync){
          hourDifference = differenceInHours(now,new Date(lastModifiedForDeltaSync))
          //add one to make sure we take care of fractional difference in hours
          hourDifference += 1
          deltaSyncFilter = `<condition attribute="modifiedon" operator="last-x-hours" value="${hourDifference}"/>`
          // statusFilter = ``;
        }
        else deltaSyncFilter = '';
        fetchXML1 = fetchXML1.replace('{modifiedOnFilter}',deltaSyncFilter);
        fetchXML2 = fetchXML2.replace('{modifiedOnFilter}',deltaSyncFilter);
        //segmentPlanID filter
        let planIDFilterString;
        planIDFilterString = segmentPlanID? `<filter><condition attribute="indskr_cycleplanid" operator="eq" value="${segmentPlanID}"></condition></filter>`:''
        fetchXML1 = fetchXML1.replace('{segmentPlanFilter}',planIDFilterString);
        // fetchXML1 = fetchXML1.replace('{statusFilter}', statusFilter);
        fetchXML2 = fetchXML2.replace('{segmentPlanFilter}',planIDFilterString);
        // fetchXML2 = fetchXML2.replace('{statusFilter}', statusFilter);
        let tasks = []
        tasks.push(this.dynamics.executeFetchQuery('indskr_customercallplans',fetchXML1).then(myPositionPlans =>{
          console.log('call plans my position', myPositionPlans);
          if(!segmentPlanID) this.searchConfigService.isContactProfileDataMappedToContacts = false;
          this.disk.updateOrInsert(DB_KEY_PREFIXES.MY_POSITON_CALL_PLANS,(doc)=>{
            if(!doc || !doc.raw){
              doc = {
                raw:[]
              }
            }
            if(fullSync){
              doc.raw = myPositionPlans
              doc.lastUpdated = now.getTime();
              return doc;
            }
            else{
              if(!fullSync && !segmentPlanID && deletedSegmentPlans.length) doc.raw = doc.raw.filter(o=> !deletedSegmentPlans.some(r=> r.indskr_entityid==o.cycleplanid));
              /** filter out duplicate data. Used when the user selects a call plan from the list(it has segmentPlanID). 
               * indskr_customercallplanid is generated when reopening and distributing in Dynamics. Then it caused the duplicate data
               * raw data is filtered by cycleplanid and contactId before checking indskr_customercallplanid.
               */
              if(!fullSync && myPositionPlans.length && segmentPlanID && this.callPlanOffline.selectedCallPlan && segmentPlanID == this.callPlanOffline.selectedCallPlan?.cycleplanid) {
                const selectedCallPlanId = this.callPlanOffline.selectedCallPlan?.cycleplanid;
                myPositionPlans.forEach(cp => {
                  doc.raw = doc.raw.filter(r=> !(r.cycleplanid == selectedCallPlanId && r.contactId == cp.contactId));
                });
              }
              let offlineCopy = doc.raw.slice();
              myPositionPlans.forEach(cp => {
                cp.lastUpdated = now.getTime();
                let idx = offlineCopy.findIndex(o=>o.indskr_customercallplanid==cp.indskr_customercallplanid)
                if(idx>-1)offlineCopy[idx]=cp
                else offlineCopy.push(cp);
              });
              // offlineCopy = offlineCopy.filter(oc => oc.statecode != 1);
              doc.raw = offlineCopy;
              if(!segmentPlanID) doc.lastUpdated = now.getTime();
              if(segmentPlanID && this.callPlanOffline.selectedCallPlan?.cycleplanid==segmentPlanID && myPositionPlans.length){
                let selectedSegmentPlans = offlineCopy.filter(o=>o.cycleplanid == segmentPlanID);
                selectedSegmentPlans[0].customerName = selectedSegmentPlans[0].contactFirstName + " " + selectedSegmentPlans[0].contactLastName
                let a = {'cycleplanid': selectedSegmentPlans[0].cycleplanid,
                          'cycleplanname':selectedSegmentPlans[0].cycleplanname,
                          'repPlans':[selectedSegmentPlans[0]],
                          'indskr_enddate':selectedSegmentPlans[0].indskr_enddate,
                          'indskr_startdate':selectedSegmentPlans[0].indskr_startdate,
                          'actualCalls':selectedSegmentPlans[0].indskr_actualcalls || 0,
                          'totalGoalCalls':selectedSegmentPlans[0].indskr_hocalls || 0,
                          'actualEmails':selectedSegmentPlans[0].indskr_actualemails || 0,
                          'totalGoalEmails':selectedSegmentPlans[0].indskr_hoemails || 0,
                          'allRepCallPlansApproved': selectedSegmentPlans[0].statuscode == 2?true:false,
                          'productName':selectedSegmentPlans[0].productid_Formatted || '',
                          'suggestionApprovalneeded':  selectedSegmentPlans[0].cycleplansuggestionapprovalneeded
                        };
                        for(var id=1; id<selectedSegmentPlans.length;id++){
                          let c = selectedSegmentPlans[id];
                          c.customerName = c.contactFirstName + " " + c.contactLastName
                          a.repPlans.push(c)
                          a.actualCalls += c.indskr_actualcalls?c.indskr_actualcalls:0
                          a.totalGoalCalls += c.indskr_hocalls?c.indskr_hocalls:0
                          a.actualEmails += c.indskr_actualemails?c.indskr_actualemails:0
                          a.totalGoalEmails += c.indskr_hoemails?c.indskr_hoemails:0
                          a.allRepCallPlansApproved = a.allRepCallPlansApproved && c.statuscode == 2?true:false
                        }
                let idx = this.callPlanOffline.segmentCallPlans.findIndex(o=>o.cycleplanid == a.cycleplanid)
                if(idx>-1)this.callPlanOffline.segmentCallPlans[idx] = a;
                this.callPlanOffline.selectedCallPlan = a;
                this.callPlanOffline.segmentCallPlansList$.next({hasUpdatedData:true})
              }
              return doc;
            }
          })
        }));
        tasks.push(this.dynamics.executeFetchQuery('indskr_customercallplans',fetchXML2).then(otherPosPlans=>{
          console.log('call plans other position', otherPosPlans);
          this.disk.updateOrInsert(DB_KEY_PREFIXES.OTHER_POSITON_CALL_PLANS,(doc)=>{
            if(!doc || !doc.raw){
              doc = {
                raw:[]
              }
            }
            if(fullSync){
              doc.raw = otherPosPlans
              doc.lastUpdated = now.getTime();
              return doc;
            }
            else{
              if(!fullSync && !segmentPlanID && deletedSegmentPlans.length) doc.raw = doc.raw.filter(o=> !deletedSegmentPlans.some(r=> r.indskr_entityid==o.cycleplanid));
              /** filter out duplicate data. Used when the user selects a call plan from the list(it has segmentPlanID). 
               * indskr_customercallplanid is generated when reopening and distributing in Dynamics. Then it caused the duplicate data
               * raw data is filtered by cycleplanid and contactId before checking indskr_customercallplanid.
               */
             if(!fullSync && otherPosPlans.length && segmentPlanID && this.callPlanOffline.selectedCallPlan && segmentPlanID == this.callPlanOffline.selectedCallPlan?.cycleplanid) {
              const selectedCallPlanId = this.callPlanOffline.selectedCallPlan?.cycleplanid;
              otherPosPlans.forEach(cp => {
                doc.raw = doc.raw.filter(r=> !(r.cycleplanid == selectedCallPlanId && r.contactId == cp.contactId));
               });
              }
              let offlineCopy = doc.raw.slice();
              otherPosPlans.forEach(cp => {
                cp.lastUpdated = now.getTime();
                let idx = offlineCopy.findIndex(o=>o.indskr_customercallplanid==cp.indskr_customercallplanid)
                if(idx>-1)offlineCopy[idx]=cp
                else offlineCopy.push(cp);
              });
              // offlineCopy = offlineCopy.filter(oc => oc.statecode != 1);
              doc.raw = offlineCopy
              if(!segmentPlanID) doc.lastUpdated = now.getTime();
              return doc;
            }
          }).then(()=>{
            if(segmentPlanID && this.callPlanOffline.selectedCallPlan?.cycleplanid==segmentPlanID && otherPosPlans.length){
              this.callPlanOffline.otherRepCallPlansList$.next({hasUpdatedData:true})
            }
          })
        }))
        await Promise.all(tasks);
      } catch (error) {
        console.log(error)
      }
    }

    async getTeamCallPlans(dataRange: { from: string, to: string },fullSync? : boolean){
      try {
        let fetchXML1 = fetchQueries.callPlans.myPositionCallPlans;
        let fetchXML2 = fetchQueries.callPlans.otherCoveragePositionCallPlans;
        let positionIDstring = ''; let now = new Date()
        //position filters
        fetchXML1 = fetchXML1.split('{positionAttributeIdentifier}').join('parentpositionid');
        fetchXML2 = fetchXML2.split('{positionAttributeIdentifier}').join('parentpositionid');
        this.authenticationService.user.positions.forEach((pos)=>{
          positionIDstring+= '<value>'+ pos.ID +'</value>'
        })
        fetchXML1 = fetchXML1.split('{positionIDs}').join(positionIDstring);
        fetchXML2 = fetchXML2.split('{positionIDs}').join(positionIDstring);
        //data range
        const startDate = format(parseInt(dataRange.from),'YYYY-MM-DD');
        const endDate = format(parseInt(dataRange.to),'YYYY-MM-DD');

        fetchXML1 = this.replaceAll(fetchXML1, '{startDate}', startDate)//fetchXML1.replace('/{startDate}/g',startDate);        
        fetchXML1 = this.replaceAll(fetchXML1, '{endDate}', endDate)//fetchXML1.replace('/{endDate}/g',endDate);

        fetchXML2 = this.replaceAll(fetchXML2, '{startDate}', startDate)//fetchXML2.replace('/{startDate}/g',startDate);
        fetchXML2 = this.replaceAll(fetchXML2, '{endDate}', endDate) //fetchXML2.replace('/{endDate}/g',endDate);
        //segmentPlanID filter
        fetchXML1 = fetchXML1.replace('{segmentPlanFilter}','');
        fetchXML2 = fetchXML2.replace('{segmentPlanFilter}','');
        let tasks = [];
        let myChildPositionPlans
        tasks.push(this.dynamics.executeFetchQuery('indskr_customercallplans',fetchXML1).then(data=>{
          console.log('call plans my position', data);
          myChildPositionPlans = data;
        }))
        tasks.push(this.dynamics.executeFetchQuery('indskr_customercallplans',fetchXML2).then(otherChildPosPlans=>{
          console.log('call plans other position', otherChildPosPlans);
          this.callPlanOffline.teamPositionsOtherRepPlans = otherChildPosPlans
        }))
        return Promise.all(tasks).then(()=>myChildPositionPlans, ()=>[]);
      } catch (error) {
        console.log(error)
      }
    }
}
