import { Component, ViewChild, HostBinding, Input } from '@angular/core';
import { IonNav, NavParams } from '@ionic/angular';
import { Events } from '@omni/events';
import { FooterViews, FooterService } from '../../../services/footer/footer.service';
import { VIEW_MODE_CHANGE, DeviceService } from '../../../services/device/device.service';
import { AccountOfflineService } from '../../../services/account/account.offline.service';
import { UIService } from '../../../services/ui/ui.service';
import { ActivityService } from '../../../services/activity/activity.service';
import { NavigationService, PageName, ChildNavNames } from '../../../services/navigation/navigation.service';
import { LocalizationService } from '@omni/services/localization/localization.service';

/**
 * Generated class for the AccountPageComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'account-page[base-page]',
  templateUrl: 'account-page.html',
  styleUrls:['account-page.scss']
})
export class AccountPageComponent {

  public accountListMode: string;
  @Input() paramAccount: string;

  @ViewChild('accountpagerightpane') public navCtrl: IonNav;
  @Input() listMode: string;
  @Input() callbackEvent: any;
  @Input() selectedAccountId: any;
  @Input() selectedAccountIds: any = [];
  @Input() selectedContactIds: any = [];
  @Input() accountIdfromIndDynamic: any = [];

  @HostBinding("class.account-right-pane-active")
  public get accountrightpaneactive(): boolean {
    let decision: boolean = (this.accountOfflineService.selected && this.uiService.activeView == 'accountDetails') ||
      (this.activityService.selectedActivity && this.uiService.activeView == 'activityTimelineDetails') ||
      (this.activityService.selectedActivity && this.uiService.activeView == 'account_customer_inquiry') ||
      (this.activityService.selectedActivity && this.uiService.activeView == 'ReadOnlyContact') ||
      (this.activityService.selectedActivity && this.uiService.activeView == 'ReadOnlyAccounts') ||
      this.uiService.activeView == 'AccountPageRightPaneNav'
    return decision;
  }

  constructor(
    private footerService: FooterService,
    public uiService: UIService,
    public activityService: ActivityService,
    private accountOfflineService: AccountOfflineService,
    public navService: NavigationService,
    private events: Events,
    private device: DeviceService,
    private localizationService: LocalizationService,
    private navParams: NavParams,
  ) {
    this.footerService.initButtons(FooterViews.Accounts);
  }

  async ngOnInit() {
    this.accountListMode = this.listMode;
    this.localizationService.initMultilingualFieldsData();
  }

  ionViewDidLoad() {
    this.uiService.accountDataSegment = 'info';
  }

  ngAfterViewInit() {
     this.navService.initRightPaneChildNav(this.navCtrl, ChildNavNames.AccountPageNavigation, PageName.AccountPageComponent, false,{deviceString: this.device.deviceFlags.ios ? 'ios' : 'other'});
  }

  ngOnDestroy() {
    this.navService.popChildNavCtrlFromStack(ChildNavNames.AccountPageNavigation);
    this.localizationService.resetMultilingualFieldsData();
  }

  ionViewCanEnter() {
    this.footerService.initButtons(FooterViews.Accounts);
  }

  ionViewDidEnter() {

    setTimeout(() => {
      if (this.navService.getActiveChildNavViewPageName() == PageName.AccountDetailsComponent) {
        this.footerService.initButtons(FooterViews.Accounts);
      } else if (this.navService.getActiveChildNavViewPageName() == PageName.ActivitiesDetailsPaneComponent) {
        this.footerService.initButtons(FooterViews.Activities);
      } else if (this.navService.getActiveChildNavViewPageName() == PageName.CaseManagementDetailComponent) {
        this.footerService.initButtons(FooterViews.CUSTOMER_INQUIRY);
      }
    }, 1);

    if (this.uiService.isFlipButtonClicked) {
      this.uiService.isFlipButtonClicked = false;
    }
  }


  closeAccountModal() {
    this.navService.popWithPageTracking();
    if (!this.accountOfflineService.isSchedulerInvoked) {
      this.uiService.showNewActivity = false
    }
  }

}
