import { Injectable, ApplicationRef, ElementRef } from "@angular/core";
import { Activity, ActivityType, } from '../../classes/activity/activity.class';
import { AlertController } from "@ionic/angular";
import { BehaviorSubject, Observable } from 'rxjs';
import { LoadingController } from "@ionic/angular/";
import { ConfirmationAlertData } from "../../classes/shared/confirmation-alert-data.class";
import { TranslateService } from "@ngx-translate/core";
import { EventsService } from "../events/events.service";
import { DeviceService } from '../device/device.service';
import { VeevaWidgetConfig } from '@omni/models/veeva.widget.config';
import { IndTabsDataModel } from '../../models/ind-tabs-data-model';
import { AuthenticationService } from '../authentication.service';
import { FeatureActionsMap } from '../../classes/authentication/user.class';
import { PageName } from '../navigation/navigation.service';
import { FeatureActionsService } from "../feature-actions/feature-actions.service";

export enum ComponentViewMode {
    READONLY = "readonly",
    ADDNEW = "addnew",
    LISTVIEW = "listview",
    SELECTION = "selection",
    PREVIEW= "preview"
}

export enum NewMeetingActivityViews {
    MEETING,
    EMAIL,
    PHONE,
    TIMEOFF,
    ALLOCATIONSORDER
}

export enum PresentationView {
    MEETING = "meeting",
    ADDTOMEETING = "addtomeeting",
    MENU = "menu",
    FULLSCREEN = "fullscreen",
    ADDTOEVENT = "addtoevent",
    ADDTOACCOUNTPLAN = "addtoaccountplan",
    VIEWFROMACCOUNTPLAN = "viewfromaccountplan"
}

export enum AppView {
    ACCOUNTS = "Accounts",
    CALENDAR = "Calendar",
    CALLPLANS = "CallPlans",
    CONTACTS = "Contacts",
    PRESENTATIONS = "Presentation",
    RESOURCES = "Resources",
    SETTINGS = "Settings",
    TIMEOFFS = "TimeOffs",
    CUSTOMERALLOCATIONS = "CustomerAllocations",
}

export const TabOptions = {
  '600000000' : 'plans', 
  '600000001' : 'activities', 
  '600000002' : 'edgeAnalytics', 
  '600000003' : 'xperiences', 
  '600000004' : 'Notification' 
}

@Injectable({
  providedIn: 'root'
})
export class UIService {
    public showNewActivity: boolean = false;
    public isFromOpportunites: boolean = false;
    public uiServiceData: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);  //this is a way to pass navigation data to the UI service
    public previousActiveView: string;
    public activeView: string;
    public activeViewOnPlanTab: string;
    public activitiesPageTab: string = 'agenda';
    public activitiesPagePlanTabSelectedPlan: string;
    public activitiesPagePlanTabSelectedPlanAtHome: string;
    public activeViewNewMeeting: string;
    public activeViewNewMeetingType: string;
    public prevView: string;
    public showFieldCoaching: boolean = false;
    public isConsentFromToolDrawer: boolean = false;
    public consentFlipButton: boolean = false;
    public cellData: { view: string, data: { startDate: Date, endDate: Date } };
    public oGenieMessages: Array<oGenieMessage>;
    public oGenieOptions: Array<oGenieOption>;
    private readonly MAX_MESSAGES = 7;

    public homepageLoaded: boolean;
    public tabsData: IndTabsDataModel[];
    public contactDetailsSegment: string = '';
    public accountInfoSegment: string = '';
    public accountDataSegment: string = '';

    public showCancelDoneOnActivityDetails: boolean = false;

    private _selectedCalendarDateInMonthView: Date;
    private _selectedCalendarDateInWeekView: Date;
    private _loader:HTMLIonLoadingElement;

    public isFlipButtonClicked: boolean = false;
    isPlusButtonCickedFromPatricipantsInEventDetails = false;

    private newActivityRequestDataSource = new BehaviorSubject<boolean>(false);
    public activityRequestObserver = this.newActivityRequestDataSource.asObservable();

    private tabDataSource = new BehaviorSubject<string>('');
    public tabObserver = this.tabDataSource.asObservable();

    private _showRightPane: boolean = false; //Show right pane for mobile

    public geneeSyncPopover: HTMLIonPopoverElement;

    public multiSelectFilterSelectedData: any[] = []

    public scientificInfoView: string = '';
    public nothingSelectedScreenMessage: string = ''
    agendaRefreshRequired: boolean = false;
    toolsActivityActive = false;
    public isDeltaSyncTriggred: boolean = false;

    private agendaTabSource = new BehaviorSubject<string>('');
    public agendaTabObserver = this.agendaTabSource.asObservable();
    public isAnimating: boolean = false;
    isEmailAccessedFromConsnentDetails = false;
    timelineRefreshRequired: boolean = false;

    //global attibute to access page name
    public page = PageName;

    public selectedOptionInMenu: string = '';
    public isContactsFromToolDrawer: boolean = false;
    public isAccountsFromToolDrawer: boolean = false;
    public isForcedHideGoToToolBtn: boolean = false;
    public isFilterActivated: boolean = false;

    updateNothingSelectedScreenMessageFor(dataSize: number) {

        this.nothingSelectedScreenMessage = dataSize > 0 ? this.translate.instant('NOTHING_SELECTED') : ''
        this.events.publish('nothingSelectedMessageUpdate')

    }

    setOGenieOptions(options: Array<oGenieOption>) {
        this.oGenieOptions = options;
        this.appRef.tick();
    }

    setnewActivityRequest(value: boolean) {
        this.newActivityRequestDataSource.next(value);
    }

    setTabSwitch(view: string) {
        this.tabDataSource.next(view);
    }

    addOGenieMessage(message: oGenieMessage) {
        if (this.oGenieMessages.length > this.MAX_MESSAGES) {
            this.oGenieMessages.splice(0, 1);
        }
        this.oGenieMessages.push(message);
        this.appRef.tick();
    }

    constructor(
        private appRef: ApplicationRef,
        private loadingCtrl: LoadingController,
        private alertCtrl: AlertController,
        private translate : TranslateService,
        private events : EventsService,
        private authenticationOfflineService: AuthenticationService,
        private deviceService: DeviceService,
        private faService: FeatureActionsService,
    ) {
        this.showNewActivity = false;
        this.homepageLoaded = false;
        this.oGenieMessages = [];
        this.activeViewNewMeeting = 'meeting';
    }

    public set showRightPane(value: boolean) {
        this._showRightPane = value;
    }

    public get showRightPane(): boolean {
        return this._showRightPane;
    }

    public get getUIServiceData(): Observable<any> {
        return this.uiServiceData.asObservable();
    }

    public setUIServiceData(data: any) {
        this.uiServiceData.next(data);
    }

    public get getCellData(): { view: string, data: { startDate: Date, endDate: Date } } {
        return this.cellData;
    }

    public set setCellData(data: { view: string, data: { startDate: Date, endDate: Date } }) {
        this.cellData = data;
    }

    get selectedCalendarDateInMonthView(): Date {
        return this._selectedCalendarDateInMonthView;
    }

    set selectedCalendarDateInMonthView(selDate: Date) {
        this._selectedCalendarDateInMonthView = selDate;
    }

    get selectedCalendarDateInWeekView(): Date {
        return this._selectedCalendarDateInWeekView;
    }

    set selectedCalendarDateInWeekView(selDate: Date) {
        this._selectedCalendarDateInWeekView = selDate;
    }

    setShowNewActivity(flag: boolean) {
        this.showNewActivity = flag;
    }

    public setActiveViewForNewMeeting(viewName: string, subViewName?: string) {
        this.activeViewNewMeeting = viewName;
        this.activeViewNewMeetingType = subViewName;
    }

    public setActiveView(view: string) {
        console.log("active view: " + view);
        this.activeView = view;
    }

    public async displayLoader(options:any = {}, bypassDuration:boolean = false) {
      if (this._loader) return;
      this.isAnimating = true;

        if (!this.isFromOpportunites) {
            if(!bypassDuration && !options.duration) options.duration = 15000;
            this._loader = await this.loadingCtrl.create(options);
            await this._loader.present();
        }
    }

    public async dismissLoader() {
        try {
            if (this._loader) {
                await this._loader.dismiss();
                this._loader = undefined;
                this.isAnimating = false;
            }
        } catch (err) {
            console.log(err);
        }
    }

    public displayAlertConfirmation(data:ConfirmationAlertData){
            return this.alertCtrl.create({
                header: data.title,
                message: data.message,
                buttons: [
                    {
                        text: data.cancelButtonText,
                        role: 'cancel',
                    },
                    {
                        text: data.confirmButtonText,
                        role: 'ok',
                    }
                ]
            }).then(alert=>{
              alert.present();
              return alert.onDidDismiss()
            })
            .then((res)=>{
              return res.role=='ok'
            })
    }

    public getActiveViewBasedOnActivity(activity: Activity): string {
      this.prevView;
      if (!activity) return this.prevView;
      switch (activity.type) {
        case ActivityType.Appointment:
          this.prevView = 'Appointment';
          break;
        case (ActivityType.TimeOff || ActivityType.TimeOff):
          this.prevView = 'TimeOffDetail';
          break;
        case ActivityType.PhoneCall:
          this.prevView = 'PhoneCall';
          break;
        case ActivityType.Email:
          this.prevView = 'Email';
          break;
        case ActivityType.CaseIntake:
          this.prevView = 'customer_inquiry';
          break;
        case ActivityType.Order:
        case ActivityType.FollowUp:
        case ActivityType.SurgeryOrder:
          this.prevView = 'ActivitiesPageRightPaneNav';
          break;
        case ActivityType.Sample:
          this.prevView = 'Sample';
          break;
        default:
          break;
      }

      return this.prevView;
    }

    public scrollListToView(list: ElementRef){
      if (!list) return;
      const arr = list.nativeElement.children;
      if(arr && arr.length > 0){
        arr[0].scrollIntoView(false);
      }
    }

    setAgendaTab(selectedTab: string) {
      this.agendaTabSource.next(selectedTab);
    }

    // Returns segment tab buttons
    getAgendaTabs(): IndTabsDataModel[] {
      const tabs:any = [
        {
          displayText: this.translate.instant('ACT'),
          value: "agenda"
        },
      ];

      // Short Call Launcher does not have Impact segment
      if (!this.faService.isShortCallLauncherEnabled) {
        tabs.push(
          {
            displayText: this.translate.instant('IMPACT'),
            value: "Impact",
            disable: !this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.XPERIENCES) || this.deviceService.isOffline
          }
        );
      }

      // Short Call Launcher does not have Measure segment
      if (
        !this.faService.isShortCallLauncherEnabled
        && (
          this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.EDGE_ANALYTICS_MEETING)
          || this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.PROCEDURE_LOG)
        )
      ) {
        const actIdx = tabs.findIndex(t => t.value === 'agenda');
        if (actIdx >= 0) {
          tabs.splice(actIdx + 1, 0, {
            displayText: this.translate.instant('MEASURE'),
            value: 'edgeAnalytics',
          });
        } else {
          console.warn('updateEdgeAnalyticsTab: No "Act" tab available!!');
        }
      }

      // if (this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.SCIENTIFIC_TOOL_PLAN) ||
      //     this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.CALL_PLANS) ||
      //     this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.ACCOUNT_MANAGEMENT)) {
        const actIdx = tabs.findIndex(t => t.value === 'agenda');
        if (actIdx >= 0) {
          tabs.splice(0, 0, {
            displayText: this.translate.instant('PLAN'),
            value: 'plans',
          });
        } else {
          console.warn('update PlanTab');
        }

      // }

      return tabs;
    }

    isSplitView() {
      return (window.innerWidth > 500);
    }
}

interface oGenieMessage {
    msg: string;
    ai: boolean;
}

interface oGenieOption {
    title: string;
    type: string;
    value: string;
}



