import { Component, OnChanges, OnDestroy, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { EventManager } from '@angular/platform-browser';
import { Scheduler } from '../../../classes/scheduler/scheduler.class';
import { Brand } from '../../../classes/brand/brand.class';
import { SchedulerService } from "../../../services/scheduler/scheduler.service";
import { from ,  Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { MainCardViewDataModel } from '../../../models/MainCardViewDataModel';
import { DatePipe } from '@angular/common';
import { DateTimeFormatsService } from '../../../services/date-time-formats/date-time-formats.service';
import { format } from'date-fns';


@Component({
  selector: 'scheduler-item',
  templateUrl: 'scheduler-item.html',
  styleUrls:['scheduler-item.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SchedulerItemComponent {
  @Input() isDeviceOnline: boolean = true;
  @Input() schedule: Scheduler;
  @Input() isSelected: boolean = false;
  @Output() onSelect = new EventEmitter<any>(null);
  @Output() tileAction = new EventEmitter<string>(null);
  private selectedSchedule: Scheduler;
  public itemExpandHeight: number = 25;
  public isExpanded: boolean = false;
  private selectedScheduleSubscription: Subscription;
  private selectedScheduleObs: Subscription;

  constructor(
    private cd: ChangeDetectorRef,
    private schedulerService:SchedulerService,
    private translate: TranslateService,
    private datePipe: DatePipe,
    private dateTimeFormatsService:DateTimeFormatsService
  ) {
  }

  ngOnInit(): void {
    this.selectedScheduleSubscription = this.schedulerService.schedulerListObservable.subscribe(res => {
      if(res) {
        this.cd.detectChanges();
        this.cd.markForCheck();
      }
    });

    // this.selectedScheduleObs = this.schedulerService.schedulerObs.subscribe(value => {
    //   if(value) {
    //     this.selectedSchedule = value;
    //   }
    // });

    this.translate.onLangChange.subscribe(() => {
      this.cd.detectChanges();
      this.cd.markForCheck();
    });

  }

  ngOnDestroy(): void {
    this.selectedScheduleSubscription.unsubscribe();
    //this.selectedScheduleObs.unsubscribe();
  }

  select(action: string, event: Event) {
    event.stopPropagation
    if (action === 'open') {
      this.onSelect.emit(this.schedule);
    }
    if (action === 'expand') {
      this.isExpanded = !this.isExpanded;
    }
  }

  actionSelected(ev) {
    this.schedulerService.setSchedule(this.schedule);
    this.tileAction.emit(ev);
  }

  private get productList(): string {
    let resultB: string = this.translate.instant('SCHEDULER_NO_PRODUCTS');
    let l: number = this.schedule.productsList.length;
    if (l) {
      if (l === 1) {
        resultB = this.schedule.productsList[0].value;
      }
      else {
        resultB = this.schedule.productsList[0].value + " +" + (l - 1);
      }
    }
    return resultB;
  }

  private _getFormattedAndLocalisedDate(date: Date): string {
    return this.datePipe.transform(date, this.dateTimeFormatsService.date, undefined, this.translate.currentLang)
  }

  public get schdeduleMainCardModel(): MainCardViewDataModel {
    let viewData: MainCardViewDataModel;
    if (this.schedule) {
      viewData = {
        id: this.schedule.ID,
        fixedHeight: true,
        primaryTextLeft: this._getFormattedAndLocalisedDate(new Date(this.schedule.startdate)),//(this.schedule.startdate) ? format(new Date(this.schedule.startdate), 'MMM DD') : '',
        primaryTextRight: this.schedule.name,
        secondaryTextRight: this.schedule.durationValue + 'd',
        secondaryTextRightTwo: this.productList,
        isSelected: this.isSelected,
        isExpandable: !this.schedule.isNewScheduler,
        isExpanded: this.isExpanded,
        expandableViewType: 'schedulerList',
        showArrow: false,
        arrowType: (this.isExpanded)?'chevron-up-outline':'chevron-down-outline',
        expandHeight: this.itemExpandHeight,
        clickHandler: (id: string, event, specificTarget) => {
          if (specificTarget) {
            if(specificTarget == 'endArrow'){
              this.select('expand', event)
            }else if(specificTarget == 'on_custom_action'){
              this.actionSelected(event);
            }else if(specificTarget == 'mainItem'){
              this.select('open', event);
            }
          }
          else {
            this.select('open', event);
          }
        }
      };
    }
    return viewData;
  }

}
