import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { Endpoints } from "../../../config/endpoints.config";
import { AuthenticationService } from "../../services/authentication.service";
import { Report } from "../../classes/coaching/report.class";
import { User } from "../../classes/account/child.user.class";
import { CoachingScale } from "../../classes/coaching/coaching.scale";
import { CoachingTemplate } from "../../classes/coaching/coaching-template.class";
import { CoachingTemplatesUser } from "../../classes/coaching/coaching-template-user.class";
import { CoachingActivity } from "@omni/classes/coaching/activity.class";

@Injectable({
  providedIn: 'root'
})
export class CoachingReportDataService {

  matrix: any = null;

  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService
  ) {

  }

  public async getMyCoachingReports(initialSync: boolean, lastUpdatedTime?): Promise<Report[]> {
    if (initialSync) {
      return await this.http.get<Report[]>(this.authenticationService.userConfig.activeInstance.entryPointUrl
        + Endpoints.coachingReports.GET_MY_COACHING_REPORTS, Endpoints.GLOBAL_SYNC_HEADER).toPromise();
    } else {
      const url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl
        + Endpoints.coachingReports.GET_DELTA_MY_COACHING.replace('{lastUpdatedTime}', lastUpdatedTime);
      return await this.http.get<Report[]>(url, Endpoints.GLOBAL_SYNC_HEADER).toPromise();
    }
  }

  public async getTeamCoachingReports(initialSync: boolean, lastUpdatedTime?): Promise<Report[]> {
    if (initialSync) {
      return await this.http.get<Report[]>(this.authenticationService.userConfig.activeInstance.entryPointUrl
        + Endpoints.coachingReports.GET_TEAM_COACHING_REPORTS, Endpoints.GLOBAL_SYNC_HEADER).toPromise();
    } else {
      const url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl
        + Endpoints.coachingReports.GET_DELTA_TEAM_COACHING.replace('{lastUpdatedTime}', lastUpdatedTime);
      return await this.http.get<Report[]>(url, Endpoints.GLOBAL_SYNC_HEADER).toPromise();
    }
  }

  public async getCoachingTemplates(initialSync: boolean, lastUpdatedTime?): Promise<CoachingTemplate[]> {
    let url = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.coachingReports.GET_COACHING_TEMPLATES
    const positions = this.authenticationService.user.positions.map((o) => { return o.ID });
    url = url.replace('{PositionIds}', positions.toString());
    if (initialSync) {
      return await this.http.get<CoachingTemplate[]>(url, Endpoints.GLOBAL_SYNC_HEADER).toPromise();
    } else {
      url = url.concat('&lastUpdatedTime=', lastUpdatedTime);
      return await this.http.get<CoachingTemplate[]>(url, Endpoints.GLOBAL_SYNC_HEADER).toPromise();
    }
  }

  public getCoachingTemplatesUsersMap(): Observable<CoachingTemplatesUser[]> {
    let url = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.coachingReports.GET_COACHING_TEMPLATES_USERS;
    const positions = this.authenticationService.user.positions.map((o) => { return o.ID });
    url = url.replace('{PositionIds}', positions.toString());
    return this.http.get<CoachingTemplatesUser[]>(url, Endpoints.GLOBAL_SYNC_HEADER);
  }

  public async getCoachingById(indskr_coachingreportid: any, tab: string, statuscode: number): Promise<Report> {
    let url: string;
    if (tab === 'myCoaching') {
      url = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.coachingReports.GET_MY_COACHING_REPORT.replace('{reportID}', indskr_coachingreportid);
    } else if (tab === 'teamCoaching' && (statuscode === 548910002 || statuscode === 548910003)) {
      url = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.coachingReports.GET_TEAM_COACHING_REPORT.replace('{reportID}', indskr_coachingreportid);
    } else {
      url = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.coachingReports.GET_COACHING_REPORT.replace('{reportID}', indskr_coachingreportid);
    }
    return this.http.get<Report>(url).toPromise();
  }

  public async acknowledgeReport(reportID, payload: any) {
    const url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl +
      Endpoints.coachingReports.ACKNOWLEDGE_MY_COACHING_REPORT.replace('{reportID}', reportID);
    return this.http.patch(url, payload).toPromise();
  }

  public async recallReport(reportID, payload: any) {
    const url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl +
      Endpoints.coachingReports.RECALL_TEAM_COACHING_REPORT.replace('{reportID}', reportID);
    return this.http.patch(url, payload).toPromise();
  }

  public async saveNewReport(report: Report) {
    const url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl +
      Endpoints.coachingReports.CREATE_COACHING_REPORT;
    return this.http.post(url, report).toPromise();
  }

  public async updateReport(reportID, payload: any) {
    const url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl +
      Endpoints.coachingReports.UPDATE_COACHING_REPORT.replace('{reportID}', reportID);
    return this.http.patch(url, payload).toPromise();
  }

  public async updateCoachingFor(reportID, payload: Report) {
    const positions = this.authenticationService.user.positions.map((o) => { return o.ID });
    const url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl +
      Endpoints.coachingReports.UPDATE_COAHING_FOR.replace('{reportID}', reportID).replace('{positionIds}', positions.toString());
    return this.http.patch(url, payload).toPromise();
  }

  public async scrapCoachingReport(reportID) {
    const url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl +
      Endpoints.coachingReports.SCRAP_COACHING_REPORT.replace('{reportID}', reportID);
    return this.http.delete(url).toPromise();
  }

  //Note: unused func, commented
  /*public getAssessmentMatrixMasterData(): Observable<AssessmentMatrix> {
    const buId: string = this.authenticationService.user.xBusinessUnitId;
    const url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl +
      Endpoints.coachingReports.GET_ASSESSMENT_MATRIX.replace('{buId}', buId);
    return this.http.get<AssessmentMatrix>(url, Endpoints.GLOBAL_SYNC_HEADER);
  }*/

  public getCoachingScales(): Observable<CoachingScale[]> {
    const url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl +
      Endpoints.coachingReports.GET_COACHING_SCALES;
    return this.http.get<CoachingScale[]>(url, Endpoints.GLOBAL_SYNC_HEADER);
  }

  public getCoachingForUsers(): Observable<User[]> {
    const url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl +
      Endpoints.coachingReports.GET_COACHING_FOR_USERS;
    return this.http.get<User[]>(url, Endpoints.GLOBAL_SYNC_HEADER);
  }

  public getTeamCoachingAppointments(userId: any, startTime: any, endTime: any): Observable<CoachingActivity[]> {
    const url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl +
      Endpoints.coachingReports.GET_APPOINTMENTS
        .replace('{userId}', userId)
        .replace('{periodStartDate}', startTime)
        .replace('{periodEndDate}', endTime);
    return this.http.get<CoachingActivity[]>(url);
  }

  public getTeamCoachingPhoneCalls(userId: any, startTime: any, endTime: any): Observable<CoachingActivity[]> {
    const url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl +
      Endpoints.coachingReports.GET_PHONECALLS
        .replace('{userId}', userId)
        .replace('{periodStartDate}', startTime)
        .replace('{periodEndDate}', endTime);
    return this.http.get<CoachingActivity[]>(url);
  }

  public async saveOrUpdateRating(reportID: string, categoryId: string, payload: any) {
    const url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl +
      Endpoints.coachingReports.SAVE_OR_UPDATE_RATING
        .replace('{reportID}', reportID)
        .replace('{categoryID}', categoryId);
    return this.http.put(url, payload).toPromise();
  }

  public async clearRatings(reportID: string, payload: any) {
    const url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl +
      Endpoints.coachingReports.CLEAR_RATINGS
        .replace('{reportID}', reportID);
    return this.http.put(url, payload).toPromise();
  }

  public async updateScore(reportID: string, payload: any) {
    const url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl +
      Endpoints.coachingReports.UPDATE_COACHING_SCORE
        .replace('{reportID}', reportID);
    return this.http.put(url, payload).toPromise();
  }

  public async uploadOfflineCoachingReports(offlineCoachings: any[]) : Promise<any[]>{
    const url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl +
      Endpoints.coachingReports.UPLOAD_OFFLINE_COACHINGS;
    return this.http.post<any[]>(url, offlineCoachings).toPromise();
  }

}
