
import {of as observableOf, throwError as observableThrowError, forkJoin as observableForkJoin,  Subject, ReplaySubject ,  Observable } from 'rxjs';

import {mergeMap,  catchError, flatMap } from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { FileTransfer } from '@ionic-native/file-transfer/ngx'
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { DeviceService } from "../device/device.service";
import { File } from "@ionic-native/file/ngx";
import { LogService } from "../logging/log-service";
import { Zip } from '@ionic-native/zip/ngx';
import { PresentationService } from '../presentation/presentation.service';
import { Store } from "@ngrx/store";
import * as FileAction from '../../store/io-file-service/actions/file.actions';
import * as ResourceAction from '../../store/io-file-service/actions/resource.actions';
import { fileManagerState } from '../../store/io-file-service/states/file.state';
import * as path from 'path';
import { Platform } from "@ionic/angular";
import { electronApi } from "../electron-api";
import { Resource } from "../../classes/resource/resource.class";
import { ResourceService } from "../resource/resource.service";
import { ToastStyle, NotificationService } from "../notification/notification.service";
import { TranslateService } from "@ngx-translate/core";
import { UIService } from '../ui/ui.service';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { AuthenticationService } from '../authentication.service';
import { Endpoints } from '../../../config/endpoints.config';

@Injectable({
  providedIn: 'root'
})
export class IoFileService {

    //Progress update data
    private _dlProgressSubject: Subject<number | null> = new ReplaySubject<number | null>(1);
    private _unzipProgressSubject: Subject<number | null> = new ReplaySubject<number | null>(1);
    public dlProgress$: Observable<number | 0>;
    public unzipProgress$: Observable<number | 0>;

    private dataStorageDirectory: string;
    private presDirectory = '/presentations';
    private resourceDirectory: string = '/resources';

    constructor(
        private fs: File,
        private ft: FileTransfer,
        private logService: LogService,
        private device: DeviceService,
        private zip: Zip,
        private presentationService: PresentationService,
        private platform: Platform,
        private store: Store<fileManagerState>,
        private resourceService: ResourceService,
        public translate: TranslateService,
        private notificationService: NotificationService,
        private uiService: UIService,
        private fileOpener: FileOpener,
        private authService: AuthenticationService,
        private http: HttpClient
    ) {
        this.dlProgress$ = this._dlProgressSubject.asObservable();
        this.unzipProgress$ = this._unzipProgressSubject.asObservable();
        this.initializeDirectory();
    }

    private resolveLocalUrl(url: string) {
        return [this.dataStorageDirectory.replace(/\/$/, ""), url.replace(/^\//, '')].join('/');
    }

    private async createDir(dir: string) {
        dir = this.resolveLocalUrl(dir);
        if (this.device.deviceFlags.electron) {
            return await electronApi.createDir(dir)
        }
        return await this.fs.createDir(path.dirname(dir), path.basename(dir), false);
    }

    private async deleteFile(file: string) {
        file = this.resolveLocalUrl(file);
        if (this.device.deviceFlags.electron) {
            return await electronApi.removeFile(file);
        }
        return await this.fs.removeFile(path.dirname(file), path.basename(file));
    }

    private async deleteDir(dir: string) {
        dir = this.resolveLocalUrl(dir);
        if (this.device.deviceFlags.electron) {
            await electronApi.removeRecursively(dir);
            return;
        }
        let entry: any = await this.fs.resolveLocalFilesystemUrl(dir);
        await entry.removeRecursively();
    }

    //#region Download to saving process

    public async download(src: string, dest: string, progress: (event?) => void, mime, resourcePath?: string) {
        dest = this.resolveLocalUrl(dest);
        if (!this.device.deviceFlags.electron) {
            let headers = new HttpHeaders();
            if (mime) {
                headers = headers.set('Content-Type', mime);
                headers = headers.set('Accept', mime);
            }
            const requestOptions: any = {};
            requestOptions.headers = headers;
            requestOptions.responseType = 'arraybuffer';
            requestOptions.reportProgress = true;
            let ft = this.ft.create();
            ft.onProgress(event => progress(event));
            await ft.download(src, dest, true, requestOptions)
        } else {
            if (resourcePath) await this.createDir(resourcePath);
            let response = await fetch(src, {
                headers: mime ? new Headers({
                    'Content-Type': mime,
                    'Accept': mime,
                }
                ) : undefined,
            });
            if (!response.ok) {
                throw Error(`Unable to download, server returned ${response.status} ${response.statusText}`);
            }
            const body = response.body;
            if (body == null) {
                throw Error('No response body');
            }

            const finalLength = length || parseInt(response.headers.get('Content-Length' || '0'), 10);
            const reader = body.getReader();
            const writer = electronApi.createWriteStream(dest);
            try {
                let bytesDone = 0;

                while (true) {
                    const result = await reader.read();
                    if (result.done) {
                        if (progress != null) {
                            progress({ total: finalLength, loaded: finalLength });
                        }
                        break;
                    }

                    const chunk = result.value;
                    if (chunk == null) {
                        throw Error('Empty chunk received during download');
                    } else {
                        writer.write(Buffer.from(chunk));
                        if (progress != null) {
                            bytesDone += chunk.byteLength;
                            progress({ total: finalLength, loaded: bytesDone });
                        }
                    }
                }
            }
            finally {
                writer.close();
            }
        }
        this.logService.logEvent(`${src} downloaded as ${dest}`);
        return true;
    }

    async unzip(src, dest, progress?) {
        src = this.resolveLocalUrl(src);
        dest = this.resolveLocalUrl(dest);
        return await this.zip.unzip(src, dest, progress).catch(
            function (err) {
                console.log("presentation UNZIP failed");
                return Promise.reject(err);
                // return Observable.of(null);
            }
        );
    }

    private async initializeDirectory() {
        if (this.dataStorageDirectory) return;
        await this.platform.ready();
        if (this.device.deviceFlags.electron) {
            this.dataStorageDirectory = electronApi.getPath('userData');
        } else {
            this.dataStorageDirectory = this.fs.externalDataDirectory || this.fs.dataDirectory || this.fs.tempDirectory || this.fs.cacheDirectory;
        }
        console.log('Data storage', this.dataStorageDirectory);
        if (!this.dataStorageDirectory || this.dataStorageDirectory == null || this.dataStorageDirectory == undefined) {
            this.logService.logError('dataStorageDirectory is undefined');
            return;
        }

        await this.createDir(this.presDirectory);
        await this.createDir(this.resourceDirectory);
    }

    //#region Update progress bar

    updateDowloadProgress = (progress?: ProgressEvent) => {
        this._dlProgressSubject.next(progress ? Math.round((progress.loaded / progress.total) * 100) : 0);
    }

    updateUnzipProgress = (progress: any) => {
        this._unzipProgressSubject.next(Math.round((progress.loaded / progress.total) * 100));
    }

    resetProgress() {
        this._dlProgressSubject.next(null);
        this._unzipProgressSubject.next(null);
    }

    //#endregion End of update region


    downloadPresentation = (presId: string): Observable<any> => {

        // this.activeDownload = presentationId;
        // activeDownloadSubject.next(presentationId);
        let pres = this.presentationService.getPresObject(presId);

        return observableForkJoin(
            this.download(pres.zipUrl, path.join(this.presDirectory, presId + ".zip"), progress => this.updateDowloadProgress(progress), 'application/zip')
                .catch(err => {
                    console.log("presentation zip failed", err)
                    return Promise.reject(err);
                }),
            this.download(pres.thumbnailZipUrl, path.join(this.presDirectory, presId + "_thumbnail.zip"), progress => this.updateDowloadProgress(progress), 'application/zip')
                .catch(err => {
                    console.log("thumbnail zip failed", err)
                    return Promise.reject(err);
                }),
            this.download(pres.thumbnailUrl, path.join(this.presDirectory, presId + '/pres_thumbnail', pres.thumbnailUrl.substr(pres.thumbnailUrl.lastIndexOf('/') + 1)), progress => this.updateDowloadProgress(progress), 'application/zip', path.join(this.presDirectory, presId + '/pres_thumbnail'))
            .catch(err => {
              console.log("presentation thumbnail failed", err);
                return Promise.resolve(true);
            })
        ).pipe(
            flatMap(() => {
                this.store.dispatch(new FileAction.downloadFileSuccess({ presentationId: presId }));
                return this.unzipPresentationNative(presId);
            }),
            catchError(err => {
                this.logService.logError("Download failed", err);
                this.store.dispatch(new FileAction.downloadFileDequeue());
                return observableThrowError(err);
            }),
        );
    }

    unzipPresentationNative = (presId: string): Observable<any> => {
        let presPath = path.join(this.presDirectory, presId);
        this.store.dispatch(new FileAction.unZipFile());

        const obs_cb = observableForkJoin(
            this.unzip(presPath + ".zip", presPath, (progress) => this.updateUnzipProgress(progress)).catch(
                function (rs) {
                    throw observableThrowError(rs.http_status);
                }
            ),
            this.unzip(presPath + "_thumbnail.zip", presPath + "/thumbnail").catch(
                function (rs) {
                    throw observableThrowError(rs.http_status);
                }
            )
        );

        return obs_cb.pipe(mergeMap(() => this.deleteZipFile(presId)));
    };

    //#endregion Download to saving process


    downloadFailedDelete = (presId: string) => {

        console.log("DELETE FAILED DOWNLOAD");
        this.resetProgress();
        // this.notificationService.notify( "Failed to download presentation " + pres.name, "io-file-service", "top", ToastStyle.DANGER, 3000, true);
        this.store.dispatch(new FileAction.downloadFileDequeue());
    }

    private convertFileSrc(str: string) {
        if (this.device.deviceFlags.electron) {
            return electronApi.convertFileSrc(str);
        }
        str = this.resolveLocalUrl(str);
        let ionicConvertFileSrc = (((<any>window || {}).Ionic || {}).WebView || {}).convertFileSrc;
        if (ionicConvertFileSrc) {
            return ionicConvertFileSrc(str);
        }
        return str;
    }

    getLocalURL(fn: string, presenataionId?: string) {
        if (!this.presentationService.activePresentation && this.presentationService.activePresentation instanceof Resource) return "";
        return this.convertFileSrc(path.join(this.presDirectory, presenataionId? presenataionId : this.presentationService.activePresentation['ioPresentationId'], fn));
    }

    getLocalURLForResource(resourceId: string, fn: string) {
        return this.convertFileSrc(path.join(this.resourceDirectory, resourceId, fn));
    }

    getLocalURLDocument = (fn: any): any => {
        return this.convertFileSrc(fn);
    }

    deleteZipFile = (presId: string): Observable<any> => {
        // activeDownloadSubject.next(null);
        this.resetProgress();
        return observableForkJoin(
            this.deleteFile(path.join(this.presDirectory, presId + ".zip")).catch(
                function () {
                    console.log("Presentation zip DELETE failed");
                    return observableOf(null);;
                }
            ),
            this.deleteFile(path.join(this.presDirectory, presId + "_thumbnail.zip")).catch(
                function () {
                    console.log("thumbnail zip DELETE failed");
                    return observableOf(null);
                }
            )
        );
    }
    async deleteDownloadedPres(presId: string) {
        try {
            this.deleteDir(path.join(this.presDirectory, presId));
        } catch (ex) {
            console.error('deleteDownloadedPres: Downloaded Presentation DEL Fail!!', ex)
        }
    }


    public downloadDocuments(url, fileName: string): Observable<any> {
        const rs: Observable<any> = observableForkJoin(
            this.download(url, fileName, (progress) => this.updateDowloadProgress(progress), 'application/pdf').catch(
                function (err) {
                    console.log("Download Failed");
                    console.log(err);
                    throw observableThrowError(err);
                    // return err;
                }
            ),

        )

        return rs;
    }

    downloadResource = (resourceId: string): Observable<any> => {
        let resource: Resource = this.resourceService.getResourceById(resourceId, resourceId);
        console.log("Downloading resource : " + resource.title);
        let downloadList = [];
        const file: string = resource.assetURL.substr(resource.assetURL.lastIndexOf('/') + 1);
        const resourcePath = path.join(this.resourceDirectory, resourceId);
        downloadList.push(this.download(resource.assetURL, path.join(resourcePath, file), progress => this.updateDowloadProgress(progress), 'application/zip', resourcePath)
            .catch(err => {
                console.log("resource download failed for " + resource.title, err);
                return Promise.resolve(false);
            }));
        if (resource.thumbnailURL) {
            const thumbnailFile: string = resource.thumbnailURL.substr(resource.thumbnailURL.lastIndexOf('/') + 1);
            downloadList.push(this.download(resource.thumbnailURL, path.join(resourcePath + '/thumbnail', thumbnailFile), progress => this.updateDowloadProgress(progress), 'application/zip', resourcePath + '/thumbnail')
                .catch(err => {
                    console.log("resource thumbnail download failed for " + resource.title, err);
                    resource.thumbnailFailed = true;
                    return Promise.resolve(true);
                }));
        }
        return observableForkJoin(...downloadList)
            .pipe(
                catchError(err => {
                    this.logService.logError("Download Resource failed", err);
                    return observableThrowError(err);
                }),
            );
    }

    async deleteDownloadedResource(resourceId: string) {
        try {
            this.deleteDir(path.join(this.resourceDirectory, resourceId));
        } catch (ex) {
            console.error('Delete downloaded resource failed --> res id : ' + resourceId, ex);
        }
    }

    async deleteAllDownloadedResources() {
        console.log('Deleting all downloaded resources');
        try {
            this.deleteDir(this.resourceDirectory);
        } catch (ex) {
            console.error('Error occurred while deleting all downloaded resources', ex);
        }
    }

    async writeFileBufferToSharedDirectory(fileName: string, buffer: ArrayBuffer, option: { replace: boolean } = { replace: false }, directory?: string): Promise<boolean> {
        let _directory;
        if (directory) {
            _directory = directory;
        } else if (this.device.deviceFlags.ios) {
            _directory = this.fs.documentsDirectory;
        } else if (this.device.deviceFlags.android) {
            _directory = `${this.fs.externalRootDirectory}/Download/`;
        }

        if (_directory && fileName && buffer) {
            try {
                // Replace if same file name exists.
                const res = await this.fs.writeFile(_directory, fileName, buffer, option);
                return true;
            } catch (error) {
                throw error;
            }
        } else {
            console.error(`writeFileBufferToSharedDirectory: fileName: ${fileName}, directory: ${_directory}, buffer: `, buffer);
            return false;
        }
    }

    async downloadeBase64DataFileAndOpenInNativeApp (fileName:string,file:any,mimeType:string){
      try {
        if(this.device.isNativeApp && file && fileName && mimeType){
          this.uiService.displayLoader();

          let _directory = this.fs.cacheDirectory;
          if (this.device.deviceFlags.ios) {
            _directory = this.fs.tempDirectory;
          }
          fetch(file,
            {
              method: "GET"
            }).then(res => res.blob()).then(blob => {
              this.fs.writeFile(_directory, fileName, blob, { replace: true }).then(res => {
                const path = res.nativeURL;
                this.fileOpener.open(
                  path,
                  mimeType
                ).then((res) => {
                  this.uiService.dismissLoader();
                }).catch(err => {
                  this.fileOpener.showOpenWithDialog(
                    path,
                    mimeType
                  ).then((res) => {
                    this.uiService.dismissLoader();
                  }).catch(err => {
                    console.log(err)
                    this.uiService.dismissLoader();
                  });
                });
              }).catch(err => {
                console.log(err)
                this.uiService.dismissLoader();
              });
            }).catch(err => {
              console.log(err)
              this.uiService.dismissLoader();
            });
        }
      } catch (error) {
        console.log(error);
      }
    }

  async getDocumentBody(annotationID: string) {
    //{{resource}}/api/data/v8.2/annotations(a0716ff2-cab7-eb11-8236-000d3a127385)?$select=documentbody

    // NOTE_DOCUMENT_BODY

    let url = this.authService.userConfig.activeInstance.url + Endpoints.NOTE_DOCUMENT_BODY
    url = url.replace('{annotationid}', annotationID)

    return  await this.http.get<any[]>(url).toPromise();
  }
}
