import { Component, ElementRef, ViewChild } from "@angular/core";
import { CoachingReportService, COACHING_VIEW_TYPE } from "../../../services/coaching/coaching.report.service";
import { UIService } from "../../../services/ui/ui.service";
import { ActivityService } from "../../../services/activity/activity.service";
import { ActivitiesPaneComponent } from "../../activity/activities-pane/activities-pane";
import { ChildNavNames, NavigationService, PageName } from "../../../services/navigation/navigation.service";
import { Report } from "../../../classes/coaching/report.class";
import { CoachingActivity } from "../../../classes/coaching/activity.class";
import { FooterViews, FooterService } from "../../../services/footer/footer.service";
import { DeviceService } from "../../../services/device/device.service";
import { IonList, LoadingController, IonSelect, PopoverController, IonNav } from "@ionic/angular";
import { ActivitiesDetailsPaneComponent } from "../../activity/activities-details-pane/activities-details-pane";
import * as moment from "moment";
import { ActivityDataService } from "../../../data-services/activity/activity.service";
import { NotificationService, ToastStyle } from "../../../services/notification/notification.service";
import { TranslateService } from '@ngx-translate/core';
import { IndHeaderLeftDataModel } from '../../../models/indHeaderLeftDataModel';
import { MultiSelectPopover } from '../../multi-select-popover/multi-select-popover';
import { RepServices } from '../../../data-services/rep/rep.services';
import { Subscription } from 'rxjs';
import { skip } from 'rxjs/operators';
import { AuthenticationService } from "@omni/services/authentication.service";
import { FeatureActionsMap } from "@omni/classes/authentication/user.class";
import { ActivityType, ActivityTypeCodeRaw } from "@omni/classes/activity/activity.class";
import format from "date-fns/format";
import { DateTimeFormatsService } from "@omni/services/date-time-formats/date-time-formats.service";
import { MainCardViewDataModel } from "@omni/models/MainCardViewDataModel";
import _ from "lodash";
import { AppointmentActivity } from '@omni/classes/activity/appointment.activity.class';
import { PhoneActivity } from '@omni/classes/activity/phone.activity.class';
import { EventsService } from '@omni/services/events/events.service';
import { PhoneCallDetailsComponent } from "@omni/components/phonecall/phone-call-details/phone-call-details";


@Component({
  providers: [ActivitiesPaneComponent],
  selector: 'coaching-activities',
  templateUrl: 'coaching-activities.html',
  styleUrls: ['coaching-activities.scss']
})
export class CoachingActivitiesComponent {

  public activityFilter: string = "All Meetings";
  private readonly MIN_SEARCH_LENGTH = 1;
  public report: Report = null;
  public selectedActivities: CoachingActivity[] = [];
  public searchTerm: String = '';
  public allActivities: CoachingActivity[] = [];
  private actualData: CoachingActivity[] = [];
  private modified: boolean = false;
  @ViewChild('activitySelect') activitySelector: IonSelect;
  @ViewChild(IonList, { read: ElementRef, static: false }) list: ElementRef;
  indHeaderLeftModel: IndHeaderLeftDataModel;
  headerTitleText: string = '';
  headerTitleTextRef: any;
  headerDoneButtonRef: any;
  filterPopover: any;
  filterPopoverData: any[];
  offlineStatusSub: Subscription;
  public coachingViewType = COACHING_VIEW_TYPE;
  @ViewChild('coachingactivitiesrightpane', {static:true}) public navCtrl: IonNav;


  constructor(
    public activityService: ActivityService,
    public activityDataService: ActivityDataService,
    public coachingReportService: CoachingReportService,
    public uiService: UIService,
    public navService: NavigationService,
    private footerService: FooterService,
    public device: DeviceService,
    private loadingCtrl: LoadingController,
    private notificationService: NotificationService,
    public translate: TranslateService,
    private authService: AuthenticationService,
    private popoverCtrl: PopoverController,
    public events: EventsService
  ) { }

  async ngOnInit() {
    if (this.coachingReportService.coachingViewtype === COACHING_VIEW_TYPE.FROM_TOOL) {
      // this.activityService.selectedActivity = null;
      this.activityDataService.activityDetailsLoaded = false;
    }
    this.coachingReportService.activityClick = false;
    this.uiService.showRightPane = false;
    this.report = this.coachingReportService.selectedReport.value;
    if (!_.isEmpty(this.report.meetings)) {
      this.report.meetings.forEach(m => m.activityType = ActivityType.Appointment);
    } else {
      this.report.meetings = [];
    }
    if (!_.isEmpty(this.report.phonecalls)) {
      this.report.phonecalls.forEach(p => p.activityType = ActivityType.PhoneCall);
    } else {
      this.report.phonecalls = [];
    }
    this.selectedActivities = _.orderBy(this.report.meetings.concat(this.report.phonecalls), a => a.indskr_subject);
    if (this.report.statuscode === 1 && !this.device.isOffline) {
      let loading = await this.loadingCtrl.create();
      loading.present();
      this.coachingReportService.getTeamCoachingActivities().subscribe((res: CoachingActivity[]) => {
        this.mapSelectedActivities(res);
        this.updateEmptyMessage();
        loading.dismiss();
      });
    }
    this.initFilterData();
    this.initHeaderLeft();
    this.offlineStatusSub = this.device.isOfflineObservable.pipe(skip(1)).subscribe(isOffline => this.updateDoneButtonStatus());
    if (this.authService.hasFeatureAction(FeatureActionsMap.PHONECALL_ACTIVITY)) {
      this.activityFilter = 'All Meetings and Phone Calls';
    }
  }

  ngAfterViewInit() {
    this.uiService.scrollListToView(this.list);
    this.navService.initRightPaneChildNav(this.navCtrl, ChildNavNames.CoachingActivitiesNavigation, PageName.CoachingActivitiesComponent, false, { deviceString: this.device.deviceFlags.ios ? 'ios' : 'other' });

  }
  ngOnDestroy() {
    if (this.offlineStatusSub) {
      this.offlineStatusSub.unsubscribe();
    }
    if (this.coachingReportService.coachingViewtype === COACHING_VIEW_TYPE.FROM_TOOL) {
      this.activityService.selectedActivity = this.activityService.selectedActivityAtHome;
    }
    // this.coachingReportService.selectedReport.next(this.report);
    this.footerService.initButtons(FooterViews.CoachingDetails);
  }

  mapSelectedActivities(activities: CoachingActivity[]) {
    if (this.selectedActivities.length > 0 && activities.length > 0) {
      activities.forEach(data1 => {
        this.selectedActivities.forEach(data2 => {
          if (data1.indskr_activityid === data2.indskr_activityid) {
            data1.selected = true;
          }
        });
      });
    }
    this.actualData = activities;
    this.allActivities = activities;
  }

  ionViewDidLoad() {
    this.allActivities = this.filterItems();
    this.sortMeetingsByDate(this.selectedActivities);
  }

  private initHeaderLeft() {
    this.headerTitleText = this.translate.instant('ALL_MEETINGS');
    if (this.authService.hasFeatureAction(FeatureActionsMap.PHONECALL_ACTIVITY)) {
      this.headerTitleText = this.translate.instant('ALL_MEETINGS_AND_PHONE_CALLS');
    }

    let buttons = [{
      id: 'cancel',
      imgSrc: 'assets/imgs/header_cancel.svg',
      cssClass: 'seventyPercentWidth',
      isDisabled: false,
      align: 'left'
    }];

    if (this.report.statuscode === 1) {
      buttons.push({
        id: 'done',
        imgSrc: 'assets/imgs/header_complete.svg',
        cssClass: 'seventyPercentWidth',
        isDisabled: this.isDoneButtonDisabled(),
        align: 'right'
      });
    }

    this.indHeaderLeftModel = {
      id: 'coaching-activities-header-left',
      title: this.headerTitleText,
      mode: true,
      controls: buttons
    };

    this.headerDoneButtonRef = this.indHeaderLeftModel.controls[1];
  }

  private initFilterData() {
    if (this.report && this.report.statuscode === 1) {
      if (this.authService.hasFeatureAction(FeatureActionsMap.PHONECALL_ACTIVITY)) {
        this.filterPopoverData = [{
          text: '',
          value: 'All Meetings and Phone Calls',
          items: [
            {
              text: this.translate.instant('ALL_MEETINGS_AND_PHONE_CALLS'),
              value: 'All Meetings and Phone Calls'
            },
            {
              text: this.translate.instant('ALL_MEETINGS'),
              value: 'All Meetings'
            },
            {
              text: this.translate.instant('ALL_PHONE_CALLS'),
              value: 'All Phone Calls'
            },
            {
              text: this.report.created_for_firstname + ' ' + this.translate.instant('MEETINGS'),
              value: 'My Meetings'
            },
            {
              text: this.report.created_for_firstname + ' ' + this.translate.instant('PHONE_CALLS'),
              value: 'My Phone Calls'
            },
            {
              text: this.translate.instant('JOIN_MEETING'),
              value: 'Joint Meetings'
            },
            {
              text: this.translate.instant('JOINT_PHONE_CALLS'),
              value: 'Joint Phone Calls'
            }
          ],
          handler: (selectedItem, item, itemRef) => {
            item.value = selectedItem.value;
            this.updateHeaderTitle(selectedItem.text);
            this.onActivityTypeChange(selectedItem.value);
          }
        }]
      } else {
        this.filterPopoverData = [{
          text: '',
          value: 'All Meetings',
          items: [
            {
              text: this.translate.instant('ALL_MEETINGS'),
              value: 'All Meetings'
            },
            {
              text: this.report.created_for_firstname + ' ' + this.translate.instant('MEETINGS'),
              value: 'My Meetings'
            },
            {
              text: this.translate.instant('JOIN_MEETING'),
              value: 'Joint Meetings'
            }
          ],
          handler: (selectedItem, item, itemRef) => {
            item.value = selectedItem.value;
            this.updateHeaderTitle(selectedItem.text);
            this.onActivityTypeChange(selectedItem.value);
          }
        }];
      }
    }
  }

  private isDoneButtonDisabled(): boolean {
    return this.device.isOffline ? true : this.isNotDirty;
  }

  private get isNotDirty() {
    const existingActivitIds = this.report.meetings.map(meeting => meeting.indskr_activityid);
    existingActivitIds.push(...this.report.phonecalls.map(phonecall => phonecall.indskr_activityid));
    const selectedActivityIds = this.selectedActivities.map(activity => activity.indskr_activityid);
    return _.xor(existingActivitIds, selectedActivityIds).length == 0;
  }
  
  private updateDoneButtonStatus() {
    if (this.headerDoneButtonRef) {
      this.headerDoneButtonRef.isDisabled = this.isDoneButtonDisabled();
    }
  }

  private updateHeaderTitle(title: string) {
    if (this.indHeaderLeftModel) {
      this.indHeaderLeftModel.title = title;
    }
  }

  onHeaderControlClick(id) {
    if (id === 'cancel') {
      this.closeModal(false);
    } else if (id === 'done') {
      this.closeModal(true);
    }
  }

  async openFilter(event: any) {
    if (this.filterPopover) return;

    this.filterPopover = await this.popoverCtrl.create({
      component: MultiSelectPopover,
      componentProps: { root: this.filterPopoverData },
      event
    });

    this.filterPopover.present();
    this.filterPopover.onDidDismiss().then(() => this.filterPopover = null);
  }

  onActivityTypeChange(activityType) {
    this.activityFilter = activityType;
    this.filterActivities();
  }

  public filterActivities() {
    this.searchTerm = '';
    this.allActivities = this.filterItems();
    this.updateEmptyMessage();
  }

  getItems($event) {
    if (this.searchTerm && this.searchTerm.trim().length > this.MIN_SEARCH_LENGTH) {
      this.allActivities = this.filterItems();
    } else {
      this.allActivities = this.actualData;
    }
    this.updateEmptyMessage();
  }

  filterItems() {
    return this.actualData.filter((activitiy: CoachingActivity) => {
      if (!activitiy.indskr_subject) return false;
      if (this.activityFilter === 'All Meetings and Phone Calls') {
        return activitiy.indskr_subject.toLowerCase().indexOf(this.searchTerm.trim().toLowerCase()) > -1;
      } else if (this.activityFilter === 'All Meetings') {
        return activitiy.indskr_subject.toLowerCase().indexOf(this.searchTerm.trim().toLowerCase()) > -1 && activitiy.activityType === ActivityType.Appointment;
      } else if (this.activityFilter === 'All Phone Calls') {
        return activitiy.indskr_subject.toLowerCase().indexOf(this.searchTerm.trim().toLowerCase()) > -1 && activitiy.activityType === ActivityType.PhoneCall;
      } else if (this.activityFilter === 'My Meetings') {
        return activitiy.indskr_subject.toLowerCase().indexOf(this.searchTerm.trim().toLowerCase()) > -1 && !activitiy.jointmeeting && activitiy.activityType === ActivityType.Appointment;
      } else if (this.activityFilter === 'My Phone Calls') {
        return activitiy.indskr_subject.toLowerCase().indexOf(this.searchTerm.trim().toLowerCase()) > -1 && !activitiy.jointphonecall && activitiy.activityType === ActivityType.PhoneCall;
      } else if (this.activityFilter === 'Joint Meetings') {
        return activitiy.indskr_subject.toLowerCase().indexOf(this.searchTerm.trim().toLowerCase()) > -1 && activitiy.jointmeeting && activitiy.activityType === ActivityType.Appointment;
      } else if (this.activityFilter === 'Joint Phone Calls') {
        return activitiy.indskr_subject.toLowerCase().indexOf(this.searchTerm.trim().toLowerCase()) > -1 && activitiy.jointphonecall && activitiy.activityType === ActivityType.PhoneCall;
      }
    });

  }

  async openActivityDetails(activity: CoachingActivity) {
    if (this.device.isOffline) {
      if (activity.activityType === ActivityType.Appointment)
        this.notificationService.notify(this.translate.instant("COACHING_ACTIVITIES_NOTIFY_TEXT"), "activity-details", "top", ToastStyle.DANGER, 3000, true);
      else if (activity.activityType === ActivityType.PhoneCall)
        this.notificationService.notify(this.translate.instant("COACHING_PHONE_CALL_NOTIFY_TEXT"), "activity-details", "top", ToastStyle.DANGER, 3000, true);
      return;
    }
    let raw = {
      activityid: activity.indskr_activityid,
      activitytypecode: activity.activityType == ActivityType.PhoneCall ? ActivityTypeCodeRaw.PhoneCall : ActivityTypeCodeRaw.Appointment,
      actualend: activity.indskr_scheduledend,
      scheduledend: activity.indskr_scheduledend,
      actualstart: activity.indskr_scheduledstart,
      scheduledstart: activity.indskr_scheduledstart,
      subject: activity.indskr_subject,
      location: activity.indskr_location,
      statecode: activity.statecode
    };
    const  rawActivity = activity.activityType == ActivityType.PhoneCall ? new PhoneActivity(raw) : new AppointmentActivity(raw);
    let foundActivity = this.activityService.getActivityByID(rawActivity.ID);
    this.activityService.selected = (foundActivity) ? foundActivity : rawActivity;
    await this.activityDataService.updateActivityDetails(this.activityService.selectedActivity);

    this.coachingReportService.setActivityCoaching(this.activityService.selectedActivity);
    this.uiService.showRightPane = true;
    this.coachingReportService.activityClick = true;
    this.footerService.hideAllButtons();
    this.navService.setChildNavRightPaneView(true);
    if (activity.activityType == ActivityType.PhoneCall) {
      this.navService.setChildNavRoot(PhoneCallDetailsComponent, PageName.PhoneCallDetailsComponent, PageName.CoachingActivitiesComponent, {from: PageName.CoachingActivitiesComponent});
      this.events.publish('phoneactivity:appConfigFields');
      this.footerService.initButtons(FooterViews.PHONE_CALL);
    } else {
      this.navService.setChildNavRoot(ActivitiesDetailsPaneComponent, PageName.ActivitiesDetailsPaneComponent, PageName.CoachingActivitiesComponent, {from: PageName.CoachingActivitiesComponent});
      this.events.publish('activities:appConfigFields');
      //timeout to fire the event once after the component is loaded
      setTimeout(() => { this.events.publish('detectChangesOnActivityDetails'); }, 0);
      this.footerService.initButtons(FooterViews.Activities);
    }
  }

  addSelectedActivity(activity: CoachingActivity) {
    activity.selected = true;
    this.modified = true;
    this.selectedActivities.push(activity);
    this.sortMeetingsByDate(this.selectedActivities);
    this.updateDoneButtonStatus();
  }

  removeSelectedActivity(activity: CoachingActivity) {
    const index = this.selectedActivities.findIndex(data => data.indskr_activityid === activity.indskr_activityid);
    if (index !== -1) {
      this.selectedActivities.splice(index, 1);
      this.modified = true;
    }
    this.allActivities.find(data => data.indskr_activityid === activity.indskr_activityid).selected = false;
    this.updateDoneButtonStatus();
  }

  async closeModal(saveSelection: boolean) {
    if (saveSelection && !this.device.isOffline && this.modified) {
      let payload: any = {
        "meetings": this.selectedActivities.filter(activity => activity.activityType === ActivityType.Appointment),
        "phonecalls": this.selectedActivities.filter(activity => activity.activityType === ActivityType.PhoneCall)
      };

      await this.coachingReportService.updateReport(payload);
    }
    this.uiService.showRightPane = true;
    this.footerService.initButtons(FooterViews.CoachingDetails);
    await this.navService.popChildNavCtrlFromStack(ChildNavNames.CoachingActivitiesNavigation);
    await this.navService.popWithPageTracking();
  }

  sortMeetingsByDate(meetings: CoachingActivity[]) {
    meetings.sort((meeting1: CoachingActivity, meeting2: CoachingActivity) => {
      return meeting2.indskr_scheduledstart.valueOf() - meeting1.indskr_scheduledstart.valueOf();
    })
  }

  async openSelector(event) {
    await this.activitySelector.open(event);
  }

  updateEmptyMessage() {
    this.uiService.updateNothingSelectedScreenMessageFor(this.allActivities.length)
  }

}
