import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { PdfService } from '../../services/pdf.service';
import { PdfJsViewerComponent } from 'ng2-pdfjs-viewer';
import { jsPDFDocument } from 'jspdf-autotable';
import { Subscription } from 'rxjs';
import { NavigationService, PageName } from '../../services/navigation/navigation.service';
import { FooterService, FooterViews } from '../../services/footer/footer.service';
import { UIService } from '../../services/ui/ui.service';
import { NotificationService, ToastStyle } from '../../services/notification/notification.service';
import { TranslateService } from '@ngx-translate/core';

export interface PdfPreviewCmpInputData {
  type: PdfPreviewCmpSourceType;
  data: any | PdfPreviewCmpJsPDFInput;
  pageTitle?: string;
}

export interface PdfPreviewCmpJsPDFInput {
  data: any;
  jsPdfGenerator: (data: any, pdfService: PdfService) => Promise<jsPDFDocument>;
  allowExport: boolean;
  exportHandler?: (jsPdfDoc: jsPDFDocument, fileName: string, pdfService: PdfService) => Promise<boolean>;
  exportFileName?: string;
}

export enum PdfPreviewCmpSourceType {
  url = 'url',
  jsPDF = 'jsPDF'
}

@Component({
  selector: 'pdf-preview',
  templateUrl: 'pdf-preview.html',
  styleUrls: ['pdf-preview.scss']
})
export class PdfPreviewComponent implements AfterViewInit, OnDestroy, OnInit {
  sourceType: PdfPreviewCmpSourceType;
  url: string;
  jsPdfDoc: jsPDFDocument;
  isSourceAvail: boolean = false;
  error: boolean = false;
  isProcessing: boolean = false;
  allowExport: boolean = false;
  private isAfterViewInit: boolean = false;
  private subs: Subscription[] = [];
  public type: any

  @ViewChild('pdfViewer') public pdfViewer: PdfJsViewerComponent;
  @Input() exportFileName: any;
  @Input() pdfData: any;


  constructor(private pdfService: PdfService,
    private navService: NavigationService,
    public footerService: FooterService,
    private uiService: UIService,
    private notificationService: NotificationService,
    private translate: TranslateService,) {

    // Handle loader
    this.subs.push(this.pdfService.isProcessing.subscribe(isProcessing => {
      this.isProcessing = isProcessing;
      if (isProcessing) {
        this.uiService.displayLoader();
      } else {
        this.uiService.dismissLoader();
      }
    }));


  }

  ngOnInit() {
    // Handle input data
    if (this.pdfData) {
      //const inputData: PdfPreviewCmpInputData = (this.viewCtrl.data as PdfPreviewCmpInputData);
      this.type = this.pdfData.type;
      switch (this.type) {
        case PdfPreviewCmpSourceType.url:
          this.sourceType = PdfPreviewCmpSourceType.url;
          this.url = this.pdfData.data;
          this.isSourceAvail;
          setTimeout(() => {
            if (this.isAfterViewInit && this.pdfViewer) {
              this.pdfViewer.refresh();
            }
          }, 50);
          break;

        case PdfPreviewCmpSourceType.jsPDF:
          this.sourceType = PdfPreviewCmpSourceType.jsPDF;
          if (this.pdfData.data && typeof this.pdfData.data.jsPdfGenerator === 'function') {
            this._handleJsPdfInput();
          } else {
            this.error = true;
          }
          break;

        default:
          this.error = true;
          break;
      }
    } else {
      this.error = true;
    }
  }

  ionViewWillEnter() {
    this.footerService.initButtons(FooterViews.PDF_PREVIEW, { allowExport: this.allowExport });
  }
  ngAfterViewInit() {
    if (this.jsPdfDoc && this.pdfViewer) {
      this.pdfViewer.pdfSrc = this.jsPdfDoc ? this.jsPdfDoc.output('blob') : this.url ? this.url : '';
      this.pdfViewer.refresh();
    }
    this.isAfterViewInit = true;
  }
  ngOnDestroy() {
    for (let i = 0; i < this.subs.length; i++) {
      const subscription = this.subs[i];
      subscription.unsubscribe();
    }
  }

  goBack() {
    if (this.navService.getCurrentMasterPageName() === PageName.PdfPreviewComponent) {
      this.navService.popWithPageTracking();
    } else if (this.navService.getActiveChildNavViewPageName() === PageName.PdfPreviewComponent) {
      this.navService.popChildNavPageWithPageTracking();
    } else {
      console.error('pdf-preview: goBack: Navigation lost');
    }
  }

  private async _handleJsPdfInput() {
    const data: PdfPreviewCmpJsPDFInput = (this.pdfData.data as PdfPreviewCmpJsPDFInput);
    if (data.allowExport && typeof data.exportHandler === 'function' && data.exportFileName) {
      this.allowExport = true;
    }
    this.jsPdfDoc = await data.jsPdfGenerator(data.data, this.pdfService);
    this.isSourceAvail = true;
    setTimeout(() => {
      if (this.isAfterViewInit && this.pdfViewer) {
        this.pdfViewer.pdfSrc = this.jsPdfDoc.output('blob');
        this.pdfViewer.refresh();
      }
    }, 50);
  }

  footerButtonClicked(buttonId: string) {
    if (buttonId === 'exportPDF') {
      switch (this.sourceType) {
        case PdfPreviewCmpSourceType.jsPDF:
          this._handleJsPdfExport();
          break;

        default:
          console.warn(`pdf-preview: export handler not implemented for ${this.type}`);
          break;
      }
    }
  }
  private async _handleJsPdfExport() {
    const data: PdfPreviewCmpJsPDFInput = (this.pdfData.data as PdfPreviewCmpJsPDFInput);

    if (data && data.exportFileName && typeof data.exportHandler === 'function' && this.jsPdfDoc) {
      // Error toast msg needs to be updated with translation
      let errorMsg = '';
      try {
        const isSuccess = await data.exportHandler(this.jsPdfDoc, data.exportFileName, this.pdfService);
        if (isSuccess) {
          // Success toast msg needs to be updated with translation
          this.notificationService.notify(this.translate.instant('PDF_EXPORT_SUCCESS'), 'PDF Preview');
        } else {
          console.warn('_handleJsPdfExport: --- not saved..');
          errorMsg = this.translate.instant('PDF_EXPORT_FAIL');
        }
      } catch (error) {
        // Only catches file exists error for now..
        console.error('_handleJsPdfExport: ', error);
        errorMsg = `PDF file of same name "${this.exportFileName}" already exists`;
      }

      if (errorMsg) {
        // Notify error
        this.notificationService.notify(errorMsg, 'PDF Preview', 'top', ToastStyle.DANGER);
      }
    } else {
      console.warn('_handleJsPdfExport: some data missing', this.pdfData);
    }
  }
}
