
import {takeUntil} from 'rxjs/operators';
import { DateTimeFormatsService } from './../../../services/date-time-formats/date-time-formats.service';
import { OpportunityDetailsComponent } from './../../opportunity-management/opportunity-details/opportunity-details';
import { AuthenticationService } from './../../../services/authentication.service';
import { UIService, ComponentViewMode } from './../../../services/ui/ui.service';
import { NavigationService, PageName } from './../../../services/navigation/navigation.service';
import { TranslateService } from '@ngx-translate/core';
import { AccountOfflineService } from './../../../services/account/account.offline.service';
import { IndSectionHeaderViewDataModel } from './../../../models/indSectionHeaderDataModel';
import { MainCardViewDataModel } from './../../../models/MainCardViewDataModel';
import { Opportunity } from './../../../classes/opportunity-management/opportunity.class';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { OpportunityManagementService } from '../../../services/opportunity-management/opportunity-management.service';
import { format } from 'date-fns';
import { OpportunityManagementPageComponent } from '../../opportunity-management/opportunity-management-page/opportunity-management-page';
import { FeatureActionsMap } from '../../../classes/authentication/user.class';
import { Subject, Subscription } from 'rxjs';
import { DeviceService } from '../../../services/device/device.service';
import { IonList, ModalController } from '@ionic/angular';
import { OfftakeCheckComponent } from '../offtake-check-collection/offtake-check/offtake-check.component';
import moment from 'moment';
import _ from 'lodash';
import { OffTakeCheckService } from '@omni/services/offtake-check/offtake-check.service';
import { OffTakeCheck } from '@omni/classes/offtake-check/offtake-check.class';
import { IndFormFieldViewDataModel } from '@omni/models/indFormFieldDataModel';
import { DatePipe } from '@angular/common';
import { AssessmentTemplate, SurveyFrequency, SurveyStatus, SurveyType, TemplateType } from '@omni/classes/customer-assessment/assessment-template.class';
import { CustomerSurveyService } from '@omni/services/customer-survey/customer-survey.service';
import { SubCardViewDataModel } from '@omni/models/SubCardViewDataModel';
import { SurveyListCardviewComponent } from '@omni/components/survey/survey-list-cardview/survey-list-cardview';
import { CustomerAssessment } from '@omni/classes/customer-assessment/customer-assessment.class';
import { CustomerAssessService } from '@omni/services/customer-assess/customer-assess.service';
import { SurveyDetailsComponent } from '@omni/components/survey/survey-details/survey-details';
import { AccountTimelineComponent } from '../account-timeline/account-timeline';
import { EventsService } from '@omni/services/events/events.service';
import { NotificationService, ToastStyle } from '../../../services/notification/notification.service';

/**
 * Generated class for the AccountProfileComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'account-profile',
  templateUrl: 'account-profile.html',
  styleUrls:['account-profile.scss']
})
export class AccountProfileComponent {
  applicableOpportunities: Opportunity[] = [];
  openOpportunitiesViewDataModels: MainCardViewDataModel[] = [];
  wonOpportunitiesViewDataModels: MainCardViewDataModel[] = [];
  lostOpportunitiesViewDataModels: MainCardViewDataModel[] = [];
  canceledOpportunitiesViewDataModels: MainCardViewDataModel[] = [];
  openOpportunitiesHeader: MainCardViewDataModel;
  formattedOpportunities: {
    Open: Opportunity[],
    Won: Opportunity[],
    Lost: Opportunity[],
    Canceled: Opportunity[]
  } = {
    Open: [],
    Won: [],
    Lost:[],
    Canceled:[]
  }
  wonOpportunitiesHeader: MainCardViewDataModel;
  lostOpportunitiesHeader: MainCardViewDataModel;
  canceledOpportunitiesHeader: MainCardViewDataModel;
  opportunitiesHeader:IndSectionHeaderViewDataModel;
  expandedSection: string = 'open';
  ngUnSubscribe$ = new Subject<boolean>();
  @ViewChild('scrollTop', { read: ElementRef, static: false }) scrollTop: ElementRef;
  private isReadOnly: boolean = false;
  public hasCanceledOpportunity:boolean = false;
  public offtakeFeatureEnabled: boolean = false;
  offtakeCheckHeader: IndSectionHeaderViewDataModel;
  public currentMonthOffTake: OffTakeCheck;
  private IpreviewSurveyModeSubscription: Subscription;
  public isSurveyEnabled:boolean = false;
  public surveyListSectionHeader: IndSectionHeaderViewDataModel;
  private filteredSurveyList: AssessmentTemplate[] = [];
  public filteredSurveyListToDisplay: SubCardViewDataModel[] = [];
  public AvailableFilteredSurveyList = [];
  public from: string;

  constructor(
    private opportunityService: OpportunityManagementService,
    private notificationService: NotificationService,
    public accountService: AccountOfflineService,
    public translate : TranslateService,
    public navService: NavigationService,
    public uiService: UIService,
    public authService : AuthenticationService,
    public dateTimeFormatsService: DateTimeFormatsService,
    public device: DeviceService,
    public offTakeService: OffTakeCheckService,
    public modalCtrl: ModalController,
    private datePipe: DatePipe,
    public customerSurveyService: CustomerSurveyService,
    public customerAssessService: CustomerAssessService,
    private readonly events: EventsService
  ) {

    this.events.subscribe('device:deviceIsOffline', (status) => {
      this.device.isOffline = true ;
      this.initViewData();
    });
  }

  ngOnInit() {
    this.offtakeFeatureEnabled = this.authService.hasFeatureAction(FeatureActionsMap.OFFTAKE_FEATURE);
    this.isSurveyEnabled = this.authService.hasFeatureAction(FeatureActionsMap.ACCOUNT_SURVEY);
    this.opportunityService.opportunities$.pipe(takeUntil(this.ngUnSubscribe$)).subscribe((data) => {
      if (data && data.length) {
        this.initViewData();
      }
    })
    this.IpreviewSurveyModeSubscription = this.customerSurveyService.surveyPreviewObserver.pipe(takeUntil(this.ngUnSubscribe$)).subscribe(value => {
      if(!_.isEmpty(value[1])) {
        //Submitted Survey
        if(value[1].surveyStatus == SurveyStatus.SUBMITTED) {
          this._initSurveyList();
          this._initSurveySectionHeader();
        }
      }
    });
    this.accountService.isOpenedSurveyTimelineFromAccountProfile = false;

    if (this.accountService.accessedAccountListFrom == PageName.ContactDetailsComponent && this.accountService.accountPageMode == 'readonly') {
      this.isReadOnly = true;
    }
    if(this.authService.hasFeatureAction(FeatureActionsMap.DISCONTINUE_OPPORTUNTIY)){
      this.hasCanceledOpportunity = true;
    }
    this.initViewData();
    if(this.isSurveyEnabled) {
      this._initSurveyList();
      this._initSurveySectionHeader();
    }
  }
  ngOnDestroy() {
    this.offTakeService.clear();
    this.ngUnSubscribe$.next(true);
    this.ngUnSubscribe$.complete();
  }
  ngAfterViewInit(){
    setTimeout(() => {
      if(this.scrollTop) this.scrollTop.nativeElement.scrollIntoView(false);
    }, 200);
  }
  resetViewDataModels(){
    this.formattedOpportunities = {
      Open: [],
      Won: [],
      Lost:[],
      Canceled:[]
    }
    this.applicableOpportunities = [];
    this.openOpportunitiesViewDataModels = [];
    this.wonOpportunitiesViewDataModels = [];
    this.lostOpportunitiesViewDataModels = [];
    this.canceledOpportunitiesViewDataModels = [];
  }
  initViewData() {
    this.resetViewDataModels()
    if(this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_MANAGEMENT)){
      this.applicableOpportunities = this.opportunityService.opportunities.filter((oppo)=>{
        return oppo.accountID == this.accountService.selected.id;
      })
      this.getFormattedOpportunitiesList();
      this.formattedOpportunities.Open.forEach((opportunity) => {
        const viewModel: MainCardViewDataModel = this.getOpportunityMainCardModel(
          opportunity
        );
        viewModel.customSecondaryTextRight = this.dateTimeFormatsService.formattedCurrency(opportunity.closedValueFormatted)
        viewModel.customSecondaryTextRightTwo = (opportunity.estimatedValue) ?  this.dateTimeFormatsService.formattedCurrency(opportunity.estimatedValueFormatted) : ''
        this.openOpportunitiesViewDataModels.push(viewModel)
      })
      this.formattedOpportunities.Lost.forEach((opportunity)=>{
        let viewModel : MainCardViewDataModel = {
          id: opportunity.ID,
          fixedHeight:true,
          primaryTextLeft:'',
          secondaryTextLeft: '',
          showIcon: false,
          iconName: '',
          primaryTextRight: opportunity.opportunityName,
          //secondaryTextRight:'',
          //secondaryTextRightTwo: (opportunity.closedValueFormatted) ? 'Actual ' + opportunity.closedValueFormatted : '',
          isSecondaryTextRightTwoPillType: true,
          showArrow: true,
          arrowType: 'chevron-forward-outline',
          clickHandler: (id: string, event) => {
            this.openOpportunityDetail(id)
          }
        }
        viewModel.customSecondaryTextRight = this.dateTimeFormatsService.formattedCurrency(opportunity.closedValueFormatted)
        viewModel.customSecondaryTextRightTwo = (opportunity.estimatedValue) ?  this.dateTimeFormatsService.formattedCurrency(opportunity.estimatedValueFormatted) : ''
        this.lostOpportunitiesViewDataModels.push(viewModel)
      })
      this.formattedOpportunities.Canceled.forEach((opportunity)=>{
        let viewModel : MainCardViewDataModel = {
          id: opportunity.ID,
          fixedHeight:true,
          primaryTextLeft:'',
          secondaryTextLeft: '',
          showIcon: false,
          iconName: '',
          primaryTextRight: opportunity.opportunityName,
          //secondaryTextRight:'',
          //secondaryTextRightTwo: (opportunity.closedValueFormatted) ? 'Actual ' + opportunity.closedValueFormatted : '',
          isSecondaryTextRightTwoPillType: true,
          showArrow: true,
          arrowType: 'chevron-forward-outline',
          clickHandler: (id: string, event) => {
            this.openOpportunityDetail(id)
          }
        }
        viewModel.customSecondaryTextRight = this.dateTimeFormatsService.formattedCurrency(opportunity.closedValueFormatted)
        viewModel.customSecondaryTextRightTwo = (opportunity.estimatedValue) ?  this.dateTimeFormatsService.formattedCurrency(opportunity.estimatedValueFormatted) : ''
        this.canceledOpportunitiesViewDataModels.push(viewModel)
      })
      this.formattedOpportunities.Won.forEach((opportunity)=>{
        let viewModel : MainCardViewDataModel = {
          id: opportunity.ID,
          fixedHeight: true,
            primaryTextLeft:'',
            secondaryTextLeft: '',
            showIcon: false,
            iconName: '',
            primaryTextRight: opportunity.opportunityName,
            //secondaryTextRight: (opportunity.estimatedValueFormatted) ? 'Est. ' + opportunity.estimatedValueFormatted : '',
            //secondaryTextRightTwo: (opportunity.closedValueFormatted) ? 'Actual ' + opportunity.closedValueFormatted : '',
            isSecondaryTextRightTwoPillType: true,
            showArrow: true,
            arrowType: 'chevron-forward-outline',
            clickHandler: (id: string, event) => {
              this.openOpportunityDetail(id)
            }
        }
        viewModel.customSecondaryTextRight = this.dateTimeFormatsService.formattedCurrency(opportunity.closedValueFormatted)
        viewModel.customSecondaryTextRightTwo = (opportunity.estimatedValue) ?  this.dateTimeFormatsService.formattedCurrency(opportunity.estimatedValueFormatted) : ''
        this.wonOpportunitiesViewDataModels.push(viewModel)
      })
      this.openOpportunitiesHeader = {
            id: 'open-opportunties',
            primaryTextLeft: this.translate.instant('OPEN_OPPORTUNITIES'),
            showArrow: true,
            arrowType: this.expandedSection=='open'?'chevron-up-outline':'chevron-down-outline',
            clickHandler: (id: string, event, clickTarget, viewDataRef) => {
              this.setExpanded('open')
              viewDataRef.arrowType = viewDataRef.arrowType == 'chevron-up-outline'? 'chevron-down-outline':'chevron-up-outline'
            }
      }
      this.wonOpportunitiesHeader = {
        id: 'won-opportunties',
        primaryTextLeft: this.translate.instant('WON_OPPORTUNITIES'),
        showArrow: true,
        arrowType: this.expandedSection=='won'?'chevron-up-outline':'chevron-down-outline',
        clickHandler: (id: string, event, clickTarget, viewDataRef) => {
          this.setExpanded('won')
          viewDataRef.arrowType = viewDataRef.arrowType == 'chevron-up-outline'? 'chevron-down-outline':'chevron-up-outline'
        }
      }
      this.lostOpportunitiesHeader = {
        id: 'lost-opportunties',
        primaryTextLeft: this.translate.instant('LOST_OPPORTUNITIES'),
        showArrow: true,
        arrowType: this.expandedSection=='lost'?'chevron-up-outline':'chevron-down-outline',
        clickHandler: (id: string, event, clickTarget, viewDataRef) => {
          this.setExpanded('lost')
          viewDataRef.arrowType = viewDataRef.arrowType == 'chevron-up-outline'? 'chevron-down-outline':'chevron-up-outline'
        }
      }
      this.opportunitiesHeader = {
        id : 'Opportunities',
        title : this.translate.instant('OPPORTUNITIES_CAPS'),
        controls:[]
      }
    }
    this.getOfftakeFeatureHeader();
    if (this.offtakeFeatureEnabled && !this.device.isOffline) {
      this.setCurrentMonthOfftakeCheck();
    }
  }

  getOfftakeFeatureHeader() {
    this.offtakeCheckHeader = {
      id: 'OfftakeCheck',
      title: this.translate.instant('OFFTAKECHECK_CAPS'),
      controls: [
        {
          id: 'add-offtake-check',
          text: this.translate.instant('ADD'),
          isDisabled: this.device.isOffline
        },
      ]
    }
  }
  
  private setCurrentMonthOfftakeCheck() {
    this.offTakeService.getSelectedMonthOfftakeCheck(this.accountService.selected.id, moment().startOf('months').toDate(), true).then(offTakeChecks => {
      if (!_.isEmpty(offTakeChecks)) {
        this.currentMonthOffTake = offTakeChecks[0];
      }
    });
  }

  public getModifiedByFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('MODIFIED_BY'),
      inputText:  _.isEmpty(this.currentMonthOffTake?.modifiedby) ? '-' : this.currentMonthOffTake.modifiedby,
      id: 'modified-by-field',
      isReadOnly: true,
      isDisabled: true,
      showArrow: false,
    };
    return viewData;
  }

  public getModifiedDateFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('MODIFIED_DATE'),
      inputText: this.currentMonthOffTake?.modifiedOn ? this.datePipe.transform(this.currentMonthOffTake.modifiedOn, this.dateTimeFormatsService.date, undefined, this.translate.currentLang) : '',
      id: 'modified-date-field',
      isReadOnly: true,
      isDisabled: true,
      showArrow: false,
    };
    return viewData;
  }


  private getOpportunityMainCardModel(opportunity: Opportunity): MainCardViewDataModel {
    return {
      id: opportunity.ID,
      fixedHeight: true,
      primaryTextLeft: '',
      secondaryTextLeft: '',
      showIcon: false,
      iconName: '',
      primaryTextRight: opportunity.opportunityName,
      //secondaryTextRight: (opportunity.estimatedValueFormatted) ? 'Est. ' + opportunity.estimatedValueFormatted : '',
      //secondaryTextRightTwo: (opportunity.closedValueFormatted) ? 'Actual ' + opportunity.closedValueFormatted : '',
      isSecondaryTextRightTwoPillType: true,
      showArrow: true,
      arrowType: 'chevron-forward-outline',
      clickHandler: (id: string, event) => {
        this.openOpportunityDetail(id);
      }
    };
  }

  setExpanded(sectionName: string) {
    if(sectionName){
      switch (sectionName){
        case 'open':
          this.wonOpportunitiesHeader.arrowType='chevron-down-outline'
          this.lostOpportunitiesHeader.arrowType='chevron-down-outline'
          this.expandedSection = this.expandedSection == 'open'?'':'open'
          break;
        case 'won':
          this.openOpportunitiesHeader.arrowType='chevron-down-outline'
          this.lostOpportunitiesHeader.arrowType='chevron-down-outline'
          this.expandedSection = this.expandedSection == 'won'?'':'won'
          break;
        case 'lost':
          this.openOpportunitiesHeader.arrowType='chevron-down-outline'
          this.wonOpportunitiesHeader.arrowType='chevron-down-outline'
          this.expandedSection = this.expandedSection == 'lost'?'':'lost'
          break;
      }
    }
  }
  getFormattedOpportunitiesList() {
    this.applicableOpportunities.forEach((oppo)=>{
      oppo.state == 'Open'?this.formattedOpportunities.Open.push(oppo)
        :oppo.state=='Won'?this.formattedOpportunities.Won.push(oppo)
          :oppo.statusCode!=548910002?this.formattedOpportunities.Lost.push(oppo)
            :this.formattedOpportunities.Canceled.push(oppo)
    })
  }

  public onSectionHeaderControlClick(id: string) {
    switch (id) {
      case 'add-offtake-check':
        this.addOfftakeCheck();
        break;
      case 'goto-account-timeline':
        this.accountService.isOpenedSurveyTimelineFromAccountProfile = true;
        // if (this.authService.hasFeatureAction(FeatureActionsMap.CUSTOMER360UI,true)) {
        //   this.uiService.timelineRefreshRequired = true;
        //   this.navService.pushChildNavPageWithPageTracking(AccountTimelineComponent, PageName.AccountTimelineComponent,PageName.AccountPageComponent);
        // }else {
          this.uiService.accountDataSegment = 'timeline';
          this.events.publish('refershAccountDetailSegment');
        // }
        break;
      case 'goto-AllSurveyTemplate-list':
        this._openSurveyListPage();
        break;
      default:
        console.log('Unhandled switch case statement');
        break;
    }
  }

  async addOfftakeCheck() {
    if(this.device.isOffline){
      this.notificationService.notify(this.translate.instant('OFFLINE_OFFTAKE_CLICK_ADD'), "Meeting Details", "top", ToastStyle.DANGER, 3000);
      return
    }
    const model = await this.modalCtrl.create({
      component: OfftakeCheckComponent, componentProps: {
        selectedAccount: this.accountService.selected,
      }, animated: false, backdropDismiss: false
    });
    model.present();
    model.onDidDismiss().then(obj => {
      if (obj?.data?.currentMonthData)
        this.currentMonthOffTake = obj.data.currentMonthData;
    });
  }

  openOpportunityDetail(id:string){
    if (this.isReadOnly) return;
    this.navService.pushChildNavPageWithPageTracking(OpportunityDetailsComponent,
        PageName.OpportunityDetailsComponent, PageName.AccountPageComponent, { from: 'AccountProfileOpportunities', opportunity:  this.opportunityService.getOpportunityDetailsbyId(id)})
  }

  public getOpportunityDataModel(section: string): MainCardViewDataModel {
    return  {
        id: section + '-opportunities',
        fixedHeight: true,
        primaryTextRight: section === 'open'?this.translate.instant('OPEN_OPPORTUNITIES')
                            :section ==='won'?this.translate.instant('WON_OPPORTUNITIES')
                              :section ==='lost'?this.translate.instant('LOST_OPPORTUNITIES')
                                :section ==='canceled'?this.translate.instant('DISCONTINUED_OPPORTUNITIES'):'',
        isAccordionForm: true,
        showAccordionIcon: true,
        accordionIconType: this.expandedSection === section?'accordion-minus':'accordion-add',
        isAccordionText: this.expandedSection === section,
        noSeparationLine: true,
        clickHandler: (id: string, ev) => {
          this.expandOpportunityList(ev, section);
        }
      };
  }

  expandOpportunityList(event, section:string){
    this.expandedSection = this.expandedSection === section ? 'none': section
  }

  private _initSurveyList() {
    this.filteredSurveyList = this.customerAssessService.surveytemplates.filter(template => template.indskr_type == TemplateType.SURVEY && template?.indskr_surveytype != SurveyType.INTERNAL && new Date(template.indskr_validfrom) <= new Date() && new Date(template.indskr_validto) >= new Date() && template.indskr_entity == 'account');
    this.customerSurveyService.surveyTemplates.filter(list => list.indskr_surveytype != SurveyType.INTERNAL);
    if(!_.isEmpty(this.filteredSurveyList)) {
      this._initViewData();
    }
  }
  
  private _initViewData() {
    this.filteredSurveyListToDisplay = [];
    this.AvailableFilteredSurveyList = [];
    this.filteredSurveyList = _.orderBy(this.filteredSurveyList, ['indskr_validto','indskr_name'], ['desc', 'asc']);
    this.filteredSurveyList.forEach( (survey) => {
      const templateId: string = survey.indskr_assessmenttemplateid;
      const selectedAccountId = this.accountService.selected?.id;
      let isEligibleAccount: boolean = false;
      //check eligible customers
      if(survey.indskr_metadata.hasOwnProperty('EligibleCustomers') && !_.isEmpty(survey.indskr_metadata.EligibleCustomers)) {
        let eligibleAccountIDs: string[] = [];
        const eligibleAccountsFromMetaData = survey.indskr_metadata['EligibleCustomers'];
        eligibleAccountIDs = eligibleAccountsFromMetaData.map(account=>account['ID']);
        isEligibleAccount = eligibleAccountIDs.some(accoountId => accoountId == selectedAccountId);
      }else {
        isEligibleAccount = true;
      }
      const surveyedAccountsByTemplate = this.customerAssessService.surveyedAccountsForOnetimeSurvey[templateId];
      let isCapturedOneTimeSurvey: boolean = false;
      if(!_.isEmpty(surveyedAccountsByTemplate)) {
        isCapturedOneTimeSurvey = surveyedAccountsByTemplate.some((template) => template['indskr_entityid'] == selectedAccountId );
      }
      // const isCapturedOneTimeSurvey: boolean = this.customerAssessService.isCapturedOneTimeSurvey(templateId, selectedAccountId, 'account');      //To display top 2 available surveys based on eligible dates with eligible customers, without capturing one-time surveys
      if(this.filteredSurveyListToDisplay.length < 2) {
        if(isEligibleAccount) {
          //check captured one-time survey
          if(survey.indskr_surveyfrequency == SurveyFrequency.ONCE) {
            if(!isCapturedOneTimeSurvey) {
              this.filteredSurveyListToDisplay.push(this._getSubCardDataModel(survey));
            }
          }else {
            this.filteredSurveyListToDisplay.push(this._getSubCardDataModel(survey));
          }
        }
      }
      //update number of available survey
      if(isEligibleAccount) {
        //check captured one-time survey
        if(survey.indskr_surveyfrequency == SurveyFrequency.ONCE) {
          if(!isCapturedOneTimeSurvey) {
            this.AvailableFilteredSurveyList.push(survey);
          }
        }else {
          this.AvailableFilteredSurveyList.push(survey);
        }
      }
    });
  }

  private _initSurveySectionHeader() {
    const numOfList: number = this.AvailableFilteredSurveyList.length;
    this.surveyListSectionHeader = {
      id: 'survey_list_section_header',
      // title: `${this.translate.instant("AVAILABLE")} ${this.translate.instant("SURVEY")} (${numOfList})`,
      title: `${this.translate.instant("AVAILABLE_SURVEY")} (${numOfList})`,
      isShowSperationVerticalLine: true,
      controls: [
        {
          id: "goto-account-timeline",
          text: '',
          icon: "assets/imgs/survey-icon-blue-time.svg",
          isDisabled: false //check available survey - submitted data
        },
        {
          id: 'goto-AllSurveyTemplate-list',
          text: '',
          icon: "assets/imgs/chevron-forward-outline-blue.svg",
          isDisabled: _.isEmpty(this.filteredSurveyList),
      }],
    };
  }

  private _getSubCardDataModel(survey: AssessmentTemplate): SubCardViewDataModel {
    const viewData: SubCardViewDataModel = {
      id: survey.indskr_assessmenttemplateid,
      primaryTextRight: survey.indskr_name,
      isSecondaryGrid: true,
      gridItems: [],
      startIconInline: "assets/imgs/survey_blue_bluedot.svg",
      buttonItems: [],
      clickHandler: (id: string, event, specificTarget) => {}
    };
    //grid items
    viewData.gridItems.push(
      {
        id: 'type',
        label: this.translate.instant('TYPE'),
        value: this.translate.instant('SURVEY_ACCOUNT_SURVEY'),
        rawValue: survey['indskr_surveytype'],
      },
      {
        id: 'status',
        // label: this.translate.instant("SURVEY_ACCOUNT_SURVEYED"),
        label: this.translate.instant("ACCOUNT_ELIGIBLE"),
        value: this.getAccountSurveyed(survey),
        rawValue: undefined,
      },
      {
        id: 'use-type',
        label: this.translate.instant('USE_TYPE'),
        value: this.getSurveyFrequency(survey),
        rawValue: survey['indskr_surveyfrequency'],
      },
      { id: 'expireson',
        label: this.translate.instant('EXPIRES_ON'),
        value: this._getFormattedDate(survey['indskr_validto']),
        rawValue: survey['indskr_validto'],
      }
    );
    //buttons - If external type and one-time survey is captured, start-survey button is disabled, start-survey on preview is disabled
    viewData.buttonItems.push(
      {
        id: 'preview',
        name: this.translate.instant("PREVIEW"),
        isDisabled: this.accountService.accountPageMode != ComponentViewMode.LISTVIEW
      },
      {
        id: 'start-survey',
        name: this.translate.instant("START_SURVEY"),
        isDisabled: this.accountService.accountPageMode != ComponentViewMode.LISTVIEW
      }
    );
    return viewData;
  }

  private getAccountSurveyed(survey: AssessmentTemplate): string {
    return this.customerAssessService.getNumOfAccountSurveyed(survey);
  }

  private getSurveyFrequency(survey: AssessmentTemplate) {
    return survey.indskr_surveyfrequency == SurveyFrequency.ONCE ? this.translate.instant("ONE_TIME") : this.translate.instant("RECURRING");
  }

  private _getFormattedDate(date: any): string {
    const formattedDate = this.datePipe.transform(date, this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
    return formattedDate;
  }

  public onButtonControlClick(event) {
    if(!_.isEmpty(event) && !_.isEmpty(event.button)) {
      const buutonId = event.button.id;
      const surveyTemplate = event.viewData;
      console.log("clicked button ", buutonId);
      if(buutonId == 'preview') {
        this.openSurvey(surveyTemplate, true);
      }else if(buutonId == 'start-survey') {
        this.openSurvey(surveyTemplate, false);
      }
    }
  }

  async openSurvey(selectedTemplateFromCardView: any, isReadOnly: boolean) {
    if(!_.isEmpty(selectedTemplateFromCardView)) {
      await this.uiService.displayLoader();
      const rawSurveyTemplateList = this.customerAssessService.surveytemplates.filter(template => template.indskr_type == TemplateType.SURVEY && new Date(template.indskr_validfrom) <= new Date() && new Date(template.indskr_validto) >= new Date);
      const foundTemplate: AssessmentTemplate = rawSurveyTemplateList?.find(template=> template.indskr_assessmenttemplateid == selectedTemplateFromCardView.id);
      if(!_.isEmpty(foundTemplate)) {
        this.customerSurveyService.setCurrentSurvey(foundTemplate);
        try {
          await this.navService.pushChildNavPageWithPageTracking(SurveyDetailsComponent, PageName.SurveyDetailsComponent, PageName.AccountDetailsComponent, { appointmentId: '', from: PageName.AccountDetailsComponent }).then(async()=>{
            this.navService.setChildNavRightPaneView(true);
            this.uiService.dismissLoader();
            this.customerSurveyService.selectedAccountForSurvey = this.accountService.selected ? this.accountService.selected : this.accountService.selectedAccounts[0] ?? null;

            if(!isReadOnly) {
              //check saved survey data from db before starting the survey
              const accountId: string = this.customerSurveyService.selectedAccountForSurvey.id || '';
              const templateId: string = foundTemplate.indskr_assessmenttemplateid || '';
              if(!_.isEmpty(accountId) && !_.isEmpty(templateId)) {
                // let surveysFromDB = await this.customerAssessService.getCustomerSurveyFromDB(templateId);
                let surveysByTemplateId = this.customerAssessService.getSurveysByTemplateId(templateId, 'account', false);
                if(!_.isEmpty(surveysByTemplateId)) {
                  let surveyDataFromDB: CustomerAssessment;
                  let savedSurvey = surveysByTemplateId.find(survey => survey.surveyStatus === SurveyStatus.SAVED && survey.indskr_entityid === accountId);
                  if (savedSurvey) {
                      surveyDataFromDB = savedSurvey;
                  }
                  // response update
                  const responseData = await this.customerAssessService.fetchContactSurveyForDetails(surveyDataFromDB, 'account', true);
                  if(!_.isEmpty(responseData)) {
                    surveyDataFromDB.responses = responseData;
                  }
                  this.customerSurveyService.setSurveyPreviewMode(false, surveyDataFromDB);
                }else {
                  //There is no saved data with the selected contact and survey template
                  this.customerSurveyService.setSurveyPreviewMode(false);
                }
              }
            }else {
              //preview template
              this.customerSurveyService.setSurveyPreviewMode(true);
            }
          });
        } catch(error) {
          console.log("failed to open survey ", error);
          this.uiService.dismissLoader();
        }
      }else {
        console.log("There is no found template before opening the survey");
        this.uiService.dismissLoader();
      }
    }
  }

  private async _openSurveyListPage() {
    await this.uiService.displayLoader();
    try {
      await this.navService.pushChildNavPageWithPageTracking(SurveyListCardviewComponent, PageName.SurveyDetailsComponent, PageName.AccountDetailsComponent, { appointmentId: '', from: PageName.AccountDetailsComponent }).then(async()=>{
        this.navService.setChildNavRightPaneView(true);
        this.uiService.dismissLoader();
        this.customerSurveyService.selectedAccountForSurvey = this.accountService.selected ? this.accountService.selected : this.accountService.selectedAccounts[0] ?? null;
      });
    } catch(error) {
      console.log("failed to open survey ", error);
      this.uiService.dismissLoader();
    }
  }
  

}
