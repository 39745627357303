import { ActivityColorCode } from './../../classes/activity/activity.class';
export const CALENDAR_COLOR_CODE_RESOURCES = [
  {
    id: 1,
    color: ActivityColorCode.ActivityCompleted,
  },
  {
    id: 2,
    color: ActivityColorCode.ActivityOpen,
  },
  {
    id: 3,
    color: ActivityColorCode.TimeOffActivity,
  },
  {
    id: 4,
    color: ActivityColorCode.FollowUpTask,
  },
  {
    id: 5,
    color: ActivityColorCode.Sample
  },
  // {
  //   id: 6,
  //   color: ActivityColorCode.MeetingProximityWarning
  // }
  {
    id: 6,
    color: ActivityColorCode.ProcedureLog
  },
  {
    id: 7,
    color: ActivityColorCode.StoreCheck
  }
];
