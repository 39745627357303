import { Component } from '@angular/core';
import { UIService, ComponentViewMode } from '../../../services/ui/ui.service';
import { ActivityService } from '../../../services/activity/activity.service';
import { DeviceService } from '../../../services/device/device.service';
import { NavController } from '@ionic/angular';
import { NavigationService, PageName } from '../../../services/navigation/navigation.service';
import { AccountOfflineService } from '../../../services/account/account.offline.service';
import { TrackService } from '../../../services/logging/tracking.service';
import { AccountDataService } from '../../../data-services/accounts/account.data.service';
import { AccountDetailsComponent } from '../account-details/account-details';
import { FooterService, FooterViews } from '../../../services/footer/footer.service';
import { RepServices } from '../../../data-services/rep/rep.services';

/**
 * Generated class for the AccountListRoComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'account-list-ro',
  templateUrl: 'account-list-ro.html',
  styleUrls:['account-list-ro.scss']
})
export class AccountListRoComponent {

  accountListMode:string;

  constructor(
    private uiService:UIService,
    public accountService:AccountOfflineService,
    private accountDataService:AccountDataService,
    public activityService: ActivityService,
    private device:DeviceService,
    private navCtrl:NavController,
    private navService:NavigationService,
    private trackingService:TrackService,
    private footerService:FooterService,
    public repService: RepServices,
  ) {

  }
  onCloseModal(){
    this.uiService.showNewActivity = false;
    this.uiService.activeView = this.uiService.prevView;
    if(this.device.isMobileDevice){
      this.navService.popWithPageTracking();
    }
  }

  public openAccountDetails(account){

    const insightsEventName = this.accountListMode === ComponentViewMode.ADDNEW? 'Account INFO via Meeting': 'AccountInfo'
    this.trackingService.tracking('Accounts',insightsEventName);

    this.accountService.selected = account;
    this.accountService.selected.activitesByAccount = [];
    this.accountDataService.getAccountTimelineInfo(account);
    this.uiService.showNewActivity = false;
    this.uiService.activeView = 'accountDetails';
    console.log('show account details', account)
    if(this.device.isMobileDevice && (this.navService.getCurrentPageName() == PageName.AccountListRoComponent)){
      this.navService.pushWithPageTracking(AccountDetailsComponent, PageName.AccountDetailsComponent);
    }
    this.footerService.initButtons(FooterViews.Accounts)
  }

}
