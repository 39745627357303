import { Activity, ActivityType, ActivityColorCode } from './activity.class';

export class TimeOffActivity
extends Activity
{

    public timeOffRequestId: string;
    get offlineTimeOffRequestId(): string {
      return this.offlineId;
    }
    set offlineTimeOffRequestId(offlineId: string) {
      this.offlineId = offlineId;
    }
    public timeOffReason: number;
    public totStartTime: Date;
    public positionId: string;
    public positionValue: string;
    public totIsAlldayEvent: boolean;
    public totEndTime: Date;
    public reason: string;
    public name: string;
    public other: string;
    public statecode: number;
    public statuscode: number;
    public statusValue: string;
    public totOwnerId: string;
    public totOwnerValue: string;
    // public approverId: string;
    public approverName: string;
    public approvalDate: Date;
    public isAutoApproved: boolean;
    public durationType: string;
    public pendingPushToDynamics: boolean = false;
    public comments: string = '';
    public approverComments: string = '';

    constructor(raw:Object, type:ActivityType){
        super(raw);
        this.timeOffRequestId = raw['indskr_timeoffrequestid'];
        if (raw['indskr_timeoffrequestid']) this.ID = raw['indskr_timeoffrequestid'];
        this.statecode = raw['statecode'];
        this.statuscode = raw['statuscode'] || 100000000;
        this.statusValue = raw['statuscode@OData.Community.Display.V1.FormattedValue'] || 'Open';

        if (raw['indskr_starttime'] && !isNaN(raw['indskr_starttime'])) this.scheduledStart = new Date(parseFloat(raw['indskr_starttime']));
        if (raw['indskr_endtime'] && !isNaN(raw['indskr_endtime'])) this.scheduledEnd = new Date(parseFloat(raw['indskr_endtime']));

        //CWD-3322 Fix for new issues

        this.totStartTime = this.scheduledStart;
        this.totEndTime = this.scheduledEnd;

        this.positionId = raw['_indskr_positionid_value'] || raw['indskr_positionid_value'];
        this.positionValue = raw['_indskr_positionid_value@OData.Community.Display.V1.FormattedValue'] ||
                                raw['indskr_positionid_value@OData.Community.Display.V1.FormattedValue'];
        this.totIsAlldayEvent = raw['indskr_isalldayevent'];

        this.timeOffReason = raw['indskr_reason'];
        if (raw['indskr_reason@OData.Community.Display.V1.FormattedValue']) this.reason =  raw['indskr_reason@OData.Community.Display.V1.FormattedValue'];
        this.name = raw['indskr_name'];
        if (raw['indskr_name']) this.subject = raw['indskr_name'];
        this.totOwnerId = raw['_ownerid_value'] || raw['ownerid_value'];
        this.totOwnerValue = raw['_ownerid_value@OData.Community.Display.V1.FormattedValue'] || raw['ownerid_value@OData.Community.Display.V1.FormattedValue'];
        this.type = type;
        this.color = ActivityColorCode.TimeOffRequest;
        // if (raw['_indskr_approver_value']) this.approverId = raw['_indskr_approver_value'];
        if (raw['_indskr_approver_value@OData.Community.Display.V1.FormattedValue'] ||
            raw['indskr_approver_value@OData.Community.Display.V1.FormattedValue']) {
                this.approverName = raw['_indskr_approver_value@OData.Community.Display.V1.FormattedValue'] ||
                                    raw['indskr_approver_value@OData.Community.Display.V1.FormattedValue'];
            }
        if (raw['indskr_approvaldate']) this.approvalDate = new Date(raw['indskr_approvaldate']);
        if (raw['indskr_isautoapproved']) this.isAutoApproved = raw['indskr_isautoapproved'];
        this.scheduledStartLocale = this.scheduledStart.toLocaleTimeString('en-US', { hour12: true, hour: '2-digit', minute:'2-digit'});

        this.offlineTimeOffRequestId = raw['indskr_local_timeoffrequestid'] ? raw['indskr_local_timeoffrequestid'] : "offline_tot_" +this.timeOffRequestId;
        if (!this.timeOffRequestId) {
            this.timeOffRequestId = this.offlineTimeOffRequestId;
        }
        if (!this.ID) {
            this.ID = this.offlineTimeOffRequestId;
        }
        // if(raw['indskr_isalldayevent']) this.durationType = "All Day";
        // else if (raw['indskr_durationcalc'] == "0.50 day") this.durationType = "Half Day";
        // else this.durationType = "Hourly";
        this.durationType = (raw['indskr_isalldayevent'])?'All Day':(raw['indskr_durationindays'] == '0.5')?'Half Day':'Hourly';
        this.pendingPushToDynamics = raw['pendingPushToDynamics'];
        this.comments = raw['indskr_comments'] || '';
        this.approverComments = raw['indskr_approvercomments'] || '';
    }

    get DTO(): Object {
        return {
            indskr_timeoffrequestid: this.timeOffRequestId,
            statecode: this.statecode,
            statuscode: this.statuscode,
            'statuscode@OData.Community.Display.V1.FormattedValue': this.statusValue,
            indskr_starttime: this.scheduledStart ? '' + this.scheduledStart.getTime() : null,
            indskr_positionid_value: this.positionId,
            'indskr_positionid_value@OData.Community.Display.V1.FormattedValue': this.positionValue,
            indskr_isalldayevent: this.totIsAlldayEvent,
            indskr_endtime: this.scheduledEnd ? '' + this.scheduledEnd.getTime() : null,
            indskr_reason: this.timeOffReason,
            'indskr_reason@OData.Community.Display.V1.FormattedValue': this.reason,
            indskr_name: this.name,
            ownerid_value: this.totOwnerId,
            'ownerid_value@OData.Community.Display.V1.FormattedValue': this.totOwnerValue,
            // '_indskr_approver_value': this.approverId,
            'indskr_approver_value@OData.Community.Display.V1.FormattedValue': this.approverName,
            indskr_approvaldate: this.approvalDate ? this.approvalDate.getTime() : null,
            indskr_isautoapproved: this.isAutoApproved,
            indskr_local_timeoffrequestid: this.offlineTimeOffRequestId,
        }
    }

}


export enum TimeOffStatus {
  "Open" = 100000000,
  "InReview" = 100000003,
  "NotApproved" = 100000002,
  "Approved" = 100000004
}

export enum DurationType {
  AllDay = 'All Day',
  HalfDay = 'Half Day',
  Hourly = 'Hourly',
}
