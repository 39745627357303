/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { AuthenticationService } from '../authentication.service';
import { DynamicsClientService } from '@omni/data-services/dynamics-client/dynamics-client.service';
import { FeatureActionsMap } from '@omni/classes/authentication/user.class';
import { DB_KEY_PREFIXES } from '@omni/config/pouch-db.config';
import { DiskService, OFFLINE_DATA_COUNT_ENTITY_NAME } from '../disk/disk.service';
import { UIService } from '../ui/ui.service';
import { DeviceService } from '../device/device.service';
import { NotificationService } from '../notification/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Endpoints } from 'src/config/endpoints.config';

@Injectable({
  providedIn: 'root',
})
export class UserTagService {

  public accountTags: UserTag[] = [];
  public contactTags: UserTag[] = [];
  public PresentationTags: UserTag[] = [];
  private userTagDeleted = new BehaviorSubject<boolean>(false);
  public userTagDeletedObserver = this.userTagDeleted.asObservable();

  constructor(
    private authService: AuthenticationService,
    private dynamics: DynamicsClientService,
    private disk: DiskService,
    private readonly uiService: UIService,
    private readonly deviceService: DeviceService,
    private readonly toast: NotificationService,
    private readonly translate: TranslateService,
    private http: HttpClient,
  ) {

  }

  async createOrUpdateTag(tagData: UserTag, create: boolean = false): Promise<any> {
    this.uiService.displayLoader();
    return new Promise(async (resolve, reject) => {
      tagData.pendingPushToDynamics = true;
      if (create) {
        tagData.indskr_externalid = `offlineUserTag_${new Date().getTime()}`;
      }
      tagData.stateCode = TagStateCode.Active
      console.log("requestObj", tagData)
      if (this.deviceService.isOffline) {
        this.disk.addOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.USER_TAG, 1);
        await this.disk.upsertUserTag(tagData);
        this.uiService.dismissLoader();
        this.toastNotificationForTags(tagData, create);
        resolve([{
          indskr_usertagid: tagData.indskr_usertagid,
          indskr_externalid: tagData.indskr_externalid
        }]);
      } else {
        await this.saveTagOnline(tagData).subscribe(async response => {
          response.forEach(async res => {
            tagData.pendingPushToDynamics = false;
            await this.disk.upsertUserTag(tagData);
            if (tagData.indskr_externalid === res.indskr_externalid) {
              tagData.indskr_usertagid = res.indskr_usertagid;
              this.toastNotificationForTags(tagData, create);
              if (res["indskr_usertagid"]) {
                this.uiService.dismissLoader();
                resolve(response);
              } else {
                this.uiService.dismissLoader();
                reject(response);
              }
            }
          });
        }, async error => {
          console.log("Fails to create tag due to", error);
          tagData.pendingPushToDynamics = true;
          this.disk.addOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.USER_TAG, 1);
          await this.disk.upsertUserTag(tagData);
          // error toast notification
          reject(error);
        });
      }
    });
  }

  toastNotificationForTags(tagData: UserTag, create: boolean, deleteTag = false) {
    if (deleteTag) {
      this.toast.notify(`'${tagData.indskr_name}' ${this.translate.instant("DELETED_SUCCESSFULLY")}`, "");
      return;
    }
    if (create) {
      this.toast.notify(`'${tagData.indskr_name}' ${this.translate.instant("SAVED_SUCCESSFULLY")}`, "");
    } else {
      this.toast.notify(`'${tagData.indskr_name}' ${this.translate.instant("UPDATED_SUCCESSFULLY")}`, "");
    }
  }

  saveTagOnline(reqBody: UserTag): Observable<any> {
    if (this.deviceService.isOffline) {
      if (reqBody[0].pendingPushToDynamics) {
        this.disk.addOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.USER_TAG, 1);
        reqBody[0].pendingPushToDynamics = true;
      }
    } else {
      let headers = new HttpHeaders();
      let payload;
      if (reqBody.entity == TagEntityType.ACCOUNT) {
        payload = {
          indskr_externalid: reqBody.indskr_externalid,
          indskr_usertagid: reqBody.indskr_usertagid,
          deleted: reqBody.deleted,
          indskr_name: reqBody.indskr_name,
          accounts: reqBody.entityRecords ? reqBody.entityRecords.map(a => { return { 'accountId': a.id, 'deleted': a.deleted } }) : [],
          pendingPushToDynamics: reqBody.pendingPushToDynamics,
          stateCode: reqBody.stateCode,
          indskr_taggedfor: 'ACCOUNT',
        }
      } else if (reqBody.entity == TagEntityType.CONTACT) {
        payload = {
          indskr_externalid: reqBody.indskr_externalid,
          indskr_usertagid: reqBody.indskr_usertagid,
          deleted: reqBody.deleted,
          indskr_name: reqBody.indskr_name,
          contacts: reqBody.entityRecords ? reqBody.entityRecords.map(a => { return { 'contactId': a.id, 'deleted': a.deleted } }) : [],
          pendingPushToDynamics: reqBody.pendingPushToDynamics,
          stateCode: reqBody.stateCode,
          indskr_taggedfor: 'CONTACT',
        }
      }
      let url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.contacts.CONTACT_TAGGING;
      return this.http.post(url, [payload], { headers });
    }
  }

  async deleteTag(userTagPayload: UserTag) {
    this.uiService.displayLoader();
    if (this.deviceService.isOffline) {
      
      if (userTagPayload.entity == TagEntityType.ACCOUNT) {
        // offline create and offline delete
        if (!userTagPayload.indskr_usertagid) {
          await this.disk.remove(DB_KEY_PREFIXES.USER_TAG + userTagPayload.indskr_externalid)
        } else {
          // online create and offline delete
          userTagPayload.pendingPushToDynamics = true;
          this.disk.addOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.USER_TAG, 1);
          await this.disk.upsertUserTag(userTagPayload);
        }
        if(this.accountTags.length){
          let selIndex = this.accountTags.findIndex(tag => userTagPayload.indskr_externalid === tag.indskr_externalid);
          if (selIndex >= 0) {
            this.accountTags.splice(selIndex, 1);
          }
        }
      } else if (userTagPayload.entity == TagEntityType.CONTACT) {
        // offline create and offline delete
        if (!userTagPayload.indskr_usertagid) {
          await this.disk.remove(DB_KEY_PREFIXES.CONTACT_TAG + userTagPayload.indskr_externalid)
        } else {
          // online create and offline delete
          userTagPayload.pendingPushToDynamics = true;
          this.disk.addOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.CONTACT_TAG, 1);
          await this.disk.upsertUserTag(userTagPayload);
        }
        if(this.contactTags.length){
          let selIndex = this.contactTags.findIndex(tag => userTagPayload.indskr_externalid === tag.indskr_externalid);
          if (selIndex >= 0) {
            this.contactTags.splice(selIndex, 1);
          }
        }
      }
      this.toastNotificationForTags(userTagPayload, false, true);
      this.uiService.dismissLoader();
    } else {
      await this.saveTagOnline(userTagPayload).subscribe(async response => {
        console.log(response);
        if (response) {
          userTagPayload.pendingPushToDynamics = false;
          if (userTagPayload.entity == TagEntityType.ACCOUNT) {
            await this.disk.remove(DB_KEY_PREFIXES.USER_TAG + userTagPayload.indskr_externalid);
            let selIndex = this.accountTags.findIndex(tag => userTagPayload.indskr_externalid === tag.indskr_externalid);
            if (selIndex >= 0) {
              this.accountTags.splice(selIndex, 1);
            }
          } else if (userTagPayload.entity == TagEntityType.CONTACT) {
            await this.disk.remove(DB_KEY_PREFIXES.CONTACT_TAG + userTagPayload.indskr_externalid);
            let selIndex = this.contactTags.findIndex(tag => userTagPayload.indskr_externalid === tag.indskr_externalid);
            if (selIndex >= 0) {
              this.contactTags.splice(selIndex, 1);
            }
          }

          this.toastNotificationForTags(userTagPayload, false, true);
          this.userTagDeleted.next(true);
          this.uiService.dismissLoader();
        } else {
          this.uiService.dismissLoader();
        }
      });
    }
  }

  uploadOfflineData(tagDataList: UserTag[]): Observable<any> {
    let headers = new HttpHeaders();
    let url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.contacts.CONTACT_TAGGING;
    let payload = [];
    tagDataList.forEach(item => {
      if (item.entity == TagEntityType.ACCOUNT) {
        payload.push({
          indskr_externalid: item.indskr_externalid,
          indskr_usertagid: item.indskr_usertagid,
          deleted: item.deleted,
          indskr_name: item.indskr_name,
          accounts: item.entityRecords ? item.entityRecords.map(a => { return { 'accountId': a.id, 'deleted': a.deleted } }) : [],
          pendingPushToDynamics: item.pendingPushToDynamics,
          stateCode: item.stateCode,
          indskr_taggedfor: 'ACCOUNT',
        });
      } else if (item.entity == TagEntityType.CONTACT) {
        payload.push({
          indskr_externalid: item.indskr_externalid,
          indskr_usertagid: item.indskr_usertagid,
          deleted: item.deleted,
          indskr_name: item.indskr_name,
          contacts: item.entityRecords ? item.entityRecords.map(a => { return { 'contactId': a.id, 'deleted': a.deleted } }) : [],
          pendingPushToDynamics: item.pendingPushToDynamics,
          stateCode: item.stateCode,
          indskr_taggedfor: 'CONTACT',
        });
      }
    })
    return this.http.post(url, payload, { headers });
  }

  public sortUserTags(tags:Array<UserTag>):Array<UserTag>{
    return tags.sort((tagA, tagB): number => {
      return (tagA.indskr_name.toLowerCase() < tagB.indskr_name.toLowerCase() ? -1 : 1);
    });
  }

}

export class UserTag {
  constructor(public indskr_externalid: string,
    public indskr_usertagid: string,
    public deleted: boolean = false,
    public indskr_name: string,
    public entityRecords: EntityTag[],
    public pendingPushToDynamics: boolean = false,
    public stateCode: number = TagStateCode.Active,
    public entity: TagEntityType = TagEntityType.CONTACT,
    public visibility: string
  ) {
  }

}

export class EntityTag {
  constructor(
    public id: string,
    public deleted: boolean = false) {
  }
}

export enum TagStateCode {
  Active = 0,
  InActive = 1
}

export enum TagEntityType {
  ACCOUNT = 'ACCOUNT',
  CONTACT = 'CONTACT',
  PRESENTATION ='PRESENTATION'
}
