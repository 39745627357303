/*
* Class representing the data structure for Event
* @export
* @class Event
*/

export class EventType {
    public id: string;
    public name: string;
    public state: number;
    public subTypes: Array<EventSubType> = [];

    constructor(raw: object){
        this.id = raw['indskr_event_typeid'] || '';
        this.name = raw['indskr_name'] || '';
        this.state = raw['statecode'];
        if(raw['indskr_eventsubtype1.indskr_eventsubtypeid']) this.subTypes.push({
            id: raw['indskr_eventsubtype1.indskr_eventsubtypeid'],
            name:raw['indskr_eventsubtype1.indskr_name'] || '',
            state: raw['indskr_eventsubtype1.statecode']
        });
    }
}

export class EventSubType {
    id: string;
    name: string;
    state: number;

    constructor(raw: object){
        this.id = raw['indskr_eventsubtypeid'] || '';
        this.name = raw['indskr_name'] || '';
        this.state = raw['statecode'];
    }
}

export class Speaker {
    id: string;
    name: string;
    isExternal: boolean;
    contactId?: string;
    state: number;

    constructor(raw: object){
        this.id = raw['msevtmgt_speakerid'] || '';
        this.name = raw['msevtmgt_name'] || '';
        this.isExternal = raw['indskr_onboardedasspeaker'] || '';
        this.contactId = raw['contactid'] || '';
        this.state = raw['statecode'];
    }
}