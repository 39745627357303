
import {throwError as observableThrowError,  Observable, observable } from 'rxjs';
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import { Endpoints } from "../../../config/endpoints.config";
import { AuthenticationService } from "../../services/authentication.service";
import { map ,  catchError } from "rxjs/operators";
import { EmailTemplate, CustomToken, HTMLEmailTemplate, EmailTemplateType } from "../../classes/email-templates/email-template.class";
import { LogService } from "../../services/logging/log-service";
import { Contact } from "../../classes/contact/contact.class";
import { EmailDataService } from "../email/email.data.service";
import { TranslateService } from "@ngx-translate/core";
import { SelectedSuggestionPillDataModel } from "../../models/search-config-data-model";

/**
 *
 *  Email data-services class to retrive and post email messages
 * @export
 * @class EmailTemplateServices
 */
@Injectable({
  providedIn: 'root'
})
export class EmailTemplateServices {
  public emailTemplates: EmailTemplate;
  private customeTokens: Array<CustomToken>
  private HTMLTemplates: Array<HTMLEmailTemplate>
  public emailContacts:Contact[]=[];
  public remoteMeetingURL:string;

  recentSearches: SelectedSuggestionPillDataModel[] = [];

  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    private logService:LogService,
    private emailDataService:EmailDataService,
    private translate: TranslateService
  ) {}
  /**
   * Returns all email templates , of a particular user
   *
   * @returns {Observable<EmailTemplate>}
   * @memberof EmailTemplateServices
   */
  public getAllEmailTemplates(): Observable<EmailTemplate> {
    let url = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.emailTemplates.GET_ALL_EMAIL_TEMPLATES;
    return this.http
      .get(url)
      .pipe(
        map(response => this._mapEmailTemplates(response)),
        catchError((e: any) => observableThrowError(this.errorHandler(e)))
      );
  }

  public get RemoteURLEmailTemplates():Array<HTMLEmailTemplate>{
    //let remoteTemplate = this.emailTemplates.getHTMLEmailTemplates.filter(item => item.type === EmailTemplateType.RemoteURL);
    return (this.emailTemplates) ? this.emailTemplates.getHTMLEmailTemplates.filter(item => item.type === EmailTemplateType.RemoteURL) : [];
  }

  /**
   * Return the emailTemplates , this is where the one to one mapping of the backend data is resolved
   *
   * @param {object} raw
   * @returns {EmailTemplate}
   * @memberof EmailTemplateServices
   */
  _mapEmailTemplates(raw: object): EmailTemplate {
    this.emailTemplates = new EmailTemplate(raw);
    this.customeTokens =  this.emailTemplates.getCustomTokens; //getter
    this.HTMLTemplates =  this.emailTemplates.getHTMLEmailTemplates;
    return this.emailTemplates;
  }
  /**
   *
   *
   * @readonly
   * @type {Array<CustomToken>}
   * @memberof EmailTemplateServices
   */
  public get getCustomTokens(): Array<CustomToken>{
      return this.customeTokens;
  }
  /**
   *
   *
   * @readonly
   * @type {Array<HTMLEmailTemplate>}
   * @memberof EmailTemplateServices
   */
  public get getHTMLEmailTemplates():Array<HTMLEmailTemplate>{
      return this.HTMLTemplates;
  }
  /**
   *    Handles errors and richmond forgot to add this comment but other than that he is a really great guy
   *
   * @private
   * @param {*} err
   * @memberof EmailTemplateServices
   */
  private errorHandler(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
        // A client-side or network error occurred. Handle it accordingly.
        this.logService.logError('An error occurred:', error.error.message);
      } else {
        // The backend returned an unsuccessful response code.
        // The response body may contain clues as to what went wrong,
        try {
          console.log(
            `Backend returned code ${error.status}, ` +
            `body was: ${error}, ` +  ` message was: ${error.error.errorMessage}`);
        } catch (error) {
          console.log('Error message didnt have message', error);
        }
      }
      return Observable.throw(error + this.translate.instant('SOMETHING_REALLY_BAD_HAPPENED'))
      // return an ErrorObservable with a user-facing error message , leads to runtime error , so we handle this to prevent the app from crashing?
};

  getEmailTemplateDetailById = (id):HTMLEmailTemplate=>{
    let resultTemplate = this.emailTemplates['template'].filter((value)=>{
        return (value.ID === id);
    });
    return (resultTemplate) ? resultTemplate[0] : undefined;
  }

  sendRemoteURLEmail(templateId:string){
    let template = this.getEmailTemplateDetailById(templateId);
    this.emailDataService.sendEmail(template,this.emailContacts,this.remoteMeetingURL);
  }
}
