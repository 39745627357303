import { Component, Input, SimpleChanges, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { ActivityService } from '../../../services/activity/activity.service';
import { TherapeuticArea } from '../../../classes/therapeutic-area/therapeutic-area.class';
import { TrackService, TrackingEventNames } from '../../../services/logging/tracking.service';
import { IndSectionHeaderViewDataModel } from '@omni/models/indSectionHeaderDataModel';
import { TranslateService } from '@ngx-translate/core';
import { ActivityType } from '../../../classes/activity/activity.class';

/**
 * Generated class for the PhoneCallTherapeuticAreaComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'phone-call-therapeutic-area',
  templateUrl: 'phone-call-therapeutic-area.html',
  styleUrls:['phone-call-therapeutic-area.scss']
})
export class PhoneCallTherapeuticAreaComponent {


  product: any;
  tempProducts: any;
  public isDataDirty: boolean = false;
  public orgtherapeuticAreas: TherapeuticArea[] = [];
  public therapeuticAreaHeaderModel : IndSectionHeaderViewDataModel


  @Input() therapeuticAreas: TherapeuticArea[];
  @Output() saveTA: EventEmitter<TherapeuticArea[]> = new EventEmitter();
  @Input() isReadOnlyJointPhoneCall:boolean;
  @Input() backgroundUploadInProgress: boolean;
  @Input() isTARequired: boolean;


  constructor(public activityService: ActivityService,
    public trackingService: TrackService,
    private translate: TranslateService,
    public _cd: ChangeDetectorRef) {
    /**
     * This is static product
     */
      }

  ngOnChanges(changes: SimpleChanges) {
    this.initializeSectionHeader();
    if (changes['therapeuticAreas']) {
      this.isDataDirty = false;
      this.orgtherapeuticAreas = JSON.parse(JSON.stringify(this.therapeuticAreas));
    }
  }

  ngOnInit(){
    this.initializeSectionHeader();
  }

  initializeSectionHeader() {
    this.therapeuticAreaHeaderModel = {
      id: 'therapeuticArea',
      title : `${this.translate.instant('THERAPEUTIC_AREAS_CAPS')}`,
      isRequired: this.isTARequired,
      controls: [
        {
          id: 'save',
          text: this.translate.instant('SAVE'),
          isDisabled: this.backgroundUploadInProgress || !this.isDataDirty  || this.activityService.selectedActivity?.isCompleted || this.isReadOnlyJointPhoneCall,
        }
      ]
    }
  }


  onSaveTA() {
    if (!this.isReadOnlyJointPhoneCall && !this.activityService.teamViewActive && !this.backgroundUploadInProgress) {
      this.isDataDirty = false;
      this.initializeSectionHeader();
      this.saveTA.emit(this.therapeuticAreas);
      this._cd.detectChanges();
    }
  }

// 1 is state of completed phone calls
  get isPhoneCallCompleted () {
    return (this.activityService.selectedActivity.type === ActivityType.PhoneCall || this.activityService.selectedActivity.type === ActivityType.JointPhoneCall ) && this.activityService.selectedActivity.state === 1;
  }

  updateTA(ev, ta: TherapeuticArea) {
    if(this.isReadOnlyJointPhoneCall || this.activityService.teamViewActive || this.backgroundUploadInProgress || this.isPhoneCallCompleted) return;
      if (!ta.isAutoSelected) {
        this.trackingService.tracking('SelectTheraputicAreas', TrackingEventNames.PHONECALL);
        ta.isSelected = !ta.isSelected;
      }

      this.isDataDirty = this.therapeuticAreas.some((val, idx) => {
        return (val.isSelected !== this.orgtherapeuticAreas[idx].isSelected);
      });
      this.initializeSectionHeader();
  }

  /**
 * To reOrder products
 * @param indexes
 */
reorderData(detail:any){
  detail.complete();
 }
}
