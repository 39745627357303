import { TranslateService } from '@ngx-translate/core';
import { NotificationService, ToastStyle } from './../notification/notification.service';
import { UIService } from './../ui/ui.service';
import { DeviceService } from './../device/device.service';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import TRTC from 'trtc-js-sdk';
import { Injectable } from '@angular/core';
import { getLatestUserSig } from '../../../assets/js/tencent/index.js';
import _ from 'lodash';
import { TENCENT_SDK_APP_ID } from '../../../config/endpoints.config';


@Injectable({
  providedIn: 'root',
})
export class TencentService {

  private userSig = '';
  private client = null;
  private userID;
  private roomID;
  public isInitilized = false;
  private isJoining = false;
  private isJoined = false;
  private isLeaving = false;
  // private sdkAppId = 1400638905;
  private isPublishing = false;
  private isPublished = false;
  private isUnPublishing = false;
  private localStream;
  public videoStreams = [];
  public localStreamConfig;
  public remoteStreamConfigList = [];
  private _audioActive = false;
  private _videoActive = false;
  private defaultMic: string;
  private defaultCam: string;

  constructor(
    private readonly androidPermissions: AndroidPermissions,
    private readonly deviceService: DeviceService,
    private readonly uiService: UIService,
    private notif: NotificationService,
    private readonly translate: TranslateService
  ) {
  }

  async initTencentConfig(userID, roomID, videoAllowed, audioAllowed) {
    this.uiService.displayLoader();
    this.isInitilized = false;
    this.isJoining = false;
    this.isJoined = false;
    this.isLeaving = false;
    this.isPublishing = false;
    this.isPublished = false;
    this.isUnPublishing = false;
    this.userID = userID;
    this.roomID = roomID;
    try {
      await this.initMediaDevices();
      await this.handleJoin();
      await this.handlePublish(videoAllowed, audioAllowed);
      if (this.localStream) this.isInitilized = true;
      this.muteAudio();
      this.muteVideo();
    } catch (errror) {
      console.log("Error occurred while initilizing tencent...", errror);
      this.uiService.dismissLoader();
    }
    this.uiService.dismissLoader();
  }

  async initMediaDevices() {
    try {
      if (this.deviceService.deviceFlags.nativeAndroid) {
        const PERMISSION = this.androidPermissions.PERMISSION;
        await this.androidPermissions.requestPermissions([
          PERMISSION.CAMERA,
          PERMISSION.INTERNET,
          PERMISSION.RECORD_AUDIO,
          PERMISSION.WAKE_LOCK,
          PERMISSION.MODIFY_AUDIO_SETTINGS,
        ]);
      } else {
        try {
          const mediaStream = await navigator.mediaDevices.getUserMedia({ audio: true, video: true });
          mediaStream.getTracks()[0].stop();
          const cameras = await TRTC.getCameras();
          this.defaultCam = !_.isEmpty(cameras) ? cameras[0].deviceId : '';
          const mics = await TRTC.getMicrophones();
          this.defaultMic = !_.isEmpty(mics) ? mics[0].deviceId : '';
        } catch (error) {
          console.log("Error", error);
        }

      }
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  async getUserSig() {
    const { userSig } = await getLatestUserSig(this.userID);
    this.userSig = userSig;
  }

  async initClient() {
    await this.getUserSig();
    try {
      this.client = TRTC.createClient({
        mode: 'rtc',
        sdkAppId: _.toNumber(TENCENT_SDK_APP_ID),
        userId: this.userID,
        userSig: this.userSig,
        useStringRoomId: true,
      });
      this.handleClientEvents();
    } catch (err) {
      console.error(err);
    }
    return this.client;
  }

  async handleJoin() {
    if (this.isJoining || this.isJoined) {
      return;
    }
    this.isJoining = true;
    await this.initClient();
    try {
      await this.client.join({ roomId: this.roomID });

      this.isJoining = false;
      this.isJoined = true;
      this.addUser && this.addUser(this.userID, 'local');

      this.startGetAudioLevel();
    } catch (error) {
      this.isJoining = false;
      console.error('join room failed', error);
    }
  }

  async initLocalStream(videoAllowed, audioAllowed) {
    this.localStream = TRTC.createStream({
      audio: audioAllowed,
      video: videoAllowed,
      userId: this.userID,
      cameraId: this.defaultCam,
      microphoneId: this.defaultMic,
      mirror: false,
    });
    try {
      await this.localStream.initialize();
      this.addStream && this.addStream(this.localStream);
      return this.localStream;
    } catch (error) {
      this.localStream = null;
      alert(`${JSON.stringify(error.message)}`);
    }
  }

  async handlePublish(videoAllowed, audioAllowed) {
    if (!this.isJoined || this.isPublishing || this.isPublished) {
      return;
    }
    this.isPublishing = true;
    !this.localStream && (await this.initLocalStream(videoAllowed, audioAllowed));
    try {
      await this.client.publish(this.localStream);

      this.isPublishing = false;
      this.isPublished = true;
    } catch (error) {
      this.isPublishing = false;
      console.error('publish localStream failed', error);
    }
  }

  private startGetAudioLevel() {
    this.client.on('audio-volume', (event) => {
      event.result.forEach(({ userId, audioVolume }) => {
        if (audioVolume > 2) {
          // console.log(`user: ${userId} is speaking, audioVolume: ${audioVolume}`);
          this.updateStreamConfig && this.updateStreamConfig(userId, 'audio-volume', audioVolume);
        } else {
          this.updateStreamConfig && this.updateStreamConfig(userId, 'audio-volume', 0);
        }
      });
    });
    this.client.enableAudioVolumeEvaluation(200);
  }

  handleClientEvents() {
    this.client.on('error', (error) => {
      console.error(error);
      alert(error);
    });
    this.client.on('client-banned', async (event) => {
      console.error(`client has been banned for ${event.reason}`);

      this.isPublished = false;
      this.localStream = null;
      await this.handleLeave();

      alert(event.reason);
    });
    // fired when a remote peer is joining the room
    this.client.on('peer-join', (event) => {
      const { userId } = event;
      console.log(`peer-join ${userId}`, event);
      this.addUser && this.addUser(userId);
    });
    // fired when a remote peer is leaving the room
    this.client.on('peer-leave', (event) => {
      const { userId } = event;
      console.log(`peer-leave ${userId}`, event);
      this.removeUser && this.removeUser(userId);
    });

    // fired when a remote stream is added
    this.client.on('stream-added', (event) => {
      const { stream: remoteStream } = event;
      const remoteUserID = remoteStream.getUserId();
      if (remoteUserID === `share_${this.userID}`) {
        // don't need screen shared by us
        this.handleUnSubscribe(remoteStream);
      } else {
        console.log(`remote stream added: [${remoteUserID}] type: ${remoteStream.getType()}`);
        // subscribe to this remote stream
        this.handleSubscribe(remoteStream);
        this.addStream && this.addStream(remoteStream);
      }
    });
    // fired when a remote stream has been subscribed
    this.client.on('stream-subscribed', (event) => {
      const { stream: remoteStream } = event;
      console.log('stream-subscribed userId: ', remoteStream.getUserId());
    });
    // fired when the remote stream is removed, e.g. the remote user called Client.unpublish()
    this.client.on('stream-removed', (event) => {
      const { stream: remoteStream } = event;
      remoteStream.stop();
      this.removeStream && this.removeStream(remoteStream);
      console.log(`stream-removed userId: ${remoteStream.getUserId()} type: ${remoteStream.getType()}`);
    });

    this.client.on('stream-updated', (event) => {
      const { stream: remoteStream } = event;
      this.updateStream && this.updateStream(remoteStream);
      console.log(`type: ${remoteStream.getType()} stream-updated hasAudio: ${remoteStream.hasAudio()} hasVideo: ${remoteStream.hasVideo()}`);
    });

    this.client.on('mute-audio', (event) => {
      const { userId } = event;
      console.log(`${userId} mute audio`);
      this.updateStreamConfig && this.updateStreamConfig(userId, 'mute-audio');
    });
    this.client.on('unmute-audio', (event) => {
      const { userId } = event;
      console.log(`${userId} unmute audio`);
      this.updateStreamConfig && this.updateStreamConfig(userId, 'unmute-audio');
    });
    this.client.on('mute-video', (event) => {
      const { userId } = event;
      console.log(`${userId} mute video`);
      this.updateStreamConfig && this.updateStreamConfig(userId, 'mute-video');
    });
    this.client.on('unmute-video', (event) => {
      const { userId } = event;
      console.log(`${userId} unmute video`);
      this.updateStreamConfig && this.updateStreamConfig(userId, 'unmute-video');
    });

    this.client.on('connection-state-changed', (event) => {
      // console.log(`RtcClient state changed to ${event.state} from ${event.prevState}`);
    });

    this.client.on('network-quality', (event) => {
      const { uplinkNetworkQuality, downlinkNetworkQuality } = event;
      // console.log(`network-quality uplinkNetworkQuality: ${uplinkNetworkQuality}, downlinkNetworkQuality: ${downlinkNetworkQuality}`);
      // this.updateStreamConfig && this.updateStreamConfig(this.userID, 'uplink-network-quality', uplinkNetworkQuality);
      // this.updateStreamConfig && this.updateStreamConfig(this.userID, 'downlink-network-quality', downlinkNetworkQuality);
    });
  }

  private addUser(userID, streamType?) {
    if (streamType === 'local') {
      this.localStreamConfig = {
        stream: null,
        streamType,
        userID,
        hasAudio: false,
        hasVideo: false,
        mutedAudio: false,
        mutedVideo: false,
        shareDesk: false,
        audioVolume: 0,
      };
    } else {
      this.remoteStreamConfigList = this.remoteStreamConfigList.length > 0 ? this.remoteStreamConfigList.filter(streamConfig => streamConfig.userID !== userID) : [];
      this.remoteStreamConfigList.push({
        stream: null,
        streamType: 'remote',
        userID,
        hasAudio: false,
        hasVideo: false,
        mutedAudio: false,
        mutedVideo: false,
        subscribedAudio: false,
        subscribedVideo: false,
        resumeFlag: false,
        audioVolume: 0,
      });
    }
  }

  private addStream(stream) {
    const streamType = stream.getType();
    const userID = stream.getUserId();
    switch (streamType) {
      case 'local':
        this.localStreamConfig = {
          stream,
          streamType,
          userID,
          hasAudio: true,
          hasVideo: true,
          mutedAudio: false,
          mutedVideo: false,
          shareDesk: false,
          audioVolume: 0,
        };
        break;
      default:
        this.remoteStreamConfigList = this.remoteStreamConfigList.length > 0
          ? this.remoteStreamConfigList.filter(streamConfig => !(streamConfig.userID === userID)) : [];
        this.remoteStreamConfigList
          .push({
            stream,
            streamType: 'remote',
            userID,
            hasAudio: stream.hasAudio(),
            hasVideo: stream.hasVideo(),
            mutedAudio: false,
            mutedVideo: false,
            subscribedAudio: true,
            subscribedVideo: true,
            resumeFlag: false,
            audioVolume: 0,
          });
        break;
    }

  };

  async handleLeave() {
    if (!this.isJoined || this.isLeaving) {
      return;
    }
    this.isLeaving = true;
    this.stopGetAudioLevel();
    if (this.isPublished) {
      await this.handleUnPublish();
    }
    try {
      await this.client.leave();
      console.info('leave room success');
      this.removeUser && this.removeUser(this.userID, 'local');
      this.isLeaving = false;
      this.isJoined = false;
    } catch (error) {
      this.isLeaving = false;
      console.error('leave room error', error);
    }
    this._audioActive = this._videoActive = false;
  }
  private stopGetAudioLevel() {
    this.client && this.client.enableAudioVolumeEvaluation(-1);
  }

  private removeUser(userID, streamType?) {
    if (streamType === 'local') {
      this.localStreamConfig = null;
      this.remoteStreamConfigList = [];
    } else {
      this.remoteStreamConfigList = this.remoteStreamConfigList.filter(streamConfig => streamConfig.userID !== userID);
    }
  }

  async handleUnPublish() {
    if (!this.isPublished || this.isUnPublishing) {
      return;
    }
    this.isUnPublishing = true;
    try {
      await this.client.unpublish(this.localStream);
      this.isUnPublishing = false;
      this.isPublished = false;
      console.info('unpublish localStream success');
    } catch (error) {
      this.isUnPublishing = false;
      console.error('unpublish localStream failed', error);
      switch (error.getCode()) {
        case 4096: // ErrorCode = 0x1001 INVALID_OPERATION
          console.error('stream has not been published yet, please publish first', 2000);
          break;
        case 4097: // ErrorCode = 0x1001 INVALID_PARAMETER
          console.error('publish is ongoing, please try unpublish later', 2000);
          break;
        default:
          console.error('unpublish localStream failed! please try again later', 2000);
          break;
      }
    }
    this.localStream && (await this.destroyLocalStream());
  }

  private destroyLocalStream() {
    this.removeStream && this.removeStream(this.localStream);
    this.localStream && this.localStream.stop();
    this.localStream && this.localStream.close();
    this.localStream = null;
    console.info(`Local Stream destroyed`);
  }

  private removeStream(stream) {
    const streamType = stream.getType();
    const userID = stream.getUserId();
    switch (streamType) {
      case 'local':
        this.localStreamConfig.hasAudio = false;
        this.localStreamConfig.hasVideo = false;
        break;
      default: {
        let idx = this.remoteStreamConfigList.findIndex(item => item.userID === stream.getUserId());
        if (idx > -1) {
          this.remoteStreamConfigList[idx].hasAudio = false;
          this.remoteStreamConfigList[idx].hasVideo = false;
          this.remoteStreamConfigList[idx].subscribedAudio = false;
          this.remoteStreamConfigList[idx].subscribedVideo = false;
        }
        break;
      }
    }
    console.info(`Stream removed ${streamType} - ${userID}`);
  }

  private updateStream(stream) {
    if (stream.getType() === 'local') {
      this.localStreamConfig.stream = stream;
      this.localStreamConfig.hasAudio = stream.hasAudio();
      this.localStreamConfig.hasVideo = stream.hasVideo();
    } else {
      let index = this.remoteStreamConfigList.findIndex(item => item.userID === stream.getUserId());
      if (index > -1) {
        this.remoteStreamConfigList[index].stream = stream;
        this.remoteStreamConfigList[index].hasAudio = stream.hasAudio();
        this.remoteStreamConfigList[index].hasVideo = stream.hasVideo();
      }
    }
  };

  private updateStreamConfig(userID, type, value?) {
    if (this.localStreamConfig && this.localStreamConfig.userID === userID) {
      switch (type) {
        case 'audio-volume':
          if (this.localStreamConfig.audioVolume === value) {
            break;
          }
          this.localStreamConfig.audioVolume = value;
          break;
        case 'share-desk':
          this.localStreamConfig.shareDesk = value;
          break;
        case 'uplink-network-quality':
          this.localStreamConfig.uplinkNetworkQuality = value > 0 ? 6 - value : value;
          break;
        case 'downlink-network-quality':
          this.localStreamConfig.downlinkNetworkQuality = value > 0 ? 6 - value : value;
          break;
        case 'mute-audio':
          this.localStreamConfig.mutedAudio = true;
          break;
        case 'unmute-audio':
          this.localStreamConfig.mutedAudio = false;
          break;
        case 'mute-video':
          this.localStreamConfig.mutedVideo = true;
          break;
        case 'unmute-video':
          this.localStreamConfig.mutedVideo = false;
          break;
        default:
          break;
      }
      return;
    }
    let index = this.remoteStreamConfigList.findIndex(item => item.userID === userID);
    if (index > -1) {
      switch (type) {
        case 'mute-audio':
          this.remoteStreamConfigList[index].mutedAudio = true;
          break;
        case 'unmute-audio':
          this.remoteStreamConfigList[index].mutedAudio = false;
          break;
        case 'mute-video':
          this.remoteStreamConfigList[index].mutedVideo = true;
          break;
        case 'unmute-video':
          this.remoteStreamConfigList[index].mutedVideo = false;
          break;
        case 'resume-stream':
          this.remoteStreamConfigList[index].resumeFlag = true;
          break;
        case 'audio-volume':
          if (this.remoteStreamConfigList[index].audioVolume === value) {
            break;
          }
          this.remoteStreamConfigList[index].audioVolume = value;
          break;
        default:
          break;
      }
    }
    
  }

  async handleSubscribe(remoteStream, config = { audio: true, video: true }) {
    try {
      await this.client.subscribe(remoteStream, {
        audio: config.audio ? true : config.audio,
        video: config.video ? true : config.video,
      });
    } catch (error) {
      console.error(`subscribe ${remoteStream.getUserId()} with audio: ${config.audio} video: ${config.video} error`, error);
    }
  }

  async handleUnSubscribe(remoteStream) {
    try {
      await this.client.unsubscribe(remoteStream);
    } catch (error) {
      console.error(`unsubscribe ${remoteStream.getUserId()} error`, error);
    }
  }

  muteVideo(notify?: boolean) {
    this.localStream && this.localStream.muteVideo();
    this.updateStreamConfig(this.userID, 'mute-video');
    if(notify) this.notif.notify(this.translate.instant('YOUR_DEVICE_VIDEO_CAMERA_DISABLED'), 'activity-details', 'top', ToastStyle.INFO);
    this._videoActive = false;
  }

  muteAudio(notify?: boolean) {
    this.localStream && this.localStream.muteAudio();
    this.updateStreamConfig(this.userID, 'mute-audio');
    if (notify) this.notif.notify(this.translate.instant('YOUR_DEVICE_MICROPHONE_MUTED'), 'activity-details', 'top', ToastStyle.INFO);
    this._audioActive = false;
  }

  unmuteVideo() {
    this.localStream && this.localStream.unmuteVideo();
    this.updateStreamConfig(this.userID, 'unmute-video');
    this.notif.notify(this.translate.instant('YOUR_ARE_NOW_SENDING_VIDEO'), 'activity-details', 'top', ToastStyle.INFO);
    this._videoActive = true;
  }

  unmuteAudio() {
    this.localStream && this.localStream.unmuteAudio();
    this.updateStreamConfig(this.userID, 'unmute-audio');
    this.notif.notify(this.translate.instant('YOU_ARE_NOW_SENDIING_AUDIO'), 'activity-details', 'top', ToastStyle.INFO);
    this._audioActive = true;
  }

  get audioActive() {
    return this._audioActive;
  }

  set audioActive(value) {
    this._audioActive = value;
  }

  get videoActive() {
    return this._videoActive;
  }

  set videoActive(value) {
    this._videoActive = value;
  }

}
