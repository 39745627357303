import { Component, OnInit } from '@angular/core';
import { ContactOfflineService } from '../../../services/contact/contact.service';
import { ComponentViewMode, UIService } from '../../../services/ui/ui.service';
import { ActivityService } from '../../../services/activity/activity.service';
import { DeviceService } from '../../../services/device/device.service';

import { Events } from '@omni/events';
import { NavigationService, PageName } from '../../../services/navigation/navigation.service';
import { TrackService, TrackingEventNames } from '../../../services/logging/tracking.service';
import { ContactDetailsComponent } from '../contact-details/contact-details';
import { ContactDataService } from '../../../data-services/contact/contact.data.service';
import { RepServices } from '../../../data-services/rep/rep.services';
import { Contact } from '../../../classes/contact/contact.class';
import {GlobalUtilityService} from "../../../services/global-utility.service";
import { TranslateService } from '@ngx-translate/core';
import { NotificationService, ToastStyle } from '../../../services/notification/notification.service';
import { FooterService } from '../../../services/footer/footer.service';
import { FooterViews } from '../../../services/footer/footer.service';
import { ActivityType } from '../../../classes/activity/activity.class';
import { IndHeaderLeftComponent } from '@omni/components/shared/ind-header-left/ind-header-left';
import { IndHeaderLeftDataModel } from '@omni/models/indHeaderLeftDataModel';

/**
 * Generated class for the ContactListComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'contact-list-ro',
  templateUrl: 'contact-list-ro.html',
  styleUrls:['contact-list-ro.scss']
})
export class ContactListROComponent implements OnInit{

  public compViewMode = ComponentViewMode;
  indHeaderLeftModel: IndHeaderLeftDataModel;
  contactSearchText = '';

  constructor(
    public repService: RepServices,
    public contactService: ContactOfflineService,
    private contactDataService: ContactDataService,
    public activityService: ActivityService,
    private device: DeviceService,
    private navService: NavigationService,
    private trackingService: TrackService,
    public events: Events,
    private uiService: UIService,
    public utilityService: GlobalUtilityService,
    private translate: TranslateService,
    private notificationService: NotificationService,
    private footerService: FooterService
    ) {

  }

  ngOnInit(){
    this.initHeaderDetails();
  }
  ionViewWillEnter(){
    this.disableSearchBar()
  }


  disableSearchBar() {

    if (document.getElementById("contact-searchbar"))
   {
    const inputs: any = document.getElementById("contact-searchbar").getElementsByTagName("input");
    inputs[0].disabled =  true
   }

  }

  onCloseModal(){
    this.uiService.showNewActivity = false;
    if(this.uiService.activitiesPageTab !== 'plans') this.uiService.activeView = this.uiService.prevView;
    if(this.device.isMobileDevice){
      if (this.navService.getActiveChildNavViewPageName() == PageName.CustomerXperiencesComponent) {
        this.uiService.showRightPane = false;
      }
      this.navService.popWithPageTracking();
      if (this.uiService.prevView === ActivityType.Appointment) {
        this.footerService.initButtons(FooterViews.Activities);
      }
      else if(this.uiService.prevView === 'PhoneCall'){
        this.footerService.initButtons(FooterViews.PHONE_CALL);
      }
    }
  }
  async openDetails(contact:Contact){

    if(!contact.isActive){
      this.notificationService.notify(this.translate.instant('CONTACT_NO_LONGER_ACCESS'),'Contact List Read Only','top',ToastStyle.DANGER);
      return;
    }
    const insightsEventName = this.contactService.contactPageMode === ComponentViewMode.ADDNEW? 'Contact INFO via Meeting': 'ContactInfo'
    this.trackingService.tracking(insightsEventName,TrackingEventNames.CONTACTS)
    this.uiService.showNewActivity = false;
    this.uiService.activeView = 'contactDetails';
    this.uiService.contactDetailsSegment = 'info';
    this.contactService.contactPageMode = ComponentViewMode.READONLY;
    this.contactService.isContactDetailsLoaded = false;

    // let loading = this.loader.create({content: 'Loading...'});
    // loading.present();
    this.contactService.contactInformation = this.contactService.contacts.find(ele => ele.ID === contact.ID); //contact;
    if(this.device.isMobileDevice && (this.navService.getCurrentPageName() == PageName.ContactListROComponent)){
      this.navService.pushWithPageTracking(ContactDetailsComponent, PageName.ContactDetailsComponent);
    }

    await this.contactDataService.getContactDetails(contact);
  }

  initHeaderDetails() {
    this.indHeaderLeftModel = {
      id: 'customer-ro-header-left',
      cssClass: 'main-tool-header-title',
      title: this.translate.instant(this.utilityService.globalCustomerText),
      customHeaderProps:{ hasCancel:  this.contactService.contactPageMode === this.compViewMode.READONLY},
      controls: [
        {
          id: 'close',
          icon: 'chevron-back-outline',
          isDisabled: false,
          align: 'left'
        }
      ],
    };
  }

  onPageTitleControlClick() {
    this.onCloseModal();
  }
}
