import { Component, OnInit } from '@angular/core';
import { UIService } from '../../../services/ui/ui.service';
import { NavigationService, PageName } from '../../../services/navigation/navigation.service';
import { DeviceService } from '../../../services/device/device.service';
import { LanguageSettingsComponent } from '../../app-language-settings/language-setting-page/app-language-settings';
import { TrackService, TrackingEventNames } from '../../../services/logging/tracking.service';
import { LocalizationService, Language } from '../../../services/localization/localization.service';
import { Subscription } from 'rxjs';
import {DateFormatsPage} from "../../../pages/settings/date-formats-page";
import {DateTimeFormatsService} from "../../../services/date-time-formats/date-time-formats.service";
import {PopoverController} from "@ionic/angular";
import {PopoverComponent} from "../../popover/popover";
import _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { IndPageTitleViewDataModel } from '../../../models/indPageTitleDataModel';
import { FooterService, FooterViews } from './../../../services/footer/footer.service';
import { IndSectionHeaderViewDataModel } from '@omni/models/indSectionHeaderDataModel';
import { EventsService } from '../../../services/events/events.service';
import { registerLocaleData } from '@angular/common';
import fr from '@angular/common/locales/fr';
import es from '@angular/common/locales/es';
import en from '@angular/common/locales/en';
import ja from '@angular/common/locales/ja';
import de from '@angular/common/locales/de';
import nl from '@angular/common/locales/nl';
import zh_CN from '@angular/common/locales/zh';
import { AuthenticationService } from '../../../services/authentication.service';
import { SearchConfigService } from '../../../services/search/search-config.service';
import { FeatureActionsMap } from '@omni/classes/authentication/user.class';
import { CustomerReplaceText, Utility } from '@omni/utility/util';

/**
 * Generated class for the GeneralSettingsComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'general-settings',
  templateUrl: 'general-settings.html',
  styleUrls:['general-settings.scss']
})
export class GeneralSettingsComponent implements OnInit {

  text: string;
  language: Language;
  dateFormat: string = '';
  timeFormat: string = '';
  symbolPos: string = '';
  decimalPos: string = '';
  languageSubjectSubscription: Subscription;
  dateFormatSubscription: Subscription;
  timeFormatSubscription: Subscription;
  symbolPosSubscription: Subscription;
  decimalPosSubscription: Subscription;
  private popover: HTMLIonPopoverElement;
  generalSettingsPageTitle: IndPageTitleViewDataModel;
  regionalHeader: IndSectionHeaderViewDataModel;
  dateTimeicHeader: IndSectionHeaderViewDataModel;
  currencyHeader: IndSectionHeaderViewDataModel;
  // private defaultLanguage: Language = { code: 'en', name: 'English', subName:'english' };


  constructor(
    public uiService: UIService,
    public navService: NavigationService,
    public device: DeviceService,
    private trackingService: TrackService,
    public languageService: LocalizationService,
    public dateTimeFormatsService: DateTimeFormatsService,
    public popoverCtrl: PopoverController,
    private translate : TranslateService,
    public footerService: FooterService,
    private events: EventsService,
    private authService: AuthenticationService,
    private searchConfigService: SearchConfigService
  ) {
    this.dateFormat = this.dateTimeFormatsService.selectedFormat.display;
    this.timeFormat = this.dateTimeFormatsService.selectedTimeFormat.format;
    this.symbolPos = this.dateTimeFormatsService.selectedSymbolPos.value;
    this.decimalPos = this.dateTimeFormatsService.selectedDecimalPos.value;
    this.initHeader();
  }

  ngOnInit() {

    this.footerService.initButtons(FooterViews.APP_SETTINGS_GEN_SETTINGS);

    this.getLanguageDetails();
    this.getDateTimeCurrencySettings();

    this.languageSubjectSubscription = this.languageService.language.subscribe(lang => {
      this.language = lang;
      // this.languageService.setLanguage(lang);
      this.languageService.applyLangaugeSelected(lang);
    });

    this.dateFormatSubscription = this.dateTimeFormatsService.dateFormat.subscribe( df => {
      if (df) {
        this.dateFormat = df;
      }
    });
    this.timeFormatSubscription = this.dateTimeFormatsService.timeFormat.subscribe( tf => {
      if (tf) {
        this.timeFormat = tf;
      }
    });
    this.symbolPosSubscription = this.dateTimeFormatsService.symbolPos.subscribe( sp => {
      if (sp) {
        this.symbolPos = sp;
      }
    });
    this.decimalPosSubscription = this.dateTimeFormatsService.decimalPos.subscribe( dp => {
      if (dp) {
        this.decimalPos = dp;
      }
    });
    this.initGeneralSettingsPageTitle();
    this.translate.onLangChange.subscribe(data =>{
      this.translate.use(data.lang);
      this.initGeneralSettingsPageTitle();
      this.initHeader();
    });

    this.events.subscribe('AgendaRefreshDone',()=> {
      this.uiService.dismissLoader();
    })

    this.translate.onLangChange.subscribe((data) => {
        this.languageService.setGlobalCustomerTextAfterTranlation();
    });
  }


  // private async applyLangaugeSelected() {
  //   await this.uiService.displayLoader();
  //   switch (this.language.code) {
  //     case 'fr':
  //       registerLocaleData(fr);
  //       break;
  //     case 'es':
  //       registerLocaleData(es);
  //       break;
  //     case 'ja':
  //       registerLocaleData(ja);
  //       break;
  //     case 'de':
  //       registerLocaleData(de);
  //       break;
  //     case 'nl':
  //       registerLocaleData(nl);
  //       break;
  //     default:
  //       registerLocaleData(en);
  //       break;
  //   }
  //   this.setGlobalCustomerTextAfterTranlation()
  //   this.uiService.agendaRefreshRequired = true;
  //   await this.translate.use(this.language.code);
  //   this.searchConfigService.configUpdateRequired = true;
  // }

  // private setGlobalCustomerTextAfterTranlation() {
  //   if (this.authService.hasFeatureAction(FeatureActionsMap.CONTACT_UPDATE)) {
  //     Utility.globalCustomerText = CustomerReplaceText.GLOBAL_STAKEHOLDER_REPLACE_TEXT;
  //   } else {
  //     Utility.globalCustomerText = CustomerReplaceText.GLOBAL_CUSTOMER_REPLACE_TEXT
  //   }
  // }

  initHeader(){
    this.regionalHeader = {
      id:'diagnostic-header',
      title: this.translate.instant('SETTINGS_REGIONAL_CONTROLS'),
      controls: [],
    };

    this.dateTimeicHeader = {
      id:'diagnostic-header',
      title: this.translate.instant('SETTINGS_DATE_TIME'),
      controls: [],
    };

    this.currencyHeader = {
      id:'diagnostic-header',
      title: this.translate.instant('SETTINGS_CURRENCY'),
      controls: [],
    };
  }
  getLanguageDetails() {
    let languageCode = localStorage.getItem('selectedLanguage') ? localStorage.getItem('selectedLanguage') : (typeof navigator !== 'undefined' ? navigator.language : 'en');
    let languagesList = this.languageService.getLanguages();
    let index = _.findIndex(languagesList, {code : languageCode});
    if(index != -1) {
      this.language = languagesList[index];
    }
    else {
      this.language = this.languageService.defaultLanguage;
    }
    this.languageService.setLanguage(this.language);
  }

  getDateTimeCurrencySettings() {
    let selectedDateFormat = localStorage.getItem('selectedDateFormat') ? JSON.parse(localStorage.getItem('selectedDateFormat')) : this.dateTimeFormatsService.selectedFormat;
    let selectedTimeFormat = localStorage.getItem('selectedTimeFormat') ? JSON.parse(localStorage.getItem('selectedTimeFormat')) : this.dateTimeFormatsService.selectedTimeFormat;
    let selectedSymbolPosition = localStorage.getItem('selectedSymbolPosition') ? JSON.parse(localStorage.getItem('selectedSymbolPosition')) : this.dateTimeFormatsService.selectedSymbolPos;
    let selectedDecimalPosition = localStorage.getItem('selectedDecimalPosition') ? JSON.parse(localStorage.getItem('selectedDecimalPosition')) : this.dateTimeFormatsService.selectedDecimalPos;
    this.dateTimeFormatsService.setDateFormat(selectedDateFormat);
    this.dateTimeFormatsService.setTimeFormat(selectedTimeFormat);
    this.dateTimeFormatsService.setSymbolPos(selectedSymbolPosition);
    this.dateTimeFormatsService.setDecimalPos(selectedDecimalPosition);
  }

  ngOnDestroy() {
    this.languageSubjectSubscription.unsubscribe();
    this.dateFormatSubscription.unsubscribe();
    this.timeFormatSubscription.unsubscribe();
    this.symbolPosSubscription.unsubscribe();
    this.decimalPosSubscription.unsubscribe();
    this.events.unsubscribe('AgendaRefreshDone');
  }

  public onClosePage() {
    this.uiService.showRightPane = false;
    this.navService.setChildNavRightPaneView(false);
  }

  openLanguageList() {
    if(this.device.isOffline) return;
    this.trackingService.tracking('LanguageSettingPage', TrackingEventNames.LANGUAGESETTING);
    this.navService.pushWithPageTracking(LanguageSettingsComponent, PageName.LanguageSettingsComponent, {}, PageName.LanguageSettingsComponent);
  }

  openDateFormatList() {
    this.navService.pushWithPageTracking(DateFormatsPage, PageName.DateFormatsPage, {}, PageName.DateFormatsPage);
  }

  async openTimeFormatList(evt) {
    if(!this.popover){
      this.popover = await this.popoverCtrl.create({component: PopoverComponent,
                                                    componentProps: { timeFormats: this.dateTimeFormatsService.timeFormats(), field:'global-time-formats' },
                                                    event:evt})
      this.popover.onDidDismiss().then((data:any)=>{
        if(data && data.data){
          localStorage.setItem("selectedTimeFormat", JSON.stringify(data.data.timeFormat));
          this.dateTimeFormatsService.setTimeFormat(data.data.timeFormat);
          this.dateTimeFormatsService.timeFormat.next(this.dateTimeFormatsService.selectedTimeFormat.format);
        }
        this.popover = undefined;
      });
      this.popover.present();
    }
  }

  async openSymbolPositionList(evt) {
    if(!this.popover){
      this.popover = await this.popoverCtrl.create({
          component: PopoverComponent,componentProps: { symbolPositions: this.dateTimeFormatsService.symbolPositions(), field:'global-symbol-positions' },
        event:evt});
      this.popover.onDidDismiss().then((data:any)=>{
        if(data && data.data){
          localStorage.setItem("selectedSymbolPosition", JSON.stringify(data.data.symbolPos));
          this.dateTimeFormatsService.setSymbolPos(data.data.symbolPos);
          this.dateTimeFormatsService.symbolPos.next(this.dateTimeFormatsService.selectedSymbolPos.value);
        }
        this.popover = undefined;
      });
      this.popover.present();
    }
  }

  async openDecimalPositionList(evt) {
    if(!this.popover){
      this.popover = await this.popoverCtrl.create({
                                                    component: PopoverComponent,
                                                    componentProps: { decimalPositions: this.dateTimeFormatsService.decimalPositions(), field:'global-decimal-positions' },
                                                    event:evt});
      this.popover.onDidDismiss().then((data:any)=>{
        if(data && data.data){
          localStorage.setItem("selectedDecimalPosition", JSON.stringify(data.data.decimalPos));
          this.dateTimeFormatsService.setDecimalPos(data.data.decimalPos);
          this.dateTimeFormatsService.decimalPos.next(this.dateTimeFormatsService.selectedDecimalPos.value);
        }
        this.popover = undefined;
      });
      this.popover.present();
    }
  }

  private initGeneralSettingsPageTitle(): void {
    this.generalSettingsPageTitle = {
      id: 'general-settings-page-title',
      title: this.translate.instant('SETTINGS_GENERAL_SETTINGS'),
      controls: [],
    };
  }

  public onPageTitleControlClick(id: string) {
    switch (id) {
      case 'close':
        this.onClosePage();
        break;
      default:
        console.log("Unhandled switch case statement");
        break;
    }
  }
}

