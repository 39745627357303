import { CdkTableModule } from '@angular/cdk/table';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BuildInfo } from '@ionic-native/build-info/ngx';
import { ChartModule } from 'angular-highcharts';
import { ServerErrorsInterceptor } from './services/error-handler/server-error.interceptor';
import { GlobalErrorHandler } from './services/error-handler/error-handler-service';
import { FileManagerEffects } from './store/io-file-service/effects/file.effect';
import { ResourceManagerEffects } from './store/io-file-service/effects/resource.effect';

import { ErrorHandler, Injectable, NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import {
  BrowserModule,
  HAMMER_GESTURE_CONFIG,
  HammerGestureConfig,
  HammerModule
} from '@angular/platform-browser';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { TextToSpeech } from '@ionic-native/text-to-speech/ngx';
import { IonicModule } from '@ionic/angular';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { MyApp } from './app.component';

import { SpeechRecognition } from '@ionic-native/speech-recognition/ngx';

import { Network } from '@ionic-native/network/ngx';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { Geolocation } from '@ionic-native/geolocation/ngx';
import { NativeGeocoder } from '@ionic-native/native-geocoder/ngx';

//Resources

import { FileTransfer } from '@ionic-native/file-transfer/ngx';
import { File } from '@ionic-native/file/ngx';
import { Zip } from '@ionic-native/zip/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { appReducers } from './store/application.reducer';
import { INITIAL_APP_STATE } from './store/application.state';

import { Clipboard } from '@ionic-native/clipboard/ngx';
import { NativePageTransitions } from '@ionic-native/native-page-transitions/ngx';

import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
// idle module and moment js stuff
import { MomentModule } from 'angular2-moment'; // optional, provides moment-style pipes for date formatting

import { Deeplinks } from '@ionic-native/deeplinks/ngx';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { Diagnostic } from '@ionic-native/diagnostic/ngx';
import {
  SecureStorage,
} from '@ionic-native/secure-storage/ngx';

//

import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { IonicRouteStrategy } from '@ionic/angular';

// import { LaunchDarklyProvider } from './providers/launch-darkly/launch-darkly';

//

import { MSAdal } from '@ionic-native/ms-adal/ngx';
import {
  TranslateLoader,
  TranslateModule,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import Hammer from 'hammerjs';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@Injectable()
export class hammerConfig extends HammerGestureConfig {
  overrides = {
    swipe: { velocity: 0.4, threshold: 20, direction: Hammer.DIRECTION_ALL}, // override default settings
  } as any;
}

import { Device } from '@ionic-native/device/ngx';
import { DragulaModule } from 'ng2-dragula';


import { AgmCoreModule } from '@agm/core';
import { VirtualListModule } from './components/angular-virtual-list';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';

import { SignaturePadModule } from 'angular2-signaturepad';
import { TextMaskModule } from 'angular2-text-mask';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { AppInsightsService, ApplicationInsightsModule } from '@markpieszak/ng-application-insights';
import { MbscEventcalendarModule } from '@mobiscroll/angular';
import { DxSchedulerModule } from 'devextreme-angular/ui/scheduler';
import { StarRatingModule } from 'ionic5-star-rating';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { OfflineSyncUtility } from './utility/offline-sync.utility';
import { components, pipes } from './components';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { PaginatorModule } from 'primeng/paginator';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { OpentokModule } from './components/opentok/ot.module';
import { DatePipe } from '@angular/common';
import { CalendarModule } from 'ion2-calendar';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { TooltipModule } from 'ng2-tooltip-directive';
import { QRCodeModule } from 'angularx-qrcode';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MbscModule } from '@mobiscroll/angular';
import { CameraPreview } from '@ionic-native/camera-preview/ngx';

@NgModule({
  declarations: [...components, ...pipes],
  imports: [
    MbscModule,
    ScrollingModule,
    OpentokModule,
    ReactiveFormsModule,
    VirtualListModule,
    PdfJsViewerModule,
    PdfViewerModule,
    VirtualScrollerModule,
    FilterPipeModule,
    TranslateModule, // translate Module
    SignaturePadModule,
    TextMaskModule,
    DragDropModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBZWVP70SzhmgJOLPEbaFKWwZhOQu4dGEc', // THIS SHOULD BE THE APPLICATION API KEY PROVIDED BY DEV OPS
      libraries: ['places'],
    }),
    StarRatingModule,
    DragulaModule,
    DxSchedulerModule,
    MbscEventcalendarModule,
    TranslateModule, // translate Module
    RouterModule.forRoot([]),
    FormsModule,
    IonicModule.forRoot({animated: false, mode: 'ios', swipeBackEnabled: false, _forceStatusbarPadding: true }),
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MomentModule,
    FontAwesomeModule,
    ChartModule,
    CdkTableModule,
    HammerModule,
    PaginatorModule,
    StoreModule.forRoot(appReducers, { initialState: INITIAL_APP_STATE }),
    EffectsModule.forRoot([FileManagerEffects, ResourceManagerEffects]),
    ApplicationInsightsModule.forRoot({
        instrumentationKeySetlater: true
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    DragulaModule.forRoot(),
    EditorModule,
    CalendarModule.forRoot({
      doneLabel: 'Save',
      closeIcon: true,
      canBackwardsSelected: true,
    }),
    NgxMaterialTimepickerModule,
    // ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    TooltipModule.forRoot({
      'placement': 'bottom',
      'show-delay': 500,
      'theme': 'light',
      'displayTouchscreen': false,
      'tooltip-class': 'custom-tooltip',
      'offset':4
    }),
    QRCodeModule
  ],
  bootstrap: [MyApp],
  providers: [
    CameraPreview,
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ServerErrorsInterceptor,
      multi: true,
    },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    StatusBar,
    BuildInfo,
    Network,
    SpeechRecognition,
    DatePipe,
    Geolocation,
    NativeGeocoder,
    AppInsightsService,
    File,
    Zip,
    Deeplinks,
    SecureStorage,
    FileTransfer,
    FileOpener,
    Clipboard,
    NativePageTransitions,
    InAppBrowser,
    Diagnostic,
    ScreenOrientation,
    ErrorHandler,
    //{ provide: ErrorHandler, useClass: IonicErrorHandler },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },

    AndroidPermissions,

    // LaunchDarklyProvider,

    OfflineSyncUtility,
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: hammerConfig
    },

    TextToSpeech,

    Device,
    MSAdal,
    DatePipe,
    SocialSharing,
    { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' },
    Keyboard
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class AppModule {
  constructor(){
    console.log('module');
  }
}
