import { AccountOfflineService } from './../../../../services/account/account.offline.service';
import { Component, Input } from "@angular/core";
import { TranslateService } from '@ngx-translate/core';
import { MdmService } from '@omni/services/mdm/mdm.service';
import {NavigationService, PageName} from "@omni/services/navigation/navigation.service";
import {DeviceService} from "@omni/services/device/device.service";
import {AccountCrDetailsComponent} from "@omni/components/mdm/account/account-cr-details/account-cr-details";
import {UIService} from "@omni/services/ui/ui.service";
import { NavParams } from '@ionic/angular';


@Component({
  selector: 'account-cr',
  templateUrl: 'account-cr.html',
  styleUrls: ['account-cr.scss']
})
export class AccountCrComponent {

  @Input() source: any[];
  public selected: any = null;

  constructor(
    public mdmService: MdmService,
    private navService: NavigationService,
    private device: DeviceService,
    private uiService: UIService,
    public translate: TranslateService,
    public navParams: NavParams,
    private accountService: AccountOfflineService,
  ) {
  }

  ngOnInit() {
    if (this.navParams && this.navParams.data && this.navParams.get('openedToolFromCrDetails') && this.navParams.get('openedToolFromCrDetails') === true) {
      const selectedAccountCr = this.accountService.selectedAccountCr;
      if (selectedAccountCr) this.viewDetails(selectedAccountCr);
      this.accountService.selectedAccountCr = '';
    }
  }

  async viewDetails(selected) {
    let currentData;
    if (this.device.isOffline) {
      currentData = selected;
    }
    else {
      try {
        await this.uiService.displayLoader();
        await this.mdmService.fetchAccountCRConfiguredDisplay(false, false, selected).then((res) => {
          if (res && Array.isArray(res)) {
            currentData = res[0];
          }
          else {
            currentData = res;
          }
          console.log(currentData);
          this.uiService.dismissLoader();
        });

      }catch (e) {
        console.log("error occurred fetching accountCR online: ", e);
        await this.uiService.dismissLoader();
      }
    }
    this.selected = currentData;
    this.mdmService.currentSelected.next(currentData);
    this.navService.setChildNavRightPaneView(true);
    this.uiService.showNewActivity = false;
    await this.navService.pushChildNavPageWithPageTracking(AccountCrDetailsComponent, PageName.AccountCrDetailsComponent, PageName.AccountPageComponent, {
      from: 'Account'
    });
  }

}
