import { CustomerCallPlanPage } from './../../customer-call-plans/customer-call-plan-page/customer-call-plan-page';
import { FieldMaterialManagementPageComponent } from "@omni/components/field-material-management/field-material-management-page/field-material-management-page";
import { APP_VERSION } from './../../../../config/endpoints.config';
import {takeUntil} from 'rxjs/operators';
import { DEVICE_EVENT } from './../../../services/device/device.service';
import { MyAssistantService } from './../../../services/my-assistant/my-assistant.service';
import { ResourceService, ResourceView } from './../../../services/resource/resource.service';
import { Component, OnInit, OnDestroy, ViewChild, Renderer2, ElementRef } from '@angular/core';
import { Subject ,  Subscription } from 'rxjs';
import { RepServices } from '../../../data-services/rep/rep.services';
import { AuthenticationService } from '../../../services/authentication.service';
import { PopoverController, MenuController, ModalController, IonList } from '@ionic/angular';
import { ContactPageComponent } from '../../contact/contact-page/contact-page';
import { RepStatusOptionModel, REP_STATUS, LAST_REP_STATUS_KEY } from '../../../models/rep-status-model';
import { LogService } from '../../../services/logging/log-service';
import { DeviceService } from '../../../services/device/device.service';
import { PresentationService } from '../../../services/presentation/presentation.service';
import { ContactOfflineService } from '../../../services/contact/contact.service';
import { AccountOfflineService } from '../../../services/account/account.offline.service';
import { UIService, ComponentViewMode, PresentationView, AppView } from '../../../services/ui/ui.service';
import { TrackService, TrackingEventNames } from '../../../services/logging/tracking.service';
import { PresentationPageComponent } from '../../../pages/presentation/presentation';
import { SettingsComponent } from '../../settings/settings/settings';
import { AccountPageComponent } from '../../account/account-page/account-page';
import { TimeOffComponent } from '../../time-off/time-off-home/time-off-home';
import { AllocationComponent } from '../../sample/allocation-page/allocation-page';
import { AuthenticationDataService } from '../../../data-services/authentication/authentication.service';
import { NavigationService, PageName } from '../../../services/navigation/navigation.service';
import { ResourcePageComponent } from '../../resource/resource-page/resource-page';
import { EventsService } from '../../../services/events/events.service';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { CoachingComponent } from "../../coaching/coaching-home/coaching-home";
import { ActivityService } from '../../../services/activity/activity.service';
import { FeatureActionsMap } from '../../../classes/authentication/user.class';
import { SampleService } from '../../../services/sample/sample.service';
import { AllocationShipmentService } from '../../../services/sample/allocation-shipment.service';
import { TimeOffService } from '../../../services/time-off/time-off.service';
import { isToday, isYesterday, format, isValid } from 'date-fns';
import { ActivityType } from '../../../classes/activity/activity.class';
import { InboundMeetingService } from '../../../services/meeting/inbound-meeting.service';
import { DeltaService } from '../../../data-services/delta/delta.service';
import { AccountManagementPageComponent } from '../../../pages/account-management-page/account-management-page';
import { CaseManagementHomePage } from '../../../pages/case-management-home/case-management-home';
import { ScientificActivityPage } from '../../activity/scientific-activity/scientific-activity-page/scientific-activity-page';
import { OrderManagementPageComponent } from '../../order-management/order-management-page/order-management-page';
import { CaseManagementService } from '../../../services/case-management/case-management.service';
import { FooterService } from '../../../services/footer/footer.service';
import { AppSettingsPageComponent } from '../../app-settings/appsettings-page/appsettings-page';
import { TranslateService } from '@ngx-translate/core';
import { GlobalUtilityService } from "../../../services/global-utility.service";
import { DiskService } from '../../../services/disk/disk.service';
import { OpportunityManagementPageComponent } from '../../opportunity-management/opportunity-management-page/opportunity-management-page';
import { DateTimeFormatsService } from "../../../services/date-time-formats/date-time-formats.service";
import { AlertService } from '../../../services/alert/alert.service';
import { OfflineSyncUtility } from '../../../utility/offline-sync.utility';
import { MyAssistantComponent } from '../../../components/my-assistant/my-assistant';
import {MarketScanPageComponent} from "../../market-scan/market-scan-page/market-scan-page";
import { EventsToolPageComponent } from '../../../components/events-tool/event-tool-page/events-tools-page';
import { EventsToolService } from '../../../services/events-tool/events-tool.service';
import { EmailService } from '@omni/services/email-templates/email.service';
import { ExternallinksDataService } from '../../../data-services/externallink/externallinks.data.service';
import * as CryptoJS from 'crypto-js';
import { AllocationFeatureService } from '../../../services/sample/allocation.feature.service';
import { CallPlanOfflineService } from '@omni/services/call-plan/call-plan.offline.service';
import { startRecursiveEventScheduleConflictFlaggingForEventsArray } from '../../../utility/activity.utility';
import { NothingSelectedView } from '@omni/components/shared/nothing-selected-view/nothing-selected-view';
import { AccountManagementOfflineService } from "@omni/services/account-management/account-management.service";
import { electronApi } from "@omni/services/electron-api";
import {MarketingPlanManagementPageComponent } from "@omni/pages/marketing-plan-page/marketing-plan-page";
import { QuotePageComponent } from "@omni/pages/quote-page/quote-page";
import { AgendaFooterService } from '@omni/services/footer/agenda-footer.service';
import { PharmacovigilanceComponent } from '@omni/components/pharmacovigilance-reporting/page/pharmacovigilance-page';
import { PharmacovigilanceService } from '@omni/services/pharmacovigilance-reporting/pharmacovigilance.service';
import { SurveyPageComponent } from '@omni/components/survey/survey-page/survey-page';
import { LocationOfflineService } from '@omni/services/location/location.service';
import { FeatureActionsService } from '@omni/services/feature-actions/feature-actions.service';


/**
 * Generated class for the MenuToggleComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'tool-drawer-menu',
  templateUrl: 'menu-toggle.html',
  styleUrls:['menu-toggle.scss']
})


export class MenuToggleComponent implements OnInit, OnDestroy {
  private _isOpen: boolean = false;

  // Outputs: return the selected option to the caller
  //@Output() selectOption = new EventEmitter<any>();
  public options;
  public hyperlinkOptions;
  public userStatus: string;
  public userStatusList: Array<RepStatusOptionModel> = [
    { userState: REP_STATUS.ONLINE.userState, statusDisplayTxt: this.translate.instant('ONLINE'), selected: false, disabled: false },
    { userState: REP_STATUS.AVAILABLE.userState, statusDisplayTxt: this.translate.instant('AVAILABLE'), selected: false, disabled: true },
    { userState: REP_STATUS.STANDBY.userState, statusDisplayTxt: this.translate.instant('STANDBY'), selected: false, disabled: true },
  ];
  public userName: string;
  public userPosition: string;
  public userLocation: string;
  public syncDateString: string;
  public syncTimeString: string;
  private hasBaselineEnabled: boolean = false;
  private hasCallPlansEnabled: boolean = true;
  private hasCustomerCallPlansEnabled: boolean = true;
  private hasOmnipresenceDNAEnabled: boolean = false;
  private hasInboundEnabled: boolean = false;
  private hasResourcesToolEnabled: boolean = false;
  private hasTimeoffEnabled: boolean = false;
  private hasCoachingEnabled: boolean = false;
  private hasAllocationEnabled: boolean = false;
  private hasConsentEnabled: boolean = false;
  private hasScientificActivity: boolean = false;
  private hasCaseIntakeEnabled: boolean = false;
  private hasOpportunityManagement: boolean = false;
  private hasMarketScan: boolean = false;
  private hasEventsTool:boolean = false;
  private hasQuoteEnabled = false;
  private hasPharmacovigilanceEnabled: boolean = false;
  private isCustomerSurveyEnabled = false;
  destroy$: Subject<boolean> = new Subject<boolean>();
  private _isInitialized: boolean = false;
  public hasSettingsEnabled: boolean = false;
  public backgroundUploadInProgress: boolean = false;
  @ViewChild(IonList, { read: ElementRef, static: false }) list: ElementRef;
  private _deviceConnectionChangeHandler: () => void = () => {
    this._setStatus(true);
    // Update Analytics button depending on the connection
    this._toggleAnalyticsButton();
  }
  private _menuToggleListener: (args: { isOpen?: boolean, isClose?: boolean }) => void = (args) => {
    if (args.isOpen) {
      this.removeStatusSelectSuffixIcon();
      //@ts-ignore
      // this.menuList.el.scrollTop = 0;
      this.uiService.scrollListToView(this.list);
      this.initFeatureActions();
      if(!this.device.deviceFlags.ios){ // Not calling initOptions() for ios, it was causing issue in the tool-drawer
        this.initOptions();
      }
      this.displayLastSync();
      this._setStatus();
      if (this.uiService.selectedOptionInMenu) {
        this.setSelectedOption(this.uiService.selectedOptionInMenu);
      }
      this.dbEncryptionEnabled = this.diskService.isDBEncryptionEnabled ? '*' : '';
    }
    if (args.isClose) {
      //@ts-ignore
      this.uiService.scrollListToView(this.list);
    }
  };

  private _inboundRequestCount: number = 0;

  //@ViewChild(Content, {static: true}) content: Content;
  @ViewChild('menuList', {static: true}) menuList: IonList;

  public hasAccountManagement: boolean = false;
  public hasMarketingPlanManagement: boolean = false;
  public hasOrderManagement: boolean = false;
  public dbEncryptionEnabled = '';
  private _globalCustomerTxtUpdatedSub: Subscription;
  disableStatus: boolean = false;
  isAndroid: boolean = false;
  isNonAndroidOriOS: boolean = false;
  @ViewChild('statusSelect') statusSelect: any;
  private hasFieldMaterialFA: boolean = false;

  constructor(
    private offlineSync: OfflineSyncUtility,
    public repService: RepServices,
    public authenticationService: AuthenticationService,
    private accountService: AccountOfflineService,
    private contactService: ContactOfflineService,
    private presentationService: PresentationService,
    private logServices: LogService,
    public device: DeviceService,
    public deltaService: DeltaService,
    private events: EventsService,
    private uiService: UIService,
    private trackingService: TrackService,
    private resourceService: ResourceService,
    private authenticationDataService: AuthenticationDataService,
    private navService: NavigationService,
    private inAppBrowser: InAppBrowser,
    private activityService: ActivityService,
    private sampleService: SampleService,
    private shipmentService: AllocationShipmentService,
    private timeoffService: TimeOffService,
    public popoverCtrl: PopoverController,
    private inboundMeetingService: InboundMeetingService,
    private caseManagementService: CaseManagementService,
    public menuCtrl: MenuController,
    public footerService: FooterService,
    public assistantService: MyAssistantService,
    private modalCtrl: ModalController,
    public translate: TranslateService,
    public utilityService: GlobalUtilityService,
    public diskService: DiskService,
    public dateTimeFormatsService: DateTimeFormatsService,
    private alertService: AlertService,
    private eventsToolService: EventsToolService,
    private renderer: Renderer2,
    private emailService: EmailService,
    private externallinksDataService: ExternallinksDataService,
    private allocFeatureService: AllocationFeatureService,
    public callPlanOfflineService: CallPlanOfflineService,
    public accountManagementOfflineService : AccountManagementOfflineService,
    private readonly agendaFooterService: AgendaFooterService,
    private pharmacovigilanceService: PharmacovigilanceService,
    private locationService: LocationOfflineService,
    public faService: FeatureActionsService,
  ) {
    // Offline option is only available on Mobile
    if (device.isNativeApp) {
      this.userStatusList.push({ userState: REP_STATUS.OFFLINE.userState, statusDisplayTxt: this.translate.instant('OFFLINE'), selected: false, disabled: false });
    }
    this.translate.onLangChange.subscribe(data =>{
      this.translate.use(data.lang);
      this.initOptions();
    });
  }


  async ngOnInit() {
    this.isAndroid = this.device.isAndroid();
    this.isNonAndroidOriOS = this.device.deviceFlags.web || this.device.deviceFlags.electron;
    this.initFeatureActions();
    this.initOptions();
    this.displayLastSync();
    // this.events.subscribe('changeUserStatus', (data: any) => { this.changeStatus(REP_STATUS.ONLINE.userState)})

    if (this.authenticationDataService.isOfflineLoginFlow) {
      if (this.repService.wasLastUserStateOffline()) {
        this.device.isUserStateOffline = this.repService.isOfflineState = true;
        this.repService.setCurrentUserState(REP_STATUS.OFFLINE.userState);
      }
      this.device.isOffline = true;
    } else if (!this.device.isOffline) {
      try {
        await this.repService.getCurrentState(true);
        let isUserWithoutStatus = false;
        if (!this.repService.getCurrentUserState()) {
          isUserWithoutStatus = true;
        }

        this.repService.setCurrentUserState(REP_STATUS.ONLINE.userState);

        let newStateData = { userState: REP_STATUS.ONLINE.userState };

        // Update server to ONLINE and update service afterwards
        await this.repService.setCurrentState(newStateData).then(res => {
          window.localStorage.setItem(LAST_REP_STATUS_KEY, REP_STATUS.ONLINE.userState);
        }).catch(error => { });

        if (isUserWithoutStatus) {
          // In case of a new user or a user with missing user status,
          // fetch again.
          await this.repService.getCurrentState();
        }
      } catch (error) {
        console.error('menu-toggle: ngOnInit: ', error);
      }
    }

    this._setStatus();
    this._isInitialized = true;
    if (!this._globalCustomerTxtUpdatedSub) {
      this._globalCustomerTxtUpdatedSub = this.authenticationService.globalCustomerTxtUpdatedObservable.subscribe(updated => {
        if (updated) this.initOptions();
      });
    }
    // Using an Event instead of @Input with ngOnChange to get menu toggle change from home.ts
    // because I noticed significant delay on detecting change which I'm unsure of.
    this.events.subscribe('sideMenu:toggle', this._menuToggleListener);
    // These subscription will be permanent and work in background whenever connection state changes.
    this.events.subscribe('device:deviceIsOffline', this._deviceConnectionChangeHandler);
    this.events.subscribe('device:deviceIsOnline', this._deviceConnectionChangeHandler);
    this.events.observe('sync:completed').pipe(
      takeUntil(this.destroy$))
      .subscribe(err => {
        this.initFeatureActions();
        this.initOptions();
        this.displayLastSync();
        if (!err) {
          //          this.enableMenuItem('ResourcesPage', this.resourceService.allResources.length > 0);
          this.setUserInfo();
        }
      });
    this.events.subscribe('updateUserLocation', async () => {
      if (this.faService.isShortCallLauncherEnabled) {
        this.authenticationService.user.currentLocation = await this.locationService.getCurrentLocationName();
      } else this.authenticationService.user.currentLocation = null;
    })
    // Prevent status change when there's an inbound request
    this.inboundMeetingService.inboundMeetingRequestArrayObservable.pipe(takeUntil(this.destroy$)).subscribe(
      (requestArray: any[]) => {
        this._inboundRequestCount = requestArray.length;
        this._initStatusSelectOptions();
      }
    );
    this.device.isBackgroundUploadInProgressObservable.pipe(takeUntil(this.destroy$)).subscribe(inProgress => {
      this.backgroundUploadInProgress = inProgress;
    });
  }

  ionViewWillEnter() {
    console.log('ion-view-will-enter');
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    this.events.unsubscribe('device:deviceIsOffline', this._deviceConnectionChangeHandler);
    this.events.unsubscribe('device:deviceIsOnline', this._deviceConnectionChangeHandler);
    this.events.unsubscribe('sideMenu:toggle', this._menuToggleListener);
    if (this._globalCustomerTxtUpdatedSub) {
      this._globalCustomerTxtUpdatedSub.unsubscribe();
    }
  }

  private removeStatusSelectSuffixIcon() {
    // Shadow dom parts selection is not working properly on iOS.
    // Hence hacking shadow dome for now.
    // Need to revisit later.
    const statusSelectShadowRootEl = this.statusSelect && this.statusSelect.el && this.statusSelect.el.shadowRoot ? this.statusSelect.el.shadowRoot : undefined;
    if (statusSelectShadowRootEl) {
      const statusSelectIconEl = statusSelectShadowRootEl.querySelector('.select-icon');
      if (statusSelectIconEl) {
        this.renderer.setStyle(statusSelectIconEl, 'display', 'none');
      }
    }
  }

  enableMenuItem(item: string, enable: boolean) {
    let menu = this.options.find(o => o.component === item);
    if (menu) {
      menu.enable = enable;
    }
  }

  public getDisplayText() {
    return this.userStatus ? this.translate.instant(this.userStatus) : '';
  }

  private _toggleAnalyticsButton() {
    const option = this.options.find(a => a.displayName === 'ANALYTICS');
    if (option) {
      option.enable = !this.device.isOffline;
    }
  }

  private async _setStatus(connectionChanged = false) {
    // enable / disable options
    if (connectionChanged || !this._isInitialized) {
      if (this.device.isOffline) {
        // Disable the status selection if it's real offline
        if (this.device.isDeviceRealOffline) {
          this.userStatusList.forEach((status) => status.disabled = true);
        } else {
          this._initStatusSelectOptions();
        }
      } else {
        this._initStatusSelectOptions();
      }
    }

    // set user info
    this.setUserInfo();

    // set status
    if (this.device.isOffline) {
      this.userStatus = this.repService.getCurrentUserState() ? this.repService.getCurrentUserState() : REP_STATUS.ONLINE.userState;
    } else {
      this.userStatus = this.repService.getCurrentUserState();

      if (!this.userStatus || (connectionChanged && !this.device.isOffline && this.userStatus !== REP_STATUS.OFFLINE.userState)) {
        await this.repService.getCurrentState();
        this.userStatus = this.repService.getCurrentUserState();
      }
    }
  }

  private loadUserInfoAndSet() {
    this.authenticationService.getOfflineUser().then((user) => {
      if (user) {
        this.userName = user.displayName;
        this.userPosition = user.positionName;
      } else {
        console.warn('menu-toggle: local user info not available..');
      }
    }).catch(err => console.error('menu-toggle: loadUserInfoAndSet: ', err));
  }

  private setUserInfo() {
    if (this.device.isOffline && !this.authenticationService.user) {
      this.loadUserInfoAndSet();
    } else {
      if (this.authenticationService.user) {
        this.userName = this.authenticationService.user.displayName;
        this.userPosition = this.authenticationService.user.positionName;
      } else {
        console.warn('menu-toggle: in-memory user info not available..');
        this.loadUserInfoAndSet();
      }
    }
  }

  async getUserLocation() {
    if (!this.device.isOffline && this.faService.isShortCallLauncherEnabled) {
      const currentCoordinates: any = await this.locationService.getCurrentLocation();
      if (currentCoordinates.latitude <= 0) {
        this.userLocation = this.translate.instant('LOCATION_SERVICE_UNAVAILABLE');
      } else {
        //street address, City
        const location = await this.locationService.getLocationFromCoords(currentCoordinates.latitude, currentCoordinates.longitude);
        this.userLocation = location ? location : this.translate.instant('LOCATION_SERVICE_UNAVAILABLE');
      }
    } else {
      this.userLocation = this.translate.instant('LOCATION_SERVICE_UNAVAILABLE');
    }
  }


  private _initStatusSelectOptions() {
    // Enable / Disable select options
    this.disableStatus = false;
    this.userStatusList.forEach((status) => {
      if (status.userState === REP_STATUS.ONLINE.userState || status.userState === REP_STATUS.OFFLINE.userState) {
        status.disabled = this._inboundRequestCount > 0;
      }
      if (status.userState === REP_STATUS.STANDBY.userState || status.userState === REP_STATUS.AVAILABLE.userState) {
        status.disabled = !this.hasInboundEnabled || this._inboundRequestCount > 0;
      }
    });
  }

  public isOffline() {
    if (this.userStatus && this.userStatus == REP_STATUS.OFFLINE.userState) {
      return true;
    }
    return false;
  }

  /**
   * Reloads or refresh data from endpoint
   *
   * @memberof MenuToggleComponent
   */
  public async refreshData() {
    this.trackingService.tracking('SyncClicked', TrackingEventNames.SYNC);
    if (this.activityService.teamViewActive) {
      this.activityService.teamViewActive = false;
      this.activityService.selectedActivity = undefined;
      this.authenticationService.impersonatedUser = undefined;
      this.activityService.agendaTeamViewActivities = [];
      this.activityService.activityFilter = ActivityType.AllActivity;
      this.events.publish('filterActivitiesDataForSelectedUser');
    }

    this.events.publish('home:runUpdateSync');
  }
  /**
   * Emits event on menu item clicked
   *
   * @param {any} option
   * @memberof MenuToggleComponent
   */
  async onClickMenuOption(option) {
    console.log(option);
    this.uiService.toolsActivityActive = true;
    //clear the selected accounts and other details on activity pane. CWD-2046
    // added a function to only clear filters and not the accounts object
    this.accountService.clearFilters();

    // if(option.displayName === 'Presentations' && !this.presentationService.loadedPresentation) return;
    this.options.map(value => value.selected = false);
    this.hyperlinkOptions.map(value => value.selected = false);
    option.selected = true;
    if (this.activityService.teamViewActive) {
      this.activityService.teamViewActive = false;
      this.activityService.selectedActivity = undefined;
      this.authenticationService.impersonatedUser = undefined;
      this.activityService.agendaTeamViewActivities = [];
      this.activityService.activityFilter = ActivityType.AllActivity;
      this.events.publish('filterActivitiesDataForSelectedUser');
    }
    this.uiService.setActiveViewForNewMeeting('meeting');
    this.uiService.showCancelDoneOnActivityDetails = false;
    if(this.activityService.selectedActivity)
      this.activityService.selectedActivityAtHome = this.activityService.selectedActivity
    switch (option.component) {
      case "ContactPage":
        this.trackingService.tracking('ToolsContacts', TrackingEventNames.ACTIVITY)
        if (!this.contactService.loadedContacts && !this.contactService.approvableDCRRequests.length) return;
        this.uiService.showNewActivity = false;
        this.uiService.prevView = this.uiService.activeView
        this.uiService.activeView = "Contacts";
        this.uiService.isConsentFromToolDrawer = false;
        this.uiService.consentFlipButton = false;
        this.contactService.contactInformation = null;
        this.contactService.prevSelected = null;
        this.accountService.accountPageMode = ComponentViewMode.LISTVIEW;
        this.contactService.contactPageMode = ComponentViewMode.LISTVIEW;
        this.contactService.accessedContactListFrom = PageName.ToolsDrawer;
        this.uiService.isContactsFromToolDrawer = true;
        if(this.activityService.selectedActivity) this.activityService.selectedActivityAtHome = this.activityService.selectedActivity
        if(this.navService.getActiveChildNavViewPageName() == PageName.CaseManagementDetailComponent) {
          this.caseManagementService.assignSelectedCase(undefined);
          this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView, PageName.ActivitiesPageComponent);
        }
        this.navService.pushWithPageTracking(ContactPageComponent, PageName.ContactPageComponent, {from: 'ContactTool'}, PageName.ContactPageComponent);
        //this.navCtrl.push(ContactPageComponent);
        break;
      case "ConsentPage":
        this.trackingService.tracking('ToolsConsent', TrackingEventNames.ACTIVITY);
        if (!this.contactService.loadedContacts) return;
        this.uiService.showNewActivity = false;
        this.uiService.prevView = this.uiService.activeView;
        this.uiService.activeView = "Consent";
        this.uiService.isConsentFromToolDrawer = true;
        this.uiService.consentFlipButton = false;
        this.contactService.contactInformation = null;
        this.contactService.prevSelected = null;
        this.accountService.accountPageMode = ComponentViewMode.LISTVIEW;
        this.contactService.contactPageMode = ComponentViewMode.LISTVIEW;
        this.contactService.accessedContactListFrom = PageName.ToolsDrawer;
        this.contactService.consentFrom = 'ConsentTool';
        this.emailService.selectedActivity = null;
        if(this.activityService.selectedActivity) this.activityService.selectedActivityAtHome = this.activityService.selectedActivity
        if(this.navService.getActiveChildNavViewPageName() == PageName.CaseManagementDetailComponent) {
          this.caseManagementService.assignSelectedCase(undefined);
          this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView, PageName.ActivitiesPageComponent);
        }
        this.navService.pushWithPageTracking(ContactPageComponent, PageName.ContactPageComponent, {from: 'ConsentTool'}, PageName.ContactPageComponent);
        break;
      case "AccountsPage":
        this.trackingService.tracking('ToolsAccounts', TrackingEventNames.ACTIVITY)
        if (!this.accountService.loadedAccounts) return;
        this.uiService.prevView = this.uiService.activeView;
        this.uiService.activeView = "Accounts";
        this.accountService.accessedAccountListFrom = PageName.ActivitiesPageComponent;
        this.accountService.accountPageMode = ComponentViewMode.LISTVIEW;
        this.contactService.contactPageMode = ComponentViewMode.LISTVIEW;
        this.uiService.isAccountsFromToolDrawer = true;
        this.navService.pushWithPageTracking(AccountPageComponent, PageName.AccountPageComponent, { from: 'AccountTool', 'listMode': ComponentViewMode.LISTVIEW }, PageName.AccountPageComponent);
        //this.navCtrl.push(AccountPageComponent,{'listMode':ComponentViewMode.LISTVIEW});
        break;
      case "AccountManagementPage":
        //this.trackingService.tracking('ToolsAccounts', TrackingEventNames.ACTIVITY)
        if (!this.accountService.loadedAccounts) return;
        this.uiService.prevView = this.uiService.activeView;
        this.uiService.activeView = "AccountManagement";
        this.uiService.activitiesPagePlanTabSelectedPlanAtHome = this.uiService.activitiesPagePlanTabSelectedPlan;
        if(this.accountManagementOfflineService.selectedAccountPlan$.value) {
          this.accountManagementOfflineService.selectedForGoalTab = this.accountManagementOfflineService.selectedAccountPlan$.value;
          this.accountManagementOfflineService.selectedAccountPlan$.next(null);
        }
        this.uiService.activitiesPagePlanTabSelectedPlan = undefined;
        this.navService.pushWithPageTracking(AccountManagementPageComponent, PageName.AccountManagementPageComponent, { 'listMode': ComponentViewMode.LISTVIEW }, PageName.AccountManagementPageComponent);
        //this.navCtrl.push(AccountPageComponent,{'listMode':ComponentViewMode.LISTVIEW});
        break;
        case "MarketingBusinessManagementPage":
          this.uiService.prevView = this.uiService.activeView;
          this.uiService.activeView = "marketingBusiness";
          this.uiService.activitiesPagePlanTabSelectedPlanAtHome = this.uiService.activitiesPagePlanTabSelectedPlan;
          this.uiService.showNewActivity = false;
          this.navService.pushWithPageTracking(MarketingPlanManagementPageComponent, PageName.MarketingPlanManagementPageComponent, { 'listMode': ComponentViewMode.LISTVIEW }, PageName.MarketingPlanManagementPageComponent);
          break;
      case "QuotePage":
        this.uiService.prevView = this.uiService.activeView;
        this.uiService.activeView = "quotes";
        this.uiService.activitiesPagePlanTabSelectedPlanAtHome = this.uiService.activitiesPagePlanTabSelectedPlan;
        this.uiService.showNewActivity = false;
        this.navService.pushWithPageTracking(QuotePageComponent, PageName.QuotePageComponent, { 'listMode': ComponentViewMode.LISTVIEW }, PageName.QuotePageComponent);
        break;
      case "CustomerCallPlanPage":
        this.trackingService.tracking('ToolsCallPlans', TrackingEventNames.ACTIVITY)
        this.uiService.prevView = this.uiService.activeView;
        this.uiService.activeView = "CallPlans";
        this.agendaFooterService.footerViewAtHome=this.agendaFooterService.lastFooterView;
        this.uiService.activitiesPagePlanTabSelectedPlanAtHome = this.uiService.activitiesPagePlanTabSelectedPlan;
        this.uiService.activitiesPagePlanTabSelectedPlan = undefined;
        this.navService.pushWithPageTracking(CustomerCallPlanPage, PageName.CustomerCallPlanPage, { 'listMode': ComponentViewMode.LISTVIEW }, PageName.CustomerCallPlanPage);
        break;
      case "FieldMaterialManagementPage":
        this.uiService.prevView = this.uiService.activeView;
        this.navService.pushWithPageTracking(FieldMaterialManagementPageComponent, PageName.FieldMaterialManagementPageComponent, null, PageName.FieldMaterialManagementPageComponent);
        break;
      case "PresentationPage":
        this.trackingService.tracking('ToolsPresentations', TrackingEventNames.ACTIVITY)
        this.uiService.prevView = this.uiService.activeView;
        this.uiService.activeView = "Presentation";
        this.presentationService.viewMode = PresentationView.MENU;
        this.navService.pushWithPageTracking(PresentationPageComponent, PageName.PresentationPageComponent, null, PageName.PresentationPageComponent);
        //this.navCtrl.push(PresentationPageComponent);
        // this.viewCtrl.dismiss();
        break;
      case 'PharmacovigilanceComponent':
        this.pharmacovigilanceService.loadRecordsFromDB();
        this.pharmacovigilanceService.loadInfoButtonsDataFromDB(this.authenticationService.user.businessUnitName);
        this.uiService.prevView = this.uiService.activeView;
        this.navService.pushWithPageTracking(
          PharmacovigilanceComponent,
          PageName.PharmacovigilancePage,
          null,
          PageName.PharmacovigilancePage,
        );
        break;
      case "OmnipresenceExtURL":
        this.uiService.toolsActivityActive = false;
        this.trackingService.tracking('ToolsAnalytics', TrackingEventNames.ACTIVITY)
        if (!this.device.isOffline) {
          const appInsightsInstrumentationKey = this.authenticationService.userConfig.activeInstance.appInsightsInstrumentationKey;
          let encJson = CryptoJS.AES.encrypt(JSON.stringify(appInsightsInstrumentationKey), 'analytics').toString()
          let encryptedAppInsightsKey = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(encJson))

          let url = this.authenticationService.userConfig.activeInstance.dnaDashboardUrl +
            '?etoken=' + this.authenticationService.encryptedAccess +
            '&instanceid=' + this.authenticationService.userConfig.activeInstance.id +
            '&friendlyname=' + this.authenticationService.userConfig.activeInstance.friendlyName +
            '&appinsightskey=' + encryptedAppInsightsKey +
            '&appversion=' + APP_VERSION;

          if (this.device.isNativeApp && !this.device.deviceFlags.electron) {
            // url = url + '?etoken=' + this.authenticationService.encryptedAccess +
            //   '&instanceid=' + this.authenticationService.userConfig.activeInstance.id +
            //   '&friendlyname=' + this.authenticationService.userConfig.activeInstance.friendlyName;

            if (this.device.deviceFlags.ios) {
              const browserRef = this.inAppBrowser.create(url, '_blank', { location: 'no', hideurlbar: 'yes', zoom: 'no' });
            }
            else {
              const browserRef = this.inAppBrowser.create(url, '_blank', { location: 'yes', hideurlbar: 'yes', zoom: 'no' });
            }
          } else {
            // url = url +
            //   Endpoints.DNA.LinkToOmniPresenceDNA.replace('{{userEnamilAddress}}', this.authenticationService.user.userPrincipalName) +
            //   '&instanceid=' + this.authenticationService.userConfig.activeInstance.id;

            // url = url + '?etoken=' + this.authenticationService.encryptedAccess +
            //   '&instanceid=' + this.authenticationService.userConfig.activeInstance.id +
            //   '&friendlyname=' + this.authenticationService.userConfig.activeInstance.friendlyName;
            if(this.device.deviceFlags.electron) {
              electronApi.openExternal(url);
            }else {
              window.open(url, "_system");
            }
          }
        }
        break;
      case "LanguagePage":
        // this.uiService.activeView = "Language";
        // if(this.device.isMobileDevice){
        //   this.navCtrl.push(LanguageListComponent);
        // }
        break;
      case "SettingsPage":
        this.uiService.activeView = AppView.SETTINGS;
        if (this.device.isMobileDevice) {
          this.navService.pushWithPageTracking(SettingsComponent, PageName.SettingsComponent, null, PageName.SettingsComponent);
          //this.navCtrl.push(SettingsComponent);
        }
        break;
      case "ResourcesPage":
        this.trackingService.tracking('ToolsResources', TrackingEventNames.ACTIVITY)
        this.uiService.prevView = this.uiService.activeView;
        this.uiService.activeView = "Resources";
        this.resourceService.viewMode = ResourceView.MENU;
        this.navService.pushWithPageTracking(ResourcePageComponent, PageName.ResourcePageComponent, null, PageName.ResourcePageComponent);
        break;
      case "TimeoffPage":
        this.trackingService.tracking('ToolsTimeOff', TrackingEventNames.ACTIVITY)
        this.uiService.prevView = this.uiService.activeView;
        this.timeoffService.setMobileTracker("timeOff");
        this.navService.pushWithPageTracking(TimeOffComponent, PageName.TimeOffComponent, null, PageName.TimeOffComponent);
        //this.navCtrl.push(TimeOffComponent);
        break;
      case "AllocationPage":
        this.allocFeatureService.loadShipmentsForAllocationTool();
        this.allocFeatureService.loadTeamAdjustments();
        this.trackingService.tracking('ToolsCustomerAllocations', TrackingEventNames.ACTIVITY)
        this.activityService.indexWholeSampleActivityMetasBySKU();
        this.uiService.prevView = this.uiService.activeView;
        this.uiService.activeView = "CustomerAllocation";
        if(this.activityService.selectedActivity) this.activityService.selectedActivityAtHome = this.activityService.selectedActivity;
        if (this.sampleService.selectedSampleSKU) {
          this.sampleService.selectedSampleSKU = null;
        }
        if (this.allocFeatureService.selectedShipment) {
          this.allocFeatureService.selectedShipment = null;
        }
        this.shipmentService.fromAgendaPlanTab = false;
        this.navService.pushWithPageTracking(AllocationComponent, PageName.AllocationComponent, null, PageName.AllocationComponent);
        break;
      case "CoachingPage":
        this.trackingService.tracking('ToolsCoaching', TrackingEventNames.ACTIVITY)
        this.uiService.prevView = this.uiService.activeView;
        this.uiService.activeView = "Coaching";
        this.navService.pushWithPageTracking(CoachingComponent, PageName.CoachingComponent, null, PageName.CoachingComponent);
        break;
      case "ScientificActivityPage":
        this.trackingService.tracking('ScientificPlansToolsDrawer', TrackingEventNames.SCIENTIFICACTIVITYPLAN, true);
        this.uiService.prevView = this.uiService.activeView;
        this.uiService.activeView = "ScientificActivity";
        this.uiService.activitiesPagePlanTabSelectedPlanAtHome = this.uiService.activitiesPagePlanTabSelectedPlan;
        this.uiService.activitiesPagePlanTabSelectedPlan = undefined;
        this.navService.pushWithPageTracking(ScientificActivityPage, PageName.ScientificActivityPage, null, PageName.ScientificActivityPage);
        break;
      case "Logout":
        this.uiService.toolsActivityActive = false;
        this.logout();
        // this.authenticationDataService.logout();
        // this.navCtrl.setRoot(LoginPage);
        break;
      case "closeTools":
        this.uiService.showNewActivity = false;
        this.uiService.activeView = this.uiService.prevView
        break;
      case "CaseManagementHome": {
        this.trackingService.tracking('ToolsCustomerInquiries', TrackingEventNames.ACTIVITY);
        this.uiService.prevView = this.uiService.activeView;
        this.uiService.activeView = "Case-Mangement";
        this.caseManagementService.assignSelectedCase(undefined);
        this.caseManagementService.showNewCase = false;
        this.navService.pushWithPageTracking(CaseManagementHomePage, PageName.CaseManagementHomePage, null);
        break;
      }
      case "OrderManagementPage": {
        this.trackingService.tracking('OrderManagementToolsDrawer', TrackingEventNames.ORDERMANAGEMENT);
        this.uiService.prevView = this.uiService.activeView;
        this.uiService.activeView = "OrderManagement";
        this.navService.pushWithPageTracking(OrderManagementPageComponent, PageName.OrderManagementPageComponent, {}, PageName.OrderManagementPageComponent);
        break;
      }
      case "AppSettingsPage":
        this.trackingService.tracking('AppSettingsPage', TrackingEventNames.APPSETTING)
        // this.uiService.prevView = this.uiService.activeView;
        // this.uiService.activeView = "AppSetting";
        // this.accountService.accessedAccountListFrom = PageName.ActivitiesPageComponent;
        this.navService.pushWithPageTracking(AppSettingsPageComponent, PageName.AppSettingsPageComponent, { 'listMode': ComponentViewMode.LISTVIEW }, PageName.AppSettingsPageComponent);
        //this.navCtrl.push(AccountPageComponent,{'listMode':ComponentViewMode.LISTVIEW});
        break;
      case "OpportunityManagementPage":
        if (!this.accountService.loadedAccounts) return;
        this.uiService.prevView = this.uiService.activeView;
        this.navService.pushWithPageTracking(OpportunityManagementPageComponent, PageName.OpportunityManagementPage, { 'listMode': ComponentViewMode.LISTVIEW }, PageName.OpportunityManagementPage);
        break;
      case "MarketScanPage":
        this.uiService.prevView = this.uiService.activeView;
        this.trackingService.tracking('CustomerScanToolClick', TrackingEventNames.CUSTOMER_SCAN,true);
        this.navService.pushWithPageTracking(MarketScanPageComponent, PageName.MarketScanPageComponent, {}, PageName.MarketScanPageComponent);
        break;
      case "EventsToolPageComponent":
        this.uiService.prevView = this.uiService.activeView;
        this.uiService.activeView = '';
        await this.uiService.displayLoader();
        this.eventsToolService.clearConflictEventIds();
        const lastIdx = startRecursiveEventScheduleConflictFlaggingForEventsArray(this.eventsToolService.eventsToolData, 0, this.eventsToolService.conflictCheckLastIdx, this.authenticationService.user.xSystemUserID);
        this.eventsToolService.setConflictCheckLastIdx(lastIdx);
        this.navService.pushWithPageTracking(EventsToolPageComponent, PageName.EventsToolPageComponent, {}, PageName.EventsToolPageComponent);
        await this.uiService.dismissLoader();
        break;
      case "SurveyPage":
        this.uiService.prevView = this.uiService.activeView;
        this.trackingService.tracking('SurveyPageToolsDrawer', TrackingEventNames.SURVEY, true);
        this.navService.pushWithPageTracking(SurveyPageComponent, PageName.SurveyPageComponent, {}, PageName.SurveyPageComponent);
        break;
      case "HyperlinkExtURL":
        this.uiService.toolsActivityActive = false;
        let url = option.url;
        if (this.device.isNativeApp && !this.device.deviceFlags.electron) {
          this.inAppBrowser.create(url, '_system');
        } else {
          if(this.device.deviceFlags.electron) {
            electronApi.openExternal(url);
          }else {
            window.open(url, "_system");
          }
        }
        break;
      // default:
      // this.uiService.activeView = "none";
    }
    this.menuCtrl.toggle();
  }

  private initFeatureActions() {
    this.hasBaselineEnabled = this.authenticationService.hasFeatureAction(FeatureActionsMap.BASELINE);

    this.hasCallPlansEnabled = this.hasBaselineEnabled && this.authenticationService.hasFeatureAction(FeatureActionsMap.CALL_PLANS);

    this.hasOmnipresenceDNAEnabled = this.hasBaselineEnabled && this.authenticationService.hasFeatureAction(FeatureActionsMap.OMNIPRESENCE_DNA);

    this.hasTimeoffEnabled = this.hasBaselineEnabled && this.authenticationService.hasFeatureAction(FeatureActionsMap.TIME_OFF_TOOL);

    this.hasCoachingEnabled = this.hasBaselineEnabled && this.authenticationService.hasFeatureAction(FeatureActionsMap.COACHING_TOOL);

    this.hasAllocationEnabled = this.hasBaselineEnabled && this.authenticationService.hasFeatureAction(FeatureActionsMap.ALLOCATION_TOOL);

    this.hasInboundEnabled = this.hasBaselineEnabled && this.authenticationService.hasFeatureAction(FeatureActionsMap.INBOUND_MEETING);

    this.hasResourcesToolEnabled = this.hasBaselineEnabled && this.authenticationService.hasFeatureAction(FeatureActionsMap.RESOURCE_TOOL);

    this.hasConsentEnabled = this.hasBaselineEnabled && this.authenticationService.hasFeatureAction(FeatureActionsMap.CONSENT_TOOL);

    this.hasScientificActivity = this.hasBaselineEnabled && this.authenticationService.hasFeatureAction(FeatureActionsMap.SCIENTIFIC_TOOL_PLAN);

    this.hasAccountManagement = this.hasBaselineEnabled && this.authenticationService.hasFeatureAction(FeatureActionsMap.ACCOUNT_MANAGEMENT);


    this.hasCaseIntakeEnabled = this.hasBaselineEnabled && this.authenticationService.hasFeatureAction(FeatureActionsMap.CASE_INTAKE);

    this.hasOrderManagement = this.hasBaselineEnabled && this.authenticationService.hasFeatureAction(FeatureActionsMap.ORDER_MANAGEMENT);

    this.hasSettingsEnabled = this.hasBaselineEnabled && this.authenticationService.hasFeatureAction(FeatureActionsMap.SETTING_TOOL);

    this.hasOpportunityManagement = this.hasBaselineEnabled && this.authenticationService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_MANAGEMENT);

    this.hasMarketScan = this.hasBaselineEnabled && this.authenticationService.hasFeatureAction(FeatureActionsMap.CUSTOMER_SCAN_TOOL);

    this.hasEventsTool = this.hasBaselineEnabled && this.authenticationService.hasFeatureAction(FeatureActionsMap.EVENT_TOOL);

    this.hasFieldMaterialFA = this.hasBaselineEnabled && this.authenticationService.hasFeatureAction(FeatureActionsMap.FIELD_MATERIAL_MANAGEMENT);
    this.hasMarketingPlanManagement = this.hasBaselineEnabled && this.authenticationService.hasFeatureAction(FeatureActionsMap.MARKETING_BUSINESS_PLAN);

    this.hasQuoteEnabled = this.hasBaselineEnabled && this.authenticationService.hasFeatureAction(FeatureActionsMap.QUOTES);
    this.hasPharmacovigilanceEnabled = this.hasBaselineEnabled && this.authenticationService.hasFeatureAction(FeatureActionsMap.PHARMACOVIGILANCE_TOOL);

    this.isCustomerSurveyEnabled = this.hasBaselineEnabled && (this.authenticationService.hasFeatureAction(FeatureActionsMap.CUSTOMER_SURVEY) || this.authenticationService.hasFeatureAction(FeatureActionsMap.ACCOUNT_SURVEY));
  }

  /**
   * Initializes the Menu Options
   *
   * @private
   * @memberof MenuToggleComponent
   */
  private initOptions(): void {
    let textdisplayName;
    switch (this.utilityService.globalCustomerText) {
      case 'Stakeholder':
        textdisplayName = this.translate.instant('STAKEHOLDER');
        break;
      case 'Contact':
        textdisplayName = this.translate.instant('CONTACT');
        break;
      case 'Customer':
        textdisplayName = this.translate.instant('CUSTOMER');
        break;
      default:
        textdisplayName = this.utilityService.globalCustomerText;
        break;
    }
    let allocationOrderDisplayName = this.translate.instant('CUSTOMER_ALLOCATION_WITH_TEXT', { text: textdisplayName });
    switch (this.translate.currentLang) {
      case 'it':
      case 'pt':
      case 'es':
        allocationOrderDisplayName = this.translate.instant('CUSTOMER_ALLOCATION_TEXT_IN_MENU');
        break;
      default:
        allocationOrderDisplayName = this.translate.instant('CUSTOMER_ALLOCATION_WITH_TEXT', { text: textdisplayName });
        break;
    }

    this.options = [{
      id: 'accounts',
      visible: this.hasBaselineEnabled,
      iconName: 'accounts-icon',
      displayName: this.translate.instant('ACCOUNTS'),
      selected: false,
      component: 'AccountsPage',
      enable: true
    },
    {
      id: 'accountManagement',
      visible: this.hasAccountManagement,
      iconName: 'account-management-icon',
      displayName: this.translate.instant('ACCOUNT_PLANS'),
      selected: false,
      component: 'AccountManagementPage',
      enable: true
    },
    {
      id: 'marketingBusiness',
      visible:  this.hasMarketingPlanManagement,
      iconName: 'marketing-plan-icon',
      displayName: this.translate.instant('MARKETING_BUSINESS_PLANS'),
      selected: false,
      component: 'MarketingBusinessManagementPage',
      enable: true
    },
    {
      id: 'quotes',
      visible:  this.hasQuoteEnabled,
      iconName: 'quote-tool-icon',
      displayName: this.translate.instant('QUOTES'),
      selected: false,
      component: 'QuotePage',
      enable: true
    },
    {
      id: 'orderManagement',
      visible: this.hasOrderManagement,
      iconName: 'ordermgmt-icon',
      displayName: this.translate.instant('SALES_ORDERS'),
      selected: false,
      component: 'OrderManagementPage',
      enable: true
    },
    {
      id: 'call-plan',
      visible: this.hasCallPlansEnabled,
      iconName: 'call-plan-icon',
      displayName: this.translate.instant('CALL_PLANS'),
      selected: false,
      component: 'CustomerCallPlanPage',
      enable: true
    },
    {
      id: 'case-tool',
      visible: this.hasCaseIntakeEnabled,
      iconName: 'case-intake-icon',
      displayName: this.translate.instant('CUSTOMER_INQUIRIES_WITH_TEXT', { globalCustomerText: textdisplayName }),
      selected: false,
      component: 'CaseManagementHome',
      enable: true
    },
    {
      id: 'coaching',
      visible: this.hasCoachingEnabled,
      iconName: 'coaching-icon',
      displayName: this.translate.instant('COACHING'),
      selected: false,
      component: 'CoachingPage',
      enable: true
    },
    {
      id: 'contacts',
      visible: this.hasBaselineEnabled,
      iconName: 'contacts-icon',
      displayName: this.translate.instant('CONTACTS'),
      selected: false,
      component: 'ContactPage',
      enable: true
    },
    {
      id: 'fieldMaterialManagement',
      visible: this.hasFieldMaterialFA,
      iconName: 'field-material-icon',
      displayName: this.translate.instant('FIELD_MATERIAL_MANAGEMENT'),
      selected: false,
      component: 'FieldMaterialManagementPage',
      enable: true
    },
    {
      id: 'presentations',
      visible: this.hasBaselineEnabled,
      iconName: 'presentations-icon',
      displayName: this.translate.instant('PRESENTATIONS'),
      selected: false,
      component: 'PresentationPage',
      enable: true
    },
    {
      id: 'pharmacovigilance',
      visible: this.hasPharmacovigilanceEnabled,
      iconName: 'pharmacovigilance-icon',
      displayName: this.translate.instant('PHARMACOVIGILANCE_REPORTING'),
      selected: false,
      component: 'PharmacovigilanceComponent',
      enable: true
    },
    {
      id: 'analytics',
      visible: this.hasOmnipresenceDNAEnabled,
      iconName: 'analytics-icon',
      displayName: this.translate.instant('ANALYTICS'),
      selected: false,
      component: 'OmnipresenceExtURL',
      enable: this.hasOmnipresenceDNAEnabled && !this.device.isOffline
    },
    {
      id: 'survey',
      visible: this.isCustomerSurveyEnabled,
      iconName: 'survey-icon',
      displayName: this.translate.instant('SURVEY'),
      selected: false,
      component: 'SurveyPage',
      enable: true
    },
    {
      id: 'time-off',
      visible: this.hasTimeoffEnabled,
      iconName: 'time-off-icon',
      displayName: this.translate.instant('TIME_OFF'),
      selected: false,
      component: 'TimeoffPage',
      enable: true
    },
    {
      id: 'resources',
      visible: this.hasResourcesToolEnabled,
      iconName: 'resources-icon',
      displayName: this.translate.instant('RESOURCES'),
      selected: false,
      component: 'ResourcesPage',
      enable: true
    },
    {
      id: 'allocation',
      visible: this.hasAllocationEnabled,
      iconName: 'allocation-icon',
      displayName: allocationOrderDisplayName,
      selected: false,
      component: 'AllocationPage',
      enable: true,
    },
    {
      id: 'consent',
      visible: this.hasConsentEnabled,
      iconName: 'consent-icon',
      displayName: this.translate.instant('CONSENT'),
      selected: false,
      component: 'ConsentPage',
      enable: true
    },
    {
      id: 'scientificactivity',
      visible: this.hasScientificActivity,
      iconName: 'scientific-plan-icon',
      displayName: this.translate.instant('SCIENTIFIC_PLANS'),
      selected: false,
      component: 'ScientificActivityPage',
      enable: true
    },
    {
      id: 'opportunitymgmt',
      visible: this.hasOpportunityManagement,
      iconName: 'opportunity_mgmt_tool',
      displayName: this.translate.instant('OPPORTUNITIES'),
      selected: false,
      component: 'OpportunityManagementPage',
      enable: true
    },
    {
      id: 'marketscan',
      visible: this.hasMarketScan,
      iconName: 'market_scan_tool_icon',
      displayName: this.translate.instant('CUSTOMER_SCAN'),
      selected: false,
      component: 'MarketScanPage',
      enable: true
    },
    {
      id: 'eventstool',
      visible: this.hasEventsTool,
      iconName: 'events_tool_icon',
      displayName: this.translate.instant('EVENTS'),
      selected: false,
      component: 'EventsToolPageComponent',
      enable: true
    },
    ];
    //added hyperlink list
    this.showHyperlinkList();

  }
  /**
   * Changes the Status of the rep , this has a Rep Service backend component
   *
   * @memberof MenuToggleComponent
   */
  async changeStatus(userState: string) {

    const oldCurrentState = this.repService.getCurrentUserState();

    if (userState === oldCurrentState) return;

    let newStateData = { userState };
    this.trackingService.tracking('LoginStatusChange', TrackingEventNames.LOGIN)
    try {

      //this.userStatusList.forEach((status) => status.disabled = true); //disable while making a request
      this.disableStatus = true;

      await this.repService.setCurrentState(newStateData);
      if (this.uiService.activitiesPageTab == 'scheduler' && newStateData.userState == REP_STATUS.OFFLINE.userState) {
        this.uiService.activitiesPageTab = 'scheduler';
        this.uiService.setTabSwitch(this.uiService.activitiesPageTab);
      }
      const newCurState = this.repService.getCurrentUserState();
      const newPrevState = this.repService.getPreviousUserState();
      this.repService.saveLastUserState(newCurState);
      // Now, the user has changed the status and it's been updated in the service & server.
      // Toggle isOffline variable from device service
      if (newPrevState === REP_STATUS.OFFLINE.userState && newPrevState !== newCurState) {
        // offline to online
        this.device.isOffline = this.device.isUserStateOffline = false;
        this.offlineSync.clear();
        // trigger update only sync
        this.authenticationService.shouldTrySyncingDuringAppLaunch = true;
        this.events.publish('home:runUpdateSync', { syncType: DEVICE_EVENT.userStatusBackToOnline });
      } else if (newCurState === REP_STATUS.OFFLINE.userState && newPrevState !== newCurState) {

        // online to offline
        this.device.isOffline = this.device.isUserStateOffline = true;
        this.offlineSync.setOffline();
      }
      this._initStatusSelectOptions();
      this._toggleAnalyticsButton();
      this.logServices.logDebug(this.userStatus);
      this.events.publish('userStateChanged')
    } catch (error) {
      console.error('changeStatus: ', error);
      // Revert back to previous status since server is not updated.
      if (userState !== REP_STATUS.OFFLINE.userState) {
        this.userStatus = this.repService.getCurrentUserState();
        this.device.isOffline = this.device.isUserStateOffline = this.userStatus === REP_STATUS.OFFLINE.userState;
      } else {
        // In case of offline change, we force it to go to offline state even though the service fails.
        this.repService.setCurrentUserState(REP_STATUS.OFFLINE.userState);
        this.repService.setPreviousUserState(oldCurrentState);
        this.device.isOffline = this.device.isUserStateOffline = this.repService.isOfflineState = true;
        this.repService.saveLastUserState(REP_STATUS.OFFLINE.userState)
      }
      this._initStatusSelectOptions();
      this._toggleAnalyticsButton();
    }
  }

  public openSettings() {

    this.onClickMenuOption({
      id: 'appsettings',
      visible: true,
      iconName: 'appsettings',
      displayName: 'Settings',
      selected: false,
      component: 'AppSettingsPage',
      enable: true
    });
  }

  /**
   * Logout function
   *
   * @private
   * @memberof MenuToggleComponent
   */
  public async logout() {

    this.alertService.showAlert({
      title: this.translate.instant('LOGOUT_QUES_MARK'),
      message: this.translate.instant('LOGOUT_CONFIRMATION')
    }, this.translate.instant('LOGOUT')
    ).then(res => {
      if (res.role == "ok") {
        this.trackingService.tracking('Logout', TrackingEventNames.LOGOUT);
        this.authenticationDataService.logout(this.repService.getCurrentUserState() === REP_STATUS.OFFLINE.userState);
      } else if (res.role == "cancel") {
      }
    });

  }

  setDiabledCSS(option) {
    switch (option.displayName) {
      case "ACCOUNTS":
        return !this.accountService.loadedAccounts;
      case "CONTACTS":
        return !this.contactService.loadedContacts;
      case "PRESENTATIONS":
        return !this.presentationService.loadedPresentation;
    }
  }

  displayLastSync() {
    const lastSync = (localStorage.getItem('localStorageTimestampForSync')) ? new Date(parseInt(localStorage.getItem('localStorageTimestampForSync'))) : 'Not Synced';
    if (isValid(new Date(lastSync))) {
      if (isToday(lastSync)) {
        this.syncDateString = this.translate.instant('TODAY');
      } else if (isYesterday(lastSync)) {
        this.syncDateString = this.translate.instant('YESTERDAY');
      } else {
        this.syncDateString = format(lastSync, this.dateTimeFormatsService.dateToUpper);
      }
      this.syncTimeString = format(lastSync, this.dateTimeFormatsService.timeToUpper);
    } else {
      this.syncDateString = this.syncTimeString = ''
    }
  }

  getNewItemsCount(optionName) {
    let count;
    switch (optionName) {
      case 'accounts':
        count = this.deltaService.deltaRecordsDTO.accounts.length;
        break;
      case 'accountManagement':
        count = this.deltaService.deltaRecordsDTO.accounts.length;
        break;
        // case 'marketingBusiness':
        //   count = this.deltaService.deltaRecordsDTO.accounts.length;
        //   break;
      case 'contacts':
        count = this.deltaService.deltaRecordsDTO.contacts.length;
        break;
      case 'resources':
        count = this.deltaService.deltaRecordsDTO.resources.length;
        break;
      case 'presentations':
        count = this.deltaService.deltaRecordsDTO.presentations.length;
        break;
      default:
        count = 4;
        break;
    }
    return count;
  }

  captureSwipe(ev, option) {
    if (ev && ev.direction == 4) {
      option.selected = true
      this.onClickMenuOption(option)
      if (this.menuCtrl.getMenus()[0].isOpen) {
        this.menuCtrl.getMenus()[0].close()
      }
    }
  }
  getCountUnreadNotifications() {
    return this.assistantService.countUnreadNotifications
  }
  async openMyAccount() {
    if (this.menuCtrl.isOpen()) {
      this.menuCtrl.close();
    }
    // let geeneeModal = await this.modalCtrl.create({component: MyAssistantComponent,componentProps: {}, cssClass: 'geeneeNotifications' })
    // geeneeModal.present();
    //
    let geeneeModal = this.modalCtrl.create({component: MyAssistantComponent,componentProps: {}, cssClass: 'fullscreen'})
    .then((modal)=> modal.present());
    //
  }

  get isSettingsEnabled() {
    return this.authenticationService.hasFeatureAction(FeatureActionsMap.SETTING_TOOL)
  }
  showHyperlinkList(){
    const hyperlinkListFromDynamics = [];
    this.hyperlinkOptions = [];
    let rawData = {...this.externallinksDataService.rawHyperlinkList};
    if (rawData && Object.keys(rawData).length > 0) {
      for (let item in rawData) {
        hyperlinkListFromDynamics.push({
          indskr_hyperlinkid: rawData[item]['indskr_hyperlinkid'],
          indskr_label: rawData[item]['indskr_lable'] ? rawData[item]['indskr_lable'] : rawData[item]['indskr_name'],
          indskr_url: rawData[item]['indskr_url'],
        })
      }
    }

    if (hyperlinkListFromDynamics && hyperlinkListFromDynamics.length > 0) {
      hyperlinkListFromDynamics.forEach(item=> {
        this.hyperlinkOptions.push({
          id: item.indskr_hyperlinkid,
          displayName: item.indskr_label,
          url: item.indskr_url,
          visible: true,
          selected: false,
          enable: true,
          iconName: 'tools_link',
          component: 'HyperlinkExtURL',
        });
      });
    }

    //dummy option to make bottom space
    this.hyperlinkOptions.push({
      id: 'logout',
      visible: true,
      iconName: 'create',
      displayName: 'LOGOUT',
      selected: false,
      component: 'Logout',
      order: 1,
      enable: true
    });
  }
  // To find the tool name entered through the tool menu when moving between affiliated account/contact
  setSelectedOption(optionId) {
    if (this.options) this.options.map(value => value.selected = false);
    if (this.hyperlinkOptions) this.hyperlinkOptions.map(value => value.selected = false);
    if (this.options) {
      this.options.map(value => {
        if (value.id == optionId) value.selected = true;
      });
    }
    this.uiService.selectedOptionInMenu = '';
  }

}
