import { Building } from './../../interfaces/shared/shared.interface';
import { IONote } from '@omni/classes/io/io-note.class';
import { Activity } from './../activity/activity.class';
import { AttendeeFormat, EventApprovalStatus, EventRegistrationStatus } from './../../enums/event/event.enum';
import _ from 'lodash';
import { SampleActivity } from '../activity/sample.activity.class';
/*
* Class representing the data structure for Event
* @export
* @class Event
*/

export class EventActivity extends Activity{

    public ID: string;
    public offlineId: string;  // Required for offline. Might need to ask dynamics to have this attribute added
    public name: string;
    public _startDate:Date;
    public endDate: Date
    public locationId: string;
    public locationFormatted: string;
    public eventGoal: string;
    public eventGoalFormatted: string;
    public eventGoalStateCode: number;
    public eventType: string;
    public eventTypeFormatted: string;
    public eventTypeStateCode: number;
    public eventSubType: string;
    public eventSubTypeFormatted: string;
    public eventSubTypeStateCode: number;
    public notes: string;
    public statecode: number;
    public statuscode: number;
    public formattedStatus: string;
    public pendingPushToDynamics: boolean;
    public modifiedOn:Date;
    public isHardDeleted: boolean = false;
    public isModified: boolean = false;
    public ownerId:string;
    public partners:string = '';
    public positionId:string;
    public completedby:string;
    public createdby:string;
    public _checkinTime:Date;
    public _createdOn:Date;
    public conflictingEventIds: Map<string, boolean>;
    public configuredFields: {[key: string]: any} = {};
    public msevtmgt_maximumeventcapacity: number;
    public attendeeFormat: AttendeeFormat;
    public cutOffDate: string;
    public selectionCriteria: string;
    public annotations: IONote[] = [];
    public allocations: any[] = [];

    public accounts:Array<{
        id: string,
        name: string,
        statecode: number,
        isDeleted: boolean,
    }> = [];

    public participants:Array<EventParticipant> = [];

    public covisitors:Array<{
        id: string,
        name: string,
        teamMemberId: string,
        teamMemberStatecode: number,
        isDeleted: boolean,
    }> = [];

    public products:Array<{
        id: string,
        name: string,
        statecode: number,
        isDeleted:boolean,
        ownerId?: string
    }> = [];

    public speakers:Array<{
        id: string,
        engagementId: string,
        name: string,
        statecode: number,
        isDeleted: boolean,
    }> = [];
    public passes : EventPass[] = [];
    public presentations:Array<EventPresentations> = [];
    public offlineApprovalSubmit: boolean;
    public approvalStatus: EventApprovalStatus;
    public budgetAllocated: number;
    public currencyId: string;
    public currencyName: string;
    public building: Building;

    constructor(raw){

        super(raw);

        this.ID = raw['msevtmgt_eventid'];
        this.statecode = raw['statecode'];
        this.statuscode = raw['statuscode'];
        this.offlineId = raw['indskr_externalid'] || '';
        this.completedby=raw['completedby@OData.Community.Display.V1.FormattedValue'] || '';
        this.name = raw['msevtmgt_name'] || '';
        this.createdby = raw['ac.createdby@OData.Community.Display.V1.FormattedValue'] || '';
        this.attendeeFormat = raw['indskr_attendeeformat'] ?? undefined;
        this.cutOffDate = raw['indskr_cutoffdate'] ? raw['indskr_cutoffdate'] : undefined;
        this.selectionCriteria = raw['indskr_selectioncriteria'] || undefined;
      this.msevtmgt_maximumeventcapacity = raw['msevtmgt_maximumeventcapacity'];
        try{
          this._checkinTime=raw['ac.msevtmgt_checkintime@OData.Community.Display.V1.FormattedValue'] ? new Date(raw['ac.msevtmgt_checkintime@OData.Community.Display.V1.FormattedValue']):null;
        }catch{
          this._checkinTime=raw['ac.msevtmgt_checkintime@OData.Community.Display.V1.FormattedValue'] ? new Date(parseInt(raw['ac.msevtmgt_checkintime@OData.Community.Display.V1.FormattedValue'])):null;
        }
        try{
          this._createdOn=raw['ac.createdon@OData.Community.Display.V1.FormattedValue'] ? new Date(raw['ac.createdon@OData.Community.Display.V1.FormattedValue']):null;
        }catch{
          this._createdOn=raw['ac.createdon@OData.Community.Display.V1.FormattedValue'] ? new Date(parseInt(raw['ac.createdon@OData.Community.Display.V1.FormattedValue'])):null;
        }

        try {
            this._startDate = raw['msevtmgt_eventstartdate@OData.Community.Display.V1.FormattedValue'] ? new Date(raw['msevtmgt_eventstartdate@OData.Community.Display.V1.FormattedValue']) : null;
        } catch (error) {
            this._startDate = raw['msevtmgt_eventstartdate@OData.Community.Display.V1.FormattedValue'] ? new Date(parseInt(raw['msevtmgt_eventstartdate@OData.Community.Display.V1.FormattedValue'])) : null;
        }
        try {
            this.endDate = raw['msevtmgt_eventenddate@OData.Community.Display.V1.FormattedValue'] ? new Date(raw['msevtmgt_eventenddate@OData.Community.Display.V1.FormattedValue']) : null;
        } catch (error) {
            this.endDate = raw['msevtmgt_eventenddate@OData.Community.Display.V1.FormattedValue'] ? new Date(parseInt(raw['msevtmgt_eventenddate@OData.Community.Display.V1.FormattedValue'])) : null;
        }
        this.locationId = raw['locationId'] || '';
        this.locationFormatted = raw['indskr_location'] || '';
        this.eventGoalStateCode = raw['eventgoal_statecode'];
        if (raw['eventgoal_statecode'] == 0 || !(this.statecode == 0 && this.statuscode == 1)) {
          this.eventGoal = raw['_indskr_eventgoalid_value'] || '';
          this.eventGoalFormatted = raw['_indskr_eventgoalid_value@OData.Community.Display.V1.FormattedValue'] || '';
        }
        this.eventTypeStateCode = raw['eventtype_statecode'];
        if(raw['eventtype_statecode'] == 0 || !(this.statecode == 0 && this.statuscode == 1)){
            this.eventType = raw['_indskr_event_type_value'] || '';
            this.eventTypeFormatted = raw['_indskr_event_type_value@OData.Community.Display.V1.FormattedValue'] || '';
        }
        this.eventSubTypeStateCode = raw['eventsubtype_statecode'];
        if(raw['eventsubtype_statecode'] == 0 || !(this.statecode == 0 && this.statuscode == 1)){
            this.eventSubType = raw['_indskr_eventsubtype_value'] || '';
            this.eventSubTypeFormatted = raw['_indskr_eventsubtype_value@OData.Community.Display.V1.FormattedValue'] || '';
        }
        this.notes = raw['msevtmgt_description'] || '';
        this.formattedStatus = raw['statuscode@OData.Community.Display.V1.FormattedValue'] || '';
        this.modifiedOn = raw['modifiedon'] ? new Date(parseInt(raw['modifiedon'])) : new Date(0);
        this.pendingPushToDynamics = raw['pendingPushToDynamics'] || false;
        this.ownerId = raw['_ownerid_value'] || '';
        if (!this.meetingOwnerName) {
            this.meetingOwnerName = raw['_ownerid_value@OData.Community.Display.V1.FormattedValue'] || '';
        }
        this.partners = raw['indskr_partners'] || '';
        this.positionId = raw['_indskr_positionid_value'] || '';

        if(raw['products'] && Array.isArray(raw['products']) && raw['products'].length != 0 && raw['products'][0] != null){
            this.products = [];
            raw['products'].forEach(rawProduct=> {
                if(rawProduct['product_id'] && rawProduct['product_name'] && ((this.statecode == 0 && this.statuscode == 1 && rawProduct['product_statecode'] == 0) || !(this.statecode == 0 && this.statuscode == 1))){
                    let idx = this.products.findIndex(a=> a.id == rawProduct['product_id']);
                    if(idx <0){
                        this.products.push({
                            id:rawProduct['product_id'],
                            name: rawProduct['product_name'],
                            statecode: rawProduct['product_statecode'],
                            isDeleted: rawProduct['isDeleted'] || false,
                            ownerId: raw['_ownerid_value'],
                        });
                    }
                }
            });
        }

        if(raw['covisitors'] && Array.isArray(raw['covisitors']) && raw['covisitors'].length != 0 && raw['covisitors'][0] != null){
            this.covisitors = [];
            raw['covisitors'].forEach(rawCovisitor=> {
                if(rawCovisitor['user'] && rawCovisitor['user@OData.Community.Display.V1.FormattedValue']){
                    let idx = this.covisitors.findIndex(a=> a.id == rawCovisitor['user']);
                    if(idx <0){
                        this.covisitors.push({
                            id:rawCovisitor['user'],
                            name: rawCovisitor['user@OData.Community.Display.V1.FormattedValue'],
                            teamMemberId: rawCovisitor['eventteammemberid'],
                            isDeleted: (rawCovisitor['teammember_statecode'] != 0) ? true : rawCovisitor['isDeleted'] || false,
                            teamMemberStatecode: rawCovisitor['teammember_statecode'],
                        });
                    }
                }
            });
        }

        if(raw['speakers'] && Array.isArray(raw['speakers']) && raw['speakers'].length != 0 && raw['speakers'][0] != null){
            this.speakers = [];
            raw['speakers'].forEach(rawSpeaker=> {
                if(rawSpeaker['speaker'] && rawSpeaker['speaker@OData.Community.Display.V1.FormattedValue']){
                    let idx = this.speakers.findIndex(a=> a.id == rawSpeaker['speaker']);
                    if(idx <0){
                        this.speakers.push({
                            id: rawSpeaker['speaker'],
                            engagementId: rawSpeaker['speakerengagementid'],
                            name: rawSpeaker['speaker@OData.Community.Display.V1.FormattedValue'],
                            statecode: rawSpeaker['speaker_statecode'],
                            isDeleted: rawSpeaker['isDeleted'] || false,
                        });
                    }
                }
            });
        }

        if(raw['accounts'] && Array.isArray(raw['accounts']) && raw['accounts'].length != 0 && raw['accounts'][0] != null){
            this.accounts = [];
            raw['accounts'].forEach(rawAccount=> {
                if(rawAccount['account_id'] && rawAccount['account_name']){
                    let idx = this.accounts.findIndex(a=> a.id == rawAccount['account_id']);
                    if(idx <0 ){
                        this.accounts.push({
                            id: rawAccount['account_id'],
                            name: rawAccount['account_name'],
                            statecode: rawAccount['account_statecode'],
                            isDeleted: rawAccount['isDeleted'] || false,
                        });
                    }
                }
            });
        }

        if(raw['participants'] && Array.isArray(raw['participants']) && raw['participants'].length != 0 && raw['participants'][0] != null){
            this.participants = [];
            raw['participants'].forEach(rawParticipant=> {
                if(rawParticipant['contactid'] && rawParticipant['contactid@OData.Community.Display.V1.FormattedValue']){
                    let idx = this.participants.findIndex(a=> a.id == rawParticipant['contactid']);
                    if(idx <0){
                        let notes: IONote[] = [];
                        const participant: any = {
                          id: rawParticipant['contactid'],
                          name: rawParticipant['contactid@OData.Community.Display.V1.FormattedValue'],
                          ownerId: rawParticipant['owner_id'],
                          signature: '',
                          checkinId: rawParticipant[''],
                          statecode: rawParticipant['contact_statecode'],
                          registrationId: rawParticipant['eventregistrationid'],
                          isDeleted: rawParticipant['isDeleted'] || false,
                          customerId: rawParticipant['contact_externalid'] || '',
                          title: rawParticipant['contact_title@OData.Community.Display.V1.FormattedValue'] || '',
                          firstName: rawParticipant['contact_firstname'] || '',
                          middleName: rawParticipant['contact_middlename'] || '',
                          lastName: rawParticipant['contact_lastname'] || '',
                          primaryAccount: rawParticipant['contact_primaryaccount@OData.Community.Display.V1.FormattedValue'] || '',
                          primarySpecialty: rawParticipant['contact_specialtyid@OData.Community.Display.V1.FormattedValue'] || '',
                          msevtmgt_checkintime: rawParticipant['msevtmgt_checkintime'] || rawParticipant['checkInTime'],
                          indskr_reasons: rawParticipant['indskr_reasons'] || null,
                          eventPasses: rawParticipant['eventPasses'] || []
                      };
                      if (Array.isArray(rawParticipant.annotations)) {
                        for (const rawNote of rawParticipant.annotations) {
                          // Uses timestamp in other places probably because
                          // to support local create scenarios where timestamp
                          // values are stored instead of ISO format.
                          // Following convention not to break other places..
                          rawNote.createdon = new Date(rawNote.createdon).getTime().toString();
                          notes.push(new IONote(rawNote));
                        }
                        participant.notes = notes;
                      }
                      if (rawParticipant['isAddedBack'] === true) {
                        participant.isAddedBack = true;
                      }
                      if (rawParticipant['shouldClearResponse'] === true) {
                        participant.shouldClearResponse = true;
                      }
                      this.participants.push(participant);
                    }
                }
            });
        }

      if (raw['annotations'] && Array.isArray(raw['annotations']) && raw['annotations'].length != 0 && raw['annotations'][0] != null) {
        let notes: IONote[] = [];
        this.annotations = [];
        for (const rawNote of raw['annotations']) {
          // Uses timestamp in other places probably because
          // to support local create scenarios where timestamp
          // values are stored instead of ISO format.
          // Following convention not to break other places..
          const note = {
            annotationid: rawNote['annotation_id'] || rawNote['annotationid'],
            isdocument: rawNote['annotation_isdocument'] || rawNote['isdocument'],
            filename: rawNote['annotation_filename'] || rawNote['filename'],
            createdon: rawNote['annotation_createdon'] || rawNote['createdon'],
            mimetype: rawNote['annotation_mimetype'] || rawNote['mimetype'],
            notetext: rawNote['annotation_notetext'] || rawNote['notetext'],
            ownerid: rawNote['annotation_ownerid'] || rawNote['ownerId'],
            ownerName: rawNote['annotation_ownerid@OData.Community.Display.V1.FormattedValue'] || rawNote['ownerName']
          }
          note.createdon = note.createdon instanceof Date ? new Date(note.createdon).getTime().toString() : note.createdon;
          notes.push(new IONote(note));
        }
        this.annotations = notes;
      }

      if (raw['allocations'] && Array.isArray(raw['allocations']) && raw['allocations'].length != 0 && raw['allocations'][0] != null) {
        this.allocations = raw['allocations'];
      }

        if(raw['checkIns'] && Array.isArray(raw['checkIns']) && raw['checkIns'].length != 0 && raw['checkIns'][0] != null){
            raw['checkIns'].forEach(rawCheckin => {
                if(rawCheckin ['contactid']){
                    let idx = this.participants.findIndex(a=> a.id == rawCheckin ['contactid']);
                    if(idx >= 0){
                        this.participants[idx].signature = rawCheckin['signature'];
                        this.participants[idx].checkinId = rawCheckin['checkinid'] || '';
                    }
                }
            });
        }
        if(raw['presentations'] && Array.isArray(raw['presentations']) && raw['presentations'].length != 0 && raw['presentations'][0] != null){
            this.presentations = [];
            raw['presentations'].forEach(rawPres=> {
                if(rawPres['presentationid'] && rawPres['presentation_title'] && ((this.statecode == 0 && this.statuscode == 1 && rawPres['presentation_statecode'] == 0) || !(this.statecode == 0 && this.statuscode == 1))){
                    let idx = this.presentations.findIndex(a=> a.id == rawPres['presentationid']);
                    if(idx <0){
                        this.presentations.push({
                            id: rawPres['presentationid'],
                            name: rawPres['presentation_title'],
                            statecode: rawPres['presentation_statecode'],
                            thumbnailUrl: rawPres['ckmthumbnailurl'],
                            isDeleted: rawPres['isDeleted'] || false,
                            downloaded: rawPres['downloaded'] || false
                        });
                    }
                }
            });
        }
        this.passes = raw['passes'] ? raw['passes'] : [];

        if (raw['configuredFields']) {
          this.configuredFields = raw['configuredFields'];
        }
        if (raw['indskr_approvalstatus']) {
          this.approvalStatus = raw['indskr_approvalstatus'];
        }
        if (raw['offlineApprovalSubmit']) {
          this.offlineApprovalSubmit = raw['offlineApprovalSubmit'];
        }
        if (raw['msevtmgt_budgetallocated']) {
          this.budgetAllocated = raw['msevtmgt_budgetallocated'];
        }
        if (raw['_transactioncurrencyid_value']) {
          this.currencyId = raw['_transactioncurrencyid_value'];
          if (raw['_transactioncurrencyid_value@OData.Community.Display.V1.FormattedValue']) {
            this.currencyName = raw['_transactioncurrencyid_value@OData.Community.Display.V1.FormattedValue'];
          }
        }
        if (raw['_msevtmgt_building_value']) {
          this.building = {
            msevtmgt_buildingid: raw['_msevtmgt_building_value'],
            msevtmgt_name: raw['_msevtmgt_building_value@OData.Community.Display.V1.FormattedValue'] ?? null,
            cityId: raw['msevtmgt_building_city'] ?? null,
            cityName: raw['msevtmgt_building_city@OData.Community.Display.V1.FormattedValue'] ?? null,
          };
        }
    }

    public get startDate(): Date {
        return this._startDate;
    }
  public get checkinTime(): Date {
    return this._checkinTime;
  }

  public get createdOn(): Date {
    return this._createdOn;
  }
}

export interface EventPresentations {
    id: string,
    name: string,
    statecode: number,
    thumbnailUrl: string,
    isDeleted: boolean,
    downloaded: boolean
}

export interface EventPass {
  msevtmgt_passId: string,
  msevtmgt_passName: string,
  msevtmgt_noOfPassesLeft: number,
  msevtmgt_attendeepassId: string
}
export interface EventParticipant {
    id: string,
    name: string,
    ownerId: string,
    customerId: string,
    registrationId:string,
    signature: string,
    checkinId: string,
    statecode: number,
    isDeleted: boolean,
    isAddedBack: boolean,
    shouldClearResponse?: boolean,
    title: string,
    firstName: string,
    middleName: string,
    lastName: string,
    primaryAccount: string,
    primarySpecialty: string,
    msevtmgt_checkintime: Date;
    indskr_reasons: EventRegistrationStatus;
    notes?: IONote[];
    eventPasses?: EventPass[]
}
