import { ProcedureTrackerActivity } from './../../../classes/activity/procedure-tracker.activity.class';
import { ProcedureTrackerActivityDataService } from './../../../data-services/procedure-tracker-activity/procedure-tracker-activity.data.service';
import { EventActivity, EventPresentations } from './../../../classes/events-tool/event.class';
import { Component, ChangeDetectorRef, Output, EventEmitter, ViewChild, ElementRef, Input } from "@angular/core";
import { IndSectionHeaderViewDataModel } from "../../../models/indSectionHeaderDataModel";
import { TranslateService } from "@ngx-translate/core";
import { ActivityService, ActivitySource } from "../../../services/activity/activity.service";
import { UIService, PresentationView } from "../../../services/ui/ui.service";
import { PresentationService } from "../../../services/presentation/presentation.service";
import { NavigationService, PageName, ChildNavNames } from "../../../services/navigation/navigation.service";
import { PresentationPageComponent } from "../../../pages/presentation/presentation";
import { Presentation, Page } from "../../../classes/presentation/presentation.class";
import { DeviceService } from "../../../services/device/device.service";
import { NotificationService, ToastStyle } from "../../../services/notification/notification.service";
import { MeetingActivityState, Activity, EmbeddedInteraction, EmbeddedInteractionType, ActivityType } from "../../../classes/activity/activity.class";
import { AppointmentActivity } from "../../../classes/activity/appointment.activity.class";
import { PresentationMeetingComponent } from "../../../pages/presentation/presentation-meeting";
import { LoadingController, ModalController, } from "@ionic/angular";
import { Events } from '@omni/events';
import { MeetingDataService } from "../../../data-services/meeting/meeting.data.service";
import { Subscription } from "rxjs";
import { TrackService, TrackingEventNames } from "../../../services/logging/tracking.service";
import { AlertService } from "../../../services/alert/alert.service";
import { EmailViewType, EmailActivity } from "../../../classes/activity/email.activity.class";
import { SamplingDetailsViewMode, SampleService } from "../../../services/sample/sample.service";
import { CaseManagementService } from "../../../services/case-management/case-management.service";
import { EmailService } from "../../../services/email-templates/email.service";
import { SampleActivity, CreateSampleDropRequestBody } from "../../../classes/activity/sample.activity.class";
import { CaseActivity } from "../../../classes/case-intake/case-activity.class";
import { EventsService } from "../../../services/events/events.service";
import { SampleDataService } from "../../../data-services/sample/sample.data.service";
import { AuthenticationService } from "../../../services/authentication.service";
import { FeatureActionsMap } from "../../../classes/authentication/user.class";
import { MeetingStructureService } from "../../../services/meeting-structure/meeting-structure.service";
import { NewActivityComponent } from "../../../components/activity/new-activity/new-activity";
import { CallPlanOfflineService } from "../../../services/call-plan/call-plan.offline.service";
import { Resource } from '../../../classes/resource/resource.class';
import _ from 'lodash';
import { EventsToolDataService } from '../../../data-services/event/events-tool.data.service';
import { FollowUpActivityDataService, OperationDetail } from '../../../data-services/follow-up-activity/follow-up-activity.data.service';
import { CdkDropListGroup, CdkDropList, CdkDrag, moveItemInArray, CdkDragMove } from '@angular/cdk/drag-drop';
import { ViewportRuler } from '@angular/cdk/scrolling';
import { WebsocketDataService } from '@omni/data-services/websocket/websocket.data.service';
import { SurgeryOrderActivity } from '@omni/classes/activity/surgery-order.activity.class';
import { SurgeryOrderActivityDataService } from '@omni/data-services/surgery-order-activity/surgery-order-activity.data.service';
import { FollowUpActivity } from '@omni/classes/activity/follow-up-action.activity.class';
import { CustomerAssessment } from '@omni/classes/customer-assessment/customer-assessment.class';
import { CustomerSurveyService } from '@omni/services/customer-survey/customer-survey.service';
import { AssessmentTemplate, SurveyCategory, SurveyStatus, TemplateType } from '@omni/classes/customer-assessment/assessment-template.class';
import { SurveyDetailsComponent } from '@omni/components/survey/survey-details/survey-details';
import { CustomerAssessService } from '@omni/services/customer-assess/customer-assess.service';
import { ContactOfflineService } from '@omni/services/contact/contact.service';
import { DB_KEY_PREFIXES } from '@omni/config/pouch-db.config';
import { PresentationPreviewComponent } from '@omni/components/presentation/presentation-preview/presentation-preview';
import { AccountOfflineService } from '@omni/services/account/account.offline.service';

@Component({
    selector: 'meeting-structure',
    templateUrl: 'meeting-structure.html',
  styleUrls:['meeting-structure.scss']
})

export class MeetingStructure {
    @Output() openNewActivityPage = new EventEmitter();

    meetingStructureHeader: IndSectionHeaderViewDataModel;
    private IcurrentMeetingStructureSubscription: Subscription;
    public embeddedInteractions: EmbeddedInteraction[];
    private isReadOnlyJointMeeting: boolean = false;
    public contentDisabled: boolean = false;
    public activityDisabled: boolean = false;
    public inMeetingActivitiesEnabled: boolean = false;
    public MEETING_STRUCTURE: string = "MEETING_STRUCTURE";

    @Input() meetingStructureAccessedFrom: string;
    @Input() backgroundUploadInProgress: boolean;
    @ViewChild(CdkDropListGroup) listGroup: CdkDropListGroup<CdkDropList>;
    @ViewChild(CdkDropList) placeholder: CdkDropList;
    @Output() isFormDirty = new EventEmitter<boolean>();
    //@Output() openKeyMessageSentiment = new EventEmitter();

    isAccountVisitEnabled = false;
    isAccountVisitRecord = false;
    isAccountVisitNestedMeeting = false;
    isInStoreChildMeeting = false;

    public target: CdkDropList;
    public targetIndex: number;
    public source: CdkDropList;
    public sourceIndex: number;
    public dragIndex: number;
    public activeContainer;

    constructor(private translate: TranslateService,
        private activityService: ActivityService,
        private authenticationService: AuthenticationService,
        public uiService: UIService,
        private presentationService: PresentationService,
        private navService: NavigationService,
        public device: DeviceService,
        private notificationService: NotificationService,
        private loadingController: LoadingController,
        private meetingDataService: MeetingDataService,
        public deviceService: DeviceService,
        private alertService: AlertService,
        private meetingStructureService: MeetingStructureService,
        private trackingService: TrackService,
        private emailService: EmailService,
        private sampleService: SampleService,
        private caseManagementService: CaseManagementService,
        private callPlanService: CallPlanOfflineService,
        public sampleDataService: SampleDataService,
        private eventsToolDataService: EventsToolDataService,
        private viewportRuler: ViewportRuler,
        private websocket: WebsocketDataService,
        public events:Events,
        private surgeryOrderActivityDataService: SurgeryOrderActivityDataService,
        private procedureTrackerActivityDataService: ProcedureTrackerActivityDataService,
        private followupActivityDataService: FollowUpActivityDataService,
        private customerSurveyService: CustomerSurveyService,
        private customerAssessService: CustomerAssessService,
        private contactService: ContactOfflineService,
        private accountService: AccountOfflineService
    ) {
        this.target = null;
        this.source = null;
    }

    ngOnInit() {
        this.inMeetingActivitiesEnabled = this.isInMeetingActivityEnabled;
        this.translate.onLangChange.subscribe(data => {
          this.initMeetingStructureSectionDivider();
        })
    }

    ngAfterViewInit() {
        let phElement = this.placeholder.element.nativeElement;

        phElement.style.display = 'none';
        phElement.parentElement.removeChild(phElement);
        this.IcurrentMeetingStructureSubscription = this.meetingStructureService.currentMeetingStructureObserver.subscribe((value: EmbeddedInteraction[]) => {
            this.inMeetingActivitiesEnabled = this.isInMeetingActivityEnabled;
            this.setViewData(value);
        });
      }

    private get isInMeetingActivityEnabled() {
        return (this.activityService.selectedActivity instanceof AppointmentActivity &&
            !this.activityService.selectedActivity.isLiveMeet) &&
            (this.meetingStructureAccessedFrom == 'ActivitiesDetailsPanePage') &&
            (this.authenticationService.hasFeatureAction(FeatureActionsMap.ALLOCATION_TOOL) ||
                this.authenticationService.hasFeatureAction(FeatureActionsMap.MESSAGE_ACTIVITY)
                || this.authenticationService.hasFeatureAction(FeatureActionsMap.CASE_INTAKE)
                || this.authenticationService.hasFeatureAction(FeatureActionsMap.PROCEDURE_LOG)
                || this.authenticationService.hasFeatureAction(FeatureActionsMap.FOLLOW_UP_ACTION_ACTIVITY))
                || this.authenticationService.hasFeatureAction(FeatureActionsMap.CUSTOMER_SURVEY)
                || this.authenticationService.hasFeatureAction(FeatureActionsMap.ACCOUNT_SURVEY);
    }

    ngOnChanges() {
      this.setViewData(this.embeddedInteractions);
    }


    public isScrapEnabled(embeddedInteraction: EmbeddedInteraction): boolean {
        if (this.activityDisabled) return false;
        if (embeddedInteraction.type === EmbeddedInteractionType.Activity) {
            const activity: Activity = <Activity>embeddedInteraction.interaction;
            if (activity instanceof CaseActivity && String(activity._case_status_value).toLocaleLowerCase() != 'open') {
                if (String(activity._case_status_value).toLocaleLowerCase() == 'pending sync') {
                    return true;
                } else {
                    return false;
                }
            }
            else if (activity instanceof EmailActivity && activity.emailStatus != 1) {
                return false;
            }
            else if (activity instanceof SampleActivity && activity.state != 0) {
                return false;
            } else if (activity instanceof SurgeryOrderActivity && activity.state != 0) {
              return false;
            } else if (activity instanceof FollowUpActivity && activity.state != 0) {
              return false;
            }
        }
        return true;
    }

    private setViewData(value: EmbeddedInteraction[]) {
        if (this.meetingStructureAccessedFrom == 'ActivitiesDetailsPanePage'){
            this.isReadOnlyJointMeeting = this.meetingStructureService.jointMeetingStatus;
            this.contentDisabled = (this.backgroundUploadInProgress || this.activityService.selectedActivity.state === MeetingActivityState.Completed) || this.isReadOnlyJointMeeting || this.activityService.teamViewActive || this.activityService.selectedActivity.isReopened || this.activityService.selectedActivity?.isDiffPosition;
            this.activityDisabled = this.backgroundUploadInProgress || this.isReadOnlyJointMeeting || this.activityService.teamViewActive || this.authenticationService.user.systemUserID != this.activityService.selectedActivity.ownerId || this.activityService.selectedActivity?.isDiffPosition;
        } else if (this.meetingStructureAccessedFrom == 'EventDetailsPage'){
            let activity = this.activityService.selectedActivity as EventActivity;
            if(activity){
                if(this.authenticationService.hasFeatureAction(FeatureActionsMap.EVENT_UPDATE)){
                    if (this.authenticationService.user.systemUserID === activity.ownerId) {
                        let isOwner = this.authenticationService.user.systemUserID === activity.ownerId;
                        this.contentDisabled = !(isOwner && ((activity.statecode === 0 && activity.statuscode === 1) || (activity.statecode === 0 && activity.statuscode === 548910007)));
                    } else{
                        this.contentDisabled = activity.statecode  === 1 ? true : false;
                    }
                } else{
                    this.contentDisabled = !(this.authenticationService.user.systemUserID == activity.ownerId && ((activity.statecode === 0 && activity.statuscode === 1) || (activity.statecode === 0 && activity.statuscode === 548910007)))
                }
            }
        }
        this.initMeetingStructureSectionDivider();
        if (value && value.length > 0) {
          let activity = this.activityService.selectedActivity as EventActivity;
            value.forEach((ei: EmbeddedInteraction) =>{
              ei.completed = !this.isScrapEnabled(ei);
              ei['disableRemoveButton'] = this.authenticationService.user.systemUserID !== activity.ownerId
            });
        }
        this.embeddedInteractions = value;
    }

    private initMeetingStructureSectionDivider() {
        let buttons = [];


      if (this.authenticationService.hasFeatureAction(FeatureActionsMap.ACCOUNT_VISIT)
        && this.activityService.selectedActivity.type === ActivityType.Appointment) {
        this.isAccountVisitEnabled = true;
        this.isAccountVisitRecord = !!((this.activityService.selectedActivity as AppointmentActivity).indskr_isparentcall);
        this.isAccountVisitNestedMeeting = !!((this.activityService.selectedActivity as AppointmentActivity).indskr_parentcallid);
        this.isInStoreChildMeeting = this.isAccountVisitNestedMeeting && (this.activityService.selectedActivity as AppointmentActivity).activityTypeName === this.translate.instant("INSTORE");
      }
        
        buttons.push({
            id: 'content',
            text: this.translate.instant('CONTENT'),
            isDisabled: this.contentDisabled,
        });
        if (!(this.isAccountVisitEnabled && this.isAccountVisitRecord) && this.inMeetingActivitiesEnabled || (this.meetingStructureAccessedFrom == 'EventDetailsPage' &&
          this.authenticationService.hasFeatureAction(FeatureActionsMap.ALLOCATION_TOOL))) {
            buttons.push({
                id: 'add-activity',
                isDisabled: this.contentDisabled || (this.meetingStructureAccessedFrom == 'EventDetailsPage' && this.device.isOffline),
                iconClass: 'pluse-icon',
                tooltip: 'New Activity'
            });
        }
        this.meetingStructureHeader = {
            id: 'meeting-structure-header',
            title: this.meetingStructureAccessedFrom == 'ActivitiesDetailsPanePage' ? this.translate.instant('MEETING_STRUCTURE_CAMEL_CASE') : this.translate.instant('EVENT_STRUCTURE_CAP'),
            controls: buttons,
        };
    }

    onSectionHeaderControlClick(id: string) {
        switch (id) {
            case "content":
                this.openContent()
                break;
            case "add-activity":
                this.handlePlusButton(null);
                break;
            // case "key-message-sentiment":
            //     this.openKeyMessageSentiment.emit(true);
            //     break;
            default:
                console.info("Unhandled switch case");
        }
    }

    async openContent() {
        if (!this.isReadOnlyJointMeeting && this.meetingStructureAccessedFrom == 'ActivitiesDetailsPanePage') {
            this.uiService.prevView = this.uiService.activeView;
            this.presentationService.viewMode = PresentationView.ADDTOMEETING;
        } else if (this.meetingStructureAccessedFrom == 'EventDetailsPage') {
            this.uiService.prevView = 'eventDetails';
            this.presentationService.viewMode = PresentationView.ADDTOEVENT;
        }
        this.meetingStructureService.setCurrentMeetingActivity(null);
        await this.navService.pushWithPageTracking(PresentationPageComponent, PageName.PresentationPageComponent, null, PageName.PresentationPageComponent);
    }

    openEmbeddedInteraction(embeddedInteraction: EmbeddedInteraction) {
        // if (this.meetingStructureAccessedFrom == 'EventDetailsPage') return;
        if (embeddedInteraction.type === EmbeddedInteractionType.Content) {
            this.openContentInMeeting(embeddedInteraction);
        } else if (embeddedInteraction.type === EmbeddedInteractionType.Survey) {
            this.openSurveyInMeeting(<CustomerAssessment>embeddedInteraction.interaction);
        } else {
            this.openActivityDetails(<Activity>embeddedInteraction.interaction);
        }
    }

    openContentInMeeting(embeddedInteraction: EmbeddedInteraction) {
        if (this.activityService.selectedActivity.state == MeetingActivityState.Completed || this.activityService.selectedActivity.isReopened || this.backgroundUploadInProgress) return;
        const content: Presentation | Resource = <Presentation | Resource>embeddedInteraction.interaction;
        let isAccountVisitAndParent = false;
        if (!this.isReadOnlyJointMeeting && !this.activityService.teamViewActive) {
            this.trackingService.tracking('MeetingPresentationClick', TrackingEventNames.ACTIVITY);
            if (this.device.isNativeApp && (this.device.isOffline || this.device.isDeviceRealOffline) && !content.downloaded) {
                this.notificationService.notify(this.translate.instant('PLEASE_DOWNLOAD_CONTENT_TO_VIEW__WHILE_OFFLINE'), "activity-details", "top", ToastStyle.DANGER, 3000, true);
                return;
            }
            if (this.activityService.selectedActivity instanceof AppointmentActivity) {
                this.websocket.lastResourceSelected = undefined;
                this.uiService.activeView = 'Meeting';
                this.presentationService.setCarouselBriefcase(this.activityService.selectedActivity.presentations);
                this.meetingStructureService.setCurrentMeetingActivity(embeddedInteraction);
                this.presentationService.setCurrentSelectedPres(content);
                const page: Page = _.isEmpty(this.presentationService.presPages) ? null : this.presentationService.presPages[0];
                this.presentationService.setCurrentSelectedPresPage(page);
                isAccountVisitAndParent = (this.isAccountVisitEnabled && this.isAccountVisitRecord) && !this.isAccountVisitNestedMeeting;
            }
            this.presentationService.viewMode = PresentationView.MEETING;
            if(isAccountVisitAndParent){
              this.navService.pushChildNavPageWithPageTracking(PresentationPreviewComponent, PageName.PresentationPreviewComponent,PageName.ActivitiesPageComponent, { viewMode : PresentationView.MENU });
              this.navService.setChildNavRightPaneView(true);
            }else{
              this.navService.pushWithPageTracking(PresentationMeetingComponent, PageName.PresentationMeetingComponent, null, PageName.PresentationMeetingComponent);
            }

        }
    }

    async openSurveyInMeeting(survey: any) {
      if(this.device.isOffline) {
        this.notificationService.notify(this.translate.instant("SURVEY_ACTIVITIES_NOTIFY_ONLINE"), "survey-in-meeting", "top", ToastStyle.DANGER, 3000, true);
        return;
      }
      if(!_.isEmpty(survey)) {
        await this.uiService.displayLoader();
        const surveyTemplate = this.customerAssessService.surveytemplates.filter(template => template.indskr_type == TemplateType.SURVEY && new Date(template.indskr_validfrom) <= new Date() && new Date(template.indskr_validto) >= new Date);
        const targetTemplateId = survey['_indskr_template_value'] || survey['indskr_template'];
        const foundTemplate: AssessmentTemplate = surveyTemplate.find(template=> template.indskr_assessmenttemplateid == targetTemplateId);
        const surveyFor: string = foundTemplate.indskr_entity == SurveyCategory.CONTACT ? SurveyCategory.CONTACT : SurveyCategory.ACCOUNT;
        if(!_.isEmpty(foundTemplate)) {
          this.customerSurveyService.setCurrentSurvey(foundTemplate);
        }else {
          console.log("Survey template is not avaialble");
          return;
        }
        if(surveyFor == SurveyCategory.CONTACT) {
          this.customerSurveyService.selectedContactForSurvey = this.contactService.getContactByID(survey.indskr_entityid);
          this.customerSurveyService.selectedAccountForSurvey = null;
        } else {
          this.customerSurveyService.selectedContactForSurvey = null;
          this.customerSurveyService.selectedAccountForSurvey = this.accountService.getAccountById(survey.indskr_entityid);
        }
        this.customerSurveyService.inMeetingSelectedSurveyId = survey.indskr_customerassessmentid

        const targetAppointmentId = survey['_indskr_appointment_value'] || survey['indskr_appointmentid'];
        await this.navService.pushChildNavPageWithPageTracking(SurveyDetailsComponent, PageName.SurveyDetailsComponent, PageName.ActivitiesDetailsPaneComponent, { appointmentId: targetAppointmentId, from: ActivitySource.MEETING_DETAIL });
        this.navService.setChildNavRightPaneView(true);

        // if(survey?.surveyStatus == SurveyStatus.SUBMITTED) {
          const responseData = await this.customerAssessService.fetchContactSurveyForDetails(survey, surveyFor, true);
          if(!_.isEmpty(responseData)) {
            survey.responses = responseData;
          }
        // }
        this.uiService.dismissLoader();
        this.isFormDirty.emit(false);
        this.customerSurveyService.setSurveyPreviewMode(false, survey);
      }
    }

    openActivityDetails(activity: Activity) {
        switch (activity.type) {
            case ActivityType.Sample:
                this.sampleService.samplingDetailsViewMode = this.meetingStructureAccessedFrom == 'EventDetailsPage' ? SamplingDetailsViewMode.CREATE_FROM_EVENTS : SamplingDetailsViewMode.CREATE_FROM_MEETING;
                this.sampleService.inMeetingAllocationActivity = <SampleActivity>activity;
                this.activityService.activityDetailsLoaded = true;
                this.meetingStructureService.openInMeetingActivitiesModal(activity);
                break;
            case ActivityType.CaseIntake:
                this.caseManagementService.assignSelectedCase(<CaseActivity>activity);
                this.meetingStructureService.openInMeetingCaseModal(activity);
                break;
            case ActivityType.Email:
                this.emailService.selectedActivity = <EmailActivity>activity;
                this.emailService.setCurrentEmail(activity);
                this.emailService.viewType = EmailViewType.CREATE_FROM_MEETING;
                this.meetingStructureService.openInMeetingActivitiesModal(activity);
                break;
            case ActivityType.SurgeryOrder:
                this.surgeryOrderActivityDataService.inMeetingSurgeryOrderActivity = activity as SurgeryOrderActivity;
                this.meetingStructureService.openInMeetingActivitiesModal(activity);
                break;
            case ActivityType.ProcedureTracker:
                this.procedureTrackerActivityDataService.inMeetingProcedureTrackerActivity = activity as ProcedureTrackerActivity;
                this.meetingStructureService.openInMeetingActivitiesModal(activity);
                break;
            case ActivityType.FollowUp:
                this.followupActivityDataService.inMeetingFollowupActionActivity = activity as FollowUpActivity;
                this.meetingStructureService.openInMeetingActivitiesModal(activity);
                break;
            default:
                console.log("Unhandled activity case");
                break
        }
    }

    async handlePlusButton(embeddedInteraction: EmbeddedInteraction) {
      // if (this.meetingStructureAccessedFrom == 'EventDetailsPage') return;
      const curMasterPage = this.navService.getCurrentMasterPageName();
      this.activityService.activitySource = this.meetingStructureAccessedFrom == 'EventDetailsPage' ? ActivitySource.EVENT_DETAIL : ActivitySource.MEETING_DETAIL;
      this.meetingStructureService.setCurrentMeetingActivity(embeddedInteraction);
      if (curMasterPage === PageName.CallPlanComponent) {
        this.callPlanService.callPlanPageView = "NEW_ACTIVITY_SELECTION";
      }
      const accessedFrom = this.meetingStructureAccessedFrom == 'EventDetailsPage' ? 'EventDetailsPane' : 'ActivitiesDetailsPane';
      await this.navService.pushChildNavPageWithPageTracking(NewActivityComponent, PageName.NewActivityComponent, PageName.ActivitiesDetailsPaneComponent, { from: accessedFrom });
    }

    handleInteractionRemove(embeddedInteraction: EmbeddedInteraction) {
        if (embeddedInteraction.type === EmbeddedInteractionType.Content) {
            this.handleContentRemoveButton(<Presentation | Resource | EventPresentations>embeddedInteraction.interaction);
        } else if (embeddedInteraction.type === EmbeddedInteractionType.Survey) {
            this.handleSurveyRemoveButton(<CustomerAssessment>embeddedInteraction.interaction);
        } else {
            this.handleActivityRemoveButton(<Activity>embeddedInteraction.interaction);
        }
    }

    handleContentRemoveButton(content: Presentation | Resource | EventPresentations) {
        if (this.isReadOnlyJointMeeting || this.activityService.teamViewActive) return;
        // Prevent offline for account visit
        const accountVisitRecordCheckResponse = this.activityService.accountVisitRecordCheck(this.activityService.selectedActivity as AppointmentActivity);
        if (this.activityService.accountVisitOfflineCheck(
          accountVisitRecordCheckResponse, true,
        )) {
          return;
        }

        const alertMessage = content instanceof Resource ? content.title : content.name;
        const message = accountVisitRecordCheckResponse.isAccountVisitRecord
          ? this.translate.instant('ACCOUNT_VISIT_CONTENT_REMOVE_CONFIRM')
          : this.translate.instant("MEETING_STRUCTURE_REMOVE_CONTENT_MESSAGE").replace("{{0}}", alertMessage);
        this.alertService.showAlert({
            title: this.translate.instant('MEETING_STRUCTURE_REMOVE_CONTENT_TITLE'),
            message,
        },
            this.translate.instant('REMOVE')
        ).then(async res => {
            if (res.role == "ok") {
              if (this.backgroundUploadInProgress) return;
                this.removeContent(content, accountVisitRecordCheckResponse);
                if (!(accountVisitRecordCheckResponse.isAccountVisitRecord || accountVisitRecordCheckResponse.isAccountVisitNestedMeeting))
                {
                  this.events.publish('contentIsRemoved',content);
                }
            }
        });
    }

    private async removeContent(content: Presentation | Resource | EventPresentations, accountVisitRecordCheckResponse) {
        let loader = await this.loadingController.create();
        loader.present();
        if(this.meetingStructureAccessedFrom == 'ActivitiesDetailsPanePage') {
            let contents: (Presentation | Resource)[] = [];
            let activityContents = this.activityService.selectedActivity['presentations'];
            const origContents = (Array.isArray(this.activityService.selectedActivity['presentations']) ? this.activityService.selectedActivity['presentations'].slice() : []);
            if (content instanceof Presentation) {
                contents = activityContents.filter(p => content.ioPresentationId !== p.ioPresentationId);
            } else if (content instanceof Resource && content.ioResourceId) {
                contents = activityContents.filter(p => content.ioResourceId !== p.ioResourceId);
            } else if(content instanceof Resource) {
                contents = activityContents.filter(p => content.ioDocumentId !== p.ioDocumentId);
            }
            this.activityService.selectedActivity['presentations'] = contents;

            // Account visit update logic
            if (accountVisitRecordCheckResponse.isAccountVisitRecord || accountVisitRecordCheckResponse.isAccountVisitNestedMeeting) {
              try {
                await this.meetingDataService.addRemoveContentToAccountActivity(
                  accountVisitRecordCheckResponse.isAccountVisitRecord,
                  origContents,
                  loader,
                  true,
                );

                loader.dismiss();
                this.presentationService.setCarouselBriefcase(this.activityService.selectedActivity['presentations']);
                this.events.publish('contentIsRemoved',content);
                this.meetingStructureService.removeContentFromEmbeddedInteraction(content, this.embeddedInteractions);
              } catch (error) {
                console.error('removeContent: ', error);
                // Revert
                this.activityService.selectedActivity['presentations'] = origContents;
                loader.dismiss();
                this.notificationService.notify(
                  this.translate.instant('ERROR_UPDATING_MEETING_PRESENTATIONS'),
                  'Presentation List',
                  'top',
                  ToastStyle.DANGER
                );
              }
              return;
            }

            if (this.deviceService.isOffline || this.activityService.hasOfflineMeetingData(this.activityService.selectedActivity.ID)) {
                try {
                    this.activityService.upsertMeetingsOfflineData(<AppointmentActivity>this.activityService.selectedActivity);
                    this.presentationService.setCarouselBriefcase(this.activityService.selectedActivity['presentations']);
                    loader.dismiss();
                }
                catch (e) {
                    loader.dismiss();
                    throw new Error(e);
                }
            } else {
                this.meetingDataService.addContentToMeeting(contents, <AppointmentActivity>this.activityService.selectedActivity, true)
                    .then(() => {
                        loader.dismiss();
                        this.presentationService.setCarouselBriefcase(this.activityService.selectedActivity['presentations']);
                    }).catch(() => {
                      this.activityService.upsertMeetingsOfflineData(<AppointmentActivity>this.activityService.selectedActivity, false, true)
                      .then(() => {
                        loader.dismiss()
                        this.presentationService.setCarouselBriefcase(this.activityService.selectedActivity['presentations']);
                      })
                      .catch(() => {
                        loader.dismiss();
                        this.notificationService.notify(this.translate.instant('ERROR_UPDATING_MEETING_PRESENTATIONS'), 'Presentation List', 'top', ToastStyle.DANGER);
                      });
                    });
            }
            this.meetingStructureService.removeContentFromEmbeddedInteraction(content, this.embeddedInteractions);
        } else if(this.meetingStructureAccessedFrom == 'EventDetailsPage'){
            let eventContents : EventPresentations[] = [];
            let eventActivity = (this.activityService.selectedActivity as EventActivity);
            if (content instanceof Presentation) {
                eventActivity.presentations.find(p => content.ioPresentationId == p.id).isDeleted = true;
            } else if (content instanceof Resource && content.ioResourceId) {
                eventActivity.presentations.find(p => content.ioResourceId == p.id).isDeleted = true;
            } else if (content instanceof Resource) {
                eventActivity.presentations.find(p => content.ioDocumentId == p.id).isDeleted = true;
            } else {
                eventActivity.presentations.find(p => content.id == p.id).isDeleted = true;
            }
            const payload = this.eventsToolDataService.getPresentationsDTO(eventActivity);
            let action:OperationDetail = {
                onDynamics: !this.deviceService.isOffline,
                onLocalDatabase: true,
                onLocalCopy: false,
                operationDetail: {
                  code: '',
                  message: '',
                  payload,
                },
              };
            eventActivity.modifiedOn = new Date();
            eventActivity.pendingPushToDynamics = true;
            this.eventsToolDataService.updateEventsData(action,[eventActivity]).then((value:Array<EventActivity>)=>{
              this.meetingStructureService.removeContentFromEmbeddedInteraction(content, this.embeddedInteractions);
              this.activityService.selectedActivity = value[0];
              loader.dismiss();
            },(err=>{
                loader.dismiss();
                console.log('Error updating event activity');
            }))
        }
    }

    handleSurveyRemoveButton(survey: CustomerAssessment) {
      this.alertService.showAlert({
        title: this.translate.instant('SCRAP_SURVEY'),
        message: this.translate.instant('R_U_SURE_SCRAP_SURVEY')
      }, this.translate.instant('SCRAP')
      ).then(async res => {
        if (res.role == "ok") {
          this.uiService.displayLoader();
          try{
            await Promise.all([
              this.customerAssessService.scrapSurvey(survey.indskr_customerassessmentid).then((res) => {
                if(survey.indskr_entity == SurveyCategory.CONTACT) this.customerAssessService.deleteCustomerSurveyInDB(survey.indskr_customerassessmentid);
                else this.customerAssessService.deleteAccountSurveyInDB(survey.indskr_customerassessmentid);
                this.customerAssessService.deleteCustomerSurveyAppt(survey)
              })
            ]).then(() => {
              this.customerSurveyService.setSurveyPreviewMode(true);
              this.customerSurveyService.selectedContactForSurvey = null;
              this.meetingStructureService.removeSurvey(survey);
              this.uiService.dismissLoader();
            })
          } catch(error) {
            console.log("Error scrap survey form ", error);
            this.uiService.dismissLoader();
          }
        }
      });
    }

    handleActivityRemoveButton(activity: Activity) {
        let popupTitle: string;
        switch (activity.type) {
            case ActivityType.Sample:
                popupTitle = this.translate.instant('SCRAP_ALLOCATION_ORDER');
                break;
            case ActivityType.Email:
                popupTitle = this.translate.instant('EMAIL_ACTIVITY_ALERT_TITLE_SCRAP_MESSGE');
                break;
            case ActivityType.CaseIntake:
                popupTitle = this.translate.instant('POP_SCRAP_INQUIRY');
                break;
            case ActivityType.SurgeryOrder:
                popupTitle = this.translate.instant('OM_SCRAP_LOG');
                break;
            case ActivityType.ProcedureTracker:
                popupTitle = this.translate.instant('OM_SCRAP_TRACKER');
                break;
            case ActivityType.FollowUp:
                popupTitle = this.translate.instant('SCRAP_FOLLOW_UP');
                break;
        }
        this.alertService.showAlert({
            title: popupTitle,
            message: this.getAlertMessage(activity)
        }, this.translate.instant('SCRAP')
        ).then(async res => {
            if (res.role == "ok") {
                if (this.backgroundUploadInProgress) return;
                switch (activity.type) {
                    case ActivityType.Email:
                        this.emailService.scrapEmailActivity(<EmailActivity>activity, true);
                        break;
                    case ActivityType.Sample:
                        this.uiService.displayLoader();
                        let payload: CreateSampleDropRequestBody = new CreateSampleDropRequestBody(activity);
                        payload.statecode = 2;
                        payload.statuscode = 3;
                        (activity as SampleActivity).state = 2;
                        (activity as SampleActivity).status = 3;
                        await this.sampleDataService.updateSampleActivity(payload, <SampleActivity>activity);
                        this.uiService.dismissLoader();
                        break;
                    case ActivityType.CaseIntake:
                        this.caseManagementService.scrapCustomerInquiry(<CaseActivity>activity, null, null, true);
                        break;
                    case ActivityType.SurgeryOrder:
                        this._handleInMeetingSurgeryOrderActivityDeletion(activity as SurgeryOrderActivity);
                        break;
                    case ActivityType.ProcedureTracker:
                        this._handleInMeetingProcedureTrackerActivityDeletion(activity as ProcedureTrackerActivity);
                        break;
                    case ActivityType.FollowUp:
                        this._handleInMeetingFollowupActivityDeletion(activity as FollowUpActivity);
                        break;
                }
            }
        });
    }

    private getAlertMessage(activity: any): string {
        let message: string;
        switch (activity.type) {
            case ActivityType.Sample:
                message = this.translate.instant('POP_R_U_SURE_SCRAP_ALLOC_O');
                break;
            case ActivityType.Email:
                message = this.translate.instant('R_U_SURE_SCRAP_MESG');
                break;
            case ActivityType.CaseIntake:
                message = this.translate.instant('POP_R_U_SURE_SCRAP_SELECTED_INQUIRY');
                break;
            case ActivityType.SurgeryOrder:
                message = this.translate.instant('R_U_SURE_SCRAP_SO');
                break;
            case ActivityType.ProcedureTracker:
                message = this.translate.instant('R_U_SURE_SCRAP_TRACKER');
                break;
             case ActivityType.FollowUp:
                message = this.translate.instant('POP_R_U_SURE_SCRAP_FOLLOW_UP');
                break;
        }
        return message;
    }

    ngOnDestroy() {
        this.IcurrentMeetingStructureSubscription?.unsubscribe();
        // this.translate.onLangChange.unsubscribe();
    }

    dragMoved(e: CdkDragMove) {
        let point = this.getPointerPositionOnPage(e.event);

        this.listGroup._items.forEach(dropList => {
          if (__isInsideDropListClientRect(dropList, point.x, point.y)) {
            this.activeContainer = dropList;
            return;
          }
        });
      }

      dropListDropped(event: any) {
        if (!this.target)
          return;

        let phElement = this.placeholder.element.nativeElement;
        let parent = phElement.parentElement;

        phElement.style.display = 'none';

        parent.removeChild(phElement);
        parent.appendChild(phElement);
        parent.insertBefore(this.source.element.nativeElement, parent.children[this.sourceIndex]);

        this.target = null;
        this.source = null;

        if (this.sourceIndex != this.targetIndex)
          moveItemInArray(this.embeddedInteractions, this.sourceIndex, this.targetIndex);
          this.meetingStructureService.updateSequenceOnEmbeddedInteractionDrag(this.embeddedInteractions);
      }

      dropListEnterPredicate = (drag: CdkDrag, drop: CdkDropList) => {
        if (drop == this.placeholder)
          return true;

        if (drop != this.activeContainer)
          return false;

        let phElement = this.placeholder.element.nativeElement;
        let sourceElement = drag.dropContainer.element.nativeElement;
        let dropElement = drop.element.nativeElement;

        let dragIndex = __indexOf(dropElement.parentElement.children, (this.source ? phElement : sourceElement));
        let dropIndex = __indexOf(dropElement.parentElement.children, dropElement);

        if (!this.source) {
          this.sourceIndex = dragIndex;
          this.source = drag.dropContainer;

          phElement.style.width = sourceElement.clientWidth + 'px';
          phElement.style.height = sourceElement.clientHeight + 'px';

          sourceElement.parentElement.removeChild(sourceElement);
        }

        this.targetIndex = dropIndex;
        this.target = drop;

        phElement.style.display = '';
        dropElement.parentElement.insertBefore(phElement, (dropIndex > dragIndex
          ? dropElement.nextSibling : dropElement));

        this.placeholder._dropListRef.enter(drag._dragRef, drag.element.nativeElement.offsetLeft, drag.element.nativeElement.offsetTop);
        return false;
      }

      /** Determines the point of the page that was touched by the user. */
      getPointerPositionOnPage(event: MouseEvent | TouchEvent) {
        // `touches` will be empty for start/end events so we have to fall back to `changedTouches`.
        const point = __isTouchEvent(event) ? (event.touches[0] || event.changedTouches[0]) : event;
            const scrollPosition = this.viewportRuler.getViewportScrollPosition();

            return {
                x: point.pageX - scrollPosition.left,
                y: point.pageY - scrollPosition.top
            };
        }


  private async _handleInMeetingSurgeryOrderActivityDeletion(surgeryOrderActivity: SurgeryOrderActivity) {
    const action = {
      onDynamics: !this.device.isOffline,
      onLocalDatabase: true,
      onLocalCopy: true,
      operationDetail: {
        code: 'scrapsurgeryorderactivity',
        message: this.translate.instant('OM_UPDATE_O_ACTIVITY'),
      }
    };
    this.uiService.displayLoader();
    surgeryOrderActivity.pendingPushToDynamics = true;
    this.surgeryOrderActivityDataService.updateOrderActivityStatus(action, surgeryOrderActivity).then((succ) => {
      this.activityService.publishActivityEvent({ action: 'Delete', activity: surgeryOrderActivity });
      this.events.publish('surgeryOrderActivityDeleted', surgeryOrderActivity, true);
      if (this.navService.getActiveChildNavName() === ChildNavNames.ActivitiesPageNavigation) {
        this.events.publish('refreshAgenda');
      }
      this.events.publish('deletedEmbeddedActivity', surgeryOrderActivity);
      this.uiService.dismissLoader();
    }).catch(async err => {
      //Handle error scenario
      if (err && err.errorCode && err.errorCode === 'ONLINEONLYUPDATEFAILED') {
        this.notificationService.notify(this.translate.instant('OM_WAS_A_PROBLEM_CONTACT_ADMINISTRATOR'), 'Order Details', 'top', ToastStyle.DANGER);
      }
      this.uiService.dismissLoader();
    });
  }
  private async _handleInMeetingProcedureTrackerActivityDeletion(procedureTrackerActivity: ProcedureTrackerActivity) {
    const action = {
      onDynamics: !this.device.isOffline,
      onLocalDatabase: true,
      onLocalCopy: true,
      operationDetail: {
        code: 'scrapproceduretrackeractivity',
        message: this.translate.instant('OM_UPDATE_O_ACTIVITY'),
      }
    };
    this.uiService.displayLoader();
    procedureTrackerActivity.pendingPushToDynamics = true;
    this.procedureTrackerActivityDataService.updateOrderActivityStatus(action, procedureTrackerActivity).then((succ) => {
      this.activityService.publishActivityEvent({ action: 'Delete', activity: procedureTrackerActivity });
      this.events.publish('procedureTrackerActivityDeleted', procedureTrackerActivity, true);
      if (this.navService.getActiveChildNavName() === ChildNavNames.ActivitiesPageNavigation) {
        this.events.publish('refreshAgenda');
      }
      this.events.publish('deletedEmbeddedActivity', procedureTrackerActivity);
      this.uiService.dismissLoader();
    }).catch(async err => {
      //Handle error scenario
      if (err && err.errorCode && err.errorCode === 'ONLINEONLYUPDATEFAILED') {
        this.notificationService.notify(this.translate.instant('OM_WAS_A_PROBLEM_CONTACT_ADMINISTRATOR'), 'Order Details', 'top', ToastStyle.DANGER);
      }
      this.uiService.dismissLoader();
    });
  }

  private async _handleInMeetingFollowupActivityDeletion(followupActivity: FollowUpActivity) {
    this.uiService.displayLoader();
    if (this.device.isBackgroundUploadInProgress && followupActivity.pendingPushToDynamics) return;
    let id = followupActivity.ID;
    followupActivity.pendingPushToDynamics = true;
    this.followupActivityDataService.scrapFollowUpActivity({ onDynamics: !this.device.isOffline, onLocalDatabase: true, onLocalCopy: true }, followupActivity).then(async succ => {
      this.activityService.publishActivityEvent({ action: "Delete", activity: followupActivity });
      this.events.publish('followupActionDeleted', followupActivity);
      if (this.navService.getActiveChildNavName() === ChildNavNames.ActivitiesPageNavigation) {
        this.events.publish('refreshAgenda');
      }
      this.events.publish('deletedEmbeddedActivity', followupActivity);
      this.uiService.dismissLoader();
    }).catch(err => {
      this.uiService.dismissLoader();
      //Handle error scenario
    })
  }
}

function __indexOf(collection, node) {
    return Array.prototype.indexOf.call(collection, node);
  };

  /** Determines whether an event is a touch event. */
  function __isTouchEvent(event: MouseEvent | TouchEvent): event is TouchEvent {
    return event.type.startsWith('touch');
  }

  function __isInsideDropListClientRect(dropList: CdkDropList, x: number, y: number) {
    const {top, bottom, left, right} = dropList.element.nativeElement.getBoundingClientRect();
    return y >= top && y <= bottom && x >= left && x <= right;
  }
