import { Component, ElementRef, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import {  NavParams, PopoverController } from '@ionic/angular';
import { Events } from '@omni/events';
import { format, differenceInDays } from 'date-fns';
import * as moment from 'moment';
import { SchedulerService } from '../../../../services/scheduler/scheduler.service';
import { Scheduler } from '../../../../classes/scheduler/scheduler.class';
import { CalendarAppointment } from '../../../../classes/scheduler/calendarAppointment.class';
import { TranslateService } from '@ngx-translate/core';
import {DateTimeFormatsService} from "../../../../services/date-time-formats/date-time-formats.service";

@Component({
    selector: 'scheduler-calendar-popup',
    templateUrl: 'scheduler-calendar-popup.html',
  styleUrls:['scheduler-calendar-popup.scss'],
    changeDetection: ChangeDetectionStrategy.Default
})
export class SchedulerCalendarPopupComponent {

    public startTime: string;
    private currentSchedule: Scheduler;
    public currentAppoinment: CalendarAppointment;
    minDate: any = format(new Date());
    maxDate: any = format(new Date());
    public isDisabled: boolean = true;
    public timeOffInfo: string = "";
    shortMonth: string[] = ['JAN','FEB','MAR','APR','MAY','JUN','JUL','AUG','SEP','OCT','NOV','DEC'];

    @ViewChild('myDiv', {static: true}) myDiv: ElementRef;

    constructor(
        private elementRef: ElementRef,
        public navParams: NavParams,
        public schedulerService: SchedulerService,
        private cd: ChangeDetectorRef,
        public translate: TranslateService,
        public dateTimeFormatsService: DateTimeFormatsService,
        public popoverCtrl: PopoverController
    ) {
        this.currentAppoinment = this.navParams.data.currentAppointment;
        this.startTime = moment(this.navParams.data.currentAppointment.startDate).format();
        this.isDisabled = (this.navParams.data.currentAppointment.priorityId === 0 || this.navParams.data.currentAppointment.priorityId === 2) ? true : false;
        this.timeOffInfo = this.formattedTimeOffInterval;
    }

    ngOnInit() {
        this.schedulerService.schedulerObs.subscribe(res => {
            if (res) {
                this.currentSchedule = res;
                this.maxDate = format(moment(this.currentSchedule.startdate).add((this.currentSchedule.durationValue - 1), "d").set('hour', 23).set('minute', 30).set('second', 0).toDate());
                this.timeOffInfo = this.formattedTimeOffInterval;
            }
        }).unsubscribe();
        this.minDate = format(moment(new Date(this.currentSchedule.startdate)).set('hour', 0).set('minute', 0).set('second', 0).toDate());
    }

    triggerFalseClick() {
        if (this.currentAppoinment.priorityId != 0 && this.currentAppoinment.priorityId != 2) {
            let el: HTMLElement = this.myDiv.nativeElement as HTMLElement;
            if (el) {
                el.click()
            }
            else {
                this.myDiv['_elementRef'].nativeElement.click();
            }
        }
        else
            return;
    }

    onScrap() {
        let object: any = {
            type: "scrap",
            value: this.currentAppoinment
        }
        this.popoverCtrl.dismiss(object)
    }

    onDone(data?: any) {
        if (data) {
            let s = new Date(this.startTime).getTime();
            let m = new Date(this.maxDate).getTime();
            if (s > m) {
                this.startTime = this.maxDate;
            }
            let object: any = {
                type: "edit",
                value: this.startTime
            }
            this.popoverCtrl.dismiss(object)
        }
        else {
            this.popoverCtrl.dismiss(undefined)
        }

    }

    private get formattedTimeOffInterval(): string {
        let duration: string = "";
        let totEndTime: Date = new Date(this.currentAppoinment.endDate);
        let totStartTime: Date = new Date(this.currentAppoinment.startDate);
        let event: boolean = this.currentAppoinment.isAllDay;

        if (event === true) {
            let days: any = moment(totEndTime).diff(moment(totStartTime), 'days') + 1;
            duration = days + "d";
            return duration;
        }
        else {
            let start = moment(new Date(this.currentAppoinment.startDate));
            let end = moment(new Date(this.currentAppoinment.endDate));
            let diff: number = end.diff(start, 'minutes');
            if (diff === 719 || diff === 720) {
                duration = "0.5 d";
                return duration;
            }
            else {
                let days: any = differenceInDays(totEndTime, totStartTime);
                let timeDifference: Date = new Date((new Date(totEndTime).valueOf() - new Date(totStartTime).valueOf()));
                let minutes: any = timeDifference.getUTCMinutes();
                let hours: any = timeDifference.getUTCHours();
                if (days >= 1) {
                    days = days + "d ";
                }
                else if (days < 1) {
                    days = "";
                }
                if (hours >= 1) {
                    hours = hours + "h ";
                }
                else if (hours < 1) {
                    hours = "";
                }
                if (minutes >= 1) {
                    minutes = minutes + "m ";
                }
                else if (minutes < 1) {
                    minutes = "";
                }
                duration = days + "" + hours + "" + minutes + "";
                return duration;
            }
        }
    }

}
