
import {tap, exhaustMap, filter, withLatestFrom,  map, catchError } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { of } from 'rxjs';

import * as Resource from '../actions/resource.actions';
import { IoFileService } from '../../../services/io-file-service/io-file-service';


import { ResourceState } from '../../application.state';
import { ResourceDataService } from '../../../data-services/resource/resource.data.service';

/**
 * Action effect for resource download and unzipping.
 */

@Injectable({
  providedIn: 'root'
})
export class ResourceManagerEffects {
    @Effect()
    downloadResourceEnqueue$ = this.actions$
        .pipe(ofType(Resource.RESOURCE_DOWNLOAD_ENQUEUE),
        map((action: Resource.downloadResourceEnqueue) => action.payload),
        withLatestFrom(this.store$),
        filter(([action, storeState]) => {
            return !storeState.resourceManager.isDownloading;
        }),
        map(([action, storeState]) => {
            return new Resource.downloadResource({ resourceId: storeState.resourceManager.downloadQueue[0] });
        }),);

    @Effect()
    downloadResource$ = this.actions$
        .pipe(ofType(Resource.DOWNLOAD_RESOURCE),
        map((action: Resource.downloadResource) => action.payload),
        exhaustMap(resource => {
            return this.ioFileDownload.downloadResource(resource.resourceId).pipe(
                map((flag: boolean[]) => {
                    if (!flag || flag.some(success => !success)) {
                        return new Resource.downloadResourceFailed({ resourceId: resource.resourceId });
                    }
                    return new Resource.downloadResourceSuccess({ resourceId: resource.resourceId });
                }),
                catchError(error => {
                    return of(new Resource.downloadResourceFailed({ resourceId: resource.resourceId }));
                })
            )
        }),);

    @Effect()
    downloadResourceSuccess$ = this.actions$
        .pipe(ofType(Resource.DOWNLOAD_RESOURCE_SUCCESS),
        map((action: Resource.downloadResourceSuccess) => {
            return new Resource.updateResourceRecord({ resourceId: action.payload.resourceId, downloaded: true });
        }));

    @Effect()
    downloadResourceDequeue$ = this.actions$
        .pipe(ofType(Resource.RESOURCE_DOWNLOAD_DEQUEUE),
        map((action: Resource.downloadResourceDequeue) => action),
        withLatestFrom(this.store$),
        filter(([action, storeState]) => {
            return storeState.resourceManager.downloadQueue.length > 0;
        }),
        map(([action, storeState]) => {
            return new Resource.downloadResource({ resourceId: storeState.resourceManager.downloadQueue[0] });
        }),);

    @Effect({ dispatch: false })
    updateResourceRecord$ = this.actions$
        .pipe(ofType(Resource.UPDATE_RESOURCE_RECORD),
        tap((action: Resource.updateResourceRecord) => {
            this.resourceDataService.saveResourceForOffline(action.payload.resourceId, action.payload.downloaded);
        }));

    @Effect()
    downloadResourceFailed$ = this.actions$
        .pipe(ofType(Resource.DOWNLOAD_RESOURCE_FAILURE),
        map((action: Resource.downloadResourceFailed) => {
            this.ioFileDownload.resetProgress();
            return new Resource.updateResourceRecord({ resourceId: action.payload.resourceId, downloaded: false });
        }));

    @Effect({ dispatch: false })
    deleteAlldownloadedResources$ = this.actions$
        .pipe(ofType(Resource.DELETE_ALL_DOWNLOADED_RESOURCES),
        map((action: Resource.deleteAlldownloadedResources) => {
            this.ioFileDownload.deleteAllDownloadedResources();
        }));

    constructor(
        private actions$: Actions,
        private store$: Store<ResourceState>,
        private ioFileDownload: IoFileService,
        private resourceDataService: ResourceDataService
    ) {

    }
}
