import { DB_KEY_PREFIXES } from './../../../config/pouch-db.config';
import {
  FooterService,
  FooterViews
} from "./../../../services/footer/footer.service";
import { ContactOfflineService } from "./../../../services/contact/contact.service";
import { Component, ChangeDetectorRef } from '@angular/core';
import { NavParams, NavController, } from '@ionic/angular';
import { Events } from '@omni/events';
import { RepCallPlanState } from "../../../classes/call-plan/rep-call-plan.class";
import { CallPlanOfflineService } from "../../../services/call-plan/call-plan.offline.service";
import { AuthenticationService } from '../../../services/authentication.service';
// import { NavController } from "@ionic/angular/navigation/nav-controller";
import { DeviceService } from "../../../services/device/device.service";
import { CallPlanDataService } from '../../../data-services/call-plan/call-plan.data.service';
import { NotificationService, ToastStyle } from "../../../services/notification/notification.service";
import { NavigationService, PageName } from "../../../services/navigation/navigation.service";
import { DiskService } from "../../../services/disk/disk.service";
import { RepServices } from "../../../data-services/rep/rep.services";
import { FeatureActionsMap } from "../../../classes/authentication/user.class";
import { ComponentViewMode, UIService } from "../../../services/ui/ui.service";
import { TrackService, TrackingEventNames } from "../../../services/logging/tracking.service";
import { TranslateService } from "@ngx-translate/core";
import {GlobalUtilityService} from "../../../services/global-utility.service";
import { AlertService } from "../../../services/alert/alert.service";
import { IndFormFieldViewDataModel, FormFieldType } from "../../../models/indFormFieldDataModel";
import { IndPageTitleViewDataModel } from '../../../models/indPageTitleDataModel';
import { RepCallPlanStatePipe } from '../../../pipes/rep-call-plan-state.pipe';
import { ExtendedDatePipe } from '../../../pipes/extended-date.pipe';
import { IndSectionHeaderViewDataModel } from '@omni/models/indSectionHeaderDataModel';
import { MainCardViewDataModel } from '../../../models/MainCardViewDataModel';
import { Contact } from "../../../classes/contact/contact.class";
import { ContactDetailsComponent } from "../../contact/contact-details/contact-details";
import { DateTimeFormatsService } from '../../../services/date-time-formats/date-time-formats.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ActivityService } from '@omni/services/activity/activity.service';
import { DatePipe } from '@angular/common';

/**
 * Generated class for the CallPlanCustDetailsComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: "call-plan-cust-details",
  templateUrl: "call-plan-cust-details.html",
  styleUrls:['call-plan-cust-details.scss'],
  providers:[RepCallPlanStatePipe, ExtendedDatePipe]
})
export class CallPlanCustDetailsComponent {
  public contactFirstName: string;
  public contactLastName: string;
  public suggestedEmailsGoal: string;
  public suggestedMeetingsGoal: string;
  public detailsHeaderModel : IndSectionHeaderViewDataModel;
  public meetingsHeaderModel : IndSectionHeaderViewDataModel;
  public messagingHeaderModel : IndSectionHeaderViewDataModel;
  public coverageHeaderModel : IndSectionHeaderViewDataModel;
  public hasTeamPlansEnabled: boolean = false;

  private footerHandler: (eventName: string) => void = (eventName: string) => {
    switch(eventName) {
      case 'sendForApproval':
        this.sendForApproval(true);
        break;
      case 'clear':
        this.clear(true);
        break;
      case 'reject':
        this.reject();
        break;
      case 'approve':
        this.approve();
        break;

      default:
    }
  };

  public pageTitle: IndPageTitleViewDataModel;
  otherRepCallPlans: any = [];
  ngUnSubscribe$ = new Subject<boolean>();
  private isClickedMeetingSuggested: boolean = false;
  private isClickedMessageSuggested: boolean = false;

  constructor(
    // private datePipe:ExtendedDatePipe,
    private datePipe: DatePipe,
    private statePipe: RepCallPlanStatePipe,
    private navParams: NavParams,
    public callPlanService: CallPlanOfflineService,
    public callPlanDataService: CallPlanDataService,
    public contact: ContactOfflineService,
    public footerService: FooterService,
    public authenticationService: AuthenticationService,
    public navCtrl: NavController,
    public device: DeviceService,
    public repService: RepServices,
    private alertService: AlertService,
    public notifications: NotificationService,
    private navService: NavigationService,
    private events: Events,
    private _cd: ChangeDetectorRef,
    private disk: DiskService,
    private authService: AuthenticationService,
    private uiService:UIService,
    private contactService:ContactOfflineService,
    public trackingService: TrackService,
    public translate:TranslateService,
    public utilityService:GlobalUtilityService,
    private dateTimeFormatsService: DateTimeFormatsService,
    private activityService: ActivityService
  ) {
    this.footerService.initButtons(FooterViews.CustomerCallPlan);
  }

  ionViewWillEnter() {
    this.footerService.initButtons(FooterViews.CustomerCallPlan);
  }

  ngOnInit() {
    this.events.subscribe('customer-call-plan-details:footerEvents', this.footerHandler);

    if(this.authService.hasFeatureAction(FeatureActionsMap.TEAM_CALL_PLANS)){
      this.hasTeamPlansEnabled = true;
    }
    if(this.navService.getCurrentMasterPageName() == PageName.ActivitiesPageComponent && this.uiService.activitiesPageTab === 'plans'
        && this.uiService.activitiesPagePlanTabSelectedPlan == 'callPlans')
          this.uiService.activeViewOnPlanTab = 'customerCallPlanDetails';
    this.initPageTitle();
    this.initializeSectionHeader();
    if(this.callPlanService.selectedCallPlan && !this.callPlanService.selectedCallPlan.isTeamCallPlan){
      this.disk.retrieve(DB_KEY_PREFIXES.OTHER_POSITON_CALL_PLANS).then((res)=>{
        if(res && res.raw?.length){
          this.otherRepCallPlans = res.raw.filter(o=>o.customerPlanId == this.callPlanService.selectedRepCallPlan.customerPlanId);
        }
      })
    }
    else{
      this.otherRepCallPlans = this.callPlanService.teamPositionsOtherRepPlans.filter(o=>o.customerPlanId == this.callPlanService.selectedRepCallPlan.customerPlanId);
    }
    this.callPlanService.otherRepCallPlansList$.pipe(takeUntil(this.ngUnSubscribe$)).subscribe((data)=>{
      if(data && data.hasUpdatedData){
        this.disk.retrieve(DB_KEY_PREFIXES.OTHER_POSITON_CALL_PLANS).then((res)=>{
          if(res && res.raw?.length){
            this.otherRepCallPlans = res.raw.filter(o=>o.customerPlanId == this.callPlanService.selectedRepCallPlan.customerPlanId);
          }
        })
      }
    })
  }

  initializeSectionHeader() {
    this.detailsHeaderModel = {
      id: 'details',
      title : `${this.translate.instant('DETAILS_CAP')}`,
      controls: []
    }
    let meetingTitleText = `${this.translate.instant('ENGAGEMENTS')}`;
    if(this.translate.currentLang == 'es' || this.translate.currentLang == 'it') {
      meetingTitleText = `${this.translate.instant('ENGAGEMENTS_CALL_PLAN')}`;
    }
    this.meetingsHeaderModel = {
      id: 'meetings',
      title : meetingTitleText,
      controls: []
    }
    let messageTitleText = `${this.translate.instant('MESSAGES')}`;
    if(this.translate.currentLang == 'es') {
      messageTitleText = `${this.translate.instant('MESSAGES_CALL_PLAN')}`;
    }
    this.messagingHeaderModel = {
      id: 'messages',
      title : messageTitleText,
      controls: []
    }
    this.coverageHeaderModel = {
      id: 'coverage',
      title : `${this.translate.instant('COVERAGE_TEAM_CAP')}`,
      controls: []
    }
  }

  private initPageTitle(): void {
    let buttons = [];
    buttons.push({
      id: "close",
      icon: "chevron-back-outline",
      isDisabled: false,
      align: "left"
    })
    if(this.callPlanService.selectedCallPlan && this.callPlanService.selectedCallPlan.isTeamCallPlan) {
      buttons.push({
        id: "not-approve",
        imgSrc: 'assets/imgs/header_cancel.svg',
        name: this.translate.instant('NOT_APPROVED'),
        isDisabled: this.callPlanService.selectedRepCallPlan.statuscode!=100000003,
        align: "right"
      },
      {
        id: "approve",
        imgSrc: 'assets/imgs/header_complete.svg',
        name: this.translate.instant('APPROVE'),
        isDisabled: this.callPlanService.selectedRepCallPlan.statuscode!=100000003,
        align: "right"
      });
    } else {
      buttons.push({
        id: "clear",
        imgSrc: 'assets/imgs/header_cancel.svg',
        name: this.translate.instant('CLEAR'),
        isDisabled: this.callPlanService.selectedRepCallPlan.statuscode!=1,
        align: "right"
      },
      {
        id: "send",
        imgSrc: 'assets/imgs/header_send.svg',
        name: this.translate.instant('SEND'),
        isDisabled: this.callPlanService.selectedRepCallPlan.statuscode!=1,
        align: "right"
      });
    }
    this.pageTitle = {
      id: 'call-plan-cust-detail',
      title: this.callPlanService.selectedRepCallPlan && this.callPlanService.selectedRepCallPlan?.indskr_name,
      controls: buttons,
    };
  }

  public onPageTitleControlClick(id: string) {
    switch (id) {
      case 'close':
        this.backToCallplanDetails()
        break;
      case 'not-approve':
        this.reject();
        break;
      case 'approve':
        this.approve();
        break;
      case 'clear':
        this.clear();
        break;
      case 'send':
        this.sendForApproval();
        break;
      default:
        console.log("Unhandled switch case statement");
        break;
    }
  }

  ngOnDestroy() {
    if(this.uiService.activeViewOnPlanTab == 'customerCallPlanDetails') this.uiService.activeViewOnPlanTab = undefined;
    this.events.unsubscribe('customer-call-plan-details:footerEvents', this.footerHandler);
    this.ngUnSubscribe$.next(true);
    this.ngUnSubscribe$.complete();
  }

  backToCallplanDetails() {
    this.trackingService.tracking('MyCallPlanRepCallPlanDetailsBack', TrackingEventNames.CALLPLAN)
    if(this.uiService.activeView ==='ActivitiesPageRightPaneNav' && this.uiService.activitiesPageTab ==='plans') this.uiService.activeView = 'ActivitiesPageRightPaneNav';
    else this.uiService.activeView = 'CallPlanDetails';
    this.contactService.contactInformation = undefined;
    this.navService.popChildNavPageWithPageTracking().then(()=>{
      this.footerService.initButtons(FooterViews.CallPlanDetails)
      this.events.publish('refresh-callplan-activity');
    });
  }

  clear(isClickedFromFooter?:boolean) {
    if(isClickedFromFooter) this.trackingService.tracking('MyCallPlanClearSuggestionsActionBar', TrackingEventNames.CALLPLAN);
    else this.trackingService.tracking('MyCallPlanClearSuggestions', TrackingEventNames.CALLPLAN);
    this.callPlanService.selectedRepCallPlan.indskr_suggestedcalls = 0;
    this.callPlanService.selectedRepCallPlan.indskr_suggestedemails = 0;
  }

  public async setSuggestedMeeting(value, eventName){
    if(eventName == 'input_value_confirm'){
      this.isClickedMeetingSuggested = true;
      this.callPlanService.selectedRepCallPlan.indskr_suggestedcalls = Number(value);
      await this.disk.updateOfflineRepCallPlan(this.callPlanService.selectedRepCallPlan);
    }
  }

  public async setSuggestedEmail(value, eventName){
    if(eventName == 'input_value_confirm'){
      this.isClickedMessageSuggested = true;
      this.callPlanService.selectedRepCallPlan.indskr_suggestedemails = Number(value);
      await this.disk.updateOfflineRepCallPlan(this.callPlanService.selectedRepCallPlan);
    }
  }
  public async approve() {
    if (this.device.isOffline) {
      this.notifications.notify(this.translate.instant('YOUR_DEVICE_IS_OFFLINE'), 'callPlanCustDetails', 'top', undefined, 2000, true);
      return;
    }
    if (!this.callPlanService.selectedRepCallPlan.indskr_suggestedcalls || !this.callPlanService.selectedRepCallPlan.indskr_suggestedemails) {
      this.notifications.notify(this.translate.instant('PLEASE_ENTER_A_SUGGESTIONS_FOR_EITHER_ENGAGEMENTS_OR_MESSAGES'), 'callPlanCustDetails', 'top', ToastStyle.DANGER, 2000, true);
      return;
    }
    this.alertService.showAlert({
      title: this.translate.instant('APPROVE'),
      message: this.translate.instant('APPROVE_THIS_PLANS_SUGGESTIONS')}, this.translate.instant('APPROVE')
    ).then (res => {
      if(res.role == "ok") {
        this.callPlanDataService.updateCallPlan(this.callPlanService.selectedRepCallPlan, 1, RepCallPlanState.Approved,
            this.callPlanService.selectedRepCallPlan.indskr_suggestedemails, this.callPlanService.selectedRepCallPlan.indskr_suggestedcalls)
        .then(() => {
          this.footerService.initButtons(FooterViews.CustomerCallPlan);
          this._cd.markForCheck();
          this.initPageTitle();
        });
      }
    });
  }

  public async reject() {
    if (this.device.isOffline) {
      this.notifications.notify(this.translate.instant('YOUR_DEVICE_IS_OFFLINE'), 'callPlanCustDetails', 'top', undefined, 2000, true);
      return;
    }
    if (!this.callPlanService.selectedRepCallPlan.indskr_suggestedemails || !this.callPlanService.selectedRepCallPlan.indskr_suggestedemails) {
      this.notifications.notify(this.translate.instant('PLEASE_ENTER_A_SUGGESTIONS_FOR_EITHER_ENGAGEMENTS_OR_MESSAGES'), 'callPlanCustDetails', 'top', ToastStyle.DANGER, 2000, true);
      return;
    }
    this.alertService.showAlert({
      title: this.translate.instant('NOT_APPROVED'),
      message: this.translate.instant('NOT_APPROVE_THIS_PLANS_SUGGESTIONS')}
    ).then (res => {
      if(res.role == "ok") {
        this.uiService.displayLoader();
        this.callPlanDataService.updateCallPlan(this.callPlanService.selectedRepCallPlan, 0, RepCallPlanState.NotApproved,
            this.callPlanService.selectedRepCallPlan.indskr_suggestedemails, this.callPlanService.selectedRepCallPlan.indskr_suggestedcalls)
        .then(() => {
          this.uiService.dismissLoader();
          this.footerService.initButtons(FooterViews.CustomerCallPlan);
          this._cd.markForCheck();
          this.initPageTitle();
        })
        .catch(err=>{
          this.uiService.dismissLoader();
        });
      }
    });
  }

  public async sendForApproval(isClickedFromFooter?:boolean) {
    if(isClickedFromFooter) this.trackingService.tracking('MyCallPlanSendforApprovalActionBar', TrackingEventNames.CALLPLAN);
    else this.trackingService.tracking('MyCallPlanSendforApproval', TrackingEventNames.CALLPLAN);
    if (this.device.isOffline) {
      this.notifications.notify(this.translate.instant('YOUR_DEVICE_IS_OFFLINE'), 'callPlanCustDetails', 'top', ToastStyle.DANGER, 2000, true);
      return;
    }
    if (!this.callPlanService.selectedRepCallPlan.indskr_suggestedcalls || !this.callPlanService.selectedRepCallPlan.indskr_suggestedemails) {
      if((!this.callPlanService.selectedRepCallPlan.indskr_suggestedcalls || this.callPlanService.selectedRepCallPlan.indskr_suggestedcalls<=0) && (this.callPlanService.selectedRepCallPlan.indskr_suggestedemails && this.callPlanService.selectedRepCallPlan.indskr_suggestedemails>=0)) {
        this.isClickedMeetingSuggested = true;
        this.isClickedMessageSuggested = false;
      } else if((this.callPlanService.selectedRepCallPlan.indskr_suggestedcalls && this.callPlanService.selectedRepCallPlan.indskr_suggestedcalls>0) && (!this.callPlanService.selectedRepCallPlan.indskr_suggestedemails || this.callPlanService.selectedRepCallPlan.indskr_suggestedemails<=0)) {
        this.isClickedMeetingSuggested = false;
        this.isClickedMessageSuggested = true;
      } else {
        this.isClickedMessageSuggested = this.isClickedMeetingSuggested = true;
      }
      this.notifications.notify(this.translate.instant('PLEASE_ENTER_A_SUGGESTIONS_FOR_EITHER_ENGAGEMENTS_OR_MESSAGES'), 'callPlanCustDetails', 'top', ToastStyle.DANGER, 2000, true);
      return;
    }
    this.alertService.showAlert({
      title: this.translate.instant('SEND_FOR_APPROVAL')}, this.translate.instant('SEND')
    ).then (res => {
      this.uiService.displayLoader();
      if(res.role == "ok") {
        this.callPlanDataService.updateCallPlan(this.callPlanService.selectedRepCallPlan, 0, RepCallPlanState.ForReview,
            this.callPlanService.selectedRepCallPlan.indskr_suggestedemails, this.callPlanService.selectedRepCallPlan.indskr_suggestedcalls,null,null,null,true)
        .then(() => {
          this.uiService.dismissLoader();
          this.footerService.initButtons(FooterViews.CustomerCallPlan);
          this._cd.markForCheck();
          this.initPageTitle();
        })
        .catch(err=>{
          this.uiService.dismissLoader();
        });
      }
    });
  }

  public getProductFormField():IndFormFieldViewDataModel{
    let viewData:IndFormFieldViewDataModel = {
      label: this.translate.instant('PRODUCT'),
      inputText: this.callPlanService.selectedRepCallPlan.productid_Formatted?this.callPlanService.selectedRepCallPlan.productid_Formatted:this.translate.instant('NO_PRODUCT'),
      id:'order-number-field',
      isReadOnly:true,
      isDisabled:true,
      isHidden:!(this.callPlanService.selectedCallPlan && this.callPlanService.selectedCallPlan.productName)
    };
    return viewData;
  }

  public getStatusFormField():IndFormFieldViewDataModel{

    let viewData:IndFormFieldViewDataModel = {
      label: this.translate.instant('STATUS'),
      inputText: this.callPlanService.selectedRepCallPlan.statuscode?this.statePipe.transform(this.callPlanService.selectedRepCallPlan.statuscode):'',
      id:'callplan-custdetails-status-field',
      isReadOnly:true,
      isDisabled:true,
    };
    return viewData;
  }

  public getCustNameFormField():IndFormFieldViewDataModel{
    let viewData:IndFormFieldViewDataModel = {
      label: this.utilityService.globalCustomerText,
      inputText: (this.callPlanService.selectedRepCallPlan?.contactFirstName && this.callPlanService.selectedRepCallPlan?.contactLastName)
                    ? this.callPlanService.selectedRepCallPlan?.contactFirstName + " " + this.callPlanService.selectedRepCallPlan?.contactLastName
                    :'',
      id:'callplan-custdetails-name-field',
      isReadOnly:true,
      isDisabled:!this.hasContact(),
      showArrow: this.hasContact(),
      formFieldType: FormFieldType.NEW_PAGE_SELECT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }

  private hasContact(): boolean {
    let contact = this.contactService.getContactByID(this.callPlanService.selectedRepCallPlan['contactId']);
    if(contact) return true;
    else return false;
  }

  public getStartDateFormField():IndFormFieldViewDataModel{


    let viewData:IndFormFieldViewDataModel = {
      label: this.translate.instant('CP_PERIOD_START_DATE'),
      inputText: this.callPlanService.selectedRepCallPlan && this.callPlanService.selectedRepCallPlan?.indskr_startdate
                  ? this.datePipe.transform(this.callPlanService.selectedRepCallPlan.indskr_startdate, this.dateTimeFormatsService.date, undefined, this.translate.currentLang)
                  :this.translate.instant('N_A'),
      id:'callplan-custdetails-startdate-field',
      isReadOnly:true,
      isDisabled:true,
    };
    return viewData;
  }

  public getEndDateFormField():IndFormFieldViewDataModel{

    let viewData:IndFormFieldViewDataModel = {
      label: this.translate.instant('CP_PERIOD_END_DATE'),
      inputText: this.callPlanService.selectedRepCallPlan && this.callPlanService.selectedRepCallPlan?.indskr_enddate
                  ? this.datePipe.transform(this.callPlanService.selectedRepCallPlan.indskr_enddate, this.dateTimeFormatsService.date, undefined, this.translate.currentLang)
                  :this.translate.instant('N_A'),
      id:'callplan-custdetails-enddate-field',
      isReadOnly:true,
      isDisabled:true,
    };
    return viewData;
  }

  public getSegmentFormField():IndFormFieldViewDataModel{

    let viewData:IndFormFieldViewDataModel = {
      label: this.translate.instant('SEGMENT'),
      inputText: this.callPlanService.selectedRepCallPlan?.segmentid_Formatted ? this.callPlanService.selectedRepCallPlan?.segmentid_Formatted:'',
      id:'callplan-custdetails-segment-field',
      isReadOnly:true,
      isDisabled:true,
    };
    return viewData;
  }

  public getPositionFormField():IndFormFieldViewDataModel{

    let viewData:IndFormFieldViewDataModel = {
      label: this.translate.instant('POSITION'),
      inputText: this.callPlanService.selectedRepCallPlan?.positionName ? this.callPlanService.selectedRepCallPlan?.positionName:'',
      id:'callplan-custdetails-position-field',
      isReadOnly:true,
      isDisabled:true,
    };
    return viewData;
  }

  public getMeetingGoalFormField():IndFormFieldViewDataModel{

    let viewData:IndFormFieldViewDataModel = {
      label: this.translate.instant('GOAL'),
      inputText: (this.callPlanService.selectedRepCallPlan?.indskr_hocalls != undefined) ? String(this.callPlanService.selectedRepCallPlan?.indskr_hocalls):'',
      id:'callplan-custdetails-meeting-goal-field',
      isReadOnly:true,
      isDisabled:true,
    };
    return viewData;
  }

  public getMeetingSuggestedFormField():IndFormFieldViewDataModel {
    let isDisabled = false;
    if(this.callPlanService.selectedCallPlan && this.callPlanService.selectedCallPlan.isTeamCallPlan) {
      isDisabled = this.callPlanService.selectedRepCallPlan.statuscode != 100000003;
    } else {
      isDisabled = this.callPlanService.selectedRepCallPlan.statuscode != 1;
    }
    let viewData: IndFormFieldViewDataModel = {
      label: this.translate.instant('SUGGESTED'),
      inputText: (this.callPlanService.selectedRepCallPlan.indskr_suggestedcalls > 0) ? String(this.callPlanService.selectedRepCallPlan.indskr_suggestedcalls) : '0',
      inputType: 'number',
      inputValue: (this.callPlanService.selectedRepCallPlan.indskr_suggestedcalls > 0) ? this.callPlanService.selectedRepCallPlan.indskr_suggestedcalls : 0,
      formattingSymbol: '%',
      id: 'callplan-custdetails-meeting-suggested-field',
      isReadOnly: false,
      isDisabled: isDisabled,
      placeholderLabel: '',
      showArrow: true,
      formFieldType: FormFieldType.INLINE_INPUT,
      isRequired: !isDisabled,
      errorMessage: this.activityService.getErrorMessageRequiredField(),
      isEmptyRequiredField: this.isClickedMeetingSuggested && (this.callPlanService.selectedRepCallPlan.indskr_suggestedcalls<=0 || !this.callPlanService.selectedRepCallPlan.indskr_suggestedcalls),
      eventHandler: (id: string, event, eventName) => this.setSuggestedMeeting(event.target.value, eventName),
    };
    return viewData;
  }

  public getMeetingCompletedFormField():IndFormFieldViewDataModel{

    let viewData:IndFormFieldViewDataModel = {
      label: this.translate.instant('COMPLETED'),
      inputText: (this.callPlanService.selectedRepCallPlan?.indskr_actualcalls != undefined) ? String(this.callPlanService.selectedRepCallPlan?.indskr_actualcalls):'',
      id:'callplan-custdetails-meeting-completed-field',
      isReadOnly:true,
      isDisabled:true,
    };
    return viewData;
  }

  public getMeetingCompletionFormField():IndFormFieldViewDataModel{

    let viewData:IndFormFieldViewDataModel = {
      label: this.translate.instant('COMPLETION'),
      inputText: (this.callPlanService.selectedRepCallPlan?.indskr_meetingcompletionpercent != undefined) ? String(this.callPlanService.selectedRepCallPlan?.indskr_meetingcompletionpercent)+"%":'',
      id:'callplan-custdetails-meeting-completion-field',
      isReadOnly:true,
      isDisabled:true,
    };
    return viewData;
  }

  public getMessageGoalFormField():IndFormFieldViewDataModel{

    let viewData:IndFormFieldViewDataModel = {
      label: this.translate.instant('GOAL'),
      inputText: (this.callPlanService.selectedRepCallPlan?.indskr_hoemails != undefined) ? String(this.callPlanService.selectedRepCallPlan?.indskr_hoemails):'',
      id:'callplan-custdetails-message-goal-field',
      isReadOnly:true,
      isDisabled:true,
    };
    return viewData;
  }

  public getMessageSuggestedFormField():IndFormFieldViewDataModel {
    let isDisabled = false;
    if(this.callPlanService.selectedCallPlan && this.callPlanService.selectedCallPlan.isTeamCallPlan) {
      isDisabled = this.callPlanService.selectedRepCallPlan.statuscode != 100000003;
    } else {
      isDisabled = this.callPlanService.selectedRepCallPlan.statuscode != 1;
    }

    let viewData: IndFormFieldViewDataModel = {
      label: this.translate.instant('SUGGESTED'),
      inputText: (this.callPlanService.selectedRepCallPlan.indskr_suggestedemails > 0) ? String(this.callPlanService.selectedRepCallPlan.indskr_suggestedemails) : '0',
      inputType: 'number',
      inputValue: (this.callPlanService.selectedRepCallPlan.indskr_suggestedemails > 0) ? this.callPlanService.selectedRepCallPlan.indskr_suggestedemails : 0,
      formattingSymbol: '%',
      id: 'callplan-custdetails-meeting-suggested-field',
      isReadOnly: false,
      isDisabled: isDisabled,
      placeholderLabel: '',
      showArrow: true,
      formFieldType: FormFieldType.INLINE_INPUT,
      isRequired: !isDisabled,
      errorMessage: this.activityService.getErrorMessageRequiredField(),
      isEmptyRequiredField: this.isClickedMessageSuggested && (this.callPlanService.selectedRepCallPlan.indskr_suggestedemails<=0 || !this.callPlanService.selectedRepCallPlan.indskr_suggestedemails),
      eventHandler: (id: string, event, eventName) => this.setSuggestedEmail(event.target.value, eventName),
    };
    return viewData;
  }

  public getMessageCompletedFormField():IndFormFieldViewDataModel{

    let viewData:IndFormFieldViewDataModel = {
      label: this.translate.instant('COMPLETED'),
      inputText: (this.callPlanService.selectedRepCallPlan?.indskr_actualemails != undefined) ? String(this.callPlanService.selectedRepCallPlan?.indskr_actualemails):'',
      id:'callplan-custdetails-message-completed-field',
      isReadOnly:true,
      isDisabled:true,
    };
    return viewData;
  }

  public getMessageCompletionFormField():IndFormFieldViewDataModel{

    let viewData:IndFormFieldViewDataModel = {
      label: this.translate.instant('COMPLETION'),
      inputText: (this.callPlanService.selectedRepCallPlan?.indskr_emailcompletionpercent != undefined) ? String(this.callPlanService.selectedRepCallPlan?.indskr_emailcompletionpercent)+"%":'',
      id:'callplan-custdetails-message-completion-field',
      isReadOnly:true,
      isDisabled:true,
    };
    return viewData;
  }

  public getCoverateTeamDataModel(repCallPlan): MainCardViewDataModel {
    let viewData: MainCardViewDataModel;
    viewData={
      id: repCallPlan.ID,
      primaryTextRight: repCallPlan.contactFirstName + ' ' + repCallPlan.contactLastName,
      primaryTextLeft: (repCallPlan.indskr_meetingcompletionpercent || 0) + '%',
      isPrimaryTextLeftPillType: true,
      primaryTextLeftTwo: this.translate.instant('STATUS') + ' - ' + this.statePipe.transform(repCallPlan.statuscode),
      secondaryTextRight: repCallPlan.positionName,
      clickHandler: (id: string, event) => {
      },
    }
    return viewData;
  }

  private handleFormFieldEvent(id, event, eventName) {
    if (id) {
      switch (id) {
        case 'callplan-custdetails-name-field':
          let contact = this.contactService.getContactByID(this.callPlanService.selectedRepCallPlan['contactId']);
          if(contact) this.openContactDetails(contact);
          break;
        default:
          console.log("Unhandled switch case statement");
          break;
      }
    }
  }

  private openContactDetails(contact){
    this.contactService.contactInformation = contact;
    if(this.device.isOffline) {
        this.contactService.contactInformation = this.contactService.contacts.find(x => x.ID == contact.ID);
    }
    this.uiService.contactDetailsSegment = 'info';
    this.contactService.contactPageMode = ComponentViewMode.READONLY;
    if(this.uiService.activeView == 'ActivitiesPageRightPaneNav' && this.uiService.activitiesPagePlanTabSelectedPlan =='callPlans'){
      this.navService.pushChildNavPageWithPageTracking(ContactDetailsComponent, PageName.CallPlanCustDetailsComponent, PageName.ActivitiesDetailsPaneComponent,{contactListMode :ComponentViewMode.READONLY});
    } else {
      this.navService.pushChildNavPageWithPageTracking(ContactDetailsComponent, PageName.CallPlanCustDetailsComponent, PageName.CallPlanComponent,{contactListMode :ComponentViewMode.READONLY});
    }
  }
}
