import { ProcedureTrackerActivity } from './../../../classes/activity/procedure-tracker.activity.class';
import { FooterService, FooterViews } from '@omni/services/footer/footer.service';
import { IAllocationAdjustment } from './../../../interfaces/allocation/allocation-adjustment.interface';
import { IAllocationTransfer } from './../../../interfaces/allocation/allocation-transfer.interface';
import { IAllocationShipment, IAllocationShipmentBase } from './../../../interfaces/allocation/allocation-shipment.interface';
import { AllocationFeatureService } from './../../../services/sample/allocation.feature.service';
import { TimeOffActivity } from './../../../classes/activity/timeoff.class';
import { FollowUpActivity } from './../../../classes/activity/follow-up-action.activity.class';

import {takeUntil} from 'rxjs/operators';
import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef, Injector } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { Events } from '@omni/events';
import { UIService } from '../../../services/ui/ui.service';
import { NavigationService, PageName } from '../../../services/navigation/navigation.service';
import { SampleService } from '../../../services/sample/sample.service';
import { ActivityService } from '../../../services/activity/activity.service';
import { DeviceService } from '../../../services/device/device.service';
import { AllocationOrdersList } from '../allocation-orders-list/allocation-orders-list';
import { AllocationShipmentService } from '../../../services/sample/allocation-shipment.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { AdjustmentApprovalType, FeatureActionsMap } from '../../../classes/authentication/user.class';
import { AllocationShipmentFilterPipe } from '../../../pipes/allocation-shipments-filter.pipe';
import { Subject, combineLatest } from 'rxjs';
import { TrackService, TrackingEventNames } from '../../../services/logging/tracking.service';
import { MultiSelectPopover } from '../../multi-select-popover/multi-select-popover';
import { AllocationTransferService } from '../../../services/sample/allocation-transfer.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivityType } from '../../../classes/activity/activity.class';
import { SampleActivity } from '../../../classes/activity/sample.activity.class';
import { IndTabsDataModel } from '@omni/models/ind-tabs-data-model';
import {IndHeaderLeftDataModel} from "../../../models/indHeaderLeftDataModel";
import { AllocationAdjustService } from '@omni/services/sample/allocation-adjust.service';
import { AdjustmentsListComponent } from '../adjustments-list/adjustments-list.component';
import { AppointmentActivity } from '@omni/classes/activity/appointment.activity.class';
import { PhoneActivity } from '@omni/classes/activity/phone.activity.class';
import { EmailActivity } from '@omni/classes/activity/email.activity.class';
import { OrderActivity } from '@omni/classes/activity/order.activity.class';
import { CaseActivity } from '@omni/classes/case-intake/case-activity.class';
import { LiveMeetActivity } from '../../../classes/activity/appointment.activity.class';
import { SurgeryOrderActivity } from '@omni/classes/activity/surgery-order.activity.class';
import { DatePipe } from '@angular/common';
import { AdjustmentStatus, ShipmentStatus, TransferType } from '../../../enums/allocation/allocation.enum';
import { Utility } from '@omni/utility/util';
import { GlobalUtilityService } from '../../../services/global-utility.service';
import { UserInventoryListComponent } from '../user-inventory-list/user-inventory-list';
import { AgendaFooterService, AgendaFooterView } from '@omni/services/footer/agenda-footer.service';
import { FeatureActionsService } from '@omni/services/feature-actions/feature-actions.service';

const MIN_SEARCH_LENGTH = 3;

@Component({
    selector: 'customer-allocation-list',
    templateUrl: 'customer-allocation-list.html',
    styleUrls:['customer-allocation-list.scss']
})
export class CustomerAllocationList implements OnInit, OnDestroy {
    destroy$: Subject<boolean> = new Subject<boolean>();
    public customerAllocationMode: string = 'history';
    filteredList: (IAllocationShipment | IAllocationTransfer | IAllocationAdjustment)[] = [];
    filteredListToBeDisplayed: (IAllocationShipment | IAllocationTransfer | IAllocationAdjustment)[] = [];
    private _shipmentsFilterPopover;
    public isReceiptsEnabled: boolean = false;
    public shipmentStatus = ShipmentStatus;
    public adjustmentStatus = AdjustmentStatus;
    public transferType = TransferType;
    public isTransferEnabled = false;
    public isAdjustEnabled = false;
    public isAdjustTeamRequestEnabled = false;
    private readonly allocShipmentFilterPipe: AllocationShipmentFilterPipe;
    private filteredRecordCount = 20;
    @ViewChild('content', {static:true}) content: HTMLIonContentElement;
    @ViewChild('allocOrderList') allocOrderList: AllocationOrdersList;
    @ViewChild('adjustmentList') adjustmentlist: AdjustmentsListComponent;
    @ViewChild('inventoryList') inventoryList: UserInventoryListComponent;

  filterPopoverData: { text: string; value: string; items: any; handler: (selectedItem: any, item: any, parent: any) => void; }[];
  listFiltered: boolean = false;
  filterTxt: string = '';
  filterValueSelected: boolean = false;
  public orderSearchEventSubject: Subject<string> = new Subject<string>();
  public adjustmentListSearchEventSubject: Subject<string> = new Subject<string>();
  public inventoryListSearchEventSubject: Subject<string> = new Subject<string>();
  tabsData: IndTabsDataModel[];
  indHeaderLeftModel: IndHeaderLeftDataModel;
  isSearchedInput: boolean = false;
  private appliedAllocTransferFilterCount: number = 0;
  private searchString = '';
  isShipmentDataReady = false;
  isShipmentInitDone = false;

  constructor(
    public uiService: UIService,
    private readonly navService: NavigationService,
    public sampleService: SampleService,
    public device: DeviceService,
    public activityService: ActivityService,
    private readonly events: Events,
    public allocationShipmentService: AllocationShipmentService,
    private readonly popoverCtrl: PopoverController,
    private readonly authenticationOfflineService: AuthenticationService,
    public trackingService: TrackService,
    public allocTransferService: AllocationTransferService,
    public translate: TranslateService,
    private cd: ChangeDetectorRef,
    private allocAdjustService: AllocationAdjustService,
    private datePipe: DatePipe,
    public allocFeatureService: AllocationFeatureService,
    public footerService: FooterService,
    private injector: Injector,
    private utilityService: GlobalUtilityService,
    private agendaFooterService: AgendaFooterService,
    private faService: FeatureActionsService,
  ) {
    this.allocShipmentFilterPipe = new AllocationShipmentFilterPipe(this.allocationShipmentService);
    this.initOrdersHeaderLeft();
  }

  public onClosePage(event): void {
    if (this.allocFeatureService.selectedShipment) {
      this.allocFeatureService.selectedShipment = null;
    }
    if (this.sampleService.selectedSampleSKU) {
      this.sampleService.selectedSampleSKU = null;
    }
    if (this.activityService.selectedActivity && this.activityService.selectedActivity.type === ActivityType.Sample) {
      this.activityService.selectedActivity = null;
    }

    if (this.uiService.activitiesPageTab == 'plans') {
      if (this.uiService.activitiesPagePlanTabSelectedPlan === 'planTabTodo') {
        if (this.activityService.selectedActivity === null) {
          if (this.activityService.selectedActivityAtHome && (this.activityService.selectedActivityAtHome instanceof SampleActivity)) {
            this.events.publish('allocationOrderActivityOpen', this.activityService.selectedActivityAtHome);
          }
        }
      } else if (this.uiService.activitiesPagePlanTabSelectedPlan === 'notifications') {
        this.allocFeatureService.selectedShipment = this.allocFeatureService.selectedShipmentAtHome;
      }
    }

    this.trackingService.tracking('CustomerAllocationsBack', TrackingEventNames.ALLOCATIONS);
    this.navService.popWithPageTracking().then(data => {
      this.activityService.selectedActivity = this.activityService.selectedActivityAtHome;
      this.allocFeatureService.selectedShipment = this.allocFeatureService.selectedShipmentAtHome;
      this.allocationShipmentService.newAdjustmentSelectedFromList$.next(true);
      this.allocationShipmentService.fromAgendaPlanTab = false;
      this.uiService.activeView = this._getActiveView();
      this.events.publish('scrollToDate');
      this.events.publish("tools-back-clicked", PageName.AllocationComponent);
      if (this.navService.getActiveChildNavViewPageName() === PageName.ActivitiesDetailsPaneComponent) {
        this.footerService.initButtons(FooterViews.Activities);
      } else if (this.navService.getActiveChildNavViewPageName() === PageName.EmailDetailsPageComponent) {
        this.footerService.initButtons(FooterViews.EmailDetails);
      } else if (this.navService.getActiveChildNavViewPageName() === PageName.PhoneCallDetailsComponent) {
        this.footerService.initButtons(FooterViews.PHONE_CALL);
      } else if (this.navService.getActiveChildNavViewPageName() === PageName.CaseManagementDetailComponent) {
        this.footerService.initButtons(FooterViews.CUSTOMER_INQUIRY);
      } else if (this.navService.getActiveChildNavViewPageName() === PageName.NewSampleActivityComponent) {
        this.footerService.initButtons(FooterViews.SampleDropDetails);
      } else {
        // Short call feature requires to bring the tab back to short call home
        if (this.faService.isShortCallLauncherEnabled) {
          this.agendaFooterService.initButtons(AgendaFooterView.ShortCallLauncher);
          this.uiService.setAgendaTab('shortCallHome');
        }
      }
    });
  }


  private _getActiveView() {
    let activeview = this.uiService.prevView;
    if (this.activityService.selectedActivity instanceof PhoneActivity) {
      activeview = "PhoneCall"
    } else if (this.activityService.selectedActivity instanceof EmailActivity) {
      activeview = "Email"
    } else if (this.activityService.selectedActivity instanceof OrderActivity) {
      activeview = "ActivitiesPageRightPaneNav"
    } else if (this.activityService.selectedActivity instanceof CaseActivity) {
      activeview = "customer_inquiry"
    } else if (this.activityService.selectedActivity instanceof TimeOffActivity) {
      activeview = "TimeOffDetail"
    } else if (this.activityService.selectedActivity instanceof LiveMeetActivity) {
      activeview = "ActivitiesPageRightPaneNav"
    } else if (this.activityService.selectedActivity instanceof SurgeryOrderActivity) {
      activeview = "ActivitiesPageRightPaneNav"
    } else if (this.activityService.selectedActivity instanceof ProcedureTrackerActivity) {
      activeview = "ActivitiesPageRightPaneNav"
    } else if (this.activityService.selectedActivity instanceof SampleActivity) {
      activeview = "Sample"
    } else if (this.activityService.selectedActivity instanceof FollowUpActivity || this.activityService.selectedActivity instanceof AppointmentActivity) {
      activeview = "ActivitiesPageRightPaneNav"
    } else if (this.allocFeatureService.selectedShipment) {
      activeview = 'AllocationShipment';
    }

    return activeview;
  }


    ngOnInit() {
    this.init();

    combineLatest([
      this.allocFeatureService.isShipmentDataReadyObs,
      this.allocFeatureService.currentTab$.asObservable(),
    ])
    .pipe(
      takeUntil(this.destroy$),
    )
    .subscribe(([isReady, currentTab]: [boolean, 'history' | 'receipt' | 'teamAdjustment' | 'inventory']) => {
        if (isReady) {
          // Data ready
          this.isShipmentDataReady = true;
          if (!this.isShipmentInitDone) {
            this.initShipments();
          } else if (currentTab === 'receipt') {
            this.filteredRecordCount = 20;
            this.filterShipments();
          }
        } else {
          this.isShipmentDataReady = false;
        }
      }
    );
  }

  init() {
    this.isTransferEnabled = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.ALLOCATION_TRANSFER);
    this.isAdjustEnabled = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.ALLOCATION_ADJUSTMENT);
    this.isReceiptsEnabled = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.RECIEPTS_TAB);
    this.isAdjustTeamRequestEnabled = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.ALLOCATION_ADJUSTMENT_TEAM_REQUEST) && this.authenticationOfflineService.user.buSettings['indskr_approvalforallocationadjustment'] == AdjustmentApprovalType.Legacy;

    this.allocationShipmentService.showAllShipmentsFilter$.next(true);
    this.sampleService.selectedFilterList = [];
    this.sampleService.multiProductSelectionFilter = [];

    this.setTabsData();
    this.content.scrollToTop();
  }

  initShipments() {
    if (this.isShipmentInitDone) return;

    this.allocationShipmentService.showAllShipmentsFilter$.pipe(
      takeUntil(this.destroy$))
      .subscribe(value => {
        this.filteredRecordCount = 20;
        this.filterShipments();
      });
    const itemsForProductFilter = Array.from(this.allocFeatureService.shipmentsSkuFilterOptionsMap.values());
    itemsForProductFilter.sort((a, b) => (a.text.toLowerCase() > b.text.toLowerCase()) ? 1 : -1);

    let statusItems:{text: any, value: ShipmentStatus | AdjustmentStatus}[] = [{ text: this.translate.instant('SHIPPED'), value: ShipmentStatus.Shipped },
      { text: this.translate.instant('RECEIPTED'), value: ShipmentStatus.Acknowledged }, { text: this.translate.instant('OPEN'), value: ShipmentStatus.Draft }, { text: this.translate.instant('FOR_REVIEW'), value: ShipmentStatus.ForReview }, { text: this.translate.instant('NOT_APPROVED'), value: ShipmentStatus.NotApproved }]

    if (this.isAdjustEnabled) {
       statusItems = [{ text: this.translate.instant('SHIPPED'), value: ShipmentStatus.Shipped },
      { text: this.translate.instant('RECEIPTED'), value: ShipmentStatus.Acknowledged },
      { text: this.translate.instant('OPEN'), value: AdjustmentStatus.Open },
      { text: this.translate.instant('FOR_REVIEW'), value: AdjustmentStatus.InReview },
      { text: this.translate.instant('NOT_APPROVED'), value: ShipmentStatus.NotApproved },
      { text: this.translate.instant('APPROVED'), value: AdjustmentStatus.Approved }];
    }

    this.filterPopoverData = [
      {
        text: '',
        value: 'All',
        items: [
          { text: this.translate.instant('ALL_SHIPMENTS'), value: 'All' }
        ],
        handler: (selectedItem, item, itemRef) => {
          itemRef.parent.items.map((o) => {
            o.value = '';
          });
          item.value = selectedItem.value;
          this.allocationShipmentService.clearFilters();
          this.listFiltered = false;
          this.setFilterText();
          this.updateEmptyMessage();
        }
      },
      {
        text: this.translate.instant('PRODUCT'),
        value: '',
        items: itemsForProductFilter,
        handler: (selectedItem, item, itemRef) => {
          itemRef.parent.items[0].value = ''
          item.value = item.value === selectedItem.value ? '' : selectedItem.value;
          this.allocationShipmentService.shipmentsSKUFilter = item.value;
          if (item.value == '' && !this.allocationShipmentService.shipmentsStatusFilter
            && !this.allocationShipmentService.transferTypeFilter) {
            itemRef.parent.items[0].value = 0;
            this.listFiltered = false;
            this.setFilterText();
            this.allocationShipmentService.showAllShipmentsFilter$.next(true);
            return;
          }
          this.setFilterText();
          this.listFiltered = true;
          this.allocationShipmentService.showAllShipmentsFilter$.next(false);
          this.updateEmptyMessage();
        }
      },
      {

        text: this.translate.instant('STATUS'),
        value: '',
        items: statusItems,
        handler: (selectedItem, item, itemRef) => {
          itemRef.parent.items[0].value = '';
          item.value = item.value === selectedItem.value && item.text.lowercased() ===  selectedItem.text.lowercased()? '' : selectedItem.value;
          this.allocationShipmentService.shipmentsStatusFilter = item.value;
          if (item.value == '' && !this.allocationShipmentService.shipmentsSKUFilter
            && !this.allocationShipmentService.transferTypeFilter) {
            itemRef.parent.items[0].value = 0;
            this.listFiltered = false;
            this.setFilterText();
            this.allocationShipmentService.showAllShipmentsFilter$.next(true);
            return;
          }
          this.setFilterText();
          this.listFiltered = true;
          this.allocationShipmentService.showAllShipmentsFilter$.next(false);
          this.updateEmptyMessage();
        }
      }
    ];

    let items = []
    if (this.isTransferEnabled) {
      items = [
        { text: this.translate.instant('SHIPMENT'), value: TransferType.AllocationShipment },
        { text: this.translate.instant('TRANSFER'), value: TransferType.AllocationTransfer }
      ];
      if (this.isAdjustEnabled) {
        items.push({ text: this.translate.instant('AL_ADJUSTMENT'), value: TransferType.AllocationAdjustment })
      }
    } else {
      if (this.isAdjustEnabled) {
        items = [
          { text: this.translate.instant('SHIPMENT'), value: TransferType.AllocationShipment },
          { text: this.translate.instant('AL_ADJUSTMENT'), value: TransferType.AllocationAdjustment }
        ];
      }
    }
    this.filterPopoverData.push(
      {
        text: this.translate.instant('TYPE'),
        value: '',
        items: items,
        handler: (selectedItem, item, itemRef) => {
          itemRef.parent.items[0].value = ''
          item.value = item.value === selectedItem.value ? '' : selectedItem.value;
          this.allocationShipmentService.transferTypeFilter = item.value;
          if (item.value === '' && !this.allocationShipmentService.shipmentsStatusFilter
            && !this.allocationShipmentService.shipmentsSKUFilter) {
            itemRef.parent.items[0].value = 0;
            this.listFiltered = false;
            this.setFilterText();
            this.allocationShipmentService.showAllShipmentsFilter$.next(true);
            return;
          }
          this.setFilterText();
          this.listFiltered = true;
          this.allocationShipmentService.showAllShipmentsFilter$.next(false);
          this.updateEmptyMessage();
        }
      }
    );

    this.allocTransferService.newAllocTransferAdded$.pipe(
      takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.allocationShipmentService.showAllShipmentsFilter$.next(true);
        }
      });

    this.allocAdjustService.newAllocAdjustmentAdded$.pipe(
      takeUntil(this.destroy$))
      .subscribe({
        next: (adjustment) => {
          this.filterShipments();
        }
      });

    this.filterPopoverData[0].value = 'All';
    this.updateEmptyMessage();

    this.events.subscribe('adjustmentUpdated$',() => {
      const idx = this.filteredListToBeDisplayed.findIndex(shipment =>
        this.allocFeatureService.selectedShipment.indskr_usershipmentallocation_v2id
        === shipment.indskr_usershipmentallocation_v2id
      );

      if (idx > -1) {
        const shipment = this.filteredListToBeDisplayed[idx];
        if (
          this.allocFeatureService.selectedShipment?.indskr_adjustedstatus === AdjustmentStatus.Open
          && this.allocFeatureService.selectedShipment?.indskr_adjustedstatus !== shipment.indskr_adjustedstatus
          && shipment.indskr_adjustedstatus === AdjustmentStatus.InReview
        ) {
          this.handleSelection(this.allocFeatureService.selectedShipment);
        }

        this.filteredListToBeDisplayed[idx] = this.allocFeatureService.selectedShipment;
      }
    });

    this.events.subscribe('adjustmentDeleted$', (adjustmentId) => {
      if (adjustmentId) {
        const idx = this.filteredListToBeDisplayed.findIndex(shipment => shipment.indskr_usershipmentallocation_v2id === adjustmentId);
        if (idx >= 0) {
          this.filteredListToBeDisplayed.splice(idx, 1);
          this.filterShipments();
        }
      }
    });

    this.isShipmentInitDone = true;
  }


  updateEmptyMessage() {
    if (this.customerAllocationMode !== 'history')
      this.events.publish('updateAllocationsRHSEmptyMessage', this.filteredList.length > 0 ? true : false);
  }

  ngOnDestroy() {
    this.allocationShipmentService.clearFilters(true);
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    this.allocTransferService.destroyForm();
    this.allocAdjustService.destroyForm();
    this.events.unsubscribe('adjustmentUpdated$');
    this.events.unsubscribe('adjustmentDeleted$');
    this.allocationShipmentService.adjustmentDeleted$.unsubscribe();
  }

  public get searchPlaceholderString(): string {
    let str: string = this.translate.instant('SEARCH');
    switch (this.customerAllocationMode) {
      case 'history':
        str = this.translate.instant('SEARCH_ORDERS');
        break;
      case 'receipts':
        str = this.translate.instant('SEARCH_SHIPMENTS');
        break;
      case 'adjustments':
        str = this.translate.instant('SEARCH_ADJUSTMENTS');
        break;
      case 'inventory':
        str = this.translate.instant('SEARCH_INVENTORY');
        break;
      default:
        console.log('Unhandled switch case statement');
        break;
    }
    return str;
  }

  handleSelection(shipment: IAllocationShipment | IAllocationTransfer | IAllocationAdjustment) {
    this.trackingService.tracking('CustomerAllocationReceiptsDetails', TrackingEventNames.ALLOCATIONS);
    this.sampleService.selectedSampleSKU = null;
    this.uiService.prevView = this.uiService.activeView;

    if (this.isAdjustEnabled) {
      this.allocAdjustService.destroyForm();
    }

    this.allocFeatureService.selectedShipment = shipment;
    if (shipment.indskr_transfertype === TransferType.AllocationAdjustment) {
      // Adjustment
      if ((shipment as IAllocationAdjustment).indskr_adjustedstatus === AdjustmentStatus.Open) {
        this.uiService.activeView = 'AllocationAdjust';
        this.uiService.prevView = 'CustomerAllocation';
        this.allocationShipmentService.isNewAdjustment = false;
        this.allocationShipmentService.newAdjustmentSelectedFromList$.next(true);
      } else {
        this.uiService.prevView = this.uiService.activeView;
        this.uiService.activeView = 'AllocationShipment';
        this.allocationShipmentService.newShipmentSelectedFromList$.next(PageName.AllocationComponent);
      }
    } else {
      // Shipment or Transfer
      this.uiService.prevView = this.uiService.activeView;
      this.uiService.activeView = 'AllocationShipment';
      this.allocationShipmentService.newShipmentSelectedFromList$.next(PageName.AllocationComponent);
    }

    if (this.isTransferEnabled) {
      this.allocTransferService.destroyForm();
    }
  }

  public getFormattedDate(value) {
    return `${this.datePipe.transform(value,'MMM dd' , undefined, this.translate.currentLang)}`;
  }

  getFormattedAdjustedDate(shipment: IAllocationAdjustment) {
    if (shipment.indskr_adjustmentdate) {
      return this.getFormattedDate(shipment.indskr_adjustmentdate);
    }
  }

  isShipmentApproved(shipment: IAllocationShipment | IAllocationTransfer | IAllocationAdjustment) {
    if (shipment.indskr_transfertype === TransferType.AllocationAdjustment) {
      return shipment.indskr_adjustedstatus === this.adjustmentStatus.Approved;
    } else {
      return shipment.indskr_shipmentstatus === this.shipmentStatus.Acknowledged;
    }
  }

  openOrdersFilter(event) {
    if (this.allocOrderList) {
      this.allocOrderList.filterData(event);
    }
  }

  openAdjustmentsFilter(event) {
    if (this.adjustmentlist) {
      this.adjustmentlist.filterData(event);
    }
  }

  openInventoryFilter(event) {
    if (this.inventoryList) {
      this.inventoryList.filterData(event);
    }
  }

  public async openShipmentsFilter(event) {
    this.filterValueSelected = true;
    this.trackingService.tracking('CustomerAllocationReceiptsFilter', TrackingEventNames.ALLOCATIONS);
    this._shipmentsFilterPopover = await this.popoverCtrl.create({
      component: MultiSelectPopover, componentProps: { root: this.filterPopoverData },
      event: event
    });
    this._shipmentsFilterPopover.present();
    this._shipmentsFilterPopover.onDidDismiss().then(() => {
      this._shipmentsFilterPopover = null;
    });
    this.sampleService.allocationOrdersSearchStr = '';
  }

  public setFilterText(): string {
    let str: string = '';
    this.appliedAllocTransferFilterCount = 0;
    if (!this.allocFeatureService.shipmentsSkuFilterOptionsMap) return;
    if (this.allocationShipmentService.shipmentsSKUFilter) {
      str += this.allocationShipmentService.shipmentsSKUFilter.skuName;
      this.appliedAllocTransferFilterCount++;
    }
    if (this.allocationShipmentService.shipmentsStatusFilter) {
      if (this.allocationShipmentService.shipmentsStatusFilter === ShipmentStatus.Acknowledged) {
        str += str.length > 0 ? this.translate.instant('AL_RECEIPTED') : this.translate.instant('RECEIPTED');
        this.appliedAllocTransferFilterCount++;
      }
      if (this.allocationShipmentService.shipmentsStatusFilter === ShipmentStatus.Shipped) {
        str += str.length > 0 ? this.translate.instant('AL_SHIPPED') : this.translate.instant('SHIPPED');
        this.appliedAllocTransferFilterCount++;
      }
      if (this.allocationShipmentService.shipmentsStatusFilter === ShipmentStatus.Draft) {
        str += str.length > 0 ? this.translate.instant('AL_OPEN') : this.translate.instant('OPEN');
        this.appliedAllocTransferFilterCount++;
      }
      if (this.allocationShipmentService.shipmentsStatusFilter === ShipmentStatus.ForReview) {
        str += str.length > 0 ? this.translate.instant('AL_FOR_REVIEW') : this.translate.instant('FOR_REVIEW');
        this.appliedAllocTransferFilterCount++;
      }
      if (this.allocationShipmentService.shipmentsStatusFilter === ShipmentStatus.NotApproved) {
        str += str.length > 0 ? this.translate.instant('AL_NOT_APPROVED') : this.translate.instant('NOT_APPROVED');
        this.appliedAllocTransferFilterCount++;
      }

      if (this.isAdjustEnabled) {

        if (this.allocationShipmentService.shipmentsStatusFilter === AdjustmentStatus.Open) {
          str += str.length > 0 ? this.translate.instant('AL_SHIPMENT_FILTERLIST_OPEN') : this.translate.instant('Open');
          this.appliedAllocTransferFilterCount++;
        }

        if (this.allocationShipmentService.shipmentsStatusFilter === AdjustmentStatus.InReview) {
          str += str.length > 0 ? this.translate.instant('AL_SHIPMENT_FILTERLIST_FOR_REVIEW') : this.translate.instant('FOR_REVIEW');
          this.appliedAllocTransferFilterCount++;
        }

        if (this.allocationShipmentService.shipmentsStatusFilter === AdjustmentStatus.Approved) {
          str += str.length > 0 ? this.translate.instant('AL_SHIPMENT_FILTERLIST_APPROVED') : this.translate.instant('Approved');
          this.appliedAllocTransferFilterCount++;
        }
      }
    }
    if (this.allocationShipmentService.transferTypeFilter) {
      if (this.allocationShipmentService.transferTypeFilter === TransferType.AllocationShipment) {
        str += str.length > 0 ? this.translate.instant('AL_SHIPMENT') : this.translate.instant('SHIPMENT');
        this.appliedAllocTransferFilterCount++;
      } else if (this.allocationShipmentService.transferTypeFilter === TransferType.AllocationTransfer) {
        str += str.length > 0 ? this.translate.instant('AL_TRANSFER') : this.translate.instant('TRANSFER');
        this.appliedAllocTransferFilterCount++;
      } else if (this.allocationShipmentService.transferTypeFilter === TransferType.AllocationAdjustment && this.isAdjustEnabled) {
        str += str.length > 0 ? this.translate.instant('AL_SHIPMENT_FILTERLIST_ADJUSTMENT') : this.translate.instant('AL_ADJUSTMENT');
        this.appliedAllocTransferFilterCount++;
      }
    }
    this.filterTxt = str;
  }

    private getAppliedAllocTransferFilterCount() {
      return this.filterTxt ? this.appliedAllocTransferFilterCount : 0;
    }

    getFilterButtonBadgeCount() {
      if (this.customerAllocationMode === 'receipts') {
        return this.getAppliedAllocTransferFilterCount();
      } else if (this.customerAllocationMode === 'adjustments') {
        return this.adjustmentlist ? this.adjustmentlist.getAppliedFilterCount() : 0;
      }
      else {
        return this.allocOrderList ? this.allocOrderList.getAppliedFilterCount() : 0;
      }
    }

    public trackEvent(event: string){
        this.content.scrollToTop();
        //@ts-ignore
        if(this.allocFeatureService.currentTab$.value === 'receipt' && this.isSearchedInput){
          this.searchInput({})
        }
        if (event === 'orders') {
          this.trackingService.tracking('CustomerAllocationOrderHistory', TrackingEventNames.ALLOCATIONS);
          this.allocFeatureService.currentTab$.next('history');
        } else if(event === 'receipts') {
          this.trackingService.tracking('CustomerAllocationReceipts', TrackingEventNames.ALLOCATIONS);
          this.allocFeatureService.currentTab$.next('receipt');
        } else if (event === 'adjustments') {
          this.allocFeatureService.currentTab$.next('teamAdjustment');
        } else if (event === 'inventory') {
          this.allocFeatureService.currentTab$.next('inventory');
        }

        if (this.isTransferEnabled) {
          this.allocTransferService.destroyForm();
        }

        if (this.isAdjustEnabled) {
          this.allocAdjustService.destroyForm();
        }

        this.allocFeatureService.selectedShipment = undefined;
        this.uiService.activeView = "CustomerAllocation"
        this.sampleService.allocationOrdersSearchStr = '';
        this.orderSearchEventSubject.next('');
        this.adjustmentListSearchEventSubject.next('');
        this.inventoryListSearchEventSubject.next('');
        this.cd.detectChanges();
    }

    openAllocationTransfer() {
      if (this.uiService.activeView !== 'AllocationTransfer') {
        this.uiService.prevView = this.uiService.activeView;
        this.uiService.activeView = 'AllocationTransfer';
        this.allocFeatureService.selectedShipment = undefined;
        if (this.allocAdjustService.adjustForm) {
          this.allocAdjustService.destroyForm();
        }
      }
    }

    openAllocationAdjust() {
      if (this.uiService.activeView !== 'AllocationAdjust') {
        this.uiService.prevView = this.uiService.activeView;
        this.uiService.activeView = 'AllocationAdjust';
        this.allocFeatureService.selectedShipment = undefined;
         if (this.allocTransferService.transferForm) {
          this.allocTransferService.destroyForm();
        }
        this.allocationShipmentService.isNewAdjustment = true;
      }
    }

    getShipmentIconPath(shipment: IAllocationShipmentBase) {
      if (shipment.indskr_transfertype === TransferType.AllocationTransfer) {
        // Transfer
        return shipment.ownerId === this.authenticationOfflineService.user.xSystemUserID ? 'assets/imgs/transfer_To.svg' : 'assets/imgs/transfer_From.svg';
      } else {
        // Shipment or Adjustment
        return 'assets/imgs/transfer_down.svg';
      }
    }

    getShipmentTransferToFrom(shipment: IAllocationTransfer) {
      return shipment.ownerId === this.authenticationOfflineService.user.xSystemUserID
        ? this.translate.instant('TO')+` ${shipment.userFullName}`
        : this.translate.instant('FROM')+` ${shipment.ownerFullName}`;
    }

    searchInput(event) {
      this.filterValueSelected = false;
      let val = (event?.value) ? event.value : '';

      if (this.customerAllocationMode === 'history') {
        this.orderSearchEventSubject.next(val);
      } else if (this.customerAllocationMode === 'receipts') {
        this.searchString = val;
        if (val.length < MIN_SEARCH_LENGTH) {
          this.isSearchedInput = false;
          this.filterShipments();
        } else if (val.length >= MIN_SEARCH_LENGTH) {
          this.isSearchedInput = true;
          this.filterShipments();
        }
      } else if (this.customerAllocationMode === 'adjustments') {
          this.adjustmentListSearchEventSubject.next(val);
      } else if (this.customerAllocationMode === 'inventory') {
        this.inventoryListSearchEventSubject.next(val);
      }
      this.updateEmptyMessage();
    }

    filterShipments() {
      if (!this.isShipmentDataReady) return;

      this.filteredList = this.allocShipmentFilterPipe.transform(this.allocFeatureService.shipments);

      if (this.isSearchedInput) {
        this.filteredList = this.allocShipmentFilterPipe.search(this.filteredList, this.searchString);
      }

      this.filteredList = this.allocShipmentFilterPipe.orderByShipmentOrAdjustmentDate(this.filteredList);
      this.filteredListToBeDisplayed = this.filteredList.slice(0, this.filteredRecordCount);
    }

    setTabsData() {
      this.tabsData = [
        {
          displayText: this.translate.instant('ORDERS'),
          value: 'history'
        },
      ];
      if(this.isReceiptsEnabled) {
        this.tabsData.push({
            displayText: this.translate.instant('AL_SHIPMENTS'),
            value: 'receipts',
        });
      }
      if (this.isAdjustTeamRequestEnabled) {
        this.tabsData.push({
          displayText: this.translate.instant('ALLOCATION_ADJUSTMENTS'),
          value: 'adjustments'
        });
      }
      this.tabsData.push({
        displayText: this.translate.instant('INVENTORY'),
        value: 'inventory',
      });
    }

    doInfinite(event) {
      this.filteredRecordCount = this.filteredListToBeDisplayed.length;
      this.filteredListToBeDisplayed.push(...this.filteredList.slice(this.filteredRecordCount, this.filteredRecordCount + 20));
      event.target.complete();
    }

    public onPageTitleControlClick(id: string) {
      switch (id) {
        case 'close':
          this.onClosePage(id);
          break;
        default:
          console.log("Unhandled switch case statement");
          break;
      }
    }

    public get globalCustomerText(): string {
    let translate = this.injector.get(TranslateService);
    if (Utility.globalCustomerText) {
      return translate.instant(Utility.globalCustomerText);
    }
  }

    private initOrdersHeaderLeft(): void {
      let buttons = [];
      let textdisplayName;

      switch (this.utilityService.globalCustomerText) {
      case 'Stakeholder':
        textdisplayName = this.translate.instant('STAKEHOLDER');
        break;
      case 'Contact':
        textdisplayName = this.translate.instant('CONTACT');
        break;
      case 'Customer':
        textdisplayName = this.translate.instant('CUSTOMER');
        break;
      default:
        textdisplayName = this.utilityService.globalCustomerText;
        break;
    }
      buttons.push({
          id: "close",
          imgSrc: 'assets/imgs/back_to_home_btn.svg',
          isDisabled: false,
          align: "left",
      });
      this.indHeaderLeftModel = {
        id: 'orders-list-header-left',
        cssClass: 'main-tool-header-title',
        title: this.translate.instant('CUSTOMER_ALLOCATION_WITH_TEXT', { text: textdisplayName }),
        mode: true,
        controls: buttons,
      };
    }
}
