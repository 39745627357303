import { IShipmentReason, ISkuFilter } from './../../interfaces/allocation/allocation.shared.interface';
import { AllocationFeatureService } from './allocation.feature.service';
import { Lot } from './../../classes/sample/lot.class';
import { Injectable } from "@angular/core";
import { AllocationAdjustForm } from "@omni/classes/sample/allocation-adjust.class";
import { DB_KEY_PREFIXES, PREFIX_SEARCH_ENDKEY_UNICODE } from "@omni/config/pouch-db.config";
import { LotDTO } from "@omni/models/sample-model";
import { isWithinRange, compareDesc } from "date-fns";
import { uniqBy } from "lodash";
import { SelectListData } from "../../components/popover/popover";
import { AuthenticationService } from "../authentication.service";
import { DiskService } from "../disk/disk.service";
import { SampleService } from "./sample.service";
import { AllocationShipmentService } from './allocation-shipment.service';
import { BehaviorSubject, Subject } from "rxjs";
import { FeatureActionsMap } from "@omni/classes/authentication/user.class";
import { AdjustmentError } from '../../data-services/sample/allocation-adjust.data.service';
import { HttpErrorResponse } from '@angular/common/http';
import {MyAssistantService, NOTIFICATION} from '../my-assistant/my-assistant.service';
import { TranslateService } from '@ngx-translate/core';
import { EventsService } from '../events/events.service';
import { NotificationService, ToastStyle } from '../notification/notification.service';
import { IAllocationAdjustment, IAllocationAdjustmentCreateResponseDTO } from '../../interfaces/allocation/allocation-adjustment.interface';
import { AdjustmentStatus } from '../../enums/allocation/allocation.enum';
import { convertTimestampStringToISODateFormat } from '../../utility/common.utility';
import {IndNotificationDataModel} from "@omni/models/indNotificationDataModel";


@Injectable({
  providedIn: 'root'
})
export class AllocationAdjustService {
  public adjustReasons: IShipmentReason[] = [];
  public adjustForm: AllocationAdjustForm;
  public lots: Array<Lot> = [];
  public allLots: Array<Lot> = [];
  public newAllocAdjustmentAdded$: Subject<IAllocationAdjustment> = new Subject<IAllocationAdjustment>();
  public showAllAdjustmentsFilter$ = new BehaviorSubject<boolean>(null);
  public teamAdjustmentSearchString: string;
  public selectedFilterList: { text: string, value: string }[] = [];
  multiProductSelectionFilter = [];
  public adjustmentStatusFilter: AdjustmentStatus;
  public isStatusFilterExpanded: boolean;
  public adjustmentSKUFilter: ISkuFilter;
  private allocationNotificationModel: IndNotificationDataModel;


  constructor(private disk: DiskService,
    private readonly authService: AuthenticationService,
    private readonly sampleService: SampleService,
    private readonly shipmentService: AllocationShipmentService,
    private myAssistantService: MyAssistantService,
    private translate: TranslateService,
    private notificationService: NotificationService,
    private events: EventsService,
    private allocFeatureService: AllocationFeatureService,
    ) {
    this.loadAllLotsFromDBAndMap();
  }

  async saveInitialSyncedAdjustmentsData(raw: IAllocationAdjustment[], isTeamData: boolean = false): Promise<boolean> {
    if (Array.isArray(raw)) {
      try {
        const key: string = !isTeamData ? DB_KEY_PREFIXES.ALLOC_SHIPMENT_ADJUSTMENT : DB_KEY_PREFIXES.ALLOC_SHIPMENT_TEAM_ADJUSTMENT;
        await this.disk.updateOrInsert(key, () => ({ raw }));

        return true;
      } catch (error) {
        console.error('saveInitialSyncedAdjustmentsData: ', error);
      }
    }
    return false;
  }
  async processDeltaSyncedAdjustmentData(addedOrUpdatedRawData: IAllocationAdjustment[], deletedRawData: any[], isTeam: boolean = false): Promise<boolean | null> {
    if (
        (Array.isArray(addedOrUpdatedRawData) && addedOrUpdatedRawData.length > 0)
        || (Array.isArray(deletedRawData && deletedRawData.length > 0))
    ) {
      try {
        const key: string = !isTeam ? DB_KEY_PREFIXES.ALLOC_SHIPMENT_ADJUSTMENT : DB_KEY_PREFIXES.ALLOC_SHIPMENT_TEAM_ADJUSTMENT;
        let shouldUpsert: boolean = false;
        let localDbData: { raw: IAllocationAdjustment[], _rev?: string, _id?: string } = await this.disk.retrieve(key, true);

        // Sanity checks
        if (!localDbData) {
          // No record
          localDbData = {
            raw: []
          };
          shouldUpsert = true;
        } else if (localDbData && !Array.isArray(localDbData.raw)) {
          localDbData.raw = [];
        }

        const localDataMap: Map<string, number> = new Map(localDbData.raw.map((i, idx) => [i.indskr_usershipmentallocation_v2id, idx]));
        const itemsToBeAppended: IAllocationAdjustment[] = [];
        const deletedIdxes: number[] = [];

        // First replace
        for (let i = 0; i < addedOrUpdatedRawData.length; i++) {
          const deltaData = addedOrUpdatedRawData[i];
          const idx = localDataMap.get(deltaData.indskr_usershipmentallocation_v2id)

          if (localDataMap.has(deltaData.indskr_usershipmentallocation_v2id)) {
            if (isTeam && deltaData.indskr_adjustedstatus && deltaData.indskr_adjustedstatus === AdjustmentStatus.Open) {
              // For team adjustment, open status need to be deleted
              deletedIdxes.push(idx);
            } else {
              // Replace
              localDbData.raw[idx] = deltaData;
            }
          } else {
            // New data
            // Push to array to append later
            itemsToBeAppended.push(deltaData);
          }
        }

        // Remove deleted
        for (let i = 0; i < deletedRawData.length; i++) {
          const deletedData = deletedRawData[i];

          if (localDataMap.has(deletedData.indskr_usershipmentallocation_v2id)) {
            deletedIdxes.push(localDataMap.get(deletedData.indskr_usershipmentallocation_v2id));
          }
        }
        // Sort indexes in descending order
        deletedIdxes.sort((a, b) => b - a);
        for (let i = 0; i < deletedIdxes.length; i++) {
          const idxToBeDeleted = deletedIdxes[i];
          localDbData.raw.splice(idxToBeDeleted, 1);
        }

        // Append newly added
        if (itemsToBeAppended.length > 0) {
          localDbData.raw.unshift(...itemsToBeAppended);
        }

        if (!shouldUpsert) {
          await this.disk.updateDocWithIdAndRev(localDbData, false, true);
        } else {
          await this.disk.updateOrInsert(key, doc => localDbData);
        }

        return true;
      } catch (error) {
        console.error('processDeltaSyncedAdjustmentData: ', isTeam, error);
      }
    } else {
      // No delta changes
      return true;
    }
    return false;
  }

  async loadFromDBAndMapAdjustmentReasons() {
    this.adjustReasons = [];
    let dbData = await this.disk.retrieve(DB_KEY_PREFIXES.ALLOC_ADJUST_REASONS, true);
    dbData.rawReasons = dbData.rawReasons.sort((a, b) => {
      if (a.value != 548910004)
        return a.reason.toLowerCase() > b.reason.toLowerCase() ? 1 : -1
    });
    if (dbData && Array.isArray(dbData.rawReasons)) {
      for (let i = 0; i < dbData.rawReasons.length; i++) {
        const rawReason = dbData.rawReasons[i];
        this.adjustReasons.push(rawReason);
      }
    }
  }

  public getReasonText(reasonNumber) {
    const foundReason = this.adjustReasons.find((reason) => reason.value === parseInt(reasonNumber))
    return foundReason.reason
  }

  async mapAdjustmentReasons(rawReasons: IShipmentReason[]) {
    // Doesn't have delta sync yet.
    this.adjustReasons = [];
    // rawReasons = rawReasons.sort((a,b)=> a.reason.toLowerCase() > b.reason.toLowerCase() ? 1 : -1 );

    let otherReasonIndex = rawReasons.findIndex((rawReason) => rawReason.value === 548910004);
    let otherReason = rawReasons[otherReasonIndex]

    let otherReasons = rawReasons.splice(otherReasonIndex, 1);

    rawReasons = rawReasons.sort((a, b) => a.reason.toLowerCase() > b.reason.toLowerCase() ? 1 : -1);

    rawReasons.push(otherReasons[0]);

    if (Array.isArray(rawReasons)) {
      for (let i = 0; i < rawReasons.length; i++) {
        const rawReason = rawReasons[i];
        this.adjustReasons.push(rawReason);
      }
      this.disk.updateOrInsert(DB_KEY_PREFIXES.ALLOC_ADJUST_REASONS, (doc) => {
        return { rawReasons };
      });
    }
  }

  async handleGetAnAdjustmentDetail(updatedAdjustment: IAllocationAdjustment, adjustment: IAllocationAdjustment) {
    const lot = this.lots.find(l => l.id === updatedAdjustment.at_indskr_lotid);

    if (lot && updatedAdjustment.ownerId === this.authService.user.systemUserID) {
      if ((updatedAdjustment.indskr_quantityadjusted > 0 && updatedAdjustment.indskr_shipmentstatus === AdjustmentStatus.Approved)
        && adjustment.indskr_shipmentstatus !== updatedAdjustment.indskr_shipmentstatus) {
        if (adjustment.lastUpdatedTime > this.sampleService.lotsLastSynTime) {
          lot.totalQuantityRemaining += updatedAdjustment.indskr_quantityadjusted;
        }
      } else if (updatedAdjustment.indskr_quantityadjusted < 0
        && updatedAdjustment.indskr_shipmentstatus === AdjustmentStatus.Open
        && adjustment.indskr_shipmentstatus !== updatedAdjustment.indskr_shipmentstatus) {
        // rejected
        if (adjustment.lastUpdatedTime > this.sampleService.lotsLastSynTime) {
          lot.totalQuantityRemaining += (-1 * updatedAdjustment.indskr_quantityadjusted);
        }
      }
    }

    updatedAdjustment.lastUpdatedTime = (new Date()).getTime();

    // ***** update lot *****//
    // update valid lots for Adjustment
    if (lot && this.authService.user.systemUserID === adjustment.ownerId) {
      let idx = this.lots.findIndex((aLot) => aLot.id === lot.id);
      if (idx > -1) {
        this.lots[idx] = lot;
      }

      // update all lots for adjustments.
      idx = this.allLots.findIndex((aLot) => aLot.id === lot.id);
      if (idx > -1) {
        this.allLots[idx] = lot;
      }

      idx = this.sampleService.lots.findIndex((aLot) => aLot.id === lot.id);
      if (idx > -1) {
        this.sampleService.lots[idx] = lot;
      }

      await this.sampleService.updateOrInsertLotQuantityDetailToOfflineDB(lot, true);
    }

    // Add notification
    if (this.allocFeatureService.selectedShipment.indskr_adjustedstatus !== updatedAdjustment.indskr_adjustedstatus
      && updatedAdjustment.indskr_adjustedstatus === AdjustmentStatus.Open) { // rejected Case
      // Create New Notification
      this.allocationNotificationModel = {
        type: NOTIFICATION.ADJUSTMENT_REJECTED,
        name: this.translate.instant("ADJUSTMENT_REJECTED", {productName: adjustment.at_indskr_skuname}),
        DateTime: Date.now(),
        id: NOTIFICATION.ADJUSTMENT_REJECTED + updatedAdjustment.indskr_usershipmentallocation_v2id,
        data: adjustment,
        icon: 'assets/imgs/sample_activity_agendaIcon.svg',
        isRed: false,
        params: {productName: adjustment.at_indskr_skuname}
      };
      this.myAssistantService.saveNotificationToDisk(this.allocationNotificationModel);
      await this.myAssistantService.loadAndMapNotificationsFromDB();
      /*const name = this.translate.instant('AL_ADJUSTMENTS_NOT_APPROVED_NOTIFICATION', { text: adjustment.indskr_shipmentnumber })

      const index = this.myAssistantService.myAssistantData.findIndex(data => data.name === name);

      if (index < 0) {

        // this.myAssistantService.addNewNotification(name);
      }*/

    }

    if (adjustment.ownerId === this.authService.user.systemUserID) {
      await this.allocFeatureService.accessLocalDB(updatedAdjustment, 'adjustment', 'upsert');
      this.allocFeatureService.addToOrReplaceInShipmentsArray(updatedAdjustment);
    } else {
      // team
      await this.allocFeatureService.accessLocalDB(updatedAdjustment, 'teamAdjustment', 'upsert');
    }
    return updatedAdjustment;
  }

  async handlePostAdjustmentDelete(adjustment: IAllocationAdjustment) {
    let adjustmentID = adjustment.indskr_usershipmentallocation_v2id;
    let removeSuccess = this.allocFeatureService.removeFromShipmentsArray(adjustment);
    if (removeSuccess) {
      await this.allocFeatureService.accessLocalDB(adjustment, 'adjustment', 'delete');
    }
    return adjustmentID;
  }

  clearFilters() {
    this.adjustmentStatusFilter = undefined;
    this.adjustmentSKUFilter = undefined
    this.isStatusFilterExpanded = false;
    this.showAllAdjustmentsFilter$.next(true);
  }

  async handlePostAdjustment(response: IAllocationAdjustmentCreateResponseDTO, form: AllocationAdjustForm) {
    const lotDTO = form.lot.DTO;
    const rawNewAllocAdjustment: IAllocationAdjustment = this.createRawAdjustmentData(response, form, lotDTO);
    this.allocFeatureService.addToOrReplaceInShipmentsArray(rawNewAllocAdjustment, true);
    // Add to array
    return await this.updateAdjLocalDataAndLot(response, rawNewAllocAdjustment);
  }

  private createRawAdjustmentData(response: IAllocationAdjustmentCreateResponseDTO, form: AllocationAdjustForm, lotDTO: LotDTO): IAllocationAdjustment {
    return {
      at_indskr_lotid: form.lot.id,
      at_indskr_lotname: form.lot.name,
      at_indskr_lotvalidfromdate: convertTimestampStringToISODateFormat(lotDTO.indskr_lotvalidfromdate, 'date'),
      at_indskr_lotvalidtodate: convertTimestampStringToISODateFormat(lotDTO.indskr_lotvalidtodate, 'date'),
      at_indskr_skuid: response.indskr_skuid,
      at_indskr_skuname: form.sku.title,

      indskr_adjustmentdate: convertTimestampStringToISODateFormat(response.indskr_adjustmentdate, 'dateTime'),
      indskr_adjustedstatus: response.indskr_adjustedstatus,
      indskr_adjustmentapproveddate: convertTimestampStringToISODateFormat(response.indskr_adjustmentapproveddate, 'dateTime'),
      indskr_externalid: response.indskr_externalid,
      indskr_name: response.indskr_name,
      indskr_quantityadjusted: response.indskr_quantityadjusted,
      indskr_reasonforadjustment: response.indskr_reasonforadjustment,
      indskr_comments: response.indskr_comment,
      indskr_shipmentnumber: response.indskr_shipmentnumber,
      indskr_transfertype: response.indskr_transfertype,
      indskr_usershipmentallocation_v2id: response.indskr_usershipmentallocationid,
      approvedByName: this.authService.hasFeatureAction(FeatureActionsMap.ALLOCATION_ADJUSTMENT_AUTO_APPROVAL) ? this.authService.user.displayName : response.approvedByName,
      _indskr_approvedby_value: this.authService.hasFeatureAction(FeatureActionsMap.ALLOCATION_ADJUSTMENT_AUTO_APPROVAL) ? this.authService.user.systemUserID : response.indskr_approvedby,

      ownerFullName: this.authService.user.displayName,
      ownerId: this.authService.user.xSystemUserID,
      _indskr_adjustedby_value: this.authService.user.xSystemUserID,

      lastUpdatedTime: (new Date()).getTime(),

      at_lot_statecode: null,
      at_sku_statecode: null,
      statecode: null,
      statuscode: null,
    }
  }

  private async updateTeamAdjustment(response: IAllocationAdjustmentCreateResponseDTO, adjustment: IAllocationAdjustment): Promise<IAllocationAdjustment> {
    let teamAdjustmentToReturn: IAllocationAdjustment;
    let dbData = await this.disk.retrieve(DB_KEY_PREFIXES.ALLOC_SHIPMENT_TEAM_ADJUSTMENT, true);
    let rawTeamAdjustments: IAllocationAdjustment[] = dbData?.raw ?? null;

    if (Array.isArray(rawTeamAdjustments)) {
      let idx = rawTeamAdjustments.findIndex((ad) => ad.indskr_usershipmentallocation_v2id === adjustment.indskr_usershipmentallocation_v2id);
      if (idx >= 0) {
        teamAdjustmentToReturn = rawTeamAdjustments[idx];

        teamAdjustmentToReturn.indskr_adjustedstatus = response.indskr_adjustedstatus;

        if (response.indskr_adjustedstatus === AdjustmentStatus.Approved) {
          teamAdjustmentToReturn._indskr_approvedby_value = this.authService.user.systemUserID;
          teamAdjustmentToReturn.approvedByName = this.authService.user.displayName;
          teamAdjustmentToReturn.indskr_adjustmentapproveddate = convertTimestampStringToISODateFormat(response.indskr_adjustmentapproveddate, 'dateTime');
          rawTeamAdjustments[idx] = teamAdjustmentToReturn;
        } else if (response.indskr_adjustedstatus === AdjustmentStatus.Open) {
          rawTeamAdjustments.splice(idx, 1);
        }

        await this.disk.updateDocWithIdAndRev(dbData);
      }

      return teamAdjustmentToReturn ? JSON.parse(JSON.stringify(teamAdjustmentToReturn)) : teamAdjustmentToReturn;
    }
  }

  private async updateMyAdjustment(response: any, adjustment: IAllocationAdjustment) {
    adjustment.indskr_shipmentstatus = response['indskr_adjustedstatus'];
    if (this.adjustForm) {
      adjustment.at_indskr_skuid = this.adjustForm.sku.id
      adjustment.at_indskr_skuname = this.adjustForm.sku.title;
      adjustment.at_indskr_lotname = this.adjustForm.lot.name;
      adjustment.at_indskr_lotid = this.adjustForm.lot.id;
      adjustment.indskr_reasonforadjustment = this.adjustForm.reason.value;
      adjustment.indskr_comments = this.adjustForm.comments;
      adjustment.indskr_quantityadjusted = this.adjustForm.quantityAdjusted;
      adjustment.lastUpdatedTime = (new Date()).getTime();
    }
    if (adjustment.indskr_shipmentstatus === AdjustmentStatus.Approved) {
      adjustment.indskr_adjustmentapproveddate = convertTimestampStringToISODateFormat(response['indskr_adjustmentapproveddate'], 'dateTime');
      adjustment.approvedByName = this.authService.hasFeatureAction(FeatureActionsMap.ALLOCATION_ADJUSTMENT_AUTO_APPROVAL) ?  this.authService.user.displayName : response['indskr_approvedBy'];
    }
    this.allocFeatureService.addToOrReplaceInShipmentsArray(adjustment);

    return await this.updateAdjLocalDataAndLot(response, adjustment);
  }
  private async updateAdjLocalDataAndLot(response: any, adjustment: IAllocationAdjustment): Promise<IAllocationAdjustment> {
    // Update DB
    await this.allocFeatureService.accessLocalDB(adjustment, 'adjustment', 'upsert');

    // Update Lot
    const lot = this.allLots.find(l => l.id === adjustment.at_indskr_lotid);

    if (lot) {
      if (adjustment.indskr_quantityadjusted < 0) {
        lot.totalQuantityRemaining += response.indskr_quantityadjusted;
      } else {
        if (this.authService.hasFeatureAction(FeatureActionsMap.ALLOCATION_ADJUSTMENT_AUTO_APPROVAL)) {
          lot.totalQuantityRemaining += response.indskr_quantityadjusted;
        } else {
          if (adjustment.indskr_adjustedstatus === AdjustmentStatus.Open && adjustment.indskr_quantityadjusted < 0) {
            lot.totalQuantityRemaining += (adjustment.indskr_quantityadjusted * -1);
          } else if (adjustment.indskr_adjustedstatus === AdjustmentStatus.Approved && adjustment.indskr_quantityadjusted > 0) {
            lot.totalQuantityRemaining += adjustment.indskr_quantityadjusted;
          }
        }
      }
      await this.sampleService.updateOrInsertLotQuantityDetailToOfflineDB(lot, true);
    }

    // update the lots array
    let index = this.lots.findIndex((aLot) => aLot.id === lot.id)

    if (index > -1) {
      this.lots[index] = lot;
    }

    index = this.allLots.findIndex((aLot) => aLot.id === lot.id)
    if (index > -1) {
      this.allLots[index] = lot;
    }

    index = this.sampleService.lots.findIndex((aLot) => aLot.id === lot.id);
    if (index > -1) {
      this.sampleService.lots[index] = lot;
    }

    return adjustment;
  }

  async handlePostAdjustmentUpdate(response: any, adjustment: IAllocationAdjustment) {
    if (adjustment.ownerId !== this.authService.user.systemUserID) {
      return await this.updateTeamAdjustment(response, adjustment);
    } else {
      return await this.updateMyAdjustment(response, adjustment);
    }
  }

  // ERR_IO_AD01("ERR_IO_AD01", "Allocation adjustment is already Waiting for approval"),
// ERR_IO_AD02("ERR_IO_AD02", "cannot approve/send for approval on Already approved Allocation adjustment"),
// ERR_IO_AD03("ERR_IO_AD03", "cannot approve/reject an already Rejected Allocation adjustment"),
// ERR_IO_AD04("ERR_IO_AD04", "Allocation adjustment is already created"),
// ERR_IO_AD05("ERR_IO_AD05", "Please sync your record with latest changes and then try to update"),
// ERR_IO_AD06("ERR_IO_AD06", "Allocation Adjustment does not exist or already deleted");;


  public async handleError(error, payload: any, adjustment?: IAllocationAdjustment,form?: AllocationAdjustForm,) {

    if (error && error.error && error.error.errorDetails) {

      let errorDetails = error.error.errorDetails;

      if (error instanceof HttpErrorResponse)
        if (error.error.errorCode === AdjustmentError.ERR_IO_AD01) {

          errorDetails['indskr_externalid'] = payload['indskr_externalid'];
          errorDetails['indskr_transfertype'] = payload['indskr_transfertype'];
          errorDetails['indskr_adjustmentdate'] = new Date().toJSON();

          const updatedAdjustment = await this.updateAdjustmentRecord(adjustment, errorDetails, AdjustmentStatus.InReview, form);

          return updatedAdjustment;

        } else if (error.error.errorCode === AdjustmentError.ERR_IO_AD02) {// approving already approved case
          // this.notificationService.notify(this.translate.instant('AL_ADJUSTMENT_ALREADY_APPROVED'), 'AllocationShipmentDetailsComponent', "top", ToastStyle.DANGER, 3000);
          if (form) {
            errorDetails['indskr_externalid'] = payload['indskr_externalid'];
            errorDetails['indskr_transfertype'] = payload['indskr_transfertype'];
            errorDetails['indskr_adjustmentdate'] = new Date().toJSON();
            const updatedAdjustment = await this.updateAdjustmentRecord(adjustment, errorDetails, AdjustmentStatus.InReview, form);

            return updatedAdjustment;
          } else {
            return this.updateAdjustmentRecord(adjustment, errorDetails, AdjustmentStatus.Approved);
          }
        }
        else if (error.error.errorCode === AdjustmentError.ERR_IO_AD03) {
          // this.notificationService.notify(this.translate.instant('AL_ADJUSTMENT_ALREADY_NOT_APPROVED'), 'AllocationShipmentDetailsComponent', "top", ToastStyle.DANGER, 3000);

          return this.updateAdjustmentRecord(adjustment, errorDetails, AdjustmentStatus.Open);
        } else if (error.error.errorCode === AdjustmentError.ERR_IO_AD04) {
          // this.updateAdjustmentRecord(adjustment, error, AdjustmentStatus.Approved);
          return
        }
        else if (error.error.errorCode === AdjustmentError.ERR_IO_AD05) {// already created Case
          // this.updateAdjustmentRecord(adjustment, error, AdjustmentStatus.Approved);
          return

        }
        else if (error.error.errorCode === AdjustmentError.ERR_IO_AD06) {// Deleted Cases

          return
        }
         else if (error.error.ErrorCode === 'ERR_IO_500') {
          this.notificationService.notify(this.translate.instant('AL_ADJUSTMENT_ERROR_COULD_NOT_APPROVED'), 'Adjust Approval error', 'top', ToastStyle.DANGER);
          return null;
        }
    }
  }

  private async updateAdjustmentRecord(adjustment: IAllocationAdjustment, errorDetails: any, status: number, form?: AllocationAdjustForm) {

    if (form === undefined) {// approve not approve case
      if (adjustment.ownerId !== this.authService.user.systemUserID) {
        adjustment.indskr_shipmentstatus = status;
        adjustment.indskr_usershipmentallocation_v2id = errorDetails['indskr_usershipmentallocationid'];
        adjustment.indskr_shipmentnumber = errorDetails['indskr_shipmentnumber'];
        adjustment.indskr_name = errorDetails['indskr_name'];
        if (status === AdjustmentStatus.Approved) {
          adjustment._indskr_approvedby_value = this.authService.user.systemUserID;
          adjustment.approvedByName = this.authService.user.displayName;
          adjustment.indskr_adjustmentapproveddate = new Date().toJSON();
        }
        // update team request.
        await this.handlePostAdjustmentUpdate(adjustment, adjustment);
      }
    } else {// send for Approval case,
      let lotDTO = form?.lot?.DTO;
      const createdAdjustment: IAllocationAdjustment = this.createRawAdjustmentData(errorDetails, form, lotDTO)
      adjustment = await this.updateAdjLocalDataAndLot(errorDetails, createdAdjustment);
    }
    return adjustment;
  }

  async loadAllLotsFromDBAndMap(forceReload = false) {

    this.lots = [];
    this.allLots = [];

    const now = new Date().getTime();
    const option = {
      selector: {
        '_id': {
          $gte: DB_KEY_PREFIXES.LOT,
          $lte: DB_KEY_PREFIXES.LOT + PREFIX_SEARCH_ENDKEY_UNICODE
        },
      }
    };

    try {
      const rawLots: LotDTO[] = await this.disk.find(option);

      if (rawLots && Array.isArray(rawLots)) {
        rawLots.map(rawLot => {
          let newLot = new Lot(rawLot);
          let adjustedDate = new Date(new Date((new Date().setDate(new Date().getDate() - this.authService.user.allocationAdjustmentDuration))).setHours(0, 0, 0, 0))
          this.allLots.push(newLot);
          if ((isWithinRange(new Date(), newLot.validFrom, newLot.validTo) || isWithinRange(adjustedDate, newLot.validFrom, newLot.validTo) || compareDesc(adjustedDate, newLot.validTo) !== -1) && newLot.status == 0 && newLot.totalQuantityRemaining != undefined && newLot.totalQuantityRemaining != null  && newLot.totalQuantityRemaining >= 0) {
            this.lots.push(newLot);
          }
        });
      }


  this.lots = this.lots.sort((lot1, lot2) =>  {
    return lot1.name.toLowerCase() < lot2.name.toLowerCase() ? -1 : 1;
  });

    } catch (error){
      console.error('loadLotsFromDBAndMap: ', error);
      // TODO: handle error..
    }
  }

  getAdjustProducts(lots: Lot[]): SelectListData[] {
    const availLots = lots.filter(lot => lot.totalQuantityRemaining >= 0);
    const uniqueLots = uniqBy(availLots, 'sampleSKUId');
    let arrayToReturn: SelectListData[] = [];
    arrayToReturn = uniqueLots.map((lot: Lot) => ({ id: lot.sampleSKUId, title: lot.sampleSKUName, isSelected: this.checkIfProductSelectedInAdjustForm(lot.sampleSKUId) }));
    arrayToReturn = arrayToReturn.sort((a, b) => a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1);
    return arrayToReturn;
  }

  private checkIfProductSelectedInAdjustForm(productId: string): boolean {
    return this.adjustForm && this.adjustForm.sku && this.adjustForm.sku.id === productId;
  }

  initForm(adjustment?: IAllocationAdjustment, authService?: AuthenticationService, sampleService?: SampleService) {
    if (!this.adjustForm) {
      if (this.shipmentService.isNewAdjustment) {
        this.adjustForm = new AllocationAdjustForm(undefined, authService, this.adjustReasons, this.sampleService, this.events);
      } else {
        this.adjustForm = new AllocationAdjustForm(adjustment, authService, this.adjustReasons, this.sampleService, this.events, this);
      }
    }
    return this.adjustForm;
  }

  destroyForm() {
    this.adjustForm = undefined;
  }
}
