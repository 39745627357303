import { Utility } from './../../utility/util';
import { ContactOfflineService } from '@omni/services/contact/contact.service';
import { Injectable } from '@angular/core';
import { DiskService } from '../disk/disk.service';
import { GlobalErrorHandler } from '../error-handler/error-handler-service';
import { BrandOfflineService } from '../brand/brand.service';
import { AuthenticationService } from '../authentication.service';
import { FeatureActionsMap } from '../../classes/authentication/user.class';
import { DB_KEY_PREFIXES } from '../../config/pouch-db.config';
import { SelectedSuggestionPillDataModel } from '../../models/search-config-data-model';
import { SearchConfigService } from './../search/search-config.service';
import { ComponentViewMode } from '../ui/ui.service';
import { Events } from '@omni/events';
import { Account } from '@omni/classes/account/account.class';
import { Subject } from 'rxjs';
import * as _ from 'lodash';
import { CallPlanFilter } from '@omni/classes/call-plan/rep-call-plan.class';

@Injectable({
  providedIn: 'root'
})
export class CallPlanOfflineService {
    public callPlanFilter: string = "All Products";
    public multiProductSelectionFilter: string[] = [];
    public callPlanPageView:string;
    public selectedCallPlan:any;
    public selectedRepCallPlan:any;
    public selectedCallPlanAtHome:any;
    public meetingOrEmailFilter: string = 'Engagements';
    public positionFilter: string;
    public callPlanMode:string;

    public repCallPlanDetailFilterValue: string = 'all';
    public repCallPlanDetailCompletionFilter: boolean = false;
    public repCallPlanDetailNonCompletionFilter: boolean = false;
    public repCallPlanDetailStatusFilterOpen: boolean = false;
    public repCallPlanDetailStatusFilterApproved: boolean = false;
    public repCallPlanDetailStatusFilterForReview: boolean = false;
    public repCallPlanDetailPositionFilter: string = '';
    public showAccountLIst:Boolean = false;
    public callPlanAccessedFromScheduler: boolean = false;
    public cp_selectedAccount:Array<Account>=[];
    public callplanDetailsLoaded:boolean = true;
    public callPlanSearchText: string = ''

    // advanced search
    myRecentSearches: SelectedSuggestionPillDataModel[] = [];
    teamRecentSearches: SelectedSuggestionPillDataModel[] = [];
    public contactPageMode: ComponentViewMode;

    public segmentCallPlans:any[] = [];
    public teamSegmentCallPlans:any[] = [];
    public teamPositionsOtherRepPlans:any[] = [];
    public otherRepCallPlansList$: Subject<any> = new Subject();
  public segmentCallPlansList$: Subject<any> = new Subject();
  public currentFooterFilter: string = 'call-plan-current';

    constructor(
        private disk: DiskService,
        private errorhandler:GlobalErrorHandler,
        private productService: BrandOfflineService,
        private authenticationService: AuthenticationService,
        private searchConfigService: SearchConfigService,
        private events: Events,
        private readonly contactService: ContactOfflineService
    ) {
    }

    get loadedCallPlans(): boolean {
        return this.segmentCallPlans.length > 0;
    }
    get loadedTeamCallPlans(): boolean {
      return this.teamSegmentCallPlans.length > 0;
    }

    get getCallPlanMode(){
        return this.callPlanMode;
      }

    setCallPlanMode(mode:string){
        this.callPlanMode = mode;
    }

    get getStringForFilterValue(): string {
        //Need to return a friendly string for the current filter set
        //Filter can be Status, completed or position

        //If the filter is set to all simply return All
        if (this.repCallPlanDetailFilterValue)
        return 'All';

        //Else we have to specify all the current filters set
        let returnStringCount: number = 0;

        if (this.repCallPlanDetailCompletionFilter) returnStringCount = returnStringCount + 1;
        if (this.repCallPlanDetailNonCompletionFilter) returnStringCount = returnStringCount + 1;
        if (this.repCallPlanDetailStatusFilterOpen) returnStringCount = returnStringCount + 1;
        if (this.repCallPlanDetailStatusFilterApproved) returnStringCount = returnStringCount + 1;
        if (this.repCallPlanDetailStatusFilterForReview) returnStringCount = returnStringCount + 1;

        this.authenticationService.user.positions.map(position => {
            if (position.selectedRepCallPlanFilter) returnStringCount++;
        });

        // Position Filter for Managers
        this.authenticationService.childPositions.map(position => {
            if (position.selectedRepCallPlanFilter) returnStringCount++;
        });


        return (returnStringCount == 0 ? 'All' : `Filter(${returnStringCount})`);
    }

    get formattedCallPlans(): { past:any[], present:any[], future:any[] } {
      const currentDay: Date = new Date();

      let formattedCallPlanObj = {
          past: [],
          present: [],
          future: []
      };

      this.segmentCallPlans.map(plan => {
          const endDateTimeStampAtMidnight = Utility.changeUTCDateToLocalDateWith0Time(new Date(plan.indskr_enddate).getTime(),true).getTime();
          const startDateTimeStampAtMidnight = Utility.changeUTCDateToLocalDateWith0Time(new Date(plan.indskr_startdate).getTime()).getTime();
          //If end date is in the past
          if (endDateTimeStampAtMidnight < currentDay.getTime()) {
              formattedCallPlanObj.past.push(plan);
              return;
          }

          //If start date is past current date
          //And end date is greater than current date
          if ((startDateTimeStampAtMidnight < currentDay.getTime())
          && (endDateTimeStampAtMidnight > currentDay.getTime())) {
              formattedCallPlanObj.present.push(plan);
              return;
          }

          formattedCallPlanObj.future.push(plan);
          return;
      });

      return formattedCallPlanObj;
  }

    get formattedTeamCallPlans(): { past: any[], present: any[], future: any[] } {
        const currentDay: Date = new Date();

        let formattedCallPlanObj = {
            past: [],
            present: [],
            future: []
        };

        this.teamSegmentCallPlans.map(plan => {
          plan.isTeamCallPlan = true;
          // this.mapTeamCallPlansToSearchIndex(plan);
            const endDateTimeStampAtMidnight = Utility.changeUTCDateToLocalDateWith0Time(new Date(plan.indskr_enddate).getTime(),true).getTime();
            const startDateTimeStampAtMidnight = Utility.changeUTCDateToLocalDateWith0Time(new Date(plan.indskr_startdate).getTime()).getTime();
            //If end date is in the past
            if (endDateTimeStampAtMidnight < currentDay.getTime()) {
                formattedCallPlanObj.past.push(plan);
                return;
            }

            //If start date is past current date
            //And end date is greater than current date
            if ((startDateTimeStampAtMidnight < currentDay.getTime())
            && (endDateTimeStampAtMidnight > currentDay.getTime())) {
                formattedCallPlanObj.present.push(plan);
                return;
            }

            formattedCallPlanObj.future.push(plan);
            return;
        });

        return formattedCallPlanObj;
    }

    public setRepCallPlanDetailPositionFilter(){
        let count = 0;
        this.authenticationService.childPositions.map( position => {
            if(position.selectedRepCallPlanFilter) count++;
        });
        this.repCallPlanDetailPositionFilter = 'positions_' + count.toString();
    }

    public resetAllRepCallPlanFilters() {
        this.repCallPlanDetailFilterValue = 'All';
        this.repCallPlanDetailCompletionFilter = false;
        this.repCallPlanDetailNonCompletionFilter = false;
        this.repCallPlanDetailStatusFilterOpen = false;
        this.repCallPlanDetailStatusFilterForReview = false;
        this.repCallPlanDetailStatusFilterApproved = false;
        this.repCallPlanDetailPositionFilter = '';

        this.authenticationService.user.positions.map(position => {
            position.selectedRepCallPlanFilter = false;
        });
    }

    public selectEmailOrMeetingFilter(newFilterOpt: string) {
        this.meetingOrEmailFilter = newFilterOpt;
        this.events.publish('selectedCallPlanFilter');
    }

    // public mapRepCallPlans(response: any, callPlan: CallPlan) {
    //     if (Array.isArray(response.repCallPlans)) {
    //         this.repCallPlans = [];

    //         response.repCallPlans.map(repCallPlan => {
    //             let repPlan = new RepCallPlan(repCallPlan);
    //             this.repCallPlans.push(repPlan);
    //         });

    //         callPlan.repCallPlans = this.repCallPlans;
    //         callPlan.calculateCallPlanCompletion();
    //     }

    //     if (Array.isArray(response.accounts)) {
    //         //Map the account id's to the parent call plan object for filtering.
    //         const accountArrayIDs = response.accounts.map(accountLink => accountLink.accountid);
    //         callPlan.accountIDs = accountArrayIDs;
    //     }
    // }

    // public async mapCallPlans(response: object, team?: boolean, delta?: boolean, doNotSave = false, dataRange?: { from: string, to: string }) {
    //     if (Array.isArray(response)) {

    //         let callPlanHandle = team ? this.teamCallPlans : this.callPlans;
    //         const newLastUpdatedTime = new Date().getTime();

    //         //If we already have data and it is a delta sync call then we are going to replace records, not overwrite everything
    //         if (delta && callPlanHandle.length > 0) {
    //             //Prepare two arrays for changes/additions
    //             let additions = [];
    //             let changes = [];

    //             //For every call plan in response, find it in this.callPlans
    //             response.map(rawCallPlanJson => {
    //                 rawCallPlanJson.lastUpdatedTime = newLastUpdatedTime;

    //                 //Find it
    //                 let currentCallPlanIndex = callPlanHandle.findIndex(callPlan => callPlan.ID === rawCallPlanJson.indskr_cycleplanid);

    //                 if (currentCallPlanIndex >= 0) {
    //                     //We have the new data and the call plan in question, let's map it.
    //                     rawCallPlanJson.isTeam = team;
    //                     if (rawCallPlanJson.statuscode === CallPlanStatus['Distributed']) {
    //                         let newCallPlan = new CallPlan(rawCallPlanJson);
    //                         newCallPlan.isTeamCallPlan = team;
    //                         callPlanHandle[currentCallPlanIndex] = newCallPlan;
    //                         let product = this.productService.getBrandByID(newCallPlan.productID);
    //                         if (product) {
    //                             newCallPlan.product = product;
    //                             rawCallPlanJson.product = product;
    //                         }
    //                         // advanced search
    //                         newCallPlan.isTeamCallPlan?this.mapTeamCallPlansFieldsToSearchIndex(newCallPlan):this.mapMyCallPlansFieldsToSearchIndex(newCallPlan);
    //                     } else {
    //                         // Status not Distributed anymore. Remove from array.
    //                         callPlanHandle.splice(currentCallPlanIndex, 1);
    //                     }

    //                     //Add to changes array
    //                     changes.push(rawCallPlanJson);
    //                 } else {
    //                     //Call plan doesn't exist, add it?
    //                     rawCallPlanJson.isTeam = team;
    //                     if (rawCallPlanJson.statuscode === CallPlanStatus['Distributed']) {
    //                         let callPlan = new CallPlan(rawCallPlanJson);
    //                         callPlan.isTeamCallPlan = team;
    //                         callPlanHandle.push(callPlan);
    //                         let product = this.productService.getBrandByID(callPlan.productID);
    //                         if (product) {
    //                             callPlan.product = product;
    //                             rawCallPlanJson.product = product;
    //                         }
    //                         // advanced search
    //                         callPlan.isTeamCallPlan?this.mapTeamCallPlansFieldsToSearchIndex(callPlan):this.mapMyCallPlansFieldsToSearchIndex(callPlan);
    //                         //Add additions
    //                         additions.push(rawCallPlanJson);
    //                     }
    //                 }
    //             });

    //             //Update our offline raw json with new shit, kill me.

    //             //We have new changes, we need to load the current json document,
    //             let currentJsonDocument = await this.disk.retrieve((team ? DB_KEY_PREFIXES.TEAM_CALLPLAN : DB_KEY_PREFIXES.MY_CALLPLAN));

    //             //We need to determine if any of our changes are replacements or additions, two arrays?
    //             //Got em

    //             //Additions is easy, add it to end of current json document.
    //             console.log(currentJsonDocument);

    //             if (currentJsonDocument && Array.isArray(currentJsonDocument.raw)) {
    //                 additions.map(newJson => {
    //                     currentJsonDocument.raw.push(newJson);
    //                 });

    //                 changes.map(changeJson => {
    //                     let replacementEntityIdx = currentJsonDocument.raw.findIndex(rawCallPlan => rawCallPlan.indskr_cycleplanid === changeJson.indskr_cycleplanid);
    //                     if (replacementEntityIdx >= 0) {
    //                         if (changeJson.statuscode === CallPlanStatus['Distributed']) {
    //                             currentJsonDocument.raw[replacementEntityIdx] = changeJson;
    //                         } else {
    //                             currentJsonDocument.raw.splice(replacementEntityIdx, 1);
    //                         }
    //                     }
    //                 });
    //             }

    //             try {
    //                 await this.disk.updateDocWithIdAndRev(currentJsonDocument);
    //             } catch (error) {
    //                 console.error('mapCallPlans: ', error);
    //             }

    //             return;
    //         }

    //         response.map(callPlanRaw => {
    //             callPlanRaw.lastUpdatedTime = newLastUpdatedTime;
    //             callPlanRaw.isTeam = team;
    //             if (callPlanRaw.statuscode === CallPlanStatus['Distributed'] &&
    //                 (!dataRange || (dataRange && callPlanRaw.indskr_enddate >= dataRange.from && callPlanRaw.indskr_enddate <= dataRange.to))
    //             ) {
    //                 let callPlan = new CallPlan(callPlanRaw);
    //                 callPlanHandle.push(callPlan);

    //                 // advanced search
    //                 callPlanRaw.isTeam?this.mapTeamCallPlansFieldsToSearchIndex(callPlan):this.mapMyCallPlansFieldsToSearchIndex(callPlan);

    //                 let product = this.productService.getBrandByID(callPlan.productID);
    //                 if (product) callPlan.product = product;
    //             }
    //         });

    //         if (!doNotSave) {
    //         try{
    //             if (team) {
    //                 await this.disk.updateOrInsert(DB_KEY_PREFIXES.TEAM_CALLPLAN, doc => ({raw: response}));
    //             } else {
    //                 await this.disk.updateOrInsert(DB_KEY_PREFIXES.MY_CALLPLAN, doc => ({raw: response}));
    //             }
    //         }catch(e){
    //             console.log("Runtime Error Saving Call Plans Offline " , e);
    //             this.errorhandler.handleError(new Error(e));
    //         }
    //         }
    //     }
    // }

    async purgeData(maxEndDateUnixTimestamp: number) {
        if (!this.authenticationService.hasFeatureAction(FeatureActionsMap.CALL_PLANS)) {
            return;
        }
        await this.purgeCallPlans(maxEndDateUnixTimestamp.toString());

        // if (!this.authenticationService.hasFeatureAction(FeatureActionsMap.TEAM_CALL_PLANS)) {
        //     return;
        // }
        // await this.purgeCallPlans(maxEndDateUnixTimestamp.toString(), true);
    }

    private async purgeCallPlans(maxEndDateUnixTimestampInString: string) {
        //const document = await this.disk.retrieve((isTeam ? DB_KEY_PREFIXES.TEAM_CALLPLAN : DB_KEY_PREFIXES.MY_CALLPLAN));
        const document = await this.disk.retrieve((DB_KEY_PREFIXES.MY_POSITON_CALL_PLANS));
        let filteredCallPlans;
        if (document && Array.isArray(document.raw)) {
            filteredCallPlans = document.raw.filter(raw => {
                if (new Date(raw['indskr_enddate']).getTime() >= parseInt(maxEndDateUnixTimestampInString)) {
                    return true;
                } else {
                    return false;
                }
            });

            // Only write to local db when something got purged
            if (filteredCallPlans.length < document.raw.length) {
                document.raw = filteredCallPlans;
                try {
                    await this.disk.updateDocWithIdAndRev(document);
                } catch (error) {
                    console.error('purgeCallPlans: ', error);
                }
            }
        }
        const document2 = await this.disk.retrieve((DB_KEY_PREFIXES.OTHER_POSITON_CALL_PLANS));
        let filteredCallPlans2;
        if (document2 && Array.isArray(document2.raw)) {
            filteredCallPlans2 = document2.raw.filter(raw => {
                if (new Date(raw['indskr_enddate']).getTime() >= parseInt(maxEndDateUnixTimestampInString)) {
                    return true;
                } else {
                    return false;
                }
            });

            // Only write to local db when something got purged
            if (filteredCallPlans2.length < document2.raw.length) {
                document2.raw = filteredCallPlans2;
                try {
                    await this.disk.updateDocWithIdAndRev(document2);
                } catch (error) {
                    console.error('purgeCallPlans: ', error);
                }
            }
        }
    }

    // advanced search - My Call Plans - category data
    // public mapMyCallPlansFieldsToSearchIndex(newCallPlans:CallPlan){
    //     if(!this.searchConfigService.isConfigInitiated){
    //       this.searchConfigService.initSearchConfigs();
    //       this.searchConfigService.isConfigInitiated = true;
    //     }
    //     if(newCallPlans.productName && !this.searchConfigService.myCallPlansSearchIndexesConfig.find(config=> config.categoryName == 'Products').values.some(o=> o==newCallPlans.productName))
    //         this.searchConfigService.myCallPlansSearchIndexesConfig.find(config=>config.categoryName=='Products').values.push(newCallPlans.productName);

    //     if(newCallPlans.segmentName && !this.searchConfigService.myCallPlansSearchIndexesConfig.find(config=> config.categoryName == 'Segments').values.some(o=> o == newCallPlans.segmentName)){
    //       this.searchConfigService.myCallPlansSearchIndexesConfig.find(config=> config.categoryName == 'Segments').values.push(newCallPlans.segmentName);
    //     }
    //     newCallPlans.repCallPlans.forEach(repCallPlan =>{
    //         if(repCallPlan.customerName && !this.searchConfigService.myCallPlansSearchIndexesConfig.find(config=> config.categoryName == 'Customers').values.some(o=> o == repCallPlan.customerName))
    //             this.searchConfigService.myCallPlansSearchIndexesConfig.find(config=> config.categoryName == 'Customers').values.push(repCallPlan.customerName);
    //     })
    //     newCallPlans.accountsName.forEach(accountName =>{
    //         if(newCallPlans.accountsName && newCallPlans.accountsName.length > 0 && !this.searchConfigService.myCallPlansSearchIndexesConfig.find(config=> config.categoryName == 'Accounts').values.some(o=> o == accountName))
    //             this.searchConfigService.myCallPlansSearchIndexesConfig.find(config=> config.categoryName == 'Accounts').values.push(accountName);
    //     })
    // }

    // advanced search - My Call Plans - category data
  public mapMyCallPlansToSearchIndex(newCallPlans) {
      this.searchConfigService.myCallPlansSearchIndexesConfig.find(config => config.categoryName == 'Products').values =[];
      this.searchConfigService.myCallPlansSearchIndexesConfig.find(config => config.categoryName == 'Customers').values =[];
      this.searchConfigService.myCallPlansSearchIndexesConfig.find(config => config.categoryName == 'Accounts').values =[];
      this.searchConfigService.myCallPlansSearchIndexesConfig.find(config => config.categoryName == 'Specialty').values =[];
    newCallPlans.forEach(async newCP => {
      if (!this.searchConfigService.isConfigInitiated) {
        this.searchConfigService.initSearchConfigs();
        this.searchConfigService.isConfigInitiated = true;
      }
      newCP.repPlans.forEach(p => {
        if (p.productid_Formatted && !this.searchConfigService.myCallPlansSearchIndexesConfig.find(config => config.categoryName == 'Products').values.some(o => o == p.productid_Formatted))
          this.searchConfigService.myCallPlansSearchIndexesConfig.find(config => config.categoryName == 'Products').values.push(p.productid_Formatted);
      })

      if (newCP.customerName && !this.searchConfigService.myCallPlansSearchIndexesConfig.find(config => config.categoryName == 'Customers').values.some(o => o == newCP.customerName)) {
        this.searchConfigService.myCallPlansSearchIndexesConfig.find(config => config.categoryName == 'Customers').values.push(newCP.customerName);
      }
      let accounts = this.contactService.getContactByID(newCP.contactId)?.accountRelationships || []
      accounts.forEach(account => {
        if (account.accountName.length > 0 && !this.searchConfigService.myCallPlansSearchIndexesConfig.find(config => config.categoryName == 'Accounts').values.some(o => o == account.accountName))
          this.searchConfigService.myCallPlansSearchIndexesConfig.find(config => config.categoryName == 'Accounts').values.push(account.accountName);
      })
      newCP.repPlans.forEach(sp => {
        if (sp.specialtyid_Formatted && !this.searchConfigService.myCallPlansSearchIndexesConfig.find(config => config.categoryName == 'Specialty').values.some(o => o == sp.specialtyid_Formatted))
          this.searchConfigService.myCallPlansSearchIndexesConfig.find(config => config.categoryName == 'Specialty').values.push(sp.specialtyid_Formatted);
      })
    })
  }

  public mapMyGoalsPlansToSearchIndex(newCallPlans) {
    newCallPlans.forEach(newCP => {
      if (!this.searchConfigService.isConfigInitiated) {
        this.searchConfigService.initSearchConfigs();
        this.searchConfigService.isConfigInitiated = true;
      }
      if (newCP.repPlans[0].productid_Formatted && !this.searchConfigService.myGoalsPlansSearchIndexesConfig.find(config => config.categoryName == 'Products').values.some(o => o == newCP.repPlans[0].productid_Formatted))
        this.searchConfigService.myGoalsPlansSearchIndexesConfig.find(config => config.categoryName == 'Products').values.push(newCP.repPlans[0].productid_Formatted);

      newCP.repPlans.forEach(repCallPlan => {
        repCallPlan.accounts = this.contactService.getContactByID(repCallPlan.contactId)?.accountRelationships || []
        repCallPlan.accounts.forEach(account => {
          if (account.accountName.length > 0 && !this.searchConfigService.myGoalsPlansSearchIndexesConfig.find(config => config.categoryName == 'Accounts').values.some(o => o == account.accountName))
            this.searchConfigService.myGoalsPlansSearchIndexesConfig.find(config => config.categoryName == 'Accounts').values.push(account.accountName);
        })
      })
    })
  }

    // advanced search - Team Call Plans - category data
    // public mapTeamCallPlansFieldsToSearchIndex(newCallPlans:CallPlan){
    //     if(!this.searchConfigService.isConfigInitiated){
    //       this.searchConfigService.initSearchConfigs();
    //       this.searchConfigService.isConfigInitiated = true;
    //     }
    //     if(newCallPlans.productName && !this.searchConfigService.teamCallPlansSearchIndexesConfig.find(config=> config.categoryName == 'Products').values.some(o=> o==newCallPlans.productName))
    //         this.searchConfigService.teamCallPlansSearchIndexesConfig.find(config=>config.categoryName=='Products').values.push(newCallPlans.productName);

    //     if(newCallPlans.segmentName && !this.searchConfigService.teamCallPlansSearchIndexesConfig.find(config=> config.categoryName == 'Segments').values.some(o=> o == newCallPlans.segmentName)){
    //       this.searchConfigService.teamCallPlansSearchIndexesConfig.find(config=> config.categoryName == 'Segments').values.push(newCallPlans.segmentName);
    //     }
    //     newCallPlans.repCallPlans.forEach(repCallPlan =>{
    //         if(repCallPlan.customerName && !this.searchConfigService.teamCallPlansSearchIndexesConfig.find(config=> config.categoryName == 'Customers').values.some(o=> o == repCallPlan.customerName))
    //             this.searchConfigService.teamCallPlansSearchIndexesConfig.find(config=> config.categoryName == 'Customers').values.push(repCallPlan.customerName);
    //     })
    //     newCallPlans.accountsName.forEach(accountName =>{
    //         if(newCallPlans.accountsName && newCallPlans.accountsName.length > 0 && !this.searchConfigService.teamCallPlansSearchIndexesConfig.find(config=> config.categoryName == 'Accounts').values.some(o=> o == accountName))
    //             this.searchConfigService.teamCallPlansSearchIndexesConfig.find(config=> config.categoryName == 'Accounts').values.push(accountName);
    //     })
    //     newCallPlans.repCallPlans.forEach(repCallPlan =>{
    //         if(repCallPlan.usersName && Array.isArray(repCallPlan.usersName)) {
    //             repCallPlan.usersName.forEach(userName =>{
    //                 if(userName && !this.searchConfigService.teamCallPlansSearchIndexesConfig.find(config=> config.categoryName == 'Users').values.some(o=> o ==userName))
    //                     this.searchConfigService.teamCallPlansSearchIndexesConfig.find(config=> config.categoryName == 'Users').values.push(userName);
    //             })
    //         }
    //     })
    //     newCallPlans.repCallPlans.forEach(repCallPlan =>{
    //         if(repCallPlan.positionName && !this.searchConfigService.teamCallPlansSearchIndexesConfig.find(config=> config.categoryName == 'Positions').values.some(o=> o == repCallPlan.positionName))
    //             this.searchConfigService.teamCallPlansSearchIndexesConfig.find(config=> config.categoryName == 'Positions').values.push(repCallPlan.positionName);
    //         if(repCallPlan.otherRepCallPlans && repCallPlan.otherRepCallPlans.length > 0)
    //         repCallPlan.otherRepCallPlans.forEach(otherRepCallPlan=>{
    //             if(otherRepCallPlan.positionName && !this.searchConfigService.teamCallPlansSearchIndexesConfig.find(config=>config.categoryName == 'Positions').values.some(o=> o == otherRepCallPlan.positionName))
    //             this.searchConfigService.teamCallPlansSearchIndexesConfig.find(config=> config.categoryName == 'Positions').values.push(otherRepCallPlan.positionName);
    //         })
    //     })
    // }

    // advanced search - My Call Plans - category data
    public mapTeamCallPlansToSearchIndex(newCP){
        if(!this.searchConfigService.isConfigInitiated){
          this.searchConfigService.initSearchConfigs();
          this.searchConfigService.isConfigInitiated = true;
        }
        if(newCP.repPlans[0].productid_Formatted && !this.searchConfigService.teamCallPlansSearchIndexesConfig.find(config=> config.categoryName == 'Products').values.some(o=> o==newCP.repPlans[0].productid_Formatted ))
            this.searchConfigService.teamCallPlansSearchIndexesConfig.find(config=>config.categoryName=='Products').values.push(newCP.repPlans[0].productid_Formatted );

        if(newCP.repPlans[0].segmentid_Formatted && !this.searchConfigService.teamCallPlansSearchIndexesConfig.find(config=> config.categoryName == 'Segments').values.some(o=> o == newCP.repPlans[0].segmentid_Formatted)){
          this.searchConfigService.teamCallPlansSearchIndexesConfig.find(config=> config.categoryName == 'Segments').values.push(newCP.repPlans[0].segmentid_Formatted);
        }
        newCP.repPlans.forEach(repCallPlan =>{
            if(repCallPlan.customerName && !this.searchConfigService.teamCallPlansSearchIndexesConfig.find(config=> config.categoryName == 'Customers').values.some(o=> o == repCallPlan.customerName))
                this.searchConfigService.teamCallPlansSearchIndexesConfig.find(config=> config.categoryName == 'Customers').values.push(repCallPlan.customerName);

            if(repCallPlan.positionName && !this.searchConfigService.teamCallPlansSearchIndexesConfig.find(config=> config.categoryName == 'Positions').values.some(o=> o == repCallPlan.positionName))
            this.searchConfigService.teamCallPlansSearchIndexesConfig.find(config=> config.categoryName == 'Positions').values.push(repCallPlan.positionName);

            repCallPlan.accounts = this.contactService.getContactByID(repCallPlan.contactId)?.accountRelationships || []
            repCallPlan.accounts.forEach(account =>{
                if(account.accountName.length > 0 && !this.searchConfigService.teamCallPlansSearchIndexesConfig.find(config=> config.categoryName == 'Accounts').values.some(o=> o == account.accountName))
                    this.searchConfigService.teamCallPlansSearchIndexesConfig.find(config=> config.categoryName == 'Accounts').values.push(account.accountName);
            })
            repCallPlan.users = this.authenticationService.user.childUsers.filter(u=> u.positionIDs.indexOf(repCallPlan.positionId)>-1)
            repCallPlan.users.forEach(user =>{
                if(user.fullName.length > 0 && !this.searchConfigService.teamCallPlansSearchIndexesConfig.find(config=> config.categoryName == 'Users').values.some(o=> o == user.fullName))
                    this.searchConfigService.teamCallPlansSearchIndexesConfig.find(config=> config.categoryName == 'Users').values.push(user.fullName);
            })
        })
        // newCallPlans.accountsName.forEach(accountName =>{
        //     if(newCallPlans.accountsName && newCallPlans.accountsName.length > 0 && !this.searchConfigService.teamCallPlansSearchIndexesConfig.find(config=> config.categoryName == 'Accounts').values.some(o=> o == accountName))
        //         this.searchConfigService.teamCallPlansSearchIndexesConfig.find(config=> config.categoryName == 'Accounts').values.push(accountName);
        // })
    }

    // public clear() {
    //     this.callPlans = [];
    //     this.teamCallPlans = [];
    // }
  
  aggregateRepCallPlans(callPlans: any[]) {
    let a = [];
    if (!_.isEmpty(callPlans)) {
      callPlans.map((c) => {
        let plan = a.find(o => o.contactId == c.contactId);
        c.customerName = c.contactFirstName + " " + c.contactLastName
        c.initials = c.contactFirstName?.charAt(0).toUpperCase() + c.contactLastName?.charAt(0).toUpperCase();
        if (plan) {
          plan.repPlans.push(c);
          plan.actualCalls += this.goalOrCompletedValue(c.indskr_hocalls, c.indskr_actualcalls);
          plan.totalGoalCalls += c.indskr_hocalls ? c.indskr_hocalls : 0;
          plan.actualEmails += this.goalOrCompletedValue(c.indskr_hoemails, c.indskr_actualemails);
          plan.totalGoalEmails += c.indskr_hoemails ? c.indskr_hoemails : 0;
          plan.actualF2FMeetings += this.goalOrCompletedValue(c.indskr_f2fgoals, c.totalf2fmeetingscompleted);
          plan.totalGoalF2FMeetings += c.indskr_f2fgoals ? c.indskr_f2fgoals : 0;
          plan.actualRemoteMeetings += this.goalOrCompletedValue(c.indskr_remotemeetinggoals, c.totalremotemeetingscompleted);
          plan.totalGoalRemoteMeetings += c.indskr_remotemeetinggoals ? c.indskr_remotemeetinggoals : 0;
          plan.actualPhoneCalls += this.goalOrCompletedValue(c.indskr_phonecallgoals, c.totalphonecallscompleted);
          plan.totalGoalPhoneCalls += c.indskr_phonecallgoals ? c.indskr_phonecallgoals : 0;
          plan.allRepCallPlansApproved = plan.allRepCallPlansApproved == true && c.statuscode == 2 ? true : false;
          plan.completedGoals = plan.actualCalls + plan.actualEmails + plan.actualF2FMeetings + plan.actualRemoteMeetings + plan.actualPhoneCalls;
          plan.definedGoals = plan.totalGoalCalls + plan.totalGoalEmails + plan.totalGoalF2FMeetings + plan.totalGoalRemoteMeetings + plan.totalGoalPhoneCalls;
          plan.percentage = plan.completedGoals && plan.completedGoals > 0 ? _.toNumber(((plan.completedGoals / plan.definedGoals) * 100).toFixed(0)) : 0;
        } else {
          plan = {
            'cycleplanid': c.cycleplanid,
            'contactId': c.contactId,
            'customerName': c.customerName,
            'initials': c.initials,
            'cycleplanname': c.cycleplanname,
            'repPlans': [c],
            'indskr_enddate': c.indskr_enddate,
            'indskr_startdate': c.indskr_startdate,
            'actualCalls': this.goalOrCompletedValue(c.indskr_hocalls, c.indskr_actualcalls),
            'totalGoalCalls': c.indskr_hocalls || 0,
            'actualEmails': this.goalOrCompletedValue(c.indskr_hoemails, c.indskr_actualemails),
            'totalGoalEmails': c.indskr_hoemails || 0,
            'actualF2FMeetings': this.goalOrCompletedValue(c.indskr_f2fgoals, c.totalf2fmeetingscompleted),
            'totalGoalF2FMeetings': c.indskr_f2fgoals || 0,
            'actualRemoteMeetings': this.goalOrCompletedValue(c.indskr_remotemeetinggoals, c.totalremotemeetingscompleted),
            'totalGoalRemoteMeetings': c.indskr_remotemeetinggoals || 0,
            'actualPhoneCalls': this.goalOrCompletedValue(c.indskr_phonecallgoals, c.totalphonecallscompleted),
            'totalGoalPhoneCalls': c.indskr_phonecallgoals || 0,
            'allRepCallPlansApproved': c.statuscode == 2 ? true : false,
            'productName': c.productid_Formatted || '',
            'suggestionApprovalneeded': c.cycleplansuggestionapprovalneeded
          };
          plan['completedGoals'] = plan['actualCalls'] + plan['actualEmails'] + plan['actualF2FMeetings'] + plan['actualRemoteMeetings'] + plan['actualPhoneCalls'];
          plan['definedGoals'] = plan['totalGoalCalls'] + plan['totalGoalEmails'] + plan['totalGoalF2FMeetings'] + plan['totalGoalRemoteMeetings'] + plan['totalGoalPhoneCalls'];
          plan.percentage = plan.completedGoals && plan.completedGoals > 0 ? _.toNumber(((plan.completedGoals / plan.definedGoals) * 100).toFixed(0)) : 0;
          a.push(plan);
        }
      });
    }
    return a;
  }

  private goalOrCompletedValue(goal, completed) {
    let value = 0;
    if (completed) {
      value = completed > goal ? goal : completed
    }
    return value;
  }

  get isSendDisabled(): boolean {
    const plan = this.selectedRepCallPlan;
    return (this.hasValue(plan.totalGoalCalls) && !this.hasValue(plan.indskr_suggestedcalls)) || (this.hasValue(plan.totalGoalEmails) && !this.hasValue(plan.indskr_suggestedemails))
      || (this.hasValue(plan.totalGoalF2FMeetings) && !this.hasValue(plan.indskr_suggestedf2fmeetings)) || (this.hasValue(plan.totalGoalRemoteMeetings) && !this.hasValue(plan.indskr_suggestedremotemeetings))
      || (this.hasValue(plan.totalGoalPhoneCalls) && !this.hasValue(plan.indskr_suggestedphonecallmeetings))
  }

  private hasValue(number: number): boolean {
    if (number == undefined || number <= 0) return false;
    return _.gt(number, 0);
  }
  
}
