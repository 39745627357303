import {Utility} from './../../utility/util';
import {Injectable} from "@angular/core";
import {CoachingReportDataService} from "../../data-services/coaching/coaching.report.data.service";
import {BehaviorSubject, forkJoin, Observable} from "rxjs";
import {CoachingRating, CoachingReportStatus, CoachingTrackStatus, Report} from "../../classes/coaching/report.class";
import {AssessmentMatrix} from "../../classes/coaching/assessment-matrix.class";
import {AssessmentMeasure} from "../../classes/coaching/assessment.measure.class";
import {AssessmentCategory} from "../../classes/coaching/assessment-category.class";
import {DeviceService} from "../device/device.service";
import {LoadingController} from "@ionic/angular";
import {NotificationService, ToastStyle} from "../notification/notification.service";
import {ActivityDataService} from "../../data-services/activity/activity.service";

import {CoachingActivity} from "../../classes/coaching/activity.class";
import * as moment from "moment";
import {ActivityService} from "../activity/activity.service";
import {AppointmentActivity} from "../../classes/activity/appointment.activity.class";
import {Activity, ActivityType} from "../../classes/activity/activity.class";
import {NavigationService} from "../navigation/navigation.service";
import {DiskService, OFFLINE_DATA_COUNT_ENTITY_NAME} from "../disk/disk.service";
import {DB_ALLDOCS_QUERY_OPTIONS, DB_KEY_PREFIXES, DB_SYNC_STATE_KEYS} from "../../config/pouch-db.config";
import {AssessmentRating} from "../../classes/coaching/assessment-rating.class";
import {AuthenticationService} from "../authentication.service";
import {FeatureActionsMap} from "../../classes/authentication/user.class";
import {User} from "../../classes/account/child.user.class";
import {EventsService} from "../events/events.service";
import {DeltaService, EntityNames, EntitySyncInfo} from "../../data-services/delta/delta.service";
import {Endpoints} from "../../../config/endpoints.config";
import {CoachingScale} from "../../classes/coaching/coaching.scale";
import {UIService} from "../ui/ui.service";
import {TranslateService} from "@ngx-translate/core";
import {CoachingTemplate} from "../../classes/coaching/coaching-template.class";
import {CoachingTemplatesUser} from "../../classes/coaching/coaching-template-user.class";
import _ from "lodash";
import {AlertService} from "../alert/alert.service";
import {differenceInDays, differenceInMonths, differenceInWeeks, differenceInYears} from 'date-fns';
import {SelectedSuggestionPillDataModel} from '../../models/search-config-data-model';
import {SearchConfigService} from '../search/search-config.service';
import {map} from 'rxjs/operators';
import {PhoneActivity} from '@omni/classes/activity/phone.activity.class';
import { DatePipe } from '@angular/common';
import { DateTimeFormatsService } from '../date-time-formats/date-time-formats.service';

export enum COACHING_VIEW_TYPE {
  FROM_PHONECALL,
  FROM_MEETING,
  FROM_TOOL
}

@Injectable({
  providedIn: 'root'
})
export class CoachingReportService {

  public assessmentMatrix = new BehaviorSubject<AssessmentMatrix>(null);
  public coachingScales: Map<String, AssessmentRating[]> = new Map();
  public myReportSubject = new BehaviorSubject<Report[]>([]);
  private teamReportSubject = new BehaviorSubject<Report[]>([]);
  public reportByTabSubject = new BehaviorSubject<Report[]>([]);
  public selectedReport = new BehaviorSubject<Report>(null);
  private activityCoachingDataSource = new BehaviorSubject<Report[]>([]);
  public activityCoachingObserver = this.activityCoachingDataSource.asObservable();
  public  selectedCoachingPlanTab : Report;

  public reportByTabSubjectObs = this.reportByTabSubject.asObservable();

  public activeTab: string;
  // public activeFilter: number;
  public selectedCoachingFor: string = "";
  public display = false;
  public activityClick: boolean = false;
  public coachingReports: Report[] = [];
  public users: User[] = [];
  public coachingTemplatesUserMap: CoachingTemplatesUser[] = [];
  public coachingTemplates: CoachingTemplate[] = [];
  public userMappedCoachingTemplates: CoachingTemplate[] = [];
  public offlineCoachingReportIds = new Map(); // Hashmap to keep track offline coaching report data in local db

  public recentSearches: SelectedSuggestionPillDataModel[] = []; //Advanced search
  public teamRecentSearches: SelectedSuggestionPillDataModel[] = []; //Advanced search
  public coachingViewtype = COACHING_VIEW_TYPE.FROM_TOOL;
  public templateWeight: number = 0;
  public givenRatingsWeight: number = 0;

  constructor(
    private coachingReportDataService: CoachingReportDataService,
    private alertService: AlertService,
    private deviceService: DeviceService,
    private loadingCtrl: LoadingController,
    private notificationService: NotificationService,
    private activityDataService: ActivityDataService,
    private activityService: ActivityService,
    private navService: NavigationService,
    private disk: DiskService,
    private authService: AuthenticationService,
    private events: EventsService,
    private deltaService: DeltaService,
    private uiService: UIService,
    public translate: TranslateService,
    private searchConfigService: SearchConfigService,
    private datePipe: DatePipe,
    private dateTimeFormatsService: DateTimeFormatsService
  ) {
    this.events
      .observe("sync:completed")
      .subscribe(() => {
        // this.loadAndMapCoachingReportFromDB();
      });
  }

  addToOfflineCoachingReportsIds(id: String) {
    this.offlineCoachingReportIds.set(id, true);
  }

  deleteFromOfflineCoachingReportsIds(id: String) {
    this.offlineCoachingReportIds.delete(id);
  }

  hasOfflineCoachingReportsData(id: String) {
    return this.offlineCoachingReportIds.has(id);
  }

  public async loadAndMapCoachingReportFromDB() {
    if (this.authService.hasFeatureAction(FeatureActionsMap.COACHING_TOOL)) {
      this.disk.batchFetch(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_MY_COACHING).then((data: Report[]) => {
        data.forEach(coaching => {
          this.setMeasureCoachingScales(coaching);
        });
        this.myReportSubject.next(data);
        data.forEach(myCoaching => this.mapMyCoachingFieldsToSearchIndex(myCoaching));
        console.log(`My coachings from disk : ${data ? data.length : 0}`);
      });
      if (this.authService.hasFeatureAction(FeatureActionsMap.COACHING_TEAM_VIEW)) {
        let teamCoachingReports: Report[] = [];
        await this.disk.batchFetch(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_TEAM_COACHING).then((data: Report[]) => {
          data.forEach(coaching => {
            this.setMeasureCoachingScales(coaching);
          });
          teamCoachingReports = data;
          data.forEach(teamCoaching => this.mapTeamCoachingFieldsToSearchIndex(teamCoaching));
        });
        teamCoachingReports = await this.mapOfflineTeamReports(teamCoachingReports);
        this.teamReportSubject.next(teamCoachingReports);
        console.log(`Team coachings from disk : ${this.teamReportSubject.value.length}`)
        //Fetch Coaching templates from db
        this.disk.batchFetch(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_COACHING_TEMPLATES).then((data: CoachingTemplate[]) => {
          this.coachingTemplates = data;
          console.log(`Coaching Templates from disk : ${data ? data.length : 0}`)
        });
      }

      // For offline data tracking...
      this.checkOfflineDataExists();
    }
    this.fiterReports();
  }

  private async mapOfflineTeamReports(teamCoachingReports: Report[]): Promise<Report[]> {
    const offlineCoachingReports = await this.disk.loadOfflineCoachingReports();
    if (offlineCoachingReports && offlineCoachingReports['coachingReports'] && Array.isArray(offlineCoachingReports['coachingReports']) && offlineCoachingReports['coachingReports'].length > 0) {
      let offlineReports = offlineCoachingReports['coachingReports'];
      let offlineTeamReports: Report[] = offlineReports.filter(report => !report.deleted && report.statecode === 0).map(report => report)
      offlineTeamReports.forEach(offlineTeamReport => {
        let idToBeUpdated = offlineTeamReport.offlineCoachingReportId ? offlineTeamReport.offlineCoachingReportId : offlineTeamReport.indskr_coachingreportid;
        this.setMeasureCoachingScales(offlineTeamReport);
        let index = this.teamReportSubject.value.findIndex((r: Report) => {
          return r.offlineCoachingReportId === idToBeUpdated || r.indskr_coachingreportid === idToBeUpdated;
        });
        if (index === -1) {
          teamCoachingReports = teamCoachingReports.concat(offlineTeamReport);
        } else {
          teamCoachingReports[index] = offlineTeamReport;
        }
      });
    }
    return teamCoachingReports;
  }

  private setMeasureCoachingScales(coaching: Report) {
    if (coaching.categories && coaching.categories.length > 0) {
      coaching.categories.forEach(category => {
        category.measures.forEach(measure => {
          measure.ratings = this.coachingScales.get(measure.ratingscale_id)
        })
      });
    }
  }

  public async loadOfflineCoachingReportsMasterData() {
    if (this.authService.hasFeatureAction(FeatureActionsMap.COACHING_TOOL)) {
      await this.getCoachingScales(true);
      if (this.authService.hasFeatureAction(FeatureActionsMap.COACHING_TEAM_VIEW)) {
        await this.getCoachingForUsers(true);
        await this.getCoachingTemplatesUsersMap(true);
      }
    }
    await this.loadAndMapCoachingReportFromDB();
  }

  public async getCoachingReportsMasterData(loadFromDbOnly = false) {
    if (loadFromDbOnly) {
      await this.loadOfflineCoachingReportsMasterData();
    } else {
      if (this.authService.hasFeatureAction(FeatureActionsMap.COACHING_TOOL)) {
        await this.getCoachingScales();
        await this.getMyCoachingReports();
        await this.getMyRecentSearches();
        if (this.authService.hasFeatureAction(FeatureActionsMap.COACHING_TEAM_VIEW)) {
          await this.getCoachingForUsers();
          await this.getTeamCoachingReports();
          await this.getCoachingTemplates();
          await this.getTeamRecentSearches();
          await this.getCoachingTemplatesUsersMap();
        }
        this.loadAndMapCoachingReportFromDB();
      }

    }
  }

  private async getCoachingTemplatesUsersMap(loadFromDbOnly = false) {
    let offlineFallback: boolean = this.deviceService.isOffline || loadFromDbOnly;
    this.coachingTemplatesUserMap = [];
    if (offlineFallback) {
      await this.disk.retrieve(DB_KEY_PREFIXES.COACHING_TEMPLATE_USER_MAP).then(response => {
        if (response && response['raw']) {
          let map = response['raw'];
          if (map && map.length > 0) {
            map.map(rawJSON => {
              this.coachingTemplatesUserMap.push(new CoachingTemplatesUser(rawJSON));
            });
          }
        }
      }).catch(dbError => {
        console.log("Error retreiving coaching templates-users data from offline db!", dbError);
      });
    } else {
      try {
        const data: CoachingTemplatesUser[] = await this.coachingReportDataService.getCoachingTemplatesUsersMap().toPromise();
        if (Array.isArray(data)) {
          await this.disk.updateOrInsert(DB_KEY_PREFIXES.COACHING_TEMPLATE_USER_MAP, doc => ({ raw: data }))
            .catch((err) => console.error("getCoachingTemplatesUsersMap: Error saving coaching templates-users map data to offline db!", err));
          data.map(rawJSON => {
            this.coachingTemplatesUserMap.push(new CoachingTemplatesUser(rawJSON));
          });
        }
      } catch (error) {
        console.log("Error retreiving coaching templates-users data ", error);
      }
    }
  }

  private async getMyCoachingReports() {
    const syncState = await this.disk.getSyncState(DB_SYNC_STATE_KEYS.SYNC_MY_COACHING);
    const isInitialSync = !syncState || !syncState.lastUpdatedTime;
    const currentStamp = (new Date()).getTime()
    const newLastUpdatedTime = Utility.changeLocalDateToUTCDateWith0Time(currentStamp).getTime();
    const coachingReportSyncInfo: EntitySyncInfo = {
      entityName: EntityNames.coachingReport,
      totalFailed: 0,
      totalSynced: 0,
      errors: [],
      syncStatus: true
    };
    await this.coachingReportDataService.getMyCoachingReports(isInitialSync, syncState.lastUpdatedTime).then(
      async (data: Report[]) => {
        console.log("Saving my coachings to disk...");
        if(!isInitialSync) {
          data.forEach(o=>{
            if(o.statuscode == 1) {
                o.track_action = CoachingTrackStatus.Removed;
            }
          })
        }
        await this.saveToDisk(isInitialSync, DB_KEY_PREFIXES.MY_COACHING, data);
        syncState.lastUpdatedTime = newLastUpdatedTime;
        await this.disk.updateSyncState(syncState);
        if (Array.isArray(data)) {
          coachingReportSyncInfo.totalSynced = data.length;
        }
        this.deltaService.addEntitySyncInfo(coachingReportSyncInfo);
        data.forEach(myCoaching => this.mapMyCoachingFieldsToSearchIndex(myCoaching));
        console.log(`Sync status : ${JSON.stringify(syncState)} initial sync : ${isInitialSync}`);
      }).catch(err => {
        console.error("Error occurred while fetching my coachings...")
        this.deltaService.addSyncErrorToEntitySyncInfo(coachingReportSyncInfo, this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.coachingReports.GET_MY_COACHING_REPORTS, err);
        this.deltaService.addEntitySyncInfo(coachingReportSyncInfo);
      });
  }

  private async getTeamCoachingReports() {
    const syncState = await this.disk.getSyncState(DB_SYNC_STATE_KEYS.SYNC_TEAM_COACHING);
    const isInitialSync = !syncState || !syncState.lastUpdatedTime;
    const currentStamp = (new Date()).getTime()
    const newLastUpdatedTime = Utility.changeLocalDateToUTCDateWith0Time(currentStamp).getTime();// this.disk.getCurrentGMT0Timestamp();
    const teamCoachingReportSyncInfo: EntitySyncInfo = {
      entityName: EntityNames.teamCoachingReport,
      totalFailed: 0,
      totalSynced: 0,
      errors: [],
      syncStatus: true
    };

    await this.coachingReportDataService.getTeamCoachingReports(isInitialSync, newLastUpdatedTime).then(
      async (data: Report[]) => {
        console.log("Saving team coachings to disk...");
        await this.saveToDisk(isInitialSync, DB_KEY_PREFIXES.TEAM_COACHING, data);
        syncState.lastUpdatedTime = newLastUpdatedTime;
        await this.disk.updateSyncState(syncState);
        if (Array.isArray(data)) {
          teamCoachingReportSyncInfo.totalSynced = data.length;
        }
        this.deltaService.addEntitySyncInfo(teamCoachingReportSyncInfo);
        // For Advanced Search
        data.forEach(teamCoaching => this.mapTeamCoachingFieldsToSearchIndex(teamCoaching));
        console.log(`Sync status : ${JSON.stringify(syncState)} initial sync : ${isInitialSync}`);
      }).catch(err => {
        console.error("Error occurred while fetching team coachings...")
        this.deltaService.addSyncErrorToEntitySyncInfo(teamCoachingReportSyncInfo, this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.coachingReports.GET_TEAM_COACHING_REPORTS, err);
        this.deltaService.addEntitySyncInfo(teamCoachingReportSyncInfo);
      });
  }

  private async getCoachingTemplates() {
    const syncState = await this.disk.getSyncState(DB_SYNC_STATE_KEYS.SYNC_COACHING_TEMPLATES);
    const isInitialSync = !syncState || !syncState.lastUpdatedTime || this.authService.shouldContentFullSync;
    const newLastUpdatedTime = new Date().getTime();
    const coachingTemplatesSyncInfo: EntitySyncInfo = {
      entityName: EntityNames.coachingTemplate,
      totalFailed: 0,
      totalSynced: 0,
      errors: [],
      syncStatus: true
    };
    if (isInitialSync) {
      await this.disk.deleteAllFromDbUsingAlldocsQuery(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_COACHING_TEMPLATES);
    }
    await this.coachingReportDataService.getCoachingTemplates(isInitialSync, syncState.lastUpdatedTime).then(
      async (data: CoachingTemplate[]) => {
        console.log("Saving coaching templates to disk...");
        await this.saveCoachingTemplatesToDisk(isInitialSync, data);
        syncState.lastUpdatedTime = newLastUpdatedTime;
        await this.disk.updateSyncState(syncState);
        if (Array.isArray(data)) {
          coachingTemplatesSyncInfo.totalSynced = data.length;
        }
        this.deltaService.addEntitySyncInfo(coachingTemplatesSyncInfo);
        console.log(`Sync status : ${JSON.stringify(syncState)} initial sync : ${isInitialSync}`);
      }).catch(err => {
        console.error("Error occurred while fetching coaching templates...", err)
        this.deltaService.addSyncErrorToEntitySyncInfo(coachingTemplatesSyncInfo, this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.coachingReports.GET_COACHING_TEMPLATES, err);
        this.deltaService.addEntitySyncInfo(coachingTemplatesSyncInfo);
      });
  }

  private async saveCoachingTemplatesToDisk(isInitialSync: boolean, data: CoachingTemplate[]) {
    if (data && data.length > 0) {
      if (!isInitialSync) {
        data.forEach((template: CoachingTemplate) => {
          template._id = DB_KEY_PREFIXES.COACHING_TEMPLATES + template.indskr_coachingtemplateid;
          if (!isInitialSync) {
            if (template.track_action == 548910001) {
              this.disk.retrieve(template._id.toString()).then(async (exist) => {
                if (exist) await this.disk.remove(template._id.toString());
              });
            } else {
              this.disk.retrieve(template._id.toString()).then(async (exist) => {
                if (!exist) {
                  await this.disk.updateOrInsert(template._id.toString(), doc => template)
                    .catch(error => console.error('CoachingService: saveCoachingTemplatesToDisk: ', error));
                } else {
                  exist = {
                    ...exist,
                    indskr_coachingtemplateid: template.indskr_coachingtemplateid,
                    categories: template.categories
                  }
                  await this.disk.updateOrInsert(template._id.toString(), doc => exist)
                    .catch(error => console.error('CoachingService: saveCoachingTemplatesToDisk: ', error));
                }
              }, err => {
                console.log("CoachingService: saveCoachingTemplatesToDisk: Error occurred" + err);
              });
            }
          }
        });
      }
      else {
        data.forEach((template: CoachingTemplate) => {
          template._id = DB_KEY_PREFIXES.COACHING_TEMPLATES + template.indskr_coachingtemplateid;
        });
        this.disk.bulk(data);
      }
    }
    console.log(`${data.length} coaching template(s) has been written to disk`)
  }

  private async saveToDisk(isInitialSync, dbKey, data: Report[]) {
    if (data && data.length > 0) {
      for (let index = 0; index < data.length; index++) {
        let coaching = data[index];
        coaching._id = dbKey + coaching.indskr_coachingreportid;
        if (coaching.statuscode == 548910000) {
          await this.disk.retrieve(coaching._id.toString()).then(async (exist) => {
            if (exist) {
              coaching = exist;
              coaching.statusName = "Acknowledged";
              coaching.statuscode = 548910000;
              coaching.statecode = 1;
            }
          });
        }
        if (coaching.track_action != CoachingTrackStatus.Removed && coaching.indskr_periodstartdate && coaching.indskr_periodenddate) {
          coaching.indskr_periodstartdate = parseFloat(coaching.indskr_periodstartdate.toString());
          coaching.indskr_periodenddate = parseFloat(coaching.indskr_periodenddate.toString());
          coaching.createdon = parseFloat(coaching.createdon.toString());
          // coaching.indskr_shareddate = coaching.indskr_shareddate ? new Date(coaching.indskr_shareddate).toString() : coaching.indskr_shareddate;
          // coaching.indskr_accepteddate = coaching.indskr_accepteddate ? new Date(coaching.indskr_accepteddate).toString() : coaching.indskr_accepteddate;
          // coaching.indskr_dateforreview = coaching.indskr_dateforreview ? new Date(coaching.indskr_dateforreview).toString() : coaching.indskr_dateforreview;
          // coaching.indskr_dateacknowledged = coaching.indskr_dateacknowledged ? new Date(coaching.indskr_dateacknowledged).toString() : coaching.indskr_dateacknowledged;
          coaching.createdon = parseFloat(coaching.createdon.toString());
          if (coaching.categories.length > 0) this.applyMinAndMaxRatings(coaching);
          else {
            coaching.min_rating = new CoachingRating();
            coaching.max_rating = new CoachingRating();
          }
          if (this.selectedReport.value && (this.selectedReport.value.offlineCoachingReportId === coaching.offlineCoachingReportId ||
            (this.selectedReport.value.indskr_coachingreportid && this.selectedReport.value.indskr_coachingreportid.length > 0
              && this.selectedReport.value.indskr_coachingreportid === coaching.indskr_coachingreportid))) {
            this.setMeasureCoachingScales(coaching);
            this.selectedReport.next(coaching);
          }
          if (!isInitialSync) {
            await this.disk.retrieve(coaching._id.toString()).then(async (exist) => {
              if (!exist) {
                await this.disk.updateOrInsert(coaching._id.toString(), doc => coaching);
              } else {
                if (dbKey === DB_KEY_PREFIXES.TEAM_COACHING) {
                  exist.indskr_coachingfor_value = coaching.indskr_coachingfor_value;
                  exist.created_for = coaching.created_for;
                  exist.created_for_firstname = coaching.created_for_firstname;
                  exist.indskr_name = coaching.indskr_name;
                  await this.updateSelectedCoachingInDB(exist, coaching);
                } else {
                  await this.disk.updateOrInsert(coaching._id.toString(), doc => coaching);
                }
              }
            }, err => {
              console.log("Error occurred" + err);
            });
          }
        } else {
          await this.disk.retrieve(coaching._id.toString()).then(async (exist) => {
            if (exist)
              await this.disk.remove(coaching._id.toString());
          });
        }
      }
      if (isInitialSync) {
        await this.disk.bulk(data);
      }
      console.log(`${data.length} coachings has been written to disk`)
    }
  }

  // private async getAssessmentMatrix() {
  //   const assessmentMatrixSyncInfo: EntitySyncInfo = {
  //     entityName: EntityNames.assessmentMatrix,
  //     totalFailed: 0,
  //     totalSynced: 0,
  //     errors: [],
  //     syncStatus: true
  //   };

  //   try {
  //     const data: AssessmentMatrix = await this.coachingReportDataService.getAssessmentMatrixMasterData().toPromise();
  //     this.assessmentMatrix.next(data);
  //   } catch (error) {
  //     this.deltaService.addSyncErrorToEntitySyncInfo(assessmentMatrixSyncInfo, this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.coachingReports.GET_ASSESSMENT_MATRIX, error);
  //   }

  //   this.deltaService.addEntitySyncInfo(assessmentMatrixSyncInfo);
  // }

  private async getCoachingScales(loadFromDbOnly = false) {
    let offlineFallback: boolean = this.deviceService.isOffline || loadFromDbOnly;
    const coachingScaleSyncInfo: EntitySyncInfo = {
      entityName: EntityNames.coachingScales,
      totalFailed: 0,
      totalSynced: 0,
      errors: [],
      syncStatus: true
    };
    if (!offlineFallback) {
      try {
        let scales: CoachingScale[] = await this.coachingReportDataService.getCoachingScales().toPromise();
        if (Array.isArray(scales)) {
          coachingScaleSyncInfo.totalSynced = scales.length;
        }
        await this.disk.updateOrInsert(DB_KEY_PREFIXES.COACHING_SCALES, doc => ({ raw: scales }))
          .catch(error => console.error('getCoachingScales: Error saving coaching scales data to offline db!', error));
        if (scales && scales.length > 0) {
          scales.forEach(scale => {
            this.coachingScales.set(scale.ratingscale_id, scale.ratings);
          });
        }
      } catch (error) {
        offlineFallback = true;
        this.deltaService.addSyncErrorToEntitySyncInfo(coachingScaleSyncInfo, this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.coachingReports.GET_COACHING_SCALES, error);
      }
      this.deltaService.addEntitySyncInfo(coachingScaleSyncInfo);
    }
    if (offlineFallback) {
      await this.disk.retrieve(DB_KEY_PREFIXES.COACHING_SCALES).then(response => {
        if (response && response['raw']) {
          let scales = response['raw'];
          if (scales && scales.length > 0) {
            scales.forEach(scale => {
              this.coachingScales.set(scale.ratingscale_id, scale.ratings);
            });
          }
        }
      }).catch(dbError => {
        console.log("Error retreiving coaching scales data from offline db!", dbError);
      });
    }
  }

  private async getCoachingForUsers(loadFromDbOnly = false) {
    let offlineFallback: boolean = this.deviceService.isOffline || loadFromDbOnly;
    this.users = [];
    const userCoachingSyncInfo: EntitySyncInfo = {
      entityName: EntityNames.assessmentMatrix,
      totalFailed: 0,
      totalSynced: 0,
      errors: [],
      syncStatus: true
    };
    if (!offlineFallback) {
      try {
        const data: User[] = await this.coachingReportDataService.getCoachingForUsers().toPromise();
        if (Array.isArray(data)) {
          userCoachingSyncInfo.totalSynced = data.length;
          await this.disk.updateOrInsert(DB_KEY_PREFIXES.COACHING_USERS, doc => ({ raw: data }))
            .catch((err) => console.error("getCoachingForUsers: Error saving coaching users data to offline db!", err));

          data.map(rawJSON => {
            this.users.push(new User(rawJSON));
          });
        }
      } catch (error) {
        offlineFallback = true;
        this.deltaService.addSyncErrorToEntitySyncInfo(userCoachingSyncInfo, this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.coachingReports.GET_COACHING_FOR_USERS, error);
      }
      this.deltaService.addEntitySyncInfo(userCoachingSyncInfo);
    }
    if (offlineFallback) {
      await this.disk.retrieve(DB_KEY_PREFIXES.COACHING_USERS).then(response => {
        if (response && response['raw']) {
          let users = response['raw'];
          if (users && users.length > 0) {
            users.map(rawJSON => {
              this.users.push(new User(rawJSON));
            });
          }
        }
      }).catch(dbError => {
        console.log("Error retreiving coaching users data from offline db!", dbError);
      });
    }
  }

  public getTeamCoachingActivities(): Observable<CoachingActivity[]> {
    const report: Report = this.selectedReport.value;
    const startTime = moment(report.indskr_periodstartdate).startOf('day').toDate().getTime();
    const endTime = moment(report.indskr_periodenddate).endOf('day').toDate().getTime();
    const source = [
      this.coachingReportDataService.getTeamCoachingAppointments(report.indskr_coachingfor_value, startTime, endTime)
    ];
    if (this.authService.hasFeatureAction(FeatureActionsMap.PHONECALL_ACTIVITY)) {
      source.push(this.coachingReportDataService.getTeamCoachingPhoneCalls(report.indskr_coachingfor_value, startTime, endTime))
    }
    return forkJoin(source).pipe(
      map(([appointments, phonecalls]) => {
        const result = [];
        if (!_.isEmpty(appointments)) {
          appointments.map(ap => ap.activityType = ActivityType.Appointment);
          result.push(...appointments);
        }
        if (!_.isEmpty(phonecalls)) {
          phonecalls.map(ph => ph.activityType = ActivityType.PhoneCall);
          result.push(...phonecalls);
        }
        return result;
      })
    );
  }

  public fiterReports() {
    let reports: Report[] = [];
    if (this.activeTab === 'myCoaching') {
      reports = this.myReportSubject.value;
    } else {
      reports = this.teamReportSubject.value;
    }
    this.reportByTabSubject.next(reports);
    // if (this.activeFilter == 0) {
    //   this.reportByTabSubject.next(reports);
    // } else {
    //   if (this.activeFilter === 1) {
    //     //On Filter: Open - Open reports and offline submitted FOR REVIEW
    //     this.reportByTabSubject.next(reports.filter((report: Report) => (report.statuscode == this.activeFilter || report.statuscode === 2)));
    //   }
    //   else if (this.activeFilter === 548910001) {
    //     //On Filter: Ack - Ack reports and offline submitted Ackn
    //     this.reportByTabSubject.next(reports.filter((report: Report) => (report.statuscode == this.activeFilter || report.statuscode === 3)));
    //   } else {
    //     this.reportByTabSubject.next(reports.filter((report: Report) => report.statuscode == this.activeFilter));
    //   }
    // }
  }

  public measuresByCategory(category_id: String): String[] {
    let foundCategory: AssessmentCategory = this.selectedReport.value.categories.find((category) => category.category_id == category_id);
    if (foundCategory) {
      return foundCategory.measures.map(measure => measure.rating_id);
    }
    return null;
  }

  public acceptReport() {
    let report: Report = this.selectedReport.value;
    if (report.statuscode !== 548910002 || this.activeTab !== 'myCoaching') return;

    this.alertService.showAlert({
        header: this.translate.instant('COACHING_ACCEPT'),
        message: this.translate.instant('COACHING_R_U_SURE_WANT_ACCEPT')
      },
      this.translate.instant('CONFIRM')
    ).then(async res => {
      if (res.role == "ok") {
        let payload = {
          statuscode: 548910003,
          "indskr_accepteddate": new Date().getTime()
        };
        if (this.isOffline(report)) {
          payload['indskr_coachingreportid'] = report.indskr_coachingreportid;
          if (report.offlineCoachingReportId)
            payload['offlineCoachingReportId'] = report.offlineCoachingReportId
          this.acceptReportOffline(payload, report);
        } else {
          const loader = await this.loadingCtrl.create();
          loader.present();
          this.coachingReportDataService.updateReport(report.indskr_coachingreportid, payload)
            .then(
              res => {
                // report.statecode = 1;
                report.statuscode = 548910003;
                this.selectedReport.next(report);
                let reports = this.myReportSubject.value;
                let currentReport = reports.find(data => data.indskr_coachingreportid === report.indskr_coachingreportid);
                currentReport.statuscode = 548910003;
                currentReport.statusName = "Accepted";
                let acceptedDate = new Date(payload.indskr_accepteddate);
                currentReport.indskr_accepteddate = acceptedDate.toString();
                this.myReportSubject.next(reports);
                this.events.publish('coaching-updated', currentReport);
                this.disk.retrieve(report._id.toString()).
                then((existingCoaching: Report) => {
                  existingCoaching.statuscode = report.statuscode;
                  existingCoaching.statusName = "Accepted";
                  existingCoaching.indskr_accepteddate = report.indskr_accepteddate;
                  this.disk.updateOrInsert(report._id.toString(), doc => existingCoaching);
                });
                this.fiterReports();
                loader.dismiss();
              },
              msg => {
                this.checkCoachingError(msg, report);
                loader.dismiss();
              }
            );
        }
      }
    });
  }

  public acknowledgeReport() {
    let report: Report = this.selectedReport.value;
    if (report.statuscode !== 548910001 || this.activeTab !== 'myCoaching') return;

    this.alertService.showAlert({
      header: this.translate.instant('COACHING_ACKNOWLEDGE'),
      message: this.translate.instant('COACHING_R_U_SURE_WANT_ACKNOWLEDGE')
    },
      this.translate.instant('ACKNOWLEDGE')
    ).then(async res => {
      if (res.role == "ok") {
        let payload = { indskr_dateacknowledged: new Date().getTime(), statuscode: 548910000, statecode: 1 };
        if (this.isOffline(report)) {
          payload['indskr_coachingreportid'] = report.indskr_coachingreportid;
          if (report.offlineCoachingReportId)
            payload['offlineCoachingReportId'] = report.offlineCoachingReportId
          this.acknowledgeReportOffline(payload, report);
        } else {
          const loader = await this.loadingCtrl.create();
          loader.present();
          this.coachingReportDataService.acknowledgeReport(report.indskr_coachingreportid, payload)
            .then(
              res => {
                console.log(JSON.stringify(res));
                let acknowledgedDate = new Date(payload.indskr_dateacknowledged);
                report.statecode = 1;
                report.statuscode = 548910000;
                report.indskr_dateacknowledged = acknowledgedDate.toString();
                this.selectedReport.next(report);
                let reports = this.myReportSubject.value;
                let currentReport = reports.find(data => data.indskr_coachingreportid === report.indskr_coachingreportid);
                currentReport.statuscode = 548910000;
                currentReport.statusName = "Acknowledged";
                this.events.publish('coaching-updated', report);
                this.myReportSubject.next(reports);
                this.disk.retrieve(report._id.toString()).
                  then((existingCoaching: Report) => {
                    existingCoaching.statuscode = report.statuscode;
                    existingCoaching.statusName = "Acknowledged";
                    existingCoaching.indskr_dateacknowledged = report.indskr_dateacknowledged;
                    this.disk.updateOrInsert(report._id.toString(), doc => existingCoaching);
                  });
                this.fiterReports();
                loader.dismiss();
              },
              err => {
                if(err && err.error && err.error.errorCode && err.error.errorCode=='COACHING_ALREADY_RECALLED_400'){
                  loader.dismiss();
                  report.track_action = CoachingTrackStatus.Removed;
                  this.saveToDisk(false, DB_KEY_PREFIXES.MY_COACHING, [report]);
                  let myReports: Report[] = this.myReportSubject.value;
                  myReports.forEach((item, index) =>{
                    if(item.indskr_coachingreportid === report.indskr_coachingreportid) {
                      myReports.splice(index, 1);
                    }
                  });
                  this.myReportSubject.next(myReports);
                  this.notificationService.notify(this.translate.instant('COACHING_ALREADY_RECALLED'),'','top', ToastStyle.DANGER);
                  this.navService.popToRootChildNavPageWithPageTracking();
                  this.navService.setChildNavRightPaneView(false);
                  this.fiterReports();
                  return;

                } else {
                  this.checkCoachingError(err, report);
                  loader.dismiss();
                }
              }
            );
        }
      }
    });
  }

  public async recallReport() {
    let report: Report = this.selectedReport.value;
    if (report.statuscode !== 548910001 || this.activeTab !== 'teamCoaching' || this.deviceService.isOffline) return;
    let payload = { statuscode: 1, statecode: 0 };
    const loader = await this.loadingCtrl.create();
          loader.present();
          this.coachingReportDataService.recallReport(report.indskr_coachingreportid, payload)
            .then(
              res => {
                console.log(JSON.stringify(res));
                report.statecode = 0;
                report.statuscode = 1;
                this.selectedReport.next(report);
                let reports = this.teamReportSubject.value;
                let currentReport = reports.find(data => data.indskr_coachingreportid === report.indskr_coachingreportid);
                currentReport.statuscode = 1;
                currentReport.statecode = 0;
                currentReport.statusName = "Open";
                this.teamReportSubject.next(reports);
                this.disk.retrieve(report._id.toString()).
                  then((existingCoaching: Report) => {
                    existingCoaching.statuscode = report.statuscode;
                    existingCoaching.statecode = report.statecode;
                    existingCoaching.statusName = "Open";
                    this.disk.updateOrInsert(report._id.toString(), doc => existingCoaching);
                  });
                this.fiterReports();
                loader.dismiss();
              },
              err => {
                if(err && err.error && err.error.errorCode && err.error.errorCode=='COACHING_ALREADY_ACKNOWLEDGED_400'){
                  loader.dismiss();
                  this.notificationService.notify(this.translate.instant('COACHING_ALREADY_ACKNOWLEDGED'),'','top', ToastStyle.DANGER);
                  this.events.publish("open-coaching", report);
                  this.fiterReports();
                } else {
                  this.checkCoachingError(err, report);
                  loader.dismiss();
                }
              }
            );
  }

  async acceptReportOffline(payload, report: Report) {
    payload['statuscode'] = 3; // Pending Sync in Offline
    payload['indskr_accepteddate'] = new Date().getTime();
    payload["_id"] = report._id;
    let idToBeUpdated: String = payload['offlineCoachingReportId'] ? payload['offlineCoachingReportId'] : payload['indskr_coachingreportid']; //To support old records
    this.addToOfflineCoachingReportsIds(idToBeUpdated);
    report.statuscode = payload['statuscode'];
    report.statecode = payload['statecode'];
    report.indskr_accepteddate = payload['indskr_accepteddate'];
    //Create offline document
    await this.disk.createOfflineCoachingReport(report);
    await this.updateSelectedCoachingInDB(report, report);
    this.selectedReport.next(report);
    this.events.publish('coaching-updated', report);
    this.fiterReports();
  }

  async acknowledgeReportOffline(payload, report: Report) {
    payload['statuscode'] = 5; // Pending Sync in Offline
    payload["_id"] = report._id;
    let idToBeUpdated: String = payload['offlineCoachingReportId'] ? payload['offlineCoachingReportId'] : payload['indskr_coachingreportid']; //To support old records
    this.addToOfflineCoachingReportsIds(idToBeUpdated);
    report.statuscode = payload['statuscode'];
    report.statecode = payload['statecode'];
    report.indskr_dateacknowledged = payload['indskr_dateacknowledged'];
    //Create offline document
    await this.disk.createOfflineCoachingReport(payload);
    await this.updateSelectedCoachingInDB(report, report);
    this.selectedReport.next(report);
    this.events.publish('coaching-updated', report);
    this.fiterReports();
  }

  public scrapReport() {
    let report: Report = this.selectedReport.value;
    if (report.statuscode !== 1 || this.activeTab !== 'teamCoaching') return;

    this.alertService.showAlert({
      header: this.translate.instant('COACHING_SCRAP'),
      message: this.translate.instant('COACHING_R_U_SURE_WANT_SCRAP')
    }, this.translate.instant('SCRAP')
    ).then(async res => {
      if (res.role == "ok") {
        if (this.isOffline(report)) {
          await this.scrapCoachingReportOffline(report).then(() => {
            this.navService.popChildNavPageWithPageTracking();
            this.navService.setChildNavRightPaneView(false);
          });
        } else {
          const loader = await this.loadingCtrl.create();
          loader.present();
          await this.coachingReportDataService.scrapCoachingReport(report.indskr_coachingreportid)
            .then(
              res => {
                console.log(JSON.stringify(res));
                this.selectedReport.next(null);
                this.teamReportSubject.next(this.teamReportSubject.value
                  .filter(data => data.indskr_coachingreportid !== report.indskr_coachingreportid));
                this.fiterReports();
                this.disk.remove(report._id.toString());
                loader.dismiss();
              },
              msg => {
                this.checkCoachingError(msg, report);
                loader.dismiss();
              }
            );
            this.navService.popChildNavPageWithPageTracking();
            this.navService.setChildNavRightPaneView(false);
        }
      }
    });
  }

  async scrapCoachingReportOffline(report: Report) {
    if (report.indskr_coachingreportid && report.indskr_coachingreportid.length > 0) {
      //Online create - Offline Scrap
      let payload: any = {
        'indskr_coachingreportid': report.indskr_coachingreportid,
        'offlineCoachingReportId': report.offlineCoachingReportId,
        'deleted': true
      };
      if (this.hasOfflineCoachingReportsData(report.offlineCoachingReportId ? report.offlineCoachingReportId : report.indskr_coachingreportid)) {
        await this.disk.updateofflineCoachingReportDocument(payload);
      } else {
        await this.disk.createOfflineCoachingReport(payload);
      }
      await this.disk.remove(report._id.toString()); // Remove coaching from DB
    } else {
      //Offline create - Offline remove, remove it from document
      await this.disk.removeOfflineCreatedCoachingReportFromDocument(report);
    }
    let idToBeDeleted = report.offlineCoachingReportId ? report.offlineCoachingReportId : report.indskr_coachingreportid;
    this.deleteFromOfflineCoachingReportsIds(idToBeDeleted);
    this.selectedReport.next(null);
    this.teamReportSubject.next(this.teamReportSubject.value
      .filter(data => data.offlineCoachingReportId ? data.offlineCoachingReportId != idToBeDeleted : data.indskr_coachingreportid !== idToBeDeleted));
    this.fiterReports();
  }

  public async saveNewReport() {
    const assessmentMatrix: AssessmentMatrix = this.assessmentMatrix.value;
    let startTime = moment().startOf('day').valueOf();
    let endTime = moment().endOf('day').valueOf();
    let today = new Date().getTime();
    let report = new Report("", "", "", endTime, "", startTime, "", "", "", "", 1, 0, "Open", new CoachingRating(), new CoachingRating(), "", today, "", "", "", "", "", "",null, "", `offline_coaching_report_${new Date().getTime()}`, [], [], [], "", "", "", "");
    let payload: any = {
      "indskr_periodstartdate": startTime.toString(),
      "indskr_periodenddate": endTime.toString(),
      "indskr_name": "For Unknown User on " + moment().format("MMM DD" + ", " + "YYYY"),
      "statuscode": 1,
      "statecode": 0,
      'offlineCoachingReportId': report.offlineCoachingReportId
    };
    if (this.activeTab !== 'teamCoaching') return;
    const loader = await this.loadingCtrl.create();
    loader.present();

    if (this.isOffline(report)) {
      this.addToOfflineCoachingReportsIds(report.offlineCoachingReportId);
      await this.disk.createOfflineCoachingReport(report);
      let addedReports: Report[] = [...this.teamReportSubject.value, report];
      this.mapTeamCoachingFieldsToSearchIndex(report);
      this.teamReportSubject.next(addedReports);
      this.selectedReport.next(report);
      this.fiterReports();
      loader.dismiss();
    } else {
      await this.coachingReportDataService.saveNewReport(payload)
        .then(
          async (res: Report) => {
            report.indskr_coachingreportid = res.indskr_coachingreportid;
            report.categories = res.categories
            let _id = DB_KEY_PREFIXES.TEAM_COACHING + report.indskr_coachingreportid;

            try {
              const data = await this.disk.updateOrInsert(_id, doc => report);
              console.log("Saved new report details : " + data);
              let addedReports: Report[] = [...this.teamReportSubject.value, report];
              this.mapTeamCoachingFieldsToSearchIndex(report);
              this.teamReportSubject.next(addedReports);
              this.selectedReport.next(report);
              this.fiterReports();
            } catch (error) {
              console.error('saveNewReport: ', error);
            }
            loader.dismiss();
          },
          msg => {
            this.selectedReport.next(null);
            loader.dismiss();
          }
        );
    }
  }

  public async updateCoachingFor(coaching: Report) {
    // if (this.selectedReport.value.indskr_coachingfor_value &&
    //   this.selectedReport.value.indskr_coachingfor_value !== coaching.indskr_coachingfor_value) {

    //   this.alertService.showAlert({
    //     title: this.translate.instant('COACHING_RESET'),
    //     message: this.translate.instant('COACHING_ALL_CHANGES_LOST_AND_RESET')}
    //   ).then (res => {
    //     if(res.role == "ok") {
    //       this.updateCoaching(coaching);
    //     }
    //   });
    // } else {
    //   this.updateCoaching(coaching);
    // }

    await this.updateCoaching(coaching);
  }

  private async updateCoaching(coaching: Report) {
    if (this.isDuplicateCoaching(coaching)) {
      return;
    }
    if (this.isOffline(coaching)) {
      //On change of selected user: clear meetings, ratingssummary, ratingrecommendation
      if (!coaching.indskr_coachingtemplateid) {
        coaching.meetings = [];
        coaching.phonecalls = [];
        coaching.indskr_ratingssummary = "";
        coaching.indskr_recommendations = "";
        coaching.categories = [];
      }
      coaching.min_rating = new CoachingRating();
      coaching.max_rating = new CoachingRating();
      let coachingTemplates: CoachingTemplate[];
      let assementCategory: AssessmentCategory[] = new Array<AssessmentCategory>();
      coachingTemplates = this.getCoachingTemplatesByUser(coaching.indskr_coachingfor_value);
      if (coachingTemplates.length == 1) {
        let template = coachingTemplates[0];
        assementCategory = template.categories;
        if (assementCategory.length === 0) {
          this.retainPreviousCoachingState();
          return;
        }
        coaching.categories = assementCategory;
        coaching.indskr_coachingtemplateid = template.indskr_coachingtemplateid.toString();
        coaching.indskr_coachingtemplateidname = template.indskr_name;
      }
      if (coachingTemplates.length > 1 && coaching.indskr_coachingtemplateid) {
        let template = coachingTemplates.find(temp => temp.indskr_coachingtemplateid == coaching.indskr_coachingtemplateid);
        if (template.categories.length === 0) {
          this.retainPreviousCoachingState();
          return;
        }
        coaching.categories = template.categories;
      }
      this.userMappedCoachingTemplates = coachingTemplates;
      //add to offline update document hashmap
      await this.updateCoachingReportOffline(coaching, true);
      //this.navService.popWithPageTracking();
    } else {
      const loader = await this.loadingCtrl.create();
      loader.present();
      this.coachingReportDataService.updateCoachingFor(coaching.indskr_coachingreportid, coaching)
        .then(
          async (res: Report) => {
            res.min_rating = new CoachingRating();
            res.max_rating = new CoachingRating();
            let existingCoaching = await this.updateSelectedCoachingInDB(coaching, res);
            await this.updateCoachingDetails(existingCoaching, true);
            loader.dismiss();
            //this.navService.popWithPageTracking();
          },
          (error) => {
            this.checkCoachingError(error, coaching);
            loader.dismiss();
          });
    }
  }

  public isDuplicateCoaching(coaching: Report) {
    const startTime = moment(coaching.indskr_periodstartdate).startOf('day').toDate().getTime();
    const endTime = moment(coaching.indskr_periodenddate).endOf('day').toDate().getTime();
    let index = this.teamReportSubject.value.findIndex((r: Report) =>
      (r.indskr_coachingfor_value && r.indskr_coachingfor_value.length > 0) &&
      (r.indskr_coachingfor_value === coaching.indskr_coachingfor_value) &&
      (moment(r.indskr_periodstartdate).startOf('day').toDate().getTime() === startTime) &&
      (moment(r.indskr_periodenddate).endOf('day').toDate().getTime() === endTime) &&
      ((r.offlineCoachingReportId != null && r.offlineCoachingReportId !== coaching.offlineCoachingReportId) ||
        (r.indskr_coachingreportid !== coaching.indskr_coachingreportid)));
    if (index >= 0) {
      this.notificationService.notify(this.translate.instant('ERR_IO_COACHING_400'), 'Coaching Report Service', 'top', ToastStyle.DANGER);
      this.retainPreviousCoachingState();
      return true;
    }
    return false;
  }

  public isOffline(coaching: Report) {
    return this.deviceService.isOffline ||
      this.hasOfflineCoachingReportsData(coaching.offlineCoachingReportId ? coaching.offlineCoachingReportId : coaching.indskr_coachingreportid);
  }

  private async updateCoachingReportOffline(coaching: Report, refreshSelectedReport = false, payload?) {
    let idToBeUpdated: String = coaching.offlineCoachingReportId ? coaching.offlineCoachingReportId : coaching.indskr_coachingreportid; //To support old records
    if (!this.hasOfflineCoachingReportsData(idToBeUpdated)) {
      this.addToOfflineCoachingReportsIds(idToBeUpdated);
      //Create offline document
      await this.disk.createOfflineCoachingReport(payload ? payload : coaching);
    }
    else {
      //update offline upload document for this coaching
      await this.disk.updateofflineCoachingReportDocument(coaching);
    }
    //Update online created coaching in db
    let exisitingCoaching = await this.updateSelectedCoachingInDB(coaching, coaching);
    this.updateCoachingDetails(exisitingCoaching, refreshSelectedReport);
  }

  private async updateSelectedCoachingInDB(coaching: Report, res: Report): Promise<Report> {
    const coachingInDb = await this.disk.retrieve(coaching._id && coaching._id.length > 0 && coaching._id.toString()).
      then((existingCoaching: Report) => {
        if (existingCoaching) {
          existingCoaching.statecode = res.statecode;
          existingCoaching.statuscode = res.statuscode;
          existingCoaching.meetings = res.meetings;
          existingCoaching.phonecalls = res.phonecalls;
          existingCoaching.indskr_coachingfor_value = coaching.indskr_coachingfor_value;
          existingCoaching.created_for = coaching.created_for;
          existingCoaching.created_for_firstname = coaching.created_for_firstname;
          existingCoaching.indskr_name = coaching.indskr_name;
          existingCoaching.indskr_ratingssummary = res.indskr_ratingssummary;
          existingCoaching.indskr_recommendations = res.indskr_recommendations;
          existingCoaching.categories = res.categories;
          existingCoaching.min_rating = res.min_rating;
          existingCoaching.max_rating = res.max_rating;
          existingCoaching.indskr_periodstartdate = Number(res.indskr_periodstartdate);
          existingCoaching.indskr_periodenddate = Number(res.indskr_periodenddate);
          existingCoaching.indskr_dateacknowledged = res.indskr_dateacknowledged;
          existingCoaching.indskr_shareddate = res.indskr_shareddate;
          existingCoaching.indskr_accepteddate = res.indskr_accepteddate;
          existingCoaching.indskr_dateforreview = res.indskr_dateforreview;
          existingCoaching.indskr_coachingtemplateid = res.indskr_coachingtemplateid;
          existingCoaching.indskr_coachingtemplateidname = res.indskr_coachingtemplateidname;
          existingCoaching.indskr_coachingqualifyingscore = res.indskr_coachingqualifyingscore;
          existingCoaching.indskr_coachingscore = res.indskr_coachingscore;
          this.disk.updateDocWithIdAndRev(existingCoaching);
          return existingCoaching;
        }
      });
    return coachingInDb ? coachingInDb : coaching;
  }

  private updateCoachingDetails(existingCoaching: Report, refreshSelectedReport) {
    let idToBeUpdated = existingCoaching.offlineCoachingReportId ? existingCoaching.offlineCoachingReportId : existingCoaching.indskr_coachingreportid;
    let index = this.teamReportSubject.value.findIndex((r: Report) => {
      return r.offlineCoachingReportId === idToBeUpdated || r.indskr_coachingreportid === idToBeUpdated;
    });
    existingCoaching.indskr_periodstartdate = Number(existingCoaching.indskr_periodstartdate);
    existingCoaching.indskr_periodenddate = Number(existingCoaching.indskr_periodenddate);
    this.setMeasureCoachingScales(existingCoaching);
    if (refreshSelectedReport) {
      this.selectedReport.next(existingCoaching);
    }
    if (index >= 0) {
      Object.assign(this.teamReportSubject.value[index], existingCoaching);
      this.teamReportSubject.next(this.teamReportSubject.value);
    }
    this.fiterReports();
  }

  public getCoachingTemplatesByUser(coachingFor: String): CoachingTemplate[] {
    let coachingTemplates = new Array<CoachingTemplate>();
    let templatesMapped: CoachingTemplatesUser[] = this.coachingTemplatesUserMap.filter(map => map.userId == coachingFor);
    if (templatesMapped.length > 0) {
      let cochingTemplateIds: String[] = templatesMapped[0].coachingTemplateIds;
      if (cochingTemplateIds.length > 1) {
        coachingTemplates = this.coachingTemplates.filter(template => cochingTemplateIds.indexOf(template.indskr_coachingtemplateid) > -1);
      } else {
        coachingTemplates = this.coachingTemplates.filter(coachingTemplate =>
          coachingTemplate.indskr_coachingtemplateid == cochingTemplateIds[0]);
      }
    } else {
      //Show error message: No template is mapped
      this.notificationService.notify(this.translate.instant('NO_COACHING_METRICS_CONFIGURED_400'), 'Coaching Report Service', 'top', ToastStyle.DANGER);
    }
    return coachingTemplates;
  }

  async updateReport(payload: any, resetMeetings?: boolean, selectedCoachingFor?: User) {
    let selectedReport: Report = this.selectedReport.value;
    if (resetMeetings) {
      selectedReport.meetings = [];
      selectedReport.phonecalls = [];
    }
    if (payload.hasOwnProperty("meetings")) {
      selectedReport.meetings = payload.meetings;
    }
    if (payload.hasOwnProperty("phonecalls")) {
      selectedReport.phonecalls = payload.phonecalls;
    }
    if (payload.hasOwnProperty("indskr_periodstartdate")) {
      selectedReport.indskr_periodstartdate = parseFloat(payload.indskr_periodstartdate);
    }
    if (payload.hasOwnProperty("indskr_periodenddate")) {
      selectedReport.indskr_periodenddate = parseFloat(payload.indskr_periodenddate);
    }
    if (payload.hasOwnProperty("indskr_periodstartdate") || payload.hasOwnProperty("indskr_periodenddate")) {
      if (this.isDuplicateCoaching(selectedReport)) {
        return;
      }
    }
    if (this.activeTab !== 'teamCoaching') return;

    if (this.isOffline(selectedReport)) {
      await this.updateCoachingReportOffline(selectedReport, true);
    } else {
      const loader = await this.loadingCtrl.create();
      loader.present();
      this.coachingReportDataService.updateReport(selectedReport.indskr_coachingreportid, payload)
        .then(
          (res: any) => {
            this.disk.retrieve(selectedReport._id.toString()).
              then((existingCoaching: Report) => {
                existingCoaching.meetings = selectedReport.meetings;
                existingCoaching.phonecalls = selectedReport.phonecalls;
                existingCoaching.indskr_periodstartdate = selectedReport.indskr_periodstartdate;
                existingCoaching.indskr_periodenddate = selectedReport.indskr_periodenddate;
                existingCoaching.indskr_coachingfor_value = selectedReport.indskr_coachingfor_value;
                existingCoaching.created_for = selectedReport.created_for;
                existingCoaching.created_for_firstname = selectedReport.created_for_firstname;
                existingCoaching.indskr_name = selectedReport.indskr_name;
                existingCoaching.categories = selectedReport.categories;
                this.disk.updateDocWithIdAndRev(existingCoaching);
                this.updateCoachingDetails(existingCoaching, true)
              });
            loader.dismiss();
          },
          (error) => {
            this.checkCoachingError(error, selectedReport);
            loader.dismiss();
          }
        );
    }
  }


  retainPreviousCoachingState() {
    let selectedReport: Report = this.selectedReport.value;
    this.disk.retrieve(selectedReport._id.toString()).
      then((prev_state: Report) => {
        if (prev_state) {
          let index = this.teamReportSubject.value.findIndex((r: Report) => {
            return r.indskr_coachingreportid === selectedReport.indskr_coachingreportid;
          });
          this.setMeasureCoachingScales(prev_state);
          Object.assign(this.teamReportSubject.value[index], prev_state);
          this.teamReportSubject.next(this.teamReportSubject.value);
          this.selectedReport.next(prev_state);
          this.fiterReports();
        }
      });
  }

  public async updateConfigFields(report: Report, payload: any) {
    if (this.activeTab !== 'teamCoaching') return;
    if (this.isOffline(report)) {
      payload["_id"] = report._id;
      if (payload.hasOwnProperty("indskr_coachingsessions")) {
        report['indskr_coachingsessions'] = payload.indskr_coachingsessions;
      }
      if (payload.hasOwnProperty("indskr_coachingduration")) {
        report['indskr_coachingduration'] = payload.indskr_coachingduration;
      }
      payload['indskr_coachingreportid'] = report.indskr_coachingreportid;
      if (report.offlineCoachingReportId)
        payload['offlineCoachingReportId'] = report.offlineCoachingReportId
      await this.updateCoachingReportOffline(report, false);
    } else {
      this.coachingReportDataService.updateReport(report.indskr_coachingreportid, payload)
        .then(
          (res: any) => {
            this.disk.retrieve(report._id.toString()).
            then((existingCoaching: Report) => {
              existingCoaching['indskr_coachingsessions'] = report['indskr_coachingsessions'];
              existingCoaching['indskr_coachingduration'] = report['indskr_coachingduration'];
              this.disk.updateDocWithIdAndRev(existingCoaching);
              this.updateCoachingDetails(existingCoaching, true)
            });
            console.log("Coaching config fields has been updated")
          },
          msg => {
            this.checkCoachingError(msg, report);
          }
        );
    }
  }

  public async updateRatingSummaryOrRecommendation(report: Report, payload: any) {
    if (this.activeTab !== 'teamCoaching') return;
    if (this.isOffline(report)) {
      payload["_id"] = report._id;
      if (payload.hasOwnProperty("indskr_ratingssummary")) {
        report.indskr_ratingssummary = payload.indskr_ratingssummary;
      }
      if (payload.hasOwnProperty("indskr_recommendations")) {
        report.indskr_recommendations = payload.indskr_recommendations;
      }
      payload['indskr_coachingreportid'] = report.indskr_coachingreportid;
      if (report.offlineCoachingReportId)
        payload['offlineCoachingReportId'] = report.offlineCoachingReportId
      await this.updateCoachingReportOffline(report, false);
    } else {
      this.coachingReportDataService.updateReport(report.indskr_coachingreportid, payload)
        .then(
          (res: any) => {
            this.disk.retrieve(report._id.toString()).
              then((existingCoaching: Report) => {
                existingCoaching.indskr_ratingssummary = report.indskr_ratingssummary;
                existingCoaching.indskr_recommendations = report.indskr_recommendations;
                this.disk.updateDocWithIdAndRev(existingCoaching);
              });
            console.log("Rating Summary Or Recommendation has been updated")
          },
          msg => {
            this.checkCoachingError(msg, report);
          }
        );
    }
  }

  shareReport() {
    let selectedReport: Report = this.selectedReport.value;
    let acceptCoaching = this.authService._user.acceptCoaching === 0;
    let payload: any = {
      "statuscode": 548910002,
      "indskr_shareddate": new Date().getTime()
    };
    if (acceptCoaching) {
      payload['statuscode'] = 548910003;
      payload['indskr_accepteddate'] = new Date().getTime();
    }
    if (this.activeTab !== 'teamCoaching') return;
    let alertMessage = "";
    if (!_.isEmpty(selectedReport.meetings) || !_.isEmpty(selectedReport.phonecalls))
      alertMessage = this.translate.instant('COACHING_R_U_SURE_WANT_SHARE_ACCEPTANCE');
    else
      alertMessage = this.translate.instant('COACHING_R_U_SURE_WANT_SHARE_ACCEPTANCE_WITHOUT_MEETING');

    this.alertService.showAlert({
        header: this.translate.instant('COACHING_SHARE'),
        message: alertMessage
      }, this.translate.instant('SHARE')
    ).then(async res => {
      if (res.role == "ok") {
        if (this.isOffline(selectedReport)) {
          this.shareReportOffline(selectedReport, payload, acceptCoaching);
        } else {
          const loader = await this.loadingCtrl.create();
          loader.present();
          this.coachingReportDataService.updateReport(selectedReport.indskr_coachingreportid, payload)
            .then(
              (res: Report) => {
                selectedReport.statuscode = res.statuscode;
                selectedReport.statusName = this.translate.instant("SHARED");
                let sharedDate = new Date(payload.indskr_shareddate);
                selectedReport.indskr_shareddate = sharedDate.toString();
                if (acceptCoaching) {
                  selectedReport.statusName = this.translate.instant("ACCEPTED");
                  let acceptedDate = new Date(payload.indskr_accepteddate);
                  selectedReport.indskr_accepteddate = acceptedDate.toString();
                }

                let index = this.teamReportSubject.value.findIndex((r: Report) => {
                  return r.indskr_coachingreportid === selectedReport.indskr_coachingreportid;
                });
                this.disk.retrieve(selectedReport._id.toString()).
                then((existingCoaching: Report) => {
                  existingCoaching.statuscode = selectedReport.statuscode;
                  existingCoaching.statusName = this.translate.instant("SHARED");
                  existingCoaching.indskr_shareddate = selectedReport.indskr_shareddate;
                  if (acceptCoaching) {
                    existingCoaching.statusName = this.translate.instant("ACCEPTED");
                    existingCoaching.indskr_accepteddate = selectedReport.indskr_accepteddate;
                  }
                  this.disk.updateOrInsert(selectedReport._id.toString(), doc => existingCoaching);
                });
                Object.assign(this.teamReportSubject.value[index], selectedReport);
                this.teamReportSubject.next(this.teamReportSubject.value);
                this.selectedReport.next(selectedReport);
                this.fiterReports();
              },
              msg => {
                this.checkCoachingError(msg, selectedReport);
              }
            );
          loader.dismiss();
        }
      }
    });
  }

  submitReport() {
    let selectedReport: Report = this.selectedReport.value;
    let acknowledgeCoaching = this.authService._user.acknowledgeCoaching === 0;
    let payload: any = {
      "statuscode": 548910001,
      "indskr_dateforreview": new Date().getTime()
    };
    if (acknowledgeCoaching) {
      payload['statuscode'] = 548910000;
      payload['statecode'] = 1;
      payload['indskr_dateacknowledged'] = new Date().getTime();
    }
    if (this.activeTab !== 'teamCoaching' || selectedReport.statuscode === 548910001) return;
    let alertMessage = '';
    if (this.authService.hasFeatureAction(FeatureActionsMap.COACHING_SHARE)) {
      alertMessage = this.translate.instant('COACHING_R_U_SURE_WANT_SEND_ACKNOWLEDGEMENT');
    } else {
      if (!_.isEmpty(selectedReport.meetings) || !_.isEmpty(selectedReport.phonecalls))
        alertMessage = this.translate.instant('COACHING_R_U_SURE_WANT_SEND_ACKNOWLEDGEMENT');
      else
        alertMessage = this.translate.instant('COACHING_R_U_SURE_WANT_SEND_ACKNOWLEDGEMENT_WITHOUT_ACTIVITIES');
    }

    this.alertService.showAlert({
      header: this.translate.instant('COACHING_SEND'),
      message: alertMessage
    }, this.translate.instant('SEND')
    ).then(async res => {
      if (res.role == "ok") {
        if (this.isOffline(selectedReport)) {
          this.submitReportOffline(selectedReport, payload, acknowledgeCoaching);
        } else {
          const loader = await this.loadingCtrl.create();
          loader.present();
          this.coachingReportDataService.updateReport(selectedReport.indskr_coachingreportid, payload)
            .then(
              (res: Report) => {
                selectedReport.statuscode = res.statuscode;
                let sendDate = new Date(payload.indskr_dateforreview);
                selectedReport.indskr_dateforreview = sendDate.toString();
                if (acknowledgeCoaching) {
                  let ackDate = new Date(payload.indskr_dateacknowledged);
                  selectedReport.indskr_dateacknowledged = ackDate.toString();
                  selectedReport.statecode = 1;
                  selectedReport.statusName = "Acknowledged";
                }
                let index = this.teamReportSubject.value.findIndex((r: Report) => {
                  return r.indskr_coachingreportid === selectedReport.indskr_coachingreportid;
                });
                this.disk.retrieve(selectedReport._id.toString()).
                  then((existingCoaching: Report) => {
                    existingCoaching.statuscode = selectedReport.statuscode;
                    existingCoaching.indskr_dateforreview = selectedReport.indskr_dateforreview;
                    if (acknowledgeCoaching) {
                      existingCoaching.indskr_dateacknowledged = selectedReport.indskr_dateacknowledged;
                      existingCoaching.statecode = selectedReport.statecode;
                      existingCoaching.statusName = selectedReport.statusName;
                    }
                    this.disk.updateOrInsert(selectedReport._id.toString(), doc => existingCoaching);
                  });
                Object.assign(this.teamReportSubject.value[index], selectedReport);
                this.teamReportSubject.next(this.teamReportSubject.value);
                this.selectedReport.next(selectedReport);
                this.fiterReports();
              },
              msg => {
                this.checkCoachingError(msg, selectedReport);
              }
            );
          loader.dismiss();
        }
      }
    });
  }

  private async submitReportOffline(report: Report, payload, acknowledgeCoaching?: boolean) {
    report.statuscode = 4; // Pending Sync in Offline
    report.indskr_dateforreview = new Date().getTime();
    if (acknowledgeCoaching) {
      report.statuscode = 5;
      report.statecode = 1;
      report.indskr_dateacknowledged = new Date().getTime();
      payload['indskr_dateacknowledged'] = report.indskr_dateacknowledged;
      payload['statecode'] = report.statecode;
    }
    payload["_id"] = report._id;
    payload['indskr_coachingreportid'] = report.indskr_coachingreportid;
    if (report.offlineCoachingReportId)
      payload['offlineCoachingReportId'] = report.offlineCoachingReportId
    payload['statuscode'] = report.statuscode;
    payload['indskr_dateforreview'] = report.indskr_dateforreview;
    await this.updateCoachingReportOffline(report, true);
  }

  private async shareReportOffline(report: Report, payload, acceptCoaching?: boolean) {
    report.statuscode = 2; // Pending Sync in Offline
    report.indskr_shareddate = new Date().getTime();
    if (acceptCoaching) {
      report.statuscode = 3
      report.indskr_accepteddate = new Date().getTime();
      payload['indskr_accepteddate'] = report.indskr_accepteddate;
    }
    payload["_id"] = report._id;
    payload['indskr_coachingreportid'] = report.indskr_coachingreportid;
    if (report.offlineCoachingReportId)
      payload['offlineCoachingReportId'] = report.offlineCoachingReportId
    payload['indskr_shareddate'] = report.indskr_shareddate;
    payload['statuscode'] = report.statuscode;
    await this.updateCoachingReportOffline(report, true);
  }

  static processAssessmentCategories(categories: AssessmentCategory[]): AssessmentCategory[] {
    for (let i = 0; i < categories.length; i++) {
      //For each category, filter the "measure" array to not include empty ratings
      categories[i].measures = categories[i].measures.filter((m) => {
        return ((m.rating_id != undefined
          && m.rating_id != "") || m.rating_id != null)
      });
    }
    return categories.filter((c) => c.measures.length != 0);
  }

  public isMaxRating(rating: number, ratingscale_id: String): boolean {
    //Considering only one assessment scale for all the measures, get the scale from first measure of the category
    if (ratingscale_id) {
      const ratings: AssessmentRating[] = this.coachingScales.get(ratingscale_id);
      if (ratings) {
        let max: number = Math.max(...ratings.map(value => value.rating_value));
        if (max % 2 == 0) {
          return rating > max / 2;
        }
        return rating >= max / 2;
      }
    }
    return false;
  }


  public async openActivityDetails(activity_id: string) {
    this.activityDataService.activityDetailsLoaded = false;
    this.uiService.showRightPane = true;
    this.activityClick = true;
    await this.activityDataService.getActivityByIdOnline(activity_id, "appointment")
      .then(async response => {
        let activity: Activity = new AppointmentActivity(response);
        this.setActivityCoaching(activity);
        this.uiService.activeView = 'Appointment';
        this.activityService.selectedActivity = activity;
        await this.activityDataService._appendMeetingDetailsToActivity(activity, response, false);
        this.activityDataService.activityDetailsLoaded = true;
      })
  }

  public async openPhoneCallActivityDetails(activity: CoachingActivity) {
    this.activityDataService.activityDetailsLoaded = false;
    this.uiService.showRightPane = true;
    this.activityClick = true;
    await this.activityDataService.getPhonecallActivityByIdOnline(activity.indskr_activityid, activity.indskr_scheduledstart.toString(), activity.indskr_scheduledend.toString(), this.selectedReport.value.indskr_periodstartdate.toString())
      .then(async response => {
        let activity: PhoneActivity = new PhoneActivity(response);
        this.setActivityCoaching(activity);
        this.uiService.activeView = 'PhoneCall';
        this.activityService.selectedActivity = activity;
        await this.activityDataService._appendPhoneCallDetailsToActivity(activity, response, false);
        this.activityDataService.activityDetailsLoaded = true;
      });
      setTimeout(() => {
        this.events.publish('detectChangesOnPhoneCallActivityDetails');
      }, 100);
  }


  // public findCoachingReportsByActivityId(activity_id: string):Report[] {

  // }

  public async saveOrUpdateRating(value: number, rating_label: String, rating_id: String, category_id: String, measure_id: String) {
    const selectedReport: Report = this.selectedReport.value;
    let measure: AssessmentMeasure = selectedReport.categories.find(data => data.category_id === category_id).measures.find((measure) => measure.measure_id === measure_id);
    measure.rating_id = rating_id;
    measure.rating_value = value;
    measure.rating_label = rating_label
    measure.clearRating = false;
    this.applyMinAndMaxRatings(selectedReport);
    let payload: any = {
      "indskr_assessment_metricsid": measure.indskr_assessment_metricsid ? measure.indskr_assessment_metricsid : "",
      "measure_id": measure_id,
      "rating_id": rating_id
    };
    if (this.activeTab !== 'teamCoaching') return;
    await this.calculateCoachingScore();
    payload['indskr_coachingqualifyingscore'] = selectedReport.indskr_coachingqualifyingscore;
    payload['indskr_coachingscore'] = selectedReport.indskr_coachingscore;
    payload['indskr_coachingreportid'] = selectedReport.indskr_coachingreportid;
    if (this.isOffline(selectedReport)) {
      selectedReport.categories.find(data => data.category_id === category_id).measures.forEach(measure => {
        if (measure.measure_id === measure_id) {
          measure.rating_id = rating_id;
          measure.rating_value = value;
          measure.rating_label = rating_label;
          measure.indskr_assessment_metricsid = measure.indskr_assessment_metricsid;
        }
      });
      await this.updateCoachingReportOffline(selectedReport);
    } else {
      await this.coachingReportDataService.saveOrUpdateRating(selectedReport.indskr_coachingreportid.toString(), category_id.toString(), payload)
        .then(
          (res: AssessmentMeasure) => {
            selectedReport.categories.find(data => data.category_id === category_id).measures.forEach(measure => {
              if (measure.measure_id === measure_id) {
                measure.rating_id = rating_id;
                measure.rating_value = value;
                measure.rating_label = rating_label;
                measure.indskr_assessment_metricsid = res.indskr_assessment_metricsid;
              }
            });
            let index = this.teamReportSubject.value.findIndex((r: Report) => {
              return r.indskr_coachingreportid === selectedReport.indskr_coachingreportid;
            });
            Object.assign(this.teamReportSubject.value[index], selectedReport);
            this.teamReportSubject.next(this.teamReportSubject.value);
            this.disk.retrieve(selectedReport._id.toString()).
              then((existingCoaching: Report) => {
                existingCoaching.categories = selectedReport.categories;
                existingCoaching.min_rating = selectedReport.min_rating;
                existingCoaching.max_rating = selectedReport.max_rating;
                existingCoaching.indskr_coachingqualifyingscore = selectedReport.indskr_coachingqualifyingscore;
                existingCoaching.indskr_coachingscore = selectedReport.indskr_coachingscore;
                this.disk.updateDocWithIdAndRev(existingCoaching);
              });
            this.fiterReports();
          },
          msg => {
            this.checkCoachingError(msg, selectedReport);
          }
        );
    }
  }

  async calculateCoachingScore() {
    this.givenRatingsWeight = 0;
    this.templateWeight = 0;
    let report: Report = this.selectedReport.getValue();
    report.categories.forEach(cat => {
      cat.measures.filter(o=>o.measure_isrequired).forEach(m => {
        let givenValue;
        let maxValue;
        if (m.ratingscale_id) {
          givenValue = m.rating_value ? m.rating_value : 0;
          maxValue = Math.max(...m.ratings.map(r=> r.rating_value));
        } else {
          givenValue = m.score ? Number(m.score) : 0;
          maxValue = m.max_rating;
        }
        this.givenRatingsWeight += givenValue;
        this.templateWeight += maxValue;
      })
      //adding the non-mandatory measure into claculation if their is a value provided
      cat.measures.filter(o=>!o.measure_isrequired && (o.rating_value || o.score)).forEach(m=> {
        let givenValue;
        let maxValue;
        if (m.ratingscale_id && m.rating_value) {
          givenValue = m.rating_value;
          maxValue = Math.max(...m.ratings.map(r=> r.rating_value));
        } else if(m.score) {
          givenValue = Number(m.score);
          maxValue = m.max_rating;
        }
        this.givenRatingsWeight += givenValue;
        this.templateWeight += maxValue;
      })
    })
    report.indskr_coachingqualifyingscore = this.authService.user.coachingQualifyingScore;
    let coachingScore = String(((this.givenRatingsWeight/this.templateWeight)*100).toFixed(2));
    report.indskr_coachingscore = coachingScore === '0' || coachingScore === 'NaN' ? '' : coachingScore;
  }

  public async saveOrUpdateRatingScore(score: String, category_id: String, measure_id: String) {
    const selectedReport: Report = this.selectedReport.value;
    let measure: AssessmentMeasure = selectedReport.categories.find(data => data.category_id === category_id).measures.find((measure) => measure.measure_id === measure_id);
    measure.score = score;
    measure.clearRating = false;
    this.applyMinAndMaxRatings(selectedReport);
    let payload: any = {
      "indskr_assessment_metricsid": measure.indskr_assessment_metricsid ? measure.indskr_assessment_metricsid : "",
      "measure_id": measure_id,
      "score": score ? score : ""
    };
    if (this.activeTab !== 'teamCoaching') return;
    await this.calculateCoachingScore();
    payload['indskr_coachingqualifyingscore'] = selectedReport.indskr_coachingqualifyingscore;
    payload['indskr_coachingscore'] = selectedReport.indskr_coachingscore;
    payload['indskr_coachingreportid'] = selectedReport.indskr_coachingreportid;
    if (this.isOffline(selectedReport)) {
      selectedReport.categories.find(data => data.category_id === category_id).measures.forEach(measure => {
        if (measure.measure_id === measure_id) {
          measure.score = score;
          measure.indskr_assessment_metricsid = measure.indskr_assessment_metricsid;
        }
      });
      await this.updateCoachingReportOffline(selectedReport);
    } else {
      await this.coachingReportDataService.saveOrUpdateRating(selectedReport.indskr_coachingreportid.toString(), category_id.toString(), payload)
        .then(
          (res: AssessmentMeasure) => {
            selectedReport.categories.find(data => data.category_id === category_id).measures.forEach(measure => {
              if (measure.measure_id === measure_id) {
                measure.score = score;
                measure.indskr_assessment_metricsid = res.indskr_assessment_metricsid;
              }
            });
            let index = this.teamReportSubject.value.findIndex((r: Report) => {
              return r.indskr_coachingreportid === selectedReport.indskr_coachingreportid;
            });
            Object.assign(this.teamReportSubject.value[index], selectedReport);
            this.teamReportSubject.next(this.teamReportSubject.value);
            this.disk.retrieve(selectedReport._id.toString()).
            then((existingCoaching: Report) => {
              existingCoaching.categories = selectedReport.categories;
              existingCoaching.min_rating = selectedReport.min_rating;
              existingCoaching.max_rating = selectedReport.max_rating;
              existingCoaching.indskr_coachingqualifyingscore = selectedReport.indskr_coachingqualifyingscore;
              existingCoaching.indskr_coachingscore = selectedReport.indskr_coachingscore;
              this.disk.updateDocWithIdAndRev(existingCoaching);
            });
            this.fiterReports();
          },
          msg => {
            this.checkCoachingError(msg, selectedReport);
          }
        );
    }
  }

  public async saveOrUpdateRatingComments(ratingComment, category_id: string, m: AssessmentMeasure) {
    const selectedReport: Report = this.selectedReport.value;
    let measure: AssessmentMeasure = selectedReport.categories.find(data => data.category_id === category_id).measures.find((measure) => measure.measure_id === m.measure_id);
    let payload: any = {
      "indskr_assessment_metricsid": measure.indskr_assessment_metricsid ? measure.indskr_assessment_metricsid : "",
      "rating_comments": ratingComment
    };
    if (this.activeTab !== 'teamCoaching') return;
    // await this.uiService.displayLoader();
    if (this.isOffline(selectedReport)) {
      selectedReport.categories.find(data => data.category_id === category_id).measures.forEach(measure => {
        if (measure.measure_id === m.measure_id) {
          measure.rating_comments = ratingComment;
          measure.indskr_assessment_metricsid = measure.indskr_assessment_metricsid;
        }
      });
      await this.updateCoachingReportOffline(selectedReport);
      // await this.uiService.dismissLoader();
    } else {
      await this.coachingReportDataService.saveOrUpdateRating(selectedReport.indskr_coachingreportid.toString(), category_id.toString(), payload)
        .then(
          async (res: AssessmentMeasure) => {
            selectedReport.categories.find(data => data.category_id === category_id).measures.forEach(measure => {
              if (measure.measure_id === m.measure_id) {
                measure.rating_comments = res.rating_comments;
                measure.indskr_assessment_metricsid = res.indskr_assessment_metricsid;
              }
            });
            let index = this.teamReportSubject.value.findIndex((r: Report) => {
              return r.indskr_coachingreportid === selectedReport.indskr_coachingreportid;
            });
            Object.assign(this.teamReportSubject.value[index], selectedReport);
            this.teamReportSubject.next(this.teamReportSubject.value);
            this.disk.retrieve(selectedReport._id.toString()).
              then((existingCoaching: Report) => {
                existingCoaching.categories = selectedReport.categories;
                this.disk.updateDocWithIdAndRev(existingCoaching);
              });
            // await this.uiService.dismissLoader();
          },
          async msg => {
            this.checkCoachingError(msg, selectedReport);
            // await this.uiService.dismissLoader();
          }
        );
    }
  }

  async clearRatings(category_id: String) {
    let selectedReport: Report = this.selectedReport.value;
    const payload: AssessmentMeasure[] = selectedReport.categories.find(data => data.category_id === category_id).measures.filter(ms => ms.ratingscale_id ? (ms.rating_id && !ms.clearRating) : (ms.score && !ms.clearRating));
    if (this.activeTab !== 'teamCoaching' || !payload.length) return;
    this.alertService.showAlert({
      header: this.translate.instant('COACHING_CLEAR_RATINGS'),
      message: this.translate.instant('COACHING_PLEASE_NOTE')
    }, this.translate.instant('CLEAR')
    ).then(async res => {
      if (res.role == "ok") {
        if (this.isOffline(selectedReport)) {
          await this.updateClearRatingDetails(selectedReport, category_id, true);
          await this.calculateCoachingScore();
          await this.updateCoachingReportOffline(selectedReport, true);
        } else {
          const loader = await this.loadingCtrl.create();
          loader.present();
          await this.coachingReportDataService.clearRatings(selectedReport.indskr_coachingreportid.toString(), payload)
            .then(
              async () => {

                await this.updateClearRatingDetails(selectedReport, category_id);
                await this.calculateCoachingScore();
                let index = this.teamReportSubject.value.findIndex((r: Report) => {
                  return r.indskr_coachingreportid === selectedReport.indskr_coachingreportid;
                });
                Object.assign(this.teamReportSubject.value[index], selectedReport);
                this.teamReportSubject.next(this.teamReportSubject.value);

                let scorePayload = {
                  indskr_coachingqualifyingscore: selectedReport.indskr_coachingqualifyingscore,
                  indskr_coachingscore: selectedReport.indskr_coachingscore
                };
                await this.coachingReportDataService.updateScore(selectedReport.indskr_coachingreportid.toString(), scorePayload).then(() => {

                    this.disk.retrieve(selectedReport._id.toString()).then((existingCoaching: Report) => {
                      existingCoaching.categories = selectedReport.categories;
                      existingCoaching.min_rating = selectedReport.min_rating;
                      existingCoaching.max_rating = selectedReport.max_rating;
                      existingCoaching.indskr_coachingqualifyingscore = selectedReport.indskr_coachingqualifyingscore;
                      existingCoaching.indskr_coachingscore = selectedReport.indskr_coachingscore;
                      this.disk.updateDocWithIdAndRev(existingCoaching);
                    });
                    this.fiterReports();
                    this.selectedReport.next(selectedReport);
                    loader.dismiss();
                });
            },
            async msg => {
              await this.checkCoachingError(msg, selectedReport);
              await loader.dismiss();
            });
        }
      }
    });
  }

  private async updateClearRatingDetails(selectedReport: Report, category_id: String, isOffline: boolean = false) {
    selectedReport.categories.find(data => data.category_id === category_id).measures.forEach((measure) => {
      // if (!isOffline)
        measure.rating_id = null;
      measure.rating_label = null;
      measure.rating_value = 0;
      measure.rating_name = null;
      measure.score = "";
      measure.rating_comments = null;
      measure.clearRating = true
    });
    this.applyMinAndMaxRatings(selectedReport);
  }

  private applyMinAndMaxRatings(report: Report) {
    let ratings: number[] = [];
    let ratingscale_id: String = null;
    report.categories.forEach(category => {
      category.measures.forEach(measure => {
        if (measure.rating_id && ratings.indexOf(measure.rating_value) == -1 && !measure.clearRating) {
          ratings.push(measure.rating_value);
          if (!ratingscale_id) ratingscale_id = measure.ratingscale_id;
        }
      });
    });
    if (ratings.length > 0) {
      if (ratings.length == 1) {
        let max: boolean = this.isMaxRating(ratings[0], ratingscale_id);
        if (max) {
          report.max_rating = new CoachingRating(ratings[0], true);
          report.min_rating = new CoachingRating();
        } else {
          report.max_rating = new CoachingRating();
          report.min_rating = new CoachingRating(ratings[0], true);
        }
      } else {
        report.max_rating = new CoachingRating(Math.max(...ratings), true);
        report.min_rating = new CoachingRating(Math.min(...ratings), true);
      }
    } else {
      report.min_rating = new CoachingRating();
      report.max_rating = new CoachingRating();
    }
  }

  public async checkCoachingOnline(report: Report) {
    await this.uiService.displayLoader();
    await this.coachingReportDataService.getCoachingById(report.indskr_coachingreportid, this.activeTab, report.statuscode)
      .then(
        async (response: Report) => {
          if (response.hasOwnProperty('statuscode') && response.statuscode !== report.statuscode) {
            if (this.activeTab === 'myCoaching') {
              if(response.statuscode == 1) {
                response.track_action = CoachingTrackStatus.Removed;
                await this.saveToDisk(false, DB_KEY_PREFIXES.MY_COACHING, [response]);
                let myReports: Report[] = this.myReportSubject.value;
                myReports.forEach((item, index) =>{
                  if(item.indskr_coachingreportid === report.indskr_coachingreportid) {
                    myReports.splice(index, 1);
                  }
                });
                this.myReportSubject.next(myReports);
                if(report.statuscode == 548910001) {
                  this.notificationService.notify(this.translate.instant('COACHING_ALREADY_RECALLED'),'','top', ToastStyle.DANGER);
                }
                report.statuscode = response.statuscode;
                // this.navService.popToRootChildNavPageWithPageTracking();
              } else {
                report.statuscode = response.statuscode;
                report.indskr_dateacknowledged = response.indskr_dateacknowledged;
                report.indskr_shareddate = response.indskr_shareddate;
                report.indskr_accepteddate = response.indskr_accepteddate;
                report.indskr_dateforreview = response.indskr_dateforreview;
                report.indskr_coachingduration = response.indskr_coachingduration;
                report.indskr_coachingsessions = response.indskr_coachingsessions;
                const updatedReport =  await this.updateSelectedCoachingInDB(report, response);
                let myReports: Report[] = this.myReportSubject.value;
                const idx = myReports.findIndex(r => r.indskr_coachingreportid === report.indskr_coachingreportid);
                if (idx >= 0) {
                  this.setMeasureCoachingScales(updatedReport);
                  myReports[idx]= updatedReport;
                  this.myReportSubject.next(myReports);
                  this.selectedReport.next(updatedReport);
                }
              }

            } else {
              report.statuscode = response.statuscode;
              report.indskr_dateacknowledged = response.indskr_dateacknowledged;
              report.indskr_shareddate = response.indskr_shareddate;
              report.indskr_accepteddate = response.indskr_accepteddate;
              report.indskr_dateforreview = response.indskr_dateforreview;
              report.indskr_coachingduration = response.indskr_coachingduration;
              report.indskr_coachingsessions = response.indskr_coachingsessions;
              if (response.categories) {
                report.categories = response.categories;
                this.setMeasureCoachingScales(report);
              }
              this.disk.retrieve(report._id.toString()).
                then(async (existingCoaching: Report) => {
                  if (existingCoaching) {
                    existingCoaching.statuscode = report.statuscode;
                    existingCoaching.indskr_dateacknowledged = report.indskr_dateacknowledged;
                    existingCoaching.indskr_shareddate = report.indskr_shareddate;
                    existingCoaching.indskr_accepteddate = report.indskr_accepteddate;
                    existingCoaching.indskr_dateforreview = report.indskr_dateforreview;
                    existingCoaching.indskr_coachingduration = report.indskr_coachingduration;
                    existingCoaching.indskr_coachingsessions = report.indskr_coachingsessions;
                    await this.disk.updateDocWithIdAndRev(existingCoaching);
                  }
                });
                this.teamReportSubject.next(this.teamReportSubject.value);
                this.selectedReport.next(report);
            }
            this.fiterReports();
          } else {
            this.selectedReport.next(report);
          }
          await this.uiService.dismissLoader();
        },
        async msg => {
          console.error("Error occurred while fetching coaching details of ", report.indskr_coachingreportid);
          this.checkCoachingError(msg, report);
          await this.uiService.dismissLoader();
        });
  }

  public async setActivityCoaching(data: Activity) {
    let filteredCoachings: Report[] = [];
    if (data && (data.isCompleted || data.isReopened)) {
      if (this.teamReportSubject.value.length > 0) {
        if (data instanceof PhoneActivity) {
          filteredCoachings = this.teamReportSubject.value.filter(coachingReport =>
            coachingReport.statuscode !== 1 && coachingReport.phonecalls.filter(activity => activity.indskr_activityid === data.ID).length > 0
          );
        } else {
          filteredCoachings = this.teamReportSubject.value.filter(coachingReport =>
            coachingReport.statuscode !== 1 && coachingReport.meetings.filter(activity => activity.indskr_activityid === data.ID).length > 0
          );
        }
      }
    }
    this.activityCoachingDataSource.next(filteredCoachings);
  }

  private async checkCoachingError(err: any, coaching: Report) {
    if (err.hasOwnProperty("error") && err.error.errorCode == "RECORD_NOT_FOUND_404") {
      this.selectedReport.next(null);
      this.teamReportSubject.next(this.teamReportSubject.value
        .filter(data => data.indskr_coachingreportid !== coaching.indskr_coachingreportid));
      this.fiterReports();
      this.disk.remove(coaching._id.toString());
    } else {
      this.retainPreviousCoachingState();
    }
  }

  public async uploadOfflineCoachingReports(isPartialUpload = false, maxRecordCountForPartialUpload = 10) {
    if (!isPartialUpload && this.deviceService.isOffline) return;
    const offlineCoachingReports = await this.disk.loadOfflineCoachingReports();
    const coachingReportsSyncInfo: EntitySyncInfo = {
      entityName: EntityNames.coachingReport,
      totalFailed: 0,
      totalSynced: 0,
      errors: [],
      syncStatus: true
    };
    if (offlineCoachingReports && offlineCoachingReports['coachingReports'] && Array.isArray(offlineCoachingReports['coachingReports']) && offlineCoachingReports['coachingReports'].length > 0) {
      try {
        let updatedRequestBody = offlineCoachingReports['coachingReports'];

        if (isPartialUpload) {
          if (offlineCoachingReports['eventRegResponse'].length > maxRecordCountForPartialUpload) {
            updatedRequestBody = JSON.parse(JSON.stringify(offlineCoachingReports['eventRegResponse']));
            updatedRequestBody = updatedRequestBody.splice(0, maxRecordCountForPartialUpload);
          }
        }
        updatedRequestBody.forEach(coaching => {
          if (!coaching.deleted) {
            if (coaching.statuscode === 2) {
              coaching.statuscode = 548910002
            }
            else if (coaching.statuscode === 3) {
              coaching.statuscode = 548910003;
            }
            else if (coaching.statuscode === 4) {
              coaching.statuscode = 548910001;
            }
            else if (coaching.statuscode === 5) {
              coaching.statuscode = 548910000;
            }
            if (coaching.indskr_shareddate && !_.isNumber(coaching.indskr_shareddate)) {
              coaching.indskr_shareddate = new Date(coaching.indskr_shareddate).getTime();
            }
            if (coaching.indskr_accepteddate && !_.isNumber(coaching.indskr_accepteddate)) {
              coaching.indskr_accepteddate = new Date(coaching.indskr_accepteddate).getTime();
            }
          }
        });
        const offlineCoachingsResponse = await this.coachingReportDataService.uploadOfflineCoachingReports(updatedRequestBody);
        for (let index = 0; index < offlineCoachingsResponse.length; index++) {
          let cResponse = offlineCoachingsResponse[index];
          if (!cResponse['errorId'] || cResponse['errorCode'] == "RECORD_NOT_FOUND_404") {
            let idx;
            offlineCoachingReports['coachingReports'].findIndex((a, index) => {
              if (a.offlineCoachingReportId && a.offlineCoachingReportId == cResponse['offlineCoachingReportId']) {
                idx = index;
              } else if (a.indskr_coachingreportid && a.indskr_coachingreportid == cResponse['indskr_coachingreportid']) {
                idx = index;
              }
            });
            if (idx >= 0) {
              let coaching = offlineCoachingReports['coachingReports'][idx];
              let idToBeUpdated: String = coaching.offlineCoachingReportId ? coaching.offlineCoachingReportId : coaching.indskr_coachingreportid; //To support old records
              this.deleteFromOfflineCoachingReportsIds(idToBeUpdated);
              await this.createOfflineCoachingReportInDB(updatedRequestBody, cResponse, coaching);
              offlineCoachingReports['coachingReports'].splice(idx, 1);
              offlineCoachingReports['count']--
            }
            else {
              console.warn(`uploadOfflineCoachingReport: ID ${cResponse.offlineCoachingReportId} is not found from local db.`);
            }
            coachingReportsSyncInfo.totalSynced++;
          }
        }
      } catch (httpError) {
        this.deltaService.addSyncErrorToEntitySyncInfo(coachingReportsSyncInfo, this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.customerEvents.CAPTURE_EVENT_REGISTRATION_RESPONSE, httpError);
        coachingReportsSyncInfo.totalFailed += coachingReportsSyncInfo['count'];
      }
      this.deltaService.addEntitySyncInfo(coachingReportsSyncInfo, true);
      await this.disk.updateDocWithIdAndRev(offlineCoachingReports);

      // Track offline data count
      const newCount = offlineCoachingReports['coachingReports'].length - coachingReportsSyncInfo.totalSynced + coachingReportsSyncInfo.totalFailed
      this.disk.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.COACHING_REPORT, newCount > 0 ? newCount : 0);
    }
  }

  private async createOfflineCoachingReportInDB(updatedRequestBody: any[], cResponse: any, coaching: any) {
    let reqIndex = updatedRequestBody.findIndex(offlineRequestCoaching =>
      (offlineRequestCoaching["offlineCoachingReportId"] === cResponse["offlineCoachingReportId"]) ||
      (offlineRequestCoaching["indskr_coachingreportid"] === cResponse["indskr_coachingreportid"])
    );
    if (!coaching.deleted && reqIndex >= 0) {
      let dbKey = "";
      if (!updatedRequestBody[reqIndex]["_id"] || updatedRequestBody[reqIndex]["_id"].includes(DB_KEY_PREFIXES.TEAM_COACHING)) {
        dbKey = DB_KEY_PREFIXES.TEAM_COACHING;
      }
      else {
        dbKey = DB_KEY_PREFIXES.MY_COACHING;
      }
      updatedRequestBody[reqIndex]["indskr_coachingreportid"] = cResponse["indskr_coachingreportid"];
      let coachingToBeCreated = [];
      coachingToBeCreated.push(updatedRequestBody[reqIndex]);
      await this.saveToDisk(false, dbKey, coachingToBeCreated);
    }
  }

  public async checkOfflineDataExists(): Promise<boolean> {
    const offlineCoachingReports = await this.disk.loadOfflineCoachingReports();
    const offlineDataCount = offlineCoachingReports && Array.isArray(offlineCoachingReports['coachingReports']) && offlineCoachingReports['coachingReports'].length > 0 ? offlineCoachingReports['coachingReports'].length : 0;
    this.disk.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.COACHING_REPORT, offlineDataCount);
    return offlineDataCount ? true : false;
  }


  public getCoachingDuration(report: Report): string {
    let duration: string = "";
    let days: any = differenceInDays(report.indskr_periodenddate, report.indskr_periodstartdate) + 1;
    let weeks: any = differenceInWeeks(report.indskr_periodenddate, report.indskr_periodstartdate);
    let months: any = differenceInMonths(report.indskr_periodenddate, report.indskr_periodstartdate);
    let years: any = differenceInYears(report.indskr_periodenddate, report.indskr_periodstartdate);
    if (years >= 1) {
      duration = years + "y"
    }
    else if (months >= 1) {
      duration = months + "m"
    }
    else if (weeks >= 1) {
      duration = weeks + "w"
    }
    else if (days >= 1) {
      duration = days + "d"
    }
    return duration;

  }

  private mapStatusCodeToStatusName(coaching: Report) {
    let shareFA: boolean = this.authService.hasFeatureAction(FeatureActionsMap.COACHING_SHARE);
    if (coaching.statuscode == 1) coaching.statusName = 'Open';
    else if (coaching.statuscode == 548910002 && shareFA) coaching.statusName = this.translate.instant("SHARED");
    else if (coaching.statuscode == 548910003 && shareFA) coaching.statusName = this.translate.instant("ACCEPTED");
    else if (coaching.statuscode == 548910001) coaching.statusName = this.translate.instant("FOR_REVIEW");
    else if (coaching.statuscode == 548910000) coaching.statusName = this.translate.instant("ACKNOWLEDGED")
  }

  public mapMyCoachingFieldsToSearchIndex(newCoaching: Report) {
    this.mapStatusCodeToStatusName(newCoaching);
    if (!this.searchConfigService.isConfigInitiated) {
      this.searchConfigService.initSearchConfigs();
      this.searchConfigService.isConfigInitiated = true;
    }
    if (newCoaching.statusName && !this.searchConfigService.myCoachingSearchIndexesConfig.find(config => config.categoryName == 'Status').values.some(o => o == newCoaching.statusName)) {
      this.searchConfigService.myCoachingSearchIndexesConfig.find(config => config.categoryName == 'Status').values.push(newCoaching.statusName);
    }
    if (newCoaching.created_by && !this.searchConfigService.myCoachingSearchIndexesConfig.find(config => config.categoryName == 'Coaching By').values.some(o => o == newCoaching.created_by.toString())) {
      this.searchConfigService.myCoachingSearchIndexesConfig.find(config => config.categoryName == 'Coaching By').values.push(newCoaching.created_by.toString());
    }
  }

  public mapTeamCoachingFieldsToSearchIndex(newCoaching: Report) {
    this.mapStatusCodeToStatusName(newCoaching);
    if (!this.searchConfigService.isConfigInitiated) {
      this.searchConfigService.initSearchConfigs();
      this.searchConfigService.isConfigInitiated = true;
    }
    if (newCoaching.statusName && !this.searchConfigService.teamCoachingSearchIndexesConfig.find(config => config.categoryName == 'Status').values.some(o => o == newCoaching.statusName)) {
      this.searchConfigService.teamCoachingSearchIndexesConfig.find(config => config.categoryName == 'Status').values.push(newCoaching.statusName);
    }
    if (newCoaching.created_for && !this.searchConfigService.teamCoachingSearchIndexesConfig.find(config => config.categoryName == 'Users').values.some(o => o == newCoaching.created_for.toString())) {
      this.searchConfigService.teamCoachingSearchIndexesConfig.find(config => config.categoryName == 'Users').values.push(newCoaching.created_for.toString());
    }
  }

  public getMyRecentSearches() {
    this.disk.retrieve(DB_KEY_PREFIXES.MY_COACHING_RECENT_SEARCHES, true).then((doc) => {
      if (doc && doc.raw) {
        this.recentSearches = doc.raw
      }
      else {
        this.recentSearches = [];
      }
    })
  }

  public getTeamRecentSearches() {
    this.disk.retrieve(DB_KEY_PREFIXES.TEAM_COACHING_RECENT_SEARCHES, true).then((doc) => {
      if (doc && doc.raw) {
        this.teamRecentSearches = doc.raw
      }
      else {
        this.teamRecentSearches = [];
      }
    })
  }

  sortListByFieldName(options, fieldName: string) {
    if (_.isEmpty(options)) return [];
    return options.sort((a, b) => {
      let nameA: string = a[fieldName], nameB: string = b[fieldName];
      if (!nameA || !nameB) return 1;
      nameA = nameA.toLowerCase();
      nameB = nameB.toLowerCase();
      if (nameA < nameB)
        return -1;
      if (nameA >= nameB)
        return 1;
    });
  }

  public makeStatus(statuscode: number): string {
    let coachingStatus;
    coachingStatus = this.translate.instant(CoachingReportStatus["Status_" + statuscode]);
    return coachingStatus;

  }

  public getTertiaryInfo(report: Report, sortBy) {
    let str: string = '';

    if (sortBy) {
      switch (sortBy.value) {
        case 'indskr_shareddate':
          str = report.indskr_shareddate ? this.getFormattedAndLocalisedDate(new Date(report.indskr_shareddate)) : this.translate.instant('NO_SHARED_DATE');
          break;
        case 'indskr_accepteddate':
          str = report.indskr_accepteddate ? this.getFormattedAndLocalisedDate(new Date(report.indskr_accepteddate)) : this.translate.instant('NO_ACCEPTED_DATE');
          break;
        case 'indskr_dateforreview':
          str = report.indskr_dateforreview ? this.getFormattedAndLocalisedDate(new Date(report.indskr_dateforreview)) : this.translate.instant('NO_FORREVIEW_DATE');
          break;
        case 'indskr_dateacknowledged':
          str = report.indskr_dateacknowledged ? this.getFormattedAndLocalisedDate(new Date(report.indskr_dateacknowledged)) : this.translate.instant('NO_ACKNOWLEDGED_DATE');
          break;
        case 'createdon':
          str = (report.createdon && report.createdon > 0) ? this.getFormattedAndLocalisedDate(new Date(report.createdon)) : this.translate.instant('NO_COACHING_DATE');
          break;
      }
    } else {
      console.error('no sort option found');
  }
    return str;
  }

  private getFormattedAndLocalisedDate(value: any) {
    return this.datePipe.transform(value, this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
  }
}
