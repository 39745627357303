import { OmniAccordionViewDataModel } from './../../../models/omniAccordionViewDataModel';
import {takeUntil} from 'rxjs/operators';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, ViewChild, AfterContentChecked } from '@angular/core';
import { AuthenticationService } from "@omni/services/authentication.service";
import { IonList, LoadingController, NavController, PopoverController, ModalController } from "@ionic/angular";
import { FooterService } from "@omni/services/footer/footer.service";
import { DeviceService } from "@omni/services/device/device.service";
import { ComponentViewMode, UIService } from "@omni/services/ui/ui.service";
import { ContactOfflineService } from "@omni/services/contact/contact.service";
import { ContactConsent } from "../../../classes/consent/contact-consent.class";
import { ConsentService } from "@omni/services/consent/consent.service";
import { Product } from "../../../classes/consent/product.class";
import { Channel, ChannelType, ChannelValue } from "../../../classes/consent/channel.class";
import { BrandOfflineService } from "@omni/services/brand/brand.service";
import { PopoverComponent } from "../../popover/popover";
import { ConsentTerm } from "../../../classes/consent/consent-term.class";
import { Subject, BehaviorSubject} from "rxjs";
import { ActiveConsentsFilter } from "../../../pipes/active-consents-filter.pipe";
import { NavigationService, PageName } from "@omni/services/navigation/navigation.service";
import { ConsentDetailsComponent } from "../consent-details/consent-details";
import { TranslateService } from '@ngx-translate/core';
import { AccordionViewDataModel } from '../../../models/accordionDataModel';
import { MainCardViewDataModel } from '../../../models/MainCardViewDataModel';
import { IndFormFieldViewDataModel } from '../../../models/indFormFieldDataModel';
import { format } from 'date-fns';
import { DateTimeFormatsService } from '@omni/services/date-time-formats/date-time-formats.service';
import { IndSectionHeaderViewDataModel } from '../../../models/indSectionHeaderDataModel';
import { DatePipe } from '@angular/common';
import _ from 'lodash';
import { IndAlertMsgComponent } from '@omni/components/shared/ind-alert-msg/ind-alert-msg';
import { ConsentActivity } from '@omni/classes/consent/consent-activitiy.class';
import { IndFilterMenuModalComponent, IndFilterMenuModalDataModel } from '@omni/components/shared/ind-filter-menu-modal/ind-filter-menu-modal';
import { IndDropdownListDetailModel } from '@omni/models/indDropdownListModel';
import { IndDropdownListComponent } from '@omni/components/shared/ind-dropdown-list/ind-dropdown-list';
import { TrackingEventNames, TrackService } from '@omni/services/logging/tracking.service';

/**
 * Generated class for the ContactConsentComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'contact-consent',
  templateUrl: 'contact-consent.html',
  styleUrls:['contact-consent.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class ContactConsentComponent {

  private selectedContactConsents: ContactConsent;
  private _consentsFilterPopover;
  public activeConsentsSubject = new BehaviorSubject<ConsentTerm[]>([]);
  public activeConsentsObs$ = this.activeConsentsSubject.asObservable();
  public activeConsentsModel: OmniAccordionViewDataModel[] = [];
  public pendingSyncConsentsSubject = new BehaviorSubject<ConsentTerm[]>([]);
  public pendingSyncConsentsObs$ = this.pendingSyncConsentsSubject.asObservable();
  public pendingSyncConsentsModel:OmniAccordionViewDataModel[] = [];
  destroy$: Subject<boolean> = new Subject<boolean>();
  private consentsFilter: ActiveConsentsFilter;
  isFilterApplied: boolean = false;
  public contactConsentPendingHeader: IndSectionHeaderViewDataModel;
  @ViewChild(IonList, { read: ElementRef, static: false }) list: ElementRef;
  public contactConsentActiveHeader: IndSectionHeaderViewDataModel;
  public filteredConsentActiveHeader: IndSectionHeaderViewDataModel;
  public activeConsentsGrid: any[] = [];
  public filteredActiveConsentsGrid: any[] = [];
  public pendingSyncConsentsGrid: any[] = [];
  private popover: HTMLIonPopoverElement;
  public isOptOutFiltered: boolean = false;
  public activeConsentFilterFormView: IndFilterMenuModalDataModel[] = [];
  public selectedFilters: any= [];
  private filterdConsentActivity: any[] = [];
  public isOptOutEnabled: boolean = false;

  constructor(
    public authenticationService: AuthenticationService,
    public navCtrl: NavController,
    public footerService: FooterService,
    public device: DeviceService,
    public uiService: UIService,
    private contactService: ContactOfflineService,
    private consentService: ConsentService,
    private productService: BrandOfflineService,
    private popoverCtrl: PopoverController,
    private navService: NavigationService,
    private _cd: ChangeDetectorRef,
    public translate: TranslateService,
    private dateTimeFormatService: DateTimeFormatsService,
    private datePipe: DatePipe,
    public loadingCtrl: LoadingController,
    public modalCtrl: ModalController,
    public trackingService: TrackService,
  ) {
    this.consentsFilter = new ActiveConsentsFilter(this.consentService);
  }

  ngOnInit() {
    if (this.contactService.contactInformation) {
      this.consentService.allActiveConsentSubject.subscribe((consents: ContactConsent[]) => {
        if (consents.length) {
          this._cd.detectChanges();
          if (this.contactService.contactInformation) {
            this.selectedContactConsents = consents.filter((consent: ContactConsent) =>
              consent.indskr_contactid.toString() === this.contactService.contactInformation.ID.toString()
            )[0];
          }
          this.init();
          this._cd.detectChanges();
          this._cd.markForCheck();
        }
      });
    }
  }

  ngOnDestroy() {
    this.consentService.clearFilters();
    this.destroy$.next(true);
    // this.destroy$.unsubscribe();
  }


  init() {
    this.isOptOutEnabled = this.uiService.isConsentFromToolDrawer && this.contactService.accessedContactListFrom === PageName.ToolsDrawer && !this.contactService.isOneKeySearching;
    if (this.selectedContactConsents) {

      const activeConsents = this.selectedContactConsents.activeConsents.filter(consent => !consent.isOfflineSaved);
      const pendingSyncConsents = this.selectedContactConsents.activeConsents.filter(consent => consent.isOfflineSaved);

      this.consentService.showAllConsentsFilter$.pipe(
        takeUntil(this.destroy$))
        .subscribe(value => {
          this._cd.detectChanges();
          this.activeConsentsSubject.next(this.consentsFilter.transform(activeConsents));
          this.pendingSyncConsentsSubject.next(this.consentsFilter.transform(pendingSyncConsents));
          this.initViewModels();
          this._initFilterFormViews();
          this._cd.detectChanges();
          this._cd.markForCheck();
        });
    } else {
      this.activeConsentsGrid = [];
      this.activeConsentsSubject.next([]);
    }
    this.footerService.updateButtons([], true);
    this._initSectionHeader();
  }

  get pendingSyncConsents(): ConsentTerm[] {
    let pendingConsents: ConsentTerm[] = [];
    this.pendingSyncConsentsSubject.subscribe(value => {
      pendingConsents = value;
    });
    return pendingConsents;
  }

  get activeConsents(): ConsentTerm[] {
    let activeConsents: ConsentTerm[] = [];
    this.activeConsentsSubject.subscribe(value => {
      activeConsents = value;
    });
    return activeConsents;
  }

  ngAfterViewInit(){
    setTimeout(() => {
      this.uiService.scrollListToView(this.list);
    }, 200);
  }

  // ngAfterContentChecked() {
  //   if (this.contactConsentActiveHeader) {
  //     this.contactConsentActiveHeader.title = `${this.translate.instant("ALL_ACTIVE_CONSENTS")} (${this.activeConsents?.length})`;
  //   }
  // }

  private _initFilterFormViews() {
    this.activeConsentFilterFormView = [];
    if(this.activeConsents && this.activeConsents.length !== 0) {
      this.activeConsents.forEach(consent => {
        //consent term
        let idx = this.activeConsentFilterFormView.findIndex((view) => view.id == 'consent-term');
        let terms = {
          group: this.translate.instant('CONTACT_CONSENT_TERMS'),
          id: consent['indskr_consenttermsid'].toString(),
          displayName: consent['indskr_consentTermName'].toString(),
          isSelected: false,
        }
        if(idx>-1) {
          let optionIdx = this.activeConsentFilterFormView[idx].options.findIndex((opt) => opt.id == consent['indskr_consenttermsid'].toString());
          if(optionIdx<0) this.activeConsentFilterFormView[idx].options.push(terms);
        } else {
          let consentTermView = {
            id : 'consent-term',
            displayName: this.translate.instant('CONTACT_CONSENT_TERMS'),
            isMultiSelect: true,
            options: []
          }
          consentTermView.options.push(terms);
          this.activeConsentFilterFormView.push(consentTermView);
        }
        //product
        if(!_.isEmpty(consent.products) && this.authenticationService.user.isProductConsent) {
          consent.products?.forEach((product) => {
            let option = {
              group: this.translate.instant('PRODUCTS'),
              id: product['indskr_productid'].toString(),
              displayName: product['indskr_productname'].toString(),
              isSelected: false,
            };
            let idx = this.activeConsentFilterFormView.findIndex((view) => view.id == 'Product');
            if(idx>-1) {
              let optionIdx = this.activeConsentFilterFormView[idx].options.findIndex((opt) => opt.id == product['indskr_productid']);
              if(optionIdx<0) this.activeConsentFilterFormView[idx].options.push(option);
            } else {
              let productView = {
                id : 'Product',
                displayName: this.translate.instant('PRODUCTS'),
                isMultiSelect: true,
                options: []
              }
              productView.options.push(option);
              this.activeConsentFilterFormView.push(productView);
            }
          })
        }
        //channel
        consent.channels?.forEach((channel) => {
          let options = [];
          if(!_.isEmpty(channel.values)) {
            channel.values.forEach((value) => {
              let option = {
                group: `${channel['indskr_consentType'].toString()}`,
                id: `${channel['indskr_consentType'].toString()}_${value['value'].toString()}`,
                displayName: value['value'].toString(),
                isSelected: false,
              }
              options.push(option);
            })
            options = _.uniqBy(options, 'displayName');
            let idx = this.activeConsentFilterFormView.findIndex((view) => view.id == channel['indskr_consenttypeid'].toString());
            if(idx>-1) {
              let temp = _.cloneDeep(this.activeConsentFilterFormView[idx].options);
              let channelOptions = _.uniqBy(temp.concat(options), 'displayName');
              this.activeConsentFilterFormView[idx].options = channelOptions;
            } else {
              this.activeConsentFilterFormView.push({
                id : channel['indskr_consenttypeid'].toString(),
                displayName: channel['indskr_consentType'].toString(),
                isMultiSelect: true,
                options: options
              })
            }
          }
        })
      })
    }
  }
  private initViewModels(): void {
    // this.activeConsentsModel = [];
    // this.pendingSyncConsentsModel = [];

    this.activeConsentsGrid = [];
    this.pendingSyncConsentsGrid = [];

    if(this.activeConsents && this.activeConsents.length !== 0){
      let numOfCol = 3;
      this.activeConsents.forEach(consent => {
        let childItems = [];
        const foundChildItems = this._getGridFieldsForConsentModel(consent);
        let numOfRow = Math.ceil(foundChildItems.length/3);
        const consentTermsId: string = consent.offlineId ? consent.offlineId.toString() : consent.indskr_consenttermsid.toString();
        for(let i=0; i<numOfRow; i++) {
          childItems[i] = [];
          for(let j=0; j<numOfCol; j++) {
            if(!_.isEmpty(foundChildItems[i*numOfCol+j])) childItems[i].push(foundChildItems[i*numOfCol+j]);
          }
        }
        this.activeConsentsGrid.push({
          id: consentTermsId,
          consentTermName:consent.indskr_consentTermName,
          isOptOutDisabled: false,
          childItems: childItems,
        })
      })

      // this.activeConsents.forEach(consent => {
      //   const foundChildItems = this._getChildFieldsForConsentModel(consent);
      //   const consentTermsId: string = consent.offlineId ? consent.offlineId.toString() : consent.indskr_consenttermsid.toString();
      //   this.activeConsentsModel.push({
      //     displayType: 'Accordion',
      //     id: consentTermsId,
      //     primaryText:consent.indskr_consentTermName.toString(),
      //     hasCustomSecondary: true,
      //     customSecondaryTextRight: this.authenticationService.user.isProductConsent && consent.products ? this.displayMedications(consent.products) : "",
      //     customSecondaryTextRightTwo: this.displayChannels(consent.channels),
      //     isExpanded:false,
      //     showExpandIcon: true,
      //     showCollapseIcon: true,
      //     childItems: foundChildItems,
      //     clickHandler: (id: string, event,eventName,refData) => this._handleActiveConsentExpandClick(id,event,eventName),
      //   });
      // });
    }

    if(this.pendingSyncConsentsSubject.value && this.pendingSyncConsentsSubject.value.length !== 0){
      let numOfCol = 3
      this.pendingSyncConsentsSubject.value.forEach(consent => {
        let childItems = [];
        const foundChildItems = this._getGridFieldsForConsentModel(consent);
        let numOfRow = Math.ceil(foundChildItems.length/3);
        const consentTermsId: string = consent.offlineId ? consent.offlineId.toString() : consent.indskr_consenttermsid.toString();
        for(let i=0; i<numOfRow; i++) {
          childItems[i] = [];
          for(let j=0; j<numOfCol; j++) {
            if(!_.isEmpty(foundChildItems[i*numOfCol+j])) childItems[i].push(foundChildItems[i*numOfCol+j]);
          }
        }
        this.pendingSyncConsentsGrid.push({
          id: consentTermsId,
          consentTermName: consent.indskr_consentTermName.toString(),
          isOptOutDisabled: false,
          childItems: childItems,
        })
      })

      // this.pendingSyncConsentsSubject.value.forEach(consent => {
      //   const foundChildItems = this._getChildFieldsForConsentModel(consent);
      //   const consentTermsId: string = consent.offlineId ? consent.offlineId.toString() : consent.indskr_consenttermsid.toString();
      //   this.pendingSyncConsentsModel.push({
      //     displayType: 'Accordion',
      //     id:consentTermsId,
      //     primaryText: consent.indskr_consentTermName.toString(),
      //     hasCustomSecondary: true,
      //     customSecondaryTextRight: this.authenticationService.user.isProductConsent && consent.products ? this.displayMedications(consent.products) : "",
      //     customSecondaryTextRightTwo: this.displayChannels(consent.channels),
      //     isExpanded:false,
      //     showExpandIcon: true,
      //     showCollapseIcon: true,
      //     childItems: foundChildItems,
      //     clickHandler: (id: string, event,eventName,refData) => this._handlePendingSyncConsentExpandClick(id,event,eventName),
      //   });
      // });
    }
  }

  public handleActiveConsentExpandClick(id,event,eventName){
    if(id && eventName && eventName == 'mainLabel'){
      const consent = this.activeConsents.find(consent => consent.indskr_consenttermsid == id || consent.offlineId == id);
      if(consent){
        this.openConsentDetails(consent);
      }
    }
    event.stopPropagation();
  }

  public handlePendingSyncConsentExpandClick(id,event,eventName){
    if(id && eventName && eventName == 'mainLabel'){
      const consent = this.pendingSyncConsentsSubject.value.find(consent => consent.indskr_consenttermsid == id || consent.offlineId == id);
      if(consent){
        this.openConsentDetails(consent);
      }
    }
    event.stopPropagation();
  }

  private _getGridFieldsForConsentModel(consent: ConsentTerm): Array<any> {
    const items: Array<any> = [];
    //Products Field
    if(!_.isEmpty(consent.products) && this.authenticationService.user.isProductConsent){
      let numOfProducts = consent.products.length
      let dropDownProducts = [];
      if(numOfProducts>1) {
        for(let i=1;i<consent.products.length;i++) {
          dropDownProducts.push({
            title: consent.products[i].indskr_productname
          })
        }
      }
      items.push({
        id:'products_field',
        label: this.translate.instant('PRODUCTS'),
        value: consent.products[0].indskr_productname,
        isMultipleValue: numOfProducts > 1,
        dropDownText: `(+${numOfProducts-1})`,
        dropDownValue: dropDownProducts,
        allValue: this.displayMedications(consent.products)
      });
    }
    //Channels Field
    if(!_.isEmpty(consent.channels)){
      consent.channels.forEach(channel=>{
        let dropDownChannel = [];
        if(!_.isEmpty(channel.values) && channel.values.length>1) {
          for(let i=1;i<channel.values?.length;i++) {
            dropDownChannel.push({
              title: channel.values[i].value
            })
          }
        }
        const label = this.displayChannelLabels(channel.indskr_consentType.toString());
        const text = this.displayChannelValues(channel.indskr_consentType.toString(), channel.values);
        if(label && text){
          items.push({
            id:'channel_field_'+channel.indskr_consenttypeid,
            label: label,
            value: channel.values[0].value,
            isMultipleValue: channel.values?.length > 1,
            dropDownText: `(+${channel.values?.length-1})`,
            dropDownValue: dropDownChannel,
            allValue: text
          });
        }
      });
    }
    //Captured On Field
    if(consent.createdon){
      items.push({
        id:'captured_on_field',
        label: this.translate.instant('CONTACT_CAPTURED_ON'),
        value: this.datePipe.transform(consent.createdon, this.dateTimeFormatService.date, undefined, this.translate.currentLang),
      });
    }
    //Expires On Field
    if(consent.indskr_untildate){
      items.push({
        id:'expired_on_field',
        label: this.translate.instant('EXPIRES_ON'),
        value: this.datePipe.transform(consent.indskr_untildate, this.dateTimeFormatService.date, undefined, this.translate.currentLang),
      });
    }
    //Signature Field
    items.push({
      id:'signature_field',
      label: this.translate.instant('SIGNATURE'),
      value: (consent.indskr_signature)?this.translate.instant('YES'):this.translate.instant('NO'),
    });

    return items;
  }

  public async expandItemValues(item, event) {
    const dropdownListDetail: IndDropdownListDetailModel = {
      id: 'active-consent-grid-drop-down',
      data: item.dropDownValue,
      isReadOnly: true
    };
    let gridDropDown = await this.popoverCtrl.create({component: IndDropdownListComponent,
                                                     componentProps: {viewData:dropdownListDetail},
                                                     cssClass:'dropdown-list-view',
                                                     event: event});
    gridDropDown.present();
    gridDropDown.onDidDismiss().then(async (data) => {
    });
    event.stopPropagation();
  }

  private _getChildFieldsForConsentModel(consent: ConsentTerm): Array<any> {
    const items: Array<any> = [];
    //Captured On Field
    if(consent.createdon){
      items.push({
        id:'captured_on_field',
        label: this.translate.instant('CONTACT_CAPTURED_ON'),
        value: this.datePipe.transform(consent.createdon, this.dateTimeFormatService.date, undefined, this.translate.currentLang),
      });
    }

    //Expires On Field
    if(consent.indskr_untildate){
      items.push({
        id:'expired_on_field',
        label: this.translate.instant('EXPIRES_ON'),
        value: this.datePipe.transform(consent.indskr_untildate, this.dateTimeFormatService.date, undefined, this.translate.currentLang),
      });
    }

    //Products Field
    if(consent.products && this.authenticationService.user.isProductConsent){
      items.push({
        id:'products_field',
        label: this.translate.instant('PRODUCTS'),
        value: this.displayMedications(consent.products),
      });
    }

    //Channels Field
    if(consent.channels){
      consent.channels.forEach(channel=>{
        const label = this.displayChannelLabels(channel.indskr_consentType.toString());
        const text = this.displayChannelValues(channel.indskr_consentType.toString(), channel.values);
        if(label && text){
          items.push({
            id:'channel_field'+channel.indskr_consenttypeid,
            label: label,
            value: text,
          });
        }
      });
    }

    //Signature Field
    items.push({
      id:'signature_field',
      label: this.translate.instant('SIGNATURE'),
      value: (consent.indskr_signature)?this.translate.instant('YES'):this.translate.instant('NO'),
    });

    return items;
  }

  displayMedications(products: Product[]) {
    let productNames: string = "";
    if (products.length) {
      productNames = Array.prototype.map.call(products, p => p.indskr_productname).join(', ');
    }
    return productNames;
  }

  displayChannels(channels: Channel[]) {
    try {
      channels = channels.sort((a, b) => {
        return (a.indskr_consentType.toLowerCase() > b.indskr_consentType.toLowerCase()) ? 1 : -1;
      });
    } catch (error) {
      console.log(error);
    }
    let channelNames: string = "";
    if (channels.length) {
      channelNames = Array.prototype.map.call(channels, c => this.displayChannelLabels(c.indskr_consentType)).join(', ');
    }
    return channelNames;
  }

  displayChannelLabels(label: string) {
    let displayName: string = "";
    let translateddisplayName: string = "";
    switch (label) {
      case ChannelType.EMAIL.toString():
        displayName = ChannelType.EMAIL_ADDRESS.toString();
        break;
      case ChannelType.VISIT.toString():
        displayName = ChannelType.VISIT.toString();
        break;
      case ChannelType.PHONE.toString():
        displayName = ChannelType.PHONE_NUMBER.toString();
        break;
      default:
        displayName = label;
    }
    switch (displayName) {
      case 'Email':
        translateddisplayName = this.translate.instant('EMAIL');
        break;
      case 'Address':
        translateddisplayName = this.translate.instant('ADDRESS');
        break;
      case 'Phone':
        translateddisplayName = this.translate.instant('PHONE');
        break;
      case 'SMS':
        translateddisplayName = this.translate.instant('SMS');
        break;
      case 'Visit':
        translateddisplayName = this.translate.instant('VISIT');
        break;
      case 'WhatsApp':
        translateddisplayName = this.translate.instant('WHATSAPP');
        break;
      case 'Facebook':
        translateddisplayName = this.translate.instant('FACEBOOK');
        break;
      case 'Fax':
        translateddisplayName = this.translate.instant('FAX');
        break;
      default:
        translateddisplayName = displayName;
        break;
    }

    return translateddisplayName;
  }

  displayChannelValues(type: string, values: ChannelValue[]) {
    let channelValues: string = "";
    if (!_.isEmpty(values)) {
      // if (type === ChannelType.VISIT) {
      //   channelValues = Array.prototype.map.call(values, c => c.value).join(", <br/>");
      // } else {
        channelValues = Array.prototype.map.call(values, c => c.value).join(', ');
      // }
    }
    return channelValues;
  }

  async openActiveConsentsFilter(event) {
    this.consentService.isProductFilterExpanded = true;
    this.consentService.isChannelFilterExpanded = true;
    this.isFilterApplied = true;
    this._consentsFilterPopover = await this.popoverCtrl.create({
      component:PopoverComponent,
      componentProps:{ field: "consentFilter" },
      cssClass: "consents-filter-popover",
      event: event}
    );
    this._consentsFilterPopover.present();
    this._consentsFilterPopover.onDidDismiss().then(() => {
      this._consentsFilterPopover = null;
      this._initSectionHeader();
    });
  }

  public getFilterText(): string {
    let str: string = 'All Consents';
    this.isFilterApplied = false;
    if (!this.productService.brands) return;
    if (this.consentService.consentProductFilter$) {
      this.isFilterApplied = true;
      if (str !== 'All Consents') {
        str += ', ' + this.consentService.consentProductFilter$.name;
      } else {
        str = this.consentService.consentProductFilter$.name;
      }
    }
    if (this.consentService.consentChannelFilter$) {
      this.isFilterApplied = true;
      if (str !== 'All Consents') {
        str += ', ' + this.consentService.displayChannelLabels(this.consentService.consentChannelFilter$.indskr_consentType.toString());
      } else {
        str = this.consentService.displayChannelLabels(this.consentService.consentChannelFilter$.indskr_consentType.toString());
      }
    }

    switch (str) {
      case 'All Consents':
        str = this.translate.instant('ALL_ACTIVE_CONSENTS');
        break;
      default:
        str = str;
        break;
    }
    return str;
  }

  openConsentDetails(term: ConsentTerm) {
    if (this.contactService.accessedContactListFrom === PageName.AccountDetailsComponent && this.contactService.contactPageMode === ComponentViewMode.READONLY) return;
    this.consentService.selectedConsentTerm.next(term);
    this.consentService.consentTab = "";
    this.navService.pushWithPageTracking(ConsentDetailsComponent, PageName.ConsentDetailsComponent);
  }

  private _initSectionHeader() {
    this.contactConsentPendingHeader = {
      id: 'contact-consent-pending-header',
      title: !_.isEmpty(this.pendingSyncConsents) ? this.translate.instant("CONTACT_PENDING_SYNC_CAP") +' (' + this.pendingSyncConsents.length + ')' : this.translate.instant("CONTACT_PENDING_SYNC_CAP"),
      controls: [],
    };
    this.contactConsentActiveHeader = {
      id: 'contact-consent-active-header',
      // title: this.getFilterText() +' (' + this.activeConsents.length + ')',
      title: !_.isEmpty(this.activeConsents) ? `${this.translate.instant("ALL_ACTIVE_CONSENTS")} (${this.activeConsents.length})` : this.translate.instant("ALL_ACTIVE_CONSENTS"),
      // isFilter: true,
      // isFilterStyleHeading: true,
      // showArrow: true,
      // arrowType: 'caret-down-outline',
      controls: [{
        id: 'opt-out-from-all',
        text: `${this.translate.instant('OPT_OUT_FROM_ALL')}`,
        isVisible: this.isOptOutEnabled,
        isDisabled: this.device.isOffline || this.activeConsents.length == 0,
      },{
        id: 'active-consent-filter',
        isDisabled: false,
        isVisible: this.isOptOutEnabled,
        icon: 'assets/imgs/filter-blue.svg'
      }],
      doNotModifyTitleCase: true
    }
    this.filteredConsentActiveHeader = {
      id: 'filtered-consent-active-header',
      title: `${this.translate.instant("RESULTS_SMALL")} (${this.filteredActiveConsentsGrid.length})`,
      controls: [{
        id: 'clear-results',
        text: `${this.translate.instant('CLEAR')} ${this.translate.instant('RESULTS_SMALL')}`,
        isDisabled: !this.isOptOutFiltered,
      },{
        id: 'active-consent-filter',
        isDisabled: false,
        icon: 'assets/imgs/filter-blue.svg'
      }],
      doNotModifyTitleCase: true
    }
  }

  onSectionHeaderControlClick(id:string) {
    if (id === 'opt-out-from-all') {
      this.optOutFromAllConsent(id);
    } else if (id === 'active-consent-filter') {
      this._openActiveConsentsFilter();
    } else if (id === 'clear-results') {
      this._clearFilterResults();
    }
  }

  displayChannelLabels1(label: string) {
    let translateddisplayName: string = "";
   switch (label) {
      case 'Email':
        translateddisplayName = this.translate.instant('EMAIL');
        break;
      case 'Address':
        translateddisplayName = this.translate.instant('ADDRESS');
        break;
      case 'Phone':
        translateddisplayName = this.translate.instant('PHONE');
        break;
      case 'SMS':
        translateddisplayName = this.translate.instant('SMS');
        break;
      case 'Visit':
        translateddisplayName = this.translate.instant('VISIT');
        break;
      case 'WhatsApp':
        translateddisplayName = this.translate.instant('WHATSAPP');
        break;
      case 'Facebook':
        translateddisplayName = this.translate.instant('FACEBOOK');
        break;
      case 'Fax':
        translateddisplayName = this.translate.instant('FAX');
        break;
     case 'Campaign':
       translateddisplayName = this.translate.instant('CAMPAIGN');
       break;
      default:
        translateddisplayName = label;
        break;
    }

    return translateddisplayName;
  }

  public async openMoreOptions(id, event, eventName) {
    let data  = [{
      id: 'view-details',
      title: this.translate.instant('VIEW_DETAILS')
    },{
      id: 'timeline',
      title: this.translate.instant('TIMELINE')
    }
    ]
    const dropdownListDetail: IndDropdownListDetailModel = {
      id: 'active-consent-more-option-drop-down',
      data: data,
      isReadOnly: false,
      isMultipleSelectionEnabled: false
    };
    let gridDropDown = await this.popoverCtrl.create({component: IndDropdownListComponent,
                                                     componentProps: {viewData:dropdownListDetail},
                                                     cssClass:'dropdown-list-view',
                                                     event: event});
    gridDropDown.present();
    gridDropDown.onDidDismiss().then(async (data) => {
      if (data && data.data && data.data.selectedItems) {
        if (data.data.selectedItems[0].id == 'view-details') {
          this.handleActiveConsentExpandClick(id, event, eventName);
        } else if (data.data.selectedItems[0].id == 'timeline') {
          this.uiService.contactDetailsSegment = 'timeline';
          const insightsEventName = this.contactService.contactPageMode === ComponentViewMode.ADDNEW ? 'Contact Timeline  via Meeting' : 'ContactTimeline'
          this.trackingService.tracking(insightsEventName, TrackingEventNames.CONTACTS)
        }
      }
    });
    event.stopPropagation();
  }

  public async optOutFromConsent(event, consent?) {
    console.log(consent);
    let message;
    if(this.isOptOutFiltered) {
      let channelText = Array.prototype.map.call(this.selectedFilters, a => `${a.label} <b>"${a.value}"</b>`).join(", ");
      if(channelText.includes(this.translate.instant('CONTACT_CONSENT_TERMS'))) channelText = '';
      message = this.translate.instant('OPT_OUT_EACH_TERM', { channel: channelText, termName: consent.consentTermName });
    } else {
      message = this.translate.instant('OPT_OUT_EACH_TERM', { channel: '', termName: consent.consentTermName });
    } 
    let popoverOptions = {
      header: this.translate.instant('OPT_OUT'),
      message: message,
      confirmText: this.translate.instant('CONFIRM'),
    }
    this.popover = await this.popoverCtrl.create({
      component: IndAlertMsgComponent,
      componentProps: popoverOptions,
      cssClass: this.device.isNativeApp ? 'native-ind-alert-msg' : 'ind-alert-msg',
      backdropDismiss: true,
    });
    this.popover.present();
    event.stopPropagation();

    await this.popover.onDidDismiss().then(async (res:any) => {
      if(res.data && res.data.role && res.data.role == 'ok') {
        let loader = await this.loadingCtrl.create();
        let tempConActivities = [];
        if(this.isOptOutFiltered) {
          this.filterdConsentActivity[consent.id].forEach((activity) => {
            let optOut = this._prepareOptOutPayload(activity);
            optOut['indskr_ConsentTerms@odata.bind'] = consent.id;
            optOut['indskr_consentTermName'] = consent.consentTermName;
            tempConActivities.push(optOut);
          })
        } else {
          let foundConsent = this.activeConsents.find(con => con.indskr_consenttermsid == consent.id);
          foundConsent.consentActivity.filter(ca => ca.indskr_consentactivitytype ==  100000000).forEach((act) => {
            let optOut = this._prepareOptOutPayload(act);
            optOut['indskr_ConsentTerms@odata.bind'] = consent.id;
            optOut['indskr_consentTermName'] = consent.consentTermName;
            tempConActivities.push(optOut);
          })
        }
        await loader.present();
        this.consentService.createOptOutConsentActivity(tempConActivities).then(async () => {
          await loader.dismiss();
          this._clearFilterResults();
          this._initSectionHeader();
          }, async error => {
          await loader.dismiss();
        })
      }
    })
  }

  public async optOutFromAllConsent(id) {
    let message;
    if(this.isOptOutFiltered) {
      let channelText = Array.prototype.map.call(this.selectedFilters, a => `${a.label} <b>"${a.value}"</b>`).join(", ");
      message = this.translate.instant('OPT_OUT_ALL_TERMS', {channel: channelText});
    } else {
      message = this.translate.instant('OPT_OUT_ALL_TERMS', { channel: '' });
    } 
    let popoverOptions = {
      header: this.translate.instant('OPT_OUT'),
      message: message,
      confirmText: this.translate.instant('CONFIRM'),
    }
    this.popover = await this.popoverCtrl.create({
      component: IndAlertMsgComponent,
      componentProps: popoverOptions,
      cssClass: this.device.isNativeApp ? 'native-ind-alert-msg' : 'ind-alert-msg',
      backdropDismiss: true,
    });
    this.popover.present();
    await this.popover.onDidDismiss().then(async (res: any) => {
      if(res.data && res.data.role && res.data.role == 'ok') {
        let loader = await this.loadingCtrl.create();
        let tempConActivities = [];
        this.activeConsents.forEach((consent) => {
          if(this.isOptOutFiltered) {
            this.filterdConsentActivity[consent.indskr_consenttermsid.toString()]?.forEach((filteredAct) => {
              let toBeOptedOutAct = consent.consentActivity.find(consentAct => consentAct.indskr_consentactivityid == filteredAct.indskr_consentactivityid);
              if(!_.isEmpty(toBeOptedOutAct)) {
                let filteredOptOut = this._prepareOptOutPayload(toBeOptedOutAct);
                filteredOptOut['indskr_ConsentTerms@odata.bind'] = consent.indskr_consenttermsid;
                filteredOptOut['indskr_consentTermName'] = consent.indskr_consentTermName;
                tempConActivities.push(filteredOptOut);
              }
            })
          } else {
            consent.consentActivity.filter(ca => ca.indskr_consentactivitytype ==  100000000).forEach((act) => {
              let optOut = this._prepareOptOutPayload(act);
              optOut['indskr_ConsentTerms@odata.bind'] = consent.indskr_consenttermsid;
              optOut['indskr_consentTermName'] = consent.indskr_consentTermName;
              tempConActivities.push(optOut);
            })
          }            
        })       
        await loader.present();
        this.consentService.createOptOutConsentActivity(tempConActivities).then(async () => {
          await loader.dismiss();
          this._clearFilterResults();
          this._initSectionHeader();
          }, async error => {
          await loader.dismiss();
        })
      }
    })
  }

  private _prepareOptOutPayload(activity:ConsentActivity) {
    let payload = {};
    payload['indskr_Contact@odata.bind'] = this.contactService.contactInformation.ID;
    payload['indskr_type'] = 100000001;
    payload['indskr_SalesRep@odata.bind'] = this.authenticationService.user.xSystemUserID;
    payload['indskr_Position@odata.bind'] = this.authenticationService.user.xPositionID;
    payload['indskr_ConsentType@odata.bind'] = activity.indskr_consenttypeid;
    payload['indskr_consentTypeName'] = activity.indskr_consentTypeName;
    payload['indskr_Product@odata.bind'] = activity.indskr_product;
    payload['indskr_productname'] = activity.indskr_productname;
    payload['indskr_EmailAddress@odata.bind'] = activity.indskr_emailaddress;
    payload['indskr_addressname'] = activity.indskr_addressname;
    payload['indskr_phone'] = activity.indskr_phone;
    return payload;
  }

  private async _openActiveConsentsFilter() {
    // handle selected options
    const modal = await this.modalCtrl.create({
      component: IndFilterMenuModalComponent,
      cssClass: 'filter-menu-right-modal',
      backdropDismiss: true,
      componentProps: {
        viewData: {
          from: PageName.ConsentDetailsComponent,
          data: this.activeConsentFilterFormView,
          filterTitle: this.translate.instant('OPT_OUT'),
        },
      }
    });
    await modal.present().then(() => {});
    modal.onDidDismiss().then(async(res)=>{
      //Show Result
      if(res && res.data && res.data.isDone) {
        if(!_.isEmpty(res.data.selectedItems)) {
          this.filteredActiveConsentsGrid = [];
          this.activeConsentFilterFormView = res.data.selectedItems;
          this.isOptOutFiltered = true;
          this.selectedFilters = _.chain(res.data.selectedItemsAccordion)
                                  .groupBy('value').map((value, key) => {
                                    let channelValues = Array.prototype.map.call(value, c => c.label).join(', ')
                                    return {label: key, value: channelValues};
                                  })
                                  .value();
          res.data.selectedItemsAccordion.forEach((filter) => {
            this.activeConsentsGrid.forEach((grid) => {
              if(grid.consentTermName == filter.label) this.filteredActiveConsentsGrid.push(grid);
              else {
                grid.childItems.forEach((itemRow) => {
                  let idx = itemRow.findIndex((item)=> item.allValue?.includes(filter.label));
                  if(idx>-1) this.filteredActiveConsentsGrid.push(grid);
                })
              }             
            })
          })
          this.filteredActiveConsentsGrid = _.uniqBy(this.filteredActiveConsentsGrid, 'id');
          this._getFilteredActivities(res.data.selectedItemsAccordion);
          this._initSectionHeader();
        }
        console.log(res);
      }
    })

  }

  private _getFilteredActivities(selectedFilters) {
    this.filterdConsentActivity = [];
    this.activeConsents.forEach((consent) => {
      this.filterdConsentActivity[consent.indskr_consenttermsid.toString()] = [];
      selectedFilters.forEach(filter => {
        switch (filter.type) {
          case 'consent-term' :
            if(consent.indskr_consenttermsid == filter.id) {
              let consentActivity = consent.consentActivity.filter(act => act['indskr_consentactivitytype'] == 100000000);
              this.filterdConsentActivity[consent.indskr_consenttermsid.toString()] = [...this.filterdConsentActivity[consent.indskr_consenttermsid.toString()], ...consentActivity];
            }
            break;
          case 'Product' :
            let productActivities = consent.consentActivity.filter(activity => activity['indskr_product'] == filter.id && activity['indskr_consentactivitytype'] == 100000000);
            if(!_.isEmpty(productActivities)) this.filterdConsentActivity[consent.indskr_consenttermsid.toString()] = [...this.filterdConsentActivity[consent.indskr_consenttermsid.toString()], ...productActivities];
            break;
          default :
            let channelActivities = consent.consentActivity.filter(activity => activity['indskr_consenttypeid'] == filter.type && activity['indskr_consentactivitytype'] == 100000000);
            if(!_.isEmpty(channelActivities)) {
              channelActivities.forEach((activity) => {
                if(activity['indskr_emailaddress'] == filter.label 
                  || activity['indskr_phone'] == filter.label 
                  || activity['indskr_faxnumber'] == filter.label
                  || activity['indskr_addressname'] == filter.label) this.filterdConsentActivity[consent.indskr_consenttermsid.toString()].push(activity);
              })
            }
        }
      })
      this.filterdConsentActivity[consent.indskr_consenttermsid.toString()] = _.uniqBy(this.filterdConsentActivity[consent.indskr_consenttermsid.toString()], 'indskr_consentactivityid')
    })
  }

  private _clearFilterResults() {
    this.isOptOutFiltered = false;
    this.selectedFilters = [];
    this.filterdConsentActivity = [];
    this.filteredActiveConsentsGrid = [];
    this.initViewModels();
    this._initFilterFormViews();
  }

}
