import { ChangeDetectorRef, Component, Input } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { IndHeaderLeftDataModel } from "@omni/models/indHeaderLeftDataModel";
import {IndSectionHeaderViewDataModel} from '@omni/models/indSectionHeaderDataModel';
import { DeviceService } from "@omni/services/device/device.service";
import { FooterService } from "@omni/services/footer/footer.service";
import * as _ from 'lodash';
import { ModalController, PopoverController } from '@ionic/angular';
import { NavigationService, PageName } from "@omni/services/navigation/navigation.service";
import { SelectedSuggestionPillDataModel, SuggestionPillType, UserSavedSearchTypes } from "@omni/models/search-config-data-model";
import { SearchConfigService } from "@omni/services/search/search-config.service";
import { OmniAccordionViewDataModel } from "@omni/models/omniAccordionViewDataModel";
import { AuthenticationService } from "@omni/services/authentication.service";
import { FeatureActionsMap } from "@omni/classes/authentication/user.class";
import { SearchConfigDataService } from "@omni/data-services/search-config/search-config-data-service";
import { AccountsModel } from "@omni/classes/account/account.class";
import { ContactsModel } from "@omni/classes/contact/contact.class";
import { IndDateTimeFormViewDataModel } from "@omni/models/indDateTimeFormDataModel";
import { CurViewPageType, DateTimeFieldType, IndDatetimeFormComponent } from "../ind-datetime-form/ind-datetime-form";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { ActivityService } from "@omni/services/activity/activity.service";
import { DateTimeFormatsService } from "@omni/services/date-time-formats/date-time-formats.service";
import { DatePipe } from "@angular/common";
import moment from "moment";
import { AlertService } from "@omni/services/alert/alert.service";
import { AgendaFooterService, AgendaFooterView } from "@omni/services/footer/agenda-footer.service";
import { UIService } from "@omni/services/ui/ui.service";
import { SurveyCategory, SurveyType } from "@omni/classes/customer-assessment/assessment-template.class";
import { isArray } from "lodash";
import { IndDropdownListDetailModel } from "@omni/models/indDropdownListModel";



const MIN_SEARCH_LENGTH = 1;


@Component({
  selector: 'ind-filter-menu',
  templateUrl: 'ind-filter-menu.html',
  styleUrls:['ind-filter-menu.scss']
})
export class IndFilterMenuComponent {
  @Input() viewData: any;
  @Input() selectedFilter: SelectedSuggestionPillDataModel[];
  @Input() from: any;
  @Input() subFrom: any;
  public filterMenuHeaderLeftModel: IndHeaderLeftDataModel;
  public filterMenuSectionHeader: IndSectionHeaderViewDataModel;
  public searchedFilterMenuSectionHeader: IndSectionHeaderViewDataModel;
  public searchInput: string;
  public readOnly = false;
  public isRightPaneNavActive = false;
  public searching = false;
  public categories: OmniAccordionViewDataModel[] = [];
  public searchedCategories: OmniAccordionViewDataModel[] = [];
  public selectedCategories: SelectedSuggestionPillDataModel[] = [];
  public tempSelectedcategories: SelectedSuggestionPillDataModel[] = [];
  public savedSelectedCategories: SelectedSuggestionPillDataModel[] = [];
  public startDateField: IndDateTimeFormViewDataModel;
  public endDateField: IndDateTimeFormViewDataModel;
  private startDateTimeValue: string;
  private startDateValue: string;
  private endDateTimeValue: string;
  private endDateValue: string;
  private _selectedDate: {startDate: string, endDate: string};
  public backgroundUploadInProgress: boolean = false;
  ngDestroy$: Subject<boolean> = new Subject<boolean>();
  public isDisplayDateField: boolean = false;
  public isUpdatedDate: boolean = false;
  public isSaveFilterEnabled: boolean = false;
  public savedFilters: OmniAccordionViewDataModel;
  private _isMyActivitiesDateField: boolean = false;
  public isSelectedMyActivity: boolean = false;
  public selectedCategory:OmniAccordionViewDataModel;
  public optionsList = [];
  public filteredOptionsList = [];
  public displayOptionsList = [];
  public isOptionsSearching:boolean = false;
  private recordCount: number = 30;

  constructor(
    public agendaFooterService: AgendaFooterService,
    public footerService: FooterService,
    public translate: TranslateService,
    public device: DeviceService,
    private searchConfigService: SearchConfigService,
    private readonly modalCtrl: ModalController,
    private readonly navService: NavigationService,
    public authService:AuthenticationService,
    public searchConfigDataService: SearchConfigDataService,
    private _cd: ChangeDetectorRef,
    public activityService: ActivityService,
    private popoverCtrl: PopoverController,
    public dateTimeFormatsService: DateTimeFormatsService,
    private datePipe: DatePipe,
    private alertService: AlertService,
    public uiService: UIService
  ){}

  ngOnInit() {
    if(!_.isEmpty(this.selectedFilter)) this.selectedCategories = _.cloneDeep(this.selectedFilter);
    if(!_.isEmpty(this.selectedCategories)) this.tempSelectedcategories = _.cloneDeep(this.selectedCategories);
    this._init();
    if((this.from == PageName.ContactPageComponent && this.subFrom == ContactsModel.MY_CONTACTS_TAB)
      || (this.from == PageName.AccountPageComponent && this.subFrom == AccountsModel.MY_ACCOUNTS_TAB)  || (this.from == PageName.PresentationPageComponent)) {
        this.isSaveFilterEnabled = true;
    }
    if (this.from != PageName.CustomerCallPlanPage) {
    if(this.isSaveFilterEnabled) {
      this._initSavedSearchDataModels(this._getToolSearchName(), false);
      this.agendaFooterService.initButtons(AgendaFooterView.FilterMenu);
      this._enableDisableFooterButtons();
      this.searchConfigService.savedSearchesObserver.pipe(takeUntil(this.ngDestroy$)).subscribe(savedSearches => {
        this._initSavedSearchDataModels(this._getToolSearchName());
        this.savedFilters.isExpanded = true;
      });
    }
  }
    this.device.isBackgroundUploadInProgressObservable.pipe(takeUntil(this.ngDestroy$)).subscribe(inProgress => {
      this.backgroundUploadInProgress = inProgress;
      this._cd.detectChanges();
    });

    this.searchConfigService.onToggleChildItemObserver.pipe(takeUntil(this.ngDestroy$)).subscribe(toggleChildItem => {
      if(toggleChildItem && this.searchConfigService.childUsersWithPositionsViewData) {
        this.toggleCategoryValue(this.searchConfigService.childUsersWithPositionsViewData,toggleChildItem);
        this.searchConfigService.childUsersWithPositionsViewData = null;
      }
    });
  }

  private _init(isClearFilter?: boolean) {
    this.uiService.isFilterActivated = true;
    if(this.from == PageName.ContactPageComponent) {  
      if(this.subFrom == ContactsModel.MY_CONTACTS_TAB) {
        this._isMyActivitiesDateField = true;
        this._selectedDate = _.cloneDeep(this.viewData.selectedDate);
        this.isUpdatedDate = !_.isEmpty(this._selectedDate) && !_.isEmpty(this._selectedDate.startDate) && !_.isEmpty(this._selectedDate.endDate);
        this._initDateFormFields(); 
        this._initAccordionViewForContact(isClearFilter);
      }else if(this.subFrom == ContactsModel.CHANGE_REQUESTS_TAB) {
        this._initAccordionViewForContactCR();
      }
    }else if(this.from == PageName.AccountPageComponent) {
      if(this.subFrom == AccountsModel.MY_ACCOUNTS_TAB) {
        this._initAccordionViewForAccount(isClearFilter);
      }else if(this.subFrom == AccountsModel.CHANGE_REQUESTS_TAB) {
        this._initAccordionViewForAccountCR();
      }
    } else if(this.from == PageName.TerritoryManagementDetailsComponent) {
      if(this.subFrom == ContactsModel.MY_CONTACTS_TAB) {
        this._initAccordionViewForContact();
      }
    }else if(this.from == PageName.SurveyPageComponent) {
      this._selectedDate = _.cloneDeep(this.viewData.selectedDate);
      this.isUpdatedDate = !_.isEmpty(this._selectedDate) && !_.isEmpty(this._selectedDate.startDate) && !_.isEmpty(this._selectedDate.endDate);
      this._initDateFormFields();  
      this.isDisplayDateField = this.viewData.isDisplayDateField;
      this._initAccordionViewForSurvey();
    }
    else if(this.from == PageName.CustomerCallPlanPage) {
      this._initAccordionViewForCallPlan(isClearFilter);
    } else if(this.from === PageName.MedicalInsightPageComponent){
      this._initAccordionViewForMedicalInsights(isClearFilter)
    } else if(this.from == PageName.PresentationPageComponent) {
      this._initAccordionViewForPresentation(isClearFilter);
    }
    else if(this.from == PageName.PresentationPageComponent) {
      this._initAccordionViewForPresentation(isClearFilter);
    }
    this._initFilterMenuHeaderLeft();
    this._initFilterMenuSectionHeader();
    
    //sort filter menu items
    if(!_.isEmpty(this.categories)) {
      const tempCategories = _.cloneDeep(this.categories);
      const sortedCategoriesWithFixedFilter = _.orderBy(tempCategories.filter(c=>c.isFixedFilter), ['filterOrder', 'primaryText'], ['asc','asc']);
      const sortedCategoriesWithoutFixedFilter = _.orderBy(tempCategories.filter(c=>!c.isFixedFilter), [category=>category.primaryText.toLowerCase()], ['asc']);
      this.categories = sortedCategoriesWithFixedFilter.concat(sortedCategoriesWithoutFixedFilter);
      //sort child items
      this.categories.map(c=>{
        if(!_.isEmpty(c.childItems)) {
          c.childItems = _.orderBy(c.childItems, ['label'], ['asc']);
        }
      });
    }
  }

  private _enableDisableFooterButtons() {
    if(!_.isEmpty(this.tempSelectedcategories) && !_.isEqual(this.savedSelectedCategories, this.tempSelectedcategories)) this.agendaFooterService.enableButtons(['saveFilter']);
    else this.agendaFooterService.disableButton(['saveFilter']);
  }

  ngOnDestroy() {
    this.uiService.isFilterActivated = false;
    this.isSaveFilterEnabled = false;
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }

  private _getToolSearchName() {
    let searchToolNameID , toolSearchName;
    if(this.from == PageName.ContactPageComponent) {
      if(this.subFrom == ContactsModel.MY_CONTACTS_TAB) {
        toolSearchName = this.searchConfigService.toolNames.find(o=>o.toolName=='Contacts');
      }else if(this.subFrom == ContactsModel.CHANGE_REQUESTS_TAB) {

      }
    }else if(this.from == PageName.AccountPageComponent) {
      if(this.subFrom == AccountsModel.MY_ACCOUNTS_TAB) {
        toolSearchName = this.searchConfigService.toolNames.find(o=>o.toolName=='Accounts');
      }else if(this.subFrom == AccountsModel.CHANGE_REQUESTS_TAB) {

      }
    } else if(this.from == PageName.TerritoryManagementDetailsComponent) {
      if(this.subFrom == ContactsModel.MY_CONTACTS_TAB) {

      }
    }else if(this.from == PageName.SurveyPageComponent) {
      

    }
    else if(this.from == PageName.PresentationPageComponent) {
      toolSearchName = this.searchConfigService.toolNames.find(o=>o.toolName=='Presentations'); 
    }
    searchToolNameID = toolSearchName?toolSearchName.searchToolNameID:'';
    this.searchConfigService.searchToolID = searchToolNameID;
    return searchToolNameID;  
  }
  
  _initAccordionViewForCallPlan(isClearFilter: boolean = false) {
    this.categories = [];
    const searchIndexConfig = this.searchConfigService.myCallPlansSearchIndexesConfig;
    searchIndexConfig.forEach((config) => {
      let accordionView: OmniAccordionViewDataModel;
      let existingCategoryIdx = -1;
      if(config.isThisToBeAggregated) {
        existingCategoryIdx = config.categoryDisplayName?this.categories.findIndex((cat) => cat.primaryText == config.categoryDisplayName):this.categories.findIndex((cat) => cat.primaryText == config.categoryName);
      }
      if(existingCategoryIdx<0) {
        let label;
        let selected = config.categoryDisplayName?this.tempSelectedcategories.filter((category) => category.categoryDisplayName == config.categoryDisplayName):this.tempSelectedcategories.filter((category) => category.categoryName == config.categoryName);
        if(!_.isEmpty(selected)) {
          label = config.categoryDisplayName?`${config.categoryDisplayName}(${selected.length})`:`${config.categoryName}(${selected.length})`;
        } else {
          label = config.categoryDisplayName?config.categoryDisplayName:config.categoryName;
        }
        accordionView = {
          displayType: 'Accordion',
          id: config.configuredFrom == 'Assess' ? config.categoryName : config.categoryRelativePath,
          primaryText: label,
          isExpanded: false,
          showExpandIcon: true,
          showCollapseIcon: true,
          isSearchable: true,
          searchActiveAt: 10,
          isSelectable: true,
          isAggregatedSection: config.isThisToBeAggregated,
          configuredFrom: config.configuredFrom,
          childItems: [],
          // clickHandler:(id?:string, event?:any, specificTarget?:string, dataRef?:any)=>void
        }
      } else {
        accordionView = this.categories[existingCategoryIdx];
      }
      if(!_.isEmpty(config.values)) {
        config.values.forEach((value) => {
          let categoryName = config.categoryDisplayName?config.categoryDisplayName:config.categoryName;
          let checked = this.tempSelectedcategories.some((filter) => filter.categoryName == categoryName && filter.selectedFacet == value);
          let idx =config.categoryDisplayName?accordionView.childItems?.findIndex((item) => item.id == 'filter_menu_'+config.categoryDisplayName+'_'+value):accordionView.childItems?.findIndex((item) => item.id == 'filter_menu_'+config.categoryName+'_'+value);
          if(idx<0) {
              accordionView.childItems.push({
              id: 'filter_menu_'+config.categoryName+'_'+value,
              label: value,
              value: '',
              isChecked: checked,
              type: config.isBooleanTypeCategory?SuggestionPillType.BOOLEAN_FIELD:SuggestionPillType.CATEGORY_VALUESEARCH
            })
          } else {
            accordionView.childItems[idx] = {
              id: 'filter_menu_'+config.categoryName+'_'+value,
              label: value,
              value: '',
              isChecked: checked,
              type: config.isBooleanTypeCategory?SuggestionPillType.BOOLEAN_FIELD:SuggestionPillType.CATEGORY_VALUESEARCH
            }
          }
          if(checked) {
            accordionView.isExpanded = true;
          }
        })
      } else {
        accordionView.showExpandIcon = false;
        accordionView.showCollapseIcon = false;
      }
      if(existingCategoryIdx<0) this.categories.push(accordionView);
      else this.categories[existingCategoryIdx] = accordionView;
    });
    let selected = this.tempSelectedcategories.filter((category) => (category.categoryPath == 'completed' || category.categoryPath == 'inprogress'));
    let label = !_.isEmpty(selected) ? `${this.translate.instant("STATUS")}(${selected.length})` : this.translate.instant("STATUS");
    let isCompletedChecked = this.tempSelectedcategories.some((filter) => filter.categoryPath == 'completed' && filter.selectedFacet == this.translate.instant("COMPLETED"));
    let isInprogressChecked = this.tempSelectedcategories.some((filter) => filter.categoryPath == 'inprogress' && filter.selectedFacet == this.translate.instant("INPROGRESS"));
    let statusAccordion: OmniAccordionViewDataModel = {
      displayType: 'Accordion',
      id: 'status',
      primaryText: label,
      isExpanded: isCompletedChecked || isInprogressChecked,
      showExpandIcon: true,
      showCollapseIcon: true,
      isSearchable: true,
      searchActiveAt: 10,
      isSelectable: true,
      childItems: [{
          id: "completed",
          label: this.translate.instant("COMPLETED"),
          value: '',
          isChecked: isCompletedChecked,
          type: SuggestionPillType.CATEGORY_VALUESEARCH,
        },
        {
          id: "inprogress",
          label: this.translate.instant("INPROGRESS"),
          value: '',
          isChecked: isInprogressChecked,
          type: SuggestionPillType.CATEGORY_VALUESEARCH,
        }
      ],
    }
    this.categories.push(statusAccordion);

    if(isClearFilter) {
      if(!this.searching && !_.isEmpty(this.categories)) {
        this.categories.forEach(cat => {
          cat?.childItems.map(c=>c.isChecked = false);
          cat.isExpanded = false;
        });
      }else if(this.searching && !_.isEmpty(this.categories) && !_.isEmpty(this.searchedCategories)) {
        this.searchedCategories.forEach(scat => {
          scat?.childItems.map(c=>c.isChecked = false);
          scat.primaryText = this.categories.find(cat => cat.id == scat.id).primaryText;
        });
        this.searchConfigDataService.filterMenuFilterClear.next();
      }
    }

  }


  _initAccordionViewForMedicalInsights(isClearFilter: boolean = false) {
    this.categories = [];
    const searchIndexConfig = this.searchConfigService.medicalInsightsSearchIndexsConfig;
    searchIndexConfig.forEach((config) => {
      let accordionView: OmniAccordionViewDataModel;
      let existingCategoryIdx = -1;
      if(config.isThisToBeAggregated) {
        existingCategoryIdx = config.categoryDisplayName?this.categories.findIndex((cat) => cat.primaryText == config.categoryDisplayName):this.categories.findIndex((cat) => cat.primaryText == config.categoryName);
      }
      if(existingCategoryIdx<0) {
        let label;
        let selected = config.categoryDisplayName?this.tempSelectedcategories.filter((category) => category.categoryDisplayName == config.categoryDisplayName):this.tempSelectedcategories.filter((category) => category.categoryName == config.categoryName);
        if(!_.isEmpty(selected)) {
          label = config.categoryDisplayName?`${config.categoryDisplayName}(${selected.length})`:`${config.categoryName}(${selected.length})`;
        } else {
          label = config.categoryDisplayName?config.categoryDisplayName:config.categoryName;
        }
        accordionView = {
          displayType: 'Accordion',
          id: config.configuredFrom == 'Assess' ? config.categoryName : config.categoryRelativePath,
          primaryText: label,
          isExpanded: false,
          showExpandIcon: true,
          showCollapseIcon: true,
          isSearchable: true,
          searchActiveAt: 10,
          isSelectable: true,
          isAggregatedSection: config.isThisToBeAggregated,
          configuredFrom: config.configuredFrom,
          childItems: [],
          // clickHandler:(id?:string, event?:any, specificTarget?:string, dataRef?:any)=>void
        }
      } else {
        accordionView = this.categories[existingCategoryIdx];
      }
      if(!_.isEmpty(config.values)) {
        config.values.forEach((value) => {
          let categoryName = config.categoryDisplayName?config.categoryDisplayName:config.categoryName;
          let checked = this.tempSelectedcategories.some((filter) => filter.categoryName == categoryName && filter.selectedFacet == value);
          let idx =config.categoryDisplayName?accordionView.childItems?.findIndex((item) => item.id == 'filter_menu_'+config.categoryDisplayName+'_'+value):accordionView.childItems?.findIndex((item) => item.id == 'filter_menu_'+config.categoryName+'_'+value);
          if(idx<0) {
              accordionView.childItems.push({
              id: 'filter_menu_'+config.categoryName+'_'+value,
              label: value,
              value: '',
              isChecked: checked,
              type: config.isBooleanTypeCategory?SuggestionPillType.BOOLEAN_FIELD:SuggestionPillType.CATEGORY_VALUESEARCH
            })
          } else {
            accordionView.childItems[idx] = {
              id: 'filter_menu_'+config.categoryName+'_'+value,
              label: value,
              value: '',
              isChecked: checked,
              type: config.isBooleanTypeCategory?SuggestionPillType.BOOLEAN_FIELD:SuggestionPillType.CATEGORY_VALUESEARCH
            }
          }
          if(checked) {
            accordionView.isExpanded = true;
          }
        })
      } else {
        accordionView.showExpandIcon = false;
        accordionView.showCollapseIcon = false;
      }
      if(existingCategoryIdx<0) this.categories.push(accordionView);
      else this.categories[existingCategoryIdx] = accordionView;
    });
    // let selected = this.tempSelectedcategories.filter((category) => (category.categoryPath == 'completed' || category.categoryPath == 'inprogress'));
    // let label = !_.isEmpty(selected) ? `${this.translate.instant("STATUS")}(${selected.length})` : this.translate.instant("STATUS");
    // let isCompletedChecked = this.tempSelectedcategories.some((filter) => filter.categoryPath == 'completed' && filter.selectedFacet == this.translate.instant("COMPLETED"));
    // let isInprogressChecked = this.tempSelectedcategories.some((filter) => filter.categoryPath == 'inprogress' && filter.selectedFacet == this.translate.instant("INPROGRESS"));
    // let statusAccordion: OmniAccordionViewDataModel = {
    //   displayType: 'Accordion',
    //   id: 'status',
    //   primaryText: label,
    //   isExpanded: isCompletedChecked || isInprogressChecked,
    //   showExpandIcon: true,
    //   showCollapseIcon: true,
    //   isSearchable: true,
    //   searchActiveAt: 10,
    //   isSelectable: true,
    //   childItems: [{
    //       id: "completed",
    //       label: this.translate.instant("COMPLETED"),
    //       value: '',
    //       isChecked: isCompletedChecked,
    //       type: SuggestionPillType.CATEGORY_VALUESEARCH,
    //     },
    //     {
    //       id: "inprogress",
    //       label: this.translate.instant("INPROGRESS"),
    //       value: '',
    //       isChecked: isInprogressChecked,
    //       type: SuggestionPillType.CATEGORY_VALUESEARCH,
    //     }
    //   ],
    // }
    // this.categories.push(statusAccordion);

    // if(isClearFilter) {
    //   if(!this.searching && !_.isEmpty(this.categories)) {
    //     this.categories.forEach(cat => {
    //       cat?.childItems.map(c=>c.isChecked = false);
    //       cat.isExpanded = false;
    //     });
    //   }else if(this.searching && !_.isEmpty(this.categories) && !_.isEmpty(this.searchedCategories)) {
    //     this.searchedCategories.forEach(scat => {
    //       scat?.childItems.map(c=>c.isChecked = false);
    //       scat.primaryText = this.categories.find(cat => cat.id == scat.id).primaryText;
    //     });
    //     this.searchConfigDataService.filterMenuFilterClear.next();
    //   }
    // }

  }

  _initAccordionViewForPresentation(isClearFilter: boolean = false) {
    this.categories = [];
    const searchIndexConfig = this.searchConfigService.presentationsSearchIndexesConfig.filter(pre =>pre.categoryName =='Products' || pre.categoryName =='Specialties' ||  pre.categoryName =='Category');
    searchIndexConfig.forEach((config) => {
      let accordionView: OmniAccordionViewDataModel;
      let existingCategoryIdx = -1;
      if(config.isThisToBeAggregated) {
        existingCategoryIdx = config.categoryDisplayName?this.categories.findIndex((cat) => cat.primaryText == config.categoryDisplayName):this.categories.findIndex((cat) => cat.primaryText == config.categoryName);
      }
      if(existingCategoryIdx<0) {
        let label;
        let selected = config.categoryDisplayName?this.tempSelectedcategories.filter((category) => category.categoryDisplayName == config.categoryDisplayName):this.tempSelectedcategories.filter((category) => category.categoryName == config.categoryName);
        if(!_.isEmpty(selected)) {
          label = config.categoryDisplayName?`${config.categoryDisplayName}(${selected.length})`:`${config.categoryName}(${selected.length})`;
        } else {
          label = config.categoryDisplayName?config.categoryDisplayName:config.categoryName;
        }
        accordionView = {
          displayType: 'Accordion',
          id:config.categoryRelativePath,
          primaryText: label,
          isExpanded: false,
          showExpandIcon: true,
          showCollapseIcon: true,
          isSearchable: true,
          searchActiveAt: 10,
          isSelectable: true,
          isAggregatedSection: config.isThisToBeAggregated,
          configuredFrom: config.configuredFrom,
          childItems: [],
          // clickHandler:(id?:string, event?:any, specificTarget?:string, dataRef?:any)=>void
        }
      } else {
        accordionView = this.categories[existingCategoryIdx];
      }
      if(!_.isEmpty(config.values)) {
        config.values.forEach((value) => {
          let categoryName = config.categoryDisplayName?config.categoryDisplayName:config.categoryName;
          let checked = this.tempSelectedcategories.some((filter) => filter.categoryName == categoryName && filter.selectedFacet == value);
          let idx =config.categoryDisplayName?accordionView.childItems?.findIndex((item) => item.id == 'filter_menu_'+config.categoryDisplayName+'_'+value):accordionView.childItems?.findIndex((item) => item.id == 'filter_menu_'+config.categoryName+'_'+value);
          if(idx<0) {
              accordionView.childItems.push({
              id: 'filter_menu_'+config.categoryName+'_'+value,
              label: value,
              value: '',
              isChecked: checked,
              type: config.isBooleanTypeCategory?SuggestionPillType.BOOLEAN_FIELD:SuggestionPillType.CATEGORY_VALUESEARCH
            })
          } else {
            accordionView.childItems[idx] = {
              id: 'filter_menu_'+config.categoryName+'_'+value,
              label: value,
              value: '',
              isChecked: checked,
              type: config.isBooleanTypeCategory?SuggestionPillType.BOOLEAN_FIELD:SuggestionPillType.CATEGORY_VALUESEARCH
            }
          }
          if(checked) {
            accordionView.isExpanded = true;
          }
        })
      } else {
        accordionView.showExpandIcon = false;
        accordionView.showCollapseIcon = false;
      }
      if(existingCategoryIdx<0) this.categories.push(accordionView);
      else this.categories[existingCategoryIdx] = accordionView;
    });

    if(isClearFilter) {
      if(!this.searching && !_.isEmpty(this.categories)) {
        this.categories.forEach(cat => {
          cat?.childItems.map(c=>c.isChecked = false);
          cat.isExpanded = false;
        });
      }else if(this.searching && !_.isEmpty(this.categories) && !_.isEmpty(this.searchedCategories)) {
        this.searchedCategories.forEach(scat => {
          scat?.childItems.map(c=>c.isChecked = false);
          scat.primaryText = this.categories.find(cat => cat.id == scat.id).primaryText;
        });
        this.searchConfigDataService.filterMenuFilterClear.next();
      }
    }

  }

  private _initFilterMenuHeaderLeft(): void {
    let buttons = [];
    buttons.push({
      id: "cancel",
      cssClass: 'seventyPercentWidth',
      imgSrc: 'assets/imgs/header_cancel.svg',
      isDisabled: false,
      align: "left",
    },
    );
    if (!this.readOnly) {
      buttons.push({
        id: "done",
        cssClass: 'seventyPercentWidth',
        imgSrc: 'assets/imgs/header_complete.svg',
        isDisabled: !this.readOnly ? this.noChanges : true,
        align: "right",
      });
    }
    this.filterMenuHeaderLeftModel = {
      id: 'filter-menu-header-left',
      cssClass: 'main-tool-header-title',
      title: this.translate.instant('FILTERS'),
      mode: false,
      customHeaderProps: { hasCancel: true },
      controls: buttons,
      isReadOnly: this.readOnly
    };
  }

  private _isDisabledClearFilter(): boolean {
    let isDisabled: boolean = false;
    isDisabled = _.isEmpty(this.tempSelectedcategories) ? true : false;
    
    if(this.isDisplayDateField) {
      if(!_.isEmpty(this._selectedDate) && !_.isEmpty(this._selectedDate.startDate) && !_.isEmpty(this._selectedDate.endDate)) {
        isDisabled = false;
      }
    }
    return isDisabled;
  }

  private _initFilterMenuSectionHeader() {
    let buttons = [];
    buttons.push({
      id: "clear-filter",
      text: this.translate.instant('CLEAR_FILTER'),
      isDisabled: this._isDisabledClearFilter(),
      align: "right"
    });
    //check date field
    let selectedItemCount: number = this.tempSelectedcategories?.length || 0;
    if(this.isDisplayDateField && !_.isEmpty(this._selectedDate) && !_.isEmpty(this._selectedDate.startDate) && !_.isEmpty(this._selectedDate.endDate)) {
      selectedItemCount = selectedItemCount + 1;
    }
    this.filterMenuSectionHeader = {
      id: 'filter-menu-section-header',
      title: selectedItemCount > 0 ? `${this.translate.instant("ALL_FILTERS")} (${selectedItemCount})`: this.translate.instant("ALL_FILTERS"),
      controls: buttons
    }
    this.searchedFilterMenuSectionHeader = {
      id: 'searched-filter-menu-section-header',
      title: !_.isEmpty(this.searchedCategories)?`${this.translate.instant("AL_RESULTS")} (${this.searchedCategories.length})`: this.translate.instant("AL_RESULTS"),
      controls: buttons
    }
  }

  private _initDateFormFields() {
    let dateTimeValue = this.getFormattedDateTimeText();
    if (dateTimeValue) {
      this.startDateTimeValue = dateTimeValue.startDateTime != undefined ? dateTimeValue.startDateTime : undefined;
      this.startDateValue = dateTimeValue.startDate != undefined ? dateTimeValue.startDate : undefined;
      this.endDateTimeValue = dateTimeValue.endDateTime != undefined ? dateTimeValue.endDateTime : undefined;
      this.endDateValue = dateTimeValue.endDate != undefined ? dateTimeValue.endDate : undefined;
    }
    this.startDateField = {
      isDateField: true,
      label: this.translate.instant('DATE_FROM'),
      inputText: this.startDateValue != undefined ? this.startDateValue : '',
      customPlaceholderLabel: this.translate.instant('SELECT_DATE'),
      startDateTimeValue: this.startDateTimeValue,
      endDateTimeValue: this.endDateTimeValue,
      fromViewPage: CurViewPageType.FilterMenuModal,
      id: DateTimeFieldType.StartDateField,
      isReadOnly: true,
      isDisabled: this.backgroundUploadInProgress,
      showArrow: true,
      isRequired: false,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    this.endDateField = {
      isDateField: true,
      label: this.translate.instant('DATE_TO'),
      inputText: this.endDateValue != undefined ? this.endDateValue : '',
      customPlaceholderLabel: this.translate.instant('SELECT_DATE'),
      startDateTimeValue: this.startDateTimeValue,
      endDateTimeValue: this.endDateTimeValue,
      fromViewPage: CurViewPageType.FilterMenuModal,
      id: DateTimeFieldType.EndDateField,
      isReadOnly: true,
      isDisabled: this.backgroundUploadInProgress,
      showArrow: true,
      isRequired: false,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
  }

  private _initAccordionViewForContact(isClearFilter: boolean = false) {
    this.categories = [];
    const searchIndexConfig = this.from === PageName.TerritoryManagementDetailsComponent ? this.searchConfigService.customerPositionListContactsConfiguredSearchIndexConfig : this.searchConfigService.contactConfiguredSearchIndexConfig;
    searchIndexConfig.forEach((config) => {
      if(config.values.length > 0) {
        let accordionView: OmniAccordionViewDataModel;
        let existingCategoryIdx = -1;
        if(config.isThisToBeAggregated) {
          existingCategoryIdx = config.categoryDisplayName?this.categories.findIndex((cat) => cat.primaryText == config.categoryDisplayName):this.categories.findIndex((cat) => cat.primaryText == config.categoryName);
        }
        if(existingCategoryIdx<0) {
          let label;
          let selected = config.categoryDisplayName?this.tempSelectedcategories.filter((category) => category.categoryDisplayName == config.categoryDisplayName):this.tempSelectedcategories.filter((category) => category.categoryName == config.categoryName);
          if(!_.isEmpty(selected)) {
            label = config.categoryDisplayName?`${config.categoryDisplayName}(${selected.length})`:`${config.categoryName}(${selected.length})`;
          } else {
            label = config.categoryDisplayName?config.categoryDisplayName:config.categoryName;
          }
          const isSubCategory: boolean = config.isSubCategory || false;
  
          accordionView = {
            displayType: 'Accordion',
            id: config.configuredFrom == 'Assess' ? config.categoryName : config.categoryRelativePath,
            primaryText: label,
            isExpanded: false,
            showExpandIcon: true,
            showCollapseIcon: true,
            isSearchable: false,
            searchActiveAt: 20,
            isSelectable: true,
            isAggregatedSection: config.isThisToBeAggregated,
            configuredFrom: config.configuredFrom,
            childItems: [],
            isFixedFilter: config.isFixedFilter || false,
            filterOrder: config.filterOrder || '',
            isForcedHide: config.isForcedHide,
            isSubCategory: isSubCategory
          }
        } else {
          accordionView = this.categories[existingCategoryIdx];
        }
        if(!_.isEmpty(config.values)) {
          const valuesToMapped = (config.values.length > 20) ? config.values.slice(0,19) : config.values;
          valuesToMapped.forEach((value) => {
            let categoryName = config.categoryDisplayName?config.categoryDisplayName:config.categoryName;
            let checked = this.tempSelectedcategories.some((filter) => (filter.categoryName == categoryName || filter.categoryDisplayName == categoryName) && filter.selectedFacet == value);
            let idx =config.categoryDisplayName?accordionView.childItems?.findIndex((item) => item.id == 'filter_menu_'+config.categoryDisplayName+'_'+value):accordionView.childItems?.findIndex((item) => item.id == 'filter_menu_'+config.categoryName+'_'+value);
            if(idx<0) {
                accordionView.childItems.push({
                id: 'filter_menu_'+config.categoryName+'_'+value,
                label: value,
                value: '',
                isChecked: checked,
                type: config.isBooleanTypeCategory?SuggestionPillType.BOOLEAN_FIELD:SuggestionPillType.CATEGORY_VALUESEARCH
              })
            } else {
              accordionView.childItems[idx] = {
                id: 'filter_menu_'+config.categoryName+'_'+value,
                label: value,
                value: '',
                isChecked: checked,
                type: config.isBooleanTypeCategory?SuggestionPillType.BOOLEAN_FIELD:SuggestionPillType.CATEGORY_VALUESEARCH
              }
            }
            if(checked) {
              accordionView.isExpanded = true;
            }
          })
          if(config.values.length > 20){
            accordionView.showMoreButtonEnabled = true;
          }
        } else {
          accordionView.showExpandIcon = false;
          accordionView.showCollapseIcon = false;
        }
        if(existingCategoryIdx<0) this.categories.push(accordionView);
        else this.categories[existingCategoryIdx] = accordionView;
      } //end of if(config.values.length > 0)
      else if(config.mappingValues.length > 0) {
        let accordionView: OmniAccordionViewDataModel;
        let existingCategoryIdx = -1;
        if(config.isThisToBeAggregated) {
          existingCategoryIdx = config.categoryDisplayName?this.categories.findIndex((cat) => cat.primaryText == config.categoryDisplayName):this.categories.findIndex((cat) => cat.primaryText == config.categoryName);
        }
        if(existingCategoryIdx<0) {
          let label;
          let selected = config.categoryDisplayName?this.tempSelectedcategories.filter((category) => category.categoryDisplayName == config.categoryDisplayName):this.tempSelectedcategories.filter((category) => category.categoryName == config.categoryName);
          if(!_.isEmpty(selected)) {
            label = config.categoryDisplayName?`${config.categoryDisplayName}(${selected.length})`:`${config.categoryName}(${selected.length})`;
          } else {
            label = config.categoryDisplayName?config.categoryDisplayName:config.categoryName;
          }
          const isSubCategory: boolean = config.isSubCategory || false;
  
          accordionView = {
            displayType: 'Accordion',
            id: config.configuredFrom == 'Assess' ? config.categoryName : config.categoryRelativePath,
            primaryText: label,
            isExpanded: false,
            showExpandIcon: true,
            showCollapseIcon: true,
            isSearchable: true,
            searchActiveAt: 10,
            isSelectable: true,
            isAggregatedSection: config.isThisToBeAggregated,
            configuredFrom: config.configuredFrom,
            childItems: [],
            isFixedFilter: config.isFixedFilter || false,
            filterOrder: config.filterOrder || '',
            isForcedHide: config.isForcedHide,
            isSubCategory: isSubCategory,
            isSingleSelect: config.isSingleSelect
          }
        } else {
          accordionView = this.categories[existingCategoryIdx];
        }
        if(!_.isEmpty(config.mappingValues)) {
          const valuesToMapped = (config.mappingValues.length > 20) ? config.mappingValues.slice(0,19) : config.mappingValues;

          if(config.categoryName === 'Teamusers') {
            let isAccordianChecked = this.tempSelectedcategories.find((filter) => (filter.categoryName == 'Teamusers') || (filter.categoryName == 'Team Users'));
            
            if(isAccordianChecked) {
              accordionView.childItems = this.searchConfigDataService.childUsersWithPositions;
              accordionView.isExpanded = true;
            } else {
              // accordionView.isExpanded = false;
              this.searchConfigDataService.unCheckAllChildUsersWithPositions();

              accordionView.childItems = this.searchConfigDataService.childUsersWithPositions;
            }
          } else {
            valuesToMapped.forEach((value) => {
              let categoryName = config.categoryDisplayName;
              let isAccordianChecked = this.tempSelectedcategories.find((filter) => (filter.categoryName == categoryName));
              if(isAccordianChecked) {
                accordionView.childItems.push({
                  id: 'filter_menu_'+config.categoryName+'_'+value.formattedValue,
                  label: value.formattedValue,
                  value: value.actualValue,
                  isChecked: isAccordianChecked.selectedFacet === value.formattedValue,
                  type: config.isBooleanTypeCategory?SuggestionPillType.BOOLEAN_FIELD:SuggestionPillType.CATEGORY_VALUESEARCH,
                  showArrow:true,
                  arrowType: 'chevron-down-outline',
                  category:config.categoryName
                 })
              } else {
                accordionView.childItems.push({
                  id: 'filter_menu_'+config.categoryName+'_'+value.formattedValue,
                  label: value.formattedValue,
                  value: value.actualValue,
                  isChecked: false,
                  type: config.isBooleanTypeCategory?SuggestionPillType.BOOLEAN_FIELD:SuggestionPillType.CATEGORY_VALUESEARCH,
                  showArrow:true,
                  arrowType: 'chevron-down-outline',
                  category:config.categoryName
                 })
                }

                if(isAccordianChecked) {
                  accordionView.isExpanded = true;
                } 
              });
          }          
          if(config.mappingValues.length > 20){
            accordionView.showMoreButtonEnabled = true;
          }
        } else {
          accordionView.showExpandIcon = false;
          accordionView.showCollapseIcon = false;
        }
        if(existingCategoryIdx<0) this.categories.push(accordionView);
        else this.categories[existingCategoryIdx] = accordionView;
      }; //end of if(config.mappingValues.length > 0)
    });
    if (this.authService.hasFeatureAction(FeatureActionsMap.CUSTOMER_ASSESS)) {
      let selected = this.tempSelectedcategories.filter((category) => (category.categoryPath == 'Assessed' || category.categoryPath == 'UnAssessed'));
      let label = !_.isEmpty(selected) ? `${this.translate.instant("ASSESSMENTS")}(${selected.length})` : this.translate.instant("ASSESSMENTS");
      let isAssessedChecked = this.tempSelectedcategories.some((filter) => filter.categoryPath == 'Assessed' && filter.selectedFacet == this.translate.instant("ASSESSED"));
      let isUnAssessedChecked = this.tempSelectedcategories.some((filter) => filter.categoryPath == 'UnAssessed' && filter.selectedFacet == this.translate.instant("UNASSESSED"));
      let assessedAccordion: OmniAccordionViewDataModel = {
        displayType: 'Accordion',
        id: 'Assessments',
        primaryText: label,
        isExpanded: isAssessedChecked || isUnAssessedChecked,
        showExpandIcon: true,
        showCollapseIcon: true,
        isSearchable: true,
        searchActiveAt: 10,
        isSelectable: true,
        childItems: [{
            id: "assessed",
            label: this.translate.instant("ASSESSED"),
            value: '',
            isChecked: isAssessedChecked,
            type: SuggestionPillType.CATEGORY_VALUESEARCH,
          },
          {
            id: "unAssessed",
            label: this.translate.instant("UNASSESSED"),
            value: '',
            isChecked: isUnAssessedChecked,
            type: SuggestionPillType.CATEGORY_VALUESEARCH,
          }
        ],
      }
      this.categories.push(assessedAccordion);
    }
    //Clear filter--------------------------------------------------------------------------------------------------------
    if(isClearFilter) {
      this._selectedDate = { startDate: '', endDate: '' };
      this._initDateFormFields();
      if(!this.searching && !_.isEmpty(this.categories)) {
        this.categories.forEach(cat => {
          cat?.childItems.map(c=>c.isChecked = false);
          cat.isExpanded = false;
        });
      }else if(this.searching && !_.isEmpty(this.categories) && !_.isEmpty(this.searchedCategories)) {
        this.searchedCategories.forEach(scat => {
          scat?.childItems.map(c=>c.isChecked = false);
          scat.primaryText = this.categories.find(cat => cat.id == scat.id).primaryText;
        });
        this.searchConfigDataService.filterMenuFilterClear.next();
      }
    }
  }

  private _initAccordionViewForContactCR(isClearFilter: boolean = false) {
    this.categories = [];
    let accordionViewStatus:OmniAccordionViewDataModel;
    let accordionViewRequestType:OmniAccordionViewDataModel;
    let accordionViewSourceType:OmniAccordionViewDataModel;
    let labelStatus, labelRequestType, labelSourceType;
    // add status filter
    let selectedStatus = this.tempSelectedcategories.filter((category) => category.categoryPath == 'contactCR_status');
    if(!_.isEmpty(selectedStatus)) {
      labelStatus = `${this.translate.instant('STATUS')}(${selectedStatus.length})`;
    } else {
      labelStatus = this.translate.instant('STATUS');
    }
    accordionViewStatus = {
      displayType: 'Accordion',
      id: 'contactCR_status',
      primaryText: labelStatus,
      isExpanded: false,
      showExpandIcon: true,
      showCollapseIcon: true,
      isSearchable: false,
      isSelectable: true,
      isAggregatedSection: false,
      childItems: []
    };
    this.viewData.data.find(d => d.text == this.translate.instant('STATUS')).items.forEach((item) => {
      let checked = this.selectedFilter.some((filter) => filter.categoryName == this.translate.instant('STATUS') && filter.selectedFacet == item.text);
      accordionViewStatus.childItems.push({
        id: 'filter_menu_status' + '_' + item.value,
        label: item.text,
        value: item.value,
        isChecked: checked,
        type: 'general'
      })
      if(checked) accordionViewStatus.isExpanded = true;
    });
    this.categories.push(accordionViewStatus);
    // add request type filter
    let selectedRequestType = this.tempSelectedcategories.filter((category) => category.categoryPath == 'contactCR_request_type');
    if(!_.isEmpty(selectedRequestType)) {
      labelRequestType = `${this.translate.instant('REQUEST_TYPE')}(${selectedRequestType.length})`;
    } else {
      labelRequestType = this.translate.instant('REQUEST_TYPE');
    }
    accordionViewRequestType = {
      displayType: 'Accordion',
      id: 'contactCR_request_type',
      primaryText: labelRequestType,
      isExpanded: false,
      showExpandIcon: true,
      showCollapseIcon: true,
      isSearchable: false,
      isSelectable: true,
      isAggregatedSection: false,
      childItems: []
    }
    this.viewData.data.find(d => d.text == this.translate.instant('REQUEST_TYPE')).items.forEach((item) => {
      let checked = this.selectedFilter.some((filter) => filter.categoryName == this.translate.instant('REQUEST_TYPE') && filter.selectedFacet == item.text);
      accordionViewRequestType.childItems.push({
        id: 'filter_menu_request_type' + '_' + item.value,
        label: item.text,
        value: item.value,
        isChecked: checked,
        type: 'general'
      })
      if(checked) accordionViewRequestType.isExpanded = true;
    });
    this.categories.push(accordionViewRequestType);
    // add source type filter
    let selectedSourceType = this.tempSelectedcategories.filter((category) => category.categoryPath == 'contactCR_source_type');
    if(!_.isEmpty(selectedSourceType)) {
      labelSourceType = `${this.translate.instant('SOURCE_TYPE')}(${selectedSourceType.length})`;
    } else {
      labelSourceType = this.translate.instant('SOURCE_TYPE');
    }
    accordionViewSourceType = {
      displayType: 'Accordion',
      id: 'contactCR_source_type',
      primaryText: labelSourceType,
      isExpanded: false,
      showExpandIcon: true,
      showCollapseIcon: true,
      isSearchable: false,
      isSelectable: true,
      isAggregatedSection: false,
      childItems: [],
    }
    let foundSourceTypeData = this.viewData.data.find(d => d.text == this.translate.instant('SOURCE_TYPE'));
    if(!_.isEmpty(foundSourceTypeData)) {
      foundSourceTypeData.items.forEach((item) => {
        let checked = this.selectedFilter.some((filter) => filter.categoryName == this.translate.instant('SOURCE_TYPE') && filter.selectedFacet == item.text);
        accordionViewSourceType.childItems.push({
          id: 'filter_menu_source_type' + '_' + item.value,
          label: item.text,
          value: item.value,
          isChecked: checked,
          type: 'general'
        })
        if(checked) accordionViewSourceType.isExpanded = true;
      });
      this.categories.push(accordionViewSourceType);
    }

    if(isClearFilter) {
      if(!this.searching && !_.isEmpty(this.categories)) {
        this.categories.forEach(cat => {
          cat?.childItems.map(c=>c.isChecked = false);
          cat.isExpanded = false;
        });
      }else if(this.searching && !_.isEmpty(this.categories) && !_.isEmpty(this.searchedCategories)) {
        this.searchedCategories.forEach(scat => {
          scat?.childItems.map(c=>c.isChecked = false);
          scat.primaryText = this.categories.find(cat => cat.id == scat.id).primaryText;
        });
        this.searchConfigDataService.filterMenuFilterClear.next();
      }
    }
  }

  private _initAccordionViewForAccount(isClearFilter: boolean = false) {
    this.categories = [];
    this.searchConfigService.accountConfiguredSearchIndexConfig.forEach((config) => {
      let accordionView: OmniAccordionViewDataModel;
      let existingCategoryIdx = -1;
      if(config.isThisToBeAggregated) {
        existingCategoryIdx = config.categoryDisplayName?this.categories.findIndex((cat) => cat.primaryText == config.categoryDisplayName):this.categories.findIndex((cat) => cat.primaryText == config.categoryName);
      }
      if(existingCategoryIdx<0) {
        let label;
        let selected = config.categoryDisplayName?this.tempSelectedcategories.filter((category) => category.categoryDisplayName == config.categoryDisplayName):this.tempSelectedcategories.filter((category) => category.categoryName == config.categoryName);
        if(!_.isEmpty(selected)) {
          label = config.categoryDisplayName?`${config.categoryDisplayName}(${selected.length})`:`${config.categoryName}(${selected.length})`;
        } else {
          label = config.categoryDisplayName?config.categoryDisplayName:config.categoryName;
        }
        accordionView = {
          displayType: 'Accordion',
          id: config.configuredFrom == 'Assess' ? config.categoryName : config.categoryRelativePath,
          primaryText: label,
          isExpanded: false,
          showExpandIcon: true,
          showCollapseIcon: true,
          isSearchable: true,
          searchActiveAt: 10,
          isSelectable: true,
          isAggregatedSection: config.isThisToBeAggregated,
          configuredFrom: config.configuredFrom,
          childItems: [],
        }
      } else {
        accordionView = this.categories[existingCategoryIdx];
      }
      if(!_.isEmpty(config.values)) {
        config.values.forEach((value) => {
          let categoryName = config.categoryDisplayName?config.categoryDisplayName:config.categoryName;
          let checked = this.tempSelectedcategories.some((filter) => filter.categoryName == categoryName && filter.selectedFacet == value);
          let idx = config.categoryDisplayName?accordionView.childItems?.findIndex((item) => item.id == 'filter_menu_'+config.categoryDisplayName+'_'+value):accordionView.childItems?.findIndex((item) => item.id == 'filter_menu_'+config.categoryName+'_'+value);
          if(idx<0) {
              accordionView.childItems.push({
              id: 'filter_menu_'+config.categoryName+'_'+value,
              label: value,
              value: '',
              isChecked: checked,
              type: config.isBooleanTypeCategory?SuggestionPillType.BOOLEAN_FIELD:SuggestionPillType.CATEGORY_VALUESEARCH
            })
          } else {
            accordionView.childItems[idx] = {
              id: 'filter_menu_'+config.categoryName+'_'+value,
              label: value,
              value: '',
              isChecked: checked,
              type: config.isBooleanTypeCategory?SuggestionPillType.BOOLEAN_FIELD:SuggestionPillType.CATEGORY_VALUESEARCH
            }
          }
          if(checked) {
            accordionView.isExpanded = true;
          }
        })
      } else if(!_.isEmpty(config.mappingValues)) {
        const valuesToMapped = (config.mappingValues.length > 20) ? config.mappingValues.slice(0,19) : config.mappingValues;

        if(config.categoryName === 'Teamusers') {
          let isAccordianChecked = this.tempSelectedcategories.find((filter) => (filter.categoryName == 'Teamusers') || (filter.categoryName == 'Team Users'));
          accordionView.childItems = this.searchConfigDataService.childUsersWithPositions;
          
          if(isAccordianChecked) {
            accordionView.isExpanded = true;
          } 
        } else {
          valuesToMapped.forEach((value) => {
              let categoryName = config.categoryDisplayName;
              let isAccordianChecked = this.tempSelectedcategories.find((filter) => (filter.categoryName == categoryName));
              if(isAccordianChecked) {
                accordionView.childItems.push({
                  id: 'filter_menu_'+config.categoryName+'_'+value.formattedValue,
                  label: value.formattedValue,
                  value: value.actualValue,
                  isChecked: isAccordianChecked.selectedFacet === value.formattedValue,
                  type: config.isBooleanTypeCategory?SuggestionPillType.BOOLEAN_FIELD:SuggestionPillType.CATEGORY_VALUESEARCH,
                  showArrow:true,
                  arrowType: 'chevron-down-outline',
                  category:config.categoryName
                })
              } else {
                accordionView.childItems.push({
                  id: 'filter_menu_'+config.categoryName+'_'+value.formattedValue,
                  label: value.formattedValue,
                  value: value.actualValue,
                  isChecked: false,
                  type: config.isBooleanTypeCategory?SuggestionPillType.BOOLEAN_FIELD:SuggestionPillType.CATEGORY_VALUESEARCH,
                  showArrow:true,
                  arrowType: 'chevron-down-outline',
                  category:config.categoryName
                })
                }

                if(isAccordianChecked) {
                  accordionView.isExpanded = true;
                } 
              });
          }          
          if(config.mappingValues.length > 20){
            accordionView.showMoreButtonEnabled = true;
        }
      } //end of if(config.mappingValues.length > 0)
      else {
        accordionView.showExpandIcon = false;
        accordionView.showCollapseIcon = false;
      }
      if(existingCategoryIdx<0) this.categories.push(accordionView);
      else this.categories[existingCategoryIdx] = accordionView;
    });

    if (this.authService.hasFeatureAction(FeatureActionsMap.CUSTOMER_ASSESS)) {
      let selected = this.tempSelectedcategories.filter((category) => (category.categoryPath == 'Assessed' || category.categoryPath == 'UnAssessed'));
      let label = !_.isEmpty(selected) ? `${this.translate.instant("ASSESSMENTS")}(${selected.length})` : this.translate.instant("ASSESSMENTS");
      let isAssessedChecked = this.tempSelectedcategories.some((filter) => filter.categoryPath == 'Assessed' && filter.selectedFacet == this.translate.instant("ASSESSED"));
      let isUnAssessedChecked = this.tempSelectedcategories.some((filter) => filter.categoryPath == 'UnAssessed' && filter.selectedFacet == this.translate.instant("UNASSESSED"));
      let assessedAccordion: OmniAccordionViewDataModel = {
        displayType: 'Accordion',
        id: 'Assessments',
        primaryText: label,
        isExpanded: isAssessedChecked || isUnAssessedChecked,
        showExpandIcon: true,
        showCollapseIcon: true,
        isSearchable: true,
        searchActiveAt: 10,
        isSelectable: true,
        childItems: [{
            id: "assessed",
            label: this.translate.instant("ASSESSED"),
            value: '',
            isChecked: isAssessedChecked,
            type: SuggestionPillType.CATEGORY_VALUESEARCH,
          },
          {
            id: "unAssessed",
            label: this.translate.instant("UNASSESSED"),
            value: '',
            isChecked: isUnAssessedChecked,
            type: SuggestionPillType.CATEGORY_VALUESEARCH,
          }
        ],
      }
      this.categories.push(assessedAccordion);
    }
    if(isClearFilter && this.searching && !_.isEmpty(this.categories) && !_.isEmpty(this.searchedCategories)) {
      this.searchedCategories.forEach(scat => {
        scat?.childItems.map(c=>c.isChecked = false);
        scat.primaryText = this.categories.find(cat => cat.id == scat.id).primaryText;
      });
      this.searchConfigDataService.filterMenuFilterClear.next();
    }
  }

  private _initAccordionViewForAccountCR(isClearFilter: boolean = false) {
    this.categories = [];
    let accordionViewStatus:OmniAccordionViewDataModel;
    let accordionViewRequestType:OmniAccordionViewDataModel;
    let accordionViewSourceType:OmniAccordionViewDataModel;
    let labelStatus, labelRequestType, labelSourceType;
    // add status filter
    let selectedStatus = this.tempSelectedcategories.filter((category) => category.categoryPath == 'accountCR_status');
    if(!_.isEmpty(selectedStatus)) {
      labelStatus = `${this.translate.instant('STATUS')}(${selectedStatus.length})`;
    } else {
      labelStatus = this.translate.instant('STATUS');
    }
    accordionViewStatus = {
      displayType: 'Accordion',
      id: 'accountCR_status',
      primaryText: labelStatus,
      isExpanded: false,
      showExpandIcon: true,
      showCollapseIcon: true,
      isSearchable: false,
      isSelectable: true,
      isAggregatedSection: false,
      childItems: []
    };
    this.viewData.data.find(d => d.text == this.translate.instant('STATUS')).items.forEach((item) => {
      let checked = this.selectedFilter.some((filter) => filter.categoryName == this.translate.instant('STATUS') && filter.selectedFacet == item.text);
      accordionViewStatus.childItems.push({
        id: 'filter_menu_status' + '_' + item.value,
        label: item.text,
        value: item.value,
        isChecked: checked,
        type: 'general'
      })
      if(checked) accordionViewStatus.isExpanded = true;
    });
    this.categories.push(accordionViewStatus);
    // add request type filter
    let selectedRequestType = this.tempSelectedcategories.filter((category) => category.categoryPath == 'accountCR_request_type');
    if(!_.isEmpty(selectedRequestType)) {
      labelRequestType = `${this.translate.instant('REQUEST_TYPE')}(${selectedRequestType.length})`;
    } else {
      labelRequestType = this.translate.instant('REQUEST_TYPE');
    }
    accordionViewRequestType = {
      displayType: 'Accordion',
      id: 'accountCR_request_type',
      primaryText: labelRequestType,
      isExpanded: false,
      showExpandIcon: true,
      showCollapseIcon: true,
      isSearchable: false,
      isSelectable: true,
      isAggregatedSection: false,
      childItems: []
    }
    this.viewData.data.find(d => d.text == this.translate.instant('REQUEST_TYPE')).items.forEach((item) => {
      let checked = this.selectedFilter.some((filter) => filter.categoryName == this.translate.instant('REQUEST_TYPE') && filter.selectedFacet == item.text);
      accordionViewRequestType.childItems.push({
        id: 'filter_menu_request_type' + '_' + item.value,
        label: item.text,
        value: item.value,
        isChecked: checked,
        type: 'general'
      })
      if(checked) accordionViewRequestType.isExpanded = true;
    });
    this.categories.push(accordionViewRequestType);
    // add source type filter
    let selectedSourceType = this.tempSelectedcategories.filter((category) => category.categoryPath == 'contactCR_source_type');
    if(!_.isEmpty(selectedSourceType)) {
      labelSourceType = `${this.translate.instant('SOURCE_TYPE')}(${selectedSourceType.length})`;
    } else {
      labelSourceType = this.translate.instant('SOURCE_TYPE');
    }
    accordionViewSourceType = {
      displayType: 'Accordion',
      id: 'accountCR_source_type',
      primaryText: labelSourceType,
      isExpanded: false,
      showExpandIcon: true,
      showCollapseIcon: true,
      isSearchable: false,
      isSelectable: true,
      isAggregatedSection: false,
      childItems: [],
    }
    let foundSourceTypeData = this.viewData.data.find(d => d.text == this.translate.instant('SOURCE_TYPE'));
    if(!_.isEmpty(foundSourceTypeData)) {
      foundSourceTypeData.items.forEach((item) => {
        let checked = this.selectedFilter.some((filter) => filter.categoryName == this.translate.instant('SOURCE_TYPE') && filter.selectedFacet == item.text);
        accordionViewSourceType.childItems.push({
          id: 'filter_menu_source_type' + '_' + item.value,
          label: item.text,
          value: item.value,
          isChecked: checked,
          type: 'general'
        })
        if(checked) accordionViewSourceType.isExpanded = true;
      });
      this.categories.push(accordionViewSourceType);
    }

    if(isClearFilter) {
      if(!this.searching && !_.isEmpty(this.categories)) {
        this.categories.forEach(cat => {
          cat?.childItems.map(c=>c.isChecked = false);
          cat.isExpanded = false;
        });
      }else if(this.searching && !_.isEmpty(this.categories) && !_.isEmpty(this.searchedCategories)) {
        this.searchedCategories.forEach(scat => {
          scat?.childItems.map(c=>c.isChecked = false);
          scat.primaryText = this.categories.find(cat => cat.id == scat.id).primaryText;
        });
        this.searchConfigDataService.filterMenuFilterClear.next();
      }
    }
  }

  get noChanges(): boolean {
    let noChanges = _.isEqual(this.selectedCategories, this.tempSelectedcategories);
    if(this.isDisplayDateField || this._isMyActivitiesDateField) {
      if(!_.isEmpty(this._selectedDate) && !_.isEmpty(this._selectedDate.startDate) && !_.isEmpty(this._selectedDate.startDate)) {
        noChanges = false;
      }else {
        if(this.isUpdatedDate) noChanges = false;
      }
    } 
    return noChanges
  }

  public onPageTitleControlClick(id: string) {
    switch (id) {
      case 'cancel':
        this.goBack(false);
        break;
      case 'done':
        this.goBack(true);
        break;
      default:
        console.log("Unhandled switch case statement");
        break;
    }
  }

  public onSectionHeaderControlClick(id: string) {
    switch(id) {
      case 'clear-filter':
        this.tempSelectedcategories = [];
        if(this.from == PageName.ContactPageComponent) {
          if(this.subFrom == ContactsModel.MY_CONTACTS_TAB) {
            this._initAccordionViewForContact(true);
          }else if(this.subFrom == ContactsModel.CHANGE_REQUESTS_TAB) {
            this._initAccordionViewForContactCR(true);
          }
        }else if(this.from == PageName.AccountPageComponent) {
          if(this.subFrom == AccountsModel.MY_ACCOUNTS_TAB) {
            this._initAccordionViewForAccount(true);
          }else if(this.subFrom == AccountsModel.CHANGE_REQUESTS_TAB) {
            this._initAccordionViewForAccountCR(true);
          }
        } else if (this.from == PageName.TerritoryManagementDetailsComponent) {
          if (this.subFrom == ContactsModel.MY_CONTACTS_TAB) {
            this._initAccordionViewForContact(true);
          }
        }else if(this.from == PageName.SurveyPageComponent) {
          this._initAccordionViewForSurvey(true);
        }
        else if(this.from == PageName.CustomerCallPlanPage) {
          this._initAccordionViewForCallPlan(true);
          this._initFilterMenuSectionHeader();
          this._initFilterMenuHeaderLeft();
        }
        else if(this.from == PageName.PresentationPageComponent) {
          this._initAccordionViewForPresentation(true);
          this._initFilterMenuSectionHeader();
          this._initFilterMenuHeaderLeft();
        } else if(this.from == PageName.MedicalInsightPageComponent){
          this._initAccordionViewForMedicalInsights(true);
          this._initFilterMenuSectionHeader();
          this._initFilterMenuHeaderLeft();
        }
        if(this.isSaveFilterEnabled && this.from != PageName.CustomerCallPlanPage) {
          this._initSavedSearchDataModels(this._getToolSearchName(), false, true);
          this._initFilterMenuSectionHeader();
          this._initFilterMenuHeaderLeft();
          if(this.isSaveFilterEnabled) this.agendaFooterService.disableButton(['saveFilter']);
        }else if(this.from == PageName.SurveyPageComponent) {
          this._initFilterMenuSectionHeader();
          this._initFilterMenuHeaderLeft();
        }
       this.onCloseRightPane();
        break;
      default:
        console.log("Unhandled switch case statement");
        break;
    }
  }

  public async goBack(flag: boolean) {
    let data;
    if (flag) {
      this.selectedCategories = _.cloneDeep(this.tempSelectedcategories);
      this.tempSelectedcategories = [];
      let currentSelectedDate = _.cloneDeep(this._selectedDate);
      this._selectedDate = { startDate: '', endDate: '' };

      if(!_.isEmpty(currentSelectedDate) && !_.isEmpty(currentSelectedDate.startDate) && !_.isEmpty(currentSelectedDate.endDate)) {
        data = { selectedItems: this.selectedCategories, isDone: true, selectedDate: currentSelectedDate };  
      }else {
        data = { selectedItems: this.selectedCategories, isDone: true };
      }
      this.modalCtrl.dismiss(data).then(() => this.navService.isModalViewOpen = false);
    }
    else {
    //   this.revertChanges();
      this.tempSelectedcategories = [];
      data = { selectedItems: this.selectedCategories, isDone: false };
      this.modalCtrl.dismiss(data).then(() => this.navService.isModalViewOpen = false);
    }
  }

  public searchText(ev): void {
    if(_.isEmpty(this.categories)) return;
    this.searchedCategories = [];
    let isFoundCategory: boolean = false;
    let isFoundCategoryValue: boolean = false;
    const val: string = (ev && ev.target && ev.target.value) ? ev.target.value : '';
    if (val.length >= MIN_SEARCH_LENGTH) {
      this.searching = true;
      let tempCategories = _.cloneDeep(this.categories)
      tempCategories.filter(category => {
        // search in category
        if(category.primaryText.trim().toLowerCase().includes(val.trim().toLowerCase())) {
          isFoundCategory = true;
          if(!_.isEmpty(this.searchedCategories)) {
            const idx = this.searchedCategories.findIndex(i=>i.id == category.id);
            if(idx < 0) {
              this.searchedCategories.push(category);
            }
          }else {
            this.searchedCategories.push(category);
          }
        }
        // search in category values
        else {
          let tempCat = _.cloneDeep(category);
          let searchedCategoryValues = [];
          searchedCategoryValues = tempCat.childItems.filter((item) => item.label?.trim().toLowerCase().includes(val.trim().toLowerCase()));
          if(!_.isEmpty(searchedCategoryValues)) {
            isFoundCategoryValue = true;
            tempCat.childItems = searchedCategoryValues;
            tempCat.isExpanded = true;
            if(!_.isEmpty(this.searchedCategories)) {
              const idx = this.searchedCategories.findIndex(c=>c.id == category.id);
              if(idx > -1) {
                this.searchedCategories[idx] = tempCat;
              }else {
                this.searchedCategories.push(tempCat);
              }
            }else {
              this.searchedCategories.push(tempCat);
            }
          }
        }
      })
      if(!isFoundCategory && !isFoundCategoryValue) {
        this.searchedCategories = [];
      }
    } else {
      this.searching = false;
      this.searchedCategories = [];
    }
    this._initFilterMenuSectionHeader();
  }

  public selectSavedCategories(filters, selected) {
    // reset
    if(selected && !selected.isChecked && !filters.childItems.some(item => item.isChecked)) {
      this.savedSelectedCategories = [];
      this.tempSelectedcategories = _.cloneDeep(this.savedSelectedCategories);
      this._init(true);
    } else {
      if(!_.isEmpty(selected && selected.value) && selected.isChecked) {
        this.savedSelectedCategories = selected.value;
        this.tempSelectedcategories = _.cloneDeep(this.savedSelectedCategories);
        this._init(false);
      // this.savedFilters.childItems.forEach(item => {
      //   if(item.id == selected.id) item.isChecked = selected.isChecked;
      //   else item.isChecked = false;
      // })
      }
    }
    this.agendaFooterService.disableButton(['saveFilter']);
  }

  public toggleCategoryValue(item, selected) {
    let now = new Date()
    if(!_.isEmpty(this.tempSelectedcategories)) {
      let index = this.tempSelectedcategories.findIndex((cat) => cat.categoryPath == item?.id || ((cat.categoryPath == 'Assessed'||cat.categoryPath == 'UnAssessed')&&item?.id=='Assessments'));
      if(index>-1) {
        let lastIndex = item.primaryText.lastIndexOf('(');
        item.primaryText = item.primaryText.slice(0,lastIndex);
      }
    } else if(selected.isChecked) {
      let lastIndex = item.primaryText.lastIndexOf('(');
      if(lastIndex>-1) item.primaryText = item.primaryText.slice(0,lastIndex);
    }
    // item.primaryText = item.primaryText.split('(')[0];
    let selectionMade: SelectedSuggestionPillDataModel = {
      selectedFacet: selected.label,
      charSearchText: selected.type == SuggestionPillType.BOOLEAN_FIELD ? selected.label : '',
      categoryPath: item?item.id :'',
      categoryName: item?item.primaryText : '',
      categoryDisplayName : item?item.primaryText : '',
      type: selected.type,
      value: selected.value,
      createdOn: now.getTime(),
      isAggregatedSection: item?.isAggregatedSection,
      configuredFrom: item?.configuredFrom,
      subCategories: [],
      isSubCategory: item ? item.isSubCategory || false : false,
    }
    
    if(selected.category !== "Teamusers") {
      if(!_.isEmpty(this.tempSelectedcategories)) {
        let idx = this.tempSelectedcategories.findIndex((cat) => cat.selectedFacet == selected.label && (cat.categoryPath == item?.id || ((cat.categoryPath == 'Assessed' || cat.categoryPath == 'UnAssessed') && item?.id == 'Assessments')));
        if(idx>-1) {
          if(!selected.isChecked) this.tempSelectedcategories.splice(idx,1);
        } else {
          if(selected.isChecked) this.tempSelectedcategories.push(selectionMade);
        }
      } else {
        if(selected.isChecked) this.tempSelectedcategories.push(selectionMade);
      }
    }
    else {
      if(!_.isEmpty(this.tempSelectedcategories)) {
        let idx = this.tempSelectedcategories.findIndex((cat) => cat.selectedFacet == selected.label && (cat.categoryPath == item?.id || ((cat.categoryPath == 'Assessed' || cat.categoryPath == 'UnAssessed') && item?.id == 'Assessments')));
        if(idx>-1) {
          if(!selected.isChecked) this.tempSelectedcategories.splice(idx,1);
        } else {
          if(selected.isChecked) {
            this.tempSelectedcategories = this.tempSelectedcategories.filter((item) => !(item.categoryPath === 'hasTeamusers'));

            this.tempSelectedcategories.push(selectionMade);
          }
        }
      } else {
        this.tempSelectedcategories.push(selectionMade);
      }
    }
    
    let index = this.categories.findIndex((cat)=> cat.id == item?.id);
    let numOfFilter = this.tempSelectedcategories.filter((cat) => cat.categoryPath == item?.id || ((cat.categoryPath == 'Assessed'||cat.categoryPath == 'UnAssessed')&&item?.id=='Assessments'))?.length;
    if(index>-1) {
      if(numOfFilter>0) {
        this.categories[index].primaryText = `${item.primaryText}(${numOfFilter})`;
        this.categories[index].isExpanded = true;
      }
      else this.categories[index].primaryText = item.primaryText;
    }
    if(this.searching) {
      let idx = this.searchedCategories.findIndex((cat) => cat.id == item?.id);
      if(idx>-1) {
        if(numOfFilter>0) {
          this.searchedCategories[idx].primaryText = `${item.primaryText}(${numOfFilter})`;
          this.searchedCategories[idx].isExpanded = true;
        }
      else this.searchedCategories[idx].primaryText = item.primaryText;
      }
    }
    //Check sub criteria items to display
    if(item.id == 'myActivitiesByContact' || item.id == 'filterMeetingsStatus' || item.id == 'filterMessagesStatus' || item.id == 'filterMessagesAttachment') {
      this._updateSubCategories(item);
    } else if(this.from == PageName.SurveyPageComponent) {
      const surveySubCategoriesList = ['survey_type'];
      if(surveySubCategoriesList.includes(item.id)) {
        if(item.id == 'survey_type' && selected.value == 600000000) {
          this._updateSubCategories(item);
        }
      }
    }
    if(!_.isEmpty(this.savedSelectedCategories)) {
      if(!_.isEqual(this.savedSelectedCategories, this.tempSelectedcategories)) {
        this._initSavedSearchDataModels(this._getToolSearchName(), false, true);
      }
    }
    this._initFilterMenuSectionHeader();
    this._initFilterMenuHeaderLeft();
    this._enableDisableFooterButtons();
  }

  private _initAccordionViewForSurvey(isClearFilter: boolean = false) {
    this.categories = [];
    let accordionViewType:OmniAccordionViewDataModel;
    let accordionViewUseType:OmniAccordionViewDataModel;
    let accordionViewStatus:OmniAccordionViewDataModel;
    let labelSurveyType: string = '';
    let labelSurveyUseType: string = '';
    let labelSurveyStatus: string = '';
    //survey type--------------------------------------------------------------------------------------------------------
    const selectedSurveyType = this.tempSelectedcategories.filter((category) => category.categoryPath == 'survey_type');
    if(!_.isEmpty(selectedSurveyType)) {
      labelSurveyType = `${this.translate.instant('SURVEY_TYPE')}(${selectedSurveyType.length})`;
    } else {
      labelSurveyType = this.translate.instant('SURVEY_TYPE');
    }
    accordionViewType = {
      displayType: 'Accordion',
      id: 'survey_type',
      primaryText: labelSurveyType,
      isExpanded: false,
      showExpandIcon: true,
      showCollapseIcon: true,
      isSearchable: false,
      isSelectable: true,
      isAggregatedSection: false,
      childItems: [],
      isFixedFilter: true,
      filterOrder: '1'
    };
    this.viewData.data.find(d => d.text == this.translate.instant('SURVEY_TYPE')).items.forEach((item) => {
      let checked = this.selectedFilter.some((filter) => filter.categoryPath == 'survey_type' && filter.value == item.value);
      accordionViewType.childItems.push({
        id: 'filter_menu_status' + '_' + item.value,
        label: item.text,
        value: item.value,
        isChecked: checked,
        type: 'general'
      })
      if(checked) accordionViewType.isExpanded = true;
    });
    this.categories.push(accordionViewType);
    //survey use-type--------------------------------------------------------------------------------------------------------
    const selectedSurveyUseType = this.tempSelectedcategories.filter((category) => category.categoryPath == 'survey_useType');
    if(!_.isEmpty(selectedSurveyUseType)) {
      labelSurveyUseType = `${this.translate.instant('USE_TYPE')}(${selectedSurveyUseType.length})`;
    } else {
      labelSurveyUseType = this.translate.instant('USE_TYPE');
    }
    accordionViewUseType = {
      displayType: 'Accordion',
      id: 'survey_useType',
      primaryText: labelSurveyUseType,
      isExpanded: false,
      showExpandIcon: true,
      showCollapseIcon: true,
      isSearchable: false,
      isSelectable: true,
      isAggregatedSection: false,
      childItems: [],
      isFixedFilter: true,
      filterOrder: '3'
    }
    this.viewData.data.find(d => d.text == this.translate.instant('USE_TYPE')).items.forEach((item) => {
      let checked = this.selectedFilter.some((filter) => filter.categoryPath == 'survey_useType' && filter.value == item.value);
      accordionViewUseType.childItems.push({
        id: 'filter_menu_request_type' + '_' + item.value,
        label: item.text,
        value: item.value,
        isChecked: checked,
        type: 'general'
      })
      if(checked) accordionViewUseType.isExpanded = true;
    });
    this.categories.push(accordionViewUseType);
    //survey status----Internal Survey only--------------------------------------------------------------------------------
    const selectedSurveyStatus = this.tempSelectedcategories.filter((category) => category.categoryPath == 'survey_status');
    if(!_.isEmpty(selectedSurveyStatus)) {
      labelSurveyStatus = `${this.translate.instant("SURVEY_INTERNAL_SURVEY")} - ${this.translate.instant('STATUS')}(${selectedSurveyStatus.length})`;
    } else {
      labelSurveyStatus = `${this.translate.instant("SURVEY_INTERNAL_SURVEY")} - ${this.translate.instant('STATUS')}`;
    }
    const isSelectedInternalSurvey: boolean = this.selectedCategories.some(c=>c.value == SurveyCategory.INTERNAL) || false;

    accordionViewStatus = {
      displayType: 'Accordion',
      id: 'survey_status',
      primaryText: labelSurveyStatus,
      isExpanded: false,
      showExpandIcon: true,
      showCollapseIcon: true,
      isSearchable: false,
      isSelectable: true,
      isAggregatedSection: false,
      childItems: [],
      isFixedFilter: true,
      filterOrder: '2',
      isSubCategory: true,
      isForcedHide: !isSelectedInternalSurvey
    }
    this.viewData.data.find(d => d.text == this.translate.instant('STATUS')).items.forEach((item) => {
      let checked = this.selectedFilter.some((filter) => filter.categoryPath == 'survey_status' && filter.value == item.value);
      accordionViewStatus.childItems.push({
        id: 'filter_menu_request_type' + '_' + item.value,
        label: item.text,
        value: item.value,
        isChecked: checked,
        type: 'general'
      })
      if(checked) accordionViewStatus.isExpanded = true;
    });
    this.categories.push(accordionViewStatus);

    //Clear filter--------------------------------------------------------------------------------------------------------
    if(isClearFilter) {
      this._selectedDate = { startDate: '', endDate: '' };
      this._initDateFormFields();
      if(!this.searching && !_.isEmpty(this.categories)) {
        this.categories.forEach(cat => {
          cat?.childItems.map(c=>c.isChecked = false);
          cat.isExpanded = false;
          if(cat.id == 'survey_status') {
            cat.isForcedHide = true;
          }
        });
      }else if(this.searching && !_.isEmpty(this.categories) && !_.isEmpty(this.searchedCategories)) {
        this.searchedCategories.forEach(scat => {
          scat?.childItems.map(c=>c.isChecked = false);
          scat.primaryText = this.categories.find(cat => cat.id == scat.id).primaryText;
        });
        this.searchConfigDataService.filterMenuFilterClear.next();
      }
    }
  }

  private handleFormFieldEvent(id, event, eventName) {
    if (id) {
      if (this.backgroundUploadInProgress) return;
      switch (id) {
        case 'start-date-field':
          this.openStartDatePicker(event);
          break;
        case 'end-date-field':
          this.openEndDatePicker(event);
          break;
        default:
          console.log("Unhandled switch case statement");
          break;
      }
    }
  }

  async openStartDatePicker(myEvent) {
    this.activityService.dateTimePickerType = DateTimeFieldType.StartDateField;
    let popover = await this.popoverCtrl.create(
      {
        component: IndDatetimeFormComponent,
        componentProps: {
          currentViewPage: CurViewPageType.FilterMenuModal,
          startDateTimeValue: this.startDateTimeValue,
          endDateTimeValue: this.endDateTimeValue
        },
        cssClass: "datetime-popover"
      }
    );
    popover.onDidDismiss().then((data: any) => {
      if (data !== null && !_.isEmpty(data.data) && data.data.startTime !='') {
        this.isUpdatedDate = true;
        const selectedStartDate = data.data.startTime;
        const selectedStartTime = this.dateTimeFormatsService.getFormattedTimeValue(selectedStartDate);
        // moment() is not supported date format as "DD/MM/YY" and "D/M/YY" when date and time are combined.
        // set 'MMM dd, yyyy' and 'en-US' to combine date and time, and changed date format will be adjusted through getFormattedDateTimeText().
        const getStartDate = this.datePipe.transform(selectedStartDate, "MMM dd, yyyy", undefined, 'en-US');
        const setCombinedStartDateTime = moment(getStartDate + ' ' + selectedStartTime).format();
        this.startDateTimeValue = setCombinedStartDateTime;
        // set end date time value
        let setCombinedEndDateTime: string;
        if(data.data.endTime !='') {
          const selectedEndDate = data.data.endTime;
          const selectedEndTime = this.dateTimeFormatsService.getFormattedTimeValue(selectedEndDate);
          const getEndDate = this.datePipe.transform(selectedEndDate, "MMM dd, yyyy", undefined, 'en-US');
          setCombinedEndDateTime = moment(getEndDate + ' ' + selectedEndTime).format();
          this.endDateTimeValue = setCombinedEndDateTime;
        }else {
          setCombinedEndDateTime = this.startDateTimeValue;
          this.endDateTimeValue = setCombinedEndDateTime;
        }
        this._selectedDate = {
          startDate: setCombinedStartDateTime,
          endDate: setCombinedEndDateTime,
        }
        this._initDateFormFields();
        this._initFilterMenuHeaderLeft();
        this._initFilterMenuSectionHeader();
        this._cd.detectChanges();
        this._cd.markForCheck();
      }
    });
    popover.present();
      
    
  }

  async openEndDatePicker(myEvent) {
    this.activityService.dateTimePickerType = DateTimeFieldType.EndDateField;
    let popover = await this.popoverCtrl.create(
      {
        component: IndDatetimeFormComponent,
        componentProps: {
          currentViewPage: CurViewPageType.FilterMenuModal,
          startDateTimeValue: this.startDateTimeValue,
          endDateTimeValue: this.endDateTimeValue
        },
        cssClass: "datetime-popover"
      }
    );
    popover.onDidDismiss().then((data: any) => {
      if (data !== null && !_.isEmpty(data.data) && data.data.endTime !='') {
        this.isUpdatedDate = true;
        const selectedEndDate = data.data.endTime;
        const selectedEndTime = this.dateTimeFormatsService.getFormattedTimeValue(selectedEndDate);
        // moment() is not supported date format as "DD/MM/YY" and "D/M/YY" when date and time are combined.
        // set 'MMM dd, yyyy' and 'en-US' to combine date and time, and changed date format will be adjusted through getFormattedDateTimeText().
        const getEndDate = this.datePipe.transform(selectedEndDate, "MMM dd, yyyy", undefined, 'en-US');
        const setCombinedEndDateTime = moment(getEndDate + ' ' + selectedEndTime).format();
        this.endDateTimeValue = setCombinedEndDateTime;
        // set start date time value
        let setCombinedStartDateTime: string;
        if(data.data.startTime !='') {
          const selectedStartDate = data.data.startTime;
          const selectedStartTime = this.dateTimeFormatsService.getFormattedTimeValue(selectedStartDate);
          const getStartDate = this.datePipe.transform(selectedStartDate, "MMM dd, yyyy", undefined, 'en-US');
          setCombinedStartDateTime = moment(getStartDate + ' ' + selectedStartTime).format();
          this.startDateTimeValue = setCombinedStartDateTime;
        }else {
          setCombinedStartDateTime = this.startDateTimeValue;
          this.startDateTimeValue = setCombinedStartDateTime;
        }
        this._selectedDate = {
          startDate: setCombinedStartDateTime,
          endDate: setCombinedEndDateTime,
        }
        this._initDateFormFields();
        this._initFilterMenuHeaderLeft();
        this._initFilterMenuSectionHeader();
        this._cd.detectChanges();
        this._cd.markForCheck();
      }
    });
    popover.present();
  }

  getFormattedDateTimeText(): any {
    let startDateTimeValue: Date = this._selectedDate && this._selectedDate.startDate ? new Date(this._selectedDate.startDate) : undefined;
    let endDateTimeValue: Date = this._selectedDate && this._selectedDate.endDate ? new Date(this._selectedDate.endDate) : undefined;
    let startDay = startDateTimeValue ? this.datePipe.transform(startDateTimeValue, this.dateTimeFormatsService.date, undefined, this.translate.currentLang) : undefined;
    let endDay = endDateTimeValue ? this.datePipe.transform(endDateTimeValue, this.dateTimeFormatsService.date, undefined, this.translate.currentLang) : undefined;

    let dateTimeValue: any = {
      startDateTime: undefined,
      startDate: undefined,
      endDateTime: undefined,
      endDate: undefined,
    };

    dateTimeValue = {
      startDateTime: startDateTimeValue,
      startDate: startDay,
      endDateTime: endDateTimeValue,
      endDate: endDay,
    };
    return dateTimeValue;
  }

  private _initSavedSearchDataModels(searchToolID, hasToShowShare = false, isClearFilter?: boolean){
    this.savedFilters = {
      displayType: 'Accordion',
      id: 'saved_filter_' + searchToolID,
      primaryText: this.translate.instant('SAVED_FILTER'),
      isExpanded: false,
      showExpandIcon: true,
      showCollapseIcon: true,
      isSearchable: true,
      searchActiveAt: 10,
      isSelectable: true,
      isAggregatedSection: false,
      childItems: [],
      clickHandler:((id?:string, event?:any, specificTarget?:string, dataRef?:any)=>{
        if(specificTarget && specificTarget == 'endArrow'){
          this.deleteSavedSearch(dataRef.id);
        }
      })
    }
    let expand: boolean = false;
    this.searchConfigService.savedSearches.forEach(savedSearch=>{
      if(searchToolID && savedSearch.searchToolName == searchToolID && !savedSearch.isDeleted){       
        let sharedSearchFlag =  savedSearch['searchType'] == UserSavedSearchTypes.SHARED ? true : false;
        let sharedByCurrentUser = sharedSearchFlag && (savedSearch['createdbyValue'] == this.authService.user.systemUserID || savedSearch['createdbyValue'] == undefined) ? true : false;
        let checked =  isClearFilter ? false : _.isEqual(this.tempSelectedcategories, savedSearch.categoryValuePairs)
        if (!sharedSearchFlag || sharedByCurrentUser) this.savedFilters.childItems.push({
          id: savedSearch.searchID ||savedSearch.offlineID,
          label: savedSearch.searchName,
          value: savedSearch.categoryValuePairs,
          isChecked: checked,
          type: SuggestionPillType.CATEGORY_VALUESEARCH,
          showArrow: sharedByCurrentUser || !sharedSearchFlag ? true : false,
          arrowType: 'indegene-ios-close-icon',
          isSingleSelect: true
        })
        expand = expand || checked;
      }
    })
    this.savedFilters.childItems = _.orderBy(this.savedFilters.childItems, [item=>item.label.toLowerCase()], ['asc']);
    this.savedFilters['isExpanded'] = expand;
  }

  deleteSavedSearch(savedSearchID){
    this.alertService.showAlert({ title:this.translate.instant('DELETE_SAVED_FILTER'),
                                  cssClass:'saveSearchAlert',
                                  message:this.translate.instant('FILTER_DELETE_CONFIRMATION'),
                                }, this.translate.instant('DELETE'), this.translate.instant('CANCEL'))
    .then(async (res)=>{
      if(res.role == 'ok'){
        _.remove(this.savedFilters.childItems,(model)=> model.id == savedSearchID);
        this.searchConfigDataService.deleteSavedSearch(savedSearchID);
      }
    })
  }

  public footerButtonClicked(buttonId: string) {
    switch (buttonId) {
      case "saveFilter":
        this.saveAdvancedSearch();
        break;   
      default:
        break;
    }
  }

  async saveAdvancedSearch(failedText?: string){
    let saveSearchName: string
    let searchToolNameID = this._getToolSearchName();
    let currentSavedSearches = this.searchConfigService.savedSearches.filter(o=> o.searchToolName==searchToolNameID);
    this.alertService.showAlert({ title:this.translate.instant('SAVE_FILTER'),
                                  subTitle:failedText?failedText:'',
                                  cssClass:'saveSearchAlert',
                                  message:this.translate.instant('SAVE_FILTER_POPOVER_MESSAGE'),
                                  inputs:[{type:'text', name:"name", placeholder:this.translate.instant('ENTER_NAME')}]
                                }, this.translate.instant('SAVE'), this.translate.instant('CANCEL'))
    .then(async (res)=>{
      if(res.role == 'ok'){
        if(res.data && res.data.values.name){
          saveSearchName = res.data.values.name.trim();
          let sameNameSearches = currentSavedSearches.filter(o=>o.searchName.toLowerCase().indexOf(saveSearchName.toLowerCase())==0);
          let incrementNumber: number = 1;
          if(sameNameSearches.length){
            saveSearchName += ' (';
            _.each(sameNameSearches,(searchData=>{
              if(searchData.searchName.toLowerCase().indexOf(saveSearchName.toLowerCase()) == 0){
                let currentIncrement = parseInt(searchData.searchName.toLowerCase().charAt(saveSearchName.length));
                if(!isNaN(currentIncrement) && currentIncrement>=incrementNumber) incrementNumber = currentIncrement+1;
              }
            }))
            saveSearchName+= incrementNumber+')';
          }
          this.agendaFooterService.disableButton(['saveFilter']);
          await this.searchConfigDataService.saveAdvancedSearch(saveSearchName,
                                                                searchToolNameID?searchToolNameID:'',
                                                                UserSavedSearchTypes.OWNED,
                                                                this.tempSelectedcategories.slice()
                                                                ).then(()=> {
                                                                  this.agendaFooterService.disableButton(['saveFilter']);
                                                                })

        }
        else if(res.data && res.data.values.name == ""){
          this.saveAdvancedSearch(this.translate.instant('NAME_IS_REQUIRED'));
        }
      }
    })
  }

  private _mapSubCategory(categoryPath: string, cItem: any) {
    const categoryIdx = this.categories.findIndex(c=>c.id == categoryPath);
    if(categoryIdx > -1) this.categories[categoryIdx].isForcedHide = !cItem.isChecked;
    if(!cItem.isChecked) {
      const tempCategoryIdx = this.tempSelectedcategories.findIndex(c=>c.categoryPath == categoryPath);
      if(categoryIdx > -1 && tempCategoryIdx > -1) {
        this.categories[categoryIdx].isExpanded = false;
        this.categories[categoryIdx].childItems.map(i=>{ i.isChecked = false; });
        const label: string = this.tempSelectedcategories[tempCategoryIdx].categoryDisplayName ? this.tempSelectedcategories[tempCategoryIdx].categoryDisplayName: this.tempSelectedcategories[tempCategoryIdx].categoryName;
        if(!_.isEmpty(label)) this.categories[categoryIdx].primaryText = label;
      }
      this.tempSelectedcategories = this.tempSelectedcategories.filter(c=>c.categoryPath != categoryPath);
    }
  }

  private _updateSubCategories(item: any) {
    if(item.id == "myActivitiesByContact") {
      item.childItems.forEach(cItem=>{
        const isMeetings: boolean = cItem.id.includes(this.translate.instant("MEETINGS"));
        const isMessages: boolean = cItem.id.includes(this.translate.instant("MESSAGES"));
        if(isMeetings) {
          this._mapSubCategory("filterMeetingsStatus", cItem);
        }else if(isMessages) {
          this._mapSubCategory("filterMessagesStatus", cItem);
          this._mapSubCategory("filterMessagesAttachment", cItem);
        }
      });
      //Check the selected activity in my activities to display the date duration field
      this.isSelectedMyActivity = item.childItems.some(item=>item.isChecked);
      if(!this.isSelectedMyActivity) {
        this._selectedDate = { startDate: '', endDate: '' };
        this._initDateFormFields();
      }

    }else if(item.id == "filterMeetingsStatus") {
      let tempSubCategories = this.tempSelectedcategories.find(c=>c.categoryPath == "myActivitiesByContact").subCategories || [];
      const idx = tempSubCategories.findIndex(category=>category.id == "filterMeetingsStatus");
      if(idx > -1) {
        tempSubCategories[idx] = item;
      }else {
        tempSubCategories.push(item);
      }
    }else if(item.id == "filterMessagesStatus") {
      let tempSubCategories = this.tempSelectedcategories.find(c=>c.categoryPath == "myActivitiesByContact").subCategories || [];
      const idx = tempSubCategories.findIndex(category=>category.id == "filterMessagesStatus");
      if(idx > -1) {
        tempSubCategories[idx] = item;
      }else {
        tempSubCategories.push(item);
      }
      this.tempSelectedcategories.find(c=>c.categoryPath == "myActivitiesByContact").subCategories = tempSubCategories;
    }else if(item.id == "filterMessagesAttachment") {
      let tempSubCategories = this.tempSelectedcategories.find(c=>c.categoryPath == "myActivitiesByContact").subCategories || [];
      const idx = tempSubCategories.findIndex(category=>category.id == "filterMessagesAttachment");
      if(idx > -1) {
        tempSubCategories[idx] = item;
      }else {
        tempSubCategories.push(item);
      }
      this.tempSelectedcategories.find(c=>c.categoryPath == "myActivitiesByContact").subCategories = tempSubCategories;
    } else if(this.from == PageName.SurveyPageComponent) {
      if(item.id == "survey_type") {
        item.childItems.forEach(cItem=> {
          this._mapSubCategory("survey_status", cItem);
        });
      }
    }
  }

  public loadMoreOptions(item,selected){
    this.selectedCategory = item;
    this.optionsList = [];
    this.recordCount = 30;
    this.filteredOptionsList = [];
    this.displayOptionsList = [];
    this.isOptionsSearching = false;
    let searchIndexConfig;
    if(this.from === PageName.TerritoryManagementDetailsComponent){
      searchIndexConfig = this.searchConfigService.customerPositionListContactsConfiguredSearchIndexConfig;
    }else if(this.from === PageName.ContactPageComponent){
      searchIndexConfig = this.searchConfigService.contactConfiguredSearchIndexConfig;
    }
    if(searchIndexConfig){
      //id: config.configuredFrom == 'Assess' ? config.categoryName : config.categoryRelativePath,
      let foundCategory = searchIndexConfig.find(a=> a.categoryName == item.id || a.categoryRelativePath == item.id);
      if(foundCategory && foundCategory.values.length > 0){
        const valuesToMapped =  foundCategory.values.slice(20,foundCategory.values.length);
        valuesToMapped.forEach((value) => {
          let categoryName = foundCategory.categoryDisplayName?foundCategory.categoryDisplayName:foundCategory.categoryName;
          let checked = this.tempSelectedcategories.some((filter) => (filter.categoryName == categoryName || filter.categoryDisplayName == categoryName) && filter.selectedFacet == value);
          let idx = foundCategory.categoryDisplayName?this.optionsList?.findIndex((item) => item.id == 'filter_menu_'+foundCategory.categoryDisplayName+'_'+value):this.optionsList?.findIndex((item) => item.id == 'filter_menu_'+foundCategory.categoryName+'_'+value);
          if(idx<0) {
              this.optionsList.push({
              id: 'filter_menu_'+foundCategory.categoryName+'_'+value,
              label: value,
              value: '',
              isChecked: checked,
              type: foundCategory.isBooleanTypeCategory?SuggestionPillType.BOOLEAN_FIELD:SuggestionPillType.CATEGORY_VALUESEARCH
            })
          } else {
            this.optionsList[idx] = {
              id: 'filter_menu_'+foundCategory.categoryName+'_'+value,
              label: value,
              value: '',
              isChecked: checked,
              type: foundCategory.isBooleanTypeCategory?SuggestionPillType.BOOLEAN_FIELD:SuggestionPillType.CATEGORY_VALUESEARCH
            }
          }
        })
      } else if(foundCategory && foundCategory.mappingValues.length > 0){
          const valuesToMapped =  foundCategory.mappingValues.slice(20,foundCategory.mappingValues.length);
          valuesToMapped.forEach((value) => {
            let categoryName = foundCategory.categoryDisplayName?foundCategory.categoryDisplayName:foundCategory.categoryName;
            let checked = this.tempSelectedcategories.some((filter) => (filter.categoryName == categoryName || filter.categoryDisplayName == categoryName) && filter.selectedFacet == value);
            let idx = foundCategory.categoryDisplayName?this.optionsList?.findIndex((item) => item.id == 'filter_menu_'+foundCategory.categoryDisplayName+'_'+value):this.optionsList?.findIndex((item) => item.id == 'filter_menu_'+foundCategory.categoryName+'_'+value);
            if(idx<0) {
              this.optionsList.push({
                id: 'filter_menu_'+foundCategory.categoryName+'_'+value.formattedValue,
                label: value.formattedValue,
                value: value.actualValue,
                isChecked: checked,
                showArrow:true,
                arrowType: 'chevron-down-outline',
                category: foundCategory.categoryName,
                type: foundCategory.isBooleanTypeCategory?SuggestionPillType.BOOLEAN_FIELD:SuggestionPillType.CATEGORY_VALUESEARCH
              })
            } else {
              this.optionsList[idx] = {
                id: 'filter_menu_'+foundCategory.categoryName+'_'+value.formattedValue,
                label: value.formattedValue,
                value: value.actualValue,
                isChecked: checked,
                showArrow:true,
                arrowType: 'chevron-down-outline',
                category: foundCategory.categoryName,
                type: foundCategory.isBooleanTypeCategory?SuggestionPillType.BOOLEAN_FIELD:SuggestionPillType.CATEGORY_VALUESEARCH
              }
            }
          })
      }
    }     
    this.optionsList = _.orderBy(this.optionsList, ['label'], ['asc']);
    this.displayOptionsList = this.sliceItems(0, this.recordCount);
    this.isRightPaneNavActive = true;
  }

  public onCloseRightPane(){
    this.selectedCategory = null;
    this.optionsList = [];
    this.filteredOptionsList = [];
    this.displayOptionsList = [];
    this.isOptionsSearching = false;
    this.isRightPaneNavActive = false;
  }

  public searchOptions(ev){
    const val: string = (ev && ev.target && ev.target.value) ? ev.target.value : '';
    if (val.length >= 1) {
      this.isOptionsSearching = true;
      let foundValues = [];
      const formattedSearchText = this.searchConfigService.convertFormattedString(val).trim().toLowerCase();
      this.optionsList.filter(i => {
        const formattedItem = this.searchConfigService.convertFormattedString(i.label).trim().toLowerCase();
        if(formattedItem.includes(formattedSearchText)) {
          if(!_.isEmpty(foundValues)) {
            const idx = foundValues.findIndex(v=>v.id == i.id);
            if(idx < 0) {
              foundValues.push(i);  
            } 
          }else {
            foundValues.push(i);
          }
        }
      })
      this.filteredOptionsList = foundValues;
      this.displayOptionsList = this.sliceItems(0, this.recordCount);
    } else {
      this.isOptionsSearching = false;
      this.filteredOptionsList = [];
      this.displayOptionsList = this.sliceItems(0, this.recordCount);
    }
  }

  public doInfinite(eventDetails,event) {
    this.displayOptionsList.push(...this.sliceItems(this.recordCount, 30));
    this.recordCount = this.displayOptionsList.length;
    event.target.complete();
  }

  private sliceItems(startIndex: number, count: number) {
    if(this.isOptionsSearching){
      return this.filteredOptionsList.length < startIndex + count ? this.filteredOptionsList.slice(startIndex) : this.filteredOptionsList.slice(startIndex, startIndex + count);;
    }else{
      return this.optionsList.length < startIndex + count ? this.optionsList.slice(startIndex) : this.optionsList.slice(startIndex, startIndex + count);;
    }
  }

  public selectItem(item) {
    item.isChecked = !item.isChecked;
    this.toggleCategoryValue(this.selectedCategory, item)
  }

}
