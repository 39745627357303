import { AddCustomerAvailabilityComponent } from './components/contact/add-customer-availability/add-customer-availability.component';
import { AssetDetailsComponent } from "@omni/components/field-material-management/asset-details/asset-details";
import { FieldMaterialManagementListComponent } from "@omni/components/field-material-management/field-material-management-list/field-material-management-list";
import { FieldMaterialManagementPageComponent } from "@omni/components/field-material-management/field-material-management-page/field-material-management-page";
import { TencentParticipantComponent } from './components/tencent-participant/tencent-participant.component';
import { ProcedureTrackerComponent } from './components/surgery-order/procedure-tracker/procedure-tracker';
import { ProcedureTrackerDetailsComponent } from './components/surgery-order/procedure-tracker-details/procedure-tracker-details';
import { IndReportCardTableComponent } from './components/edge-analytics/ind-report-card-table/ind-report-card-table';
import { IndReportCardComponent } from './components/edge-analytics/ind-report-card/ind-report-card';
import { CapitalizeFirstLetter } from './pipes/common-pipes/capitalize-first-letter.pipe';
import { AccountObjectiveDetails } from './components/account-management/account-objective-details/account-objective-details'
import { AccountPlanDetails } from './components/account-management/account-plan-details/account-plan-details'
import { AccountPlanList } from './components/account-management/account-plan-list/account-plan-list'
import { AccountPlanOpportunityListComponent } from './components/account-management/account-plan-opportunity-list/account-plan-opportunity-list'
import { PlanProgressReport } from './components/account-management/plan-progress-report/plan-progress-report'
import { AccountDetailsComponent } from './components/account/account-details/account-details'
//import { AccountInfoComponent } from './components/account/account-info/account-info'
import { ConfiguredAccountInfoComponent } from './components/account/configured-account-info/configured-account-info';
import { AccountListItemComponent } from './components/account/account-list-item/account-list-item';
import { AccountListRoComponent } from './components/account/account-list-ro/account-list-ro'
import { AccountListComponent } from './components/account/account-list/account-list'
import { AccountPageComponent } from './components/account/account-page/account-page'
import { AccountProfileComponent } from './components/account/account-profile/account-profile'
import { AccountTimelineComponent } from './components/account/account-timeline/account-timeline'
import { ActivitiesFilterPopoverComponent } from './components/activities-filter-popover/activities-filter-popover'
import { ActivitiesDetailsPaneComponent } from './components/activity/activities-details-pane/activities-details-pane'
import { ActivitiesPaneComponent } from './components/activity/activities-pane/activities-pane'
import { ActivityDayViewComponent } from './components/activity/activity-day-view/activity-day-view'
import { EmailActivityDetailComponent } from './components/activity/email-activity-detail/email-activity-detail'
import { RemoteURLEmailTemplate } from './components/activity/email-template/email-template'
import { FollowUpActionDetailComponent } from './components/activity/follow-up-action-detail/follow-up-action-detail'
import { NewActivityComponent } from './components/activity/new-activity/new-activity'
import { NewSampleActivityComponent } from './components/activity/new-sample-activity/new-sample-activity'
import { ScientificActivityDetail } from './components/activity/scientific-activity/scientific-activity-detail/scientific-activity-detail'
import { ScientificActivityInfo } from './components/activity/scientific-activity/scientific-activity-info/scientific-info'
import { ScientificActivityList } from './components/activity/scientific-activity/scientific-activity-list/scientific-activity-list'
import { ScientificActivityPage } from './components/activity/scientific-activity/scientific-activity-page/scientific-activity-page'
import { ScientificActivityTimeline } from './components/activity/scientific-activity/scientific-activity-timeline/scientific-activity-timeline'
import { ScientificPlanFollowUpTask } from './components/activity/scientific-activity/scientific-plan-task/scientific-plan-task'
import { ScinetificContactList } from './components/activity/scientific-activity/scinetific-contact-list/scinetific-contact-list'
import { TherapeuticAreaList } from './components/activity/scientific-activity/therapeutic-area/therapeuticarea.component'
import { AppLanguageListComponent } from './components/app-language-settings/language-list/app-language-list/app-language-list'
import { LanguageSettingsComponent } from './components/app-language-settings/language-setting-page/app-language-settings'
import { AboutDetailsComponent } from './components/app-settings/about-page/about-details/about-details'
import { AboutPageComponent } from './components/app-settings/about-page/about-page'
import { AppSettingsDetailsComponent } from './components/app-settings/appsettings-details/appsettings-details'
import { AppSettingsRecommendationsComponent } from './components/app-settings/app-settings-recommendations/app-settings-recommendations.component';
import { AppSettingsListComponent } from './components/app-settings/appsettings-list/appsettings-list'
import { AppSettingsPageComponent } from './components/app-settings/appsettings-page/appsettings-page'
import { AppSettingsProgressBarComponent } from './components/app-settings/appsettings-progress-bar/appsettings-progress-bar'
import { AppDateFormats } from './components/app-settings/date-formats/app-date-formats/app-date-formats'
import { GeneralSettingsComponent } from './components/app-settings/general-settings/general-settings'
import { CallNotesAssistantComponent } from './components/call-notes-assistant/call-notes-assistant'
import { CallPlanCustDetailsComponent } from './components/call-plans/call-plan-cust-details/call-plan-cust-details'
import { CallPlanDetails } from './components/call-plans/call-plan-details/call-plan-details'
import { CallPlanList } from './components/call-plans/call-plan-list/call-plan-list'
import { CallPlanComponent } from './components/call-plans/call-plan-page/call-plan-page'
import { CallPlanProgressBar } from './components/call-plans/call-plan-progress/call-plan-progress'
import { CaptureAllocationOrderComponent } from './components/capture-allocation-order/capture-allocation-order'
import { CaptureSignaturePopoverComponent } from './components/capture-signature-modal/capture-signature-popover'
import { CaptureCustomerInquireComponent } from './components/case-management/capture-customer-inquire/capture-customer-inquire'
import { CaseListItemComponent } from './components/case-management/case-list-item/case-list-item'
import { CaseManagementDetailComponent } from './components/case-management/case-management-detail/case-management-detail'
import { CaseManagementListComponent } from './components/case-management/case-management-list/case-management-list'
import { CasePopoverComponent } from './components/case-management/case-popover/case-popover'
import { InMeetingCasesComponent } from './components/case-management/in-meeting-cases/in-meeting-cases'
import { InquirySignatureModalComponent } from './components/case-management/inquiry-signature-modal/inquiry-signature-modal'
import { NewCaseManagementComponent } from './components/case-management/new-case-management/new-case-management'
import { ChildUsersPageComponent } from './components/child-users-page/child-users-page'
import { CoachingActivitiesComponent } from './components/coaching/coaching-activities/coaching-activities'
import { CoachingActivityItemComponent } from './components/coaching/coaching-activity-item/coaching-activity-item'
import { CoachingDetailsComponent } from './components/coaching/coaching-details/coaching-details'
import { CoachingForSelectComponent } from './components/coaching/coaching-for-select/coaching-for-select'
import { CoachingComponent } from './components/coaching/coaching-home/coaching-home'
import { CoachingItemComponent } from './components/coaching/coaching-item/coaching-item'
import { CoachingListComponent } from './components/coaching/coaching-list/coaching-list'
import { ConsentDetailsComponent } from './components/contact/consent-details/consent-details'
import { ConsentSignatureModalComponent } from './components/contact/consent-signature-modal/consent-signature-modal'
import { ContactAcountAffiliationComponent } from './components/contact/contact-change/contact-account/contact-acount-affiliation'
import { ContactNewAddressComponent } from './components/contact/contact-change/contact-address/contact-address-add'
import { ContactConsentCaptureComponent } from './components/contact/contact-consent-capture/contact-consent-capture'
import { ContactConsentComponent } from './components/contact/contact-consent/contact-consent'
import { ContactDetailsComponent } from './components/contact/contact-details/contact-details'
import { ContactInfoComponent } from './components/contact/contact-info/contact-info'
import { ContactListROComponent } from './components/contact/contact-list-ro/contact-list-ro'
import { ContactListComponent } from './components/contact/contact-list/contact-list'
import { ContactPageComponent } from './components/contact/contact-page/contact-page'
import { ContactProfileComponent } from './components/contact/contact-profile/contact-profile'
import { ContactTimelineComponent } from './components/contact/contact-timeline/contact-timeline'
import { CustomerInsightComponent } from './components/contact/customer-insight/customer-insight'
import { CustomerInterestComponent } from './components/contact/customer-insight/customer-interest/customer-interest'
import { CustomerJourneyComponent } from './components/contact/customer-insight/customer-journey/customer-journey'
import { CustomerRelationshipComponent } from './components/contact/customer-insight/customer-relationship/customer-relationship'
import { MeetingAttendiesListComponent } from './components/contact/meeting-attendies-list/meeting-attendies-list'
import { PublicationPreviewComponent } from './components/contact/publication-preview/publication-preview'
import { ScientificInfoDetailsComponent } from './components/contact/scientific-info-details/scientific-info-details'
import { XperiencePlanInsightComponent } from './components/contact/xperience-plan-insight/xperience-plan-insight'
import { CustomerXperiencesComponent } from './components/customer-xperiences/customer-xperiences'
import { InterestInsightsComponent } from './components/customer-xperiences/insights/interest-insights/interest-insights'
import { JourneyInsightsComponent } from './components/customer-xperiences/insights/journey-insights/journey-insights'
import { MorePlansInsightsComponent } from './components/customer-xperiences/insights/more-plans-insights/more-plans-insights'
import { PlansPredictionsComponent } from './components/customer-xperiences/insights/plans-predictions/plans-predictions'
import { RelationshipInsightsComponent } from './components/customer-xperiences/insights/relationship-insights/relationship-insights'
import { DatetimePickerComponent } from './components/datetime-picker/datetime-picker'
import { EmailTemplateDetailsComponent } from './components/email/email-template-details/email-template-details'
import { EmailTemplateItemComponent } from './components/email/email-template-item/email-template-item'
import { EmailTemplateListComponent } from './components/email/email-template-list/email-template-list'
import { EmailTemplatePageComponent } from './components/email/email-template-page/email-template-page'
import { MarketingEmailDetailsComponent } from './components/email/marketing-email-details/marketing-email-details'
import { ReplacementTokenListComponent } from './components/email/replacement-token-list/replacement-token-list'
import { TemplatePersonalizePageComponent } from './components/email/template-personalize-page/template-personalize-page'
import { TemplatePreviewComponent } from './components/email/template-preview/template-preview'
import { EventRegistrationComponent } from './components/event-registration/event-registrations'
import { EventDetailsComponent } from './components/events-tool/event-details/event-details';
import { EventsToolPageComponent } from './components/events-tool/event-tool-page/events-tools-page';
import { EventsListComponent } from './components/events-tool/events-list/events-list';
import { ExpandableComponent } from './components/expandable/expandable'
import { FooterToolbarComponent } from './components/footer-toolbar/footer-toolbar'
import { GeneeNotificationPopoverComponent } from './components/genee-notification-popover/genee-notification-popover'
import { GeneralInsightsPlusComponent } from './components/general-insights-plus/general-insights-plus'
import { InMeetingAllocationComponent } from './components/in-meeting-allocation/in-meeting-allocation'
import { InsightsPlusComponent } from './components/inisghts-plus/insights-plus'
import { InputBoxQuantComponent } from './components/input-box-quant/input-box-quant'
import { InstanceSelectionComponent } from './components/instance-selection/instance-selection'
import { IOCarousel } from './components/io-component/io-carousel/io-carousel'
import { CarouselPopover } from './components/io-component/io-carousel/io-carousel-popover'
import { IOIframe } from './components/io-component/io-iframe/io-iframe'
import { IOMultiSelect } from './components/io-component/io-multiselect/io-multiselect'
import { EditableNote } from './components/io-component/io-note/io-note'
import { ColorDirective, IonScrollComponent } from './components/ion-scroll.component'
import { LocationComponent } from './components/location/location'
import { MenuFooterComponent } from './components/menu-footer/menu-footer'
import { MoreOptionsPopoverComponent } from './components/more-options-popover/more-options-popover'
import { MultiSelectFilterPopoverComponent } from './components/multi-select-filter-popover/multi-select-filter-popover'
import { MultiSelectPopover } from './components/multi-select-popover/multi-select-popover'
import { GeneeInfoComponent } from './components/my-assistant/genee-info/genee-info'
import { GeneeNotificationComponent } from './components/my-assistant/genee-notification/genee-notification'
import { MyAssistantComponent } from './components/my-assistant/my-assistant'
import { MeetingStructure } from './components/new-components/meeting-structure/meeting-structure'
import { OGenieMessages } from './components/ogenie-messages/ogenie-messages'
import { NewOpportunityComponent } from './components/opportunity-management/new-opportunity/new-opportunity'
import { OpportunitiesListComponent } from './components/opportunity-management/opportunities-list/opportunities-list'
import { OpportunityAgreementsComponent } from './components/opportunity-management/opportunity-agreements/opportunity-agreements'
import { OpportunityDetailsComponent } from './components/opportunity-management/opportunity-details/opportunity-details'
import { OpportunityInfoComponent } from './components/opportunity-management/opportunity-info/opportunity-info'
import { OpportunityManagementPageComponent } from './components/opportunity-management/opportunity-management-page/opportunity-management-page'
import { OpportunityOrdersComponent } from './components/opportunity-management/opportunity-orders/opportunity-orders'
import { OpportunityQuoteDetailsComponent } from './components/opportunity-management/opportunity-quote-details/opportunity-quote-details'
import { OpportunityQuotesComponent } from './components/opportunity-management/opportunity-quotes/opportunity-quotes'
import { OrderAddQuantityComponent } from './components/order-management/order-add-quantity/order-add-quantity'
import { OrderDetailsComponent } from './components/order-management/order-details/order-details'
import { OrderManagementPageComponent } from './components/order-management/order-management-page/order-management-page'
import { OrdersListComponent } from './components/order-management/orders-list/orders-list'
import { PhoneCallDetailsComponent } from './components/phonecall/phone-call-details/phone-call-details'
import { PhoneCallMeetingStructureComponent } from './components/phonecall/phone-call-meeting-structure/phone-call-meeting-structure'
import { PhoneCallProductKeyMessageComponent } from './components/phonecall/phone-call-product-key-message/phone-call-product-key-message'
import { PhoneCallTherapeuticAreaComponent } from './components/phonecall/phone-call-therapeutic-area/phone-call-therapeutic-area'
import { InboundPopoverComponent } from './components/popover/inbound-popover/inbound-popover'
import { PopoverComponent } from './components/popover/popover'
import { SchedulerPopoverComponent } from './components/popover/scheduler-popover/scheduler-popover'
import { SyncPopoverComponent } from './components/popover/sync-popover/sync-popover'
import { PresentationItem } from './components/presentation/presentation-item/presentation-item'
import { PresentationListComponent } from './components/presentation/presentation-list/presentation-list'
import { PresentationViewPopover } from './components/presentation/presentation-list/presentation-view-popover'
import { PresentationPreviewComponent } from './components/presentation/presentation-preview/presentation-preview'
import { ProductKeyMessagesComponent } from './components/product-key-messages/product-key-messages'
import { ResourceInteractionDetailComponent } from './components/resource-interaction-detail/resource-interaction-detail'
import { ParticipantListComponent } from './components/resource/participant-list/participant-list'
import { ResourceDetailsComponent } from './components/resource/resource-details/resource-details'
import { ResourceListItemComponent } from './components/resource/resource-list-item/resource-list-item'
import { ResourceListComponent } from './components/resource/resource-list/resource-list'
import { ResourcePageComponent } from './components/resource/resource-page/resource-page'
import { ResourceShareHistoryComponent } from './components/resource/resource-share-history/resource-share-history'
import { SharedResourcesComponent } from './components/resource/shared-resources/shared-resources'
import { AllocationOrdersList } from './components/sample/allocation-orders-list/allocation-orders-list'
import { AllocationComponent } from './components/sample/allocation-page/allocation-page'
import { AllocationShipmentDetailsComponent } from './components/sample/allocation-shipment-details/allocation-shipment-details'
import { AllocationTransferComponent } from './components/sample/allocation-transfer/allocation-transfer'
import { CustomerAllocationList } from './components/sample/customer-allocation-list/customer-allocation-list'
import { SchedulerCalendarComponent } from './components/scheduler/scheduler-calendar/scheduler-calendar'
import { SchedulerCalendarPopupComponent } from './components/scheduler/scheduler-calendar/scheduler-calendar-popup/scheduler-calendar-popup'
import { SchedulerDetailComponent } from './components/scheduler/scheduler-detail/scheduler-detail'
import { SchedulerItemComponent } from './components/scheduler/scheduler-item/scheduler-item'
import { SchedulerListComponent } from './components/scheduler/scheduler-list/scheduler-list'
import { LanguageListComponent } from './components/settings/language-list/language-list'
import { SettingsHomeComponent } from './components/settings/settings-home/settings-home'
import { SettingsComponent } from './components/settings/settings/settings'
import { VideoSettingsComponent } from './components/settings/video-settings/video-settings'
import { DynamicFormLookupPopoverComponent } from './components/shared/dynamic-form-lookup-popover/dynamic-form-lookup-popover'
import { AlertWithInput} from '@omni/components/shared/alert-with-input/alert-with-input'
import { IndArrowScrollerComponent } from './components/shared/ind-arrow-scroller/ind-arrow-scroller'
import { IndBlockLevelLoader } from './components/shared/ind-block-level-loader/ind-block-level-loader'
import { IndChipsComponent } from './components/shared/ind-chips/ind-chips'
import { DisplayFormComponent } from './components/shared/ind-display-form/ind-display-form'
import { IndDropdownListComponent } from './components/shared/ind-dropdown-list/ind-dropdown-list'
import { DynamicFormComponent } from './components/shared/ind-dynamic-form/ind-dynamic-form'
import { IndFormFieldComponent } from './components/shared/ind-form-field/ind-form-field'
import { IndHeaderLeftComponent } from './components/shared/ind-header-left/ind-header-left'
import { IndKeyControlsAreaComponent } from './components/shared/ind-key-controls-area/ind-key-controls-area'
import { IndListGridComponent } from './components/shared/ind-list-grid/ind-list-grid'
import { IndPageTitleComponent } from './components/shared/ind-page-title/ind-page-title'
import { IndRightpaneReadonlyComponent } from './components/shared/ind-rightpane-readonly/ind-rightpane-readonly'
import { IndSectionHeaderComponent } from './components/shared/ind-section-header/ind-section-header'
import { IndTabsComponent } from './components/shared/ind-tabs/ind-tabs'
import { IndTextAreaComponent } from './components/shared/ind-text-area/ind-text-area'
import { MainToolTemplateComponent } from './components/shared/main-tool-template/main-tool-template'
import { NothingSelectedView } from './components/shared/nothing-selected-view/nothing-selected-view'
import { OmniAccordionComponent } from './components/shared/omni-accordion/omni-accordion';
import { OmniAccordionItemComponent } from './components/shared/omni-accordion-item/omni-accordion-item';
import { SearchSuggestionPopoverComponent } from './components/shared/search-suggestion-popover/search-suggestion-popover'
import { SelectListComponent } from './components/shared/select-list/select-list'
import { MainCardComponent } from './components/shared/ui-cards/main-card/main-card'
import { SpinnerComponent } from './components/spinner/spinner'
import { NewTimeOffComponent } from './components/time-off/new-time-off/new-time-off'
import { TimeOffDetailsComponent } from './components/time-off/time-off-details/time-off-details'
import { TimeOffComponent } from './components/time-off/time-off-home/time-off-home'
import { TimeOffItemComponent } from './components/time-off/time-off-item/time-off-item'
import { TimeOffListComponent } from './components/time-off/time-off-list/time-off-list'
import { MenuToggleComponent } from './components/toolsDrawer/menu-toggle/menu-toggle'
import { TrendingAccountsComponent } from './components/trending-accounts/trending-accounts'
import { TrendingFeedsComponent } from './components/trending-feeds/trending-feeds';
import { TrendingCustomersComponent } from './components/xperiences/trending-customers/trending-customers'
import { DebouncedInputDirective } from './directives/coaching-textarea/debounce-input'
import { TextareaAutosizeDirective } from './directives/coaching-textarea/textarea-autosize'
import { EmbedChildDirective } from './directives/embed-child-directive'
import { DebounceClickDirective } from './directives/debounce-click-directive';
import { ImagePreloadDirective } from './directives/image-load'
import { ProductKeyClickedDirective } from './directives/product-key-clicked/product-key-clicked'
import { AccountManagementPageComponent } from './pages/account-management-page/account-management-page'
import { ActivitiesPageComponent } from './pages/activities-page/activities-page'
import { CaseManagementHomePage } from './pages/case-management-home/case-management-home'
import { HomePage } from './pages/home/home'
import { LoginPage } from './pages/login/login'
import { PresentationPageComponent } from './pages/presentation/presentation'
import { PresentationFullscreenComponent } from './pages/presentation/presentation-fullscreen'
import { PresentationMeetingComponent } from './pages/presentation/presentation-meeting'
import { DateFormatsPage } from './pages/settings/date-formats-page'
import { ActiveConsentsFilter } from './pipes/active-consents-filter.pipe'
import { EligibilitiesSearchPipe } from './pipes/allocation-eligibilities-search.pipe'
import { AllocationOrderFilter } from './pipes/allocation-orders-filter.pipe'
import { CallPlanFilterPipe } from './pipes/call-plan-filter.pipe'
import { CallbackPipe } from './pipes/callback.pipe'
import { IndDateFormatPipe } from './pipes/common-pipes/date-format.pipe'
import { TextSearchFilterPipe } from './pipes/common-pipes/text-search.pipe'
import { ContactSearchPipe } from './pipes/contact-search.pipe'
import { ContentCustomSortPipe } from './pipes/content-custom-sort.pipe'
import { ExtendedDatePipe } from './pipes/extended-date.pipe'
import { GroupActivityPipe } from './pipes/group-activity/group-activity'
import { MonthTranslatePipe } from './pipes/month-translate/month-translate'
import { OfflinePresentationFilter } from './pipes/offline-presentation-filter'
import { OrderListFilter } from './pipes/order-list-filter.pipe'
import { OrderByPipe } from './pipes/orderby.pipe'
import { FilterReasonsPipe } from './pipes/filterReasons.pipe'
import { PresentationFilterByPipe } from './pipes/presentation-filter.pipe'
import { RepCallPlanFilterPipe } from './pipes/rep-call-plan-filter.pipe'
import { RepCallPlanStatePipe } from './pipes/rep-call-plan-state.pipe'
import { SafePipe } from './pipes/safe.pipe'
import { SchedulerPipe } from './pipes/scheduler.pipe'
import { ScientificActivityPlanFilter } from './pipes/scientific-activity-plan.pipe'
import { SplitRepCallPlanPipe } from './pipes/split-rep-call-plan.pipe'
import { TimeOffFilterPipe } from './pipes/time-off-filter.pipe'
import { TimeOffSearchPipe } from './pipes/time-off-search/time-off-search'
import { UtcDatePipe } from './pipes/utc-date.pipe'
import { CaptureEventsComponent } from './components/events-tool/capture-events/capture-events';
import { MarketScanDetailsComponent } from './components/market-scan/market-scan-details/market-scan-details';
import { MarketScanListComponent } from './components/market-scan/market-scan-list/market-scan-list';
import { MarketScanPageComponent } from './components/market-scan/market-scan-page/market-scan-page';
import { MarketScanTableComponent } from './components/market-scan/market-scan-table/market-scan-table';
import { NextCallObjectiveComponent } from './components/next-call-objective/next-call-objective';
import { PdfPreviewComponent } from './components/pdf-preview/pdf-preview';
import { ReportsFiltersComponent } from './components/reports/reports-filters/reports-filters';
import { IndReportGroupTileComponent } from './components/shared/ind-report-group-tile/ind-report-group-tile';
import { IndReportTileComponent } from './components/shared/ind-report-tile/ind-report-tile';
import { IndSplitPaneComponent } from './components/shared/ind-split-pane/ind-split-pane.component';
import { MyApp } from './app.component'

import {StarRatingComponent} from './components/shared/star-rating/star-rating.component';
import {NewChangeRequestComponent} from "@omni/components/mdm/new-change-request/new-change-request";
import {ContactCrComponent} from "@omni/components/mdm/contact/change-requests/contact-cr";
import {AccountCrComponent} from "@omni/components/mdm/account/change-requests/account-cr";
import { ContactVeevaWidgetComponent } from './components/contact/contact-veeva-widget/contact-veeva-widget';
import { EmailPopoverComponent } from './components/email/email-popover/email-popover';
import {CrDetailsComponent} from "@omni/components/mdm/contact/cr-details/cr-details";
import {AccountCrDetailsComponent} from "@omni/components/mdm/account/account-cr-details/account-cr-details";
import { VeevaWidgetComponent } from './pages/veeva-widget/veeva-widget';
import { VeevaProfileWidgetComponent } from './pages/veeva-profile-widget/veeva-profile-widget.component';
import { EdgeAnalyticsListComponent } from './components/edge-analytics/edge-analytics-list/edge-analytics-list';
import { EdgeAnalyticsDetailComponent } from './components/edge-analytics/edge-analytics-detail/edge-analytics-detail';
import { PlansListComponent } from './components/plans/plans-list/plans-list';
import { BusinessProcessComponent } from './components/shared/business-process/business-process';
import { IOParticipantCarousel } from './components/io-component/io-carousel/io-participant-carousel.component';
import { OTPublisherComponent } from './components/opentok/ot-publisher.component';
import { OTSubscriberComponent } from './components/opentok/ot-subscriber.component';
import { ImpactDetailsComponent } from './components/xperiences/impact-details-pane/impact-details-pane';
import { ActivitySkeletonPaneComponent } from './components/activity/activity-sekelton-pane/activity-skeleton-pane';
import { SurgeryOrderDetailsComponent } from './components/surgery-order/surgery-order-details/surgery-order-details';
import { IndNoSearchResultComponent } from './components/shared/ind-no-search-result/ind-no-search-result';
import { AllocationAdjustComponent } from './components/sample/allocation-adjust/allocation-adjust';
import { IndAffiliationsCardComponent } from './components/shared/ind-affiliations-card/ind-affiliations-card';
import { CalendarInviteComponent } from './components/calendar-invite/calendar-invite';
import { CalendarInviteTemplatePageComponent } from './components/calendar-invite-template/calendar-invite-template-page/calendar-invite-template-page';
import { CalendarInviteTemplateListComponent } from './components/calendar-invite-template/calendar-invite-template-list/calendar-invite-template-list';
import { AdjustmentsListComponent } from './components/sample/adjustments-list/adjustments-list.component';
import { OpportunityCollaboratorsComponent } from './components/opportunity-management/opportunity-collaborators/opportunity-collaborators'
import { SearchLookupPopoverComponent } from './components/popover/search-lookup-popover/search-lookup-popover';
import { IndDatetimeFormComponent } from './components/shared/ind-datetime-form/ind-datetime-form';
import { AgendaPlanListComponent } from './components/agenda-plan-list/agenda-plan-list.component';
import { SurveyComponent } from './components/shared/ind-survey/ind-survey' ;
import { AffiliationExplorerComponent } from '@omni/components/shared/affiliation-explorer/affiliation-explorer';
import {NotificationListComponent} from "@omni/components/notification/notification-list/notification-list";
import {NotificationDetailsComponent} from "@omni/components/notification/notification-details/notification-details";
import { ActivityConflictMsgComponent } from './components/shared/activity-conflict-alert-msg/activity-conflict-alert-msg';
import{GoalsPlansComponent} from './components/goals-plans/goals-plans.component';
import { IndHierarchyListItemComponent } from './components/shared/ind-hierarchy-list-item/ind-hierarchy-list-item';
import { CustomerAssessComponent } from '@omni/components/assessment/customer-assess/customer-assess';
import { SectionSpecificComponent } from '@omni/components/assessment/section-specific/section-specific';
import { NewAccountPlanComponent } from './components/account-management/new-account-plan/new-account-plan';
import { OpportunitySelectionPageComponent } from './components/opportunity-management/opportunity-selection-master-page/opportunity-selection-master-page';
import { ExpensesDetails } from './components/account-management/account-plan-expenses/expenses-details';
import { EventParticipantComponent } from "./components/event-participant/event-participant";
import { AssetTransferDetailsComponent } from './components/field-material-management/asset-transfer-details/asset-transfer-details';
import { AccountPlanInfo } from "./components/account-management/account-plan-info/account-plan-info";
import { AccountPlanTimeline } from "./components/account-management/account-plan-timeline/account-plan-timeline";
import { AccountPlanActivities } from "./components/account-management/account-plan-activities/account-plan-activities";
import { MarketScanEditPageComponent } from "./components/market-scan/market-scan-edit-page/market-scan-edit-page";
import { PresentationDetailComponent } from "./components/presentation/presentation-detail/presentation-detail";
import {MarketingPlanManagementPageComponent } from "./pages/marketing-plan-page/marketing-plan-page";
import {MarketingPlanList } from "./components/marketing-business/marketing-plan-list/marketing-plan-list";
import { MarketingPlanDetails } from "./components/marketing-business/marketing-plan-details/marketing-plan-details";
import { MarketingPlanInfo } from "./components/marketing-business/marketing-plan-info/marketing-plan-info";
import { MarketingPlanTimeline } from "./components/marketing-business/marketing-plan-timeline/marketing-plan-timeline";
import { CustomerAvailabilityComponent } from "./components/contact/customer-availability/customer-availability.component";
import { UserInventoryListComponent } from "./components/sample/user-inventory-list/user-inventory-list";
import { UserInventoryDetailComponent } from "./components/sample/user-inventory-detail/user-inventory-detail";
import { IOReferenceCarousel } from './components/io-component/io-carousel/io-reference-carousel.component';
import { ReferenceViewerComponent } from './components/presentation/reference-viewer/reference-viewer.component';
import { QuotePageComponent } from './pages/quote-page/quote-page';
import { QuoteListComponent } from './components/quote/quote-list/quote-list.component';
import { QuoteDetailsComponent } from './components/quote/quote-details/quote-details.component';
import { NewQuoteComponent } from './components/quote/new-quote/new-quote.component';
import { CustomerCallPlanPage } from './components/customer-call-plans/customer-call-plan-page/customer-call-plan-page';
import { CustomerCallPlanList } from './components/customer-call-plans/customer-call-plan-list/customer-call-plan-list';
import { CustomerCallPlanDetails } from './components/customer-call-plans/customer-call-plan-details/customer-call-plan-details';
import { QuoteProductsComponent } from './components/quote/quote-products/quote-products.component';
import { IndFilterMenuComponent } from './components/shared/ind-filter-menu/ind-filter-menu';
import { CustomerCallPlanOverView} from './components/customer-call-plans/customer-call-plan-overview/customer-call-plan-overview';
import { CustomerCallPlanSuggest } from './components/customer-call-plans/customer-call-plan-suggest/customer-call-plan-suggest';
import { PharmacovigilanceComponent } from './components/pharmacovigilance-reporting/page/pharmacovigilance-page';
import { PharmacovigilanceList } from './components/pharmacovigilance-reporting/list/pharmacovigilance-list';
import { PharmacovigilanceDetail } from './components/pharmacovigilance-reporting/detail/pharmacovigilance-detail';
import { CustomNotificationDetails } from './components/custom-notification/custom-notification-details';
import { IndFilterMenuModalComponent } from './components/shared/ind-filter-menu-modal/ind-filter-menu-modal';
import { IndAlertMsgComponent } from './components/shared/ind-alert-msg/ind-alert-msg';
import { CustomerAssessTeamviewComponent } from './components/assessment/customer-assess-teamview/customer-assess-teamview';
import { IndPopupModalHeaderComponent } from './components/shared/ind-popup-modal-header/ind-popup-modal-header';
import { PharmacovigilanceInfoModalComponent } from './components/pharmacovigilance-reporting/info-modal/pharmacovigilance-info-modal';
import { KOLStatusPageComponent } from './components/contact/kol-status-page/kol-status-page';
import { KOLStatusListComponent } from './components/contact/kol-status-list/kol-status-list';
import { KOLStatusDetailsComponent } from './components/contact/kol-status-details/kol-status-details';
import { ContactMedicalInsightComponent } from './components/contact/contact-medical-insight/contact-medical-insight';
import { TerritoryManagementPageComponent } from './pages/territory-management-page/territory-management-page.component';
import { TerritoryManagementListComponent } from './components/territory-management/territory-management-list/territory-management-list';
import { TerritoryManagementDetailsComponent } from './components/territory-management/territory-management-details/territory-management-details';
import { NumberCardComponent } from './components/shared/ui-cards/number-card/number-card.component';
import { TerritoryManangementListDetailsComponent } from './components/territory-management/territory-manangement-list-details/territory-manangement-list-details';
import { TerritoryManangementInfoComponent } from './components/territory-management/territory-manangement-info/territory-manangement-info';
import { AvatarListItemComponent } from './components/shared/avatar-list-item/avatar-list-item';
import { OmniListItemComponent } from './components/shared/omni-list-item/omni-list-item.component';
import { SearchFilterComponent } from './components/shared/search-filter/search-filter';
import { OmniInfoAlertComponent } from './components/shared/omni-info-alert/omni-info-alert.component';
import { HopQRCodeModal } from './components/contact/hop-qr-code-modal/hop-qr-code-modal';
import { SurveyPageComponent } from './components/survey/survey-page/survey-page';
import { SurveyListComponent } from './components/survey/survey-list/survey-list';
import { SurveyDetailsComponent } from './components/survey/survey-details/survey-details';
import { SurveyPreviewComponent } from './components/survey/survey-preview/survey-preview';
import { SurveyHistoryComponent } from './components/survey/survey-history/survey-history';
import { ReorderItemsListComponent } from './components/shared/reorder-items-list/reorder-items-list-component';
import { SubCardComponent } from './components/shared/ui-cards/sub-card/sub-card';
import { SurveyListCardviewComponent } from './components/survey/survey-list-cardview/survey-list-cardview';
import { MdmMaterialModal } from './components/contact/mdm-material/mdm-material-modal';
import { NewManageListComponent } from './components/territory-management/new-manage-list/new-manage-list.component'
import { MedicalInsightPageComponent } from './components/contact/medical-insight-page/medical-insight-page';
import { MedicalInsightListComponent } from './components/contact/medical-insight-list/medical-insight-list';
import { StoreCheckDetailsComponent } from './components/activity/store-check-activity/store-check-details';
import { IndPhotoCaptureViewerComponent } from './components/shared/ind-photo-capture-viewer/ind-photo-capture-viewer';
import { IndPhotoViewerComponent } from './components/shared/ind-photo-viewer/ind-photo-viewer';
import { AppealDetailsComponent } from './components/appeal-details/appeal-details.component';
import { ApprovalListComponent } from './components/approval-list/approval-list.component';
import { ApprovalListItemComponent } from './components/approval-list-item/approval-list-item.component';
import { OfftakeCheckProductComponent } from './components/account/offtake-check-collection/offtake-check-product/offtake-check-product.component';
import { OfftakeCheckComponent } from './components/account/offtake-check-collection/offtake-check/offtake-check.component';
import { IndTextInputPopupComponent } from './components/shared/ind-text-input-popup/ind-text-input-popup';
import { ShortCallHomeComponent } from './components/activity/short-call-home/short-call-home';
import { LeftPaneUndefinedDefaultComponent } from './components/activity/left-pane-undefined-default/left-pane-undefined-default';
import { IoContentCardsComponent } from './components/io-component/io-content-cards/io-content-cards.component';
import { IoShortCallDetailsComponent } from './components/io-component/io-short-call-details/io-short-call-details.component';
import { IoContentFilter } from './components/io-component/io-content-filter/io-content-filter';

export const components = [
  LoginPage,
  ActivitiesPageComponent,
  HomePage,
  PresentationPageComponent,
  PresentationMeetingComponent,
  PresentationFullscreenComponent,
  ActivitiesPaneComponent,
  AppSettingsProgressBarComponent,
  ActivitiesDetailsPaneComponent,
  ActivityDayViewComponent,
  ExpandableComponent,
  CoachingComponent,
  CoachingListComponent,
  CoachingDetailsComponent,
  CoachingForSelectComponent,
  CoachingActivitiesComponent,
  ContactPageComponent,
  ContactListComponent,
  ContactDetailsComponent,
  ContactInfoComponent,
  ContactNewAddressComponent,
  ContactTimelineComponent,
  ContactConsentComponent,
  ContactConsentCaptureComponent,
  ConsentDetailsComponent,
  ContactProfileComponent,
  ScientificInfoDetailsComponent,
  ScientificActivityInfo,
  ScinetificContactList,
  ScientificActivityTimeline,
  TherapeuticAreaList,
  PublicationPreviewComponent,
  ContactListROComponent,
  ContactAcountAffiliationComponent,
  CallPlanComponent,
  GoalsPlansComponent,
  CallPlanList,
  CallPlanDetails,
  CallPlanCustDetailsComponent,
  MeetingAttendiesListComponent,
  RemoteURLEmailTemplate,
  FooterToolbarComponent,
  PopoverComponent,
  InboundPopoverComponent,
  SchedulerPopoverComponent,
  SyncPopoverComponent,
  MultiSelectPopover,
  OGenieMessages,
  MenuToggleComponent,
  NewActivityComponent,
  MenuFooterComponent,
  DatetimePickerComponent,
  EmailActivityDetailComponent,
  LanguageListComponent,
  SettingsComponent,
  VideoSettingsComponent,
  SettingsHomeComponent,
  AccountPageComponent,
  AccountListComponent,
  AccountDetailsComponent,
  //AccountInfoComponent,
  ConfiguredAccountInfoComponent,
  PresentationListComponent,
  IOIframe,
  IOCarousel,
  IOParticipantCarousel,
  IOMultiSelect,
  PresentationPreviewComponent,
  PresentationViewPopover,
  LocationComponent,
  InstanceSelectionComponent,
  TimeOffComponent,
  TimeOffListComponent,
  TimeOffItemComponent,
  TimeOffDetailsComponent,
  PresentationItem,
  ProductKeyMessagesComponent,
  IonScrollComponent,
  AccountListItemComponent,
  //AccountInfoComponent,
  ConfiguredAccountInfoComponent,
  MyAssistantComponent,
  AccountTimelineComponent,
  CarouselPopover,
  ResourceDetailsComponent,
  ResourceListComponent,
  ResourceListItemComponent,
  ResourcePageComponent,
  ParticipantListComponent,
  SharedResourcesComponent,
  AccountListRoComponent,
  SpinnerComponent,
  CallPlanProgressBar,
  ResourceShareHistoryComponent,
  NewTimeOffComponent,
  SchedulerListComponent,
  SchedulerItemComponent,
  SchedulerCalendarComponent,
  SchedulerCalendarPopupComponent,
  SchedulerDetailComponent,
  AllocationComponent,
  CustomerAllocationList,
  NewSampleActivityComponent,
  AllocationOrdersList,
  AllocationTransferComponent,
  AllocationAdjustComponent,
  AllocationShipmentDetailsComponent,
  CoachingItemComponent,
  CoachingActivityItemComponent,
  CaptureAllocationOrderComponent,
  CaptureSignaturePopoverComponent,
  ConsentSignatureModalComponent,
  InMeetingAllocationComponent,
  CallNotesAssistantComponent,
  GeneeNotificationPopoverComponent,
  ActivitiesFilterPopoverComponent,
  ChildUsersPageComponent,
  EmailTemplatePageComponent,
  EmailTemplateListComponent,
  EmailTemplateItemComponent,
  EmailTemplateDetailsComponent,
  AccountManagementPageComponent,
  MarketingPlanManagementPageComponent,
  QuotePageComponent,
  AccountPlanList,
  MarketingPlanList,
  TemplatePersonalizePageComponent,
  ReplacementTokenListComponent,
  TemplatePreviewComponent,
  AccountPlanDetails,
  MarketingPlanDetails,
  NothingSelectedView,
  AccountObjectiveDetails,
  PlanProgressReport,
  FollowUpActionDetailComponent,
  EditableNote,
  ResourceInteractionDetailComponent,
  MultiSelectFilterPopoverComponent,
  CaseManagementListComponent,
  CaseManagementDetailComponent,
  CaseListItemComponent,
  CaseManagementHomePage,
  CasePopoverComponent,
  EmailPopoverComponent,
  NewCaseManagementComponent,
  NewChangeRequestComponent,
  ContactCrComponent,
  CrDetailsComponent,
  AccountCrDetailsComponent,
  AccountCrComponent,
  ScientificActivityPage,
  ScientificActivityList,
  ScientificActivityDetail,
  ScientificPlanFollowUpTask,
  InMeetingCasesComponent,
  OrderManagementPageComponent,
  OrdersListComponent,
  OrderDetailsComponent,
  OrderAddQuantityComponent,
  SelectListComponent,
  TrendingCustomersComponent,
  AppSettingsPageComponent,
  AppSettingsListComponent,
  AppSettingsDetailsComponent,
  AppSettingsRecommendationsComponent,
  //,ProgressBarSettingsComponent
  CustomerXperiencesComponent,
  PlansPredictionsComponent,
  InterestInsightsComponent,
  JourneyInsightsComponent,
  RelationshipInsightsComponent,
  MorePlansInsightsComponent,
  CustomerInsightComponent,
  CustomerJourneyComponent,
  CustomerRelationshipComponent,
  CustomerInterestComponent,
  XperiencePlanInsightComponent,
  EventRegistrationComponent,
  MoreOptionsPopoverComponent,
  InputBoxQuantComponent,
  GeneralSettingsComponent,
  DateFormatsPage,
  LanguageSettingsComponent,
  AppLanguageListComponent,
  AppDateFormats,
  MainCardComponent,
  OpportunityManagementPageComponent,
  OpportunitiesListComponent,
  OpportunityDetailsComponent,
  OpportunityInfoComponent,
  OpportunityQuotesComponent,
  OpportunityAgreementsComponent,
  OpportunityOrdersComponent,
  NewOpportunityComponent,
  IndFormFieldComponent,
  IndSectionHeaderComponent,
  OpportunityQuoteDetailsComponent,
  IndRightpaneReadonlyComponent,
  IndPageTitleComponent,
  IndHeaderLeftComponent,
  AccountProfileComponent,
  MarketingEmailDetailsComponent,
  AccountPlanOpportunityListComponent,
  AboutPageComponent,
  AboutDetailsComponent,
  MeetingStructure,
  IndTabsComponent,
  IndKeyControlsAreaComponent,
  IndChipsComponent,
  IndDropdownListComponent,
  IndTextAreaComponent,
  IndListGridComponent,
  SearchSuggestionPopoverComponent,
  MainToolTemplateComponent,
  IndHierarchyListItemComponent,
  DynamicFormComponent,
  IndBlockLevelLoader,
  InsightsPlusComponent,
  GeneralInsightsPlusComponent,
  CaptureCustomerInquireComponent,
  InquirySignatureModalComponent,
  DynamicFormLookupPopoverComponent,
  AlertWithInput,
  DisplayFormComponent,
  PhoneCallDetailsComponent,
  PhoneCallMeetingStructureComponent,
  PhoneCallProductKeyMessageComponent,
  IndArrowScrollerComponent,
  GeneeInfoComponent,
  TrendingAccountsComponent,
  GeneeNotificationComponent,
  PhoneCallTherapeuticAreaComponent,
  MyApp,
  TrendingFeedsComponent,
  OmniAccordionComponent,
  OmniAccordionItemComponent,
  StarRatingComponent,
  EdgeAnalyticsListComponent,
  EdgeAnalyticsDetailComponent,
  PlansListComponent,
  NotificationListComponent,
  NotificationDetailsComponent,
  BusinessProcessComponent,
  ActivitySkeletonPaneComponent,
  SurgeryOrderDetailsComponent,
  IndNoSearchResultComponent,
  IndAffiliationsCardComponent,
  CalendarInviteComponent,
  CalendarInviteTemplatePageComponent,
  CalendarInviteTemplateListComponent,
  AdjustmentsListComponent,
  IndReportCardComponent,
  IndReportCardTableComponent,
  OpportunityCollaboratorsComponent,
  SearchLookupPopoverComponent,
  IndDatetimeFormComponent,
  AgendaPlanListComponent,
  AffiliationExplorerComponent,
  ActivityConflictMsgComponent,
  CustomerAssessComponent,
  SectionSpecificComponent,
  NewAccountPlanComponent,
  OpportunitySelectionPageComponent,
  ProcedureTrackerDetailsComponent,
  ProcedureTrackerComponent,
  FieldMaterialManagementPageComponent,
  FieldMaterialManagementListComponent,
  EventParticipantComponent,
  AssetDetailsComponent,
  PresentationDetailComponent,
  AssetTransferDetailsComponent,
  MarketScanEditPageComponent,
  CustomerAvailabilityComponent,
  UserInventoryListComponent,
  UserInventoryDetailComponent,
  AddCustomerAvailabilityComponent,
  IOReferenceCarousel,
  ReferenceViewerComponent,
  QuoteListComponent,
  QuoteDetailsComponent,
  NewQuoteComponent,
  CustomerCallPlanPage,
  CustomerCallPlanList,
  CustomerCallPlanDetails,
  QuoteProductsComponent,
  IndFilterMenuComponent,
  CustomerCallPlanOverView,
  CustomerCallPlanSuggest,
  PharmacovigilanceComponent,
  PharmacovigilanceList,
  PharmacovigilanceDetail,
  IndFilterMenuModalComponent,
  IndAlertMsgComponent,
  CustomerAssessTeamviewComponent,
  IndPopupModalHeaderComponent,
  PharmacovigilanceInfoModalComponent,
  TerritoryManagementPageComponent,
  TerritoryManagementListComponent,
  TerritoryManagementDetailsComponent,
  NumberCardComponent,
  AvatarListItemComponent,
  SearchFilterComponent,
  KOLStatusPageComponent,
  KOLStatusListComponent,
  KOLStatusDetailsComponent,
  ContactMedicalInsightComponent,
  TerritoryManangementListDetailsComponent,
  TerritoryManangementInfoComponent,
  OmniListItemComponent,
  OmniInfoAlertComponent,
  HopQRCodeModal,
  MdmMaterialModal,
  SurveyPageComponent,
  SurveyListComponent,
  SurveyDetailsComponent,
  SurveyPreviewComponent,
  SurveyHistoryComponent,
  ReorderItemsListComponent,
  SubCardComponent,
  SurveyListCardviewComponent,
  NewManageListComponent,
  MedicalInsightPageComponent,
  MedicalInsightListComponent,
  StoreCheckDetailsComponent,
  IndPhotoCaptureViewerComponent,
  IndPhotoViewerComponent,
  OfftakeCheckComponent,
  OfftakeCheckProductComponent,
  IndTextInputPopupComponent,
  ShortCallHomeComponent,
  LeftPaneUndefinedDefaultComponent
];

export const pipes= [
  GroupActivityPipe,
  CallbackPipe,
  CallPlanFilterPipe,
  RepCallPlanFilterPipe,
  RepCallPlanStatePipe,
  OrderByPipe,
  FilterReasonsPipe,
  UtcDatePipe,
  OfflinePresentationFilter,
  SplitRepCallPlanPipe,
  TimeOffFilterPipe,
  SafePipe,
  SchedulerPipe,
  AllocationOrderFilter,
  OrderListFilter,
  ContactSearchPipe,
  TextSearchFilterPipe,
  IndDateFormatPipe,
  ExtendedDatePipe,
  EligibilitiesSearchPipe,
  ActiveConsentsFilter,
  PresentationFilterByPipe,
  ContentCustomSortPipe,
  ScientificActivityPlanFilter,
  MonthTranslatePipe,
  TimeOffSearchPipe,
  TextareaAutosizeDirective,
  DebouncedInputDirective,
  ImagePreloadDirective,
  ColorDirective,
  ProductKeyClickedDirective,
  EmbedChildDirective,
  DebounceClickDirective,
  IndSplitPaneComponent,
  ReportsFiltersComponent,
  IndReportGroupTileComponent,
  CapitalizeFirstLetter,
  IndReportTileComponent,
  MarketScanPageComponent,
  MarketScanDetailsComponent,
  MarketScanListComponent,
  MarketScanTableComponent,
  NextCallObjectiveComponent,
  EventsToolPageComponent,
  EventDetailsComponent,
  EventsListComponent,
  CaptureEventsComponent,
  PdfPreviewComponent,
  ContactVeevaWidgetComponent,
  VeevaWidgetComponent,
  VeevaProfileWidgetComponent,
  ImpactDetailsComponent,
  SurveyComponent,
  ExpensesDetails,
  AccountPlanInfo,
  MarketingPlanInfo,
  AccountPlanTimeline,
  MarketingPlanTimeline,
  AccountPlanActivities,
  CustomNotificationDetails,
  TencentParticipantComponent,
  AppealDetailsComponent,
  ApprovalListComponent,
  ApprovalListItemComponent,
  IoContentCardsComponent,
  IoShortCallDetailsComponent,
  IoContentFilter
]
