import { Component } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { IonNav, PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash';
import { IndPageTitleViewDataModel } from '@omni/models/indPageTitleDataModel';
import { EmailActionType, EmailActivity, EmailViewType } from '../../../classes/activity/email.activity.class';
import { ContentToken, ReplacementToken, ResourceEmailTemplate } from '../../../classes/email-templates/email-template.class';
import { EmailService } from '../../../services/email-templates/email.service';
import { EventsService } from '../../../services/events/events.service';
import { FooterService, FooterViews } from '../../../services/footer/footer.service';
import { TrackingEventNames, TrackService } from '../../../services/logging/tracking.service';
import { NavigationService } from '../../../services/navigation/navigation.service';
import { UIService } from '@omni/services/ui/ui.service';
import { Activity } from '@omni/classes/activity/activity.class';
import { DeviceService } from '@omni/services/device/device.service';
import { IndDropdownListComponent } from '@omni/components/shared/ind-dropdown-list/ind-dropdown-list';
import { IndDropdownListDetailModel } from '@omni/models/indDropdownListModel';
import { ActivityService } from '@omni/services/activity/activity.service';

/**
 * Generated class for the TemplatePreviewComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'template-preview[base-page]',
  templateUrl: 'template-preview.html',
  styleUrls: ['template-preview.scss']
})

export class TemplatePreviewComponent {

  public preview: SafeHtml;
  private previewString: string;
  private previewBody: string;
  public contentBody: string;
  private contentTokens: ContentToken[];
  public template: ResourceEmailTemplate;
  private email: EmailActivity;
  public templatePreviewPageTitle: IndPageTitleViewDataModel;
  private isChangeListenerExisting: boolean = false;

  constructor(
    public readonly footerService: FooterService,
    private readonly navCtrl: IonNav,
    private readonly emailService: EmailService,
    private readonly navService: NavigationService,
    private readonly sanitizer: DomSanitizer,
    private readonly events: EventsService,
    private readonly trackingService: TrackService,
    public readonly translate: TranslateService,
    private readonly uiService: UIService,
    public deviceService: DeviceService,
    public popoverCtrl: PopoverController,
    private activityService: ActivityService,
  ) {

  }

  ngOnInit() {
    this.footerService.initButtons(FooterViews.EmailTemplatesPreview);
    this.email = this.emailService.selectedActivity;
    this.template = this.emailService.emailTemplates.find(temp => temp.indskr_emailtemplateid === this.email.template_id);

    if (this.email.editablePreview) {
      this.previewString = this.email.editablePreview;
      this.previewBody = this.email.description;
      this.contentBody = this.email.editablePreview
      let formattedBody = this._replacePropertyInTag(this.email.editablePreview);
      this.preview = this.sanitizer.bypassSecurityTrustHtml(formattedBody);
    }

    this.contentTokens = cloneDeep(this.emailService.contentTokens);

    this.initPageTitle();

    // this.events.subscribe('replacement-selected', (data) => {
    //   if (data && data.currentSelction && data.currentSelction.replacement_value) {
    //     let title = data.currentSelction.replacement_value;
    //     if (title) {
    //       this.events.publish('updateReplacementTokenTitle', title);
    //     }
    //   }
    //   this.personalizePreview(data);
    // });

    this.events.subscribe('replacement-back-clicked', () => {
      this.goBack(false);
    });
  }

  private initPageTitle(): void {
    let controls = [];
    // if (this.deviceService.isMobileDevice) {
    //   controls.push({
    //     id: 'close',
    //     imgSrc: 'assets/imgs/header_cancel.svg',
    //     name: this.translate.instant('CANCEL'),
    //     isDisabled: false,
    //     align: 'left'
    //   });
    // }

    controls.push({
        id: 'close',
        icon: "chevron-back-outline",
        isDisabled: false,
        align: 'left'
      },
      {
        id: 'done',
        imgSrc: 'assets/imgs/header_complete.svg',
        name: this.translate.instant('DONE'),
        isDisabled: false,
        align: 'right'
      }
    );

    this.templatePreviewPageTitle = {
      id: 'template-preview-title',
      title: this.template && this.template.indskr_name,
      controls: controls,
    };
  }

  public onPageTitleControlClick(id: string) {
    switch (id) {
      case 'close':
        this.goBack(false);
        break;
      case 'done':
        this.goBack(true);
        break;
      default:
        console.log('Unhandled switch case statement');
        break;
    }
  }

  private personalizePreview(data) {
    const rToken: ReplacementToken = data.currentSelction;
    this.previewBody = this.previewBody.replace(new RegExp(data.prevSelection.id, 'g'), rToken.replacement_id);
    this.previewString = this.previewString.replace(new RegExp(data.prevSelection.id, 'g'), rToken.replacement_id);
    this.previewString = this.previewString.replace(new RegExp('<label class="custom">' + data.prevSelection.value + '</label>', 'g'), '<label class="custom">' + rToken.replacement_value + '</label>');
    this.contentBody = this.previewString;
    let formattedBody = this._replacePropertyInTag(this.previewString);
    this.preview = this.sanitizer.bypassSecurityTrustHtml(formattedBody);
  }

  furtherGoBack(saveSelection: boolean) {
    if (!saveSelection) {
      this.trackingService.tracking('EmaiTemplatePersonalizeCanceled', TrackingEventNames.EMAIL);
    }

    if (this.emailService.viewType === EmailViewType.CREATE_FROM_MEETING ||
      this.emailService.viewType === EmailViewType.CREATE_FROM_PHONE_CALL) {
      // //@ts-ignore
      // if (this.navCtrl._elementRef.nativeElement.classList.contains('fullStretchView')) {
      //   //@ts-ignore
      //   this.navCtrl._elementRef.nativeElement.classList.remove('fullStretchView');
      // }
      if (document.getElementsByClassName('modal-wrapper')[0].classList.contains('fullStretchView')) {
        document.getElementsByClassName('modal-wrapper')[0].classList.remove('fullStretchView');
      }
      this.events.publish('inmeeting-activity-back', { 'email': this.email });
      // this.navCtrl.pop({ progressAnimation: false }).then(() => {
      //   this.emailService.setCurrentEmail(this.email);
      // });
    }
    else {
      this.navService.popWithPageTracking().then(() => {
        if(this.emailService.viewType !== EmailViewType.EMAIL_FROM_MEETING_PRESENTATION) {
          this.footerService.initButtons(FooterViews.EmailDetails);
        }
      });
    }
  }

  async goBack(saveSelection: boolean) {
    if (saveSelection) {
      if (this.emailService.isActionTriggeredFromFooter) { this.trackingService.tracking('EmaiTemplatePersonalizeActionBar', TrackingEventNames.EMAIL); }
      else { this.trackingService.tracking('EmaiTemplatePersonalizeComplete', TrackingEventNames.EMAIL); }
      //reset the value for next iteration
      this.emailService.isActionTriggeredFromFooter = false;
      await this.emailService.updateEmailActivity({ description: this.previewBody }, EmailActionType.TEMPLATE_UPDATE);
      this.furtherGoBack(saveSelection);
    } else {
      this.furtherGoBack(saveSelection);
    }
  }

  private openReplacementList(cId, rId) {
    const contentToken: ContentToken = this.contentTokens.find(ct => ct.indskr_contenttokenid == cId);
    contentToken.replacementTokens = contentToken.replacementTokens.map(rt => {
      return { ...rt, is_default: rId === rt.replacement_id };
    });
    this.emailService.selectContentToken(contentToken);
    // this.listSelection();
    this.listSelection(contentToken);
  }

  private async listSelection(contentToken) {
    // this.uiService.showRightPane = false;
    // this.navService.setChildNavRightPaneView(false);
    const prevSelectedValue = contentToken.replacementTokens.find(rt => rt.is_default);
    let dropdownListDetail: IndDropdownListDetailModel = {
      id: 'meeting-type-select',
      data: contentToken.replacementTokens.map(rt => {
        let obj = {
          title: rt.replacement_value,
          id: rt.replacement_id,
          isSelected: false,
        };
        if (prevSelectedValue.replacement_value === rt.replacement_value) {
          obj['isSelected'] = true;
        }
        return obj;
      }),
    };
    let popover = await this.popoverCtrl.create({
      component: IndDropdownListComponent,
      componentProps: { viewData: dropdownListDetail },
      cssClass: 'dropdown-list-view',
      event: event
    })
    await popover.present();
    await popover.onDidDismiss().then(async (data: any) => {
      if (data && data.data && data.data.selectedItems && data.data.selectedItems.length == 1 && data.data.selectedItems[0].id != prevSelectedValue.replacement_id) {
        await this.uiService.displayLoader();
        let cReplacementToken: ReplacementToken;
        contentToken.replacementTokens.forEach(rToken => {
          rToken.is_default = false;
          if(rToken.replacement_id == data.data.selectedItems[0].id) {
            cReplacementToken = rToken;
            rToken.is_default = true;
          }
        });
        let replacemet = {
          prevSelection: { id: prevSelectedValue.replacement_id, value: prevSelectedValue.replacement_value },
          currentSelction: cReplacementToken
        }
        this.personalizePreview(replacemet);
        // this.events.publish('replacement-selected',
        // {
        //   prevSelection: { id: prevSelectedValue.replacement_id, value: prevSelectedValue.replacement_value },
        //   currentSelction: cReplacementToken
        // });
        if (this.deviceService.isMobileDevicePortrait) this.emailService.resetContentToken();
        await this.uiService.dismissLoader();
      }
    });
  }

  ngAfterViewInit() {
    document.getElementsByTagName('template-preview')[0].addEventListener('click', (ev) => {
      const element: Element = (ev.srcElement as Element);
      if (element.parentElement && element.parentElement.className && element.parentElement.className == 'highlightCustomToken') {
        const tokens: string[] = element.parentElement.id.split(':');
        let contentToken = this.contentTokens.find((token) => token.indskr_contenttokenid ==  tokens[0]);
        if(contentToken && contentToken.indskr_iseditable && !this.isChangeListenerExisting) {
          this.editPersonalizedText(tokens[0], tokens[1], element.innerHTML);
        } else if(!contentToken.indskr_iseditable) {
          this.openReplacementList(tokens[0], tokens[1]);
        }
      }
    });
  }

  ngOnDestroy() {
    this.events.unsubscribe('replacement-selected');
    this.events.unsubscribe('replacement-back-clicked');
  }

  private async editPersonalizedText(cId, rId, editedText){
    this.isChangeListenerExisting = true;  // this variable is for preventing the 'change' event listeners from adding multiple times
    const contentToken: ContentToken = this.contentTokens.find(ct => ct.indskr_contenttokenid == cId);
    const rDefaultValue = contentToken.replacementTokens.find(rDV => rDV.is_default).replacement_id;
    this.emailService.selectContentToken(contentToken);
    let tokenDocument = document.getElementsByTagName('template-preview')[0].getElementsByClassName('custom_' + cId)[0];
    editedText = editedText.replace('&amp;', '&');
    // editedText = editedText.replace('&lt;', '<').replace('&gt;', '>'); Disable escaping since user is no longer able to enter '%', '<', '>'

    // In case the template is newly added, have to check replacement ID
    let rIndex: number = this.previewBody.indexOf(cId + '.') + cId.length + 1;
    let rText: string = this.previewBody.substring(rIndex);
    if(rText.indexOf('%custom.')>0) rText = rText.substring(0, rText.indexOf('%custom.'));
    let isReplacementTextDirty: boolean = (this.emailService.isTemplateNewlyAdded || rDefaultValue == rText.substring(0, rText.indexOf('%')) || rText.indexOf('%&thinsp;')<0) ? false : true;
    rText = isReplacementTextDirty ? rText.substring(0, rText.indexOf('%&thinsp;')) : rText.substring(0, rText.indexOf('%'));
    let rIdPreviewBody = (rText == rId) ? rText : editedText
    rIdPreviewBody = rIdPreviewBody.replace(/[[\]{}()*+=:?\/\\^$|#,]/g, "\\$&");
    rIdPreviewBody = rIdPreviewBody.match(/\S/g) ? rIdPreviewBody.replace(/\n/g, '<br>').replace(/[^\S\r\n]/g, '&nbsp;') : rIdPreviewBody;

    tokenDocument.addEventListener('change', (ev) => {
      ev.stopPropagation();
      // update user entered value
      let enteredText = (ev.target as HTMLInputElement).value;
      tokenDocument.innerHTML = enteredText;
      enteredText = enteredText.split('$').join('$$'); //workaround for escaping special replacement patterns of the replace method
      enteredText = enteredText.replace(/[%<>]/g, ""); // Prevent the user from entering '%','<','>'
      let enteredTextForPreview = enteredText.replace(/\n/g, '<br>').replace(/[^\S\r\n]/g, '&nbsp;'); // Allow the user to use 'enter' and multiple spaces
      let textToBeReplaced = isReplacementTextDirty ? '%custom.' + cId + '.' + rIdPreviewBody + '%&thinsp;' : '%custom.' + cId + '.' + rText  + '%';
      this.previewBody = this.previewBody.replace(new RegExp(textToBeReplaced, 'g'), '%custom.' + cId + '.' + enteredTextForPreview + '%&thinsp;');
      this.previewString = this.previewString.replace('class="custom_' + cId + '">' + editedText, 'class="custom_' + cId + '">' + enteredText);
      this.contentBody = this.previewString;
      let formattedBody = this._replacePropertyInTag(this.previewString);
      this.preview = this.sanitizer.bypassSecurityTrustHtml(formattedBody);
      this.isChangeListenerExisting = false;
      ev.stopPropagation();
    })
    tokenDocument.addEventListener('focusout', (ev) => {
      let newTokenDocument = tokenDocument.cloneNode(true);
      tokenDocument.parentNode.replaceChild(newTokenDocument, tokenDocument);
      this.isChangeListenerExisting = false;
    })
  }
  private _replacePropertyInTag(contentBody: any): any {
    return this.activityService.replacePropertyInTag(contentBody);
  }
  openUrl(event:any, contentBody: any) {
    if(event && event.target && (event.target.id == '' || event.target.id == undefined) || this.deviceService.isOffline) return;
    const eventId: string = event.target.id;
    this.activityService.openExternalLink(eventId, contentBody);
  }
}
