import { Component, Input, SimpleChange } from '@angular/core';
import { Report, CoachingReportStatus } from '../../../classes/coaching/report.class';
import { CoachingReportService } from '../../../services/coaching/coaching.report.service';
import { TranslateService } from '@ngx-translate/core';
import { format } from 'date-fns';
import { DatePipe } from '@angular/common';
import { DateTimeFormatsService } from '../../../services/date-time-formats/date-time-formats.service';

/**
 * Generated class for the CoachingItemComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'coaching-item',
  templateUrl: 'coaching-item.html',
  styleUrls:['coaching-item.scss']
})
export class CoachingItemComponent {

  @Input() type: string;
  @Input() report: Report;
  @Input('showTertiary')  showTertiary:boolean;
  @Input('sortBy') sortBy:{text: string, value: string, asc: boolean};

  constructor(
    private coachingReportService: CoachingReportService,
    public translate: TranslateService,
    private datePipe: DatePipe,
    private dateTimeFormatsService: DateTimeFormatsService) { }

  public statusName(statuscode: number): String {
    let coachingStatus;
    coachingStatus = this.coachingReportService.makeStatus(statuscode);
    return coachingStatus;
  }


  /* Custom duration for tile view */
  get calculateDuration(): string {
    return this.coachingReportService.getCoachingDuration(this.report)
  }


  getTertiaryInfo(report: Report) {
    let str: string = '';
    str = this.coachingReportService.getTertiaryInfo(report, this.sortBy);
    return str;
  }

  private getFormattedAndLocalisedDate(value: any) {
    return this.datePipe.transform(value, this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
  }

  get hasGreater(): boolean {
    let isGreater: boolean;
    if (this.report.indskr_coachingqualifyingscore) {
      isGreater = Number(this.report.indskr_coachingqualifyingscore) <= Number(this.report.indskr_coachingscore);
    } else {
      isGreater = true;
    }
    return isGreater;
  }

  showCoachingScore(): boolean {
    let show: boolean = false;
    if (this.coachingReportService.activeTab === "myCoaching") {
      let status = this.report.statuscode;
      if (status == 548910001 || status == 548910000 || status == 5) {
        show = true;
      }
    } else {
      show = !(!this.report.indskr_coachingscore || this.report.indskr_coachingscore === '0.00');
    }
    return show;
  }

}
