import { RepServices } from './../../data-services/rep/rep.services';
import { APP_VERSION } from './../../../config/endpoints.config';
import { DiskService } from './../disk/disk.service';
import { Injectable } from '@angular/core';
import { AuthenticationService } from "../authentication.service";
import { ComponentViewMode } from "../ui/ui.service";
import { DeviceService } from '../device/device.service';
import { FeatureActionsMap } from '../../classes/authentication/user.class';
import { Platform } from "@ionic/angular";
import { DB_KEY_PREFIXES } from '../../config/pouch-db.config';
import _ from 'lodash';
import { sha256 } from 'js-sha256';
import { AppInsightsService } from '@markpieszak/ng-application-insights';
//import { googleTagManagerService } from "./googletagmanager.service";


@Injectable({
  providedIn: 'root'
})
export class TrackService {
  initialized = false;
  countryName: string = '';
  stateName: string = '';
  cityName: string = '';
  offlineEventsStore: {
    eventName: string
    params: {}
  }[] = [];

  constructor(private authenticationOfflineService: AuthenticationService,
    private appInsightsService: AppInsightsService,
    public device: DeviceService,
    public platform: Platform,
    private disk: DiskService,
    public repService: RepServices
    //public googleAnalyticsService: googleTagManagerService
  ) {

  }
  async tracking(eventAction: string, name: string, isStart?: boolean, isComplete?: boolean) {
    let buildVersion = APP_VERSION;
    let splitBuildVer = buildVersion.split('.');
    let releaseVersion = splitBuildVer[0] + (splitBuildVer[1] ? '.' + splitBuildVer[1] : '');
    const insightParams = {
      eventAction: eventAction,
      Time: new Date().getTime().toString(),
      userId: sha256(this.authenticationOfflineService.user.xSystemUserID),
      CRMInstanceID: this.authenticationOfflineService.userConfig.activeInstance.id,
      CRMFriendlyName: this.authenticationOfflineService.userConfig.activeInstance.friendlyName,
      //userName: this.authenticationOfflineService.user.displayName,
      connectionState: this.device.isOffline || this.repService.isOfflineState ? ' Offline' : 'Online',
      timezonecode: this.authenticationOfflineService.user.timezonecode,
      omnipresenceBuildVersion: buildVersion,
      omnipresenceReleaseVersion: releaseVersion
      // countryName : this.countryName,
      // stateName: this.stateName,
      // cityName: this.cityName,
    }
    if (isStart) insightParams['isStart'] = isStart;
    if (isComplete) insightParams['isComplete'] = isComplete;
    if (this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.GOOGLE_ANALYTCS) && this.initialized) {
      insightParams['countryName'] = this.countryName
      insightParams['stateName'] = this.stateName
      insightParams['cityName'] = this.cityName
      let eventName = name ? name : 'customEvent';
      if (this.device.isOffline) this.addToOfflineQueue({ eventName: eventName, params: insightParams })
      else this.appInsightsService.trackEvent(eventName, insightParams)
    }
  }
  async addToOfflineQueue(eventData) {
    if (eventData) {
      await this.disk.updateOrInsert(DB_KEY_PREFIXES.ANALYTICS_EVENTS, (doc) => {
        if (!doc || !doc.raw) {
          doc = {
            raw: []
          };
        }
        doc.raw.push(eventData);
        return doc;
      })
    }
  }
  async pushAppAnalyticsDataFromOffline() {
    await this.disk.retrieve(DB_KEY_PREFIXES.ANALYTICS_EVENTS, true).then((doc) => {
      if (doc && doc.raw && Array.isArray(doc.raw) && doc.raw.length > 0) {
        this.offlineEventsStore = doc.raw
        _.remove(this.offlineEventsStore, (event) => {
          this.appInsightsService.trackEvent(event.eventName, event.params);
          return true
        })
      }
    })
    await this.disk.updateOrInsert(DB_KEY_PREFIXES.ANALYTICS_EVENTS, (doc) => {
      doc = {
        raw: []
      };
      return doc;
    })
  }
}

export enum TrackingEventNames {
  LOGIN = 'Login',
  LOGOUT = 'Logout',
  SYNC = 'Sync',
  ACTIVITY = 'Activity_Scheduling_and_Recording',
  PHONECALL = 'Phone Call',
  CONTACTS = 'Contacts',
  ACCOUNTS = 'Accounts',
  ACCOUNT_PLANS = "Account Plans",
  PRESENTATIONS = 'Presentations',
  RESOURCES = 'Resources',
  CALLPLAN = 'CallPlan',
  ALLOCATIONS = 'Allocations',
  COACHING = 'Coaching',
  SCHEDULER = 'Scheduler',
  TIMEOFF = 'TimeOff',
  DIGITAL = 'Digital_Detailing',
  CONSENT = 'Consent',
  EMAIL = 'Triggered_Email',
  ACCOUNTPLANNING = 'Account_Planning',
  SCIENTIFICACTIVITYPLAN = 'Scientific_Plans',
  CUSTOMER_INQUIRY = 'Customer_Inquiries',
  APPSETTING = 'App_Setting',
  ORDERMANAGEMENT = 'Order_Management',
  LANGUAGESETTING = 'Language_setting',
  OPPORTUNITYMANAGEMENT = 'Opportunity_Management',
  XPERIENCES = 'Xperiences',
  INSIGHTSPLUS = 'Insights+',
  CONTACT_CREATE = 'Contact_Create',
  CONTACT_EDIT = 'Contact_Edit',
  ACCOUNT_CREATE = 'Account_Create',
  ACCOUNT_EDIT = 'Account_Edit',
  GENEE = 'Genee',
  EVENTS = 'Events',
  PLANS_LIST = 'Plans_List',
  EDGE_ANALYTICS = 'EdgeAnalytics',
  SURGERY_ORDER = 'Surgery Order',
  PLAN_TAB_TO_DO = 'PlanToDo',
  NOTIFICATIONS = 'Notifications',
  PROCEDURE_TRACKER = "Bulk Procedure Log",
  CUSTOMER_SCAN = "Customer Scan",
  SURVEY = "Survey",
  ACCOUNT_VISIT = "Account Visit",
}

export enum TrackingGeneeEventProperties {
  GENEE_START = 'GeneeStart',
  GENEE_KPI_SUGGESTION = 'GeneeKPISuggestion',
  GENEE_LIST_SUGGESTION = 'GeneeListSuggestion',
  GENEE_ACTIVITIES_SUGGESTION = 'GeneeActivitiesSuggestion',
  GENEE_VOICE_CLICK = 'GeneeVoiceClick',
  GENEE_MUTE_BUTTON = 'GeneeMuteButton',
  GENEE_QUICK_INFO = 'GeneeQuickInfo',
  GENEE_NOTIFICATION_PILL = 'GeneeNotificationPill',
  GENEE_SUGGESTED_ACTION_CLICK = 'GeneeSuggestedActionClick',
  GENEE_FEEDBACK_THUMBS_UP = 'GeneeFeedbackThumbsup',
  GENEE_FEEDBACK_THUMBS_DOWN = 'GeneeFeedbackThumbsdown',
  GENEE_SCHEDULE_MEETING = 'GeneeScheduleMeeting',
  GENEE_SEND_MESSAGE = 'GeneeSendMessage',
  GENEE_CLEAR_CHAT = 'GeneeClearChat',
  GENEE_CUSTOMERS_NEAR_ME_SUGGESTION = 'GeneeCustomersNearMeSuggestion',
  GENEE_CUSTOMERS_NEAR_ME_CLICK_CUSTOMERS = 'GeneeCustomersNearMeClickCustomers',
  GENEE_CUSTOMERS_NEAR_ME_SCHEDULE_MEETING = 'GeneeCustomersNearMeSchedulemeeting',
  GENEE_CUSTOMERS_NEAR_ME_SEND_MESSAGE = 'GeneeCustomersNearMeSendMessage',

  GENEE_CUSTOMERS_NOT_MET_SUGGESTION = 'GeneeCustomersNotMetSuggestion',
  GENEE_CUSTOMERS_NOT_MET_CLICK_CUSTOMERS = 'GeneeCustomersNotMetClickCustomers',
  GENEE_CUSTOMERS_NOT_MET_SCHEDULE_MEETING = 'GeneeCustomersNotMetSchedulemeeting',
  GENEE_CUSTOMERS_NOT_MET_SEND_MESSAGE = 'GeneeCustomersNotMetSendMessage',

  GENEE_INCOMPLETE_MEETING_SUGGESTION = 'GeneeIncompleteMeetingSuggestion',
  GENEE_CLICK_ON_INCOMPLETE_MEETINGG = 'GeneeClickOnIncompleteMeeting',
  GENEE_SHOW_MORE = 'GeneeShowmore',

  GENEE_CUSTOMER_LIST_SUGGESTION = 'GeneeCustomerlistSuggestion',
  GENEE_CUSTOMER_LIST_CUSTOMER_CLICK = 'GeneeCustomerlistCustomerClick',

  GENEE_CUSTOMER_LIST_BY_CITY_SUGGESTION = 'GeneeCustomerlistbycitySuggestion',
  GENEE_CUSTOMER_LIST_BY_CALLPLANS_SUGGESTION = 'GeneeCustomerlistbycallplansSuggestion',

  GENEE_CUSTOMER_NOT_MET_BY_CALLPLAN = 'Geneecustomernotmetbycallplan',
  GENEE_CUSTOMERS_NOT_MET_BY_CALLPLAN_SCHEDULE_MEETING = 'GeneecustomersnotmetbycallplanSchedulemeeting',
  GENEE_CUSTOMERS_NOT_MET_BY_CALLPLANS_SEND_MESSAGE = 'GeneeCustomersnotmetbycallplansSendMessage',

  GENEE_WORKING_DAYS = 'GeneeWorkingdays',
  GENEE_TOTAL_MEETING_COMPLETED = 'GeneeTotalmeetingcompleted',
  GENEE_TIMEOFF_BY_USER = 'Geneetimeoffbyuser',
  GENEE_TIMEOFF = 'Geneetimeoff',
  GENEE_CALLPLAN_GOALS = 'Geneecallplangoals',
  GENEE_MEETINGS_REACH = 'GeneeMeetingsReach',
  GENEE_MEETING_WITHOUT_PRESENTATION = 'Geneemeetingswithoutpresentation',
  GENEE_MEETINGS_WITH_PRESENTATION = 'Geneemeetingswithpresentation',
}
