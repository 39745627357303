import { DatePipe } from "@angular/common";
import { ChangeDetectionStrategy, Component, ElementRef, Input, SimpleChanges, ViewChild } from "@angular/core";
import { IonList, LoadingController, NavController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { PhoneActivity } from "@omni/classes/activity/phone.activity.class";
import { EventActivity } from "@omni/classes/events-tool/event.class";
import { EventDetailsComponent } from "@omni/components/events-tool/event-details/event-details";
import {
  SurgeryOrderDetailsComponent
} from "@omni/components/surgery-order/surgery-order-details/surgery-order-details";
import { AccountDataService } from "@omni/data-services/accounts/account.data.service";
import { EventsToolDataService } from "@omni/data-services/event/events-tool.data.service";
import { DynamicFormsService } from "@omni/services/dynamic-forms/dynamic-forms-service";
import { EventsToolService } from "@omni/services/events-tool/events-tool.service";
import { format, getDate } from "date-fns";
import _ from "lodash";
import { ActivityType } from "../../../classes/activity/activity.class";
import { AppointmentActivity } from "../../../classes/activity/appointment.activity.class";
import { FeatureActionsMap } from "../../../classes/authentication/user.class";
import { CaseActivity } from "../../../classes/case-intake/case-activity.class";
import { PhoneCallDetailsComponent } from "../../../components/phonecall/phone-call-details/phone-call-details";
import { ActivityDataService } from "../../../data-services/activity/activity.service";
import { CaseManagementDataService } from "../../../data-services/case-management/case-management.data.service";
import { AccountOfflineService } from "../../../services/account/account.offline.service";
import { ActivityService } from "../../../services/activity/activity.service";
import { AuthenticationService } from "../../../services/authentication.service";
import { AccesingMode, CaseManagementService } from "../../../services/case-management/case-management.service";
import { DateTimeFormatsService } from "../../../services/date-time-formats/date-time-formats.service";
import { DeviceService } from "../../../services/device/device.service";
import { EventsService } from "../../../services/events/events.service";
import { FooterService, FooterViews } from "../../../services/footer/footer.service";
import { GlobalUtilityService } from "../../../services/global-utility.service";
import { NavigationService, PageName } from "../../../services/navigation/navigation.service";
import { NotificationService, ToastStyle } from "../../../services/notification/notification.service";
import { ComponentViewMode, UIService } from "../../../services/ui/ui.service";
import { ActivitiesDetailsPaneComponent } from "../../activity/activities-details-pane/activities-details-pane";
import { CaseManagementDetailComponent } from "../../case-management/case-management-detail/case-management-detail";
import { OrderDetailsComponent } from "../../order-management/order-details/order-details";
import {
  ProcedureTrackerDetailsComponent
} from "./../../surgery-order/procedure-tracker-details/procedure-tracker-details";
import { StoreCheckActivity } from "@omni/classes/activity/store-check.activity.class";
import { StoreCheckDetailsComponent } from "@omni/components/activity/store-check-activity/store-check-details";
import { CustomerAssessService } from "@omni/services/customer-assess/customer-assess.service";
import { AssessmentTemplate, SurveyCategory, SurveyFrequency, SurveyStatus, TemplateType } from "@omni/classes/customer-assessment/assessment-template.class";
import { SurveyDetailsComponent } from "@omni/components/survey/survey-details/survey-details";
import { CustomerSurveyService } from "@omni/services/customer-survey/customer-survey.service";
import { CustomerAssessment } from "@omni/classes/customer-assessment/customer-assessment.class";

/**
 * Generated class for the AccountTimelineComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'account-timeline',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: 'account-timeline.html',
  styleUrls:['account-timeline.scss']
})
export class AccountTimelineComponent {
  public months: Array<String> = ["January", "Febuary", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"];
  public weekDay: Array<String> = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
  public getday = getDate;
  public groupedActivitiesByAccount: Array<any> = [];
  @Input() accountActivities: any;
  public accountTimelineFilter: string = 'All Activities';
  public userName: string;
  public filterOptions: Array<{value:string,isSelected:boolean,displayText:string}> = [];
  @ViewChild(IonList, { read: ElementRef, static: false }) list: ElementRef;
  private formattedSurveysList: any = [];
  constructor(
    public authenticationService: AuthenticationService,
    public accountOfflineService: AccountOfflineService,
    public accountDataService: AccountDataService,
    public device: DeviceService,
    public uiService: UIService,
    public activityService: ActivityService,
    public activityDataService: ActivityDataService,
    public navCtrl: NavController,
    public footerService: FooterService,
    private navService: NavigationService,
    public caseService: CaseManagementService,
    public caseDataService: CaseManagementDataService,
    private loadCtrl: LoadingController,
    private translate: TranslateService,
    public utilityService:GlobalUtilityService,
    private notifiationService: NotificationService,
    private events: EventsService,
    public readonly dateTimeFormatsService: DateTimeFormatsService,
    public datepipe: DatePipe,
    public eventsToolDataService: EventsToolDataService,
    public eventsToolService: EventsToolService,
    private dynamicFormService: DynamicFormsService,
    private customerAssessService: CustomerAssessService,
    private customerSurveyService: CustomerSurveyService
  ) {
    this.getUserInfo();
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes['accountActivities']) {
      this.accountActivities = changes['accountActivities'].currentValue
      this.groupedActivitiesByAccount = [];
      this.accountTimelineFilter = 'All Activities'
      this.initFilterOptions();
      this.getGroupedActivities(this.accountTimelineFilter);
    }
  }
  ngOnInit() {
    console.log('Hello AccountTimelineComponent Component', this.accountActivities);
    this.accountTimelineFilter = 'All Activities';
    this.initFilterOptions();
    this.getGroupedActivities(this.accountTimelineFilter);
    // this.authenticationService.getOfflineUser().then((user)=>{
    //   if(user){
    //     this.userName = user.displayName;
    //     console.log("see this" +this.userName);
    //   }
    //   return;
    // });
  }

  async ngDoCheck() {
    if(this.uiService.timelineRefreshRequired){
      this.uiService.timelineRefreshRequired = false;
      this.uiService.displayLoader();
      await this.accountDataService.getAccountTimelineInfo(this.accountOfflineService.selected);
      this.getGroupedActivities(this.accountTimelineFilter);
      this.uiService.dismissLoader();
    }
  }

  ngAfterViewInit(){
    this.uiService.scrollListToView(this.list);
  }
  private initFilterOptions():void {
    this.filterOptions = [];
    if ((this.accountOfflineService.selected && this.accountOfflineService.selected.activitesByAccount && this.accountOfflineService.selected.activitesByAccount.length > 0) || (this.accountOfflineService.selected.events && this.accountOfflineService.selected.events.length > 0)) {
      this.filterOptions.push({value:'All Activities',isSelected:true,displayText:'ACCOUNT_TIMELINE_OPTION_ALL_ACTIVITY'});
      this.filterOptions.push({value:'My Activities',isSelected:false,displayText:'MY_ACTIVITIES'});
      if(this.accountOfflineService.selected.activitesByAccount.some(activity => activity.type == ActivityType.Appointment)){
        this.filterOptions.push({value:'Appointment',isSelected:false,displayText:'ACCOUNT_TIMELINE_OPTION_MEETINGS'})
      }
      if(this.accountOfflineService.selected.activitesByAccount.some(activity => activity.type == ActivityType.CaseIntake)){
        this.filterOptions.push({value:'CaseIntake',isSelected:false,displayText:'CUSTOMER_INQUIRIES_WITH_TEXT'})
      }
      if(this.accountOfflineService.selected.activitesByAccount.some(activity => activity.type == ActivityType.Order)){
        this.filterOptions.push({value:'indskr_order',isSelected:false,displayText:'ACCOUNT_TIMELINE_OPTION_ORDERS'})
      }
      if(this.accountOfflineService.selected.activitesByAccount.some(activity => activity.type == ActivityType.PhoneCall)){
        this.filterOptions.push({value:ActivityType.PhoneCall,isSelected:false,displayText:'PHONE_CALLS'})
      }
      if(this.accountOfflineService.selected.activitesByAccount.some(activity => activity.type == ActivityType.SurgeryOrder || activity.type == ActivityType.ProcedureTracker)){
        this.filterOptions.push({value:'SurgeryOrder' || ActivityType.ProcedureTracker,isSelected:false,displayText:'PROCEDURE_LOGS'})
      }
      if (!_.isEmpty(this.accountOfflineService.selected.events) && this.authenticationService.hasFeatureAction(FeatureActionsMap.EVENT_TOOL)) {
        this.filterOptions.push({ value: 'Event', isSelected: false, displayText: 'EVENTS' })
      }
      if(this.accountOfflineService.selected.activitesByAccount.some(activity => activity.type == ActivityType.StoreCheck)){
        this.filterOptions.push({value:'StoreCheck',isSelected:false,displayText:'STORE_CHECKS'})
      }
      if (!_.isEmpty(this.formattedSurveysList) && this.authenticationService.hasFeatureAction(FeatureActionsMap.ACCOUNT_SURVEY)) {
        this.filterOptions.push({ value: ActivityType.CustomerSurvey, isSelected: false, displayText: this.translate.instant('SURVEY') })
      }
    }
  }

  private getUserInfo(): void {
    if (this.device.isOffline) {
      this.authenticationService.getOfflineUser().then((user) => {
        if (user) this.userName = user.displayName;
        else console.warn('User info not available..');
      });
    } else {
      if (this.authenticationService.user) {
        this.userName = this.authenticationService.user.displayName;
      } else console.warn('User info not available..');
    }
  }

  getGroupedActivities(filterValue: string) {
    let items: any = [];
    items = this.combineEventsAndActivities(items);
    if(this.authenticationService.hasFeatureAction(FeatureActionsMap.ACCOUNT_SURVEY)) items = this.combineSurveysAndActivities(items);    
    // Set pre-selected filter value 
    // #1. From Account Profile Survey
    if(this.accountOfflineService.isOpenedSurveyTimelineFromAccountProfile && !_.isEmpty(this.filterOptions)) {
      this.filterOptions.map(option=> {
        option.isSelected = option.value == ActivityType.CustomerSurvey ? true : false;
      });
      this.accountTimelineFilter = ActivityType.CustomerSurvey;
      filterValue = ActivityType.CustomerSurvey;
    }
    this.groupedActivitiesByAccount = this.accountOfflineService.getgroupedActivitiesByAccount(items,filterValue);
    console.log(this.groupedActivitiesByAccount);
  }

  onTimelineTypeChange(filterValue: string) {
    this.getGroupedActivities(this.accountTimelineFilter);
    this.accountOfflineService.isOpenedSurveyTimelineFromAccountProfile = false;
  }

  public formatHeader(value): string {
    // let edge = window.navigator.userAgent.indexOf('Edge/');
    // let formatedDate: any;
    // if (edge > 0) {
    //   let tempDate = value.split("-");
    //   let currentMonthIndex: number;
    //   let filteredMonth = this.months.filter((month: string, index: number) => {
    //     let compare = month.substring(0, 3);
    //     currentMonthIndex = index;
    //     return compare === tempDate[1];
    //   });
    //   let realDate = new Date(Date.parse(tempDate[0] + " " + tempDate[1] + " " + tempDate[2]));
    //   let dayOfTheWeek = this.weekDay[realDate.getDay() - 1];
    //   tempDate = dayOfTheWeek + " " + filteredMonth[filteredMonth.length - 1] + " " + tempDate[0];
    //   formatedDate = parseInt(window.navigator.userAgent.substring(edge + 5, window.navigator.userAgent.indexOf('.', edge)), 10) >= 16 ? tempDate : value;
    // } else {
    //   formatedDate = format(value, 'MMMM YYYY');
    // }
    try {
      return this.datepipe.transform(value, 'MMMM y', undefined, this.translate.currentLang)
    } catch (error) {
      console.error("error " + error);
    }
    return value;
  }

  openActivityDetails = async (selActivity) => {
    //prevent navigation issue
    if ((this.accountOfflineService.accessedAccountListFrom == PageName.ContactDetailsComponent && this.accountOfflineService.accountPageMode === ComponentViewMode.READONLY)
      || this.accountOfflineService.accountPageMode === ComponentViewMode.PREVIEW
      || this.dynamicFormService.isOpenedAffiliatedContactOnAccount
      || this.dynamicFormService.isOpenedAffiliatedAccountOnAccount
      || this.caseService.accessedFrom == AccesingMode.INMEETING) {
      console.log("The current activity cannot be opened to avoid possible problems with navigation loop");
      return;
    }
    if (this.device.isOffline) {
      this.notifiationService.notify(this.translate.instant('ACCOUNT_TIMELINE_NO_INTERNET'),'Account Timeline','top',ToastStyle.DANGER);
      return;
    }

    if (selActivity.type === ActivityType.CaseIntake) {
      const loader = await this.loadCtrl.create({});
      loader.present()
      await this.caseDataService.findCaseById(selActivity.ID).then(async res => {
        if (res) {
          for (let key in res) {
            if (key.charAt(0) === "_") {
              var a = key.substring(1, key.length);
              res[a] = res[key];
              delete res[key];
            }
          }
          let iCase: CaseActivity;
          iCase = new CaseActivity(res);
          iCase = await this.caseService.reInitDataDependency(iCase);
          this.activityService.selectedActivity = iCase;
          this.caseService.accessedFrom = AccesingMode.ACCOUNT_TIMELINE;
          this.navService.pushChildNavPageWithPageTracking(CaseManagementDetailComponent, PageName.CaseManagementDetailComponent, PageName.AccountPageComponent, { from: 'AccountTimeline' });
          this.caseService.assignSelectedCase(iCase);
          this.footerService.initButtons(FooterViews.CUSTOMER_INQUIRY);
        }
        loader.dismiss();
      }).catch(() => {
        console.log("Failed to load customer inquiry:" + selActivity.ID);
        loader.dismiss();
      });
    }
    else if (selActivity.type == ActivityType.Order) {
      let foundActivity = this.activityService.getActivityByID(selActivity.ID);
      if(foundActivity){
        this.activityService.selectedActivity = foundActivity;
      }else{
        this.uiService.displayLoader();
        foundActivity = await this.activityDataService.getRealTimeActivityDetails(selActivity);
        this.uiService.dismissLoader();
      }
      this.activityService.selectedActivity = (foundActivity) ? foundActivity : selActivity;
      this.navService.pushChildNavPageWithPageTracking(OrderDetailsComponent, PageName.OrderDetailsComponent, PageName.AccountPageComponent, { from: 'AccountTimeline', activity: this.activityService.selectedActivity });
    }
    else if (selActivity.type == ActivityType.ProcedureTracker) {
      let foundActivity = this.activityService.getActivityByID(selActivity.ID);
      if(foundActivity){
        this.activityService.selectedActivity = foundActivity;
      }else{
        this.uiService.displayLoader();
        foundActivity = await this.activityDataService.getRealTimeActivityDetails(selActivity);
        this.uiService.dismissLoader();
      }
      if (!foundActivity) return;
      this.navService.pushChildNavPageWithPageTracking(ProcedureTrackerDetailsComponent, PageName.ProcedureTrackerDetailsComponent, PageName.AccountPageComponent, { from: 'AccountTimeline', activity: this.activityService.selectedActivity });
    }
    else if (selActivity.type == ActivityType.Appointment) {

      let activity = this.activityService.getActivityByID(selActivity.ID);
      this.activityService.prevSelectedActivity = this.activityService.selectedActivity;

      if (activity) {
        this.activityService.prevSelectedActivity = this.activityService.selectedActivity;
        this.activityService.selected = activity;
      }
      this.uiService.displayLoader();
      // activity = await this.activityDataService.getRealTimeActivityDetails(selActivity);
      let tempActivity:AppointmentActivity = new AppointmentActivity({
        activityid: selActivity.ID,
        activitytypecode: "appointment",
        indskr_ownerfullname: selActivity.meetingOwner,
        indskr_ownerid: selActivity.ownerId,
        location: selActivity.ownerId,
        scheduledend: selActivity.scheduledEnd.getTime().toString(),
        scheduledstart: selActivity.scheduledStart.getTime().toString(),
        statuscode: selActivity.status,
        statecode: selActivity.state,
        subject: selActivity.subject,
      });
      await this.activityDataService.updateActivityDetails(activity ? activity : tempActivity);
      this.accountOfflineService.tempSelected = _.cloneDeep(this.accountOfflineService.selected);
      this.uiService.dismissLoader();
      this.activityService.selected = (activity) ? activity : tempActivity;
      this.activityService.selectedActivity.accessedFrom = PageName.AccountTimelineComponent;
      this.uiService.showNewActivity = false;
      this.activityDataService.activityDetailsLoaded = true;
      this.uiService.showNewActivity = false;
      this.activityDataService.activityDetailsLoaded = true;
      await this.navService.pushChildNavPageWithPageTracking(ActivitiesDetailsPaneComponent, PageName.ActivitiesDetailsPaneComponent, PageName.AccountPageComponent, { from: 'AccountTimeline' });
      //this.activityDataService.updateActivityDetails(activity);
      this.events.publish("detectChangesOnActivityDetails");
      if (this.activityService.selectedActivity) {
        switch (selActivity.type) {
          case ActivityType.Appointment:
            this.footerService.initButtons(FooterViews.Activities);
            break;
          default:
            this.footerService.initButtons('');
        }
      }
    } else if (selActivity.type == ActivityType.PhoneCall) {
      let activity = this.activityService.getActivityByID(selActivity.ID);
      if (activity) {
        this.activityService.prevSelectedActivity = this.activityService.selectedActivity;
        this.activityService.selected = activity;
      }
      await this.uiService.displayLoader();
      //activity = await this.activityDataService.getRealTimeActivityDetails(selActivity);
      // await this.activityDataService.updateActivityDetails(activity);
      await this.activityDataService.getPhonecallActivityByIdOnline(selActivity.ID, selActivity.scheduledStart.getTime().toString(), selActivity.scheduledEnd.getTime().toString(), selActivity.scheduledStart.getTime().toString()).then(async (response) => {
        activity = new PhoneActivity(response);
        await this.activityDataService.updateActivityForConfigField(activity, response, ActivityType.PhoneCall);
        await this.activityDataService._appendPhoneCallDetailsToActivity(activity, response, false);
        this.accountOfflineService.tempSelected = _.cloneDeep(this.accountOfflineService.selected);
        this.activityDataService.activityDetailsLoaded = true;
      })
      await this.uiService.dismissLoader();
      this.activityService.selected = activity;
      this.activityService.selectedActivity.accessedFrom = PageName.AccountTimelineComponent;
      this.uiService.showNewActivity = false;
      this.activityDataService.activityDetailsLoaded = true;
      await this.navService.pushChildNavPageWithPageTracking(PhoneCallDetailsComponent, PageName.PhoneCallDetailsComponent, PageName.AccountPageComponent, { from: 'AccountTimeline' });

      this.events.publish("detectChangesOnPhoneCallActivityDetails");
      this.footerService.initButtons(FooterViews.PHONE_CALL);
    }else if (selActivity.type == ActivityType.SurgeryOrder) {
      let foundActivity = this.activityService.getActivityByID(selActivity.ID);
      if(foundActivity){
        this.activityService.selectedActivity = foundActivity;
      }else{
        this.uiService.displayLoader();
        foundActivity = await this.activityDataService.getRealTimeActivityDetails(selActivity);
        this.uiService.dismissLoader();
      }
      this.activityService.selectedActivity = (foundActivity) ? foundActivity : selActivity;
      this.navService.pushChildNavPageWithPageTracking(SurgeryOrderDetailsComponent, PageName.SurgeryOrderDetailsComponent, PageName.AccountPageComponent, { from: 'AccountTimeline', activity: this.activityService.selectedActivity });
      this.footerService.initButtons(FooterViews.SURGERY_ORDER_ACTIVITY);
    }
    else if (selActivity.type == ActivityType.Event) {
      const eventsToolData = this.eventsToolService.eventsToolData;
      const foundEvent = eventsToolData.find(e=> {
        if(e.ID) {
          return e.ID === selActivity.ID;
        }else if (e.offlineId) {
          return e.offlineId === selActivity.ID;
        }else {
          return false;
        }
      });
      if(!_.isEmpty(foundEvent)) {
        selActivity = foundEvent;
        this.eventsToolService.selectedEventOnEventsTool = foundEvent;
      }else {
        return;
      }
      if(!this.device.isOffline) {
        this.uiService.displayLoader();
        await this.eventsToolDataService.fetchEventRealTimeDetails(selActivity as EventActivity).then((updatedEvent: EventActivity) => {
          selActivity = updatedEvent;
          this.uiService.dismissLoader();
        }).catch(err => {
          console.log(err);
          this.uiService.dismissLoader();
        });
      }
      this.activityService.prevSelectedActivity = this.activityService.selectedActivity;
      this.activityService.selectedActivity = selActivity;
      this.navService.pushChildNavPageWithPageTracking(EventDetailsComponent, PageName.EventDetailsPageComponent, PageName.AccountPageComponent, {
        from: 'AccountTimeline', event: this.activityService.selectedActivity,
      });
      this.eventsToolService.setSelectedEventUpdates(this.activityService.selectedActivity as EventActivity);
      this.footerService.initButtons(FooterViews.EVENTS_DETAILS);
    }  else if(selActivity.type == ActivityType.StoreCheck){
      let activity = this.activityService.getActivityByID(selActivity.ID);
      this.activityService.prevSelectedActivity = this.activityService.selectedActivity;

      if (activity) {
        this.activityService.prevSelectedActivity = this.activityService.selectedActivity;
        this.activityService.selected = activity;
      }
      await this.uiService.displayLoader();
      let tempActivity: StoreCheckActivity = new StoreCheckActivity({
        activityId: selActivity.ID,
        activitytypecode: "appointment",
        indskr_ownerfullname: selActivity.meetingOwner,
        indskr_ownerid: selActivity.ownerId,
        location: selActivity.ownerId,
        scheduledend: selActivity.scheduledEnd.getTime().toString(),
        scheduledstart: selActivity.scheduledStart.getTime().toString(),
        statuscode: selActivity.status,
        statecode: selActivity.state,
        subject: selActivity.subject,
        indskr_type: ActivityType.StoreCheck
      });
      this.activityService.selected = (activity) ? activity : tempActivity;
      await this.activityDataService.updateActivityDetails(activity ? activity : tempActivity);
      this.accountOfflineService.tempSelected = _.cloneDeep(this.accountOfflineService.selected);
      this.uiService.dismissLoader();
      this.activityService.selectedActivity.accessedFrom = PageName.AccountTimelineComponent;
      this.uiService.showNewActivity = false;
      this.activityDataService.activityDetailsLoaded = true;
      this.uiService.showNewActivity = false;
      this.activityDataService.activityDetailsLoaded = true;
      await this.navService.pushChildNavPageWithPageTracking(StoreCheckDetailsComponent, PageName.StoreCheckDetailsComponent, PageName.AccountPageComponent, { from: 'AccountTimeline' });
    }
    else if (selActivity.type == ActivityType.CustomerSurvey) {
      if(this.device.isOffline) {
        this.notifiationService.notify(this.translate.instant("SURVEY_ACTIVITIES_NOTIFY_ONLINE"), "survey-activities", "top", ToastStyle.DANGER, 3000, true);
        return;
      }
      if(!_.isEmpty(selActivity)) {
        this.openSubmittedSurveyDetails(selActivity);
      }
    }
  }
  private combineEventsAndActivities(items: any) {
    if (this.accountOfflineService.selected.activitesByAccount) {
      items = [...this.accountOfflineService.selected.activitesByAccount];
    }
    if (this.accountOfflineService.selected.events && this.authenticationService.hasFeatureAction(FeatureActionsMap.EVENT_TOOL)) {
      this.accountOfflineService.selected.events.forEach(er => {
        er.type = ActivityType.Event;
        er['scheduledStart'] = er.startDate;
        items.push(er);
      });
    }
    return items;
  }

  private combineSurveysAndActivities(items: any) {
    const accountId: string = this.accountOfflineService.selected.id;
    this.formattedSurveysList = [];
    let surveysByAccountId: any = this.customerAssessService.accountSurveysForTimeline.filter(survey => survey.surveyStatus != SurveyStatus.SAVED) || [];
    if(!_.isEmpty(surveysByAccountId)) {
      let groupedResponsesByDate: any = [];
      groupedResponsesByDate = _.groupBy(surveysByAccountId, 'indskr_assessmentdate');
      groupedResponsesByDate = _.values(groupedResponsesByDate);

      groupedResponsesByDate.forEach((resp) => {
        let activity: any = {};

        const templateId: string = resp[0]['_indskr_template_value'];
        const foundTemplate: AssessmentTemplate = this.customerAssessService.surveytemplates.find(survey=> survey.indskr_assessmenttemplateid == templateId);

        let surveyFrequency: string = foundTemplate['indskr_surveyfrequency'] ?  foundTemplate['indskr_surveyfrequency'] == SurveyFrequency.ONCE ? this.translate.instant("ONE_TIME") : this.translate.instant("RECURRING") : '';
        activity['type'] = ActivityType.CustomerSurvey;
        activity['subject'] = resp[0]['indskr_name'] || '';
        activity['assessmentId'] = resp[0]['indskr_customerassessmentid'];
        activity['scheduledStart'] = resp[0]['indskr_assessmentdate'];
        activity['surveyFrequency'] = surveyFrequency;
        activity['surveyedBy'] = resp[0]['_modifiedby_value_Formatted'] || '';
        activity['templateId'] = templateId;
        activity['category'] = SurveyCategory.ACCOUNT;
        // activity['rawData'] = resp;
        items.push(activity);
        this.formattedSurveysList.push(activity);
      });
    }
    return items;
  }


  getSelectedText(value){
    const tot = this.groupedActivitiesByAccount.reduce((total,value)=> (total + value.list.length),0);
    switch (value){
      case 'All Activities':
          return `${this.translate.instant('ALL_ACTIVITIES')} (${tot})`;
      case 'My Activities':
        return `${this.translate.instant('MY_ACTIVITIES')} (${tot})`;
      case 'Appointment':
          return  `${this.translate.instant('MEETINGS')} (${tot})`;
      case 'Email':
          return `${this.translate.instant('MESSAGES')} (${tot})`;
      case 'Allocation_Order':
          return `${this.translate.instant('ALLOWCATION_ORDERS')} (${tot})`;
      case 'CaseIntake':
          return `${this.utilityService.globalCustomerText} Inquiries (${tot})`;
      case 'indskr_order':
          return `${this.translate.instant('SALES_ORDERS')} (${tot})`;
      case 'Event':
        return `${this.translate.instant('EVENTS')} (${tot})`;
      case ActivityType.SurgeryOrder:
        return `${this.translate.instant('PROCEDURE_LOGS')} (${tot})`;
      case ActivityType.PhoneCall:
        return `${this.translate.instant('PHONE_CALLS')} (${tot})`;
      case ActivityType.ProcedureTracker:
        return `${this.translate.instant('BULK_PROCEDURE_LOGS')} (${tot})`;
      case ActivityType.CustomerSurvey:
        return `${this.translate.instant('SURVEY')} (${tot})`;
    }
  }


formattedDate(val) {
    return format(val, this.dateTimeFormatsService.timeToUpper) || '';
  }

  public async openSubmittedSurveyDetails(survey: any) {
    if(this.device.isOffline) {
      this.notifiationService.notify(this.translate.instant("SURVEY_ACTIVITIES_NOTIFY_ONLINE"), "contact-timeline", "top", ToastStyle.DANGER, 3000, true);
      return;
    }
    //check selected survey tempalte id
    const filteredSurveyList = this.customerAssessService.surveytemplates.filter(template => template.indskr_type == TemplateType.SURVEY);
    const templateId: string = survey['templateId'];
    const foundTemplate: AssessmentTemplate = filteredSurveyList.find(survey=> survey.indskr_assessmenttemplateid == templateId);
    this.customerSurveyService.setCurrentSurvey(foundTemplate);
    try {
      await this.navService.pushChildNavPageWithPageTracking(SurveyDetailsComponent, PageName.SurveyDetailsComponent, PageName.AccountDetailsComponent, { appointmentId: '', from: PageName.AccountDetailsComponent }).then(async()=>{
        this.navService.setChildNavRightPaneView(true); 
        this.uiService.dismissLoader();
        this.customerSurveyService.selectedAccountForSurvey = this.accountOfflineService.selected;
        let loader =await this.loadCtrl.create();
        try {
          //map survey based on survey responses
          loader.present();
          const responseData = await this.customerAssessService.fetchContactAccountSurveyForDetails(survey, SurveyCategory.ACCOUNT);
          if(!_.isEmpty(responseData)) {
            const formattedSurvey: CustomerAssessment = await this.customerAssessService.mapCustomerSurveyRespForTimeline(responseData);
            if (!_.isEmpty(formattedSurvey)) {
              this.customerSurveyService.setSurveyPreviewMode(true, formattedSurvey, 'timeline');
            }
          }
          loader.dismiss();
        } catch (error) {
          console.log("Error open survey details from surve-timeline ", error);
          loader.dismiss();
        }
      });
    } catch(error) {
      console.log("failed to open survey ", error);
      this.uiService.dismissLoader();
    }
    
  }

}
