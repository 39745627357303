
import {takeUntil} from 'rxjs/operators';
import { FooterService, FooterViews } from "./../../../services/footer/footer.service";
import { OrderByPipe } from "./../../../pipes/orderby.pipe";
import { EventsService } from "./../../../services/events/events.service";
import { ActivityService } from "./../../../services/activity/activity.service";
import { WebsocketDataService } from "./../../../data-services/websocket/websocket.data.service";
import { UIService } from "./../../../services/ui/ui.service";
import { NavigationService, PageName } from "./../../../services/navigation/navigation.service";
import { Component, Input } from "@angular/core";
import {
  Resource,
  ResourceParticipantStatus
} from "../../../classes/resource/resource.class";
import {
  ResourceService,
  ResourceView
} from "../../../services/resource/resource.service";
import {
  Contact,
  ContactMeetingState
} from "../../../classes/contact/contact.class";
import { AppointmentActivity } from "../../../classes/activity/appointment.activity.class";
import { NavParams, NavController, IonNav } from "@ionic/angular";
import { DeviceService } from "../../../services/device/device.service";
import { Subject } from "rxjs";
import { ResourceShareHistoryComponent } from "../resource-share-history/resource-share-history";
import { TrackService, TrackingEventNames } from "../../../services/logging/tracking.service";
import {GlobalUtilityService} from "../../../services/global-utility.service";
import { TranslateService } from "@ngx-translate/core";
import { DateTimeFormatsService } from '@omni/services/date-time-formats/date-time-formats.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: "participant-list",
  templateUrl: "participant-list.html",
  styleUrls:['participant-list.scss']
})
export class ParticipantListComponent {
  @Input()
  public resource: Resource;
  public participantHeader;
  public resourceHeader = { id : 'Resource', title : this.translate.instant('RESOURCE'), controls:[] };
  participants: Array<{
    participant: Contact;
    canChangeSelection: boolean;
    isSelected: boolean;
    contactStatus: number;
    resourceStatus: ResourceParticipantStatus;
    statuses: Array<{ date: Date; status: ResourceParticipantStatus }>;
    date: Date;
  }> = [];
  ngDestroy$ = new Subject<boolean>();

  ResourceParticipantStatus = ResourceParticipantStatus;
  ResourceParticipantStatusDisplay = [
    "",
    "SENT",
    "ACCEPTED",
    "DECLINED",
    "ERROR"
  ];
  ContactStatusDisplay = [
    "assets/imgs/contact_offline.svg",
    "assets/imgs/contact_conn.png",
    "assets/imgs/contact_disc.png"
  ];
  @Input()
  viewMode: ResourceView;
  @Input()
  appointment: AppointmentActivity;
  get canPushResources(): boolean {
    return !!this.participants.some(c => c.isSelected && c.canChangeSelection);
  }

  get selectedParticipants(): Contact[] {
    return this.participants
      .filter(c => c.isSelected && c.canChangeSelection)
      .map(c => c.participant);
  }

  get title(): string {
    return this.viewMode === ResourceView.PUSH
      ? this.translate.instant('PUSH_RESOURCE_TO_CUSTOMER')
      : this.resource.title;
  }

  get readOnly(): boolean {
    return this.viewMode !== ResourceView.PUSH;
  }

  public backButton = [{
    id: "close",
    icon: "chevron-back-outline",
    isDisabled: false,
    align: "left"
  }];

  public initHeaderData() {
    let header: any = {
      id : 'Participants',
      title : this.translate.instant('ALL_WITH_TEXT_CAP', {text: this.utilityService.globalCustomerText.toUpperCase()}),
      controls:[]
    }
    if (!this.readOnly) {
      header.controls = [
        {
        id: 'select',
        text: this.isSelectedAll ? this.translate.instant('DESELECT_ALL') :  this.translate.instant('SELECT_ALL'),
        isDisabled: false,
      },
      {
        id: 'send',
        text: this.translate.instant('SEND'),
        isDisabled: !this.canPushResources,
      }
    ]
    }
    this.participantHeader = header;
  }

  public onSectionHeaderControlClick(id: string) {
    switch (id) {
      case 'select':
        this.selectAll();
        break;
      case 'send':
        this.pushResource();
        break;
      default:
        console.log("Unhandled switch case statement");
        break;
    }
  }

  get isSelectedAll(): boolean {
    let selectableParticipants = this.participants
      .filter(c => c.canChangeSelection);
    return selectableParticipants.length == this.selectedParticipants.length;
  }

  constructor(
    private resourceService: ResourceService,
    private websocket: WebsocketDataService,
    private navService: NavigationService,
    private navCtrl: IonNav,
    public device: DeviceService,
    private activityService: ActivityService,
    private uiService: UIService,
    private events: EventsService,
    public footerService: FooterService,
    private navParams: NavParams,
    public trackingService: TrackService,
    public utilityService: GlobalUtilityService,
    private translate:TranslateService,
    private dateTimeFormatsService: DateTimeFormatsService,
    private datePipe: DatePipe
  ) {
    this.resource = (this.navParams.data && this.navParams.data.resource);
    this.appointment = (this.navParams.data && this.navParams.data.appointment);
    this.viewMode = (this.navParams.data && this.navParams.data.viewMode);
  }

  ngOnInit() {
    this.initHeaderData();
    this.footerService.initButtons("ParticipantList");
    this.events
      .observe("refresh_resource_status").pipe(
      takeUntil(this.ngDestroy$))
      .subscribe(() => this.refresh());
    this.events.publish("refresh_resource_status");
    this.events.subscribe('remote_meeting_participant_Joined_left',(data)=> {
      this.initHeaderData();
    });
  }

  ngOnDestroy() {
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }

  refresh() {
    let allContacts = [
      ...new Set([
        ...this.appointment.contacts,
        ...this.appointment.sharedResources.map(sr => sr.contact)
      ])
    ];
    this.participants = allContacts
      .map(contact => {
        let contactStatus = 0; /* LEFT:-1, NOTJOINED:0, JOINED: 1 */
        let sharedResource = this.appointment.sharedResources.find(
          obj => obj.resource === this.resource && obj.contact === contact
        );
        let resourceStatus =
          sharedResource &&
          sharedResource.statuses &&
          sharedResource.statuses.length > 0
            ? new OrderByPipe().transform(
                sharedResource.statuses,
                "date",
                false
              )[0]
            : undefined;
        contactStatus = [
          ContactMeetingState.NOTJOINED,
          ContactMeetingState.JOINED,
          ContactMeetingState.LEFT
        ].indexOf(contact.connectionState);
        let participant = this.participants.filter(
          p => p.participant === contact
        )[0] || {
          participant: contact,
          contactStatus,
          resourceStatus: resourceStatus
            ? resourceStatus.status
            : ResourceParticipantStatus.NOTSENT,
          date: resourceStatus ? resourceStatus.date : null,
          canChangeSelection:
            contact.connectionState === ContactMeetingState.JOINED,
          isSelected: contact.connectionState === ContactMeetingState.JOINED,
          statuses: sharedResource && sharedResource.statuses
        };
        participant.resourceStatus = resourceStatus
          ? resourceStatus.status
          : ResourceParticipantStatus.NOTSENT;
        participant.date = resourceStatus ? resourceStatus.date : null;
        participant.contactStatus = contactStatus;
        participant.canChangeSelection =
          contact.connectionState === ContactMeetingState.JOINED;
        participant.isSelected =
          contact.connectionState === ContactMeetingState.JOINED
            ? participant.isSelected
            : false;
        participant.statuses = sharedResource && sharedResource.statuses;
        return participant;
      })
      .filter(
        s =>
          !this.readOnly ||
          s.resourceStatus != ResourceParticipantStatus.NOTSENT
      );
  }

  async goBack() {
    if (this.activityService.selectedSharedResource) {
      this.uiService.activeView = "ActivitiesPageRightPaneNav";
      this.activityService.selectedSharedResource = null;
      if (this.uiService.activeView === 'Appointment' || this.uiService.activeView === 'activityTimelineDetails' || this.navService.getActiveChildNavViewPageName() === PageName.ActivitiesDetailsPaneComponent) {
        this.footerService.initButtons(FooterViews.Activities);
      }
      if (this.navService.getCurrentMasterPageName() == PageName.CallPlanComponent || this.navService.getCurrentMasterPageName() == PageName.ContactPageComponent || this.navService.getCurrentMasterPageName() === PageName.ActivitiesPageComponent) {
        this.navService.popChildNavPageWithPageTracking();
      }
    }
    else {
      this.navService.popChildNavPageWithPageTracking().then(() => {
        this.footerService.initButtons(
          this.resourceService.viewMode === ResourceView.PUSH
            ? FooterViews.ResourcesInMeeting
            : FooterViews.Resources
          );
      });
    }
  }

  selectAll() {
    if (this.isSelectedAll) {
      this.participants.filter(c => c.canChangeSelection).map(c => {
        c.isSelected = false;
      });
    } else {
      this.participants.filter(c => c.canChangeSelection).map(c => {
        c.isSelected = true;
      });
    }
    this.initHeaderData();
  }

  public selectParticipant(participant) {
    if (this.readOnly)
      this.navCtrl.push(ResourceShareHistoryComponent, { participant });
    this.initHeaderData();
  }

  public async pushResource() {
    this.trackingService.tracking('PresentationResourcesSend', TrackingEventNames.DIGITAL, null, true)
    let selectedParticipants = this.selectedParticipants;
    this.resourceService.addParticipantsForResource(
      this.resource,
      selectedParticipants
    );
    this.websocket.pushResource(
      this.appointment,
      this.resource,
      selectedParticipants
    );
    this.events.publish("refresh_resource_status");
    //    this.viewCtrl.dismiss();
  }

  ngAfterViewInit() {
    this.initHeaderData();
  }

  getStatusDate(date){
    return this.datePipe.transform(date, this.dateTimeFormatsService.date, undefined, this.translate.currentLang)
  }
}
