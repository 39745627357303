import { Component, Input } from "@angular/core";
import { PopoverController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { Inventory, InventoryLot, UserInventory } from "@omni/classes/sample/allocation-inventory.class";
import { MultiSelectPopover } from "@omni/components/multi-select-popover/multi-select-popover";
import { AllocationInventoryDataService } from "@omni/data-services/sample/allocation-inventory.data.service";
import { IndSectionHeaderViewDataModel } from "@omni/models/indSectionHeaderDataModel";
import { MainCardViewDataModel } from "@omni/models/MainCardViewDataModel";
import { DeviceService } from "@omni/services/device/device.service";
import { EventsService } from "@omni/services/events/events.service";
import { NavigationService, PageName } from "@omni/services/navigation/navigation.service";
import { AllocationAdjustService } from "@omni/services/sample/allocation-adjust.service";
import { AllocationInventoryService } from "@omni/services/sample/allocation-inventory.service";
import { AllocationTransferService } from "@omni/services/sample/allocation-transfer.service";
import { AllocationFeatureService } from "@omni/services/sample/allocation.feature.service";
import { SampleService } from "@omni/services/sample/sample.service";
import { UIService } from "@omni/services/ui/ui.service";
import { Utility } from "@omni/utility/util";
import _ from "lodash";
import { Observable, Subject, Subscription } from "rxjs";
import { takeUntil } from 'rxjs/operators';
import { UserInventoryDetailComponent } from "../user-inventory-detail/user-inventory-detail";

const MIN_SEARCH_LENGTH = 3;

@Component({
  selector: 'user-inventory-list',
  templateUrl: 'user-inventory-list.html',
styleUrls:['user-inventory-list.scss']
})
export class UserInventoryListComponent {

  @Input() searchEvent: Observable<string>;
  private searchEventSubscription: Subscription;
  public isLoading: boolean = true;
  public listFiltered: boolean = false;
  public isSearchedInput: boolean = false;
  public userInventory: UserInventory[] = [];
  public filteredUserInventory: UserInventory[] = [];
  public userInventoryInStockActive: UserInventory[] = [];
  public inventoryHeaderModel: IndSectionHeaderViewDataModel;
  public multiSelectFilterPopoverData: { text: string; value: string;  items: any; multiselect?: boolean, handler: (selectedItem: any, item: any, itemRef: any) => void; }[];
  filterBy: { text: string; value: string; asc: boolean; };
  private readonly ngDestroy$: any = new Subject<boolean>();


  constructor(
    private allocationInventoryService: AllocationInventoryService,
    private allocationInventoryDataService: AllocationInventoryDataService,
    private popover: PopoverController,
    public translate: TranslateService,
    public uiService: UIService,
    private allocAdjustService: AllocationAdjustService,
    public allocFeatureService: AllocationFeatureService,
    public allocTransferService: AllocationTransferService,
    private events: EventsService,
    public device: DeviceService,
    public sampleService: SampleService,
  ) {

  }
  
  ngOnInit() {
    this.userInventory = [];
    this.fetchInventory();
    this._initFilterPopover();
    if (this.searchEvent) {
      this.searchEventSubscription = this.searchEvent.subscribe(searchTxt => this.searchInput(searchTxt));
    }
    this.events.subscribe('syncAllocationInventory', () => {
      this.isLoading = true;
      this.fetchInventory();
    })
    this.device.isOfflineObservable.pipe(takeUntil(this.ngDestroy$)).subscribe((offline) => {
      this.isLoading = true;
      this.fetchInventory();
    });

  }

  ngOnDestroy() {
    this.events.unsubscribe('syncAllocationInventory');
    this.isLoading = false;
  }

  async fetchInventory() {
    if(!this.device._isOffline) {
      await this.allocationInventoryDataService.fetchInventory(false, false).then(() => {
        this.userInventory = this.allocationInventoryService.userInventory;
        this.isLoading = false;
      }).catch((err) => {
        console.log("fetching allocation Inventory failed: " + err);
        this.userInventory = this.allocationInventoryService.userInventory;
        this.isLoading = false;
      });
    } else {
      this.userInventory = this.allocationInventoryService.userInventory;
      this.sampleService.lots.forEach((lot) => {
        this.userInventory.forEach((inventory) => {
          let idxLot = inventory.lot.findIndex(il => il.lotId == lot.DTO.indskr_lotid);
          if(idxLot>-1) {
            inventory.lot[idxLot].totalQtyRemaining = lot.DTO.indskr_totalquantityremaining;
            inventory.lot[idxLot].totalQtyRecieved = lot.DTO.indskr_totalquantityrecieved;
            inventory.lot[idxLot].totalQtyDropped = lot.DTO.indskr_totalquantitydropped;
          }
        })
      })
      this.isLoading = false;
    }
    //Default inventory data is all (in stock and active)
    const today = Utility.changeUTCDateToLocalDateWith0Time(
      new Date().getTime(),
      true
    );
    this.userInventoryInStockActive = _.cloneDeep(this.userInventory);
    this.userInventoryInStockActive.forEach((inventory, index) => {
      let filteredLot = inventory.lot.filter(lot => (new Date(lot.validTo).getTime() >= today.getTime() || _.isEmpty(lot.validTo)) && lot.totalQtyRemaining != 0);
      if(!_.isEmpty(filteredLot)) {
        inventory.lot = filteredLot;
      } else {
        inventory.lot = [];
      }          
    });
    this.userInventoryInStockActive = this.userInventoryInStockActive.filter((inventory) => inventory.lot.length>0);
  }

  public getInvSectionHeaderDataModel(inventory: UserInventory): IndSectionHeaderViewDataModel {
    let viewData: IndSectionHeaderViewDataModel;
    viewData = {
      id: 'inventory_' + inventory.skuId,
      title: inventory.skuName,
      controls: [{
        id: 'inventory_expand_'+inventory.skuId,
        isDisabled: false,
        icon: inventory.expanded ? 'assets/imgs/up-arrow.svg' : 'assets/imgs/down-arrow.svg',
      }]
    }
    return viewData;
  }

  public getLotMaincardDataModel(lot: InventoryLot): MainCardViewDataModel {
    return  {
      id: 'inventory_' + lot.lotId,
      fixedHeight: true,
      primaryTextRight: lot.lotName,
      isSecondaryGrid: true,
      secondaryTextLeft: this.translate.instant('QUANTITY_REMAINING'),
      secondaryTextLeftTwo: lot.totalQtyRemaining.toString(),
      secondaryTextRight: this.translate.instant('EXPIRY_DATE'),
      secondaryTextRightTwo: lot.validTo,
      noSeparationLine: false,
      clickHandler: (id: string, ev) => {
        this.uiService.activeView = 'UserInventory';
        this.allocationInventoryService.selectedLot$.next(lot);
        this.allocFeatureService.selectedShipment = undefined;
        if (this.allocAdjustService.adjustForm) {
          this.allocAdjustService.destroyForm();
        }
        if (this.allocTransferService.transferForm) {
          this.allocTransferService.destroyForm();
        }
      }
    };
  }

  public filterData(event) {
    this.popover
      .create({ component: MultiSelectPopover, componentProps: { root: this.multiSelectFilterPopoverData }, event: event })
      .then((data) => {
        data.present();
      });
    // this.adjustmentService.teamAdjustmentSearchString = '';
  }

  updateEmptyMessage() {
    this.events.publish('updateAllocationsRHSEmptyMessage', this.userInventory.length > 0 ? true : false)
  }

  searchInput(val: string) {

    if(val.length < MIN_SEARCH_LENGTH) {
      this.isSearchedInput = false;
      this.listFiltered = false;
      this.filteredUserInventory = [];
    }
    if (val.length >= MIN_SEARCH_LENGTH) {
      if (val.trim().length == 0) return;
      this.isSearchedInput = true;
      this.listFiltered = true;
      let isFoundSku: boolean = false;
      let isFoundLot: boolean = false;
      this.userInventory.forEach((inventory) => {
        //search in sku
        if(inventory.skuName.toUpperCase().includes(val.toUpperCase())) {
          isFoundSku = true;
          if(!_.isEmpty(this.filteredUserInventory)) {
            const idx = this.filteredUserInventory.findIndex(i=>i.skuId == inventory.skuId);
            if(idx == -1) {
              this.filteredUserInventory.push(inventory);  
            } 
          }else {
            this.filteredUserInventory.push(inventory);
          }
        }
        //search in lot 
        else {
          let tempInv = _.cloneDeep(inventory);
          let filteredLot = [];
          filteredLot = inventory.lot.filter(lo => lo.lotName.toUpperCase().includes(val.toUpperCase()));
          if(!_.isEmpty(filteredLot)) {
            isFoundLot = true;
            tempInv.lot = filteredLot;
            if(!_.isEmpty(this.filteredUserInventory)) {
              const idx = this.filteredUserInventory.findIndex(i=>i.skuId == inventory.skuId);
              if(idx > -1) {
                this.filteredUserInventory[idx] = tempInv;
              }else {
                this.filteredUserInventory.push(tempInv);  
              }
            }else {
              this.filteredUserInventory.push(tempInv);
            }
          }
        }
      });
      if(!isFoundSku && !isFoundLot) {
        this.filteredUserInventory = [];
      }
    }
    // this.noSearchResults = !this.filteredSampleOrders.length && !val.length;
    this.updateEmptyMessage();
  }

  onSectionHeaderControlClick(event, skuId) {
    if (event.includes('inventory_expand')) {
      let index = this.userInventory.findIndex(inventory => inventory.skuId == skuId);
      this.userInventory[index].expanded = !this.userInventory[index].expanded;
    }
  }

  getInventoryExpanded(inventory:UserInventory) {
    return inventory.expanded;
  }

  private _initFilterPopover() {
    this.multiSelectFilterPopoverData = [
      {
        text: '',
        value: 'all',
        items: [
          { text: this.translate.instant('ALL'), value: 'all' },
          { text: this.translate.instant('EXPIRED_LOT'), value: 'expired' },
          { text: this.translate.instant('ACTIVE'), value: 'active' },
          { text: this.translate.instant('OUT_OF_STOCK'), value: '0' }
        ],
        multiselect: false,
        handler: (selectedItem, item, itemRef, selected?: boolean) => {
          itemRef.parent.items[0].value = '';
          item.value = item.value === selectedItem.value && item.text.lowercased() === selectedItem.text.lowercased() ? '' : selectedItem.value;
          const today = Utility.changeUTCDateToLocalDateWith0Time(
            new Date().getTime(),
            true
          );
          if (item.value == 'all') {
            this.listFiltered = true;
            this.userInventory = this.allocationInventoryService.userInventory;
            this.filteredUserInventory = _.cloneDeep(this.userInventory);
            this.filteredUserInventory.forEach((inventory, index) => {
              let filteredLot = inventory.lot.filter(lot => (new Date(lot.validTo).getTime() >= today.getTime() || _.isEmpty(lot.validTo)) && lot.totalQtyRemaining != 0);
              if(!_.isEmpty(filteredLot)) {
                inventory.lot = filteredLot;
              } else {
                inventory.lot = [];
              }          
            });
            this.filteredUserInventory = this.filteredUserInventory.filter((inventory) => inventory.lot.length>0);
          } else if (item.value == 'expired') {
            this.listFiltered = true;
            this.filteredUserInventory = _.cloneDeep(this.userInventory);
            this.filteredUserInventory.forEach((inventory, index) => {
              let filteredLot = inventory.lot.filter(lot => new Date(lot.validTo).getTime() < today.getTime());
              if(!_.isEmpty(filteredLot)) {
                inventory.lot = filteredLot;
              } else {
                inventory.lot = [];
              }
            });
            this.filteredUserInventory = this.filteredUserInventory.filter((inventory) => inventory.lot.length>0);
          } else if (item.value == 'active') {
            this.listFiltered = true;
            this.filteredUserInventory = _.cloneDeep(this.userInventory);
            this.filteredUserInventory.forEach((inventory, index) => {
              let filteredLot = inventory.lot.filter(lot => new Date(lot.validTo).getTime() >= today.getTime() || _.isEmpty(lot.validTo));
              if(!_.isEmpty(filteredLot)) {
                inventory.lot = filteredLot;
              } else {
                inventory.lot = [];
              }          
            });
            this.filteredUserInventory = this.filteredUserInventory.filter((inventory) => inventory.lot.length>0);
          } else if (item.value == '0') {
            this.listFiltered = true;
            this.filteredUserInventory = _.cloneDeep(this.userInventory);
            this.filteredUserInventory.forEach((inventory, index) => {
              let filteredLot = inventory.lot.filter(lot => lot.totalQtyRemaining == 0);
              if(!_.isEmpty(filteredLot)) {
                inventory.lot = filteredLot;
              } else {
                inventory.lot = [];
              } 
            });
            this.filteredUserInventory = this.filteredUserInventory.filter((inventory) => inventory.lot.length>0);
          }
          this.updateEmptyMessage();
        }
      }
    ]
  }

}