export class ConfiguredFields {
  public entityName?: string;
  public entityId?: string;
  public fieldId?: string;
  public fieldName?: string;
  public fieldLabel?: string;
  public fieldType?: string;
  public fieldAlias?: string;
  public fieldSequence?: number;
  public responseField?: string;
  public appFieldId?: string;
  public entityDisplayName?: string;
  public statecode?: number;
  public order?: number;
  public datatype?: string;
  public value?: string;
  public readOnly?: boolean;
  public mandatory?: string;
  public isEmptyRequiredField?:boolean;

  // These three will be used for multiple selection.
  public multipleLabels?: string[];
  public multipleValues?: number[];
  public hasMultipleValues?: boolean;
  public selectedValues?: string;

  constructor(raw) {
    this.entityName = raw['indskr_entitylogicalname'];
    this.entityId = raw['indskr_entitymetadataid'];
    this.fieldId = raw['indskr_fieldmetadataid'];
    this.fieldName = raw['indskr_name'];
    this.fieldLabel = raw['indskr_fielddisplayname'];
    this.fieldType = raw['indskr_fieldtype'];
    this.fieldAlias = raw['indskr_fieldalias'];
    this.responseField = raw['response_fieldname'];
    this.appFieldId = raw['indskr_appfieldid'];
    this.entityDisplayName = raw['indskr_entitydisplayname'];
    // this.order = raw['indskr_order'];
    this.order = (raw['indskr_order']) ? parseInt(raw['indskr_order']) : 10000;
    this.statecode = raw['statecode'];

    let readOnlyValue = raw['indskr_readonly'];
    if (readOnlyValue === undefined || readOnlyValue === null || readOnlyValue === "false" || readOnlyValue === false) {
      this.readOnly = false;
    } else {
      this.readOnly = true;
    }

    this.mandatory = raw['indskr_mandatory'];
    //assign a random high number to place unordered fields in the
    this.fieldSequence = (raw['indskr_order']) ? parseInt(raw['indskr_order']) : 10000;

    // Default for Multiple Selection
    this.hasMultipleValues = false;
    this.multipleLabels = [];
    this.multipleValues = [];
    this.selectedValues = '';
    this.value = '';
  }
}

export const hcpInteractionDefaultField = {
  appFieldId: "4c6fb995-2d14-eb11-a813-000d3af2ffed",
  entityDisplayName: "Appointment",
  entityId: null,
  entityName: "appointment",
  fieldAlias: "indskr_hcpinteraction",
  fieldId: null,
  fieldLabel: "Customer Requested",
  fieldName: "indskr_hcpinteraction",
  fieldSequence: 1,
  fieldType: "Picklist",
  hasMultipleValues: false,
  mandatory: "true",
  multipleLabels: [],
  multipleValues: [],
  order: 1,
  readOnly: false,
  responseField: "indskr_hcpinteraction@OData.Community.Display.V1.FormattedValue",
  selectedValues: "",
  statecode: 0,
  value: "",
}
