import { AuthenticationService } from '@omni/services/authentication.service';
import { Account } from '@omni/classes/account/account.class';
import { Contact } from '@omni/classes/contact/contact.class';
import { DB_KEY_PREFIXES } from '../../config/pouch-db.config';
import { DeviceService } from '@omni/services/device/device.service';
import {
  DEFAULT_ACCOUNT_SCONDARY_INFO,
  DEFAULT_CONTACT_SECONDARY_INFO,
  ISecInfoConfig,
  SecondaryInfoEntityName,
} from '../../classes/sec-info-config/sec-info.class';
import { DiskService } from '@omni/services/disk/disk.service';
import { DynamicsClientService } from '../dynamics-client/dynamics-client.service';
import { Injectable } from '@angular/core';
import { fetchQueries } from '@omni/config/dynamics-fetchQueries';
import _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class SecInfoConfigDataService {
  public secondaryInfoConfigList = [];
  public fetchedAttributes;
  public isContactSecInfoUpdated = false;
  public isAccountSecInfoUpdated = false;

  constructor(
    private readonly disk: DiskService,
    public dynamics: DynamicsClientService,
    public device: DeviceService,
    private readonly authenticationService: AuthenticationService
  ) {}

  public SecondaryInfoFetchXML(entityName: string) {
    const secInfo = this.secondaryInfoConfigList.filter(
      (info) => info.entity === entityName
    );
    return secInfo[0] ? secInfo[0].fetchXMLStr : '';
  }

  public SecondaryInfoFormatedString(entityName: string) {
    const secInfo = this.secondaryInfoConfigList.filter(
      (info) => info.entity === entityName
    );
    return secInfo[0] ? secInfo[0].attributes : '';
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  public async getSecInfoConfig(loadFromDBOnly = false): Promise<void> {
    try {
      let offlineSecInfo = [];

      const document = await this.disk
        .retrieve(DB_KEY_PREFIXES.SEC_INFO_CONFG)
        .catch((err) => console.log(err));
      if (document && document.raw) {
        offlineSecInfo = [...document.raw];
      }

      if (loadFromDBOnly || this.device.isOffline) {
        if (document && Array.isArray(document.raw) && document.raw.length > 0) {
          this.secondaryInfoConfigList = [...document.raw];
        } else {
          // Have default configs if no config available
          this.secondaryInfoConfigList = [];
          this.insertDefault();
        }
        return;
      }

      let fetchXML: string =
        fetchQueries.configuredFormFetchXMLs.fetchSecondaryInfoConfiguration;
      const now = new Date();

      fetchXML = fetchXML.replace(
        '{systemUserId}',
        this.authenticationService.user.systemUserID
      );
      fetchXML = fetchXML.replace(
        '{userBUId}',
        this.authenticationService.user.xBusinessUnitId
      );

      const resSecondaryInfo = await this.dynamics.executeFetchQuery(
        'indskr_secondaryinfoconfigurations',
        fetchXML
      );

      this.secondaryInfoConfigList = [];
      if (resSecondaryInfo && resSecondaryInfo.length > 0) {
        resSecondaryInfo.forEach((res) => {
          this.fetchSecondaryInfo(res);
        });
        this.insertDefault();
        this.isConfigDataChanged(offlineSecInfo, this.secondaryInfoConfigList);

        this.disk
          .updateOrInsert(DB_KEY_PREFIXES.SEC_INFO_CONFG, (doc) => {
            doc = {
              raw: this.secondaryInfoConfigList,
              lastModified: now.getTime(),
            };
            return doc;
          })
          .catch((error) => {
            console.error('Save Forms to DB error: ', error);
          });
      } else {
        this.disk
          .updateOrInsert(DB_KEY_PREFIXES.SEC_INFO_CONFG, (doc) => {
            doc = {
              raw: [],
              lastModified: now.getTime(),
            };
            return doc;
          })
          .catch((error) => {
            console.error('Save Forms to DB error: ', error);
          });
        this.isContactSecInfoUpdated = this.isAccountSecInfoUpdated =
          offlineSecInfo.length !== 0;
        this.insertDefault();
      }
    } catch (exception) {
      this.insertDefault();
      console.error(`exception in getSecInfoConfig ${exception}`);
    }
  }

  private insertDefault() {
    if (
      !this.secondaryInfoConfigList.some(
        (item) => item.entity === SecondaryInfoEntityName.Contact
      )
    ) {
      this.fetchSecondaryInfo({
        indskr_secondaryinfoconfigurationid: '_contactdefault_12345',
        modifiedon: 1602120785,
        indskr_entityname: DEFAULT_CONTACT_SECONDARY_INFO.indskr_entityname,
        indskr_secondaryinfoconfigdata: JSON.stringify(
          DEFAULT_CONTACT_SECONDARY_INFO.indskr_secondaryinfoconfigdata
        ),
      });
    }
    if (
      !this.secondaryInfoConfigList.some(
        (item) => item.entity === SecondaryInfoEntityName.Account
      )
    ) {
      this.fetchSecondaryInfo({
        indskr_secondaryinfoconfigurationid: '_accountdefault_12345',
        modifiedon: 1602120785,
        indskr_entityname: DEFAULT_ACCOUNT_SCONDARY_INFO.indskr_entityname,
        indskr_secondaryinfoconfigdata: JSON.stringify(
          DEFAULT_ACCOUNT_SCONDARY_INFO.indskr_secondaryinfoconfigdata
        ),
      });
    }
  }
  private isConfigDataChanged(localDBList, newList): void {
    this.isContactSecInfoUpdated = !_.isEqual(
      localDBList.filter((o) => o.entity === SecondaryInfoEntityName.Contact),
      newList.filter((o) => o.entity === SecondaryInfoEntityName.Contact)
    );
    this.isAccountSecInfoUpdated = !_.isEqual(
      localDBList.filter((o) => o.entity === SecondaryInfoEntityName.Account),
      newList.filter((o) => o.entity === SecondaryInfoEntityName.Account)
    );
  }
  private getAttributeText(props: ISecInfoConfig) {
    if (props.customText) {
      this.fetchedAttributes.push(props);
      return undefined;
    }
    if (props.from) {
      let linkEntityStr = `<link-entity name="${props.name}" from="${props.from}" to="${props.to}" link-type="outer">`;
      if (props.attributes) {
        for (const attr of props.attributes) {
          linkEntityStr += this.getAttributeText(attr);
        }
        if (props.filter) {
          linkEntityStr += `<filter type="${props.filter.type}">`;
          linkEntityStr += props.filter.conditions
            .map(
              (cond) =>
                `<condition attribute="${cond.attribute}" operator="${cond.op}" value="${cond.value||''}" />`
            )
            .join('');
          linkEntityStr += `</filter>`;
        }
      }
      linkEntityStr += `</link-entity>`;
      return linkEntityStr;
    }

    this.fetchedAttributes.push(props);
    return `<attribute name='${props.name}' alias='${props.alias}'/>`;
  }
  private fetchSecondaryInfo(response: any) {
    if (
      !this.secondaryInfoConfigList.some(
        (item) => item.entity === response.indskr_entityname
      )
    ) {
      const configData: ISecInfoConfig = JSON.parse(
        response.indskr_secondaryinfoconfigdata
      );
      this.fetchedAttributes = [];
      this.secondaryInfoConfigList.push({
        configId: response.indskr_secondaryinfoconfigurationid,
        modifiedOn: new Date(response.modifiedon).getTime(),
        entity: response.indskr_entityname,
        fetchXMLStr: configData.attributes
          .map((res) => this.getAttributeText(res))
          .join(''),
        seperator: configData.seperator,
        attributes: [...this.fetchedAttributes],
      });
    }
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  public getSecondaryInfoFormatedText(entity: Contact | Account, entityName) {
    const secInfo = this.secondaryInfoConfigList.filter(
      (info) => info.entity === entityName
    )[0];
    let formatedSecondaryInfo = '';
    if (secInfo) {
      formatedSecondaryInfo = secInfo.attributes
        .map((attr) => {
          if (attr.customText) {
            return attr.customText;
          }
          if (attr.alias && entity && entity.raw) {
            return (
              entity.raw[`${attr.alias}_Formatted`] ||
              entity.raw[attr.alias] ||
              entity.raw[
                `${attr.alias}@OData.Community.Display.V1.FormattedValue`
              ]
            );
          }
          return undefined;
        })
        .filter(Boolean)
        .join(`${secInfo.seperator}`);
    }
    return formatedSecondaryInfo;
  }
}
