import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IonNav } from '@ionic/angular';
import { Quotes } from '@omni/classes/quotes/quotes.class';
import { Events } from '@omni/events';
import { AccountOfflineService } from '@omni/services/account/account.offline.service';
import { DeviceService } from '@omni/services/device/device.service';
import { FooterService } from '@omni/services/footer/footer.service';
import { ChildNavNames, NavigationService, PageName } from '@omni/services/navigation/navigation.service';
import { QuotesService } from '@omni/services/quote/quote-tool.service';
import { ComponentViewMode, UIService } from '@omni/services/ui/ui.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'quote-page',
  templateUrl: './quote-page.html',
  styleUrls: ['./quote-page.scss'],
})
export class QuotePageComponent implements OnInit, OnDestroy {
  @ViewChild('quotepagerightpane', { static: true }) public navCtrl: IonNav;
  ngUnSubscibe$ = new Subject<boolean>();
  quoteListMode: string;
  currentQuote: Quotes;
  constructor(
    private footerService: FooterService,
    public device: DeviceService,
    public navService: NavigationService,
    public accountService: AccountOfflineService,
    public uiService: UIService,
    public quoteOfflineService: QuotesService,
    private events: Events
  ) { }

  ngOnInit() {
    this.quoteListMode = ComponentViewMode.LISTVIEW;
    this.accountService.accessedAccountListFrom = PageName.QuotePageComponent;
    this.navService.initRightPaneChildNav(this.navCtrl, ChildNavNames.QuotesNavigation, PageName.QuotePageComponent, false, { deviceString: this.device.deviceFlags.ios ? 'ios' : 'other' });
    this.footerService.initButtons('');
    this.uiService.updateNothingSelectedScreenMessageFor(0); 
  }

  ngOnDestroy() {
    this.navService.setChildNavRightPaneView(false);
    this.quoteOfflineService.selectedQuotes$.next(null);
    this.ngUnSubscibe$.next(true);
    this.ngUnSubscibe$.complete();
    this.navService.popChildNavCtrlFromStack(ChildNavNames.QuotesNavigation).then(() => {
      this.events.publish("tools-back-clicked", PageName.QuotePageComponent);
    });
  }

}
