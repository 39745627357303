import { Component, Input, OnChanges, ViewChild, ElementRef, Output, EventEmitter, SimpleChanges, Renderer2 } from '@angular/core';
import { Activity, ActivityType, MeetingActivityState } from '../../classes/activity/activity.class';
import { PopoverComponent } from '../popover/popover';
import { PopoverController,  LoadingController } from '@ionic/angular';
import { Events } from '@omni/events';
import { PresentationService } from '../../services/presentation/presentation.service';
import { Page, Presentation } from '../../classes/presentation/presentation.class';
import { ContactOfflineService } from '../../services/contact/contact.service';
import { format, differenceInMilliseconds, max } from 'date-fns';
import { AuthenticationService } from '../../services/authentication.service';
import { AppointmentActivity } from '../../classes/activity/appointment.activity.class';
import { SENTIMENT } from '../../data-services/websocket/payloads/sentiment.payload';
import { DeviceService } from '../../services/device/device.service';
import { DiskService } from '../../services/disk/disk.service';
import { MeetingDataService, InitiateMeetingPayload } from '../../data-services/meeting/meeting.data.service';
import { ActivityService, ActivitySource } from '../../services/activity/activity.service';
import { Brand } from '../../classes/brand/brand.class';
import { AccountRelation, ContactRelation, ContactRepCallPlan, Contact } from '../../classes/contact/contact.class';
import { RepCallPlanStatePipe } from '../../pipes/rep-call-plan-state.pipe';
import { SplitRepCallPlanPipe } from '../../pipes/split-rep-call-plan.pipe';
import { ActivityPresentationSlideContact } from '../../classes/presentation/activity-slide-contacts.class';
import { addMinutes } from 'date-fns';
import { TrackService, TrackingEventNames } from '../../services/logging/tracking.service';
import { ActivityDataService } from '../../data-services/activity/activity.service';
import { UIService } from '../../services/ui/ui.service';
import { NavigationService, PageName } from '../../services/navigation/navigation.service';
import { ActivitiesDetailsPaneComponent } from '../activity/activities-details-pane/activities-details-pane';
import { NewActivityComponent } from '../activity/new-activity/new-activity';
import {
  EventHistory,
  Publication,
  Research,
  SessionHistory,
  SpeakerEngagement
} from "../../classes/contact/scientific-info.class";
import { OrderActivity } from '../../classes/activity/order.activity.class';
import { CustomerEventsService } from '../../services/customer-event/customer-events.service';
import { ToastStyle, NotificationService } from '../../services/notification/notification.service';
import { ScientificActivityPlan } from '../../classes/scientific-activity/scientific-activity.class';
import { TranslateService } from '@ngx-translate/core';
import { CaseActivity } from '../../classes/case-intake/case-activity.class';
import { DateTimeFormatsService } from "../../services/date-time-formats/date-time-formats.service";
import _ from "lodash";
import { ActivityPresentation } from '../../classes/presentation/activity-presentation.class';
import { ActivityResource } from '../../classes/resource/activity-resource.class';
import { ActivityResourceContact } from '../../classes/resource/activity-resource-contact.class'
import { EventsService } from '../../services/events/events.service';
import { Subject } from 'rxjs';
import { RepServices } from '../../data-services/rep/rep.services';
import { REP_STATUS } from '../../models/rep-status-model';
import { Opportunity } from '@omni/classes/opportunity-management/opportunity.class';
@Component({
  selector: 'expandable',
  templateUrl: 'expandable.html',
  styleUrls:['expandable.scss']
})
export class ExpandableComponent implements OnChanges {

  @ViewChild('expandWrapper', { read: ElementRef, static: false }) expandWrapper;
  @Input('expanded') expanded;
  @Input('expandHeight') expandHeight;
  @Input('contentText') contentText;
  @Input('viewType') viewType;
  @Input('activity') activity: Activity;
  @Input('contactaccountrelation') contactAccountRelation: AccountRelation;
  @Input('contactContactRelation') contactContactRelation: ContactRelation;
  @Input('contactCallPlans') contactCallPlans: ContactRepCallPlan[];
  @Input('scientificPlans') scientificPlans: ScientificActivityPlan[];
  @Input('opportunities') opportunities: Opportunity[];
  @Input('contactCallPlanViewMode') contactCallPlanViewMode;
  @Input('publications') publication: Publication;
  @Input('researches') research: Research;
  @Input('engagements') spkEngagement: SpeakerEngagement;
  @Input('events') evtHistory: EventHistory;
  @Input('sessions') sesHistory: SessionHistory;
  @Input('customerEvent') customerEvent: any;
  @Input('meetingContentDuration') meetingContentDurationEnabled: boolean;
  @Output() onDeleteActivity = new EventEmitter;
  @Output() onCustomAction = new EventEmitter<string>(null);
  @Output() eventRegStatus = new EventEmitter();
  @Output() openSPContactProfile: EventEmitter<ScientificActivityPlan> = new EventEmitter();
  @Output() openOpportunity: EventEmitter<Opportunity> = new EventEmitter();
  @Output() onAddNewActivityClick = new EventEmitter();
  @Input() expandableAccessedFrom: string;

  public pageName = PageName;
  @Input() isDeviceOnline: boolean = true;
  public activityType = ActivityType;
  public meetingState = MeetingActivityState;
  public readOnlyMeeting: boolean = false;
  private activityCopy: AppointmentActivity;
  public activityContents: (ActivityPresentation | ActivityResource)[] = [];
  public keyMessageSentiments:any = [];

  constructor(public renderer: Renderer2,
    private popoverCtrl: PopoverController,
    public presService: PresentationService,
    //public authenticationService:AuthenticationService,
    public trackingService: TrackService,
    private activityDataService: ActivityDataService,
    private events: Events,
    private navService: NavigationService,
    public device: DeviceService,
    private uiService: UIService,
    public activityService: ActivityService,
    private contactService: ContactOfflineService,
    public authenticationOfflineService: AuthenticationService,
    private customerEventService: CustomerEventsService,
    private translate: TranslateService,
    public dateTimeFormatsService: DateTimeFormatsService,
    private notificationService: NotificationService,
    ) {

  }

  getStartTimeForContent(content: ActivityResource | ActivityPresentation): number {
    if (content instanceof ActivityPresentation) return Math.min(...content.activityPresentationSlides.map(as => Number(as.starttime)));
    else return content.activityResourceSlides && Math.min(...content.activityResourceSlides.map(r => Number(r.indskr_starttime)));
  }

  ngOnInit() {
    if ((this.viewType == 'contactPresHistory' || this.viewType == 'contactActivePres') && this.activity && this.activity instanceof AppointmentActivity) {
      if (this.activity.activityPresentations && this.activity.activityPresentations.length > 0) {
        this.activityContents = this.activity.activityPresentations.map(ap => { ap['type'] = 'presentation'; return ap });
      }
      if (this.activity.activityResources && this.activity.activityResources.length > 0) {
        this.activityContents = _.concat(this.activityContents, this.activity.activityResources.map(ar => { ar['type'] = 'resource'; return ar }));
      }
      if(this.activity.activityContactSentiments && this.activity.activityContactSentiments.length > 0 && this.contactService.contactInformation){
        this.activity.activityContactSentiments.forEach(actCon => {
          if(actCon.indskr_contactid == this.contactService.contactInformation.ID){
            actCon.activityKeyMessageSentiments.forEach(conKeyMsg => {
              this.keyMessageSentiments.push({
                "indskr_name": conKeyMsg.indskr_name,
                "sentimentImgURL": conKeyMsg.indskr_keymessagesentiment == SENTIMENT.POSITIVE ? "assets/imgs/sentiment-up.png" : conKeyMsg.indskr_keymessagesentiment == SENTIMENT.NEGATIVE ? "assets/imgs/sentiment-down.png" : "assets/imgs/sentiment-nutral.png",
                "sentiment": conKeyMsg.indskr_keymessagesentiment,
                "indskr_keymessageid": conKeyMsg.indskr_keymessageid,
                keyMessageObj: conKeyMsg,
              })
            })
          }
        })
      }
      this.activityContents.sort((c1, c2): number => {
        let startTime1: number = this.getStartTimeForContent(c1);
        let startTime2: number = this.getStartTimeForContent(c2);
        if (startTime1 > startTime2) return 1;
        else if (startTime1 < startTime2) return -1;
        else return 0;
      });

    }
  }
  /**
   * After view initalized we set the height to our expandheight value
   */
  ngAfterViewInit() {
    this.renderer.setStyle(this.expandWrapper.nativeElement, 'height', this.expandHeight + 'px');
    if (this.viewType == 'contactCallplans' || this.viewType == 'scientificplans' || this.viewType == 'opportunities') {
      this.renderer.setStyle(this.expandWrapper.nativeElement, 'height', 'auto');
    }
    if (this.viewType == 'contactActivePres' && this.activity && this.activity instanceof AppointmentActivity) {
      this.activityCopy = _.cloneDeep(this.activity);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.activity) {
      if (this.activity.type === ActivityType.Appointment || this.activity.type === ActivityType.Sample) {
        if (this.activity["isJointmeeting"]) {
          let meetingOwnerId = this.activity['meetingOwnerId'];
          if (this.activity.type == ActivityType.Sample) {
            meetingOwnerId = this.activity['ownerId'];
          }
          let userId = this.authenticationOfflineService.user.systemUserID;
          //meeting owner is not the person who logged in
          //disable the buttons as the meeting is readonly for this person.
          this.readOnlyMeeting = (meetingOwnerId != userId);
        }
      }
    }
    if (this.expandWrapper && changes.hasOwnProperty('expanded')) {
      if (this.viewType === 'scientificplans' && this.scientificPlans && this.scientificPlans.length > 0 && changes['expanded'].currentValue) {
        this.renderer.setStyle(this.expandWrapper.nativeElement, 'height', 'auto');
      }
    }
  }

  public get checkCustomScrapDisabledCondition(): boolean {
    let flag: boolean = false;
    if (this.activity.type == ActivityType.FollowUp && this.activity.ownerId != this.authenticationOfflineService.user.systemUserID) {
      flag = true;
    }
    else if (this.activity && this.activity.type == ActivityType.TimeOffRequest &&
      this.activity.status !== 100000000) {
      return true;
    }
    else if (this.activity && this.activity.type == 'CaseIntake') {
      let caseActivity: CaseActivity = this.activity as CaseActivity;
      if (caseActivity && caseActivity._case_status_value != 'Open' &&
        caseActivity._case_status_value != 'Pending Sync') {
        return true;
      }
    }
    else if (this.activity && this.activity.type == ActivityType.Order) {
      flag = true;
      if (this.activity.ownerId == this.authenticationOfflineService.user.systemUserID && (this.activity as OrderActivity).orderStatusString == 'Draft') {
        flag = false;
      }
    } else if (this.activity && this.activity.type === ActivityType.Email && (this.authenticationOfflineService.user.systemUserID !== this.activity.ownerId || this.activity.status !== 1 || this.activityService.teamViewActive)) {
      flag = true;
    }
    return flag;
  }

  onDeleteClick(e) {
    e.stopPropagation();
    if (this.checkCustomScrapDisabledCondition) return;
    this.trackingService.tracking('AgendaScrapFromTile', TrackingEventNames.ACTIVITY)
    if (!this.readOnlyMeeting && !this.activityService.teamViewActive) {
      if (this.activity && this.activity.type == ActivityType.Order) {
        this.trackingService.tracking('AgendaCardScrapOrder', TrackingEventNames.ORDERMANAGEMENT);
      }
      this.onDeleteActivity.emit();
    }
  }

  async openSentimentPopover(myEvent, slide: Page) {
    if (this.viewType == 'contactPresHistory') return;
    myEvent.stopPropagation();
    if (this.activity.state != MeetingActivityState.Completed && this.activity['meetingOwner'] === this.authenticationOfflineService.user.displayName) {
      let ev = {
        target: {
          getBoundingClientRect: () => {
            return {
              top: myEvent.pageY - 60,
              left: myEvent.pageX - 60,
            };
          }
        }
      };
      let popover =await this.popoverCtrl.create({
        component: PopoverComponent,
        componentProps:{ field: 'sentiments' },
        cssClass: 'sentiment-popover',
        event:myEvent});
      popover.present();
      popover.onDidDismiss().then((data: any) => {
        const sentiment = data.data;
        switch (sentiment) {
          case 'positive':
            slide.sentiment = SENTIMENT.POSITIVE;
            slide.sentimentImgURL = 'assets/imgs/sentiment-up.png';
            this.updateSentiments(slide);
            break;
          case 'neutral':
            slide.sentiment = SENTIMENT.NEUTRAL;
            slide.sentimentImgURL = 'assets/imgs/sentiment-nutral.png';
            this.updateSentiments(slide);
            break;
          case 'negative':
            slide.sentiment = SENTIMENT.NEGATIVE;
            slide.sentimentImgURL = 'assets/imgs/sentiment-down.png';
            this.updateSentiments(slide);
            break;
          default:
            // slide.sentiment = SENTIMENT.NEUTRAL;
            // slide.sentimentImgURL = 'assets/imgs/sentiment-nutral.png';
            // this.updateSentiments(slide);
            break;
        }
      });
    }
  }

  public async openKeyMessageSentimentPopover(myEvent, item) {
    if (this.viewType == 'contactPresHistory' || this.device.isOffline) return;
    myEvent.stopPropagation();
    if (this.activity.state != MeetingActivityState.Completed && this.activity['meetingOwner'] === this.authenticationOfflineService.user.displayName) {
      let ev = {
        target: {
          getBoundingClientRect: () => {
            return {
              top: myEvent.pageY - 60,
              left: myEvent.pageX - 60,
            };
          }
        }
      };
      let popover = await this.popoverCtrl.create({
        component: PopoverComponent,
        componentProps: { field: 'sentiments' },
        cssClass: 'sentiment-popover',
        event: myEvent
      });
      popover.present();
      popover.onDidDismiss().then((data: any) => {
        const sentiment = data.data;
        switch (sentiment) {
          case 'positive':
            item.sentiment = SENTIMENT.POSITIVE;
            item.sentimentImgURL = 'assets/imgs/sentiment-up.png';
            if(item.keyMessageObj){
              item.keyMessageObj.indskr_keymessagesentiment = SENTIMENT.POSITIVE;
            }
            //this.updateKeyMessageSentiments(item);
            break;
          case 'neutral':
            item.sentiment = SENTIMENT.NEUTRAL;
            item.sentimentImgURL = 'assets/imgs/sentiment-nutral.png';
            if(item.keyMessageObj){
              item.keyMessageObj.indskr_keymessagesentiment = SENTIMENT.NEUTRAL;
            }
            //this.updateKeyMessageSentiments(item);
            break;
          case 'negative':
            item.sentiment = SENTIMENT.NEGATIVE;
            item.sentimentImgURL = 'assets/imgs/sentiment-down.png';
            if(item.keyMessageObj){
              item.keyMessageObj.indskr_keymessagesentiment = SENTIMENT.NEGATIVE;
            }
            //this.updateKeyMessageSentiments(item);
            break;
          default:
            // slide.sentiment = SENTIMENT.NEUTRAL;
            // slide.sentimentImgURL = 'assets/imgs/sentiment-nutral.png';
            // this.updateSentiments(slide);
            break;
        }
        this.events.publish("contact-key-message-sentiment-update",true);
      });
    }
  }


  getPresSlides(presID): Array<Page> {
    let presObj = this.presService.getPresObject(presID);
    return presObj.presentationPages;
  }

  get contentProducts() {

    return this.activity && (this.activity.products || this.activity['activityProducts']) ? (this.viewType === 'contactActivePres' ? this.activity.products.filter(pr => pr.isSelected) : this.activity['activityProducts']) : [];
    // if (this.viewType === 'contactActivePres') {
    //   const products = this.activity.products;
    //   if (!_.isEmpty(products) && Array.isArray(products)) {
    //     return products.filter(pr => pr.isSelected);
    //   }
    //   return [];
    // }
    // return this.activity['activityProducts'];
  }

  getKeyMessages(prod): string {
    let keyMsgs = [];
    if (!_.isEmpty(prod['activityProductKeyMessages'])) {
      if (this.viewType === 'contactActivePres') {
        keyMsgs = prod['activityProductKeyMessages'].filter(keymsg => keymsg.indskr_isautomaticallyselected || keymsg['isSelected']).map(km => km['indskr_name']);
      } else {
        keyMsgs = prod['activityProductKeyMessages'].map(km => km['indskr_name']);
      }
    }
    if (!_.isEmpty(prod['keyMessages'])) {
      if (this.viewType === 'contactActivePres') {
        keyMsgs = _.concat(keyMsgs, prod['keyMessages'].filter(km => km.isSelected).map(keymsg => keymsg['name']));
      } else {
        keyMsgs = _.concat(keyMsgs, prod['keyMessages'].map(keymsg => keymsg['name']));
      }
    }
    return keyMsgs.join(', ');
  }

  getDuration(slide,content:any = null): string {
    let starttime = slide['indskr_starttime'] || slide['starttime'];
    let endtime = slide['indskr_endtime'] || slide['endtime'];

    if (starttime && endtime) {
      let milliseconds = differenceInMilliseconds(parseFloat(endtime), parseFloat(starttime));
      if (!isNaN(milliseconds) && milliseconds < 0) {
        return '00:00:00';
      }
      var hours = milliseconds / (1000 * 60 * 60);
      var absoluteHours = Math.floor(hours);
      var h = absoluteHours > 9 ? absoluteHours : '0' + absoluteHours;

      //Get remainder from hours and convert to minutes
      var minutes = (hours - absoluteHours) * 60;
      var absoluteMinutes = Math.floor(minutes);
      var m = absoluteMinutes > 9 ? absoluteMinutes : '0' + absoluteMinutes;

      //Get remainder from minutes and convert to seconds
      var seconds = (minutes - absoluteMinutes) * 60;
      var absoluteSeconds = Math.floor(seconds);
      var s = absoluteSeconds > 9 ? absoluteSeconds : '0' + absoluteSeconds;

      let keyMsgStr:string = '';
      if(content && content['presentationId'] && slide && slide['ckmpageid']){
        const pres = this.presService.getPresentationById(content['presentationId']);
        if(pres && pres.presentationPages && pres.presentationPages.length > 0){
          const page = pres.presentationPages.find(pg => pg.id == slide['ckmpageid']);
          if(page && page.keyMessage && page.keyMessage.length > 0){
              keyMsgStr= ' |';
            page.keyMessage.forEach(kMsg => {
              keyMsgStr+= ' '+kMsg.keyMessageName+','
            });
            keyMsgStr = keyMsgStr.substring(0, keyMsgStr.length - 1);
          }
        }
      }

      return h + ':' + m + ':' + s + keyMsgStr;

    } else {
      return '00:00:00';
    }
  }

  isSlideShared(slide): boolean {
    let slideContacts = slide['activityPresentationSlideContacts'] || [];
    let resourceSlideContacts = slide['activityResourceSlideContacts'] || [];
    if (slideContacts.length > 0 || resourceSlideContacts.length > 0) {
      let contact;
      let resourceContact;
      if(!_.isEmpty(this.contactService.contactInformation)) {
        let contactInfoId = (this.contactService.contactInformation['contactid']) ? this.contactService.contactInformation['contactid'] : this.contactService.contactInformation['ID'];
        contact = slideContacts.find(item => (item.contactid === contactInfoId) || (item.indskr_contactid === contactInfoId));
        resourceContact = resourceSlideContacts.find(item => (item.contactid === contactInfoId) || (item.indskr_contactid === contactInfoId));
      }
      if (contact) {
        let sentiment = (contact['indskr_pagesentiment']) ? contact['indskr_pagesentiment'] : contact['pagesentiment'];
        switch (sentiment) {
          case "POSITIVE":
            slide['sentimentImgURL'] = 'assets/imgs/sentiment-up.png';
            break;
          case "NEGATIVE":
            slide['sentimentImgURL'] = 'assets/imgs/sentiment-down.png';
            break;
          default:
            slide['sentimentImgURL'] = 'assets/imgs/sentiment-nutral.png';
            break;
        }
        return true;
      } else if (resourceContact) {
        let sentiment = (resourceContact['indskr_pagesentiment']) ? resourceContact['indskr_pagesentiment'] : resourceContact['pagesentiment'];
        switch (sentiment) {
          case "POSITIVE":
            slide['sentimentImgURL'] = 'assets/imgs/sentiment-up.png';
            break;
          case "NEGATIVE":
            slide['sentimentImgURL'] = 'assets/imgs/sentiment-down.png';
            break;
          default:
            slide['sentimentImgURL'] = 'assets/imgs/sentiment-nutral.png';
            break;
        }
        return true;
      }
      else {
        slide['sentimentImgURL'] = 'assets/imgs/sentiment-nutral.png';
        return false;
      }
    } else {
      return false;
    }

  }
  isResourceShared(resource: ActivityResource): boolean {
    let resourceContacts = resource.activityResourceContacts || [];
    if (resourceContacts.length == 0) return false;
    let contactInfoId = (this.contactService.contactInformation['contactid']) ? this.contactService.contactInformation['contactid'] : this.contactService.contactInformation['ID'];
    return resourceContacts.findIndex(item => item.indskr_contactid === contactInfoId) !== -1;
  }

  updateResourceContact(resource: ActivityResource, event?) {
    let isChecked = this.isResourceShared(resource);
    let contactInfoId = (this.contactService.contactInformation['contactid']) ? this.contactService.contactInformation['contactid'] : this.contactService.contactInformation['ID'];

    if (event && isChecked) {
      let idx = resource.activityResourceContacts.findIndex(con => con.indskr_contactid === contactInfoId);
      if (idx > -1) resource.activityResourceContacts.splice(idx, 1);
    } else {
      resource.activityResourceContacts.push(new ActivityResourceContact({ indskr_contactid: contactInfoId, indskr_startdate: resource.indskr_starttime, indskr_enddate: resource.indskr_endtime }));
    }
    this.events.publish("contact-content-update", true);
  }

  updateSentiments(page: Page, event?) {
    let isChecked = this.isSlideShared(page);
    let contactInfoId = (this.contactService.contactInformation['contactid']) ? this.contactService.contactInformation['contactid'] : this.contactService.contactInformation['ID'];

    if (event && isChecked) {
      if (page['activityPresentationSlideContacts']) {
        let idx = page['activityPresentationSlideContacts'].findIndex(con => con['contactid'] === contactInfoId);
        if (idx > -1) page['activityPresentationSlideContacts'].splice(idx, 1);
      } else if (page['activityResourceSlideContacts']) {
        let idx = page['activityResourceSlideContacts'].findIndex(con => con['indskr_contactid'] === contactInfoId);
        if (idx > -1) page['activityResourceSlideContacts'].splice(idx, 1);
      }
    } else {
      if (page['activityPresentationSlideContacts'] && page['activityPresentationSlideContacts'].length > 0) {
        let row = page['activityPresentationSlideContacts'].find(con => con['contactid'] === contactInfoId);

        if (row) {
          if (row['indskr_pagesentiment']) {
            row['indskr_pagesentiment'] = page.sentiment || SENTIMENT.NEUTRAL;
          } else {
            row['pagesentiment'] = page.sentiment || SENTIMENT.NEUTRAL;
          }
        } else {
          let obj = {
            "indskr_contactid": this.contactService.contactInformation.ID,
            "indskr_pagesentiment": page.sentiment || SENTIMENT.NEUTRAL,
          }
          page['activityPresentationSlideContacts'].push(new ActivityPresentationSlideContact(obj));
        }
      } else if (page['activityResourceSlideContacts'] && page['activityResourceSlideContacts'].length > 0) {
        let row = page['activityResourceSlideContacts'].find(con => con['indskr_contactid'] === contactInfoId);

        if (row) {
          if (row['indskr_pagesentiment']) {
            row['indskr_pagesentiment'] = page.sentiment || SENTIMENT.NEUTRAL;
          } else {
            row['pagesentiment'] = page.sentiment || SENTIMENT.NEUTRAL;
          }
        } else {
          let obj = {
            "indskr_contactid": this.contactService.contactInformation.ID,
            "indskr_pagesentiment": page.sentiment || SENTIMENT.NEUTRAL,
          }
          page['activityResourceSlideContacts'].push(new ActivityResourceContact(obj));
        }
      } else {
        let obj = {
          "indskr_contactid": this.contactService.contactInformation.ID,
          "indskr_pagesentiment": page.sentiment || SENTIMENT.NEUTRAL,
        }
        if (page['activityPresentationSlideContacts']) {
          page['activityPresentationSlideContacts'].push(new ActivityPresentationSlideContact(obj));
        }
        if (page['activityResourceSlideContacts']) {
          page['activityResourceSlideContacts'].push(new ActivityResourceContact(obj));
        }
      }
    }
    this.events.publish("contact-content-update", this.isSntimentsChanged(page, contactInfoId));

  }

  isSntimentsChanged(page, contactId): boolean {
    let result: boolean = false;
    let slide;
    if (this.activityCopy.activityPresentations && this.activityCopy.activityPresentations.length) {
      this.activityCopy.activityPresentations.forEach(pres => {
        pres.activityPresentationSlides.forEach(item => {
          if (item['id'] === page['id']) {
            slide = item;
          }
        });
      });
    } else if (this.activityCopy.activityResources && this.activityCopy.activityResources.length) {
      this.activityCopy.activityResources.forEach(res => {
        res.activityResourceSlides.forEach(item => {
          if (item['indskr_ioresourceid'] && item['indskr_ioresourceid'] === page['indskr_ioresourceid'] && item['indskr_pagenumber'] === page['indskr_pagenumber']) {
            slide = item;
          }
          else if (item['indskr_iodocumentid'] && item['indskr_iodocumentid'] === page['indskr_iodocumentid'] && item['indskr_pagenumber'] === page['indskr_pagenumber']) {
            slide = item;
          }
        });
      });
    }
    if (slide) {
      if (!slide['activityPresentationSlideContacts'] && page['activityPresentationSlideContacts'] && page['activityPresentationSlideContacts'].length > 0) {
        result = true;
      }
      else if (!slide['activityPresentationSlideContacts'] && page['activityPresentationSlideContacts'] && page['activityPresentationSlideContacts'].length == 0) {
        result = false;
      }
      else {
        if (slide['activityPresentationSlideContacts'] && page['activityPresentationSlideContacts']) {
          let slideContact = slide['activityPresentationSlideContacts'].filter(item => item['contactid'] === contactId);
          let pageContact = page['activityPresentationSlideContacts'].filter(item => item['contactid'] === contactId);
          if (slideContact.length > 0 && pageContact.length > 0) {
            result = !((slideContact[0]['pagesentiment'] === pageContact[0]['pagesentiment']));
          } else {
            result = true;
          }
        }
      }
      if (!slide['activityResourceSlideContacts'] && page['activityResourceSlideContacts'] && page['activityResourceSlideContacts'].length > 0) {
        result = true;
      }
      else if (!slide['activityResourceSlideContacts'] && page['activityResourceSlideContacts'] && page['activityResourceSlideContacts'].length == 0) {
        result = false;
      }
      else {
        if (slide['activityResourceSlideContacts'] && page['activityResourceSlideContacts']) {
          let slideContact = slide['activityResourceSlideContacts'].filter(item => item['indskr_contactid'] === contactId);
          let pageContact = page['activityResourceSlideContacts'].filter(item => item['indskr_contactid'] === contactId);
          if (slideContact.length > 0 && pageContact.length > 0) {
            result = !((slideContact[0]['indskr_pagesentiment'] === pageContact[0]['indskr_pagesentiment']));
          } else {
            result = true;
          }
        }
      }
    } else {
      result = true;
    }
    return result;
  }

  filterPresSlides(pres): Array<any> {
    let slideList = pres['activityPresentationSlides'] || pres['presentationPages'];
    return (!slideList) ? [] : slideList.filter(item => item['ckmpageid'] || item['id'] != null);
  }

  sortedPresSlidesByStartTime(content: ActivityPresentation | ActivityResource) {
    if (content instanceof ActivityPresentation) {
      if (!content || !content.activityPresentationSlides) return [];
      return content.activityPresentationSlides.sort((a, b) => a['starttime'] > b['starttime'] ? 1 : a['starttime'] === b['starttime'] ? 0 : -1);
    } else {
      if (!content || !content.activityResourceSlides) return [];
      return content.activityResourceSlides.sort((a, b) => a['indskr_starttime'] > b['indskr_starttime'] ? 1 : a['indskr_starttime'] === b['indskr_starttime'] ? 0 : -1);
    }

  }
  sortedResources(resources: ActivityResource[]) {
    if (!resources || !resources.length) return [];
    return resources.sort((a, b) => a['indskr_starttime'] > b['indskr_starttime'] ? 1 : a['indskr_starttime'] === b['indskr_starttime'] ? 0 : -1);
  }

  async addNewActivity(event, activity) {
    if (event.stopPropagation) event.stopPropagation();
    this.activityService.selectedActivity = activity;
    await this.activityDataService.updateActivityDetails(activity);
    if (activity.type == ActivityType.Appointment && !this.readOnlyMeeting && !this.activityService.teamViewActive) {
      //this.trackingService.tracking('Schedule Activity from Activity screen')
      this.trackingService.tracking('AgendaFollowUpMeeting', TrackingEventNames.ACTIVITY)

    }
    else if(activity.type == this.activityType.PhoneCall){

      //Do something which is specific to phone call activity

    }
    // To fetch the contacts for selected activity in order to save for adding into follow up meeting
    if (activity['ID']) {
      this.contactService.followUpMeetingContacts = [];
      if (activity) this.contactService.followUpMeetingContacts.
      push(...activity['contacts']);
    }

    this.createFollwupActivity(activity)


  }

  createFollwupActivity(activity : Activity){
    let startTime, endTime;
      startTime = new Date(activity.startDate);
      startTime.setDate(startTime.getDate() + 1);
      startTime.setHours(9);
      startTime.setMinutes(0);
      startTime.setSeconds(0);

      endTime = startTime;
      endTime = addMinutes(endTime, 30);
      let cellData = {
        startDate: startTime, endDate: endTime, from : this.activity.type
      }
      //Going to need a payload for initiation
      this.uiService.setCellData = { view: "follow-up", data: cellData };

      if (this.device.shouldBeMobileView) {
        this.navService.pushWithPageTracking(NewActivityComponent, PageName.NewActivityComponent);
      } else {
        this.uiService.setShowNewActivity(!this.uiService.showNewActivity);
      }
      //this.events.publish('openNewActivityPage');
  }

  schedulerActionInvoked(ev) {
    if (this.device.isOffline) return;
    this.onCustomAction.emit(ev);
  }

  customerInquiryDelete(e) {
    this.onDeleteActivity.emit(e);
  }

  captureEventRegResponse(response: boolean, eventRegistrationId: string, contactName: string, contactId: string, eventId: string) {
    this.customerEventService.captureEventRegistrationResponse(response, eventRegistrationId, contactId, eventId).then(res => {
      if (response) {
        this.customerEvent.event.status = 'accepted';
        this.notificationService.notify(this.translate.instant('EXPANDABLE_HAS_SIGNED_UP', { text: contactName }), 'Expandable Component');
      } else {
        this.customerEvent.event.status = 'declined';
        this.notificationService.notify(this.translate.instant('EXPANDABLE_HAS_NOT_SIGNED_UP', { text: contactName }), 'Expandable Component', 'top', ToastStyle.DANGER);
      }
      this.eventRegStatus.emit({ response: response, eventRegistrationId: eventRegistrationId });
    }).catch(err => {
      //Resetting the status in case of failure
      this.customerEvent.event.status = null;
    });
  }

  openSP(sp) {
    this.openSPContactProfile.emit(sp);
  }

  formattedDate(val): string {
    return (val && !isNaN(parseInt(val))) ? format(parseInt(val), this.dateTimeFormatsService.dateToUpper) : ''
  }

  openOp(opportunity) {
this.openOpportunity.emit(opportunity.ID);
  }

}
