import { ActivitiesPageComponent } from './../../../pages/activities-page/activities-page';
import { Component, Input, OnChanges, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { DeviceService } from '../../../services/device/device.service';
//import { timer } from 'rxjs/observable/timer';
//import { AsyncPipe } from '@angular/common';
//import { observableToBeFn } from 'rxjs/testing/TestScheduler';
import { TimeOffUtilityService } from '../../../services/time-off/time-off-utility.service';
import { TimeOffService } from '../../../services/time-off/time-off.service';
import { PopoverController, NavParams,  AlertController } from '@ionic/angular';
import { Events } from '@omni/events';
import { PopoverComponent } from '../../popover/popover';
import { DatetimePickerComponent } from '../../datetime-picker/datetime-picker';
import { UIService } from '../../../services/ui/ui.service';
import { DurationType, TimeOffActivity, TimeOffStatus } from '../../../classes/activity/timeoff.class';
//import { format } from 'date-fns';
import { NavigationService, PageName } from '../../../services/navigation/navigation.service';
//import { TimeOffComponent } from '../time-off-home/time-off-home';
import * as moment from 'moment';
import { TimeOffDataService } from '../../../data-services/time-off/time-off.data.service';
import { RepServices } from '../../../data-services/rep/rep.services';
import { AuthenticationService } from "../../../services/authentication.service";
//import { TImeOffReason } from "../../../classes/timeoff/timeoffReasons.class";
import { FooterService, FooterViews } from '../../../services/footer/footer.service';
import { ActivityService } from "../../../services/activity/activity.service";
import { NotificationService, ToastStyle } from '../../../services/notification/notification.service';
import { REP_STATUS } from '../../../models/rep-status-model';
//import { ActivityType } from "../../../classes/activity/activity.class";
import { Subject, Subscription } from 'rxjs';
import { TrackService, TrackingEventNames } from '../../../services/logging/tracking.service';
import { FeatureActionsMap } from '../../../classes/authentication/user.class';
import { ActivityType } from '../../../classes/activity/activity.class';
import { TranslateService } from '@ngx-translate/core';
import { DateTimeFormatsService } from "../../../services/date-time-formats/date-time-formats.service";
import { IndFormFieldViewDataModel, FormFieldType } from '../../../models/indFormFieldDataModel';
import { IndDateTimeFormViewDataModel } from '@omni/models/indDateTimeFormDataModel';
import { CurViewPageType, DateTimeFieldType, IndDatetimeFormComponent } from '@omni/components/shared/ind-datetime-form/ind-datetime-form';
import _ from 'lodash';
import { takeUntil } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { addMinutes, format } from 'date-fns';
import { EventsService } from '@omni/services/events/events.service';
import { NothingSelectedView } from '@omni/components/shared/nothing-selected-view/nothing-selected-view';
import { LocalizationService } from '@omni/services/localization/localization.service';

/**
 * Generated class for the TimeOffDetailsComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'time-off-details',
  templateUrl: 'time-off-details.html',
  styleUrls:['time-off-details.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimeOffDetailsComponent implements OnChanges {

  @Input() selectedTot: TimeOffActivity = null;
  private _isNewlyCreatedActivity: boolean = false;
  private isReasonAutoApproved: boolean = false;
  get isNewlyCreatedActivity(): boolean {
    return this._isNewlyCreatedActivity;
  }
  @Input() set isNewlyCreatedActivity(newValue: boolean) {
    this._isNewlyCreatedActivity = newValue;
  }
  private selectedTotSubscription: Subscription;

  public activityType: any;
  public isMobile: boolean = false;
  public currentStatus: string = "";
  public eventType: string = "All Day"
  //private canApprovalDisabled: boolean = false;
  public hasAutoApp: boolean = false;
  public popupSelectedEventText: string;
  public timeOffAutoSubject: boolean = false;

  public pageTitle: string = "";
  public pageTitleControls = [];

  // date time picker
  public startDateField: IndDateTimeFormViewDataModel;
  public startTimeField: IndDateTimeFormViewDataModel;
  public endDateField: IndDateTimeFormViewDataModel;
  public endTimeField: IndDateTimeFormViewDataModel;
  public durationField: IndDateTimeFormViewDataModel;
  public timePeriodField: IndDateTimeFormViewDataModel;
  private startDateTimeValue: string;
  private startDateValue: string;
  private startTimeValue: string;
  private endDateTimeValue: string;
  private endDateValue: string;
  private endTimeValue: string;
  private duration: string;
  public selectedDurationType: string;
  public popupSelectedTimePeriodText: string;
  private ngDestroy$ = new Subject<boolean>();
  public backgroundUploadInProgress: boolean = false;
  private isClickedReason: boolean = false;
  private shouldCheckConflict: boolean = false;
  private conflictCheckCounter: number = 0;
  public displayConflictMsg: boolean = false;
  public commentsForAdminLeave: boolean = false;
  public hideSubjectAndPosition: boolean = false;

  constructor(
    public navParams: NavParams,
    public device: DeviceService,
    private popoverCtrl: PopoverController,
    private changeDetectorRefref: ChangeDetectorRef,
    private events: Events,
    private eventService: EventsService,
    private uiService: UIService,
    public timeOffService: TimeOffService,
    private timeOffUtilityService: TimeOffUtilityService,
    private timeOffDataService: TimeOffDataService,
    private navService: NavigationService,
    public repService: RepServices,
    public footerService: FooterService,
    public authenticationService: AuthenticationService,
    public activityService: ActivityService,
    private notificationService: NotificationService,
    public trackingService: TrackService,
    private alertCtrl: AlertController,
    public translate: TranslateService,
    public dateTimeFormatsService: DateTimeFormatsService,
    private datePipe: DatePipe,
    private localisationService: LocalizationService,
  ) {
    this.activityType = !(this.navParams.data && this.navParams.data.activityType) ? this.uiService.uiServiceData : undefined;
    this.hasAutoApp = this.authenticationService.hasFeatureAction(FeatureActionsMap.TIME_OFF_AUTO_APPROVAL);
    this.commentsForAdminLeave = this.authenticationService.hasFeatureAction(FeatureActionsMap.TIMEOFF_ADMIN_COMMENTS);
    this.hideSubjectAndPosition = this.authenticationService.hasFeatureAction(FeatureActionsMap.TIMEOFF_HIDE_SUBJECT_POSITION);
  }

  ngOnInit() {
    this.displayConflictMsg = this.checkConflictMsgDisplay();
    this.activityService.signalRefreshConflictAlertMsg$.asObservable()
    .pipe(takeUntil(this.ngDestroy$)).subscribe(() => {
      this.displayConflictMsg = this.checkConflictMsgDisplay();
    });

    this.setViewData();
    this.setTimeOffFrequency();

    this.translate.onLangChange.subscribe((data)=>{
      this.setTimeOffFrequency();
      this.initPageTitleControls();
      this.pageTitle = this.timeOffAutoSubject ? this.selectedTot.subject : this.selectedTot.subject == 'Time Off' ? this.translate.instant('TIME_OFF') : this.selectedTot.subject;
    });
    this.eventService.observe('device:deviceIsOffline').pipe(takeUntil(this.ngDestroy$)).subscribe((status) => this.initPageTitleControls());
    this.eventService.observe("sync:completed").pipe(takeUntil(this.ngDestroy$)).subscribe((status) => {
      this.hasAutoApp = this.authenticationService.hasFeatureAction(FeatureActionsMap.TIME_OFF_AUTO_APPROVAL);
      this.initPageTitleControls()
    });
    this.eventService.observe('device:deviceIsOnline').pipe(takeUntil(this.ngDestroy$)).subscribe((status) => this.initPageTitleControls());
    this.eventService.observe('userStateChanged').pipe(takeUntil(this.ngDestroy$)).subscribe((status) => this.initPageTitleControls());
    this.device.isBackgroundUploadInProgressObservable.pipe(takeUntil(this.ngDestroy$)).subscribe(inProgress => {
      if (this.navService.getCurrentPageName() === PageName.TimeOffComponent || (this.navService.getCurrentPageName() == PageName.ActivitiesPageComponent && this.navService.getActiveChildNavViewPageName() === PageName.TimeOffDetailsComponent)) {
        if (this.selectedTot) {
          this.backgroundUploadInProgress = inProgress && this.selectedTot.pendingPushToDynamics;
          this.initPageTitleControls();
          this.footerService.initButtons(FooterViews.TimeOffDetails);
          this.changeDetectorRefref.detectChanges();
        }
      }
    });
  }

  private setTimeOffFrequency() {
    switch (this.timeOffService.popupSelectedEvent) {
      case 'All Day':
        this.popupSelectedEventText = this.translate.instant('ALL_DAY');
        break;
      case 'Half Day':
        this.popupSelectedEventText = this.translate.instant('HALF_DAY');
        break;
      case 'Hourly':
        this.popupSelectedEventText = this.translate.instant('HOURLY');
        break;
      default:
        this.popupSelectedEventText = this.timeOffService.popupSelectedEvent;
        break;
    }
    // Update start/end time field with selected time from the time picker
    this.dateTimeFormatsService.updateSelectedTimeTimeOffRequest$.pipe(takeUntil(this.ngDestroy$)).subscribe((timeData) => {
      if (timeData) {
        if (timeData.id == DateTimeFieldType.StartTimeField) {
           this.selectedTot.totStartTime = new Date(timeData.time);
           if (this.selectedDurationType == DurationType.Hourly) {
             let setCombinedDateTimeForEndTime = format(addMinutes(timeData.time, 60));
             this.selectedTot.totEndTime = new Date(setCombinedDateTimeForEndTime);
           }
           this.activityService.selectedActivity['totStartTime'] = new Date(this.selectedTot.totStartTime);
           this.activityService.selectedActivity['totEndTime'] = new Date(this.selectedTot.totEndTime);
        } else if (timeData.id == DateTimeFieldType.EndTimeField) {
          this.selectedTot.totEndTime = new Date(timeData.time);
          this.activityService.selectedActivity['totEndTime'] = new Date(this.selectedTot.totEndTime);
        }
        this.timeOffService.generateTimeDurationText(this.selectedTot);
        this.timeOffService.setIsTotModified(true);
        this.initDateTimeFormFields();
        this.timeOffUtilityService.updateTimeOff(this.selectedTot, false, false);
        this.conflictCheckCounter = 2;
        this.shouldCheckConflict = true;
        this.changeDetectorRefref.detectChanges();
        this.changeDetectorRefref.markForCheck();
      }
    });
  }

  private setViewData() {
    this.selectedTotSubscription = this.timeOffService.selectedTot.subscribe(tot => {
      if (tot == null) {
        this.uiService.showNewActivity = true;
        this.uiService.activeView = "";
        this.activityService.selectedActivity = null;
      }
      else {
        this.hasAutoApp = this.authenticationService.hasFeatureAction(FeatureActionsMap.TIME_OFF_AUTO_APPROVAL);
        this.selectedTot = tot;
        this.activityService.selectedActivity = this.selectedTot;
        this.initializeDetails();
      }
    });
  }

  private initPageTitleControls() {
    let buttons = [];
    if(!this.selectedTot) return [];
    if (!this.authenticationService.user.buConfigs || this.authenticationService.user.buConfigs['indskr_timeoffreopen'] == true || this.authenticationService.user.buConfigs['indskr_timeoffreopen'] == null ) {
      buttons.push({
        id: "reOpen",
        name: this.translate.instant('TIMEOFF_REOPEN'),
        imgSrc: 'assets/imgs/header_reopen.svg',
        isDisabled: this.backgroundUploadInProgress || this.device.isOffline || !(((this.selectedTot.statuscode === TimeOffStatus.Approved || this.selectedTot.statuscode === TimeOffStatus.InReview) && this.authenticationService.user.systemUserID == this.selectedTot.totOwnerId) || (this.authenticationService.user.systemUserID != this.selectedTot.totOwnerId && this.selectedTot.statuscode === TimeOffStatus.Approved)),
        align: "right"
      })
    }
    if (this.timeOffService.totMode == 'myRequests') {
      buttons.push({
        id: "scrap",
        imgSrc: 'assets/imgs/header_cancel.svg',
        name: this.translate.instant('SCRAP'),
        isDisabled: this.backgroundUploadInProgress || (this.selectedTot.statuscode !== TimeOffStatus.Open || this.authenticationService.user.systemUserID != this.selectedTot.totOwnerId || this.activityService.teamViewActive),
        align: "right"
      })
      this.isReasonAutoApproved = false;
      let reasonsConfig =  this.authenticationService.user.ioConfigurations?.find(o=>o.configName=="Timeoff.Reasons.Configuration")
      if(reasonsConfig && this.selectedTot.reason){
        let selectedReasonConfig = JSON.parse(reasonsConfig.configValue).find(o=> o["Reason"]== this.selectedTot.reason);
        if(selectedReasonConfig && selectedReasonConfig["AutoApproved"] == "Yes") this.isReasonAutoApproved = true
      }
      if (!this.hasAutoApp && !this.isReasonAutoApproved) {
        buttons.push({
          id: "send-for-approval",
          imgSrc: 'assets/imgs/header_send.svg',
          name: this.translate.instant('SEND'),
          isDisabled: this.backgroundUploadInProgress || (!this.selectedTot.reason || this.selectedTot.statuscode !== TimeOffStatus.Open || this.device.isOffline || this.authenticationService.user.systemUserID != this.selectedTot.totOwnerId || this.activityService.teamViewActive) || (this.selectedTot.timeOffReason==548910003 && !this.selectedTot.comments && this.commentsForAdminLeave),
          align: "right"
        })
      }
      if (this.hasAutoApp || this.isReasonAutoApproved) {
        buttons.push({
          id: "save",
          imgSrc: 'assets/imgs/header_save.svg',
          name: this.translate.instant('CR_SAVE'),
          isDisabled: this.backgroundUploadInProgress || (!this.selectedTot.reason || this.selectedTot.statuscode !== TimeOffStatus.Open || this.device.isOffline || this.authenticationService.user.systemUserID != this.selectedTot.totOwnerId || this.activityService.teamViewActive)|| (this.selectedTot.timeOffReason==548910003 && !this.selectedTot.comments && this.commentsForAdminLeave),
          align: "right"
        })
      }
    }
    if(!(this.timeOffService.totMode != 'teamRequests')) {
      buttons.push({
        id: "not-approve",
        imgSrc: 'assets/imgs/header_cancel.svg',
        name: this.translate.instant('NOT_APPROVE'),
        isDisabled: this.backgroundUploadInProgress || (this.canApprovalDisabled || this.activityService.teamViewActive),
        align: "right"
      },
      {
        id: "approve",
        imgSrc: 'assets/imgs/header_complete.svg',
        name: this.translate.instant('APPROVE'),
        isDisabled: this.backgroundUploadInProgress || (this.canApprovalDisabled || this.activityService.teamViewActive),
        align: "right"
      })
    }
    this.pageTitleControls = buttons;
  }

  public onPageTitleControlClick(id: string) {
    switch (id) {
      case 'not-approve':
        this.notApproveTimeOff();
        break;
      case 'approve':
        this.approveTimeOff();
        break;
      case 'save':
        this.submitTimeOff();
        break;
      case 'send-for-approval':
        this.submitTimeOff();
        break;
      case 'scrap':
        this.scrapTimeOff();
        break;
      case 'reOpen':
        this.reOpenTimeOff();
        break;
      case 'close':
        this.closepage();
        break;
    }
  }

  ngOnChanges() {
    this.setViewData();
  }

  ngAfterContentChecked() {
    if (this.navService.getCurrentPageName() === PageName.TimeOffComponent || (this.navService.getCurrentPageName() == PageName.ActivitiesPageComponent && this.navService.getActiveChildNavViewPageName() === PageName.TimeOffDetailsComponent)) {
      if (this.selectedTot != undefined && this.selectedTot != null) {
        this.initDateTimeFormFields();
        this.changeDetectorRefref.markForCheck();
        this.footerService.initButtons(FooterViews.TimeOffDetails);
      }
    }
  }

  ngOnDestroy() {
    this.selectedTotSubscription.unsubscribe();
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }

  /**
   *
   *
   * @memberof TimeOffDetailsComponent
   */
  initializeDetails() {
    this.isMobile = this.device.isMobileDevice;
    this.footerService.initButtons(FooterViews.TimeOffDetails);
    if (this.selectedTot != undefined && this.selectedTot != null) {
      this.currentStatus = this.selectedTot.statusValue;
      this.timeOffService.popupSelectedEvent = this.eventType = this.timeOffUtilityService.decodeEventType(this.selectedTot);
      this.timeOffService.popupSelectedTimePeriodEvent = this.timeOffUtilityService.getTimePeriodForTimeOffEvent(this.selectedTot);
      this.popupSelectedTimePeriodText = this.timeOffService.getTimePeriodText(this.timeOffService.popupSelectedTimePeriodEvent); // AM or PM
      this.selectedDurationType = this.timeOffUtilityService.checkCurrentDurationType(this.timeOffUtilityService.currentDurationType); // All Day, Half Day, Hourly
      this.timeOffService.reasonSelectedEvent = this.setReason(this.selectedTot.reason);
      this.pageTitle = this.timeOffAutoSubject ? this.selectedTot.subject : this.selectedTot.subject == 'Time Off' ? this.translate.instant('TIME_OFF') : this.selectedTot.subject;
      this.initPageTitleControls();
      this.initDateTimeFormFields();
      //this.canApprovalDisabled = this.setCanApprovalDisabled;
      this.changeDetectorRefref.detectChanges();
      this.changeDetectorRefref.markForCheck();
    }
  }

  /**
   *
   *
   * @memberof TimeOffDetailsComponent
   */
  openSubjectPopover = (event) => {
    if (this.selectedTot != null && !this.activityService.teamViewActive) {
      if (this.selectedTot.statuscode === TimeOffStatus.Open && this.authenticationService.user.systemUserID === this.selectedTot.totOwnerId && !this.isAutoSubjectEnabled) {
        let subject = (event && event.target && event.target.value && event.target.value.length != 0) ? event.target.value : this.translate.instant('TIME_OFF');
        if (this.selectedTot.name != subject) {
          this.selectedTot.name = subject;
          this.selectedTot.subject = subject;
          this.pageTitle = subject;
          this.timeOffUtilityService.updateTimeOff(this.selectedTot, false, false);
          this.initDateTimeFormFields();
          this.changeDetectorRefref.markForCheck();
        }
      }
    }
  }

  handleCommentsInput(event) {
    if (this.selectedTot != null && !this.activityService.teamViewActive) {
      if (this.selectedTot.statuscode === TimeOffStatus.Open && this.authenticationService.user.systemUserID === this.selectedTot.totOwnerId) {
        let comment = (event && event.target && event.target.value && event.target.value.length != 0) ? event.target.value : "";
        if (this.selectedTot.comments != comment) {
          this.selectedTot.comments = comment;
          this.timeOffUtilityService.updateTimeOff(this.selectedTot, false, false);
          this.changeDetectorRefref.markForCheck();
        }
      }
    }
  }

  /**
   *
   *
   * @memberof TimeOffDetailsComponent
   */
  async openEventPopover (event) {
    if (this.selectedTot != null && !this.activityService.teamViewActive) {
      if (this.selectedTot.statuscode === TimeOffStatus.Open && this.authenticationService.user.systemUserID === this.selectedTot.totOwnerId) {
        let popover = await this.popoverCtrl.create({
          component: PopoverComponent,
          componentProps:{ field: 'timeOffEvent' },
          cssClass: "timeOffEvent-popover",
          event:event});
        popover.present();
        popover.onDidDismiss().then((obj: any) => {
          const data = obj && obj.data;
          if (data != null) {
            this.changeDetectorRefref.detectChanges();
            this.timeOffService.popupSelectedEvent = data;
            this.popupSelectedEventText = data;
            let dateUpdate: Object = this.timeOffUtilityService.getDateTimeForTimeOffEvent(data, this.selectedTot);
            this.selectedTot.totStartTime = this.activityService.selectedActivity['totStartTime'] = dateUpdate["totStartTime"];
            this.selectedTot.totEndTime = this.activityService.selectedActivity['totEndTime'] = dateUpdate["totEndTime"];
            this.selectedTot.totIsAlldayEvent = this.timeOffUtilityService.recodeEventType(data);
            this.selectedTot.durationType = this.timeOffUtilityService.decodeEventType(this.selectedTot);
            this.selectedDurationType = this.timeOffUtilityService.checkCurrentDurationType(this.timeOffUtilityService.currentDurationType);
            this.timeOffService.popupSelectedTimePeriodEvent = this.timeOffUtilityService.getTimePeriodForTimeOffEvent(this.selectedTot);
            this.popupSelectedTimePeriodText = this.timeOffService.popupSelectedTimePeriodEvent;
            if(this.authenticationService.user.ioConfigurations?.find(o=>o.configName=="Timeoff.Reasons.Configuration")){
              this.selectedTot.reason = '';
              this.selectedTot.timeOffReason = null;
              if (this.authenticationService.hasFeatureAction(FeatureActionsMap.TIME_OFF_AUTOSUBJECT)) {
                this.selectedTot.name = this.translate.instant('TIME_OFF');
                this.selectedTot.subject = this.translate.instant('TIME_OFF');
                this.timeOffAutoSubject = true;
                this.pageTitle = this.selectedTot.subject;
              }
            }
            this.timeOffUtilityService.updateTimeOff(this.selectedTot, false, false);
            this.initDateTimeFormFields();
            this.shouldCheckConflict = true;
            this.changeDetectorRefref.detectChanges();
            this.changeDetectorRefref.markForCheck();
          }
        });
      }
    }
  };

  async openTimePeriodPopover (event) {
    if (this.selectedTot != null && !this.activityService.teamViewActive) {
      if (this.selectedTot.statuscode === TimeOffStatus.Open && this.authenticationService.user.systemUserID === this.selectedTot.totOwnerId) {
        let popover = await this.popoverCtrl.create({
          component: PopoverComponent,
          componentProps:{ field: 'timePeriod' },
          cssClass: "timeOffEvent-popover",
          event:event});
        popover.present();
        popover.onDidDismiss().then((obj: any) => {
          const data = obj && obj.data;
          if (data != null) {
            this.shouldCheckConflict = true;
            this.changeDetectorRefref.detectChanges();
            this.timeOffService.popupSelectedTimePeriodEvent = data;
            this.popupSelectedTimePeriodText = data;
            let dateUpdate: Object = this.timeOffUtilityService.getDateTimeForTimeOffEvent("Half Day", this.selectedTot);
            if (this.popupSelectedTimePeriodText == "AM") {
              let halfStartTIme: Date = moment(dateUpdate["totStartTime"]).set('hour', 0).set('minute', 0).set('second', 0).toDate();
              let halfEndTIme: Date = moment(dateUpdate["totEndTime"]).set('hour', 11).set('minute', 59).set('second', 59).toDate();
              dateUpdate["totStartTime"] = halfStartTIme.valueOf();
              dateUpdate["totEndTime"] = halfEndTIme.valueOf();
            } else {
              let halfStartTIme: Date = moment(dateUpdate["totStartTime"]).set('hour', 12).set('minute', 0).set('second', 0).toDate();
              let halfEndTIme: Date = moment(dateUpdate["totEndTime"]).set('hour', 23).set('minute', 59).set('second', 59).toDate();
              dateUpdate["totStartTime"] = halfStartTIme.valueOf();
              dateUpdate["totEndTime"] = halfEndTIme.valueOf();
            }
            this.selectedTot.totStartTime = dateUpdate["totStartTime"];
            this.selectedTot.totEndTime = dateUpdate["totEndTime"];
            this.activityService.selectedActivity['totStartTime'] = new Date(this.selectedTot.totStartTime);
            this.activityService.selectedActivity['totEndTime'] = new Date(this.selectedTot.totEndTime);
            this.timeOffUtilityService.updateTimeOff(this.selectedTot, false, false);
            this.initDateTimeFormFields();
            this.changeDetectorRefref.detectChanges();
            this.changeDetectorRefref.markForCheck();
          }
        });
      }
    }
  };

  /**
     *
     *
     * @memberof TimeOffDetailsComponent
     */
  async openReasonPopover (event) {
    if (this.selectedTot != null && !this.activityService.teamViewActive) {
      if (this.selectedTot.statuscode === TimeOffStatus.Open && this.authenticationService.user.systemUserID === this.selectedTot.totOwnerId) {
        let popover = await this.popoverCtrl.create({
          component: PopoverComponent,
          componentProps:{ field: 'ReasonListing' },
          cssClass: "subject-popover",
          event:event});
        popover.present();
        popover.onDidDismiss().then((obj: any) => {
          const data = obj && obj.data;
          this.trackingService.tracking('TimeOffUpdateReason', TrackingEventNames.TIMEOFF);
          this.isClickedReason = true;
          if (data != null) {
            if (this.selectedTot.reason != data.reason) {
              if (this.authenticationService.hasFeatureAction(FeatureActionsMap.TIME_OFF_AUTOSUBJECT)) {
                this.selectedTot.name = data.reason + ' - ' + this.translate.instant('TIME_OFF');
                this.selectedTot.subject = data.reason + ' - ' + this.translate.instant('TIME_OFF');
                this.timeOffAutoSubject = true;
                this.pageTitle = this.selectedTot.subject;
              }
              this.selectedTot.reason = data.reason;
              //let totReason: TImeOffReason = this.timeOffService.timeOffReasonList.filter(data => data.reason === this.selectedTot.reason)[0];
              this.selectedTot.timeOffReason = data.reasonCode;
              this.timeOffService.setIsTotModified(true);
              this.timeOffUtilityService.updateTimeOff(this.selectedTot, false, false).then(() => {
                this.initPageTitleControls();
                this.footerService.initButtons(FooterViews.TimeOffDetails);
              });
            }
          }
        })
      }
    }
  }
  /**
     *
     *
     * @memberof TimeOffDetailsComponent
     */
  closepage(): void {
    let view: string = this.timeOffService.getMobileTracker();
    this.uiService.activeView = "";
    this.uiService.prevView = 'Timeoff';
    // this.timeOffService.setSelectedTot(undefined);
    // this.activityService.selectedActivity = undefined;
    //update time off request and send for review
    // if(this.timeOffService.isTotModified) this.timeOffUtilityService.updateTimeOff(this.selectedTot, false);
    if (this.device.shouldBeMobileView) {
      if (view === "agenda" || view === "calendar") {
        this.timeOffService.unsetMobileTracker();
        this.activityService.prevSelectedActivity = this.activityService.selectedActivity;
        this.activityService.selectedActivity = undefined;
        this.activityService.selectedActivityAtHome = undefined;
        this.uiService.showRightPane = false;
        this.uiService.activeView = 'Appointment';
        if (view === 'agenda') {
          this.activityService.shouldScrollToPrevSelectedActivity = !this.activityService.didOpenDetailPageFromAgendaPage;
          this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView);
          this.events.publish('scrollToDate');
        }
        // if(view === "calendar") {
        //   this.navService.popToRootWithPageTracking().then(res => {
        //     this.navService.pushWithPageTracking(CalendarPoCPage, PageName.CalendarPage, null, PageName.CalendarPage);
        //   }).catch(err => {
        //     console.log("some error at navigating time-off details")
        //   });
        // }
        // else if(view === "agenda") {
        //   // this.navService.popToRootWithPageTracking();
        //   this.uiService.showRightPane = false;
        // }
      } else if (view === "timeOff") {
        this.timeOffService.unsetMobileTracker();
        this.timeOffService.totShowRightPane = false;
        // this.navService.popWithPageTracking().then(
        //   success => {
        //     console.log(success);
        //   },
        //   err => {
        //     console.log(err);
        //   }
        // )
      } else if (view === "") {
        // alert("time off view undefined");
        this.activityService.prevSelectedActivity = this.activityService.selectedActivity;
        this.activityService.selectedActivity = undefined;
        this.activityService.selectedActivityAtHome = undefined;
        if (this.uiService.activitiesPageTab === 'day' || this.uiService.activitiesPageTab === 'agenda') {
          this.uiService.showRightPane = false;
          this.uiService.activeView = 'Appointment';
          this.navService.popToRootWithPageTracking();
        } else if (this.uiService.activitiesPagePlanTabSelectedPlan === 'planTabTodo') {
          if (this.device.shouldBeMobileView) {
            this.uiService.showRightPane = false;
          }

          this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView);
        } else {
          this.timeOffService.unsetMobileTracker();
          this.navService.popWithPageTracking().then(res => { })
            .catch(err => {
              this.navService.popToRootWithPageTracking();
            })
        }
      } else {
        this.timeOffService.totShowRightPane = false;
        this.uiService.showRightPane = false;
      }
    } else {
      // this.navService.popToRootWithPageTracking();
      this.timeOffService.totShowRightPane = false;
      this.uiService.showRightPane = false;
    }
    this.activityService.didOpenDetailPageFromAgendaPage = false;
  }

  /**
   *
   *
   * @memberof TimeOffDetailsComponent
   */
  get positionName(): string {
    if (this.selectedTot != undefined && this.selectedTot != null) {
      return (this.selectedTot.positionValue === "" || this.selectedTot.positionValue === undefined) ? this.authenticationService.user.positionName.toString() : this.selectedTot.positionValue;
    }
    return this.authenticationService.user.positionName.toString();

  }

  /**
   *
   *
   * @memberof TimeOffDetailsComponent
   */
  get approvalDateFormatted(): string {
    if (this.selectedTot.approvalDate != undefined && moment(this.selectedTot.approvalDate).isValid()) {
      return this.datePipe.transform(this.selectedTot.approvalDate, this.dateTimeFormatsService.date, undefined, this.translate.currentLang)
    }
    return "";
  }

  /**
   *
   *
   * @memberof TimeOffDetailsComponent
   */
  public get canApprovalDisabled(): boolean {
    if (this.selectedTot != null) {
      //CWD-2421 check if android user status set to offline
      if (this.selectedTot.statuscode !== TimeOffStatus.InReview || this.device.isOffline || (this.repService.getCurrentUserState() === REP_STATUS.OFFLINE.userState)) {
        return true;
      } else {
        return false;
      }
    }
  }

  /**
   *
   *
   * @memberof TimeOffDetailsComponent
   */
  get displayTeamTotSpecifics(): boolean {
    return (this.timeOffService.totMode == 'teamRequests' && this.selectedTot.statuscode === TimeOffStatus.Approved) && (this.selectedTot.type == ActivityType.TimeOffRequest || this.selectedTot.type == ActivityType.TimeOff);
  }

  /**
   *
   *
   * @memberof TimeOffDetailsComponent
   */
  get isAutoSubjectEnabled(): boolean {
    return this.authenticationService.hasFeatureAction(FeatureActionsMap.TIME_OFF_AUTOSUBJECT);
  }

  /**
   *
   *
   * @memberof TimeOffDetailsComponent
   */
  setReason(data: any): string {
    this.selectedTot.reason = data;
    return this.selectedTot.reason;
  }

  /**
   *
   *
   * @memberof TimeOffDetailsComponent
   */
  scrapTimeOff() {
    if (this.selectedTot.statuscode !== TimeOffStatus.Open || this.authenticationService.user.systemUserID != this.selectedTot.totOwnerId || this.activityService.teamViewActive) return;
    this.trackingService.tracking('TimeOffScrapTimeOffTool', TrackingEventNames.TIMEOFF)
    let view: string = this.timeOffService.getMobileTracker();
    this.timeOffUtilityService.scrapTimeOff(this.selectedTot, view);
  }

  /**
   *
   *
   * @memberof TimeOffDetailsComponent
   */
  public async submitTimeOff(fromActionBar?: boolean) {
    if (this.backgroundUploadInProgress || !this.selectedTot.reason || this.selectedTot.statuscode !== TimeOffStatus.Open || this.device.isOffline || this.authenticationService.user.systemUserID != this.selectedTot.totOwnerId || this.activityService.teamViewActive) return;
    if (fromActionBar) {
      this.trackingService.tracking('TimeOffSendforApproval', TrackingEventNames.TIMEOFF)
    }
    else {
      this.trackingService.tracking('TimeOffSendforApproval', TrackingEventNames.TIMEOFF)
    }
    if (this.device.isOffline || (this.repService.getCurrentUserState() === REP_STATUS.OFFLINE.userState)) {
      this.notificationService.notify(this.translate.instant('YOUR_DEVICE_IS_OFFLINE'), 'TimeOffDetails', 'top', undefined, 2000, true);
      return;
    }
    // Check if the feature auto approval is enabled

    try {
      // check time off grace period
      let days: any = moment(new Date()).diff(moment(new Date(this.selectedTot.totStartTime)), 'days');
      let pastTimeOffDays: number = this.authenticationService.user.pastTimeOffGracePeriod;
      if(pastTimeOffDays && days > pastTimeOffDays) {
        this.notificationService.notify(this.translate.instant('NEW_ACTIVITY_TOAST_CAN_NOT_SCHEDULE_TIMEOFF',{maxTimeOffStartDays:pastTimeOffDays}),'Date Time Picker','top',ToastStyle.DANGER);
        return;
      }
      //Alert popup is only when user is not mapped to Auto Approval Feature Action
      let response: Boolean = false;
      if (this.hasAutoApp || this.isReasonAutoApproved) {
        response = true;
      } else {
        response = await this.showAlert(this.translate.instant('R_U_SURE_WANT_SUB_TIME_OFF'), this.translate.instant('SEND_FOR_APPROVAL_TIMEOFF'), this.translate.instant('CANCEL'), this.translate.instant('SEND'));
      }
      if (response && !this.backgroundUploadInProgress) {
        if (!this.device.isOffline && this.selectedTot.timeOffRequestId.includes('offline')) {
          await this.timeOffDataService.uploadOneOfflineTimeOff(this.selectedTot);
        }
        await this.timeOffUtilityService.updateTimeOff(this.selectedTot, true, this.hasAutoApp || this.isReasonAutoApproved, true);
        this.displayConflictMsg = false;
      }
    } catch (error) {
      // This is just to catch failure from "uploadOneOfflineTimeOff"
      console.error('submitTimeOff: Failed to upload offline data');
    }
  }

  /**
   *
   *
   * @memberof TimeOffDetailsComponent
   */
  public async cancelReviewTimeOff() {
    if (this.backgroundUploadInProgress || this.device.isOffline || this.authenticationService.user.systemUserID != this.selectedTot.totOwnerId || this.activityService.teamViewActive || this.selectedTot.statuscode === TimeOffStatus.Open) return;
    if (this.device.isOffline || (this.repService.getCurrentUserState() === REP_STATUS.OFFLINE.userState)) {
      this.notificationService.notify(this.translate.instant('YOUR_DEVICE_IS_OFFLINE'), 'TimeOffDetails', 'top', undefined, 2000, true);
      return;
    }
    if (await this.showAlert(this.translate.instant('YOU_SURE_WANT_REOPEN_TIME_OFF') + ` "` + this.selectedTot.subject + `"?`,
      this.translate.instant('TIMEOFF_REOPEN_TIME_OFF'), this.translate.instant('CANCEL'), this.translate.instant('TIMEOFF_REOPEN')) && !this.backgroundUploadInProgress) {
      this.timeOffUtilityService.cancelReviewTimeOff(this.selectedTot);
    }
  }

  /**
   *
   *
   * @memberof TimeOffDetailsComponent
   */
  public async approveTimeOff(fromActionBar?: boolean) {
    if (this.backgroundUploadInProgress || this.canApprovalDisabled || this.activityService.teamViewActive) return;
    //only trigger once if its not initiated before TC-482
    if (!this.timeOffService.isProcessing) {
      if (fromActionBar) {
        this.trackingService.tracking('TimeOffApproveActionBar', TrackingEventNames.TIMEOFF)
      }
      else {
        this.trackingService.tracking('TimeOffApprove', TrackingEventNames.TIMEOFF);
      }
      if (!this.canApprovalDisabled) {
        this.timeOffUtilityService.approveTimeOff(this.selectedTot, true);
      }
    }
  }

  /**
   *
   *
   * @memberof TimeOffDetailsComponent
   */
  public async notApproveTimeOff(fromActionBar?: boolean) {
    if (this.backgroundUploadInProgress || this.canApprovalDisabled || this.activityService.teamViewActive) return;
    if (!this.timeOffService.isProcessing) {
      if (fromActionBar) {
        this.trackingService.tracking('TimeOffRejectActionBar', TrackingEventNames.TIMEOFF)
      }
      else {
        this.trackingService.tracking('TimeOffReject', TrackingEventNames.TIMEOFF)
      }
      if (!this.canApprovalDisabled) {
        this.timeOffUtilityService.approveTimeOff(this.selectedTot, false);
      }
    }
  }

  /**
   *
   *
   * @memberof TimeOffDetailsComponent
   */
  getStatusString(): string {
    if (this.selectedTot != null) {
      return this.selectedTot.statusValue;
    }
    return "";
  }

  reOpenTimeOff() {
    if (this.device.isOffline || this.selectedTot.statuscode === TimeOffStatus.Open || this.backgroundUploadInProgress) return;
    console.log("this.selectedTot.subject", this.selectedTot.subject)
    this.showAlert(this.translate.instant('TIMEOFF_REOPEN_CONFIRMATION'),
      this.translate.instant('TIMEOFF_REOPEN_TIME_OFF'), this.translate.instant('CANCEL'), this.translate.instant('TIMEOFF_REOPEN')).then((res) => {
        if (res && !this.backgroundUploadInProgress) {
          if (this.selectedTot.statuscode === TimeOffStatus.InReview) {
            this.timeOffUtilityService.cancelReviewTimeOff(this.selectedTot);
          } else {
            this.timeOffUtilityService.reOpenTimeOff(this.selectedTot, this.activityService.teamViewActive);
          }
          this.shouldCheckConflict = true;
        }
      });
  }

  showAlert(message, title, cancelButtonName, yesButtonName) {
    return new Promise<Boolean>(resolve => {
      this.alertCtrl.create({
        header:title,
        message: message,
        backdropDismiss: false,
        buttons: [
          {
            text: cancelButtonName,
            role: 'cancel',
            handler: () => { resolve(false) }
          },
          {
            text: yesButtonName,
            handler: () => {
              resolve(true);
            }
          }
        ],
      }).then((alert)=> alert.present());
    });
  }

  public getSubjectFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('SUBJECT'),
      inputText: (this.selectedTot && this.selectedTot.name) ? (this.selectedTot.name == 'Time Off' ? this.translate.instant('TIME_OFF') : this.selectedTot.name) : this.translate.instant('TIME_OFF'),
      inputValue: (this.selectedTot && this.selectedTot.name) ? (this.selectedTot.name == 'Time Off' ? this.translate.instant('TIME_OFF') : this.selectedTot.name) : this.translate.instant('TIME_OFF'),
      id: 'subject-field',
      isReadOnly: !this.selectedTot || this.activityService.teamViewActive || this.selectedTot.statuscode !== TimeOffStatus.Open || this.authenticationService.user.systemUserID !== this.selectedTot.totOwnerId || this.isAutoSubjectEnabled,
      isDisabled: this.backgroundUploadInProgress || !this.selectedTot || this.activityService.teamViewActive || this.selectedTot.statuscode !== TimeOffStatus.Open || this.authenticationService.user.systemUserID !== this.selectedTot.totOwnerId || this.isAutoSubjectEnabled,
      placeholderLabel: this.translate.instant('SUBJECT'),
      showArrow: !(!this.selectedTot || this.activityService.teamViewActive || this.selectedTot.statuscode !== TimeOffStatus.Open || this.authenticationService.user.systemUserID != this.selectedTot.totOwnerId || this.isAutoSubjectEnabled),
      formFieldType: FormFieldType.INLINE_INPUT,
      isRequired: !(!this.selectedTot || this.activityService.teamViewActive || this.selectedTot.statuscode !== TimeOffStatus.Open || this.authenticationService.user.systemUserID != this.selectedTot.totOwnerId || this.isAutoSubjectEnabled),
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }

  public getStatusFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      isHidden: this.stautsLabelFlag(),
      label: this.translate.instant('STATUS'),
      inputText: (this.selectedTot && this.selectedTot.statusValue) ? this.gettranslatedstatus(this.selectedTot.statusValue) : this.translate.instant('OPEN'),
      id: 'status-field',
      isReadOnly: true,
      isDisabled: true,
      showArrow: false,
    };
    return viewData;
  }


  public gettranslatedstatus(statusValue): string {
    let timeOffStatus: any;
    switch (statusValue) {
      case 'Open':
        timeOffStatus = this.translate.instant('OPEN');
        break;
      case 'InReview':
        timeOffStatus = this.translate.instant('POP_IN_REVIEV');
        break;
      case 'For Review':
        timeOffStatus = this.translate.instant('FOR_REVIEW');
        break;
      case 'NotApproved':
        timeOffStatus = this.translate.instant('NOT_APPROVED');
        break;
      case 'Approved':
        timeOffStatus = this.translate.instant('APPROVED');
        break;
      default:
        timeOffStatus = statusValue;
        break;
    }
    return timeOffStatus;
  }

  public


  public getCreatedByFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      isHidden: !(this.timeOffService.totMode == 'teamRequests'),
      label: this.translate.instant('CREATED_BY'),
      inputText: (this.selectedTot && this.selectedTot.totOwnerValue) ? this.selectedTot.totOwnerValue : "",
      id: 'created-by-field',
      isReadOnly: true,
      isDisabled: true,
      showArrow: false,
    };
    return viewData;
  }

  public getDayTypeFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    let popupSelectedEvent: string;
    switch (this.timeOffService.popupSelectedEvent) {
      case 'Half Day':
        popupSelectedEvent = this.translate.instant('HALF_DAY');
        break;
      case 'All Day':
        popupSelectedEvent = this.translate.instant('ALL_DAY');
        break;
      case 'Hourly':
        popupSelectedEvent = this.translate.instant('HOURLY');
        break;
      default:
        popupSelectedEvent = this.timeOffService.popupSelectedEvent;
        break;
    }
    viewData = {
      label: this.translate.instant('DURATION_TYPE'),
      inputText: popupSelectedEvent,
      id: 'day-type-field',
      isReadOnly: true,
      isDisabled: !(this.selectedTot != null && !this.activityService.teamViewActive && (this.selectedTot.statuscode === TimeOffStatus.Open && this.authenticationService.user.systemUserID === this.selectedTot.totOwnerId)),
      showArrow: (this.selectedTot != null && !this.activityService.teamViewActive && (this.selectedTot.statuscode === TimeOffStatus.Open && this.authenticationService.user.systemUserID === this.selectedTot.totOwnerId)),
      formFieldType: FormFieldType.POPOVER_SELECT,
      isRequired: (this.selectedTot != null && !this.activityService.teamViewActive && (this.selectedTot.statuscode === TimeOffStatus.Open && this.authenticationService.user.systemUserID === this.selectedTot.totOwnerId)),
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }

  private initDateTimeFormFields() {
    let popupSelectedTimePeriod: string = this.timeOffService.getTimePeriodText(this.timeOffService.popupSelectedTimePeriodEvent);
    let dateTimeValue = (this.selectedTot) ? this.timeOffService.generateTimeDurationText(this.selectedTot) : '';
    if (dateTimeValue) {
      this.startDateTimeValue = dateTimeValue.startDateTime;
      this.startDateValue = dateTimeValue.startDate;
      this.startTimeValue = dateTimeValue.startTime;
      this.endDateTimeValue = dateTimeValue.endDateTime;
      this.endDateValue = dateTimeValue.endDate;
      this.endTimeValue = dateTimeValue.endTime;
      this.duration = dateTimeValue.duration
    }
    this.startDateField = {
      isDateField: true,
      label: this.translate.instant('START_DATE'),
      inputText: this.startDateValue,
      startDateTimeValue: this.startDateTimeValue,
      endDateTimeValue: this.endDateTimeValue,
      fromViewPage: CurViewPageType.TimeOff,
      id: DateTimeFieldType.StartDateField,
      isReadOnly: true,
      isDisabled: !(this.selectedTot != null && !this.activityService.teamViewActive && (this.selectedTot.statuscode === TimeOffStatus.Open && this.authenticationService.user.systemUserID === this.selectedTot.totOwnerId)),
      showArrow: (this.selectedTot != null && !this.activityService.teamViewActive && (this.selectedTot.statuscode === TimeOffStatus.Open && this.authenticationService.user.systemUserID === this.selectedTot.totOwnerId)),
      isRequired: (this.selectedTot != null && !this.activityService.teamViewActive && (this.selectedTot.statuscode === TimeOffStatus.Open && this.authenticationService.user.systemUserID === this.selectedTot.totOwnerId)),
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    this.startTimeField = {
      isTimeField: true,
      label: this.translate.instant('START_TIME'),
      inputText: this.startTimeValue,
      startDateTimeValue: this.startDateTimeValue,
      endDateTimeValue: this.endDateTimeValue,
      fromViewPage: CurViewPageType.TimeOff,
      id: DateTimeFieldType.StartTimeField,
      isReadOnly: true,
      isDisabled: !(this.selectedTot != null && !this.activityService.teamViewActive && (this.selectedTot.statuscode === TimeOffStatus.Open && this.authenticationService.user.systemUserID === this.selectedTot.totOwnerId)),
      showArrow: (this.selectedTot != null && !this.activityService.teamViewActive && (this.selectedTot.statuscode === TimeOffStatus.Open && this.authenticationService.user.systemUserID === this.selectedTot.totOwnerId)),
      isRequired: (this.selectedTot != null && !this.activityService.teamViewActive && (this.selectedTot.statuscode === TimeOffStatus.Open && this.authenticationService.user.systemUserID === this.selectedTot.totOwnerId)),
    };
    this.endDateField = {
      isDateField: true,
      label: this.translate.instant('END_DATE'),
      inputText: this.endDateValue,
      startDateTimeValue: this.startDateTimeValue,
      endDateTimeValue: this.endDateTimeValue,
      fromViewPage: CurViewPageType.TimeOff,
      id: DateTimeFieldType.EndDateField,
      isReadOnly: true,
      isDisabled: !(this.selectedTot != null && !this.activityService.teamViewActive && (this.selectedTot.statuscode === TimeOffStatus.Open && this.authenticationService.user.systemUserID === this.selectedTot.totOwnerId)),
      showArrow: (this.selectedTot != null && !this.activityService.teamViewActive && (this.selectedTot.statuscode === TimeOffStatus.Open && this.authenticationService.user.systemUserID === this.selectedTot.totOwnerId)),
      isRequired: (this.selectedTot != null && !this.activityService.teamViewActive && (this.selectedTot.statuscode === TimeOffStatus.Open && this.authenticationService.user.systemUserID === this.selectedTot.totOwnerId)),
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    this.endTimeField = {
      isTimeField: true,
      label: this.translate.instant('END_TIME'),
      inputText: this.endTimeValue,
      startDateTimeValue: this.startDateTimeValue,
      endDateTimeValue: this.endDateTimeValue,
      fromViewPage: CurViewPageType.TimeOff,
      id: DateTimeFieldType.EndTimeField,
      isReadOnly: true,
      isDisabled: !(this.selectedTot != null && !this.activityService.teamViewActive && (this.selectedTot.statuscode === TimeOffStatus.Open && this.authenticationService.user.systemUserID === this.selectedTot.totOwnerId)),
      showArrow: (this.selectedTot != null && !this.activityService.teamViewActive && (this.selectedTot.statuscode === TimeOffStatus.Open && this.authenticationService.user.systemUserID === this.selectedTot.totOwnerId)),
      isRequired: (this.selectedTot != null && !this.activityService.teamViewActive && (this.selectedTot.statuscode === TimeOffStatus.Open && this.authenticationService.user.systemUserID === this.selectedTot.totOwnerId)),
    };
    this.timePeriodField = {
      isTimePeriodField: true,
      label: this.translate.instant('TIME') + ' ' + this.translate.instant('PERIOD'),
      inputText: popupSelectedTimePeriod,
      fromViewPage: CurViewPageType.TimeOff,
      id: DateTimeFieldType.TimePeriodField,
      isReadOnly: true,
      isDisabled: !(this.selectedTot != null && !this.activityService.teamViewActive && (this.selectedTot.statuscode === TimeOffStatus.Open && this.authenticationService.user.systemUserID === this.selectedTot.totOwnerId)),
      showArrow: (this.selectedTot != null && !this.activityService.teamViewActive && (this.selectedTot.statuscode === TimeOffStatus.Open && this.authenticationService.user.systemUserID === this.selectedTot.totOwnerId)),
      isRequired: (this.selectedTot != null && !this.activityService.teamViewActive && (this.selectedTot.statuscode === TimeOffStatus.Open && this.authenticationService.user.systemUserID === this.selectedTot.totOwnerId)),
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    }
  }

  public getReasonFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('REASON'),
      inputText: (this.selectedTot && this.selectedTot.reason) ? this.localisationService.getTranslatedString(this.selectedTot.reason) : '', //this.timeOffService.reasonSelectedEvent,
      id: 'reason-field',
      isReadOnly: true,
      customPlaceholderLabel: (!(this.selectedTot != null && !this.activityService.teamViewActive && (this.selectedTot.statuscode === TimeOffStatus.Open && this.authenticationService.user.systemUserID === this.selectedTot.totOwnerId))) ? this.translate.instant('NO_REASON') : this.translate.instant('SELECT_REASON'),
      isDisabled: !(this.selectedTot != null && !this.activityService.teamViewActive && (this.selectedTot.statuscode === TimeOffStatus.Open && this.authenticationService.user.systemUserID === this.selectedTot.totOwnerId)),
      showArrow: (this.selectedTot != null && !this.activityService.teamViewActive && (this.selectedTot.statuscode === TimeOffStatus.Open && this.authenticationService.user.systemUserID === this.selectedTot.totOwnerId)),
      formFieldType: FormFieldType.POPOVER_SELECT,
      isRequired: (this.selectedTot != null && !this.activityService.teamViewActive && (this.selectedTot.statuscode === TimeOffStatus.Open && this.authenticationService.user.systemUserID === this.selectedTot.totOwnerId)),
      errorMessage: this.activityService.getErrorMessageRequiredField(),
      isEmptyRequiredField: this.isClickedReason && _.isEmpty(this.selectedTot.reason),
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }

  public getCommentFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('ACCOUNT_COMMENTS'),
      inputText: (this.selectedTot && this.selectedTot.comments)? this.selectedTot.comments : '',
      inputValue: (this.selectedTot && this.selectedTot.comments) ? this.selectedTot.comments : '',
      id: 'comment-field',
      isReadOnly: !this.selectedTot || this.activityService.teamViewActive || this.selectedTot.statuscode !== TimeOffStatus.Open || this.authenticationService.user.systemUserID !== this.selectedTot.totOwnerId,
      isDisabled: this.backgroundUploadInProgress || !this.selectedTot || this.activityService.teamViewActive || this.selectedTot.statuscode !== TimeOffStatus.Open || this.authenticationService.user.systemUserID !== this.selectedTot.totOwnerId,
      placeholderLabel: this.translate.instant('ACCOUNT_COMMENTS'),
      showArrow: !(!this.selectedTot || this.activityService.teamViewActive || this.selectedTot.statuscode !== TimeOffStatus.Open || this.authenticationService.user.systemUserID != this.selectedTot.totOwnerId),
      formFieldType: FormFieldType.INLINE_INPUT,
      isRequired: !(!this.selectedTot || this.activityService.teamViewActive || this.selectedTot.statuscode !== TimeOffStatus.Open || this.authenticationService.user.systemUserID != this.selectedTot.totOwnerId || this.selectedTot.timeOffReason!=548910003),
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }

  public getPositionFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('POSITION'),
      inputText: (this.positionName) ? this.positionName : "",
      id: 'position-field',
      isReadOnly: true,
      isDisabled: true,
      showArrow: false,
    };
    return viewData;
  }

  public getApprovedByFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      isHidden: !(this.displayTeamTotSpecifics),
      label: this.translate.instant('TIMEOFF_APPROVED_BY'),
      inputText: (this.selectedTot && this.selectedTot.approverName) ? this.selectedTot.approverName : "",
      id: 'approve-by-field',
      isReadOnly: true,
      isDisabled: true,
      showArrow: false,
    };
    return viewData;
  }

  public getApprovedOnFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      isHidden: !(this.displayTeamTotSpecifics),
      label: this.translate.instant('TIMEOFF_APPROVED_ON'),
      inputText: (this.approvalDateFormatted) ? this.approvalDateFormatted : "",
      id: 'approve-on-field',
      isReadOnly: true,
      isDisabled: true,
      showArrow: false,
    };
    return viewData;
  }

  public getApproverCommentsFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      isHidden: this.isReasonAutoApproved || this.hasAutoApp || !((this.selectedTot.statuscode == 100000004)
                  || (this.selectedTot.statuscode == 100000002)
                  || (this.selectedTot.statuscode == 100000000 && this.selectedTot.approverComments)),
      label: this.translate.instant('APPROVER_COMMENTS'),
      inputText: (this.selectedTot && this.selectedTot.approverComments) ? this.selectedTot.approverComments : "",
      id: 'approver-comments-field',
      isReadOnly: true,
      isDisabled: true,
      showArrow: false,
    };
    return viewData;
  }

  private handleFormFieldEvent(id, event, eventName) {
    if (id) {
      if (this.backgroundUploadInProgress) return;
      switch (id) {
        case 'subject-field':
          if (eventName && eventName == 'input_value_confirm') {
            this.openSubjectPopover(event);
          }
          break;
        case 'day-type-field':
          this.openEventPopover(event);
          break;
        case 'start-date-field':
          this.openStartDatePicker(event);
          break;
        case 'end-date-field':
          this.openEndDatePicker(event);
          break;
        case 'time-period-field':
          this.openTimePeriodPopover(event);
          break;
        case 'reason-field':
          this.openReasonPopover(event);
          break;
        case 'comment-field':
          if (eventName && eventName == 'input_value_confirm') {
            this.handleCommentsInput(event);
          }
          break;
        default:
          console.log("Unhandled switch case statement");
          break;
      }
    }
  }

  private stautsLabelFlag(): boolean {
    if (this.timeOffService.totMode == 'teamRequests') {
      return false;
    }
    else if (this.timeOffService.totMode == 'myRequests' && !this.hasAutoApp && !this.isReasonAutoApproved) {
      return false;
    } else {
      return true;
    }
  }

  async openStartDatePicker(myEvent) {
    this.activityService.dateTimePickerType = DateTimeFieldType.StartDateField;
    if (this.selectedTot != null && !this.activityService.teamViewActive) {
      if (this.selectedTot.statuscode === TimeOffStatus.Open && this.authenticationService.user.systemUserID === this.selectedTot.totOwnerId) {
        let popover = await this.popoverCtrl.create(
          {
            component: IndDatetimeFormComponent,
            componentProps: {
              currentViewPage: CurViewPageType.TimeOff,
              startDateTimeValue: this.selectedTot.totStartTime,
              endDateTimeValue: this.selectedTot.totEndTime
            },
            cssClass: "datetime-popover"
          }
        );
        popover.onDidDismiss().then((data: any) => {
          if (data !== null && !_.isEmpty(data.data) && data.data.startTime !='') {
            let diff: number = Math.ceil(moment(new Date(data.data.endTime)).diff(moment(new Date(data.data.startTime)), 'seconds'));
            if(diff == 0){
              this.notificationService.notify(this.translate.instant('EQUAL_DATE'), 'TimeOffDetails', 'top', ToastStyle.DANGER);
              return;
            }
            this.shouldCheckConflict = true;
            if (this.selectedTot.totStartTime != data.data.startTime) {
              this.selectedTot.totStartTime = data.data.startTime;
              this.selectedTot.totEndTime = data.data.endTime;
            }
            this.timeOffService.generateTimeDurationText(this.selectedTot);
            this.activityService.selectedActivity['totStartTime'] = new Date(data.data.startTime);
            this.activityService.selectedActivity['totEndTime'] = new Date(data.data.endTime);
            this.timeOffService.setIsTotModified(true);
            this.initDateTimeFormFields();
            this.timeOffUtilityService.updateTimeOff(this.selectedTot, false, false);
            this.changeDetectorRefref.detectChanges();
            this.changeDetectorRefref.markForCheck();
          }
        });
        popover.present();
      }
    }
  }

  async openEndDatePicker(myEvent) {
    this.activityService.dateTimePickerType = DateTimeFieldType.EndDateField;
    if (this.selectedTot != null && !this.activityService.teamViewActive) {
      if (this.selectedTot.statuscode === TimeOffStatus.Open && this.authenticationService.user.systemUserID === this.selectedTot.totOwnerId) {
        let popover = await this.popoverCtrl.create(
          {
            component: IndDatetimeFormComponent,
            componentProps: {
              currentViewPage: CurViewPageType.TimeOff,
              startDateTimeValue: this.selectedTot.totStartTime,
              endDateTimeValue: this.selectedTot.totEndTime
            },
            cssClass: "datetime-popover"
          }
        );
        popover.onDidDismiss().then((data: any) => {
          if (data !== null && !_.isEmpty(data.data) && data.data.startTime !='') {
            let diff: number = Math.ceil(moment(new Date(data.data.endTime)).diff(moment(new Date(data.data.startTime)), 'seconds'));
            if(diff == 0){
              this.notificationService.notify(this.translate.instant('EQUAL_DATE'), 'TimeOffDetails', 'top', ToastStyle.DANGER);
              return;
            }
            this.shouldCheckConflict = true;
            if (this.selectedTot.totEndTime != data.data.endTime) {
              this.selectedTot.totStartTime = data.data.startTime;
              this.selectedTot.totEndTime = data.data.endTime;
            }
            this.timeOffService.generateTimeDurationText(this.selectedTot);
            this.activityService.selectedActivity['totStartTime'] = new Date(data.data.startTime);
            this.activityService.selectedActivity['totEndTime'] = new Date(data.data.endTime);
            this.timeOffService.setIsTotModified(true);
            this.initDateTimeFormFields();
            this.timeOffUtilityService.updateTimeOff(this.selectedTot, false, false);
            this.changeDetectorRefref.detectChanges();
            this.changeDetectorRefref.markForCheck();
          }
        });
        popover.present();
      }
    }
  }
  public footerButtonClicked: (btnId: string) => void = (btnId: string) => {
    if (this.backgroundUploadInProgress) return;
    switch (btnId) {
      case 'savetimeoff':
      case 'sendtimeoff':
        this.submitTimeOff(true);
        break;
      case 'approvetimeoff':
        this.approveTimeOff(true);
        break;
      case 'notapprovedtimeoff':
        this.notApproveTimeOff(true);
        break;
      default:
    }
  };

  checkConflictMsgDisplay() {
    const flag = this.isNewlyCreatedActivity || this.shouldCheckConflict;
    // Due to inconsistent conflict check signal generated across different type of activities,
    // was not able to iron it down to produce a single signal for time update
    // and had to resort to this hack...
    if (this.conflictCheckCounter > 0) {
      this.conflictCheckCounter--;
    }
    if (this.conflictCheckCounter <= 0) {
      this.shouldCheckConflict = false;
      this.conflictCheckCounter = 0;
    }

    const activity = this.activityService.activities.find(a => a.ID === this.activityService.selectedActivity.ID);
    const shouldDisplayMsg = (
      flag
      && activity?.conflictingActivityIds?.size > 0
    ) ? true : false;
    return shouldDisplayMsg;
  }
}
