import { Component, Input } from '@angular/core';
import { format, addMinutes, differenceInMinutes, differenceInDays, subDays, differenceInCalendarDays, isValid, addYears, addDays } from 'date-fns';
import {   PopoverController } from '@ionic/angular';
import { Events } from '@omni/events';
import { MeetingDataService, UpdateMeetingPayload } from '../../data-services/meeting/meeting.data.service';
import { ActivityService } from '../../services/activity/activity.service';
import { AppointmentActivity } from '../../classes/activity/appointment.activity.class';
import { TimeOffActivity } from '../../classes/activity/timeoff.class';
import { TimeOffService } from '../../services/time-off/time-off.service';
import { UIService } from '../../services/ui/ui.service';
import * as moment from 'moment';
import { Activity, ActivityType } from '../../classes/activity/activity.class';
import { DeviceService } from '../../services/device/device.service';
import { AuthenticationService } from '../../services/authentication.service';
import { TrackService, TrackingEventNames } from '../../services/logging/tracking.service';
import { TranslateService } from '@ngx-translate/core';
import {DateTimeFormatsService} from "../../services/date-time-formats/date-time-formats.service";
import { NotificationService, ToastStyle } from '../../services/notification/notification.service';
import { NavigationService, PageName } from '../../services/navigation/navigation.service';
import { PhoneActivity } from '../../classes/activity/phone.activity.class';
import { PhoneCallDataService } from '../../data-services/phone-call/phonecall.data.service';
import { MarketScanService } from '@omni/services/market-scan/market-scan.service';

/**
 * Generated class for the DatetimePickerComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'datetime-picker',
  templateUrl: 'datetime-picker.html',
  styleUrls:['datetime-picker.scss']
})
export class DatetimePickerComponent {

  @Input() startTime: string;
  @Input() endTime: string;
  public prevStartTime: string;
  public prevEndTime: string;
  public duration: string;
  @Input() currentEvent: string = "Other";
  @Input() fromPage: PageName;
  public showLoader: boolean = false;
  public minStartDate: string;
  public maxDate: string|number = new Date().getFullYear() - 0 + 3;
  shortMonth: string[] = ['JAN','FEB','MAR','APR','MAY','JUN','JUL','AUG','SEP','OCT','NOV','DEC'];
  public datepickerTitle : string = this.translate.instant('TIME');

  constructor(
    public activityService: ActivityService,
    private events: Events,
    private timeOffService: TimeOffService,
    public meetingDataService: MeetingDataService,
    private uiService: UIService,
    private device: DeviceService,
    private userService: AuthenticationService,
    public trackingService: TrackService,
    public translate:TranslateService,
    public dateTimeFormatsService: DateTimeFormatsService,
    private notificationService: NotificationService,
    private navService: NavigationService,
    private phoneCallDataService : PhoneCallDataService,
    public popoverCtrl:PopoverController,
    private marketScanService: MarketScanService
  ) {
    if(this.navService.getCurrentMasterPageName() === PageName.TimeOffComponent || this.navService.getCurrentMasterPageName() === PageName.MarketScanPageComponent){
      this.datepickerTitle = this.translate.instant('DATE');
    }
    else{
      this.datepickerTitle = this.translate.instant('TIME');
    }

  }

  ngOnInit() {
    if(this.navService.getCurrentMasterPageName() === PageName.MarketScanPageComponent) {
      const marketScan = this.marketScanService.getSelectedMarketScan();
      this.currentEvent = 'CustomerScan';
      this.maxDate = moment(new Date()).endOf('month').format();
      const startDate: string = marketScan.indskr_date ? marketScan.indskr_date : new Date().getTime().toString();
      this.startTime = format(new Date(parseInt(startDate)))
      const endDate: string = marketScan.indskr_enddate ? marketScan.indskr_enddate : this.marketScanService.getEndDateAsPerConf(new Date(this.startTime)).getTime().toString();
      this.endTime = format(new Date(parseInt(endDate)));
      const start = moment(this.startTime).format('YYYY-MM-DD');
      const end = moment(this.endTime).format('YYYY-MM-DD');
      let days: any = moment(end).diff(moment(start), 'days') + 1;
      this.duration = days + " " + this.translate.instant("DAYS");
    }
    else if(this.startTime ){
      this.startTime = format(new Date(this.startTime));
      this.prevStartTime = format(new Date(this.startTime));
      if(this.endTime){
        this.endTime = format(new Date(this.endTime));
        this.prevEndTime = format(new Date(this.endTime));
      }else{
        this.endTime = format(addMinutes(this.startTime, 30));
        this.prevEndTime = format(addMinutes(this.startTime, 30));
      }
      let dt = new Date();
      dt.setHours(0, 0)
      this.minStartDate = format(subDays(dt, this.userService.user.maxMeetingStartDays));
      this.getFormattedTimeInterval();
    }else if (this.activityService.selectedActivity && this.activityService.selectedActivity.type === ActivityType.Appointment) {
      //So that we can view the unselected common date-time picker on activities view
      //this.timeOffService.popupSelectedEvent = "Other";
      this.currentEvent = "None";
      let dt = new Date();
      dt.setHours(0, 0)
      this.minStartDate = format(subDays(dt, this.userService.user.maxMeetingStartDays));
      this.startTime = format(this.activityService.selectedActivity.scheduledStart);
      this.endTime = format(this.activityService.selectedActivity.scheduledEnd);
      this.prevStartTime = format(this.activityService.selectedActivity.scheduledStart);
      this.prevEndTime = format(this.activityService.selectedActivity.scheduledEnd);
      this.getFormattedTimeInterval();
    }
    else if (this.activityService.selectedActivity && this.activityService.selectedActivity.type === ActivityType.PhoneCall) {
      this.currentEvent = "None";
      let dt = new Date();
      dt.setHours(0, 0)
      this.minStartDate = format(subDays(dt, this.userService.user.maxMeetingStartDays));
      this.startTime = format(this.activityService.selectedActivity.scheduledStart);
      this.endTime = format(this.activityService.selectedActivity.scheduledEnd);
      this.prevStartTime = format(this.activityService.selectedActivity.scheduledStart);
      this.prevEndTime = format(this.activityService.selectedActivity.scheduledEnd);
      this.getFormattedTimeInterval();
    }
    else if (this.activityService.selectedActivity && (this.activityService.selectedActivity.type === ActivityType.TimeOff || this.activityService.selectedActivity.type === ActivityType.TimeOffRequest)) {
      this.currentEvent = this.timeOffService.popupSelectedEvent;
      //conversion to ISO 8601 string for ionic datepicker
      this.startTime = moment(this.activityService.selectedActivity['totStartTime']).format();
      this.endTime = moment(this.activityService.selectedActivity['totEndTime']).format();
      //Setting the default values during initialization
      this.prevStartTime = this.startTime;
      this.prevEndTime = this.endTime;

      this.computeEventBasedDate(this.currentEvent);
    } else if (this.activityService.selectedActivity && this.activityService.selectedActivity.type === ActivityType.Email) {
      this.currentEvent = 'Email';
      this.startTime = format(this.activityService.selectedActivity.scheduledStart);
      this.endTime = format(this.activityService.selectedActivity.scheduledEnd);
      this.prevStartTime = format(this.activityService.selectedActivity.scheduledStart);
      this.prevEndTime = format(this.activityService.selectedActivity.scheduledEnd);
      this.computeEventBasedDate(this.currentEvent);
    } else {
      //So that we can view the unselected common date-time picker on activities view
      this.timeOffService.popupSelectedEvent = "None";
      this.startTime = format(this.getRoundedTime(new Date()));
      this.endTime = format(addMinutes(this.startTime, 30));
      this.getFormattedTimeInterval();
    }
  }

  handleStartDateTime(ev, isEndDate?) {
    let m = moment(this.startTime);
    if (!m.isValid()) {
      this.notificationService.notify(this.translate.instant('NEW_ACTIVITY_TOAST_INVALID_DATE'),'Date Time Picker','top',ToastStyle.DANGER);
    } else {
      const pM = moment(this.prevStartTime);
      const diffDays = differenceInCalendarDays(this.minStartDate, this.startTime);
      if (diffDays > 0 && this.uiService.activeViewNewMeeting !== 'timeoff') {
        if(this.navService.getActiveChildNavViewPageName() == PageName.EventDetailsPageComponent){
          this.notificationService.notify(this.translate.instant('DATETIME_P_CANT_SHEDULE_EVENT_DAYS_AGO', {Eventstartdays : this.userService.user.maxMeetingStartDays}),'New Activity', 'top', ToastStyle.DANGER);
        }else if(this.fromPage == PageName.SurgeryOrderDetailsComponent ){
          this.notificationService.notify(this.translate.instant('DATETIME_P_CANT_SHEDULE_PROCEDURE_LOG_DAYS_AGO', {Eventstartdays : this.userService.user.maxMeetingStartDays}),'New Activity', 'top', ToastStyle.DANGER);
        }else {
          this.notificationService.notify(this.translate.instant('DATETIME_P_CANT_SHEDULE_MEETING_DAYS_AGO', {Meetingstartdays : this.userService.user.maxMeetingStartDays}),'New Activity', 'top', ToastStyle.DANGER);
        }
      } else {
        let days: any = moment(new Date()).diff(moment(new Date(this.startTime)), 'days');
        let pastTimeOffDays: number = this.userService.user.pastTimeOffGracePeriod;
        if(pastTimeOffDays && days > pastTimeOffDays &&
          (this.activityService.selectedActivity.type === ActivityType.TimeOff || this.activityService.selectedActivity.type === ActivityType.TimeOffRequest)) {
          this.notificationService.notify(this.translate.instant('NEW_ACTIVITY_TOAST_CAN_NOT_SCHEDULE_TIMEOFF',{maxTimeOffStartDays:pastTimeOffDays}),'Date Time Picker','top',ToastStyle.DANGER);
          this.startTime = moment(this.activityService.selectedActivity['totStartTime']).format();
          this.endTime = moment(this.activityService.selectedActivity['totEndTime']).format();
          this.computeEventBasedDate(this.currentEvent);
          return;
        }
        if (
          this.activityService.selectedActivity &&
          (this.activityService.selectedActivity.type === ActivityType.TimeOff || this.activityService.selectedActivity.type === ActivityType.TimeOffRequest) &&
          this.currentEvent === "Other"
        ) {
          this.endTime = format(addMinutes(this.startTime, 60));
        } else if(isEndDate){
          if(this.startTime != this.prevStartTime){
            this.endTime = format(addMinutes(this.startTime, 30));
            this.computeEventBasedDate(this.currentEvent);
          }else if(new Date(this.endTime).getTime() > new Date(this.startTime).getTime()) {
            this.getAllDayTimeInterval();
          }else {
            this.startTime = moment(this.activityService.selectedActivity['totStartTime']).format();
            this.endTime = moment(this.activityService.selectedActivity['totEndTime']).format();
            this.computeEventBasedDate(this.currentEvent);
          }
        }
        else {
          this.endTime = format(addMinutes(this.startTime, 30));
        }
        this.prevStartTime = this.startTime;
        this.getFormattedTimeInterval();
        return;
      }
    }

    this.startTime = this.prevStartTime;
    ev.year = new Date(this.prevStartTime).getFullYear();
    ev.day = new Date(this.prevStartTime).getDate();
    ev.month = new Date(this.prevStartTime).getMonth() + 1;
    ev.hour = new Date(this.prevStartTime).getHours();
    ev.minute = new Date(this.prevStartTime).getMinutes();
  }

  updateMarketScanDate(event) {
    const marketScan = this.marketScanService.getSelectedMarketScan();
    if (marketScan.indskr_date && new Date(parseInt(marketScan.indskr_date)).setHours(0, 0, 0, 0) === new Date(this.startTime).setHours(0, 0, 0, 0)) return;
    let m = moment(this.startTime);
    if (!m.isValid()) {
      this.notificationService.notify(this.translate.instant('NEW_ACTIVITY_TOAST_INVALID_DATE'), 'Date Time Picker', 'top', ToastStyle.DANGER);
      this.startTime = this.marketScanService.getSelectedMarketScan().indskr_date;
      return;
    }
    if (new Date(this.maxDate).getTime() < new Date(this.startTime).getTime()) {
      this.startTime = this.marketScanService.getSelectedMarketScan().indskr_date;
      return;
    }
    this.endTime = format(this.marketScanService.getEndDateAsPerConf(new Date(this.startTime)));
    const start = moment(this.startTime).format('YYYY-MM-DD');
    const end = moment(this.endTime).format('YYYY-MM-DD');
    let days: any =moment(end).diff(moment(start), 'days') + 1;
    this.duration = days + " " + this.translate.instant("DAYS");
  }


  handleEndDateTime(ev) {
    let m = moment(this.endTime);
    if (!m.isValid()) {
      this.notificationService.notify(this.translate.instant('NEW_ACTIVITY_TOAST_INVALID_DATE'),'Date Time Picker','top',ToastStyle.DANGER);
    } else {
      let diffDays = differenceInCalendarDays(new Date(), this.startTime);
      if ((diffDays <= this.userService.user.maxMeetingStartDays) || (this.uiService.activeViewNewMeeting == 'timeoff')) {
        if (new Date(this.endTime).getTime() < new Date(this.startTime).getTime()) {
          this.notificationService.notify(this.translate.instant('NEW_ACTIVITY_TOAST_END_TIME_CAN_NOT_BEFORE_START_TIME'),'Date Time Picker','top',ToastStyle.DANGER);
        } else {
          // this.endTime = format(addMinutes(this.startTime, 30));
          this.prevEndTime = this.endTime;
          this.getFormattedTimeInterval();
          return;
        }
      } else {
        if(this.navService.getActiveChildNavViewPageName() == PageName.EventDetailsPageComponent){
          this.notificationService.notify(this.translate.instant('DATETIME_P_CANT_SHEDULE_EVENT_DAYS_AGO', {Eventstartdays : this.userService.user.maxMeetingStartDays}),'New Activity', 'top', ToastStyle.DANGER);
        } else if(this.fromPage == PageName.SurgeryOrderDetailsComponent ){
          this.notificationService.notify(this.translate.instant('DATETIME_P_CANT_SHEDULE_PROCEDURE_LOG_DAYS_AGO', {Eventstartdays : this.userService.user.maxMeetingStartDays}),'New Activity', 'top', ToastStyle.DANGER);
        } else{
          this.notificationService.notify(this.translate.instant('DATETIME_P_CANT_SHEDULE_MEETING_DAYS_AGO', {Meetingstartdays : this.userService.user.maxMeetingStartDays}),'New Activity', 'top', ToastStyle.DANGER);
        }
      }
    }

    this.endTime = this.prevEndTime;
    ev.year = new Date(this.prevEndTime).getFullYear();
    ev.day = new Date(this.prevEndTime).getDate();
    ev.month = new Date(this.prevEndTime).getMonth() + 1;
    ev.hour = new Date(this.prevEndTime).getHours();
    ev.minute = new Date(this.prevEndTime).getMinutes();
  }

  setDuration(): string {
    let diff = differenceInMinutes(this.endTime, this.startTime);

    if (diff > 60) {
      return Math.floor(diff / 60) + ` ${this.translate.instant('HOURS')} ${this.translate.instant('AND')} ` + (diff % 60) + ` ${this.translate.instant('MINUTES')}`;
    } else {
      return diff + ` ${this.translate.instant('MINUTES')}`;
    }
  }

  async onDismiss(isDone: boolean) {
    this.showLoader = true;

    let data: any = {
      'startTime': '',
      'endTime': ''
    };

    let diff: number = Math.ceil(moment(new Date(this.endTime)).diff(moment(new Date(this.startTime)), 'seconds'));

    // end date cannot be less than start  date
    if (isDone && (diff >= 0)) {
      data = {
        'startTime': this.startTime,
        'endTime': this.endTime
      }
      if (this.currentEvent && (this.currentEvent == 'GENERAL_START_AND_END_TIME_SELECTION' || this.currentEvent === 'CustomerScan')){
        this.showLoader = false;
        this.popoverCtrl.dismiss(data);
        return;
      }else if (this.activityService.selectedActivity.type === ActivityType.Appointment) {
        this.trackingService.tracking('MeetingTimeUpdate', TrackingEventNames.ACTIVITY)
        this.activityService.selectedActivity.scheduledStart = new Date(this.startTime);
        this.activityService.getActivityByID(this.activityService.selectedActivity.ID).scheduledStart = new Date(this.startTime);
        this.activityService.selectedActivity.scheduledEnd = new Date(this.endTime);
        this.activityService.getActivityByID(this.activityService.selectedActivity.ID).scheduledEnd = new Date(this.endTime);
        let currentAppointmentActivity = this.activityService.selectedActivity;
        let payload = new UpdateMeetingPayload(
          currentAppointmentActivity.subject,
          currentAppointmentActivity.location,
          currentAppointmentActivity.scheduledStart,
          currentAppointmentActivity.scheduledEnd
        );

        // this.activityService.isActivityUpdate = true;

        await this.activityService.upsertMeetingsOfflineData(this.activityService.selectedActivity as AppointmentActivity);

        if (this.activityService.selectedActivity instanceof AppointmentActivity && (this.device.isOffline || this.activityService.hasOfflineMeetingData(this.activityService.selectedActivity.ID))) {

          this.showLoader = false;
          this.popoverCtrl.dismiss(data).then(() => {
            if (!this.uiService.toolsActivityActive){
              this.events.publish("refreshAgenda");
            } else this.uiService.agendaRefreshRequired = true;
          }); // send the textInput in general when ever requested
        }

        this.meetingDataService.updateMeeting(this.activityService.selectedActivity as AppointmentActivity, payload, false, true)
          .then(() => {
            if (!this.uiService.toolsActivityActive){
              this.events.publish("refreshAgenda");
            } else this.uiService.agendaRefreshRequired = true;
          });
      }
      else if (this.activityService.selectedActivity.type === ActivityType.PhoneCall){
        this.showLoader = false;
        this.trackingService.tracking('PhoneCallUpdateDate', TrackingEventNames.PHONECALL)
        this.activityService.selectedActivity['totStartTime'] = new Date(this.startTime);
        this.activityService.selectedActivity['totEndTime'] = new Date(this.endTime);
        this.activityService.selectedActivity.scheduledStart = new Date(this.startTime);
        this.activityService.getActivityByID(this.activityService.selectedActivity.ID).scheduledStart = new Date(this.startTime);
        this.activityService.selectedActivity.scheduledEnd = new Date(this.endTime);
        this.activityService.getActivityByID(this.activityService.selectedActivity.ID).scheduledEnd = new Date(this.endTime);
        let phoneCallPayLoad = this.phoneCallDataService.setPhoneCallPayload(this.activityService.selectedActivity as PhoneActivity)
        this.uiService.displayLoader();
        if (!this.device.isOffline && !this.activityService.hasOfflinePhoneCallData(this.activityService.selectedActivity.ID)) {
          await this.phoneCallDataService.updatePhoneCall(this.activityService.selectedActivity as PhoneActivity, phoneCallPayLoad);
          this.uiService.dismissLoader();
          // this.activityService.isActivityUpdate = true;
        }
        else {
            await this.activityService.upsertPhoneCallOfflineData(this.activityService.selectedActivity as PhoneActivity);
            this.uiService.dismissLoader();
        }
        this.activityService.selectedActivity.scheduledStartLocale = this.activityService.selectedActivity.scheduledStart.toLocaleTimeString('en-US', { hour12: true, hour: '2-digit', minute: '2-digit' });
        if (!this.uiService.toolsActivityActive){
          this.events.publish("refreshAgenda");
        } else this.uiService.agendaRefreshRequired = true;
      }
      else if ((this.activityService.selectedActivity.type === ActivityType.TimeOff || this.activityService.selectedActivity.type === ActivityType.TimeOffRequest)) {
        this.trackingService.tracking('TimeOffUpdateDate', TrackingEventNames.TIMEOFF)
        this.activityService.selectedActivity['totStartTime'] = new Date(this.startTime);
        this.activityService.selectedActivity['totEndTime'] = new Date(this.endTime);
      }
    }
    else {
      if (diff < 0) {
        this.notificationService.notify(this.translate.instant('INVALID_DURATION'),'Date Time Picker','top',ToastStyle.DANGER);
      }
      if ( this.currentEvent && (this.currentEvent == 'GENERAL_START_AND_END_TIME_SELECTION' || this.currentEvent == 'Email')) {
        this.showLoader = false;
        if(!isDone) {
          this.popoverCtrl.dismiss(null);
        }
        return;
      }
      let activity: Activity = this.activityService.selectedActivity;
      if (activity instanceof AppointmentActivity || activity instanceof PhoneActivity) {
        data = {
          'startTime': this.activityService.selectedActivity.scheduledStart,
          'endTime': this.activityService.selectedActivity.scheduledEnd
        };
      }
      else if (activity instanceof TimeOffActivity) {
        if (isDone) {
          data = {
            'startTime': activity["totStartTime"],
            'endTime': activity["totEndTime"]
          };
        } else{
          data = null;
        }
      }
      // else if (activity instanceof PhoneActivity) {
      //   data = {
      //     'startTime': this.activityService.selectedActivity.scheduledStart,
      //     'endTime': this.activityService.selectedActivity.scheduledEnd
      //   };
      // }
    }

    this.showLoader = false;

    this.popoverCtrl.dismiss(data);
  }

  getRoundedTime(mDate) {
    let d = mDate;
    if (d.getMinutes() < 30) {
      d.setMinutes(30);
      return d;
    } else {
      d.setMinutes(0);
      d.setHours(d.getHours() + 1);
      return d;
    }
  }

  getFormattedTimeInterval(): void {
    let days: any = differenceInDays(this.endTime, this.startTime);
    let timeDifference: Date = new Date((new Date(this.endTime).valueOf() - new Date(this.startTime).valueOf()));
    console.log("UTC Time: ", new Date((new Date(this.endTime).valueOf() - new Date(this.startTime).valueOf())).toUTCString()); //toGMTString will not be supported soon for old browers we might have to
    let minutes: any = timeDifference.getUTCMinutes();
    let hours: any = timeDifference.getUTCHours();
    if (days === 0) {
      days = "";
    } else if (days <= 1) {
      days = days + " "+this.translate.instant('DAY')+" ,";
    }
    else {
      days = days + " "+this.translate.instant('DAYS')+" ,";
    }
    if (hours === 0) {
      hours = "";
    } else if (hours <= 1) {
      hours = hours + " "+this.translate.instant('HOUR')+" ,";
    }
    else {
      hours = hours + " "+this.translate.instant('HOURS')+" ,";
    }
    this.duration = days + "" + hours + "" + minutes + " "+this.translate.instant('MINUTES');
  }

  //end time is 4 hrs from start time
  handleStartHalfDayDateTime() {
    let days: any = moment(new Date()).diff(moment(new Date(this.startTime)), 'days');
    let pastTimeOffDays: number = this.userService.user.pastTimeOffGracePeriod;
    if(pastTimeOffDays && days > pastTimeOffDays) {
      this.notificationService.notify(this.translate.instant('NEW_ACTIVITY_TOAST_CAN_NOT_SCHEDULE_TIMEOFF',{maxTimeOffStartDays:pastTimeOffDays}),'Date Time Picker','top',ToastStyle.DANGER);
      this.startTime = moment(this.activityService.selectedActivity['totStartTime']).format();
      this.endTime = moment(this.activityService.selectedActivity['totEndTime']).format();
      this.computeEventBasedDate(this.currentEvent);
    } else {
      let start = moment(new Date(this.startTime));
      let meridian: string = start.format('A');
      this.duration = "0.5"+" "+this.translate.instant('DAYS')

      if (meridian === "AM") {
        this.startTime = start.set('hour', 0).set('minute', 0).set('second', 0).format();
        this.endTime = start.set('hour', 11).set('minute', 59).set('second', 59).format();
      }
      else if (meridian === "PM") {
        this.startTime = start.set('hour', 12).set('minute', 0).set('second', 0).format();
        this.endTime = start.set('hour', 23).set('minute', 59).set('second', 59).format();
      }
    }
  }


  /* Customized logic to return only the days between the  */
  getAllDayTimeInterval() {
    let days: any = moment(new Date(this.endTime)).diff(moment(new Date(this.startTime)), 'days') + 1;
    if (days === 0 || days === 1) {
      days = "1"+this.translate.instant('DAY');
      this.duration = days;
    }
    else if (days > 1) {
      days = days + " "+ this.translate.instant('DAYS');
      this.duration = days;
    }
    else if (days < 0) {
      days = this.translate.instant('INVALID_DATE_RANGE');
      this.duration = days;
    }
  }

  computeEventBasedDate(currentEvent: string) {
    if (currentEvent == 'All Day') {
      this.getAllDayTimeInterval();
    }
    else if (currentEvent == 'Half Day') {
      this.duration = "0.5"+this.translate.instant('DAY')
    }
    else if (currentEvent != 'Half Day' && currentEvent != 'All Day') {
      this.getFormattedTimeInterval();
    }
  }

  updateAllDayChange(isEndDate: boolean, event) {
    if (event.currentTarget && event.currentTarget.classList && event.currentTarget.classList.contains("ng-untouched")) return;
    if(isEndDate) {
      //End time is updated and now check if its valid, else revert to old state
      if(new Date(this.endTime).getTime() > new Date(this.startTime).getTime()) {
        this.startTime = moment(new Date(this.startTime).setHours(0, 0, 0 )).format();
        this.endTime = moment(new Date(this.endTime).setHours(23, 59, 59 )).format();
        this.getAllDayTimeInterval();
      }
      else {
        this.notificationService.notify(this.translate.instant('NEW_ACTIVITY_TOAST_END_TIME_CAN_NOT_BEFORE_START_TIME'),'Date Time Picker','top',ToastStyle.DANGER);
        this.startTime = moment(this.activityService.selectedActivity['totStartTime']).format();
        this.endTime = moment(this.activityService.selectedActivity['totEndTime']).format();
        this.computeEventBasedDate(this.currentEvent);
      }
    }
    else {
      let days: any = moment(new Date()).diff(moment(new Date(this.startTime)), 'days');
      let pastTimeOffDays: number = this.userService.user.pastTimeOffGracePeriod;
      if(pastTimeOffDays && days > pastTimeOffDays) {
        this.notificationService.notify(this.translate.instant('NEW_ACTIVITY_TOAST_CAN_NOT_SCHEDULE_TIMEOFF',{maxTimeOffStartDays:pastTimeOffDays}),'Date Time Picker','top',ToastStyle.DANGER);
        this.startTime = moment(this.activityService.selectedActivity['totStartTime']).format();
        this.endTime = moment(this.activityService.selectedActivity['totEndTime']).format();
        this.computeEventBasedDate(this.currentEvent);
      } else {
        this.startTime = moment(new Date(this.startTime).setHours(0, 0, 0 )).format();
        this.endTime = moment(new Date(this.startTime).setHours(23, 59, 59 )).format();
        this.getAllDayTimeInterval();
      }
    }
  }

}
