import { Activity, ActivityType } from './activity.class';
import { Brand } from '../brand/brand.class';
import { TherapeuticArea } from '../therapeutic-area/therapeutic-area.class';
import { AccompainedUser } from './accompained-user.class';
import { SharedResourceStatus, Resource } from '../resource/resource.class';
import { ContactAttendees } from './appointment.activity.class';
import { isValid } from 'date-fns';
import { Contact } from '../contact/contact.class';
import { ActivityPresentation } from '../presentation/activity-presentation.class';
import { ActivityResource } from '../resource/activity-resource.class';
import { Presentation } from '../presentation/presentation.class';
import { Utility } from '../../utility/util';
import { Account } from '../account/account.class';
import { UpdateTypeAndSubTypeActivityPayLoad } from '@omni/data-services/meeting/meeting.data.service';
import { ConfiguredFields } from '../authentication/configured.field.class';
import _ from 'lodash';


export class PhoneActivity extends Activity {


  public actualDurationMinutes: number;
  public actualStart: string;
  public actualEnd: string;
  public location: string;
  public meetingURL: string;
  public notes: string;
  public accountId: string;
  public createdOffline: boolean;
  public contacts: Array<Contact>;
  public accounts: Array<any>;
  public products: Array<Brand>;
  public activityProducts: Array<Brand>
  public activityPresentations: Array<ActivityPresentation>
  public activityResources: Array<ActivityResource>
  public activityTherapeuticAreas: Array<TherapeuticArea>
  public presentations: (Presentation | Resource)[];
  public presPlayList: (Presentation | Resource)[];
  public subtype: number;
  public selectedMobileNumber: string;
  public appconfiglookupfields: UpdateTypeAndSubTypeActivityPayLoad[] = [];
  public appConfigFields: ConfiguredFields[] = [];
  public raw_contactAttendees: Array<ContactAttendees> = [];

  public accountPlanId : string;

  public indskr_totreason: number;
  public indskr_totreasonFormattedValue: string;

  private _raw_activityAccounts: Array<any>;
  private _raw_activityPlaylists: Array<any>;
  private _raw_activityPresentations: Array<any>;
  private _raw_activityProducts: Array<any>;
  private _raw_activitySharedResources: Array<any>;
  private _raw_activitySurveys: Array<any>;
  private _raw_contactAttendees: Array<ContactAttendees>;

  private _raw_indskr_accesstoken: string;
  private _raw_indskr_positionid: string;
  private _raw_isalldayevent: number;
  private _raw_meetingTopicName: string;

  public offlineMeetingId: string;
  public offlineId: string;

  private readonly DEFAULT_LOCATION_MESSAGE = 'No Location';
  public sharedResources: SharedResourceStatus[] = [];
  public get isLiveMeet() {
    return this.location === 'LiveMeet';
  }
  public isJointmeeting: boolean;
  public isMeetingPresentationDetailed: boolean = false;
  public positionId: string;
  public isOfflineMeeting: boolean;
  public senton: string;
  public readOn: string;
  public indskr_dnascheduler: boolean;
  public indskr_meetingtype: number;
  public lastUpdatedTime: number;
  public jointphonecall: boolean;
  public accompaniedUserList: AccompainedUser[] = [];
  public accompaniedUserString: string;
  public indskr_datecompleted: string;
  public indskr_actualdurationminutesandseconds: string;

  /* array of id's of case intakes for a particular meeting */
  public customerInquiries: any[] = [];

  constructor(raw: Object) {
    super(raw);
    if (!this.ID && raw.hasOwnProperty('offlineMeetingId')) {
      this.ID = raw['offlineMeetingId'];
      this._raw_activityAccounts = raw['activityAccounts'] || undefined;
    }
    this._raw_contactAttendees = raw['contactAttendees'] || undefined;
    this.raw_contactAttendees = raw['contactAttendees'] || undefined;

    if (raw['subject']) {
      this.subject = raw['subject'];
    }
    this.contacts = [];
    this.accounts = [];
    this.lastUpdatedTime = raw['lastUpdatedTime'];
    this.offlineId = raw['offlineId'] || raw['indskr_externalid'];
    this.activityTherapeuticAreas = [];
    if (raw['activityTherapeuticAreas'] && Array.isArray(raw['activityTherapeuticAreas'])) {
      raw['activityTherapeuticAreas'].forEach(ta => {
        let newTa = new TherapeuticArea(ta);
        newTa.isSelected = true;
        this.activityTherapeuticAreas.push(newTa);
      });
    }

    if (raw['phonenumber']) {
      this.selectedMobileNumber = raw['phonenumber'];
    }
    if (raw['mobileNumber']) {
      this.selectedMobileNumber = raw['mobileNumber'];
    }

    this.activityProducts = raw['activityProducts'] || [];

    if(raw['accountPlanId']){
      this.accountPlanId = raw['accountPlanId'];
    }

    // this.createdOffline = (raw['createdOffline'] ? raw['createdOffline'] : false);
    this.isOfflineMeeting = !!raw['indskr_isofflinemeeting'];
    if (raw['ownerid']) {
      this.meetingOwnerName = raw['ownerid'].Name;
      this.meetingOwnerId = raw['ownerid'].Id;
    } else if (raw['indskr_ownerfullname']) {
      this.meetingOwnerName = (raw['indskr_ownerfullname']) ? raw['indskr_ownerfullname'] : this.meetingOwnerName;
      this.meetingOwnerId = (raw['indskr_ownerid']) ? raw['indskr_ownerid'] : this.meetingOwnerId;
    }
    if (!this.meetingOwnerId) {
      this.meetingOwnerId = raw['indskr_ownerid'];
    }
    this.actualDurationMinutes = 0;
    this.indskr_actualdurationminutesandseconds = raw['indskr_actualdurationminutesandseconds'] || "0";
    this.actualStart = raw['actualstart'];
    this.actualEnd = raw['actualend'];
    this.location = raw['location'] ? raw['location'] : raw['app_location'];
    this.meetingURL = raw['indskr_meetingurl'];
    this.notes = raw['indskr_notes'];
    this.subtype == raw['indskr_subtype'];
    if (!this.location) this.location = this.DEFAULT_LOCATION_MESSAGE;
    this.indskr_totreason = raw['indskr_totreason'] || undefined;
    this.indskr_totreasonFormattedValue = raw['indskr_totreasonFormattedValue'] || '';
    this.accompaniedUserList = this.fetchUserList(raw);
    this.accompaniedUserString = this.fetchUserString(raw);

    this.offlineMeetingId = raw['offlineMeetingId'] ? raw['offlineMeetingId'] : undefined;
    this._raw_activitySharedResources = raw['activitySharedResources'] ? raw['activitySharedResources'] : undefined;
    // this.sharedResources = _.isEmpty(this._raw_activitySharedResources) ? [] : this._raw_activitySharedResources;
    this.positionId = raw['indskr_positionid'] ? raw['indskr_positionid'] : undefined;
    this.senton = raw['senton'];
    this.readOn = raw['readOn'];
    this.indskr_activitytype = raw['indskr_activitytype'];
    this.indskr_activitysubtype = raw['indskr_activitysubtype'];
    this.activityTypeName = raw['activityTypeName'] ? raw['activityTypeName'] : "";
    this.activitySubTypeName = raw['activitySubTypeName'] ? raw['activitySubTypeName'] : "";
    if(raw['indskr_datecompleted']) this.indskr_datecompleted = this.omnip_datecompleted = raw['indskr_datecompleted'];
    if (raw.hasOwnProperty('configuredFields')) {
      const rawConfigFields = raw['configuredFields'];
      this.appConfigFields = rawConfigFields;
    }

    if (raw['activitytypecode']) {
      this.type = ActivityType.PhoneCall;
    }
    if (raw['statecode']) {
      this.state = raw['statecode'];
    }

    this.indskr_dnascheduler = raw['indskr_dnascheduler'];
    this.jointphonecall = raw['jointphonecall'];
  }

  public fetchUserList(data: any): AccompainedUser[] {
    let raw: any[];

    if (data["accompaniedUsers"] && Array.isArray(data["accompaniedUsers"])) {
      raw = data["accompaniedUsers"];
    }
    //Changes due to bulk data changes in TC-294, key changes handled at class level
    else if (data["activityAccompaniedUsers"] && Array.isArray(data["activityAccompaniedUsers"])) {
      raw = data["activityAccompaniedUsers"];
    }

    let userList: AccompainedUser[] = [];
    if (raw != undefined) {
      raw.map(e => userList.push(new AccompainedUser(e)));
    }
    return userList;
  }

  /**
    * Returns a DTO version of our associated accounts
    *
    * @readonly
    * @type {Array<Object>}
    * @memberof AppointmentActivity
    */
  get accountsDTO(): Array<Object> {
    let accountsArray = [];

    this.accounts.map(account => {
      accountsArray.push({
        indskr_accountid: account.id || account.accountId
      });
    });
    return accountsArray;
  }

  /**
    * Returns a DTO version of our associated products
    *
    * @readonly
    * @type {Array<Object>}
    * @memberof AppointmentActivity
    */
  get productsDTO(): Array<Object> {

    if (this.products == undefined) return;

    let productsArray = [];
    //reset the products array
    let _priority = 1;
    this.products.map((product, index) => {
      if (!product.isSelected && !product.isGenieSelected) return;
      let activityProductKeyMessages = [];
      if (product.isSelected || product.isGenieSelected) {
        product.priority = _priority
        if (product.keyMessages) {
          product.keyMessages.map((keyMsg, index) => {

            if (!keyMsg.isSelected && !keyMsg.isGenieSelected) return;
            activityProductKeyMessages.push({
              indskr_keymessageid: keyMsg.ID,
              indskr_name: keyMsg.name,
              indskr_isautomaticallyselected: keyMsg.isAutoSelected,
              indskr_geneeselected: keyMsg.isGenieSelected
            });
          });
        }
        productsArray.push({
          indskr_productid: product.ID,
          indskr_isautomaticallyselected: product.isAutoSelected,
          indskr_geneeselected: product.isGenieSelected,
          indskr_sequence: _priority,
          indskr_name: product.name,
          activityProductKeyMessages: activityProductKeyMessages
        });
        _priority++;
      }

    });
    //console.log("Whats been sent to back end=> " , productsArray);
    return productsArray;
  }

  get activityTherapeuticAreaDTO(): Array<Object> {
    let activityTherapeuticAreas = [];
    if (!this.activityTherapeuticAreas) return;
    activityTherapeuticAreas = this.activityTherapeuticAreas.map(ta => ta.TherapeuticAreaMeetingDTO);
    return activityTherapeuticAreas;
  }

  /**
     * Returns a string depending on the current contacts associated with the appointment
     *
     * @readonly
     * @type {string}
     * @memberof AppointmentActivity
     */
  get contactString(): string {
    let contactString: string = `${this.isLiveMeet ? 'Participants' : Utility.globalCustomersText}`;

    //find first alphabetical account
    this.contacts.sort((contactA, contactB): number => {
      let contactAFullName = (contactA.firstName.length > 0) ? contactA.firstName + " " + contactA.lastName : contactA.lastName;
      let contactBFullName = (contactB.firstName.length > 0) ? contactB.firstName + " " + contactB.lastName : contactB.lastName;
      if (contactAFullName.toLowerCase() > contactBFullName.toLowerCase()) return 1;
      if (contactAFullName.toLowerCase() < contactBFullName.toLowerCase()) return -1;
      return 0;
    });

    //Only one contact, return his name
    if (this.contacts.length === 1) {
      contactString = `${this.contacts[0].fullname}`;
      if (!this.contacts[0].isActive && String(this.contacts[0].fullName).trim() != '') {
        contactString += ' (Inactive)';
      }
    } else if (this.contacts.length >= 2) {
      contactString = `${this.contacts[0].fullName}`;
      if (!this.contacts[0].isActive) {
        contactString += ` (Inactive) +${this.contacts.length - 1}`;
      } else if (this.contacts.some((con, index) => {
        if (index == 0) return false;
        return (!con.isActive);
      })) {
        contactString = `${this.contacts[0].fullName} +${this.contacts.length - 1} (Inactive)`;
      } else {
        contactString = `${this.contacts[0].fullName} +${this.contacts.length - 1}`;
      }

      // if(this.contacts[this.contacts.length - 1] === undefined){
      //     return;
      // }else{
      //     return `${this.contacts[this.contacts.length - 1].fullName} +${this.contacts.length-1}`;
      // }
    }

    if (!contactString || contactString == undefined || contactString == 'undefined ') {
      console.log(this.contacts);
      return "";
    }
    else
      return contactString;
  }

  /**
   * Returns a string depending on the current accounts associated with the appointment
   *
   * @readonly
   * @type {string}
   * @memberof PhoneActivity
   */
  get accountString(): string {
    //Only one contact, return his name
    if (this.accounts.length === 1) {
      let accountString: string = `${this.accounts[0].accountName}`;
      if (this.accounts[0].status === 2) {
        return accountString += ' (Inactive)';
      }
      return accountString;
    } else if (this.accounts.length >= 2) {
      //find first alphabetical account
      this.accounts.sort((accountA, accountB): number => {
        if (accountA.accountName > accountB.accountName) return 1;
        if (accountA.accountName < accountB.accountName) return -1;
        return 0;
      });

      let accountString = `${this.accounts[0].accountName}`;
      if (this.accounts[0].status === 2) {
        accountString += ` (Inactive) +${this.accounts.length - 1}`;
      } else if (this.accounts.some((acc, index) => {
        if (index == 0) return false;
        return (acc.status === 2);
      })) {
        accountString = `${this.accounts[0].accountName} +${this.accounts.length - 1} (Inactive)`;
      } else {
        accountString = `${this.accounts[0].accountName} +${this.accounts.length - 1}`;
      }
      return accountString;
    }
    return 'Select Accounts';
  }

  /**
     * Returns an AppointmentActivity DTO for updating details
     *
     * @readonly
     * @type {Object}
     * @memberof AppointmentActivity
     */
  public get DTO(): Object {
    const dto = {
      activityAccounts: this.accountsDTO ? this.accountsDTO : [],
      activityPresentations: undefined,
      activityResources: undefined,
      activityTherapeuticAreas: this.activityTherapeuticAreaDTO ? this.activityTherapeuticAreaDTO : [],
      activityPlaylists: undefined,
      activityProducts: this.productsDTO ? this.productsDTO : [],
      activitytypecode: 'phonecall',
      contactAttendees: this.contacts ? this.contacts : [],
      indskr_notes: this.notes ? this.notes : "",
      activityId: this.ID,
      indskr_ownerfullname: this.meetingOwnerName || this.meetingOwner,
      indskr_ownerid: this.meetingOwnerId,
      indskr_jointmeeting: this.isJointmeeting,
      //Dont even ask.
      activityid: this.ID,
      accountPlanId : this.accountPlanId,
      createdOffline: this.createdOffline,
      indskr_isofflinemeeting: !!this.isOfflineMeeting,
      actualend: '' + this.scheduledEnd.getTime(),
      scheduledend: '' + this.scheduledEnd.getTime(),
      scheduledstart: '' + this.scheduledStart.getTime(),
      actualstart: '' + this.scheduledStart.getTime(),
      indskr_actualdurationminutesandseconds: this.indskr_actualdurationminutesandseconds,
      indskr_iomeetingtype: 100000000,
      indskr_subtype: 100000001,
      indskr_type: 100000000,
      location: this.location,
      offlineMeetingId: (this.offlineMeetingId ? this.offlineMeetingId : undefined),
      stateCode: this.state,
      statuscode: this.status,
      offlineId: this.offlineId,
      subject: this.subject,
      mobileNumber: this.selectedMobileNumber ? this.selectedMobileNumber : "",
      indskr_meetingurl: this.meetingURL,
      indskr_meetingtype: this.indskr_meetingtype,
      indskr_activitytype: this.indskr_activitytype,
      indskr_activitysubtype: this.indskr_activitysubtype,
      activityTypeName: this.activityTypeName,
      activitySubTypeName: this.activitySubTypeName,
      appconfiglookupfields: this.getAppConfigLookupFields(this.appconfiglookupfields),
      // appconfigfields: this.getAppConfigFields(this.appConfigFields),
      // TODO: Hemang, check for the offline push by uncommenting the above line.
      appconfigfields: this.appConfigFields,
      activitySharedResources: this.sharedResources.reduce(
        (list, { resource, contact, statuses }) => list.concat(...statuses.map(s => ({
          indskr_contactid: contact.ID,
          indskr_resultstatus: s.status - 1,
          indskr_resulttime: `${s.date.getTime()}`,
          indskr_ioresourceid: resource.ioResourceId,
          indskr_iodocumentid: resource.ioDocumentId,
        }))), []),
        omnip_datecompleted: this.omnip_datecompleted,
        indskr_datecompleted: this.indskr_datecompleted,
      //Added for JointPhoneCall
      activityAccompaniedUsers: this.getAccompaniedUser(this.accompaniedUserList),
      jointphonecall: this.jointphonecall
    };
    if (this.positionId || this._raw_indskr_positionid) {
      dto['indskr_positionid'] = this.positionId || this._raw_indskr_positionid;
    }
    return dto;
  }

  getAccompaniedUser(raw: AccompainedUser[]): any[] {
    let res: any[] = [];
    raw.map(e => {
      res.push(
        {
          "indskr_user": e.id,
          'userfullname': e.name,
        }
      )
    });
    return res;
  }

  getAppConfigLookupFields(raw: UpdateTypeAndSubTypeActivityPayLoad[]): any[] {
    let res: any[] = [];
    raw.map(e => {
      res.push(
        {
          "name": e.name,
          'pluralEntityName': e.pluralEntityName,
          'entity': e.entity,
          'id': e.id,
        }
      )
    });
    return res;
  }

  getAppConfigFields(raw: ConfiguredFields[]): any[] {
    let res: any[] = [];
    raw.map(e => {
      if (e.datatype) {
        res.push(
          {
            "fieldname": e.fieldName,
            'datatype': e.datatype,
            'value': e.value,
          })
      } else {
        console.error('Datatype is missing for the ConfiguredField:', e.fieldName);
      }
    });
    return res;
  }

  public fetchUserString(data: any): string {

    let raw: any[];

    if (data["accompaniedUsers"] && Array.isArray(data["accompaniedUsers"])) {
      raw = data["accompaniedUsers"];
    }
    else if (data["activityAccompaniedUsers"] && Array.isArray(data["activityAccompaniedUsers"])) {
      raw = data["activityAccompaniedUsers"];
    }
    let userString: string = "";
    if (raw != undefined) {
      const names = raw.map(e => e.userfullname).sort(function (a, b) {
        let nameA = a.toLowerCase(), nameB = b.toLowerCase();
        if (nameA < nameB) //sort string ascending
          return -1;
        if (nameA > nameB)
          return 1;
        return 0; //default return value (no sorting)
      });;
      userString = names[0] + (names.length == 1 ? '' : (' +' + (names.length - 1)));
    }
    return userString;
  }



  update(response: object, contacts: Array<Contact>, accounts: Array<Account>, products: Array<Brand>,
    therapeuticAreas: Array<any>) {

    this.notes = response['indskr_notes'] || '';

    this.contacts = contacts;
    this.accounts = accounts;
    this.products = products;
    this.activityProducts = products;
    this.activityTherapeuticAreas = therapeuticAreas;
    //Update basic fields
    this.location = response['location'];
    this.subject = response['subject'];
    this.actualEnd = response['actualend'];
    this.actualStart = response['actualstart'];
    this.completed = response['indskr_datecompleted'];
    this.indskr_datecompleted = this.omnip_datecompleted = response['indskr_datecompleted'];
    this.subtype == response['indskr_subtype'];
    this._raw_indskr_accesstoken = response['indskr_accesstoken'];
    this._raw_indskr_positionid = response['indskr_positionid'];
    this.indskr_subtype = response['indskr_subtype'];
    this.indskr_type = response['indskr_type'];
    this._raw_isalldayevent = response['isalldayevent'];
    this._raw_meetingTopicName = response['meetingTopicName'];
    this.jointphonecall = response['jointphonecall'];
    this.accompaniedUserList = this.fetchUserList(response);
    this.accompaniedUserString = this.fetchUserString(response);
    this.indskr_activitytype = response['indskr_activitytype'];
    this.indskr_activitysubtype = response['indskr_activitysubtype'];
    this.activityTypeName = response['activityTypeName'];
    this.activitySubTypeName= response['activitySubTypeName'];
  }

}
export const OFFLINE_PHONE_CALL_ID_PREFIX = 'offline_phonecall_';
