import { AuthenticationService } from './../authentication.service';
import { AuthenticationDataService } from '@omni/data-services/authentication/authentication.service';
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { SpeechRecognition, SpeechRecognitionListeningOptions } from '@ionic-native/speech-recognition/ngx';

import { DirectLine, User } from 'botframework-directlinejs';
import { DeviceService } from '../device/device.service';

import { SpeechConfig, AudioConfig, SpeechRecognizer } from 'microsoft-cognitiveservices-speech-sdk';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { Subject } from 'rxjs';

export enum CreateMeetingType {
  polls, customersNotSurveyed, other
}
export class ConversationResult {
  conversationId: string;
  token: string;
  expires_in: number;
  streamUrl: string;
}
export class SendResponse {
  id: string
}
declare var cordova;

@Injectable({
  providedIn: 'root'
})
export class MyAssistantDataService {

  displayGeneeBotMessages: {
    id?: string,
    from: string,
    text: string,
    hasAttachments?: boolean,
    contentType?: string,
    timestamp?: string,
    localTimestamp?: string
    cards: {
      type: string,
      title: string,
      value: string
    }[],
    feedback?: string,
    isFocusable?: boolean;
  }[];

  geneeBotMessages: {
    id?: string,
    from: string,
    text: string,
    hasAttachments?: boolean,
    contentType?: string,
    timestamp?: string,
    localTimestamp?: string
    cards: {
      type: string,
      title: string,
      value: string
    }[],
    feedback?: string,
    isFocusable?: boolean;
  }[];
  directLine: DirectLine;
  selectedVoice: any;
  ttsSetup: { speechSynthesis, SpeechSynthesisUtterance };
  iosSpeechListener: any;
  isSpeakerMuted = false;
  private sessionCount: number = 0;
  private threshholdSessions = 5;
  private savedHistoryRange: Map<number, number> = new Map<number, number>();
  public renderedCardMap: Map<string, HTMLElement> = new Map<string, HTMLElement>();
  recognizer: any;
  iosSpeech: any
  inputSpeechSubject = new Subject<any>();
  isGeneeRelaunched: boolean = false;
  isGeneeInitialized: boolean = false;
  suggestedActions = [];
  suggestionsToRemember: any[] = [];
  showTypingLoader = false;
  existingConversationId = '';
  createMeetingType = CreateMeetingType.other;
  selectedCustomerIdsForMeeting: any[];
  selectedPollsIdsForMeeting: any[];
  contactPresentationCombination: any[] = [];

  public userName: string = this.authService.user.displayName;
  public userId: string = this.authService.user.systemUserID;
  public objValue:any;
  public objUser: User = {
    id: this.userId,
    name: this.authService.userConfig.activeInstance.friendlyName + '|' + this.userName
  }

  constructor(private androidPermission: AndroidPermissions,
    public http: HttpClient,
    private speech: SpeechRecognition,
    private device: DeviceService,
    private authService: AuthenticationService,
    private authDataService: AuthenticationDataService) {

    this.authDataService.getToken().then((token)=>{
      this.objValue = {
        userName: this.userName,
        userId: this.userId,
        isUserManager: this.authService.user.isManager,
        accessToken: token,
        geneeVersion: this.authService.userConfig.activeInstance.geneeVersion
      };
    });

    this.requestPermission();
    this.geneeBotMessages = [];
    this.populate();
  }

  // Call this function every time you push into the geneeBotMessages array.
  populate() {
    this.displayGeneeBotMessages = [];
    if (this.geneeBotMessages.length) {
      this.displayGeneeBotMessages = [...this.geneeBotMessages];
      this.displayGeneeBotMessages.reverse();
    }
  }

  showInitialSetOfOptions() {
    // console.warn(`showInitialSetOfOptions`);
    //@ts-ignore
    return this.directLine.postActivity({
      from: this.objUser,
      type: 'event',
      //@ts-ignore
      name: 'requestSuggestions',
      value: this.objValue
    });
  }

  initializeGeneeBotForKPI() {
    // console.warn(`initializeGeneeBotForKPI`);

    if (!this.directLine) {
      this.directLine = new DirectLine({
        token: this.authService.userConfig.activeInstance.geneeSecret,
        webSocket: true
      });
    }
    //@ts-ignore
    this.directLine.postActivity({
      from: this.objUser,
      type: 'event',
      //@ts-ignore
      name: 'requestWelcomeDialog',
      value: this.objValue
    }).subscribe();
  }

  endKPIBotConnection() {
    // console.warn(`endKPIBotConnection`);

    if (this.directLine) {
      this.directLine.end();
      this.directLine = undefined;
    }

    this.updateChatHistory();
  }

  resumeGeneeBotConnection() {
    // console.warn(`resumeGeneeBotConnection`);

    if (!this.directLine) {
      this.directLine = new DirectLine({
        token: this.authService.userConfig.activeInstance.geneeSecret,
        webSocket: true,
        conversationId: this.existingConversationId
      });
    }
    //@ts-ignore
    return this.directLine.activity$;
  }

  resetBotConnectionForSync() {
    this.isGeneeInitialized = false;
    this.existingConversationId = '';

    this.suggestedActions = [];
    this.selectedCustomerIdsForMeeting = [];
    this.selectedPollsIdsForMeeting = [];
    this.contactPresentationCombination = [];

    if (this.directLine) {
      this.directLine.end();
      this.directLine = undefined;
    }

    this.chatHistoryReset();
  }

  /**
  * Private helper to update the chat history every time session (KPI connection) ends.
  *
  * @memberOf MyAssistantDataService
  */
  public updateChatHistory() {
    // If any genee messages are there then only
    // We've to perform this action.
    if (this.geneeBotMessages.length) {

      // Session Count keep increasing as we end the Genee session.
      this.sessionCount += 1;

      // We will store current session chat count with the session count index
      if (this.sessionCount > 1) {
        // To store the exact chat history count for each session.
        let prevSessionsChatHistoryCount = 0;
        this.savedHistoryRange.forEach((value, key) => { prevSessionsChatHistoryCount += value; });
        // Then we will remove the prev sessions chat history count from the geneeBotMessages array to get the length of new messages.
        let actualCurrentSessionChatHistoryCount = (this.geneeBotMessages.length - prevSessionsChatHistoryCount);
        // Keep the new message count for the current session.
        this.savedHistoryRange.set(this.sessionCount, actualCurrentSessionChatHistoryCount);
      } else {
        this.savedHistoryRange.set(1, this.geneeBotMessages.length);
      }

      // console.warn(`this.sessionCount > ${this.sessionCount}`);
      // console.warn(`this.threshholdSessions > ${this.threshholdSessions}`);

      // If reach over the defined threshhold value
      // if (this.sessionCount > this.threshholdSessions) {
        // console.error(">>>> threshholdSessions <<<<");

        // let keyIndex = this.sessionCount - this.threshholdSessions;
        // let range = this.savedHistoryRange.get(keyIndex);
        // if (range) {
        //   console.log("range");

        //   if (this.geneeBotMessages.length > range) {
        //     console.log("this.geneeBotMessages.length > range");

        //     this.geneeBotMessages = this.geneeBotMessages.splice(range);
        //     this.savedHistoryRange.delete(keyIndex);
        //   }
        // }
      // }

    }
  }

  /**
  * Helper to reset the chat history. It will remove all the chat conversation.
  *
  * @memberOf MyAssistantDataService
  */
  public chatHistoryReset() {
    this.renderedCardMap.clear();
    this.sessionCount = 0;
    this.savedHistoryRange.clear();
    this.geneeBotMessages = [];
    this.populate();
  }

  initializeGeneeBotConnection() {
    this.initializeGeneeBotForKPI();
    return this.directLine.activity$;
  }

  sendMessage(message: string) {

    return this.directLine.postActivity({
      from: this.objUser,
      type: 'message',
      text: message,
      value: this.objValue
    });
  }

  submitAction(actionData: Object) {
    let objValue = { ...this.objValue, ...actionData };

    return this.directLine.postActivity({
      from: this.objUser,
      type: 'message',
      text: null,
      value: objValue
    });
  }


  /**
  * To submit action when links of QNA info (adaptive) card are clicked
  */
  submitQnAAction(actionText: string, actionData: Object) {
    let objValue = { ...this.objValue, ...actionData };

    return this.directLine.postActivity({
      from: this.objUser,
      type: 'message',
      text: actionText,
      value: objValue
    });
  }

  async hasPermission(): Promise<boolean> {
    try {
      return await this.speech.hasPermission();
    } catch (e) {
      console.error(e);
    }
  }

  async getPermission(): Promise<void> {
    try {
      this.speech.requestPermission();
    } catch (e) {
      console.error(e);
    }
  }

  async isSpeechSupported(): Promise<boolean> {
    try {
      return await this.speech.isRecognitionAvailable();
    } catch (e) {
      return false;
    }
  }

  scrollToBottom(delay: number, content): void {
    try {
      delay = delay ? delay : 0;
      content.scrollToBottom(delay);
    } catch (err) { }
  }

  async requestPermission() {
    if (this.device.isNativeApp && this.device.deviceFlags.android) {
      await this.androidPermission.requestPermission(this.androidPermission.PERMISSION.RECORD_AUDIO);
    }
  }

  public async stopSpeechRecording() {
    if (!this.device.deviceFlags.ios) {
      if (this.recognizer) {
        this.recognizer.stopContinuousRecognitionAsync();
      }
      this.recognizer = undefined;
    }
    else {
      if(this.iosSpeech) {
        this.iosSpeech.stopContinuousRecognition((res) => {
          console.log('ios stop recognition ', res);
        },
          (err) => {
            console.log('ios stop recog error ', err);
          });
      }
    }
  }

  public getUserInputSpeech() {
    return this.inputSpeechSubject.asObservable();
  }

  public startSpeechRecording() {
    if (!this.device.deviceFlags.ios) {
      if (!this.authService.notesAssistantConfig
        || !this.authService.notesAssistantConfig.speechSDKSubscriptionID) return;

      if (!this.recognizer) {
        const SpeechSDKSubscriptionID = this.authService.notesAssistantConfig.speechSDKSubscriptionID;
        const region = this.authService.notesAssistantConfig.speechRegion;
        const lang = this.authService.notesAssistantConfig.speechLanguage;

        const SpeechSDKCID = this.authService.notesAssistantConfig.speechCID;
        const speechConfig = SpeechConfig.fromSubscription(SpeechSDKSubscriptionID, region);
        speechConfig.endpointId = SpeechSDKCID;
        speechConfig.speechRecognitionLanguage = lang;

        const audioConfig = AudioConfig.fromDefaultMicrophoneInput();

        this.recognizer = new SpeechRecognizer(speechConfig, audioConfig);

        this.recognizer.recognizing = (s, e) => {
          console.log('speech recognizing');
          this.inputSpeechSubject.next({ status: 'recognizing', value: '' });
        };

        this.recognizer.recognized = (s, e) => {
          console.log('speech recognized');
          if (e.result && e.result.text) {
            this.inputSpeechSubject.next({ status: 'result', value: e.result.text });
          } else {
            this.inputSpeechSubject.next({ status: 'no-text', value: '' });
          }
        };

        this.recognizer.cancelled = (s, e) => {
          console.log('speech caceled');
          this.inputSpeechSubject.next({ status: 'cancelled', value: '' });
        }

        this.recognizer.sessionStarted = (s, e) => {
          console.log('speech sessionStarted');
          this.inputSpeechSubject.next({ status: 'session', value: 'started' });
        };

        this.recognizer.sessionStopped = (s, e) => {
          console.log('speech sessionStopped');
          this.inputSpeechSubject.next({ status: 'session', value: 'stopped' });
        };

        this.recognizer.speechStartDetected = (s, e) => {
          console.log('speech StartDetected');
          this.inputSpeechSubject.next({ status: 'speech', value: 'start' });
        };

        this.recognizer.addEventListener = (s, e) => {
          console.log('addEventListener');
        };

        // Signals that the speech service has detected that speech has stopped.
        this.recognizer.speechEndDetected = (s, e) => {
          console.log('speechEndDetected');
          this.inputSpeechSubject.next({ status: 'speech', value: 'end' });
        };
      }

      this.recognizer.startContinuousRecognitionAsync(() => {

      },
        (error) => {
          console.log('recognitionError', error);
          this.inputSpeechSubject.next({ status: 'error', value: '' });
        },
      );
    }
    else {
      if (!this.iosSpeech) {
        this.iosSpeech = (<any>cordova).plugins.msSpeechService;
      }
      this.iosSpeech.setupSpeechRecognizer((res) => {
        this.iosSpeech.startContinuousRecognition((res) => {
          console.log(res);
          if (res && res.isFinal) {
            this.inputSpeechSubject.next({ status: 'result', value: res.value });
          } else {
            this.inputSpeechSubject.next({ status: 'no-text', value: '' });
          }
        }, (err) => {
          console.log(err);
          this.inputSpeechSubject.next({ status: 'cancelled', value: '' });
        });
      }, (err) => {
        console.log('recognitionError', err);
        this.inputSpeechSubject.next({ status: 'error', value: '' });
      }, this.authService.notesAssistantConfig.speechSDKSubscriptionID,
        this.authService.notesAssistantConfig.speechRegion,
        this.authService.notesAssistantConfig.speechCID);
    }
  }

  private startListeningToSpeech() {
    let speechOptions: SpeechRecognitionListeningOptions = {
      showPopup: false
    }
    if (this.device.deviceFlags.ios) {
      speechOptions.showPartial = true;
    }
    return this.speech.startListening(speechOptions);
  }

  private startListeningOnIOS(): Promise<string> {
    return new Promise((resolve, reject) => {
      if (!this.iosSpeechListener) {
        this.iosSpeechListener = (<any>cordova).plugins.msSpeechService;
      }
      this.iosSpeechListener.setupSpeechRecognizer((res) => {
        this.iosSpeechListener.startContinuousRecognition((res) => {
          console.log(res);
          if (res && res.isFinal) {
            if (this.iosSpeechListener) {
              this.iosSpeechListener.stopContinuousRecognition((res) => { },
                (err) => { })
            }
            resolve(res.value);
          }
        }, (err) => console.log(err))
      }, (err) => {
        console.log('init recog error', err);
        reject('recognitionError');
      }, this.authService.notesAssistantConfig.speechSDKSubscriptionID,
        this.authService.notesAssistantConfig.speechRegion,
        this.authService.notesAssistantConfig.speechCID);
    })
  }

  private stopListeningToSpeech() {
    if (this.device.deviceFlags.ios) {
      this.iosSpeechListener.stopContinuousRecognition((res) => { }, (err) => { })
      this.iosSpeechListener = undefined;
    }
    else {
      this.speech.stopListening();
    }
  }

  async speak(msg: string) {
    if (!this.ttsSetup) {
      this.ttsSetup = (<any>window).WebSpeechCognitiveServices.create({
        credentials: {
          region: this.authService.notesAssistantConfig.speechRegion,
          subscriptionKey: this.authService.notesAssistantConfig.speechSDKSubscriptionID
        }
      });
      return new Promise((resolve, reject) => {
        this.ttsSetup.speechSynthesis.addEventListener('voiceschanged', () => {
          try {
            const voices = this.ttsSetup.speechSynthesis.getVoices();
            const utterance = new this.ttsSetup.SpeechSynthesisUtterance(msg);
            this.selectedVoice = voices.find(voice => /JessaRUS/u.test(voice.name));
            //this.selectedVoice = voices.find(voice => /Guy24kRUS/u.test(voice.name));
            utterance.voice = this.selectedVoice;
            utterance.addEventListener('end', (data) => {
              resolve(true);
            })
            utterance.addEventListener('error', (data) => {
              reject();
            })

            if (!this.isSpeakerMuted) {
              this.ttsSetup.speechSynthesis.speak(utterance);
            } else {
              resolve(true);
            }

            //resolve();
          } catch (error) {
            //reject();
          }
        });
      })
    }
    else {
      return new Promise((resolve, reject) => {
        try {
          const utterance = new this.ttsSetup.SpeechSynthesisUtterance(msg);
          utterance.voice = this.selectedVoice;
          utterance.addEventListener('end', (data) => {
            resolve(true);
          })
          utterance.addEventListener('error', (data) => {
            reject();
          })

          if (!this.isSpeakerMuted) {
            this.ttsSetup.speechSynthesis.speak(utterance)
          } else {
            resolve(true);
          }

        } catch (error) {
          console.log(error)
        }
      });
    }
  }

  async stopSpeaking() {
    console.log("Speaking will stop immediately");
    if (this.ttsSetup) {
      this.ttsSetup.speechSynthesis.cancel();
    }
  }
}
