import { Placeholder } from "@angular/compiler/src/i18n/i18n_ast";
import { ChannelType } from "../consent/channel.class";

export enum EmailTemplateType{
    RemoteURL = 100000000,
    Resource = 100000001,
    CalendarInvite = 548910001,
}
/**
 * A class representing email templates and their custom tokens to be inserted into the html email body
 *
 * @export
 * @class EmailTemplate
 */
export class EmailTemplate {
    private customTokens: Array<CustomToken> = new Array<CustomToken>();
    private template: Array<HTMLEmailTemplate> = new Array<HTMLEmailTemplate>();
    private customToken:CustomToken;

    get firstTemplate(): HTMLEmailTemplate {
        if (this.template.length == 0) return undefined;
        return this.template[0];
    }

    constructor(raw: object) {
        let customObjects = raw['customTokens'];
        for(let tokenID in customObjects) {
            let tokenInnerObject = customObjects[tokenID];
            let tempCustomToken  = new CustomToken(tokenID , tokenInnerObject['indskr_name'] ,tokenInnerObject['indskr_placeholder'], tokenInnerObject['values']);
            this.customTokens.push(tempCustomToken);
        }

        let emailTemplates = raw['emailTemplates']['value'];
        //implement some error handler
        if(Array.isArray(emailTemplates)) {
            emailTemplates.map(data=> {
                let HTMLEmailTemplates = new HTMLEmailTemplate(data.indskr_emailtemplateid , data.indskr_type , data.indskr_name ,data.indskr_email_subject, data.indskr_body , data.statuscode);
                this.template.push(HTMLEmailTemplates);
            });
        }

    }

    public get getCustomTokens(): Array<CustomToken> {
        return this.customTokens;
    }

    public get getHTMLEmailTemplates():Array<HTMLEmailTemplate> {
        return this.template;
    }

    public getASpecificCustomToken(id:string):CustomToken {
        if(id === null) {
            return;
        }

        return this.customTokens[this.customTokens.length - 1];
    }
}
/**
 *
 *  An email template class
 * @export
 * @class HTMLEmailTemplate
 */
export class HTMLEmailTemplate {
    public ID: string;
    public type: number;
    public name: string;
    public subject: string;
    public body: string;
    public statusCode: number;
    constructor(id:string , type:number , name:string , subject:string , body:string , statusCode:number) {
        this.ID = id;
        this.type = type;
        this.name = name;
        this.subject = subject;
        this.body = body;
        this.statusCode = statusCode;
    }
}
/**
 * Csutom token class related to email templates
 *
 * @export
 * @class CustomToken
 */
export class CustomToken {
    public ID: string;
    public placeholder: string;
    public name: string;
    public values: Array<string>;
    constructor(id:string , placeholder:string , name:string , values: Array<string>) {
        this.ID = id;
        this.placeholder = placeholder;
        this.name = name;
        this.values = values;
    }
}
export interface ResourceEmailTemplate {
    [x: string]: any;
    channelTypes: {channelType: ChannelType, body: string, channelId?: string}[];
    _id: String,
    _rev: String,
    indskr_name:string;
    indskr_email_subject: string;
    indskr_editablebyuser:boolean;
    indskr_description:string;
    indskr_body:string;
    indskr_emailtemplateid:string;
    productName:string;
    product_id:string;
    content_tokens: Array<string>;
    createdon:number;
    modifiedon:number;
    track_action: number;
    statuscode: number;
    indskr_thumbnailurl;
    selectable:boolean;
    indskr_type: EmailTemplateType;
    therapeuticAreaName: string;
    therapeuticareaId: string;
    indskr_available_from: number;
    indskr_available_until: number;
    indskr_groupguid: string;
    indskr_fb_body?: string,
    indskr_sms_body?: string,
    indskr_whatsapp_body?: string,
}

/**
 * Content token class related to email templates
 *
 * @export
 * @class ContentToken
 */
export interface ContentToken {
    _id: String;
    indskr_name:string;
    indskr_contenttokenid:string;
    indskr_iseditable:boolean;
    replacementTokens: ReplacementToken[]
}


export interface ReplacementToken {
    indskr_name:string;
    replacement_id:string;
    replacement_value:string;
    replacement_name:string;
    is_default:boolean;
}
