import { Brand } from '../brand/brand.class';
import { ActivityPresentationSlide } from './activity-presentation-slide.class';
import { Specialty } from '../contact/contact.class';
import * as _ from 'lodash';

export enum ListType {
    LIST = "list",
    GRID = "grid"
}

export enum ContentMode {
    PRESENTATION = "Presentation",
    RESOURCE = "Resource"
}
export enum SwipeStatus {
    ENABLED_DEFAULT = "Enabled Default",
    ENABLED_BY_USER = "Enabled By User",
    DISABLED_FOR_CONTENT_INTERACTION = "Disabled For Content Interaction",
    DISABLED_BY_USER = "Disabled By User"
}

export class Presentation {
    public createdOn: string;
    public modifiedOn: string;
    public availableFrom: Date;
    public availableUntil: Date;
    public height: number;
    public width: number;
    public isResponsive: boolean;
    public ioPresentationId: string;
    public contentId: string;
    public productID:string;
    public name: string;
    public thumbnailUrl: string;
    public zipUrl: string;
    public thumbnailZipUrl: string;
    public presentationPages: Page[];
    public virtualPages: Page[];
    public favourite: boolean;
    public downloadDate: string;
    public downloaded: boolean;
    public description: string;
    public languageId: string;
    public languageName: string;
    public isSelected: boolean = false;
    public keyMessages: KeyMessage[] = [];
    public brands: Array<Brand>;
    public stateCode:number;
    public statusCode:number;
    public specialties: Specialty[] = [];
    public theraputicAreas: string[] =[];
    public presentationTherapeuticAreas: PresentationTherapeuticAreas[] = [];
    public isAutoLoaded: boolean = true;
    public activityPresentationSlides: Array<ActivityPresentationSlide>;
    public isNew: boolean;
    public diseaseStates: DiseaseState[] =[];
    public showCustomSecInfo: boolean = false;
    public secondaryInfo: string = ''
    public customePageLocation: string;
    public categoryId: string;
    public categoryName: string;
    public indskr_ecard: boolean = false;
    public indskr_externalcontentlink: string;

    constructor(raw: object) {
        this.createdOn = raw["createdon"];
        this.modifiedOn = raw['modifiedon'];
        this.availableFrom = new Date(parseInt(raw['indskr_availablefrom']));
        this.availableUntil = new Date(parseInt(raw['indskr_availableuntil']));

        this.ioPresentationId = raw["indskr_iopresentationid"];
        this.contentId = raw["indskr_iopresentationid"];
        //for some reason ckm has this field in dynamics but we are not able to retrieve , this is might change is we have multiple products associated with the presentation
        //please take note and modeified the code accordingly
        this.productID = raw['indskr_productid'];
        this.name = (raw["indskr_title"])?raw["indskr_title"]:'';
        this.height = raw["indskr_ckmdefaultheight"];
        this.width = raw["indskr_ckmdefaultwidth"];
        this.isResponsive = raw["omnip_ckmisresponsive"];
        this.thumbnailUrl = raw["indskr_ckmthumbnailurl"];
        this.zipUrl = raw["indskr_ckmzipurl"];
        this.thumbnailZipUrl = raw["indskr_ckmthumbnailszipurl"];
        let pages = _.groupBy<Page>(raw["presentationPages"] || [], page => page.indskr_isvirtualpage ? 'virtual' : 'actual')
        this.virtualPages = pages.virtual || [];
        this.presentationPages = pages.actual || [];
        this.favourite = raw["favourite"];
        this.downloadDate = null;
        this.downloaded = false;
        this.description = raw["indskr_ckmdescription"] || "";
        this.languageId = raw["_indskr_languagid_value"] || "";
        this.languageName = raw["languageName"] || "";
        this.categoryId =raw["_indskr_categoryid_value"] || "";
        this.categoryName =raw ["categoryName"] || "",
        this.stateCode = raw["statecode"];
        this.statusCode = raw["statuscode"];
        this.indskr_externalcontentlink = raw["indskr_externalcontentlink"] || "";
        this.indskr_ecard = raw["indskr_ecard"] ?? false;
        this.isNew = false;
        if (raw['brands']) {
            let brands = raw['brands'];
            if (Array.isArray(brands)) {
                this.brands = [];

                brands.map(brand => {
                    this.brands.push(new Brand(brand));
                });
            }
        } else {
            this.brands = []
        }

        if (Array.isArray(raw['specialities'])) {
            this.specialties = raw['specialities'].map((specialtyDTO: PresentationSpecialtyDTO) => ({id: specialtyDTO.indskr_lu_specialtyid, name:specialtyDTO.indskr_specialty, code: specialtyDTO.indskr_specialtycode }));
        }

        if (Array.isArray(raw['therapeuticAreas'])) {
            this.theraputicAreas = raw['therapeuticAreas'].map(ta => ta['indskr_therapeuticareaid']);
        }

        if (this.presentationTherapeuticAreas && Array.isArray(raw['therapeuticAreas'])) {
            this.presentationTherapeuticAreas = raw['therapeuticAreas'].map((ta: PresentationTherapeuticAreas) => ({
                    indskr_name: ta.indskr_name,
                    indskr_therapeuticareaid: ta.indskr_therapeuticareaid,
                    presentationid: ta.presentationid
                }));
        }

        if (this.diseaseStates && Array.isArray(raw['diseaseStates'])) {
            this.diseaseStates = raw['diseaseStates'].map((diseaseState: DiseaseState) => ({
                    indskr_diseasestateid: diseaseState.indskr_diseasestateid,
                    indskr_name: diseaseState.indskr_name,
                    presentationid: diseaseState.presentationid
                }));
        }

        if(this.presentationPages && this.presentationPages.length > 0) {
            for (let page of this.presentationPages){
                if(page.keyMessage){
                    for (let key of page.keyMessage) {
                        if(!this.keyMessages.some(x => x.keyMessageId === key.keyMessageId)) {
                            this.keyMessages.push(key);
                        }
                    }
                }
                // if(page.sentimentImgURL) page.sentimentImgURL = "assets/imgs/sentiment-nutral.png";
            }
        }
    }


    get DTO() {
        return {
            indskr_iopresentationid: this.ioPresentationId,
            indskr_name: this.name,
            indskr_productid: (this.brands ? (this.brands.length > 0 ? this.brands[0].ID : null) : null),
            activityPresentationSlides: this.activityPresentationSlides
        }
    }

}

export interface Products {
    id: number;
    productId: string;
    sequence: string;
}

export interface Sort {
    direction: string;
    property: string;
    ignoreCase: boolean;
    nullHandling: string;
    ascending: boolean;
}

export interface Page {
    name: string;
    thumbnail: string;
    id: string;
    pageUrl: string;
    indskr_isvirtualpage: boolean;
    indskr_virtualpagevalue: string;
    indskr_virtualpagetype: string;
    pageSequence: string;
    keyMessage: Array<KeyMessage>;
    sentimentImgURL:string;
    sentiment:string;
    cameFromCarousel?: boolean;
    contentTypeResource?: boolean;
    fileType?: string;
}

export interface KeyMessage {
    keyMessageId: string;
    keyMessageName: string;
}

// export interface Brands {
//     id: number;
//     code: string;
//     name: string;
//     description: string;
//     status: string;
// }

export interface OfflinePresentation {
    ioPresentationId: string;
    name: string;
    downloadDate: Date;
    downloaded: boolean;
}

export interface PresentationSpecialtyDTO {
    indskr_lu_specialtyid: string;
    indskr_specialty: string;
    indskr_specialtycode: string;
    presentationid: string;
}

export interface DiseaseState {
    indskr_diseasestateid: string;
    indskr_name: string;
    presentationid: string;
}

export interface PresentationTherapeuticAreas {
    indskr_name: string;
    indskr_therapeuticareaid: string;
    presentationid: string;
}
