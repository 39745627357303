import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Domain } from '../../classes/domain/domain.class';
import { Endpoints } from '../../../config/endpoints.config';
import { map } from 'rxjs/operators';
import { AuthenticationService } from '../../services/authentication.service';
import { LogService } from '../../services/logging/log-service';
import { InterceptorSkipHeader } from '@omni/interceptors/token.interceptor';

/**
 * Service for getting domain details
 * eg. https://entrypoint-devps-na.io.indegene.com/api/tnt/azuretenantdetails/domain?domain=dynamics.dev.indegene.com
 * 
 * @export
 * @class DomainDataService
 * @extends {HTTPServiceBase}
 */
@Injectable({
  providedIn: 'root'
})
export class DomainDataService {

    constructor(private http: HttpClient, private authenticationService: AuthenticationService ,private logService:LogService) {}

    public getDomainDetailsForUsername(domain: string): Promise<any> {
        let url = Endpoints.domain.GET_DOMAIN_DETAILS + domain;
        return this.http.get(url, {headers: {[InterceptorSkipHeader]: "true"}}).toPromise();
    }

    /**
     * HTTP Get call to backend to pull required domain details for login/realm/tenant
     * 
     * @param {String} domain
     * @returns {Domain}
     * 
     * @memberOf DomainDataService
     */
    public getDomainDetails(domain: string): Observable<Domain> {
        let url = Endpoints.domain.GET_DOMAIN_DETAILS + domain;
        return this.http.get(url).pipe(
            map(response => this._mapDomain(response))
        );
    }

    /**
     * Internal function for mapping raw JSON to a domain class
     * 
     * @private
     * @param {Object} response
     * @returns {Domain}
     * 
     * @memberOf DomainDataService
     */
    private _mapDomain(response: Object): Domain {
         //Multiple domains
         if (Array.isArray(response) && response[0]) {
            this.authenticationService.activeDomain = new Domain(response[0]);

            
            this.authenticationService.saveDomainDetails(response);
        }

        return this.authenticationService.activeDomain;
    }
}