import { GlobalUtilityService } from './services/global-utility.service';
import { Component, ViewChild, NgZone, HostListener, ChangeDetectorRef } from "@angular/core";
import { Platform, NavController } from "@ionic/angular";
import { Events } from '@omni/events';
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { LoginPage } from "./pages/login/login";
import { LogService } from "./services/logging/log-service";
import { APP_VERSION } from "./../config/endpoints.config";
import { Deeplinks } from '@ionic-native/deeplinks/ngx';
import { RepServices } from "./data-services/rep/rep.services";
//import { REP_STATUS } from "./models/rep-status-model";
import { DeviceService } from "./services/device/device.service";
// import { LaunchDarklyProvider } from './providers/launch-darkly/launch-darkly';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { LocalizationService } from './services/localization/localization.service';
import { DateTimeFormatsService } from "./services/date-time-formats/date-time-formats.service";
// importing locale for date localization
import { registerLocaleData } from '@angular/common';
import fr from '@angular/common/locales/fr';
import es from '@angular/common/locales/es';
import en from '@angular/common/locales/en';
import ja from '@angular/common/locales/ja';
import de from '@angular/common/locales/de';
import nl from '@angular/common/locales/nl';
import tr from '@angular/common/locales/tr';
import pt from '@angular/common/locales/pt';
import it from '@angular/common/locales/it';
import zh from '@angular/common/locales/zh';
import zh_CN from '@angular/common/locales/zh';
import { ActivatedRoute, Params } from '@angular/router';
import { VeevaWidgetComponent } from './pages/veeva-widget/veeva-widget';
import { VeevaProfileWidgetComponent } from './pages/veeva-profile-widget/veeva-profile-widget.component';
import { CordovaPlugin } from './services/cordova';
import { BUILD_ENV } from "./../config/endpoints.config";

declare global {
  interface Window { MyNamespace: any; }
}


declare var IRoot: any;

@Component({
  selector: "app-root",
  templateUrl: "app.html",
})
export class MyApp {
  rootPage: any;
  @ViewChild("navigate", { static: true }) nav: NavController;
  recognizer: any;
  langaugeSubscription: Subscription;
  private _cordovaHelpers = new CordovaPlugin('CordovaHelpers');
  constructor(
    private logService: LogService,
    private platform: Platform,
    private statusBar: StatusBar,
    private deeplinking: Deeplinks,
    // private launchDarkly: LaunchDarklyProvider,
    private guv: GlobalUtilityService,
    private ngZone: NgZone,
    private events: Events,
    private translate: TranslateService,
    private localizationService: LocalizationService,
    private dateTimeFormatsService: DateTimeFormatsService,
    public repService: RepServices,
    public deviceService: DeviceService,
    private cd: ChangeDetectorRef,
    private route: ActivatedRoute
  ) {
    console.warn(`Current Version: ${APP_VERSION}`);

    this.initialize()
      .then(() => {
        this.ngZone.run(() => {
          this.route.queryParams.subscribe((params: Params) => {
            if (params && params["veevaWidget"] && params["veevaWidget"] === 'VEEVA_SEARCH') {
              this.rootPage = VeevaWidgetComponent;
            } else if (params && params["veevaWidget"] && params["veevaWidget"] === 'VEEVA_PROFILE') {
              this.rootPage = VeevaProfileWidgetComponent;
            }
            else {
              this.rootPage = LoginPage;
            }
          });
        });
      })

    let localStorageLanguage = localStorage.getItem('selectedLanguage');
    let storedDateFormat = localStorage.getItem('selectedDateFormat');
    let storedTimeFormat = localStorage.getItem('selectedTimeFormat');
    let storedSymbolPosition = localStorage.getItem('selectedSymbolPosition');
    let storedDecimalPosition = localStorage.getItem('selectedDecimalPosition');

    if (localStorageLanguage) {
      this.platform.ready().then(() => {
        this.translate.use(localStorageLanguage);
        registerLocaleData(this.getLocaleId(localStorageLanguage));
        this.localizationService.getLanguageDetails();
      });
    }
    else {
      let browserLang = typeof navigator !== 'undefined' ? navigator.language : 'en';
      let languageList = this.localizationService.getLanguages();
      languageList.forEach((lang) => {
        switch (browserLang) {
          case lang.code:
            translate.use(lang.code)
            localStorage.setItem('selectedLanguage', lang.code);
            registerLocaleData(<any>lang.code);
            break;
          case 'es-ES':
            translate.use('es');
            localStorage.setItem('selectedLanguage', 'es')
            registerLocaleData(es);
            break;
          case 'en-US':
            translate.use('en');
            localStorage.setItem('selectedLanguage', 'en')
            registerLocaleData(en);
            break;
          case 'ja-JP':
          case 'ja':
            translate.use('ja');
            localStorage.setItem('selectedLanguage', 'ja')
            registerLocaleData(ja);
            break;

          default:
            translate.setDefaultLang('en');
            localStorage.setItem('selectedLanguage', 'en')
            registerLocaleData(en);
            break;
        }
      })
    }

    if (storedDateFormat) {
      this.platform.ready().then(() => {
        this.dateTimeFormatsService.setDateFormat(JSON.parse(storedDateFormat));
      });
    }
    if (storedTimeFormat) {
      this.platform.ready().then(() => {
        this.dateTimeFormatsService.setTimeFormat(JSON.parse(storedTimeFormat));
      });
    }
    if (storedSymbolPosition) {
      this.platform.ready().then(() => {
        this.dateTimeFormatsService.setSymbolPos(JSON.parse(storedSymbolPosition));
      });
    }
    if (storedDecimalPosition) {
      this.platform.ready().then(() => {
        this.dateTimeFormatsService.setDecimalPos(JSON.parse(storedDecimalPosition));
      });
    }

  }

  public getLocaleId(localeLang): String {

    let localestr: any;
    switch (localeLang) {
      case 'fr':
        localestr = fr;
        break;
      case 'en':
        localestr = en;
        break;
      case 'es':
        localestr = es;
        break;
      case 'ja':
        localestr = ja;
        break;
      case 'de':
        localestr = de;
        break;
      case 'nl':
        localestr = nl;
        break;
      case 'tr':
        localestr = tr;
        break;
      case 'pt':
        localestr = pt;
        break;
      case 'it':
        localestr = it;
        break;
      case 'zh':
        localestr = zh;
        break;
      case 'zh_CN':
        localestr = zh_CN;
        break;
      default:
        localestr = en;
        break;
    }
    return localestr;
  }

  @HostListener('window:appload')
  async appLoaded() {
    await this.platform.ready();
    (navigator as any)?.splashscreen?.hide();
  }

  async initialize() {
    await this.platform.ready();
    // Okay, so the platform is ready and our plugins are available.
    // Here you can do any higher level native things you might need.
    if (this.deviceService.isNativeApp) {

      if (!this.deviceService.deviceFlags.electron) {
        if (this.deviceService.deviceFlags.ios) {
          this.statusBar.overlaysWebView(true);
          this.statusBar.backgroundColorByHexString('#00000000');
        } else if (this.deviceService.deviceFlags.android) {
          this.statusBar.overlaysWebView(false);
          this.statusBar.backgroundColorByHexString('#204B8F');
        } else {
          this.statusBar.overlaysWebView(false);
          this.statusBar.styleDefault();
          this.statusBar.backgroundColorByHexString('#fff');
        }
      }

      this.repService.isOfflineState$.subscribe(isOffline => {
        this.cd.detectChanges();
      });
    }

    const hasNetwork = await this.deviceService.checkNetworkConnectivity()
    if (hasNetwork) {
      // await this.launchDarkly.initialize();
      this.guv.setupSessionStack();
    }

    /**
     * Check if device is rooted and exit if it is
     */

    // if (!this.platform.is("core")) {
    //   if (typeof (IRoot) !== 'undefined' && IRoot && !this.device.isVirtual) {
    //     IRoot.isRooted((data) => {
    //       if (data && data == 1) {
    //         console.log("HALO This is rooted device");
    //         let alert = this.alert.create({
    //           title: 'Cannot use the app on rooted device.',
    //           buttons: [
    //             {
    //               text: 'OK',
    //               role: 'cancel',
    //               handler: data => {
    //                 this.platform.exitApp();
    //               }
    //             }
    //           ]
    //         });
    //         alert.present();
    //       } else {
    //         console.log("This is not rooted device");
    //       }
    //     }, (data) => {
    //       console.log("Rooted device detection failed case", data);
    //     });
    //   }
    // }

    if (this.platform.is('cordova')) {
      const handleVeevaDeeplinks = ev => {
        let eventName = undefined;
        let eventData = undefined;
        switch (ev?.$link?.path) {
          case '/veeva/hcp-search/select':
            if (ev?.$link?.queryString?.startsWith('res=')) {
              eventName = 'select';
              eventData = atob(ev?.$link?.queryString?.substring(4))
            }
            break;
          case '/veeva/hco-search/select':
            eventName = 'select';
            eventData = atob(ev?.$link?.queryString?.substring(4))
            break;
          case '/veeva/profile/dcr-submitted':
            eventName = 'dcr-submitted';
            eventData = atob(ev?.$link?.queryString?.substring(4))
            break;
        }
        if (eventName && eventData) {
          eventData = JSON.parse(eventData);
          const myEvent = new CustomEvent(`veeva-network-${eventName}`, {
            detail: eventData,
            bubbles: true,
            cancelable: true,
            composed: false,
          });
          console.log(myEvent);
          window.dispatchEvent(myEvent);
        }
      }
      this.deeplinking.routeWithNavController(this.nav, {
        '/login': LoginPage
      }).subscribe(
        match => {
          console.log(match);
          handleVeevaDeeplinks(match);
        },
        nomatch => {
          console.log(nomatch);
          handleVeevaDeeplinks(nomatch);
        }
      );
      // var appToken = "e612d4d1-580c-4dc9-b6cd-f46f15f74452";
      // let options = {};
      // options['Bugsee.AndroidOptions.MonitorNetwork'] = false;
      // options['Bugsee.IOSOptions.ShakeToReport'] = true;
      // (<any>window).Bugsee.launch(appToken, options);
    }

    //Set our window encryption method
    (<any>window).AESGCM = {
      name: "AES-GCM",
      length: 256,
    }

    //this.statusBar.hide();
    //      splashScreen.hide();
    this.logService.logDebug("Navigation: ", this.nav);

    //enableProdMode();

    //await this.disk._loadLastModified()
    if (this.platform.is('cordova')) {
      if (this.deviceService.isNativeApp) {
        if (BUILD_ENV === 'dev' || BUILD_ENV === 'demo' || BUILD_ENV === 'qa') {
          this.enableBugsee();
        }
      }
    }
  }

  @HostListener("window:message", ["$event.data"])
  onMessage(message) {
    if (message.hasOwnProperty("messageSource")) return;
    this.events.publish("window:message", message);
  }

  enableBugsee() {
    // if (this.deviceService.deviceFlags.ios) {
    //       let applicationToken = '';
    //       var bugseeOptions = new (<any>window).Bugsee.IOSLaunchOptions();
    //       bugseeOptions.shakeToReport = true;
    //       bugseeOptions.reportPrioritySelector = true;
    //       (<any>window).Bugsee.launch(this.getAppTokenForIos(BUILD_ENV), bugseeOptions);
    //   } else if (this.deviceService.deviceFlags.android) {
    //       var bugseeOptions = new (<any>window).Bugsee.AndroidLaunchOptions();
    //           bugseeOptions.shakeToTrigger = true;
    //           bugseeOptions.useSdCard = true;
    //           bugseeOptions.notificationBarTrigger = false;
    //           (<any>window).Bugsee.launch(this.getAppTokenForAndriod(BUILD_ENV), bugseeOptions);
    //   }
  }

  getAppTokenForIos(env): String {
    let applicationToken = '';
    // switch(env) {
    //      case 'dev' :
    //       applicationToken = BugseeConfig.iOS.DEV;
    //       break
    //      case 'qa' :
    //       applicationToken = BugseeConfig.iOS.QA;
    //       break
    //      case 'demo' :
    //       applicationToken = BugseeConfig.iOS.DEMO;
    //       break
    // }
    return applicationToken
  }

  getAppTokenForAndriod(env): String {
    let applicationToken = '';
    // switch(env) {
    //      case 'dev' :
    //       applicationToken = BugseeConfig.ANDROID.DEV;
    //       break
    //      case 'qa' :
    //       applicationToken = BugseeConfig.ANDROID.QA;
    //       break
    //      case 'demo' :
    //       applicationToken = BugseeConfig.ANDROID.DEMO;
    //       break
    // }
    return applicationToken
  }
}
