import { DatePipe } from "@angular/common";
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { LoadingController, PopoverController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { Contact } from "@omni/classes/contact/contact.class";
import { IONote } from "@omni/classes/io/io-note.class";
import { PopoverComponent, SelectListData } from "@omni/components/popover/popover";
import { DynamicsClientService } from "@omni/data-services/dynamics-client/dynamics-client.service";
import { EventsToolDataService } from "@omni/data-services/event/events-tool.data.service";
import { EventRegistrationStatus } from "@omni/enums/event/event.enum";
import { IndDropdownListDetailModel } from "@omni/models/indDropdownListModel";
import { FormFieldType, IndFormFieldViewDataModel } from "@omni/models/indFormFieldDataModel";
import { IndPageTitleViewDataModel } from "@omni/models/indPageTitleDataModel";
import { IndSectionHeaderViewDataModel } from "@omni/models/indSectionHeaderDataModel";
import { AuthenticationService } from "@omni/services/authentication.service";
import { DateTimeFormatsService } from "@omni/services/date-time-formats/date-time-formats.service";
import { DeviceService } from "@omni/services/device/device.service";
import { EventsToolService } from "@omni/services/events-tool/events-tool.service";
import { EventsService } from "@omni/services/events/events.service";
import { NavigationService, PageName } from "@omni/services/navigation/navigation.service";
import { NotificationService, ToastStyle } from "@omni/services/notification/notification.service";
import { UIService } from "@omni/services/ui/ui.service";
import { MAXIMUM_NOTE_ATTACHMENT_SIZE, NOTE_ATTACHMENT_MIME_TYPES_SUPPORTED_REGEX, toBase64 } from "@omni/utility/util";
import _ from "lodash";
import { isEmpty } from "lodash";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Guid } from "typescript-guid";
import { EventActivity, EventParticipant } from "../../classes/events-tool/event.class";
import { IndDropdownListComponent } from "../shared/ind-dropdown-list/ind-dropdown-list";

@Component({
  selector: 'event-participant',
  templateUrl: 'event-participant.html',
  styleUrls: ['event-participant.scss'],
})
export class EventParticipantComponent implements OnInit, OnDestroy {
  @ViewChild('attachInput') attachInput: ElementRef;
  @Input() eventParticipant: EventParticipant;
  @Input() contact: Contact;
  @Input() currentEvent: EventActivity;
  eventNotesPageTitle: IndPageTitleViewDataModel;
  notesHeader: IndSectionHeaderViewDataModel;
  notesPlaceholder = '';
  isAttachmentAdded = false;
  isSaveNotesEnabled = false;
  attachmentTitle = '';
  tempNoteText = '';
  base64str: any;
  attachmentFile: any;
  currentCustomerNotes: IONote[] = [];
  pageName = PageName.EventParticipantComponent;
  indskr_reasons = EventRegistrationStatus.Draft;
  isEventOwnerOrTeamMember = false;
  isOffline = false;
  isEventCompleted = false;
  private addParticipantAndSaveNoteInProgress = false;
  private ngDestroy$ = new Subject<boolean>();
  loading = true;
  private eventActivity: EventActivity;

  constructor(
    private navSvc: NavigationService,
    private translate: TranslateService,
    private loadingCtrl: LoadingController,
    private notificationService: NotificationService,
    private authService: AuthenticationService,
    private eventsToolDataService: EventsToolDataService,
    public eventsToolService: EventsToolService,
    private deviceService: DeviceService,
    private events: EventsService,
    private uiService: UIService,
    private datePipe: DatePipe,
    private dateTimeFormatsService: DateTimeFormatsService,
    private dynamics: DynamicsClientService,
    private popoverCtrl: PopoverController,
  ) { }

  public formFieldData: IndFormFieldViewDataModel[] = [
    {
      label: this.translate.instant('NAME'),
      inputText: '',
      id: 'name-field',
      isReadOnly: true,
      isDisabled: true,
    },
    {
      label: this.translate.instant('STATUS'),
      inputText: '',
      id: 'status-field',
      isReadOnly: true,
      isDisabled: true,
    },
    {
      label: this.translate.instant('CHECK_IN_DATE_TIME'),
      inputText: '',
      id: 'checkin-date-time-field',
      isReadOnly: true,
      isDisabled: true,
    },
    {
      label: this.translate.instant('INVITED_BY'),
      inputText: '',
      id: 'invited-by-field',
      isReadOnly: true,
      isDisabled: true,
    },
    {
      label: this.translate.instant('START_DATE'),
      inputText: '',
      id: 'start-date-field',
      isReadOnly: true,
      isDisabled: true,
    },
    {
      label: this.translate.instant('END_TIME'),
      inputText: '',
      id: 'end-time-field',
      isReadOnly: true,
      isDisabled: true,
    },
    {
      label: this.translate.instant('PASS'),
      inputText: '',
      id: 'pass-field',
      formFieldType: FormFieldType.POPOVER_SELECT,
      isReadOnly: true,
      isDisabled: true,
      eventHandler: (id: string, event, eventName) =>  this.handlePassField(event)
    },
  ];

  private getFormattedDate(date: Date) {
    return this.datePipe.transform(date, this.dateTimeFormatsService.date, undefined, this.translate.currentLang) + ' ' + this.datePipe.transform(date, this.dateTimeFormatsService.time, undefined, this.translate.currentLang);
  }

  private initPageHeader() {
    this.eventNotesPageTitle = {
      id: 'calendar-invite-page-header',
      title: this.eventActivity?.name,
      controls: [
        {
          id: 'close',
          icon: 'chevron-back-outline',
          isDisabled: false,
          align: 'left'
        },
        ...(this.indskr_reasons == EventRegistrationStatus.Draft || this.indskr_reasons == EventRegistrationStatus.Cancelled ? [
        {
          id: 'report-send-for-approval',
          name: this.translate.instant('SEND_FOR_APPROVAL_EVENT'),
          imgSrc: 'assets/imgs/header_send.svg',
          isDisabled: false,
          align: 'right',
        }] : []),
      ],
    };
  }
  private initSectionHeaders() {
    const controls: any[] = [
      {
        id: 'attach_note',
        text: this.translate.instant('ATTACH'),
        isDisabled: this.isEventCompleted || this.isOffline
          || !this.isEventOwnerOrTeamMember,
      },
      {
        id: 'save_note',
        text: this.translate.instant('SAVE'),
        isDisabled: this.isEventCompleted || this.isOffline
          || (isEmpty(this.tempNoteText) && !this.isAttachmentAdded),
      }
    ];

    this.notesHeader = {
      id: 'event-participant-section-header',
      title: this.translate.instant('NOTES'),
      controls,
    };
  }

  private async initEventDetails() {
    try {
      this.loading = true;
      if (this.eventParticipant) {
        this.currentCustomerNotes = this.eventParticipant.notes?.slice() || [];
        this.indskr_reasons = this.eventParticipant.indskr_reasons;
      } else if (this.contact) {
        const details = this.eventsToolService.getPendingParticipantDetails(this.contact.ID);
        if (Array.isArray(details?.notes)) {
          this.currentCustomerNotes = details?.notes?.slice() || [];
        }
        this.indskr_reasons = details?.indskr_reasons || EventRegistrationStatus.Draft;
      }
      this.formFieldData[0].inputText = this.eventActivity?.name;
      this.formFieldData[1].inputText = EventRegistrationStatus[this.indskr_reasons];
      this.formFieldData[2].inputText = this.eventParticipant && this.eventParticipant.msevtmgt_checkintime ? this.getFormattedDate(this.eventParticipant.msevtmgt_checkintime) : "";
      this.formFieldData[2].isHidden = this.indskr_reasons != EventRegistrationStatus["Checked-in"];
      this.formFieldData[3].inputText = this.authService.user.displayName + ' |';
      this.formFieldData[3].secondaryInputText = ` ` + this.getFormattedDate(new Date());
      this.formFieldData[4].inputText = this.getFormattedDate(this.eventActivity.startDate);
      this.formFieldData[5].inputText = this.getFormattedDate(this.eventActivity.endDate);
      this.formFieldData[6].inputText = this.getPassFieldName();
      this.formFieldData[6].isDisabled = this.isPassFieldDisabled();
      this.formFieldData[6].customPlaceholderLabel = this.formFieldData[6].isDisabled ? this.translate.instant('NO_PASS') : this.translate.instant("SELECT_PASS");
      this.formFieldData[6].showArrow = !this.formFieldData[6].isDisabled;
      this.eventNotesPageTitle.title = this.formFieldData[0].inputText;
      this.initPageHeader();
      this.loading = false;
    } catch (error) {
      console.error(error);
    }
  }

  ngOnInit() {
    this.isOffline = this.deviceService.isOffline;
    this.eventActivity = this.eventsToolService.selectedEventOnEventsTool || this.currentEvent;
    this.isEventCompleted = this.eventActivity?.statecode === 1
      && this.eventActivity?.statuscode === 548910005;
    this.isEventOwnerOrTeamMember =
      this.eventActivity.ownerId === this.authService.user.systemUserID
        || (
          Array.isArray(this.eventActivity.covisitors)
          && (
            this.eventActivity.covisitors.findIndex(
              c => c.id === this.authService.user.systemUserID
            ) >= 0
          )
        ) ? true : false;

    this.initPageHeader();
    this.initSectionHeaders();
    this.notesPlaceholder = this.translate.instant('ENTER_NOTES');
    this.initEventDetails();
    this.deviceService.isOfflineObservable
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe(isOffline => {
        this.isOffline = isOffline;
        this.initSectionHeaders();
      });
    this.events
      .observe("eventsTool:submit-for-approval:refresh")
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe(() => {
        this.initEventDetails();
      });
  }
  ngOnDestroy() {
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }

  private getPassFieldName(): string {
    const contactId = this.contact?.ID ? this.contact.ID : this.eventParticipant?.id;
    let participant = this.currentEvent['eventPassesToSave']?.find(participant => participant.id == contactId);
    if (participant) {
      const passess = _.orderBy(participant?.eventPasses.filter(pass => !pass.deleted), 'msevtmgt_passName');
      return _.isEmpty(passess) ? '' : (passess[0].msevtmgt_passName + (passess.length > 1 ? (' +' + (passess.length - 1)) : ''));
    } else {
      if (!_.isEmpty(this.eventParticipant?.eventPasses)) this.eventParticipant.eventPasses = _.orderBy(this.eventParticipant.eventPasses, 'msevtmgt_passName');
      return !this.eventParticipant || _.isEmpty(this.eventParticipant.eventPasses) ? "" : (this.eventParticipant.eventPasses[0].msevtmgt_passName + (this.eventParticipant.eventPasses.length > 1 ? (' +' + (this.eventParticipant.eventPasses.length - 1)) : ''));
    }
  }

  /**
   * Disable when device is offline / no event pass available for participant for completed event / no passes mapped to event / no passes left for the event
   *
   * @param participant
   * @returns
   */
  private isPassFieldDisabled() {
    return (_.isEmpty(this.eventParticipant?.eventPasses) &&
      (
        this.isEventCompleted
        || !this.isEventOwnerOrTeamMember
        || this.deviceService.isOffline
        || !this.currentEvent.passes.some(pass => pass['temp_msevtmgt_noOfPassesLeft'] > 0)
      )
    )
      || _.isEmpty(this.currentEvent.passes);
  }

  onPageTitleControlClick(id: string) {
    switch (id) {
      case 'close':
        this.closePage();
        break;
      case 'report-send-for-approval':
        this.handleSendForApprovalBtnClick();
        break;
      default:
        break;
    }
  }
  onSectionHeaderControlClick(id: string) {
    switch (id) {
      case 'attach_note':
        this.openAttachOptionsPopover(id);
        break;

      case 'save_note':
        this.handleSaveNoteBtnClick();
        break;
      default:
        break;
    }
  }

  private async handlePassField(event) {
    const selectedPassIds = [];
    const contactId = this.contact?.ID ? this.contact.ID : this.eventParticipant?.id;
    let participant = this.currentEvent['eventPassesToSave']?.find(participant => participant.id == contactId);
    participant = participant ? participant : this.eventParticipant;
    const data: SelectListData[] = _.orderBy(this.currentEvent.passes, obj => obj.msevtmgt_passName).map(pass => {
      const isPassSelected: boolean = participant?.eventPasses ? participant.eventPasses.some(eventPass => eventPass.msevtmgt_passId == pass.msevtmgt_passId && !eventPass.deleted) : false;
      if (isPassSelected) selectedPassIds.push(pass.msevtmgt_passId);
      if (!(!isPassSelected && pass['temp_msevtmgt_noOfPassesLeft'] <= 0)) {
        return {
          id: pass.msevtmgt_passId,
          title: pass.msevtmgt_passName,
          isSelected: isPassSelected
        }
      }
    })
    const dropdownListDetail: IndDropdownListDetailModel = {
      id: 'pass-dropdown',
      data: _.remove(data, undefined),
      isReadOnly: this.deviceService.isOffline
    };
    const dropdown = await this.popoverCtrl.create({
      component: IndDropdownListComponent,
      componentProps: { viewData: dropdownListDetail },
      cssClass: 'dropdown-list-view',
      event: event,
    });
    await dropdown.present();
    await dropdown.onDidDismiss().then(async (obj: any) => {
      if(this.deviceService.isOffline) return;
      if (obj?.data && obj.data.isDone) {
        if (obj.data.selectedItems.length == selectedPassIds.length && obj.data.selectedItems.every(item => selectedPassIds.includes(item.id))) {
          this.prepareEventAttendeePassPayload(obj.data.selectedItems.map(item => item.id));
          this.prepareEventAttendeePassPayload([]);
          this.formFieldData[6].inputText = null;
          this.events.publish('updateContactListIsDirty');
        } else {
          this.prepareEventAttendeePassPayload(obj.data.selectedItems.map(item => item.id));
          this.formFieldData[6].inputText = this.getPassFieldName();
          this.events.publish('updateContactListIsDirty');
        }
      }
    })
  }

  private prepareEventAttendeePassPayload(selectedPassIds: string[]) {
    const eventPassesToRemove = [];
    const contactID = this.contact?.ID ? this.contact.ID : this.eventParticipant?.id;
    if (this.eventParticipant?.eventPasses) {
      this.removeSavedEventPasses(selectedPassIds, eventPassesToRemove);
    }
    this.removeUnSavedEventPasses(selectedPassIds, contactID);
    const eventPassesToSave = [];
    selectedPassIds
      .forEach(passId => {
        const existingEPassIndex = this.eventParticipant?.eventPasses.findIndex(pass => pass.msevtmgt_passId === passId);
        const index = this.currentEvent.passes.findIndex(pass => pass.msevtmgt_passId == passId);
        if (index >= 0) {
          this.currentEvent.passes[index]['temp_msevtmgt_noOfPassesLeft'] -= 1;
        }
        eventPassesToSave.push({
          msevtmgt_attendeepassid: existingEPassIndex >= 0 ? this.eventParticipant?.eventPasses[existingEPassIndex].msevtmgt_attendeepassId : Guid.create().toString(),
          msevtmgt_passName: this.currentEvent.passes[index].msevtmgt_passName,
          passId: passId,
          msevtmgt_passId: passId
        })
      });
    const payload = [...eventPassesToRemove, ...eventPassesToSave];
    if (_.isEmpty(this.currentEvent['eventPassesToSave'])) this.currentEvent['eventPassesToSave'] = [];
    const eventPassToSaveInx = this.currentEvent['eventPassesToSave'].findIndex(passesToSave => passesToSave.id == contactID);
    if (eventPassToSaveInx == -1) {
      this.currentEvent['eventPassesToSave'].push({
        id: contactID,
        eventPasses: payload
      });
    } else {
      this.currentEvent['eventPassesToSave'][eventPassToSaveInx].eventPasses = payload;
    }
  }

  private removeUnSavedEventPasses(selectedPassIds: string[], contactId) {
    if (this.currentEvent['eventPassesToSave']) {
      const eventPassesToSave = this.currentEvent['eventPassesToSave'].find(eventPass => eventPass.id === contactId);
      if (eventPassesToSave) {
        eventPassesToSave.eventPasses.filter(eventPass => !selectedPassIds.includes(eventPass.msevtmgt_passId)).forEach(item => {
          const existingEPass = this.eventParticipant?.eventPasses.find(pass => pass.msevtmgt_passId === item.msevtmgt_passId);
          if (!existingEPass) {
            const index = this.currentEvent.passes.findIndex(pass => pass.msevtmgt_passId == item.msevtmgt_passId);
            if (index >= 0)
              this.currentEvent.passes[index]['temp_msevtmgt_noOfPassesLeft'] += 1;
          }
        })
      }
    }
  }

  private removeSavedEventPasses(selectedPassIds: string[], eventPassesToRemove: any[]) {
    this.eventParticipant.eventPasses
      .filter(eventPass => !selectedPassIds.includes(eventPass.msevtmgt_passId))
      .forEach(eventPass => {
        const index = this.currentEvent.passes.findIndex(pass => pass.msevtmgt_passId == eventPass.msevtmgt_passId);
        if (index >= 0) {
          this.currentEvent.passes[index]['temp_msevtmgt_noOfPassesLeft'] += 1;
          eventPassesToRemove.push({
            msevtmgt_passId: eventPass.msevtmgt_passId,
            msevtmgt_attendeepassid: eventPass.msevtmgt_attendeepassId,
            msevtmgt_passName: this.currentEvent.passes[index].msevtmgt_passName,
            eventRegId: this.eventParticipant.registrationId,
            deleted: true
          });
        }
      });
  }

  async openAttachOptionsPopover(myEvent) {
    const options = [
      {
        id: 'fromDevice',
        title: this.translate.instant('FROM_DEVICE'),
        value: 'fromDevice',
      },
      {
        id: 'pastRegistration',
        title: this.translate.instant('PREVIOUS_EVENTS'),
        value: 'pastRegistration',
      },
    ];
    const selectOption = async (data) => {
      this.popoverCtrl.dismiss();
      if (data?.id) {
        if (data.id == 'fromDevice') {
          this.eventsToolService.pastEventAttachments = [];
          this.eventsToolService.annotation = null;
          this.handleAttachmentBtnClick();
        } else {
          await this.handlePastEventAttachments();
        }
      }
    }
    const popOver = await this.popoverCtrl.create({
      component: PopoverComponent,
      componentProps: { selectListPopupData: options, field: 'select-list-popup', selectEvent: selectOption },
      cssClass: 'account-plan-select',
      event: event,
    });
    popOver.present();
  }

  async handlePastEventAttachments() {
    await this.eventsToolDataService.getPastEventAttachments(
      [this.eventParticipant ? this.eventParticipant.id : this.contact.ID]
    );
    if (!this.eventsToolService.pastEventAttachments.length) {
      this.notificationService.notify(
        this.translate.instant('NO_ATTACHMENTS_FOUND_FROM_PREVIOUS_EVENTS'),
        'Event Notes',
        'top',
        ToastStyle.INFO,
      );
    }
  }

  selectPastEventAttachment(attachment, $event) {
    this.eventsToolService.pastEventAttachments.forEach(ev => {
      ev.attachments.forEach(att => {
        if (att.annotationid == attachment.annotationid) {
          att.checked = true;
          this.attachmentTitle = att.filename;
        } else {
          att.checked = false;
        }
      })
    })
    if ($event.target.checked) {
      this.isAttachmentAdded = true;
    } else {
      this.attachmentTitle = '';
      this.isAttachmentAdded = false;
    }
    this.initSectionHeaders();
  }

  notesChanged(event) {
    if (event?.target) {
      this.tempNoteText = event.target.value;
    } else {
      this.tempNoteText = '';
    }
    if (this.tempNoteText.length > 0) {
      this.isSaveNotesEnabled = true;
    } else {
      this.isSaveNotesEnabled = false;
    }
    this.initSectionHeaders();
  }
  removeAttachment(event) {
    if (this.attachInput?.nativeElement?.value) {
      this.attachInput.nativeElement.value = null;

      this.attachmentTitle = '';
      this.isAttachmentAdded = false;
      this.attachmentFile = null;
      this.base64str = null;
      this.initSectionHeaders();
    }
    if (this.eventsToolService.pastEventAttachments.length && !this.attachInput?.nativeElement?.value) {
      this.eventsToolService.pastEventAttachments.forEach(ev => {
        ev.attachments.forEach(att => {
          att.checked = false;
        })
      })
      this.attachInput.nativeElement.value = null;

      this.attachmentTitle = '';
      this.isAttachmentAdded = false;
      this.attachmentFile = null;
      this.base64str = null;
      this.initSectionHeaders();
    }
  }

  async loadFileFromDevice(event) {
    if (event?.target?.files) {
      try {
        const file = event.target.files[0];
        if (file && (file.size / 1000) < MAXIMUM_NOTE_ATTACHMENT_SIZE) {
          if (NOTE_ATTACHMENT_MIME_TYPES_SUPPORTED_REGEX.test(file.name)) {
            const b64Str: any = await toBase64(file);
            const b64StrSplitArray = b64Str.split(',');
            this.base64str = Array.isArray(b64StrSplitArray)
              && b64StrSplitArray.length > 1
              ? b64StrSplitArray[1] : b64Str || null;
            this.attachmentFile = file;
            this.attachmentTitle = file.name;
            this.isAttachmentAdded = true;
            this.initSectionHeaders();
          } else {
            this.notificationService.notify(
              this.translate.instant('NOTE_ATTACHMENT_MIME_TYPE_NOT_SUPPORTED_NOTIFICATION'),
              'Event Notes',
              'top',
              ToastStyle.INFO,
            );
            this.removeAttachment(false);
          }
        } else {
          this.notificationService.notify(
            this.translate.instant(
              'MAXIMUM_NOTE_ATTACHMENT_SIZE_NOTIFICATION',
              { size: MAXIMUM_NOTE_ATTACHMENT_SIZE }
            ),
            'Event Notes',
            'top',
            ToastStyle.INFO,
          );
          this.removeAttachment(false);
        }
      } catch (error) {
        console.error('loadFileFromDevice: ', error);
      }
    }
  }

  canEditNote(note: IONote) {
    return !this.isEventCompleted && !this.isOffline && note.ownerId === this.authService.user.systemUserID;
  }
  canDeleteNote(note: IONote) {
    return !this.isEventCompleted && !this.isOffline && note.ownerId === this.authService.user.systemUserID;
  }
  handleUpdateNoteBtnClick(event) {
    if (this.eventParticipant) {
      this.updateNote(event);
    } else if (this.contact) {
      this.updatePendingParticipantNote(event);
    }
  }
  async updateNote(event) {
    if (event?.action) {
      try {
        const noteIdx: number = this.eventParticipant.notes?.findIndex(n => n.noteId === event.noteId);
        if (noteIdx >= 0) {
          const note: IONote = this.eventParticipant.notes[noteIdx];
          const origNote: IONote = JSON.parse(JSON.stringify(note));
          const payload: any = {
            noteid: note.noteId,
            eventregistrationid: this.eventParticipant.registrationId,
          };

          if (event.action === 'DELETE') {
            payload.deleted = true;
            this.eventParticipant.notes.splice(noteIdx, 1);
          } else if (event.action === 'SAVE') {
            note.noteText = payload.notetext = event.updatedText;

            if (event.attachmentFileUpdated) {
              note.hasDocument = payload.isdocument = true;
              note.documentName = payload.filename = event.documentName;
              note.documentMimeType = payload.mimetype = event.documentMimeType;
              // note.documentSize = payload.filesize = event.documentSize;
              const b64StrSplitArray = event.attachmentFileDataUrl?.split(',');
              payload.documentbody = Array.isArray(b64StrSplitArray)
                && b64StrSplitArray.length > 1
                ? b64StrSplitArray[1] : event.attachmentFileDataUrl || null;
              payload.fileupdated = true;
            } else if (event.isAttachmentRemoved) {
              note.hasDocument = payload.isdocument = false;
              note.documentName = payload.filename
                = note.documentMimeType = payload.mimetype = '';
              note.documentSize = payload.filesize = null;
              payload.fileremoved = true;
            }
          }

          // let loader: any;
          try {
            // loader = await this.loadingCtrl.create();
            // await loader.present();
            await this.uiService.displayLoader();

            const response = await this.eventsToolDataService.createUpdateDeleteEventNote([payload]);
            if (Array.isArray(response) && response[0]?.noteid) {
              // Update local DB
              this.eventsToolDataService.updateEventsData(
                {
                  onDynamics: false,
                  onLocalDatabase: true,
                  onLocalCopy: true,
                },
                [this.eventActivity],
              );
            }
          } catch (error) {
            console.error('event-participant: updateNote: createUpdateDeleteEventNote: ', error);
            // Revert
            this.eventParticipant.notes[noteIdx] = origNote;
          } finally {
            // await loader?.dismiss();
            await this.uiService.dismissLoader();
          }
        }
      } catch (error) {
        console.error('event-participant: updateNote: ', error);
      }
    }
  }
  private async updatePendingParticipantNote(event) {
    if (event?.action) {
      try {
        await this.uiService.displayLoader();
        const contactId = this.contact?.ID || this.eventParticipant?.id;
        const pendingNotes = this.eventsToolService.getPendingParticipantDetails(contactId)?.notes;
        if (Array.isArray(pendingNotes)) {
          const noteIdx: number = pendingNotes.findIndex(n => n.noteId === event.noteId);
          if (noteIdx >= 0) {
            const note: IONote = pendingNotes[noteIdx];

            if (event.action === 'DELETE') {
              pendingNotes.splice(noteIdx, 1);
              this.currentCustomerNotes.splice(noteIdx, 1);
            } else if (event.action === 'SAVE') {
              note.noteText = event.updatedText;

              if (event.attachmentFileUpdated) {
                note.hasDocument = true;
                note.documentName = event.documentName;
                note.documentMimeType = event.documentMimeType;
                const b64StrSplitArray = event.attachmentFileDataUrl?.split(',');
                note.documentDataURL = Array.isArray(b64StrSplitArray)
                  && b64StrSplitArray.length > 1
                  ? b64StrSplitArray[1] : event.attachmentFileDataUrl || null;
              } else if (event.isAttachmentRemoved) {
                note.hasDocument = false;
                note.documentName = note.documentMimeType = '';
                note.documentSize = null;
              }
            }
          }
        } else {
          console.error('event-participant: updatePendingParticipantNote: not found: ', event, pendingNotes);
        }
      } catch (error) {
        console.error('event-participant: updatePendingParticipantNote: ', error);
      } finally {
        await this.uiService.dismissLoader();
      }
    }
  }

  private async closePage() {
    this.eventsToolService.pastEventAttachments = [];
    this.eventsToolService.annotation = null;
    this.navSvc.popChildNavPageWithPageTracking();
  }
  private handleAttachmentBtnClick() {
    this.attachInput?.nativeElement?.click();
  }

  private async handleSendForApprovalBtnClick() {
    try {
      await this.uiService.displayLoader();
      if (this.eventParticipant) {
        await this.saveReason();
      } else if (this.contact) {
        await this.savePendingReason();
      }
      this.initPageHeader();
      this.formFieldData[1].inputText = EventRegistrationStatus[this.indskr_reasons];
    }
    finally {
      this.notificationService.notify(`${this.translate.instant("NOTIFICATION_PARTICIPANT_FOR_APPROVAL")}`, "");
      await this.uiService.dismissLoader();
    }
  }

  private async saveReason() {
    let indskr_reasons = EventRegistrationStatus.Proposed;
    await this.dynamics.dwa.update(this.eventParticipant?.registrationId, 'msevtmgt_eventregistrations', { indskr_reasons });
    this.eventParticipant.indskr_reasons = indskr_reasons;
    this.indskr_reasons = indskr_reasons;
    this.eventsToolDataService.updateEventsData(
      {
        onDynamics: false,
        onLocalDatabase: true,
        onLocalCopy: true,
      },
      [this.eventActivity],
    );
  }

  private async savePendingReason() {
    let indskr_reasons = EventRegistrationStatus.Proposed;
    const contactId = this.contact?.ID;
    const details = this.eventsToolService.getPendingParticipantDetails(contactId);
    if (!details) {
      this.eventsToolService.createPendingParticipantDetails(contactId, { notes: this.currentCustomerNotes.slice(), indskr_reasons });
    } else {
      details.indskr_reasons = indskr_reasons;
    }
    this.indskr_reasons = indskr_reasons;
  }

  private async handleSaveNoteBtnClick() {
    if (!isEmpty(this.tempNoteText) || this.isAttachmentAdded) {
      if (this.eventParticipant) {
        await this.saveNote();
      } else if (this.contact) {
        await this.savePendingParticipantNote();
      }
    }
  }
  private async saveNote() {
    // const loader = await this.loadingCtrl.create();
    // await loader.present();
    await this.uiService.displayLoader();
    this.isSaveNotesEnabled = false;

    const now = new Date().getTime().toString();
    const payload: any = {
      eventregistrationid: this.eventParticipant.registrationId,
      notetext: this.tempNoteText,
      createdon: now,
      ownerName: this.authService.user.displayName,
      ownerid: this.authService.user.systemUserID,
    };

    if (this.base64str) {
      payload['isdocument'] = true;
      payload['documentbody'] = this.base64str;
      payload['filename'] = this.attachmentFile.name;
      // payload['filesize'] = this.attachmentFile.size;
      payload['mimetype'] = this.attachmentFile.type;
    }
    await this.fetchPastEventAttachment(payload);

    try {
      const response: any[] = await this.eventsToolDataService.createUpdateDeleteEventNote([payload]);
      if (Array.isArray(response) && response.length === 1) {
        const rawNote = response[0];
        if (rawNote.noteid) {
          payload.annotationid = rawNote.noteid;
          const newNote = new IONote(payload);
          if (!Array.isArray(this.eventParticipant.notes)) {
            this.currentCustomerNotes = this.eventParticipant.notes = [];
          }
          this.eventParticipant.notes.unshift(newNote);

          // Update local DB
          this.eventsToolDataService.updateEventsData(
            {
              onDynamics: false,
              onLocalDatabase: true,
              onLocalCopy: true,
            },
            [this.eventActivity],
          );

          // Reset form
          this.tempNoteText = '';
          this.removeAttachment(null);
          this.isSaveNotesEnabled = false;
        } else {
          console.error('handleSaveNoteBtnClick: noteid missing: ', response);
        }
      }
    } catch (error) {
      console.error('handleSaveNoteBtnClick: ', error);
      this.isSaveNotesEnabled = true;
    } finally {
      this.initSectionHeaders();
      await this.uiService.dismissLoader();
    }
  }
  private async savePendingParticipantNote() {
    try {
      await this.uiService.displayLoader();
      const now = new Date().getTime().toString();
      const payload: any = {
        annotationid: 'pending_note_' + now,
        eventregistrationid: null,
        notetext: this.tempNoteText,
        createdon: now,
        ownerName: this.authService.user.displayName,
        ownerid: this.authService.user.systemUserID,
      };

      if (this.base64str) {
        payload['isdocument'] = true;
        payload['documentbody'] = this.base64str;
        payload['filename'] = this.attachmentFile.name;
        // payload['filesize'] = this.attachmentFile.size;
        payload['mimetype'] = this.attachmentFile.type;
      }

      await this.fetchPastEventAttachment(payload);

      const note = new IONote(payload);
      this.currentCustomerNotes.unshift(note);
      const pendingNotes = this.eventsToolService.getPendingParticipantDetails(this.contact.ID)?.notes;
      if (!pendingNotes) {
        this.eventsToolService.createPendingParticipantDetails(this.contact.ID, {notes: [note], indskr_reasons: this.indskr_reasons});
      } else {
        pendingNotes.unshift(note);
      }

      // Reset form
      this.tempNoteText = '';
      this.removeAttachment(null);
      this.isSaveNotesEnabled = false;
    } catch (error) {
      console.error('event-participant: savePendingParticipantNote: ', error);
      this.isSaveNotesEnabled = true;
    } finally {
      this.initSectionHeaders();
      await this.uiService.dismissLoader();
    }
  }

  async fetchPastEventAttachment(payload) {
    let annotationId: string = '';
    if (this.eventsToolService.pastEventAttachments.length && this.isAttachmentAdded) {
      this.eventsToolService.pastEventAttachments.forEach(ev => {
        ev.attachments.forEach(at => {
          if (at.checked) {
            annotationId = at.annotationid;
          }
        })
      })
      if (annotationId) {
        await this.eventsToolDataService.getAnnotationById(annotationId).then(() => {
          payload['isdocument'] = true;
          payload['documentbody'] = this.eventsToolService.annotation['documentbody'];
          payload['filename'] = this.eventsToolService.annotation['filename'];
          payload['mimetype'] = this.eventsToolService.annotation['mimetype'];
        })
      }
    }
  }

}
