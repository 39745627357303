import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from 'rxjs';
import { DiskService } from '../disk/disk.service';
import * as _ from 'lodash';
import { AlertService } from '../alert/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { EventsService } from '../events/events.service';
import { PopoverController } from '@ionic/angular';
import { Currency, MarketingPlan } from '@omni/classes/marketing-management/marketing-plan.class';
import { AccountOfflineService } from "../account/account.offline.service";
import { ContactOfflineService } from "../contact/contact.service";
import { BrandOfflineService } from "../brand/brand.service";
import { SurgeryOrderActivityDataService } from "@omni/data-services/surgery-order-activity/surgery-order-activity.data.service";

/**
 * Offline data service for Marketing Buniness Plans Management
 *
 *
 */
@Injectable({
  providedIn: 'root'
})

export class MarketingPlansManagementOfflineService {
  public marketingPlans$: BehaviorSubject<MarketingPlan[]> = new BehaviorSubject([]);
  public selectedMarketingPlan$: BehaviorSubject<MarketingPlan> = new BehaviorSubject(null);
  public marketingPlans: MarketingPlan[] = [];
  public currencies : Currency[] = [];
  // public currencySymbol: string;
  // public selectedMode: MarketingPlanMode;
  public isRightPaneNavActive: boolean = false;
  // public currentMarketingBusinessPlan: MarketingPlan;
  // public tempReqPayload: any;

  constructor(
    public disk: DiskService,
    public alertService: AlertService,
    public translate: TranslateService,
    public events: EventsService,
    public popoverCtrl: PopoverController,
    private accountService :AccountOfflineService,
    private contactService:ContactOfflineService,
    private readonly brandService: BrandOfflineService,
    private surgeryOrderActivityDataService: SurgeryOrderActivityDataService

  ) { }


  public getMarketingBuninessPlanByPlanId(id: string): MarketingPlan {
    let marketingPlan: MarketingPlan = undefined;
    marketingPlan = this.marketingPlans.find(ap => ap.ID == id);
    return marketingPlan;
  }

  sortListByFielName(options, fieldName: string) {
    if (_.isEmpty(options)) return [];
    return options.sort((a, b) => {
      let nameA: string = a[fieldName], nameB: string = b[fieldName];
      if (!nameA || !nameB) return 1;
      nameA = nameA.toLowerCase();
      nameB = nameB.toLowerCase();
      if (nameA < nameB)
        return -1;
      if (nameA >= nameB)
        return 1;
    });
  }

  isProductMappedToUser(productId) {
    // products
    let productExists = this.brandService.brands.some(({ ID }) => ID === productId);
    if (productExists) return true;

    // product bundle
    if (this.surgeryOrderActivityDataService.procedureTrackerProductHierarchies.length > 0) {
      productExists = this.surgeryOrderActivityDataService.procedureTrackerProductHierarchies.some(({ surgeryId }) => surgeryId === productId);
    }
    return productExists;
  }

  isAccountMappedToUser(accountId): boolean {
    return this.accountService.accounts.some(({ id }) => id == accountId);
  }

  isContactMappedToUser(contactId){
    const foundContact = this.contactService.getContactByID(contactId);
    return foundContact != undefined;
  }

  getCurrencyById(id: string): Currency {
    let currency: Currency;

    currency = this.currencies?.find(c => c.id === id);

    return currency;
  }
}