import { InMeetingAllocationComponent } from './../../in-meeting-allocation/in-meeting-allocation';
import { ModalController, PopoverController } from '@ionic/angular';
import { EventsToolService } from '@omni/services/events-tool/events-tool.service';
import { debounceTime, first, takeUntil } from 'rxjs/operators';
import { CallPlanOfflineService } from '@omni/services/call-plan/call-plan.offline.service';
import {
  AfterContentChecked,
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { ContactOfflineService } from '@omni/services/contact/contact.service';
import { ComponentViewMode, UIService } from '@omni/services/ui/ui.service';
import { DeviceService } from '@omni/services/device/device.service';
import { LoadingController, NavParams } from '@ionic/angular';
import { AuthenticationService } from '@omni/services/authentication.service';
import { TrackingEventNames, TrackService } from '@omni/services/logging/tracking.service';
import { ChildNavNames, NavigationService, PageName } from '@omni/services/navigation/navigation.service';
import { FooterService, FooterViews } from '@omni/services/footer/footer.service';
import { addMinutes } from 'date-fns';
import {
  InitiateMeetingPayload,
  MeetingDataService,
  UpdateMeetingPayload
} from '../../../data-services/meeting/meeting.data.service';
import { ActivityDataService } from '../../../data-services/activity/activity.service';
import { ActivityService, ActivitySource } from '@omni/services/activity/activity.service';
import { AppointmentActivity, OFFLINE_ID_PREFIX } from '../../../classes/activity/appointment.activity.class';
import { ActivitiesDetailsPaneComponent } from '../../activity/activities-details-pane/activities-details-pane';
import { RepServices } from '../../../data-services/rep/rep.services';
import { FeatureActionsMap } from '../../../classes/authentication/user.class';
import { ScientificActivityPlan } from '../../../classes/scientific-activity/scientific-activity.class';
import { NewActivityComponent } from '../../activity/new-activity/new-activity';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService, ToastStyle } from '@omni/services/notification/notification.service';
import { ContactDataService } from '../../../data-services/contact/contact.data.service';
import { CustomerEventsDataService } from '../../../data-services/customer-event/customer-events.data.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Contact } from '../../../classes/contact/contact.class';
import { IndTabsDataModel } from '@omni/models/ind-tabs-data-model';
import { MeetingStructureService } from '@omni/services/meeting-structure/meeting-structure.service';
import { DynamicFormsService } from '@omni/services/dynamic-forms/dynamic-forms-service';
import { EventsService } from '@omni/services/events/events.service';
import { ContactConsentCaptureComponent } from '../contact-consent-capture/contact-consent-capture';
import { ContentMatchingService } from '@omni/services/content-matching/content-matching.service';
import { ContactPageComponent } from '../contact-page/contact-page';
import { NothingSelectedView } from '@omni/components/shared/nothing-selected-view/nothing-selected-view';
import { AffiliationExplorerComponent } from '@omni/components/shared/affiliation-explorer/affiliation-explorer';
import { DiskService } from '@omni/services/disk/disk.service';
import { CustomerAssessService } from '@omni/services/customer-assess/customer-assess.service';
import { CustomerAssessment } from '@omni/classes/customer-assessment/customer-assessment.class';
import { AssessmentTemplate, EntityOptions, TemplateType } from '@omni/classes/customer-assessment/assessment-template.class';
import _ from 'lodash';
import { AccountOfflineService } from '@omni/services/account/account.offline.service';
import { IndDropdownListDetailModel } from '@omni/models/indDropdownListModel';
import { SelectListData } from '@omni/components/popover/popover';
import { IndDropdownListComponent } from '@omni/components/shared/ind-dropdown-list/ind-dropdown-list';
import { Guid } from 'typescript-guid';
import { EventActivity, EventParticipant } from '@omni/classes/events-tool/event.class';
import { Brand } from '@omni/classes/brand/brand.class';
import { EventParticipantComponent } from '@omni/components/event-participant/event-participant';
import { AttendeeFormat } from '@omni/enums/event/event.enum';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { electronApi } from "@omni/services/electron-api";
import { TerritoryManagementPageComponent } from '@omni/pages/territory-management-page/territory-management-page.component';
import { TerritoryManangementListDetailsComponent } from '@omni/components/territory-management/territory-manangement-list-details/territory-manangement-list-details';
import { TerritoryManagementDetailsComponent } from '@omni/components/territory-management/territory-management-details/territory-management-details';
import { TerritoryManagementTabs } from '@omni/components/territory-management/territory-management-list/territory-management-list';
import { HopQRCodeModal } from '../hop-qr-code-modal/hop-qr-code-modal';
import { MdmService } from '@omni/services/mdm/mdm.service';

/**
 * Generated class for the ContactDetailsComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'contact-details',
  templateUrl: 'contact-details.html',
  styleUrls:['contact-details.scss']
})
export class ContactDetailsComponent implements OnInit, AfterViewInit, AfterContentChecked {

  public contactSegment:string = 'info';
  public compViewMode = ComponentViewMode;
  public isAutoSubjectEnabled: boolean = false;
  public isConsentToolEnabled: boolean = false;
  @Input() selectedSP:ScientificActivityPlan;
  public isCustomerEventsEnabled : boolean = false;
  public isEventToolfeatureAction: boolean = false;
  public hasCustomerAssessFA: boolean = false;

  @Input()
  contactListMode:string;
  accessedFrom: string;
  callbackEvent: any;
  @Output() editContactHandler = new EventEmitter();
  @Output() openScientificPlanDetails:EventEmitter<ScientificActivityPlan> = new EventEmitter();
  @ViewChild('contactAssessment') contactAssessment;
  @ViewChild('contactAssessmentTeamview') contactAssessmentTeamview;
  //@ViewChild(Content, {static:true}) content: Content;
  pageName = PageName;

  @ViewChild(ContactConsentCaptureComponent) ccCapture: ContactConsentCaptureComponent;
  isEventParticipantNoteEnabled = false;
  private isEventPassParticipantAssociationEnabled = false;
  private eventNoteModalHandle: any;

  private unsubscribe$ = new Subject<void>();
  public contactInfo: Contact;
  hasPhoneCallEnabled: boolean = false;
  public isPopPageBtnEnabled:boolean = false;
  public pageTitleControls = [];
  public okPageTitleControls = [];
  public contactRelatedToContact:any[]=[];
  public contactToAccount:any[]=[];
  public contactRelatedFromContact:any[]=[];
  private scrollBehaviorSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  public readonly scrollObservable: Observable<any> = this.scrollBehaviorSubject.asObservable().pipe(debounceTime(100));
  private contactHighLightedFromAffiliatedExplorer: boolean = false;
  public contactAssessmentTemplates: AssessmentTemplate[] = [];
  public assessRawData: CustomerAssessment;
  public customerAssessmentIsLoading: boolean = true;
  private _isDisabledGoToContactsToolBtn: boolean = false;
  public isGoToContactBtnEnabled: boolean = false;
  private ngDestroy$ = new Subject<boolean>();
  //assess teamview
  private isTeamViewEnabled: boolean = false;
  public customerAssessmentTeamviewIsLoading: boolean = true;
  public contactAssessmentTemplatesTeamview: AssessmentTemplate[] = [];
  public showQrCodeBtn: boolean = false;
  public enableEditBtn: boolean = false;
  public buLine: number = 0;
  constructor(public contactService: ContactOfflineService,
              private contactDataService: ContactDataService,
              public device:DeviceService,
              public repService: RepServices,
              public uiService:UIService,
              private authenticationOfflineService: AuthenticationService,
              public footerService:FooterService,
              public trackingService: TrackService,
              private navService: NavigationService,
              private loadingCtrl: LoadingController,
              private meetingService: MeetingDataService,
              private activityDataService: ActivityDataService,
              public notificationService: NotificationService,
              private activityService: ActivityService,
              public callPlanService: CallPlanOfflineService,
              public customerEventDataService: CustomerEventsDataService,
              private eventsToolService: EventsToolService,
              private translate:TranslateService,
              public events: EventsService,
              private meetingStructureService: MeetingStructureService,
              private dynamicFormService: DynamicFormsService,
              private contentMatchService: ContentMatchingService,
              private disk: DiskService,
              private customerAssessService: CustomerAssessService,
              private accountService: AccountOfflineService,
              private navParams: NavParams,
              private modalCtrl: ModalController,
              private readonly popoverCtrl: PopoverController,
              private inAppBrowser: InAppBrowser,
              private readonly mdmService: MdmService,
            ) {
              console.log(this.contactListMode);
  }

  ngOnInit() {
    this.buLine = this.authenticationOfflineService.user?.buConfigs?.indskr_businessline; 
    this.getActiveContsent();
    this.hasPhoneCallEnabled = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.PHONECALL_ACTIVITY) ? true : false;
    if(this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.CONSENT_TOOL)){
      this.isConsentToolEnabled = true;
    }
    this.hasCustomerAssessFA = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.CUSTOMER_ASSESS);
    this.isTeamViewEnabled = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.ASSESSMENTS_TEAM_VIEW);
    if (this.contactService.accessedContactListFrom === PageName.EventDetailsPageComponent) {
      if (this.eventsToolService.selectedEventOnEventsTool) {
        const participant = this.eventsToolService.getEventParticipantByContactId(this.eventsToolService.selectedEventOnEventsTool.participants, this.contactService.contactInformation.ID);
        this.isEventParticipantNoteEnabled = (
          !Array.isArray(this.eventsToolService.selectedEventOnEventsTool.participants)
          || !this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.EVENT_PARTICIPANT_NOTES)
        )
          ? false
          : participant ? true : false;
        this.isEventPassParticipantAssociationEnabled = (
          !_.isEmpty(this.eventsToolService.selectedEventOnEventsTool.participants)
          || !this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.EVENT_PASS_PARTICIPANT_ASSOCIATION))
          ? false : participant ? true : false;
      }

      this.events.observe('selectedEventParticipantChanged')
        .pipe(takeUntil(this.ngDestroy$))
        .subscribe(({selectedContacts, contact}: {selectedContacts: Contact[], contact: Contact}) => {
          if (Array.isArray(selectedContacts)) {
            this.isEventParticipantNoteEnabled = (
              !Array.isArray(selectedContacts)
              || !this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.EVENT_PARTICIPANT_NOTES)
            )
              ? false
              : selectedContacts.some(c => c.ID === this.contactService.contactInformation.ID) ? true : false;
            this.isEventPassParticipantAssociationEnabled = (
              !Array.isArray(selectedContacts)
              || !this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.EVENT_PASS_PARTICIPANT_ASSOCIATION)
            )
              ? false
              : selectedContacts.some(c => c.ID === this.contactService.contactInformation.ID) ? true : false;
            this.initPageTitleControls();
          }
          if (this.isEventParticipantNoteEnabled && this.navParams.data.contact.ID == contact.ID && this.eventsToolService.selectedEventOnEventsTool.attendeeFormat === AttendeeFormat.propose) {
            this.openEventParticipantDetailsForm(this.eventsToolService.selectedEventOnEventsTool);
          }
        });
    }

    this.footerService.initButtons(FooterViews.Contacts);

    this.setTabsData();
    
    if(this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.EVENT_REGISTRATION)){
      this.isCustomerEventsEnabled = true;
    } if (this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.EVENT_TOOL)) {
      this.isEventToolfeatureAction = true;
    }
    this.contactService.contactInformationObservable.pipe(takeUntil(this.unsubscribe$)).subscribe(async data=> {
      if(data && (!this.contactInfo || (this.contactInfo && this.contactInfo.ID != data.ID))) {
        this.fetchContactProfile();
  
        if (this.contactService.isAffiliationEnabled) {
          this.contactRelatedToContact = await this.contactService.getContactRelatedToByContactId(data.ID);
          this.contactToAccount = await this.contactService.getContactToAccountByContactId(data.ID);
          this.contactRelatedFromContact = await this.contactService.getContactRelatedFromByContactId(data.ID);
        }
        this.initPageTitleControls();
        this.initCustomerAssessData(data);
        this.contactService.contactUpdated.next(true);
      }
      this.contactInfo = data;
      if (!this.uiService.isPlusButtonCickedFromPatricipantsInEventDetails) return;
      this.uiService.isPlusButtonCickedFromPatricipantsInEventDetails = false;
    });
    this.events.observe("affilition:updated")
      .subscribe(async () => {
        this.contactRelatedToContact = await this.contactService.getContactRelatedToByContactId(this.contactInfo.ID);
        this.contactToAccount = await this.contactService.getContactToAccountByContactId(this.contactInfo.ID);
        this.contactRelatedFromContact = await this.contactService.getContactRelatedFromByContactId(this.contactInfo.ID);
        this.initPageTitleControls();
      });
    if(this.navService.getActiveChildNavViewPageName() === PageName.ScientificActivityDetail ||
       this.navService.getActiveChildNavViewPageName() === PageName.CallPlanCustDetailsComponent ||
      (this.navService.getCurrentMasterPageName() == PageName.ContactPageComponent && this.uiService.activeView == 'ReadOnlyContact' && this.activityService.selectedActivity) ||
      (this.navService.getCurrentMasterPageName() == PageName.ActivitiesPageComponent && this.uiService.activeView == 'ReadOnlyContact' && this.activityService.selectedActivity) ||
      this.navService.getCurrentMasterPageName() === PageName.ContactListROComponent ||
      (this.navService.getCurrentMasterPageName() == PageName.ActivitiesPageComponent && this.uiService.activitiesPagePlanTabSelectedPlan ==='goalsPlansTab') ||
      (this.navService.getCurrentMasterPageName() == PageName.CallPlanComponent && this.uiService.activeView == 'CallPlanDetails') ||
      this.navService.getCurrentMasterPageName() === PageName.ShortCallHomeComponent ||
      this.navService.getActiveChildNavViewPageName() === PageName.AccountDetailsComponent )  {
      this.isPopPageBtnEnabled = true;
    }
    if ((this.dynamicFormService.isOpenedAffiliatedContactOnContact && this.navService.getActiveChildNavViewPageName() === PageName.ContactDetailsComponent)
      || (this.dynamicFormService.isOpenedAffiliatedContactOnAccount && this.navService.getActiveChildNavViewPageName() === PageName.AccountDetailsComponent)) {
      this.contactService.isEnableGoToContactsTool = true;
      this.isPopPageBtnEnabled = true;
    }
    this.events.observe('refreshContactDetailPageTitle').pipe(first()).subscribe(() => {
      if ((this.dynamicFormService.isOpenedAffiliatedContactOnContact && this.navService.getActiveChildNavViewPageName() === PageName.ContactDetailsComponent)
      || (this.dynamicFormService.isOpenedAffiliatedContactOnAccount && this.navService.getActiveChildNavViewPageName() === PageName.AccountDetailsComponent)
      || this.contactService.contactPageMode == ComponentViewMode.PREVIEW && this.navService.getActiveChildNavViewPageName() === PageName.ContactDetailsComponent
      ) {
        this.contactService.isEnableGoToContactsTool = true;
        this.isPopPageBtnEnabled = true;
      } else if (this.navService.getCurrentMasterPageName() === PageName.ShortCallHomeComponent) {
        this.contactService.isEnableGoToContactsTool = false;
        this.isPopPageBtnEnabled = true;
      } else {
        this.contactService.isEnableGoToContactsTool = false;
        this.isPopPageBtnEnabled = false;
      }
      this.initPageTitleControls();
    });
    this.events.observe('refreshContactDetailPageTitleOnly').pipe(takeUntil(this.ngDestroy$)).subscribe(() => {
      this.initPageTitleControls();
    });
    this.initPageTitleControls();
    this.initOKPageTitleControls();
    this.device.isOfflineObservable.pipe(takeUntil(this.ngDestroy$)).subscribe(isOffline => {
      // if the device offlice diisable
      this.initPageTitleControls();
    });
  }

  async getActiveContsent(){
    if (!this.device.isOffline) {
      const consent = this.enableEditBtn = await this.meetingService.getActiveGeneralConsent(this.contactService.contactInformation.ID);
      this.showQrCodeBtn = !consent;
      this.initPageTitleControls();
    }
  }

  private initCustomerAssessData(data: Contact) {
    if (this.hasCustomerAssessFA) {
      if (!this.contactService.getContactByID(data.ID)) {
        this.customerAssessmentIsLoading = false;
        this.customerAssessmentTeamviewIsLoading = false;
      } else {
        this.contactAssessmentTemplates = this.customerAssessService.getTemplatesForEntity(TemplateType.ASSESSMENT, EntityOptions.CONTACT);
        if(this.isTeamViewEnabled) this.contactAssessmentTemplatesTeamview = this.customerAssessService.getTemplatesTeamviewForEntity(TemplateType.ASSESSMENT, EntityOptions.CONTACT);
        if(!this.device.isOffline) {
          this.fetchAssessRawData(data);
        }else {
          // this.fetchOfflineAssessRawData(data);
        }
      }
    }
  }

  private async fetchAssessRawData(data: Contact) {
    if (this.contactAssessmentTemplates.length == 1) {
      const isProcedureSectionEnabled: boolean = this.contactAssessmentTemplates[0].indskr_metadata.Sections.some(section => section.Procedure) && data.primarySpecialty?.id != null;
      const procedures = isProcedureSectionEnabled ? this.customerAssessService.specialtyProceduresMap.get(data.primarySpecialty.id) : [];
      this.customerAssessService.fetchContactAssessment(data.ID, this.contactAssessmentTemplates[0].indskr_assessmenttemplateid).then((data: CustomerAssessment) => {
        if (isProcedureSectionEnabled && data?.responses) {
          if (_.isEmpty(procedures)) {
            data.responses = data.responses.filter(resp => resp['indskr_procedures'] == null);
          } else {
            const procedureIds = procedures.map(procedure => procedure.ID);
            data.responses = data.responses.filter(resp => resp['indskr_procedures'] == null || procedureIds.includes(resp['indskr_procedures']))
          }
        }
        this.assessRawData = data;
        this.customerAssessmentIsLoading = false;
        this.customerAssessmentTeamviewIsLoading = false;
      });
    } else {
      this.customerAssessmentIsLoading = false;
      this.customerAssessmentTeamviewIsLoading = false;
    }
  }

  // private async fetchOfflineAssessRawData(data: Contact) {
  //   if(this.contactAssessmentTemplates.length == 1) {
  //     const isProcedureSectionEnabled: boolean = this.contactAssessmentTemplates[0].indskr_metadata.Sections.some(section => section.Procedure) && data.primarySpecialty?.id != null;
  //     const procedures = isProcedureSectionEnabled ? this.customerAssessService.specialtyProceduresMap.get(data.primarySpecialty.id) : [];
  //     this.customerAssessService.fetchOfflineContactAssessment(data.ID).then((data: CustomerAssessment) => {
  //       if (isProcedureSectionEnabled && data?.responses) {
  //         if (_.isEmpty(procedures)) {
  //           data.responses = data.responses.filter(resp => resp['indskr_procedures'] == null);
  //         } else {
  //           const procedureIds = procedures.map(procedure => procedure.ID);
  //           data.responses = data.responses.filter(resp => resp['indskr_procedures'] == null || procedureIds.includes(resp['indskr_procedures']))
  //         }
  //       }
  //       this.assessRawData = data;
  //       this.customerAssessmentIsLoading = false;
  //       this.customerAssessmentTeamviewIsLoading = false;
  //     });
  //   }else {
  //     this.customerAssessmentIsLoading = false;
  //     this.customerAssessmentTeamviewIsLoading = false;
  //   }
  // }

  public isContactAssessmentFormDirty(isDirty: boolean) {
    const index = this.pageTitleControls.findIndex(button => button.id == 'save');
    if (index > -1) {
      this.pageTitleControls[index].isDisabled = !isDirty;
    }
  }

  public savedCustomerAssessment(data: CustomerAssessment) {
    this.assessRawData = data;
    this.initPageTitleControls();
  }

  ngOnDestroy() {
    if (this.accountService.accountPageMode === ComponentViewMode.PREVIEW) this.accountService.accountPageMode = ComponentViewMode.LISTVIEW;
    this.dynamicFormService.isOpenedAffiliatedContactOnAccount = false;
    this.dynamicFormService.isOpenedAffiliatedAccountOnAccount = false;
    this.dynamicFormService.isOpenedAffiliatedAccountOnContact = false;
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }

  fetchContactProfile() {
    this.contactDataService.getContactProfile(this.contactService.contactInformation)
      .then(() => {
        this.contactService.isContactProfileLoaded = true;
        console.log('Called updateProfileData event')
        this.events.publish('updateProfileData')
      })
      .catch(() => {
        // Even in case of error, show offline only data
        this.contactService.isContactProfileLoaded = true;
    });
  }


  // ionViewDidEnter(){
  //   this.footerService.initButtons(FooterViews.Contacts);
  // }
  ngAfterViewInit(){
    this.contactSegment = this.uiService.contactDetailsSegment ||'info';
   // this.content.resize();
  }

  ngAfterContentChecked() {
    if(this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.MEETING_AUTO_SUBJECT)){
      this.isAutoSubjectEnabled = true;
    }
  }

  async segmentChanged(selectedTab: string) {
    if (this.hasCustomerAssessFA) {
      if (this.uiService.contactDetailsSegment == "customer-assess" && this.customerAssessService.isAssessmentDirty && !(await this.customerAssessService.discardChagesForAssess())) {
        return;
      }
    }
    this.uiService.contactDetailsSegment = selectedTab;
    if (selectedTab === 'profile') {
      const insightsEventName = this.contactService.contactPageMode === ComponentViewMode.ADDNEW ? 'Contact Profile via Meeting' : 'ContactProfile'
      this.trackingService.tracking(insightsEventName, TrackingEventNames.CONTACTS)
      this.contactService.isContactProfileLoaded = false;
    } else if (selectedTab === 'timeline') {
      const insightsEventName = this.contactService.contactPageMode === ComponentViewMode.ADDNEW ? 'Contact Timeline  via Meeting' : 'ContactTimeline'
      this.trackingService.tracking(insightsEventName, TrackingEventNames.CONTACTS)
    } else if (selectedTab === 'capture') {
      this.footerService.initButtons(FooterViews.ConsentCapture);
    } else {
      this.footerService.initButtons(FooterViews.Contacts);
    }
    this.initPageTitleControls();
  }

  async closepage(){
    if(this.customerAssessService.isAssessmentDirty && !(await this.customerAssessService.discardChagesForAssess())) return;
    if(this.navService.getCurrentMasterPageName() == PageName.CallPlanComponent){
      // this.callPlanService.callPlanPageView = "CallPlanCustomerDetail";
      this.uiService.activeView = 'CallPlanDetails';
      this.navService.popChildNavPageWithPageTracking();
    } else if(this.navService.getCurrentMasterPageName() == PageName.ActivitiesPageComponent && this.uiService.activitiesPageTab == 'plans') {
      this.uiService.activeView = 'ActivitiesPageRightPaneNav';
      this.uiService.showNewActivity = false;
      this.uiService.showRightPane = true;
      this.navService.popChildNavPageWithPageTracking();
    } else if (this.navService.getCurrentPageName() == PageName.ContactPageComponent && this.navService.getPreviousPageName() == PageName.ConsentDetailsComponent) {
      this.contactService.isConsentInvoked = false;
      this.footerService.initButtons(FooterViews.ConsentDetails);
      this.uiService.activeView = this.uiService.prevView;
      await this.navService.popWithPageTracking();
      if(this.uiService.consentFlipButton) {
        this.uiService.isEmailAccessedFromConsnentDetails = true;
      }
      return;
    } else {
      this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView, PageName.ContactPageComponent);
      this.navService.setChildNavRightPaneView(false)
    }
    this.contactService.contactInformation = undefined;
  }

  poppage(){
    this.isPopPageBtnEnabled = false;
    if(this.navService.getActiveChildNavName() === 'scientificactivityrightpane'){
      this.navService.popToRootChildNavPageWithPageTracking();
      this.contactService.contactInformation = undefined;
    } else if(this.navService.getCurrentMasterPageName() == PageName.ActivitiesPageComponent && this.uiService.activitiesPageTab == 'plans') {
      this.uiService.activeView = 'ActivitiesPageRightPaneNav';
      this.uiService.showNewActivity = false;
      this.uiService.showRightPane = true;
      this.contactService.contactInformation = undefined;
      this.navService.popChildNavPageWithPageTracking();
    } else if(this.navService.getCurrentMasterPageName() == PageName.ContactPageComponent) {
      if (this.contactService.contactPageMode == ComponentViewMode.PREVIEW || this.dynamicFormService.isOpenedAffiliatedContactOnContact) {
        if (this.contactService.prevSelected) this.contactService.contactInformation = this.contactService.prevSelected;
        this.events.publish('highlightContact', this.contactService.contactInformation);
        if (this.contactService.contactPrevPageMode == ComponentViewMode.ADDNEW || this.contactService.contactPrevPageMode == ComponentViewMode.SELECTION) {
          this.contactService.contactPageMode = this.contactService.contactPrevPageMode;
        } else {
          this.contactService.contactPageMode = ComponentViewMode.LISTVIEW;
        }
        this.dynamicFormService.isOpenedAffiliatedContactOnContact = false;
        this._isDisabledGoToContactsToolBtn = false;
        this.events.publish('refreshDisplayForm');
      } else {
        this.uiService.showRightPane = false;
        this.contactService.contactInformation = undefined;
      }
    } else if(this.navService.getCurrentMasterPageName() == PageName.CallPlanComponent){
      this.uiService.activeView = 'CallPlanDetails';
      this.navService.popChildNavPageWithPageTracking();
    } else if(this.navService.getCurrentMasterPageName() == PageName.AccountPageComponent){
      if (this.dynamicFormService.isOpenedAffiliatedContactOnAccount) {
        if (this.contactService.contactPrevPageMode === ComponentViewMode.ADDNEW || this.contactService.contactPrevPageMode === ComponentViewMode.SELECTION) {
          this.contactService.contactPageMode = this.contactService.contactPrevPageMode;
        } else {
          this.contactService.contactPageMode = ComponentViewMode.LISTVIEW;
        }
        this.dynamicFormService.isOpenedAffiliatedContactOnAccount = false;
        this._isDisabledGoToContactsToolBtn = false;
        this.events.publish('refreshDisplayForm');
        this.navService.popChildNavPageWithPageTracking();
      } else if (this.dynamicFormService.isOpenedAffiliatedContactOnContact) {
        if (this.contactService.prevSelected) this.contactService.contactInformation = this.contactService.prevSelected;
        this.events.publish('highlightContact', this.contactService.contactInformation);
        if (this.contactService.contactPrevPageMode === ComponentViewMode.ADDNEW || this.contactService.contactPrevPageMode === ComponentViewMode.SELECTION) {
          this.contactService.contactPageMode = this.contactService.contactPrevPageMode;
        } else {
          this.contactService.contactPageMode = ComponentViewMode.LISTVIEW;
        }
        this.dynamicFormService.isOpenedAffiliatedContactOnContact = false;
        this._isDisabledGoToContactsToolBtn = false;
        this.events.publish('refreshDisplayForm');
      } else {
        this.navService.popChildNavPageWithPageTracking();
      }
    } else if (this.dynamicFormService.isOpenedAffiliatedContactOnAccount) {
      if (this.contactService.contactPrevPageMode === ComponentViewMode.ADDNEW || this.contactService.contactPrevPageMode === ComponentViewMode.SELECTION) {
        this.contactService.contactPageMode = this.contactService.contactPrevPageMode;
      } else {
        this.contactService.contactPageMode = ComponentViewMode.LISTVIEW;
      }
      this.dynamicFormService.isOpenedAffiliatedContactOnAccount = false;
      this._isDisabledGoToContactsToolBtn = false;
      this.events.publish('refreshDisplayForm');
      this.navService.popChildNavPageWithPageTracking();
    } else if (this.navService.getCurrentMasterPageName() === PageName.ShortCallHomeComponent) {
      this.uiService.contactDetailsSegment = undefined
      this.contactService.contactInformation = undefined;
      this.contactService.contactPageMode = undefined;
      this.navService.popWithPageTracking();
    } else{
      this.navService.popWithPageTracking();
    }
  }

  handlePlusButton(){
    this.activityService.activitySource = ActivitySource.CONTACT_INFO;
    this.trackingService.tracking('ContactNewActivity', TrackingEventNames.ACTIVITY)
    if(this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.ALLOCATION_TOOL)
    || this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.FOLLOW_UP_ACTION_ACTIVITY)
    || this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.SCIENTIFIC_FOLLOW_UP_TASK)
    || this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.CASE_INTAKE)
    || this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.MESSAGE_ACTIVITY)
    || this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.PROCEDURE_LOG)
    ){
      // sets the default selected activity to meeting
      this.uiService.activeViewNewMeeting = 'meeting';
      if(this.navService.getActiveChildNavName() === "scientificactivityrightpane" || this.navService.getActiveChildNavName() === ChildNavNames.ActivitiesPageNavigation && this.uiService.activitiesPageTab === 'plans' ){
        this.navService.pushChildNavPageWithPageTracking(NewActivityComponent, PageName.NewActivityComponent, PageName.ScientificActivityPage,
                                                        { from: 'ScientificActivityPage', plan: this.selectedSP, contact: this.contactService.contactInformation})
      } else {
        this.navService.pushChildNavPageWithPageTracking(NewActivityComponent, PageName.NewActivityComponent, PageName.ContactPageComponent,
          { from: 'ContactPageComponent', contact: this.contactService.contactInformation});
      }
    }
    else{
      this.addNewActivity()
    }
  }

  async handleNavigationForBaseURL() {
    let url:string = this.contactService.baseURLforContactsTool;
    url = url.replace('{{contact.guid}}', this.contactInfo.ID);

    if (this.device.isNativeApp && !this.device.deviceFlags.electron) {
      if (this.device.deviceFlags.ios) {
        const browserRef = this.inAppBrowser.create(url, '_blank', { location: 'no', hideurlbar: 'yes', zoom: 'no' });
      }
      else {
        const browserRef = this.inAppBrowser.create(url, '_blank', { location: 'yes', hideurlbar: 'yes', zoom: 'no' });
      }
    } else {
      if(this.device.deviceFlags.electron) {
        electronApi.openExternal(url);
      }else {
        window.open(url, "_system");
      }
    }
  }

  async handleAffiliationExplorer() {
    this.navService.pushChildNavPageWithPageTracking(AffiliationExplorerComponent, PageName.AffiliationExplorerComponent, PageName.ContactPageComponent,
      {
        from: 'ContactPageComponent', parent: this.contactService.contactInformation,
        relatedTo: this.contactRelatedToContact, contactAccountAffiliation: this.contactToAccount,
        relatedFrom: this.contactRelatedFromContact
      });
   }

  async addNewActivity(){
    event.stopPropagation();

    //We don't show the new activity screen, we just insta create it.

    //Start a loader while we wait for new meeting
    let loader =await this.loadingCtrl.create();
    loader.present();

    //Going to need a payload for initiation
    let startTime, endTime;
    startTime = new Date();
    endTime = new Date();
    endTime = addMinutes(startTime, 30);

    //Add contact to new appointment activity
    let contact = this.contactService.getContactByID(
      this.contactService.contactInformation["ID"]
    );
      let subject:string = `${contact.fullName} - Meeting`;

    if (this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.MEETING_AUTO_SUBJECT)) {
      subject = `${contact.fullName} - Meeting - ${this.authenticationOfflineService.user.displayName}`;
    }

    let payload: InitiateMeetingPayload = new InitiateMeetingPayload(subject,"",startTime.getTime(),endTime.getTime(),"", OFFLINE_ID_PREFIX + new Date().getTime());

    let response = await this.meetingService.createNewMeeting(payload, false);
    this.activityService.selectedActivity = response;

    if (contact) {
      if (
        this.activityService.selectedActivity instanceof AppointmentActivity
      ) {
        this.activityService.selectedActivity.contacts.push(contact);

        const requestSuccess = await this.meetingService.addContactsToMeeting(this.activityService.selectedActivity, true);
        this.contentMatchService.isNewContactAddedToMeeting = true;
        // TODO: Only hybrid app does offline stuff in future
        if (!requestSuccess/* && this.device.isHybridApp*/) {
          await this.activityService.upsertMeetingsOfflineData(this.activityService.selectedActivity, true);
        }
        if(contact.addressesList && contact.addressesList.length){
          const primaryAddress = contact.addressesList.find((address) => address.isPrimary).compositeAdd;
          this.activityService.selectedActivity.location = primaryAddress;
          let payload = new UpdateMeetingPayload(
          this.activityService.selectedActivity.subject,
           this.activityService.selectedActivity.location,
          this.activityService.selectedActivity.scheduledStart,
          this.activityService.selectedActivity.scheduledEnd
          );
          if (this.device.isOffline || this.activityService.hasOfflineMeetingData(this.activityService.selectedActivity.ID)){
            await this.activityService.upsertMeetingsOfflineData(this.activityService.selectedActivity as AppointmentActivity);
          } else{
            try {
              await this.meetingService.updateMeeting((this.activityService.selectedActivity as AppointmentActivity), payload);
            } catch (error) {
              console.error('ContactDetailsComponent: addNewActivity: ', error);
              this.activityService.upsertMeetingsOfflineData(this.activityService.selectedActivity as AppointmentActivity, false, true);
            }
          }
         }
      }
    } else {
      this.notificationService.notify(this.translate.instant('ERROR_FINDING_CONTACT_TO_ADD_TO_MEETING'),'Contact Details');
    }

    this.meetingStructureService.createEmbeddedInteractions(<AppointmentActivity>this.activityService.selectedActivity);

    this.navService.pushChildNavPageWithPageTracking(ActivitiesDetailsPaneComponent, PageName.ActivitiesDetailsPaneComponent, PageName.ContactPageComponent);
    loader.dismiss();
    this.activityDataService.activityDetailsLoaded = true;
  }
  isSplitView(){
    return (window.innerWidth > 500);
  }

  onEditContact(){
    this.editContactHandler.emit();
  }

  onOpenScientificPlanDetails(plan){
    this.openScientificPlanDetails.emit(plan);
  }

  setTabsData() {
    this.uiService.tabsData = [
      {
        displayText: this.translate.instant('INFO'),
        value: "info"
      },
      {
        displayText: this.translate.instant('ASSESS'),
        value: "customer-assess",
        hide: !this.hasCustomerAssessFA || (!(!this.uiService.isConsentFromToolDrawer || this.contactService.accessedContactListFrom !== this.pageName.ToolsDrawer)),
      },
      {
        displayText: this.translate.instant('PROFILE'),
        value: "profile",
        hide: !((!this.uiService.isConsentFromToolDrawer || this.contactService.accessedContactListFrom !== this.pageName.ToolsDrawer)  && !this.contactService.isInGlobalSearch),
        disable: this.contactService.isOneKeySearching || (this.navParams.data && this.navParams.data.from == PageName.TerritoryManagementDetailsComponent)
      },
       {
        displayText: this.translate.instant('CONSENTS'),
        value: "consent",
        hide: (!((this.uiService.isConsentFromToolDrawer && this.contactService.accessedContactListFrom === this.pageName.ToolsDrawer) && this.isConsentToolEnabled) || this.contactService.isOneKeySearching),
         disable: this.contactService.isOneKeySearching
       },
       {
        displayText: this.translate.instant('CAPTURE'),
        value: "capture",
        hide: (!((this.uiService.isConsentFromToolDrawer && this.contactService.accessedContactListFrom === this.pageName.ToolsDrawer) && this.isConsentToolEnabled) || this.contactService.isOneKeySearching)
       },
       {
        displayText: this.translate.instant('TIMELINE'),
        value: "timeline",
        disable: this.contactService.isOneKeySearching || (this.navParams.data && this.navParams.data.from == PageName.TerritoryManagementDetailsComponent)
       }
    ];
  }

  private initPageTitleControls(): void {
    let buttons = [];
    if ((!!this.contactService.contactInformation || this.uiService.activeView == 'ContactPageRightPaneNav') &&
      this.isPopPageBtnEnabled || (!this.isPopPageBtnEnabled && !this.isSplitView())) {
      buttons.push({
        id: "close",
        icon: "chevron-back-outline",
        isDisabled: false,
        align: "left"
      });
    }
    if (this.isEventPassParticipantAssociationEnabled) {
      let participant = this.eventsToolService.selectedEventOnEventsTool['eventPassesToSave']?.find(participant => participant.id == this.contactService.contactInformation.ID);
      participant = !participant ? this.eventsToolService.selectedEventOnEventsTool.participants.find(participant => participant.id == this.contactService.contactInformation.ID) : participant;
      buttons.push({
        id: 'addPass',
        imgSrc: 'assets/imgs/pass.svg',
        name: this.getPassButtonName(participant),
        isDisabled: this.isPassButtonDisabled(participant),
        align: 'right'
      })
    }
    if (this.uiService.contactDetailsSegment === 'customer-assess') {
      buttons.push({
        id: 'save',
        imgSrc: 'assets/imgs/header_save.svg',
        name: this.translate.instant('SAVE'),
        isDisabled: true,
        align: 'right'
      })
    } else if (this.uiService.contactDetailsSegment !== 'customer-assess' && this.activityDataService.isCustomerAdditionalInformationEnabled
    &&this.contactService.contactPageMode !== ComponentViewMode.LISTVIEW) {
      let logoforContactsTool = this.contactService.logoforContactsTool ? this.contactService.logoforContactsTool : `assets/imgs/pass.svg`;
      if (this.contactService.labelforContactsTool) {
        buttons.push({
          id: "customer-additional-info",
          imgSrc: logoforContactsTool,
          name: this.contactService.labelforContactsTool,
          isDisabled: false,
          align: "right"
        });
      }
    } else if (this.contactService.contactPageMode == ComponentViewMode.LISTVIEW) {
      if (this.activityDataService.isCustomerAdditionalInformationEnabled) {
          let logoforContactsTool = this.contactService.logoforContactsTool ? this.contactService.logoforContactsTool : `assets/imgs/pass.svg`;
          if (this.contactService.labelforContactsTool) {
          buttons.push({
            id: "customer-additional-info",
            imgSrc: logoforContactsTool,
            name: this.contactService.labelforContactsTool,
            isDisabled: false,
            align: "right"
          });
        }
      }
      if (this.showQrCodeBtn && !this.contactService.isInGlobalSearch) {
        buttons.push({
          id: "qrcode",
          imgSrc: 'assets/imgs/qr_code_white.svg',
          name: this.translate.instant('QR_CODE'),
          align: "right"
        });
      }
      if (this.contactService.isAffiliationEnabled) {
        buttons.push({
          id: "affiliation-explorer",
          imgSrc: 'assets/imgs/affiliation-explorer.svg',
          name: this.translate.instant('AFFILIATION_EXPLORER'),
          isDisabled: !((this.contactService.linkEntityContactTo && this.contactService.linkEntityContactTo.length) || (this.contactService.linkEntityContactFrom && this.contactService.linkEntityContactFrom.length)
                        || (this.contactService.affiliationAccount && this.contactService.affiliationAccount.length)),
          align: "right"
        })
      }
      if (!this.contactService.isInGlobalSearch) {
        buttons.push(
          {
            id: "pluse-icon",
            imgSrc: 'assets/imgs/ios_add_3x.svg',
            name: this.translate.instant('CREATE'),
            isDisabled: this.contactService.isOneKeySearching,
            align: "right"
          })
      }
    } else if (this.isEventParticipantNoteEnabled) {
    //   buttons.push({
    //     id: "eventNote",
    //     imgSrc: 'assets/imgs/ios_add_3x.svg',
    //     name: this.translate.instant('NOTES'),
    //     isDisabled: false,
    //     align: "right"
    // });
    }
    if(this.contactHighLightedFromAffiliatedExplorer){
      buttons = [];
      buttons.push({
        id: "close",
        icon: "chevron-back-outline",
        isDisabled: false,
        align: "left"
      });
    }
    this.isGoToContactBtnEnabled = this.uiService.contactDetailsSegment != 'customer-assess'
                                                && (this.contactService.contactPageMode == ComponentViewMode.PREVIEW || this.accountService.accountPageMode == ComponentViewMode.PREVIEW)
                                                && (this.uiService.isAccountsFromToolDrawer || this.uiService.isContactsFromToolDrawer)
                                                && this.isPopPageBtnEnabled && this.contactService.isEnableGoToContactsTool
                                                && (this.dynamicFormService.isOpenedAffiliatedContactOnAccount || this.dynamicFormService.isOpenedAffiliatedContactOnContact)
                                                && !this.uiService.isForcedHideGoToToolBtn;
    if (this.isGoToContactBtnEnabled || (this.accessedFrom == 'NotificationDetails' && this.uiService.contactDetailsSegment != 'customer-assess')) {
      buttons = [];
      buttons.push({
          id: "close",
          icon: "chevron-back-outline",
          isDisabled: false,
          align: "left"
      });
      buttons.push({
        id: "goToContactsTool",
        imgSrc: 'assets/imgs/open_in_new_white.svg',
        name: this.translate.instant('OPEN'),
        isDisabled: this._isDisabledGoToContactsToolBtn,
        align: "right"
      });
    }
    const isAssessTeamview: boolean = this.uiService.contactDetailsSegment =='customer-assess-teamview';
    if(isAssessTeamview) {
      buttons = [{
        id: "close-teamview",
        icon: "chevron-back-outline",
        isDisabled: false,
        align: "left"
      }];
    }

    if(this.navParams.data && this.navParams.data.from==PageName.TerritoryManagementDetailsComponent){
      buttons = [];
      buttons.push({
          id: "gotoTerritoryMgmtDetails",
          icon: "chevron-back-outline",
          isDisabled: false,
          align: "left"
      });
    }

    this.pageTitleControls = buttons
  }

  /**
   * Disable when device is offline / no event pass available for participant for completed event / no passes mapped to event / no passes left for the event
   *
   * @param participant
   * @returns
   */
  private isPassButtonDisabled(participant: EventParticipant) {
    return (_.isEmpty(participant?.eventPasses) &&
      (
        this.contactService.contactPageMode == ComponentViewMode.READONLY
        || this.device.isOffline
        || !this.eventsToolService.selectedEventOnEventsTool.passes.some(pass => pass.msevtmgt_noOfPassesLeft > 0)
      )
    )
      || _.isEmpty(this.eventsToolService.selectedEventOnEventsTool.passes);
  }

  private getPassButtonName(participant: EventParticipant) {
    if (participant && !_.isEmpty(participant.eventPasses)) {
      const passess = _.orderBy(participant.eventPasses.filter(pass => !pass['deleted']), 'msevtmgt_passName');
      return _.isEmpty(passess) ? this.translate.instant('PASS') : (passess[0].msevtmgt_passName + (passess.length > 1 ? (' +' + (passess.length - 1)) : ''));
    }
    return this.translate.instant('PASS');
  }

  private initOKPageTitleControls(): void {
    let buttons = [];
    if ((!!this.contactService.contactInformation || this.uiService.activeView == 'ContactPageRightPaneNav') &&
      this.isPopPageBtnEnabled || (!this.isPopPageBtnEnabled && !this.isSplitView())) {
      buttons.push({
        id: "close",
        icon: "chevron-back-outline",
        isDisabled: false,
        align: "left"
      });
    }
    const isAssessTeamview: boolean = this.uiService.contactDetailsSegment =='customer-assess-teamview';
    if(isAssessTeamview) {
      buttons = [{
        id: "close-teamview",
          icon: "chevron-back-outline",
          isDisabled: false,
          align: "left"
      }];
    }
    this.okPageTitleControls = buttons
  }

  public async onPageTitleControlClick(id: string) {
    switch (id) {
      case 'addPass':
        this.handlePassButton();
        break;
      case 'save':
        this.saveContactAssessment();
        break;
      case 'close':
        if (this.isPopPageBtnEnabled) {
          this.poppage();
        } else if (!this.isPopPageBtnEnabled && !this.isSplitView()) {
          this.closepage();
        } else if (this.accessedFrom === 'NotificationDetails') {
            this.navService.popChildNavPageWithPageTracking();
        }
        break;
      case 'close-teamview':
        this.uiService.contactDetailsSegment = 'customer-assess';
        this.initOKPageTitleControls();
        this.initPageTitleControls();
        break;
      case 'pluse-icon':
        this.handlePlusButton();
        break;
      case 'customer-additional-info':
        this.handleNavigationForBaseURL();
        break;
      case 'affiliation-explorer':
        this.handleAffiliationExplorer();
        break;
      case 'goToContactsTool':
        this.handleGoToContactsTool();
        break;
      case 'eventNote':
        // this.handleEventNoteClick();
        break;
        case 'gotoTerritoryMgmtDetails':
        this.handleGoToTerritoryMgmtDetails();
        break;
      case 'qrcode':
          // businessCode  : 
          // type : this.authenticationOfflineService.user.buConfigs.indskr_businessline
          // "hcoCode": account.extendedDetails['externalId']
          // userid : this.authenticationOfflineService.user.mail,
          // console.log(this.contactInfo)
          const emailVal  = this.authenticationOfflineService.user.mail ? this.authenticationOfflineService.user.mail.split('@') : [];
          const contact = this.contactService.contactInformation;
          const contactEtms = contact.indskr_externalid;
          if (!contactEtms) {
            this.notificationService.notify(this.translate.instant('CONTACT_NO_HCPCODE'), 'DynamicFormComponent', "top", ToastStyle.INFO, 3000);
            return;
          }
          if (_.isEmpty(contact.accountRelationships)) {
            this.notificationService.notify(this.translate.instant('CONTACT_NO_AFFILIATIONS'), 'DynamicFormComponent', "top", ToastStyle.INFO, 3000);
            return;
          }
          let account = this.accountService.getAccountById(contact.accountRelationships[0].accountId);
          if (!account || !account.extendedDetails || !account.extendedDetails['externalId']) {
            this.notificationService.notify(this.translate.instant('ACCOUNT_ETMS_ERR_NOTIFY', { accountName: (account && account.accountName) ? account.accountName : "" }), 'DynamicFormComponent', "top", ToastStyle.INFO, 3000);
            return;
          }
          this.uiService.displayLoader();
          let payload = {
            userid: !_.isEmpty(emailVal) ? emailVal[0] : null,
            businessCode: contactEtms,
            type: this.buLine,
            hcoCode: account.extendedDetails['externalId']
          }
          const qrResponse: any = await this.mdmService.getHcpQrCode(payload);
          this.uiService.dismissLoader();
          if (qrResponse?.status == 1000 && qrResponse?.data.qrcodeUrl) {
            let popover = await this.popoverCtrl.create({
              component: HopQRCodeModal,
              componentProps: { qrCodeData: qrResponse?.data.qrcodeUrl },
              cssClass: "hop-qr-code-modal",
              backdropDismiss: true
            });
            popover.present();
          } else if (qrResponse?.status == 2004 && qrResponse?.message) {
            this.notificationService.notify(qrResponse?.message, 'DynamicFormComponent', "top", ToastStyle.INFO, 3000);
          }
        break;
      default:
        console.log("Unhandled switch case statement");
        break;
    }
  }

  private async handlePassButton() {
    const selectedPassIds = [];
    const eventParticipant = this.eventsToolService.selectedEventOnEventsTool.participants?.find(participant => participant.id === this.contactInfo.ID);
    let participant = this.eventsToolService.selectedEventOnEventsTool['eventPassesToSave']?.find(participant => participant.id === this.contactInfo.ID);
    participant = participant ? participant : eventParticipant;
    const data: SelectListData[] = _.orderBy(this.eventsToolService.selectedEventOnEventsTool.passes, obj => obj.msevtmgt_passName).map(pass => {
      const isPassSelected: boolean = participant?.eventPasses ? participant.eventPasses.some(eventPass => eventPass.msevtmgt_passId == pass.msevtmgt_passId && !eventPass.deleted) : false;
      if (isPassSelected) selectedPassIds.push(pass.msevtmgt_passId);
      if (!(!isPassSelected && pass['temp_msevtmgt_noOfPassesLeft'] <= 0)) {
        return {
          id: pass.msevtmgt_passId,
          title: pass.msevtmgt_passName,
          isSelected: isPassSelected
        }
      }
    })
    const dropdownListDetail: IndDropdownListDetailModel = {
      id: 'pass-dropdown',
      data: _.remove(data, undefined),
      isReadOnly: this.device.isOffline
    };
    const dropdown = await this.popoverCtrl.create({
      component: IndDropdownListComponent,
      componentProps: { viewData: dropdownListDetail },
      cssClass: 'dropdown-list-view',
      event: event,
    });
    await dropdown.present();
    await dropdown.onDidDismiss().then(async (obj: any) => {
      if(this.device.isOffline) return;
      if (obj?.data && obj.data.isDone) {
        if (obj.data.selectedItems.length == selectedPassIds.length && obj.data.selectedItems.every(item => selectedPassIds.includes(item.id))) return;
        this.prepareEventAttendeePassPayload(obj.data.selectedItems.map(item => item.id), eventParticipant);
        this.initPageTitleControls();
        this.events.publish('updateContactListIsDirty');
      }
    })
  }

  private prepareEventAttendeePassPayload(selectedPassIds: string[], eventParticipant) {
    const eventPassesToRemove = [];
    const contactID = this.contactInfo?.ID ? this.contactInfo.ID : eventParticipant?.id;
    if (eventParticipant?.eventPasses) {
      this.removeSavedEventPasses(selectedPassIds, eventPassesToRemove, eventParticipant);
    }
    this.removeUnSavedEventPasses(selectedPassIds, contactID, eventParticipant);
    const eventPassesToSave = [];
    selectedPassIds
      .forEach(passId => {
        const existingEPassIndex = eventParticipant?.eventPasses.findIndex(pass => pass.msevtmgt_passId === passId);
        const index = this.eventsToolService.selectedEventOnEventsTool.passes.findIndex(pass => pass.msevtmgt_passId == passId);
        if (index >= 0) {
          this.eventsToolService.selectedEventOnEventsTool.passes[index]['temp_msevtmgt_noOfPassesLeft'] -= 1;
        }
        eventPassesToSave.push({
          msevtmgt_attendeepassid: existingEPassIndex >= 0 ? eventParticipant?.eventPasses[existingEPassIndex].msevtmgt_attendeepassId : Guid.create().toString(),
          msevtmgt_passName: this.eventsToolService.selectedEventOnEventsTool.passes[index].msevtmgt_passName,
          passId: passId,
          msevtmgt_passId: passId
        })
      });
    const payload = [...eventPassesToRemove, ...eventPassesToSave];
    if (_.isEmpty(this.eventsToolService.selectedEventOnEventsTool['eventPassesToSave'])) this.eventsToolService.selectedEventOnEventsTool['eventPassesToSave'] = [];
    const eventPassToSaveInx = this.eventsToolService.selectedEventOnEventsTool['eventPassesToSave'].findIndex(passesToSave => passesToSave.id == contactID);
    if (eventPassToSaveInx == -1) {
      this.eventsToolService.selectedEventOnEventsTool['eventPassesToSave'].push({
        id: contactID,
        eventPasses: payload
      });
    } else {
      this.eventsToolService.selectedEventOnEventsTool['eventPassesToSave'][eventPassToSaveInx].eventPasses = payload;
    }
  }

  private removeUnSavedEventPasses(selectedPassIds: string[], contactId, eventParticipant) {
    if (this.eventsToolService.selectedEventOnEventsTool['eventPassesToSave']) {
      const eventPassesToSave = this.eventsToolService.selectedEventOnEventsTool['eventPassesToSave'].find(eventPass => eventPass.id === contactId);
      if (eventPassesToSave) {
        eventPassesToSave.eventPasses.filter(eventPass => !selectedPassIds.includes(eventPass.msevtmgt_passId)).forEach(item => {
          const existingEPass = eventParticipant?.eventPasses.find(pass => pass.msevtmgt_passId === item.msevtmgt_passId);
          if (!existingEPass) {
            const index = this.eventsToolService.selectedEventOnEventsTool.passes.findIndex(pass => pass.msevtmgt_passId == item.msevtmgt_passId);
            if (index >= 0)
              this.eventsToolService.selectedEventOnEventsTool.passes[index]['temp_msevtmgt_noOfPassesLeft'] += 1;
          }
        })
      }
    }
  }

  private removeSavedEventPasses(selectedPassIds: string[], eventPassesToRemove: any[], eventParticipant) {
    eventParticipant.eventPasses
      .filter(eventPass => !selectedPassIds.includes(eventPass.msevtmgt_passId))
      .forEach(eventPass => {
        const index = this.eventsToolService.selectedEventOnEventsTool.passes.findIndex(pass => pass.msevtmgt_passId == eventPass.msevtmgt_passId);
        if (index >= 0) {
          this.eventsToolService.selectedEventOnEventsTool.passes[index]['temp_msevtmgt_noOfPassesLeft'] += 1;
          eventPassesToRemove.push({
            msevtmgt_passId: eventPass.msevtmgt_passId,
            msevtmgt_attendeepassid: eventPass.msevtmgt_attendeepassId,
            msevtmgt_passName: this.eventsToolService.selectedEventOnEventsTool.passes[index].msevtmgt_passName,
            eventRegId: eventParticipant.registrationId,
            deleted: true
          });
        }
      });
  }

  private saveContactAssessment() {
    if (this.contactAssessment) {
      this.contactAssessment.saveCustomerAssessment({ entity: 'contact', id: this.contactInfo.ID });
    }
  }

  onScroll(ev) {
    this.scrollBehaviorSubject.next(ev);
  }

  onFooterButtonClicked(buttonId: string) {
    if (this.ccCapture) this.ccCapture.footerHandler(buttonId);
  }

  handleGoToContactsTool() {
    this.contactService.isClickedGoToContactsTool = true;
    this.uiService.isContactsFromToolDrawer = true;
    this.contactService.isInGlobalSearch = false;
    if (!this.contactService.isNotInMyContactList) {
      const contactInfo = this.contactService.contactInformation;
      this.uiService.isContactsFromToolDrawer = true;
      this.navService.popWithPageTracking();
      this.navService.pushWithPageTracking(ContactPageComponent, PageName.ContactPageComponent, {from: 'ContactTool', openedToolFromContactDetails: true, contactInformation: contactInfo}, PageName.ContactPageComponent).then(()=> {
        this.navService.setChildNavRightPaneView(true);
      });
    } else if (this.contactService.errorMessageOpenContactDetails) {
      this.contactService.isClickedGoToContactsTool = false;
      this.notificationService.notify(this.contactService.errorMessageOpenContactDetails, 'Contact Details', 'top', ToastStyle.INFO);
      this._isDisabledGoToContactsToolBtn = true;
      this.initPageTitleControls();
    }
  }

  handleGoToTerritoryMgmtDetails() {
    this.navService.popChildNavPageWithPageTracking().then(() => {
      this.callbackEvent();
    });
    this.navService.setChildNavRightPaneView(true);
  }

  private async openEventParticipantDetailsForm(event: EventActivity) {
    const eventParticipant = this.eventsToolService.getEventParticipantByContactId(
      event.participants,
      this.contactService.contactInformation.ID,
    );
    const viewData: Record<string, any> = {
      eventParticipant,
      currentEvent: event,
    };
    if (!eventParticipant) {
      viewData.contact = this.contactService.contactInformation;
    }
    await this.navService.pushChildNavPageWithPageTracking(EventParticipantComponent, PageName.ContactDetailsComponent, PageName.EventParticipantComponent, viewData)
  }
}
