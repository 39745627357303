import { SearchConfigService } from './../../services/search/search-config.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Endpoints } from '../../../config/endpoints.config';
import { DiskService } from '../../services/disk/disk.service';
import { CustomerSampleAllocationDTO, SampleAllocationDTO, LotDTO } from '../../models/sample-model';
import { AuthenticationService } from '../../services/authentication.service';
import { DB_SYNC_STATE_KEYS, DB_KEY_PREFIXES } from '../../config/pouch-db.config';
import { DeviceService } from '../../services/device/device.service';
import { SampleService } from '../../services/sample/sample.service';
import { CreateSampleDropRequestBody, SampleActivity } from '../../classes/activity/sample.activity.class';
import { ActivityService } from '../../services/activity/activity.service';

import { Events } from '@omni/events';
import { FeatureActionsMap } from '../../classes/authentication/user.class';
import { NotificationService, ToastStyle } from '../../services/notification/notification.service';
import { DeltaService, EntityNames, EntitySyncInfo } from '../delta/delta.service';
import { UIService } from '../../services/ui/ui.service';
import { TranslateService } from '@ngx-translate/core';
import { CustomerLicenseDTO } from '../../classes/sampling/customer-license.class';
import { GlobalUtilityService } from '../../services/global-utility.service';
import { Contact } from '../../classes/contact/contact.class';
import { SyncFeatureCategory } from '../../enums/delta-service/delta-service.enum';
import { AllocationAdjustService } from '../../services/sample/allocation-adjust.service';
import moment from 'moment';
import { Utility } from '@omni/utility/util';

@Injectable({
  providedIn: 'root'
})
export class SampleDataService {
    private _newDeltaSampleAllocIds: Array<string> = [];
    private _newDeltaCustSampleAllocIds: { addedOrUpdated: string[], deleted: Array<{ id: string, contactId: string }> } = { addedOrUpdated: [], deleted: [] };
    private _newDeltaLotIds: Array<string> = [];
    private _isInitialMappingDone: boolean = false;
    private _isInitialCustomerAllocationMappingDone: boolean = false;


    constructor(
        private http: HttpClient,
        private disk: DiskService,
        private deviceService: DeviceService,
        private authenticationService: AuthenticationService,
        private sampleService: SampleService,
        public activityService: ActivityService,
        public events: Events,
        public notificationService: NotificationService,
        private deltaService: DeltaService,
        private uiService: UIService,
        public searchConfigService: SearchConfigService,
        public translate:TranslateService,
        private globalUtility: GlobalUtilityService,
        private adjustService: AllocationAdjustService
    ) {
    }

    async syncSamples(forceFullSync = false, loadFromDbOnly = false) {
        // Fetch from backend and save to DB
        //await this._fetchAndSaveSampleAllocations(forceFullSync);
        if(!this.authenticationService.hasFeatureAction(FeatureActionsMap.ALLOCATION_TOOL)){
            return
        }
        this.deltaService.pushSyncEntityName(SyncFeatureCategory.orders);
        await this._fetchAndSaveCustomerSampleAllocations(forceFullSync, loadFromDbOnly);

        // Load all from DB. Runs only once when app is loading.
        //await this.sampleService.loadAllSampleAndCustomerAllocationsFromDBAndMap(forceFullSync);
        //await this.sampleService.loadAllContactCustomerAllocationsFromDBAndMap(forceFullSync);

        // Load newly saved delta from DB.
        //await this.sampleService.loadAndMapNewDeltaToSampleAndCustomerAllocations(this._newDeltaSampleAllocIds, this._newDeltaCustSampleAllocIds);
        // await this.sampleService.loadAndMapNewDeltaToContactCustomerAllocations(this._newDeltaCustSampleAllocIds);

        //this._newDeltaSampleAllocIds = [];
        this._newDeltaCustSampleAllocIds = { addedOrUpdated: [], deleted: [] };
        this.searchConfigService.isAllocationsMappedToContacts = false;
    }

    async syncIndividualCustomerAllocationsFromContactProfile(rawAllocations:CustomerSampleAllocationDTO[]){
        this._newDeltaCustSampleAllocIds = { addedOrUpdated: [], deleted: [] };
        await this.sampleService.saveDeltaSyncedCustomerSampleAllocations(rawAllocations, this._newDeltaCustSampleAllocIds);
        await this.sampleService.loadAndMapNewDeltaToContactCustomerAllocations(this._newDeltaCustSampleAllocIds, true, rawAllocations);
        this._newDeltaCustSampleAllocIds = { addedOrUpdated: [], deleted: [] };
        this.events.publish('Contact-Profile:refreshContactSampleEligibilities')
    }

    private async _fetchAndSaveCustomerSampleAllocations(forceFullSync = false, loadFromDbOnly = false) {
        if (!(this.deviceService.isOffline || loadFromDbOnly)) {
            const syncState = await this.disk.getSyncState(DB_SYNC_STATE_KEYS.SYNC_CUST_SAMPLE_ALLOC);
            const newLastUpdatedTime = new Date().getTime();
            const isInitialSync = !syncState || syncState.lastUpdatedTime ? new Date(syncState.lastUpdatedTime).getMonth() != new Date(newLastUpdatedTime).getMonth() : !syncState.lastUpdatedTime;
            const doFullSync = forceFullSync || isInitialSync;
            const startDate = new Date().getTime();
            const endDate = new Date(moment(new Date()).add(1, 'month').endOf('month').format('YYYY-MM-DD')).getTime();
            let url = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.sample.CUSTOMER_SAMPLE_ALLOCATIONS;

            url = url.replace('{{positionIDs}}', this.authenticationService.getLoggedInUserPositions().toString())
            .replace('{{startDate}}', startDate.toString())
            .replace('{{endDate}}', endDate.toString());
            url = !doFullSync ? url + '&lastUpdatedTime=' + syncState.lastUpdatedTime : url;

            const allocationSyncInfo: EntitySyncInfo = {
                entityName: EntityNames.sampleProduct,
                totalFailed: 0,
                totalSynced: 0,
                errors: [],
                syncStatus: true
            };

            let response: CustomerSampleAllocationDTO[];
            try {
                response = await this.http.get<CustomerSampleAllocationDTO[]>(url, Endpoints.GLOBAL_SYNC_HEADER).toPromise();
            } catch (error) {
                console.error('_fetchAndSaveCustomerSampleAllocations: ', error);
                //allocationSyncInfo.errorMessage = '[sampleAllocation][error]' + error ? error.errorMessage : '';
                this.deltaService.addSyncErrorToEntitySyncInfo(allocationSyncInfo, url, error);

            }

            if (response && Array.isArray(response)) {
                if (doFullSync) {
                    // Full sync flow
                    if (response.length > 0) {
                        await this.sampleService.saveFullSyncedCustomerSampleAllocations(response, forceFullSync);
                    }
                    this._isInitialCustomerAllocationMappingDone = true;
                } else {
                    if (!this._isInitialCustomerAllocationMappingDone) {
                      await this.sampleService.loadAllContactCustomerAllocationsFromDBAndMap([], true);
                      this._isInitialCustomerAllocationMappingDone = true;
                    }
                    // Delta sync flow
                    if (response.length > 0) {
                      this._newDeltaCustSampleAllocIds = { addedOrUpdated: [], deleted: [] };
                      await this.sampleService.saveDeltaSyncedCustomerSampleAllocations(response, this._newDeltaCustSampleAllocIds);
                      await this.sampleService.loadAndMapNewDeltaToContactCustomerAllocations(this._newDeltaCustSampleAllocIds, false, response);
                    }
                }

                // Done sync. Update sync state.
                if (allocationSyncInfo.syncStatus) {
                    syncState.lastUpdatedTime = newLastUpdatedTime;
                    allocationSyncInfo.totalSynced = response.length;
                    await this.disk.updateSyncState(syncState);
                }
            }
            this.deltaService.addEntitySyncInfo(allocationSyncInfo);
        } else {
          await this.sampleService.loadAllContactCustomerAllocationsFromDBAndMap([], true); 
        }
    }

    async syncLots(dataRange: { from: string, to: string }, forceFullSync = false, loadFromDbOnly = false) {
        if(!this.authenticationService.hasFeatureAction(FeatureActionsMap.ALLOCATION_TOOL)){
            return
        }
        // Fetch from backend and save to DB
        await this._fetchAndSaveLots(dataRange, forceFullSync, loadFromDbOnly);

        // Load all from DB. Runs only once when app is loading.
        await this.sampleService.loadAllLotsFromDBAndMap(forceFullSync);
        await this.adjustService.loadAllLotsFromDBAndMap(forceFullSync)

        // Load newly saved delta from DB.
        // await this.sampleService.loadAndMapNewDeltaToLots(this._newDeltaLotIds);
        // await this.adjustService.loadAndMapNewDeltaToLots(this._newDeltaLotIds);

        this._newDeltaLotIds = [];
    }

    private async _fetchAndSaveLots(dataRange: { from: string, to: string }, forceFullSync = false, loadFromDbOnly = false) {
        if (!this.deviceService.isOffline && !loadFromDbOnly) {
            const syncState = await this.disk.getSyncState(DB_SYNC_STATE_KEYS.SYNC_LOT);
            const isInitialSync = !syncState || !syncState.lastUpdatedTime;
            const doFullSync = forceFullSync || isInitialSync;
            const newLastUpdatedTime = new Date().getTime();

            const lotSyncInfo: EntitySyncInfo = {
                entityName: EntityNames.lot,
                totalFailed: 0,
                totalSynced: 0,
                errors: [],
                syncStatus: true
            };

            let url = this.authenticationService.userConfig.activeInstance.entryPointUrl +
                Endpoints.sample.LOTS;

            url = url.replace('{{startDate}}', dataRange.from);
            url = url.replace('{{positionIDs}}', this.authenticationService.getLoggedInUserPositions().toString());
            url = this.authenticationService.hasFeatureAction(FeatureActionsMap.ALLOCATION_TRANSFER) ? url + '&features=allocation_transfer' : url;
            url = !doFullSync ? url + '&lastUpdatedTime=' + syncState.lastUpdatedTime : url;

            try {
                const response: LotDTO[] = await this.http.get<LotDTO[]>(url, Endpoints.GLOBAL_SYNC_HEADER).toPromise();

                if (response && Array.isArray(response)) {
                    if (doFullSync) {
                        // Full sync flow
                        if (response.length > 0) {
                            await this.sampleService.saveFullSyncedLots(response, forceFullSync);
                        }
                    } else {
                        // Delta sync flow
                        if (response.length > 0) {
                            this._newDeltaLotIds = [];
                            await this.sampleService.saveDeltaSyncedLots(response, this._newDeltaLotIds);
                        }
                    }
                    lotSyncInfo.totalSynced = response.length;
                }
                this.sampleService.lotsLastSynTime = newLastUpdatedTime;
                // Done sync. Update sync state.
                syncState.lastUpdatedTime = newLastUpdatedTime;
                await this.disk.updateSyncState(syncState);
            } catch (error) {
                console.error('syncLots: ', error);
                this.deltaService.addSyncErrorToEntitySyncInfo(lotSyncInfo, url, error);
            }

            this.deltaService.addEntitySyncInfo(lotSyncInfo);
        }
    }

    public async initiateSampleOrder(payload:CreateSampleDropRequestBody){
        //We need to save the payload in the DB/SS until we can resync with backend
        let responseActivity;

        // Manually set activity status to open
        payload['statecode'] = 0;
        payload['activitytypecode'] = 'indskr_sampledrop';
        let current = new Date();
        payload['offlineActivityId'] = 'offline_sampleActivity_'+current.getTime();
        if (this.deviceService.isOffline) {
            //Create a new appointment activity with our payload.
            let offlineReqBody = payload;

            let sampleOrderActivity = new SampleActivity(
              offlineReqBody
            );
            sampleOrderActivity.ID = offlineReqBody['offlineActivityId'];
            sampleOrderActivity.ownerId = this.authenticationService.user.systemUserID;

            let rawOfflineSampleOrder = await this.disk.createOfflineSampleOrder(sampleOrderActivity);
            if(!this.activityService.hasOfflineSampleOrderData(rawOfflineSampleOrder.offlineActivityId)){
                this.activityService.addToOfflineSampleOrderIds(rawOfflineSampleOrder.offlineActivityId);
            }
            this.activityService.addActivity(sampleOrderActivity);
            if (!this.uiService.toolsActivityActive){
              this.events.publish('refreshAgenda');
            } else this.uiService.agendaRefreshRequired = true;
            responseActivity = sampleOrderActivity;
            return responseActivity
        }
        //   call the create sample order service here to create activity in dynamics
           let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.sample.CREATE_ALLOCATIONS_DROP;
           let headers = Endpoints.meeting.INITIATE_MEETING_HEADERS;
           headers.headers = headers.headers.set(
             'X-BusinessUnitId',
             this.authenticationService.user.xBusinessUnitId
           ).set(
             "X-PositionId",
             this.authenticationService.user.xPositionID
           );

           await this.http
             .post(url, payload, headers)
             .toPromise()
             .then(async (response)=>{
                response['statecode'] = 0;
                response['activitytypecode'] = 'indskr_sampledrop'
                let responseAllocationActivity = new SampleActivity(response)
                await this.disk.updateOrInsert(DB_KEY_PREFIXES.SAMPLE_ACTIVITY + responseAllocationActivity.ID, (doc) => {
                    return responseAllocationActivity;
                });
                responseActivity = responseAllocationActivity;
                return
             })
             .catch(async (error)=>{
                let offlineReqBody = payload;
                // Manually set activity status to open

                let sampleOrderActivity = new SampleActivity(
                  offlineReqBody
                );
                sampleOrderActivity.ID = offlineReqBody['offlineActivityId'];
                sampleOrderActivity.ownerId = this.authenticationService.user.systemUserID;
                let rawOfflineSampleOrder = await this.disk.createOfflineSampleOrder(
                  sampleOrderActivity
                );
                if(!this.activityService.hasOfflineSampleOrderData(rawOfflineSampleOrder.offlineActivityId)){
                    this.activityService.addToOfflineSampleOrderIds(rawOfflineSampleOrder.offlineActivityId);
                }
                this.activityService.addActivity(sampleOrderActivity)
                if (!this.uiService.toolsActivityActive){
                  this.events.publish('refreshAgenda');
                } else this.uiService.agendaRefreshRequired = true;
                responseActivity = sampleOrderActivity;
                return
             })
            return responseActivity
    }
    public async updateSampleActivity(payload:CreateSampleDropRequestBody, sampleActivity?: SampleActivity, contact?: Contact):Promise<any>{
        let updatedActivity;

        if (this.deviceService.isOffline || sampleActivity.ID.includes('offline')) {
            //update local db for this activity
            this.disk.updateOrInsertActivityToActivityDetailRawDocument(sampleActivity, true)
            // let localSavedActivity =
            // let rawOfflineSampleOrder = await this.disk.createOfflineSampleOrder(
            //   sampleOrderActivity
            // );

            //add to offline update document hashmap
            let id_to_be_updated:string =  sampleActivity.offlineActivityId;
            if(!this.activityService.hasOfflineSampleOrderData(id_to_be_updated)){
                this.activityService.addToOfflineSampleOrderIds(id_to_be_updated);
                await this.disk.createOfflineSampleOrder(sampleActivity)
            }
            //update offline upload document for this activity
            else(
                this.disk.updateofflineSampleOrderDocument(sampleActivity)
            )
            //add/update to session display activities
            if(sampleActivity.state == 2){
                await this.activityService.removeActivity(sampleActivity);
                if(sampleActivity.appointmentID && sampleActivity.appointmentID.length > 0)
                    this.events.publish('deletedEmbeddedActivity', sampleActivity);
            }
            else{
                await this.activityService.addActivity(sampleActivity, true);
            }
            // this.activityService.isActivityUpdate = true;
            this.activityService.publishActivityEvent({action: "Update", activity: sampleActivity});

            if (sampleActivity.appointmentID && sampleActivity.appointmentID.length > 0 && sampleActivity.state === 1) {
                this.events.publish('updateEmbeddedActivity', sampleActivity);
            }
            // interaction for contact for completed sample order
            if(sampleActivity.status === 2){
               this.globalUtility.updateInteractionContact(contact, 'Allocations');
            }
            //Publish event to update the agenda list
            if (!this.uiService.toolsActivityActive){
              this.events.publish('refreshAgenda');
            } else this.uiService.agendaRefreshRequired = true;
            //responseActivity = sampleOrderActivity;
            //Publish event to update the completed allocation order in contact timeline offline mode
            if(this.deviceService.isOffline && sampleActivity.status === 2) {
              await this.activityService.publishOfflineActivityForTimeline(sampleActivity);
            }
            return // responseActivity
        }
        else{
            let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.sample.UPDATE_ALLOCATIONS_DROP;
            if(payload.activityid){
                url = url.replace('{{SampleActivityId}}', payload.activityid)
            }
            else{
                //add to offline update document hashmap
                if(!this.activityService.hasOfflineSampleOrderData(sampleActivity.offlineActivityId)){
                    this.activityService.addToOfflineSampleOrderIds(sampleActivity.offlineActivityId);
                    this.disk.createOfflineSampleOrder(sampleActivity)
                }
                //update offline upload document for this activity
                else(
                    this.disk.updateofflineSampleOrderDocument(sampleActivity)
                )

                //add/update to session display activities
                if(sampleActivity.state == 2){
                    await this.activityService.removeActivity(sampleActivity);
                    if(sampleActivity.appointmentID && sampleActivity.appointmentID.length > 0)
                        this.events.publish('deletedEmbeddedActivity', sampleActivity);
                }
                else{
                    await this.activityService.addActivity(sampleActivity, true);
                }

                if (sampleActivity.appointmentID && sampleActivity.appointmentID.length > 0 && sampleActivity.state === 1) {
                    this.events.publish('updateEmbeddedActivity', sampleActivity);
                }
                // interaction for contact for completed sample order
                if (sampleActivity.status === 2) {
                    this.globalUtility.updateInteractionContact(contact, 'Allocations');
                }
                //Publish event to update the agenda list
                if (!this.uiService.toolsActivityActive){
                  this.events.publish('refreshAgenda');
                } else this.uiService.agendaRefreshRequired = true;
                //responseActivity = sampleOrderActivity;
                return // responseActivity
            }
            let headers = Endpoints.meeting.INITIATE_MEETING_HEADERS;
            headers.headers = headers.headers.set(
             'X-BusinessUnitId',
             this.authenticationService.user.xBusinessUnitId
            ).set(
             "X-PositionId",
             this.authenticationService.user.xPositionID
            ).set(
                "X-SystemUserId",
                this.authenticationService.user.systemUserID
            );
            await this.http
             .patch(url, payload, headers)
             .toPromise()
             .then(async ()=>{
                // add to session display activities
                if(sampleActivity.state == 2){
                    this.activityService.removeActivity(sampleActivity)
                    if(sampleActivity.appointmentID && sampleActivity.appointmentID.length > 0)
                        this.events.publish('deletedEmbeddedActivity', sampleActivity);
                }
                else{
                    await this.activityService.addActivity(sampleActivity, true);
                }
                 // interaction for contact for completed sample order
                 if (sampleActivity.status === 2) {
                     this.globalUtility.updateInteractionContact(contact, 'Allocations');
                 }
                // await this.disk.updateOrInsert(DB_KEY_PREFIXES.SAMPLE_ACTIVITY + payload.activityid, (doc) => {
                //     return updatedActivity;
                // });
                if (sampleActivity.appointmentID && sampleActivity.appointmentID.length > 0 && sampleActivity.state === 1) {
                    this.events.publish('updateEmbeddedActivity', sampleActivity);
                }
                //UPSERT LOCAL DISK RAW ACTIVITY
                this.disk.updateOrInsertActivityToActivityDetailRawDocument(sampleActivity, true)
                //publish event to update agenda list
                // this.activityService.isActivityUpdate = true;
                this.activityService.publishActivityEvent({action: "Update", activity: sampleActivity});
                if (!this.uiService.toolsActivityActive)
                {
                  this.events.publish("refreshAgenda");
                }
                else {
                  this.uiService.agendaRefreshRequired = true;
                }
              return;
             })
             .catch(async (error)=>{
                 let errorInfo = (error.hasOwnProperty('error')) ? error.error : error;
                if(errorInfo.errorCode =='ERR_IO_SR01'){
                    //for some reason, limits have exceeded for one of the SKUs in this sample activity
                    this.notificationService.notify(this.translate.instant('EXCEEDED_ALLOWCATION_LIMIT'),'Sample Data Service','top');
                    this.sampleService.updateSampleEligibilities(sampleActivity,errorInfo['errorDetails']);
                    throw new Error('exceededLimits');
                }
                if(errorInfo.errorCode =='ERR_IO_SR04'){
                    this.notificationService.notify(this.translate.instant('ALLOWCATION_COULD_NOT_BE_COMPLETED'),'Sample Data Service','top');
                    //this.sampleService.updateSampleEligibilities(sampleActivity,errorInfo['errorDetails']);
                    throw new Error('allocation recalled');
                }
                if(errorInfo.errorCode == "ERR_IO_500"){
                    this.notificationService.notify(this.translate.instant('NETWORK_ISSUES_WHILE_COMPLETE_REQUEST'),'Sample Data Service','top',ToastStyle.DANGER);
                    throw new Error('Server Error');
                }
                this.disk.updateOrInsertActivityToActivityDetailRawDocument(sampleActivity, true)
                // let localSavedActivity =
                // let rawOfflineSampleOrder = await this.disk.createOfflineSampleOrder(
                //   sampleOrderActivity
                // );

                //add to offline update document hashmap
                if(!this.activityService.hasOfflineSampleOrderData(sampleActivity.offlineActivityId)){
                    this.activityService.addToOfflineSampleOrderIds(sampleActivity.offlineActivityId);
                    this.disk.createOfflineSampleOrder(sampleActivity)
                }
                //update offline upload document for this activity
                else(
                    this.disk.updateofflineSampleOrderDocument(sampleActivity)
                )

                //add/update to session display activities
                if(sampleActivity.state == 2){
                    await this.activityService.removeActivity(sampleActivity);
                    if(sampleActivity.appointmentID && sampleActivity.appointmentID.length > 0)
                        this.events.publish('deletedEmbeddedActivity', sampleActivity);
                }
                else{
                    await this.activityService.addActivity(sampleActivity, true);
                }

                if (sampleActivity.appointmentID && sampleActivity.appointmentID.length > 0 && sampleActivity.state === 1) {
                    this.events.publish('updateEmbeddedActivity', sampleActivity);
                }
                //Publish event to update the agenda list
                // this.activityService.isActivityUpdate = true;
                this.activityService.publishActivityEvent({action: "Update", activity: sampleActivity});
                if (!this.uiService.toolsActivityActive){
                  this.events.publish('refreshAgenda');
                } else this.uiService.agendaRefreshRequired = true;
                //responseActivity = sampleOrderActivity;
                return // responseActivity
             })
             return updatedActivity
        }
    }

    // public async getConsentTerms(loadFromDbOnly = false) {
    //     if (!loadFromDbOnly) {
    //     let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.sample.GET_CONSENT_TERMS;
    //     let headers = Endpoints.meeting.INITIATE_MEETING_HEADERS;
    //         headers.headers = headers.headers.set(
    //          'X-BusinessUnitId',
    //          this.authenticationService.user.xBusinessUnitId
    //        ).set(
    //          "X-PositionId",
    //          this.authenticationService.user.xPositionID
    //        ).set('Sync-Service', 'true');

    //     const consentTermsSyncInfo: EntitySyncInfo = {
    //         entityName: EntityNames.consentTerm,
    //         totalFailed: 0,
    //         totalSynced: 0,
    //         errors: [],
    //         syncStatus: true
    //     };

    //     try {
    //         let response = <any> await this.http
    //          .get(url, headers)
    //          .toPromise()

    //         if (response && response.length>0) {
    //             consentTermsSyncInfo.totalSynced = response.length;
    //             localStorage.setItem('samplingConsentTerms', response[0].indskr_body)
    //             //localStorage.setItem('samplingConsentName', response[0].indskr_name)
    //         }
    //     } catch (error) {
    //         this.deltaService.addSyncErrorToEntitySyncInfo(consentTermsSyncInfo, url, error);
    //     }

    //     this.deltaService.addEntitySyncInfo(consentTermsSyncInfo);
    //     }
    // }

    // public async getConsentType(){
    //     let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.sample.GET_CONSENT_TYPES;
    //     let headers = Endpoints.meeting.INITIATE_MEETING_HEADERS;
    //         headers.headers = headers.headers.set(
    //          'X-BusinessUnitId',
    //          this.authenticationService.user.xBusinessUnitId
    //        ).set(
    //          "X-PositionId",
    //          this.authenticationService.user.xPositionID
    //        );
    //        let response = <any> await this.http
    //        .get(url, headers)
    //        .toPromise()
    //         return
    // }

    async syncCustomerLicenses(forceFullSync = false, loadFromDbOnly = false) {
        if (!(this.deviceService.isOffline || loadFromDbOnly)) {
            const syncState = await this.disk.getSyncState(DB_SYNC_STATE_KEYS.SYNC_CUSTOMER_LICENSES);
            const isInitialSync = !syncState || !syncState.lastUpdatedTime;
            const doFullSync = forceFullSync || isInitialSync;
            const newLastUpdatedTime = new Date().getTime();

            let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.sample.GET_CUSTOMER_LICENSES;
            url = url.replace('{{positionIDs}}',this.authenticationService.getLoggedInUserPositions().toString())
            url = !doFullSync ? url + '&lastUpdatedTime=' + syncState.lastUpdatedTime : url;

            const licenseSyncInfo: EntitySyncInfo = {
                entityName: EntityNames.customerLicense,
                totalFailed: 0,
                totalSynced: 0,
                errors: [],
                syncStatus: true
            };

            let response: CustomerLicenseDTO[];
            try {
                response = await this.http.get<CustomerLicenseDTO[]>(url, Endpoints.GLOBAL_SYNC_HEADER).toPromise();
            } catch (error) {
                console.error('syncLicenses: ', error);
                this.deltaService.addSyncErrorToEntitySyncInfo(licenseSyncInfo, url, error);
            }

            if (response && Array.isArray(response)) {
                if (doFullSync) {
                    // Initial full sync flow
                    await this.sampleService.mapFullSyncedCustomerLicenses(response, newLastUpdatedTime, forceFullSync);
                    this._isInitialMappingDone = true;
                } else {
                    // Delta sync flow
                    const dbCustomerLicenses = await this.sampleService.loadCustomerLicensesFromDB();

                    if (!this._isInitialMappingDone) {
                        await this.sampleService.mapCustomerLicensesWithDbData(dbCustomerLicenses);
                        this._isInitialMappingDone = true;
                    }

                    // Map delta
                    await this.sampleService.mapDeltaSyncedCustomerLicenses(response, dbCustomerLicenses, newLastUpdatedTime);
                }

                // Done sync. Update sync state.
                if (licenseSyncInfo.syncStatus) {
                    syncState.lastUpdatedTime = newLastUpdatedTime;
                    licenseSyncInfo.totalSynced = response.length;
                    await this.disk.updateSyncState(syncState);
                }
            }

            this.deltaService.addEntitySyncInfo(licenseSyncInfo);
        } else {
            // Just load data from DB and map.
            const dbCustomerLicenses = await this.sampleService.loadCustomerLicensesFromDB();
            await this.sampleService.mapCustomerLicensesWithDbData(dbCustomerLicenses);
        }
    }

}
