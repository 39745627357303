import { NotificationService, ToastStyle } from './../../../services/notification/notification.service';
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IonNav,  LoadingController, ModalController, PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Events } from '@omni/events';
import { format, isBefore, isFuture } from 'date-fns';
import * as _ from 'lodash';
import { sample } from 'rxjs/operators';
import { ActivityType, ActivityColorCode } from '../../../classes/activity/activity.class';
import { CreateSampleDropRequestBody, SampleActivity } from '../../../classes/activity/sample.activity.class';
import { FeatureActionsMap } from '../../../classes/authentication/user.class';
import { Contact, ContactAddress } from '../../../classes/contact/contact.class';
import { AllocationQuantitiesDetailViewModel, AllocationQuantitiesLotDetailViewModel, CustomerSampleAllocation } from '../../../classes/sample/customer-sample-allocation.class';
import { MainToolTemplateComponent } from '../../../components/shared/main-tool-template/main-tool-template';
import { ActivityDataService } from '../../../data-services/activity/activity.service';
import { RepServices } from '../../../data-services/rep/rep.services';
import { SampleDataService } from '../../../data-services/sample/sample.data.service';
import { FormFieldType, IndFormFieldViewDataModel } from '../../../models/indFormFieldDataModel';
import { IndSectionHeaderViewDataModel } from '../../../models/indSectionHeaderDataModel';
import { MainCardViewDataModel } from '../../../models/MainCardViewDataModel';
import { MainToolTemplateDetail, MainToolTemplateListSelectionType } from '../../../models/mainToolTemplateDetail.model';
import { SampleDetailInSampleActivity, ValidatedCustomerLicense } from '../../../models/sample-model';
import { ActivityService } from '../../../services/activity/activity.service';
import { AlertService } from '../../../services/alert/alert.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { CallPlanOfflineService } from '../../../services/call-plan/call-plan.offline.service';
import { ContactOfflineService } from '../../../services/contact/contact.service';
import { DateTimeFormatsService } from '../../../services/date-time-formats/date-time-formats.service';
import { DEVICE_ORIENTATION, DeviceService } from '../../../services/device/device.service';
import { DiskService, OFFLINE_DB_LINKED_ENTITY_NAME } from '../../../services/disk/disk.service';
import { EventName, EventsService } from '../../../services/events/events.service';
import { FooterService, FooterViews } from '../../../services/footer/footer.service';
import { GlobalUtilityService } from '../../../services/global-utility.service';
import { TrackingEventNames, TrackService } from '../../../services/logging/tracking.service';
import { NavigationService, PageName } from '../../../services/navigation/navigation.service';
import { SampleService, SamplingDetailsViewMode } from '../../../services/sample/sample.service';
import { ComponentViewMode, UIService  } from '../../../services/ui/ui.service';
import { CaptureAllocationOrderComponent } from '../../capture-allocation-order/capture-allocation-order';
import { ContactPageComponent } from '../../contact/contact-page/contact-page';
import { PopoverComponent } from '../../popover/popover';
import { IndPageTitleViewDataModel } from './../../../models/indPageTitleDataModel';
import { NothingSelectedView } from '../../shared/nothing-selected-view/nothing-selected-view';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { DatePipe } from '@angular/common';

/**
 * Generated class for the NewSampleActivityComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'new-sample-activity',
  templateUrl: 'new-sample-activity.html',
  styleUrls:['new-sample-activity.scss']
})
export class NewSampleActivityComponent implements OnInit, OnDestroy {

  public elligibleAllocations: CustomerSampleAllocation[] = [];
  public quantitiesHeader: IndSectionHeaderViewDataModel;
  public detailsHeader: IndSectionHeaderViewDataModel;
  public allocationQuantitiesData: Array<AllocationQuantitiesDetailViewModel> = [];
  private _invalidValuePairs: Array<{id: string,value: string}> = [];
  //List of selected SKUs sample activity details page
  //public selectedProducts: SampleDetailInSampleActivity[];
  public selectedAddressObj: ContactAddress = null;
  public selectedAddress: string;
  public isThereEligibleProductWithNoLicenseIssue = false;
  private popover: HTMLIonPopoverElement;
  public isCurrentActivityCompleted = false;
  orderNumber: string;
  public pageName = PageName;
  public statusString: string;
  public activity: SampleActivity;
  //hideSpinner: boolean = true;
  public inMeetingSamplingFlow = false;
  public inMeetingPresentationFlow = false;
  //orientation change handler for mobile device (switching between full view and s[lit view)
  private _screenOrientation$;
  comingFromContacts = false;
  noEligibleAddress = false;
  contactAddressList: ContactAddress[];
  newSampleActivityPanePageTitle: IndPageTitleViewDataModel;
  statusFormField: IndFormFieldViewDataModel;
  orderNumberFormField: IndFormFieldViewDataModel;
  customerFormField: IndFormFieldViewDataModel;
  addressFormField: IndFormFieldViewDataModel;
  elligibleProductsFormField: IndFormFieldViewDataModel;
  @Input() from: PageName;
  //@ViewChild(Content, {static:true}) content: Content;
  private readonly  ngDestroy$ = new Subject<boolean>();
  private lotsUpdated = false;
  private backgroundUploadInProgress: boolean = false;
  private _isInputLotValueValid: boolean = true;
  //mandatory field
  private _isClickedContact: boolean = false;
  private _isClickedAddress: boolean = false;
  private _isClickedProduct: boolean = false;

  private viewDestroyed:boolean = false;

  public isAllQuantitiesInValid:boolean = false;

  constructor(
    public uiService: UIService,
    private readonly callPlanService: CallPlanOfflineService,
    public contactService: ContactOfflineService,
    public navService: NavigationService,
    public activityService: ActivityService,
    public repService: RepServices,
    public popoverCtrl: PopoverController,
    public sampleService: SampleService,
    public events: Events,
    public eventService: EventsService,
    public device: DeviceService,
    public footerService: FooterService,
    public _cd: ChangeDetectorRef,
    public activityDataService: ActivityDataService,
    public sampleDataService: SampleDataService,
    public loadingCtrl: LoadingController,
    public navCtrl: IonNav,
    public trackingService: TrackService,
    public utilityService: GlobalUtilityService,
    private readonly alertService: AlertService,
    private readonly translate: TranslateService,
    public dateTimeFormatsService: DateTimeFormatsService,
    public authService: AuthenticationService,
    private readonly disk: DiskService,
    public modalCtrl: ModalController,
    public notificationService: NotificationService,
    private datePipe: DatePipe

  ) {
    console.log('Hello NewSampleActivityComponent Component');
    this.sampleService.isHandleElligibleProductSelect = false;

    this.eventService.observe('updateMandatoryField_footer').pipe(takeUntil(this.ngDestroy$)).subscribe((button) => {
      if(button == 'contactsAllocation') this._isClickedContact = true;
    });
    this.eventService.observe(EventName.SAMPLEELLIGIBILITESFOOTERBUTTON).pipe(takeUntil(this.ngDestroy$)).subscribe((data) => {
      this._isClickedProduct = true;
      this._handleElligibleProductSelect();
    });
    this.eventService.observe(EventName.ALLOCATION_SIGNATURE_FOOTER_BUTTON).pipe(takeUntil(this.ngDestroy$)).subscribe((data) => {
      this.handleSignatureCapture();
    });
    this.eventService.observe('selectedActivityChangedInBackround').pipe(takeUntil(this.ngDestroy$)).subscribe((data) => {
      this.setViewData(true);
    });
    this.eventService.observe('updateMandatoryField').pipe(takeUntil(this.ngDestroy$)).subscribe((field: PageName) => {
      this._updateMandatoryField(field);
    });
    this.eventService.observe('updateRequestFromFooterService').pipe(takeUntil(this.ngDestroy$)).subscribe(() => {
      this._updateFooterButtons();
    });

    this.eventService.observe('sync:completed').pipe(takeUntil(this.ngDestroy$)).subscribe(() => {
      if (this.activity.status === 2) {
        this.disableFooterbuttons();
      }
    });
    this.eventService.observe('tools-back-clicked').pipe(takeUntil(this.ngDestroy$)).subscribe((pageName) => {
      if (pageName == PageName.AllocationComponent) {
        this._initAllocationQuantitiesDetails();
        this.initAllDataModels();
        this._updateFooterButtons();
        this._cd.detectChanges();
      }
    });
  }

  ngOnInit() {
    if (this.sampleService.samplingDetailsViewMode &&
      this.sampleService.samplingDetailsViewMode === SamplingDetailsViewMode.CREATE_FROM_MEETING || 
      this.sampleService.samplingDetailsViewMode === SamplingDetailsViewMode.CREATE_FROM_EVENTS ||
      this.sampleService.samplingDetailsViewMode === SamplingDetailsViewMode.CREATE_FROM_MEETING_PRESENTATION) {
      //sampling from In-Meeting flow detected
      this.inMeetingSamplingFlow = true;
      if (this.sampleService.samplingDetailsViewMode === SamplingDetailsViewMode.CREATE_FROM_MEETING_PRESENTATION) {
        this.inMeetingPresentationFlow = true;
      }
    }
    else {
      this.inMeetingSamplingFlow = false;
    }
    this.setViewData(true);
    //unsubscribe called to remove any previouus subscriptions. Just a cautious step
    // this.events.unsubscribe('selectedActivityChangedInBackround');
    // this.events.unsubscribe(EventName.SAMPLEELLIGIBILITESFOOTERBUTTON);
    // this.events.unsubscribe(EventName.ALLOCATION_SIGNATURE_FOOTER_BUTTON);
    //subscribing to event to look for changes made while details page is not the top page in nav
    // this.events.subscribe('selectedActivityChangedInBackround', () => this.setViewData());
    // this.events.subscribe(EventName.SAMPLEELLIGIBILITESFOOTERBUTTON, () => this._handleElligibleProductSelect());
    // this.events.subscribe(EventName.ALLOCATION_SIGNATURE_FOOTER_BUTTON, () => this.handleSignatureCapture());


    // if (this.sampleService.samplingDetailsViewMode &&
    //   this.sampleService.samplingDetailsViewMode ==
    //   SamplingDetailsViewMode.CREATE_FROM_CONTACT_PROFILE || this.activity.isFromXperiences) {
    //   this.comingFromContacts = true;
    //   //sample activity created form a contact profile.
    //   //Jump straight to product selection page as contact is already selected
    //   if (this.contactName) {
    //     this.showEligibilities('contactProfile');
    //   }
    // } else
    if (this.sampleService.samplingDetailsViewMode && this.sampleService.samplingDetailsViewMode === SamplingDetailsViewMode.VIEW_DETAILS &&
      this.navService.getCurrentPageName() === PageName.ContactPageComponent) {
      this.comingFromContacts = true;
    }
    else {
      this.comingFromContacts = false;
    }

    this.translate.onLangChange.subscribe(data => {
      if(this.navService.getCurrentPageName() != PageName.AppSettingsPageComponent) {
        this.initHeaderFooterFormFieldsData();
      }
      this.initAllDataModels();
    });
    this.device.isBackgroundUploadInProgressObservable.pipe(takeUntil(this.ngDestroy$)).subscribe(inProgress => {
      if (this.activity && this.navService.getActiveChildNavViewPageName() === PageName.NewSampleActivityComponent) {
        this.backgroundUploadInProgress = (inProgress && this.activityService.hasOfflineSampleOrderData(this.activity.ID));
        this.initAllDataModels();
        this._updateFooterButtons();
      }
    });
  }

  async setViewData(init: boolean = false) {
    this._initFooterButtons();
    if (!this.activity) {
      this.uiService.showNewActivity = false;
      this.uiService.activeView = undefined;
      return;
    }

    if (!this.activityService.teamViewActive && this.activity.contactState === 1 && this.activity.status !== 2) {
      // const loader = await this.loadingCtrl.create();
      // loader.present();
      this.activity.contactID = '';
      this.activity.contactName = '';
      this.activity.location = '';
      this.activity.addressID = '';
      this.activity.samples.map((o) => {
        o.deleted = true;
        if(o.lots && o.lots.length > 0){
          o.lots.map(l=> {
            l.deleted = true
          });
        }
      });
      this.activity.contactState = 0;
      this._updateCurrentSampleActivity();
    }
    else {
      this.selectedAddressObj = this.contactService.getContactAddressObjById(this.activity.contactID, this.activity.addressID);
      this.selectedAddress = this.selectedAddressObj && this.selectedAddressObj.compositeAdd
                                                      ? this.selectedAddressObj.compositeAdd
                                                        : '';

      if (!this.selectedAddress && this.activity.location) {
        this.selectedAddress = this.activity.location;
      } else {
        this.activity.location = this.selectedAddress;
      }
    }
    this.orderNumber = this.activity.orderId ? this.activity.orderId : this.activity.subject;

    switch (this.activity.statusString) {
      case 'Open':
        this.statusString = this.translate.instant('OPEN');
        break;
      case 'N/A':
        this.statusString = this.translate.instant('N_A');
        break;
      case 'Scheduled':
        this.statusString = this.translate.instant('SCHEDULED');
        break;
      case 'Completed':
        this.statusString = this.translate.instant('COMPLETED');
        break;
      case 'Canceled':
        this.statusString = this.translate.instant('CANCELLED');
        break;
      default:
        this.statusString = this.activity.statusString;
        break;
    }

    if (this.activity.statusString === 'Completed') {
      if (this.activity.actualEnd) {
        this.statusString = this.statusString + ' - ' + this.datePipe.transform(new Date(parseInt(this.activity.actualEnd)), this.dateTimeFormatsService.date, undefined, this.translate.currentLang)
        // this.datePipe.transform(new Date(parseInt(this.activity.actualEnd)), this.dateTimeFormatsService.date, undefined, this.translate.currentLang)//
                                  }
      this.isCurrentActivityCompleted = true;
      // Reset flag
      this.noEligibleAddress = false;
    }
    else {

      if (this.activity.contactID) {
        const contact = this.contactService.contacts.find((o) => {
          return o.ID === this.activity.contactID;
        });
        if (contact) {
          this.contactAddressList = [];
          const rawData = await this.disk.retrieve(OFFLINE_DB_LINKED_ENTITY_NAME.ADDRESSES);
          if(rawData && rawData.raw && Array.isArray(rawData.raw) && rawData.raw.length !== 0){
            rawData.raw.forEach(item => {
              if(item.hasOwnProperty('contactid') && contact.ID === item['contactid']){
                const foundAddress:ContactAddress = {
                  city: item['indskr_address.indskr_city_lu@OData.Community.Display.V1.FormattedValue']||'',
                  state: item['indskr_address.indskr_state_lu@OData.Community.Display.V1.FormattedValue']||'',
                  postal: item['indskr_address.indskr_postalcode_lu@OData.Community.Display.V1.FormattedValue']||'',
                  country: item['indskr_address.indskr_country_lu@OData.Community.Display.V1.FormattedValue']||'',
                  countryCode: item['indskr_address.indskr_countrycode_lu@OData.Community.Display.V1.FormattedValue']||'',
                  street: item['indskr_address.indskr_line1']||'',
                  street2: item['indskr_address.indskr_line2']||'',
                  street3: item['indskr_address.indskr_line3']||'',
                  region: item['indskr_address.indskr_luregion@OData.Community.Display.V1.FormattedValue']||'',
                  postOfficeBox: item['indskr_address.indskr_postofficebox@OData.Community.Display.V1.FormattedValue']||'',
                  isPrimary: item['indskr_indskr_customeraddress_v2.indskr_isprimary']||'',
                  addressId: item['indskr_indskr_customeraddress_v2.indskr_address']||'',
                  compositeAdd: item['indskr_address.indskr_composite']||'',
                  primaryContact: item['indskr_indskr_customeraddress_v2.indskr_primarycontact@OData.Community.Display.V1.FormattedValue']||'',
                  fax: item['indskr_indskr_customeraddress_v2.indskr_fax@OData.Community.Display.V1.FormattedValue']||'',
                  fax2: item['indskr_indskr_customeraddress_v2.indskr_fax2@OData.Community.Display.V1.FormattedValue']||'',
                  telephone1: item['indskr_indskr_customeraddress_v2.indskr_telephone1@OData.Community.Display.V1.FormattedValue']||'',
                  telephone2: item['indskr_indskr_customeraddress_v2.indskr_telephone2@OData.Community.Display.V1.FormattedValue']||'',
                  isSampleEligible: item['indskr_indskr_customeraddress_v2.indskr_samplingeligible'],
                  customerAddressID: item['indskr_indskr_customeraddress_v2.indskr_indskr_customeraddress_v2id']||'',
                  concatanatedAddress: item['indskr_indskr_customeraddress_v2.indskr_address@OData.Community.Display.V1.FormattedValue']||'',
                  latitude: item['indskr_address.indskr_latitude']||'',
                  longitude: item['indskr_address.indskr_longitude:']||'',
                };
                const idx = this.contactAddressList.findIndex(a => a.addressId === foundAddress.addressId);
                if (idx < 0) {
                  this.contactAddressList.push(foundAddress);
                }
              }
            })
          }
        } else {
          this.contactAddressList = [];
        }
        if (this.contactAddressList) {
          const hasData = this.contactAddressList.some(x=>x.addressId);
          this.noEligibleAddress = !hasData ? true : false;
        } else {
          this.noEligibleAddress = true;
        }
      } else {
        this.contactAddressList = [];
        this.noEligibleAddress = true;
      }
      //open status activity
      this.isCurrentActivityCompleted = false;

    }
    this.activity.calculateLotsAndQuantitiesForSKU(this.activity.samples);
    if(this.elligibleAllocations.length === 0){
      this._getElligibleAllocations();
    }
    this.initHeaderFooterFormFieldsData();

    if (init === false) {
      this.eventService.publish("allocationOrderActivityIsUpdated", this.activity);
    }

    this._cd.detectChanges();
  }

  private initHeaderFooterFormFieldsData() {
    this._updateFooterButtons();
    this.initAllDataModels();
    if (this._isInputLotValueValid) {
      this._initAllocationQuantitiesDetails();
    }
  }

  private _initPageTitle() {
    let buttons = [];
    if (!this.isSplitView
      || (this.navService.getCurrentMasterPageName() !== this.pageName.ActivitiesPageComponent && this.navService.getCurrentMasterPageName() !== this.pageName.AllocationComponent)
      || (this.navService.getCurrentMasterPageName() === this.pageName.ActivitiesPageComponent && !this.footerService.isActivitiesButton)
      || this.inMeetingSamplingFlow) {
      buttons.push({
        id: 'close',
        icon: 'chevron-back-outline',
        isDisabled: false,
        align: 'left'
      });
    }
    if (!this.inMeetingPresentationFlow) {
      buttons.push({
        id: 'allocation_order_scrap',
        imgSrc: 'assets/imgs/header_cancel.svg',
        name: this.translate.instant('SCRAP'),
        isDisabled: (this.activity.statusString === 'Completed' || this.activityService.teamViewActive || this.backgroundUploadInProgress) ? true : false,
        align: 'right'
      },
        {
          id: 'allocation_order_complete',
          imgSrc: 'assets/imgs/header_acknowledgement.svg',
          name: this.isCurrentActivityCompleted ? this.translate.instant('SIGNATURE') : this.translate.instant('COMPLETE'),
          isDisabled: (this.activity.statusString === 'Completed' && !this.activityService.teamViewActive && !this.backgroundUploadInProgress) ? false : ((this.activityService.teamViewActive || this.isGetSignatureDisabled || this.backgroundUploadInProgress || !this._isInputLotValueValid || this.isAllQuantitiesInValid) ? true : false),          align: 'right'
        });
    }
    this.newSampleActivityPanePageTitle = {
      id: 'new-sample-activities-details-page-title',
      title: this.translate.instant('ALLOWCATION_ORDER') + ((this.activity.orderId) ? (' - ' + this.activity.orderId) : ''),
      controls: buttons
    };
  }

  ionViewWillEnter() {
    //create orientation change handler
    if (!this._screenOrientation$) {
      this._screenOrientation$ = this.device.screenOrientation.subscribe((deviceOrientation: number) => {
        if (this.navService.getCurrentMasterPageName() === PageName.ActivitiesPageComponent
          && this.navService.getCurrentPageName() === PageName.NewSampleActivityComponent) {
          //was in portrait mode full view. So, if user switched to landscape, show split view
          if (deviceOrientation === DEVICE_ORIENTATION['landscape-primary']
            || deviceOrientation === DEVICE_ORIENTATION['landscape-secondary']) {
            this.uiService.showRightPane = false;
            // this.navService.popWithPageTracking();
          }
        }
      });
    }
  }
  ionViewWillLeave() {
    //unsubscribe to orintation change detection
    if (this._screenOrientation$) {
      this._screenOrientation$.unsubscribe();
      this._screenOrientation$ = null;
    }
  }

  ngOnDestroy() {
    if(!(this.activityService.selectedActivity && this.uiService.activeView === 'Sample')){
      console.log('destroying sample details page')
      this.sampleService.samplingDetailsViewMode = undefined;
      this.sampleService.validatedLicenses = [];
      this.footerService.samplingInModalViewActive = false;
      this.events.unsubscribe('selectedActivityChangedInBackround');
      this.events.unsubscribe(EventName.SAMPLEELLIGIBILITESFOOTERBUTTON);
      this.events.unsubscribe(EventName.ALLOCATION_SIGNATURE_FOOTER_BUTTON);
      this.events.unsubscribe('updateMandatoryField');
      this.events.unsubscribe('updateMandatoryField_footer');
    }
    this.viewDestroyed = true;
    this.ngDestroy$.next(true);
  }
  ngAfterViewInit() {
    //this.content.resize();
  }

  private _checkLicenses(customerAllocId: string, licenseIds: string[]): { isValid: boolean, validatedLicenses: ValidatedCustomerLicense[] } {
    const result: { isValid: boolean, validatedLicenses: ValidatedCustomerLicense[] } = this.sampleService.validateLicenses(customerAllocId, licenseIds,
                                                              this.selectedAddressObj ? this.selectedAddressObj.country : '',
                                                              this.selectedAddressObj ? this.selectedAddressObj.state : '',
                                                              this.selectedAddressObj ? this.selectedAddressObj.customerAddressID : '',);
    return result;
  }

  private _initSectionHeaderDetails(): void {
    this.quantitiesHeader = {
      id:'quantities-header',
      title: this.translate.instant('QUANTITIES_CAP'),
      isRequired: !(this.activity.status === 2 || this.activityService.teamViewActive || !this.productsStr),
      controls: [],
    };
    let detailsHeaderControls = [];
    if (this.inMeetingPresentationFlow) {
      detailsHeaderControls.push({
        id: 'allocation_order_scrap',
        text: this.translate.instant('SCRAP'),
        isDisabled: (this.activity.statusString === 'Completed' || this.activityService.teamViewActive || this.backgroundUploadInProgress) ? true : false,
      },
      {
        id: 'allocation_order_complete',
        text: (this.activity.statusString === 'Completed') ? this.translate.instant('ACTIVITY_SHOW_ACKNOWLEDGEMENT') : this.translate.instant('ACTIVITY_COMPLETE_ORDER'),
        isDisabled: (this.activity.statusString === 'Completed' && !this.activityService.teamViewActive && !this.backgroundUploadInProgress) ? false : ((this.activityService.teamViewActive || this.isGetSignatureDisabled || this.backgroundUploadInProgress || !this._isInputLotValueValid || this.isAllQuantitiesInValid) ? true : false),
      });
    }
    this.detailsHeader = {
      id:'details-header',
      title: this.translate.instant('DETAILS_CAP'),
      controls: detailsHeaderControls
    };
  }
  private _initFooterButtons(): void {
    if (this.inMeetingPresentationFlow) {
      this.activity = this.sampleService.inMeetingAllocationActivity;
      this.footerService.initButtons(FooterViews.PREVIEW_MEETING_ACTIVITY);
    } else if (this.sampleService.samplingDetailsViewMode === SamplingDetailsViewMode.CREATE_FROM_MEETING || 
      this.sampleService.samplingDetailsViewMode === SamplingDetailsViewMode.CREATE_FROM_EVENTS) {
      this.activity = this.sampleService.inMeetingAllocationActivity;
      this.footerService.initButtons(FooterViews.SampleDropInMeetingDetails);
    }
    else if (this.activityService.selectedActivity instanceof SampleActivity) {
      this.activity = this.activityService.selectedActivity as SampleActivity;
      this.footerService.initButtons(FooterViews.SampleDropDetails);
    }
  }
  private _updateFooterButtons(): void {
    if (this.inMeetingPresentationFlow) return;
    //enable/disable footer buttons based on sample form filling status
    if(!this.footerService.getActiveButtonsList().some(id => id === 'samplingeligibilities')){
      this._initFooterButtons();
    }
    this.footerService.disableButton(['samplingeligibilities', 'signature', 'contactsAllocation', 'cancelSamplingInMeeting', 'cancelmeeting']);
    if (this.backgroundUploadInProgress) return;
    if (this.activity.status !== 2) {
      if (this.elligibleAllocations.length === 0) {
        this._getElligibleAllocations();
      }
      if (!this.activityService.teamViewActive && this.selectedAddress && this.elligibleAllocations && this.elligibleAllocations.length !== 0) {
        this.footerService.enableButtons(['samplingeligibilities']);
      }
      if (!this.activityService.teamViewActive) {
        this.footerService.enableButtons([this.inMeetingSamplingFlow ? 'cancelSamplingInMeeting' : 'cancelmeeting']);
        this.footerService.enableButtons(['contactsAllocation'])
      }
      if (!this.isGetSignatureDisabled && this._isInputLotValueValid && !this.isAllQuantitiesInValid) {
        this.footerService.enableButtons(['signature']);
      }
      if (!this._isInputLotValueValid || this.isAllQuantitiesInValid) {
        this.footerService.disableButton(['signature']);
      }
    } else {
      this.disableFooterbuttons();
    }
  }

  private enableFooterButtonsForMeeting() {
    // Allocation created from Meeting structure either enabled all the options in FAB or scrap button will be disabled
    // so re-initiate the footer for the meeting.
    if (this.inMeetingSamplingFlow) {
      if (this.uiService.activeView === "Appointment" || this.uiService.activeView === "activityTimelineDetails") {
        this.footerService.initButtons(FooterViews.Activities);
      }
    }
  }

  private disableFooterbuttons() {
    this.footerService.disableButton(['cancelSamplingInMeeting', 'cancelmeeting', 'samplingeligibilities', 'contactsAllocation']);
    this.footerService.enableButtons(['signature']);
  }

  public onPageTitleControlClick(id: string) {
    switch (id) {
      case 'allocation_order_scrap':
      case 'allocation_order_complete':
        this.onSectionHeaderControlClick(id);
        break;
      case 'close':
        this.onClosePage();
        break;
      default:
        console.log('Unhandled switch case statement');
        break;
    }
  }

  public onSectionHeaderControlClick(id: string){
    switch(id){
        case 'allocation_order_scrap':
            this.scrapAllocation();
            break;
        case 'allocation_order_complete':
            this.handleSignatureCapture();
            break;
        default:
            console.log('Unhandled switch case statement');
            break;
    }
}

  public get isGetSignatureDisabled(): boolean {
    if (!this.activity) return;
    let flag = true;
    let isInvalid = false;
    if (this.activity.statusString === 'Completed') {
      flag = false;
    }else if (this.selectedAddress && this.activity.samples.length > 0) {
      flag = false;
      let quantity = 0;
      for(let i=0; i<this.activity.samples.length; i++){
        const sku = this.activity.samples[i];
        if(!sku.deleted){
          if (!(sku['totalQuantity'] && sku.totalQuantity > 0)) {
            flag = true;
            break;
          }else{
            quantity = (quantity-0)+(sku.totalQuantity-0);
          }
          if (sku.isInvalid) {
            isInvalid = true;
          }
        }
      }
      if(quantity === 0) flag = true;
    }
    if(this._invalidValuePairs.length > 0){
      flag = true;
    }
    if (!this.inMeetingPresentationFlow) {
      if (flag || isInvalid || !this.isThereEligibleProductWithNoLicenseIssue) {
        this.footerService.enableButtons(['signature'], false);
      } else {
        this.footerService.enableButtons(['signature']);
      }
    }
    return (flag || isInvalid || !this.isThereEligibleProductWithNoLicenseIssue);
  }

  handleSignatureCapture() {
    if (!this.isCurrentActivityCompleted && (this.isGetSignatureDisabled || this.activityService.teamViewActive)) return;

    // Do the final validation before
    if (!this.isCurrentActivityCompleted) {
      this._initAllocationQuantitiesDetails();
      this.initAllDataModels();
      this._updateFooterButtons();
      this._cd.detectChanges();
    }

    if (this.isCurrentActivityCompleted) this.trackingService.tracking('AllocationShowSignature', TrackingEventNames.ALLOCATIONS);
    if (!this.isCurrentActivityCompleted) this.trackingService.tracking('AllocationOrderSignature', TrackingEventNames.ALLOCATIONS);
    if (this.sampleService.samplingDetailsViewMode === SamplingDetailsViewMode.CREATE_FROM_MEETING || 
      this.sampleService.samplingDetailsViewMode === SamplingDetailsViewMode.CREATE_FROM_EVENTS) {
      //Modal view uses internal nav in-meeting sampling flow
      this.navCtrl.push(CaptureAllocationOrderComponent, {modalView:{},orderCompletionEventHandler:(signatureData)=>this._handleOrderCompletion(signatureData)}, {progressAnimation: false });
    } else if (this.sampleService.samplingDetailsViewMode === SamplingDetailsViewMode.CREATE_FROM_MEETING_PRESENTATION) {
      this.navService.pushChildNavPageWithPageTracking(CaptureAllocationOrderComponent, PageName.CaptureAllocationOrderComponent,PageName.PresentationMeetingComponent ,{orderCompletionEventHandler:(signatureData)=>this._handleOrderCompletion(signatureData)});
    } else {
      //regular flow uses master nav
      if(this.navService.getCurrentPageName() !== PageName.CaptureAllocationOrderComponent ){
        this.navService.pushWithPageTracking(CaptureAllocationOrderComponent, PageName.CaptureAllocationOrderComponent, {orderCompletionEventHandler:(signatureData)=>this._handleOrderCompletion(signatureData)});
      }
    }
  }

  gotoContactSelection() {
    if (this.activity.status === 2 || this.activityService.teamViewActive || this.activity.isFromXperiences) return;
    //go to contact selection screen
    this.uiService.showNewActivity = false;
    this.contactService.contactPageMode = ComponentViewMode.SELECTION;
    this.contactService.accessedContactListFrom = PageName.NewSampleActivityComponent;
    if (this.sampleService.samplingDetailsViewMode === SamplingDetailsViewMode.CREATE_FROM_MEETING ||  
      this.sampleService.samplingDetailsViewMode === SamplingDetailsViewMode.CREATE_FROM_EVENTS) {
      this.uiService.prevView = this.uiService.activeView;
      this.uiService.activeView = 'contactDetails';
      // //@ts-ignore
      // if (!this.navCtrl.el.classList.contains('fullStretchView')) {
      //   //@ts-ignore
      //   this.navCtrl.el.classList.add('fullStretchView');
      // }
      if (!document.getElementsByClassName('modal-wrapper')[0].classList.contains('fullStretchView')) {
        document.getElementsByClassName('modal-wrapper')[0].classList.add('fullStretchView');
      }
      this.navCtrl.push(ContactPageComponent, {modalView:{}}, { progressAnimation: false });
      return;
    } else if (this.sampleService.samplingDetailsViewMode === SamplingDetailsViewMode.CREATE_FROM_MEETING_PRESENTATION) {
      this.uiService.activeView = 'contactDetails';
      this.uiService.prevView = this.uiService.activeView;
    } else {
      this.uiService.activeView = 'contactDetails';
      this.uiService.prevView = 'Sample';
    }
    this.navService.pushWithPageTracking(ContactPageComponent, PageName.ContactPageComponent, null, PageName.ContactPageComponent);
  }

  async openAddressPicker(myEvent) {
    //open addresses list for the contact in popover
    // let contact = this.contactService.contacts.find((o)=>{
    //   return o.ID == this.activity.contactID
    // })
    // let contactAddressList = contact.addressesList;
    if (this.activityService.teamViewActive || this.isCurrentActivityCompleted) return;
    if (!this.popover && this.contactAddressList && this.contactAddressList.length > 0) {
      this.popover = await this.popoverCtrl.create({component: PopoverComponent,
                                                    componentProps: { samplingAddressList: this.contactAddressList, field: 'sampling-address' },
                                                    event: myEvent
                                                    });
      this.popover.onDidDismiss().then(async (data: any) => {
        data = data.data;
        if (data && data.contactAddress
            && (!this.selectedAddressObj
                || (this.selectedAddressObj && this.selectedAddressObj.addressId !== data.contactAddress.addressId))) {
          this.selectedAddressObj = data.contactAddress;
          this.selectedAddress = data.contactAddress.compositeAdd;

          // Address changed. Reset slate.
          this._clearAllSamples();
          await this._updateCurrentSampleActivity();
        }
        this._initAllocationQuantitiesDetails();
        this.initAllDataModels();
        this._updateFooterButtons();
        this._cd.detectChanges();
        this.popover = undefined;
      })
      this.popover.present();
    }
  }

  scrapAllocation() {
    if (this.activity.statusString !== 'Completed' && !this.activityService.teamViewActive) {
      if (this.inMeetingSamplingFlow) {
        this.scrapInMeetingSampleDrop();
      } else {
        this.events.publish('deleteMeeting');
      }
    }
  }

  onClosePage() {
    this.trackingService.tracking('AllocationOrderBack', TrackingEventNames.ALLOCATIONS)
    const currentPage: PageName = this.navService.getCurrentPageName();
    const currentPagecurrentMasterPage: PageName = this.navService.getCurrentMasterPageName();
    //going back in activities page right pane
    if (!this.inMeetingSamplingFlow && currentPage === PageName.ActivitiesPageComponent) {
      // this.activityService.isActivityUpdate = true;
      this.activityService.prevSelectedActivity = this.activityService.selectedActivity;
      this.activityService.selectedActivity = undefined;
      if (this.device.shouldBeMobileView) {
        this.uiService.activeView = '';
        this.uiService.showRightPane = false;
        this.activityService.shouldScrollToPrevSelectedActivity = !this.activityService.didOpenDetailPageFromAgendaPage;
      }
      if (this.uiService.activitiesPageTab === 'plans') {
        this.uiService.activeView = 'ActivitiesPageRightPaneNav';
        this.uiService.showNewActivity = false;
        this.uiService.showRightPane = true;
        this.footerService.initButtons('');
        if (this.uiService.activitiesPagePlanTabSelectedPlan === 'planTabTodo') {
          if (this.device.shouldBeMobileView) {
            this.uiService.showRightPane = false;
          }
          this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView);
        } else {
          this.navService.popToRootChildNavPageWithPageTracking();
          // this.navService.popChildNavPageWithPageTracking();
        }
      }
      if (!this.footerService.isActivitiesButton) {
        this.events.publish('go-back-to-xperiences', PageName.NewSampleActivityComponent);
        return;
      }
      this.activityService.didOpenDetailPageFromAgendaPage = false;
      this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView);
    }
    if (!this.inMeetingSamplingFlow && currentPage === PageName.ScientificActivityPage) {
      this.footerService.initButtons('');
      if(this.from === PageName.ContactDetailsComponent){
        this.navService.popChildNavPageWithPageTracking();
      }
    }
    if (currentPage === PageName.NewSampleActivityComponent && currentPagecurrentMasterPage === PageName.ActivitiesPageComponent) {
      // this.activityService.isActivityUpdate = true;
      this.uiService.showRightPane = false;
      this.activityService.selectedActivity = undefined;
      this.navService.popToRootWithPageTracking().then(() => {
        this.events.publish('scrollToDate');
      })
    }
    //going back in allocations tool right pane
    else if (currentPage === PageName.AllocationComponent) {
      this.uiService.activeView = '';
      this.activityService.selectedActivity = undefined;
    }
    //going back in contacts tool right pane
    else if (currentPagecurrentMasterPage === PageName.ContactPageComponent && !this.inMeetingSamplingFlow) {
      this.activityService.selectedActivity = undefined;
      this.uiService.showNewActivity = false;
      this.navService.popChildNavPageWithPageTracking();
      this.footerService.initButtons(FooterViews.Contacts);
    }
    //going back from Events Sampling creation flow
    else if (currentPagecurrentMasterPage === PageName.EventsToolPageComponent && this.inMeetingSamplingFlow) {
      this.activityService.selectedActivity = undefined;
      this.uiService.showNewActivity = false;
      this.inMeetingSamplingFlow = false;
      if (this.activity && this.activity.statusString === 'Completed') {
        this.modalCtrl.dismiss({ isCompleted: true });
      }
      else {
        this.modalCtrl.dismiss();
      }
    }
    //going back from modal view of sampling opened in meeting
    else if (this.inMeetingSamplingFlow) {
      this.sampleService.samplingDetailsViewMode = undefined;
      this.footerService.samplingInModalViewActive = false;
      this.inMeetingSamplingFlow = false;
      this.sampleService.inMeetingAllocationActivity = undefined;
      if (this.activity && this.activity.statusString === 'Completed') {
        this.modalCtrl.dismiss({ isCompleted: true });
      }
      else {
        this.modalCtrl.dismiss();
      }
      if (currentPage === PageName.PresentationMeetingComponent) {
        this.uiService.activeView = 'Meeting';
        this.footerService.initButtons(FooterViews.PreviewMeeting);
      } else if (currentPage === PageName.CallPlanComponent) {
        this.callPlanService.callPlanPageView = 'CallPlanNewMeetingDetails';
        this.footerService.initButtons(FooterViews.Activities);
      } else if(currentPage === PageName.ActivitiesPageComponent) {
        this.uiService.activeView = 'ActivitiesPageRightPaneNav';
        this.footerService.initButtons(FooterViews.Activities);
      }
    }
  }

  async scrapInMeetingSampleDrop() {
    const activity = this.sampleService.inMeetingAllocationActivity;

    this.alertService.showAlert({
      title: this.translate.instant('SCRAP_ALLOCATION_ORDER'),
      message: this.translate.instant('POP_R_U_SURE_SCRAP_ALLOC_O')
    },
    this.translate.instant('SCRAP')
    ).then (res => {
      if(res.role === 'ok') {
        this.uiService.displayLoader();
        if (activity.type === ActivityType.Sample) {
          let payload: CreateSampleDropRequestBody = new CreateSampleDropRequestBody(activity);
          payload.statecode = 2;
          payload.statuscode = 3;
          (activity as SampleActivity).state = 2;
          (activity as SampleActivity).status = 3;
          this.sampleDataService.updateSampleActivity(payload, activity).then(() => {
            this.eventService.publish("allocationOrderActivityDeleted", activity);
            if (!this.uiService.toolsActivityActive){
              this.events.publish("refreshAgenda");
            } else this.uiService.agendaRefreshRequired = true;
            this.sampleService.samplingDetailsViewMode = undefined;
            this.sampleService.inMeetingAllocationActivity = undefined;
            this.activityService.samplingToInMeetingActivityMapping.delete(this.activityService.selectedActivity.ID);
            this.uiService.dismissLoader();
            this.onClosePage();
            return;
          }).catch(() => {
            // this.notificationService.notify("Allocation scrap failed.','Allocation Details','top',ToastStyle.DANGER);
            this.uiService.dismissLoader();
            return;
          })
        }
      }
    });
  }
  initAllDataModels() {
    this._initSectionHeaderDetails();
    this._initPageTitle();
    this.getStatusFormField();
    this.getOrderNumberFormField();
    this.getCustomerFormField();
    this.getAddressFormField();
    this.getElligibleProductsFormField();
  }

  public getStatusFormField() {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('STATUS'),
      inputText: this.statusString,
      id: 'status-field',
      isReadOnly: true,
      isDisabled: true,
      showArrow: false,
    };
    this.statusFormField = viewData;
  }

  public getOrderNumberFormField() {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('ORDER_NUMBER'),
      inputText: this.orderNumber.toLowerCase() === 'Allocation Order'.toLowerCase() ? this.translate.instant('ALLOWCATION_ORDER') : this.orderNumber,
      id: 'order-number-field',
      isReadOnly: true,
      isDisabled: true,
      showArrow: false,
    };
    this.orderNumberFormField = viewData;
  }

  public getCustomerFormField() {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.utilityService.globalCustomerText,
      inputText: _.isEmpty(this.activity.contactName) ? '' : this.activity.contactName,
      customPlaceholderLabel: (this.activityService.teamViewActive || this.activity.status === 2) ? this.translate.instant('NO_CUSTOMER'):this.translate.instant('SELECT_WITH_GLOBALCUSTOMER', {globalCustomerText :this.utilityService.globalCustomersText}),
      id: 'customer-field',
      isReadOnly: true,
      isDisabled: this.activity.status === 2 || this.activityService.teamViewActive,
      showArrow: !(this.activity.status === 2 || this.activityService.teamViewActive),
      isRequired: !(this.activity.status === 2 || this.activityService.teamViewActive),
      errorMessage: this.activityService.getErrorMessageRequiredField(),
      isEmptyRequiredField: this._isClickedContact && !this.activity.contactName ? true : false,
      formFieldType: FormFieldType.NEW_PAGE_SELECT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    this.customerFormField = viewData;
  }

  public getAddressFormField() {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('ELIGIBLE_ADDRESS'),
      inputText: (!this.noEligibleAddress && this.selectedAddress)?this.selectedAddress:'',
      customPlaceholderLabel:(this.noEligibleAddress || this.activityService.teamViewActive) ? this.translate.instant('ACTIVITY_NO_ELIGIBLE_ADDRESS'):(!this.selectedAddress)?this.translate.instant('SELECT_ADDRESS'):'',
      id: 'address-field',
      isReadOnly: true,
      isDisabled: this.activity.status === 2 || this.activityService.teamViewActive || this.noEligibleAddress,
      showArrow: !(this.activity.status === 2 || this.activityService.teamViewActive || this.noEligibleAddress),
      isRequired: !(this.activity.status === 2 || this.activityService.teamViewActive || !this.activity.contactName),
      errorMessage: this.activityService.getErrorMessageRequiredField(),
      isEmptyRequiredField: this._isClickedAddress && !this.noEligibleAddress && !this.selectedAddress ? true : false,
      formFieldType: FormFieldType.POPOVER_SELECT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    this.addressFormField = viewData;
  }

  public getElligibleProductsFormField() {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('ELIGIBLE_PRODUCTS_CAMEL_CASE'),
      inputText: this.productsStr,
      placeholderLabel: !this.selectedAddress
                          ?   this.translate.instant('SELECT') + ' ' + this.translate.instant('ELIGIBLE_PRODUCTS_CAMEL_CASE')
                            : (this.activity.status === 2 || this.activityService.teamViewActive)
                              ?   this.translate.instant('ACTIVITY_NO_ELIGIBLE_PRODUCTS')
                                : ((this.elligibleAllocations && this.elligibleAllocations.length !== 0))
                                  ?   this.translate.instant('ELIGIBLE_PRODUCTS_CAMEL_CASE')
                                    : this.translate.instant('ACTIVITY_NO_ELIGIBLE_PRODUCTS'),
      id: 'elligible-product-field',
      isReadOnly: true,
      isDisabled: !this.selectedAddress || this.activity.status === 2 || this.activityService.teamViewActive || (this.elligibleAllocations && this.elligibleAllocations.length === 0),
      showArrow: !(this.activity.status === 2 || this.activityService.teamViewActive || (this.elligibleAllocations && this.elligibleAllocations.length === 0) || !this.selectedAddress),
      isRequired: !(this.activity.status === 2 || this.activityService.teamViewActive || !this.selectedAddress),
      errorMessage: this.activityService.getErrorMessageRequiredField(),
      isEmptyRequiredField: this._isClickedProduct && this.selectedAddress && !this.productsStr ? true : false,
      formFieldType: FormFieldType.NEW_PAGE_SELECT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    this.elligibleProductsFormField = viewData;
  }

  private handleFormFieldEvent(id, event, eventName) {
    if (id) {
      if(this.backgroundUploadInProgress) return;
      switch (id) {
        case 'customer-field':
          this._isClickedContact = true;
          this._isClickedAddress = false;
          this._isClickedProduct = false;
          this.gotoContactSelection();
          break;
        case 'address-field':
          this._isClickedAddress = true;
          this._isClickedProduct = false;
          this.openAddressPicker(event);
          break;
        case 'elligible-product-field':
          this._isClickedProduct = true;
          this._handleElligibleProductSelect();
          break;
        default:
          console.log('Unhandled switch case statement');
          break;
      }
    }
  }

  private get productsStr(): string {
    let str = '';
    if(this.activity && this.activity.samples && this.activity.samples.length !== 0){
      let activitySamples = this.activity.samples.filter(sample => !sample.deleted);
      activitySamples = activitySamples.sort((a,b)=> (a.indskr_skuname.toLowerCase()>b.indskr_skuname.toLowerCase())? 1 : -1);
      if(activitySamples.length === 1){
        str = activitySamples[0].indskr_skuname;
      }else if(activitySamples.length > 1){
        str = activitySamples[0].indskr_skuname + ' +'+(activitySamples.length-1);
      }
    }
    return str;
  }

  private async _handleElligibleProductSelect(){
    this._getElligibleAllocations();
    if(this.elligibleAllocations && this.elligibleAllocations.length !== 0){
      const doReceiptCheck = this.authService.hasFeatureAction(FeatureActionsMap.RECIEPTS_TAB);
      const listDetail: MainToolTemplateDetail = {
        title: this.translate.instant('ELIGIBLE_PRODUCTS_CAMEL_CASE'),
        dividerTitle:this.translate.instant('ALL_ELIGIBLE_PRODUCTS_CAP'),
        isSearchEnabled:true,
        showLeftHeaderButton: true,
        leftHeaderBtnImgSrc: 'assets/imgs/header_cancel.svg',
        leftHeaderBtnText: this.translate.instant('CANCEL'),
        showRightHeaderButton: true,
        rightHeaderBtnImgSrc: 'assets/imgs/header_complete.svg',
        rightHeaderBtnText: this.translate.instant('DONE'),
        orderByPropertyName: 'primaryTextRight',
        searchTitle: this.translate.instant('SEARCH_ELIGIBLE_PRODUCTS'),
        isListSelectionEnabled:true,
        listSelectionType: MainToolTemplateListSelectionType.MULTIPLESELECTION,
        navOptions: { animate: false },
        eventsHandler: (data: any, eventTarget: string, refData: MainToolTemplateDetail)=>  this._handleElligibleProductComponentEvent(data,eventTarget,refData),
        searchHandler:(text: string) => this._handleElligibleProductsSearch(text),
        data: this.elligibleAllocations.map((alloc: CustomerSampleAllocation) => {
          let isSelected = false;
          let childItems = [];

          childItems = this._getApplicableLotsForSKU(alloc.sampleSKUId, alloc.id);
          let totalQuantityRemaining;
          if (childItems && childItems.length !== 0 && this.authService.hasFeatureAction(FeatureActionsMap.RECIEPTS_TAB) && this.activity.status !== 2) {
            totalQuantityRemaining = childItems.map(item => {
              if (item.lotRefData && item.lotRefData.totalQuantityRemaining) {
                return item.lotRefData.totalQuantityRemaining;
              } else {
                return 0;
              }
            }).reduce((a, b) => (a - 0) + (b - 0), 0);
          }
          // each lot number validation
          if (childItems && childItems.length !== 0 && this.activity.status !== 2) {
            childItems.forEach(item => {
              const totalNumber: number = item.lotRefData.totalQuantityRemaining || 0;
              const inputNumber: number = item.view.inputValue || 0;
              this._isInputLotValueValid = true;
              if (inputNumber > 0) {
                const _checkLotValueValid: boolean = totalNumber - inputNumber >= 0;
                this._isInputLotValueValid = this._isInputLotValueValid && _checkLotValueValid;
              }
            });
          }

          if(this.activity && this.activity.samples && this.activity.samples.some(sample => !sample.deleted && sample.indskr_customersampleproductid === alloc.id)){
            isSelected = true;
          }

          const viewData: MainCardViewDataModel = {
            id: alloc.id,
            primaryTextLeft: '',
            secondaryTextLeft: '',
            showEndIcon: true,
            mainItemCssClass: 'selector-item',
            isItemSelectedForSelectionView: isSelected,
            endIconType: isSelected?'indegene-selectors-checkmark-icon':'indegene-selectors-add-icon',
            endIconCssClass: isSelected?'checkmark-icon':'add-icon',
            primaryTextRight: (alloc.sampleSKUName)+((doReceiptCheck)?' ('+alloc.onHandLotsQuantity+')':''),
            secondaryTextRight:this.translate.instant('REMAINING'),
            secondaryTextRightTwo:(!alloc.isUnlimited)?alloc.remaining:this.translate.instant('UNLIMITED'),
            isSecondaryTextRightTwoPillType: true,
            showArrow: false,
            arrowType: '',
            noSeparationLine: true,
            childItems: !_.isEmpty(childItems) ? childItems.map((item)=>{
              const viewModel: MainCardViewDataModel = {
                id: item.view.id,
                primaryTextLeft: '',
                secondaryTextLeft:'',
                primaryTextRight: item.view.label,
                secondaryTextRight: item.view.secondaryInfoLabel
              };
              return viewModel;
            }) : [],
          }
          return viewData;
        }),
      };

      // Set flag to true if navigation is for Product Selection screen to show Parent-Child
      this.sampleService.isHandleElligibleProductSelect= true;

      this.trackingService.tracking('AllocationOrderShowEligiblities', TrackingEventNames.ALLOCATIONS);
      //should not have added real-time fetch for lots at every click on product button.
      //But if its required, it should be synchronous as otherwise, conflicts in lot validation logic
      await this.fetchRealTimeLotDetails();

      //go to product selection page
      if (this.sampleService.samplingDetailsViewMode === SamplingDetailsViewMode.CREATE_FROM_MEETING || 
         this.sampleService.samplingDetailsViewMode === SamplingDetailsViewMode.CREATE_FROM_EVENTS) {
        // //@ts-ignore
        // if (!this.navCtrl.el.classList.contains('fullStretchView')) {
        //   //@ts-ignore
        //   this.navCtrl.el.classList.add('fullStretchView');
        // }

        if (!document.getElementsByClassName('modal-wrapper')[0].classList.contains('fullStretchView')) {
          document.getElementsByClassName('modal-wrapper')[0].classList.add('fullStretchView');
        }
        setTimeout(() => {
          this.navCtrl.push(MainToolTemplateComponent, {viewData:listDetail, isNavWithinModalView: true, navWithinModalRef: this.navCtrl}, { progressAnimation: false });
        }, 10);
        return;
      }
      setTimeout(() => {
        if(this.navService.getCurrentMasterPageName() === PageName.MainToolTemplateComponent) {
          this.navService.popWithPageTracking();
        }
        this.navService.pushWithPageTracking(MainToolTemplateComponent,PageName.NothingSelectedView,{viewData:listDetail},PageName.MainToolTemplateComponent);
      }, 10);
      // this.fetchRealTimeLotDetails();
    }
  }

  private _handleElligibleProductsSearch(text: string): string[]{
    let ids: Array<string> = [];
    if(text.length >= 1){
      ids = this.elligibleAllocations.filter(alloc=>{
        return alloc.sampleSKUName.trim().toLowerCase().includes(text.trim().toLowerCase());
      }).map(alloc => alloc.id);
    }else{
      ids = this.elligibleAllocations.map(alloc => alloc.id);
    }
    return ids;
  }

  private async fetchRealTimeLotDetails() {
    if (!this.device.isOffline && !this.device.isUserStateOffline ) {
      this.uiService.displayLoader();
      const dataRangeWithFutureBoundBySixMonths = this.authService.getFromToDateRangeInUTCMiliSec(undefined);
      await this.sampleDataService.syncLots(dataRangeWithFutureBoundBySixMonths);
      // this.lotsUpdated = true;
      this.uiService.dismissLoader();
    }
  }

  private async _handleElligibleProductComponentEvent(data: any,eventTarget: string,refData: MainToolTemplateDetail){

    if (eventTarget && (eventTarget === 'RightHeaderButtonClick' || eventTarget === 'LeftHeaderButtonClick')) {

      // this.fetchRealTimeLotDetails();
      this.setViewData();
      if (this.sampleService.samplingDetailsViewMode === SamplingDetailsViewMode.CREATE_FROM_MEETING ||  
        this.sampleService.samplingDetailsViewMode === SamplingDetailsViewMode.CREATE_FROM_EVENTS) {
        // //@ts-ignore
        // if (this.navCtrl.el.classList.contains('fullStretchView')) {
        //   //@ts-ignore
        //   this.navCtrl.el.classList.remove('fullStretchView');
        // }
        if (!document.getElementsByClassName('modal-wrapper')[0].classList.contains('fullStretchView')) {
          document.getElementsByClassName('modal-wrapper')[0].classList.add('fullStretchView');
        }
      }
    }
    if(eventTarget && eventTarget === 'RightHeaderButtonClick'){
      if(data && data.isDone){
        if(data.removedItems && Array.isArray(data.removedItems) && data.removedItems.length >= 0){
          data.removedItems.forEach(item => {
            const activitySampleDrop = this.activity.samples.find(sampleDrop => sampleDrop.indskr_customersampleproductid === item.id);
            if(activitySampleDrop){
              activitySampleDrop.deleted = true;
              activitySampleDrop.totalQuantity = 0;
              if(activitySampleDrop.lots && activitySampleDrop.lots.length !== 0){
                activitySampleDrop.lots.map(lot=> {
                  lot.deleted = true;
                  lot.indskr_quantity = 0;
                  return lot;
                });
              }
            }
          });
        }
        if(data.selectedItems && Array.isArray(data.selectedItems) && data.selectedItems.length >= 0){
          data.selectedItems.forEach(item => {
            const activitySampleDrop = this.activity.samples.find(sampleDrop => sampleDrop.indskr_customersampleproductid === item.id);
            if(!activitySampleDrop){
              const droppedAllocation = this.elligibleAllocations.find(alloc => alloc.id === item.id);
              if(droppedAllocation){
                const newSampleDrop: SampleDetailInSampleActivity = {
                  indskr_skuid: droppedAllocation.sampleSKUId,
                  indskr_skuname: droppedAllocation.sampleSKUName,
                  deleted: false,
                  indskr_customersampleproductid: droppedAllocation.id,
                  lotsCount: 0,
                  totalQuantity: 0,
                  indskr_isunlimited: droppedAllocation.isUnlimited,
                  indskr_esignaturenecessaryflag: droppedAllocation.eSignatureNecessary,
                  samplelimitpervisit: droppedAllocation.sampleDropLimitPerVisit,
                  lots: [],
                };
                this.activity.samples.push(newSampleDrop);
              }
            }else{
              activitySampleDrop.deleted = false;
            }
          });
        }
        this._updateCurrentSampleActivity();
      }
    }
  }

  private _handleMainSkuClick(id,event,eventName,refData){
    if(id && eventName && eventName === 'endIcon'){
      const targetSampleDrop = this.activity.samples.find(sample => sample.indskr_customersampleproductid === id);
      if(targetSampleDrop && this.activity.status !== 2 && !this.activityService.teamViewActive){
        targetSampleDrop.deleted = true;
        if(targetSampleDrop.lots && targetSampleDrop.lots.length !== 0){
          targetSampleDrop.lots.map(lot=> {
            lot.indskr_quantity = 0;
            lot.deleted = true;
            return lot;
          });
        }
        this._updateCurrentSampleActivity();
      }
    }else if(id){
      const quantityDetail = this.allocationQuantitiesData.find(a=> a.id === id);
      if(quantityDetail && quantityDetail.childItems && quantityDetail.childItems.length !== 0){
        quantityDetail.isExpanded = !quantityDetail.isExpanded;
        if(quantityDetail.isExpanded){
          quantityDetail.view.isAccordionText = true;
          quantityDetail.view.accordionIconType = 'accordion-minus';
        }else{
          quantityDetail.view.isAccordionText = false;
          quantityDetail.view.accordionIconType = 'accordion-add';
        }
        this._cd.detectChanges();
      }
    }
  }

  private _validateQuantityEntered(id, value): boolean {
    let flag:boolean = false;
    try {
      value = parseInt(value);
    } catch (error) {

    }
    if (value === null || value === undefined || isNaN(value)) {
      value = 0;
    }
    const targetLot = this.sampleService.lots.find(lot => id.includes(lot.id));
    const targetSampleDrop = this.activity.samples.find(sample => id.includes(sample.indskr_customersampleproductid));
    let activitySampleDropLot;
    if(targetSampleDrop && targetSampleDrop.lots){
      activitySampleDropLot  = targetSampleDrop.lots.find(lot => id.includes(lot.indskr_lotid));
    }
    const targetAllocation = this.elligibleAllocations.find(alloc => id.includes(alloc.id));
    if (targetSampleDrop && targetLot && targetAllocation) {
      let elligibilityLimit = 0;
      if (this.authService.hasFeatureAction(FeatureActionsMap.RECIEPTS_TAB)) {
        if (targetSampleDrop.indskr_isunlimited) {
          elligibilityLimit = targetLot.totalQuantityRemaining;
        } else {
          const remainingAllocQuantity = targetAllocation.remaining - (targetSampleDrop.totalQuantity - (activitySampleDropLot?activitySampleDropLot.indskr_quantity:0));
          if (remainingAllocQuantity <= targetLot.totalQuantityRemaining) {
            elligibilityLimit = remainingAllocQuantity;
          } else {
            elligibilityLimit = targetLot.totalQuantityRemaining;
          }
        }
      } else {
        if (targetSampleDrop.indskr_isunlimited) {
          elligibilityLimit = null;
          return value >= 0;
        } else {
          elligibilityLimit = targetAllocation.remaining;
        }
      }
      flag = value <= elligibilityLimit && value >= 0;
    }
    return flag;
  }

  private handleLotQuantityFormField(id,event,eventName){
    this._cd.detectChanges();
    if (this.backgroundUploadInProgress) return;
    if(eventName === 'DetectChange'){

    }else if(eventName === 'InvalidValueEntered'){
      if(!this._invalidValuePairs.find(pair => pair.id === id)){
        this._invalidValuePairs.push({
          id: id,
          value: event.value,
        });
      }
      this._initSectionHeaderDetails();
      this._initPageTitle();
      this._updateFooterButtons();
      this._cd.detectChanges();
    }else {
      const idx = this._invalidValuePairs.findIndex(pair=>pair.id === id);
      if(idx>=0){
        this._invalidValuePairs.splice(idx,1);
        this._initSectionHeaderDetails();
        this._initPageTitle();
        this._updateFooterButtons();
        this._cd.detectChanges();
      }
    }
    if((eventName === 'input_value_confirm') && id){
      event.value = event.target.value;
      // try {
      //   event.value = parseInt(event.value);
      // } catch (error) {
      // }
      if (event.value === null || event.value === undefined || isNaN(event.value) || event.value ==='') {
        event.value = 0;
      }
      const targetLot = this.sampleService.lots.find(lot => id.includes(lot.id));
      const targetSampleDrop = this.activity.samples.find(sample => id.includes(sample.indskr_customersampleproductid));
      const targetAllocation = this.elligibleAllocations.find(alloc => id.includes(alloc.id));
      let isQuantityInValid = false;

      if(targetSampleDrop && targetLot && targetAllocation){
        //Check for valid input
        const hasLimitPerVisit = targetSampleDrop.samplelimitpervisit > 0;

        if(event && event.value >= 0 && this._validateQuantityEntered(id,event.value)){
          const targetSampleDropLot = targetSampleDrop.lots.find(lot => id.includes(lot.indskr_lotid));
          const intEventValue = parseInt(event.value);
          const hasLimitPerVisit = targetSampleDrop.samplelimitpervisit > 0;

          if(targetSampleDropLot && intEventValue >= 0){
            //Quantity Update Logic
            if(intEventValue !== targetSampleDropLot.indskr_quantity){

              //To check if samplelimitpervisit exist and value entered is more than that show Error
              if(hasLimitPerVisit && intEventValue > targetSampleDrop.samplelimitpervisit) {
                  // To set isQuantityInValid flag to true and update allocation Error message for that sample
                  isQuantityInValid = true;
                  // To set default error message of ALLOCATION_QUANTITY_EXCEEDS_ORDER_LIMIT
                  let errorMessage = this.translate.instant('ALLOCATION_QUANTITY_EXCEEDS_ORDER_LIMIT');
                  // To pass customersampleproductid to show user modified details with Error message based on isUnlimited flag
                  this.showErrorMessageForAllocationQuantitiesDetails(targetSampleDrop.indskr_customersampleproductid, errorMessage, isQuantityInValid);
              }
              else {
                targetSampleDropLot.indskr_quantity = intEventValue;
                targetSampleDropLot.deleted = (intEventValue >= 0) ? false : true;
                targetSampleDrop.totalQuantity = targetSampleDrop.lots.map(item => {
                  if(item.indskr_quantity){
                    return item.indskr_quantity;
                  }else {
                    return 0;
                  }
                }).reduce((a,b)=> (a-0)+(b-0),0);

                //To check if samplelimitpervisit exist and value entered is more than totalQuantity
                if(hasLimitPerVisit && targetSampleDrop.totalQuantity > targetSampleDrop.samplelimitpervisit) {
                    // To set isQuantityInValid flag to true and update allocation Error message for that sample
                    isQuantityInValid = true;
                    // To set default error message of ALLOCATION_QUANTITY_EXCEEDS_ORDER_LIMIT
                    let errorMessage = this.translate.instant('ALLOCATION_QUANTITY_EXCEEDS_ORDER_LIMIT');
                    // To pass customersampleproductid to show user modified details with Error message based on isUnlimited flag
                    this.showErrorMessageForAllocationQuantitiesDetails(targetSampleDrop.indskr_customersampleproductid, errorMessage, isQuantityInValid);
                }
                else {
                  this._updateCurrentSampleActivity();
                  this.showErrorMessageForAllocationQuantitiesDetails(targetSampleDrop.indskr_customersampleproductid, '', isQuantityInValid);
                }
              }
            }
            else {
               // To set isQuantityInValid flag to true and update allocation Error message for that sample
                let errorMessage = '';
                const quantityEntered = parseInt(event.value);

                //To check if samplelimitpervisit exist and value entered is more than that show Error
                if(quantityEntered > targetLot.totalQuantityRemaining) {
                  isQuantityInValid = true;
                  errorMessage = this.translate.instant('ALLOCATION_QUANTITY_EXCEEDS_ON_HAND');
                }
                //To check if samplelimitpervisit exist and value entered is more than that show Error
                else if(hasLimitPerVisit && parseInt(event.value) > targetSampleDrop.samplelimitpervisit) {
                  isQuantityInValid = true;
                  errorMessage = this.translate.instant('ALLOCATION_QUANTITY_EXCEEDS_ORDER_LIMIT');
                }
                //To check if samplelimitpervisit exist and value entered is more than totalQuantity
                else if(hasLimitPerVisit && targetSampleDrop.totalQuantity > targetSampleDrop.samplelimitpervisit) {
                  // To set isQuantityInValid flag to true and update allocation Error message for that sample
                  isQuantityInValid = true;
                  // To set default error message of ALLOCATION_QUANTITY_EXCEEDS_ORDER_LIMIT
                  errorMessage = this.translate.instant('ALLOCATION_QUANTITY_EXCEEDS_ORDER_LIMIT');
                  // To pass customersampleproductid to show user modified details with Error message based on isUnlimited flag
                  this.showErrorMessageForAllocationQuantitiesDetails(targetSampleDrop.indskr_customersampleproductid, errorMessage, isQuantityInValid);
                }
                // To check if targetAllocation not isUnlimited then check quantityEntered with remaining
                else if(!targetAllocation.isUnlimited)
                {
                  const quantityOfAllLotsBySKUId = quantityEntered +
                  _.sumBy(targetSampleDrop.lots.filter(lot =>  lot.indskr_lotid !== targetLot.id),
                  function(o) { return o.indskr_quantity; });

                  if (quantityOfAllLotsBySKUId > targetAllocation.remaining) {
                    isQuantityInValid = true;
                    errorMessage = this.translate.instant('ALLOCATION_QUANTITY_EXCEEDS_ELIGIBLE_LIMIT');
                  }
                }

              this.showErrorMessageForAllocationQuantitiesDetails(targetSampleDrop.indskr_customersampleproductid, errorMessage, isQuantityInValid);
            }
          }else if (intEventValue > 0){

            //To check if samplelimitpervisit exist and value entered is more than that show Error
            if(hasLimitPerVisit && intEventValue > targetSampleDrop.samplelimitpervisit) {
                // To set isQuantityInValid flag to true and update allocation Error message for that sample
                isQuantityInValid = true;
                // To set default error message of ALLOCATION_QUANTITY_EXCEEDS_ORDER_LIMIT
                let errorMessage = this.translate.instant('ALLOCATION_QUANTITY_EXCEEDS_ORDER_LIMIT');
                // To pass customersampleproductid to show user modified details with Error message based on isUnlimited flag
                this.showErrorMessageForAllocationQuantitiesDetails(targetSampleDrop.indskr_customersampleproductid, errorMessage, isQuantityInValid);
            }
            else {
              //New Quantity Add Logic
              targetSampleDrop.lots.push({
                indskr_quantity: intEventValue,
                indskr_lotid: targetLot.id,
                indskr_lotname: targetLot.name,
                indskr_lotvalidtodate: targetLot.validTo.getTime().toString(),
                indskr_lotvalidfromdate: targetLot.validFrom.getTime().toString(),
                indskr_lotnumber : targetLot.lotNumber,
                formattedDate: targetLot.validTo,
                deleted: false,
              });
              targetSampleDrop.totalQuantity = targetSampleDrop.lots.map(item => {
                if(item.indskr_quantity){
                  return item.indskr_quantity;
                }else {
                  return 0;
                }
              }).reduce((a,b)=> (a-0)+(b-0),0);

              //To check if samplelimitpervisit exist and value entered is more than totalQuantity
              if(hasLimitPerVisit && targetSampleDrop.totalQuantity > targetSampleDrop.samplelimitpervisit) {
                // To set isQuantityInValid flag to true and update allocation Error message for that sample
                isQuantityInValid = true;
                // To set default error message of ALLOCATION_QUANTITY_EXCEEDS_ORDER_LIMIT
                let errorMessage = this.translate.instant('ALLOCATION_QUANTITY_EXCEEDS_ORDER_LIMIT');
                // To pass customersampleproductid to show user modified details with Error message based on isUnlimited flag
                this.showErrorMessageForAllocationQuantitiesDetails(targetSampleDrop.indskr_customersampleproductid, errorMessage, isQuantityInValid);
              }
              else {
                this._updateCurrentSampleActivity();
                this.showErrorMessageForAllocationQuantitiesDetails(targetSampleDrop.indskr_customersampleproductid, '', isQuantityInValid);
              }
            }
          }
        }else{

          // To set isQuantityInValid flag to true and update allocation Error message for that sample
          let errorMessage = '';
          const quantityEntered = parseInt(event.value);

          //To check if samplelimitpervisit exist and value entered is more than that show Error
          if(quantityEntered > targetLot.totalQuantityRemaining) {
            isQuantityInValid = true;
            errorMessage = this.translate.instant('ALLOCATION_QUANTITY_EXCEEDS_ON_HAND');
          }
          //To check if samplelimitpervisit exist and value entered is more than that show Error
          else if(hasLimitPerVisit && parseInt(event.value) > targetSampleDrop.samplelimitpervisit) {
            isQuantityInValid = true;
            errorMessage = this.translate.instant('ALLOCATION_QUANTITY_EXCEEDS_ORDER_LIMIT');
          }
          //To check if samplelimitpervisit exist and value entered is more than totalQuantity
          else if(hasLimitPerVisit && targetSampleDrop.totalQuantity > targetSampleDrop.samplelimitpervisit) {
            // To set isQuantityInValid flag to true and update allocation Error message for that sample
            isQuantityInValid = true;
            // To set default error message of ALLOCATION_QUANTITY_EXCEEDS_ORDER_LIMIT
            errorMessage = this.translate.instant('ALLOCATION_QUANTITY_EXCEEDS_ORDER_LIMIT');
            // To pass customersampleproductid to show user modified details with Error message based on isUnlimited flag
            this.showErrorMessageForAllocationQuantitiesDetails(targetSampleDrop.indskr_customersampleproductid, errorMessage, isQuantityInValid);
          }
          // To check if targetAllocation not isUnlimited then check quantityEntered with remaining
          else if(!targetAllocation.isUnlimited)
          {
            const quantityOfAllLotsBySKUId = quantityEntered +
            _.sumBy(targetSampleDrop.lots.filter(lot =>  lot.indskr_lotid !== targetLot.id),
            function(o) { return o.indskr_quantity; });

            if (quantityOfAllLotsBySKUId > targetAllocation.remaining) {
              isQuantityInValid = true;
              errorMessage = this.translate.instant('ALLOCATION_QUANTITY_EXCEEDS_ELIGIBLE_LIMIT');
            }
          }

          // To pass customersampleproductid to show user modified details with Error message based on isUnlimited flag
          this.showErrorMessageForAllocationQuantitiesDetails(targetSampleDrop.indskr_customersampleproductid, errorMessage, isQuantityInValid);
        }
      }
    }
  }

  private showErrorMessageForAllocationQuantitiesDetails(indskr_customersampleproductid: string, errorMessage: string, isQuantityInValid: boolean){
    this.allocationQuantitiesData.forEach((element, index) => {
      if(element.id === indskr_customersampleproductid) {
        this.allocationQuantitiesData[index].errorMessage = errorMessage;
        this.allocationQuantitiesData[index].isQuantityInValid = isQuantityInValid;
      }
     });

     this.checkAllQuantitiesAreInValid();
  }

  private checkAllQuantitiesAreInValid(){
    this.isAllQuantitiesInValid =  this.allocationQuantitiesData.some((element)=>{
      return element.isQuantityInValid;
    });

    this._initSectionHeaderDetails();
    this._initPageTitle();
    this._updateFooterButtons();
    this._cd.detectChanges();
  }

  private _getElligibleAllocations(){
    const foundAllocations: Array<CustomerSampleAllocation>= [];
    const doReceiptCheck: boolean = this.authService.hasFeatureAction(FeatureActionsMap.RECIEPTS_TAB);
    this.isThereEligibleProductWithNoLicenseIssue = false;
    this.sampleService.validatedLicenses = [];

    if(this.activity && this.activity.contactID){
      const contactAllocations = this.sampleService.contactCustomerSampleAllocations.find(alloc => alloc.contactId === this.activity.contactID);
      if(contactAllocations && contactAllocations.currentCustomerSampleAllocations && contactAllocations.currentCustomerSampleAllocations.length !== 0){

        for (let i = 0; i < contactAllocations.currentCustomerSampleAllocations.length; i++) {
          const alloc = contactAllocations.currentCustomerSampleAllocations[i];
          if(!alloc.sampleSKUApplicability) continue;
          if(alloc.isUnlimited || alloc.remaining > 0){
            // License validation
            const validationResult = this._checkLicenses(alloc.id, alloc.customerLicenseIds);
            this.isThereEligibleProductWithNoLicenseIssue = this.isThereEligibleProductWithNoLicenseIssue || validationResult.isValid;

            if (!validationResult.isValid) continue;
            this.sampleService.validatedLicenses = _.uniqBy(_.concat(this.sampleService.validatedLicenses, validationResult.validatedLicenses), 'id');

            if(doReceiptCheck){
              let onHandSKUQuantity:number = 0;
              if(this.sampleService.lots){
                for(let idx = 0;idx<this.sampleService.lots.length;idx++){
                  const currentLot = this.sampleService.lots[idx];
                  if (currentLot
                      && currentLot.sampleSKUId === alloc.sampleSKUId
                      && currentLot.totalQuantityRemaining > 0
                      && currentLot.status === 0
                      && isFuture(currentLot.validTo)) {

                    if(!foundAllocations.find(a=>a.id===alloc.id)){
                      foundAllocations.push(alloc);
                    }
                    onHandSKUQuantity = (onHandSKUQuantity-0)+(currentLot.totalQuantityRemaining-0);
                  }
                }
                if(onHandSKUQuantity > 0){
                  foundAllocations.find(a=>a.id === alloc.id).onHandLotsQuantity = onHandSKUQuantity;
                }
              }
            }else{
              if(this.sampleService.lots){
                for(let idx = 0;idx<this.sampleService.lots.length;idx++){
                  const currentLot = this.sampleService.lots[idx];
                  if (currentLot
                      && currentLot.sampleSKUId === alloc.sampleSKUId
                      && currentLot.status === 0
                      && isFuture(currentLot.validTo)) {

                    foundAllocations.push(alloc);
                    break;
                  }
                }
              }
            }
          }
        }
      }
    }else{
      // No Selected Contact Yet
    }
    this.elligibleAllocations = foundAllocations;
  }

  private _getApplicableLotsForSKU(skuID: string,customerSampleProductId: string): Array<AllocationQuantitiesLotDetailViewModel>{

    let foundLots:Array<AllocationQuantitiesLotDetailViewModel> = [];
    if(skuID && customerSampleProductId){
      if(this.activity.status === 2 || this.activityService.teamViewActive){
        if(this.activity && this.activity.samples && this.activity.samples.length !== 0){
          this.activity.samples.forEach(sample=>{
            if(sample.indskr_customersampleproductid === customerSampleProductId && sample.indskr_skuid === skuID && sample.lots && sample.lots.length !== 0){
              sample.lots.forEach(activityLot=>{
                let currentLotView:AllocationQuantitiesLotDetailViewModel = {
                  id: activityLot.indskr_lotid,
                  view: {
                    label: activityLot.indskr_lotname,
                    secondaryInfoLabel: (activityLot.indskr_lotvalidtodate ? this.translate.instant('AL_EXPIRES')+' '+ this.datePipe.transform(new Date(parseInt(activityLot.indskr_lotvalidtodate)), this.dateTimeFormatsService.date, undefined, this.translate.currentLang) : ''),
                    inputText: activityLot.indskr_quantity?activityLot.indskr_quantity+'':'0',
                    inputType: 'number',
                    inputValue: activityLot.indskr_quantity,
                    customPlaceholderLabel: '0',
                    id: customerSampleProductId+activityLot.indskr_lotid,
                    isReadOnly: true,
                    isDisabled: true,
                    showArrow: false,
                    isAccordionForm: true,
                    formFieldType: FormFieldType.INLINE_INPUT,
                    inputValidator:(id: string,value: any) => this._validateQuantityEntered(id,value),
                    eventHandler: (id: string, event, eventName) => this.handleLotQuantityFormField(id,event,eventName),
                  },
                  lotRefData: null,
                  activityLotRefData: activityLot,
                };
                // check duplicate lots before pushing a current lot
                let idx = foundLots.findIndex(fl => fl.id === currentLotView.id);
                if (idx < 0) {
                  if (this.activity.status === 2 && activityLot.indskr_quantity != 0) {
                    foundLots.push(currentLotView);
                  } else if (this.activityService.teamViewActive) {
                    foundLots.push(currentLotView);
                  }
                } else {
                  console.log("Duplicate lot data detected");
                }
              });
            }
          })
        }
      }else{

        this.sampleService.lots.forEach(lot=>{
          let alreadyAddedLot;
          let targetSampleSKU: SampleDetailInSampleActivity;
          if(this.activity && this.activity.samples && this.activity.samples.length !== 0){
            this.activity.samples.forEach(sample => {
              if(sample.indskr_customersampleproductid === customerSampleProductId && sample.indskr_skuid === skuID){
                alreadyAddedLot = sample.lots.find(activityLot=> activityLot.indskr_lotid === lot.id);
                targetSampleSKU = sample;
              }
            })
          }
          const inputTextStr: string = (alreadyAddedLot && alreadyAddedLot.indskr_quantity) ? alreadyAddedLot.indskr_quantity+'' : '0';
          const inputValueNum: number = (alreadyAddedLot && alreadyAddedLot.indskr_quantity) ? alreadyAddedLot.indskr_quantity : 0;
          let currentLotView: AllocationQuantitiesLotDetailViewModel = {
            id: lot.id,
            view: {
              label: lot.name+' '+(this.authService.hasFeatureAction(FeatureActionsMap.RECIEPTS_TAB)?'('+lot.totalQuantityRemaining+')':''),
              secondaryInfoLabel: (lot.validTo ? this.translate.instant('AL_EXPIRES') + ' ' + this.datePipe.transform(lot.validTo, this.dateTimeFormatsService.date, undefined, this.translate.currentLang) : ''),
              inputText: inputTextStr,
              inputType: 'number',
              inputValue: inputValueNum,
              customPlaceholderLabel: '0',
              id: customerSampleProductId+lot.id,
              isReadOnly: false,
              isDisabled: false,
              showArrow: true,
              isAccordionForm: true,
              errorMessage: this.translate.instant('ALLOCATION_NOW_INVALID_DELETE'),
              formFieldType: FormFieldType.INLINE_INPUT,
              inputValidator:(id: string,value: any) => this._validateQuantityEntered(id,value),
              eventHandler: (id: string, event, eventName) => this.handleLotQuantityFormField(id,event,eventName),
            },
            lotRefData: lot,
            activityLotRefData: (alreadyAddedLot)?alreadyAddedLot:null,
          };
          const currentLotAllocation = this.elligibleAllocations.find(a=> a.id === customerSampleProductId);
          if(currentLotAllocation && alreadyAddedLot && alreadyAddedLot.indskr_quantity > currentLotAllocation.remaining){
            currentLotView.view.invalidInputValueTrigger = true;
          }
          if (this.authService.hasFeatureAction(FeatureActionsMap.RECIEPTS_TAB)) {
            if (lot.totalQuantityRemaining > 0 && lot.sampleSKUId === skuID && lot.status === 0 && isFuture(lot.validTo)) {
              if(alreadyAddedLot && alreadyAddedLot.indskr_quantity && alreadyAddedLot.indskr_quantity > lot.totalQuantityRemaining){
                currentLotView.view.invalidInputValueTrigger = true;
              }
              // check duplicate lots before pushing a current lot
              let idx = foundLots.findIndex(fl => fl.id === currentLotView.id);
              if (idx < 0) {
                foundLots.push(currentLotView);
              } else {
                console.log("Duplicate lot data detected");
              }
            }else if(alreadyAddedLot){
              //Treat as Invalid quantitiy now
              targetSampleSKU.isInvalid = true;
            }
          }else{
            if(lot.sampleSKUId === skuID && isFuture(lot.validTo) && lot.status === 0){
              // check duplicate lots before pushing a current lot
              let idx = foundLots.findIndex(fl => fl.id === currentLotView.id);
              if (idx < 0) {
                foundLots.push(currentLotView);
              } else {
                console.log("Duplicate lot data detected");
              }
            }else if(alreadyAddedLot){
              //Treat as Invalid quantitiy now
              targetSampleSKU.isInvalid = true;
            }
          }
        });
      }
    }
    // Sorting of applicable lots
    try {
      foundLots = foundLots.sort((a,b)=> {
        if(a.lotRefData && b.lotRefData){
          return isBefore(a.lotRefData.validTo,a.lotRefData.validTo)?1:-1;
        }else if(a.activityLotRefData && b.activityLotRefData){
          return isBefore((new Date(parseInt(a.activityLotRefData.indskr_lotvalidtodate))),new Date(parseInt(b.activityLotRefData.indskr_lotvalidtodate)))?-1:1;
        }
      })
    } catch (error) {
      console.log('Error in sorting lots data'+error);
    }
    return foundLots;
  }

  private _initAllocationQuantitiesDetails(){
    this._invalidValuePairs = [];
    this._getElligibleAllocations();

    if(this.activity && this.activity.samples.length !== 0){
      this.allocationQuantitiesData = [];
      this.activity.samples.forEach(sample => {
        const alloc = this.elligibleAllocations.find(allo => allo.id === sample.indskr_customersampleproductid);
        sample.isInvalid = this.activity.checkProductValidation(sample);
        if(!alloc && this.activity.status !== 2 && !this.activityService.teamViewActive){
          sample.isInvalid = true;
        }
        const childItems = this._getApplicableLotsForSKU(sample.indskr_skuid,sample.indskr_customersampleproductid);
        let totalQuantityRemaining;
        if(childItems && childItems.length !== 0 && this.authService.hasFeatureAction(FeatureActionsMap.RECIEPTS_TAB) && this.activity.status !== 2){
          totalQuantityRemaining = childItems.map(item => {
            if(item.lotRefData && item.lotRefData.totalQuantityRemaining){
              return item.lotRefData.totalQuantityRemaining;
            }else {
              return 0;
            }
          }).reduce((a,b)=> (a-0)+(b-0),0);
        }
        // each lot number validation
        if (childItems && childItems.length !==0 && this.activity.status !==2) {
          childItems.forEach(item => {
            const totalNumber: number = item.lotRefData.totalQuantityRemaining || 0;
            const inputNumber: number = item.view.inputValue || 0;
            this._isInputLotValueValid = true;
            if (inputNumber > 0) {
              const _checkLotValueValid: boolean = totalNumber - inputNumber >= 0;
              this._isInputLotValueValid = this._isInputLotValueValid && _checkLotValueValid;
            }
          });
          if (!this._isInputLotValueValid) {
            this._initSectionHeaderDetails();
            this._initPageTitle();
            this._updateFooterButtons();
            this._cd.detectChanges();
          }
        }
        if(!sample.deleted){
          const allocationProductView: MainCardViewDataModel = {
            id: sample.indskr_customersampleproductid,
            primaryTextLeft: '',
            secondaryTextLeft: '',
            primaryTextRight: sample.indskr_skuname+((!totalQuantityRemaining)?(''):(' ' + '('+totalQuantityRemaining+')')),
            secondaryTextRight: (this.activity.status === 2)?this.translate.instant('ORDERED'):(this.translate.instant('REMAINING')),
            secondaryTextRightTwo:(this.activity.status === 2)?(sample.totalQuantity+''):((alloc && !alloc.isUnlimited)?(alloc.remaining+''):this.translate.instant('UNLIMITED')),

            secondaryTextRightThree:(this.activity.status !== 2 && sample.samplelimitpervisit > 0)? this.translate.instant('LIMIT_PER_VISIT') : '',
            secondaryTextRightFour:(this.activity.status !== 2 && sample.samplelimitpervisit > 0)? sample.samplelimitpervisit : '',

            secondaryTextRightFive:(this.activity.status !== 2 && sample.totalQuantity && sample.totalQuantity > 0)?(this.translate.instant('ORDERED')):(''),
            secondaryTextRightSix:(this.activity.status !== 2 && sample.totalQuantity && sample.totalQuantity > 0)?(sample.totalQuantity):(''),

            isSecondaryTextRightPillType: false,
            isSecondaryTextRightTwoPillType: true,
            showAccordionIcon: (childItems && Array.isArray(childItems) && childItems.length !== 0),
            accordionIconType: (childItems && Array.isArray(childItems) && childItems.length !== 0)?'accordion-minus':'accordion-add',
            isAccordionForm: true,
            isAccordionText: (childItems && Array.isArray(childItems) && childItems.length !== 0)? true : false,
            noSeparationLine: true,
            clickHandler: (id: string, event,eventName,refData) => this._handleMainSkuClick(id,event,eventName,refData),
          };
          if(sample.isInvalid && this.activity.status !== 2 && !this.activityService.teamViewActive){
            // allocationProductView.secondaryTextRight = this.translate.instant('ALLOCATION_NOW_INVALID_DELETE');
            // allocationProductView.secondaryTextRight = '';
            // allocationProductView.secondaryTextRightTwo = '';
            // allocationProductView.secondaryTextRightThree = '';
            // allocationProductView.secondaryTextRightFour = '';
            allocationProductView.isSecondaryTextRightPillType = false;
            // allocationProductView.showArrow = false;
            allocationProductView.showAccordionIcon = false;
            allocationProductView.showEndIcon = true;
            allocationProductView.endIconType = 'indegene-selectors-remove-icon';
            allocationProductView.mainItemCssClass = 'danger-secondary-text-item';
            allocationProductView.endIconCssClass = 'remove-icon';
            allocationProductView.isAccordionText = false;
          }
          const allocationQuantityDetail: AllocationQuantitiesDetailViewModel = {
            id: sample.indskr_customersampleproductid,
            view: allocationProductView,
            childItems: (sample.isInvalid && this.activity.status !== 2 && !this.activityService.teamViewActive) ? [] : childItems,
            isExpanded: !(sample.isInvalid && this.activity.status !== 2 && !this.activityService.teamViewActive),
            isInvalidValue: (sample.isInvalid && this.activity.status !== 2 && !this.activityService.teamViewActive),
            isQuantityInValid: (sample.isInvalid && this.activity.status !== 2 && !this.activityService.teamViewActive),
            errorMessage: this.translate.instant('ALLOCATION_NOW_INVALID_DELETE'),
          };
          this.allocationQuantitiesData.push(allocationQuantityDetail);
        }
      });
    }else{
      this.allocationQuantitiesData = [];
    }
  }

  private async _updateCurrentSampleActivity(){
    await this.uiService.displayLoader();
    let payload: CreateSampleDropRequestBody = new CreateSampleDropRequestBody(this.activity);
    await this.sampleDataService.updateSampleActivity(payload, this.activity).then(() => {
      this.eventService.publish("allocationOrderActivityIsUpdated", this.activity);
      this.uiService.dismissLoader();
      this._initAllocationQuantitiesDetails();
      this.initAllDataModels();
      this._updateFooterButtons();
      this._cd.detectChanges();
    },
    (error) => {
      this.uiService.dismissLoader();
      this._initAllocationQuantitiesDetails();
      this.initAllDataModels();
      this._updateFooterButtons();
      this._cd.detectChanges();
    });
    if(this.device.isOffline || this.activity.ID.includes('offline')){
      setTimeout(()=>{
        this.uiService.dismissLoader();
      },10);
    }
  }

  public get isSplitView(): boolean {
    return (window.innerWidth > 500);
  }

  private _clearAllSamples() {
    if (this.activity && Array.isArray(this.activity.samples)) {
      this.activity.samples.map((o) => {
        o.deleted = true;
        o.totalQuantity = 0;
        if(o.lots && o.lots.length > 0){
          o.lots.map(l=> {
            l.indskr_quantity = 0;
            l.deleted = true
          });
        }
      });
    }
  }

  public async _handleOrderCompletion(signatureData){
    let loader = await this.loadingCtrl.create();
    loader.present();
    const now = new Date().getTime().toString();
    let currentSampleDrop = this.activity;
    let payload = new CreateSampleDropRequestBody(currentSampleDrop);
    payload.indskr_signature = signatureData || '';
    payload.statecode = 1;
    payload.statuscode = 2;
    payload.actualend = now;
    currentSampleDrop.state = 1;
    currentSampleDrop.status = 2;
    currentSampleDrop.actualEnd = now;
    await this.sampleDataService.updateSampleActivity(payload, currentSampleDrop , this.contactService.getContactByID(currentSampleDrop.contactID)).then(
      ()=>{
        this.eventService.publish("allocationOrderActivityCompleted", this.activity);
        currentSampleDrop.statusString = 'Completed';
        this.statusString = this.translate.instant('COMPLETED');
        this.isCurrentActivityCompleted = true;
        this.statusString = this.translate.instant('COMPLETED') + ' - ' + this.datePipe.transform(new Date(parseInt(this.activity.actualEnd)), this.dateTimeFormatsService.date, undefined, this.translate.currentLang)

        currentSampleDrop.color = ActivityColorCode.ActivityCompleted;
        currentSampleDrop.signature = signatureData || '';
        this.activityService.addNewActivityToSampleActivityMetasIndexedBySKU(currentSampleDrop);
        loader.dismiss();
        this.notificationService.notify(this.translate.instant('ALLOCATION_O_SUB_SUCCESSFULLY'),'',
                                          'top',ToastStyle.INFO);
        this.sampleService.updateSampleEligibilities(currentSampleDrop,null);
        if(this.authService.hasFeatureAction(FeatureActionsMap.RECIEPTS_TAB)){
          this.sampleService._addToLotDroppedQuantityDetail(currentSampleDrop);
        }
      },
      async (error)=>{
        currentSampleDrop.state = 0;
        currentSampleDrop.status = 1;
        loader.dismiss();
      }
    );
    if(this.viewDestroyed == false){
      this._initAllocationQuantitiesDetails();
      this.initAllDataModels();
      this._updateFooterButtons();
      this.enableFooterButtonsForMeeting();
      this._cd.detectChanges();
    }
  }

  private _updateMandatoryField(field: PageName) {
    if (field == PageName.ContactPageComponent) {
      this.getCustomerFormField();
    }
  }
}
