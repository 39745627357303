import { SelectListData } from "@omni/components/popover/popover";

export interface IndFormFieldViewDataModel {
    [x:string]: any,
    id:string;
    label:string;
    secondaryInfoLabel?:string;
    secondaryInfoText?:string;
    inputText:string;
    inputType?:string;
    inputValue?:any;
    inputMin?: number;
    inputMax?: number;
    inputDebounceTime?: number;
    maxLength?:number;
    formattingSymbol?:string;
    isReadOnly?:boolean;
    isDisabled?:boolean;
    isHidden?:boolean;
    placeholderLabel?:string;
    skipPlaceholderTranslation?: boolean;
    customPlaceholderLabel?:string;
    showArrow?:boolean;
    formFieldType?:string;
    inputRegEx?: RegExp;
    invalidInputValueTrigger?:boolean;
    inputValidator?:(id:string,value:any,event?:any, eventName?:string)=> boolean,
    eventHandler?:(id?:string, event?:any, eventName?:string, dataRef?: any)=>void;
    dropdown?: { values?: (() => Promise<SelectListData[]> | SelectListData[]) | SelectListData[] };
    phoneIcon?: string;
    imgSrc?: string;
    cssIcon?: string;
    isDateTimeField?: boolean;
    postLabelIcon?: {
      domId?: string,
      domClass?: string,
      clickable?: boolean,
      isClickEnabled?: boolean,
      src?: string,
    }
    isAccordionForm?:boolean;
    errorMessage?:string;
    additionalcontrols?: Array<{
      id?: string,
      src?: string,
      arrowType?: string,
      }>;
    isRequired?:boolean;
    isInvalid?:boolean;
    isEmptyRequiredField?: boolean;
    infoText?:string;
    displayInfoText?: boolean;
    infoTextEventHandler?:(id:string,value:any,event?:any, eventName?:string)=> void;
    isTextNextIcon?: boolean;
    subId?: string;
}

export enum FormFieldType {
    POPOVER_SELECT = 'popover-select',
    NEW_PAGE_SELECT = 'new-page-select',
    INLINE_INPUT = 'inline-input',
    DATA_SUBMITTED = 'data-submitted',
    OPTIONS_NOT_AVAILABLE = 'options-not-available',
    DESCRIPTION_AND_CONFIRM = 'description-and-confirm',
    TOGGLE = 'toggle'
}
