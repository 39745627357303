export class SurveyForm {

  public raw: any;
  public surveyFormId: string;
  public name: string;
  public surveyConfiguration: string;
  public modiefiedOn: Date;
  public templateType:MobileAppTemplateType;

  constructor(raw) {
    this.raw = raw;
    this.surveyFormId = raw['indskr_expertinquirytemplateid'] || '';
    this.name = raw['indskr_name'] || '';    
    this.modiefiedOn = raw['modifiedon'] ? new Date(raw['modifiedon']) : null;

    if(raw['indskr_surveyconfiguration'] && (typeof raw['indskr_surveyconfiguration'] == 'string')) {
      this.surveyConfiguration = JSON.parse(raw['indskr_surveyconfiguration']);
    } else { 
      this.surveyConfiguration = raw['indskr_surveyconfiguration'];
    }
    if(raw['indskr_templatetype']){
      this.templateType = raw['indskr_templatetype'];
    }
  }
}

export class SurveyResponse {

  public raw: any;
  public surveyResponseId: string;
  public question: string;
  public answer: string;
  public caseId: string;
  public modiefiedOn: Date;

  constructor(raw) {
    this.raw = raw;
    this.surveyResponseId = raw['indskr_expertinquiryresponseid'] || '';
    this.question = raw['indskr_question'] || '';
    this.answer = raw['indskr_answer'] || '';  
    this.caseId = raw['_indskr_incidentid_value'] || '';
    this.modiefiedOn = raw['modifiedon'] ? new Date(raw['modifiedon']) : null;
  }

}

export enum MobileAppTemplateType {
  SalesOrderType = 600000000
}