import { TrackingEventNames, TrackService } from './../../../services/logging/tracking.service';
import { OpportunityQuoteDetailsComponent } from './../opportunity-quote-details/opportunity-quote-details';
import { OpportunityManagementService } from './../../../services/opportunity-management/opportunity-management.service';
import { Component, ElementRef, Input, ViewChild } from "@angular/core";
import { PopoverController } from "@ionic/angular";
import { IndSectionHeaderViewDataModel } from "../../../models/indSectionHeaderDataModel";
import { MainCardViewDataModel } from "../../../models/MainCardViewDataModel";
import { Opportunity, Quote } from '../../../classes/opportunity-management/opportunity.class';
import { NavigationService, PageName } from '../../../services/navigation/navigation.service';
import { MultiSelectPopover } from '../../multi-select-popover/multi-select-popover';
import { TranslateService } from '@ngx-translate/core';
import { DeviceService } from '../../../services/device/device.service';

@Component({
    selector: 'opportunity-quotes',
    templateUrl: 'opportunity-quotes.html',
  styleUrls:['opportunity-quotes.scss']
})
export class OpportunityQuotesComponent {

    public isLayoverPushView:boolean = false;
    public layoverViewPushedFrom:string = '';

    public allQuotesHeader:IndSectionHeaderViewDataModel;

    public opportunityQuotes:Array<MainCardViewDataModel> = [];
    public selectedOpportunity: Opportunity;
    applicableQuotes: Quote[];
    filterPopoverData: { text: string; value: string; items: { text: string; value: string; }[]; handler: (selectedItem: any, item: any, itemRef: any) => void; }[];
    opportunityQuotesFilter: string;
    isTotalQuotesListEmpty: boolean = false;
    public noQoutes:string;
    @Input() from: any
    @Input() opportunity: any
    @ViewChild('scrollTop', { read: ElementRef, static: false }) scrollTop;

    constructor(
        private opportunityService: OpportunityManagementService,
        private navService: NavigationService,
        private popover: PopoverController,
        private trackingService: TrackService,
        private translate: TranslateService,
        public device: DeviceService
    ) {
    }

    ngOnInit(){
        if (this.from) {
          this.isLayoverPushView = true;
          this.layoverViewPushedFrom = this.from;
        }
        if (this.opportunity) {
          this.selectedOpportunity = this.opportunity;
        }
      this.noQoutes=this.translate.instant('NO_QOUTES');
      this.initOpportunityQuotesFormFields();
      this.filterPopoverData = [
        {
          text: "",
          value: "All",
          items: [{ text:this.translate.instant('ALL_QUOTES'), value: 'All' }],
          handler: (selectedItem, item, itemRef) => {
            this.trackingService.tracking('SelectFilterTab', TrackingEventNames.OPPORTUNITYMANAGEMENT)
            itemRef.parent.items.map((o)=>{
              o.value = '';
            })
            item.value = selectedItem.value;
            this.opportunityQuotesFilter = '';
            this.initOpportunityQuotesFormFields()
          }
        },
        {
          text: this.translate.instant('STATUS'),
          value: "",
          items: [
                    { text:this.translate.instant('OM_DRAFT'), value: 'Draft' },
                    { text:this.translate.instant('ACTIVE'), value: 'Active' },
                    { text:this.translate.instant('WON'), value: 'Won' },
                    { text:this.translate.instant('CLOSED'), value: 'Closed' }
                  ],
          handler: (selectedItem, item, itemRef) => {
            this.trackingService.tracking('SelectFilterTab', TrackingEventNames.OPPORTUNITYMANAGEMENT)
            itemRef.parent.items[0].value=''
            item.value = item.value === selectedItem.value ? "" : selectedItem.value;
            this.opportunityQuotesFilter = item.value;
            if(!this.opportunityQuotesFilter){
              itemRef.parent.items[0].value='All'
            }
            this.initOpportunityQuotesFormFields();
            }
          },
        ];
    }

    ngAfterViewInit(){
      if(this.scrollTop){
        setTimeout(() => {
          this.scrollTop.nativeElement.scrollIntoView(false);
        }, 200);
      }
    }
    public initOpportunityQuotesFormFields(){
        this.applicableQuotes = this.opportunityService.qoutes.filter((q)=> q.opportunityID == this.selectedOpportunity.ID)
        if(this.applicableQuotes.length == 0) this.isTotalQuotesListEmpty = true;
        if(this.opportunityQuotesFilter){
          this.applicableQuotes = this.applicableQuotes.filter((o)=>{
            return o.statecodeFormatted == this.opportunityQuotesFilter
          })
        }
        this.opportunityQuotes = this.applicableQuotes.map((o)=>{
          let quoteDisplayObject: MainCardViewDataModel
          quoteDisplayObject = {
            id: o.quoteID,
            fixedHeight: true,
            primaryTextLeft : o.quoteNumber,
            secondaryTextLeft: o.statecodeFormatted,
            showArrow: true,
            arrowType:'chevron-forward-outline',
            clickHandler: (quotedID)=>{
              this.goToQuoteDetails(quotedID);
            }
          }
          return quoteDisplayObject;
        })

        if(!this.isTotalQuotesListEmpty){
          this.allQuotesHeader = {
            id:'all-quotes-header',
            title: this.headerString+' ('+this.applicableQuotes.length+')',
            isFilter: true,
            showArrow: true,
            arrowType: 'caret-down-sharp',
            controls: [],
          };
        }
        else this.allQuotesHeader = undefined
    }
  goToQuoteDetails(id:string) {
    this.trackingService.tracking('SelectQuote', TrackingEventNames.OPPORTUNITYMANAGEMENT)
    let selectedQuote = this.applicableQuotes.find((o)=> o.quoteID == id);
    this.navService.pushChildNavPageWithPageTracking(OpportunityQuoteDetailsComponent, PageName.OpportunityQuoteDetailsComponent,
                    PageName.OpportunityManagementPage, { quote: selectedQuote });
  }
  filterOrders(){
    this.popover
      .create({component: MultiSelectPopover,componentProps: { root: this.filterPopoverData },event:event})
      .then((data)=> data.present());
  }
  get headerString(){
    let str: string = this.translate.instant('ALL_QUOTES');
    if(this.opportunityQuotesFilter){
      switch(this.opportunityQuotesFilter){
        case 'Draft':
        str = this.translate.instant('OM_DRAFT')
        break;
        case 'Active':
        str = this.translate.instant('ACTIVE');
        break;
        case 'Won':
        str = this.translate.instant('WON');
        break;
        case 'Closed':
        str = this.translate.instant('CLOSED');
        break;
      }
    }
    return str.toUpperCase();
  }

}
