import { Utility } from './../../utility/util';
import { isPast, isFuture, format } from 'date-fns';
import { IONote } from '../io/io-note.class';
import { Opportunity } from '../opportunity-management/opportunity.class';
import { EventActivity } from '../events-tool/event.class';

export class MarketingPlan {
  public ID: string;
  public brandPlanName: string;
  public startDate: string;
  public endDate: string;
  public accountId: string;
  public accountName: string;
  public parentAccountId: string;
  public state: number;
  public status: string;
  public statusCode: any;
  public brandPlanTimeFrame: string;
  public products: any[];
  public lastUpdated: number;
  public ownerID: string;
  public estimatedRevenue: number;
  public estimatedRevenureFormatted: string;
  public actualRevenue: number;
  public actualRevenueFormatted: string;
  public indskr_totalbudget: number;
  public currencyId: string;
  public currencyName: string;
  public plannedBudgetFormatted: string;
  public contactAccountAffs: any[];
  public events: EventActivity[];
  public accounts: Account[];
  public contacts: any;
  // public notes: IONote[];
  public MarketingPlansNotes: Array<IONote> = [];
  public opportunities: Opportunity[];

  constructor(raw: JSON) {
    this.brandPlanName = raw['indskr_name'] || '';
    this.ID = raw['indskr_brandplanid'];
    this.startDate = raw['indskr_validfrom'] ? Utility.changeUTCDateToLocalDateWith0Time(new Date(raw['indskr_validfrom']).getTime()).getTime().toString() : '';
    this.endDate = raw['indskr_validto'] ? Utility.changeUTCDateToLocalDateWith0Time(new Date(raw['indskr_validto']).getTime(), true).getTime().toString() : '';
    this.accountName = raw['account.name'];
    this.parentAccountId = raw['account.parentaccountid'] || '';
    this.accountId = raw['account.accountid'];

    this.state = raw['statecode'] || 0;
    this.statusCode = raw['statusCode'] || 1
    this.status = raw['statuscode'] && raw['statuscode'] == 548910001 ? 'Completed' : raw['statuscode'] && raw['statuscode'] == 2 ? 'Inactive' : raw['statuscode'] && raw['statuscode'] == 548910002 ? 'Cancelled' : 'Open';
    this.products = [];
    // this.trackAction = raw['track_action@OData.Community.Display.V1.FormattedValue'] ? raw['track_action@OData.Community.Display.V1.FormattedValue'] : '';
    this.brandPlanTimeFrame = isPast(Utility.changeUTCDateToLocalDateWith0Time(new Date(raw['indskr_enddate']).getTime(), true)) ? 'Past'
      : isFuture(Utility.changeUTCDateToLocalDateWith0Time(new Date(raw['indskr_startdate']).getTime())) ? 'Future' : 'Present';
    this.ownerID = raw['_ownerid_value'] || '';
    this.estimatedRevenue = raw['indskr_estimatedrevenuetotal'] || 0.00;
    this.estimatedRevenureFormatted = raw['indskr_estimatedrevenuetotal@OData.Community.Display.V1.FormattedValue'] || '0.00';
    this.actualRevenue = raw['indskr_actualrevenuetotal'] || 0.00;
    this.actualRevenueFormatted = raw['indskr_actualrevenuetotal@OData.Community.Display.V1.FormattedValue'] || '0.00';
    this.indskr_totalbudget = raw['indskr_totalbudget'] || 0.00;
    this.plannedBudgetFormatted = raw['indskr_totalbudget@OData.Community.Display.V1.FormattedValue'] || '0.00';
    this.currencyId = raw['transactioncurrencyid_value'] || raw['transactionCurrecnyId'] || ''
    this.currencyName = raw['transactioncurrencyid_value@OData.Community.Display.V1.FormattedValue'] || ''
    this.contactAccountAffs = [];
    this.events = [];
    this.opportunities = [];
    this.accounts = [];
    this.contacts = [];
  }

  get planID() {
    return this.ID;
  }

}
export class Account {
  constructor(public id: string,
    public name: string,
    public statecode: number) { }
}

export class Contact {
  constructor(public id: string,
    public name: string,) { }
}

export class Currency {
  public id: string;
  public symbol: string;
  public name: string;
  public code: string;
  constructor(raw:JSON) {
    this.id = raw['transactioncurrencyid'],
    this.symbol = raw['currencysymbol'],
    this.name = raw['currencyname']
    this.code = raw['isocurrencycode'];
   }
}

export enum CurrencySymbolInHtml {
  'US Dollar' = '$',
  'Rupee' = '&#8377;',
  'Pound' = '&#163;',
}

export enum accounPlanObjectiveType {
  type_548910000 = 'QUANTITATIVE',
  type_548910001 = 'QUALITATIVE'
}

