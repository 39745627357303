import { CustomerSampleAllocationDTO, LotDetailInSampleActivity } from '../../models/sample-model';
import { Utility } from '../../utility/util';
import { MainCardViewDataModel } from '../../models/MainCardViewDataModel';
import { IndFormFieldViewDataModel } from '../../models/indFormFieldDataModel';
import { Lot } from './lot.class';

export class CustomerSampleAllocation {
    id: string;
    sampleSKUId: string;
    sampleSKUName: string;
    sampleSKUApplicability: boolean;
    contactId: string;
    contactName: string;
    startDate: Date;
    endDate: Date;
    limit: number;
    dropped: number;
    remaining: number;
    isSelected: boolean = false;
    isUnlimited: boolean;
    eSignatureNecessary: boolean;
    sampleDropLimitPerVisit: number;

    //Maintained on App only for showing on UI
    onHandLotsQuantity:number = 0;
    customerLicenseIds: string[];

    _id: string;
    _rev: string;
    lastUpdatedTime: number;

    constructor(raw: CustomerSampleAllocationDTO) {
        this._id = raw._id ? raw._id : null;
        this._rev = raw._rev ? raw._rev : null;
        this.lastUpdatedTime = raw.lastUpdatedTime ? raw.lastUpdatedTime : null;
        this.id = raw.indskr_customersampleproductid;
        this.sampleSKUId = raw.indskr_skuid;
        this.sampleSKUName = raw.indskr_skuname;
        this.sampleSKUApplicability = raw["indskr_skuapplicability"]? (raw["indskr_skuapplicability"].split(",").indexOf('100000001')>-1 || raw["indskr_skuapplicability"].split(",").indexOf('100000003')>-1 )? true: false: true;
        this.contactId = raw.indskr_contactid;
        this.contactName = raw.indskr_contactname;
        this.startDate = Utility.changeUTCDateToLocalDateWith0Time(parseInt(raw.indskr_startdate));
        this.endDate = Utility.changeUTCDateToLocalDateWith0Time(parseInt(raw.indskr_enddate),true);
        this.limit = raw.indskr_eligibilitylimit;
        this.dropped = raw.indskr_totalsamplesdropped;
        this.remaining = raw.indskr_totalsamplesremaining;
        this.isUnlimited = raw['indskr_isunlimited'];
        this.eSignatureNecessary = raw['indskr_esignaturenecessaryflag'] || false;
        this.customerLicenseIds = raw['customerLicense'] ? raw['customerLicense'] : [];
        this.sampleDropLimitPerVisit = raw['samplelimitpervisit'];
    }
}

export interface AllocationQuantitiesDetailViewModel {
    id:string;
    view: MainCardViewDataModel;
    isExpanded: boolean;
    childItems: Array<AllocationQuantitiesLotDetailViewModel>;
    isInvalidValue?: boolean;
    isQuantityInValid: boolean;
    errorMessage?: string;
}

export interface AllocationQuantitiesLotDetailViewModel {
    id:string;
    view:IndFormFieldViewDataModel;
    activityLotRefData:LotDetailInSampleActivity;
    lotRefData:Lot;
}
