import { IShipmentReason } from './../../interfaces/allocation/allocation.shared.interface';
import { IAllocationAdjustment, IAllocationAdjustmentCreateDTO, IAllocationAdjustmentCreateResponseDTO } from './../../interfaces/allocation/allocation-adjustment.interface';
import { AllocationFeatureService } from './../../services/sample/allocation.feature.service';
import { DynamicsClientService } from '@omni/data-services/dynamics-client/dynamics-client.service';
import { ADJUSTMENT_ENTITY_NAME, ADJUSTMENT_FETCH_BY_ID_TEMPLATE } from '../../config/fetch-xml/allocation/adjustment-fetchXMLs';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { FeatureActionsMap } from "@omni/classes/authentication/user.class";
import { AuthenticationService } from "@omni/services/authentication.service";
import { AllocationAdjustService } from "@omni/services/sample/allocation-adjust.service";
import { Endpoints } from "src/config/endpoints.config";
import { DeltaService, EntityNames, EntitySyncInfo } from "../delta/delta.service";
import { AllocationAdjustForm } from '../../classes/sample/allocation-adjust.class';
import { DeviceService } from '../../services/device/device.service';
import { NotificationService, ToastStyle } from '../../services/notification/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { AdjustmentStatus, TransferType } from '../../enums/allocation/allocation.enum';

@Injectable({
    providedIn: 'root'
  })
  export class AllocationAdjustmentDataService {

    constructor(
        private http: HttpClient,
        private authService: AuthenticationService,
        private deltaService: DeltaService,
        private allocAdjustService: AllocationAdjustService,
        private device: DeviceService,
        private notificationService: NotificationService,
        private translate: TranslateService,
        private dynamics: DynamicsClientService,
        private allocationFeatureService: AllocationFeatureService,
    ) {}

  async getAdjustmentDetail(adjustment: IAllocationAdjustment, forDeleteVerification = false): Promise<IAllocationAdjustment> {
    let returnValue: IAllocationAdjustment;

    if (!this.device._isOffline && adjustment.indskr_usershipmentallocation_v2id) {
      let fetchXML: string = ADJUSTMENT_FETCH_BY_ID_TEMPLATE
        .replace('{ADJUSTMENT_ID}', adjustment.indskr_usershipmentallocation_v2id);

      try {
        let response: DynamicsWebApi.FetchXmlResponse<IAllocationAdjustment> = await this.dynamics.executeFetchXml(
          ADJUSTMENT_ENTITY_NAME,
          fetchXML,
          undefined,
          null,
          null
        );

        returnValue = Array.isArray(response?.value) && response.value[0] ? response.value[0] : undefined;

        if (forDeleteVerification && returnValue === undefined) {
          returnValue = adjustment;
        } else {
          if (returnValue !== undefined) {
            await this.allocAdjustService.handleGetAnAdjustmentDetail(returnValue, adjustment);
          } else {
            // deleted?
            this.notificationService.notify(this.translate.instant('AL_AJUSTMNT_ALREADY_DELETED'), 'AllocationAdjustComponent', "top", ToastStyle.DANGER, 3000);
            await this.allocAdjustService.handlePostAdjustmentDelete(adjustment);
          }
        }
      } catch (error) {
        console.error('getAdjustmentDetail: ', error);
      }
    }

    return returnValue;
  }

  async deleteAdjustment(adjustment: IAllocationAdjustment) {
    if (!this.device._isOffline) {
      let url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.alloc_adjustment.ALOCC_DELETE_ADJUSTEMNT;
      url = url.replace('{{adjustmentID}}',adjustment.indskr_usershipmentallocation_v2id) ;


      // get the details of teh adjustment quickly to check if that is for review or not

      const adjustmentDeletionResponse: IAllocationAdjustment = await this.getAdjustmentDetail(adjustment, true);

      if (adjustmentDeletionResponse) {
        if (adjustmentDeletionResponse.indskr_shipmentstatus === AdjustmentStatus.InReview) {

          this.notificationService.notify(this.translate.instant('AL_ADJUSTMENT_CANT_DELETE_FOR_REVIEW'), 'AllocationAdjustComponent', "top", ToastStyle.DANGER, 3000);

          return await this.allocAdjustService.handleGetAnAdjustmentDetail(adjustmentDeletionResponse, adjustment);

        } else if (adjustmentDeletionResponse['indskr_adjustedstatus'] === AdjustmentStatus.Approved) {
          this.notificationService.notify(this.translate.instant('AL_ADJUSTMENT_CANT_DELETE_APPROVED'), 'AllocationAdjustComponent', "top", ToastStyle.DANGER, 3000);
          return await this.allocAdjustService.handleGetAnAdjustmentDetail(adjustmentDeletionResponse, adjustment);
        }
      }


      let deletedAdjustmentID

      try {
        let response = await this.http.delete(url).toPromise();
        deletedAdjustmentID = await this.allocAdjustService.handlePostAdjustmentDelete(adjustment)
      }
      catch (error) {
        if (error.error.errorCode && error.error.errorCode === AdjustmentError.ERR_IO_AD06) {
            deletedAdjustmentID = await this.allocAdjustService.handlePostAdjustmentDelete(adjustment);
        }
      }

      return deletedAdjustmentID;

    } else {
      return
    }
  }

    async getAdjustmentReasons(loadFromDbOnly = false) {
        if (this.authService.hasFeatureAction(FeatureActionsMap.ALLOCATION_ADJUSTMENT) || this.authService.hasFeatureAction(FeatureActionsMap.ALLOCATION_ADJUSTMENT_TEAM_REQUEST)) {
            if (loadFromDbOnly) {
              try {
                await this.allocAdjustService.loadFromDBAndMapAdjustmentReasons();
                this.allocationFeatureService.adjustmentReasonDataReadState.setState('Success');
              } catch (error) {
                console.error('getAdjustmentReasons: ', error);
                this.allocationFeatureService.adjustmentReasonDataReadState.setState('Fail');
              }
            } else {
                let url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.alloc_adjustment.ALLOC_ADJUSTMENT_REASONS;
                const adjustmentReasonSyncInfo: EntitySyncInfo = {
                    entityName: EntityNames.allocAdjustReason,
                    totalFailed: 0,
                    totalSynced: 0,
                    errors: [],
                    syncStatus: true
                };

                let headers: HttpHeaders = new HttpHeaders();
                headers = headers.set('Authorization', 'Bearer ' + this.authService.access)
                .set('Sync-Service', 'true');

                try {
                  this.allocationFeatureService.adjustmentReasonFetchState.setState('Sync');
                    const response: IShipmentReason[]  = await this.http.get<IShipmentReason[]>(url, { headers }).toPromise();
                    if (Array.isArray(response)) {
                        adjustmentReasonSyncInfo.totalSynced = response.length;
                    }
                    await this.allocAdjustService.mapAdjustmentReasons(response);
                    this.allocationFeatureService.adjustmentReasonFetchState.setState('Success');
                    this.allocationFeatureService.adjustmentReasonDataReadState.setState('Success');
                } catch (error) {
                    console.error('AllocationAdjustmentDataService: getTransferReasons: ', error);
                    this.deltaService.addSyncErrorToEntitySyncInfo(adjustmentReasonSyncInfo, url, error);
                    this.allocationFeatureService.adjustmentReasonFetchState.setState('Fail');
                    await this.allocAdjustService.loadFromDBAndMapAdjustmentReasons();
                    this.allocationFeatureService.adjustmentReasonDataReadState.setState('Success');
                }
                this.deltaService.addEntitySyncInfo(adjustmentReasonSyncInfo);
            }
        }
    }

    async sendForApproval(form: AllocationAdjustForm) {
        if (this.authService.hasFeatureAction(FeatureActionsMap.ALLOCATION_ADJUSTMENT)) {
            let url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.alloc_adjustment.ALLOC_ADJUSTMENT_CREATION;
            const transferDTO: IAllocationAdjustmentCreateDTO = {
                indskr_externalid: form.external_id,
                indskr_quantityadjusted: form.quantityAdjusted,
                indskr_reasonforadjustment: form.reason.value,
                indskr_comment: form.comments,
                indskr_adjustmentdate: form.adjustmentDate.getTime().toString(),
                indskr_lotid: form.lot.id,
                indskr_skuid: form.sku.id,
                indskr_adjustedstatus: this.authService.hasFeatureAction(FeatureActionsMap.ALLOCATION_ADJUSTMENT_AUTO_APPROVAL) ? AdjustmentStatus.Approved : AdjustmentStatus.InReview,
                indskr_transfertype: TransferType.AllocationAdjustment,
                indskr_approvedby: this.authService.hasFeatureAction(FeatureActionsMap.ALLOCATION_ADJUSTMENT_AUTO_APPROVAL) ? this.authService.user.systemUserID : null,
                indskr_adjustmentapproveddate: this.authService.hasFeatureAction(FeatureActionsMap.ALLOCATION_ADJUSTMENT_AUTO_APPROVAL) ? (new Date()).getTime().toString() : null
            };

            let options = Endpoints.alloc_adjustment.ALLOC_ADJUSTMENT_HEADERS;
            options.headers = options.headers.set(
                "X-SystemUserId",
                this.authService.user.systemUserID
            );
            try {
                const response: IAllocationAdjustmentCreateResponseDTO = await this.http.post<IAllocationAdjustmentCreateResponseDTO>(url, transferDTO, options).toPromise();
                if (response) {
                    const allocAdjustment = await this.allocAdjustService.handlePostAdjustment(response, form);
                    return allocAdjustment;
                }
            } catch (error) {
                console.error('AllocationTransferDataService: transfer: ', error);
                return await this.allocAdjustService.handleError(error, transferDTO,undefined,form);
            }
        }
    }

    async updateStatus(status: number, form?: AllocationAdjustForm): Promise<IAllocationAdjustment> {
      const selectedAdjustment: IAllocationAdjustment = this.allocationFeatureService.selectedShipment as IAllocationAdjustment;
      let url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.alloc_adjustment.ALLOC_ADJUSTMENT_UPDATE.replace('{{adjustmentID}}',selectedAdjustment.indskr_usershipmentallocation_v2id);
      let payload;

      if (form) {
        payload = {
          indskr_adjustedstatus: status,
          indskr_approvedby: status === AdjustmentStatus.Approved ? this.authService.user.systemUserID : null,
          indskr_adjustmentapproveddate: status === AdjustmentStatus.Approved ? (new Date()).getTime().toString() : null,
          indskr_skuid: form.sku.id,
          indskr_lotid: form.lot.id,
          indskr_reasonforadjustment: form.reason.value,
          indskr_comment: form.comments,
          indskr_quantityadjusted: form.quantityAdjusted
        };
      } else {
        payload = {
          indskr_adjustedstatus: status,
          indskr_approvedby: status === AdjustmentStatus.Approved ? this.authService.user.systemUserID : null,
          indskr_adjustmentapproveddate: status === AdjustmentStatus.Approved ? (new Date()).getTime().toString() : null,
          indskr_skuid: selectedAdjustment.at_indskr_skuid,
          indskr_lotid: selectedAdjustment.at_indskr_lotid,
          indskr_reasonforadjustment: selectedAdjustment.indskr_reasonforadjustment,
          indskr_comment: selectedAdjustment.indskr_comment,
          indskr_quantityadjusted: selectedAdjustment.indskr_quantityadjusted
        };
      }

          let headers = Endpoints.alloc_adjustment.ALLOC_ADJUSTMENT_HEADERS;
          headers.headers = headers.headers.set('X-SystemUserId', this.authService.user.systemUserID);

          try {
            const response = await this.http.patch<IAllocationAdjustmentCreateResponseDTO>(url, payload, headers).toPromise();

            if (response) {
              const updatedAllocAdjustment = await this.allocAdjustService.handlePostAdjustmentUpdate(response, selectedAdjustment);
              return updatedAllocAdjustment;
            }
          } catch (error) {
            console.error('AllocationTransferDataService: transfer: ', error);
            return this.allocAdjustService.handleError(error, payload ,selectedAdjustment);
          }
        }
  }




export enum AdjustmentError {
  ERR_IO_AD01 = 'ERR_IO_AD01',
  ERR_IO_AD02 = 'ERR_IO_AD02',
  ERR_IO_AD03 = 'ERR_IO_AD03',
  ERR_IO_AD04 = 'ERR_IO_AD04',
  ERR_IO_AD05 = 'ERR_IO_AD05',
  ERR_IO_AD06 = 'ERR_IO_AD06'
}

