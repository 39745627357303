import { Component, Input, Output, EventEmitter } from '@angular/core';
import { OmniAccordionViewDataModel } from '@omni/models/omniAccordionViewDataModel';
import { DeviceService } from '@omni/services/device/device.service';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { electronApi } from "@omni/services/electron-api";
import { DomSanitizer } from '@angular/platform-browser';
import _ from 'lodash';
import { SearchConfigDataService } from '@omni/data-services/search-config/search-config-data-service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SearchConfigService } from '@omni/services/search/search-config.service';

@Component({
  selector: 'omni-accordion',
  templateUrl: 'omni-accordion.html',
  styleUrls:['omni-accordion.scss']
})
export class OmniAccordionComponent {

  @Input() viewData:OmniAccordionViewDataModel;
  public chekcedlinkableText: any;
  @Output() favouriteAdd = new EventEmitter();
  @Output() onSearchInput = new EventEmitter();
  @Output() onToggleItem = new EventEmitter();
  @Output() onToggleItemClick = new EventEmitter();
  @Output() onLoadMoreOptions = new EventEmitter();
  @Output() onToggleSingleItem = new EventEmitter();
  public childItemSelected: boolean;
  public searchText: string = '';
  @Input('depth') depth: number = 0;

  tempViewData: OmniAccordionViewDataModel;
  public searching: boolean = false;
  public ngDestroy$ = new Subject<boolean>();

  constructor(
    public device: DeviceService,
    private iab: InAppBrowser,
    private readonly sanitizer: DomSanitizer,
    public searchConfigDataService: SearchConfigDataService,
    public searchConfigService: SearchConfigService,
  ){
    this.searchConfigDataService.filterMenuFilterClear$.pipe(takeUntil(this.ngDestroy$)).subscribe(()=>{
      this._clearFilter();
    });
  }

  ngOnDestroy() {
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }

  //linkable text conversion
  linkableText(text) {
    const urlRegexp = new RegExp(/([^\S]|^)(((https?\:\/\/)|(www\.))(\S+))/,'gi');
    let checkedText = '';
    checkedText = (text.toString() || "").replace(urlRegexp, function(match, space, url) {
      return space + '<a id="' + url + '"' + ' style="color:#007AFF; text-decoration:underline">' + url + '</a>';
    });
    let formattedText = this.sanitizer.bypassSecurityTrustHtml(checkedText);
    return formattedText;
  }
  openUrl(event:any, text) {
    if(event && event.target && event.target.id == '') return;
    let checkedText: string[] = [];
    const urlRegexp = new RegExp(/([^\S]|^)(((https?\:\/\/)|(www\.))(\S+))/,'gi');
    (text.toString() || "").replace(urlRegexp, function(match, space, url) {
      let hyperlink = url;
      if (!hyperlink.match('^https?:\/\/') && !hyperlink.match('^http?:\/\/')) {
        hyperlink = 'https://' + hyperlink;
      }
      hyperlink = hyperlink.replaceAll('"','');
      hyperlink = hyperlink.replaceAll('<','');
      hyperlink = hyperlink.replaceAll('>','');
      checkedText.push(hyperlink);
    });
    if(checkedText && checkedText.length > 0) {
      let targetId = event && event.target ? event.target.id : '';
      targetId = targetId.replace('https://', '');
      targetId = targetId.replace('http://', '');
      targetId = targetId.replace('wwww.', '');
      const idx = checkedText.findIndex(t=> targetId && t.includes(targetId));
      if(idx>-1) {
        if (this.device.isNativeApp && !this.device.deviceFlags.electron) {
          if (this.device.deviceFlags.ios) this.iab.create(checkedText[idx], '_blank', { location: 'no', hideurlbar: 'yes', zoom: 'no' });
          else this.iab.create(checkedText[idx], '_blank', { location: 'yes', hideurlbar: 'yes', zoom: 'no' });
        } else {
          if(this.device.deviceFlags.electron) {
            electronApi.openExternal(checkedText[idx]);
          }else {
            window.open(checkedText[idx], "_system");
          }
        }
      }
    }
  }
  public expandChildItems(event,value){
    if(!this.viewData.isExpanded && this.viewData.isAccordionOnlineOnly){
      if(!this.viewData.childItems.length) {
        this.viewData.callbackForOnlineChildDataFetch().then((data)=>{
          if(data && data.length){
            this.viewData.childItems = data;
            this.viewData.isExpanded = value;
          }
        })
      }
      else this.viewData.isExpanded = value;
    }
    else  this.viewData.isExpanded = value;
  }

  public handlePrimaryLabelClick(event, specificClickTarget?: string) {
    if (this.viewData.clickHandler) {
      this.viewData.clickHandler(this.viewData.id, event, specificClickTarget, this.viewData);
      try {
        if (event) {
          event.stopPropagation();
        }
      } catch (error) {
        // Handle Error To Do
      }
    }
  }

  public favourite(item) {
    event.stopPropagation();
    this.favouriteAdd.emit(item);
  }

  public handleRightIconCLick(event, specificClickTarget?:string, selected?){
    if(this.viewData.clickHandler){
      this.viewData.clickHandler(this.viewData.id, event, specificClickTarget, selected);
    }else if(selected && selected.id && selected.id.includes('loadmoreoptions')){
      this.onLoadMoreOptions.emit(selected);
    }
  }

  public handleLableCLick(event,specificClickTarget?:string){
    if(specificClickTarget && specificClickTarget == 'loadmoreoptions'){
      this.onLoadMoreOptions.emit(true);
    }
  }

  public toggleItem(item, event) {
    if (event && event.detail) {
      if(event.detail.checked)  {
        if (item.isSingleSelect || this.viewData.isSingleSelect) this.viewData.childItems.forEach((child) => {
          if(child.id != item.id) child.isChecked = false;
        })
        this.viewData.childItems.find((i)=> i.id == item.id).isChecked = true;
        item.isChecked = true;
      } else {
        this.viewData.childItems.find((i)=> i.id == item.id).isChecked = false;
        item.isChecked = false;
      }
      if(item.isSingleSelect) this.onToggleSingleItem.emit(item);
      else this.onToggleItem.emit(item);
    } 
  }

  public handleCheckboxClick(item,event){
    if (event && event.detail) {
      this.onToggleItemClick.emit(item);
    } 
  }

  emitToggleItemClick(item,event){
    this.onToggleItemClick.emit(item);
  }
  emitToggleItem(item,event){
    this.onToggleItem.emit(item);
  }

  // public _onSearchInput(ev: any) {
  //   this.onSearchInput.emit(ev);
  // }

  public _onSearchInput(viewData, ev) {
    this.tempViewData = _.cloneDeep(viewData);
    const val: string = (ev && ev.target && ev.target.value) ? ev.target.value : '';
    if (val.length >= 1) {
      this.searching = true;
      let foundValues = [];
      const formattedSearchText = this.searchConfigService.convertFormattedString(val).trim().toLowerCase();
      this.tempViewData.childItems.filter(i => {
        const formattedItem = this.searchConfigService.convertFormattedString(i.label).trim().toLowerCase();
        if(formattedItem.includes(formattedSearchText)) {
          if(!_.isEmpty(foundValues)) {
            const idx = foundValues.findIndex(v=>v.id == i.id);
            if(idx < 0) {
              foundValues.push(i);  
            } 
          }else {
            foundValues.push(i);
          }
        }
      })
      this.tempViewData.childItems = foundValues;
      this.tempViewData.searchActiveAt = 0;
    } else {
      this.searching = false;
      this.tempViewData = undefined;
    }
  }

  private _clearFilter() {
    if(this.searching && !_.isEmpty(this.tempViewData) && !_.isEmpty(this.tempViewData.childItems)) {
      this.tempViewData.childItems.map(c=>c.isChecked = false);
    }
  }
}
