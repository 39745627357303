import { Component, Input, ViewChild } from '@angular/core';
import { NextCallObjectiveService } from '../../services/next-call-objectives/next.call.objectives.service';
import { AuthenticationService } from '../../services/authentication.service';
import { NextCallObjective, NextCallObjectiveStateCode, NextCallObjectiveStatusCode } from '../../classes/Next-Call-Objective/next.call.objective';
import { format, isBefore } from 'date-fns';
import { DateTimeFormatsService } from '../../services/date-time-formats/date-time-formats.service';
import * as _ from 'lodash';
import { NextCallObjectiveDataService } from '../../data-services/next-call-objective/next.call.objective.data.service';
import { ActivityService } from '../../services/activity/activity.service';
import { ToastStyle } from '../../services/notification/notification.service';
import { NotificationService } from '../../services/notification/notification.service';
import { Activity } from '../../classes/activity/activity.class';
import { Contact } from '../../classes/contact/contact.class';
import { TranslateService } from '@ngx-translate/core';
import { IonItemSliding } from '@ionic/angular';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { EventsService, EventName } from '@omni/services/events/events.service';
import { IndSectionHeaderViewDataModel } from '@omni/models/indSectionHeaderDataModel';
import { DatePipe } from '@angular/common';

/**
 * Generated class for the NextCallObjectiveComponent component.
 * Description : This component is called in activity-details-pane.html
 */
@Component({
  selector: 'next-call-objective',
  templateUrl: 'next-call-objective.html',
  styleUrls:['next-call-objective.scss']
})
export class NextCallObjectiveComponent {

  @Input() contacts: Contact[] = [];
  @Input() isMeetingComplete: boolean = false;
  @Input() backgroundUploadInProgress: boolean;
  newObjectiveInput: string = '';
  nextObjectivesList: NextCallObjective[] = [];
  editingObjective: NextCallObjective;
  isSaveObjectiveEnabled: boolean = false;
  selectedActivity: Activity;
  contactIdList: string[] = [];
  isMeetingReadOnly = false;
  private isSlidingDragging = false;
  private waitingForUserTouchNotSliding = false;
  @ViewChild('slidingItem', { static: false }) slidingItem: IonItemSliding;
  private ngDestroy$ = new Subject<boolean>();
  public isCovisitor: boolean = false;

  constructor(
    public nextCallServe: NextCallObjectiveService,
    public authService: AuthenticationService,
    public dateTimeFormatsService: DateTimeFormatsService,
    private nextCallObjectiveDataService: NextCallObjectiveDataService,
    private activityService: ActivityService,
    private notif: NotificationService,
    private translate: TranslateService,
    private eventService: EventsService,
    private datePipe: DatePipe
  ) {

  }

  ngOnInit() {
    this.eventService.observe(EventName.ACTIVITY_DETAILS_INTERACTION).pipe(takeUntil(this.ngDestroy$)).subscribe((data: any) => {
      this.closeSlideOption();
    });
    this.initNCOHeaderModel();
    this.translate.onLangChange.subscribe(data => {
      this.initNCOHeaderModel();
    })
  }

  ngOnChanges() {
    this.getAllObjectiveList();

  }

  ngAfterContentChecked() {
    this.isCovisitor = this.activityService.selectedActivity && (!_.isEmpty(this.activityService.selectedActivity['accompaniedUserList']) ? this.activityService.selectedActivity['accompaniedUserList'].some(user => user.id === this.authService.user.systemUserID) : false);
  }

  ngOnDestroy() {
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }

  /**
   * @description This method gets list all the ncos from the serive and assign it to the local variable "nextObjectivesList"
  */
  getAllObjectiveList() {
    this.isMeetingReadOnly = this.activityService.selectedActivity.isCompleted || this.backgroundUploadInProgress ? 
    true : 
    this.isCovisitor && !this.authService.user.covisitorEditMeeting ? 
    true : 
    this.activityService.teamViewActive || this.activityService.selectedActivity?.isDiffPosition;
    this.selectedActivity = this.activityService.selectedActivity;
    this.contactIdList = this.contacts.map(contact => { return contact.ID });
    this.nextObjectivesList = [];
    if(!this.isMeetingReadOnly) {
      if (this.contactIdList && this.contactIdList.length > 0) {
        this.nextObjectivesList = this.nextCallServe.getPendingObjectivesForContact(this.contactIdList);
      }
    } else if (this.activityService.teamViewActive) {
      if (this.contactIdList && this.contactIdList.length > 0) {
        this.nextObjectivesList = this.nextCallServe.getPendingObjectivesForContact(this.contactIdList);
      }
    }
  // Filter out objectives
    this.selectActivityForMeeting();
    this.removeDuplicated();
    this.removeDuplicatesbasedOnExternalIDFirstHalf(); // This ensures We don't have duplicates and only one objective exists in the entire array for
    this.removeDeleted();
    this.sortObjective();
  }

  /**
   * @description This method removes duplicates nco for multi customer meeting
  */
//  obj1-0
//  obj1-1
//  obj2-0
//  obj2-1
//  obj3-0
//  obj3-1

  private removeDuplicatesbasedOnExternalIDFirstHalf() {
    const temp = [];
    this.nextObjectivesList.forEach((objective) => {
      // Find out objectives with same externalID with first half that is  offline_callobjective_<<milliseconds>>
      const externalIDArray = objective.externalid.split('--');
      const firstHalfOfExternalID =
        externalIDArray.length > 0 ? externalIDArray[0] : objective.externalid;

      const fiteredObjectives = this.nextObjectivesList.filter(
        (filteredObjective) => {
          return (
            filteredObjective.externalid.indexOf(firstHalfOfExternalID) !== -1
          );
        }
      ); // Filtered out items which are having first half of externalID same

      fiteredObjectives.forEach((filteredObjective) => {
        const foundObjective = this.nextObjectivesList.find((item, index) => {
          return item.externalid === filteredObjective.externalid;
        });

        this.nextObjectivesList.splice(
          this.nextObjectivesList.indexOf(foundObjective),
          1
        );
      });
      this.nextObjectivesList.unshift(objective);
    });
  }
  /**
   * @description This method gets list all the ncos the current meeting and append it to the local variable "nextObjectivesList"
  */
  selectActivityForMeeting() {
    const nextObjectivesCapturedInMeeting = this.nextCallServe.getPendingObjectiveForMeeting(this.selectedActivity.ID);
    const nextObjectivesCompletedInMeeting = this.nextCallServe.getCompletedObjectiveForMeeting(this.selectedActivity.ID);
    this.nextObjectivesList = [...this.nextObjectivesList, ...nextObjectivesCapturedInMeeting, ...nextObjectivesCompletedInMeeting]
  }


  onObjectiveFocus() {
    // console.log('onNotesFocus');
  }

  /**
   * @description This method is called on clear nco click and it clears the nco variables
  */
  public async saveObjective() {
    if (this.contactIdList.length == 0) {
      this.notif.notify(this.translate.instant('PLEASE_SELECT_CUSTOMER_NCO'), "activity-details", "top", ToastStyle.INFO);
      return;
    }
    if (this.newObjectiveInput && this.newObjectiveInput.trim() != '') {
      if (this.editingObjective) {
        await this.updateObjective();
      }
      else {
        await this.createObjective();
      }
      this.newObjectiveInput = '';
      this.objectiveChanged();
    }
  }

  /**
   * @description This method is called on clear nco click and it clears the nco variables
  */
  public clearObjective() {
    this.newObjectiveInput = '';
    this.isSaveObjectiveEnabled = false;
    this.editingObjective = undefined;
    this.initNCOHeaderModel();
  }

  /**
   * @description This method is called on save nco click and calls data service to update the nco in dynamics
  */
  private async updateObjective() {

    const objectivestoBeEdited = this.nextCallServe.getObjectivesWithExternalID(this.editingObjective.externalid);

    objectivestoBeEdited.forEach((objective, index) => {
      objective.objective = this.newObjectiveInput;
      objective.syncPending = true;
      const objectivefromList = this.nextObjectivesList.find(item => {
        return objective.externalid === item.externalid;
      });
      if (objectivefromList) {
        objectivefromList.objective = this.newObjectiveInput;
      }
    });

    await this.nextCallObjectiveDataService.bulkNextCallService(objectivestoBeEdited);

    this.editingObjective = undefined;
  }

  /**
   * @description This method is called on save nco click and calls data service to create the nco in dynamics
  */
  private async createObjective() {
    const objectives: NextCallObjective[] = this.createObjectiveData(this.newObjectiveInput);
    if (objectives.length > 0) {// this is to add only one objective in case of multiple created for multiple customer.
      this.nextObjectivesList.push(objectives[0]);// Thsi will ensure that we have only one is taen out of multiple created
    }
    // let objectiveId: string = this.nextCallObjectiveDataService.createNextCallObjective(objective);
    await this.nextCallObjectiveDataService.bulkNextCallService(objectives);
    this.sortObjective();
  }

  objectiveChanged() {
    setTimeout(() => {
      this.isSaveObjectiveEnabled = (this.newObjectiveInput && this.newObjectiveInput.trim() != '')? true: false;
      this.initNCOHeaderModel();
    }, 10);
  }

  // public getFormattedDateTime(value: Date): string {
  //   return format(value, this.dateTimeFormatsService.dateTimeToUpper);
  // }


  public getFormattedDateTime(value: Date): string {
    if (value) {
      // return format(value, this.dateTimeFormatsService.dateTimeToUpper);
      let noteDay = this.datePipe.transform(value, this.dateTimeFormatsService.date, undefined, this.translate.currentLang)

      let noteTime = (new Date(value)).toLocaleTimeString(this.translate.currentLang, { hour12: this.dateTimeFormatsService.is12HourFormat, hour: '2-digit', minute: '2-digit' });
      return noteDay + " " + noteTime;
    } else {
      return '';
    }
  }


  /**
   * @description This method is called on edit nco click. it moves the nco to the text area to update
   * @param {any=} id from nco list in html to get the nco from the list
   * @param {ItemSliding=} slidingItem from nco list in html to close the slider after edit click
  */
  editObjective(id,slidingItem: IonItemSliding) {
    const index = _.findIndex(this.nextObjectivesList, { externalid: id });
    if (index != -1) {
      this.editingObjective = this.nextObjectivesList[index];
      this.newObjectiveInput = this.editingObjective.objective;
      this.objectiveChanged();
      slidingItem.close();
    }
  }

  /**
   * @description This method is called on delete nco click. it deletes the nco from local variable and calls data service to delete nco
   * @param {any=} id from nco list in html to get the nco from the list
  */
  deleteObjective(id) {
    const index = _.findIndex(this.nextObjectivesList, { externalid: id });
    if (index != -1) {
      const objective = this.nextObjectivesList[index]
      const objectivestoBeDeleted = this.nextCallServe.getObjectivesWithExternalID(objective.externalid);

      objectivestoBeDeleted.forEach((objective, index) => {
        objective.deletestate = true;
        objective.stateCode = NextCallObjectiveStateCode.active;
        objective.statusCode = NextCallObjectiveStatusCode.pending;
        objective.syncPending = true;

        const foundObjective = this.nextObjectivesList.find((item, index) => {
          return item.externalid === objective.externalid;
        });
        if (foundObjective) {
          this.nextObjectivesList.splice(this.nextObjectivesList.indexOf(foundObjective), 1);
        }
      });
      this.nextCallObjectiveDataService.bulkNextCallService(objectivestoBeDeleted);
    }
  }

  /**
   * @description This method is called on complete nco click. it changes to variable "isComplete" of the nco in the list and calls data service to update nco
   * @param {any=} id from nco list in html to get the nco from the list
  */
  completeObjectiveTogggle(id) {
    if (!this.isMeetingReadOnly) {
      const index = _.findIndex(this.nextObjectivesList, { externalid: id });
      if (index != -1) {

        const objective = this.nextObjectivesList[index];

        if (objective.userId !== this.authService.user.systemUserID) {
          return;
        }

      const objectivestoBeCompleted = this.nextCallServe.getObjectivesWithExternalID(objective.externalid);

      objectivestoBeCompleted.forEach((anObjective, index) => {
        const isComplete = !anObjective.isComplete
        anObjective.isComplete = isComplete;
        anObjective.stateCode = isComplete ? NextCallObjectiveStateCode.inActive : NextCallObjectiveStateCode.active;
        anObjective.statusCode = isComplete ? NextCallObjectiveStatusCode.completed: NextCallObjectiveStatusCode.pending;
        anObjective.syncPending = true;
        anObjective.completedMeetingId = isComplete ? this.activityService.selectedActivity.ID: '';

        const foundObjective = this.nextObjectivesList.find(item => {
          return item.externalid === objective.externalid;
        });
        if (foundObjective) {
        const newIndex = this.nextObjectivesList.indexOf(foundObjective)
        this.nextObjectivesList[newIndex] = anObjective
        }
      });
        this.nextCallObjectiveDataService.bulkNextCallService(objectivestoBeCompleted);
      }
    }
  }

  /**
   * @description This method creates the new nco data with the text passed
   * @param {any=} objectiveText from createObjective method the add the nco text which create nco object
  */
  createObjectiveData(objectiveText) {

    const objectives: NextCallObjective[] = [];
    const time = new Date().getTime().toString();

    this.contactIdList.forEach((contactID, index) => {

      const objective: NextCallObjective = {
        objectiveID: '',
        externalid: 'offline_callobjective_' + time + '--' + index,
        objective: objectiveText,
        stateCode: NextCallObjectiveStateCode.active,
        statusCode: NextCallObjectiveStatusCode.pending,
        isComplete: false,
        contactId: contactID,
        userId: this.authService.user.systemUserID,
        capturedMeetingId: this.activityService.selectedActivity.ID,
        completedMeetingId: '',
        createdOn: new Date().getTime(),
        syncPending: true,
        deletestate: false,
      }
      objectives.push(objective);
    })
    return objectives;
  }

  sortObjective() {
    this.nextObjectivesList.sort((a, b) => {
      return (isBefore(a.createdOn, b.createdOn) ? 1 : -1)
    });
  }

  removeDeleted() {
    this.nextObjectivesList = _.filter(this.nextObjectivesList, (e) => {
      return e.deletestate === false;
    });
  }

  removeDuplicated() {
    this.nextObjectivesList = _.filter(this.nextObjectivesList, (e) => {
      return e !=undefined;
    })
    this.nextObjectivesList = _.uniqBy(this.nextObjectivesList, (e) => {
      return e.externalid;
    });
  }

  isMyObjective(objective: NextCallObjective): boolean {
    return objective.userId === this.authService.user.systemUserID;
  }

  /**
   * @description This method will close slide option.
  */
  private closeSlideOption() {
    if (this.isSlidingDragging) {
      this.isSlidingDragging = false;
      this.waitingForUserTouchNotSliding = true;
    } else if (this.waitingForUserTouchNotSliding) {
      this.slidingItem.closeOpened();
      this.waitingForUserTouchNotSliding = false;
    }
  }

  /**
   * @description This method will listen to ionDrag event.
  */
  public onSlideDragging(event) {
    this.isSlidingDragging = true;
    this.waitingForUserTouchNotSliding = false;
  }

 public ncoHeaderModel: IndSectionHeaderViewDataModel;

  private initNCOHeaderModel() {

    let detailsHeaderControls = [];
    detailsHeaderControls.push({
      id: 'meeting-nco-save',
      text: this.translate.instant('SAVE'),
      isDisabled: !this.isSaveObjectiveEnabled || this.isMeetingReadOnly,
    });
    detailsHeaderControls.push({
      id: 'meeting-nco-cancel',
      text: this.translate.instant('CANCEL'),
      isDisabled: !this.isSaveObjectiveEnabled || this.isMeetingReadOnly,
    });
    this.ncoHeaderModel = {
      id: 'notes-header',
      title: this.translate.instant('NEXT_MEETING_OBJECTIVES_CAP'),
      controls: detailsHeaderControls,
    };
  }

  onSectionHeaderControlClick(id) {
    if (id === 'meeting-nco-save') {

      this.saveObjective();

    } else if ( id === 'meeting-nco-cancel') {
        this.clearObjective();
    }
  }
}
