import {DatePipe} from "@angular/common";
import {Inject, LOCALE_ID, Pipe, PipeTransform} from "@angular/core";
import {DATE_TIME_CONSTANTS, DateTimeFormatsService} from "../services/date-time-formats/date-time-formats.service";

@Pipe({
  name: 'customDate'
})
export class ExtendedDatePipe extends DatePipe implements PipeTransform{

  constructor(
    @Inject(LOCALE_ID) locale: string,
    public dateTimeFormatsService: DateTimeFormatsService,
  ) {
    super(locale);
  }

  transform(value: any, format, timezone?: string, locale?: string) {
    switch (format) {
      case DATE_TIME_CONSTANTS.DATE : {
        format = this.dateTimeFormatsService.date;
        break;
      }
      case DATE_TIME_CONSTANTS.TIME : {
        format = this.dateTimeFormatsService.time;
        break;
      }
      case DATE_TIME_CONSTANTS.DATE_TIME : {
        format = this.dateTimeFormatsService.dateTime;
        break;
      }
      default:
        format = 'MMM dd, yyyy';
    }
    return super.transform(value, format, timezone, locale);
  }
}
