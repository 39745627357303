import { Component, Input } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { IonNav } from '@ionic/angular';
import { cloneDeep } from 'lodash';
import { IndPageTitleViewDataModel } from '@omni/models/indPageTitleDataModel';
import { ContentToken, ResourceEmailTemplate } from '../../../classes/email-templates/email-template.class';
import { EmailService } from '../../../services/email-templates/email.service';
import { FooterService, FooterViews } from '../../../services/footer/footer.service';
import { UIService } from '../../../services/ui/ui.service';
import { NavigationService } from '@omni/services/navigation/navigation.service';
import { DeviceService } from '@omni/services/device/device.service';
import { TranslateService } from '@ngx-translate/core';
import { Channel, ChannelActivityType, ChannelType } from '@omni/classes/consent/channel.class';
import { EmailActivity } from '@omni/classes/activity/email.activity.class';
import { ConsentService } from '@omni/services/consent/consent.service';
import { AuthenticationService } from '@omni/services/authentication.service';
import { ActivityService } from '@omni/services/activity/activity.service';

/**
 * Generated class for the EmailTemplateDetailsComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'email-template-details',
  templateUrl: 'email-template-details.html',
  styleUrls: ['email-template-details.scss']
})

export class EmailTemplateDetailsComponent {

  @Input() template: ResourceEmailTemplate;
  public preview: SafeHtml;
  public contentBody: string;
  public customPreview: SafeHtml;
  public editedTemplateBody: string;
  private contentTokens: ContentToken[];
  templateDetailsPageTitle: IndPageTitleViewDataModel;

  constructor(
    public readonly footerService: FooterService,
    private readonly navCtrl: IonNav,
    private readonly emailService: EmailService,
    private readonly sanitizer: DomSanitizer,
    private readonly navService: NavigationService,
    public readonly translate: TranslateService,
    public device: DeviceService,
    public uiService: UIService,
    private consentService: ConsentService,
    private authService: AuthenticationService,
    private activityService: ActivityService,
    ) {

  }

  convertFromTokenToHtml(elements) {
    const parser = new DOMParser();
    const allTokens = this.emailService.contentTokens.reduce((result, value) => result = [...result, ...value['replacementTokens']], []);
    const value = parser.parseFromString(elements, 'text/html');
    if (elements.match(/\%(.*?)\%/g)) {
      elements.match(/\%(.*?)\%/g).forEach((node) => {
        let foundToken;
        if (node.indexOf("%custom") !== -1) {
          const repId = node.substr(node.lastIndexOf(".") + 1, 36);
          const tId = node.substr(8, 36);
          foundToken = allTokens.find(subtk => subtk.indskr_contenttokenid === tId && subtk.replacement_id === repId);
        }
        if (foundToken) {
          const html = `<span class="highlightCustomToken" id="${foundToken.indskr_contenttokenid}:${foundToken.replacement_id}"><label class="custom" data-cid="${foundToken.indskr_contenttokenid}:${foundToken.replacement_id}">${foundToken.replacement_value}</label><img style="width: 24px; height: 24px; margin-left: 2px" src="assets/imgs/email_template_edit.svg"></span>`;
          elements = elements.replace(node, html);
        }
      });
    }

    return elements;
  }

  ngOnInit() {
    this.initTemplateDetailsPageTitle();
    let body;
    if (this.template && this.template.channelTypes) {
      body = this.template.indskr_body ?? '';
      const email: EmailActivity = this.emailService.selectedActivity;
      const channelId = email?.indskr_channelid
      if (channelId !== undefined && channelId !== "") {
        const cha: Channel = this.consentService.savedChannels.find(sch => sch.indskr_consenttypeid === channelId);
        switch (cha.activityType){
          case ChannelActivityType.FACEBOOK:
            body = this.template && this.template.indskr_fb_body;
            break;
          case ChannelActivityType.SMS:
            body = this.template && this.template.indskr_sms_body;
            break;
          case ChannelActivityType.WHATSAPP:
            body = this.template && this.template.indskr_whatsapp_body;
            break;
          default:
            body = this.template && this.template.indskr_body;
            break;
        }
      }
    }
    if (body) {
      if (this.template.content_tokens) {
        let content_body = body;
        this.editedTemplateBody = body;
        const contentTokens: ContentToken[] = this.emailService.contentTokens;
        const hideContentTokenId: string = this.emailService.getHideContentTokenId();
        // Replaced custom token name
        this.template.content_tokens.forEach(addedToken => {
          contentTokens.forEach(allToken => {
            const cId = allToken.indskr_contenttokenid;
            const cIdindex = content_body.indexOf(cId);
            const replacement = allToken.replacementTokens.find(rt => rt.is_default);
            if (replacement && addedToken === cId && content_body && cIdindex !== -1) {
              if(allToken.indskr_iseditable) {
                let token = content_body.substring(content_body.indexOf(cId + '.'));
                token = token.substring(0, token.indexOf('%')+1);
                content_body = content_body.replace('%custom.' + token, '');
              } else {
                let tokenHolder = '%custom.' + content_body.substring(cIdindex, cIdindex + (2 * cId.length) + 2);
                if (cId != hideContentTokenId) {
                  content_body = content_body.replace(tokenHolder, allToken.indskr_name);
                } else {
                  // To hide the content token if there is a content token that needs to be hidden. 
                  const numOfOccurrences = content_body.split(hideContentTokenId).length - 1;
                  for (let numToHide = 1; numToHide <= numOfOccurrences; numToHide++) {
                    content_body = content_body.replace(tokenHolder, '');
                  }
                }
              }
            }
          })
        });
        //linkable text conversion
        this.contentBody = content_body;
        let formattedBody = this._replacePropertyInTag(content_body);
        this.preview = this.sanitizer.bypassSecurityTrustHtml(formattedBody);
      }
    }
    this.footerService.initButtons(FooterViews.EmailTemplates);
    this.contentTokens = cloneDeep(this.emailService.contentTokens);
  }
  private _replacePropertyInTag(contentBody: any): any {
    return this.activityService.replacePropertyInTag(contentBody);
  }
  openUrl(event:any, contentBody: any) {
    if(event && event.target && (event.target.id == '' || event.target.id == undefined) || this.device.isOffline) return;
    const eventId: string = event.target.id;
    this.activityService.openExternalLink(eventId, contentBody);
  }

  private initTemplateDetailsPageTitle(): void {
    this.templateDetailsPageTitle = {
      id: 'template-details-page-title',
      title: this.template && this.template.indskr_name,
      controls: [],
    };
  }

  async goBack() {
    this.navService.setChildNavRightPaneView(false);
  }

  public onPageTitleControlClick(id: string) {
    switch (id) {
      case 'close':
        this.goBack();
        break;
      default:
        console.log('Unhandled switch case statement');
        break;
    }
  }

}
