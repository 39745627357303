import { Component, Input, ViewChild } from '@angular/core';
import { ComponentViewMode, UIService } from '../../services/ui/ui.service';
import { NavigationService, PageName, ChildNavNames } from '../../services/navigation/navigation.service';
import { AccountOfflineService } from '../../services/account/account.offline.service';
import { NavController,  IonNav } from '@ionic/angular';
import { Events } from '@omni/events';
import { AccountPlanList } from '../../components/account-management/account-plan-list/account-plan-list';
import { AccountManagementOfflineService } from '../../services/account-management/account-management.service';
import { DeviceService } from '../../services/device/device.service';
import { FooterService, FooterViews } from '../../services/footer/footer.service';
import { TrackService, TrackingEventNames } from '../../services/logging/tracking.service';
import { AccountPlanDetails } from '@omni/components/account-management/account-plan-details/account-plan-details';
import { AccountPlan, AccountPlanMode } from '@omni/classes/account-management/account-plan.class';
import { AccountPlansSelectionPageModel } from '@omni/models/account-plans-selection-page-model';

/**
 * Generated class for the AccountManagementPageComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'account-management-page[base-page]',
  templateUrl: 'account-management-page.html',
  styleUrls:['account-management-page.scss']
})
export class AccountManagementPageComponent {

  public accountListMode: ComponentViewMode;
  @ViewChild('accountmanagementrightpane', {static: true}) public navCtrl: IonNav;

  @Input() viewData: AccountPlansSelectionPageModel = {viewMode: ComponentViewMode.LISTVIEW};

  constructor(
    public navService: NavigationService,
    public accountService: AccountOfflineService,
    public uiService: UIService,
    public accountManagementService: AccountManagementOfflineService,
    public device: DeviceService,
    private footerService: FooterService,
    public trackingService: TrackService,
    private events: Events
  ) {
    this.accountListMode = ComponentViewMode.LISTVIEW;
  }

  ngOnInit(){
    this.accountService.accessedAccountListFrom = PageName.AccountManagementPageComponent;
    this.navService.initRightPaneChildNav(this.navCtrl,ChildNavNames.AccountManagementNavigation,PageName.AccountManagementPageComponent, false, {deviceString: this.device.deviceFlags.ios ? 'ios' : 'other'});
    this.footerService.initButtons('');
  }

  ionViewDidEnter(){
    if(this.uiService.isFlipButtonClicked){
      this.uiService.isFlipButtonClicked = false;
    }
  }

  public isSplitView():boolean {
    return (window.innerWidth > 500);
  }

  // public async closeAccountPlansModal(ev) {
  //   this.trackingService.tracking('AccountManagementBack', TrackingEventNames.ACCOUNTPLANNING)
  //   this.uiService.activeView = '';
  //   if(this.uiService.activitiesPageTab === 'plans') {
  //     this.uiService.showNewActivity = false;
  //     this.uiService.activeView = 'ActivitiesPageRightPaneNav';
  //     this.uiService.activitiesPagePlanTabSelectedPlan = 'accountPlansTab';
  //   }
  //   if(this.accountService.accessedAccountListFrom === PageName.AccountManagementPageComponent) {
  //     this.accountService.accessedAccountListFrom = null;
  //     this.accountService.selected = undefined;
  //     this.uiService.showRightPane = false;
  //     // this.navService.popChildNavCtrlFromStack(ChildNavNames.AccountManagementNavigation);
  //     await this.navService.popToRootWithPageTracking();
  //   }
  // }

  ngOnDestroy(){
    this.accountManagementService.isRightPaneNavActive = false;
    this.navService.popChildNavCtrlFromStack(ChildNavNames.AccountManagementNavigation).then(() => {
      this.events.publish("tools-back-clicked", PageName.AccountManagementPageComponent);
    });
  }

  public openAccountPlanDetail(event): void {
    if (event?.plan) {
      this.accountManagementService.isRightPaneNavActive = true;
      this.navService.setChildNavRoot(AccountPlanDetails, PageName.AccountPlanDetailsComponent, PageName.AccountManagementPageComponent, { data: event.plan, selectedMode: event.selectedMode });
      this.footerService.initButtons(FooterViews.AccountPlanObjectives);
    }
  }

}
