import { Component, ViewChild, ElementRef, OnInit, Input, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AffiliatedAccount } from '@omni/classes/market-scan/market-scan.class';
import { IndPageTitleViewDataModel } from '@omni/models/indPageTitleDataModel';
import { NavigationService, PageName } from '@omni/services/navigation/navigation.service';
import { AccountOfflineService } from '../../../services/account/account.offline.service';
import * as d3 from "d3";
import { data } from 'jquery';
import { AccountDataService } from '@omni/data-services/accounts/account.data.service';
import { Account } from '@omni/classes/account/account.class';
import { FooterViews, FooterService } from '@omni/services/footer/footer.service';
import { AccountDetailsComponent } from '@omni/components/account/account-details/account-details';
import { ComponentViewMode, UIService } from '@omni/services/ui/ui.service';
import { DynamicFormsService } from '@omni/services/dynamic-forms/dynamic-forms-service';
import { DeviceService } from '@omni/services/device/device.service';
import { ContactOfflineService } from '@omni/services/contact/contact.service';
import { EventsService } from '@omni/services/events/events.service';
import _ from 'lodash';
import { IonContent } from '@ionic/angular';
import { IndSectionHeaderViewDataModel } from '@omni/models/indSectionHeaderDataModel';
import { PopoverController } from '@ionic/angular';
import { MultiSelectPopover } from '@omni/components/multi-select-popover/multi-select-popover';
import { Contact } from '@omni/classes/contact/contact.class';
import { ContactDetailsComponent } from '@omni/components/contact/contact-details/contact-details';
import { NotificationService, ToastStyle } from '@omni/services/notification/notification.service';

@Component({
  selector: 'affiliation-explorer',
  templateUrl: 'affiliation-explorer.html',
  styleUrls: ['affiliation-explorer.scss'],
})

export class AffiliationExplorerComponent implements OnInit {
  @Input() parent
  @Input() relatedTo;
  @Input() relatedFrom;
  @Input() contactAccountAffiliation; // this variable for both account and contact
  @Input() accountToBrand
  @Input() from: string;
  @ViewChild('chart', { static: true }) private chartContainer: ElementRef;
  @ViewChild(IonContent, { static: true }) private ionContent: IonContent;
  public affiliationFilterViewData: IndSectionHeaderViewDataModel;
  private filterBy: { text: string, value: string, asc: boolean };
  private selectedFilterByValue = ['all'];
  private selectedSourceTypeFilterByValue = 'all';
  private filterByDisplay : string;
  private filterAffiliationData;
  private data;
  private source;
  affiliationPageTitle: IndPageTitleViewDataModel = {
    id: 'affililiation-page',
    title: this.translate.instant('AFFILIATION_EXPLORER'),
    controls: [
      {
        id: "close",
        icon: "chevron-back-outline",
        isDisabled: false,
        align: "left"
      }
    ]
  };

  constructor(public navService: NavigationService,
    private translate: TranslateService,
    private accountOfflineService: AccountOfflineService,
    private uiService: UIService,
    private dynamicFormService: DynamicFormsService,
    private readonly footerService: FooterService,
    private accountDataService: AccountDataService,
    private device: DeviceService,
    private events: EventsService,
    private contactOfflineService: ContactOfflineService,
    public popover: PopoverController,
    private notificationService: NotificationService) {
  }
  ngOnInit() {
    this.filterAffiliationData = [
      {
        text: "",
        expanded: true,
        value: "all",
        selectedValues: ["all"],
        multiselect: true,
        items: [
          { text: this.translate.instant('ALL'), value: "all", asc: true },
          { text: this.translate.instant('CONTACT_AFFILIATIONS'), value: "contact", asc: true },
          { text: this.translate.instant('ACCOUNT_AFFILIATIONS'), value: "account", asc: false },
          {
            text: this.translate.instant('SOURCE_TYPE'),
            expanded: true,
            value: 'all',
            items: [
              { text: this.translate.instant('ALL'), value: 'all', filterBy: 'sourceType' },
              { text: this.translate.instant('BUSINESS_AFFILIATIONS'), value: 'Business', filterBy: 'sourceType' },
              { text: this.translate.instant('MDM_AFFILIATIONS'), value: 'MDM', filterBy: 'sourceType' },
            ],
            handler: (selectedItem, item) => {
              item.value = selectedItem.value;
              
              this.selectedSourceTypeFilterByValue = selectedItem.value;

              if (this.source && selectedItem.value) {
                this.data = _.cloneDeep(this.source);
                this.affiliationFilter(this.data, this.selectedFilterByValue, this.selectedSourceTypeFilterByValue);
                this.chartContainer.nativeElement.innerHTML = "";
                this.renderChart();
              }
              this.initFilterHeader();
            }
          }
        ],
        handler: (selectedItem, item, itemRef, selected?: boolean) => {
          this.onFilterChange(selectedItem, item, itemRef, selected)
            item.value = selectedItem.value;
            this.filterBy = selectedItem;
            let filterByDisplayArr = [];
            itemRef.selectedValues.forEach(
              (data) => {
               let selectedItemData = item.items.find((itemdata) => data === itemdata.value );
               if(selectedItemData) {
                filterByDisplayArr.push(selectedItemData.text);
                filterByDisplayArr = Array.from(new Set(filterByDisplayArr));
               }
              }
              );
              this.filterByDisplay = filterByDisplayArr.join(", ")
          if (this.source && selectedItem.value) {
            this.data = _.cloneDeep(this.source);

            this.selectedFilterByValue = itemRef.selectedValues;

            this.affiliationFilter(this.data, this.selectedFilterByValue, this.selectedSourceTypeFilterByValue);
            this.chartContainer.nativeElement.innerHTML = "";
            this.renderChart();
          }
          this.initFilterHeader();
        }
      }
    ];
    // this.filterAffiliationData[0].value = "all";
    // this.filterAffiliationData[0].selectedValues = ["all"];
    this.filterByDisplay = this.translate.instant('ALL');

    this.filterBy = this.filterAffiliationData[0].items[0];
    this.initFilterHeader();
    if (this.from === "ContactPageComponent") {
      this.data = {
        entity_id: this.parent.ID,
        name:this.parent.fullname && this.parent.fullname.length > 20 ? this.parent.fullname.substring(0, 20).concat("...") : this.parent.fullname,
        value: 100,
        fill: '#007AFF',
        stroke: '#0361CA',
        affiliationRole: '',
        affiliationSourceType: this.parent.raw["indskr_contactsourcetype_Formatted"],
        affiliationType: '',
        relationship: '',
        icon: 'assets/imgs/contact-affiliation.svg',
        children: this.formatAffiliationDataFromContactRelationship(this.relatedTo, this.relatedFrom, this.contactAccountAffiliation)
      }
    } else if (this.from === "AccountPageComponent") {
      this.data = {
        entity_id: this.parent.id,
        name: this.parent.accountName && this.parent.accountName.length > 20 ? this.parent.accountName.substring(0, 20).concat("...") : this.parent.accountName,
        value: 100,
        fill: '#E64969',
        stroke: '#BF1D3E',
        affiliationRole: '',
        affiliationSourceType: this.parent.raw["indskr_accountsourcetype_Formatted"],
        affiliationType: '',
        relationship: '',
        icon: 'assets/imgs/account-affiliation.svg',
        children: this.formatAffiliationDataFromAccountRelationship(this.relatedTo, this.relatedFrom, this.contactAccountAffiliation, this.accountToBrand)

      }
      this.filterAffiliationData[0].items.splice(3,0,{ text: this.translate.instant('BRAND_AFFILIATION'), value: "brand", asc: false });
    }
    this.source = _.cloneDeep(this.data);
    this.renderChart()

  }

  private onFilterChange(selectedItem, item, itemRef = null, selected?: boolean) {
    if(selectedItem && selectedItem.value){
      const val = selectedItem.value;
      let filters = [];
      if (_.isEqual(val, "all")) {
       filters = ["all"];
      } else {
        filters = itemRef.selectedValues.filter(v => !_.isEqual(v, "all"));
        if (!selected) filters = itemRef.selectedValues.filter(v => !_.isEqual(v, val));
        const availableFilters = this.filterAffiliationData[0].items.filter(i => !_.isEqual(i.value, "all")).length;
        if (_.isEmpty(filters) || filters.length == availableFilters) filters = ["all"];;
      }
      itemRef.selectedValues = this.filterAffiliationData[0].selectedValues = filters;
      this.filterAffiliationData[0].value = val;
    }

  }

  private async renderChart() {
    var tooltip = d3.select(".tooltip");
    if (tooltip.size() == 0) {
      tooltip = d3.select("body")
        .append("div")
        .style("opacity", 0)
        .attr("class", "tooltip")
        .style("background-color", "#ffffff")
        .style("border-radius", "8px")
        .style("padding", "5px")
        .style("border", "none")
        .style("box-shadow", "0px 10px 25px rgba(0, 0, 0, 0.2)");
    }
    let aeThis = this;

    const scrollElement = await aeThis.ionContent.getScrollElement();
    var margin = {
      top: 20,
      right: 120,
      bottom: 120,
      left: 180
    },
      width = 1200 - margin.right - margin.left,
      height = scrollElement.clientHeight - margin.top - margin.bottom;

    var i = 0,
      duration = 750,
      root;

    var tree = d3.layout.tree().size([height, width]);
    var diagonal = d3.svg.diagonal()
      .projection(function (d: any) {
        return [d.y, d.x];
      });

    var svg = d3.select("#render").append("svg")
      .attr("width", width + margin.right + margin.left)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");
    this.data.children?.forEach(d => d.name = d.name && d.name.length > 20 ? d.name.substring(0, 20).concat("...") : d.name);
    root = this.data;
    root.x0 = height / 2;
    root.y0 = 0;

    root.children.forEach(collapse);
    update(root);

    function collapse(d: any) {
      if (d.children) {
        d._children = d.children;
        d._children.forEach(collapse);
        d.children = null;
      }
    }

    function update(source) {
      var newHeight = Math.max(tree.nodes(root).reverse().length * 30, height);
      d3.select("#render svg")
        .attr("width", margin.left + margin.right + 300 +(source.depth * 180))
        .attr("height", newHeight + margin.top + margin.bottom);
      tree = d3.layout.tree().size([newHeight, width]);
      var nodes = tree.nodes(root).reverse(),
          links = tree.links(nodes);
      nodes.forEach(function (d: any) {
        d.y = d.depth * 180;
      });

      var node = svg.selectAll("g.node")
        .data(nodes, function (d: any) {
          return d.id || (d.id = ++i);
        });

      var nodeEnter = node.enter().append("g")
        .attr("class", "node")
        .attr("transform", function (d: any) {
          return "translate(" + source.y0 + "," + source.x0 + ")";
        })
        .on("click", click);

      nodeEnter.append("text")
        .attr("x", function (d: any) {
          return 15;
        })
        .attr("dy", ".35em")
        .attr("text-anchor", function (d: any) {
          return "start";
        })
        .text(function (d: any) {
          return d.name;
        })
        .style("fill-opacity", 1e-6);

      var nodeUpdate = node.transition()
        .duration(duration)
        .attr("transform", function (d: any) {
          return "translate(" + d.y + "," + d.x + ")";
        });

      nodeUpdate.select('text')
        .style("fill-opacity", 1)
        .text((d) => { return d.name; })
        .attr("text-anchor", function (d) {
          return d.children ? "end" : "start";
        })
        .attr("x", function (d) {
          return d.children ? -15 : 15;
        })
        .style('font-weight', (d) => {
          if (d.children) {
            return d._children ? 'font-weight' : '550';
          } else {
            return d.children ? 'font' : '400';
          }
        })
        .style('font-size', (d) => {
          if (d.children) {
            return d._children ? 'font-size' : '12px';

          } else {
            return d.children ? 'font' : '12px ';
          }
        })

      nodeEnter.append('circle')
        .attr('class', 'node')
        .attr('r', 1e-6)
        .style('fill', (d) => {
          return d._children ? 'blue' : '#fff';
        })

      nodeUpdate.select('circle.node')
        .attr('r', 10)
        .style('stroke-width', '3px')
        .style('stroke', (d) => {
          if (d.children) {
            return d.stroke
          }
          else {
            return d.fill
          }
        })
        .style('fill', (d) => {
          return d.fill
        })
        .attr('cursor', 'pointer');

      nodeEnter.append("svg:image")
        .attr("xlink:href", (d: any) => {
          return d.icon;

        })
        .attr("x", -8)
        .attr("y", -8)
        .attr("width", 16)
        .attr("height", 16);

      nodeUpdate.select("text")
        .style("fill-opacity", 1);

      var nodeExit = node.exit().transition()
        .duration(duration)
        .attr("transform", function (d: any) {
          return "translate(" + source.y + "," + source.x + ")";
        })
        .remove();

      nodeExit.select("circle")
        .attr("r", 1e-6);

      nodeExit.select("text")
        .style("fill-opacity", 1e-6);

      var link = svg.selectAll("path.link")
        .data(links, function (d: any) {
          return d.target.id;
        });

      link.enter().insert("path", "g")
        .attr("class", "link")
        .attr("d", function (d: any) {
          var o = {
            x: source.x0,
            y: source.y0
          };
          return diagonal({
            source: o,
            target: o
          });
        });

      link.transition()
        .duration(duration)
        .attr("d", diagonal);

      link.exit().transition()
        .duration(duration)
        .attr("d", function (d: any) {
          var o = {
            x: source.x,
            y: source.y
          };
          return diagonal({
            source: o,
            target: o
          });
        })
        .remove();

      nodes.forEach(function (d: any) {
        d.x0 = d.x;
        d.y0 = d.y;
      });
      if (aeThis.device.isNativeApp && !aeThis.device.deviceFlags.electron) {
        let timer;
        nodeEnter.on("touchstart", function (d) {
          const event: any = d3.event;
          timer = setTimeout(() => {
            if (!_.isEmpty(event.changedTouches)) {
              tooltipPopOver(d, event.changedTouches[0].pageX, event.changedTouches[0].pageY);
            }
            else {
              tooltipPopOver(d, event['pageX'], event['pageY']);
            }
          }, 1000);
        })
        nodeEnter.on("touchend", () => { 
          if (timer) {
            clearTimeout(timer);
            timer = null;
          }
          setTimeout(() => {
            tooltip.style("opacity", 0).style("z-index", -10000);
          }, 2000);
        });
      } else {
        let timer;
        nodeEnter.on('mouseover', (d) => {
          if (timer) {
            clearTimeout(timer);
            timer = null;
          }
          if (d?.type != 'brand') tooltip.style("opacity", 1).style("z-index", 10000);
        })
        nodeEnter.on("mousemove", (d) => {
          if (d?.type != 'brand') {
            tooltipPopOver(d, d3.event['pageX'], d3.event['pageY']);
          }
        }).on("mouseout", () => {
          timer = setTimeout(() => { tooltip.style("opacity", 0).style("z-index", -10000); }, 2000);
        });
      }
      
    }

    
    async function click(selected: any) {
      if (selected?.type == 'brand') return;
      if (selected?.depth > 3 || aeThis.data.entity_id == selected.entity_id) return;
      if (_.isEmpty(selected.children)) {
        let selectedDataChildren;
        if (selected.type == "account") {
          let accountData = await aeThis.accountOfflineService.getAccountRelationshipById(selected.entity_id,true);
          if (aeThis.filterBy.value) {
            selectedDataChildren = aeThis.filterAccountAffiliationOnclick(accountData.linkEntityAccountFrom, accountData.linkEntityAccountTo, accountData.linkedAccountContact, accountData.brandAffiliationByAccountId);
          } else {
            selectedDataChildren = aeThis.formatAffiliationDataFromAccountRelationship(accountData.linkEntityAccountTo, accountData.linkEntityAccountFrom, accountData.linkedAccountContact, accountData.brandAffiliationByAccountId);
          }
        } else {
          let contactRelatedTo = await aeThis.contactOfflineService.getContactRelatedToByContactId(selected.entity_id);
          let contactRealtedFrom = await aeThis.contactOfflineService.getContactRelatedFromByContactId(selected.entity_id);
          let contactToAccount = await aeThis.contactOfflineService.getContactToAccountByContactId(selected.entity_id);
          if (aeThis.filterBy.value) {
            selectedDataChildren = aeThis.filterContactAffiliationOnclick(contactRealtedFrom, contactRelatedTo, contactToAccount);
          } else {
            selectedDataChildren = aeThis.formatAffiliationDataFromContactRelationship(contactRelatedTo, contactRealtedFrom, contactToAccount);
          }
        }
        if (!_.isEmpty(selectedDataChildren)) {
          selectedDataChildren.forEach(d => {
            d.name = d.name && d.name.length > 20 ? d.name.substring(0, 20).concat("...") : d.name;
          });
          selected.children = selectedDataChildren;
          if (selected.parent) {
            selected.parent.children.forEach(e => {
              if (selected !== e) collapse(e);
            });
          }
        }
      } else {
        selected.children = null;
      }
      update(selected);
    }

    function tooltipPopOver(d, pageX, pageY) {
      tooltip.style("opacity", 1);
      tooltip.style("z-index", 10000);
      let relationshipContactText;
      let previewText = aeThis.translate.instant('PREVIEW');
      let affiliationSourceTypeText = aeThis.translate.instant('AFFILIATION_SOURCE_TYPE');
      if (aeThis.from === "ContactPageComponent") {
        if (d.type == "contact") {
          relationshipContactText = aeThis.translate.instant('RELATIONSHIP');
        } else {
          relationshipContactText = aeThis.translate.instant('AFFILIATION_ROLE');
        }
      } else if (aeThis.from === "AccountPageComponent") {
        if (d.type == "contact") {
          relationshipContactText = aeThis.translate.instant('AFFILIATION_ROLE');
        } else {
          relationshipContactText = aeThis.translate.instant('AFFILIATION_TYPE');
        }
      }
      tooltip
        .html(
          d.type == "contact" ?
            `<table  style='font-size: 13px; border-collapse: separate; color : #0D0D0D' >
              <tr id="relationship" class="border-bottom">
                <td style="color:#737373">${relationshipContactText}</td>
                <td style="color:#000000" class = "text-right">${d.relationship == null || d.relationship == 'None' ? '' : d.relationship}</td>
              <tr>
              <tr id = "affiliationSourceType" class="border-bottom">
                <td style="color:#737373">${d.affiliationSourceType == undefined || d.affiliationSourceType == 'None' ? '' : `${affiliationSourceTypeText}`} </td>
                <td style="color:#000000" class = "text-right">${d.affiliationSourceType == undefined || d.affiliationSourceType == 'None' ? '' : d.affiliationSourceType}</td></tr>
              <tr  id="preview"><td style="color : #007AFF"> ${previewText} ${d.type}</td>
                <td class = "text-right"><i class="arrow right"></i></td>
              </tr>
          </table>` : d.type=="account"?
            `<table  style='font-size: 13px; border-collapse: separate; color : #0D0D0D' >
            <tr id="affiliationRole" class="border-bottom" >
              <td style="color:#737373">${relationshipContactText}</td>
              <td style="color:#000000" class ="text-right">${d.affiliationRole == undefined || d.affiliationRole == 'None' ? '' : d.affiliationRole}</td>
            </tr>
            <tr id="affiliationSourceType" class="border-bottom">
              <td style="color:#737373">${d.affiliationSourceType == undefined || d.affiliationSourceType == 'None' ? '' : `${affiliationSourceTypeText}`}</td>
              <td style="color:#000000" class ="text-right">${d.affiliationSourceType == undefined || d.affiliationSourceType == 'None' ? '' : d.affiliationSourceType}</td>
            </tr>
            <tr id="preview">
              <td style="color : #007AFF"> ${previewText} ${d.type}</td>
              <td  class = "text-right"><i class="arrow right "></i></td>
            </tr>
          </table>`:null)
        .style("left", `${d.x + 20}px`)
        .style("top", `${d.y}px`)
        .style('left', `${pageX - 100}px`)
        .style('top', `${pageY + 25}px`)
        .style("opacity", 1)
      if (!d.affiliationRole) {
        d3.select("#affiliationRole").style('display', 'none')
      }
      if (!d.relationship || d.relationship == 'None') {
        d3.select("#relationship").style('display', 'none')
      }
      if (!d.affiliationSourceType) {
        d3.select("#affiliationSourceType").style('display', 'none')
      }
      if (!d.type) {
        d3.select(".preview").style('display', 'none')
      }
      d3.select("#preview").on("click", async () => {
        tooltip
          .style("opacity", 0)
        tooltip.style("z-index", 10000);
        if (d.type === 'account') {
          aeThis.openAccountDetails(d.entity_id);
        } else {
          aeThis.openContactDetails(d.entity_id);
        }

      });
    }
  }

  private async openAccountDetails(accountId: string) {
    let account: Account = this.accountOfflineService.getAccountById(accountId);
    if (!account) {
      this.notificationService.notify(this.translate.instant('ACCOUNT_NO_LONGER_ACCESS'), 'Account list', 'top', ToastStyle.DANGER);
      return;
    }
    this.uiService.accountDataSegment = 'info';
    this.accountOfflineService.accountPageMode = ComponentViewMode.READONLY;
    this.accountOfflineService.selected = account;
    this.accountDataService.getAccountTimelineInfo(account);
    if (this.navService.getCurrentMasterPageName() === PageName.AccountPageComponent) {
      this.navService.popChildNavPageWithPageTracking();
      this.dynamicFormService.isOpenedAffiliatedAccountOnAccount = true;
      this.events.publish('highlightAccount', account);
    } else {
      this.dynamicFormService.isOpenedAffiliatedAccountOnContact = true;
      this.accountOfflineService.accessedAccountListFrom = PageName.ContactDetailsComponent;
      this.navService.pushChildNavPageWithPageTracking(AccountDetailsComponent, PageName.ContactDetailsComponent, PageName.ContactPageComponent, { listMode: this.accountOfflineService.accountPageMode });
    }
  }

  private async openContactDetails(contactId: string) {
    let contact: Contact = this.contactOfflineService.getContactByID(contactId);
    if (!contact) {
      this.notificationService.notify(this.translate.instant('CONTACT_NO_LONGER_ACCESS'), 'Contact list', 'top', ToastStyle.DANGER);
      return;
    }
    if (contact) {
      if (this.navService.getCurrentMasterPageName() === PageName.AccountPageComponent) {
        this.dynamicFormService.isNavAffiliatedContactFromAccount = false;
        this.contactOfflineService.contactInformation = contact;
        this.uiService.contactDetailsSegment = 'info';
        this.contactOfflineService.contactPageMode = ComponentViewMode.READONLY;
        this.contactOfflineService.accessedContactListFrom = PageName.AccountDetailsComponent;
        this.dynamicFormService.isOpenedAffiliatedContactOnAccount = true;
        this.navService.pushChildNavPageWithPageTracking(ContactDetailsComponent, PageName.AccountDetailsComponent, PageName.AccountPageComponent, { contactListMode: ComponentViewMode.READONLY });
      } else {
        this.dynamicFormService.isOpenedAffiliatedContactOnContact = true;
        this.events.publish('highlightContact', contact);
      }
    }
  }

  private formatAffiliationDataFromContactRelationship(contactRelatedTo, contactRelatedFrom, contactToAccount) {
    let contactAffData: AffilitionData[] = [];
    if (!_.isEmpty(contactRelatedTo)) {
      contactRelatedTo.forEach(element => {
        if (element["indskr_contactrelationship.statecode"] === 0 && element["indskr_contactrelationship.indskr_relatedcontactid_Formatted"]) {
          contactAffData.push(new AffilitionData(element["indskr_contactrelationship.indskr_relatedcontactid"], "contact", element["indskr_contactrelationship.indskr_relatedcontactid_Formatted"], 100, '#007AFF', '#0361CA', null, element["indskr_contactrelationship.indskr_relationshipsourcetype_Formatted"], null, element["indskr_contactrelationship.indskr_relationship_Formatted"] ? element["indskr_contactrelationship.indskr_relationship_Formatted"]:element["indskr_contactrelationship.omnione_relationship_Formatted"], 'assets/imgs/contact-affiliation.svg'));
        }
      });
    }
    if (!_.isEmpty(contactRelatedFrom)) {
      contactRelatedFrom.forEach(element => {
        if (element["indskr_contactrelationship.statecode"] === 0 && element["indskr_contactrelationship.indskr_contactid_Formatted"]) {
          contactAffData.push(new AffilitionData(element["indskr_contactrelationship.indskr_contactid"], "contact", element["indskr_contactrelationship.indskr_contactid_Formatted"], 100, '#007AFF', '#0361CA', null, element["indskr_contactrelationship.indskr_relationshipsourcetype_Formatted"], null, element["indskr_contactrelationship.indskr_relationship_Formatted"]?element["indskr_contactrelationship.indskr_relationship_Formatted"]:element["indskr_contactrelationship.omnione_relationship_Formatted"], 'assets/imgs/contact-affiliation.svg'));
        }
      });
    }
    if (!_.isEmpty(contactToAccount)) {
      contactToAccount.forEach(element => {
        if (element["indskr_accountcontactaffiliation.statecode"] === 0 && element["indskr_accountcontactaffiliation.indskr_accountid_Formatted"]) {
          contactAffData.push(new AffilitionData(element["indskr_accountcontactaffiliation.indskr_accountid"], "account", element["indskr_accountcontactaffiliation.indskr_accountid_Formatted"], 100, '#E64969', '#BF1D3E', element["indskr_accountcontactaffiliation.omnione_role_Formatted"] ? element["indskr_accountcontactaffiliation.omnione_role_Formatted"] : element["indskr_accountcontactaffiliation.omnione_lu_affiliationrole_Formatted"], element["indskr_accountcontactaffiliation.indskr_affiliationsourcetype_Formatted"], null, null, 'assets/imgs/account-affiliation.svg'));
        }
      });
    }
    return _.uniqBy(contactAffData, 'entity_id');
  }

  private formatAffiliationDataFromAccountRelationship(accountRelatedTo, accountRelatedFrom, accountToContact, accountToBrand) {
    let accountAffData: AffilitionData[] = [];
    if (!_.isEmpty(accountRelatedTo)) {
      accountRelatedTo.forEach(element => {
        if (element["indskr_accountaccountaffiliation.statecode"] === 0 && element["indskr_accountaccountaffiliation.indskr_affiliatedfromaccountid_Formatted"]) {
          accountAffData.push(new AffilitionData(element["indskr_accountaccountaffiliation.indskr_affiliatedfromaccountid"], "account", element["indskr_accountaccountaffiliation.indskr_affiliatedfromaccountid_Formatted"], 100, '#E64969', '#BF1D3E', element["indskr_accountaccountaffiliation.omnione_relationship_Formatted"] ? element["indskr_accountaccountaffiliation.omnione_relationship_Formatted"] : element["indskr_accountaccountaffiliation.omnione_lu_affiliationtype_Formatted"], element["indskr_accountaccountaffiliation.indskr_affiliationsourcetype_Formatted"], null, null, 'assets/imgs/account-affiliation.svg'))
        }
      });
    }
    if (!_.isEmpty(accountRelatedFrom)) {
      accountRelatedFrom.forEach(element => {
        if (element["indskr_accountaccountaffiliation.statecode"] === 0 && element["indskr_accountaccountaffiliation.indskr_affiliatedtoaccountid_Formatted"]) {
          accountAffData.push(new AffilitionData(element["indskr_accountaccountaffiliation.indskr_affiliatedtoaccountid"], "account", element["indskr_accountaccountaffiliation.indskr_affiliatedtoaccountid_Formatted"], 100, '#E64969', '#BF1D3E', element["indskr_accountaccountaffiliation.omnione_relationship_Formatted"] ? element["indskr_accountaccountaffiliation.omnione_relationship_Formatted"] : element["indskr_accountaccountaffiliation.omnione_lu_affiliationtype_Formatted"], element["indskr_accountaccountaffiliation.indskr_affiliationsourcetype_Formatted"], null, null, 'assets/imgs/account-affiliation.svg'))
        }
      });
    }
    if (!_.isEmpty(accountToContact)) {
      accountToContact.forEach(element => {
        if (element["indskr_accountcontactaffiliation.statecode"] === 0 && element["indskr_accountcontactaffiliation.indskr_contactid_Formatted"]) {
          accountAffData.push(new AffilitionData(element["indskr_accountcontactaffiliation.indskr_contactid"], "contact", element["indskr_accountcontactaffiliation.indskr_contactid_Formatted"], 100, '#007AFF', '#0361CA', null, element["indskr_accountcontactaffiliation.indskr_affiliationsourcetype_Formatted"], null, element["indskr_accountcontactaffiliation.indskr_contactrole_Formatted"] ? element["indskr_accountcontactaffiliation.indskr_contactrole_Formatted"] : element["indskr_accountcontactaffiliation.omnione_role_Formatted"], 'assets/imgs/contact-affiliation.svg'))
        }
      });
    }
    if (!_.isEmpty(accountToBrand)) {
      accountToBrand.forEach(element => {
        if (element["indskr_accountbrandaffiliation.statecode"] === 0 && element["indskr_accountbrandaffiliation.indskr_affiliatedtoid_Formatted"]) {
          accountAffData.push(new AffilitionData(element["indskr_accountbrandaffiliation.indskr_accountbrandaffiliationid"], "brand", element["indskr_accountbrandaffiliation.indskr_affiliatedtoid_Formatted"], 100, '#42d77d', '#28ba62', null, null, null, null, 'assets/imgs/brand-affiliation.svg'))
        }
      });
    }
    return _.uniqBy(accountAffData, 'entity_id');
  }

  initFilterHeader() {
    const sortButton = []
    sortButton.push({
      id: 'affilitionType-sort',
      text: this.filterByDisplay ? this.filterByDisplay : '',
      isDisabled: false,
      img: "assets/imgs/sort_with_double_arrows.svg",
    });
    this.affiliationFilterViewData = {
      id: 'all-contacts-header',
      title: this.translate.instant("ALL_AFFILIATIONS"),
      controls: sortButton
    };
  }

  affiliationFilter(treeData, slectedFilterValue:string[], selectedSourceTypeFilterValue:string) {
    console.log(slectedFilterValue);
    let affiliationData = [];
    let relatedFromData = this.relatedFrom;
    let relatedToData = this.relatedTo;
    let brandData = []
    treeData.children = [];
    if (this.from === "AccountPageComponent") {
      affiliationData = this.contactAccountAffiliation;
      brandData = this.accountToBrand;
    } else if (this.from === "ContactPageComponent") {
      affiliationData = this.contactAccountAffiliation;
      brandData = [];
    }

    if (selectedSourceTypeFilterValue !== 'all') {
      affiliationData = affiliationData.filter((element) => {
        return element["indskr_accountcontactaffiliation.indskr_affiliationsourcetype_Formatted"] == selectedSourceTypeFilterValue;
      });

      relatedFromData = relatedFromData.filter((element) => {
        return element['indskr_accountaccountaffiliation.indskr_affiliationsourcetype_Formatted'] ? element['indskr_accountaccountaffiliation.indskr_affiliationsourcetype_Formatted'] == selectedSourceTypeFilterValue : element["indskr_contactrelationship.indskr_relationshipsourcetype_Formatted"] == selectedSourceTypeFilterValue;
      });

      relatedToData = relatedToData.filter((element) => {
        return element['indskr_accountaccountaffiliation.indskr_affiliationsourcetype_Formatted'] ? element['indskr_accountaccountaffiliation.indskr_affiliationsourcetype_Formatted'] == selectedSourceTypeFilterValue : element["indskr_contactrelationship.indskr_relationshipsourcetype_Formatted"] == selectedSourceTypeFilterValue;
      });
    }

    slectedFilterValue.forEach(el =>{
      if(el !== 'all'){
        if(el === 'contact'){
          if (this.from === "AccountPageComponent") {
            treeData.children = treeData.children.concat(this.formatAffiliationDataFromAccountRelationship([], [], affiliationData, []))
          } else {
            treeData.children = treeData.children.concat(this.formatAffiliationDataFromContactRelationship(relatedToData, relatedFromData, []));
          }
        }
          else if(el === 'account'){
          //treeData.children = treeData.children.concat(this.formatAffiliationDataFromContactRelationship([], [], this.contactAccountAffiliation));
          if (this.from === "AccountPageComponent") {
            treeData.children = treeData.children.concat(this.formatAffiliationDataFromAccountRelationship(relatedToData, relatedFromData, [], []))
          } else {
            treeData.children = treeData.children.concat(this.formatAffiliationDataFromContactRelationship([], [], affiliationData));
          }
        } else if(el === 'brand'){
          if (this.from === "AccountPageComponent") {
            treeData.children = treeData.children.concat(this.formatAffiliationDataFromAccountRelationship([], [], [], brandData));
          } else {
            treeData.children = treeData.children.concat(this.formatAffiliationDataFromContactRelationship([], [], brandData));
          }
        }
      } else {
        if (this.from === "AccountPageComponent") {
          treeData.children = this.formatAffiliationDataFromAccountRelationship(relatedToData, relatedFromData, affiliationData, brandData)
        } else {
          treeData.children = this.formatAffiliationDataFromContactRelationship(relatedToData, relatedFromData, affiliationData);
        }
      }
    })
  }

  onFilterControlClick(id: string) {
    if (id === 'affilitionType-sort') {
      this.popover
        .create({ component: MultiSelectPopover, componentProps: { root: this.filterAffiliationData }, event: event })
        .then((data) => {
          data.present();
        })
    }
  }

  filterAccountAffiliationOnclick(relatedDataFrom, relatedDataTo, accountData, brandData) {
    let data: AffilitionData[] = [];
    this.filterAffiliationData[0].selectedValues.forEach(filter => {
      switch (filter) {
        case 'account':
          data.push(...this.formatAffiliationDataFromAccountRelationship(relatedDataTo, relatedDataFrom, [], []));
          break;
        case 'contact':
          data.push(...this.formatAffiliationDataFromAccountRelationship([], [], accountData, []));
          break;
        case 'brand':
          data.push(...this.formatAffiliationDataFromAccountRelationship([], [], [], brandData));
          break;
        default:
          data = this.formatAffiliationDataFromAccountRelationship(relatedDataTo, relatedDataFrom, accountData, brandData);
      }
    });
    return data;
  }

  filterContactAffiliationOnclick(relatedDataFrom, relatedDataTo, contactData) {
    let data: AffilitionData[] = [];
    this.filterAffiliationData[0].selectedValues.forEach(filter => {
      switch (filter) {
        case 'contact':
          data.push(...this.formatAffiliationDataFromContactRelationship(relatedDataTo, relatedDataFrom, []));
          break;
        case 'account':
          data.push(...this.formatAffiliationDataFromContactRelationship([], [], contactData));
          break;
        case 'brand':
          data.push(...this.formatAffiliationDataFromContactRelationship([], [], []));
          break;
        default:
          data = this.formatAffiliationDataFromContactRelationship(relatedDataTo, relatedDataFrom, contactData);
      }
    });
    return data;
  }
}

class AffilitionData {
  constructor(
    public entity_id: string,
    public type: string,
    public name: string,
    public value: number,
    public fill: string,
    public stroke: string,
    public affiliationRole: string,
    public affiliationSourceType: string,
    public affiliationType: string,
    public relationship: string,
    public icon: string,
    public Children?: Array<AffilitionData>
  ) {
  }
}
