
import {takeUntil} from 'rxjs/operators';
import { Component, AfterViewInit, ViewChild, HostListener, ElementRef, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { DxSchedulerComponent } from "devextreme-angular/ui/scheduler";
import * as moment from 'moment';
import { ActivityService } from '../../../services/activity/activity.service';
import { Subscription ,  Subject } from 'rxjs';
import { SchedulerDataService } from '../../../data-services/scheduler/scheduler.data.service';
import { ContactOfflineService } from '../../../services/contact/contact.service';
import { NavigationService } from '../../../services/navigation/navigation.service';
import { LoadingController, PopoverController } from '@ionic/angular';
import { SchedulerService } from '../../../services/scheduler/scheduler.service';
import { Scheduler } from '../../../classes/scheduler/scheduler.class';
import { DeviceService } from '../../../services/device/device.service';
import { SchedulerCalendarPopupComponent } from './scheduler-calendar-popup/scheduler-calendar-popup';
import { TrackService, TrackingEventNames } from '../../../services/logging/tracking.service';
import { EventsService } from '../../../services/events/events.service';
import { CalendarAppointment, ISuggestionMeetingResponse } from '../../../classes/scheduler/calendarAppointment.class';
import { ActivityDataService } from '../../../data-services/activity/activity.service';
import { Contact } from '../../../classes/contact/contact.class';
import { TranslateService } from '@ngx-translate/core';
import { GlobalUtilityService } from "../../../services/global-utility.service";
import { REP_STATUS } from '../../../models/rep-status-model';
import { RepServices } from '../../../data-services/rep/rep.services';
import { locale, loadMessages } from "devextreme/localization"
import { NotificationService, ToastStyle } from '../../../services/notification/notification.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { FeatureActionsMap } from '../../../classes/authentication/user.class';
import { ContentMatchingService, CONTENTMATCHTYPE } from '../../../services/content-matching/content-matching.service';
import { PresentationService } from '../../../services/presentation/presentation.service';
import { ResourceService } from '../../../services/resource/resource.service';
import { UIService } from '../../../services/ui/ui.service';
import _ from 'lodash';
declare var require: any;
let locales = 'en'
const localeJson = require(`devextreme/localization/messages/${locales}.json`);
@Component({
    selector: 'scheduler-calendar',
    templateUrl: 'scheduler-calendar.html',
  styleUrls:['scheduler-calendar.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SchedulerCalendarComponent implements AfterViewInit {

    @Input() isDeviceOnline: boolean = true;

    appointmentsData: CalendarAppointment[] = [];
    currentDate: Date = new Date();
    currentView: string = 'Week1';

    isMultiWeek: boolean = false;
    currentWeek: number = 1;
    maxWeek: number = 1;
    alert: HTMLIonAlertElement;

    views: any = [
        {
            name: "Week1",
            type: 'week',
            intervalCount: 1
        }
    ];
    private selectedSchedule: Scheduler;
    private ImatchedContactSubscription: Subscription;
    private selectedScheduleObs: Subscription;
    dayOfWeek: number = 0;
    crossScrollingEnabled: boolean = true;
    hasConfirmed: boolean = false;
    scheduledMeetings: CalendarAppointment[] = [];
    feedbackForMeetings: CalendarAppointment[] = [];
    hasMeetingsProposed: boolean = false;
    @ViewChild(DxSchedulerComponent, {static: true}) target: DxSchedulerComponent;
    cellDuration: number;

    ngdestroy$: any = new Subject<boolean>();
    isAndroid: boolean = false;
    selectedLocale: string;

    constructor(
        private activityService: ActivityService,
        public contactService: ContactOfflineService,
        private navService: NavigationService,
        private loadingCtrl: LoadingController,
        public schedulerService: SchedulerService,
        private popoverCtrl: PopoverController,
        public deviceService: DeviceService,
        private trackingService: TrackService,
        private schedulerDataService: SchedulerDataService,
        private events: EventsService,
        private activityDataService: ActivityDataService,
        private _cd: ChangeDetectorRef,
        private translate: TranslateService,
        public utilityService: GlobalUtilityService,
        public repService: RepServices,
        private notificationService: NotificationService,
        private authService: AuthenticationService,
        private contentMatchService: ContentMatchingService,
        private presentationService: PresentationService,
        private resourceService: ResourceService,
        private uiService: UIService
    ) {

        locale(locales)
        loadMessages(localeJson)
        this.cellDuration = authService.user.meeting_duration;
      
        this.selectedLocale = this.translate.currentLang;
        this._dxSchedulerLocale();
        this.translate.onLangChange.subscribe(data => {
          if (data) {
            this.selectedLocale = this.translate.currentLang;
            this._dxSchedulerLocale();
          }
        });
    }

    ngOnInit() {
      this.isAndroid = this.deviceService.isAndroid();
        this.selectedScheduleObs = this.schedulerService.schedulerObs.subscribe(value => {
            if (value) {
                this._cd.detectChanges();
                this.selectedSchedule = value;
                this.isMultiWeek = false;
                this.currentDate = new Date(value.startdate);
                this.dayOfWeek = moment(value.startdate).day();

                let duration: number = moment(value.enddate).diff((moment(value.startdate)), 'days');

                this.maxWeek = Math.ceil(duration / 7);
                let count: string = "Week" + this.maxWeek;
                if (this.maxWeek > 1) { this.isMultiWeek = true; }
                if (this.target && this.target.instance) {
                    this.target.instance.repaint();
                    this.target.instance.scrollToTime(8, 50);
                }
                this._cd.detectChanges();
                this._cd.markForCheck();
            }
        });

        this.ImatchedContactSubscription = this.contactService.matchedContactObservable.subscribe((res: Contact[]) => {
            console.log(res);
            this._cd.detectChanges();
            this.selectedSchedule.contactList = this.getMatchedContactFormat(res);
            this.fetchMeetingsForSelectedContacts();
        });

        this.events.observe('device:deviceIsOffline').pipe(
            takeUntil(this.ngdestroy$))
            .subscribe((status) => {
                // the network service indicate that the device is offline so no arguments.
                this._cd.detectChanges();
                this.isDeviceOnline = false;
                this._cd.detectChanges();
                this._cd.markForCheck();
            });

        this.events.observe('device:deviceIsOnline').pipe(
            takeUntil(this.ngdestroy$))
            .subscribe((status) => {
                if (this.repService.getCurrentUserState() === REP_STATUS.OFFLINE.userState) {
                    this._cd.detectChanges();
                    this.isDeviceOnline = false;
                    this._cd.detectChanges();
                    this._cd.markForCheck();
                }
                else {
                    this._cd.detectChanges();
                    this.isDeviceOnline = true;
                    this._cd.detectChanges();
                    this._cd.markForCheck();
                }
            });

    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            if (this.target && this.target.instance) {
                this.target.instance.repaint();
                this.target.instance.scrollToTime(8, 50);
            }
        }, 200);
    }

    ngOnDestroy() {
        this.selectedScheduleObs.unsubscribe();
        this.ImatchedContactSubscription.unsubscribe();
        //User clicks on back button
        if (!this.hasConfirmed) {
            this.trackingService.tracking('Proposed Meetings Canceled', TrackingEventNames.SCHEDULER);
        }

        this.ngdestroy$.next(true);
        this.ngdestroy$.complete();
    }

    async switchWeek(nav: string) {
        let loader = await this.loadingCtrl.create();

        try {
            if (nav === 'next' && this.currentWeek != this.maxWeek && this.currentWeek < this.maxWeek) {
                loader.present();
                this.currentWeek++;
                try {
                    this.currentDate = moment(this.currentDate).add(7, "d").toDate();
                    this.target.instance.repaint();
                    this.target.instance.scrollToTime(8, 50);
                } catch (error) {
                    console.log(error);
                    console.log('error navigating forward');
                }
            }
            else if (nav === 'prev' && this.currentWeek != 1 && this.currentWeek > 1) {
                loader.present();
                this.currentWeek--;
                try {
                    this.currentDate = moment(this.currentDate).subtract(7, "d").toDate();
                    this.target.instance.repaint();
                    this.target.instance.scrollToTime(8, 50);
                } catch (error) {
                    console.log(error);
                    console.log('error navigating bacward');
                }

            }
            loader.dismiss();
            this._cd.detectChanges();
            this._cd.markForCheck();
        } catch (error) {
            console.log('error in conditions');
        }

    }

    getMeetingDetails(meetings): string {
        console.log(meetings);
        return "testing";
    }

    /* Logic to open date picker for scheduler update */
    async onAppointmentClick(e?: any) {
        try {
            e.cancel = true;
            e.event.cancel = true;
            e.event.preventDefault();
            e.event.stopPropagation();
            this.target.instance.hideAppointmentTooltip();
        } catch (error) {
            console.log(error);
            console.log("Some js error that needs to be ignored");
        }
        let currentAppointment: CalendarAppointment = e.appointmentData;
        let popover = await this.popoverCtrl.create({
            component: SchedulerCalendarPopupComponent,
            componentProps: { currentAppointment },
            showBackdrop: true, backdropDismiss: true, cssClass: "custom-scheduler-backdrop",
            event: event });
        popover.onDidDismiss().then(async (obj:any) => {
            const data = obj.data;
            this.schedulerService.isOpenDateTimePicker = false;
            if (data && data.type === "edit") {
                currentAppointment.startDate = new Date(data.value);
                currentAppointment.endDate = moment(currentAppointment.startDate).add(15, 'minute').toDate();
                this.updateMeetingData(currentAppointment);
            }
            if (data && data.type === "scrap") {
                this.deleteAppointment(data.value);
            }
        });
        popover.present();

    }

    onAppointmentFormOpening(e) {
        e.form._$element[0].hidden = true;
        this.onAppointmentClick();
        try {
            e.cancel = true;
            e.event.cancel = true;
            e.event.preventDefault();
            e.event.stopPropagation();
            this.target.instance.hideAppointmentTooltip();
            return;
        } catch (error) {
            console.log(error);
            console.log("Some js error that needs to be ignored");
        }
        return;
    }

    onAppointmentDblClick(e) {
        try {
            e.cancel = true;
            e.event.cancel = true;
            e.event.preventDefault();
            e.event.stopPropagation();
            this.target.instance.hideAppointmentTooltip();
            return;
        } catch (error) {
            console.log(error);
            console.log("Some js error that needs to be ignored");
        }
    }

    onAppointmentRendered(data) {
        //console.log(data);
        //existing meeting and approved timeoff request
        if (data.appointmentData.priorityId === 0 || data.appointmentData.priorityId === 2) {
            data.appointmentElement.classList.add("readonly-instance");
        }
        //proposed meeting
        else if (data.appointmentData.priorityId === 1) {
            data.appointmentElement.classList.add("editable-instance");
        }

        if (this.deviceService.isMobileDevice) {
            data.appointmentElement.classList.add("mobile-font");
        }
    }

    onAppointmentUpdating(data) {
        if (data.oldData.priorityId === 0 || data.oldData.priorityId === 2) {
            data.cancel = true;
        }
        else {
            if (data.newData.allDay) {
                data.cancel = true;
            }
        }
    }

    onAppointmentUpdated(event) {
        this.feedbackForMeetings.forEach(val => {
            if (val.refid === event.appointmentData.refid) {
                val.isUpdated = true;
            }
        });
    }

    onClose() {
        this.trackingService.tracking('Proposed Meetings Canceled', TrackingEventNames.SCHEDULER);
        this.navService.popWithPageTracking().catch(e => console.log(e));
    }

    async onConfirm() {
        this.trackingService.tracking('SchedulerComplete', TrackingEventNames.SCHEDULER, null, true);
        if (!this.deviceService.isOffline) {
            console.log(this.scheduledMeetings);
            this.scheduledMeetings = [];
            const configuredMeetingActivityType = this.activityService.getDefaultMeetingActivityType();
            this.appointmentsData.forEach(element => {
                if (element.priorityId === 1) {
                    if (this.authService.hasFeatureAction(FeatureActionsMap.CONTENT_MATCHING)) {
                        const activity = element;
                        activity['contacts'] = element['contactAttendees'];
                        element.presentations = this.contentMatchService.getAllMatchedContents(this.presentationService.initialPres, this.resourceService.allResources, activity['contacts']);
                    }
                    if (configuredMeetingActivityType) _.extend(element, configuredMeetingActivityType);
                    this.scheduledMeetings.push(element);
                }
            });
            console.log(this.scheduledMeetings);
            let loader = await this.loadingCtrl.create();
            loader.present();
            this.hasConfirmed = true;
            try {
                await this.schedulerDataService.dnaFeedbackCall(this.feedbackForMeetings, this.selectedSchedule);
            } catch (error) {
                console.log("Error while sending feedback to DNA team")
            }
            this.schedulerDataService.createBulkMeetings(this.scheduledMeetings).subscribe(
                async res => {
                    loader.dismiss();
                    this.contactService.contacts.forEach(e => e.schedulerFrequency = 0);
                    this.contactService.isSchedulerInvoked = false;
                    await this.schedulerService.convertToOfflineMeetingsDTO(this.scheduledMeetings, res).then(res => {
                        if (!this.uiService.toolsActivityActive){
                          this.events.publish('refreshAgenda');
                        } else this.uiService.agendaRefreshRequired = true;
                        this.uiService.showRightPane = false;
                        this.navService.popToRootWithPageTracking().then(
                            e => {
                                this.uiService.showRightPane = false;
                                this.schedulerService.setSchedule(undefined);
                                this.activityService.selectedActivity = undefined;
                            }
                        );
                    }).catch(err => {
                        this.contactService.contacts.forEach(e => e.schedulerFrequency = 0);
                        this.contactService.isSchedulerInvoked = false

                        console.log(err);
                    });
                },
                async err => {
                    loader.dismiss();
                    //TC-466
                    let fakeResponse: object = {};
                    this.scheduledMeetings.forEach(request => {
                        let fakeObject: any = {
                            offlineMeetingId: request.id,
                            errorMessage: this.translate.instant('SCHEDULER_NO_INTERNET'),
                            subject: request.meetingName,
                            errorId: "Err.iO.MeetingMS",
                            offlineSampleDrops: []
                        };
                        // Incase the error occurs, we create a fake response body to work on the offline scenario.
                        fakeResponse[request.id] = fakeObject;
                    });
                    console.log(fakeResponse);
                    await this.schedulerService.convertToOfflineMeetingsDTO(this.scheduledMeetings, fakeResponse).then(res => {
                        this.trackingService.tracking('Proposed Meetings Confirmed',TrackingEventNames.SCHEDULER);
                        if (!this.uiService.toolsActivityActive){
                          this.events.publish('refreshAgenda');
                        } else this.uiService.agendaRefreshRequired = true;
                        this.navService.popToRootWithPageTracking().then(
                            e => {
                                this.schedulerService.setSchedule(undefined);
                                this.activityService.selectedActivity = undefined;
                            }
                        );
                    }).catch(err => {
                        console.log(err);
                    });
                }
            );
        }
        else
            return;
    }

    deleteAppointment(e: any) {
        let idx = this.scheduledMeetings.findIndex(el => el.refid === e.refid);
        this.scheduledMeetings.splice(idx, 1);
        this.target.instance.deleteAppointment(e);

        /* TC-915 */
        this.feedbackForMeetings.forEach(val => {
            if (val.refid === e.refid) {
                val.isScrapped = true;
                val.isUpdated = true;
            }
        });

    }

    findMeetingById(id: string): CalendarAppointment {
        return this.appointmentsData.find(e => e.id === id);
    }

    updateMeetingData(data: CalendarAppointment) {
        let indx = this.appointmentsData.findIndex(e => e.refid === data.refid);
        this.target.instance.updateAppointment(this.appointmentsData[indx], data);

        /* TC-915 */
        this.feedbackForMeetings.forEach(val => {
            if (val.refid === data.refid) {
                val.isUpdated = true;
            }
        });
    }

    async fetchMeetingsForSelectedContacts() {
        if (!this.deviceService.isOffline) {
            let loader = await this.loadingCtrl.create();
            loader.present();
            this.schedulerDataService.fetchSuggestedMeetings(this.selectedSchedule).subscribe(
                res => {
                    this._cd.detectChanges();
                    this.scheduledMeetings = [];
                    loader.dismiss();
                    this.appointmentsData = this.schedulerService.formatCalendarAppointment(this.activityService.activities);

                    //BugFix(Time Off All Day Event)
                    this.appointmentsData.forEach((appointment: CalendarAppointment) => {
                        if (appointment.isAllDay) {
                            appointment.startDate.setHours(0, 0, 0, 0);
                            appointment.endDate.setHours(23, 59, 59, 999);
                        }
                    });

                    if (res.length === 0) {
                        this.notificationService.notify(this.translate.instant('SCHEDULER_NO_SLOTS_FOUND_WITH_TEXT', {text : this.utilityService.globalCustomerText.toLowerCase()}),'Scheduler Calendar');
                    }
                    else {
                        res.forEach((value: ISuggestionMeetingResponse) => {
                            if (this.authService.hasFeatureAction(FeatureActionsMap.MEETING_AUTO_SUBJECT)) {
                              value.isMeetingAutoSubject = true;
                              value.repName = this.authService.user.displayName;
                            }
                            let input = new CalendarAppointment(value);
                            input.contactAttendees.push(this.contactService.contacts.find(e => e.ID === value.contactid));
                            this.scheduledMeetings.push(input);
                        });
                        //TC-915
                        this.feedbackForMeetings = []
                        //For sending feedback to DNA service
                        Object.assign(this.feedbackForMeetings, this.scheduledMeetings);

                        this.appointmentsData = this.appointmentsData.concat(this.scheduledMeetings);
                        this.hasMeetingsProposed = true;
                        //refresh the component for showing meetings
                        this.target.instance.repaint();
                        this.target.instance.scrollToTime(8, 50);
                    }
                    this._cd.detectChanges();
                    this._cd.markForCheck();
                },
                err => {
                    loader.dismiss();
                    this._cd.detectChanges();
                    this.notificationService.notify(this.translate.instant('SCHEDULER_UNABLE_TO_COMPLETE_ACTION'),'Scheduler Details','top',ToastStyle.DANGER);
                    this._cd.detectChanges();
                    this._cd.markForCheck();
                }
            );
        }
        else {
            this.notificationService.notify(this.translate.instant('SCHEDULER_NO_INTERNET_CONNECTION'),'Scheduler Details','top',ToastStyle.DANGER);
            this.navService.popToRootWithPageTracking().then(
                e => {
                    this.schedulerService.setSchedule(undefined);
                    this.activityService.selectedActivity = undefined;
                }
            );
            return;
        }
    }

    getMatchedContactFormat(res: Contact[]): any {
        let contactPayload: any = { matched: [], other: [] };

        res.forEach((contact: Contact) => {
            if (this.schedulerService.otherContacts) {
                let index = this.schedulerService.otherContacts.findIndex(e => e.ID === contact.ID);
                //Index found, so its a part of the matched contact array
                if (index > -1) {
                    contactPayload.other.push(contact);
                }
                else {
                    contactPayload.matched.push(contact);
                }
            }
            else {
                contactPayload.matched.push(contact);
            }
        });
        return contactPayload;
    }

    private _dxSchedulerLocale() {
      locale(this.selectedLocale);
      const localeValue: string = this.translate.instant("ALL_DAY")
      loadMessages({
        'en': {
          'dxScheduler-allDay': localeValue
        },
        'es': {
          'dxScheduler-allDay': localeValue
        },
        'fr': {
          'dxScheduler-allDay': localeValue
        },
        'ja': {
          'dxScheduler-allDay': localeValue
        },
        'de': {
          'dxScheduler-allDay': localeValue
        },
        'nl': {
          'dxScheduler-allDay': localeValue
        },
      });
    }
}
