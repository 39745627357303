import { Injectable } from "@angular/core";
import { Inventory } from "@omni/classes/sample/allocation-inventory.class";
import { ALLOCATION_INVENTORY_ENTITY_NAME, ALLOCATION_INVENTORY_FETCH } from "@omni/config/fetch-xml/allocation/inventory-fetchXMLs";
import { DB_KEY_PREFIXES, DB_SYNC_STATE_KEYS } from "@omni/config/pouch-db.config";
import { DiskService } from "@omni/services/disk/disk.service";
import { AllocationInventoryService } from "@omni/services/sample/allocation-inventory.service";
import _ from "lodash";
import { DynamicsClientService } from "../dynamics-client/dynamics-client.service";

@Injectable({
  providedIn: 'root'
})
export class AllocationInventoryDataService {

  constructor(
    private dynamics: DynamicsClientService,
    private disk: DiskService,
    private allocationInventoryService: AllocationInventoryService,
  ) {

  }

  public async fetchInventory(forceFullSync: boolean, loadFromDBOnly: boolean) {
    let tempInventory: Inventory[] = [];
    if (loadFromDBOnly) {
      const data = await this.disk.retrieve(DB_KEY_PREFIXES.ALLOCATION_INVENTORY);
      if (data?.raw) {
        this.allocationInventoryService.aggregateUserInventory(data.raw);
      }
    } else {
      let fetchXml = ALLOCATION_INVENTORY_FETCH;
      const syncState = await this.disk.getSyncState(DB_SYNC_STATE_KEYS.SYNC_ALLOCATION_INVENTORY);
      const isInitialSync = forceFullSync || !syncState || !syncState.lastUpdatedTime;
      if (isInitialSync) {
        this.allocationInventoryService.userInventory = [];
        fetchXml = fetchXml.replace("{deltaSyncCondition}", '');
      } else {
        const deltaSyncFilter = `<condition attribute="modifiedon" operator="ge" value="` + new Date(syncState.lastUpdatedTime).toISOString() + `" />`
        fetchXml = fetchXml.replace("{deltaSyncCondition}", deltaSyncFilter);
        const data = await this.disk.retrieve(DB_KEY_PREFIXES.ALLOCATION_INVENTORY);
        if (data?.raw) {
          // this.allocationInventoryService.userInventory = data.raw;
          tempInventory = data.raw;
        }
      }
      const inventory = await this.dynamics.executeFetchQuery(ALLOCATION_INVENTORY_ENTITY_NAME, fetchXml);
      if (inventory) {
        inventory.forEach(prod => {
          // const index = this.allocationInventoryService.userInventory.findIndex(inv => inv.userAllocationQtyId === prod['userAllocationQtyId']);
          const index = tempInventory.findIndex(inv => inv.userAllocationQtyId === prod['userAllocationQtyId']);
          if (index >= 0) {
            // this.allocationInventoryService.userInventory[index] = new Inventory(prod);
            tempInventory[index] = new Inventory(prod);
          } else {
            // this.allocationInventoryService.userInventory.push(new Inventory(prod));
            tempInventory.push(new Inventory(prod));
          }
        })
        this.allocationInventoryService.aggregateUserInventory(tempInventory);
        await this.disk.updateOrInsert(DB_KEY_PREFIXES.ALLOCATION_INVENTORY, (doc) => { 
          if (!doc || !doc.raw) {
            doc = {
              raw: []
            };
          }
          doc.raw = tempInventory;
          return doc;
         }).catch((err) =>
            console.error("fetchInventories: Error saving all inventories to offline db! ", err)
          );
        syncState.lastUpdatedTime = new Date().getTime();
        await this.disk.updateSyncState(syncState);
      }
    }
  }
}