import { Injectable } from '@angular/core';
import { AuthenticationService } from '../authentication.service';
import { AssistantData } from '../../classes/my-assistant/my-assistant.class';
import { DiskService } from '../disk/disk.service';
import { differenceInDays, differenceInHours,format } from 'date-fns';
import {
  DB_ALLDOCS_QUERY_OPTIONS,
  DB_KEY_PREFIXES,
  DB_SYNC_STATE_KEYS
} from '../../config/pouch-db.config';
import { BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { EventsService } from '@omni/services/events/events.service';
import { DateTimeFormatsService } from '@omni/services/date-time-formats/date-time-formats.service';
import { IndNotificationDataModel } from '@omni/models/indNotificationDataModel';
import { DeviceService } from '@omni/services/device/device.service';
import _ from 'lodash';
import { fetchQueries } from '@omni/config/dynamics-fetchQueries';
import { DynamicsClientService } from '@omni/data-services/dynamics-client/dynamics-client.service';

@Injectable({
  providedIn: 'root',
})
export class MyAssistantService {
  public myAssistantViewType = 'My Assistant';
  public myAssistantData: Array<AssistantData> = [];
  public countUnreadNotifications = 0;
  public unReadNotifications: Array<AssistantData>;
  notificationsSearchText = '';
  public notificationsSubject = new BehaviorSubject<IndNotificationDataModel[]>(
    []
  );
  notificationsObs$ = this.notificationsSubject.asObservable();
  public selectedNotification = new BehaviorSubject<IndNotificationDataModel>(
    null
  );
  selectedNotificationObs$ = this.selectedNotification.asObservable();
  public hasAnyUnRedNotifications = false;

  constructor(
    private readonly authenticationOfflineService: AuthenticationService,
    public dateTimeFormatsService: DateTimeFormatsService,
    public translate: TranslateService,
    private readonly events: EventsService,
    public deviceService: DeviceService,
    private readonly diskService: DiskService,
    public dynamics: DynamicsClientService,
    public authService: AuthenticationService,
  ) {}

  public async loadAndMapNotificationsFromDB() {
    const notifications: any[] = [];
    await this.diskService
      .batchFetch(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_NOTIFICATIONS)
      .then((data: any[]) => {
        // Filter last 30 days notifications
        data = data.filter((v) => {
          return differenceInDays(new Date(), new Date(v.DateTime)) <= 30;
        });
        data.forEach((d) => {
          if(d.type != NOTIFICATION.CUSTOM_NOTIFICATION || this.authService.user.customNotifications){
            notifications.push(d);
          } 
        });
        this.notificationsSubject.next(notifications);
        console.log(
          `Notifications from disk : ${
            notifications ? notifications.length : 0
          }`
        );
      });
  }

  public async offlineFallbackDataNotifications(loadFromDBOnly = false) {
    try {
      const offlineFallback: boolean =
        this.deviceService.isOffline || loadFromDBOnly;
      if (offlineFallback) {
        await this.loadAndMapNotificationsFromDB();
      }
    } catch (error) {
      console.log('fetch notifications failed', error);
    }
  }

  public async markAllRedNotifications() {
    const notifications = this.notificationsSubject.getValue();
    await this.diskService
      .batchFetch(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_NOTIFICATIONS)
      .then((data: any[]) => {
        if (data.length) {
          data.map((o) => {
            o.isRed = true;
          });
          this.diskService.bulk(data);
        }
      });
    if (notifications.length) {
      notifications.map((o) => (o.isRed = true));
      this.notificationsSubject.next(notifications);
    }
  }

  public async clearAllNotifications() {
    await this.diskService.deleteAllFromDbUsingAlldocsQuery(
      DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_NOTIFICATIONS
    );
    this.notificationsSubject.next([]);
    this.selectedNotification.next(null);
  }

  async getAndSaveAssistantData() {
    return await this.diskService
      .retrieve(DB_KEY_PREFIXES.MY_ASSISTANT_DATA, true)
      .then((data) => {
        if (!data) {
          this.myAssistantData = [];
        } else {
          this.myAssistantData = data.MyAssistantData || [];
        }
        this.myAssistantData.map((o) => {
          if (o.Type == 'Notifications' && !o.read) {
            this.countUnreadNotifications++;
          }
        });
        // remove data thats older than 6 months
        this.myAssistantData = this.myAssistantData.filter((o) => {
          return differenceInDays(new Date(), new Date(o.DateAndTime)) <= 180;
        });
        console.log(this.myAssistantData);
        this.saveMyAssistantData();
      });
  }

  markAllAsRead() {
    this.myAssistantData.map((o) => {
      o.read = true;
    });
    this.countUnreadNotifications = 0;
    this.saveMyAssistantData();
  }

  addNewNotification(name: string, dateTime = new Date()) {
    const newdata = new AssistantData({
      Description: name,
      DateTime: dateTime,
      name,
      Type: 'Notifications',
      read: false,
    });
    this.countUnreadNotifications++;
    this.myAssistantData.push(newdata);
    console.log('from service', this.myAssistantData);
    this.saveMyAssistantData();
  }

  saveNotificationToDisk(notification: IndNotificationDataModel) {
    const _id = DB_KEY_PREFIXES.NOTIFICATIONS_LIST + notification.id;
    notification.dbKey = _id;
    this.diskService.updateOrInsert(_id, (doc) => notification);
  }
  async saveCustomNotificationToDisk(notifications: IndNotificationDataModel[], newLastUpdatedTime) {
    notifications.forEach((notification: IndNotificationDataModel) => {
      notification._id = DB_KEY_PREFIXES.NOTIFICATIONS_LIST + notification.id;
      notification.dbKey = DB_KEY_PREFIXES.NOTIFICATIONS_LIST + notification.id;
    });
    await this.diskService.bulk(notifications);
  }

  private async DeltaSyncedCustomNotification(notifications: IndNotificationDataModel[], newLastUpdatedTime) {
    if (notifications && notifications.length > 0) {
      for (let notification of notifications) {
        const _id = DB_KEY_PREFIXES.NOTIFICATIONS_LIST + notification.id;
        notification.dbKey = _id;
        this.diskService.updateOrInsert(_id, (doc) => notification);
      }
    }
  }

  public async saveMyAssistantData(): Promise<any> {
    return await this.diskService
      .updateOrInsert(DB_KEY_PREFIXES.MY_ASSISTANT_DATA, (doc) => ({
        MyAssistantData: this.myAssistantData,
      }))
      .catch((error) => console.error('saveMyAssistantData: ', error));
  }

  public async markReadNotification(data) {
    data.isRed = true;
    await this.diskService.updateOrInsert(data.dbKey, (doc) => data);
    const notifications = this.notificationsSubject.getValue();
    const idx = notifications.findIndex((e) => e.dbKey == data.dbKey);
    if (idx > -1) notifications[idx].isRed = true;
    this.hasAnyUnRedNotifications = _.some(notifications, ['isRed', false]);
    if (!this.hasAnyUnRedNotifications)
      this.notificationsSubject.next(notifications);
  }

  public async markUnReadNotification(data) {
    data.isRed = false;
    await this.diskService.updateOrInsert(data.dbKey, (doc) => data);
    const notifications = this.notificationsSubject.getValue();
    const idx = notifications.findIndex((e) => e.dbKey == data.dbKey);
    if (idx > -1) notifications[idx].isRed = false;
    const unRedNotifications = _.filter(notifications, ['isRed', false]);
    if (unRedNotifications.length == 1) {
      this.hasAnyUnRedNotifications = true;
      this.notificationsSubject.next(notifications);
    }
  }

  public async clearNotification(data) {
    await this.diskService.remove(data.dbKey);
    const notifications = this.notificationsSubject.getValue();
    const idx = _.findIndex(notifications, ['dbKey', data.dbKey]);
    if (idx > -1) delete notifications[idx];
    this.notificationsSubject.next(notifications);
    this.selectedNotification.next(null);
  }

  public resetSearchText() {
    this.notificationsSearchText = '';
  }

  public async getGroupedDataByMonth(filter: string) {
    // let offlineData = await this.diskService.retrieve('MyAssistantData');
    // if(!offlineData){
    //     return undefined;
    // }
    let data = this.myAssistantData;
    // data = data.filter(o=>{
    //     return differenceInDays(new Date(), new Date(o.DateAndTime)) <= 180
    // })
    // this.myAssistantData = data;
    // this.saveMyAssistantData();
    if (filter != 'My Assistant') {
      data = data.filter((o) => {
        return o.Type == filter;
      });
    }

    if (this.notificationsSearchText.length) {
      data = data.filter((o) => {
        return (
          o.Description.toLowerCase().indexOf(
            this.notificationsSearchText.toLowerCase()
          ) > -1
        );
      });
    }

    const groupedBymonth = {};
    const groupedData: any = [];

    if (data.length > 0) {
      data.sort((a, b) => {
        return b.timeStamp - a.timeStamp;
      });
      data.forEach((o) => {
        const currentMonth = format(o.DateTime, 'MMMM, YYYY');
        if (!(currentMonth in groupedBymonth)) {
          groupedBymonth[currentMonth] = [];
        }
        groupedBymonth[currentMonth].push(o);
      });

      for (const prop in groupedBymonth) {
        groupedData.push({
          key: prop,
          list: groupedBymonth[prop],
        });
      }
    }

    return groupedData;
  }

  public async getCustomNotification(loadFromDBOnly) {
    if (loadFromDBOnly) {
      // This fetch request was breaking offline launch scenario
      // Applying temporary fix to unblock
      // This will need to be handled properly by the feature owner
      return;
    }
    if (this.authService.user.customNotifications) {
      let syncState = await this.diskService.getSyncState(DB_SYNC_STATE_KEYS.SYNC_CUSTOM_NOTIFICATIONS);
      const isInitialSync = !syncState || !syncState.lastUpdatedTime;
      const lastUpdatedTime = syncState['lastUpdatedTime'];
      const now = new Date().getTime();
      let positionIds = this.authService.user.positions.map(o => {
        return o.ID
      });
      let positionString = '';
      positionIds.forEach(p => {
        positionString += '<value>' + p + '</value>'
      })
      let fetchXML = fetchQueries.customNotifications.fetchcustomNotifications.split('{positionIDs}').join(positionString);;
      if (!isInitialSync) {
        let hourDifference = differenceInHours(now, new Date(lastUpdatedTime));
        hourDifference += 1
        let deltaSyncFilter;
        deltaSyncFilter = `<filter type="and">
            <condition attribute="modifiedon" operator="last-x-hours" value="{lastXHours}" />
          </filter>`
        deltaSyncFilter = deltaSyncFilter.replace('{lastXHours}', hourDifference);
        fetchXML = fetchXML.replace('{deltaSyncFilter}', deltaSyncFilter)

      } else {
        fetchXML = fetchXML.replace('{deltaSyncFilter}', '');
      }

      await this.dynamics.executeFetchQuery('indskr_customnotificationses', fetchXML)
        .then(async (res) => {
          console.log("custom notification" + res)
          if (res) {
            if (Array.isArray(res)) {
              let customNotifications = [];
              customNotifications = res.map(record => {
                const contactName = record['contactFirstName'] + ' ' + record['contactLastName']
                let customNotificationModel = {
                  type: NOTIFICATION.CUSTOM_NOTIFICATION,
                  name: contactName,
                  DateTime: Date.now(),
                  id: NOTIFICATION.CUSTOM_NOTIFICATION + record['indskr_customnotificationsid'],
                  data: record,
                  icon: 'assets/imgs/notifications-active.svg',
                  isRed: false,
                  params: { contactName }
                };
                return customNotificationModel
              })
              const newLastUpdatedTime = new Date().getTime().toString();
              if (isInitialSync) {
                this.saveCustomNotificationToDisk(customNotifications, newLastUpdatedTime);
              } else {
                this.DeltaSyncedCustomNotification(customNotifications, newLastUpdatedTime)
              }

              await this.loadAndMapNotificationsFromDB();
            }
            syncState.lastUpdatedTime = now;
            await this.diskService.updateSyncState(syncState);
          }
        })
        .catch(async (err) => {
          console.log('Error while fetching new team sales order notifications' + err);
        });
    }
  }
}

export enum NOTIFICATION {
  SYNC_FAILED = 'SYNC_FAILED',
  MESSAGE_OPENED = 'MESSAGE_OPENED',
  MESSAGE_ATTACHMENT_CLICKED = 'MESSAGE_ATTACHMENT_CLICKED',
  MESSAGE_SENT = 'MESSAGE_SENT',
  MESSAGE_NOT_DELEVERED = 'MESSAGE_NOT_DELEVERED',
  INQUIRY_STATUS = 'INQUIRY_STATUS',
  CONTACT_CHANGE_REQUEST_STATUS = 'CONTACT_CHANGE_REQUEST_STATUS',
  ACCOUNT_CHANGE_REQUEST_STATUS = 'ACCOUNT_CHANGE_REQUEST_STATUS',
  ADJUSTMENT_REJECTED = 'ADJUSTMENT_REJECTED',
  CUSTOMER_ALLOCATION_TRANSFER = 'CUSTOMER_ALLOCATION_TRANSFER',
  NOTIFICATION_TO_COVISITOR='NOTIFICATION_TO_COVISITOR',
  NEW_SEARCH_ADV_SEARCH = 'NEW_SEARCH_ADV_SEARCH',
  MISSED_CALL = 'MISSED_CALL',
  DATA_UPLOAD_ERR_MEETING = 'DATA_UPLOAD_ERR_MEETING',
  DATA_UPLOAD_ERR_ALLOCATION = 'DATA_UPLOAD_ERR_ALLOCATION',
  DATA_UPLOAD_ERR_PHONECALL = 'DATA_UPLOAD_ERR_PHONECALL',
  DATA_UPLOAD_ERR_MESSAGE = 'DATA_UPLOAD_ERR_MESSAGE',
  DATA_UPLOAD_ERR_FOLLOWUP = 'DATA_UPLOAD_ERR_FOLLOWUP',
  SERVICE_ERR = 'SERVICE_ERR',
  NEW_CONTACTS_NOTIFICATION = 'NEW_CONTACTS_NOTIFICATION',
  NEW_ACCOUNTS_NOTIFICATION = 'NEW_ACCOUNTS_NOTIFICATION',
  NEW_PRESENTATIONS_NOTIFICATION = 'NEW_PRESENTATIONS_NOTIFICATION',
  NEW_RESOURCES_NOTIFICATION = 'NEW_RESOURCES_NOTIFICATION',
  NEW_FOLLOW_UP_NOTIFICATION = 'NEW_FOLLOW_UP_NOTIFICATION',
  BUSINESS_CHANGE_REQUEST_SUBMITTED = 'BUSINESS_CHANGE_REQUEST_SUBMITTED',
  BUSINESS_ACCOUNT_CHANGE_REQUEST_SUBMITTED = 'BUSINESS_ACCOUNT_CHANGE_REQUEST_SUBMITTED',
  BUSINESS_CHANGE_REQUEST_OPEN = "BUSINESS_CHANGE_REQUEST_OPEN",
  BUSINESS_ACCOUNT_CHANGE_REQUEST_OPEN = "BUSINESS_ACCOUNT_CHANGE_REQUEST_OPEN",
  BUSINESS_CHANGE_REQUEST_APPROVED = 'BUSINESS_CHANGE_REQUEST_APPROVED',
  BUSINESS_ACCOUNT_CHANGE_REQUEST_APPROVED = 'BUSINESS_ACCOUNT_CHANGE_REQUEST_APPROVED',
  BUSINESS_CHNAGE_REUEST_REJECTED = "BUSINESS_CHNAGE_REUEST_REJECTED",
  BUSINESS_ACCOUNT_CHANGE_REQUEST_REJECTED = "BUSINESS_ACCOUNT_CHANGE_REQUEST_REJECTED",
  ONE_KEY_CHANGE_REQUEST_STAGED = "ONE_KEY_CHANGE_REQUEST_STAGED",
  ONECRM_DCR_REQUEST_STAGED = "ONECRM_DCR_REQUEST_STAGED", 
  ONECRM_UPDATE_DCR_REQUEST_STAGED = "ONECRM_UPDATE_DCR_REQUEST_STAGED",
  ONE_KEY_CHANGE_REQUEST_SUBMITTED = "ONE_KEY_CHANGE_REQUEST_SUBMITTED",
  ONE_KEY_APPROVAL_AND_NEW_CONTACT_ADDEDD = "ONE_KEY_APPROVAL_AND_NEW_CONTACT_ADDEDD",
  ONE_KEY_REJECTED_SEE_COMMENTS = "ONE_KEY_REJECTED_SEE_COMMENTS",
  ONE_KEY_CHANGE_REQUEST_UPDATE_STAGED = "ONE_KEY_CHANGE_REQUEST_UPDATE_STAGED",
  ONE_KEY_CHNAGE_REQUEST_UPDATE_REQUEST_SUBMITTED = "ONE_KEY_CHNAGE_REQUEST_UPDATE_REQUEST_SUBMITTED",
  ONE_KEY_CHANGE_REQUEST_UPDATE_APPROVED_CONTACT_BE_UPDATED = "ONE_KEY_CHANGE_REQUEST_UPDATE_APPROVED_CONTACT_BE_UPDATED",
  ONECRM_DCR_APPROVED = "ONECRM_DCR_APPROVED",
  ONECRM_DCR_REJECTED = "ONECRM_DCR_REJECTED",
  ONE_KEY_CHANGE_REQUEST_UPDATE_REJECTED_SEE_COMMENTS = "ONE_KEY_CHANGE_REQUEST_UPDATE_REJECTED_SEE_COMMENTS",
  ONE_KEY_CHANGE_REQUEST_IN_PROCESS = "ONE_KEY_CHANGE_REQUEST_IN_PROCESS",
  ONE_KEY_CHANGE_REQUEST_SUCCESSFULLY_SUBMITTED = "ONE_KEY_CHANGE_REQUEST_SUCCESSFULLY_SUBMITTED",
  NEW_TEAM_ORDER_NOTIFICATION = 'NEW_TEAM_ORDER_NOTIFICATION',
  ASSET_REMOVED = 'ASSET_REMOVED_NOTIFICATION',
  CUSTOM_NOTIFICATION = 'CUSTOM_NOTIFICATION',
  CUSTOMER_POSITION_LIST_SUBMITTED = "CUSTOMER_POSITION_LIST_SUBMITTED",
  CUSTOMER_POSITION_LIST_APPROVED = "CUSTOMER_POSITION_LIST_APPROVED",
  CUSTOMER_POSITION_LIST_REJECTED = "CUSTOMER_POSITION_LIST_REJECTED",
  DCR_FOR_APPROVAL = "DCR_FOR_APPROVAL",
  APPEAL_STATUS_SUBMITTED = "APPEAL_STATUS_SUBMITTED",
  APPEAL_STATUS_APPROVED = "APPEAL_STATUS_APPROVED",
  APPEAL_STATUS_REJECTED = "APPEAL_STATUS_REJECTED",
  APPEAL_STATUS_PENDING_FOR_SUBMISSION = "APPEAL_STATUS_PENDING_FOR_SUBMISSION"
}
