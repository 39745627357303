import { LotDTO } from '../../models/sample-model';
import { Utility } from '../../utility/util';

export class Lot {
    id: string;
    name: string;
    lotNumber: string;
    sampleSKUId: string;
    sampleSKUName: string;
    validFrom: Date;
    validTo: Date;
    status: number;
    totalQuantityReceived: number;
    totalQuantityDropped:number;
    totalQuantityRemaining:number;
    isSelected: boolean = false;

    _id: string;
    _rev: string;
    lastUpdatedTime: number;

    constructor(raw: LotDTO) {
        this._id = raw._id ? raw._id : null;
        this._rev = raw._rev ? raw._rev : null;
        this.lastUpdatedTime = raw.lastUpdatedTime ? raw.lastUpdatedTime : null;
        this.id = raw.indskr_lotid;
        this.name = raw.name;
        this.lotNumber = raw.indskr_lotnumber;
        this.sampleSKUId = raw.indskr_skuid;
        this.sampleSKUName = raw.indskr_skuname || null;
        this.validFrom = Utility.changeUTCDateToLocalDateWith0Time(parseInt(raw.indskr_lotvalidfromdate));
        this.validTo = Utility.changeUTCDateToLocalDateWith0Time(parseInt(raw.indskr_lotvalidtodate),true);
        this.totalQuantityDropped = (raw.indskr_totalquantitydropped) ? raw.indskr_totalquantitydropped : 0;
        this.totalQuantityReceived = (raw.indskr_totalquantityrecieved) ? raw.indskr_totalquantityrecieved : 0;
        this.totalQuantityRemaining = (raw.indskr_totalquantityremaining)
        this.status = raw.statecode;
    }

    public get DTO(){
        return {
            name: this.name,
            statecode: this.status,
            indskr_lotnumber: this.lotNumber,
            indskr_lotvalidfromdate: Utility.changeLocalDateToUTCDateWith0Time(this.validFrom.getTime()).getTime().toString(),
            indskr_lotvalidtodate: Utility.changeLocalDateToUTCDateWith0Time(this.validTo.getTime()).getTime().toString(),
            indskr_lotid: this.id,
            indskr_skuid: this.sampleSKUId,
            indskr_skuname: this.sampleSKUName,
            indskr_totalquantitydropped: this.totalQuantityDropped,
            indskr_totalquantityrecieved: this.totalQuantityReceived,
            indskr_totalquantityremaining: this.totalQuantityRemaining,
        }
    }
}

// export interface LotQuantityDetail extends PouchDbDoc{
//     lotId:string;
//     lotName: string;
//     lotValidToDate:Date;
//     lotValidFromDate:Date;
//     totalQuantity?:number;
//     lotStateCode:number;
//     //totalQuantityRemaining?:number;
//     totalQuantityDropped?:number;
//     shipmentsAcknowledged?:Array<{
//         shipmentId:string;
//         quantityAcknowledged?:number;
//         quantityShipped?:number;
//     }>;
//     samplesDropped?:Array<{
//         sampleActivityId:string;
//         skuId:string;
//         quantityDropped?:number;
//     }>;
// }

export interface PouchDbDoc {
    _id?: string;
    _rev?: string;
    lastUpdatedTime?: number;
}

