import { SurveyForm, SurveyResponse } from './../../classes/survey/survey-form.class';
import { Injectable } from "@angular/core";
import { SURVEY_LOOKUP_POPOVER, SURVEY_LOOKUP_POPOVER_OTHERS, SURVEY_TEMPLATE_FETCHXML } from "@omni/config/fetch-xml/survey-fetchXMLs";
import { DB_KEY_PREFIXES } from "@omni/config/pouch-db.config";
import { DiskService } from "../disk/disk.service";
import { differenceInHours } from "date-fns";
import { DynamicsClientService } from "@omni/data-services/dynamics-client/dynamics-client.service";
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthenticationService } from '../authentication.service';


@Injectable({
  providedIn: 'root'
})

export class SurveyService {
  public surveyForms = [];
  public surveyResponses = [];
  public surveyStaticResults: any;
  public surveyLookupResults: Array<any>=[];
  private surveyLookup = new BehaviorSubject<any>(undefined);
  public dynamicLookupEntities: Array<any>=[];
  public surveyActiveFor:string = '';
  public surveyFlow: SurveyFlow = SurveyFlow.surveyTool;

  constructor(
    private disk: DiskService,
    private dynamics: DynamicsClientService,
    private readonly authService: AuthenticationService,

  ) {}

  public async getSurveyForms(isFullSync, loadFromDbOnly = false) {

    let offlineDocs;
    let lastModifiedForDeltaSync, hourDifference;
    let now = new Date();

    await this.disk.retrieve(DB_KEY_PREFIXES.SURVEY_FORM, true).then((doc)=>{
      offlineDocs = doc
        if(doc && doc.raw){
          this.surveyForms = doc.raw
        }
        else {
          this.surveyForms = [];
          isFullSync = true;
        }
      })

    if(!loadFromDbOnly) {
      let fetchXML = SURVEY_TEMPLATE_FETCHXML;
      fetchXML = fetchXML.replace('{userBUId}', this.authService.user.xBusinessUnitId);
      if(isFullSync) {
        fetchXML = fetchXML.replace('{deltaSyncFilter}', '');
      }
      else {
        let deltaSyncFilter = `
          <filter type="and"> 
            <condition attribute="modifiedon" operator="last-x-hours" value="{lastXHours}" />
          </filter> 
        `;
        lastModifiedForDeltaSync = offlineDocs ? offlineDocs.lastDeltaSync : null;
        if(lastModifiedForDeltaSync){
          hourDifference = differenceInHours(
            now,
            new Date(lastModifiedForDeltaSync)
          )
          //add one to make sure we take care of fractional difference in hours
          hourDifference += 1;
          deltaSyncFilter = deltaSyncFilter.replace('{lastXHours}', hourDifference);
        }
        else deltaSyncFilter = ''
        fetchXML = fetchXML.replace('{DeltaSyncFilter}', deltaSyncFilter);
      }

      try {
        let response = await this.dynamics.executeFetchQuery('indskr_expertinquirytemplates', fetchXML);
        if(response){
          await this.agreegateSurveyForms(response);          
          this.disk.updateOrInsert(DB_KEY_PREFIXES.SURVEY_FORM, (doc)=>{
            doc = {
              raw: this.surveyForms,
              lastDeltaSync: new Date().getTime()
            };
            return doc;
          })
        }
        if(this.surveyForms) this.getLookupEntity(this.surveyForms);
      } catch(error) {
        console.log("Error fetching form metadata: ", error)
      }
    }
  }

  private agreegateSurveyForms(data) {

    data.map((rawData) => {
      let surveyForm;
      let surveyFormID = rawData['indskr_expertinquirytemplateid'];
      if (surveyFormID) {
        surveyForm = this.surveyForms.find(o => o.surveyFormId == rawData.indskr_expertinquirytemplateid)
        if(!surveyForm) {
          if(rawData['ab.statecode'] == 0 && rawData['statecode'] == 0){
            surveyForm = new SurveyForm(rawData);
            this.surveyForms.push(surveyForm);
          }
        } else {let index = this.surveyForms.findIndex(form => form.surveyFormId === surveyFormID)
          if(rawData['ab.statecode'] == 0 && rawData['statecode'] == 0){
            surveyForm = new SurveyForm(rawData);
            this.surveyForms[index] = surveyForm;
          }else{
            this.surveyForms.splice(index,1);
          }
        }
      }
    });
  }

  private getLookupEntity(surveyForms) {
    let entities: Array<any> = [];
    this.surveyForms.forEach((form)  => {
      if(form && form.surveyConfiguration && form.surveyConfiguration.pages){
        form.surveyConfiguration.pages[0].elements.forEach((element) => {
          if(element.type=='lookup') {
            const targetEntity = element.metadata.target;
            const entityAttribute = targetEntity.attributes
            if(targetEntity.name !== 'contact' && targetEntity.name !=='product' && targetEntity.name !== 'account' && targetEntity.name !== 'indskr_expertcategorytype') {
              let ent = {
                pluralName: targetEntity.setName,
                entityName: targetEntity.name,
                attributeId: entityAttribute.id,
                attributeName: entityAttribute.name
              }
              entities.push(ent)
              this.fetchLookupEntityData(ent);
            }
          }
        });
      }
    });
    this.dynamicLookupEntities = entities;
  }

  private async fetchLookupEntityData(entity, isFullSync=false, loadFromDbOnly = false) {
    let offlineDocs;
    let lastModifiedForDeltaSync, hourDifference;
    let now = new Date();
    let dbkey = DB_KEY_PREFIXES.SURVEY_LOOKUP_ + entity.entityName;

    await this.disk.retrieve(dbkey, true).then((doc)=>{
      offlineDocs = doc
      if(!doc) isFullSync = true;
    })

    if(!loadFromDbOnly) {
      let fetchXML;
      if(entity.entityName == 'entitlement' || entity.entityName == 'sla') {
        fetchXML = SURVEY_LOOKUP_POPOVER_OTHERS;
      } else {
        fetchXML = SURVEY_LOOKUP_POPOVER;
      }
      fetchXML = fetchXML.replace('{entityName}', `<entity name="${entity.entityName}">`);
      fetchXML = fetchXML.replace('{attributeId}', `<attribute name="${entity.attributeId}"/>`)
      fetchXML = fetchXML.replace('{attributeName}', `<attribute name="${entity.attributeName}"/>`)

      if(isFullSync) {
        fetchXML = fetchXML.replace('{deltaSyncFilter}', '');
      }
      else {
        let deltaSyncFilter = `<condition attribute="modifiedon" operator="last-x-hours" value="{lastXHours}" />`
        lastModifiedForDeltaSync = offlineDocs ? offlineDocs.lastDeltaSync : null;
        if(lastModifiedForDeltaSync){
          hourDifference = differenceInHours(
            now,
            new Date(lastModifiedForDeltaSync)
          )
          //add one to make sure we take care of fractional difference in hours
          hourDifference += 1;
          deltaSyncFilter = deltaSyncFilter.replace('{lastXHours}', hourDifference);
        }
        else deltaSyncFilter = ''
        fetchXML = fetchXML.replace('{DeltaSyncFilter}', deltaSyncFilter);
      }

      try {
        let response = await this.dynamics.executeFetchQueryWithPageNumber(entity.pluralName, fetchXML, 0);
        if(response){
          this.disk.updateOrInsert(dbkey,(doc)=>{
            doc = {
              lastDeltaSync: new Date().getTime(),
              raw: response
            };
          return doc;
          })
        }
      } catch(error) {
        console.log("Error fetching form metadata: ", error)
      }
    }
  }

  assignLookupResult(input) {
    if(input && this.surveyLookupResults && this.surveyLookupResults.length>0) {
      let index = this.surveyLookupResults.findIndex(result => result.targetEntity == input['targetEntity'])
      if(index>-1) {
        this.surveyLookupResults[index] = input;
      } else {
        this.surveyLookupResults.push(input);
      }
    } else if(input) {
      this.surveyLookupResults.push(input);
    }
    this.surveyLookup.next(input);
  }

  get currentLLookupResult(): Observable<any> {
    return this.surveyLookup.asObservable();
  }

}


export enum SurveyFlow {
  surveyTool = 1,
  meeting = 2,
  contact = 3
}