import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { AlertController } from '@ionic/angular';
import _ from 'lodash';


@Component({
  selector: 'alert-with-input',
  templateUrl: 'alert-with-input.html',
  styleUrls: ['alert-with-input.scss'],
})
export class AlertWithInput implements OnInit {
  currentAlert: HTMLIonPopoverElement;
  @Input() inputs: any;
  @Input() header: string;
  @Input() message: string
  @Input() Placeholder: string
  @Input() cancelText;
  @Input() confirmText;
  @Input() isMandatory: boolean;
  selectedItem: any;
  selectedOtherItem: string;

  constructor(
    private popoverCtrl: PopoverController,
    public alertController: AlertController,
  ) {


  }

  onSelectOption(ev) {
    this.selectedItem = ev;
    if (this.selectedItem != 'Others') {
      this.selectedOtherItem = null;
    }
  }

  onDismiss(flag) {
    //To check if configurable list (dropdown) is not present i.e. text is present
    if(this.inputs.length === 0)
      if (flag && this.isMandatory && _.isEmpty(this.selectedOtherItem)) return;
    
    if (!flag) {
      this.popoverCtrl.dismiss();
      return;
    }

    let payload = {
      inputs: this.selectedItem,
      selectedOtherItem: this.selectedOtherItem ? this.selectedOtherItem : null,
      role: 'ok'
    }

    this.popoverCtrl.dismiss(payload);

  }


  ngOnInit() { }

}
