import { AppointmentActivity } from "../activity/appointment.activity.class";
import { format, isPast, isFuture } from "date-fns";
import {
  ScientificInfo
} from "./scientific-info.class";
import { EventRegistration } from "../customer-event/customer-event.class";
import { Activity } from "../activity/activity.class";
import { Utility } from "../../utility/util";
import { EventActivity } from "../events-tool/event.class";
import { WebsiteAccessLogs} from "./website-access.class";
import _ from "lodash";

// TODO: values need to be updated.
export enum ContactTitle{
  Dr = 548910000,
  Mr = 548910000,
  Mrs = 548910002,
  Miss = 548910000,
  Ms = 548910000,
}

export enum ContactAccountRole{
  "Primary" = 1,
  "Decision Maker" = 2,
  "Approver" = 3
}


export enum ContactMeetingState{
  JOINED = 100000000,
  NOTJOINED = 100000002,
  LEFT = 100000003,
}

export enum ContactSentiment{
  Positive = 'positive',
  Neutral = 'neutral',
  Negative = 'negative'
}

export enum TrackAction {
  Download = 548910000,
  Deleted = 548910001,
}

export enum ContactStatusCode {
  Verified = 1,
  Unverified = 548910000,
}

/**
 * Main class for contacts from CRM, including ability to assign optional extra params via Object.Assign
 *
 * @export
 * @class Contact
 */
export class Contact {
  [x: string]: any;
  public raw: any;
  public ID: string;
  public indskr_iseventparticipant: boolean = false;
  public msevtmgt_eventid: string = "";
  public appId: string;
  public external_source: string;
  public title: string;
  public indskr_title: number;
  private titleFormattedValue: string;
  public firstName: string;
  public middleName: string;
  public lastName: string;
  public fullname: string;
  public trackActionFormattedValue: string;
  public trackAction: number;
  public isActive: boolean;
  public statusCode: number;
  public veevaRecordState: number;
  public isUsedInOneTimeMeeting = false;
  public isVerified: boolean;
  public verificationStatus: string;
  public isSelected: boolean;
  public isSelectedForTag: boolean;
  public primaryEmail:string;
  public emailAddressList: Array<Email>=[];
  public stakeholderRolesList: Array<any>=[];
  public addressesList: Array<ContactAddress>=[];
  public accountRelationships:AccountRelation[]=[];
  public contactRelationships:ContactRelation[]=[];
  public customerPositions:CustomerPosition[]=[];
  public productSegmentations:ProductSegmentation[] = [];
  public fax:string;
  public mobilePhone:string;
  public telephone:string;
  public isKOL:string;
  public isSpeaker:string;
  public language:string;
  public primaryLanguage:Language;
  public primarySpecialty: Specialty;
  public primaryAccount:{
    id:string,
    accountName:string,
  };
  public speciality:string;
  public specialties: Specialty[];
  public combinedSpecialty: string;
  public professionalDesignation: ProfessionalDesignation = {id: '', designation: ''};
  public selectedAddress:string;
  public activitesByContact:Array<Activity>=[];
  public activitesTimeline:Array<Activity>=[];
  public repCallPlansByContact: Array<ContactRepCallPlan>=[]
  public connectionState:number;
  public isguest:boolean;
  public isremote:boolean;
  public isleftmanually:boolean;
  public join_date:string;
  public left_date:string;
  public configuredFields:Array<object>;
  public isRadioSelected: boolean;
  public contactPresentationMatching:Array<object> = [];
  public contactResourceMatching:Array<object> = [];
  public contactDocumentMatching:Array<object> = [];
  public lastSyncedTime: number;
  public schedulerFrequency: number = 0;
  public isSelectable:boolean = false;
  public scientificInfo: ScientificInfo;
  public ind_investigator: boolean;
  public investigatorString: string;
  public ind_grantrecipient: boolean;
  public grantReceipientString: string;
  public eventsAndRegistrationsByContact:Array<EventRegistration>=[];
  public eventsCheckInByContact: Array<EventActivity> = [];
  public eventsCompletedByContact: Array<EventActivity> = [];
  public websiteAccessLogs:Array<WebsiteAccessLogs>=[];
  public customerJourneysTimeline:Array<Activity>=[];
  public indskr_facebookpsid: string;
  public indskr_whatsappid: string;
  public indskr_alternatephone1: string;
  public indskr_alternatephone2: string;
  public indskr_alternatephone3: string;
  public contactAllocations: ContactAllocation[];
  public lastConsentedDate: number = 0;
  public consentExpiryDate: number = 4000000000000;
  private compositeAddValues = ['street', 'street2', 'street3', 'city', 'state', ];
  public modifiedOn: number;
  public hasActiveConsent: string = 'No';
  public isNew: boolean;
  public interactionDate: number;
  public interactionType: string;
  public createdOn: number;
  public mdmType: number;
  public indskr_contactsourcetype: string;
  public indskr_mdmid: string;
  public indskr_contacttype: number;
  public indskr_wechatuserid: string;
  public tagLabels: string[];
  public isForcedHideAddIcon?: boolean = false;
  public indskr_externalid: string;
  public indskr_hcpid: string;
  public gendercode: number;

  get fullName(): string {
    return this.fullname ? this.fullname : `${this.firstName} ${this.lastName}`;
  }

  get secondaryInfo(): string {
    return [this.primarySpecialtyName, this.getPrimaryAccountRelationship, this.getPrimaryCitySate].filter(Boolean).join(", ");
  }

  get primarySpecialtyName(): string {
    return this.primarySpecialty ? this.primarySpecialty.name : '';
  }

  get emailDTO(): object {
    return {
      id: this.ID,
      firstName: this.firstName,
      lastName: this.lastName,
      email: (this.getPrimaryEmail) ? this.getPrimaryEmail : "",
      validConsent: true
    };
  }

  set contactJoinedState(state){
    this.connectionState = state;
  }


  get otherEmails():string{
    if (this.emailAddressList.length == 0) return '';

    let otherEmails: string[]=[];
    //For each email, check if it is primary
    this.emailAddressList.forEach(email => {
      if (!email.isPrimary) otherEmails.push(email.emailAddress);
    });
    return otherEmails.toString();
  }

  get alternatePhoneNumbers(): string {
    let alternatePhones: string[] = [];
    if (this.indskr_alternatephone1) alternatePhones.push(this.indskr_alternatephone1);
    if (this.indskr_alternatephone2) alternatePhones.push(this.indskr_alternatephone2);
    if (this.indskr_alternatephone3) alternatePhones.push(this.indskr_alternatephone3);
    if (!alternatePhones.length) return '';
    return alternatePhones.toString();
  }

  get getPrimaryEmail(): string {
    if (this.emailAddressList.length == 0) return undefined;

    let primaryEmailFound: Email;
    //For each email, check if it is primary
    this.emailAddressList.forEach(email => {
      if (email.isPrimary) primaryEmailFound = email;
    });

    //Do we have a first choice?
    if (primaryEmailFound) {
      return primaryEmailFound.emailAddress;
    } else {
      //Fallback to any choice. Should not return any if no primary
      return undefined;
    }
  }

  get getPrimaryAccountRelationship(): string{
    if(this.primaryAccount) return this.primaryAccount.accountName;
    if (this.accountRelationships.length == 0) return undefined;

    let primaryAccRelationshipFound: AccountRelation;
    //For each email, check if it is primary
    this.accountRelationships.forEach(acc => {
      if (acc.isPrimary) primaryAccRelationshipFound = acc;
    });

    //Do we have a first choice?
    if (primaryAccRelationshipFound) {
      return primaryAccRelationshipFound.accountName;
    } else {
      //Fallback to any choice
      return '';
    }
  }

  get getPrimaryAddress(): string {
    if (this.addressesList.length == 0) return undefined;

    let primaryAddFound: ContactAddress;
    //For each email, check if it is primary
    this.addressesList.forEach(add => {
      if (add.isPrimary) primaryAddFound = add;
    });

    //Do we have a first choice?
    if (primaryAddFound) {
      return primaryAddFound.compositeAdd;
    } else {
      //Fallback to any choice
      return this.addressesList[0].compositeAdd;
    }
  }

  get getPrimaryCitySate(): string {
    if (this.addressesList.length == 0) return undefined;

    let primaryCtryFound: ContactAddress;

    this.addressesList.forEach(add => {
      if (add.isPrimary) primaryCtryFound = add;
    });

    if(primaryCtryFound) {
      return [primaryCtryFound.city, primaryCtryFound.state].filter(Boolean).join(", ");
    } else {
      return '';
    }
  }

  get contactAccountString(){
    if (this.accountRelationships.length === 1) {
      return `${this.accountRelationships[0].accountName}`;

  } else if (this.accountRelationships.length >= 2) {

      //find first alphabetical account
      this.accountRelationships.sort((accountA, accountB): number => {
          if (accountA.accountName > accountB.accountName) return 1;
          if (accountA.accountName < accountB.accountName) return -1;

          return 0;
      });

      return `${this.accountRelationships[0].accountName} +${this.accountRelationships.length-1}`;
  }

  return 'Select accounts';
  }

  get allPhoneNumbers(){
    return this.mobilePhone +','+this.telephone+','+this.alternatePhoneNumbers
  }
  constructor(raw: ContactDTO) {
    this.raw = raw;
    this.ID = raw["contactid"];
    this.indskr_iseventparticipant = (raw["indskr_iseventparticipant"])? true : false;
    this.msevtmgt_eventid = raw["msevtmgt_eventid"] || '';
    this.title = raw["indskr_title@OData.Community.Display.V1.FormattedValue"] || '';
    this.indskr_title = raw['indskr_title'] || undefined;
    this.titleFormattedValue = raw['titleFormattedValue'] || '';

    if (!this.title && typeof this.indskr_title === 'number') {
      // TODO: get possible values for 'indskr_title' from MSE
      // We have the data but straight from dynamics
    }
    if (!this.title && this.titleFormattedValue) {
      this.title = this.titleFormattedValue;
    }

    this.appId = raw['indskr_externalid'] || '';
    this.indskr_hcpid = raw['indskr_hcpid'] || '';
    this.gendercode = raw['gendercode'] || undefined;

    this.firstName = raw["firstname"] || "";
    this.middleName = raw['middlename'] || '';
    this.lastName = raw["lastname"] || "";
    this.fullname = raw['fullname'] ? raw['fullname'] : `${raw["firstname"]} ${raw["lastname"] ? raw["lastname"] : ''}`;
    this.isSelected = false;
    //this.isActive = raw.statuscode === 1 || raw.statuscode === 548910000; // 1 = Verified(Active), 548910000 = Unverified(Active), 2 = Inactive(Inactive)
    this.isActive = !(raw['statecode'] == 1 && (raw['statuscode'] == 2 || raw['statuscode'] == 548910003 || raw['statuscode'] == 548910010 || raw['statuscode'] == 548910011 || raw['statuscode'] == 548910012 || raw['statuscode'] == 548910013))
    this.isVerified = raw.statuscode === 1;
    this.verificationStatus = this.isVerified? 'Verified': 'Unverified';
    this.statusCode = raw.statuscode;
    this.trackActionFormattedValue = raw['track_action@OData.Community.Display.V1.FormattedValue'] || '';
    this.trackAction = raw['track_action'];
    this.isNew = false;

    this.connectionState = raw["indskr_joinstatus"] || ContactMeetingState.NOTJOINED;
    this.isguest = raw["indskr_isguest"] || false;

    this.fax = raw['fax'] || '';
    this.mobilePhone = raw['mobilephone'] || '';
    this.telephone = raw['telephone1'] || '';
    this.indskr_facebookpsid = raw['indskr_facebookpsid'] || '';
    this.indskr_whatsappid = raw['indskr_whatsappid'] || '';
    this.isKOL = (raw['indskr_kol']) ? 'Yes' : 'No';
    this.isSpeaker = (raw['indskr_speaker']) ? 'Yes' : 'No';
    // this.language = (raw['indskr_lu_languageid']) ? raw['indskr_lu_languageid'].Name : '';
    // this.speciality = (raw['indskr_lu_specialtyid']) ? raw['indskr_lu_specialtyid'].Name : '';
    this.language = raw['languageName'] || '';
    this.speciality = raw['_indskr_lu_specialtyid_value@OData.Community.Display.V1.FormattedValue'] || '';
    this.indskr_externalid = raw['indskr_externalid'] || '';
    this.specialties = [];
    this.indskr_alternatephone1 = raw['indskr_alternatephone1'] || '';
    this.indskr_alternatephone2 = raw['indskr_alternatephone2'] || '';
    this.indskr_alternatephone3 = raw['indskr_alternatephone3'] || '';
    this.mdmType = raw["indskr_mdm"] || '';
    this.indskr_contactsourcetype = raw['indskr_contactsourcetype'];
    this.indskr_mdmid = raw['indskr_mdmid'];
    this.indskr_contacttype = raw['indskr_contacttype'];
    this.indskr_wechatuserid = raw['indskr_wechatuserid'];
    this.primaryLanguage = {
      id: raw['_indskr_lu_languageid_value'] || '',
      name: raw['languageName'] || '',
      createdon : ''
    }

    if(raw['_indskr_primaryaccount_value'] && raw['_indskr_primaryaccount_value@OData.Community.Display.V1.FormattedValue']){
      this.primaryAccount = {
        id: raw['_indskr_primaryaccount_value'],
        accountName: raw['_indskr_primaryaccount_value@OData.Community.Display.V1.FormattedValue'],
      };
    }

    if(this.speciality) {
      this.primarySpecialty = {
        name: this.speciality,
        id: raw['_indskr_lu_specialtyid_value'] || '',
      }
      this.specialties.push(this.primarySpecialty);
    }


    this.professionalDesignation.id = raw['_indskr_lu_professional_designationid_value'] || '';
    this.professionalDesignation.designation = raw['_indskr_lu_professional_designationid_value@OData.Community.Display.V1.FormattedValue'] || '';

    this.join_date = raw['indskr_joineddate'] || "";
    this.left_date = raw['indskr_leftdate'] || "";
    this.isremote  = raw["indskr_isremote"] || false;
    this.isleftmanually  = raw["indskr_leftmanually"] || false;
    this.lastSyncedTime = raw.lastSyncedTime || 0;
    this.configuredFields = [];

    this.ind_grantrecipient = raw['ind_grantrecipient'] || false;
    this.grantReceipientString = raw['ind_grantrecipient']? 'Yes': 'No';
    this.ind_investigator = raw['ind_investigator'] || false;
    this.investigatorString = raw['ind_investigator']? 'Yes': 'No';
    this.modifiedOn = raw['modifiedon']? new Date(raw['modifiedon']).getTime():0;
    this.createdOn = raw['createdon'] ? parseInt(raw['createdon']) : 0;
    this.interactionDate = raw['interactionDate'] ? parseInt(raw['interactionDate']) : 0;
    this.interactionType = raw['interactionType'];
    this.isUsedInOneTimeMeeting = !!raw['omniveev_isusedinonetimemeeting'];
    this.veevaRecordState = raw['omniveev_recordstate'];
    this.tagLabels = _.isEmpty(raw['tagLabels']) ? []: [...raw['tagLabels']];

    const shouldSetupDetail: boolean = this.isActive && !this.isguest;

    this.contactPresentationMatching = [];
    if(raw['presentationsContentMatching'] && Array.isArray(raw['presentationsContentMatching'])){
      raw['presentationsContentMatching'].forEach(pres => {
        this.contactPresentationMatching.push(pres);
      });
    }

    this.contactResourceMatching = [];
    if(raw['resourceContentMatching'] && Array.isArray(raw['resourceContentMatching'])){
      raw['resourceContentMatching'].forEach(pres => {
        this.contactResourceMatching.push(pres);
      });
    }

    this.contactDocumentMatching = [];
    if(raw['documentContentMatching'] && Array.isArray(raw['documentContentMatching'])){
      raw['documentContentMatching'].forEach(pres => {
        this.contactDocumentMatching.push(pres);
      });
    }


    this.emailAddressList = [];
    if (shouldSetupDetail && raw.emailAddresses && Array.isArray(raw.emailAddresses)) {
      for (let email of raw.emailAddresses) {
        let emailObj: Email = {
          emailAddress: email.emailAddress,
          emailAddressId: email.indskr_email_addressid,
          isPrimary: email.isPrimary && email.isPrimary === 'true',
          isSelected: false,
          isSelectable: false,
          isVerified: email.isVerified == false ? false : true,
          isNew: raw["contactid"] ? false: true,
        };

        this.emailAddressList.push(emailObj);
      }
    }

    if (shouldSetupDetail && raw.customerPositions && Array.isArray(raw.customerPositions)) {
      for (let position of raw.customerPositions) {
        let addObj: CustomerPosition = {
          positionId: position._indskr_positionid_value,
          position: position.positionname
        }

        this.customerPositions.push(addObj);
      }
    }

    if (shouldSetupDetail && raw.customerAddresses && Array.isArray(raw.customerAddresses)) {
      for (let address of raw.customerAddresses) {
        let addObj: ContactAddress = {
          city: address.city || '',
          state: address.stateorprovince || '',
          postal: address.postalcode || '',
          country: address.country || '',
          countryCode: address.countrycode3 || '',
          street: address.address_line1 || '',
          street2: address.address_line2 || '',
          street3: address.address_line3 || '',
          region: address.regionname || '',
          postOfficeBox: address.postalbox || '',
          isPrimary: !!address.indskr_primary,
          addressId: address.customeraddressid || '',
          compositeAdd: '',
          primaryContact: address.primarycontact || '',
          fax: address.fax || '',
          fax2: address.secondaryfax || '',
          telephone1: address.telephone1 || '',
          telephone2: address.telephone2 || '',
          isSampleEligible: !!address.issamplingeligible,
          customerAddressID: address.indskr_indskr_customeraddress_v2id || '',
          concatanatedAddress: '',
          latitude: address.indskr_latitude ||'',
          longitude: address.indskr_longitude||''
        };

        const addressArray: string[] = [
          addObj.postOfficeBox,
          addObj.street,
          addObj.street2,
          addObj.street3,
          addObj.city,
          addObj.postal,
          addObj.state,
          addObj.country
        ];

        addObj.compositeAdd = Utility.getCommaSeparatedString(addressArray);
        addObj.concatanatedAddress = this.getConcatanatedAddress(addObj);
        this.addressesList.push(addObj);
      }
    }

    if (shouldSetupDetail && raw.productSegmentations && Array.isArray(raw.productSegmentations)) {
      for (let seg of raw.productSegmentations) {
        let addObj: ProductSegmentation = {
          intimacy: seg.intimacy || null,
          intimacyId: seg.intimacyid || null,
          segmentation: seg.segmentation || null,
          segmentationId: seg.segmentationid || null,
          productName: seg.productname || null,
          productId: seg._indskr_product_value || null,
          productRatingId: seg.indskr_productratingid || null,
          marketPotential: seg._indskr_marketpotential_new_value || null
        };

        this.productSegmentations.push(addObj);
      }
    }

    if (shouldSetupDetail && raw.accountRelationships && Array.isArray(raw.accountRelationships)) {
      for (let accRel of raw.accountRelationships) {
        let addObj: AccountRelation = {
          accountId: accRel._indskr_accountid_value || null,
          accountContactAffiliationId: accRel.indskr_accountcontactaffiliationid || null,
          accountName: accRel.accountname || '',
          name: accRel.indskr_name || '',
          startDate: (accRel.indskr_startdate && !isNaN(parseInt(accRel.indskr_startdate))) ? format(parseInt(accRel.indskr_startdate), "MMM D, YYYY") : '',
          endDate: (accRel.indskr_enddate && !isNaN(parseInt(accRel.indskr_enddate))) ? format(parseInt(accRel.indskr_enddate), "MMM D, YYYY") : '',
          createdDate: (accRel.createdon && !isNaN(parseInt(accRel.createdon))) ? format(parseInt(accRel.createdon), "MMM D, YYYY") : '',
          comments: accRel.indskr_comments || '',
          contactRoleId: accRel.indskr_contactrole || '',
          contactRole: accRel.indskr_contactroleFormattedValue || '',
          directRelation: accRel.indskr_directrelationshipflag ? 'True' : 'False',
          status: accRel.statuscodeFormattedValue ? accRel.statuscodeFormattedValue : 'Unverified',
          statecode: accRel.statecode || null,
          expandIcon: 'arrow-dropup',
          showDetails: false,
          isPrimary: !!accRel.isPrimary,
          jobTitle: accRel.indskr_jobtitle || null,
          indskr_specialty: accRel.indskr_specialtyFormattedValue || '',
          indskr_sanofispecialty: accRel.indskr_sanofispecialty || '',
          indskr_department: accRel.indskr_department || ''
        };

        this.accountRelationships.push(addObj);
      }

      this.accountRelationships.sort((a,b) => {
        return a.name>b.name?1:-1;
      });
    }

    if (shouldSetupDetail && raw['contactRelationships'] && Array.isArray(raw['contactRelationships'])) {
      for (let contactRel of raw['contactRelationships'] as ContactContactRelationshipDTO[]) {
        let addObj: ContactRelation = {
          name: contactRel.indskr_name || '',
          contactRelationshipId: contactRel.indskr_contactrelationshipid || null,
          contactId: contactRel.contactId || null,
          contactName: contactRel.indskr_relatedcontactname || '',
          relatedContactId: contactRel.indskr_relatedcontactid || null,
          relationship: contactRel.indskr_relationshipFormattedValue,
          createdDate: contactRel.createdon,
          statecode: contactRel.statecode || null,
          expandIcon: 'arrow-dropup',
          showDetails: false
        };

        this.contactRelationships.push(addObj);
      }
    }

    // if (shouldSetupDetail && raw['repCallPlans'] && Array.isArray(raw['repCallPlans'])) {
    //   this.mapCallPlans(raw['repCallPlans']);
    // }

    if (raw['scientificInformation']) {
      this.mapScientificInformation(raw['scientificInformation']);
    }
    this.contactAllocations = [];
  }


  // get contactCreationDTO(): any {

  //   return {
  //     "contactid": this.ID,
  //     "indskr_externalsource": this.external_source || '',
  //     "indskr_externalid": this.appId || '',
  //     "firstname": this.firstName,
  //     "lastname": this.lastName,
  //     "middlename": this.middleName || '',
  //     "indskr_title": this.indskr_title ? this.indskr_title : null,
  //     "telephone1": this.telephone,
  //     "mobilephone": this.mobilePhone,
  //     "indskr_lu_language": this.primaryLanguage ? (this.primaryLanguage.id || '')  : '',
  //     "indskr_lu_specialty": this.primarySpecialty ? (this.primarySpecialty.id || '')  : '',
  //     "indskr_lu_professional_designationid": this.professionalDesignation ? (this.professionalDesignation.id || null) : null,
  //     // "indskr_speaker": this.isSpeaker == 'No' ? false : true,
  //     // "indskr_kol": this.isKOL == 'No' ? false : true,
  //     "emails": this.contactEmailDTO,
  //     "addresses": this.contactAddressDTO,
  //     "accounts": this.contactAccountDTO,
  //   }
  // }

  //For Contact Creation and Editing
  //I don't know why they can't use the same property names
  get contactEmailDTO(): any {
    let emailList = [];

    for (let email of this.emailAddressList) {
      let emailDTO = {
        "indskr_emailaddress": email.emailAddress,
        "indskr_isprimary": email.isPrimary,
      }
      emailList.push(emailDTO);
    }

    return emailList;
  }

  //For Contact Creation and Editing
  //I don't know why they can't use the same property names\
  get contactAddressDTO(): any {
    let addressList = [];

    for (let address of this.addressesList) {
      let addressDTO = {
        "indskr_addressid": address.addressId,
        "indskr_isprimary": address.isPrimary,
      }
      addressList.push(addressDTO);
    }

    return addressList;
  }

  get contactAccountDTO(): any {
    let accountList = [];

    for (let account of this.accountRelationships) {
      let accountDTO = {
        "indskr_accountid": account.accountId,
        "indskr_contactrole": account.contactRoleId,
      }
      accountList.push(accountDTO);
    }

    return accountList;
  }

  /*get rowDTO():Object{
    return {
      "contactid":this.ID,
      "customerAddresses":this.addressesList || null,
      "emailAddresses": this.emailAddressList || null,
      "fax": this.fax,
      "firstname": this.firstName,
      "indskr_isguest": this.isguest,
      "indskr_kol": this.isKOL,
      "indskr_lu_languageid": this.language,
      "indskr_lu_specialtyid": this.speciality,
      "indskr_speaker":this.isSpeaker,
      "indskr_title":this.title,
      "lastname":this.lastName,
      "mobilephone":this.mobilePhone,
      "ownerid":{
        "Id":"02ec1a2a-d83f-e711-811b-480fcfeab991",
        "KeyAttributes":[],
        "LogicalName":"systemuser",
        "Name":"Alok Roy",
        "RowVersion":null
      },
      "statuscode":1,
      "telephone1":this.telephone,
      "indskr_joineddate" : this.join_date,
      "indskr_leftdate" : this.left_date
    }
  }*/

  // mapCallPlans(repCallPlans: ContactRepCallPlanDTO[]) {
  //   this.repCallPlansByContact = [];

  //   for (let repCallPlan of repCallPlans) {
  //     let addObj: ContactRepCallPlan = {
  //       name: repCallPlan.indskr_name,
  //       createDate: repCallPlan.createdon,
  //       productName: repCallPlan.productName,
  //       firstName: repCallPlan.firstName,
  //       lastName: repCallPlan.lastName,
  //       stateCode: repCallPlan.statecode,
  //       emailGoals: repCallPlan.indskr_hoemails,
  //       meetingGoals: repCallPlan.indskr_hocalls,
  //       emailsComplete: repCallPlan.indskr_actualemails || 0,
  //       meetingComplete: repCallPlan.indskr_actualcalls || 0,
  //       emailCompletePercentage: repCallPlan.indskr_actualemails / repCallPlan.indskr_hoemails * 100 || 0,
  //       meetingCompletePercentage: repCallPlan.indskr_actualcalls / repCallPlan.indskr_hocalls * 100 || 0,
  //       startDate: repCallPlan.indskr_startdate,
  //       endDate: repCallPlan.indskr_enddate,
  //       callPlanID: repCallPlan.indskr_customercallplanid,
  //       callPlanTimeFrame: isPast(new Date(parseInt(repCallPlan.indskr_enddate))) ? 'Past' : isFuture(new Date(parseInt(repCallPlan.indskr_startdate))) ? 'Future' : 'Present',
  //       callPlanStatus: repCallPlan.statuscode,
  //       segmentCallPlanName: repCallPlan.segmentCallPlanName,
  //       //segmentName: repCallPlan.segmentName,
  //       // suggestedCalls: repCallPlan.indskr_suggestedcalls || 0,
  //       // suggestedMails: repCallPlan.indskr_suggestedemails || 0,
  //       // totalCompletedEmails: repCallPlan.indskr_totalcompletedemails || 0,
  //       // totalCompletedMeetings: repCallPlan.indskr_totalcompletedmeetings || 0,
  //       // totalPositionEmailGoals: repCallPlan.indskr_totalpositionemailgoals || 0,
  //       // totalPositionMeetingGoals: repCallPlan.indskr_totalpositionmeetinggoals || 0,
  //       // timerRemaining: repCallPlan.indskr_timeremaining,
  //       positionId: repCallPlan._indskr_positionid_value,
  //       positionName : repCallPlan.positionname || ''
  //     };

  //     this.repCallPlansByContact.push(addObj);
  //   }
  // }

  mapActivities(activities: any[], isRawData = false) {
    if (activities && Array.isArray(activities)) {
      if (isRawData) {
        this.activitesByContact = [];
        for (let i = 0; i < activities.length; i++) {
          const rawActivity = activities[i];
          this.activitesByContact.push(new AppointmentActivity(rawActivity));
        }
      } else {
        this.activitesTimeline = activities;
      }
      this.activitesByContact.sort((a, b) => (b.scheduledStart.getTime()) - (a.scheduledStart.getTime()));
    }
  }

  mapEvents(events: EventRegistration[]) {
    this.eventsAndRegistrationsByContact = [];
    if(events && Array.isArray(events)) {
         events.forEach((event: EventRegistration) => {
           let rc: string;
           if(event.responseCapturedOn) {
             rc = event.responseCapturedOn.toString();
           }
           this.eventsAndRegistrationsByContact.push(new EventRegistration(event.eventRegistrationId, event.eventId, event.eventName, event.eventStartDate.toString(), event.eventEndDate.toString(),
                 event.description, event.eventImage, event.registeredBy, event.indskr_ownerid, event.indskr_ownername, event.response, event.registrationresponseId,
                 rc, event.registeredOn.toString(), TrackAction.Download, null, null));
         });
      console.log('Fetched Contact registration events for timeline: ' + this.eventsAndRegistrationsByContact.length);
    }
  }
  mapCheckInEvents(events: EventActivity[]) {
    this.eventsCheckInByContact = [];
    if (events && Array.isArray(events)) {
      events.forEach((event: EventActivity) => {
        this.eventsCheckInByContact.push(new EventActivity(event));
      })
      console.log('Fetched Contact check-in events for timeline: ' + this.eventsCheckInByContact.length);
    }
  }
  mapCompletedEvents(events: EventActivity[]) {
    this.eventsCompletedByContact = [];
    if (events && Array.isArray(events)) {
      events.forEach((event: EventActivity) => {
        this.eventsCompletedByContact.push(new EventActivity(event));
      })
      console.log('Fetched Contact completed events for timeline: ' + this.eventsCompletedByContact.length);
    }
  }

  mapWebsiteAccessLogs(websiteAccessLogs: WebsiteAccessLogs[]) {
    this.websiteAccessLogs = [];
    if (websiteAccessLogs && Array.isArray(websiteAccessLogs)) {
      websiteAccessLogs.forEach((websiteAccessLog: WebsiteAccessLogs) => {
        this.websiteAccessLogs.push(new WebsiteAccessLogs(websiteAccessLog));
      })
      console.log('Fetched Contact completed events for timeline: ' + this.websiteAccessLogs.length);
    }
  }

  /**************************Customer Journey**************************/
  mapCustomerJourneys(response: any) {
    this.customerJourneysTimeline = [];
    if (response && Array.isArray(response.activities)) {
      response.activities.forEach((activity: Activity) => {
        this.customerJourneysTimeline.push(new Activity(activity));
      })
      console.log('Fetched Contact completed events for customer journey: ' + this.customerJourneysTimeline.length);
    }
  }
  /**************************Customer Journey**************************/

  mapScientificInformation(scientificInfo: ScientificInfo) {
    this.scientificInfo = new ScientificInfo([], [], [], [], []);
    if (scientificInfo.publications && Array.isArray(scientificInfo.publications)) {
      this.scientificInfo.publications = [...this.scientificInfo.publications, ...scientificInfo.publications];
    }
    if (scientificInfo.researches && Array.isArray(scientificInfo.researches)) {
      this.scientificInfo.researches = [...this.scientificInfo.researches, ...scientificInfo.researches];
    }
    if (scientificInfo.speakerEngagements && Array.isArray(scientificInfo.speakerEngagements)) {
      this.scientificInfo.speakerEngagements = [...this.scientificInfo.speakerEngagements, ...scientificInfo.speakerEngagements];
    }
    if (scientificInfo.eventHistory && Array.isArray(scientificInfo.eventHistory)) {
      this.scientificInfo.eventHistory = [...this.scientificInfo.eventHistory, ...scientificInfo.eventHistory];
    }
    if (scientificInfo.sessionHistory && Array.isArray(scientificInfo.sessionHistory)) {
      this.scientificInfo.sessionHistory = [...this.scientificInfo.sessionHistory, ...scientificInfo.sessionHistory];
    }
  }

  get contactRawObject() {

    return {
        //----------------- Minimum -------------------
      contactid: this.ID,
      firstname: this.firstName,
      middleName: this.middleName,
      lastname: this.lastName,
      statuscode: this.isVerified ?  0 : 548910000,
    }
  }

  getConcatanatedAddress(address: ContactAddress): string {
      let concatenatedAddress = '';
      this.compositeAddValues.forEach((val, index) => {
        if(address[val] && address[val].length > 0) {
          concatenatedAddress = concatenatedAddress + address[val] + (index + 1 < this.compositeAddValues.length ? ',' : '');
        }
      });
      return concatenatedAddress;
  }

  get conacatanatedAddress() {
    if (this.addressesList.length == 0) return undefined;

    let primaryAddFound: ContactAddress;
    //For each email, check if it is primary
    this.addressesList.forEach(add => {
      if (add.isPrimary) primaryAddFound = add;
    });

    //Do we have a first choice?
    if (primaryAddFound) {
      return primaryAddFound.concatanatedAddress;
    } else {
      //Fallback to any choice
      return this.addressesList[0].concatanatedAddress;
    }
  }
}

export interface Email {
  emailAddressId: string,
  emailAddress: string,
  isPrimary: boolean,
  isVerified: boolean,
  isSelected?: boolean,
  isSelectable?: boolean,
  hasConsented?: boolean,
  appId?: string,
  isNew?: boolean,
}

export interface ContactEmailAddressDTO {
  indskr_email_addressid: string,
  contactId: string,
  emailAddress: string,
  isPrimary: string,
  isSelectable?: boolean,
  isVerified: boolean,
}

export interface ContactAddress {
  city: string;
  state: string;
  postal: string;
  country: string;
  countryCode: string;
  street: string;
  street2: string;
  street3: string;
  region?: string;
  postOfficeBox:string;
  isPrimary:boolean;
  addressId:string;
  compositeAdd:string;
  primaryContact:string;
  fax:string;
  fax2:string;
  telephone1:string;
  telephone2:string;
  isSampleEligible: boolean;
  customerAddressID: string;
  isNew?:boolean;
  concatanatedAddress: string;
  latitude?: string;
  longitude?: string;
}

export interface ContactAddressDTO {
  modifiedon: string,
  city: string,
  indskr_primary: boolean,
  customeraddressid: string,
  contactId: string,
  postalcode: string,
  stateorprovince: string,
  address_line1: string,
  address_line2: string,
  address_line3: string,
  name: string,
  country: string,
  countrycode3: string,
  indskr_availabletill: any,
  indskr_availablefrom: any,
  postalbox: string,
  addresstype: any,
  addresstypeFormattedValue: string,
  primarycontact: string,
  telephone1: string,
  telephone2: string,
  fax: string,
  secondaryfax: string,
  regionname: string,
  geographicclassificationname: string,
  fipscountycode: any,
  issamplingeligible: boolean,
  indskr_indskr_customeraddress_v2id: string,
  _indskr_address_value: string,
  createdon: string,
  indskr_latitude:string,
  indskr_longitude:string
}

export interface AccountRelation{
  name: string,
  accountId: string,
  accountName: string,
  contactRole?: string,
  accountContactAffiliationId?: string,
  startDate?: string,
  endDate?: string,
  createdDate?: string,
  comments?: string,
  contactRoleId?: string,
  directRelation?: string,
  status?: string,
  statecode?: string,
  expandIcon?: string,
  showDetails?: boolean,
  isNew?: boolean,
  isPrimary?: boolean,
  jobTitle?: string,
  indskr_specialty?: string,
  indskr_sanofispecialty?: string,
  indskr_department?: string
}


export interface ContactAccountRelationshipDTO {
  _indskr_accountid_value: string,
  indskr_accountcontactaffiliationid: string,
  indskr_name: string,
  createdon: string,
  indskr_startdate: string,
  indskr_enddate: string,
  contactId: string,
  statecode: string,
  indskr_directrelationshipflag: boolean,
  indskr_comments: string,
  indskr_contactrole: string,
  indskr_contactroleFormattedValue: string,
  statuscode: string,
  statuscodeFormattedValue: string,
  accountname: string,
  isPrimary?: boolean,
  indskr_jobtitle?: string,
  indskr_specialtyFormattedValue?: string,
  indskr_sanofispecialty?: string,
  indskr_department?: string
}

export interface ContactRelation{
  name: string,
  contactRelationshipId: string,
  contactId: string,
  contactName:string,
  relationship:string,
  relatedContactId: string,
  createdDate:string,
  statecode: string,
  expandIcon:string,
  showDetails:boolean,
}

export interface ContactContactRelationshipDTO {
  indskr_contactrelationshipid: string,
  indskr_name: string,
  createdon: string,
  statecode: string,
  indskr_relationship: string,
  indskr_relationshipFormattedValue: string,
  indskr_relatedcontactname: string,
  indskr_relatedcontactid: string,
  contactId: string
}

export interface ProductSegmentation{
  intimacy:string;
  intimacyId: string;
  productName:string;
  productId: string;
  productRatingId:string;
  segmentation:string;
  segmentationId: string;
  marketPotential: string;
}

export interface ContactProductSegmentationDTO {
  indskr_productratingid: string,
  contactId: string,
  _indskr_marketpotential_new_value: any,
  segmentation: string,
  segmentationid: string,
  _indskr_product_value: string,
  intimacy: string,
  intimacyid: string,
  productname: string
}

export interface CustomerPosition{
  positionId: string,
  position: string,
}

export interface ContactCustomerPositionDTO {
  _indskr_positionid_value: string,
  positionname: string,
  parentpositionid: string,
  parentpositionname: string,
  contactid: string
}

export interface ContactRepCallPlan{
  name: string,
  productName: string,
  segmentCallPlanName: string,
  //segmentName: string,
  firstName: string,
  lastName: string,
  stateCode: string,
  emailGoals: number,
  meetingGoals: number,
  emailsComplete: number,
  meetingComplete: number,
  emailCompletePercentage: number,
  meetingCompletePercentage: number,
  //totalCompletedEmails: number,
  //totalCompletedMeetings: number,
  //totalPositionEmailGoals: number,
  //totalPositionMeetingGoals: number,
  //suggestedCalls: number,
  //suggestedMails: number,
  //timerRemaining: string,
  startDate: string,
  endDate: string,
  callPlanID:string,
  createDate: string,
  callPlanTimeFrame: string,
  callPlanStatus: any,
  positionId: string,
  positionName: string
}

export interface ContactAllocation{
  sampleSKUId: string;
  sampleSKUName: string;
  contactId: string;
  contactName: string;
}

export interface ContactRepCallPlanDTO {
  indskr_customercallplanid: string,
  _indskr_cycleplanid_value: string,
  segmentCallPlanName: string,
  indskr_name: string,
  _indskr_customerid_value: string,
  indskr_totalcompletedemails: number,
  _indskr_productid_value: string,
  indskr_hocalls: number,
  indskr_hoemails: number,
  statecode: string,
  statuscode: string,
  indskr_state: string,
  indskr_emailcompletionpercent: number,
  indskr_totalcompletedmeetings: number,
  indskr_startdate: string,
  indskr_enddate: string,
  createdon: string,
  _indskr_segment_v2_value: string,
  indskr_meetingcompletionpercent: number,
  userid: string,
  parentposid: string,
  _indskr_positionid_value: string,
  positionname: string,
  username: string,
  parentpositionname: string,
  indskr_suggestedcalls: number,
  indskr_suggestedemails: number,
  productName: string,
  segmentName: string,
  firstName: string,
  lastName: string,
  indskr_isrepcallplan: boolean,
  indskr_timeremaining: string,
  indskr_totalpositionmeetinggoals: number,
  indskr_totalpositionemailgoals: number,
  indskr_actualcalls: number,
  indskr_actualemails: number,
  callPlanAccounts: string[]
}

export interface Specialty {
  id: string,
  name?: string,
  code?: string,
  createdon?: string,
  isprimary?: boolean,
  indskr_sadeptnm?: string
}

export interface Language {
  id: string,
  name: string,
  createdon?: string,
}


export interface ProfessionalDesignation {
  id: string;
  designation: string;

  // constructor(raw: any) {
  //   this.id = raw['indskr_lu_professional_designationid'] || "";
  //   this.designation = raw['indskr_professionaldesignation'] || "";

  // }
}

export interface SelectableLinkedEntity {
  selId: string,
  isPrimary: boolean,
  selName: string,
  isDisableChkBox: boolean,
  isChecked: boolean,
}

export interface ContactDTO {
//----------------- Minimum -------------------
  indskr_iseventparticipant?:boolean;
  msevtmgt_eventid?: string,
  contactid: string,
  firstname: string,
  indskr_isguest?: boolean,
//----------------- Base -------------------
  indskr_externalid?: string,
  gendercode?: number,
  indskr_hcpid?: string,
  indskr_title?: number,
  title?: string,
  'indskr_title@OData.Community.Display.V1.FormattedValue'?: string,
  '@odata.etag'?: string,
  'indskr_isguest@OData.Community.Display.V1.FormattedValue'?: string,
  'indskr_kol@OData.Community.Display.V1.FormattedValue'?: string,
  indskr_kol?: boolean,
  'statuscode@OData.Community.Display.V1.FormattedValue'?: string,
  statuscode?: number,
  fullname?: string,
  '_ownerid_value@OData.Community.Display.V1.FormattedValue'?: string,
  '_ownerid_value@Microsoft.Dynamics.CRM.associatednavigationproperty'?: string,
  '_ownerid_value@Microsoft.Dynamics.CRM.lookuplogicalname'?: string,
  '_ownerid_value'?: string,
  'indskr_speaker@OData.Community.Display.V1.FormattedValue'?: string,
  indskr_speaker?: boolean,
  lastname?: string,
  telephone1?: string,
  mobilephone?: string,
  userid?: string,
  username?: string,
  track_action?: number,
  customerPositions?: ContactCustomerPositionDTO[],
  emailAddresses?: ContactEmailAddressDTO[],
  customerAddresses?: ContactAddressDTO[],
  productSegmentations?: ContactProductSegmentationDTO[],
  contactRelationships?: ContactContactRelationshipDTO[],
  accountRelationships?: ContactAccountRelationshipDTO[],
  repCallPlans?: ContactRepCallPlanDTO[],
  lastSyncedTime?: number,
//----------------- Delta Sync -------------------
  indskr_action?: number,
  'indskr_action@OData.Community.Display.V1.FormattedValue'?: string,
//----------------- Config Fields -------------------
  jobtitle?: string,
  indskr_sanofispecialty?: string,
  indskr_department?: string,
  indskr_specialty?: string,
  middlename?: string,
  'track_action@OData.Community.Display.V1.FormattedValue'?: string,
//----------------- Online Meeting Contact -------------------
  indskr_joinstatus?: ContactMeetingState,
  indskr_joineddate?: string,
  indskr_leftdate?: string,
  indskr_isremote?: boolean,
  indskr_leftmanually?: boolean,
  scientificInformation?: ScientificInfo,
  ind_grantrecipient?: boolean,
  ind_investigator?: boolean,
  indskr_alternatephone1?: string,
  indskr_alternatephone2?: string,
  indskr_alternatephone3?: string,
  modifiedon?:string,
  createdon?:string,
  interactionDate?: string,
  interactionType?: string,
  indskr_contactsourcetype?: string,
  indskr_mdmid?: string,
  indskr_contacttype?: number,
  tagLabels?: string
}

export enum MEDICAL_FEATURES {
  M_PROFILE = 'M_PROFILE', M_MARKETING = 'M_MARKETING', FM_PROFILE = 'FM_PROFILE', FM_MARKETING = 'FM_MARKETING'
}

export enum MedicalInsightCategory {
  "Device" = 600000001,
  "CompetitiveProduct" = 600000000,
  "Disease/Diagnosis" = 600000002,
  "Sanofi Product (Efficacy/Safety/Other)" = 808210000,
  "Guidelines/treatment protocols" = 808210001,
  "Benefit/Risk" = 808210002,
  "Research/Study Design" = 808210003,
  "Healthcare Environment/Policy" = 808210004,
  "Patient – Journey/Access/Advocacy" = 808210005,
  "Scientific Data" = 808210006
}

export class ContactMedicalInsight {
  public ID:string;
  public contactId:string;
  public contactNameString: string;
  public dateCreated:Date;
  public description:string;
  public insightCategory:MedicalInsightCategory;
  public insightCategoryNameString:string;
  public name:string;
  public priority:boolean;
  public productCatalogId:string;
  public productCatalogNameString:string;
  public specialityId:string;
  public specialityNameString:string;
  public summary:string;
  public ownerId:string;
  public ownerNameString:string;
  public statecode:number;
  public statuscode:number;
  public statusReason:string;

  constructor(raw: any){
    this.ID = raw['indskr_customerinteractioninsightsid'] ? raw['indskr_customerinteractioninsightsid'] : '';
    this.contactId = raw['_indskr_customer_value'] ? raw['_indskr_customer_value'] : '';
    this.contactNameString = raw['_indskr_customer_value_Formatted'] ? raw['_indskr_customer_value_Formatted'] : '';
    this.dateCreated = (raw['indskr_datecreated'] ? new Date(raw['indskr_datecreated']) : null);
    this.description = raw['indskr_description'] ? raw['indskr_description'] : '';
    this.insightCategory = raw['indskr_insightcategory'] ? raw['indskr_insightcategory'] : '';
    this.insightCategoryNameString = raw['indskr_insightcategory_Formatted'] ? raw['indskr_insightcategory_Formatted'] : '';
    this.name = raw['indskr_name'] ? raw['indskr_name'] : '';
    this.priority = raw['indskr_priority'];
    this.productCatalogId = raw['_indskr_productcatalog_value'] ? raw['_indskr_productcatalog_value'] : '';
    this.productCatalogNameString = raw['_indskr_productcatalog_value_Formatted'] ? raw['_indskr_productcatalog_value_Formatted'] : '';
    this.specialityId = raw['_indskr_specialty_value'] ? raw['_indskr_specialty_value'] : '';
    this.specialityNameString = raw['_indskr_specialty_value_Formatted'] ? raw['_indskr_specialty_value_Formatted'] : '';
    this.summary = raw['indskr_summary'] ? raw['indskr_summary'] : '';
    this.ownerId = raw['_ownerid_value'] ? raw['_ownerid_value'] : '';
    this.ownerNameString = raw['_ownerid_value_Formatted'] ? raw['_ownerid_value_Formatted'] : '';
    this.statecode = raw['statecode'];
    this.statuscode = raw['statuscode'];
    this.statusReason = raw['statuscode_Formatted'] ? raw['statuscode_Formatted'] : null;
  }

  public getServiceDTO():any {
    let payload = {
      //contactId: this.contactId,
      indskr_datecreated: this.dateCreated,
      indskr_description: this.description,
      indskr_insightcategory: this.insightCategory,
      indskr_name: this.name,
      indskr_priority: this.priority,
      indskr_productcatalog: this.productCatalogId,
      indskr_specialty: this.specialityId,
      indskr_summary: this.summary,
    };
    if(this.ID){
      payload['indskr_customerinteractioninsightsid'] = this.ID;
    }
    if(this.contactId){
      payload['contactId'] = this.contactId;
    }
    return payload;
  }

  public getOfflineDataDTO():any {
    return {
      "indskr_customerinteractioninsightsid": this.ID,
      "contactid": this.contactId,
      "_indskr_customer_value": this.contactId,
      "_indskr_customer_value_Formatted": this.contactNameString,
      "indskr_datecreated": this.dateCreated,
      "indskr_description": this.description,
      "indskr_insightcategory": this.insightCategory,
      "indskr_insightcategory_Formatted": this.insightCategoryNameString,
      "indskr_name": this.name,
      "indskr_priority": this.priority,
      "_indskr_productcatalog_value": this.productCatalogId,
      "_indskr_productcatalog_value_Formatted": this.productCatalogNameString,
      "_indskr_specialty_value": this.specialityId,
      "_indskr_specialty_value_Formatted": this.specialityNameString,
      "indskr_summary": this.summary,
      "_ownerid_value": this.ownerId,
      "_ownerid_value_Formatted": this.ownerNameString,
      "statecode": this.statecode,
      "statuscode": this.statuscode,
      "statuscode_Formatted": this.statusReason,
    };
  }
}

export class DCRApprovalReq {

  public ID:string;
  public name:string;
  public ownerId:string;
  public ownerName:string;
  public createdOn:Date;
  public statuscode:number;
  public statusString:string;
  public statecode:number;
  public approvalActivityId:string;
  public approvalStatus:string;
  public isApprovalRecord:boolean;
  public indskr_attachment1: string;
  public indskr_attachment2: string;

  constructor(raw:any){
    this.ID = raw['indskr_contactcrid'] ? raw['indskr_contactcrid'] : '';
    this.name = raw['indskr_lastname'] ? raw['indskr_lastname'] : '';
    this.ownerId = raw['_ownerid_value'] ? raw['_ownerid_value'] : '';
    this.ownerName = raw['_ownerid_value_Formatted'] ? raw['_ownerid_value_Formatted'] : '';
    this.createdOn = raw['createdon'] ? new Date(raw['createdon']) : null;
    this.statuscode = raw['statuscode'] ? raw['statuscode'] : null;
    this.statusString = raw['statuscode_Formatted'] ? raw['statuscode_Formatted'] : null;
    this.statecode = raw['statecode'] ? raw['statecode'] : null;
    this.approvalActivityId = raw['aa.indskr_approvalactivityid'] ? raw['aa.indskr_approvalactivityid'] : '';
    this.approvalStatus = raw['aa.statuscode_Formatted'] ? raw['aa.statuscode_Formatted'] : '';
    this.isApprovalRecord = raw['isApprovalRecord'] ? raw['isApprovalRecord'] : false;
    this.indskr_attachment1 = raw['indskr_attachment1'] ? raw['indskr_attachment1'] : '';
    this.indskr_attachment2 = raw['indskr_attachment2'] ? raw['indskr_attachment2'] : '';
  }
}

export enum ContactsModel {
  MY_CONTACTS_TAB = "myContacts",
  CHANGE_REQUESTS_TAB = "changeRequests",
  APPROVALS_TAB = "approvalRequests"
}
