import { Component } from "@angular/core";
import { SignaturePad } from "angular2-signaturepad/signature-pad";
import { ViewChild } from "@angular/core";
import {
  LoadingController,
  NavController
} from "@ionic/angular";
import { DeviceService } from "@omni/services/device/device.service";
import { RepServices } from "../../../data-services/rep/rep.services";
import {
  TrackingEventNames,
  TrackService
} from "@omni/services/logging/tracking.service";
import { ConsentService } from "@omni/services/consent/consent.service";
import { ConsentTerm } from "../../../classes/consent/consent-term.class";
import { Channel, ChannelValue } from "../../../classes/consent/channel.class";
import {
  NotificationService,
  ToastStyle
} from "@omni/services/notification/notification.service";
import {
  NavigationService,
  ChildNavNames,
  PageName
} from "@omni/services/navigation/navigation.service";
import { UIService } from "@omni/services/ui/ui.service";
import { AuthenticationService } from "@omni/services/authentication.service";
import { TranslateService } from "@ngx-translate/core";
import { AlertService } from "@omni/services/alert/alert.service";
import { PopoverController } from '@ionic/angular';


/**
 * Generated class for the CaptureSignatureModalComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: "consent-signature-modal",
  templateUrl: "consent-signature-modal.html",
  styleUrls:['consent-signature-modal.scss']
})
export class ConsentSignatureModalComponent {
  @ViewChild('sigPad', {static:true}) signaturePad: SignaturePad;
  public submitEnable: boolean = false;
  private isSigned: boolean = false;
  public signaturePadOptions: Object = {
    minWidth: 0.5,
    canvasWidth: 300,
    canvasHeight: 150
  };
  public clearEnable = false;
  constructor(
    public authenticationService: AuthenticationService,
    public loadingCtrl: LoadingController,
    public notificationService: NotificationService,
    private device: DeviceService,
    public repService: RepServices,
    private alertService: AlertService,
    public trackingService: TrackService,
    public consentService: ConsentService,
    public navCtrl: NavController,
    private navService: NavigationService,
    private uiService: UIService,
    private translate:TranslateService,
    public popoverCtrl: PopoverController,
  ) {
  }

  drawStart() {
    console.log("detected signature pad draw start");
    this.clearEnable = true;
  }
  drawComplete() {
    console.log("draw end", this.signaturePad, this.signaturePad.toDataURL());
    this.isSigned = true;
    this.submitEnable = true;
  }
  clearDraw() {
    this.signaturePad.clear();
    this.submitEnable = false;
    this.clearEnable = false;
  }
  closePopover() {
    this.popoverCtrl.dismiss({ completedFlow: false });
  }

  openConfirmationPopup() {
    if(this.navService.getActiveChildNavName() == ChildNavNames.EventsToolNavigation){
      this.alertService.showAlert({
        title: this.translate.instant('CONSENT_SUB_SIGN'),
        message: this.translate.instant('PARTICIPANTS_SIGNATURE_CONFIRMATION_MESSAGE')}, this.translate.instant('SUBMIT')
      ).then (res => {
        if(res.role == "ok") {
          this.submitDraw();
        }
      });
    } else {
    this.alertService.showAlert({
      title: this.translate.instant('CONSENT_CONFIRM'),
      message: this.consentService.selectedConsentTerm.value.indskr_confirmationmessage?this.consentService.selectedConsentTerm.value.indskr_confirmationmessage: this.translate.instant('CONSENT_CONFIRMATION_MESSAGE_DEFAULT')
    },  this.translate.instant('SUBMIT')
    ).then (res => {
      if(res.role == "ok") {
        this.submitDraw();
      }
    });
  }
  }

  async submitDraw () {
    let signatureData = this.signaturePad.toDataURL();
    if (this.navService.getActiveChildNavViewPageName() == PageName.EventDetailsPageComponent) {
      await this.popoverCtrl.dismiss({completedFlow: true, signatureData: signatureData}); //pass to the parent components
      return
    }
    let loader = await this.loadingCtrl.create();
    const currentConsentTerm: ConsentTerm = JSON.parse(JSON.stringify(this.consentService.selectedConsentTerm.value));
    delete currentConsentTerm._id;
    delete currentConsentTerm._rev;
    if (this.isSigned) {
      currentConsentTerm.indskr_signature = signatureData;
    } else {
      currentConsentTerm.indskr_signature = "";
    }
    if(this.authenticationService.user.isProductConsent){
      currentConsentTerm.products = currentConsentTerm.products.filter(
        product => product.isChecked
      );
    }

    let channels: Channel[] = [];
    currentConsentTerm.channels.forEach(channel => {
      delete channel._id;
      delete channel._rev;
      let values: ChannelValue[] = [];
      if (channel.values) {
        channel.values.forEach(value => {
          if (value.isChecked) values.push(value);
        });
        if (values.length) {
          channel.values = values;
          channels.push(channel);
        }
      }
    });
    currentConsentTerm.createdon = new Date().getTime();
    currentConsentTerm.indskr_positionid = this.authenticationService.user.xPositionID;
    currentConsentTerm.userid = this.authenticationService.user.xSystemUserID;

    if ((this.authenticationService.user.isProductConsent && currentConsentTerm.products.length && channels && channels.length) || (!this.authenticationService.user.isProductConsent && channels && channels.length)) {

      this.consentService.currentConsentTerm.next(currentConsentTerm);

      currentConsentTerm.channels = channels;

      // To check whether Product Consent flag is false for this Business Unit
      // then passed empty Product array so that in Dynamics Product value should be blank in Optin Records
      currentConsentTerm.products = this.authenticationService.user.isProductConsent ? currentConsentTerm.products : [];

      await loader.present();
      
      await this.trackingService.tracking(
        "Submit Signature",
        TrackingEventNames.CONSENT
      );
      this.consentService.createConsentTerm(currentConsentTerm).then(
        async () => {
          await loader.dismiss();
          await this.popoverCtrl.dismiss({completedFlow: true});
        },
        async error => {
          await loader.dismiss();
          //await this.sampleDataService.syncSamples();
          await this.popoverCtrl.dismiss({completedFlow: false});
        }
      );
    } else {
      if (this.authenticationService.user.isProductConsent) {
        this.notificationService.notify(
          this.translate.instant('CONSENT_CANT_SUBMITTED'),
          "Consent Signature",
          "top",
          ToastStyle.DANGER,
          3000,
          true
        );
      } else {
        this.notificationService.notify(
          this.translate.instant('CONSENT_CANT_SUBMITTED_PRODUCT_CONSENT_FLAG_OFF'),
          "Consent Signature",
          "top",
          ToastStyle.DANGER,
          3000,
          true
        );
      }
    }
  };
}
