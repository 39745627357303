
import { Component, ViewChild } from '@angular/core';
import {  IonNav, NavParams } from '@ionic/angular';
import { Events } from '@omni/events';
import { UIService } from '../../../services/ui/ui.service';
import { DeviceService } from '../../../services/device/device.service';
import {ChildNavNames, NavigationService, PageName} from "../../../services/navigation/navigation.service";
import { CoachingReportService } from '../../../services/coaching/coaching.report.service';
import { Report } from '../../../classes/coaching/report.class';
import { CoachingDetailsComponent } from '../coaching-details/coaching-details';
import { Subject } from 'rxjs';
import { ActivityService } from '@omni/services/activity/activity.service';
import { AgendaFooterService, AgendaFooterView } from '@omni/services/footer/agenda-footer.service';
import { FeatureActionsService } from '@omni/services/feature-actions/feature-actions.service';

/**
 * Generated class for the CoachingComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'coaching-home[base-page]',
  templateUrl: 'coaching-home.html',
  styleUrls:['coaching-home.scss']
})

export class CoachingComponent {

  @ViewChild("detailsPage", {static:true})
  private navCtrl: IonNav;
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private uiService: UIService,
    public device: DeviceService,
    public navService: NavigationService,
    private activityService: ActivityService,
    public coachingService:CoachingReportService,
    private events: Events,
    public navParams: NavParams,
    private agendaFooterService: AgendaFooterService,
    private faService: FeatureActionsService,
  ) {
  }

  ngOnInit() {
    this.navService.initRightPaneChildNav(this.navCtrl, ChildNavNames.CoachingDetailsNavigation, PageName.CoachingDetailsComponent, false, {
      'deviceString': this.device.deviceFlags.ios ? 'ios' : 'other'
    });
    this.uiService.showFieldCoaching = false;

    if(this.navParams.data) {
      if (this.navParams.data.isOpenCoachingFromGenee) {
        const isOpenCoachingFromGenee = this.navParams.data.isOpenCoachingFromGenee;
        if (isOpenCoachingFromGenee) {
          const coachingId = this.navParams.data.coachingId;
          const isTeamCoaching = this.navParams.data.isTeamCoaching;
          const requestParams = {'coachingId': coachingId, 'isTeamCoaching': isTeamCoaching};
          setTimeout(() => {
            this.events.publish('open-coaching-from-genee', requestParams);
          }, 30);
        }
      }
    }
  }

  onCloseModal = (event) => {
    this.uiService.showNewActivity = false;
    this.uiService.activeView = this.uiService.prevView;
    this.navService.popToRootWithPageTracking().then(() => {
      if (this.navService.getActiveChildNavViewPageName() === PageName.NothingSelectedView && this.uiService.showRightPane) {
        this.uiService.showRightPane = false;
      }

      // Short call feature requires to bring the tab back to short call home
      if (this.faService.isShortCallLauncherEnabled) {
        this.agendaFooterService.initButtons(AgendaFooterView.ShortCallLauncher);
        this.uiService.setAgendaTab('shortCallHome');
      }

      this.activityService.selectedActivity = this.activityService.selectedActivityAtHome;
      this.events.publish("tools-back-clicked", PageName.CoachingComponent);
    });
  };

  onNewCoaching = (event) => {
    console.log("received request for new coaching report");
    this.coachingService.saveNewReport().then(() => {
      if (this.navService.getActiveChildNavViewPageName() !== PageName.CoachingDetailsComponent)
        this.navService.pushChildNavPageWithPageTracking(CoachingDetailsComponent, PageName.CoachingDetailsComponent, PageName.CoachingComponent);
        this.navService.setChildNavRightPaneView(true);
    });
  }

  async onCoachingReportSelection(report: Report) {
    if ((report.statuscode === 548910001 || report.statuscode === 548910002 || report.statuscode === 548910003) && !this.coachingService.isOffline(report)) {
      await this.coachingService.checkCoachingOnline(report);
    } else {
      this.coachingService.selectedReport.next(report);
    }
    if (report.statuscode === 1) {
      await this.checkDeactivatedTemplate(report);
      if (this.coachingService.activeTab === 'myCoaching') {
        this.navService.popToRootChildNavPageWithPageTracking();
        this.navService.setChildNavRightPaneView(false);
        return;
      }
    }

    if (this.navService.getActiveChildNavViewPageName() !== PageName.CoachingDetailsComponent)
      await this.navService.pushChildNavPageWithPageTracking(CoachingDetailsComponent, PageName.CoachingDetailsComponent, PageName.CoachingComponent);
    /*if (this.navService.getActiveChildNavViewPageName() === PageName.CoachingDetailsComponent){
      await this.navService.popChildNavPageWithPageTracking()
      await this.navService.pushChildNavPageWithPageTracking(CoachingDetailsComponent, PageName.CoachingDetailsComponent, PageName.CoachingComponent);
    }*/
    this.navService.setChildNavRightPaneView(true);
  }

  async checkDeactivatedTemplate(report: Report) {
    if (!report.indskr_coachingtemplateid || !report.indskr_coachingfor_value) return;
    if (report.indskr_coachingtemplateid) {
      let templateUserMap = this.coachingService.coachingTemplatesUserMap.find(uTMap => uTMap.userId === report.indskr_coachingfor_value);
      if (templateUserMap) {
        let index = templateUserMap.coachingTemplateIds.indexOf(report.indskr_coachingtemplateid);
        if (index < 0) {
          report.indskr_coachingtemplateid = "";
          report.indskr_coachingtemplateidname = "";
          await this.coachingService.updateCoachingFor(report);
        }
      }
    }
  }

  ngOnDestroy() {
    if(!this.activityService.selectedActivity && this.device.isMobileDevice) {
      this.uiService.showRightPane = false;
    }
    this.coachingService.selectedReport.next(null);
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    this.navService.popChildNavCtrlFromStack(ChildNavNames.CoachingDetailsNavigation);
  }

}

