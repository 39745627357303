import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { IonContent, ModalController } from '@ionic/angular';
import { UIService } from '@omni/services/ui/ui.service';
import { DeviceService } from '@omni/services/device/device.service';
import { AuthenticationService } from '@omni/services/authentication.service';
import { VeevaWidgetConfig } from '@omni/models/veeva.widget.config';
// import { LaunchDarklyProvider } from '@omni/providers/launch-darkly/launch-darkly';
import { FooterService } from '@omni/services/footer/footer.service';
import { VeevaWidgetService } from '@omni/services/veeva-widget/veeva-widget.service';

@Component({
  selector: 'contact-veeva-widget',
  templateUrl: './contact-veeva-widget.html',
  styleUrls: ['./contact-veeva-widget.scss'],
})
export class ContactVeevaWidgetComponent implements OnInit {

  public customerName: string;
  public widgetHtml = 'Please wait...';
  private widgetElement: HTMLElement;

  @ViewChild(IonContent, { static: true, read: ElementRef }) private content: ElementRef<HTMLElement>;

  constructor(public uiService: UIService,
    private modalCtrl: ModalController,
    private device: DeviceService,
    public footerService: FooterService,
    private authService: AuthenticationService,
    public veeva: VeevaWidgetService,
    // private launchDarkly: LaunchDarklyProvider
    ) {
    this.initializeWidget();
  }

  private async initializeWidget() {
    const config = this.veeva.config;
    let widgetConfig: any[] = undefined;
    let events: string[] = [];
    let buNavigationProp: string;

    switch (this.veeva.widgetType) {
      case 'VEEVA_PROFILE':
        widgetConfig = [
          'veeva-network-profile-widget',
          ['widget-id', config.profileWidgetId],
          ['widget-name', config.profileWidgetName],
          ['auth-domain', config.profileWidgetAuthDomain],
          ['identifier', this.veeva.widgetData],
          // ['hold-initialization', 'true']
        ];
        events = [/*'select', */'dcr-presubmit', 'dcr-submitted'];
        break;
      case 'VEEVA_MY_REQUESTS':
        widgetConfig = [
          'veeva-network-myrequests-widget',
          ['widget-id', config.myRequestsWidgetId],
          ['widget-name', config.myRequestsWidgetName],
          ['auth-domain', config.myRequestsWidgetAuthDomain],
        ];
        break;
      case 'VEEVA_CONTACT_SEARCH':
        widgetConfig = [
          'veeva-network-search-widget',
          ['widget-id', config.hcpSearchWidgetId],
          ['widget-name', config.hcpSearchWidgetName],
          ['auth-domain', config.hcpSearchWidgetAuthDomain],
          ['enriched-results', 'true'],
          ['preset-filter', 'hcp.hcp_status__v=A'],
        ];
        events = ['select', 'select-failed', 'close-widget', 'add-request-submitted', 'add-request-process-timeout', 'add-request-presubmit', 'add-request-create-failed'];
        break;
      case 'VEEVA_ACCOUNT_SEARCH':
        widgetConfig = [
          'veeva-network-search-widget',
          ['widget-id', config.hcoSearchWidgetId],
          ['widget-name', config.hcoSearchWidgetName],
          ['auth-domain', config.hcoSearchWidgetAuthDomain],
          ['enriched-results', 'true'],
          ['preset-filter', 'hco.hco_status__v=A'],
        ];
        events = ['select', 'select-failed', 'close-widget', 'add-request-submitted', 'add-request-process-timeout', 'add-request-presubmit', 'add-request-create-failed'];
        break;
    }

    let widgetElem = document.createElement(widgetConfig[0]);
    for(let [key, value] of widgetConfig.slice(1)) {
      widgetElem.setAttribute(key, value);
    }

    for (let eventName of events) {
      let newEventName = `veeva-network:${widgetConfig[2][1]}:${eventName}`;
      widgetElem.addEventListener(newEventName, ((eventName) => (event) => {
        const myEvent = new CustomEvent(`veeva-network-${eventName}`, {
          detail: {
            widgetType: widgetConfig[0],
            widgetName: widgetConfig[2][1],
            widget: widgetElem,
            ...(event.detail || {}),
          },
          bubbles: true,
          cancelable: true,
          composed: false,
        });
        console.log(myEvent);
        window.dispatchEvent(myEvent);
    })(eventName));
  }
  this.widgetElement = widgetElem;

  // this.content.el.appendChild(widgetElem);

  //   // this.widgetHtml = `<${widgetConfig[0]} ${widgetConfig.slice(1).map(x => `${x[0]}="${x[1]}"`).join(' ')}></${widgetConfig[0]}>`;
  //   setTimeout(() => {
  //     document.getElementById('searchWidget').addEventListener('veeva-network:OmnipresenceSearchWidget:select', (event) => this.createVeevaEntity(event));
  // }, 2000);

}

  ngOnInit() {
    this.content.nativeElement.innerHTML = '';
    this.content.nativeElement.appendChild(this.widgetElement);

    if (this.veeva.widgetType == 'VEEVA_PROFILE') {
      // setTimeout(() => {
      //   const profileWidget = document.getElementById('profileWidget');
      //   // @ts-expect-error
      //   profileWidget.start(this.widgetData);
      // }, 200);
    } else {
      setTimeout(() => {
        this.widgetElement
          ?.shadowRoot
          ?.querySelector(".veeva-network-page-slider__page")
          ?.shadowRoot
          ?.querySelector(".veeva-network-search-home__footer")
          ?.setAttribute("style", "display:none");
      }, 200);
    }
  }

  public onPageTitleControlClick(id: string) {
    switch (id) {
      case 'close':
        try {
          (this.widgetElement as any)?.close();
          // if (this.veeva.widgetType == 'VEEVA_PROFILE') {
          //   // @ts-expect-error
          //   document.getElementById('profileWidget').close();
          // } else {
          //   // @ts-expect-error
          //   document.getElementById('searchWidget').close();
          // }
          } catch (err) {
            console.log("widget close error : ", err);
          }
          // this.launchDarkly.initialize();
            // this.launchDarkly.refreshUser({
            //   "key": this.authService.user.userID
            // });
        this.modalCtrl.dismiss().then(() => {
          if (this.device.isMobileDevice) this.device.unlockDeviceOrientation();
        });
        break;
      default:
        console.log('Unhandled switch case statement');
        break;
    }
  }
}
