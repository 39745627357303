import { IShipmentReason } from './../../interfaces/allocation/allocation.shared.interface';
import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { AuthenticationService } from "../../services/authentication.service";
import { AllocationTransferForm } from "../../classes/sample/allocation-transfer.class";
import { EntitySyncInfo, EntityNames, DeltaService } from "../delta/delta.service";
import { AllocationTransferService } from "../../services/sample/allocation-transfer.service";
import { Endpoints } from "../../../config/endpoints.config";
import { FeatureActionsMap } from "../../classes/authentication/user.class";
import { IrawAccompainedUser } from "../../classes/activity/accompained-user.class";
import { NotificationService, ToastStyle } from "@omni/services/notification/notification.service";
import { TranslateService } from '@ngx-translate/core';
import { IAllocationTransfer, IAllocationTransferCreateDTO, IAllocationTransferCreateResponseDTO } from "../../interfaces/allocation/allocation-transfer.interface";
import { ShipmentStatus, TransferType } from '../../enums/allocation/allocation.enum';

@Injectable({
  providedIn: 'root'
})
export class AllocationTransferDataService {
    constructor(
        private http: HttpClient,
        private authService: AuthenticationService,
        private deltaService: DeltaService,
        private allocTransferService: AllocationTransferService,
        private notificationService: NotificationService,
        private translate: TranslateService
    ) {}

    async getTransferReasons(loadFromDbOnly = false) {
        if (this.authService.hasFeatureAction(FeatureActionsMap.ALLOCATION_TRANSFER)) {
            if (loadFromDbOnly) {
                await this.allocTransferService.loadFromDBAndMapTransferReasons();
            } else {
                let url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.alloc_transfer.ALLOC_TRANSFER_REASONS;
                const transferReasonSyncInfo: EntitySyncInfo = {
                    entityName: EntityNames.allocTransferReason,
                    totalFailed: 0,
                    totalSynced: 0,
                    errors: [],
                    syncStatus: true
                };

                let headers: HttpHeaders = new HttpHeaders();
                headers = headers.set('Authorization', 'Bearer ' + this.authService.access)
                .set('Sync-Service', 'true');

                try {
                    const response: IShipmentReason[]  = await this.http.get<IShipmentReason[]>(url, { headers }).toPromise();
                    if (Array.isArray(response)) {
                        transferReasonSyncInfo.totalSynced = response.length;
                    }
                    await this.allocTransferService.mapTransferReasons(response);
                } catch (error) {
                    console.error('AllocationTransferDataService: getTransferReasons: ', error);
                    this.deltaService.addSyncErrorToEntitySyncInfo(transferReasonSyncInfo, url, error);
                    await this.allocTransferService.loadFromDBAndMapTransferReasons();
                }
                this.deltaService.addEntitySyncInfo(transferReasonSyncInfo);
            }
        }
    }

    async getAllocationTransferUsers(loadFromDbOnly = false): Promise<void> {
        if (this.authService.hasFeatureAction(FeatureActionsMap.ALLOCATION_TRANSFER)) {
            if (loadFromDbOnly) {
                await this.allocTransferService.loadFromDBAndMapTransferUsers();
            } else {
                const url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.sample.GET_ALLOCATION_TRANSFER_USERS;
                const allocationTransferSyncInfo: EntitySyncInfo = {
                    entityName: EntityNames.allocTransferUser,
                    totalFailed: 0,
                    totalSynced: 0,
                    errors: [],
                    syncStatus: true
                };

                try {
                    let headers: HttpHeaders = Endpoints.GLOBAL_SYNC_HEADER.headers;
                    headers = headers.set('X-SystemUserId', this.authService.user.systemUserID);

                    let response: IrawAccompainedUser[] = await this.http.get<IrawAccompainedUser[]>(url, { headers }).toPromise();
                    if (Array.isArray(response)) {
                        allocationTransferSyncInfo.totalSynced = response.length;
                        await this.allocTransferService.mapAllocationTransferUsers(response);
                    }
                } catch (error) {
                    this.deltaService.addSyncErrorToEntitySyncInfo(allocationTransferSyncInfo, url, error);
                    await this.allocTransferService.loadFromDBAndMapTransferUsers();
                }
                this.deltaService.addEntitySyncInfo(allocationTransferSyncInfo);
            }
        }
    }

    async transfer(form: AllocationTransferForm): Promise<IAllocationTransfer> {
        if (this.authService.hasFeatureAction(FeatureActionsMap.ALLOCATION_TRANSFER)) {
            let url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.alloc_transfer.ALLOC_TRANSFER;
            const transferDTO: IAllocationTransferCreateDTO = {
                indskr_quantityshipped: form.quantityTransferred,
                indskr_reasonfortransfer: form.reason.value,
                indskr_comments: form.comments,
                indskr_shipmentdate: form.shipmentDate.getTime().toString(),
                indskr_user: form.user.id,
                indskr_lotid: form.lot.id,
                indskr_skuid: form.sku.id,
                indskr_shipmentstatus: this.authService.user.approvalForAllocationTransfer ? ShipmentStatus.Draft : ShipmentStatus.Shipped,
                indskr_transfertype: TransferType.AllocationTransfer,
                indskr_externalid: form.external_id,
            };

            let options = Endpoints.alloc_transfer.ALLOC_TRANSFER_HEADERS;
            options.headers = options.headers.set(
                "X-SystemUserId",
                this.authService.user.systemUserID
            );
            try {
                const response: IAllocationTransferCreateResponseDTO = await this.http.post<IAllocationTransferCreateResponseDTO>(url, transferDTO, options).toPromise();
                if (response) {
                    const newAllocTransfer = await this.allocTransferService.handlePostAllocTransfer(response, form);
                    return newAllocTransfer;
                }
            } catch (error) {
                console.error('AllocationTransferDataService: transfer: ', error);
                if (error && error.error && error.error.errorDetails) {
                  if (error instanceof HttpErrorResponse && error.error.errorCode === 'ERR_IO_SR06') {
                    this.notificationService.notify(this.translate.instant('AL_TRANSFER_ALREADY_TRANSFERRED'), 'AllocationTransferComponent', "top", ToastStyle.DANGER, 3000);
                    this.allocTransferService.isDisableTransfer = true;
                    let errorDetails = error.error.errorDetails
                    const existingAllocationTransferDTO : IAllocationTransferCreateResponseDTO = {
                      indskr_name: errorDetails['indskr_name'],
                      indskr_shipmentnumber: errorDetails['indskr_shipmentnumber'],
                      indskr_usershipmentallocationid:  errorDetails['indskr_usershipmentallocationid'],
                      indskr_quantityshipped: form.quantityTransferred,
                      indskr_reasonfortransfer: form.reason.value,
                      indskr_comments: form.comments,
                      indskr_shipmentdate: form.shipmentDate.getTime().toString(),        // timestamp in string
                      indskr_user: form.user.id,
                      indskr_lotid: form.lot.id,
                      indskr_skuid: form.sku.id,
                      indskr_shipmentstatus: this.authService.user.approvalForAllocationTransfer ? ShipmentStatus.Draft : ShipmentStatus.Shipped,
                      indskr_transfertype: TransferType.AllocationTransfer,
                      indskr_externalid: form.external_id,
                    }
                    const existingAllocTransfer = await this.allocTransferService.handlePostAllocTransfer(existingAllocationTransferDTO, form);
                    return existingAllocTransfer;
                  }
                }
            }
          }
    }
}
