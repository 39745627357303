import { TranslateService } from '@ngx-translate/core';
import { IndHeaderLeftDataModel } from '@omni/models/indHeaderLeftDataModel';
import { Component, Input } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { childUser } from '../../classes/authentication/user.class';
import { NavigationService } from '../../services/navigation/navigation.service';
import { RepServices } from '../../data-services/rep/rep.services';

import { Events } from '@omni/events';
import { ActivityService } from '../../services/activity/activity.service';
import { TrackService, TrackingEventNames } from '../../services/logging/tracking.service';
import { FollowUpActivityDataService } from '../../data-services/follow-up-activity/follow-up-activity.data.service';
import { ActivityType } from '../../classes/activity/activity.class';
import { AssignmentHistory, FollowUpActivity, TaskUser } from '../../classes/activity/follow-up-action.activity.class';
import { DeviceService } from '../../services/device/device.service';
import { UIService } from '../../services/ui/ui.service';
import { CaseManagementService } from '../../services/case-management/case-management.service';
import { ActivityDataService } from '../../data-services/activity/activity.service';
import * as _ from 'lodash';
import { FooterService } from '@omni/services/footer/footer.service';
import { IonNav } from '@ionic/angular';
const MIN_SEARCH_LENGTH = 3;
/**
 * Generated class for the ChildUsersPageComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'child-users-page[base-page]',
  templateUrl: 'child-users-page.html',
  styleUrls:['child-users-page.scss']
})
export class ChildUsersPageComponent {

  public usersdata: childUser[] = [];
  public formattedUsersList:Array<UserListObject> = [];
  public selectedUsers:Array<UserListObject> = [];
  private _initialSelectedUsers:Array<UserListObject> = [];
  public selectedUser: childUser;
  public mode:string;
  private isLayoverPushView:boolean = false;
  private layoverViewPushedFrom:string;
  private hardDisableDoneButton:boolean = false;
  public searchInput:string;
  public searchActive:boolean = false;

  public isSPTask = false; // Enable for offline
  @Input() from:any;
  @Input() users:any;
  indHeaderLeftModel: IndHeaderLeftDataModel;
  usersToDisplay = [];
  private recordCount: number = 30;

  constructor(
    public authService: AuthenticationService,
    public navService: NavigationService,
    public repService: RepServices,
    public events: Events,
    public activityService: ActivityService,
    private activityDataService: ActivityDataService,
    public trackingService: TrackService,
    private followUpDataService: FollowUpActivityDataService,
    public device: DeviceService,
    private uiService: UIService,
    private caseManagementService: CaseManagementService,
    public translate: TranslateService,
    public footerService: FooterService,
    public navCtrl: IonNav,
  ) {

  }
  ngOnInit() {
    if(this.from){
      this.isLayoverPushView = true;
      this.layoverViewPushedFrom = this.from;
      if(this.from == 'ActivtiesPageFilter'){
        this.mode = UserListMode.SINGLESELECTION;
        this.usersdata = this.authService.user.childUsers;
        this.selectedUser = this.authService.impersonatedUser? this.authService.impersonatedUser: undefined;
      }
      else if ((this.from == 'FollowUpTaskDetails' && this.activityService.selectedActivity && this.activityService.selectedActivity.type == ActivityType.FollowUp) || ((this.from == 'InMeetingFollowUpTaskDetails' || this.from == 'InPresentationFollowUpTaskDetails') && this.followUpDataService.inMeetingFollowupActionActivity)) {
        const selectedActivity = this.from == 'FollowUpTaskDetails' ? this.activityService.selectedActivity as FollowUpActivity : this.followUpDataService.inMeetingFollowupActionActivity;
        //If completed/ not assigned to, show in read only
        if ((selectedActivity.state == 1 && selectedActivity.status == 5) || (selectedActivity.state == 2 && selectedActivity.status == 6) ||
          (!_.isEmpty(selectedActivity.assignedTo) && selectedActivity.assignedTo.findIndex(user => user.userId === this.authService.user.systemUserID) === -1)
          && selectedActivity.ownerId != this.authService.user.systemUserID) {
          this.mode = UserListMode.READONLY;
          selectedActivity.assignedTo.forEach(user => {
            this.selectedUsers.push(new UserListObject({
              id: user.userId,
              displayName: user.userFullName,
              isSelected: true
            }))
          })
        } else {
          this.mode = UserListMode.MULTISELECTION;
          this.followUpDataService.sameLevelAndChildUsersList.forEach(user => {
            let isSelected: boolean = false;
            let isRemovable: boolean = false;
            let assignedUser = null;
            if (!_.isEmpty(selectedActivity.assignedTo)) {
              assignedUser = selectedActivity.assignedTo.find(userId => userId.userId == user.systemuserid);
            }
            if (assignedUser) {
              isSelected = true;
              if ((assignedUser.ownerId === this.authService.user.systemUserID) || selectedActivity.ownerId === this.authService.user.systemUserID) {
                isRemovable = true;
              }
            } else {
              isRemovable = true;
            }
            let obj = {
              id: user.ownerid,
              displayName: user.fullname,
              emailaddress : user.internalemailaddress,
              businessunitname : user.businessunitname,
              isSelected: isSelected,
              isRemovable: isRemovable
            }
            let formattedUser = new UserListObject(obj);
            //Assigned user should not be able to add owner as an assigned user
            if ((selectedActivity.ownerId === this.authService.user.systemUserID)
              || (selectedActivity.ownerId != this.authService.user.systemUserID && formattedUser.id != selectedActivity.ownerId)) {
              this.formattedUsersList.push(formattedUser);
            }
            if (isSelected) {
              this.selectedUsers.push(formattedUser);
              this._initialSelectedUsers.push(formattedUser);
            }
          });

          if (!_.isEmpty(selectedActivity.assignedTo)) {
            //If selected users are not mapped to logged in user
            if (_.isEmpty(this.selectedUsers)) {
              selectedActivity.assignedTo.forEach(user => {
                this.selectedUsers.push(new UserListObject({
                  id: user.userId,
                  displayName: user.userFullName,
                  isSelected: true,
                  isRemovable: selectedActivity.ownerId === this.authService.user.systemUserID
                }))
              })
            } else {
              //If some users are mapped,
              const userIds = this.selectedUsers.map(user => user.id);
              const usersNotMapped = selectedActivity.assignedTo.filter(user => !userIds.includes(user.userId));
              usersNotMapped.forEach(user => {
                this.selectedUsers.push(new UserListObject({
                  id: user.userId,
                  displayName: user.userFullName,
                  isSelected: true,
                  isRemovable: selectedActivity.ownerId === this.authService.user.systemUserID
                }))
              })
            }
          }
        }
        if (!_.isEmpty(this.selectedUsers))
          this.selectedUsers = this.selectedUsers.sort((a, b) => {
            return (a.displayName.toLowerCase() > b.displayName.toLowerCase()) ? 1 : -1
          })
      }
      else if(this.from == 'CasePageFilter'){
        this.mode = UserListMode.SINGLESELECTION;
        this.usersdata = this.authService.user.childUsers;
        this.selectedUser = this.caseManagementService.filterUserObject ? this.caseManagementService.filterUserObject : undefined;
      }
      else if (this.from == 'ScientificActivityPage') {
        this.mode = UserListMode.MULTISELECTION;
        this.usersdata = this.users || [];

        this.isSPTask = true;
        const selectedActivity = this.activityService.selectedActivity as FollowUpActivity;

        if (selectedActivity.assignedTo.length > 0) {
          (this.activityService.selectedActivity as FollowUpActivity).assignedTo.forEach(u => {
            let obj = {
              id: u['userId'],
              displayName: u['userFullName'],
              isSelected: true,
              isRemovable: (u.ownerId === this.authService.user.systemUserID) || selectedActivity.ownerId === this.authService.user.systemUserID
            }
            let formattedUser = new UserListObject(obj)
            this.selectedUsers.push(formattedUser);
            this._initialSelectedUsers.push(formattedUser);
          });
        }
        //If completed/ not assigned to, show in read only
        if ((selectedActivity.state == 1 && selectedActivity.status == 5) ||
          (!_.isEmpty(selectedActivity.assignedTo) && selectedActivity.assignedTo.findIndex(user => user.userId === this.authService.user.systemUserID) === -1)
          && selectedActivity.ownerId != this.authService.user.systemUserID) {
          this.mode = UserListMode.READONLY;
          this.usersdata = [];
        } else {
          this.usersdata.forEach(user => {
            let isSelected: boolean = false;
            let isRemovable: boolean = true;
            if (!_.isEmpty(selectedActivity.assignedTo)) {
              const assignedTo = selectedActivity.assignedTo.find(userId => userId.userId === user['ownerid']);
              if (assignedTo) {
                isSelected = true;
                isRemovable = (assignedTo.ownerId === this.authService.user.systemUserID) || selectedActivity.ownerId === this.authService.user.systemUserID
              }
            }
            let obj = {
              id: user['ownerid'],
              displayName: user['fullname'],
              isSelected: isSelected,
              isRemovable: isRemovable
            }
            let formattedUser = new UserListObject(obj)
            //Assigned user should not be able to add owner as an assigned user
            if ((selectedActivity.ownerId === this.authService.user.systemUserID)
              || (selectedActivity.ownerId != this.authService.user.systemUserID && formattedUser.id != selectedActivity.ownerId)) {
              this.formattedUsersList.push(formattedUser);
            }
          });
        }
      }
      this.usersToDisplay = this.sliceItems(0, this.recordCount);
      this.initUsersLeftHeader();
    }
  }

  public get isDoneDisabled():boolean {
    let decision:boolean = false;
    if(this.hardDisableDoneButton) return true;
    if(this.mode == UserListMode.SINGLESELECTION){
      decision = !this.selectedUser;
    } else if (this.mode == UserListMode.MULTISELECTION){
      decision = (this.device.isOffline && !this.isSPTask) || (_.xor(this.selectedUsers,this._initialSelectedUsers).length === 0);
    } else {
      decision = true;
    }
    return decision;
  }

  public async onCloseModal(done:boolean) {
    if(this.isLayoverPushView && this.mode != UserListMode.READONLY){
      if(this.layoverViewPushedFrom == 'ActivtiesPageFilter'){
        if(!done){
          this.trackingService.tracking('UserSelectionCanceled', TrackingEventNames.ACTIVITY);
        }
        else{
          this.trackingService.tracking('UserSelected', TrackingEventNames.ACTIVITY, null, true);
          if(this.selectedUser != this.authService.impersonatedUser){
            this.activityService.selectedActivity = undefined;
            this.uiService.activeView = '';
            this.authService.impersonatedUser = this.selectedUser;
            this.events.publish('filterActivitiesDataForSelectedUser', 'UserSelected');
          }
        }
      }
      else if(this.layoverViewPushedFrom == 'FollowUpTaskDetails' || this.layoverViewPushedFrom == 'InMeetingFollowUpTaskDetails' || this.layoverViewPushedFrom == 'InPresentationFollowUpTaskDetails'){
        let followup:FollowUpActivity = this.layoverViewPushedFrom == 'FollowUpTaskDetails' ? (this.activityService.selectedActivity as FollowUpActivity) : this.followUpDataService.inMeetingFollowupActionActivity;
        if(!this.device.isOffline && done && followup){
            let updatedFollowUp = new FollowUpActivity(followup.offlineDataDTO);
            const newSelectedUsers: TaskUser[] = [];
            const createdon = new Date().getTime().toString();
            updatedFollowUp.assignedTo = this.selectedUsers.map(user => {
              let ownerId = this.authService.user.systemUserID;
              let assignedUser: TaskUser = null;
              if (!_.isEmpty(updatedFollowUp.assignedTo)) {
                assignedUser = updatedFollowUp.assignedTo.find(userId => userId.userId == user.id);
                if (assignedUser && !_.isEmpty(assignedUser.ownerId)) {
                  ownerId = assignedUser.ownerId
                }
              }
              let userRaw: TaskUser = new TaskUser({});
              userRaw.activityId = (this.activityService.selectedActivity as FollowUpActivity).ID;
              userRaw.userFullName = user.displayName;
              userRaw.userId = user.id;
              userRaw.ownerId = ownerId;
              if (!assignedUser) {
                userRaw.overriddencreatedon = createdon;
                newSelectedUsers.push(userRaw);
              }
              return userRaw;
            });
            updatedFollowUp.pendingPushToDynamics = true;
            this.hardDisableDoneButton = true;
            this.uiService.displayLoader();
            const assignmentHistory: AssignmentHistory = new AssignmentHistory({});
            assignmentHistory.createdon = createdon;
            assignmentHistory.users = newSelectedUsers;
            updatedFollowUp.assignmentHistory.push(assignmentHistory)
            await this.followUpDataService.updateFollowUpActivity({ onDynamics: !this.device.isOffline, onLocalCopy: true, onLocalDatabase: true, appendActivityDetails: false, operationDetail: { code: 'FUT101', message: 'Update assigned to field' } }, [updatedFollowUp], new Date().getTime()).then(succ => {
              followup.assignedTo = updatedFollowUp.assignedTo;
              followup.pendingPushToDynamics = updatedFollowUp.pendingPushToDynamics;
              followup.assignmentHistory.push(assignmentHistory);
              this.events.publish("followupTask:assignmentHistoryUpdated");//,this.activityService.selectedActivity
              this.events.publish('activityUpdated', this.activityService.selectedActivity);
              this.hardDisableDoneButton = false;
            }).catch(err => {
              err;
              this.hardDisableDoneButton = false;
              // Handle error ecenario
            })
            this.uiService.dismissLoader();
        }
      }
      else if(this.layoverViewPushedFrom === 'CasePageFilter') {
        /* Make Selection */
        if(done){
          this.caseManagementService.filterUserObject = this.selectedUser;
          this.caseManagementService.teamInquiriesFilter.user = this.selectedUser.fullName
          if(this.caseManagementService.teamInquiriesFilter.type=='All'){
            this.caseManagementService.teamInquiriesFilter.type=''
          }
          if (this.caseManagementService.listMode === 'teamCases') {
            this.caseManagementService.getTeamCaseList();
          } else {
            this.caseManagementService.getMyCaseList();
          }
        }
      } else if(this.layoverViewPushedFrom === 'ScientificActivityPage'){
        if(done && this.activityService.selectedActivity && this.activityService.selectedActivity.type == ActivityType.FollowUp){
          let updatedFollowUp = new FollowUpActivity((this.activityService.selectedActivity as FollowUpActivity).offlineDataDTO);
          const newSelectedUsers: TaskUser[] = [];
          const createdon = new Date().getTime().toString();
          updatedFollowUp.assignedTo = this.selectedUsers.map(user => {
            let ownerId = this.authService.user.systemUserID;
            let assignedUser: TaskUser = null;
            if (!_.isEmpty(updatedFollowUp.assignedTo)) {
              assignedUser = updatedFollowUp.assignedTo.find(userId => userId.userId == user.id);
              if (assignedUser && !_.isEmpty(assignedUser.ownerId)) {
                ownerId = assignedUser.ownerId
              }
            }
            let userRaw: TaskUser = new TaskUser({});
            userRaw.activityId = (this.activityService.selectedActivity as FollowUpActivity).ID;
            userRaw.userFullName = user.displayName;
            userRaw.userId = user.id;
            userRaw.ownerId = ownerId;
            if (!assignedUser) {
              userRaw.overriddencreatedon = createdon;
              newSelectedUsers.push(userRaw);
            }
            return userRaw;
          });
          updatedFollowUp.pendingPushToDynamics = true;
          this.hardDisableDoneButton = true;
          this.uiService.displayLoader();
          const assignmentHistory: AssignmentHistory = new AssignmentHistory({});
          assignmentHistory.createdon = createdon;
          assignmentHistory.users = newSelectedUsers;
          updatedFollowUp.assignmentHistory.push(assignmentHistory)
          await this.followUpDataService.updateFollowUpActivity({onDynamics:!this.device.isOffline,onLocalCopy:true,onLocalDatabase:true,appendActivityDetails:false,operationDetail:{code:'FUT101',message:'Update assigned to field'}},[updatedFollowUp],new Date().getTime()).then(succ=>{
            (this.activityService.selectedActivity as FollowUpActivity).assignedTo = updatedFollowUp.assignedTo;
            (this.activityService.selectedActivity as FollowUpActivity).pendingPushToDynamics = updatedFollowUp.pendingPushToDynamics;
            (this.activityService.selectedActivity as FollowUpActivity).assignmentHistory.push(assignmentHistory);
              this.events.publish("followupTask:assignmentHistoryUpdated");//this.activityService.selectedActivity
            this.hardDisableDoneButton = false;
          }).catch(err=>{
            err;
            this.hardDisableDoneButton = false;
            // Handle error ecenario
          })
          this.uiService.dismissLoader();
        }
      }
    }
    if(this.layoverViewPushedFrom == 'InMeetingFollowUpTaskDetails'){
      if (document.getElementsByClassName('modal-wrapper')[0].classList.contains('fullStretchView')) {
        document.getElementsByClassName('modal-wrapper')[0].classList.remove('fullStretchView');
      }
      this.navCtrl.pop({ progressAnimation: false }).then(() => {
        
      });
    }else{
      this.navService.popWithPageTracking().then(()=>{
        this.events.publish('scrollToDate');
     });
    }
    
  }

  public handleUserSelection(user: childUser):void {
    if(this.layoverViewPushedFrom == 'ActivtiesPageFilter' && this.mode != UserListMode.READONLY){
      this.selectedUser = user;
    }
    else if(this.layoverViewPushedFrom == 'CasePageFilter') {
      this.selectedUser = user;
    }
    this.initUsersLeftHeader();
  }

  public addRemoveToSelected(user:UserListObject,action):void {
    if(action){
      user.isSelected = true;
      this.selectedUsers.push(user);
    }else{
      user.isSelected = false;
      let idx = this.selectedUsers.findIndex(u => u.id == user.id);
      let foundUser;
      if (this.mode == UserListMode.MULTISELECTION){
        foundUser = this.formattedUsersList.find(u => u['id'] == user.id);
      }else{
        foundUser = this.usersdata.find(u => u['ownerid'] == user.id);
      }

      if(foundUser) foundUser['isSelected'] = false;
      if(idx >= 0){
        this.selectedUsers.splice(idx,1);
      }
    }
    this.initUsersLeftHeader();
  }

  public searchText(ev):void {
    let val:string = (ev.target && ev.target.value) ? ev.target.value : '';
    if(val.length >= MIN_SEARCH_LENGTH){
      this.searchActive = true;
    }else {
      this.searchActive = false;
    }
    this.usersToDisplay = this.sliceItems(0, this.recordCount);
  }

  public get getUsersList():Array<any>{
    let users;
    if(this.mode == UserListMode.SINGLESELECTION){
      if(this.searchActive){
        return this.usersdata.filter(user =>user.fullName.toLowerCase().includes(this.searchInput.toLowerCase()))
      }else{
        users = this.usersdata
      }
      users = users.sort((a,b)=>{
        return (a.fullName.toLowerCase() > b.fullName.toLowerCase())? 1:-1
      })
    }else if (this.mode == UserListMode.MULTISELECTION){
      if(this.searchActive){
        return this.formattedUsersList.filter(user => user.displayName.toLowerCase().includes(this.searchInput.toLowerCase()))
      }else{
        users = this.formattedUsersList
      }
      users = users.sort((a,b)=>{
        return (a.displayName.toLowerCase() > b.displayName.toLowerCase())? 1: -1
      })
    }else {
      users = [];
    }
    return users
  }

  private initUsersLeftHeader(): void {
    let buttons = [];
    buttons.push(
      {
        id: "closePage",
        imgSrc: 'assets/imgs/header_cancel.svg',
        cssClass: 'seventyPercentWidth',
        isDisabled: false,
        align: "left",
      },
      {
        id: "done",
        cssClass: 'seventyPercentWidth',
        imgSrc: 'assets/imgs/header_complete.svg',
        isDisabled: this.isDoneDisabled,
        align: "left",
    }
    );
    this.indHeaderLeftModel = {
      id: 'users-list-header-left',
      cssClass: 'main-tool-header-title',
      title: this.from == 'ActivtiesPageFilter'? this.translate.instant('TEAM_VIEW'):this.translate.instant('USERS'),
      mode: false,
      customHeaderProps:{hasCancel:true},
      controls: buttons,
    };
  }

  onPageTitleControlClick(id){
    if(id==='closePage'){
      this.onCloseModal(false);
    }
    if(id==='done'){
      this.onCloseModal(true);
    }
  }

  public doInfinite(eventDetails, event) {
    this.usersToDisplay.push(...this.sliceItems(this.recordCount, 30));
    this.recordCount = this.usersToDisplay.length;
    event.target.complete();
  }

  private sliceItems(startIndex: number, count: number) {
    return this.getUsersList.length < startIndex + count ? this.getUsersList.slice(startIndex) : this.getUsersList.slice(startIndex, startIndex + count);;
  }
}

export enum UserListMode {
  SINGLESELECTION = 'single_selection',
  READONLY = 'read_only',
  MULTISELECTION = 'multi_selection',
}

export class UserListObject {
  public id:string;
  public displayName:string;
  public isSelected:boolean;
  public isRemovable: boolean;
  public emailaddress? : string;
  public businessunitname? : string;

  constructor(raw:any){
    this.id = raw['id'];
    this.displayName = raw['displayName'];
    this.isSelected = (raw['isSelected']) ? raw['isSelected'] : false;
    this.isRemovable = raw['isRemovable'] != null ? raw['isRemovable'] : true;
    this.emailaddress = raw['emailaddress'] || null;
    this.businessunitname = raw['businessunitname'] || null;
  }
}
