import { Injectable } from "@angular/core";
import { FollowUpActivity, FOLLOW_UP_TYPE, FollowupActionType, FOLLOWUP_ACTION_TYPE_STATUS, FollowupActionStatusReason } from '../../classes/activity/follow-up-action.activity.class';
import { DB_ALLDOCS_QUERY_OPTIONS, DB_KEY_PREFIXES, PREFIX_SEARCH_ENDKEY_UNICODE } from "../../config/pouch-db.config";
import { DiskService, OFFLINE_DATA_COUNT_ENTITY_NAME } from "../../services/disk/disk.service";
import { ActivityService } from "../../services/activity/activity.service";
import { ContactOfflineService } from "../../services/contact/contact.service";
import { Contact } from "../../classes/contact/contact.class";
import { Endpoints } from "../../../config/endpoints.config";
import { AuthenticationService } from "../../services/authentication.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { EventsService } from "../../services/events/events.service";
import { NotificationService } from "../../services/notification/notification.service";
import { UIService } from "../../services/ui/ui.service";
import { Activity, ActivityType, ConfigFieldOptionResponse } from "../../classes/activity/activity.class";
import {MyAssistantService, NOTIFICATION} from "../../services/my-assistant/my-assistant.service";
import { TranslateService } from "@ngx-translate/core";
import { DeltaService } from '../delta/delta.service';
import { SyncFeatureCategory } from '../../enums/delta-service/delta-service.enum';
import { FeatureActionsMap } from "@omni/classes/authentication/user.class";
import { fetchQueries } from "@omni/config/dynamics-fetchQueries";
import { differenceInHours, isValid } from "date-fns";
import { DynamicsClientService } from '../dynamics-client/dynamics-client.service';
import { map } from 'rxjs/operators';
import { FollowupService } from '../../services/followup/followup.service';
import { Sort } from '../../classes/presentation/presentation.class';
import * as _ from 'lodash';
import { IONote } from '../../classes/io/io-note.class';
import {IndNotificationDataModel} from "@omni/models/indNotificationDataModel";
import { getConfigFieldPickListRequestURL } from "@omni/utility/common.utility";
import { DeviceService } from "@omni/services/device/device.service";
/**
 * Offline/Online data operations service for Follow Up Activity
 *
 *
 */
@Injectable({
  providedIn: 'root'
})
export class FollowUpActivityDataService {

    private _sameLevelAndChildUsersList:Array<FollowUpAssignedUserModel> = [];
    private  followupActionTypes: FollowupActionType[] = [];
    private followUpNotificationModel: IndNotificationDataModel;
    public inMeetingFollowupActionActivity: FollowUpActivity;
    public isInMeetingFollowupFlow:boolean = false;
    public followupActionObjectives = [];


    constructor(
        private contactOfflineService: ContactOfflineService,
        private disk: DiskService,
        private activityOfflineService: ActivityService,
        private authenticationService: AuthenticationService,
        private http: HttpClient,
        private events: EventsService,
        private notificationService: NotificationService,
        private uiService: UIService,
        private myAssistantService: MyAssistantService,
        private translate:TranslateService,
        private deltaService: DeltaService,
        private dynamics: DynamicsClientService,
        private followupService: FollowupService,
         private deviceService: DeviceService,
    ){}

    public get sameLevelAndChildUsersList():Array<FollowUpAssignedUserModel> {
        return this._sameLevelAndChildUsersList;
    }

    public async getSameLevelAndChildUsersListOnline(loadFromDBOnly = false, isOnlineResponse ?:boolean):Promise<any>{
        this.deltaService.pushSyncEntityName(SyncFeatureCategory.profiles);
        if (loadFromDBOnly) {
            const dbData = await this.disk.retrieve(DB_KEY_PREFIXES.SAME_LEVEL_AND_CHILD_USERS_LIST, true);
            if (dbData && dbData.raw) {
                this.mapRawData(dbData.raw);
                return Promise.resolve('Successfully loaded same level and child users list');
            }
        }
        else {
          let response;
          if(this.authenticationService.hasFeatureAction(FeatureActionsMap.FOLLOW_UP_ASSIGNMENT_ORGANIZATION)){
            // response = await this.dynamics.retrieveAll('systemusers',['systemuserid','fullname','internalemailaddress'],`accessmode ne 3 and accessmode ne 5 and isdisabled eq false and not startswith(fullname, '#') `).then(res=>res.value)
            let url: string = this.authenticationService.userConfig.activeInstance.url + "/api/data/v9.0/systemusers?$select=systemuserid,fullname,internalemailaddress&$expand=businessunitid($select=name)&$filter=accessmode ne 3 and accessmode ne 5 and isdisabled eq false and not startswith(fullname,'%23')";
            response = (await this.http.get<any>(url).toPromise()).value;
            // response = await this.dynamics.retrieveAttributes('systemusers','',['systemuserid','fullname','internalemailaddress'], `accessmode ne 3 and accessmode ne 5 and isdisabled eq false and not startswith(fullname, '#') `, '' )
          }
          else{
            let url:string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.followup.GET_SAME_LEVEL_AND_CHILD_USERS_LIST;
            url = url.replace('{{positionIds}}',this.authenticationService.getLoggedInUserPositions().toString())
            response = await this.http.get(url).toPromise();
          }
          if(isOnlineResponse) {
            return response;
          }
          if(response && Array.isArray(response)){
              this.mapRawData(response);
              this.disk.updateOrInsert(DB_KEY_PREFIXES.SAME_LEVEL_AND_CHILD_USERS_LIST, doc => ({ raw: response }));
              return Promise.resolve('Successfully saved same level and child users list');
          }else{
              return Promise.reject('Error occured while fetching same level and child users list');
          }
        }
    }

    public async getFollowupActiontypes(fullSync, loadFromDBOnly = false){
    let isFollowupActivityEnabled = this.authenticationService.hasFeatureAction(FeatureActionsMap.FOLLOW_UP_ACTION_ACTIVITY);
    if (!isFollowupActivityEnabled ) return;
    let lastModifiedForDeltaSync, hourDifference;
    await this.disk.retrieve(DB_KEY_PREFIXES.FOLLOW_UP_ACTION_TYPES).then((doc)=>{
      if(doc && doc.raw && doc.raw.length){
        this.followupActionTypes = doc.raw;
        if (this.followupActionTypes) {
          this.followupService.followupActionTypes = this.followupActionTypes.filter((followup) => {
            return followup.statusCode == FOLLOWUP_ACTION_TYPE_STATUS.ACTIVE
          });
          this._sortActiveFollowupActionTypes();
        }
        lastModifiedForDeltaSync = doc.lastUpdatedTime;
      }
      else{
        // this.productHierarchies = [];
        fullSync = true;
      }
    });
    if (loadFromDBOnly) return;
    let fetchXML = fetchQueries.followupActions.planType;
    fetchXML = fetchXML.replace('{userBu}',this.authenticationService.user.xBusinessUnitId);
    //add delta sync filter
    if(fullSync){
      fetchXML = fetchXML.replace('{deltaSyncFilter}', '');
    }
    else{
      let deltaSyncFilter;
      let now = new Date();
      if(lastModifiedForDeltaSync){

        deltaSyncFilter = `<filter type="and">
      <condition attribute="modifiedon" operator="last-x-hours" value="{lastXHours}" />
    </filter>`
        hourDifference = differenceInHours(
          now,
          new Date(lastModifiedForDeltaSync)
        )
        //add one to make sure we take care of fractional difference in hours
        hourDifference += 1
        deltaSyncFilter = deltaSyncFilter.replace('{lastXHours}', hourDifference);

      }
      else deltaSyncFilter = ''
      fetchXML = fetchXML.replace('{deltaSyncFilter}', deltaSyncFilter);
    }

      await this.dynamics.executeFetchQuery('indskr_followupactiontypes', fetchXML).then(async (data) => {

        await this.aggregateActionTypeData(data);

        this.followupService.followupActionTypes = this.followupActionTypes.filter((followup) => {
          return followup.statusCode == FOLLOWUP_ACTION_TYPE_STATUS.ACTIVE
        });

        this._sortActiveFollowupActionTypes();

      this.disk.updateOrInsert(DB_KEY_PREFIXES.FOLLOW_UP_ACTION_TYPES,(doc)=>{
        if(!doc || !doc.raw){
          doc = {
            raw:[]
          };
        }
         doc.raw = this.followupActionTypes;
        doc.lastUpdatedTime = new Date().getTime();
        return doc;
      });
    }, (errror)=>console.log(errror));
  }

  public async getFollowupActionStatusReasons (fullSync, loadFromDBOnly = false){
    if(loadFromDBOnly){
      await this.disk.retrieve(DB_KEY_PREFIXES.FOLLOWUP_STATUS_REASON_CODES, true).then((doc)=>{
        if(doc && doc.raw){
          this.followupService.followupActionStatusReasons = doc.raw
        }
        else {
          this.followupService.followupActionStatusReasons = [];
        }
      })
      return;
    }else{
      const url:string = this.authenticationService.userConfig.activeInstance.url + Endpoints.followup.GET_STATUS_REASONS;
      const headers = new HttpHeaders().set('X-SystemUserId', this.authenticationService.user.xSystemUserID);
      const response = await this.http.get(url, { headers }).toPromise();

      try {
        if(response && response['value'] && Array.isArray(response['value'])){
          let idx = response['value'].findIndex(a=> a.LogicalName == 'statuscode');
          if(idx >= 0){
            this.followupService.followupActionStatusReasons = [];
            response['value'][idx]['OptionSet']['Options'].forEach((reason)=>{
              let statusReason:FollowupActionStatusReason = {
                name: (reason['Label'] && reason['Label']['UserLocalizedLabel']) ? reason['Label']['UserLocalizedLabel']['Label'] : reason['Label']['LocalizedLabels'][0]['Label'],
                statusCode: reason['Value'],
                stateCode: reason['State'],
              };
              this.followupService.followupActionStatusReasons.push(statusReason);
            });
          }


        }
      } catch (error) {
        console.log(error);
        this.followupService.followupActionStatusReasons = [];
      }
      this.disk.updateOrInsert(DB_KEY_PREFIXES.FOLLOWUP_STATUS_REASON_CODES, doc => ({ raw: this.followupService.followupActionStatusReasons }));
    }

  }


  private _sortActiveFollowupActionTypes() {
    this.followupService.followupActionTypes = this.followupService.followupActionTypes.sort((a, b) => {
      if (a.name && b.name) {
        return (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1;
      }
    });
  }

  private async aggregateActionTypeData(data){

    data.map((rawData) => {
      let followupActionType: FollowupActionType;
      let actionTypeID = rawData['indskr_followupactiontypeid'];
      if (actionTypeID) {
        followupActionType = this.followupActionTypes.find(o => o.followupActionTypeID == rawData.indskr_followupactiontypeid)
        if(!followupActionType) {
          followupActionType = new FollowupActionType(rawData);
          this.followupActionTypes.push(followupActionType);
        } else {
          let index = this.followupActionTypes.findIndex(actiontype=> actiontype.followupActionTypeID === actionTypeID)
          followupActionType = new FollowupActionType(rawData);
          this.followupActionTypes[index] = followupActionType;
        }
      }
    });
  }

    private mapRawData(raw: any) {
      this._sameLevelAndChildUsersList = raw.map((item) => {
        return { ...item, businessunitname: item.businessunitid ? item.businessunitid.name : item['_businessunitid_value@OData.Community.Display.V1.FormattedValue'] }
      });
        this._sameLevelAndChildUsersList.sort((a,b)=>{
            if(a.fullname && b.fullname){
                return (a.fullname.toLowerCase() > b.fullname.toLowerCase()) ? 1 : -1;
            }
        });
    }

    private async createFollowUpActivityOnline(payload):Promise<any> {
        // Hard removing the indskr_accountplan property from payload for POST request until service makes the changes to accept empty string as value
        if(payload.indskr_accountplan == ''){
            delete payload.indskr_accountplan;
        }
        if(payload.indskr_account == ''){
            delete payload.indskr_account;
        }
        let url:string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.followup.CREATE_UPDATE_FOLLOW_UP;
        // Create new follow up activity on dynamics and return promise with success or failure
        return this.http.post(url, payload).toPromise();
    }

    private scrapFollowUpActivityOnline(followup:FollowUpActivity):Promise<any>{
        let url:string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.followup.DELETE_FOLLOW_UP;
        url = url.replace('{{followupid}}',followup.ID)
        return this.http.delete(url,{params:{subject: followup.subject}}).toPromise();
    }

    private UpdateFollowUpActivityOnline(payload:object):Promise<any> {
        let headers = Endpoints.headers.content_type.json;
        if(payload['notes'] && payload['notes'].length > 0){
            headers.headers = headers.headers.set(
                'No-Retry', 'true'
            );
        }
        let url:string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.followup.CREATE_UPDATE_FOLLOW_UP;
        return this.http.patch(url, payload,headers).toPromise();
    }

    public loadOfflineFollowUpActivities():Promise<any> {
        return new Promise(async (resolve,reject)=> {
            let option = {
                selector: {
                    '_id': {
                        $gte: DB_KEY_PREFIXES.FOLLOW_UP_ACTIVITY,
                        $lte: DB_KEY_PREFIXES.FOLLOW_UP_ACTIVITY + PREFIX_SEARCH_ENDKEY_UNICODE
                    }
                }
            };
            option.selector['pendingPushToDynamics'] = {
                $eq: true
            };
            try {
                // Fetch from DB
                const offlineFollowups: any[] = await this.disk.find(option);
                if(offlineFollowups && Array.isArray(offlineFollowups) && offlineFollowups.length > 0){
                    let rawfollowupspayload = offlineFollowups.map(rawfollowup => {
                        return new FollowUpActivity(rawfollowup).serviceDTO;
                    });
                    // Track Offline data count
                    this.disk.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.FOLLOW_UP, rawfollowupspayload.length);

                    resolve(rawfollowupspayload);
                }else{
                    resolve(null);
                }
            }catch(error){
                reject('Error occured while fetching follow up activities data from offline db');
            }
        });
    }

    public handleOfflinePushResponse(rawResponse:any):Promise<any>{
        return new Promise(async (resolve,reject)=> {
            let updatedOfflineFollowups = [];
            for (var i = 0; i < rawResponse.length; i++) {
                let followUpActivity = rawResponse[i];
                if ((followUpActivity.hasOwnProperty('activityid') || followUpActivity.hasOwnProperty('offlineId')) && !followUpActivity['errorCode']) {
                    let idtobeused = (followUpActivity.offlineId) ? DB_KEY_PREFIXES.FOLLOW_UP_ACTIVITY+followUpActivity.offlineId : DB_KEY_PREFIXES.FOLLOW_UP_ACTIVITY+followUpActivity.activityid
                    let savedOfflineObject  = await this.disk.retrieve(idtobeused);
                  if (savedOfflineObject) {

                    savedOfflineObject.activityid = followUpActivity.activityid;

                    savedOfflineObject.pendingPushToDynamics = false;

                    // if(savedOfflineObject.notes && savedOfflineObject.notes.length > 0){

                    let notesArrayFromResponse = followUpActivity['notes'];
                    let followUpNotes = this._offlineNotesParsing(notesArrayFromResponse);

                    savedOfflineObject.notes = followUpNotes;

                    if (savedOfflineObject.statecode == 2 && savedOfflineObject.statuscode == 6) {
                      savedOfflineObject = getDeletedPayloadObejct(savedOfflineObject);
                    }
                    updatedOfflineFollowups.push(savedOfflineObject);
                  }
                    const followup = this.activityOfflineService.getActivityByID(followUpActivity.offlineId?followUpActivity.offlineId:followUpActivity.activityid);
                  let notesArrayFromResponse = followUpActivity['notes'];
                  let followUpNotes = this._offlineNotesParsing(notesArrayFromResponse);

                    if (followup) {
                        (<FollowUpActivity>followup).ID = followUpActivity.activityid;
                        (<FollowUpActivity>followup).pendingPushToDynamics = false;
                        (<FollowUpActivity>followup).followUpNotes = followUpNotes
                    }else{
                        const followup2 = this.activityOfflineService.getActivityByID(followUpActivity.activityid);
                        if (followup2) {
                            (<FollowUpActivity>followup2).ID = followUpActivity.activityid;
                            (<FollowUpActivity>followup2).pendingPushToDynamics = false;
                            (<FollowUpActivity>followup2).followUpNotes = followUpNotes
                        }
                    }
                }else if(followUpActivity['errorCode']){
                    if(followUpActivity['errorCode']){
                        this.followUpNotificationModel = {
                          type: NOTIFICATION.DATA_UPLOAD_ERR_FOLLOWUP,
                          name: this.translate.instant("DATA_UPLOAD_ERR_FOLLOWUP", {errorCode: followUpActivity['errorCode']}),
                          DateTime: Date.now(),
                          id: NOTIFICATION.DATA_UPLOAD_ERR_FOLLOWUP + followUpActivity.offlineId ? followUpActivity.offlineId : followUpActivity.activityid,
                          data: {data: this.translate.instant("DATA_UPLOAD_ERR_FOLLOWUP", {errorCode: followUpActivity['errorCode']})},
                          icon: 'assets/imgs/follow-up_32x32.svg',
                          isRed: false,
                          params: {errorCode: followUpActivity['errorCode']}
                        };
                        this.myAssistantService.saveNotificationToDisk(this.followUpNotificationModel);
                        if(followUpActivity['errorCode'] == 'ERR_IO_ALDY_CMTD_APFT' && (followUpActivity.hasOwnProperty('activityid') || followUpActivity.hasOwnProperty('offlineId'))){
                            // Already completed task or Creating/Updating Task is failed but Notes are uploaded
                            let idtobeused = (followUpActivity.offlineId) ? DB_KEY_PREFIXES.FOLLOW_UP_ACTIVITY+followUpActivity.offlineId : DB_KEY_PREFIXES.FOLLOW_UP_ACTIVITY+followUpActivity.activityid
                            let savedOfflineObject  = await this.disk.retrieve(idtobeused);
                            if(savedOfflineObject){
                                // this.myAssistantService.addNewNotification(this.translate.instant('THIS_ACTION_DATASUBJECT_COMPLITED',{datasubject : savedOfflineObject.subject}));
                                savedOfflineObject.activityid = followUpActivity.activityid;
                                savedOfflineObject.pendingPushToDynamics = false;
                                //Clear offline status for uploaded notes
                                // if(savedOfflineObject.notes && savedOfflineObject.notes.length > 0){
                                //     savedOfflineObject.notes.forEach(note=>{
                                //         if(note.annotationid.includes('offline')){
                                //             note.annotationid = note.annotationid.replace('offline','');
                                //         }
                                //     })
                                // }

                              let notesArrayFromResponse = followUpActivity['notes'];
                              let followUpNotes = this._offlineNotesParsing(notesArrayFromResponse);

                              savedOfflineObject.notes = followUpNotes;

                                // if( savedOfflineObject.statecode == 2 && savedOfflineObject.statuscode == 6){
                                //     savedOfflineObject = { _id: savedOfflineObject._id, _rev: savedOfflineObject._rev, _deleted: true,pendingPushToDynamics:false };
                                // }
                                updatedOfflineFollowups.push(savedOfflineObject);

                              if (savedOfflineObject['statecode'] && savedOfflineObject['statuscode'] && savedOfflineObject['statecode'] == 2 && savedOfflineObject['statuscode'] == 6) {
                                // Re-open the follow up task
                                savedOfflineObject['statecode'] = 1;
                                savedOfflineObject['statuscode'] = 5;
                                savedOfflineObject._deleted = false;
                              }
                            }
                          let foundLocalRecord: boolean = false;
                          const followup = this.activityOfflineService.getActivityByID(followUpActivity.offlineId ? followUpActivity.offlineId : followUpActivity.activityid);
                          let notesArrayFromResponse = followUpActivity['notes'];
                          let followUpNotes = this._offlineNotesParsing(notesArrayFromResponse);
                            if (followup) {
                                (<FollowUpActivity>followup).ID = followUpActivity.activityid;
                                (<FollowUpActivity>followup).pendingPushToDynamics = false;
                                (<FollowUpActivity>followup).followUpNotes = followUpNotes;
                                foundLocalRecord = true;
                            }else{
                                const followup2 = this.activityOfflineService.getActivityByID(followUpActivity.activityid);
                              if (followup2) {
                                (<FollowUpActivity>followup2).ID = followUpActivity.activityid;
                                (<FollowUpActivity>followup2).pendingPushToDynamics = false;
                                (<FollowUpActivity>followup).followUpNotes = followUpNotes;
                              }
                                foundLocalRecord = true;
                            }
                            if(!foundLocalRecord && savedOfflineObject && savedOfflineObject['statecode'] && savedOfflineObject['statuscode'] && savedOfflineObject['statecode'] == 2 && savedOfflineObject['statuscode'] == 6){
                                // Re add follow up task in local array
                                this.activityOfflineService.addActivity(new FollowUpActivity(savedOfflineObject));
                            }
                        }else if(followUpActivity['errorCode'] == 'ERR_IO_ADFT' && (followUpActivity.hasOwnProperty('activityid') || followUpActivity.hasOwnProperty('offlineId'))){
                            // Already deleted task so everything fails
                            let idtobeused = (followUpActivity.offlineId) ? DB_KEY_PREFIXES.FOLLOW_UP_ACTIVITY+followUpActivity.offlineId : DB_KEY_PREFIXES.FOLLOW_UP_ACTIVITY+followUpActivity.activityid
                            let savedOfflineObject  = await this.disk.retrieve(idtobeused);
                            if(savedOfflineObject){
                                // this.myAssistantService.addNewNotification(this.translate.instant('THIS_ACTION_DATASUBJECT_SCRAPPED',{datasubject : savedOfflineObject.subject}));
                                savedOfflineObject.pendingPushToDynamics = false;
                                this.scrapFollowUpActivity({onDynamics:false,onLocalDatabase:true,onLocalCopy:true,operationDetail:{code:'HARDDELETE',message:'Hard Deletion Of Follwuptask'}},new FollowUpActivity(savedOfflineObject),true);
                            }
                        }else if(followUpActivity['errorCode'] == 'ERR_IO_NOTE_APFT'){
                            // Creating/Updating Task is success but uploading notes get failed
                        }else if(followUpActivity['errorCode'] == 'ERR_IO_ALDY_CMTD_NOTE_APFT'){
                            // Creating/Updating Task is failed and uploading notes get failed
                            let idtobeused = (followUpActivity.offlineId) ? DB_KEY_PREFIXES.FOLLOW_UP_ACTIVITY+followUpActivity.offlineId : DB_KEY_PREFIXES.FOLLOW_UP_ACTIVITY+followUpActivity.activityid
                            let savedOfflineObject  = await this.disk.retrieve(idtobeused);
                            if(savedOfflineObject){
                                // this.myAssistantService.addNewNotification(this.translate.instant('THIS_ACTION_DATASUBJECT_COMPLITED',{datasubject:savedOfflineObject.subject}));
                                savedOfflineObject.activityid = followUpActivity.activityid;
                                savedOfflineObject.pendingPushToDynamics = false;
                                // if( savedOfflineObject.statecode == 2 && savedOfflineObject.statuscode == 6){
                                //     savedOfflineObject._deleted = true;
                                // }
                                updatedOfflineFollowups.push(savedOfflineObject);
                            }
                            const followup = this.activityOfflineService.getActivityByID(followUpActivity.offlineId?followUpActivity.offlineId:followUpActivity.activityid);
                            if (followup) {
                                (<FollowUpActivity>followup).ID = followUpActivity.activityid;
                                (<FollowUpActivity>followup).pendingPushToDynamics = false;
                            }else{
                                const followup2 = this.activityOfflineService.getActivityByID(followUpActivity.activityid);
                                if (followup2) {
                                    (<FollowUpActivity>followup2).ID = followUpActivity.activityid;
                                    (<FollowUpActivity>followup2).pendingPushToDynamics = false;
                                }
                            }
                        }
                    }
                }
            }
            if(updatedOfflineFollowups.length > 0){
                try {
                    // Bulk save docs to DB
                    await this.disk.bulk(updatedOfflineFollowups);

                    // Track offline data count
                    this.activityOfflineService.trackOfflineFollowUpDataCount();
                    resolve('Successfully updated in offline DB');
                } catch (error) {
                    reject('Error Occured while updating offline data'+error);
                }
            }else{
                resolve('No follow up found to be updated');
            }
        });
    }

  private _notehasDocument(note) {
    return (note.hasOwnProperty('filesize') && note['filesize'] > 0)
  }

  private _offlineNotesParsing(notesArrayFromResponse: any) {
    let followUpNotes = [];
    if (_.isArray(notesArrayFromResponse)) {
      notesArrayFromResponse.map(noteFromResponse => {
        if (noteFromResponse.hasOwnProperty('deleted') && !noteFromResponse['deleted']) {
          noteFromResponse['isdocument'] = this._notehasDocument(noteFromResponse)
          followUpNotes.push(new IONote(noteFromResponse));
        }
      });
    }
    return followUpNotes;
  }

    public async loadFollowUpActivitiesFromDb(dataRange: { from: string, to: string }): Promise<any> {
        return new Promise(async (resolve,reject)=> {
            let option = {
                selector: {
                    '_id': {
                        $gte: DB_KEY_PREFIXES.FOLLOW_UP_ACTIVITY,
                        $lte: DB_KEY_PREFIXES.FOLLOW_UP_ACTIVITY + PREFIX_SEARCH_ENDKEY_UNICODE
                    }
                }
            };
            if (dataRange && dataRange.from) {
                option.selector['scheduledstart'] = {
                    $gte: dataRange.from
                };
            }
            if (dataRange && dataRange.to) {
                option.selector['scheduledstart'] = Object.assign({},
                    option.selector['scheduledstart'],
                    { $lte: dataRange.to }
                );
            }
            try {
                // Fetch from DB and do mapping
                const rawFollowups: any[] = await this.disk.find(option);
                if(rawFollowups && Array.isArray(rawFollowups) && rawFollowups.length > 0){
                    let followups = [];
                    rawFollowups.forEach(rawfollowup => {
                        if(!rawfollowup['_deleted']){
                            let newFollowUp = new FollowUpActivity(rawfollowup);
                            if(!(newFollowUp.state == 2 && newFollowUp.status == 6) || newFollowUp.reasonForCancellation){
                                followups.push(newFollowUp);
                            }
                        }
                    });
                    if(followups.length > 0){
                        let action:OperationDetail = {
                            onDynamics : false,
                            onLocalDatabase : false,
                            onLocalCopy : true,
                            operationDetail: {
                              code: 'OFFLINE_DB_DATA_MAPPING',
                              message: 'Offline db data mapping'
                            }
                        };
                        // Track Offline data count
                        let offlineDataCount = followups.filter(f => f.pendingPushToDynamics === true).length;
                        this.disk.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.FOLLOW_UP, offlineDataCount);
                        await this.createFollowUpActivity(action,followups,null,false).then(success=>{
                            resolve(null);
                            return;
                        });
                    }else{
                        resolve(null);
                    }
                }else{
                    resolve(null);
                }
            }catch(error){
                reject('Error occured while fetching follow up activities data from offline db');
            }
        });
    }

    public scrapFollowUpActivity(action:OperationDetail,followUp:FollowUpActivity,force:boolean = false):Promise<any>{
        /*
        Statecode :
            Value: 0, Label: Open
            Value: 1, Label: Completed
            Value: 2, Label: Canceled
        Statuscode:
            Value: 2, Label: Not Started
            Value: 3, Label: In Progress
            Value: 4, Label: Waiting on someone else
            Value: 5, Label: Completed
            Value: 6, Label: Canceled
            Value: 7, Label: Deferred
        */
        if(followUp){
            if(followUp.ownerId != this.authenticationService.user.systemUserID && !force){
                return Promise.reject(this.translate.instant('NOT_AUTHORIZED_TO_SCRAP_ACTIVITY'));
            }else if(followUp.state == 1 && followUp.status == 5 && !force){
                return Promise.reject('Cannot scrap completed activity');
            }else if(followUp.state == 2 && followUp.status == 6 && !force){
                return Promise.reject('Cannot scrap already cancelled activity');
            }else if(followUp.state == 0 || force){
                followUp.state = 2;
                followUp.status = 6;
                this.activityOfflineService.publishActivityEvent({action: "Delete", activity: followUp});
                return this.updateFollowUpActivity(action,[followUp],new Date().getTime());
            }
        }else{
            return Promise.reject('No activity passed for deletion');
        }

    }

    public markFollowUpActivityComplete(action:OperationDetail,followUp:FollowUpActivity): Promise<any> {
        if(followUp){
            if((followUp.ownerId == this.authenticationService.user.systemUserID) || (followUp.assignedTo.some(u=> u.userId == this.authenticationService.user.systemUserID)) ){

                if(followUp.subject && (followUp.accountId || (followUp.scientificPlanId && followUp.contactId)  || followUp.planType === FOLLOW_UP_TYPE.NONE)){
                    if(followUp.state == 0){
                        followUp.state = 1;
                        followUp.status = 5;
                        followUp.statusString = 'Completed';
                        followUp.pendingPushToDynamics = true;
                        action.operationDetail = {
                            code: 'FUT102',
                            message: this.translate.instant('FOLLOW_UP_MARKING_ACIVITY'),
                        }
                        return this.updateFollowUpActivity(action,[followUp],new Date().getTime()).then(succ =>{
                            followUp.pendingPushToDynamics = followUp.pendingPushToDynamics;
                        });
                    }else{
                        return Promise.reject(this.translate.instant('FOLLOW_UP_ALREADY_COMPLETE'));
                    }
                }else{
                    return Promise.reject(this.translate.instant('FOLLOW_UP_NOT_MATCHED_CRITERIA_TO_COMPLETE_ACTIVITY'));
                }
            }else{
                return Promise.reject(this.translate.instant('NOT_AUTHORIZED_TO_SCRAP_ACTIVITY'));
            }
        }else{
            return Promise.reject(this.translate.instant('NO_ACTIVITY_PASSED_FOR_COMPLETION'));
        }
    }

    public async createFollowUpActivity(action:OperationDetail, data:Array<FollowUpActivity>, newLastUpdatedTime:number, isInitialSync:boolean = false):Promise<any> {
        return new Promise(async (resolve,reject)=> {
            if(data.length == 0 ) reject(this.translate.instant('NO_DATA_PASSED_FOR_OPERATION'));
            let checkNextAction:boolean = true;
            if(action.onDynamics){
                if(data && data.length == 1){ //Realtime follow-up-activity creation on dynamics
                    let serviceDTO = data[0].serviceDTO;
                    // console.log(serviceDTO);
                    await this.createFollowUpActivityOnline(serviceDTO).then( info => {
                        // Succesfully created on dynamics
                        if(info && info['activityid']){
                            data[0].ID = info['activityid'];
                            data[0].pendingPushToDynamics = false;
                        }
                        if(!action.onLocalDatabase && !action.onLocalCopy){
                            resolve(this.translate.instant('NO_SUCCESSFULLY_CREATED_ON_SERVER'));
                            checkNextAction = false;
                        }
                    }).catch( error => {
                        // Handle any error scenario
                        //Still Create it as an offline followup activity
                        checkNextAction = true;
                            return;
                    });
                }else{
                    console.log('Got offline data as:'+data);
                    resolve(null);
                    // can be used forbulk data upload on dynamics
                }
            }
            if(action.onLocalDatabase && checkNextAction){
                if(isInitialSync){
                    try {
                        await this.disk.deleteAllFromDbUsingAlldocsQuery(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_FOLLOW_UP_ACTIVITIES);
                        this.disk.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.FOLLOW_UP, 0);
                    } catch (error) {
                        reject(this.translate.instant('ERROR_WHILE_CLEARING_OFFLINE_DB')+error);
                        return;
                    }
                }
                //Initialise document id and get offline DTO for each activity
                if(data){
                    let offlineData = [];
                    for(let i=0;i<data.length;i++){
                        let followUp = data[i];
                        let offlineDTO = followUp.offlineDataDTO;
                        offlineDTO._id = followUp.offlineDBId;
                        offlineDTO.lastUpdatedTime = newLastUpdatedTime;
                        offlineData.push(offlineDTO);
                    };

                    if(offlineData && Array.isArray(offlineData)){
                        try {
                            // Bulk save docs to DB
                            await this.disk.bulk(offlineData);

                            // Track offline data count
                            const offlineCount: number = offlineData.filter(o => o.pendingPushToDynamics).length;
                            if (offlineCount > 0) {
                                this.disk.addOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.FOLLOW_UP, offlineCount);
                            }
                        } catch (error) {
                            reject(this.translate.instant('ERROR_WHILE_SAVING_OFFLINE_DATA') +error);
                            checkNextAction = false;
                        }
                        if(!action.onLocalCopy){
                            resolve(this.translate.instant('SUCCESSFULLY_CREATED_ON_OFFLINE_DB'));
                            checkNextAction = false;
                        }
                    }
                }
            }
            if(action.onLocalCopy && checkNextAction){
                // push into local array of follow-up-activities
                if(data){
                    let callFilterActivities = true;
                    if(action.operationDetail && action.operationDetail.code && (action.operationDetail.code == 'INITIAL_SYNC_DATA_MAPPING' || action.operationDetail.code == 'OFFLINE_DB_DATA_MAPPING')){
                      callFilterActivities = false;
                    }
                    data.forEach(followUp => {
                        this.activityOfflineService.addActivity(followUp,false,false,null,callFilterActivities).then(success => {
                            resolve(this.translate.instant('SUCCESSFULLY_CREATED_FOLLOWUP_ACTIVITY'));
                        }).catch(err => {
                            reject(this.translate.instant('FOLLOW_UP_ERROR_WHILE_SAVING_TO_LOCAL_ARRAY')+err);
                        })
                    })
                }
            }
        });
    }

    public async updateFollowUpActivities(followups: Array<FollowUpActivity>) {
      await this.updateFollowUpActivity({ onDynamics: false, onLocalCopy: true, onLocalDatabase: true }, followups, new Date().getTime());
      const url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.offline.UPLOAD_MEETING;
      let headers = Endpoints.authentication.AUTHENTICATE_USER_STATUS;
      headers.headers = headers.headers.set('X-SystemUserId', this.authenticationService.user.systemUserID);
      const offlineFollowUps = await this.loadOfflineFollowUpActivities();
      const body = {
        offlineFollowUpTasks: offlineFollowUps
      }
      const response = await this.http.post(url, body, headers).toPromise();
      if (response && response['offlineFollowupTasks']) {
        await this.handleOfflinePushResponse(response['offlineFollowupTasks']);
      }
    }

    public async updateFollowUpActivity(action:OperationDetail,data:Array<FollowUpActivity>,newLastUpdatedTime, shouldRefreshWeekView: boolean = true):Promise<any>{
        return new Promise(async (resolve,reject)=> {
            if(data.length == 0 ) reject(this.translate.instant('NO_DATA_PASSED_FOR_OPERATION'));
            let checkNextAction:boolean = true;
            if(action.onDynamics){
                if(data && data.length == 1){ //Realtime follow-up-activity creation on dynamics
                    if(!data[0].ID.includes('offline')){
                        let serviceDTO = data[0].serviceDTO;
                        if(data[0].state == 2 && data[0].status == 6 && !action.operationDetail){
                            await this.scrapFollowUpActivityOnline(data[0]).then(info => {
                                data[0].pendingPushToDynamics = false;
                            }).catch( err => {
                                //reject({message:'Error occured while deleting follow up task on server',error:err});
                                // Handle any error scenario
                                if(err && err['error']){
                                    let errorDetail = err['error'];
                                    if(errorDetail['errorCode']){
                                        if(errorDetail['errorCode'] == 'ERR_IO_ALDY_CMTD_APFT'){
                                            //Delever genee notification that follow up is already completed
                                            this.notificationService.notify(this.translate.instant('THIS_ACTION_DATASUBJECT_COMPLITED',{datasubject:data[0].subject}),'Followup Data Service');
                                            // Send error code to component to call the real time detail fetch service from activity data service
                                            reject({
                                                errorCode:'ALREADYCOMPLETEDACTIVITY',
                                                message: this.translate.instant('THIS_FOLLOW_UP_ALREADY_COMPLETED_BY_OTHER_USER')
                                            });
                                            checkNextAction = false;
                                            data[0].pendingPushToDynamics = false;
                                        }
                                        //Clear offline status for uploaded notes
                                        // data[0].followUpNotes.forEach(note=>{
                                        //     if(note.noteId.includes('offline')){
                                        //         note.noteId = note.noteId.replace('offline','');
                                        //     }
                                        // })

                                      let notesArrayFromResponse = errorDetail['notes'];
                                      let followUpNotes = this._offlineNotesParsing(notesArrayFromResponse);

                                      data[0].followUpNotes = followUpNotes;
                                    }
                                }
                                checkNextAction = true;
                            });
                        } else {
                          await this.UpdateFollowUpActivityOnline(serviceDTO).then(info => {
                            // Succesfully updated on dynamics
                            if (info && info['activityid']) {
                              data[0].pendingPushToDynamics = false;
                              // Update offline notes id

                              let notesArrayFromResponse = info['notes'];

                              let followUpNotes = [];
                              if ( notesArrayFromResponse && _.isArray(notesArrayFromResponse)) {

                                notesArrayFromResponse.map(noteFromResponse => {
                                  if (noteFromResponse.hasOwnProperty('deleted') && !noteFromResponse['deleted']) {
                                    noteFromResponse['isdocument'] = this._notehasDocument(noteFromResponse);
                                    followUpNotes.push(new IONote(noteFromResponse));
                                  }
                                });
                              }
                              data[0].followUpNotes = followUpNotes;
                            }
                            if (!action.onLocalDatabase && !action.onLocalCopy) {
                              resolve('Successfully updated on server');
                              checkNextAction = false;
                            }
                          }).catch(async err => {
                            //reject({message:'Error occured while updating follow up task on server',error:err});
                            // Handle any error scenario
                            if (err && err['error']) {
                              let errorDetail = err['error'];
                              if (errorDetail['errorCode']) {
                                if (errorDetail['errorCode'] == 'ERR_IO_ALDY_CMTD_APFT') {
                                  // Already completed task or Creating/Updating Task is failed but Notes are uploaded
                                  if (!(action.operationDetail && action.operationDetail.code == 'FUAANT101')) {
                                    //Delever genee notification that follow up is already completed
                                    this.notificationService.notify(this.translate.instant('THIS_ACTION_DATASUBJECT_COMPLITED', { datasubject: data[0].subject }), 'Followup Data Service');
                                  }
                                  // Send error code to component to call the real time detail fetch service from activity data service
                                  reject({
                                    errorCode: 'ALREADYCOMPLETEDACTIVITY',
                                    message: this.translate.instant('THIS_FOLLOW_UP_ALREADY_COMPLETED_BY_OTHER_USER')
                                  });
                                  checkNextAction = false;
                                  data[0].pendingPushToDynamics = false;
                                  //Clear offline status for uploaded notes
                                  // data[0].followUpNotes.forEach(note => {
                                  //   if (note.noteId.includes('offline')) {
                                  //     note.noteId = note.noteId.replace('offline', '');
                                  //   }
                                  // })

                                  let notesArrayFromResponse = errorDetail['notes'];
                                  let followUpNotes = this._offlineNotesParsing(notesArrayFromResponse);

                                  data[0].followUpNotes = followUpNotes;
                                } else if (errorDetail['errorCode'] == 'ERR_IO_ADFT') {
                                  // Already deleted task so everything fails and delete it from app as well
                                  this.notificationService.notify(this.translate.instant('THIS_TASK_DATASUBJECT_SCRAPPED', { datasubject: data[0].subject }), 'Followup Data Service')
                                  data[0].pendingPushToDynamics = false;
                                  // Send error code to component to call the real time detail fetch service from activity data service
                                  reject({
                                    errorCode: 'ALREADYSCRAPPEDACTIVITY',
                                    message: this.translate.instant('THIS_FOLLOW_UP_ALREADY_SCRAPPED_BY_OWNER')
                                  });
                                  this.scrapFollowUpActivity({ onDynamics: false, onLocalDatabase: true, onLocalCopy: true, operationDetail: { code: 'FUP_103', message: 'Hard Refresh Agenda' } }, data[0], true);
                                  checkNextAction = false;
                                } else if (errorDetail['errorCode'] == 'ERR_IO_NOTE_APFT') {
                                  // Creating/Updating Task is success but uploading notes get failed
                                } else if (errorDetail['errorCode'] == 'ERR_IO_ALDY_CMTD_NOTE_APFT') {
                                  // Creating/Updating Task is failed and uploading notes get failed
                                  if (!(action.operationDetail && action.operationDetail.code == 'FUAANT101')) {
                                    //Delever genee notification that follow up is already completed
                                    this.notificationService.notify(this.translate.instant('THIS_TASK_DATASUBJECT_ALREADY_COMPLETED', { datasubject: data[0].subject }), 'Followup Data Service');
                                    // Send error code to component to call the real time detail fetch service from activity data service
                                    reject({
                                      errorCode: 'ALREADYCOMPLETEDACTIVITY',
                                      message: this.translate.instant('THIS_FOLLOW_UP_ALREADY_COMPLETED_BY_OTHER_USER')
                                    });
                                    checkNextAction = false;
                                    data[0].pendingPushToDynamics = false;
                                  }
                                }
                              }
                            }
                            checkNextAction = true; // As per current expected behaviour still push the updates to offline db
                            if (action.operationDetail && action.operationDetail.code == 'FUT101') {
                              // Update assigned to field is online only feature so dont commit to offline db after request failure
                              checkNextAction = false;
                              reject(action.operationDetail.message + ' failed because of error: ' + err);
                            }


                          });
                        }
                    }else{
                        // Check whether to push activity online or not
                    }
                }else{
                    // can be used forbulk data upload on dynamics
                }
            }
            if(action.onLocalDatabase && checkNextAction){
                let offlineData = [];
                for(let i=0;i<data.length;i++){
                    let followUp = data[i];
                    let offlineDTO = followUp.offlineDataDTO;
                    // Handle Deleted activities and track action
                    if(action.onDynamics){ // Will reach here only if the updates have been pushed to dynamics and we can remove the object from offline db
                        if(followUp.state == 2 && followUp.status == 6 && !action.operationDetail){
                            offlineDTO._deleted = true;
                        }
                    }
                    offlineDTO._id = followUp.offlineDBId;
                    let savedOfflineObject  = await this.disk.retrieve(followUp.offlineDBId);
                    if(savedOfflineObject){
                        offlineDTO._id = savedOfflineObject['_id'];
                        offlineDTO._rev = savedOfflineObject['_rev'];
                    }else{
                      // this is new follow up task, adding for notification
                      this.addFollowUpNotification(followUp).catch((err) => console.error(err));
                    }
                    if(action.operationDetail && action.operationDetail.code == 'FUPDEL101' && savedOfflineObject && savedOfflineObject.pendingPushToDynamics && followUp.state == 1){
                      // Offline completed followup that got pushed to dynamics but didn't get updated in local db
                      offlineDTO.pendingPushToDynamics = false;
                      offlineDTO.lastUpdatedTime = newLastUpdatedTime;
                      offlineData.push(offlineDTO);
                      this.disk.subtractOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.FOLLOW_UP, 1);
                    }else if(action.operationDetail && action.operationDetail.code == 'FUPDEL101' && savedOfflineObject && savedOfflineObject.pendingPushToDynamics){
                    // In delta Sync follow up update, do not update activities that have pending changes to be pushed
                        checkNextAction = false;
                        resolve(null);
                    }else if(action.operationDetail && action.operationDetail.code == 'HARDDELETE' && savedOfflineObject){
                        savedOfflineObject = getDeletedPayloadObejct(savedOfflineObject);
                        offlineData.push(getDeletedPayloadObejct(savedOfflineObject));

                        // If a new update in offline, add the offline data count
                        if (!savedOfflineObject.pendingPushToDynamics && offlineDTO.pendingPushToDynamics) {
                            this.disk.addOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.FOLLOW_UP, 1);
                        }
                    }else{
                        offlineDTO.lastUpdatedTime = newLastUpdatedTime;
                        offlineData.push(offlineDTO);

                        // If a new update in offline, add the offline data count
                        if ((savedOfflineObject && !savedOfflineObject.pendingPushToDynamics && offlineDTO.pendingPushToDynamics)
                            || (!savedOfflineObject && offlineDTO.pendingPushToDynamics)) {
                            this.disk.addOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.FOLLOW_UP, 1);
                        }
                    }
                }
                if(offlineData.length > 0){
                    try {
                        // Bulk save docs to DB
                        await this.disk.bulk(offlineData);
                    } catch (error) {
                        reject('Error Occured while updating offline data'+error);
                        checkNextAction = false;
                    }
                    if(!action.onLocalCopy){
                        resolve('Successfully updated in offline DB');
                        checkNextAction = false;
                    }
                }
            }
            if(action.onLocalCopy && checkNextAction){
                if(data){
                  let callFilterActivities = true;
                  if(action.operationDetail && action.operationDetail.code && action.operationDetail.code == 'FUPDEL101'){
                    callFilterActivities = false;
                  }
                    data.forEach(followUp => {
                        if(action.appendActivityDetails){
                            let contacts: Array<Contact> = [];
                            // Append Contacts data to local object
                            if (followUp.contactId && this.contactOfflineService.loadedContacts) {
                                let tmpContact = this.contactOfflineService.getContactByID(followUp.contactId);
                                if (tmpContact) {
                                    followUp.contactNameString = tmpContact.fullName;
                                }
                            }
                        }
                        if(followUp.state == 2 && followUp.status == 6 && !action.appendActivityDetails && !action.operationDetail){// If follow up activity is deleted or track action is deletion one
                            if(this.activityOfflineService.selectedActivity && this.activityOfflineService.selectedActivity.type == ActivityType.FollowUp && this.activityOfflineService.selectedActivity.ID == followUp.ID){
                                this.activityOfflineService.selectedActivity = null;
                                this.uiService.activeView = '';
                            }
                            this.activityOfflineService.removeActivity(followUp,false,null,callFilterActivities).then(success => {
                                resolve('Successfully updated follow up activity');
                            }).catch(err => {
                                reject('Error Occured while updating follow up activity into local array'+err);
                            })
                        }else{
                            this.activityOfflineService.addActivity(followUp,true,false,null,callFilterActivities).then(success => {
                                resolve('Successfully updated follow up activity');
                            }).catch(err => {
                                reject('Error Occured while updating follow up activity into local array'+err);
                            })
                        }
                        if(action.operationDetail && (action.operationDetail.code == 'FUP_103' || action.operationDetail.code == 'HARDDELETE')){
                          if (!this.uiService.toolsActivityActive){
                            this.events.publish('refreshAgenda');
                          } else this.uiService.agendaRefreshRequired = true;
                        }
                        // Need to refresh UI on the week view tab
                        console.log('Followup Data Service -> update Follow up activity ->refreshing week view ' + new Date());
                        if (shouldRefreshWeekView) {
                          // this.events.publish('weekview:RefreshUI');
                          if (followUp.state !=2 && followUp.status !=6) {
                            this.activityOfflineService.publishActivityEvent({action: "Update", activity: followUp});
                          }
                        }
                    })
                }
            }
        });
    }

  public async addFollowUpNotification(followUp: FollowUpActivity) {
    if (followUp) {
      if(!isValid(followUp.created)){
        followUp.created = undefined;
      }
      const displayName = this.translate.instant("FOLLOW_UP_ACTION");
      this.followUpNotificationModel = {
        type: NOTIFICATION.NEW_FOLLOW_UP_NOTIFICATION,
        name: displayName,
        DateTime: Date.now(),
        id: NOTIFICATION.NEW_FOLLOW_UP_NOTIFICATION + Date.now(),
        data: followUp,
        icon: 'assets/imgs/follow-up_32x32.svg',
        isRed: false,
        params: { followUp: displayName }
      }
      this.myAssistantService.saveNotificationToDisk(this.followUpNotificationModel);
    }
  }

    public async handleDeletedTrackActionForFollowUpTask(raw) {
        if(raw && raw['activityid']){
            let followUp = (this.activityOfflineService.getActivityByID(raw['activityid']) as FollowUpActivity);
            if(!followUp){// Search for the activity in offline db
                let option = {
                    selector: {
                        '_id': {
                            $gte: DB_KEY_PREFIXES.FOLLOW_UP_ACTIVITY,
                            $lte: DB_KEY_PREFIXES.FOLLOW_UP_ACTIVITY + PREFIX_SEARCH_ENDKEY_UNICODE
                        },
                        'activityid': {
                            $eq: raw['activityid']
                        },
                    }
                };
                const rawFollowups: any[] = await this.disk.find(option);
                if(rawFollowups && Array.isArray(rawFollowups) && rawFollowups.length > 0){
                    followUp = new FollowUpActivity(rawFollowups[0]);
                }
            }
            if(followUp){
                this.scrapFollowUpActivity({onDynamics:false,onLocalDatabase:true,onLocalCopy:true,operationDetail:{code:'HARDDELETE',message:'Hard Deletion Of Follwuptask'}},followUp,true);
            }
        }
    }

    public getActivitySecondaryTextStringForFollowup(activity: FollowUpActivity): string {
        let str:string = '';
        if (activity.ownerId == this.authenticationService.user.systemUserID) {
            if (activity.assignedTo && activity.assignedTo.length > 0) {
                if(activity.assignedTo.some(u => u.userId == this.authenticationService.user.systemUserID)){
                    if(activity.assignedTo.length == 1){
                        str = this.translate.instant('ME');
                    // }else if (activity.assignedTo.length == 2) {
                    //     if(activity.assignedTo[0].userFullName == this.authenticationService.user.displayName){
                    //         str = 'Me + '+activity.assignedTo[1].userFullName;
                    //     }else{
                    //         str = 'Me + '+activity.assignedTo[0].userFullName;
                    //     }
                    } else {
                        str = this.translate.instant('ME')+ ' +' + (activity.assignedTo.length - 1);
                    }
                }else if (activity.assignedTo.length == 1) {
                    str = activity.assignedTo[0].userFullName;
                } else {
                    str = activity.assignedTo[0].userFullName+' +' + (activity.assignedTo.length - 1);
                }
            } else {
                // str = this.translate.instant('ME');
                str = this.authenticationService.user.displayName;
                // console.log(str);
            }
        } else if (activity.assignedTo.length > 0 && activity.assignedTo.some(u => u.userId == this.authenticationService.user.systemUserID)) {
            if (activity.assignedTo.length == 1) {
                str =this.translate.instant('ME');
            // } else if (activity.assignedTo.length == 2) {
            //     if(activity.assignedTo[0].userFullName == this.authenticationService.user.displayName){
            //         str = 'Me + '+activity.assignedTo[1].userFullName;
            //     }else{
            //         str = 'Me + '+activity.assignedTo[0].userFullName;
            //     }
            } else {
                str = this.translate.instant('ME')+ ' +' + (activity.assignedTo.length - 1);
            }
        }
        else {
            if (activity.assignedTo && activity.assignedTo.length > 0) {
                if (activity.assignedTo.length == 1) {
                    str = activity.assignedTo[0].userFullName;
                } else {
                    str = activity.assignedTo[0].userFullName+' +' + (activity.assignedTo.length - 1);
                }
            }else{
                str = activity.ownerNameString;
            }
        }
        // return this.translate.instant('COACHING_FOR')+' ' + str;
        return str;
    }

    public async fetchFollowupActionObjectives(loadFromDBOnly: boolean) {
      // ! this should be followup FA
      if(!this.authenticationService.hasFeatureAction(FeatureActionsMap.FOLLOW_UP_ACTION_OBJECTIVE_SELECTION)) return;
      if (loadFromDBOnly) {
        await this.disk.retrieve(DB_KEY_PREFIXES.FOLLOWUP_ACTION_OBJECTIVES).then((data) => {
          if (data && data['raw']) {
              this.followupActionObjectives = data['raw'];
              console.log(`Follow up action objectives from disk : ${data['raw'].length}`);
          }
      }).catch((err) => console.error("loadEventsFromDB: Error saving events data to offline db!", err));
      } else {
        return await this.dynamics.executeFetchQuery('indskr_followupactionobjectiveses', fetchQueries.folloupactionObjectives).then(async (response) => {
          this.followupActionObjectives = response;
          await this.disk.updateOrInsert(DB_KEY_PREFIXES.FOLLOWUP_ACTION_OBJECTIVES, doc => ({ raw: response })).catch((err) => console.error("syncEvents: Error saving follow up action objectives data to offline db!", err));
        })
      }
    }

    public async saveFollowupActionObjectives(payload, currentFollowUpActivity) {
      if (!this.deviceService.isOffline) {
        const url: string = (this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.followup.SAVE_FOLLOW_UP_OBJECTIVES).replace('{followupid}', currentFollowUpActivity.ID);
        await this.http
          .put(url, payload)
          .toPromise().catch((err) => console.error(err));
          currentFollowUpActivity.pendingPushToDynamics = false;
        }
        await this.updateFollowUpActivity({ onDynamics: false , onLocalCopy: true, onLocalDatabase: true }, [currentFollowUpActivity] , new Date().getTime());
      // await this.updateFollowUpActivity(this.activityOfflineService.selectedActivity, false, false)
    }

}

function getDeletedPayloadObejct (raw){
    let responsePayload = {
        _id: raw._id,
        _rev: raw._rev,
        offlineId: raw.offlineId,
        statecode: raw.statecode,
        statuscode:raw.statuscode,
        _deleted: true,
        pendingPushToDynamics:false,
    };
    if(raw['activityid']){
        responsePayload['activityid'] = raw['activityid'];
    }
    return responsePayload;
}

export interface OperationDetail {
    onDynamics:boolean;
    onLocalDatabase:boolean;
    onLocalCopy:boolean;
    appendActivityDetails?:boolean;
    operationDetail?:{
        code:string;
        message:string;
        payload?: {};
    }
}

export interface FollowUpAssignedUserModel {
    fullname:string;
    systemuserid:string;
    ownerid:string;
    internalemailaddress?:string;
    businessunitname?:string;
}

