import { ProcedureTrackerActivity } from './../../../classes/activity/procedure-tracker.activity.class';
import { isAfter, isBefore, isSameMonth, isSameWeek, isSunday, endOfMonth } from 'date-fns';
import { MultiSelectPopover } from '@omni/components/multi-select-popover/multi-select-popover';
import { unsubscribeSubscriptionArray } from '@omni/utility/common.utility';
import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, SimpleChanges, ViewChild, AfterViewInit, ChangeDetectorRef, Inject, Renderer2 } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { MbscEventcalendarComponent, MbscEventcalendarOptions } from '@mobiscroll/angular';
import { TranslateService } from '@ngx-translate/core';
import { Events } from '@omni/events';
import { addMonths, addWeeks, parse, subWeeks, addDays } from 'date-fns';
import { DxSchedulerComponent } from 'devextreme-angular/ui/scheduler';
import { Subscription, Subject, BehaviorSubject, combineLatest } from 'rxjs';
import { debounceTime, filter, skip } from 'rxjs/operators';
import { Activity, ActivityType } from '../../../classes/activity/activity.class';
import { Appointment } from '../../../models/appointment-data-model';
import { ActivityService } from '../../../services/activity/activity.service';
import { DeviceService } from '../../../services/device/device.service';
import { GlobalUtilityService } from '../../../services/global-utility.service';
import { NavigationService, PageName } from '../../../services/navigation/navigation.service';
import { TimeOffService } from '../../../services/time-off/time-off.service';
import { UIService } from '../../../services/ui/ui.service';
import { NewActivityComponent } from '../new-activity/new-activity';
import { Scroller } from '@mobiscroll/angular/src/js/classes/scroller';
import { IActivityEvent, IActivityFilterEvent } from './../../../interfaces/activity/activity-event.interface';
import DataSource from 'devextreme/data/data_source';
import ArrayStore from 'devextreme/data/array_store';
import { orderBy } from 'lodash';
import { locale, loadMessages } from 'devextreme/localization';
import { DateTimeFormatsService } from '@omni/services/date-time-formats/date-time-formats.service';
import { CALENDAR_COLOR_CODE_RESOURCES } from '../../../config/activity/activity.config';
import { DOCUMENT } from '@angular/common';
import { CaseActivity } from '@omni/classes/case-intake/case-activity.class';
import { AppointmentActivity } from '@omni/classes/activity/appointment.activity.class';
import { EmailActivity } from '@omni/classes/activity/email.activity.class';
import { PhoneActivity } from '@omni/classes/activity/phone.activity.class';
import { FollowUpActivity } from '@omni/classes/activity/follow-up-action.activity.class';
import { OrderActivity } from '@omni/classes/activity/order.activity.class';
import { SampleActivity } from '@omni/classes/activity/sample.activity.class';
import { SurgeryOrderActivity } from '@omni/classes/activity/surgery-order.activity.class';
import { TimeOffActivity } from '@omni/classes/activity/timeoff.class';
import { AuthenticationService } from '@omni/services/authentication.service';
import { FeatureActionsMap } from '@omni/classes/authentication/user.class';

export type CalendarViewType = 'day' | 'three-days' | 'week' | 'month';
export type CalendarTypeOption = 'week' | 'month';
const MOBISCROLL_LG_WIDTH_BREAKPOINT: number = 1921;
const MOBISCROLL_LG_HEIGHT: number = 152;
const MOBISCROLL_SM_HEIGHT: number = 123;

@Component({
    selector: 'activity-day-view',
    templateUrl: 'activity-day-view.html',
  styleUrls:['activity-day-view.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActivityDayViewComponent implements OnInit, AfterViewInit, OnDestroy {
  mbscData: Appointment[] = [];
  dataSource: DataSource;
  views: CalendarViewType[] = ['day', 'week', 'month'];
  currentView: CalendarViewType = 'day';
  currentViewLabel: string = '';
  dayViewIntervalCount: number = 1;
  threeDaysViewCurrentDate: Date;
  threeDaysViewMonthYearLabel: string;
  currentView$: BehaviorSubject<CalendarViewType> = new BehaviorSubject(this.currentView);
  currentDate: string | number | Date = new Date();
  viewOptionsPopoverHandle;
  viewOptionsPopoverData;
  shouldHideScheduler: boolean = false
  loaderCounter: number = 0;
  transitionView: boolean = false;
  private hasSuggestedMeetingsEnabled: boolean;
  private hasSuggestedWeComMessagesEnabled: boolean;

  private _onSetDate$: Subject<{ date: Date; control: 'calendar' | undefined }> = new Subject();
  private _onPageChange$: Subject<{ firstDay: Date }> = new Subject();
  private _dataRangeFrom: Date;
  private _dataRangeTo: Date;
  private _isUpdatingCalendar = false;
  private _isGoingToDayViewViaClick = false;
  private _isGoingFromMonthViewViaOption = false;
  private _shouldIgnoreNextPageChange = false;
  private _isClickedActivity: boolean = false;
  private _isClickedEmptyCell: boolean = false;
  private _mbscDataRefreshCounter: number = 0;
  private _mbscDataRefreshRequest$: Subject<any> = new Subject();
  private _dataSourceInitDone: boolean = false;
  private _redrawAwaitIntervalId: any = null;
  private _threeDaysViewEventListeners: any[] = [];
  private _refreshSignal$: Subject<boolean> = new Subject();
  private readonly THREE_DAYS_IN_MILLISECONDS: number = 259200000;

    readonly priorities: { id: number, color: string }[];
    scrollDate: Date;
    selectedLocale: string;
    private _subscriptions: Subscription[] = [];
    private _uiRefreshRequest$: Subject<boolean> = new Subject();
    private _uiRefreshEventSub: () => void = () => {
      this._uiRefreshRequest$.next(true);
    }
    private _refreshWeekviewHandler: () => void = async () => {
      this.scheduler?.instance?.repaint();
      this._scrollToCell(this._scrollTargetDate, this._isAllDayActivity);
      this.events.publish('updateActivitiesRHSEmptyPage', this.dataSource?.items()?.length > 0 ? false : true);
    };
    private _syncCompleteHandler: () => void = async () => {
      await this.uiService.displayLoader();

      this.activityService.filterDisplayActivity();
      this._setLoaderCounter(1);
      this.cd.detectChanges();
      this._initSchedulerDataSource(this.currentDate as Date);
      this.cd.markForCheck();

      await this.uiService.dismissLoader();
    }
    private _refreshCalendarSignalHandler = () => {
      this._refreshSignal$.next(true);
    }
    private _refreshCalendarViewHandler: () => void = async () => {
      if (this._isLocaleChanged) {
        this._isLocaleChanged = this._isTimeFormatChanged = false;
        this._initViewOptionsPopoverData();
        this._initMobiscroll();
        this._setLoaderCounter(3);
        this.cd.detectChanges();
        this._initSchedulerDataSource(this.currentDate as Date);
        if (this.currentView != 'month') {
          this.shouldHideScheduler = false;
          this._updateSchedulerOptionsForRefreshCalendarView();
        } else {
          this.shouldHideScheduler = true;
          this.currentView$.next('month');
        }
        this.cd.markForCheck();
      } else if (!this._isLocaleChanged && this._isTimeFormatChanged) {
        this._isTimeFormatChanged = false;
        if (this.currentView != 'month') {
          this.shouldHideScheduler = false;
          this._updateSchedulerOptionsForRefreshCalendarView();
        } else {
          this.shouldHideScheduler = true;
        }
      } else {
        this._initSchedulerDataSource(this.currentDate as Date);
        if (this.currentView != 'month') {
          this.shouldHideScheduler = false;
          this._updateSchedulerOptionsForRefreshCalendarView();
          this.events.publish('updateActivitiesRHSEmptyPage', this.dataSource?.items()?.length > 0 ? false : true);
        } else {
          this.shouldHideScheduler = true;
          this.currentView$.next('month');
        }
      }
    };
    @ViewChild(DxSchedulerComponent, {static:true}) scheduler: DxSchedulerComponent;
    @ViewChild('mobiscroll', {static:true}) mbscEventCalendar: MbscEventcalendarComponent;
    calendarOptions: MbscEventcalendarOptions;

    @Input() weekViewSearchText = '';
    locale: string;
    private _isActivityEvent: boolean = false;
    private _scrollTargetDate: Date;
    private _isAllDayActivity: boolean = false;
    private _isTimeFormatChanged: boolean = false;
    private _isLocaleChanged: boolean = false;
    private _currentWeekFirstDay: Date;
    private _delayedInit: boolean = false;

    constructor(
        public activityService: ActivityService,
        public events: Events,
        public deviceServices: DeviceService,
        protected uiService: UIService,
        public popoverCtrl: PopoverController,
        protected navService: NavigationService,
        private readonly timeOffService: TimeOffService,
        private readonly translate: TranslateService,
        public utilityService: GlobalUtilityService,
        public deviceService: DeviceService,
        public dateTimeFormatsService: DateTimeFormatsService,
        private cd: ChangeDetectorRef,
        @Inject(DOCUMENT) private document: Document,
        private renderer2: Renderer2,
        private authService: AuthenticationService
    ) {
        // Reset it when init
        // this.activityService.isActivityUpdate = false;
        this.uiService.cellData = undefined;
        // locale for DxScheduler(this.locale) and Mobiscroll Event Calendar(this.selectedLocale)
        this.locale = this.selectedLocale = this.translate.currentLang == 'zh_CN' ? 'zh': this.translate.currentLang;
        this._dxSchedulerLocale();
        this.translate.onLangChange.subscribe(data => {
          if (data) {
            this._isLocaleChanged = true;
            this.locale = this.selectedLocale = this.translate.currentLang == 'zh_CN' ? 'zh': this.translate.currentLang;
            this._dxSchedulerLocale();
            this._calculateWidth();
          }
        });
        this._subscriptions.push(
          this.dateTimeFormatsService.timeFormat.subscribe(tf => {
            if (tf) {
              this._isTimeFormatChanged = true;
              this._calculateWidth();
            }
        }));
        this.hasSuggestedMeetingsEnabled = this.authService.hasFeatureAction(FeatureActionsMap.SHOW_SUGGESTED_MEETINGS);
        this.hasSuggestedWeComMessagesEnabled = this.authService.hasFeatureAction(FeatureActionsMap.SHOW_SUGGESTED_MEETINGS);
        const tempDate = this.activityService.getScrollDate;
        if (tempDate !== undefined) { // use the date the user scroll into otherwise use todays date as default
            const temp: Date = parse(tempDate);
            this.scrollDate = temp;
            this.currentDate = temp;
        }

        this.uiService.selectedCalendarDateInWeekView = new Date(this.currentDate as Date);
        this._updateThreeDaysViewCurrentDate(this.currentDate as Date);

        // DX scheduler init
        this.priorities = JSON.parse(JSON.stringify(CALENDAR_COLOR_CODE_RESOURCES));
        this._setLoaderCounter(3);
        this._initSchedulerDataSource();

        // Mobiscroll init
        this._initViewOptionsPopoverData();
        this._initMobiscroll();

        events.unsubscribe('weekview:RefreshUI');
        events.subscribe('weekview:RefreshUI', this._uiRefreshEventSub);
        // events.unsubscribe('sync:completed');
        events.subscribe('sync:completed', this._syncCompleteHandler);
        events.unsubscribe('calendarView:RefreshUI');
        events.subscribe('calendarView:RefreshUI', this._refreshCalendarViewHandler);
        events.unsubscribe('tools-back-clicked');
        events.subscribe('tools-back-clicked', this._refreshCalendarSignalHandler);

        this._subscriptions.push(
          combineLatest([
            this.activityService.refreshAgendaStateObservable,
            this._refreshSignal$.asObservable()
          ]).pipe(
            filter(([state, refreshSignal]) => state === 'Done' && refreshSignal)
          ).subscribe(([state, refreshSignal]) => {
            this._refreshSignal$.next(false);
            this._refreshCalendarViewHandler();
          })
        );

        this._subscriptions.push(
          this._uiRefreshRequest$
          .pipe(debounceTime(250))
          .subscribe(() => {
            this._refreshWeekviewHandler();
          })
        );

        // Screen width height change handler
        this._subscriptions.push(
          combineLatest([
            this.deviceService.screenWidth,
            this.deviceService.screenHeight,
            this.navService.currentPageStack$,
          ]).pipe(
            skip(1),
            debounceTime(200),
            filter(([w,h,p])=>(
              this.navService.getCurrentPageStack()?.pageName === PageName.ActivitiesPageComponent
              && this.navService.getCurrentPageStack()?.masterPageName === PageName.ActivitiesPageComponent
              && !(
                this.deviceService.isMobileDevicePortrait
                && this.uiService.showRightPane
              )
            )),
          ).subscribe(([width, height]) => {
            if (this.scheduler) {
              // Width change can trigger height change because of some CSS media query rules
              // Hence, update height as well when width changes.
              const newWidth = this._calculateWidth();
              const newHeight = this._calculateHeight();
              this.scheduler.instance.option({
                width: newWidth,
                height: newHeight,
              });
              // update mobiscroll calendar height
              this._resizeCalendarView(newHeight);
              setTimeout(() => {
                this.cd.detectChanges();
              }, 20);
            }
          })
        );

        // Handle current view changes
        this._subscriptions.push(
          this.currentView$.asObservable()
          .pipe(
            skip(1),
          )
          .subscribe((newCurrentView: CalendarViewType) => {
            // update current view value
            const prevView = this.currentView;
            this.currentView = newCurrentView;
            this.currentViewLabel = this._getCurrentViewLabel(newCurrentView);
            this.viewOptionsPopoverData[0].value = newCurrentView;
            this.transitionView = false;

            // Hide scheduler ASAP
            if (this.currentView === 'month') {
              this.shouldHideScheduler = this.currentView === 'month' ? true : false;
              this.cd.detectChanges();
            }

            // update mobiscroll calendar type
            const calendarType: CalendarTypeOption = newCurrentView === 'month' ? 'month' : 'week';
            this.mbscEventCalendar.instance.option({
              view: {
                calendar: {
                  type: calendarType,
                },
              }
            });
            if (prevView === 'month') this.cd.detectChanges();
            // update mobiscroll calendar height
            const height: number = window.innerHeight;
            this._resizeCalendarView(height);
            this.mbscEventCalendar.instance.setDate(this.currentDate as Date);
            // Refresh the mobiscroll calendar
            // This is the only workaround I found so far
            // that can properly refresh the calendar
            // Let's find a better way later.
            if (
              prevView === 'week'
              ||
              (
                (prevView === 'month' || prevView === 'day')
                && newCurrentView === 'week'
              )
            ) {
              setTimeout(() => {
                this.mbscEventCalendar.instance.hide(true);
                this.mbscEventCalendar.instance.show(true);
              }, 100);
            }

            // Un-hide scheduler if needed
            if (
              this.currentView === 'three-days'
              && prevView === 'month'
            ) {
              this.shouldHideScheduler = false;
            }
            this._updateSchedulerOptions();
          })
        );

        // Handle activity CRUD events
      this._subscriptions.push(
        this.activityService.activityEvent$
          .subscribe((event: IActivityEvent) => {
            if (event?.activity &&
              ((event.activity.type === ActivityType.Appointment
                && event.activity['indskr_suggest']
                && !this.hasSuggestedMeetingsEnabled)
                || (event.activity.type === ActivityType.Email
                  && event.activity['indskr_suggest'] && !this.hasSuggestedWeComMessagesEnabled))) {
            } else {
              this._activityEventHandler(event);
            }
          })
        );

        // Handle activity data refresh event
        this._subscriptions.push(
          this.activityService.activityDataFilterEvent$
          .pipe(
            debounceTime(250),
          )
          .subscribe((event: IActivityFilterEvent) => {
            if (event.filterType === 'teamView') {
              this._setLoaderCounter(2);
              this.cd.detectChanges();
              this._initSchedulerDataSource(this.currentDate as Date ?? undefined);
              this._refreshWeekviewHandler();
            } else if (event.filterType === 'activityType') {
              this._setLoaderCounter(1);
              this.cd.detectChanges();
              this._initSchedulerDataSource(this.currentDate as Date);
            }
            this.cd.markForCheck();
          })
        );
    }

    ngOnInit(){
      this.timeOffService.setMobileTracker('week');
    }
    ngAfterViewInit() {
      let delay = 0;
      if (this._delayedInit) {
        delay = 60;
      }
      setTimeout(() => {
        // Defer the init & repaint to afterInit lifecycle so that the dynamic height calculation can detect the actual
        // content DOM height of current view and not the previous view
        this._initDxSchedulerOptions();
        this.scheduler?.instance?.repaint();
      }, delay);
    }
    ngAfterViewChecked() {
      // TODO: Need to revisit later to replace this logic
      if (this.activityService.isNewActivityCreated && this.activityService.selectedActivity) {
        this.activityService.isNewActivityCreated = false;
        this.events.publish('activity-pane:selectActivity', this.activityService.selectedActivity);
      }
    }
    ngOnChanges(changes: SimpleChanges) {
        try {
          if (changes) {
            if (changes.weekViewSearchText
              && changes.weekViewSearchText.previousValue !== undefined
              && (changes.weekViewSearchText.currentValue !== changes.weekViewSearchText.previousValue || changes.weekViewSearchText.firstChange)
            ) {
              const searchTxt: string = changes?.weekViewSearchText?.currentValue ?? '';
              this._searchByActivitySubject(searchTxt);
            }
          }
        }
        catch (err) {
          console.error(err);
        }
    }
    ngOnDestroy() {
        this.events.unsubscribe('weekview:RefreshUI', this._uiRefreshEventSub);
        // this.events.unsubscribe('sync:completed', this._syncCompleteHandler);
        this.events.unsubscribe('calendarView:RefreshUI', this._refreshCalendarViewHandler);
        this.events.unsubscribe('tools-back-clicked', this._refreshCalendarSignalHandler);
        unsubscribeSubscriptionArray(this._subscriptions);
        this._removeThreeDaysViewEventListeners();
        if (this._redrawAwaitIntervalId !== null) {
          clearInterval(this._redrawAwaitIntervalId);
        }
    }
    /**
     *
     *
     * @param {any} e
     * @memberof ActivityDayViewComponent
     */
    onContentReady(e) {
        if (this.currentView != "month") {
          if (!this._isAllDayActivity && !this._isClickedActivity && !this._isActivityEvent) {
            let filterItemsForScroll = e.component.getFilteredItems();
            filterItemsForScroll = filterItemsForScroll.filter(rawData => !rawData.allDay);
            if (filterItemsForScroll.length != 0) {
              filterItemsForScroll = orderBy(filterItemsForScroll, [d=>d.startDate.getHours(), d=>d.startDate.getMinutes()], 'asc');
              this._scrollToCell(filterItemsForScroll[0].startDate);
            } else {
              let scrollTargetHours: number = 7;
              let scrollTargetMinutes: number = 50;
              setTimeout(()=>{
                this.scheduler.instance.scrollToTime(scrollTargetHours, scrollTargetMinutes);
              }, 100);
            }
          } else if (this._scrollTargetDate != undefined) {
            this._scrollToCell(this._scrollTargetDate, this._isAllDayActivity);
          }
        }
        this._isGoingToDayViewViaClick = false;
        this._decreaseLoaderCounter();
        // console.log('### onContentReady: ', this.loaderCounter);
        if (this.currentView === 'three-days') {
          this._removeThreeDaysViewEventListeners();
          this._addEventListenersToThreeDaysViewDateCells();
          this._updateThreeDaysViewCurrentDate(this.currentDate as Date);
        }
    }
    /**
     *
     *
     * @param {any} e
     * @memberof ActivityDayViewComponent
     */
    onCellClick(e) {
        this._isClickedActivity = false;
        this._isActivityEvent = false;
        this._isClickedEmptyCell = true;
        this._scrollTargetDate = undefined;
        //two options , use option changed or the cell clicked to handle the date naviagtion which ever comes first
        this.scrollDate = ((e.cellData !== undefined) && (e.cellData.startDate instanceof Date)) ? new Date(e.cellData.startDate) : undefined;
        // mbscEventCalendar - update highlited date with the seleceted cell on week view
        if (this.currentView && this.currentView == "week") {
          this.mbscEventCalendar.instance.navigate(new Date(this.scrollDate));
        }

        // this.activityService.setScrollDate(this.scrollDate);
        this.uiService.selectedCalendarDateInWeekView = this.scrollDate;
        this.currentDate = this.scrollDate;
        if (this.currentView !== 'three-days') {
          this._updateThreeDaysViewCurrentDate(this.currentDate as Date);
        }
        this.hideAppointmentPopUp(e);
        this.uiService.cellData = { view: 'day-week-views', data: e.cellData };

        if (this.deviceServices.isMobileDevice && this.deviceServices.deviceOrientation === 'PORTRAIT') {
            //this.navService.pushWithPageTracking(NewActivityComponent, PageName.NewActivityComponent);
            this.uiService.showRightPane = true;
        }
        if (!this.activityService.teamViewActive) {
          this.uiService.setShowNewActivity(true);
          if (this.navService.getActiveChildNavViewPageName() != PageName.NewActivityComponent)
            this.navService.pushChildNavPageWithPageTracking(NewActivityComponent, PageName.NewActivityComponent, PageName.ActivitiesPageComponent);
          // update new activity - date and time
          this.events.publish('updatedCellClick');
        }
    }


    /**
     *
     *
     * @memberof ActivityDayViewComponent
     */
    async onAppointmentClick(event){
        event.cancel = true;
        this._isActivityEvent = false;
        this._isClickedEmptyCell = false;
        this._isClickedActivity = true;
        this.uiService.cellData = { view: 'activity-day-week-views', data: {startDate: new Date(event.appointmentData.startDate), endDate: new Date(event.appointmentData.startDate)} };
        this.currentDate = new Date(event.appointmentData.startDate);
        if (this.currentView !== 'three-days') {
          this._updateThreeDaysViewCurrentDate(this.currentDate as Date);
        }
        this.uiService.selectedCalendarDateInWeekView = new Date(this.currentDate);
        if (event && event.appointmentData) {
            if (this.currentView == "week") {
              this.mbscEventCalendar.instance.navigate(new Date(event.appointmentData.startDate));
            }
            const activity = this.activityService.getActivityByID(event.appointmentData.id);
            if (activity) {
              this.events.publish('selectedActivityChangedInBackround');
              this.events.publish('activity-pane:selectActivity', activity);
            }
            //event.appointmentElement.style.border = 'solid 2px green';
            this._scrollTargetDate = new Date(this.currentDate);
            this._isAllDayActivity = event.appointmentData?.allDay;
            this._scrollToCell(this._scrollTargetDate, this._isAllDayActivity);
        }
      }
    /**
     *
     *
     * @memberof ActivityDayViewComponent
     */
    onAppointmentDblClick = (event) => {
        event.cancel = true;
        event.appointmentElement.style.border = undefined;
    }
    onAppointmentAdded = (event) => {
        event.cancel = true;
    }
    onAppointmentFormOpening = (event) => {
        event.cancel = true;
    }

    protected hideAppointmentPopUp = e => {
      this.scheduler.instance.hideAppointmentPopup(false);
    };


    /** ----------------------------------------------------------------------------------------
     *  Event handlers
     */
    private _handleRedrawAfterActivityEventHandle(newActivityObj: Appointment) {
      if (this._redrawAwaitIntervalId === null) {
        this._redrawAwaitIntervalId = setInterval(() => {
          const homeIonNavEl: HTMLElement = this.document?.querySelector('page-home ion-nav');
          const childCount: number = homeIonNavEl?.childElementCount;
          if (homeIonNavEl && !isNaN(childCount) && childCount === 1) {
            if (this.currentView === 'month') {
              this._redrawMobiscroll();
            } else {
              this.scheduler?.instance?.repaint();
              this._isAllDayActivity = newActivityObj?.allDay;
              this._scrollToCell(this._scrollTargetDate, this._isAllDayActivity);
            }
            clearInterval(this._redrawAwaitIntervalId);
            this._redrawAwaitIntervalId = null;
          }
        }, 100);
      } else {
        // If new request comes, clear previous and restart
        clearInterval(this._redrawAwaitIntervalId);
        this._redrawAwaitIntervalId = null;

        this._handleRedrawAfterActivityEventHandle(newActivityObj);
      }
    }
    private async _activityEventHandler(event: IActivityEvent) {
      if (event?.action === 'Update') {
        if (event?.activity?.ID) {
          let newActivityObj: Appointment = this.activityService.formatActivityForCalendar(event.activity);
          let target = await this._getAppointmentById(event.activity.ID);
          if (target) {
            this.scheduler.instance.updateAppointment(target, newActivityObj);
          } else {
            const offlineId: string = this._getOfflineId(event.activity);
            if (offlineId) {
              target = await this._getAppointmentById(offlineId);
              if (target && offlineId != event.activity.ID) {
                this.scheduler.instance.deleteAppointment(target);
                this.scheduler.instance.addAppointment(newActivityObj);
              } else {
                console.warn('activity-day-view: _activityEventHandler: update: no data with offline-id: ', event);
                return;
              }
            } else {
              console.warn('activity-day-view: _activityEventHandler: update: no offline-id: ', event);
            }
          }
          // scroll time and navigate date
          const oldCurrentDate = new Date(this.currentDate);
          this.currentDate = new Date(newActivityObj.startDate);
          this._updateThreeDaysViewCurrentDate(this.currentDate as Date);
          this.uiService.selectedCalendarDateInWeekView = new Date(newActivityObj.startDate);
          this.mbscEventCalendar.instance.navigate(new Date(newActivityObj.startDate));

          // Try to fetch dataSource again if the new date is not in the same week
          if (this._currentWeekFirstDay) {
            const sameWeek = isSameWeek(this._currentWeekFirstDay, oldCurrentDate);
            if (!sameWeek) {
              this._checkAndFetchMoreSchedulerData(this._currentWeekFirstDay);
            }
          }

          this._isActivityEvent = true;
          this._updateSchedulerOptions();
          this._scrollTargetDate = new Date(newActivityObj.startDate);

          this._handleRedrawAfterActivityEventHandle(newActivityObj);
          this._mbscDataRefreshRequest$.next();
        }
      } else if (event?.action === 'Create') {
        if (event?.activity) {
          const target: Appointment = await this._getAppointmentById(event.activity.ID);
          if (!target) {
            const newActivityObj: Appointment = this.activityService.formatActivityForCalendar(event.activity);
            this.scheduler.instance.addAppointment(newActivityObj);
            // scroll time and navigate date
            const oldCurrentDate = new Date(this.currentDate);
            this.currentDate = new Date(newActivityObj.startDate);
            this._updateThreeDaysViewCurrentDate(this.currentDate as Date);
            this.uiService.selectedCalendarDateInWeekView = new Date(newActivityObj.startDate);
            this.mbscEventCalendar.instance.navigate(new Date(newActivityObj.startDate));

            // Try to fetch dataSource again if the new date is not in the same week
            if (this._currentWeekFirstDay) {
              const sameWeek = isSameWeek(this._currentWeekFirstDay, oldCurrentDate);
              if (!sameWeek) {
                this._checkAndFetchMoreSchedulerData(this._currentWeekFirstDay);
              }
            }

            this._isActivityEvent = true;
            this._scrollTargetDate = new Date(newActivityObj.startDate);
            this._updateSchedulerOptions();

            this._handleRedrawAfterActivityEventHandle(newActivityObj);
            this._mbscDataRefreshRequest$.next();
          } else {
            console.warn('activity-day-view: _activityEventHandler: Received creation event of existing activity: ', event);
          }
        }
      } else if (event?.action === 'Delete') {
        if (event?.activity?.ID) {
          const foundActivityObj: Appointment = await this._getAppointmentById(event.activity.ID);
          if (foundActivityObj) {
            this._scrollTargetDate = new Date(foundActivityObj.startDate);
            this.scheduler.instance.deleteAppointment(foundActivityObj);
            this._mbscDataRefreshRequest$.next();

            if (this.currentView === 'month') {
              this._redrawMobiscroll();
            }
          }
        }
      }
    }


    /** ----------------------------------------------------------------------------------------
     *  Calendar view options fns
     */
    // View option btn click handler
    async onCalendarViewOptionBtnClick(event) {
      if (!this.viewOptionsPopoverHandle) {
        this.viewOptionsPopoverHandle = await this.popoverCtrl.create(
          {
            component: MultiSelectPopover,
            componentProps: {
              root: this.viewOptionsPopoverData
            },
            event
          }
        );

        await this.viewOptionsPopoverHandle.present();
        await this.viewOptionsPopoverHandle.onDidDismiss();
        this.viewOptionsPopoverHandle = null;
      }
    }

    private _initViewOptionsPopoverData() {
      const dayLabel = this._getCurrentViewLabel('day');
      this.currentViewLabel = dayLabel;
      this.viewOptionsPopoverData = [
        {
          text: '',
          expanded: true,
          value: this.currentView,
          items: [
            { text: dayLabel, value: 'day' },
            { text: this._getCurrentViewLabel('three-days'), value: 'three-days' },
            { text: this._getCurrentViewLabel('week'), value: 'week' },
            { text: this._getCurrentViewLabel('month'), value: 'month' },
          ],
          handler: async (selectedItem: { text: string, value: CalendarViewType }, popoverData: { value: string }) => {
            if (popoverData.value !== selectedItem.value) {
              popoverData.value = selectedItem.value;
              this.currentViewLabel = selectedItem.text;
              if (this.currentView === 'month') {
                // Requires some event filtering when going from month view
                this._isGoingFromMonthViewViaOption = true;
                this._setLoaderCounter(selectedItem.value === 'week' ? 6 : 4);
              } else if (this.currentView === 'week') {
                // this.shouldHideScheduler = true;
                this._setLoaderCounter(selectedItem.value === 'month' ? 5 : selectedItem.value === 'three-days' ? 5 : 4);
              } else if (this.currentView === 'three-days') {
                this._setLoaderCounter(selectedItem.value === 'month' ? 4 : selectedItem.value === 'week' ? 4 : 3);
              } else if (this.currentView === 'day') {
                this._setLoaderCounter(selectedItem.value === 'month' ? 3 : selectedItem.value === 'three-days' ? 3 : 4);
              }
              if (selectedItem.value === 'three-days') {
                this._updateThreeDaysViewCurrentDate(this.currentDate as Date);
              }
              this.cd.detectChanges();

              if (this.viewOptionsPopoverHandle) {
                this.viewOptionsPopoverHandle.dismiss();
              }
              if (selectedItem.value != 'month') this.transitionView = true;
              setTimeout(() => {
                this.currentView$.next(selectedItem.value);
              }, 20);
            } else {
              if (this.viewOptionsPopoverHandle) {
                this.viewOptionsPopoverHandle.dismiss();
              }
            }
          }
        }
      ];
    }


    /** ----------------------------------------------------------------------------------------
     *  Mobiscroll Event Calendar fns
     */
    // onSetDate handler
    private _onSetDate: (event: { date: Date; control: 'calendar' | undefined }, inst: Scroller) => void = async (event, inst) => {
      this._isActivityEvent = false;
      if (this.currentView != "week") this._isClickedActivity = false;
      if (
        event.control === 'calendar'
        && (
          this.currentView === 'month'
          || this.currentView === 'week'
        )
      ) {
        // Going to day view.
        this._setLoaderCounter(this.currentView === 'week' ? 6 : 4);
        this.cd.detectChanges();
        this.currentDate = event.date;
        this._updateThreeDaysViewCurrentDate(this.currentDate as Date);
        this._isUpdatingCalendar = true;
        this._isGoingToDayViewViaClick = true;
        this.uiService.selectedCalendarDateInWeekView = new Date(this.currentDate as Date);
        this.events.publish('updatedDayClick');
        this.transitionView = true;
        setTimeout(() => {
          this.currentView$.next('day');
        }, 20);
      } else if(
        event.control === 'calendar'
        && this.currentView === 'day'
      ) {
        this.transitionView = false;
        if (event.date.getTime() !== (this.currentDate as Date).getTime()) {
          // Rest of scenarios
          this._setLoaderCounter(1);
          this.cd.detectChanges();
          this.currentDate = event.date;
          this._updateThreeDaysViewCurrentDate(this.currentDate as Date);
          this.uiService.selectedCalendarDateInWeekView = new Date(this.currentDate as Date);
          this.events.publish('updatedDayClick');
          setTimeout(() => {
            this._onSetDate$.next(event);
          }, 20);
        }
      } else {
        this.transitionView = false;
        // event.control = undefined => date change triggered by week / month change

        // View option changed from month view to week / day view
        if (this._isGoingFromMonthViewViaOption && !this._isGoingToDayViewViaClick && this.currentView !== 'three-days') {
          this._isGoingFromMonthViewViaOption = false;
        }

        if (
          (!this._isUpdatingCalendar && !this._isGoingFromMonthViewViaOption)
          || (this._isUpdatingCalendar && this._isGoingToDayViewViaClick)
        ) {
          if (this.currentView === 'month') {
            this.currentDate = event.date;
            this._updateThreeDaysViewCurrentDate(this.currentDate as Date);
          }
          // condition to check for clicked empty cell to avoid flickering in the week view
          if (!this._isClickedEmptyCell) {
            setTimeout(() => {
              this._onSetDate$.next(event);
            }, 20);
          }
        }
      }
    };
    private _onPageChange: (event: { firstDay: Date }, inst: Scroller) => void = (event, inst) => {
      this._isClickedEmptyCell = false;
      this._isClickedActivity = false;
      this._currentWeekFirstDay = event.firstDay;
      if (!this._isGoingToDayViewViaClick && !this._shouldIgnoreNextPageChange) {
        // Only trigger if
        //  - not current week
        //  - not a page change triggered by month change in non month view
        //    which sets current date to first day of the new month
        const sameWeek = isSameWeek(event.firstDay, this.currentDate);
        if (
          (!sameWeek
          && (
            this.currentView !== 'month'
            && (this.locale =='fr' || this.locale =='es'
                || (isSunday(event.firstDay) && (this.locale == 'en' || this.locale == 'ja')))
          ))
          || this.currentView === 'month'
        ) {
          this._onPageChange$.next(event);
        }
        this._isUpdatingCalendar = false;
      } else {
        this._isUpdatingCalendar = false;
        if (this._isGoingToDayViewViaClick) {
          // Required for following scenario
          // Month view => select prev / next month's date
          this._shouldIgnoreNextPageChange = true;
          this._updateSchedulerOptions();
        }
      }
    }

    private _initMobiscroll() {
      this.calendarOptions = {
        theme: 'ios',
        display: 'inline',
        view: {
            calendar: {
                type: 'week',
                size: 1
            }
        },
        onSetDate: this._onSetDate,
        onPageChange: this._onPageChange,
        onMarkupReady: (event, inst) => {
          // console.log('### onMarkupReady: ', this.loaderCounter);
          this._decreaseLoaderCounter();
        }
      };

      // filter onSetDate function calls
      this._subscriptions.push(
        this._onSetDate$
        .pipe(
          debounceTime(0),
        )
        .subscribe((ev: { date: Date; control: 'calendar' | undefined }) => {
          this._shouldIgnoreNextPageChange = false;
          this._isAllDayActivity = false;
          this._updateSchedulerOptions();
        })
      );

      this._subscriptions.push(
        this._onPageChange$
        .pipe(
          debounceTime(100),
        )
        .subscribe((event: { firstDay: Date }) => {
          if (this.currentView !== 'month') {
            this.currentDate = (this.locale != 'fr' && this.locale != 'es') ? addDays(event.firstDay, 1) : event.firstDay;
            this._updateThreeDaysViewCurrentDate(this.currentDate as Date);
            this.scheduler?.instance?.option('currentDate', this.currentDate);
            this.mbscEventCalendar?.instance?.navigate(this.currentDate);
            this.uiService.selectedCalendarDateInWeekView = new Date(this.currentDate as Date);
            this.events.publish('updatedDayClick');
          } else {
            // In month view, only update currentDate when month is changed
            if (!isSameMonth(this.currentDate, event.firstDay)) {
              this.currentDate = event.firstDay;
              this._updateThreeDaysViewCurrentDate(this.currentDate as Date);
              this.uiService.selectedCalendarDateInWeekView = new Date(this.currentDate as Date);
              this.events.publish('updatedDayClick');
            }
          }
          this._checkAndFetchMoreSchedulerData(event?.firstDay);
        })
      );

      // Handle Mobiscroll data refresh
      this._subscriptions.push(
        this._mbscDataRefreshRequest$.asObservable()
        .pipe(
          debounceTime(150),
        )
        .subscribe(() => {
          if (this._mbscDataRefreshCounter > 0) {
            this._mbscDataRefreshCounter--;
          }

          if (this._mbscDataRefreshCounter === 0) {
            this.mbscData.length = 0;
            this.mbscData = this.dataSource.items();
            this.cd.detectChanges();
          }
        })
      );
    }
    private _redrawMobiscroll() {
      this.mbscEventCalendar?.instance?.redraw();
      this.cd.detectChanges();
    }


    /** ----------------------------------------------------------------------------------------
     *  DxScheduler fns
     */
    private _initDxSchedulerOptions() {
      this.scheduler?.instance?.option({
        height: this._calculateHeight(),
        width: this._calculateWidth(),
        currentDate: this.currentDate,
        currentView: 'day',
      });
    }
    onInitialized(event) {
      // If height is 0, probably parent components are not ready to be rendered yet
      // In such case, delay the scheduler init so that parent components are ready
      // so that the available height can be calculated
      const height = this._calculateHeight();
      if (!isNaN(height) && height > 0) {
        this._initDxSchedulerOptions();
      } else {
        // probably parent component is not fully loaded yet
        // retry the init with a tick dely
        this._delayedInit = true;
        // setTimeout(() => {
        //   this._initDxSchedulerOptions();
        // }, 40);
      }
    }
    onOptionChanged(event: { name: string, previousValue: any, value: any }) {
      if (this.currentView === 'three-days' && event.name === 'currentDate') {
        if (this._isGoingFromMonthViewViaOption) {
          this._isGoingFromMonthViewViaOption = false;
        } else {
          this._setLoaderCounter(2);
        }
        this.cd.detectChanges();
        this.currentDate = new Date(event.value);
        this.mbscEventCalendar?.instance?.navigate(this.currentDate);
        this._updateThreeDaysViewCurrentDate(event.value, true);
      }
    }
    onAppointmentRendered(event: { appointmentElement: HTMLElement }) {
      // console.log('### onAppointmentRendered: ', event);
      this._reduceHeightOfAppointmentBoxByOnePixel(event.appointmentElement);
    }
    private _getDataWithinRange(curDate: Date = new Date()): Appointment[] {
      //      - Data loading range
      //         - from: cur month first day - 6 weeks or cur date - 8 weeks, which ever is earlier
      //         - to: cur next month first day + 6 weeks or cur date + 8 weeks, which ever is later
      const curMonthFirstDay: Date = new Date(curDate);
      curMonthFirstDay.setDate(1);
      curMonthFirstDay.setHours(0, 0, 0, 0);
      const nextMonthFirstDay: Date = addMonths(curMonthFirstDay, 1);

      const curDateMinus8Weeks: Date = subWeeks(curDate, 8);
      const curMonthFirstDayMinus6Weeks: Date = subWeeks(curMonthFirstDay, 6);
      const curDatePlus8Weeks: Date = addWeeks(curDate, 8);
      const nextMonthFirstDayPlus6Weeks: Date = addWeeks(nextMonthFirstDay, 6);

      this._dataRangeFrom = curDateMinus8Weeks.getTime() < curMonthFirstDayMinus6Weeks.getTime()
        ? curDateMinus8Weeks : curMonthFirstDayMinus6Weeks;
      this._dataRangeTo = curDatePlus8Weeks.getTime() > nextMonthFirstDayPlus6Weeks.getTime()
        ? curDatePlus8Weeks : nextMonthFirstDayPlus6Weeks;

      return this.activityService.getCalendarDataInDateRange(this._dataRangeFrom, this._dataRangeTo);
    }
    private async _checkAndFetchMoreSchedulerData(firstDay: Date) {
      const lastDayOfWeek: Date = addDays(firstDay, 6);
      const lastDay = this.currentView === 'month'
                      ? endOfMonth(lastDayOfWeek) : lastDayOfWeek;

      if (isBefore(firstDay, this._dataRangeFrom)) {
        this._setLoaderCounter(1);
        this.cd.detectChanges();
        this._initSchedulerDataSource(firstDay);
      } else if (isAfter(lastDay, this._dataRangeTo)) {
        this._setLoaderCounter(1);
        this.cd.detectChanges();
        this._initSchedulerDataSource(lastDay);
      }
      if (this.currentView === 'month') {
        this.cd.markForCheck();
      }
    }
    private _initSchedulerDataSource(curDate?: Date) {
      const appointments = this._getDataWithinRange(curDate);

      this._mbscDataRefreshCounter = 0;
      this.mbscData.length = 0;
      this._dataSourceInitDone = false;
      this.dataSource = new DataSource({
        // Data store config
        store: new ArrayStore({
          key: 'id',
          data: appointments,
        }),
        paginate: false,
        reshapeOnPush: true,
        // Search config
        searchExpr: (dataItem: Appointment) => dataItem.text + ' ' + dataItem.location,
        onChanged: () => {
          if (!this._dataSourceInitDone) {
            this._dataSourceInitDone = true;
            this.mbscData = this.dataSource.items();

            if (this.activityService?.activityFilter !== ActivityType.AllActivity) {
              this._filterActivitiesByType(this.activityService?.activityFilter as ActivityType);
            }
            if (this.activityService?.activitiesSearchText && this.activityService?.activitiesSearchText.length > 1) {
              this._searchByActivitySubject(this.activityService?.activitiesSearchText);
            }
          }
        }
      });
    }
    private _searchByActivitySubject(searchTxt: string) {
      this._dataSourceSearch(searchTxt.length > 1 ? searchTxt : '');
    }
    private _dataSourceSearch(searchTxt: string) {
      this._mbscDataRefreshCounter++;
      this.dataSource?.searchValue(searchTxt);
      this.dataSource?.load()
      .then((t, u, v, s) => {
        this._mbscDataRefreshRequest$.next();
      })
      .catch((t, u, v, s) => {
        this._mbscDataRefreshRequest$.next();
      });
    }
    private async _getAppointmentById(id: string): Promise<Appointment> {
      const store: ArrayStore = this.dataSource?.store();
      let appointment: Appointment;
      if (store) {
        try {
          appointment = await store.byKey(id);
        } catch (error) {
          // E4009 is data not found error
          // Data item cannot be found. See:\nhttp://js.devexpress.com/error/20_1/E4009'
          // We ignore this and return undefined for not found.
          if (!error?.message?.includes('E4009')) {
            console.error('activity-day-view: _getAppointmentById: ', error);
          }
        }
      }
      return appointment;
    }
    private _filterActivitiesByType(type: ActivityType) {
      this._dataSourceFilter(
        (
          type?.length > 0
          && type !== ActivityType.AllActivity
        )
        ? (itemData) => itemData.type === type
        : null
      );
    }
    private _dataSourceFilter(filterFn: (itemData: Appointment) => boolean | null) {
      this._mbscDataRefreshCounter++;
      this.dataSource?.filter(filterFn);
      this.dataSource?.load()
      .then((t, u, v, s) => {
        this._mbscDataRefreshRequest$.next();
      })
      .catch((t, u, v, s) => {
        this._mbscDataRefreshRequest$.next();
      });
    }
    private _updateSchedulerOptions() {
      const prevHideValue = this.shouldHideScheduler;
      this.shouldHideScheduler = this.currentView === 'month' ? true : false;

      this.scheduler?.instance?.beginUpdate();
      this.scheduler?.instance?.option({
        currentDate: this.shouldHideScheduler ? null : this.currentDate,
        currentView: this.currentView !== 'week' ? 'day' : this.currentView
      });

      if (
        (this.currentView === 'three-days' && this.dayViewIntervalCount === 1)
        || (this.currentView !== 'three-days' && this.dayViewIntervalCount === 3)
      ) {
        this.scheduler?.instance?.option({
          height: this._calculateHeight()
        });
      }
      this.scheduler?.instance?.endUpdate();

      if (this.currentView === 'three-days' && this.dayViewIntervalCount === 1) {
        this.dayViewIntervalCount = 3;
      } else if (this.currentView !== 'three-days' && this.dayViewIntervalCount === 3) {
        this.dayViewIntervalCount = 1;
      }

      // Repaint scheduler after bring it back
      if (!this.shouldHideScheduler && prevHideValue !== this.shouldHideScheduler) {
        setTimeout(() => {
          this.scheduler?.instance?.repaint();
        }, 20);
      }
    }
    private _updateSchedulerOptionsForRefreshCalendarView() {
      this.scheduler?.instance?.beginUpdate();
        this.scheduler?.instance?.option({
          currentDate: this.currentDate,
          currentView: this.currentView !== 'week' ? 'day' : this.currentView
        });
      this.scheduler?.instance?.endUpdate();
      if (this.currentView === 'three-days' && this.dayViewIntervalCount === 1) {
        this.dayViewIntervalCount = 3;
      } else if (this.currentView !== 'three-days' && this.dayViewIntervalCount === 3) {
        this.dayViewIntervalCount = 1;
      }

      // Repaint scheduler after bring it back
      if (!this.shouldHideScheduler) {
        setTimeout(() => {
          this.scheduler?.instance?.repaint();
        }, 20);
      }
    }
    private _calculateHeight(): number {
      let width: number = window.innerWidth;
      const mobiHeight = this.currentView === 'three-days' ? 56 : width > MOBISCROLL_LG_WIDTH_BREAKPOINT ? MOBISCROLL_LG_HEIGHT : MOBISCROLL_SM_HEIGHT;
      const computedHeight = this.document?.querySelector('activities-pane ion-content')?.getBoundingClientRect()?.height - mobiHeight;
      return computedHeight > 0 ? computedHeight : 0;
    }
    private _calculateWidth(): number {
      let calendarWidth: number = window.innerWidth;
      if (!this.deviceService.isMobileDevicePortrait) {
        calendarWidth = calendarWidth * 0.4;
      }
      return calendarWidth;
    }
    private _resizeCalendarView(height: number) {
      const defaultMonthViewCalendarHeight: number = 344; //344 = scrollHeight(464) based on window.innerHeight(640) - calendar header(120)
      let newHeight: number;
      if (this.currentView === "month") {
        if (this.deviceService.isMobileDevicePortrait && height < 640) {
          newHeight = defaultMonthViewCalendarHeight;
        } else {
          const _currentViewArea = document.getElementsByClassName("current-view");
          newHeight = _currentViewArea[0].scrollHeight - 120;
          if (newHeight < defaultMonthViewCalendarHeight) {
            newHeight = defaultMonthViewCalendarHeight;
          }
        }
      } else {
        newHeight = 50;
      }
      this.calendarOptions = {calendarHeight: newHeight};
    }
    /** ----------------------------------------------------------------------------------------
     *  Component helper fns
     */
    private _dxSchedulerLocale() {
      locale(this.locale);
      const localeValue: string = this.translate.instant("ALL_DAY")
      loadMessages ({
        'en': {
          'dxScheduler-allDay': localeValue
        },
        'es': {
          'dxScheduler-allDay': localeValue
        },
        'fr': {
          'dxScheduler-allDay': localeValue
        },
        'ja': {
          'dxScheduler-allDay': localeValue
        },
        'de': {
          'dxScheduler-allDay': localeValue
        },
        'nl': {
          'dxScheduler-allDay': localeValue
        },
        'zh': {
          'dxScheduler-allDay': localeValue
        }
      });
    }
    getFormattedTimeCell(timeCell: any) {
      let formattedTimeCellText: string = '';
      const timeCellDate = new Date(timeCell.date);
      if (timeCell?.text) {
        // formattedTimeCellText = timeCellDate.toLocaleTimeString('en-US', { hour12: this.dateTimeFormatsService.is12HourFormat, hour: '2-digit', minute: '2-digit' });
        formattedTimeCellText = timeCellDate.toLocaleTimeString('en-US', { hour12: this.dateTimeFormatsService.is12HourFormat, hour: 'numeric' });
      }
      return formattedTimeCellText;
    }
    private _scrollToCell(targetDate: Date, isAllDayActivity: boolean = false) {
      let scrollTargetHours: number = 7;
      let scrollTargetMinutes: number = 50;
      if (isAllDayActivity) {
        setTimeout(()=>{
          this.scheduler.instance.scrollToTime(scrollTargetHours, scrollTargetMinutes);
        }, 100);
        return;
      }
      if (targetDate != undefined) {
        const currnetHours = new Date(targetDate).getHours();
        const currentMinutes = new Date(targetDate).getMinutes();
        if (currentMinutes < 30) {
          scrollTargetHours = currnetHours - 1;
          scrollTargetMinutes = currentMinutes + 30;
        } else {
          scrollTargetHours = currnetHours;
          scrollTargetMinutes = currentMinutes - 30;
        }
      } else {
        scrollTargetHours = 7;
        scrollTargetMinutes = 50;
      }
      setTimeout(() => {
        if (scrollTargetHours < 0) {
          scrollTargetHours = scrollTargetMinutes = 0;
        }
        this.scheduler.instance.scrollToTime(scrollTargetHours, scrollTargetMinutes);
      }, 100);
    }
    private _reduceHeightOfAppointmentBoxByOnePixel(el: HTMLElement) {
      if (el?.style?.height) {
        try {
          const origHeight: number = Number(el?.style?.height.replace('px', ''));
          if (origHeight > 0) {
            const newHeight: number = origHeight - 1;
            if (newHeight > 0) {
              this.renderer2.setStyle(el, 'height', `${newHeight}px`);
            }
          }
        } catch (error) {
          console.error('_reduceHeightOfAppointmentBoxByOnePixel: ', error);
        }
      }
    }
    // Sets the loader counter to display a loader during the UI transition.
    // We're observing some performance issues on lower spec devices so
    // a loader has been introduced for better UX.
    // The counter number is customized for each transition path
    // and yes, it is a hack. However, it's not possible to get a
    // pattern out of it since we're trying to orchestrate two different libraries.
    // Might need to revisit later to better it.
    private _setLoaderCounter(count: number) {
      if (count === null || count === undefined || count < 0) {
        count = 0;
      }
      this.loaderCounter = count;
      // console.log('### set loader counter: ', this.loaderCounter);
    }
    private _decreaseLoaderCounter() {
      this.loaderCounter--;
      this.loaderCounter = this.loaderCounter < 0 ? 0 : this.loaderCounter;
      // console.log('### decreased loader counter: ', this.loaderCounter);
      if (this.loaderCounter === 0) {
        setTimeout(() => {
          this.cd.detectChanges();
        }, 20);
      }
    }
    private _getOfflineId(activity: Activity): string {
      let offlineId: string = '';
      if (activity instanceof CaseActivity) {
        offlineId = activity.offline_ID;
      } else if (activity instanceof AppointmentActivity) {
        offlineId = activity.offlineMeetingId;
      } else if (activity instanceof PhoneActivity) {
        offlineId = activity.offlineId;
      } else if (activity instanceof EmailActivity) {
        offlineId = activity.offlineActivityId;
      } else if (activity instanceof FollowUpActivity) {
        offlineId = activity.offlineId;
      } else if (activity instanceof OrderActivity) {
        offlineId = activity.offlineId;
      } else if (activity instanceof SampleActivity) {
        offlineId = activity.offlineActivityId;
      } else if (activity instanceof SurgeryOrderActivity) {
        offlineId = activity.offlineId;
      } else if (activity instanceof ProcedureTrackerActivity) {
        offlineId = activity.offlineId;
      } else if (activity instanceof TimeOffActivity) {
        offlineId = activity.offlineTimeOffRequestId;
      }
      return offlineId;
    }
    private _handleDateCellClick = (event) => {
      if (event?.target?.cellDate !== null && event?.target?.cellDate !== undefined && !isNaN(event?.target?.cellDate)) {
        this._setLoaderCounter(3);
        this.cd.detectChanges();

        this.currentDate = new Date(event?.target?.cellDate);
        this.uiService.selectedCalendarDateInWeekView = new Date(event?.target?.cellDate);
        this.events.publish('updatedDayClick');
        this._isGoingToDayViewViaClick = true;
        this._updateThreeDaysViewCurrentDate(event?.target?.cellDate);
        setTimeout(() => {
          this.currentView$.next('day');
        }, 20);
      }
    }
    private _addEventListenersToThreeDaysViewDateCells() {
      const dateCellEls: NodeListOf<HTMLLIElement> = this.document?.querySelectorAll('dx-scheduler .dx-scheduler-work-space-count .dx-scheduler-header-panel .dx-scheduler-header-row .dx-scheduler-header-panel-cell');
      let idx: number = 0;
      try {
        const firstDate: Date = new Date((this.scheduler.instance as any)?._workSpace._firstViewDate);
        for (const cell of dateCellEls) {
          this._threeDaysViewEventListeners.push(
            this.renderer2.listen(cell, 'click', this._handleDateCellClick)
          );
          const dateToBeAppended = addDays(firstDate, idx);
          this.renderer2.setProperty(cell, 'cellDate', dateToBeAppended.getTime());
          idx++;
        }
      } catch (error) {
        console.error('_addEventListenersToThreeDaysViewDateCells: ', error);
      }
    }
    private _removeThreeDaysViewEventListeners() {
      if (Array.isArray(this._threeDaysViewEventListeners) && this._threeDaysViewEventListeners.length > 0) {
        for (const removeListener of this._threeDaysViewEventListeners) {
          try {
            removeListener();
          } catch (error) {
            console.error('_removeThreeDaysViewEventListeners: ', error);
          }
        }
      }
    }
    private _isDateWithinCurrentThreeDaysRange(date: Date): boolean {
      let isWithinRange: boolean = false;

      try {
        const currentThreeDaysRangeBegin: number = this.threeDaysViewCurrentDate.getTime();
        const currentThreeDaysRangeEnd: number = currentThreeDaysRangeBegin + this.THREE_DAYS_IN_MILLISECONDS;
        const dateToBeCompared: number = date.getTime();
        isWithinRange = (dateToBeCompared >= currentThreeDaysRangeBegin
                        && dateToBeCompared < currentThreeDaysRangeEnd) ? true : false;
      } catch (error) {
        console.error('_isDateWithinCurrentThreeDaysRange: ', error);
      }

      return isWithinRange;
    }
    private _updateThreeDaysViewCurrentDate(date: Date, updateViewLabel: boolean = false) {
      if (
        this._isGoingToDayViewViaClick
        || this.currentView !== 'three-days'
        || (this.currentView === 'three-days' && !this._isDateWithinCurrentThreeDaysRange(date))
      ) {
        const zeroHoursDate = new Date(date);
        zeroHoursDate.setHours(0, 0, 0, 0);
        this.threeDaysViewCurrentDate = zeroHoursDate;
        this._updateThreeDaysViewLabel();
      }
      if (updateViewLabel) {
        this._updateThreeDaysViewLabel();
      }
    }
    private _updateThreeDaysViewLabel() {
      const label: HTMLElement = (this.mbscEventCalendar?.instance as any)?.markup.querySelector('.mbsc-cal-hdr .mbsc-cal-month');
      if (label) {
        this.threeDaysViewMonthYearLabel = label.innerText;
      }
    }
    private _getCurrentViewLabel(view: CalendarViewType): string {
      let label: string;
      switch (view) {
        case 'day':
          label = this.translate.instant('DAY');
          break;
        case 'three-days':
          label = '3 ' + this.translate.instant('DAYS');
          break;
        case 'week':
          label = this.translate.instant('WEEK');
          break;
        case 'month':
          label = this.translate.instant('MONTH');
          break;
        default:
          break;
      }
      return label;
    }
}
