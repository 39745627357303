import { IONote } from '@omni/classes/io/io-note.class';
import { EventParticipant, EventPass } from './../../classes/events-tool/event.class';
import { ConfigFieldOptionValue } from './../../classes/activity/activity.class';
import { Injectable } from "@angular/core";
import { EventType, EventSubType, Speaker } from "../../classes/events-tool/events-tool.event.class";
import { Subject } from "rxjs";
import { EventActivity } from "../../classes/events-tool/event.class";
import { AlertService } from '../../services/alert/alert.service';
import { TranslateService } from "@ngx-translate/core";
import { DiskService } from "../disk/disk.service";
import { DB_KEY_PREFIXES } from "../../config/pouch-db.config";
import { isValidActivityToCheckTimeConflict } from "../../utility/activity.utility";
import { binarySearchInsertionIdx } from '../../utility/common.utility';
import _ from 'lodash';
import { EventRegistrationStatus } from '@omni/enums/event/event.enum';
import { EventGoal } from '../../interfaces/event-tool/event-tool.interface';
import { AuthenticationService } from '../authentication.service';

const EventTypeNeededForParticipantsMandatoryIoConfigName = 'EventType_Needed_For_Participants_Mandatory';
interface EventTypeNeededForParticipantsMandatoryConfig {
  EventType: string
};

@Injectable({
  providedIn:'root'
})
export class EventsToolService {

    public eventGoals: EventGoal[] = [];
    public eventTypes: EventType[] = [];
    public eventSubTypes: EventSubType[] = [];
    public eventSpeakers: Speaker[] = [];
    public eventsToolData: Array<EventActivity> = [];
    public selectedEventOnEventsTool: EventActivity;
    public pastEventAttachments = [];
    public annotation = null;

    private eventsUpdate = new Subject();
    public eventsUpdateObserver$ = this.eventsUpdate.asObservable();

    private selectedEventUpdates = new Subject();
    public selectedEventUpdatesObserver$ = this.selectedEventUpdates.asObservable();
    public configFieldOptionsValues: { [key: string]: ConfigFieldOptionValue[] } = {};

    public isCovisitorViewMode: boolean = false;
    public pendingParticipantDetails: Map<string, { indskr_reasons: EventRegistrationStatus, notes: IONote[] }> = new Map();
    private _conflictCheckLastIdx: number = -1;
    get conflictCheckLastIdx() {
      return this._conflictCheckLastIdx;
    }

    private _eventTypeNeededForParticipantsMandatory: string;
    get eventTypeNeededForParticipantsMandatory() {
      return this._eventTypeNeededForParticipantsMandatory;
    }

    constructor(private alertService: AlertService,
        public translate: TranslateService,
        private diskService: DiskService,
        private authService: AuthenticationService,
    ) {}

    public setEventsUpdate(updates:Array<EventActivity> ) {
        this.eventsUpdate.next(updates);
    }

    public setSelectedEventUpdates(value:EventActivity ) {
        this.selectedEventUpdates.next(value);
    }

    public getEventDetailsbyId(id: string): EventActivity {
      if (this.eventsToolData && this.eventsToolData.length > 0) {
        return this.eventsToolData.find(event => event.ID == id
          || (event.offlineId
            && event.offlineId == id));
      } else {
        return null;
      }
    }

    public showDiscardChangesPopup() {
        return this.alertService.showAlert({
            title: this.translate.instant('DISCARD_CHANGES'),
            message: this.translate.instant('CI_R_U_WANT_DISCARD_CHANGES')
        }, this.translate.instant('DISCARD')
        );
    }
    public async updateEventsInPouchDB(eventsData: EventActivity) {

    }

    async aggregateEventTypes(eventTypesRawData){
        let eventTypes: EventType[] = [];
        eventTypesRawData.map(et=>{
            try{
                if(et['indskr_event_typeid']){
                    let eventType: EventType;
                    eventType = eventTypes.find(e=>e.id == et['indskr_event_typeid'])
                    if(!eventType){
                        eventType = new EventType(et);
                        eventTypes.push(eventType);
                    } else {
                            eventType.subTypes.push({
                                id: et['indskr_eventsubtype1.indskr_eventsubtypeid'],
                                name:et['indskr_eventsubtype1.indskr_name'],
                                state: et['indskr_eventsubtype1.statecode']
                            });
                    }
                }
            } catch (error){
                console.error('Error occured while aggregating EventTypes data: ' + error);
            }
        })
        this.eventTypes = eventTypes;
    }

  async aggregateEventAttachments(eventAttachRawData){
    const eventRegAttachments = [];
    eventAttachRawData.map(et=>{
      try{
        if(et['msevtmgt_eventregistrationid']){
          let eventReg: any = {};
          eventReg = eventRegAttachments.find(e=>e.eventRegId == et['msevtmgt_eventregistrationid'])
          if(!eventReg){
            eventReg = {
              eventRegId: et['msevtmgt_eventregistrationid'],
              eventRegName: et['msevtmgt_name'],
              eventId: et['_msevtmgt_eventid_value'],
              eventName: et['_msevtmgt_eventid_value_Formatted'],
              attachments: [
                {
                  annotationid: et['annotationId'],
                  filename: et['fileName'],
                  checked: false,
                }
              ]
            };
            eventRegAttachments.push(eventReg);
          } else {
            eventReg.attachments.push({
              annotationid: et['annotationId'],
              filename: et['fileName'],
              checked: false,
            });
          }
        }
      } catch (error){
        console.error('Error occured while aggregating pastEventAttachments data: ' + error);
      }
    })
    this.pastEventAttachments = eventRegAttachments;
  }

    public  mapEventSubtypes(raw: EventSubType[]){
        if(Array.isArray(raw)){
            this.eventSubTypes = raw.map(rawJSON => new EventSubType(rawJSON));
        } else {
            console.error('Unsupported data format for event subtypes!');
        }

    }

    public mapSpeakers(raw: Speaker[]){
        if(Array.isArray(raw)){
            this.eventSpeakers = raw.map(rawJSON => new Speaker(rawJSON));
        } else {
            console.error('Unsupported data format for event speakers!');
        }
    }

    public initEventsToolData():Promise<any>{
        return new Promise(async (resolve, reject) => {
            let offlineData;
            await this.diskService.retrieve(DB_KEY_PREFIXES.EVENT_TYPES_ACTIVITY_BULK, true).then((doc) => {
            if (doc && doc.raw) {
                offlineData = doc.raw;
            }
            else {
                offlineData = [];
            }
            }).catch(err => {
            offlineData = [];
            });
            if(offlineData && offlineData.length != 0){
                // this.eventsToolData = [];
                // offlineData.forEach(item => {
                //     if(item && item.statuscode != 548910006){
                //     this.eventsToolData.push(new EventActivity(item));
                //     }
                // });
                this.initData(offlineData);
            }
            resolve(null);
        });
    }

  initData(dataSource: any[]) {
    this.eventsToolData.length = 0;

    if (Array.isArray(dataSource) && dataSource.length > 0) {
      for (let i = 0; i < dataSource.length; i++) {
        const item = dataSource[i];
        try {
          if (item && item.statuscode !== 548910006){
            this.eventsToolData.push(new EventActivity(item));
          }
        } catch (error) {
          console.error('EventsToolService: initData: inside loop: ', error);
        }
      }

      try {
        this.eventsToolData.sort((a, b) => b._startDate.getTime() - a._startDate.getTime());
      } catch (error) {
        console.error('EventsToolService: initData: ', error);
      }
    }
  }

  addEventData(event: EventActivity) {
    if (event instanceof EventActivity) {
      let binarySearchInsertionIndex = binarySearchInsertionIdx(
        this.eventsToolData,
        event._startDate.getTime(),
        (a, b) => b - a,
        (o) => o._startDate.getTime(),
      );
      if (binarySearchInsertionIndex > 0) {
        // Found the event with same startDate. Insert in front of it.
        binarySearchInsertionIndex--;
      } else if (binarySearchInsertionIndex < 0) {
        // Returns x where x is (-m - 1)
        binarySearchInsertionIndex = -(binarySearchInsertionIndex + 1);
      }

      this.eventsToolData.splice(binarySearchInsertionIndex, 0, event);
    }
  }

  clearConflictActivityIds() {
    for (let i = 0; i < this.eventsToolData.length; i++) {
      const event = this.eventsToolData[i];

      if (event.conflictingActivityIds instanceof Map === false) {
        event.conflictingActivityIds = new Map();
      } else {
        event.conflictingActivityIds.clear();
      }
    }
  }
  clearConflictEventIds() {
    for (let i = 0; i < this.eventsToolData.length; i++) {
      const event = this.eventsToolData[i];

      if (event.conflictingEventIds instanceof Map === false) {
        event.conflictingEventIds = new Map();
      } else {
        event.conflictingEventIds.clear();
      }
    }
  }

  setConflictCheckLastIdx(idx: number) {
    if (isNaN(idx) || idx < 0) {
      idx = -1;
    }
    this._conflictCheckLastIdx = idx;
  }

  hasTimeConflict(event: EventActivity): boolean {
    let hasConflict: boolean = false;

    if (event instanceof EventActivity) {
      hasConflict = (
        event.conflictingActivityIds instanceof Map && event.conflictingActivityIds.size > 0
        || event.conflictingEventIds instanceof Map && event.conflictingEventIds.size > 0
      ) ? true : false;
    }

    return hasConflict;
  }

  shouldReCheckTimeConflict(oldEvent: EventActivity, newEvent: EventActivity, userId: string): boolean {
    let shouldReCheck: boolean = false;
    try {
      if ( oldEvent instanceof EventActivity && newEvent instanceof EventActivity && isValidActivityToCheckTimeConflict(oldEvent, userId)) {
        // Diff
        if (
          oldEvent._startDate.getTime() !== newEvent._startDate.getTime()
          || oldEvent.endDate.getTime() !== newEvent.endDate.getTime()
          || oldEvent.statuscode !== newEvent.statuscode
          || oldEvent.ownerId !== newEvent.ownerId
          || (
            this.isMeCoVisitor(oldEvent, userId) !== this.isMeCoVisitor(newEvent, userId)
          )
        ) {
          shouldReCheck = true;
        }
      }
    } catch (error) {
      console.error('shouldReCheckTimeConflict: ', error);
    }
    return shouldReCheck;
  }
  isMeCoVisitor(event: EventActivity, userId: string): boolean {
    return Array.isArray(event.covisitors)
      && event.covisitors.findIndex(a => a.id === userId) >= 0
      ? true: false;
  }

  resetConfigFieldOptionsValues() {
    if (this.configFieldOptionsValues) {
      for (const fieldName in this.configFieldOptionsValues) {
        if (Object.prototype.hasOwnProperty.call(this.configFieldOptionsValues, fieldName)) {
          const values = this.configFieldOptionsValues[fieldName];
          values.length = 0;
        }
      }

      this.configFieldOptionsValues = {};
    }
  }

  getEventParticipantByContactId(participants: EventParticipant[], contactId: string): EventParticipant {
    let participant: EventParticipant;

    if (
      Array.isArray(participants)
      && contactId
    ) {
      participant = participants.find(p => p.id === contactId);
    }

    return participant;
  }

  hasPendingParticipantDetails() {
    return this.pendingParticipantDetails.size > 0;
  }
  getPendingParticipantDetails(contactId: string) {
    if (this.pendingParticipantDetails.has(contactId)) {
      return this.pendingParticipantDetails.get(contactId);
    }
    return null;
  }
  createPendingParticipantDetails(contactId: string, details: {indskr_reasons: EventRegistrationStatus, notes: IONote[]}) {
    if (!this.pendingParticipantDetails.has(contactId)) {
      this.pendingParticipantDetails.set(contactId, details);
      return true;
    }
    return false;
  }
  removePendingParticipantDetails(contactId: string) {
    if (this.pendingParticipantDetails.has(contactId)) {
      this.pendingParticipantDetails.delete(contactId);
    }
  }
  clearPendingParticipantDetails() {
    this.pendingParticipantDetails.clear();
  }

  public updateEventPassesLeft(eventPassespayload: any[], event: EventActivity) {
    eventPassespayload.forEach(payload => {
      const index = event.participants.findIndex(participant => participant.registrationId == payload['eventRegId']);
      if (index >= 0) {
        let eventPassIndx = -1;
        if (!_.isEmpty(event.participants[index].eventPasses)) {
          eventPassIndx = event.participants[index].eventPasses.findIndex(eventPass => eventPass.msevtmgt_attendeepassId == payload['msevtmgt_attendeepassid'])
        } else {
          event.participants[index].eventPasses = [];
        }
        if (payload['deleted']) {
          if (eventPassIndx >= 0)
            event.participants[index].eventPasses.splice(eventPassIndx, 1);
        } else {
          if (eventPassIndx == -1) {
            const eventPass: EventPass = this.getEventPass(payload, event)
            event.participants[index].eventPasses.push(eventPass);
          }
        }
      }
    });
  }

  private getEventPass(payload: any, event: EventActivity): EventPass {
    return {
      msevtmgt_attendeepassId: payload['msevtmgt_attendeepassid'],
      msevtmgt_passId: payload['passId'],
      msevtmgt_passName: payload['msevtmgt_passName'],
      msevtmgt_noOfPassesLeft: null
    };
  }

  setEventTypeNeededForParticipantsMandatory() {
    const ioConfig = this.authService.user.ioConfigurations?.find(c => c.configName === EventTypeNeededForParticipantsMandatoryIoConfigName);
    if (ioConfig) {
      try {
        const parsedConfigValue: EventTypeNeededForParticipantsMandatoryConfig
          = JSON.parse(ioConfig.configValue);

        this._eventTypeNeededForParticipantsMandatory = parsedConfigValue?.EventType || undefined;
      } catch (error) {
        console.error('setEventTypeNeededForParticipantsMandatory: ', error);
      }
    }
  }
}
