import { PopoverComponent } from './../popover/popover';
import { Contact } from './../../classes/contact/contact.class';
import { EmailTemplateType } from './../../classes/email-templates/email-template.class';
import { EmailService } from '@omni/services/email-templates/email.service';
import { DeviceService } from '@omni/services/device/device.service';
import { NotificationService, ToastStyle } from './../../services/notification/notification.service';
import { takeUntil, distinctUntilChanged, debounceTime, skip } from 'rxjs/operators';
import { ErrorCode } from './../../enums/exception/general-error-codes.enum';
import { GeneralException } from './../../classes/exception/general-exception.class';
import { MeetingDataService } from '@omni/data-services/meeting/meeting.data.service';
import { CalendarInviteTemplatePageComponent } from './../calendar-invite-template/calendar-invite-template-page/calendar-invite-template-page';
import { Component, OnInit, OnDestroy, ViewChild, EventEmitter } from '@angular/core';
import { IonNav, ModalController, PopoverController } from '@ionic/angular';
import { IndPageTitleViewDataModel } from '../../models/indPageTitleDataModel';
import { IndSectionHeaderViewDataModel } from '../../models/indSectionHeaderDataModel';
import { ActivityService } from '../../services/activity/activity.service';
import { FooterService, FooterViews } from '../../services/footer/footer.service';
import { UIService } from '../../services/ui/ui.service';
import { TranslateService } from '@ngx-translate/core';
import { EditorComponent } from '@tinymce/tinymce-angular';
import { AppointmentActivity } from '../../classes/activity/appointment.activity.class';
import { ActivityType } from '../../classes/activity/activity.class';
import { FormFieldType, IndFormFieldViewDataModel } from '../../models/indFormFieldDataModel';
import { BehaviorSubject, Observable, Subscription, Subject } from 'rxjs';
import { DynamicsBoolean } from '../../enums/shared-enums';
import { GlobalUtilityService } from '../../services/global-utility.service';
import { AuthenticationService } from '../../services/authentication.service';
import { FeatureActionsMap } from '../../classes/authentication/user.class';
import { isPast } from 'date-fns';

@Component({
  selector: 'calendar-invite',
  templateUrl: 'calendar-invite.html',
  styleUrls: ['calendar-invite.scss']
})
export class CalendarInviteComponent implements OnInit, OnDestroy {
  @ViewChild('editor', { static: false }) editorCmp: EditorComponent;
  calendarInvitePageTitle: IndPageTitleViewDataModel;
  detailsHeader: IndSectionHeaderViewDataModel;
  agendaHeader: IndSectionHeaderViewDataModel;
  statusFormField: IndFormFieldViewDataModel;
  formFields: IndFormFieldViewDataModel[] = [];
  maxContactsToBeShown: number = 5;
  maxCoVisitorsToBeShown: number = 5;
  globalCustomerText: string = '';
  showMore: boolean = true;
  coVisitorShowMore: boolean = true;
  tinyMceConfig: any;
  defaultTinyMceConfig: any = {
    base_url: 'tinymce',
    suffix: '.min',
    branding: false,
    min_height: Math.min(300, window.innerHeight - 182),
    max_height: Math.max(500, window.innerHeight - 175),
    menubar: false,
    plugins: [
      'advlist autolink lists link image preview',
      'searchreplace autoresize',
      'media table paste wordcount'
    ],
    toolbar:
      'undo redo | formatselect | bold italic backcolor | \
      alignleft aligncenter alignright alignjustify | \
      link unlink | searchreplace | \
      bullist numlist outdent indent | removeformat',
    init_instance_callback: (editor) => {
      this.origContent = editor.getContent();
      editor.on('Change', (e) => this.onTinyMceChange(e));
      editor.on('keyup', (e) => this.onTinyMceChange(e));
      editor.on('SetContent', (e) => this.onTinyMceChange(e));
      editor.on('Redo', (e) => this.onTinyMceChange(e));
      editor.on('Undo', (e) => this.onTinyMceChange(e));
    },
  };

  activity: AppointmentActivity;
  origContent = '';
  editorContent = '';

  private modalCloseSubscription: Subscription;
  private sendButtonRef: any;
  private saveButtonRef: any;
  private isSaveButtonDisabledState: boolean = false;

  private destroy$: Subject<boolean> = new Subject<boolean>();
  private isLoading$: BehaviorSubject<number> = new BehaviorSubject(0);
  readonly isLoading: Observable<number> = this.isLoading$.asObservable();
  private inputChange$: Subject<string> = new Subject<string>();
  private emailToolTip: any;
  private subscriptions: Subscription[] = [];

  constructor(
    public activityService: ActivityService,
    private meetingDataService: MeetingDataService,
    private translate: TranslateService,
    private uiService: UIService,
    public footerService: FooterService,
    private modalCtrl: ModalController,
    private navCtrl: IonNav,
    private notificationService: NotificationService,
    private deviceService: DeviceService,
    private emailService: EmailService,
    private popoverCtrl: PopoverController,
    private utilityService: GlobalUtilityService,
    private authService: AuthenticationService
  ) {}

  ngOnInit() {
    // Replace editor content with the selected template
    this.subscriptions.push(this.activityService.calendarInviteTemplateSelection$
      .subscribe((selectedTemplate: { body: string, isEditable: boolean }) => this.handleTemplateSelection(selectedTemplate)));

    // Listen to content change and do diff to update the save button status
    this.subscriptions.push(this.inputChange$
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
      )
      .subscribe(() => this.diffContent()));

    // Init activity and editor content
    if (this.activityService.selectedActivity && this.activityService.selectedActivity.type === ActivityType.Appointment) {
      this.activity = this.activityService.selectedActivity as AppointmentActivity;
      if (this.activity?.description === '' || this.activity?.description.includes(`meta name="Generator" content="Microsoft Exchange Server"`)) {
        // If agenda is empty (actual variable is empty or has an exchange generated empty body html tags)
        // copy a default template value to the agenda
        this.editorContent = this.getDefaultTemplate();
        this.isSaveButtonDisabledState = false;
      } else {
        let preview = this.emailService.personalizeTemplateBody(
          this.activity.description,
          {
            user: this.authService.user,
            productId: this.activity['products'][0] ? this.activity['products'][0].ID : '',
            contactId: this.activity['contacts'][0] ? this.activity['contacts'][0].ID : '',
            activity: this.activity
          }
        );
        this.editorContent = preview?.templatePreview ?? this.activity.description;;
        this.isSaveButtonDisabledState = true;
      }
    } else {
      console.error('CalendarInviteComponent: Not an Appointment Activity', this.activityService.selectedActivity);
    }

    // Init
    this.initTinyMce();
    this.setGlobalCustomerText();
    this.initPageHeader();
    this.initSectionHeaders();
    this.initFormFields();
    this.initFooter();

    // Offline observable
    this.subscriptions.push(this.deviceService.isOfflineObservable
      .pipe(
        skip(1),
      )
      .subscribe(isOffline => {
        if (this.sendButtonRef) {
          this.sendButtonRef.isDisabled = this.isSendButtonDisabled();
        }
        if (this.saveButtonRef) {
          this.saveButtonRef.isDisabled = !this.isSaveButtonEnabled();
        }
        this.updateFooter();
    }));

    // If Schedule start is in Past we have to display a Toast OMNI-24396
    let todayStart = new Date(new Date().setHours(0, 0, 0, 0));
    let activityStartDate = new Date(this.activityService.selectedActivity.scheduledStart);
    activityStartDate = new Date(activityStartDate.setHours(0,0,0,0));

    if (activityStartDate < todayStart) {
      this.notificationService.notify(this.translate.instant("MEETING_DATE_TIME_IN_PAST"), 'Meeting Details');
    }
  }
  ngOnDestroy() {
    if (Array.isArray(this.subscriptions)) {
      for (let i = 0; i < this.subscriptions.length; i++) {
        const subscription = this.subscriptions[i];
        subscription.unsubscribe();
      }
    }
    this.inputChange$.complete();
    this.isLoading$.unsubscribe();
    if (this.editorCmp?.editor) {
      this.editorCmp.editor.destroy();
    }
    if (this.modalCloseSubscription) {
      this.modalCloseSubscription.unsubscribe();
    }
  }

  /**
   * Init
   * ---------------------------------------------------------------------------------------------------------------------
   */
  private initTinyMce() {
    this.tinyMceConfig = this.defaultTinyMceConfig;
  }
  private initPageHeader() {
    const buttons: any[] = [];

    buttons.push({
      id: 'close',
      icon: 'chevron-back-outline',
      isDisabled: false,
      align: 'left'
    });

    // Init save / send button depending on the state
    if (this.activity) {
      this.saveButtonRef = {
        id: 'save',
        imgSrc: 'assets/imgs/header_save.svg',
        name: this.activity.isInviteSent === DynamicsBoolean.true ? this.translate.instant('UPDATE') : this.translate.instant('SAVE'),
        isDisabled: !this.isSaveButtonEnabled(),
        align: 'right'
      };
      buttons.push(this.saveButtonRef);

      // Only display send button when it's a draft
      if (this.activity.isInviteSent === DynamicsBoolean.false) {
        this.sendButtonRef = {
          id: 'send',
          imgSrc: 'assets/imgs/header_send.svg',
          name: this.translate.instant('SEND'),
          isDisabled: this.isSendButtonDisabled(),
          align: 'right'
        };

        buttons.push(this.sendButtonRef);
      }
    }

    this.calendarInvitePageTitle = {
      id: 'calendar-invite-page-header',
      title: this.translate.instant('CALENDAR_INVITE'),
      controls: buttons
    };
  }
  private initSectionHeaders() {
    this.detailsHeader = {
      id: 'calendar-invite-detail-header',
      title: this.translate.instant('DETAILS'),
      rightLabelTxt: '',
      isFilter: false,
      controls: []
    };
    this.agendaHeader = {
      id: 'calendar-invite-agenda-header',
      title: this.translate.instant('AGENDA'),
      rightLabelTxt: '',
      isFilter: false,
      controls: [
        {
          id: 'agenda-template',
          text: this.translate.instant('TEMPLATE'),
          isDisabled: false,
          isVisible: true,
        },
      ]
    };
  }
  private initFormFields() {
    this.statusFormField = {
      label: this.translate.instant('STATUS'),
      inputText: this.activity.isInviteSent === DynamicsBoolean.true ? this.translate.instant('SENT') : this.translate.instant('OM_DRAFT'),
      id: 'status-field',
      isReadOnly: true,
      isDisabled: true,
      showArrow: false,
    };

    this.formFields.push(...
      [
        {
          label: this.translate.instant('SUBJECT'),
          inputText: this.getCalendarInviteSubject(),
          id: 'subject-field',
          isReadOnly: true,
          placeholderLabel: this.translate.instant('SUBJECT'),
          showArrow: false,
        },
        {
          label: this.translate.instant('TIME'),
          inputText: this.activityService.getFormattedMeetingTimeText(),
          id: 'time-field',
          isReadOnly: true,
          showArrow: false,
        },
        {
          label: this.translate.instant('ACTIVITY_DETAILS_LOCATION'),
          inputText: this.activity && this.activity.location && this.activity.location !== 'No Location'
                      && this.activity.location !== this.translate.instant('NO_LOCATION') ? this.activity.location : '',
          id: 'location-field',
          isReadOnly: true,
          placeholderLabel: this.translate.instant('ACTIVITY_DETAILS_LOCATION'),
          showArrow: false,
          formFieldType: FormFieldType.NEW_PAGE_SELECT,
        },
      ]
    );
  }

  private getCalendarInviteSubject() {

// OMNI-23107: If Meeting Auto Subject is Enbaled Then Append the Rep's name in teh Calendar Invite Subject Other wise Set the Plane Subject for the
    if (this.authService.hasFeatureAction(FeatureActionsMap.MEETING_AUTO_SUBJECT)) {
      return `${((this.activityService.selectedActivity && this.activityService.selectedActivity.subject) ? (this.activityService.selectedActivity.subject == 'Meeting' ? this.translate.instant('MEETING') : this.activityService.selectedActivity.subject) + '' : '')}`; //  - ${this.authService.user.displayName} We have to revert when there is a way to achive this.

    } else {
      return `${((this.activityService.selectedActivity && this.activityService.selectedActivity.subject) ? (this.activityService.selectedActivity.subject == 'Meeting' ? this.translate.instant('MEETING') : (this.authService.hasFeatureAction(FeatureActionsMap.VISIT_AUTO_SUBJECT) && this.activityService.selectedActivity.subject == 'Visit') ? this.translate.instant('VISIT') : this.activityService.selectedActivity.subject) + '' : '')}`
    }
  }

  private initFooter() {
    const options = {
      sendButton: {
        show: !!this.sendButtonRef,
        isActive: !this.isSendButtonDisabled(),
      },
      saveButton: {
        isActive: this.isSaveButtonEnabled(),
        buttonId: this.activity.isInviteSent === DynamicsBoolean.true ? 'generalUpdate' : 'generalSave',
      }
    };
    this.footerService.initButtons(FooterViews.CalendarInvite, options);
  }


  /**
   * Event handlers
   * ---------------------------------------------------------------------------------------------------------------------
   */
  private closePage() {
    this.emailService.selectedCalendarInviteTemplate = undefined;
    this.modalCtrl.dismiss();
    this.uiService.showNewActivity = false;
    this.footerService.initButtons(FooterViews.Activities);
  }
  onPageTitleControlClick(id: string) {
    switch (id) {
      case 'close':
        this.closePage();
        break;

      case 'send':
        this.sendMeetingInvite();
        break;

      case 'save':
        this.saveMeetingDescription();
        break;

      default:
        break;
    }
  }
  onAgendaSectionHeaderControlClick(id: string) {
    switch (id) {
      case 'agenda-template':
        this.openTemplatePage();
        break;

      default:
        break;
    }
  }
  onTinyMceChange(arg) {
    if (this.editorCmp?.editor) {
      this.inputChange$.next(this.editorCmp.editor.getContent());
    }
  }
  async onContactClick(event, contact: Contact) {
    if (contact) {
      let emailAddress = '';
      if (Array.isArray(contact.emailAddressList)) {
        const primaryEmail = contact.emailAddressList.find(e => e.isPrimary);
        emailAddress = primaryEmail?.emailAddress ?? '';
      }

      if (!this.emailToolTip) {
        this.emailToolTip = await this.popoverCtrl.create({
          component: PopoverComponent,
          componentProps: {
            tooltipData: {
              name: contact.fullName,
              emailAddress
            },
            field : 'calendarInviteEmailTooltip'
          },
          cssClass: 'emailAddress-popover',
          event
        });

        this.emailToolTip.onDidDismiss().then(() => {
          this.emailToolTip = undefined;
        });

        this.emailToolTip.present();
      }
    }
  }
  onFooterButtonClicked(buttonId: string) {
    switch (buttonId) {
      case 'generalSend':
        this.sendMeetingInvite();
        break;
      case 'generalSave':
      case 'generalUpdate':
        this.saveMeetingDescription();
        break;
      case 'generalTemplate':
        this.openTemplatePage();
        break;

      default:
        break;
    }
  }


  /**
   * Actions
   * ---------------------------------------------------------------------------------------------------------------------
   */
  private async sendMeetingInvite() {
    this.incLoadingCount();
    try {
      // Update the description first
      await this.saveMeetingDescription();

      // Flip indskr_invitesent attribute => begins exchange sync
      await this.meetingDataService.sendMeetingInvite(this.activity);
      this.postSendSuccess();

      this.decLoadingCount();
    } catch (error) {
      console.error('sendMeetingInvite: ', error);
      this.decLoadingCount();

      if (error instanceof GeneralException) {
        let shouldNotify: boolean = false;

        if (error.code === ErrorCode.E01) {
          shouldNotify = true;
        } else if (error.code === ErrorCode.E03) {
          this.postSendSuccess();
          shouldNotify = true;
        }

        if (shouldNotify) {
          this.notificationService.notify(
            error.message,
            'Calendar Invite',
            'top',
            ToastStyle.DANGER,
            0
          );
        }
      }
    }
  }
  private async saveMeetingDescription() {
    this.incLoadingCount();

    try {
      await this.meetingDataService.updateMeetingDescription(this.activity, this.editorContent);
      this.postDescriptionUpdateSuccess();

      this.decLoadingCount();
    } catch (error) {
      console.error('saveMeetingDescription: ', error);
      this.decLoadingCount();

      if (error instanceof GeneralException) {
        let shouldNotify: boolean = false;

        if (error.code === ErrorCode.E01) {
          shouldNotify = true;
        } else if (error.code === ErrorCode.E03) {
          this.postDescriptionUpdateSuccess();
          shouldNotify = true;
        }

        if (shouldNotify) {
          this.notificationService.notify(
            error.message,
            'Calendar Invite',
            'top',
            ToastStyle.DANGER,
            0
          );
        }
      }
    }
  }
  private postSendSuccess() {
    this.changeSaveButtonNameToUpdate();
    this.removeSendButton();
    this.refreshHeaderData();
    this.changeStatusTextToSent();
    this.updateFooter();
  }
  private postDescriptionUpdateSuccess() {
    this.origContent = this.editorContent;
    if (this.saveButtonRef) {
      this.saveButtonRef.isDisabled = this.isSaveButtonDisabledState = true;
    }
    this.updateFooter();
  }


  /**
   * Display data update helpers
   * ---------------------------------------------------------------------------------------------------------------------
   */
  private updateFooter() {
    const options = {
      update: true,
      sendButton: {
        show: !!this.sendButtonRef,
        isActive: !this.isSendButtonDisabled(),
      },
      saveButton: {
        isActive: this.isSaveButtonEnabled(),
        buttonId: this.activity.isInviteSent === DynamicsBoolean.true ? 'generalUpdate' : 'generalSave',
      }
    };
    this.footerService.initButtons(FooterViews.CalendarInvite, options);
  }
  private diffContent() {
    const isSame: boolean = this.editorContent === this.origContent;

    if (this.saveButtonRef) {
      this.isSaveButtonDisabledState = isSame;
      this.saveButtonRef.isDisabled = this.isSaveButtonDisabledState || this.deviceService.isOffline;
    }
    this.updateFooter();
  }
  private refreshHeaderData() {
    this.sendButtonRef = undefined;
    this.saveButtonRef = undefined;

    const clonedData = JSON.parse(JSON.stringify(this.calendarInvitePageTitle));
    this.calendarInvitePageTitle = clonedData;
    this.sendButtonRef = this.getHeaderButton('send');
    this.saveButtonRef = this.getHeaderButton('save');
  }
  private removeSendButton() {
    if (Array.isArray(this.calendarInvitePageTitle?.controls)
        && this.calendarInvitePageTitle.controls.length === 3
        && this.sendButtonRef) {

      this.sendButtonRef = undefined;
      this.calendarInvitePageTitle.controls.pop();
    }
  }
  private changeSaveButtonNameToUpdate() {
    if (this.saveButtonRef) {
      this.saveButtonRef.name = this.translate.instant('UPDATE');
    }
  }
  private changeStatusTextToSent() {
    if (this.statusFormField) {
      const clonedData = JSON.parse(JSON.stringify(this.statusFormField));
      clonedData.inputText = this.translate.instant('SENT');
      this.statusFormField = clonedData;
    }
  }


  /**
   * Navigation helpers
   * ---------------------------------------------------------------------------------------------------------------------
   */
  private openTemplatePage() {
    if (!document.getElementsByClassName('modal-wrapper')[0].classList.contains('fullStretchView')) {
      document.getElementsByClassName('modal-wrapper')[0].classList.add('fullStretchView');
    }
    if (this.modalCloseSubscription) {
      this.modalCloseSubscription.unsubscribe();
    }
    const evEmitter: EventEmitter<string> = new EventEmitter<string>();
    this.modalCloseSubscription = evEmitter.subscribe((modalId: string) => this.onModalClose(modalId));
    this.navCtrl.push(CalendarInviteTemplatePageComponent, { evEmitter }, { progressAnimation: false });
    return;
  }
  private onModalClose(modalId: string) {
    if (this.modalCloseSubscription) {
      this.modalCloseSubscription.unsubscribe();
    }
    this.initFooter();
  }


  /**
   * TinyMCE Helpers
   * ---------------------------------------------------------------------------------------------------------------------
   */
  private replaceContent(newContent: string) {
    this.editorCmp.editor.execCommand('mceSetContent', false, newContent);
    // Remove focus from the editor after content paste
    this.editorCmp.editor.iframeElement?.blur();
  }
  private handleTemplateSelection({ body, isEditable }) {
    if (body) {
      this.replaceContent(body);
    }
    // this.changeEditorMode(!!isEditable);
  }


  /**
   * General helpers
   * ---------------------------------------------------------------------------------------------------------------------
   */
  private getHeaderButton(id: string) {
    let btnRef: any;
    if (this.calendarInvitePageTitle && Array.isArray(this.calendarInvitePageTitle.controls)) {
      btnRef = this.calendarInvitePageTitle.controls.find(b => b.id === id);
    }
    return btnRef;
  }
  private getDefaultTemplate(): string {
    // Just get the very first template we find for now
    let defaultTemplateBody: string = '';
    const inviteTemplates = Array.isArray(this.emailService.emailTemplates)
                              ? this.emailService.emailTemplates
                                .filter(template => template.indskr_type === EmailTemplateType.CalendarInvite)
                              : undefined;
    if (Array.isArray(inviteTemplates) && inviteTemplates.length > 0) {
      defaultTemplateBody = this.emailService.personalizeCalendarInviteTemplate(this.activity, inviteTemplates[0]);
    }

    return defaultTemplateBody;
  }
  private setGlobalCustomerText() {
    switch (this.utilityService.globalCustomerText) {
      case 'Stakeholder':
        this.globalCustomerText = this.translate.instant('STAKEHOLDERS');
        break;
      case 'Contact':
        this.globalCustomerText = this.translate.instant('CONTACTS');
        break;
      case 'Customer':
        this.globalCustomerText = this.translate.instant('CUSTOMERS');
        break;
      default:
        this.globalCustomerText = this.utilityService.globalCustomerText;
        break;
    }
  }
  private isSendButtonDisabled(): boolean {
    return this.activity.isInviteSent === DynamicsBoolean.true
        || this.deviceService.isOffline
        || this.activityService.hasOfflineMeetingData(this.activity.ID)
        || !((Array.isArray(this.activity.contacts) && this.activity.contacts.length > 0 )
          || (Array.isArray(this.activity.accompaniedUserList) && this.activity.accompaniedUserList.length > 0));
  }
  private isSaveButtonEnabled(): boolean {
    return !this.isSaveButtonDisabledState && !this.deviceService.isOffline;
  }


  /**
   * Loader helpers
   * ---------------------------------------------------------------------------------------------------------------------
   */
  private incLoadingCount() {
    let newCount = this.isLoading$.getValue() + 1;
    this.isLoading$.next(newCount);
  }
  private decLoadingCount() {
    let newCount = this.isLoading$.getValue() - 1;
    if (newCount < 0) {
      newCount = 0;
    }
    this.isLoading$.next(newCount);
  }
}
