import { Activity } from './activity.class';
import { DB_KEY_PREFIXES } from '../../config/pouch-db.config';
import { addMinutes } from 'date-fns';
import { TrackAction } from '../../utility/common-enums';
import { Utility } from '../../utility/util';
import { OrderProductClass, OrderTypes } from './order.activity.class';
import * as moment from 'moment';
import { IONote } from '../io/io-note.class';
import * as _ from 'lodash'
import { Products } from '../presentation/presentation.class';
import { CoOwner } from './procedure-tracker.activity.class';
export class SurgeryOrderActivity extends Activity {

    public offlineId : string;
    public ownerNameString : string;
    public accountId : string;
    public accountNameString : string;
    public isAccountActive : boolean;
    public createdDate : Date;
    public pendingPushToDynamics : boolean;
    public products : Array<OrderProductClass> = [];
    public isTeamOrder : boolean = false;
    public isHardDeleted : boolean = false;
    public customers : Array <OrderContact> = [];
    public assets : Array<OrderAsset> = [];
    public customersNameString : string;
    public surgeryId : string;
    public surgeryNameString : string;
    public productCategory : Array <OrderProductCategory> = [];
    public orderType: any;
    public orderTypeFormatted: OrderTypes;
    public weekOfYear: number;
    public year: number;
    public month: number;
    public appointmentId : string;
    public offlineMeetingId : string;
    public indskr_noprocedureday : boolean;
    public procedureNotes: Array<IONote> = [];
    public indskr_datecompleted: string;
    public surveyResponse:any;
    public surveyResponseData:any;
    public surveyResponseLookupData:any;
    public surveyResponseAdditionalData:any;
    public surveyResponseDTO:any = {};
    public coOwners: CoOwner[] = [];

    constructor(raw: any) {
        super(raw);
        if(!this.ID){
            this.ID = raw['salesorderid'];
            if(!this.ID){
                this.ID = raw['indskr_externalid'];
            }
        }
        this.offlineId = (raw['indskr_externalid']) ? raw['indskr_externalid'] : '';
        this.ownerId = raw['ownerid'] || '';
        this.ownerNameString = (raw['ownerName']) ? raw['ownerName'] : '';
        if(raw['statuscode']){
            this.status = parseInt(raw['statuscode']);
        }
        this.subject = (raw['name']) ? raw['name'] : 'Procedure Log';
        this.accountId = (raw['customerid']) ? raw['customerid'] : '';
        this.appointmentId = (raw['appointmentId']) ? raw['appointmentId'] : '';
        this.offlineMeetingId = (raw['offlineMeetingId']) ? raw['offlineMeetingId'] : '';
        this.accountNameString = (raw['customerName']) ? raw['customerName'] : '';
        this.createdDate = (raw['createdon']) ? new Date(parseInt(raw['createdon'])) : null;
        this.indskr_noprocedureday = (raw['indskr_noprocedureday']) ? raw['indskr_noprocedureday'] : false;
        if(raw['createdon'] && !raw['scheduledstart']){ //indskr_scheduleddate
            // Manually create the schedule start and scheduled end from createdon field untill MSE introduces these fields in st.
            let roundedDownTimeForAgenda = Utility.getRoundedDownTime(new Date(parseInt(raw['createdon'])));
            this.scheduledStart = roundedDownTimeForAgenda;
            this.scheduledEnd = addMinutes(roundedDownTimeForAgenda,30);
            this.scheduledStartLocale = this.scheduledStart.toLocaleTimeString('en-US', { hour12: true, hour: '2-digit', minute:'2-digit'});
        }
        this.isHardDeleted = (raw['track_action'] && raw['track_action'] == TrackAction.Deleted) ? true : (raw['isHardDeleted'] ? raw['isHardDeleted'] : false) ;
        this.pendingPushToDynamics = (raw['pendingPushToDynamics']) ? raw['pendingPushToDynamics'] : null;
        if(raw['products'] && raw['products'].length > 0){
            this.products = [];
            raw['products'].forEach(product => {
                this.products.push(new OrderProductClass({
                  productId: product['productid'],
                  productName: (product['productName']) ? product['productName'] : '',
                  total: (product.extendedamount) ? product.extendedamount : 0,
                  formattedTotalAmount: (product['formattedExtendedamount'])? product['formattedExtendedamount'] : '',
                  unitPrice: (product.priceperunit) ? product.priceperunit : 0,
                  quantity: (product.quantity) ? product.quantity : 0,
                  uomid: (product['uomid']) ? product['uomid'] : '',
                  isDeleted: (product['isDeleted']) ? product['isDeleted'] : false,
                  productCategoryId: (product['productCategoryId']) ? product['productCategoryId'] : '',
                  productCategoryName: (product['productCategoryName']) ? product['productCategoryName'] : ''
                }));
            });
        };
        if(raw['productCategory'] && raw['productCategory'].length > 0){
          this.productCategory = [];
          raw['productCategory'].forEach(productCat => {
              this.productCategory.push(new OrderProductCategory({
                productCategoryId: productCat.productId,
                productCategoryName: productCat.productName,
              }));
          });
        };
        if(raw['assets'] && raw['assets'].length > 0){
          this.assets = [];
          raw['assets'].forEach(asset => {
              this.assets.push(new OrderAsset({
                msdyn_customerassetid: asset.msdyn_customerassetid || '',
                msdyn_name: asset.msdyn_name || '',
                ownerid: asset.ownerid || '',
                ownerName: asset.ownerName || '',
                createdon: asset.createdon || '',
                salesorderid: asset.salesorderid || ''
              }));
          });
        };
        if(raw['contactorders'] && raw['contactorders'].length > 0){
          this.customers = [];
          raw['contactorders'].forEach(contact => {
              this.customers.push(new OrderContact({
                id: contact['id'] || '',
                firstName: contact['firstName'] || '',
                title: contact['title'] || '',
                middleName: contact['middleName'] || '',
                lastName: contact['lastName'] || '',
                stateCode: contact['stateCode'],
                statusCode: contact['statusCode'],
              }));
          });
        };
        if(raw['notes'] && raw['notes'].length > 0){
          this.procedureNotes = [];
          raw['notes'].forEach(note => {
            this.procedureNotes.push(new IONote({
              annotationid: note['annotationid'] || '',
              createdon: note['createdon'] || null,
              notetext: note['notetext'] || '',
              ownerid: note['ownerid'] || '',
              ownerName: note['ownerName'] || '',
              deleted: note['deleted'] || false,
              updated: note['updated'] || false,
            }));
          });
        }
        this.surgeryId = raw['indskr_surgeryproduct'];
        this.surgeryNameString = raw['indskr_surgeryproductName'] || '';
        this.orderType = 548910001;
        this.orderTypeFormatted = OrderTypes.Surgery;
        //this.location = raw['indskr_location'] || this.location || '';

        if(raw['indskr_scheduleddate']){
          this.scheduledStart = new Date(parseInt(raw['indskr_scheduleddate']));
        }

        if(raw['indskr_scheduledenddate']){
          this.scheduledEnd = new Date(parseInt(raw['indskr_scheduledenddate']));
          this.setYearMonthWeek();
        } else {
          this.scheduledEnd = addMinutes(this.scheduledStart, 30);
        }
        this.customersNameString = raw['contactformattedstring'] || this.getCustomerString();
        if(raw['accountStatusCode'] === 2 || raw['accountStatusCode'] == 548910003 || raw['accountStatusCode'] == 548910010 || raw['accountStatusCode'] == 548910011 || raw['accountStatusCode'] == 548910012 || raw['accountStatusCode'] == 548910013) {
          this.isAccountActive = false;
        }else{
          this.isAccountActive = true;
        }
        this.indskr_datecompleted = raw['indskr_datecompleted'];

        this.surveyResponse = raw['surveyResponse'] || '';
        this.surveyResponseData = raw['surveyResponseData'] || '';
        this.surveyResponseLookupData = raw['surveyResponseLookupData'] || [];
        this.surveyResponseAdditionalData = raw['surveyResponseAdditionalData'] || {};

        this.coOwners = raw['coOwners'] || [];
    }

    public get coOwnersUpdateDTO(): any {
      if (!this.coOwners || !this.ID) return {};
      return {
        salesorderid: this.ID,
        coOwners: this.coOwners
      }
    }

    public get offlineDBId():string {
        if(this.offlineId){
            return DB_KEY_PREFIXES.SURGERY_ORDER_ACTIVITY + this.offlineId;
        }else{
            return DB_KEY_PREFIXES.SURGERY_ORDER_ACTIVITY + this.ID;
        }
    }

    public get surgeryOrderStatusString():string {
        let str:string = '';
        switch(this.status){
            case 100001:
            case 548910001:
                str = 'Completed';
                break;
            default:
                str = 'Draft';
        }
        return str;
    }

    public getCustomerString(): string {
      let str = '';
      const currentCustomers = this.customers;
      if (currentCustomers) {
          if (currentCustomers.length === 1) {
              str = `${currentCustomers[0].fullname}`;
              if(currentCustomers[0].statusCode !== 1 && currentCustomers[0].statusCode !== 548910000){
                  str += ' (Inactive)';
              }
          } else if (currentCustomers.length >= 2) {
            currentCustomers.sort((a, b): number => {
                  if (a.fullname > b.fullname) return 1;
                  if (a.fullname < b.fullname) return -1;
                  return 0;
              });
              let frstStr = '';
              let scndStr = '';
              if(currentCustomers[0].statusCode !== 1 && currentCustomers[0].statusCode !== 548910000){
                  frstStr = ' (Inactive)';
              }else if(currentCustomers.some(a=> a.statusCode !== 1 && a.statusCode !== 548910000)){
                  scndStr = ' (Inactive)';
              }
              str = `${currentCustomers[0].fullname}${frstStr} +${currentCustomers.length - 1}${scndStr}`;
          }
      }
      return str;
    }

    public get serviceDTO():any{
        let payloadProducts = [];
        this.products.map(product => {
            if(!product.isDeleted){
                payloadProducts.push({
                    productid: product.productId,
                    quantity: product.quantity,
                    uomid: product.uomid,
                    indskr_productCategoryId: product.productCategoryID
                });
            }
        });
        let contactorders = [];
        this.customers.map(con=>{
          contactorders.push({
            contactid:con.ID
          })
        })
        let product_category = [];
        this.productCategory.map(prod=>{
          product_category.push({
            productid:prod.productCategoryId
          })
        })
        let notes = [];
        this.procedureNotes.map(note => {
          notes.push({
            annotationid: (note.noteId && !note.noteId.includes('offline'))?note.noteId:'',
            notetext: note.noteText,
            deleted: note.isDeleted,
            updated: note.updated
          })
        })
        let assets = [];
        this.assets.map(asset => {
          assets.push({
            msdyn_customerassetid: asset.msdyn_customerassetid,
            msdyn_name: asset.msdyn_name,
            salesorderid: asset.salesorderid
          })
        })
        let payload = {
            indskr_externalid: this.offlineId  ? this.offlineId : '',
            customerid_account: (this.accountId) ? this.accountId : '',
            ownerid: this.ownerId,
            createdon: this.createdDate.getTime()+'',
            name: this.subject,
            products: payloadProducts,
            indskr_ordertype: this.orderType,
            indskr_surgeryproduct: (this.surgeryId) ? this.surgeryId: "",
            //indskr_location:this.location,
            contactorders: contactorders,
            product_category: product_category,
            indskr_scheduledenddate: this.scheduledEnd.getTime()+'',
            indskr_scheduleddate: this.scheduledStart.getTime()+'',
            indskr_noprocedureday : this.indskr_noprocedureday,
            notes: notes,
            indskr_datecompleted: this.indskr_datecompleted,
            assets: assets
            //appointmentId: this.appointmentId ? this.appointmentId : '',
        };
        if(this.appointmentId){
          payload['appointmentId'] = this.appointmentId;
        }else if(this.offlineMeetingId){
          payload['offlineMeetingId'] = this.offlineMeetingId;
        }
        if(!this.ID.includes('offline')){
            payload['salesorderid'] = this.ID;
        }
        if((this.state == 2 && this.status == 4) || (this.state == 1 && this.status == 548910001)){
            payload['statecode'] = this.state+'';
            payload['statuscode'] = this.status+'';
        }
        if(this.surveyResponseDTO){
          payload['surveyResponses'] = this.surveyResponseDTO;
        }

        if(this.coOwners){
          payload['coOwners'] = this.coOwners;
        }

        return payload;
    }

    public get statusUpdateDTO():any {
        if(!this.ID) return {};
        return {
            salesorderid: this.ID,
            statecode: this.state+'',
            statuscode:this.status+'',
            indskr_datecompleted: this.indskr_datecompleted
        };
    }

    public get offlineDataDTO():any{
        return {
            indskr_externalid : this.offlineId,
            salesorderid: this.ID,
            ownerName : this.ownerNameString,
            customerid : this.accountId,
            customerName : this.accountNameString,
            pendingPushToDynamics : this.pendingPushToDynamics,
            isHardDeleted: this.isHardDeleted,
            statuscode : this.status,
            statecode : this.state ,
            ownerid : this.ownerId,
            activitytypecode : this.type,
            createdon : this.createdDate.getTime()+'',
            name: this.subject,
            scheduledstart: this.scheduledStart.getTime()+'',
            scheduledend: this.scheduledEnd.getTime()+'',
            indskr_ordertype: this.orderType,
            indskr_surgeryproduct: (this.surgeryId) ? this.surgeryId : "",
            indskr_surgeryproductName: this.surgeryNameString,
            //indskr_location:this.location,
            contactformattedstring: this.customersNameString,
            indskr_scheduledenddate: this.scheduledEnd.getTime()+'',
            indskr_scheduleddate: this.scheduledStart.getTime()+'',
            appointmentId: this.appointmentId ? this.appointmentId : '',
            offlineMeetingId: this.offlineMeetingId ? this.offlineMeetingId : '',
            indskr_noprocedureday : this.indskr_noprocedureday,
            indskr_datecompleted: this.indskr_datecompleted,
            contactorders: this.customers.map(customer => {
              return {
                id:customer.ID,
                firstName:customer.firstName,
                title:customer.title,
                middleName:customer.middleName,
                lastName:customer.lastName,
                fullName:customer.fullname,
                stateCode:customer.isActive ? 0 : 1,
                statusCode:customer.statusCode,
              };
            }),
            productCategory: this.productCategory.map (category => {
              return {
                productId: category.productCategoryId,
                productName: category.productCategoryName
              };
            }),
            products: this.products.map(product => {
                return {
                    productid: product.productId,
                    productName: product.productName,
                    extendedamount: product.total,
                    formattedExtendedamount: product.formattedTotal,
                    priceperunit: product.unitPrice,
                    quantity: product.quantity,
                    isDeleted: product.isDeleted,
                    uomid: product.uomid,
                    productCategoryId: product.productCategoryID,
                    productCategoryName: product.productCategoryName
                }
            }),
            notes: this.procedureNotes.map(note => {
              return {
                annotationid: note.noteId,
                notetext: note.noteText,
                deleted: note.isDeleted,
                ownerid: note.ownerId,
                ownerName: note.ownerName,
                updated: note.updated,
                createdon: note.createdTime?note.createdTime.getTime().toString():0,
              };
            }),
            coOwners: this.coOwners,
            assets: this.assets
        }

    }

  resetScheduledStartLocale() {
    this.scheduledStartLocale = this.scheduledStart.toLocaleTimeString('en-US', { hour12: true, hour: '2-digit', minute:'2-digit'});
  }

  setYearMonthWeek() {
    if (this.scheduledEnd) {
      this.weekOfYear = moment(this.scheduledEnd).week();
      this.year = this.scheduledEnd.getFullYear();
      this.month = this.scheduledEnd.getMonth() + 1;
    }
  }
}

export class OrderContact {

  public ID: string;
  public title: string;
  public firstName: string;
  public middleName: string;
  public lastName: string;
  public fullname: string;
  public isActive: boolean;
  public statusCode: number;

  constructor(raw:any){
    this.ID = (raw['id']) ? raw['id'] : '';
    this.title = raw["title"] || "";
    this.firstName = raw["firstName"] || "";
    this.middleName = raw['middleName'] || "";
    this.lastName = raw["lastName"] || "";
    this.fullname = raw['fullName'] ? raw['fullName'] : `${raw["firstName"]} ${raw["lastName"] ? raw["lastName"] : ''}`;
    this.isActive = !(raw['stateCode'] == 1 && (raw['statusCode'] == 2 || raw['statusCode'] == 548910003 || raw['statusCode'] == 548910010 || raw['statusCode'] == 548910011 || raw['statusCode'] == 548910012 || raw['statusCode'] == 548910013));
    this.statusCode = raw['statusCode'] || null;
  }

}

export class OrderAsset {

  public msdyn_customerassetid: string;
  public msdyn_name: string;
  public ownerid: string;
  public ownerName: string;
  public createdon: string;
  public salesorderid: string;

  constructor(raw:any){
    this.msdyn_customerassetid = (raw['msdyn_customerassetid']) ? raw['msdyn_customerassetid'] : '';
    this.msdyn_name = raw["msdyn_name"] || "";
    this.ownerid = raw["ownerid"] || "";
    this.ownerName = raw['ownerName'] || "";
    this.createdon = raw["createdon"] || "";
    this.salesorderid = raw["salesorderid"] || "";
  }

}

export class OrderProductCategory {

  public productCategoryId:string;
  public productCategoryName:string;

  constructor(raw:any){
    this.productCategoryId = (raw['productCategoryId']) ? raw['productCategoryId'] : '';
    this.productCategoryName = raw["productCategoryName"] || "";
  }

}



/**
 *
 * @param rawData
 * @odata.etag:"W/"289288839"",
indskr_name:"Positon Group 4",
indskr_positiongroupsid:"64a549f0-f951-e911-a97f-000d3af49211",
createdon@OData.Community.Display.V1.FormattedValue:"3/29/2019 1:39 PM",
createdon:"2019-03-29T08:09:17Z",
surgery.productid@OData.Community.Display.V1.AttributeName:"productid",
surgery.productid:"ee33e1ec-951b-ec11-b6e6-00224828c952",
skuandcategories.indskr_producttype@OData.Community.Display.V1.AttributeName:"indskr_producttype",
skuandcategories.indskr_producttype@OData.Community.Display.V1.FormattedValue:"SKU",
skuandcategories.indskr_producttype:100000003,
surgery.validtodate@OData.Community.Display.V1.AttributeName:"validtodate",
surgery.validtodate@OData.Community.Display.V1.FormattedValue:"12/31/2021",
surgery.validtodate:"2021-12-31",
skuandcategories.statuscode@OData.Community.Display.V1.AttributeName:"statuscode",
skuandcategories.statuscode@OData.Community.Display.V1.FormattedValue:"Active",
skuandcategories.statuscode:1,
surgery.name@OData.Community.Display.V1.AttributeName:"name",
surgery.name:"Spine Surgery",
surgery.statuscode@OData.Community.Display.V1.AttributeName:"statuscode",
surgery.statuscode@OData.Community.Display.V1.FormattedValue:"Active",
surgery.statuscode:1,
surgery.validfromdate@OData.Community.Display.V1.AttributeName:"validfromdate",
surgery.validfromdate@OData.Community.Display.V1.FormattedValue:"1/1/2021",
surgery.validfromdate:"2021-01-01",
skuandcategories.name@OData.Community.Display.V1.AttributeName:"name",
skuandcategories.name:"Inflammatory Medicine",
surgery.statecode@OData.Community.Display.V1.AttributeName:"statecode",
surgery.statecode@OData.Community.Display.V1.FormattedValue:"Active",
surgery.statecode:0,
surgery.productstructure@OData.Community.Display.V1.AttributeName:"productstructure",
surgery.productstructure@OData.Community.Display.V1.FormattedValue:"Product Bundle",
surgery.productstructure:3,
skuandcategories.productid@OData.Community.Display.V1.AttributeName:"productid",
skuandcategories.productid:"04c0e4d6-961b-ec11-b6e6-00224828c952"
 * @returns
 */

export enum PRODUCT_CATEGORY_SKU {
  category = 548910000,
  sku = 100000003
}

export interface SKUProduct {
  productId: string;
  productName: string;
  productStatecode: number;
  uomid: string;
  productCategoryId: string;
  productCategoryName: string;
  indskr_newproductintroduction: boolean;
}

export interface PositionGroup {
  positiongroupid: string;
  statecode: number;
  state: string;
}
export class SurgeryProductHierarchy{
  buproductstatecode:number;
  surgeryId:string;
  psoitiongroupProductIDs: string[];
  positionGroups: PositionGroup[];
  surgeryName:string;
  surgeryStatecode:number;
  surgeryStatuscode:number;
  trackAction:any;
  surveyTemplateId?:string;
  hasTagAssets?:boolean;
  productCategories:{
    productCategoryId: string;
    productCategoryName: string;
    productCategoryStatecode: number;
    indskr_newproductintroduction: boolean;
    products:SKUProduct[]
  }[]
  subProducts: {
    productId:string;
    productName:string;
  }[]
  constructor(raw, surgerySKUProductRelationship){

    this.buproductstatecode = raw['positiongroupProduct.statecode'] ;
    this.surgeryId = raw['surgery.productid'];
    this.surgeryName = raw['surgery.name'] || '';
    this.surgeryStatecode = raw['surgery.statecode'];
    this.surgeryStatuscode = raw['surgery.statuscode'];
    this.trackAction = raw['track_action'] || 548910000;
    if(raw['surgeryTemplateAssets.statuscode'] == 1){
      this.surveyTemplateId = raw['surgeryTemplate.indskr_expertinquirytemplateid'] || '';
      this.hasTagAssets = raw['surgeryTemplateAssets.indskr_tagassets'] || false;
    }else{
      this.surveyTemplateId = ''
      this.hasTagAssets = false;
    }

    this.psoitiongroupProductIDs = [raw['positiongroupProduct.indskr_positiongroupproductid']];

    this.positionGroups = [{
      positiongroupid: raw['indskr_positiongroupsid'] || '',
      statecode: raw['statecode'],
      state: raw['statecode@OData.Community.Display.V1.FormattedValue']
    }];
    let products: SKUProduct[] = [];
    let filteredRecords = surgerySKUProductRelationship.filter(o => o['productid'] === this.surgeryId && o['skuProduct.productid'] === raw['skuProduct.productid'])
    if (!_.isEmpty(filteredRecords)) {
      products = [{
        productId: raw['skuProduct.productid'] || '',
        productName: raw['skuProduct.name'] || '',
        productStatecode: raw['skuProduct.statecode'],
        uomid: raw['skuProduct.defaultuomid'] || '',
        productCategoryId: raw['categoryProduct.productid'] || '',
        productCategoryName: raw['categoryProduct.name'] || '',
        indskr_newproductintroduction: raw['skuProduct.indskr_newproductintroduction'] ? raw['skuProduct.indskr_newproductintroduction'] : false
      }]
    }

    this.productCategories = [{
      productCategoryId:raw['categoryProduct.productid'] || '',
      productCategoryName:raw['categoryProduct.name'] || '',
      productCategoryStatecode:raw['categoryProduct.statecode'],
      products: products,
      indskr_newproductintroduction: raw['categoryProduct.indskr_newproductintroduction'] ? raw['categoryProduct.indskr_newproductintroduction'] : false
    }];

    if(raw['subProducts.productid'])
    this.subProducts = [{
      productId: raw['subProducts.productid'],
      productName: raw['subProducts.name']
    }]
  }
}


export class SurgeryNewProductHierarchy{
  surgeryId:string;
  surgeryName:string;
  surgeryStatecode:number;
  surgeryStatuscode:number;
  productCategories: SurgeryCategoryProduct[]
  constructor(raw){
    this.surgeryId = raw['surgery.productid'];
    this.surgeryName = raw['surgery.name'] || '';
    this.surgeryStatecode = raw['surgery.statecode'];
    this.surgeryStatuscode = raw['surgery.statuscode'];
    this.productCategories = [new SurgeryCategoryProduct(raw)];
  }
}

export class SurgeryCategoryProduct {

  productCategoryId: string;
  productCategoryName: string;
  productCategoryStatecode: number;
  products: SurgerySKUProduct[];

  constructor(raw) {
    this.products  = [new SurgerySKUProduct(raw)];
  }
}

export class SurgerySKUProduct {
  productId: string;
  productName: string;
  productStatecode: number;
  uomid: string;
  productCategoryId: string;
  productCategoryName: string;
  constructor (raw) {
    this.productId = ''
  }
}

export enum PRODUCT_DISPLAY {
  ALL_PRODUCTS = 548910000,
  NPI_PRODUCTS_ONLY = 548910001,
  ALL_EXCLUDING_NPI_PRODUCTS = 548910002
}