import { Component, Input, Output, EventEmitter, SimpleChanges, ChangeDetectorRef } from "@angular/core";
import { TherapeuticAreaDataService } from "../../../../data-services/therapeutic-area/therapeutic-area.data.service";
import { TherapeuticArea } from "../../../../classes/therapeutic-area/therapeutic-area.class";
import { ActivityService } from "../../../../services/activity/activity.service";
import { TranslateService } from '@ngx-translate/core';



@Component({
    selector: 'therapeutic-area-list',
    templateUrl:'therapeuticarea-list.html',
  styleUrls:['therapeutic-area-list.scss']
})

export class TherapeuticAreaList{

    public orgtherapeuticAreas:TherapeuticArea[] = [];
    public isDataDirty:boolean = false;

    @Input() therapeuticAreas:TherapeuticArea[];
    @Input() isReadOnlyJointMeeting:boolean;
    @Input() backgroundUploadInProgress: boolean;

    @Output() saveTA:EventEmitter<TherapeuticArea[]>=new EventEmitter();
    @Input() isTARequired: boolean;

    constructor(
        public theraputicService:TherapeuticAreaDataService,
        public activityService:ActivityService,
        public _cd: ChangeDetectorRef,
        public translate: TranslateService,
    ){
    }

    ngOnChanges(changes:SimpleChanges){
        if(changes['therapeuticAreas']){
            this.isDataDirty = false;
            this.orgtherapeuticAreas = JSON.parse(JSON.stringify(this.therapeuticAreas));
        }
    }

    onSaveTA(){
      if(!this.isReadOnlyJointMeeting && !this.activityService.teamViewActive && !this.backgroundUploadInProgress && !this.activityService.selectedActivity?.isDiffPosition){
        this.saveTA.emit(this.therapeuticAreas);
        this.orgtherapeuticAreas = JSON.parse(JSON.stringify(this.therapeuticAreas));
        this.isDataDirty = false;
        this._cd.detectChanges();
      }
    }

    updateTA(ev,ta:TherapeuticArea){
      if(!this.isReadOnlyJointMeeting && !this.activityService.teamViewActive && !this.backgroundUploadInProgress && !this.activityService.selectedActivity?.isDiffPosition){
        if(!ta.isAutoSelected){
            ta.isSelected = !ta.isSelected;
        }

        this.isDataDirty = this.orgtherapeuticAreas.some(ota => ota.therapeuticareaId === ta.therapeuticareaId && ota.isSelected !== ta.isSelected);

        this._cd.detectChanges();
      }
    }

    reorderData(detail:any){
      detail.complete();
    }

    onSectionHeaderControlClick(id) {
      if (id === 'ta-header-save') {
      this.onSaveTA();
      }
    }
}
