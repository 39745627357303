import { OrderByPipe } from "./../../../pipes/orderby.pipe";
import { FooterService, FooterViews } from "./../../../services/footer/footer.service";
import { UIService } from "./../../../services/ui/ui.service";
import {
  NavigationService,
  PageName
} from "./../../../services/navigation/navigation.service";
import { Component, ViewChildren, Input, ChangeDetectorRef, ElementRef, HostListener } from "@angular/core";
import {
  ResourceService,
  ResourceView
} from "../../../services/resource/resource.service";
import { Resource } from "../../../classes/resource/resource.class";
import { DeviceService } from "../../../services/device/device.service";
import { ModalController, PopoverController, IonNav } from "@ionic/angular";
import { ActivityService } from "../../../services/activity/activity.service";
import { MultiSelectPopover } from "../../multi-select-popover/multi-select-popover";
import { EventsService } from "../../../services/events/events.service";
import { FeatureActionsMap } from "../../../classes/authentication/user.class";
import { AuthenticationService } from "../../../services/authentication.service";
import { ContentMatchingService, CONTENTMATCHTYPE } from "../../../services/content-matching/content-matching.service";
import { TrackService, TrackingEventNames } from "../../../services/logging/tracking.service";
import { EmailViewType } from "../../../classes/activity/email.activity.class";
import { DeltaService } from "../../../data-services/delta/delta.service";
import { EmailService } from "../../../services/email-templates/email.service";
import { TranslateService } from "@ngx-translate/core";
import { RepServices } from '../../../data-services/rep/rep.services';
import { SearchConfigService } from "../../../services/search/search-config.service";
import { IndKeyControlsAreaModel } from './../../../models/indKeyControlsAreaModel';
import { MainCardViewDataModel } from "../../../models/MainCardViewDataModel";
import { IndSectionHeaderViewDataModel } from "../../../models/indSectionHeaderDataModel";
import { SelectedSuggestionPillDataModel, SuggestionPillType, UserSavedSearchTypes } from "../../../models/search-config-data-model";
import { GlobalUtilityService } from "../../../services/global-utility.service";
import * as _ from 'lodash';
import { AlertService } from "../../../services/alert/alert.service";
import { SearchConfigDataService } from "../../../data-services/search-config/search-config-data-service";
import { DiskService } from "../../../services/disk/disk.service";
import { DB_KEY_PREFIXES } from "../../../config/pouch-db.config";
import { ResourceState } from '../../../store/application.state';
import { Store } from "@ngrx/store";
import * as ResourceAction from '../../../store/io-file-service/actions/resource.actions';
import { NotificationService } from "../../../services/notification/notification.service";
import { ToastStyle } from "../../../services/notification/notification.service";
import { ResourceDataService } from "../../../data-services/resource/resource.data.service";
import { ContentCustomSortPipe } from "../../../pipes/content-custom-sort.pipe";
import { Utility } from "../../../utility/util";
import {IndHeaderLeftDataModel} from "../../../models/indHeaderLeftDataModel";
import { CallbackPipe } from '../../../pipes/callback.pipe';
import { AgendaFooterService, AgendaFooterView } from "@omni/services/footer/agenda-footer.service";
import { FeatureActionsService } from "@omni/services/feature-actions/feature-actions.service";
@Component({
  selector: "resource-list",
  templateUrl: "resource-list.html",
  styleUrls: ['resource-list.scss']
})
export class ResourceListComponent {
  @Input() viewMode: ResourceView;
  @Input() resourcesFromNav: Resource[];
  @Input() collectionName: string;

  public listTitle: string = this.translate.instant("RESOURCES")

  public isAddToEmail = false;
  public resourceMode = ResourceView;
  public emailResource: Resource[] = [];
  public newResources: Resource[] = [];
  public contentMatchedRes: Resource[] = [];

  public isSingleFileType: boolean = false;
  public resourceCollectionName: string;

  resources: Array<Resource> = [];
  filteredList: Resource[] = [];
  allowPushResource: boolean;
  // filterPopoverData: any[];
  sortBy: { text: string; value: string; asc: boolean; };
  resourceFilter = { title: "", assetType: "", brands: "" };

  @ViewChildren('resourceItem') displayedResourceItem;
  isContentMatching: boolean;
  // Boolean to track if changes are made to email resource
  private isModified = false;
  public isSelectAll: boolean = false;
  public selectedResourceList: Resource[] = [];
  sortPopoverData: ({ text: string; expanded: boolean; value: string; items: { text: string; value: string; asc: boolean; }[]; handler: (selectedItem: any, item: any) => void; })[] | ({ text: string; value: string; items: { text: String; value: String; }[]; handler: (selectedItem: any, item: any) => void; expanded?: undefined; })[];
  get hasResources(): boolean {
    return this.resources && this.resources.length > 0;
  }

  //@ViewChild(Content, {static: true}) content: Content;

  public resourceListKeyControlAreaModel: IndKeyControlsAreaModel;
  public searching: boolean = false;
  suggestionsActive: boolean = false;
  shouldFireSearchChangeEvent: boolean = true;
  resourceSearchText: string;
  public singleSelect: boolean = false;
  @Input() resourceListMode: string;
  suggestionsData: {
    header: IndSectionHeaderViewDataModel,
    listData: MainCardViewDataModel[]
  }[] = [];
  //search key to be passed to search-suggestion-popover
  searchKey: string = '';
  selectedSuggestionsData: SelectedSuggestionPillDataModel[] = [];
  disableSaveSearch: boolean = false;

  public filterMetadata = { count: 0 };
  contentCustomSortPipe: ContentCustomSortPipe<Resource> = new ContentCustomSortPipe<Resource>();

  public filterByDate = Utility.filterByDate;
  public emailResourceCopy: Resource[];
  indHeaderLeftModel: IndHeaderLeftDataModel;
  public allResourcesHeaderModel: IndSectionHeaderViewDataModel;
  slicedResources: Resource[];
  sliceCount = 15;
  private callbackPipe: CallbackPipe = new CallbackPipe();
  private orderBy = new OrderByPipe();

  constructor(
    public resourceService: ResourceService,
    public resourceDataService: ResourceDataService,
    public navService: NavigationService,
    protected popoverCtrl: ModalController,
    private events: EventsService,
    protected popover: PopoverController,
    private footerService: FooterService,
    private activityService: ActivityService,
    public uiService: UIService,
    private authService: AuthenticationService,
    public contentMatchService: ContentMatchingService,
    public trackingService: TrackService,
    private deltaService: DeltaService,
    private emailService: EmailService,
    private navCtrl: IonNav,
    public translate: TranslateService,
    public repService: RepServices,
    private resourceListElement: ElementRef,
    public searchConfigService: SearchConfigService,
    public utilityService: GlobalUtilityService,
    public _cd: ChangeDetectorRef,
    public disk: DiskService,
    public alertService: AlertService,
    public searchConfigDataService: SearchConfigDataService,
    public store: Store<ResourceState>,
    private notificationService: NotificationService,
    public deviceService: DeviceService,
    protected cdRef: ChangeDetectorRef,
    private agendaFooterService: AgendaFooterService,
    private faService: FeatureActionsService,
  ) { }
  ngOnInit() {
    if (this.viewMode === this.resourceMode.ADDTOEMAIL) {
      this.isAddToEmail = true;
      this.addSelectedEmailResource();
    }

    if (this.viewMode === this.resourceMode.DOCUMENTS) {
      this.isSingleFileType = true;
      this.listTitle = this.translate.instant("R_STRATEGY_DOCUMENTS")
    }

    this.resources = this.resourceService.allResources.map(resource => {
      resource.assetType = resource.assetType || "other";
      resource.isSelected = false;
      return resource;
    });
    if (this.resourcesFromNav) {
      this.resources = this.resourcesFromNav;
      this.resourceCollectionName = this.collectionName || '';
    }
    if (this.deltaService.deltaRecordsDTO.resources.length) {
      this.resourceService.allResources.forEach(resource => {
        if (this.deltaService.deltaRecordsDTO.resources.indexOf(resource.ioResourceId) != -1 ||
          this.deltaService.deltaRecordsDTO.resources.indexOf(resource.ioDocumentId) != -1) {
          this.newResources.push(resource);
        }
      });
    }

    this.resourceService.updateNewResourceStatus(this.newResources);

    this.contentMatchService.isContentMatchingApplied = false;
    if (this.resourceService.viewMode === ResourceView.PUSH && this.authService.hasFeatureAction(FeatureActionsMap.CONTENT_MATCHING)) {
      this.resources = this.contentMatchService.SelectedContactsPresentation(this.resources, CONTENTMATCHTYPE.RESOURCES, this.activityService.selectedActivity);
      this.contentMatchedRes = this.resources;
      this.sliceRecordsForInfiniteScroll();
    }

    if (this.searchConfigService.configUpdateRequired) {
      this.searchConfigService.updateSearchConfigsForSelectedLanguage();
      this.searchConfigService.configUpdateRequired = false;
    }

    let orderBy = new OrderByPipe();
    // this.filterPopoverData = [
    //   {
    //     text: "",
    //     value: "All",
    //     items: [{ text:this.translate.instant('SMALL_ALL_RESOURCES'), value: 'All' }],
    //     handler: (selectedItem, item, itemRef) => {
    //       itemRef.parent.items.map((o)=>{
    //         o.value = '';
    //       })
    //       item.value = selectedItem.value;
    //       this.resourceFilter = Object.assign({}, this.resourceFilter, { brands: '', assetType: ''  });
    //         // if (item.value !== '') {
    //         //     this.trackingService.tracking('PresentationProductFilter',TrackingEventNames.PRESENTATIONS);
    //         // }
    //         //this.resources = this.presentationFilterByPipe.transform(this.sortedPresentations, this.presentationFilter);
    //     }
    //   },
    //   {
    //     text: this.translate.instant('PRODUCT'),
    //     value: "",
    //     items: orderBy.transform(this.brandService.brands.map(b => ({ text: b.name, value: b.name })), "text", true),
    //     handler: (selectedItem, item, itemRef) => {
    //       itemRef.parent.items[0].value=''
    //       item.value = item.value === selectedItem.value ? "" : selectedItem.value;
    //       this.resourceFilter.brands = item.value;
    //       if(this.resourceFilter.brands == '' && this.resourceFilter.assetType == ''){
    //         itemRef.parent.items[0].value='All'
    //       }
    //     }
    //   },
    //   {
    //     text: this.translate.instant('TYPE'),
    //     value: "",
    //     items: [
    //       { text: this.translate.instant('R_DOCUMENT'), value: "document" },
    //       { text: this.translate.instant('R_IMAGE'), value: "image" },
    //       { text: this.translate.instant('VIDEO'), value: "video" },
    //       { text: this.translate.instant('R_WEB'), value: "web" },
    //       { text: this.translate.instant('OTHER'), value: "other" }
    //     ],
    //     handler: (selectedItem, item, itemRef) => {
    //       itemRef.parent.items[0].value=''
    //       item.value = item.value === selectedItem.value ? "" : selectedItem.value;
    //       this.resourceFilter.assetType = item.value;
    //       if(this.resourceFilter.brands == '' && this.resourceFilter.assetType == ''){
    //         itemRef.parent.items[0].value='All'
    //       }
    //     }
    //   }
    // ];
    this.sortPopoverData = [
      {
        text: "",
        expanded: true,
        value: "title",
        items: [
          { text: this.translate.instant('NAME'), value: "title", asc: true },
          { text: this.translate.instant('LAST_MODIFIED_DATE'), value: "modifiedOn", asc: false },
          ... this.isSingleFileType ? [] : [{ text: this.translate.instant('TYPE'), value: "assetType", asc: true }]
        ],
        handler: (selectedItem, item) => {
          item.value = selectedItem.value;
          this.sortBy = selectedItem;
          if (selectedItem.value == '$downloaded') {
            this.filteredList = this.contentCustomSortPipe.transform(this.filteredList, { downloaded: true });
            this.resources = this.contentCustomSortPipe.transform(this.resources, { downloaded: true });
            this.newResources = this.contentCustomSortPipe.transform(this.newResources, { downloaded: true });
            this.emailResource = this.contentCustomSortPipe.transform(this.emailResource, { downloaded: true });
          }
          this.sliceRecordsForInfiniteScroll();
          this.initAllResourcesSectionHeader();
        }
      }
    ];
    this.sortBy = this.sortPopoverData[0].items[0];
    if (this.deviceService.isNativeApp) {
      this.sortPopoverData[0].items.push({ text: this.translate.instant('DOWNLOADS'), value: "$downloaded", asc: true });
    }
    this.sliceRecordsForInfiniteScroll();
    this.allowPushResource = this.resourceService.viewMode === ResourceView.PUSH;
    this.initResourceHeaderLeft();
    this.initAllResourcesSectionHeader();
  }
  // filter() {
  //   this.trackingService.tracking('ResourceFilter', TrackingEventNames.RESOURCES);
  //   if (this.resourceService.viewMode === ResourceView.PUSH && this.authService.hasFeatureAction(FeatureActionsMap.CONTENT_MATCHING)) {
  //     this.resources = this.contentMatchedRes;
  //   }
  //   this.popover
  //     .create(MultiSelectPopover, { root: this.filterPopoverData })
  //     .present({ ev: event });

  //   this.resourceFilter.title = '';
  // }
  sort() {
    if (this.resourceService.viewMode === ResourceView.PUSH && this.authService.user.featureActions.indexOf(FeatureActionsMap.CONTENT_MATCHING) > - 1) {
      this.resources = this.contentMatchedRes;
      this.sliceRecordsForInfiniteScroll();
    }
    this.popover
      .create({ component: MultiSelectPopover, componentProps: { root: this.sortPopoverData }, event: event })
      .then((data) => data.present())
  }

  sortResourcesBySortSelection(selectedSortOption) {
    if (!this.searching) {
      if (selectedSortOption.value.includes('$')) {
        return;
      }
      if (selectedSortOption.asc) {
        this.resources.sort((a, b) => {
          if (a[selectedSortOption.value] > b[selectedSortOption.value]) return 1;
          else return -1;
        })
      }
      else {
        this.resources.sort((a, b) => {
          if (a[selectedSortOption.value] > b[selectedSortOption.value]) return -1;
          else return 1;
        })
      }
      // this.resources = this.contentMatchedRes;
      this.sliceRecordsForInfiniteScroll();
    }
  }

  // handleSearch(){
  //   if(this.resourceSearchText.length>0){
  //     this.filterPopoverData.map(o=>{
  //       o.value = '';
  //     })
  //     this.resourceFilter = Object.assign({}, this.resourceFilter, { title:this.resourceSearchText, brands: '', assetType: ''  });
  //   }
  //   else{
  //     this.onSearchCancel();
  //   }
  // }

  // onSearchCancel(){
  //   this.resourceFilter = Object.assign({},this.resourceFilter, {title:''});
  // }

  highlightResource(resource) {
    this.trackingService.tracking('ResourcePreview', TrackingEventNames.RESOURCES)
    this.resourceService.highlightResource(resource);
  }
  pushResource(event: Event, resource: Resource) {
    this.resourceService.highlightResource(resource);
    this.events.publish("share-resource");
  }

  async cancel() {
    return this.goBack();
  }
  async goBack() {
    if (this.isAddToEmail) {
      this.trackingService.tracking('EmailAttachmentsCanceled', TrackingEventNames.EMAIL);
    }
    else if (!this.isAddToEmail && this.resourceService.viewMode !== ResourceView.PUSH) {
      this.deltaService.deltaRecordsDTO.resources.length = 0;
      this.trackingService.tracking('ResourcesBack', TrackingEventNames.RESOURCES);
    }
    else {
      this.trackingService.tracking('ResourcesBack', TrackingEventNames.RESOURCES);
    }
    if (this.emailService.viewType == EmailViewType.CREATE_FROM_MEETING ||
      this.emailService.viewType == EmailViewType.CREATE_FROM_PHONE_CALL) {
      if (this.emailService.selectedActivity && (this.emailService.viewType == EmailViewType.CREATE_FROM_MEETING ||
        this.emailService.viewType == EmailViewType.CREATE_FROM_PHONE_CALL)) {
        if (document.getElementsByClassName('modal-wrapper')[0].classList.contains('fullStretchView')) {
          document.getElementsByClassName('modal-wrapper')[0].classList.remove('fullStretchView');
        }
        this.navCtrl.pop({ progressAnimation: false }).then(() => {
          this.emailService.setCurrentEmail(this.emailService.selectedActivity);
        });
        return;
      }
    }
    this.navService.popWithPageTracking().then(() => {
      // if(!this.isAddToEmail)
      if (this.navService.getCurrentMasterPageName() == PageName.PresentationMeetingComponent) return;
      if (this.navService.getCurrentMasterPageName() == PageName.ActivitiesPageComponent && this.uiService.activitiesPageTab === 'plans') {
        if(this.uiService.activitiesPagePlanTabSelectedPlan === 'accountPlansTab') {
          this.footerService.initButtons(FooterViews.AccountPlanObjectives);
        }
        if(this.uiService.activitiesPagePlanTabSelectedPlan === 'callPlans') {
          this.footerService.initButtons(FooterViews.EmailDetails);
        }
        this.uiService.activeView = 'ActivitiesPageRightPaneNav';
        this.uiService.showNewActivity = false;
        if(this.isAddToEmail){
          this.uiService.showRightPane = true;
        }
      } else {
        this.uiService.activeView = this.uiService.prevView;
        if (this.navService.getActiveChildNavViewPageName() === PageName.EmailDetailsPageComponent) {
          this.footerService.initButtons(FooterViews.EmailDetails);
        } else if (this.uiService.activeView === 'customer_inquiry' || this.navService.getActiveChildNavViewPageName() === PageName.CaseManagementDetailComponent) {
          this.footerService.initButtons(FooterViews.CUSTOMER_INQUIRY);
        } else {
          // Short call feature requires to bring the tab back to short call home
          if (this.faService.isShortCallLauncherEnabled) {
            this.agendaFooterService.initButtons(AgendaFooterView.ShortCallLauncher);
            this.uiService.setAgendaTab('shortCallHome');
          }
        }
      }
    });
  }
  public addToEmail() {
    this.trackingService.tracking('Done from Resource', TrackingEventNames.PRESENTATIONS)
    if (!this.isModified) {
      this.navService.popWithPageTracking().then(() => {
      });
      return;
    }
  }
  public addResourceToArray(resource: Resource) {
    this.isModified = true;
    let element = this.emailResource.find(p => p.assetID === resource.assetID);
    if (!element) {
      // pres.isSelected = true;
      this.emailResource.push(resource);
    } else {
      // pres.isSelected = false;
      let index = this.emailResource.indexOf(resource);
      this.emailResource.splice(index, 1);
    }
    if (this.indHeaderLeftModel.controls.length > 1) {
      this.indHeaderLeftModel.controls[1].isDisabled = this.disableDoneBtn;
    }
  }
  // Select Mode
  public updateSelResourceList(resource: Resource) {
    this.resourceService.updateSelResourceList(resource);
  }
  public addEmailResource() {
    _.uniqBy(this.emailResource, r => r.ioResourceId != null ? r.ioResourceId : r.ioDocumentId);
    this.trackingService.tracking('EmailAttachmentsAdded', TrackingEventNames.EMAIL);
    if (this.emailService.selectedActivity && (this.emailService.viewType == EmailViewType.CREATE_FROM_MEETING ||
      this.emailService.viewType == EmailViewType.CREATE_FROM_PHONE_CALL)) {
      // //@ts-ignore
      // if (this.navCtrl._elementRef.nativeElement.classList.contains('fullStretchView')) {
      //   //@ts-ignore
      //   this.navCtrl._elementRef.nativeElement.classList.remove('fullStretchView')
      // }
      if (document.getElementsByClassName('modal-wrapper')[0].classList.contains('fullStretchView')) {
        document.getElementsByClassName('modal-wrapper')[0].classList.remove('fullStretchView');
      }
      this.navCtrl.pop({ progressAnimation: false }).then(async () => {
        this.emailService.setCurrentEmail(this.emailService.selectedActivity);
        await this.uiService.displayLoader();
        this.emailService.detectResourceAddedOrRemoved(this.emailResource).then(async () => {
          await this.uiService.dismissLoader();
        }).catch(async () => {
          await this.uiService.dismissLoader();
        });
      });
      return;
      
    }
    this.navService.popWithPageTracking().then(async() => {
      // if (!this.isAddToEmail)
      if(this.uiService.activitiesPagePlanTabSelectedPlan == 'callPlans') {
        this.uiService.activeView = 'ActivitiesPageRightPaneNav';
        this.uiService.showRightPane = true;
      } else {
        this.uiService.activeView = this.uiService.prevView;
      }
      this.emailService.setCurrentEmail(this.emailService.selectedActivity);
      await this.uiService.displayLoader();
      this.emailService.detectResourceAddedOrRemoved(this.emailResource).then(async () => {
        await this.uiService.dismissLoader();
      }).catch(async() => {
        await this.uiService.dismissLoader();
      });
    });
  }

  public addSelectedEmailResource() {
    if (this.emailService.selectedActivity) {
      this.emailResource = [];
      this.emailService.selectedActivity.emailAttachments.map(el => {
        let i = this.resourceService.allResources.find(v => {
          return (v.ioResourceId === el.indskr_resourceid) || (v.ioDocumentId === el.indskr_resourceid)
        });
        if (i && (this.emailResource.findIndex(c => {
          return (c.ioResourceId === el.indskr_resourceid) || (c.ioDocumentId === el.indskr_resourceid)
        }) === -1)) {
          this.emailResource.push(i);
        }
      });
      this.emailResourceCopy = _.cloneDeep(this.emailResource);
    }
    else
      return;
  }

  get disableDoneBtn() {
    return _.isEmpty(this.emailResource) || _.isEqual(this.emailResourceCopy, this.emailResource);
  }

  checkResourceSelected(resource: Resource): boolean {
    if (this.emailResource && this.emailResource.length) {
      let x = this.emailResource.find(e => { return e.assetID === resource.assetID });
      if (x) {
        return true;
      }
      else
        return false;
    }
    else
      return false;
  }
  getSectionHeadingText() {
    let str = ''
    if (this.resourceFilter.brands) {
      str = this.resourceFilter.brands;
      if (this.resourceFilter.assetType) str += ', ' + this.resourceFilter.assetType;
    }
    else if (this.resourceFilter.assetType) str = this.resourceFilter.assetType;
    else if (this.resourceFilter.title) str = this.translate.instant('RES_RESULTS')
    else str = this.translate.instant('ALL_RESOURCES');
    return str.toUpperCase();
  }

  // start advance search functions
  @HostListener('window:click', ['$event.target'])
  onClick(targetElement: string) {
    if (this.suggestionsActive) {
      this.suggestionsActive = false
    }
    this._cd.detectChanges();
  }

  searchResourcesList() {
    //second level search for resources
    this.searching = true;
    let filteredResources: any = this.resources;
    let selectedSuggestionsDataCopy = this.selectedSuggestionsData.slice();
    let entityLevelCharSearch = selectedSuggestionsDataCopy.find(o => o.type == SuggestionPillType.ENTITY_LEVEL_CHARACTERSEARCH);
    const formattedSearchText = entityLevelCharSearch && entityLevelCharSearch.charSearchText ? this.searchConfigService.convertFormattedString(entityLevelCharSearch.charSearchText).toUpperCase() : '';
    const formattedSearchTextSplit = formattedSearchText ? formattedSearchText.split(" ") : '';
    if (entityLevelCharSearch && formattedSearchTextSplit) {
      formattedSearchTextSplit.forEach(searchText => {
        filteredResources = filteredResources.filter(resource => {
          let resourceEntityLevel = this.getOjectValues(resource);
           return resourceEntityLevel.includes(searchText);
         });
      });
    }
    filteredResources = this.searchConfigService.fetchFilteredListBasedOnSuggestions(selectedSuggestionsDataCopy, filteredResources);
    this.filteredList = filteredResources;
    this.filterMetadata.count = this.filteredList.length;
  }

  searchResourceSuggestions(resource: Resource[], searchText: string, event?): Resource[] {

    if (searchText && resource) {
      searchText = searchText.toUpperCase();
      //first search categories and category values
      let searchIndexConfig = this.searchConfigService.resourcesSearchIndexesConfig;

      if (searchIndexConfig.find(config => config.categoryName == 'Types').values.length > 0) {
        let typesSearched = searchIndexConfig.find(config => config.categoryName == 'Types');
        typesSearched.values.forEach((value, index) => {
          if (value == 'document') {
            typesSearched.values[index] = this.translate.instant('R_DOCUMENT');
          } else if (value == 'image') {
            typesSearched.values[index] = this.translate.instant('R_IMAGE');
          } else if (value == 'video') {
            typesSearched.values[index] = this.translate.instant('VIDEO');
          } else if (value == 'web') {
            typesSearched.values[index] = this.translate.instant('R_WEB');
          } else if (value == 'pdf' || value == 'Pdf') {
            typesSearched.values[index] = this.translate.instant(value.toUpperCase());
          } else if (value == 'other') {
            typesSearched.values[index] = this.translate.instant('OTHER');
          }
        })
      }
      // if(this.uiService.isConsentFromToolDrawer) searchIndexConfig = searchIndexConfig.filter(config=>!config.customizedBypassGateEnabled);
      this.suggestionsData = this.searchConfigService.fetchSuggestions(searchIndexConfig, this.selectedSuggestionsData, this.translate.instant("RESOURCES"), this.resourceSearchText);
      if (this.suggestionsData.length) this.suggestionsActive = true;
      if (!this.selectedSuggestionsData || !this.selectedSuggestionsData.length) {
        this.filteredList = resource;
      }
      const formattedSearchText = this.searchConfigService.convertFormattedString(searchText).trim();
      this.filteredList = this.filteredList.filter(resource => {
        let resourceString = this.getOjectValues(resource);
        return resourceString.includes(formattedSearchText);
      });
    } else {
      return [];
    }
    this.filterMetadata.count = this.filteredList.length;
  }

  getOjectValues(resObject): string {
    let objString: string[] = [];
    let rscInclude = ['name', 'raw'];
    for (let resource in resObject) {
      if (rscInclude.indexOf(resource) > -1 && resObject[resource]) {
        if (Array.isArray(resObject[resource])) {
          for (let i = 0; i < resObject[resource].length; i++) {
            objString.push(this.getOjectValues(resObject[resource][i]));
          }
        } else {
          if (resource == "raw") {
            for (let subKey in resObject[resource]) {
              if (subKey == "indskr_ckmtitle") {
                objString.push(resObject[resource]["indskr_ckmtitle"])
              }
            }
          } else {
            objString.push(resObject[resource]);
          }
        }
      }
    }
    return this.searchConfigService.convertFormattedString(objString.toString().toUpperCase());
  }

  ionSearchFocus(ev) {
    try {
      let el = this.resourceListElement.nativeElement.ownerDocument.getElementById('allResources');
      if (el) {
        el.scrollIntoView({ behavior: 'smooth' });
      }
    } catch (error) {
      console.log("scroll error");
    }
  }

  clickSearchArea(ev) {
    ev.stopPropagation();
    if (!this.suggestionsActive) {
      this.suggestionsActive = true;
      if (!this.resourceService.recentSearches || !this.resourceService.recentSearches.length) {
        let searchToolName = this.searchConfigService.toolNames.find(tool => tool.toolName == 'Resources')
        if (searchToolName && (this.searchConfigService.savedSearches.filter(search => search.searchToolName == searchToolName.searchToolNameID)).length == 0) {
          this.suggestionsActive = false;
        }
      }
    }
  }

  onInput(event) {
    this.searchKey = '';
    if (!this.shouldFireSearchChangeEvent) {
      this.shouldFireSearchChangeEvent = true;
      return;
    }
    let params = (event.detail && event.detail.value) ? event.detail.value : '';

    //for length > 2
    if (params.length > 2) {
      if (params.trim().length == 0) return;
      this.searching = true;
      this.searchKey = params;
      this.searchResourceSuggestions(this.resources, params, event);
    }
    // for length 0 to 2
    else {
      this.suggestionsData = [];
      if (!this.selectedSuggestionsData || !this.selectedSuggestionsData.length) {
        this.filteredList = []
        this.searching = false;
        // this.handleSearch();
        this.sortResourcesBySortSelection(this.sortBy);
      }
      else {
        this.searchResourcesList();
      }
      if (!this.resourceService.recentSearches || !this.resourceService.recentSearches.length) {
        let searchToolName = this.searchConfigService.toolNames.find(tool => tool.toolName == 'Resources')
        if (searchToolName && (this.searchConfigService.savedSearches.filter(search => search.searchToolName == searchToolName.searchToolNameID)).length == 0) {
          this.suggestionsActive = false;
        }
      }
    }
    this._cd.detectChanges();
  }

  removeAllSelectedSuggestions() {
    this.disableSaveSearch = true;
    this.selectedSuggestionsData = [];
    //this.content.resize();
    this.filteredList = [];
    this.resourceSearchText = '';
    this.shouldFireSearchChangeEvent = false;
    this.searching = false;
  }

  removeSelectedSuggestion(suggestion) {
    this.disableSaveSearch = false;
    _.remove(this.selectedSuggestionsData, (o) => _.isEqual(o, suggestion));
    if (this.selectedSuggestionsData.length == 0) {
      //this.content.resize();
      this.filteredList = [];
      this.resourceSearchText = '';
      this.shouldFireSearchChangeEvent = false;
      this.searching = false;
    }
    else {
      this.searchResourcesList();
    }
  }

  async saveAdvancedSearch(failedText?: string) {
    let saveSearchName: string
    let toolName;
    toolName = this.searchConfigService.toolNames.find(o => o.toolName == 'Resources')
    let currentSavedSearches = this.searchConfigService.savedSearches.filter(o => o.searchToolName == toolName.searchToolNameID);
    this.alertService.showAlert({
      title: this.translate.instant('SAVE_SEARCH'),
      subTitle: failedText ? failedText : '',
      cssClass: 'saveSearchAlert',
      message: this.translate.instant('SAVE_SEARCH_POPOVER_MESSAGE'),
      inputs: [{ type: 'text', name: "name", placeholder: this.translate.instant('ENTER_NAME') }]
    }, this.translate.instant('SAVE'), this.translate.instant('CANCEL'))
      .then(async (res) => {
        if (res.role == 'ok') {
          if (res.data && res.data.values.name) {
            saveSearchName = res.data.values.name.trim();
            let sameNameSearches = currentSavedSearches.filter(o => o.searchName.toLowerCase().indexOf(saveSearchName.toLowerCase()) == 0);
            let incrementNumber: number = 1;
            if (sameNameSearches.length) {
              saveSearchName += ' (';
              _.each(sameNameSearches, (searchData => {
                if (searchData.searchName.toLowerCase().indexOf(saveSearchName.toLowerCase()) == 0) {
                  let currentIncrement = parseInt(searchData.searchName.toLowerCase().charAt(saveSearchName.length));
                  if (!isNaN(currentIncrement) && currentIncrement >= incrementNumber) incrementNumber = currentIncrement + 1;
                }
              }))
              saveSearchName += incrementNumber + ')';
            }
            this.disableSaveSearch = true;
            await this.searchConfigDataService.saveAdvancedSearch(saveSearchName,
              toolName ? toolName.searchToolNameID : '',
              UserSavedSearchTypes.OWNED,
              this.selectedSuggestionsData.slice())
          }
          else if (res.data && res.data.values.name == "") {
            this.saveAdvancedSearch(this.translate.instant('NAME_IS_REQUIRED'));
          }
        }
      })
  }

  clickedInSuggestionsArea(ev) {
    ev.stopPropagation();
  }

  handleFacetSelection(data: SelectedSuggestionPillDataModel) {
    this.disableSaveSearch = false;
    console.log(data);
    this.selectedSuggestionsData = this.searchConfigService.manageSelectedSuggestions(data, this.selectedSuggestionsData);
    this.selectedSuggestionsData.sort((a, b) => {
      if (a.createdOn < b.createdOn) return 1
      else return -1
    })
    ///if(this.selectedSuggestionsData.length == 1) this.content.resize();
    this.suggestionsActive = false;
    this.resourceSearchText = '';
    this.suggestionsData = [];
    this.shouldFireSearchChangeEvent = false;
    this.searchResourcesList();
    if (!data.isComingFromRecentSearch) {
      let recentSearches;
      recentSearches = this.resourceService.recentSearches
      if (!recentSearches.some(recSearch => recSearch.categoryName == data.categoryName && recSearch.selectedFacet == data.selectedFacet)) {
        recentSearches.unshift(data);
        _.remove(recentSearches, (o, index) => index > 2);
        this.disk.updateOrInsert(DB_KEY_PREFIXES.RESOURCE_RECENT_SEARCHES, (doc) => {
          if (!doc || !doc.raw) {
            doc = {
              raw: []
            }
          }
          doc.raw = recentSearches;
          return doc;
        })
      }
    }
  }

  handleSavedSearchSelection(data) {
    this.selectedSuggestionsData = [];
    if (data && data.categoryValuePairs) {
      data.categoryValuePairs.forEach(catValPair => {
        this.selectedSuggestionsData = this.searchConfigService.manageSelectedSuggestions(catValPair, this.selectedSuggestionsData);
      })
      this.selectedSuggestionsData.sort((a, b) => {
        if (a.createdOn < b.createdOn) return 1
        else return -1
      })
      //if(this.selectedSuggestionsData.length > 0) this.content.resize();
      this.suggestionsActive = false;
      this.resourceSearchText = '';
      this.shouldFireSearchChangeEvent = false;
      this.searchResourcesList();
    }
  }

  public selectMode() {
    this.resourceService.isSelectMode = !this.resourceService.isSelectMode;
    this.initAllResourcesSectionHeader();
    this.resources.map(res => res.isSelected = false);
    this.isSelectAll = false;
    this.selectedResourceList = [];
    this.sliceRecordsForInfiniteScroll();
  }

  public selectAll() {
    if (this.isSelectAll == true) {
      this.selectedResourceList = [];
      this.resources.map(res => {
        res.isSelected = true
        this.selectedResourceList.push(res);
      });

    } else {
      this.resources.map(res => {
        res.isSelected = false;
      });
      this.selectedResourceList = [];
    }
    this.sliceRecordsForInfiniteScroll();
    this.cdRef.detectChanges();
    this.cdRef.markForCheck();
  }

  public get hasSelectedResources(): boolean {
    return this.selectedResourceList.length > 0;
  }

  public get downloadButtonState() {
    return this.selectedResourceList.some(resource => resource.downloaded != true);
  }

  public updateSelectedResource(res: Resource) {

    const element = this.selectedResourceList.find(r => r == res);
    if (!element) {
      this.selectedResourceList.push(res);
    } else {
      const index = this.selectedResourceList.indexOf(res);
      this.selectedResourceList.splice(index, 1);
    }
    this.cdRef.detectChanges();
    this.cdRef.markForCheck();
  }

  public bulkDownloadResources() {
    if (this.selectedResourceList.length < 1 || this.deviceService.isOffline || !this.deviceService.isNativeApp) {
      return;
    }
    const orderBy = new OrderByPipe();
    const seletedResources = orderBy.transform(this.selectedResourceList, this.sortBy['value'], true);
    seletedResources.forEach(res => {
      const id = res.ioResourceId ? res.ioResourceId : res.ioDocumentId;
      if (!res.downloaded) {
        if (res.assetURL) {
          this.store.dispatch(new ResourceAction.downloadResourceEnqueue({ resourceId: id }));
        } else {
          this.notificationService.notify(this.translate.instant('FAILED_TO_DOWNLOAD_RESOURCE') + res.title, "io-file-service", "top", ToastStyle.DANGER, 3000, true);
        }
      } else {
        this.resourceDataService.deleteDownloadedResource(id);
      }
    });
    this.cdRef.detectChanges();
    this.cdRef.markForCheck();
  }

  /**
     * @param {Presentation} pres
     * @memberof PresentationListComponent
     */
  public downloadResource(resource: Resource) {
    this.trackingService.tracking('ResourceDownlaod', TrackingEventNames.RESOURCES);
    const resourceId: string = resource.ioResourceId ? resource.ioResourceId : resource.ioDocumentId;
    if ((this.deviceService.isOffline || this.deviceService.isDeviceRealOffline) && !resource.downloaded) {
      this.notificationService.notify(this.translate.instant('CANNOT_DOWNLOAD_RESOURCE_WHILE_OFFLINE'), "Resource List", "top", ToastStyle.DANGER, 2000, true);
      return;
    }

    if (!resource.downloaded) {
      this.notificationService.notify((this.translate.instant('DOWNLOADING') + ' ' + resource.title), 'Resource Download');

      if (resource.assetURL) {
        this.store.dispatch(new ResourceAction.downloadResourceEnqueue({ resourceId: resourceId }));
      } else {
        this.notificationService.notify(this.translate.instant('FAILED_TO_DOWNLOAD_RESOURCE') + resource.title, "io-file-service", "top", ToastStyle.DANGER, 3000, true);
      }
    } else {
      this.notificationService.notify(this.translate.instant('SUCCESSFULLY_REMOVED') + ' ' + resource.title, "Resource List");
      this.resourceDataService.deleteDownloadedResource(resourceId);
    }
    this.cdRef.detectChanges();
    this.cdRef.markForCheck();
  }

  ngOnDestroy() {
    this.resourceService.isSelectMode = false;
  }

  private initResourceHeaderLeft(): void {
    let buttons;
    if(this.resourceService.viewMode=== this.resourceMode.DOCUMENTS
        || this.resourceService.viewMode=== this.resourceMode.ADDTOEMAIL
        || this.resourceService.viewMode=== this.resourceMode.PUSH) {
      buttons = [{
        id: "cancel",
        cssClass: 'seventyPercentWidth',
        imgSrc: 'assets/imgs/header_cancel.svg',
        isDisabled: false,
        align: "left",
      }];
    } else {
      buttons = [{
        id: "cancel",
        // cssClass: 'seventyPercentWidth',
        imgSrc: 'assets/imgs/back_to_home_btn.svg',
        isDisabled: false,
        align: "left",
      }];
    }
    if (this.isAddToEmail) {
      buttons.push({
        id: "done",
        cssClass: 'seventyPercentWidth',
        imgSrc: 'assets/imgs/header_complete.svg',
        isDisabled: this.disableDoneBtn,
        align: "right",
      });
    }
    this.indHeaderLeftModel = {
      id: 'resource-list-header-left',
      cssClass: (this.resourceService.viewMode == this.resourceMode.MENU)?'main-tool-header-title':'leftScreenHeaderTitle',
      title: this.listTitle,
      mode: false,
      customHeaderProps: {
        hasCancel: (this.allowPushResource || this.isAddToEmail || this.resourceService.viewMode != this.resourceMode.MENU)
      },
      controls: buttons,
    };
  }

  public onPageTitleControlClick(id: string) {
    switch (id) {
      case 'cancel':
        this.goBack();
        break;
      case 'done':
        if (!this.isAddToEmail) return;
        this.addEmailResource();
        break;
      default:
        console.log("Unhandled switch case statement");
        break;
    }
  }

  initAllResourcesSectionHeader() {
    this.allResourcesHeaderModel = {
      id: 'all-resources-header',
      title: '',
      isMultiFilter: true,
      controls: [],
    };
    if (!this.resourceService.isSelectMode) {
      this.allResourcesHeaderModel.controls.push({
        id: 'resource-sort',
        text: this.sortBy?.text,
        isDisabled: false,
        icon:'assets/imgs/sort_with_double_arrows.svg'
      });
    }
  }

  onSectionHeaderControlClick(event) {
    if (event == 'resource-sort') {
      this.sort();
    }
  }

  doInfinite(eventDetail, event) {
    let count = this.slicedResources.length;
    this.slicedResources.push(...this.resources.slice(count,  count + 15));
    event.target.complete();
  }

  sliceRecordsForInfiniteScroll() {
    this.resources = this.callbackPipe.transform(this.resources, this.filterByDate);
    if (this.sortBy && this.sortBy.value !== "$downloaded") {
      this.resources = this.orderBy.transform(this.resources, this.sortBy.value, this.sortBy.asc)
    }
    this.slicedResources = this.resources.slice(0, this.sliceCount);
  }

}
//f75445e
