
import {tap, catchError, exhaustMap, filter, withLatestFrom, map} from 'rxjs/operators';

import { Observable ,  Subject ,  of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';

import * as File from '../actions/file.actions';
import { fileManagerState, INITIAL_FILE_MANAGER_STATE } from '../states/file.state';
import { downloadFileEnqueue, FILE_DOWNLOAD_ENQUEUE, downloadFileDequeue } from './../actions/file.actions';
import { IoFileService } from '../../../services/io-file-service/io-file-service';


import { fileState } from '../../application.state';
import { PresentationDataService } from '../../../data-services/presentation/presentation.data.service';

/**
 * Action effect for file download and unzipping.
 */

@Injectable({
  providedIn: 'root'
})
export class FileManagerEffects {
    @Effect()
    downloadFileEnqueue$ = this.actions$
        .pipe(
        ofType(File.FILE_DOWNLOAD_ENQUEUE),
        map((action: File.downloadFileEnqueue) => action.payload),
        withLatestFrom(this.store$),
        filter(([action, storeState]) => {
            return !storeState.fileManager.isDownloading && !storeState.fileManager.isUnZipping;
        }),
        map(([action, storeState]) => {
            console.log("ENQUEUE")
            return new File.downloadFile({ presentationId: storeState.fileManager.downloadQueue[0] });
        }),);

    @Effect()
    downloadFileDequeue$ = this.actions$
        .pipe(ofType(File.FILE_DOWNLOAD_DEQUEUE),
        map((action: File.downloadFileDequeue) => action),
        withLatestFrom(this.store$),
        filter(([action, storeState]) => {
            return storeState.fileManager.downloadQueue.length > 0;
        }),
        map(([action, storeState]) => {
            console.log("DEQUEUE");
            return new File.downloadFile({ presentationId: storeState.fileManager.downloadQueue[0] });
        }),);


    @Effect()
    downloadFile$ = this.actions$
        .pipe(ofType(File.DOWNLOAD_FILE),
        map((action: File.downloadFile) => action.payload),
        exhaustMap(file =>
            this.ioFileDownload.downloadPresentation(file.presentationId).pipe(
                map(flag => {
                    return new File.unZipFileSuccess({ presentationId: file.presentationId });
                }),
                   
                catchError(error => of(new File.downloadFileFailed({presentationId: file.presentationId}))),)
        ),);

    @Effect()
    unZipFileSuccess$ = this.actions$
        .pipe(ofType(File.UNZIP_FILE_SUCCESS),
        map((action: File.unZipFileSuccess) => {
            return new File.updateFileRecord({ presentationId: action.payload.presentationId });
        }));

    @Effect({ dispatch: false })
    updateFileRecord$ = this.actions$
        .pipe(ofType(File.UPDATE_FILE_RECORD),
        tap((action: File.updateFileRecord) => {
            this.presDataService.savePresForOffline(action.payload.presentationId);
        }))

    @Effect({dispatch: false})
    downloadFailed$ = this.actions$
        .pipe(ofType(File.DOWNLOAD_FILE_FAILURE),
        map((action: File.downloadFileFailed) => {
            console.log("DOWNLOADE FAILLED EFFFECTS: ", action.payload);
            return this.ioFileDownload.downloadFailedDelete(action.payload.presentationId);
        }))

    constructor(
        private actions$: Actions,
        private store$: Store<fileState>,
        private ioFileDownload: IoFileService,
        private presDataService: PresentationDataService,
    ) {

    }
}
