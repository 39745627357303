
export class Inventory {
  userAllocationQtyId: string;
  userId: string;
  skuName: string;
  skuId: string;
  lot: InventoryLot;


  constructor(raw) {
    this.userAllocationQtyId = raw.indskr_userallocationquantityid;
    this.userId = raw['_indskr_user_value'];
    this.skuName = raw['aa.indskr_parentproductid_Formatted'];
    this.skuId = raw['aa.indskr_parentproductid'];
    this.lot = new InventoryLot(raw);
  }

  
}

export class InventoryLot {
  lotName: string;
  lotId: string;
  validFrom: string;
  validTo: string;
  totalQtyRemaining: number;
  totalQtyRecieved: number;
  totalQtyDropped: number;
  totalQtyTransferred: number;
  qtyAdjusted: number;

  constructor(raw) {
    this.lotName = raw['aa.name'];
    this.lotId = raw['_indskr_lot_value'];
    this.validFrom = raw['aa.validfromdate'];
    this.validTo = raw['aa.validtodate'];
    this.totalQtyRemaining = (raw.indskr_totalquantityremaining) ? raw.indskr_totalquantityremaining : 0;
    this.totalQtyRecieved = (raw.indskr_totalquantityrecieved) ? raw.indskr_totalquantityrecieved : 0;
    this.totalQtyDropped = (raw.indskr_totalquantitydropped) ? raw.indskr_totalquantitydropped : 0;
    this.totalQtyTransferred = (raw.indskr_totalquantitytransferred) ? raw.indskr_totalquantitytransferred : 0;
    this.qtyAdjusted = (raw.indskr_quantityadjusted) ? raw.indskr_quantityadjusted : 0;
  }
}

export interface UserInventory {
  userId: string;
  skuName: string;
  skuId: string;
  expanded: boolean;
  lot: InventoryLot[];
}