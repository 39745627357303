import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, ElementRef, HostBinding, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { AppointmentActivity } from '@omni/classes/activity/appointment.activity.class';
import { Brand } from '@omni/classes/brand/brand.class';
import { ShortCallField } from '@omni/classes/short-call/short-call.class';
import { ActivityService } from '@omni/services/activity/activity.service';
import { DateTimeFormatsService } from '@omni/services/date-time-formats/date-time-formats.service';

const CARD_FULL_HEIGHT_PADDING_AT_TOP = 24;
const CARD_HEADER_FOOTER_HEIGHT_COMBINED = 44 + 64;
@Component({
  selector: 'io-short-call-details',
  templateUrl: './io-short-call-details.component.html',
  styleUrls: ['./io-short-call-details.component.scss'],
})
export class IoShortCallDetailsComponent implements OnInit, AfterViewInit {
  @HostBinding('class.ion-page') pageClass = false;
  @ViewChild('content', { read: ElementRef }) content;
  @ViewChild('contentWrapper', { read: ElementRef }) contentWrapper;
  @Input() private view: string;
  public fields: ShortCallField[] = [];

  constructor(
    private navParams: NavParams,
    private activityService: ActivityService,
    private dateFormat: DateTimeFormatsService,
    private translate: TranslateService,
    private datePipe: DatePipe,
    private readonly modalCtrl: ModalController,
    private renderer: Renderer2,
  ) {

   }

  ngOnInit() {
    console.log(this.view);
    console.log(this.navParams.data);
    this.initShortCallFields();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      const maxContentHeight = window.innerHeight - CARD_FULL_HEIGHT_PADDING_AT_TOP - CARD_HEADER_FOOTER_HEIGHT_COMBINED;
      const contentHeight = Math.min(maxContentHeight, this.contentWrapper.nativeElement.offsetHeight);
      this.setContentHeight(contentHeight);
    }, 50);
  }

  public initShortCallFields() {
    const meeting = this.activityService.selectedActivity;
    if (meeting && meeting instanceof AppointmentActivity) {
      let dateTimeValue = this.activityService.getFormattedDateTimeText();
      let dateField: ShortCallField = null;
      if (dateTimeValue) {
        const visitDate = this.datePipe.transform(dateTimeValue.startDateTime, this.dateFormat.date, undefined, this.translate.currentLang);
        dateField = { label: 'VISIT_DATE', value: visitDate };
      }
      let conatctStr: string = this.translate.instant('NO_CONTACT');
      if (meeting.contacts.length > 0) {
        conatctStr = `${meeting.contacts[0].firstName} ${meeting.contacts[0].lastName}`;
        if (meeting.contacts.length > 1) {
          conatctStr += `(+${meeting.contacts.length})`;
        }
      }
      let productStr: string = this.translate.instant('NO_PRODUCTS');
      let keyMsgFields: ShortCallField[] = [
        {
          label: 'SCH_KEYMESSAGE',
          value: this.translate.instant('NO_KEY_MSGS'),
        }
      ];
      if (meeting.products?.length > 0) {
        const selectedProducts: Brand[] = meeting.products.filter(p => p.isSelected || p.isAutoSelected);
        if (selectedProducts.length > 0) {
          productStr = `${selectedProducts[0].name}`;
          if (selectedProducts.length > 1) {
            productStr += `(+${selectedProducts.length})`;
          }
        }

        const allKeyMessages = selectedProducts.reduce((result, product) => {
          if (product.keyMessages && Array.isArray(product.keyMessages)) {
            result.push(...product.keyMessages);
          }
          return result;
        }, []).filter(p => p.isSelected || p.isAutoSelected);

        if (allKeyMessages?.length > 0) {
          keyMsgFields.pop();
          for (let i = 0; i < allKeyMessages.length; i++) {
            const keyMessage = allKeyMessages[i];
            keyMsgFields.push(
              {
                label: i === 0 ? 'SCH_KEYMESSAGE' : '',
                value: keyMessage.name,
              }
            );
          }
        }
      }
      const contactField: ShortCallField = { label: "CONTACT", value: conatctStr };
      const productField: ShortCallField = { label: "PRODUCTS", value: productStr };
      this.fields = [dateField, contactField, productField, ...keyMsgFields];
    }
  }

  public closeModal(type: string) {
    this.modalCtrl.dismiss({ type: type });
  }

  private setContentHeight(height: number) {
    if (height && this.content?.nativeElement) {
      this.renderer.setStyle(
        this.content.nativeElement,
        'height',
        `${height}px`,
      );
    }
  }
}
