import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { DeltaService } from "@omni/data-services/delta/delta.service";
import { BrandOfflineService } from "@omni/services/brand/brand.service";
import { DeviceService } from "@omni/services/device/device.service";
import { AgendaFooterService, AgendaFooterView } from "@omni/services/footer/agenda-footer.service";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import _ from 'lodash';
import { PresentationService } from "@omni/services/presentation/presentation.service";
import { Page, Presentation } from "@omni/classes/presentation/presentation.class";
import { Account } from "@omni/classes/account/account.class";
import { AccountOfflineService } from "@omni/services/account/account.offline.service";
import { TerritoryManagementService } from "@omni/services/territory-management/territory-management.service";
import { AuthenticationService } from "@omni/services/authentication.service";
import { AccountPageComponent } from "@omni/components/account/account-page/account-page";
import { ContactOfflineService } from "@omni/services/contact/contact.service";
import { NavigationService, PageName } from "@omni/services/navigation/navigation.service";
import { ComponentViewMode, PresentationView, UIService } from "@omni/services/ui/ui.service";
import { Contact } from "@omni/classes/contact/contact.class";
import { MeetingDataService } from "@omni/data-services/meeting/meeting.data.service";
import { NotificationService, ToastStyle } from "@omni/services/notification/notification.service";
import { TranslateService } from "@ngx-translate/core";
import { AppointmentActivity } from "@omni/classes/activity/appointment.activity.class";
import { ActivityService } from "@omni/services/activity/activity.service";
import { MeetingStructureService } from "@omni/services/meeting-structure/meeting-structure.service";
import { EmbeddedInteraction } from "@omni/classes/activity/activity.class";
import { PresentationMeetingComponent } from "@omni/pages/presentation/presentation-meeting";
import { FooterService, FooterViews } from "@omni/services/footer/footer.service";
import { ContactDetailsComponent } from "@omni/components/contact/contact-details/contact-details";
import { ContentCard, ParentTag, ShortCallContent, ShortCallField, ShortCallFilterField } from "@omni/classes/short-call/short-call.class";
import { FeatureActionsMap } from "@omni/classes/authentication/user.class";
import { ContactTimelineAggregatorService } from "@omni/services/contact/contact-timeline.service";
import { sortObjArrayByChineseStringField, sortObjArrayByStringField } from "@omni/utility/common.utility";
import { Counter } from "@omni/classes/shared/counter.class";
import { IoContentFilter } from "@omni/components/io-component/io-content-filter/io-content-filter";
import { ModalController } from "@ionic/angular";

@Component({
  selector: 'short-call-home',
  templateUrl: 'short-call-home.html',
  styleUrls: ['short-call-home.scss'],
})
export class ShortCallHomeComponent implements OnInit, OnDestroy {

  private ngDestroy$ = new Subject<boolean>();

  isAndroid: boolean = false;
  searchText: string = '';
  backgroundUploadInProgress: boolean = false;
  syncInProgress: boolean = false;
  public eCardData: ShortCallContent = {
    title: "ECARD",
    showSkeleton: false,
    cards: []
  };
  public edaData: ShortCallContent = {
    title: "EDA",
    showSkeleton: false,
    cards: []
  };

  public productTabData = [];
  public selectedProductTab: string = null;
  private nonExpiredContents: Presentation[] = [];
  public selectedAccount: Account;
  public targetContactsCount: number = 0;
  public selectedActivityOption: string = null;
  public shortCallActivityOptions: string[] = ["MEETING"];
  public selectedContact: Contact;
  public affiliatedContacts: Contact[];
  private hasTerritoryFAEnabled = false;
  private hasConsentCheckFAEnabled = false;
  private contactListToBeDisplayed$: BehaviorSubject<Contact[]> = new BehaviorSubject([]);
  public contactListToBeDisplayedObservable: Observable<Contact[]> = this.contactListToBeDisplayed$.asObservable();
  public mostRecentActivityTitle = '';
  public mostRecentActivityLoadingCounter: Counter = new Counter('mostRecentActivityLoadingCounter');
  public suggestionsActive: boolean = false;
  public suggestionsData = [];
  private filterTags: ShortCallFilterField[] = [];
  public selectedTags: ShortCallField[] = [];

  constructor(
    private agendaFooterService: AgendaFooterService,
    private device: DeviceService,
    private cd: ChangeDetectorRef,
    public deltaService: DeltaService,
    private brandService: BrandOfflineService,
    private presentationService: PresentationService,
    private accountService: AccountOfflineService,
    public territoryService: TerritoryManagementService,
    private contactService: ContactOfflineService,
    private navService: NavigationService,
    private meetingService: MeetingDataService,
    private notificationService: NotificationService,
    private translate: TranslateService,
    private activityService: ActivityService,
    private meetingStructureService: MeetingStructureService,
    private footerService: FooterService,
    public uiService: UIService,
    private authService: AuthenticationService,
    private contactTimelineAggregatorService: ContactTimelineAggregatorService,
    private modalCtrl: ModalController
  ) {
    this.isAndroid = this.device.isAndroid();
  }

  ngOnInit() {
    this.device.isBackgroundUploadInProgressObservable.pipe(takeUntil(this.ngDestroy$)).subscribe(inProgress => {
      this.backgroundUploadInProgress = inProgress;
      this.cd.detectChanges();
    });
    this.device.syncInProgress$.pipe(takeUntil(this.ngDestroy$)).subscribe(inProgress => {
      this.syncInProgress = inProgress;
      this.searchText = '';
      this.initilizeHomeData();
      this.suggestionsActive = false;
      this.suggestionsData = [];
      this.cd.detectChanges();
    });
    this.initilizeHomeData();
    this.agendaFooterService.initButtons(AgendaFooterView.ShortCallLauncher);

    // Loading counter to track the number of mostRecentActivity data fetch in progress
    this.mostRecentActivityLoadingCounter.resetCounter();
  }
  ngOnDestroy() {
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }

  async onSearchInput(ev) {
    if (ev && ev.target) {
      let params = ev.target.value;
      //OMNI-42997: user inputs at least one Chinese character, or three English alphabets
      if (this.translate.currentLang == 'zh_CN') {
        if (params.length >= 1) {
          await this.getSuggestedData(params);
        } else {
          this.suggestionsData = [];
        }
      } else {
        if (params.length >= 3) {
          await this.getSuggestedData(params);
        } else {
          this.suggestionsData = [];
        }
      }
      this.suggestionsActive = !_.isEmpty(this.suggestionsData);
    }
  }

  private async getSuggestedData(params: any) {
    this.suggestionsData = [];
    try {
      const filteredContacts = this.contactService.contacts.filter(contact => contact.fullName.toLowerCase().includes(params.toLowerCase()));
      if (!_.isEmpty(filteredContacts)) {
        const accContactAffs = await this.contactService.getAccountContactAffiliationsFromDB();
        filteredContacts.forEach(contact => {
          const affAccounts = accContactAffs.filter(accContactAff => accContactAff['indskr_accountcontactaffiliation.indskr_contactid'] === contact.ID
            && this.accountService.accounts.some(acc => acc.id === accContactAff['indskr_accountcontactaffiliation.indskr_accountid']));
          if (!_.isEmpty(affAccounts)) {
            const searchText = contact.fullName.substring(contact.fullName.toLowerCase().indexOf(params.toLowerCase()), contact.fullName.toLowerCase().indexOf(params.toLowerCase()) + params.length);
            affAccounts.forEach(account => {
              this.suggestionsData.push({
                contactId: contact.ID,
                accountId: account.accountid,
                text: `<span class='contactName'>`
                  + contact.fullName.split(new RegExp(params, 'i')).join(`<span class='highlight'>` + searchText + `</span>`) + `<span class='secondary-text'> | `
                  + [contact.indskr_externalid, account['indskr_accountcontactaffiliation.indskr_accountid@OData.Community.Display.V1.FormattedValue']].filter(Boolean).join(' | ') + `</span>`,
                contactName: contact.fullName,
              });
            });
          }
        });
      }
    } catch (err) {
      console.error('Failed to get contact search suggested data', err);
    }
  }

  private initilizeHomeData() {
    this.resetSelectedContact();
    this.checkFAs();
    this.initProducts();
    this.initContent();
    this.initAccountWidget();
    this.initFilterTagData();
  }

  private async initAccountWidget() {
    if (!this.syncInProgress && this.accountService.accounts?.length > 0) {
      //&& this.authService.hasFeatureAction(FeatureActionsMap.CUSTOMER_LIST_MANAGEMENT) && this.territoryService.currentListPeriod && this.territoryService.currentListPeriod.tagLabel) {
      // const targetAccounts = this.accountService.accounts.filter(account => account.tagLabels.includes(this.territoryService.currentListPeriod.tagLabel));
      // this.selectedAccount = _.sortBy(targetAccounts, 'accountName')[0];
      if (this.accountService.shortCallSelectedAccountId) {
        this.selectedAccount = this.accountService.getAccountById(this.accountService.shortCallSelectedAccountId);
      } else {
        const nearByAccounts = await this.accountService.getAccountNearByMeter(5000);
        if (_.isEmpty(nearByAccounts)) {
          this.selectedAccount = _.sortBy(this.accountService.accounts, 'accountName')[0];
        } else {
          this.selectedAccount = nearByAccounts[0];
        }
        // Mark current selected account
        this.accountService.shortCallSelectedAccountId = this.selectedAccount.id
      }

      this.fetchAffContacts();
    }
  }

  public selectOption(option: string) {
    if (option == this.selectedActivityOption) {
      this.selectedActivityOption = null;
      this.resetSelectedContact();
      this.mostRecentActivityLoadingCounter.resetCounter();
      this.edaData.cards.forEach(c => c.selected = false);
    } else {
      this.selectedActivityOption = option;
    }
  }

  private initContent() {
    console.log(this.presentationService.presentation);
    this.eCardData.showSkeleton = this.edaData.showSkeleton = true;
    if (!this.syncInProgress && this.presentationService.presentation?.length > 0) {
      this.presentationService.presentation = [...this.presentationService.initialPres];
      this.nonExpiredContents = this.presentationService.filterExpiredPresentations(this.presentationService.presentation);
      this.filterContent();
    }
    this.eCardData.showSkeleton = this.edaData.showSkeleton = this.syncInProgress;
  }

  private initProducts() {
    this.productTabData = [];
    if (this.syncInProgress) {
      this.productTabData = Array.from({ length: 3 }, (_, index) => ({
        value: index + 1,
        displayText: null,
        showSkeleton: true
      }))
    } else {
      if (this.brandService.brands?.length > 0) {
        this.productTabData = _.orderBy(this.brandService.brands.map(prod => { return { value: prod.ID, displayText: prod.name } }), 'displayText');
        this.selectedProductTab = this.productTabData[0].value;
      }
    }
  }

  private initFilterTagData() {
    this.filterTags = [];
    const parentTags = this.presentationService.userTagData?.parentTags;
    if (parentTags?.length > 0) {
      this.filterTags = parentTags.map(pt => ({
        value: pt.parent_tag_id,
        label: pt.parent_tag_name,
        selected: false,
        tags: pt.childTags.map(child => ({
          value: child.indskr_usertagid,
          label: child.indskr_name,
          selected: false
        }))
      }));
    }
  }

  private checkFAs() {
    this.hasTerritoryFAEnabled = this.authService.hasFeatureAction(FeatureActionsMap.CUSTOMER_LIST_MANAGEMENT)
      && this.territoryService.currentListPeriod?.tagLabel
      ? true : false;
    this.hasConsentCheckFAEnabled = this.authService.hasFeatureAction(FeatureActionsMap.MEETING_CONSENT_CHECK);
  }
  private resetSelectedContact() {
    this.selectedContact = null;
  }

  public selectedTabChange(product: string) {
    this.edaData.showSkeleton = true;
    this.selectedProductTab = product;
    this.filterContent();
  }

  private filterContent() {
    const contentsByProduct: Presentation[] = this.nonExpiredContents.filter(c => c.brands?.length > 0 && c.brands.some(c => c.ID == this.selectedProductTab));
    let eDaCards: ContentCard[] = [];
    let eCards: ContentCard[] = [];
    const tags = this.presentationService.userTagData?.presentationTags ?? [];
    this.selectedTags = this.filterTags?.reduce((acc, obj) => acc.concat(obj.tags.filter(tag => tag.selected)), []) ?? [];
    contentsByProduct.forEach(c => {
      const contentCard: ContentCard = {
        contentId: c.contentId,
        imageUrl: c.thumbnailUrl,
        name: c.name,
        tags: tags.find(t => t.presentation_id == c.contentId)?.tags ?? []
      };
      const addContent = (ecard: boolean, contentCard: ContentCard): void => {
        if (ecard) {
          if(!eCards.some(c => c.contentId == contentCard.contentId)) eCards.push(contentCard);
        } else {
          if (!eDaCards.some(c => c.contentId == contentCard.contentId)) eDaCards.push(contentCard);
        }
      }
      if (this.selectedTags.length > 0) {
        if (contentCard.tags.length > 0) {
          this.selectedTags.forEach(st => {
            if (contentCard.tags.some(cc => cc.indskr_usertagid == st.value)) {
              addContent(c.indskr_ecard, contentCard);
            }
          })
        }
      } else {
        addContent(c.indskr_ecard, contentCard);
      } 
    });

    this.edaData.cards = eDaCards;
    this.eCardData.cards = eCards;
    this.eCardData.showSkeleton = this.edaData.showSkeleton = false;
  }

  public async openContentFilter() {
    console.log('Content filter');
    const pageModal = await this.modalCtrl.create({
      component: IoContentFilter, componentProps: {
        view: 'short-call-home',
        tags: this.filterTags,
      }, backdropDismiss: true, cssClass: 'short-call-home'
    })
    await pageModal.present();
    await pageModal.onDidDismiss().then(async (data) => {
      const filter: { type: string, tags: ShortCallFilterField[], modified: boolean } = data?.data;
      console.log(filter);
      if (filter?.modified) this.initContent();
    });
  }

  public onContentSelect(card: ContentCard, ecard: boolean) {
    console.log("Selected Content: ", card);
    this.createMeeting(card, ecard);
  }

  private prepContactToDisplayList() {
    if (!Array.isArray(this.affiliatedContacts)) {
      this.contactListToBeDisplayed$.next([]);
      return;
    }

    let target: Contact[] = [];
    let nonTarget: Contact[] = [];

    if (this.hasTerritoryFAEnabled) {
      // Separate out target & non-target contacts
      const reducedContacts = this.affiliatedContacts.reduce((result, contact) => {
        const isTargetContact = contact.tagLabels?.includes(this.territoryService.currentListPeriod.tagLabel);
        contact.isTargetContact = isTargetContact;

        return isTargetContact
          ? { ...result, target: [...result.target, contact] }
            : { ...result, nonTarget: [...result.nonTarget, contact] }
      },
      {
        target: [],
        nonTarget: [],
      });

      target = reducedContacts.target;
      nonTarget = reducedContacts.nonTarget;
    } else {
      nonTarget = [...this.affiliatedContacts];
    }
    // Sort both list
    target = this.translate.currentLang == 'zh_CN'
      ? sortObjArrayByChineseStringField(target, 'fullName')
      : target.sort(sortObjArrayByStringField('fullName'));
    nonTarget = this.translate.currentLang == 'zh_CN'
      ? sortObjArrayByChineseStringField(nonTarget, 'fullName')
      : nonTarget.sort(sortObjArrayByStringField('fullName'));
    this.targetContactsCount = target.length;
    // Merge them
    this.contactListToBeDisplayed$.next([...target, ...nonTarget]);
  }

  private async fetchAffContacts() {
    this.affiliatedContacts = await this.contactService.getAffiliatedContactsFromAccountsForMeeting([this.selectedAccount]);
    // this.targetContactsCount = !_.isEmpty(this.affiliatedContacts) ? this.affiliatedContacts.length : 0;
    this.contactService.mapProductSegmentationsToContactsForShortCallHome(this.affiliatedContacts);
    this.prepContactToDisplayList();
  }

  async openAccountSelection() {
    this.accountService.accessedAccountListFrom = PageName.ShortCallHomeComponent;
    this.navService.pushWithPageTracking(AccountPageComponent, PageName.AccountPageComponent, {
      'listMode': ComponentViewMode.ADDNEW,
      selectedAccountId: this.selectedAccount.id,
      callbackEvent: (data) => this.onAccountSelection(data)
    }, PageName.AccountPageComponent);
  }

  async onAccountSelection(data) {
    if (data && data.isDone) {
      // Reset selected contact if a new account is selected
      if (
        this.selectedAccount?.id
        && data.selectedItem?.id
        && this.selectedAccount.id !== data.selectedItem.id
      ) {
        this.resetSelectedContact();
      }
      this.selectedAccount = data.selectedItem;
      // Mark current selected account
      this.accountService.shortCallSelectedAccountId = this.selectedAccount.id
      await this.fetchAffContacts();
    }
  }

  private getMostRecentActivityTitle(contact) {
    const cache = this.contactService.getMostRecentActivityDataById(contact.ID);

    // Display cache upfront
    if (cache?.mostRecentActivityTitle) {
      this.mostRecentActivityTitle = cache.mostRecentActivityTitle;
    }
    // If offline, fetch is already triggered, or it has been less than 5 min since last fetch, return
    // UPDATE: decided to trigger the fetch everytime without 5 min wait time.
    // Putting 10s as the min delay
    if (
      this.device.isOffline
      || cache?.isFetching
      || (cache?.recentFetchTime && new Date().getTime() - 10000 < cache.recentFetchTime)
    ) {
      return;
    }

    // Start fetch
    // Update fetch flag to cache
    this.contactService.setMostRecentActivityData(
      contact.ID,
      {
        isFetching: true,
        mostRecentActivityTitle: cache?.mostRecentActivityTitle ?? null,
        recentFetchTime: cache?.recentFetchTime ?? null,
      }
    );
    this.mostRecentActivityLoadingCounter.incCounter();

    this.contactTimelineAggregatorService.fetchContactTimeline(contact)
      .then(() => {
        const mostRecentActivitySubject = this.contactTimelineAggregatorService.getMostRecentActivitySubject(contact);
        // Only update label if it's current contact
        if (this.selectedContact.ID === contact.ID) {
          this.mostRecentActivityTitle = mostRecentActivitySubject;
        }
        this.contactService.setMostRecentActivityData(
          contact.ID,
          {
            isFetching: false,
            mostRecentActivityTitle: mostRecentActivitySubject,
            recentFetchTime: new Date().getTime(),
          }
        )
      })
      .catch((err) => {
        console.error('short-call-home: getMostRecentActivityTitle: ', err);
      })
      .finally(() => this.mostRecentActivityLoadingCounter.decCounter());
  }

  private async hasConsent(contact: Contact): Promise<boolean> {
    let hasConsent = false;
    try {
      hasConsent = await this.meetingService.getActiveGeneralConsent(contact?.ID);
    } catch (error) {
      console.error(' short-call-home: hasConsent: ', contact, error);
    }
    return hasConsent;
  }

  async contactChipClick(contact: Contact, fromSearch=false) {
    if (this.selectedContact) {
      let curSelectedContact = this.selectedContact;
      if (!fromSearch) {
        // Reset selection & most recent activity label
        this.mostRecentActivityTitle = '';
        this.selectedContact = undefined;
      }
      // It was just de-selecting a contact
      if (curSelectedContact.ID === contact.ID) {
        return;
      }
    }

    // Selecting a contact
    this.selectedContact = contact;
    this.getMostRecentActivityTitle(contact);
    setTimeout(() => {
      if(document.getElementById(contact.ID))
        document.getElementById(contact.ID).scrollIntoView();
    }, 100);
    if (this.hasConsentCheckFAEnabled) {
      // Run consent check and notify if FA enabled
      this.hasConsent(contact)
      .then(hasConsent => {
        if (!hasConsent) {
          this.notificationService.notify(this.translate.instant('SHORT_MEETING_NO_CONSENT'), 'Short Call Home', 'top', ToastStyle.INFO);
        }
      });
    }
  }

  openContactDetail(contact: Contact) {
    this.uiService.contactDetailsSegment = 'info';
    this.contactService.contactInformation = contact;
    this.contactService.contactPageMode = ComponentViewMode.READONLY;
    this.navService.pushWithPageTracking(ContactDetailsComponent, PageName.ContactDetailsComponent, null, PageName.ShortCallHomeComponent);
  }

  private async createMeeting(content: ContentCard, ecard: boolean) {
    if (!this.selectedContact) { 
      this.notificationService.notify(this.translate.instant('SHORT_CALL_HCP_MANDATORY'), 'Short Call Home', 'top', ToastStyle.DANGER);
      return;
    } else if (this.device.isOffline) {
      this.notificationService.notify(this.translate.instant('SCHEDULER_NO_INTERNET_CONNECTION'), 'Short Call Home', 'top', ToastStyle.DANGER);
      return;
    }
    await this.uiService.displayLoader();

    // Consent check can block UI and make user feel the app not responsive
    // hence placed this check after the loader
    if (
      this.hasConsentCheckFAEnabled
      && this.selectedContact
      && !await this.hasConsent(this.selectedContact)
    ) {
      // Selected contact does not have consent. Notify and return.
      await this.uiService.dismissLoader();
      this.notificationService.notify(this.translate.instant('SHORT_MEETING_NO_CONSENT_CONTACT_TO_START_MEETING'), 'Short Call Home', 'top', ToastStyle.DANGER);
      return;
    }

    const presentation = this.nonExpiredContents.find(p => p.contentId == content.contentId);
    try {
      const product = this.brandService.brands.find(b => b.ID == this.selectedProductTab);
      const clonedProduct = _.cloneDeep(product);
      clonedProduct.isSelected = true;
      if (clonedProduct?.keyMessages.length > 0) {
        clonedProduct.keyMessages.forEach(k => k.isAutoSelected = k.isSelected = false);
      }
      await this.meetingService.createMeetingFromShortCallHome(this.selectedAccount, this.selectedContact, presentation, clonedProduct, ecard);
      const shortcall = this.activityService.selectedActivity as AppointmentActivity;
      await this.meetingStructureService.createEmbeddedInteractions(shortcall);
      if (this.activityService.selectedActivity instanceof AppointmentActivity) {
        this.activityService.selectedActivity.isMeetingPresentationDetailed = this.activityService.selectedActivity['activityPresentations'].length > 0;
        this.presentationService.clearCurrentPresentation();
        this.presentationService.setCurrentSelectedPres(null);
        this.presentationService.setCarouselBriefcase(this.meetingStructureService.contents);
        const firstActivity: EmbeddedInteraction = this.meetingStructureService.embeddedIntreactions?.length > 0 ? this.meetingStructureService.embeddedIntreactions[0] : null;
        this.meetingStructureService.setCurrentMeetingActivity(firstActivity);
        this.presentationService.setCurrentSelectedPres(presentation);
        const page: Page = _.isEmpty(this.presentationService.presPages) ? null : this.presentationService.presPages[0];
        this.presentationService.setCurrentSelectedPresPage(page);
        this.presentationService.selectedActivityId = shortcall.ID;
        this.presentationService.viewMode = PresentationView.MEETING;
        this.activityService.activityDetailsLoaded = true;
        this.footerService.initButtons(FooterViews.PreviewMeeting);
        // This ensures that the footer doesn't change when HCP joins
        this.uiService.activeView = 'Meeting';
        const onShortCallCompletion = (data) => { if (data != undefined) this.selectOption(this.selectedActivityOption); };
        await this.navService.pushWithPageTracking(PresentationMeetingComponent, PageName.PresentationMeetingComponent,
          { from: PageName.ShortCallHomeComponent, onShortCallCompletion: onShortCallCompletion }, PageName.PresentationMeetingComponent);
        this.uiService.dismissLoader()
      }
    } catch (err) {
      this.uiService.dismissLoader();
      this.notificationService.notify(this.translate.instant('SHORT_MEETING_CREATE_FAILED'), 'Short Call Home', 'top', ToastStyle.DANGER); 
    }
  }

  clickedInSuggestionsArea(ev){
    ev.stopPropagation();
  }

  async handleFacetSelection(data) {
    console.log(data);
    const contact = this.contactService.contacts.find(con => con.ID === data.contactId);
    this.selectedAccount = this.accountService.accounts.find(acc => acc.id === data.accountId);
    // Mark current selected account
    this.accountService.shortCallSelectedAccountId = this.selectedAccount.id
    await this.fetchAffContacts();
    this.selectedActivityOption = this.shortCallActivityOptions[0]; //Default select first option
    this.contactChipClick(contact, true);
    this.suggestionsActive = false;
    this.suggestionsData = [];
    this.searchText = '';
  }
  
}