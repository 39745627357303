import { PageName } from './../../../services/navigation/navigation.service';
import { IAllocationShipmentAndTransferBase, IAllocationShipment, IAllocationShipmentAcknowledgeDTO } from './../../../interfaces/allocation/allocation-shipment.interface';
import { IAllocationAdjustment } from './../../../interfaces/allocation/allocation-adjustment.interface';
import { AllocationFeatureService } from './../../../services/sample/allocation.feature.service';
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AllocationShipmentService } from '../../../services/sample/allocation-shipment.service';
import { RepServices } from '../../../data-services/rep/rep.services';
import { DeviceService } from '../../../services/device/device.service';
import { UIService } from '../../../services/ui/ui.service';
import { AllocationShipmentDataService } from '../../../data-services/sample/allocation-shipment.data.service';
import { FooterService, FooterViews } from '../../../services/footer/footer.service';
import { SampleService } from '../../../services/sample/sample.service';
import { NotificationService, ToastStyle } from '../../../services/notification/notification.service';
import { TrackService, TrackingEventNames } from '../../../services/logging/tracking.service';
import { AllocationTransferService } from '../../../services/sample/allocation-transfer.service';
import { Subject, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from '../../../services/alert/alert.service';
import { IndDropdownListDetailModel } from '../../../models/indDropdownListModel';
import { PopoverController, AlertController } from '@ionic/angular';
import { IndDropdownListComponent } from '../../../components/shared/ind-dropdown-list/ind-dropdown-list';
import { IndPageTitleViewDataModel } from './../../../models/indPageTitleDataModel';
import { IndSectionHeaderViewDataModel } from '../../../models/indSectionHeaderDataModel';
import { IndFormFieldViewDataModel, FormFieldType } from '../../../models/indFormFieldDataModel';
import { DateTimeFormatsService } from '../../../services/date-time-formats/date-time-formats.service';
import { EventsService } from './../../../services/events/events.service';
import { takeUntil, debounceTime, skip } from 'rxjs/operators';
import { AuthenticationService } from '../../../services/authentication.service';
import { FeatureActionsMap } from '@omni/classes/authentication/user.class';
import { AllocationAdjustService } from '../../../services/sample/allocation-adjust.service';
import { AllocationAdjustmentDataService } from '../../../data-services/sample/allocation-adjust.data.service';
import { DatePipe } from '@angular/common';
import { NavigationService } from '../../../services/navigation/navigation.service';
import { IAllocationTransfer } from '../../../interfaces/allocation/allocation-transfer.interface';
import { AdjustmentStatus, ShipmentStatus, TransferType } from '../../../enums/allocation/allocation.enum';
import { convertTimestampNumberToISODateFormat } from '../../../utility/common.utility';
import { ActivityService } from '@omni/services/activity/activity.service';
import _ from 'lodash';


@Component({
    selector: 'allocation-shipment-details',
    templateUrl: 'allocation-shipment-details.html',
    styleUrls:['allocation-shipment-details.scss']
})
export class AllocationShipmentDetailsComponent implements OnInit, OnDestroy {
    @Input() masterPage: PageName;
    public isAllocationTransfer = false;
    public shipmentStatus = ShipmentStatus;
    public adjustmentStatus = AdjustmentStatus;
    public transferType = TransferType;
    private subscription: Subscription;
    private _selectReasonPopover;
    allocationShipmentDetailPanePageTitle: IndPageTitleViewDataModel;
    public sectionTitle: IndSectionHeaderViewDataModel;
    public isShipmentTypeReceived = false;
    public isShipmentTypeSent = false;
    public isShipmentStatusAcknowledged = false;
    public isShipmentStatusShipped = false;
    statusFormField: IndFormFieldViewDataModel;
    productField: IndFormFieldViewDataModel;
    transferNumberField: IndFormFieldViewDataModel;
    shipmentDateField: IndFormFieldViewDataModel;
    lotNumberField: IndFormFieldViewDataModel;
    userField: IndFormFieldViewDataModel;
    qtyShippedField: IndFormFieldViewDataModel;
    qtyAdjustedFields: IndFormFieldViewDataModel;
    adjustedByFormField: IndFormFieldViewDataModel;
    approvedByFormField: IndFormFieldViewDataModel;
    approvedOnFormField: IndFormFieldViewDataModel;
    adjustmentDateField: IndFormFieldViewDataModel;
    adjustmentReasonFormField: IndFormFieldViewDataModel;
    adjustmentCommentFormField: IndFormFieldViewDataModel;
    qtyField: IndFormFieldViewDataModel;
    receiptedDateField: IndFormFieldViewDataModel;
    transferReasonField: IndFormFieldViewDataModel;
    transferCommentFormField: IndFormFieldViewDataModel;
    private _reasonFieldInputText = '';
    private _reasonFieldPlaceholderLabel = '';
    focusedInputElement: HTMLElement;
    alert: HTMLIonAlertElement;
    private ngDestroy$ = new Subject<boolean>();
    public isDeviceOnline = true;
    isAdjustment = false;
    quantityReceivedInputValue: number = 0;
    private quantityReceivedInput$: Subject<boolean> = new Subject();
    reasonForLossInputValue: number = undefined;
    private refreshReq$: Subject<boolean> = new Subject();
    private isClickedReason: boolean = false;
    public isClickedQty: boolean = false;
    public errorMessageMandatoryField: string = '';

  private footerHandler: (eventName: string) => void = (eventName: string) => {
    switch (eventName) {
      case 'approveAdjustment':
        this.approveAdjustment();
        break;
      case 'notApproveAdjustment':
        this.notApproveAdjustment();
        break;
      default: break;
    }
  };

  private async createAlert(header, message,button:any) {

    let buttons = [{
      text: this.translate.instant('CANCEL'),
      role: 'cancel',
      handler: () => { }
    }]
    buttons.push(button);
    this.alert = await this.alertCtrl.create({
      header: header,
      message: message,
      buttons: buttons
    });
  }

    constructor(
        public shipmentsService: AllocationShipmentService,
        private readonly sampleService: SampleService,
        private readonly shipmentsDataService: AllocationShipmentDataService,
        public repService: RepServices,
        private readonly device: DeviceService,
        public uiService: UIService,
        public footerService: FooterService,
        private readonly alertService: AlertService,
        private readonly notificationService: NotificationService,
        public trackingService: TrackService,
        private readonly allocTransferService: AllocationTransferService,
        public translate: TranslateService,
        private readonly popoverCtrl: PopoverController,
        public dateTimeFormatsService: DateTimeFormatsService,
        public events: EventsService,
        private _cd: ChangeDetectorRef,
        private readonly authService: AuthenticationService,
        private readonly allocationAdjustmentService: AllocationAdjustService,
        private readonly allocationAdjustmentDataService: AllocationAdjustmentDataService,
        private readonly alertCtrl: AlertController,
        private datePipe: DatePipe,
        private navService: NavigationService,
        public allocFeatureService: AllocationFeatureService,
        public activityService: ActivityService,
    ){}

    ngOnInit(){
      this.transferType.AllocationTransfer
      this.init();

      // Only utilized in allocation tool
      // Plan list uses setRoot on child nav, hence, new shipment selection will
      // init a fresh component and won't need this subscription
      if (!this.subscription && this.masterPage === PageName.AllocationComponent) {
        this.subscription = this.shipmentsService.newShipmentSelectedFromList$
        .subscribe({
          next: async (masterPage: PageName) => {
            if (masterPage === this.masterPage) {
              this.isClickedQty = false;
              await this.init();
            }
          }
        });
      }
      this.sectionTitle = {
        id: 'material-transfer-header',
        title: this.allocFeatureService.selectedShipment?.indskr_transfertype === this.transferType.AllocationTransfer ? this.translate.instant('AL_MATERIAL_TRANS') : this.translate.instant('DETAILS_CAP'),
        controls: [],
      };
      this.events.unsubscribe('refreshShipmentDetails');
      this.events.subscribe('refreshShipmentDetails', () => {
        this._initAllDataModels();
      });
      this.errorMessageMandatoryField = this.activityService.getErrorMessageRequiredField();

      this.events.subscribe('shipment-detail:footerEvents', this.footerHandler);


      this.events.observe('device:deviceIsOffline').pipe(
      takeUntil(this.ngDestroy$))
      .subscribe((status) => {
        this.disableScrapButtonsOfAdjustment();
      });

      this.events.observe('device:deviceIsOnline').pipe(
      takeUntil(this.ngDestroy$))
      .subscribe((status) => {
        this.disableScrapButtonsOfAdjustment();
      });

      this.device.isOfflineObservable.pipe(takeUntil(this.ngDestroy$)).subscribe((offline) => {
        this.isDeviceOnline = !offline;
        this.getStatusFormField();

        if (this.allocFeatureService.selectedShipment?.indskr_transfertype !== TransferType.AllocationAdjustment) {
          if (this.allocFeatureService.selectedShipment?.indskr_shipmentstatus === this.shipmentStatus.Acknowledged || !this.canBeAcknowledged) {
            this.footerService.disableButton(['acknowledgeShipment', 'transferallocation']);
            this._cd.detectChanges();
          }
        } else {
          this.enableDisableFooterButtons();
        }

          this._initPageTitle();
          this._cd.detectChanges();
        });

      this.quantityReceivedInput$.asObservable()
        .pipe(
          takeUntil(this.ngDestroy$),
          debounceTime(500)
        ).subscribe(() => { if (this.navService.getActiveChildNavViewPageName() === PageName.AllocationShipmentDetailsComponent || this.masterPage === PageName.AllocationComponent) { this.handleQuantityReceivedInputChange() } });

      // As a temp workaround, de-bouncing ngDoCheck initiated checks..
      this.refreshReq$.asObservable()
        .pipe(
          takeUntil(this.ngDestroy$),
          debounceTime(500)
        ).subscribe(() => {
          if (this.navService.getActiveChildNavViewPageName() === PageName.AllocationShipmentDetailsComponent || this.masterPage === PageName.AllocationComponent) {
            this.footerService.disableButton(['acknowledgeShipment', 'transferallocation']);
            this._initPageTitle();
            this._initAllDataModels();
          }
        });
    }


  private enableDisableFooterButtons() {
    if (this.allocFeatureService.selectedShipment?.indskr_adjustedstatus !== AdjustmentStatus.Approved) {
      if (this.allocFeatureService.selectedShipment?.ownerId !== this.authService.user.systemUserID) {
        this.footerService.updateButtons(['notApproveAdjustment', 'approveAdjustment'], true);
        if (this.device.isOffline) {
          this.footerService.disableButton(['notApproveAdjustment', 'approveAdjustment']);
        } else {
          this.footerService.enableButtons(['notApproveAdjustment', 'approveAdjustment'])
        }
      } else {
        if (this.authService.hasFeatureAction(FeatureActionsMap.ALLOCATION_ADJUSTMENT_AUTO_APPROVAL)) {
          this.footerService.updateButtons(['scrapAdjustment', 'saveAllocationAdjustment'], true);
          this.footerService.disableButton(['scrapAdjustment', 'saveAllocationAdjustment']);
        } else {
          this.footerService.updateButtons(['scrapAdjustment', 'adjustShipment'], true);
          this.footerService.disableButton(['scrapAdjustment', 'adjustShipment']);
        }
      }
    } else {
      if (this.allocFeatureService.selectedShipment?.ownerId !== this.authService.user.systemUserID) {
        this.footerService.updateButtons(['notApproveAdjustment', 'approveAdjustment'], true);
        this.footerService.disableButton(['notApproveAdjustment', 'approveAdjustment']);
      } else {
        if (this.authService.hasFeatureAction(FeatureActionsMap.ALLOCATION_ADJUSTMENT_AUTO_APPROVAL)) {
          this.footerService.updateButtons(['scrapAdjustment', 'saveAllocationAdjustment'], true);
          this.footerService.disableButton(['scrapAdjustment', 'saveAllocationAdjustment']);
        } else {
          this.footerService.updateButtons(['scrapAdjustment', 'adjustShipment'], true);
          this.footerService.disableButton(['scrapAdjustment', 'adjustShipment']);
        }
      }
    }
  }


    async init() {
      this.isAllocationTransfer = this.allocFeatureService.selectedShipment?.indskr_transfertype === this.transferType.AllocationTransfer;
      this.isShipmentTypeSent = this.isAllocationTransfer
        ? this.allocFeatureService.selectedShipment?.ownerId === this.authService.user.xSystemUserID
        : false;
      this.isShipmentTypeReceived = !this.isShipmentTypeSent;
      this.isShipmentStatusAcknowledged = this.allocFeatureService.selectedShipment?.indskr_shipmentstatus === this.shipmentStatus.Acknowledged;
      this.isShipmentStatusShipped = this.allocFeatureService.selectedShipment?.indskr_shipmentstatus === this.shipmentStatus.Shipped;
      this.isAdjustment = this.allocFeatureService.selectedShipment?.indskr_transfertype === this.transferType.AllocationAdjustment;

      this._reasonFieldInputText = '';
      this.reasonForLossInputValue = undefined;
      if (this.isAllocationTransfer && this.isShipmentTypeSent) {
        this.footerService.initButtons(FooterViews.ALLOCATION_TRANSFER);
      } else {
        this.footerService.initButtons(FooterViews.ShipmentDetails);
      }

      if (!this.isAdjustment) {
        // Quantity received value for shipment & transfer
        this.quantityReceivedInputValue = (this.allocFeatureService.selectedShipment as IAllocationShipmentAndTransferBase)?.indskr_quantityreceived ?? 0;
        if (this.isAllocationTransfer && this.isShipmentTypeReceived && this.isShipmentStatusShipped) {
          // Pre-fill received transfer value
          this.quantityReceivedInputValue = (this.allocFeatureService.selectedShipment as IAllocationTransfer)?.u_quantityshipped ?? 0;
        }
      }

        this._initPageTitle();
        this._initAllDataModels();

      if (this.isAdjustment) {

        if (!this.device.isOffline && !this.device.isUserStateOffline) {
          await this.uiService.displayLoader();
          const updatedAdjustment: IAllocationAdjustment = await this.allocationAdjustmentDataService.getAdjustmentDetail(this.allocFeatureService.selectedShipment as IAllocationAdjustment);
          await this.uiService.dismissLoader();

          if ((this.allocFeatureService.selectedShipment)?.indskr_adjustedstatus !== updatedAdjustment.indskr_adjustedstatus) {
              this.allocFeatureService.selectedShipment = updatedAdjustment;
            this._initPageTitle();
            this._initAllDataModels();
          }

          this.enableDisableFooterButtons();

            if (updatedAdjustment.ownerId !== this.authService.user.systemUserID) {
              this.events.publish('adjustmentUpdated');
            } else {
              this.events.publish('adjustmentUpdated$');
            }
        }
      }
    }

    private _initPageTitle() {
        let buttons = [];
        if (!this.isSplitView) {
            buttons.push({
                id: 'close',
                icon: 'chevron-back-outline',
                isDisabled: false,
                align: 'left'
            });
        }

      if (this.isAdjustment) {
        if (this.allocFeatureService.selectedShipment?.ownerId !== this.authService.user.systemUserID) {
          if (this.authService.hasFeatureAction(FeatureActionsMap.ALLOCATION_ADJUSTMENT_TEAM_REQUEST)) {
            buttons.push({
              id: 'not_approve',
              imgSrc: 'assets/imgs/header_cancel.svg',
              name: this.translate.instant('NOT_APPROVE'),
              isDisabled: !this.canBeApprovedNotApproved,
              align: 'right'
            });

            buttons.push({
              id: 'approve',
              imgSrc: 'assets/imgs/header_complete.svg',
              name: this.translate.instant('APPROVE'),
              isDisabled: !this.canBeApprovedNotApproved,
              align: 'right'
            });
          }
        } else {
          buttons.push({
            id: 'scrap',
            imgSrc: 'assets/imgs/header_cancel.svg',
            name: this.translate.instant('SCRAP'),
            isDisabled: !this.canBeApprovedNotApproved,
            align: 'right'
          });


          if (this.authService.hasFeatureAction(FeatureActionsMap.ALLOCATION_ADJUSTMENT_AUTO_APPROVAL)) {
            buttons.push({
              id: 'Adjust',
              imgSrc: 'assets/imgs/header_save.svg',
              name: this.translate.instant('SAVE'),
              isDisabled: !this.canBeApprovedNotApproved,
              align: 'right'
            });
          } else {
            buttons.push({
              id: 'Adjust',
              imgSrc: 'assets/imgs/header_send.svg',
              name: this.translate.instant('SEND'),
              isDisabled: !this.canBeApprovedNotApproved,
              align: 'right'
            });
          }
        }
      } else {
        if (this.isShipmentTypeReceived) {
          buttons.push(
            {
              id: 'receipt',
              imgSrc: 'assets/imgs/header_complete.svg',
              name: this.translate.instant('RECEIPT'),
              isDisabled: !this.canBeAcknowledged,
              align: 'right'
            });
        }

        if (this.isShipmentTypeSent) {
          buttons.push({
            id: 'transfer',
            imgSrc: 'assets/imgs/header_send.svg',
            name: this.translate.instant('TRANSFER'),
            isDisabled: true,
            align: 'right'
          });
        }
      }


      this.allocationShipmentDetailPanePageTitle = {
        id: 'allocation-shipment-details-page-title',
        title: this.isAllocationTransfer
                ? this.translate.instant('AL_TRANSFER_NUMBER') + `${this.allocFeatureService.selectedShipment?.indskr_shipmentnumber}`
                : this.isAdjustment
                  ? this.translate.instant('AL_ADJUSTMENT_NUMBER') + ` ${this.allocFeatureService.selectedShipment?.indskr_shipmentnumber}`
                  : `${this.translate.instant('AL_SHIPMENT_NUM')}  ${this.allocFeatureService.selectedShipment?.indskr_shipmentnumber}`,
        controls: buttons
      };
    }

    public onPageTitleControlClick(id: string) {
        switch (id) {
            case 'close':
                this.onClosePage();
                break;
            case 'receipt':
                this.acknowledgeShipment();
                break;
              case  'not_approve':
                this.notApproveAdjustment();
                break;
              case  'approve':
                 this.approveAdjustment();
                break;
            default:
                console.log('Unhandled switch case statement');
                break;
        }
    }
    _initAllDataModels() {
      this.getStatusFormField();
      this.getProductField();
      this.getTransferNumberField();
      if (!this.isAdjustment) {
        this.getShipmentDateField();
      }
      this.getLotNumberField();
      this.getUserField();
      this.getQtyShippedField();
      this.getQtyAdjustedFields();
      this.getQtyField();
      this.getTransferReasonField();
      this.getTransferCommentField();
      this.getShipmentReasonField();
      this.getAdjustmentReasonField();
      this.getAdjustmentCommentField();
      this.getReceiptedDateField();
      this.getPlaceholderReasonField();
      this.isDisabledReasonField();
      if (this.isAdjustment) {
        this.getApprovedByFormField();
        this.getApprovedDateFormField();
        this.getAdjustedByField();
        this.getAdjustmentDateField();
      }
    }

    private _getStatus() {
      if (this.isAdjustment) {
        const selectedShipment: IAllocationAdjustment = this.allocFeatureService.selectedShipment as IAllocationAdjustment;
        let status = this.translate.instant('OPEN');
       if (selectedShipment?.indskr_adjustedstatus === AdjustmentStatus.InReview) {
          status = this.translate.instant('FOR_REVIEW');
        }
         else if (selectedShipment?.indskr_adjustedstatus === AdjustmentStatus.Open) {
          status = this.translate.instant('OPEN');
        }
         else {
          status = this.translate.instant('APPROVED');
        }
        return status;
      } else {
        return this.allocFeatureService.selectedShipment?.indskr_shipmentstatus === this.shipmentStatus.Acknowledged
          ? this.translate.instant('RECEIPTED')
          : this.allocFeatureService.selectedShipment?.indskr_shipmentstatus === this.shipmentStatus.Draft ? this.translate.instant('OPEN')
          : this.allocFeatureService.selectedShipment?.indskr_shipmentstatus === this.shipmentStatus.NotApproved ? this.translate.instant('NOT_APPROVED')
          : this.allocFeatureService.selectedShipment?.indskr_shipmentstatus === this.shipmentStatus.ForReview ? this.translate.instant('FOR_REVIEW')
          : this.translate.instant('SHIPPED');
      }
    }

    public getStatusFormField() {
        let viewData: IndFormFieldViewDataModel;
        viewData = {
          label: this.translate.instant('STATUS'),
          inputText: this._getStatus(),
          id: 'status-field',
          isReadOnly: true,
          isDisabled: true,
          showArrow: false,
        };
        this.statusFormField = viewData;
      }
      public getProductField() {
        let viewData: IndFormFieldViewDataModel;
        viewData = {
          label: this.translate.instant('PRODUCT'),
          inputText: this.allocFeatureService.selectedShipment?.indskr_skuname ?? this.allocFeatureService.selectedShipment?.at_indskr_skuname,
          id: 'product-field',
          isReadOnly: true,
          isDisabled: true,
          showArrow: false,
        };
        this.productField = viewData;
    }
    public getTransferNumberField() {
        let viewData: IndFormFieldViewDataModel;
        viewData = {
          label: this.isAllocationTransfer ? this.translate.instant('TRANSFER_NUMBER') : (this.isAdjustment) ? this.translate.instant('AL_ADJUSTMENT_NUMBER') : this.translate.instant('AL_SHIPMENT_NUM'),
          inputText: this.allocFeatureService.selectedShipment?.indskr_shipmentnumber,
          id: 'transfer-number-field',
          isReadOnly: true,
          isDisabled: true,
          showArrow: false,
        };
        this.transferNumberField = viewData;
    }
    public getShipmentDateField() {
        let viewData: IndFormFieldViewDataModel;
        viewData = {
          label: this.isAllocationTransfer ? this.translate.instant('AL_TRANSFER_DATE') : (this.isAdjustment) ? this.translate.instant('AL_ADJUSTMENT_DATE') : this.translate.instant('AL_SHIPMENT_DATE'),
          inputText: (this.allocFeatureService.selectedShipment as IAllocationShipmentAndTransferBase)?.indskr_shipmentdate
            ? this.datePipe.transform((this.allocFeatureService.selectedShipment as IAllocationShipmentAndTransferBase)?.indskr_shipmentdate, this.dateTimeFormatsService.date, undefined, this.translate.currentLang) : '',
          id: 'shipment-date-field',
          isReadOnly: true,
          isDisabled: true,
          showArrow: false,
        };
        this.shipmentDateField = viewData;
    }

    public getAdjustedByField() {
      let viewData: IndFormFieldViewDataModel;
        viewData = {
          label: this.translate.instant('AL_ADJUSTED_BY'),
          inputText: this.allocFeatureService.selectedShipment?.ownerFullName || (this.allocFeatureService.selectedShipment as IAllocationShipmentAndTransferBase)?.userFullName,
          id: 'adjusted-by-field',
          isReadOnly: true,
          isDisabled: true,
          showArrow: false,
        };
        this.adjustedByFormField = viewData;
    }

  public getAdjustmentDateField() {
    let viewData: IndFormFieldViewDataModel;
    let adjustment: IAllocationAdjustment;
    if (this.isAdjustment) {
      adjustment = this.allocFeatureService.selectedShipment as IAllocationAdjustment;
    }

    viewData = {
          label: this.translate.instant('AL_ADJUSTMENT_DATE'),
          inputText: adjustment?.indskr_adjustmentdate ? this.datePipe.transform(adjustment?.indskr_adjustmentdate, this.dateTimeFormatsService.date, undefined, this.translate.currentLang) : '',
          id: 'adjustment-date-field',
          isReadOnly: true,
          isDisabled: true,
          showArrow: false,
        };
    this.adjustmentDateField = viewData
  }


    public getApprovedByFormField() {

      let adjustment: IAllocationAdjustment;
      if (this.isAdjustment) {
        adjustment = this.allocFeatureService.selectedShipment as IAllocationAdjustment;
      }
      let viewData: IndFormFieldViewDataModel;
        viewData = {
          label: this.translate.instant('TIMEOFF_APPROVED_BY'),
          inputText: adjustment?.approvedByName,
          id: 'approved-by-field',
          isReadOnly: true,
          isDisabled: true,
          showArrow: false,
        };
        this.approvedByFormField = viewData;
    }

    public getApprovedDateFormField() {
      let adjustment: IAllocationAdjustment;
      if (this.isAdjustment) {
        adjustment = this.allocFeatureService.selectedShipment as IAllocationAdjustment;
      }
      let viewData: IndFormFieldViewDataModel;
        viewData = {
          label: this.translate.instant('AL_APPROVED_DATE'),
          inputText: adjustment?.indskr_adjustmentapproveddate ? this.datePipe.transform(adjustment?.indskr_adjustmentapproveddate, this.dateTimeFormatsService.date, undefined, this.translate.currentLang) : '',
          id: 'approved-date-field',
          isReadOnly: true,
          isDisabled: true,
          showArrow: false,
        };
        this.approvedOnFormField = viewData;
    }

    public getLotNumberField() {
        let viewData: IndFormFieldViewDataModel;
        viewData = {
          label: this.translate.instant('AL_LOT_NUMBER'),
          inputText: this.allocFeatureService.selectedShipment?.indskr_lotname ?? this.allocFeatureService.selectedShipment?.at_indskr_lotname,
          id: 'lot-number-field',
          isReadOnly: true,
          isDisabled: true,
          showArrow: false,
        };
        this.lotNumberField = viewData;
    }
    public getUserField() {
        let viewData: IndFormFieldViewDataModel;
        viewData = {
          label: this.isAllocationTransfer ? this.isShipmentTypeSent ? this.translate.instant('AL_TRANSFER_TO') : this.translate.instant('AL_TRANSFERED_BY') : '',
          inputText: this.isAllocationTransfer ? this.isShipmentTypeSent ? (this.allocFeatureService.selectedShipment as IAllocationTransfer)?.userFullName : this.allocFeatureService.selectedShipment?.ownerFullName : '',
          id: 'user-field',
          isReadOnly: true,
          isDisabled: true,
          showArrow: false,
        };
        this.userField = viewData;
    }
    public getQtyShippedField() {
        let viewData: IndFormFieldViewDataModel;
        viewData = {
          label: this.isAllocationTransfer ? this.translate.instant('AL_QUANTITY_TRANS') : (this.isAdjustment) ? this.translate.instant('QUANTITY_ADJUSTED') : this.translate.instant('AL_QUNTITY_SHIPPED'),
          inputText: (this.allocFeatureService.selectedShipment as IAllocationShipmentAndTransferBase)?.u_quantityshipped?.toString() ?? (this.allocFeatureService.selectedShipment as IAllocationAdjustment)?.indskr_quantityadjusted?.toString(),
          id: 'quantity-shipped-field',
          isReadOnly: true,
          isDisabled: true,
          showArrow: false,
        };
        this.qtyShippedField = viewData;
    }

  public getQtyAdjustedFields() {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label:  this.translate.instant('QUANTITY_ADJUSTED'),
      inputText: this.isAdjustment ? (this.allocFeatureService.selectedShipment as IAllocationAdjustment)?.indskr_quantityadjusted?.toString() : '0',
      id: 'quantity-adjusted-field',
      isReadOnly: true,
      isDisabled: true,
      showArrow: false,
    };
    this.qtyAdjustedFields = viewData;
  }

    public getQtyField() {
        let viewData: IndFormFieldViewDataModel;
        const isAcknowledged: boolean = this.allocFeatureService.selectedShipment?.indskr_shipmentstatus === this.shipmentStatus.Acknowledged;
        viewData = {
          label: isAcknowledged ? this.translate.instant('AL_QUANTITY_RECEIPTED') : this.translate.instant('QUANTITY_RECEIVED'),
          inputText: this.quantityReceivedInputValue?.toString() ?? '0',
          id: 'quantity-field',
          isReadOnly: true,
          isDisabled: true,
          showArrow: false,
        };
        this.qtyField = viewData;
    }
    public getReceiptedDateField() {
        let viewData: IndFormFieldViewDataModel;
        viewData = {
          label: this.translate.instant('AL_DATE_RECEIPTED'),
          inputText: (this.allocFeatureService.selectedShipment as IAllocationShipmentAndTransferBase)?.indskr_dateacknowledged
            ? this.datePipe.transform((this.allocFeatureService.selectedShipment as IAllocationShipmentAndTransferBase)?.indskr_dateacknowledged, this.dateTimeFormatsService.date, undefined, this.translate.currentLang) : '',
          id: 'receipted-date-field',
          isReadOnly: true,
          isDisabled: true,
          showArrow: false,
        };
        this.receiptedDateField = viewData;
    }

  private _getReasonLabel() {

    if (this.isAdjustment) {
      return this.translate.instant('AL_ADJUSTMENT_REASON');
    }
    else {
      return this.isShipmentTypeSent ? this.translate.instant('REASON') : this.translate.instant('AL_TRANS_REASON');
    }
  }

    public getTransferReasonField() {
        let viewData: IndFormFieldViewDataModel;
        viewData = {
          label: this._getReasonLabel(),
          inputText: this.isAllocationTransfer ? this.allocTransferService.getReasonTxt((this.allocFeatureService.selectedShipment as IAllocationTransfer)?.indskr_reasonfortransfer) : '',
          id: 'transfer-reason-field',
          isReadOnly: true,
          isDisabled: true,
          showArrow: false,
        };
        this.transferReasonField = viewData;
    }

    public getTransferCommentField()  {
      let viewData: IndFormFieldViewDataModel;
      viewData = {
        label: this.translate.instant('ACCOUNT_COMMENTS'),
        inputText: (this.allocFeatureService.selectedShipment as IAllocationTransfer)?.indskr_comments,
        id: 'comment-field',
        isReadOnly: true,
        isDisabled: true,
        showArrow: false,
      };

      this.transferCommentFormField = viewData
  }

    public getShipmentReasonField(): IndFormFieldViewDataModel {
        let viewData: IndFormFieldViewDataModel;
        let selectedReason = this.shipmentsService.getSelectedReasonText((this.allocFeatureService.selectedShipment as IAllocationShipment)?.indskr_reasonforloss);
        viewData = {
            label: this.translate.instant('REASON'),
            inputText: this.allocFeatureService.selectedShipment?.indskr_shipmentstatus === this.shipmentStatus.Acknowledged ? selectedReason
              : this._reasonFieldInputText,
            customPlaceholderLabel: this._reasonFieldPlaceholderLabel,
            id:'reason-field',
            isReadOnly: true,
            isDisabled: this.isDisabledReasonField(),
            showArrow: this.allocFeatureService.selectedShipment?.indskr_shipmentstatus === this.shipmentStatus.Shipped,
            formFieldType: FormFieldType.POPOVER_SELECT,
            isRequired: this.quantityReceivedInputValue > 0 && this.quantityReceivedInputValue !== (this.allocFeatureService.selectedShipment as IAllocationShipmentAndTransferBase)?.u_quantityshipped && this.allocFeatureService.selectedShipment.indskr_shipmentstatus === this.shipmentStatus.Shipped,
            errorMessage: this.activityService.getErrorMessageRequiredField(),
            isEmptyRequiredField: this.isClickedReason && this.allocFeatureService.selectedShipment.indskr_shipmentstatus === this.shipmentStatus.Shipped && _.isEmpty(this._reasonFieldInputText),
            eventHandler: (id: string, event, eventName) => this.openSelectReason(event),
        };
        return viewData;
    }

    public getAdjustmentReasonField()  {
        let viewData: IndFormFieldViewDataModel;
        let selectedReason = ''
      if (this.isAdjustment) {
        selectedReason = this.allocationAdjustmentService.getReasonText((this.allocFeatureService.selectedShipment as IAllocationAdjustment)?.indskr_reasonforadjustment);
      }
      viewData = {
            label: this.translate.instant('REASON'),
            inputText: selectedReason,
            id:'reason-field',
            isReadOnly: true,
            isDisabled: true,
            showArrow: false,
        };

        this.adjustmentReasonFormField = viewData
    }

    public getAdjustmentCommentField()  {
        let viewData: IndFormFieldViewDataModel;
        viewData = {
          label: this.translate.instant('ACCOUNT_COMMENTS'),
          inputText: (this.allocFeatureService.selectedShipment as IAllocationAdjustment)?.indskr_comment,
          id: 'comment-field',
          isReadOnly: true,
          isDisabled: true,
          showArrow: false,
        };

        this.adjustmentCommentFormField = viewData
    }

    checkInputReceivedValue(event) {
      this.quantityReceivedInput$.next(true);
    }
    private handleQuantityReceivedInputChange() {
      this.getPlaceholderReasonField();
      this.isDisabledReasonField();
      this._initPageTitle();
    }

    getPlaceholderReasonField() {
        if (!((this.allocFeatureService.selectedShipment as IAllocationShipment)?.indskr_reasonforloss)) {
            if (this.allocFeatureService.selectedShipment?.indskr_shipmentstatus === this.shipmentStatus.Shipped) {
                this._reasonFieldPlaceholderLabel = this.translate.instant('SELECT_REASON');
            } else this._reasonFieldPlaceholderLabel = this.translate.instant('NO_REASON');
        }
    }

    isDisabledReasonField(): boolean {
        let flag: boolean = false;
        if (!this.isAllocationTransfer && !this.isAdjustment
          && this.allocFeatureService.selectedShipment?.indskr_shipmentstatus !== this.shipmentStatus.Acknowledged) {
            if (this.quantityReceivedInputValue
              && (this.allocFeatureService.selectedShipment as IAllocationShipmentAndTransferBase)?.u_quantityshipped
                === this.quantityReceivedInputValue) {
                this._reasonFieldInputText = '';
                this.reasonForLossInputValue = undefined;
                this._reasonFieldPlaceholderLabel = this.translate.instant('NO_REASON');
                flag = true;
            } else {
                this._reasonFieldPlaceholderLabel = this.translate.instant('SELECT_REASON');
                flag = false;
            }
        } else if ((this.allocFeatureService.selectedShipment as IAllocationShipment)?.indskr_reasonforloss) {
                flag = false;
            }
        return flag;
    }

    ngDoCheck() {
      if ((this.navService.getActiveChildNavViewPageName() === PageName.AllocationShipmentDetailsComponent || this.masterPage === PageName.AllocationComponent) && this.allocFeatureService.selectedShipment && !this.isAdjustment && !this.canBeAcknowledged) {
        this.refreshReq$.next(true);
      }
    }

    ngOnDestroy() {
      this.ngDestroy$.next(true);
      this.ngDestroy$.complete();
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
      this.events.unsubscribe("refreshShipmentDetails");
      this.events.unsubscribe('shipment-detail:footerEvents');
    }

    isSplitView() {
        return (window.innerWidth > 500);
    }

    onClosePage(){
        this.trackingService.tracking('CustomerAllocationReceiptsDetailsBack', TrackingEventNames.ALLOCATIONS);
        this.allocFeatureService.selectedShipment = null;
        if (this.shipmentsService.fromAgendaPlanTab) {
          this.uiService.showRightPane = false;
          this.navService.popChildNavPageWithPageTracking();

          if (this.device.isMobileDevicePortrait) {
            this.events.publish('closeShipmentDetail');
          }

          this.shipmentsService.fromAgendaPlanTab = false;
        } else {
          this.uiService.activeView = 'CustomerAllocation';
        }
    }

    acknowledgeShipment() {
        if (this.canBeAcknowledged) {
            this.alertService.showAlert({
                title: this.translate.instant('AL_RECEIPT_SHIPMENT'),
                message: this.translate.instant('AL_R_U_SURE_RECEIPT_SHIPMENT')},
                this.translate.instant('RECEIPT'),
                this.translate.instant('CANCEL')
            ).then(async res => {
                if(res.role === 'ok') {
                    await this.uiService.displayLoader();
                    this.trackingService.tracking('CustomerAllocationReceiptAcknowledged', TrackingEventNames.ALLOCATIONS);
                    if (this.quantityReceivedInputValue
                        === (this.allocFeatureService.selectedShipment as IAllocationShipmentAndTransferBase)?.u_quantityshipped) {
                        this.reasonForLossInputValue = undefined;
                    }
                    const dateAcknowledged = new Date();
                    const shipment_id_to_be_acknowledged = this.allocFeatureService.selectedShipment?.indskr_usershipmentallocation_v2id;
                    const payload: IAllocationShipmentAcknowledgeDTO = {
                        indskr_shipmentstatus: ShipmentStatus.Acknowledged,
                        indskr_reasonforloss: this.reasonForLossInputValue,
                        indskr_description: this.allocFeatureService.selectedShipment.indskr_description,
                        indskr_quantityreceived: this.quantityReceivedInputValue,
                        indskr_dateacknowledged: dateAcknowledged.getTime(),
                    }

                    if((this.allocFeatureService.selectedShipment as IAllocationShipmentAndTransferBase)?.indskr_shipmentdate) {
                      payload.indskr_datereceived = new Date((this.allocFeatureService.selectedShipment as IAllocationShipmentAndTransferBase).indskr_shipmentdate).getTime();
                    }
                    // Need to include transfer type in case the shipment is transferred from other user
                    if (this.isAllocationTransfer
                      && this.isShipmentTypeReceived) {
                        payload['indskr_transfertype'] = TransferType.AllocationTransfer;
                    }

                    this.shipmentsDataService.acknowledgeShipment(this.allocFeatureService.selectedShipment?.indskr_usershipmentallocation_v2id, payload).subscribe(async (res) => {
                        payload;
                        if(res && res['errorCode'] && res['errorCode'] === 'ERR_IO_SR03'){
                            if(res['errorDetails']){
                              let errorDetail = res['errorDetails'];
                              if (
                                 errorDetail['indskr_usershipmentallocationid']
                                 && errorDetail['indskr_usershipmentallocationid'] === this.allocFeatureService.selectedShipment.indskr_usershipmentallocation_v2id
                                 && errorDetail['indskr_shipmentstatus'] === this.shipmentStatus.Acknowledged
                              ) {
                                  this.notificationService.notify( 'User Shipment Allocation is already acknowledged', '','top', ToastStyle.DANGER, 3000, true);

                                  const ackDateNumber: number = Number(errorDetail['indskr_dateacknowledged']);
                                  const errorPayload: IAllocationShipmentAcknowledgeDTO = {
                                    indskr_shipmentstatus: ShipmentStatus.Acknowledged,
                                    indskr_reasonforloss: errorDetail['indskr_reasonforloss'],
                                    indskr_description: errorDetail['indskr_description'],
                                    indskr_quantityreceived: Number(errorDetail['indskr_quantityreceived']),
                                    indskr_dateacknowledged: ackDateNumber > 0 ? ackDateNumber : undefined,
                                  }

                                  await this.handleAcknowledgeSuccess(errorPayload);
                              }
                            }
                            await this.uiService.dismissLoader();
                        } else {
                          await this.handleAcknowledgeSuccess(payload);
                          await this.uiService.dismissLoader();
                        }
                        if(!this.canBeAcknowledged) this.footerService.disableButton(['acknowledgeShipment', 'transferallocation']);
                        this._cd.detectChanges();
                    },
                    async (error) => {
                        await this.uiService.dismissLoader();
                        console.error('acknowledgeShipment: ', error);
                        let errorInfo = (error.hasOwnProperty('error')) ? error.error : error;
                        if(errorInfo.errorCode == "ERR_IO_500"){
                            if(this.allocFeatureService.selectedShipment?.indskr_shipmentstatus === this.shipmentStatus.Acknowledged) {
                              this._initAllDataModels();
                              this.notificationService.notify( 'User Shipment Allocation is already acknowledged', '','top', ToastStyle.DANGER, 3000, true);
                              throw new Error('Already acknowledged');
                            }
                        }
                        if(!this.canBeAcknowledged) this.footerService.disableButton(['acknowledgeShipment', 'transferallocation']);
                        this._cd.detectChanges();
                    });
                }
            });
        }
    }

    private async handleAcknowledgeSuccess(payload: IAllocationShipmentAcknowledgeDTO) {
      // Update selectedShipment object data
      this.allocFeatureService.selectedShipment.indskr_shipmentstatus = ShipmentStatus.Acknowledged;
      (this.allocFeatureService.selectedShipment as IAllocationShipmentAndTransferBase).indskr_quantityreceived = payload.indskr_quantityreceived;
      (this.allocFeatureService.selectedShipment as IAllocationShipmentAndTransferBase).indskr_dateacknowledged = convertTimestampNumberToISODateFormat(payload.indskr_dateacknowledged, 'dateTime');
      this.allocFeatureService.selectedShipment.lastUpdatedTime = new Date().getTime();
      if (this.allocFeatureService.selectedShipment.indskr_transfertype !== TransferType.AllocationTransfer
        && this.allocFeatureService.selectedShipment.indskr_transfertype !== TransferType.AllocationAdjustment
      ) {
        // Shipment
        (this.allocFeatureService.selectedShipment as IAllocationShipment).indskr_reasonforloss = payload.indskr_reasonforloss;
      }

      // Update DB
      await this.allocFeatureService.accessLocalDB(this.allocFeatureService.selectedShipment, 'shipmentOrTransfer', 'upsert');

      // Update Lots
      await this.sampleService._addToLotTotalQuantityDetail(this.allocFeatureService.selectedShipment);

      // Update shipments list
      if (Array.isArray(this.allocFeatureService.shipments) && this.allocFeatureService.shipments.length > 0) {
        this.allocFeatureService.addToOrReplaceInShipmentsArray(this.allocFeatureService.selectedShipment);
      }

      // Update pending shipments list
      const removedFromPendingList = this.allocFeatureService.removeFromPendingShipmentsArray(this.allocFeatureService.selectedShipment.indskr_usershipmentallocation_v2id);
      if (removedFromPendingList) {
        this.events.publish('shipmentAcknowledged', this.allocFeatureService.selectedShipment);
      }
    }

    async openSelectReason(event){
        if(this.allocFeatureService.selectedShipment?.indskr_shipmentstatus === this.shipmentStatus.Shipped
          && this.quantityReceivedInputValue > 0
          && (this.allocFeatureService.selectedShipment as IAllocationShipmentAndTransferBase)?.u_quantityshipped
            !== this.quantityReceivedInputValue){
            if(Array.isArray(this.shipmentsService.allLossReasons) && this.shipmentsService.allLossReasons.length > 0){
                let dropdownListDetail: IndDropdownListDetailModel = {
                    id: 'Shipment-Reason-Select',
                    data: this.shipmentsService.allLossReasons.map(reason=>{
                        let obj = {
                            title: reason.reason,
                            id: reason.value.toString(),
                            isSelected: false,
                        };
                        if(this.allocFeatureService.selectedShipment
                          && reason.value === this.reasonForLossInputValue){
                            obj['isSelected'] = true;
                        }
                        return obj;
                    }),
                };
                this._selectReasonPopover = await this.popoverCtrl.create({component: IndDropdownListComponent,componentProps: {viewData:dropdownListDetail}, event:event, cssClass:'dropdown-list-view'});
                this._selectReasonPopover.onDidDismiss().then(async (selectedData)=>{
                    let data = selectedData.data;
                    if (data && data.selectedItems && data.selectedItems.length === 1
                        && data.selectedItems[0].id !== this.reasonForLossInputValue) {
                      this.reasonForLossInputValue = Number(data.selectedItems[0].id) ?? null;
                      this._reasonFieldInputText = data?.selectedItems[0]?.title;
                      this._initPageTitle();
                    }
                    this._initPageTitle();
                    this._selectReasonPopover = undefined;
                    this.isClickedReason = true;
                });
                this._selectReasonPopover.present();
                this._initAllDataModels();
            }
        }
    }

    get canBeAcknowledged(): boolean {
        let flag: boolean = false;
        if (this.isShipmentTypeReceived
            && this.allocFeatureService.selectedShipment?.indskr_shipmentstatus !== this.shipmentStatus.Acknowledged
            && this.quantityReceivedInputValue > 0
            && this.isDeviceOnline) {
            if (this.quantityReceivedInputValue
              === (this.allocFeatureService.selectedShipment as IAllocationShipmentAndTransferBase)?.u_quantityshipped) {
                flag = true;
            } else if (this.reasonForLossInputValue!==548910002 && this._reasonFieldInputText) {
                flag = true;
            } else if (this.reasonForLossInputValue===548910002 && this.allocFeatureService.selectedShipment.indskr_description) {
              flag = true;
            }
        }
        if (flag) {
            this.footerService.enableButtons(['acknowledgeShipment']);
        } else {
            this.footerService.disableButton(['acknowledgeShipment', 'transferallocation']);
        }
        return flag;
    }

    get canBeApprovedNotApproved(): boolean {
        let flag: boolean = false;
        if (this.allocFeatureService.selectedShipment?.indskr_adjustedstatus === this.adjustmentStatus.InReview
            && this.isDeviceOnline && this.authService.hasFeatureAction(FeatureActionsMap.ALLOCATION_ADJUSTMENT_TEAM_REQUEST) && this.allocFeatureService.selectedShipment?.ownerId !== this.authService.user.systemUserID) {
            flag = true;
        }
        return flag;
    }

    private disableScrapButtonsOfAdjustment() {
      if (this.footerService.footerForView === FooterViews.AdjustDtails) {
        const activeButtons = this.footerService.getActiveButtonsList();
        if (activeButtons.includes('scrapAdjustment')) {
          if (this.device.isOffline)
            this.footerService.disableButton(['scrapAdjustment']);
          else
            this.footerService.enableButtons(['scrapAdjustment']);
        }
      }
    }

    onInputFocus(focusInput) {
      this.focusedInputElement = focusInput as HTMLElement;
    }

    onFocusOut(event) {
      this.isClickedQty = true;
    }

    private async approveAdjustment() {

    if (!this.alert) {
      let button = {
        text: this.translate.instant('APPROVE'),
        handler: await this._approveConfirmed,
      }
      await this.createAlert(this.translate.instant('AL_ADJUSTMENT_APPROVE_HEADER'), this.translate.instant('AL_ADJUSTMENT_APPROVE_MESSAGE'), button);
      this.alert.present();
      this.alert.onDidDismiss().then(() => this.alert = undefined);
    }
  }

  private async notApproveAdjustment() {

    if (!this.alert) {
      let button = {
        text: this.translate.instant('NOT_APPROVE'),
        handler: await this._notApproveConfirmed,
      }
      await this.createAlert(this.translate.instant('AL_ADJUSTMENT_NOT_APPROVE_HEADER'), this.translate.instant('AL_ADJUSTMENT_NOT_APPROVE_MESSAGE'), button);
      this.alert.present();
      this.alert.onDidDismiss().then(() => this.alert = undefined);
    }
  }

  private _notApproveConfirmed = async () => {
    await this.updateStatus(AdjustmentStatus.Open);
  }

  private _approveConfirmed = async () => {
    await this.updateStatus(AdjustmentStatus.Approved)
  }


  private async updateStatus(status: AdjustmentStatus) {
    await this.uiService.displayLoader();
    let updatedAdjustment = await this.allocationAdjustmentDataService.updateStatus(status);
    if (updatedAdjustment) {
      this.allocFeatureService.selectedShipment = updatedAdjustment;
      this.events.publish('adjustmentUpdated');
    }
     await this.uiService.dismissLoader();

    if (updatedAdjustment?.indskr_adjustedstatus === AdjustmentStatus.Open) {
      this.onClosePage();
      return;
    }
    await this.init();
  }

  footerButtonClicked(clickedButton: string) {
    switch (clickedButton) {
      case 'acknowledgeShipment':
        this.acknowledgeShipment();
        break;
      default:
        break;
    }
  }

  public getCommentFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('ACCOUNT_COMMENTS'),
      inputText: (this.allocFeatureService.selectedShipment && (this.allocFeatureService.selectedShipment as IAllocationShipment)?.indskr_description) ? this.allocFeatureService.selectedShipment['indskr_description'] : '',
      inputValue: (this.allocFeatureService.selectedShipment && (this.allocFeatureService.selectedShipment as IAllocationShipment)?.indskr_description) ? this.allocFeatureService.selectedShipment['indskr_description'] : '',
      id: 'comment-field',
      isReadOnly: !this.allocFeatureService.selectedShipment || this.allocFeatureService.selectedShipment.indskr_shipmentstatus !== this.shipmentStatus.Shipped || this.authService.user.systemUserID !== this.allocFeatureService.selectedShipment.ownerId,
      isDisabled: !this.allocFeatureService.selectedShipment || this.allocFeatureService.selectedShipment.indskr_shipmentstatus !== this.shipmentStatus.Shipped || this.authService.user.systemUserID !== this.allocFeatureService.selectedShipment.ownerId,
      placeholderLabel: this.translate.instant('ACCOUNT_COMMENTS'),
      showArrow: !(!this.allocFeatureService.selectedShipment || this.allocFeatureService.selectedShipment.indskr_shipmentstatus !== this.shipmentStatus.Shipped || this.authService.user.systemUserID != this.allocFeatureService.selectedShipment.ownerId),
      formFieldType: FormFieldType.INLINE_INPUT,
      isRequired: !(!this.allocFeatureService.selectedShipment || this.allocFeatureService.selectedShipment.indskr_shipmentstatus !== this.shipmentStatus.Shipped || this.authService.user.systemUserID != this.allocFeatureService.selectedShipment.ownerId || this.reasonForLossInputValue!==548910002),
      eventHandler: (id: string, event, eventName) => this.handleCommentsInput(event, eventName),
    };
    return viewData;
  }

  handleCommentsInput(event, eventName) {
    if (this.allocFeatureService.selectedShipment != null && eventName == 'input_value_confirm') {
      if (this.allocFeatureService.selectedShipment.indskr_shipmentstatus === this.shipmentStatus.Shipped && this.authService.user.systemUserID === this.allocFeatureService.selectedShipment.ownerId) {
        let comment = (event && event.target && event.target.value && event.target.value.length != 0) ? event.target.value : "";
        if (this.allocFeatureService.selectedShipment.indskr_description != comment) {
          this.allocFeatureService.selectedShipment.indskr_description = comment;
          this._cd.markForCheck();
        }
      }
    }
  }
}
