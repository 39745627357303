import { AgendaFooterService } from './../../../services/footer/agenda-footer.service';
import { NotificationService, ToastStyle } from './../../../services/notification/notification.service';
import { EventsService } from './../../../services/events/events.service';
import { ChangeDetectorRef, Component, Input } from "@angular/core";
import { IndPageTitleViewDataModel } from './../../../models/indPageTitleDataModel';
import { TranslateService } from '@ngx-translate/core';
import { ActivityService } from "@omni/services/activity/activity.service";
import { SurgeryOrderActivity } from "@omni/classes/activity/surgery-order.activity.class";
import { TrackingEventNames, TrackService } from "@omni/services/logging/tracking.service";
import { AuthenticationService } from "@omni/services/authentication.service";
import { IndSectionHeaderViewDataModel } from "@omni/models/indSectionHeaderDataModel";
import { FormFieldType, IndFormFieldViewDataModel } from "@omni/models/indFormFieldDataModel";
import { FooterService, FooterViews } from "@omni/services/footer/footer.service";
import { MainCardViewDataModel } from "@omni/models/MainCardViewDataModel";
import { AlertService } from "@omni/services/alert/alert.service";
import { AccountOfflineService, AccountSelectedFor } from "@omni/services/account/account.offline.service";
import { ChildNavNames, NavigationService, PageName } from "@omni/services/navigation/navigation.service";
import { AccountPageComponent } from "@omni/components/account/account-page/account-page";
import { ComponentViewMode, UIService } from "@omni/services/ui/ui.service";
import { LoadingController, PopoverController, ModalController, IonNav } from "@ionic/angular";
import { DatetimePickerComponent } from "@omni/components/datetime-picker/datetime-picker";
import { LogService } from "@omni/services/logging/log-service";
import { DateTimeFormatsService } from "@omni/services/date-time-formats/date-time-formats.service";
import { addMinutes, differenceInCalendarDays, differenceInDays, format, isPast, subDays, isBefore } from "date-fns";
import { ContactOfflineService } from "@omni/services/contact/contact.service";
import { ContactPageComponent } from '@omni/components/contact/contact-page/contact-page';
import { LocationComponent } from '@omni/components/location/location';
import { DeviceService } from '@omni/services/device/device.service';
import { MainToolTemplateDetail, MainToolTemplateListSelectionType } from "@omni/models/mainToolTemplateDetail.model";
import { MainToolTemplateComponent } from "@omni/components/shared/main-tool-template/main-tool-template";
import { SurgeryOrderActivityDataService } from "@omni/data-services/surgery-order-activity/surgery-order-activity.data.service";
import { OperationDetail } from '@omni/data-services/follow-up-activity/follow-up-activity.data.service';
import { Contact } from '@omni/classes/contact/contact.class';
import { Account } from '@omni/classes/account/account.class';
import { NothingSelectedView } from '@omni/components/shared/nothing-selected-view/nothing-selected-view';
import _, { cloneDeep } from 'lodash';
import * as moment from 'moment';
import { IONote } from '@omni/classes/io/io-note.class';
import { IndDateTimeFormViewDataModel } from '@omni/models/indDateTimeFormDataModel';
import { DatePipe } from '@angular/common';
import { CurViewPageType, DateTimeFieldType, IndDatetimeFormComponent } from '@omni/components/shared/ind-datetime-form/ind-datetime-form';
import { takeUntil } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
import { GlobalUtilityService } from '@omni/services/global-utility.service';
import { SurveyService } from '@omni/services/survey/survey.service';
import { FeatureActionsMap } from '@omni/classes/authentication/user.class';
import { ProcedureTrackerActivityDataService } from '@omni/data-services/procedure-tracker-activity/procedure-tracker-activity.data.service';
import { SelectListComponent } from '@omni/components/shared/select-list/select-list';
import { Guid } from 'typescript-guid';
import { FieldMaterialManagementService } from '@omni/services/field-materials/field-material-management.service';
import { AssetSelectionViewDataModel } from '@omni/models/meeting-assets-selection-model';
import { CustomerAsset } from '@omni/classes/field-materials/customer-asset.class';
import { FieldMaterialManagementPageComponent } from '@omni/components/field-material-management/field-material-management-page/field-material-management-page';

@Component({
  selector: 'surgery-order-details',
  templateUrl: 'surgery-order-details.html',
  styleUrls:['surgery-order-details.scss']
})
export class SurgeryOrderDetailsComponent {

  @Input() from: any;
  @Input() activity: any;
  public currentSurgeryOrderActivity: SurgeryOrderActivity;
  private isLayoverPushView: boolean;
  private layoverViewPushedFrom: string;
  public isReadOnlyMode = true;
  public isCompleteEnabled = false;
  // public surgeryOrderTime: string;
  public surgeryOrderDetailsPanePageTitle: IndPageTitleViewDataModel;
  public detailsHeader: IndSectionHeaderViewDataModel;
  public quantitiesHeader: IndSectionHeaderViewDataModel;
  public assetsHeader: IndSectionHeaderViewDataModel;
  public statusFormField: IndFormFieldViewDataModel;
  public customerFormField: IndFormFieldViewDataModel;
  public accountFormField: IndFormFieldViewDataModel;
  //public locationFormField: IndFormFieldViewDataModel;
  public surgeryFormField: IndFormFieldViewDataModel;
  public productCategoyFormField: IndFormFieldViewDataModel;
  public productFormField: IndFormFieldViewDataModel;
  public selectedProductsFormFields: IndFormFieldViewDataModel[];
  public isInMeetingProcedureLogFlow: boolean = false;
  public noProcedureDay:boolean = false;
  public notesHeaderModel:IndSectionHeaderViewDataModel;
  public notesPlaceholder:string = '';
  public isSaveNotesEnabled:boolean = false;
  public tempNoteText: string = '';
  // date time picker
  public startTime: string;
  public endTime: string;
  public prevStartTime: string;
  public prevEndTime: string;
  public startDateField: IndDateTimeFormViewDataModel;
  public startTimeField: IndDateTimeFormViewDataModel;
  public endDateField: IndDateTimeFormViewDataModel;
  public endTimeField: IndDateTimeFormViewDataModel;
  public durationField: IndDateTimeFormViewDataModel;
  private startDateTimeValue: string;
  private startDateValue: string;
  private startTimeValue: string;
  private endDateTimeValue: string;
  private endDateValue: string;
  private endTimeValue: string;
  private duration: string;
  public minStartDate: string;
  private ngDestroy$ = new Subject<boolean>();
  dateFormatSubscription : Subscription;
  public backgroundUploadInProgress: boolean = false;
  public globalCustomerText: string;
  // mandatory field
  private _isClickedCustomer: boolean = false;
  private _isClickedAccount: boolean = false;
  private _isClickedSurgery: boolean = false;
  private _isClickedProductCategory: boolean = false;
  private _isClickedProduct: boolean = false;
  public isWeeklyFlag: boolean = false;
  private surgeryOrderDetailsPageTitleText: string;
  public hasTagAssets: boolean = false;
  public taggedAssets:[] = [];
  public isSurveyConfigured: boolean = false;
  public procedureSurveyHeaderModel: IndSectionHeaderViewDataModel;
  public surveyLookupResults: Array<any>=[];
  public surveyTemplate: any;
  private isSurveyValid:boolean = false;
  private _surveyJsAttributesFetched:boolean = false;
  public coOwnersFormField: IndFormFieldViewDataModel;
  public _hasTagAssetsSurveyJS: boolean = false;
  private _surveyTemplateLoaded:boolean = false;

  constructor(
    private readonly authService: AuthenticationService,
    public readonly footerService: FooterService,
    private readonly alertService: AlertService,
    private readonly accountService: AccountOfflineService,
    private readonly agendaFooterService: AgendaFooterService,
    public activityService: ActivityService,
    public translate: TranslateService,
    private trackingService: TrackService,
    private _CD: ChangeDetectorRef,
    public navService: NavigationService,
    private popoverCtrl: PopoverController,
    private logService: LogService,
    public dateTimeFormatsService: DateTimeFormatsService,
    public uiService: UIService,
    private contactService: ContactOfflineService,
    public device: DeviceService,
    private surgeryOrderDataService: SurgeryOrderActivityDataService,
    private events: EventsService,
    private notifications: NotificationService,
    private loadingController: LoadingController,
    public modalCtrl: ModalController,
    public navCtrl: IonNav,
    private datePipe: DatePipe,
    private utilityService: GlobalUtilityService,
    public readonly surveyService: SurveyService,
    private procedureTrackerDataService: ProcedureTrackerActivityDataService,
    public fieldMaterialManagementService: FieldMaterialManagementService,
  ) {
    this.dateFormatSubscription = this.dateTimeFormatsService.dateFormat.subscribe( df => {
      if (df) {
        this.initDateTimeFormFields();
      }
    });
    this.events.observe('updateMandatoryField').pipe(takeUntil(this.ngDestroy$)).subscribe((field: PageName) => {
      this._updateMandatoryField(field);
    });
    this.events.subscribe('reloadSurveyTemplate', () => {
      this.loadSurveyTemplate();
    })
  }

  async ngOnInit() {
    this.isWeeklyFlag = (this.authService.user.procedureLogDuration === 'Weekly');
    this.hasTagAssets = this.authService.user.hasTagAssetsToProcedureLog;
    if (this.from) {
      this.isLayoverPushView = true;
      this.layoverViewPushedFrom = this.from;
      if (this.layoverViewPushedFrom === 'InMeetingProcedure') {
        this.surgeryOrderDataService.isInMeetingProcedureLogFlow = true;
      }
      if (this.layoverViewPushedFrom === 'PresentationMeetingComponent') {
        this.isInMeetingProcedureLogFlow = true;
      }else{
        this.surgeryOrderDataService.inMeetingSurgeryOrderActivity = null;
      }
    }
    this.currentSurgeryOrderActivity = this.activityService.selectedActivity as SurgeryOrderActivity
    if (this.activity) {
      this.currentSurgeryOrderActivity = this.activity;
      //if (this.currentSurgeryOrderActivity.location == 'No Location') this.currentSurgeryOrderActivity.location = '';
    }
    
    switch (this.utilityService.globalCustomerText) {
      case 'Customer':
        this.globalCustomerText = this.translate.instant('CUSTOMER');
          break;
        case 'Stakeholder':
          this.globalCustomerText = this.translate.instant('STAKEHOLDER');
          break;
        default:
          this.globalCustomerText = this.utilityService.globalCustomerText;
          break;
      }

    // this.surgeryOrderTime = this.getSurgeryOrderTime();
    this.noProcedureDay = this.currentSurgeryOrderActivity.indskr_noprocedureday;

    let dt = new Date().setHours(0, 0);
    this.minStartDate = format(subDays(dt, this.authService.user.maxMeetingStartDays));

    this.startTime = moment(this.currentSurgeryOrderActivity.scheduledStart).format();
    this.endTime = moment(this.currentSurgeryOrderActivity.scheduledEnd).format();

    // Update start/end time field with selected time from the time picker
    this.dateTimeFormatsService.updateSelectedTimeSurgeryOrder$.pipe(takeUntil(this.ngDestroy$)).subscribe((timeData) => {
      if (timeData) {
        if (timeData.id == DateTimeFieldType.StartTimeField) {
          this.startTime = timeData.time;
          setTimeout(() => {
            this.handleStartDateTimeValue();
          }, 0);
        } else if (timeData.id == DateTimeFieldType.EndTimeField) {
          this.endTime = timeData.time;
          setTimeout(() => {
            this.handleEndDateTimeValue();
          }, 0);
        }
      }
    });
    // init mandatory product field
    if (this.currentSurgeryOrderActivity && this.currentSurgeryOrderActivity.products && this.currentSurgeryOrderActivity.products.length > 0) {
      this.currentSurgeryOrderActivity.products.forEach(product => {
        if (product.isEmpty) product.isEmpty = false;
      });
    }
    await this._initProcedureLogSurveyJsAttributes();
    this.initAllDataModels();

    this.trackingService.tracking('OrderInfo', TrackingEventNames.SURGERY_ORDER);
    this.initProcedureNotesModel();
    this.device.isOfflineObservable.pipe(takeUntil(this.ngDestroy$)).subscribe((data) => {
      this.getPageTitle();
      this._initProcedureLogSurveyJsAttributes();
      this.initFooterViewButtons();
    });
    this.device.isBackgroundUploadInProgressObservable.pipe(takeUntil(this.ngDestroy$)).subscribe(inProgress => {
      if (this.currentSurgeryOrderActivity) {
        this.backgroundUploadInProgress = inProgress && this.currentSurgeryOrderActivity.pendingPushToDynamics;
        this.initFooterViewButtons();
        this.getPageTitle();
        this.getDetailsHeader();
        this.initProcedureNotesModel();
        this.getSelectedProductsFormField();
      }
    });

    // this.surgeryOrderDataService.validateRetiredProducts();
    setTimeout(() => {
      try {
        document.getElementById('maindetailslist').scrollIntoView();
      } catch (error) {
        
      }
    },400);
    
  }

  ngDoCheck() {
    if (this.surgeryOrderDataService.isSelectedSurgeryOrderActivityUpdated) {
      this.currentSurgeryOrderActivity = this.activityService.selectedActivity as SurgeryOrderActivity;
      this.initAllDataModels();
      // this.initFooterViewButtons();
      // this.getPageTitle();
      // this.getDetailsHeader(); not required ?? initAllDataModels will call
      this.surgeryOrderDataService.isSelectedSurgeryOrderActivityUpdated = false;
      this.initSurveyData();
    }
    if (this.activityService.selectedActivity instanceof SurgeryOrderActivity) {
      if(this.navService.getCurrentMasterPageName() === PageName.ActivitiesPageComponent && this.navService.getActiveChildNavViewPageName() === PageName.SurgeryOrderDetailsComponent){
        // if(!this.footerService.getActiveButtonsList().some(id => id === 'scrapSurgeryOrderActivity' || id === 'markCompleteSurgeryOrder')){
            this.footerService.initButtons(FooterViews.SURGERY_ORDER_ACTIVITY);
            this.initFooterViewButtons();
        // }
      }
    }
    if(this.from && this.from == 'InMeetingProcedure' && this.surgeryOrderDataService.isInMeetingProcedureLogFlow
    && this.navService.getActiveChildNavViewPageName() == PageName.ActivitiesDetailsPaneComponent){
      this.initFooterViewButtons();
    }
  }

  ngAfterContentChecked() {
  }

  ngOnDestroy() {
    this.events.unsubscribe('surgeryOrder:footerEvents');
    this.events.unsubscribe('reloadSurveyTemplate');
    this.noProcedureDay = false;
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
    this.dateFormatSubscription.unsubscribe();
  }

  public get isReopenButtonEnabled(): boolean {
    let flag: boolean = false;
    if(!this.device.isOffline && this.currentSurgeryOrderActivity.surgeryOrderStatusString === 'Completed'
    && (this.activityService.selectedActivity.ownerId == this.authService.user.systemUserID || this.currentSurgeryOrderActivity.coOwners.some((coOwner) => coOwner.userId === this.authService.user.systemUserID))
    && this.authService.user.pastProcedureLogReopenPeriod >=  differenceInCalendarDays(new Date(), this.currentSurgeryOrderActivity.createdDate)){
      flag = true;
    }
    return flag;
  }

  public get isScrapButtonEnabled(): boolean {
    let flag: boolean = false;
    if (this.currentSurgeryOrderActivity.surgeryOrderStatusString === 'Draft' && (this.activityService.selectedActivity.ownerId == this.authService.user.systemUserID || this.currentSurgeryOrderActivity.coOwners.some((coOwner) => coOwner.userId === this.authService.user.systemUserID))) {
      flag = true;
    }
    return flag;
  }

  public get isCompleteButtonEnabled(): boolean {
    let flag: boolean = false;
    if(this.noProcedureDay && this.currentSurgeryOrderActivity.surgeryOrderStatusString === 'Draft' && (this.activityService.selectedActivity.ownerId == this.authService.user.systemUserID || this.currentSurgeryOrderActivity.coOwners.some((coOwner) => coOwner.userId === this.authService.user.systemUserID))){
      return true;
    }
    if (this.currentSurgeryOrderActivity.surgeryOrderStatusString === 'Draft'
        && (this.activityService.selectedActivity.ownerId == this.authService.user.systemUserID || this.currentSurgeryOrderActivity.coOwners.some((coOwner) => coOwner.userId === this.authService.user.systemUserID))
        && this.currentSurgeryOrderActivity.customers.length>0
        && this.currentSurgeryOrderActivity.accountId
       // && this.currentSurgeryOrderActivity.location
        && isPast(this.currentSurgeryOrderActivity.scheduledStart)
        && this.currentSurgeryOrderActivity.products.length>0
        && !this.currentSurgeryOrderActivity.products.some(p=> !p.quantity ||p.quantity==0)) {
      flag = true;
    }
    return flag;
  }

  private get _enableBackButton(): boolean {
    return window.innerWidth < 500 || (this.from && (this.from === 'AccountTimeline' || this.from === 'ContactTimeline'
    || this.from === 'InMeetingProcedure' || this.from === 'ProcedureLogFromContactTool' || this.from === 'ProcedureLogFromAccountTool')) ;
  }

  initAllDataModels() {
    this.initFooterViewButtons();
    this.getPageTitle();
    this.getDetailsHeader();
    this.getQuantitiesHeader();
    this.getAssetsHeader()
    this.getStatusFormField();
    this.getCustomerFormField();
    this.getAccountFormField();
    //this.getLocationFormField();
    this.getCoOwnersFormField();
    this.getSurgeryFormField();
    this.getProductCategoryFormField();
    this.getProductFormField();
    this.getSelectedProductsFormField();
    this.initDateTimeFormFields();
    this._CD.detectChanges();
    this.initProcedureNotesModel();
  }

  public getPageTitle(){
    let buttons = [];
    if (this._enableBackButton) {
      buttons = [{
          id: 'close',
          icon: 'chevron-back-outline',
          isDisabled: false,
          align: 'left'
      }];
    }
    buttons.push(
      {
        id: "reopensurgeryactivity",
        imgSrc: 'assets/imgs/header_activity_reopen.svg',
        name: this.translate.instant('REOPEN'),
        isDisabled: this.backgroundUploadInProgress ? true : !this.isReopenButtonEnabled,
        align: "right"
      },
      {
        id: "scrapsurgeryorderactivity",
        imgSrc: 'assets/imgs/header_cancel.svg',
        name: this.translate.instant('SCRAP'),
        isDisabled: this.backgroundUploadInProgress ? true : !this.isScrapButtonEnabled,
        align: "right"
      },
      {
        id: "markcomplete",
        imgSrc: 'assets/imgs/header_complete.svg',
        name: this.translate.instant('COMPLETE'),
        isDisabled: this.backgroundUploadInProgress ? true : !this.isCompleteButtonEnabled,
        align: "right"
      },
    );
    this.surgeryOrderDetailsPanePageTitle = {
      id: 'activities-details-page-title',
      title:this.initializeheaderTitle(),
      controls: buttons
    };
  }

  private initializeheaderTitle() {
    switch (this.currentSurgeryOrderActivity.subject) {
      case 'Procedure Log':
        this.surgeryOrderDetailsPageTitleText = this.translate.instant('NEW_ACTIVITY_PROCEDURE_LOG');
        break;
      default:
        this.surgeryOrderDetailsPageTitleText = this.currentSurgeryOrderActivity.subject;
        break;
    }
    return this.surgeryOrderDetailsPageTitleText;
  }



  public getDetailsHeader() {
    let buttons = [];
    if (this.isInMeetingProcedureLogFlow) {
      buttons.push(
        {
          id: "reopensurgeryactivity",
          text: this.translate.instant('REOPEN'),
          isDisabled: this.backgroundUploadInProgress ? true : !this.isReopenButtonEnabled,
        },
        {
          id: 'scrapsurgeryorderactivity',
          text: this.translate.instant('SCRAP'),
          isDisabled: this.backgroundUploadInProgress ? true : !this.isScrapButtonEnabled,
        },
        {
          id: 'markcomplete',
          text: this.translate.instant('COMPLETE'),
          isDisabled: this.backgroundUploadInProgress ? true : !this.isCompleteButtonEnabled,
        });
    }
    this.detailsHeader = {
      id: 'details-header',
      title: this.translate.instant('DETAILS'),
      controls: buttons,
    };
  }

  public getQuantitiesHeader() {

    this.quantitiesHeader = {
      id:'quantities-header',
      title: this.translate.instant('QUANTITIES'),
      controls: []
    };
  }

  public getAssetsHeader() {
    this.assetsHeader = {
      id:'assets-header',
      title: this.translate.instant('ASSETS'),
      controls: [{
        id: "tagassets",
        text: this.translate.instant('ADD'),
        isDisabled: this.backgroundUploadInProgress ? true : (this.currentSurgeryOrderActivity.surgeryOrderStatusString != 'Draft' || this.activityService.selectedActivity.ownerId != this.authService.user.systemUserID),
      }]
    };
  }

  private initFooterViewButtons(): boolean {
    if (!this.currentSurgeryOrderActivity) return;
    if(this.isInMeetingProcedureLogFlow){
      this.footerService.initButtons(FooterViews.PREVIEW_MEETING_ACTIVITY);
    }
    this.events.unsubscribe('surgeryOrder:footerEvents');
    this.events.subscribe("surgeryOrder:footerEvents", (data) => {
      if(data) {
        this.handleButtonClick(data);
        data = null;
      }
    });
    switch (this.currentSurgeryOrderActivity.surgeryOrderStatusString) {
      case 'Draft':
        if(!this.isInMeetingProcedureLogFlow){
          this.footerService.initButtons(FooterViews.SURGERY_ORDER_ACTIVITY);
          let btnsArr = [];
          const selectedSurgery = this.surgeryOrderDataService.productHierarchies.find(a => a.surgeryId == this.currentSurgeryOrderActivity.surgeryId);
          if (!this.isCompleteButtonEnabled || this.backgroundUploadInProgress || (selectedSurgery && selectedSurgery.surveyTemplateId && !this.isSurveyValid)) btnsArr.push('markCompleteSurgeryOrder');
          if(!this.isScrapButtonEnabled || this.backgroundUploadInProgress) btnsArr.push('scrapSurgeryOrderActivity');
          if(!this.isReopenButtonEnabled || this.backgroundUploadInProgress) btnsArr.push('reopensurgeryactivity');
          this.footerService.disableButton(btnsArr);
        }
        this.isReadOnlyMode = false;
        break;
      case 'Completed':
        if(!this.isInMeetingProcedureLogFlow){
          this.footerService.initButtons(FooterViews.SURGERY_ORDER_ACTIVITY);
          let btnsArr = ['scrapSurgeryOrderActivity', 'markCompleteSurgeryOrder'];
          if(!this.isReopenButtonEnabled || this.backgroundUploadInProgress) btnsArr.push('reopensurgeryactivity');
          this.footerService.disableButton(btnsArr);
        }
        this.isReadOnlyMode = true;
        break;
      default:
        if(!this.isInMeetingProcedureLogFlow){
          this.footerService.initButtons(FooterViews.SURGERY_ORDER_ACTIVITY);
        }
        break;
    }
    if(this.currentSurgeryOrderActivity.ownerId != this.authService.user.systemUserID && !this.currentSurgeryOrderActivity.coOwners.some((coOwner) => coOwner.userId === this.authService.user.systemUserID)){
      this.isReadOnlyMode = true;
    }
  }

  private updateFooterViewButtons(): boolean {
    if (!this.currentSurgeryOrderActivity) return;
    switch (this.currentSurgeryOrderActivity.surgeryOrderStatusString) {
      case 'Draft':
          this.isReadOnlyMode = false;
        break;
      case 'Completed':
        this.isReadOnlyMode = true;
        break;
      default:
        break;
    }
  }

  public getStatusFormField() {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('STATUS'),
      inputText: this.currentSurgeryOrderActivity.surgeryOrderStatusString+((this.currentSurgeryOrderActivity.pendingPushToDynamics)?' - Pending Sync':''),
      id:'status-field',
      isReadOnly:true,
      isDisabled:true,
    };
    this.statusFormField =  viewData;
  }

  private initDateTimeFormFields() {
    let dateTimeValue = this.getSurgeryOrderTimeText();
    if (dateTimeValue) {
      this.startDateTimeValue = dateTimeValue.startDateTime;
      this.startDateValue = dateTimeValue.startDate;
      this.startTimeValue = dateTimeValue.startTime;
      this.endDateTimeValue = dateTimeValue.endDateTime;
      this.endDateValue = dateTimeValue.endDate;
      this.endTimeValue = dateTimeValue.endTime;
      this.duration = dateTimeValue.duration;
    }
    this.startDateField = {
      isDateField: true,
      label: this.translate.instant('START_DATE'),
      inputText: this.startDateValue,
      startDateTimeValue: this.startDateTimeValue,
      endDateTimeValue: this.endDateTimeValue,
      fromViewPage: CurViewPageType.SurgeryOrder,
      id: DateTimeFieldType.StartDateField,
      isReadOnly: true,
      isDisabled:this.isReadOnlyMode,
      showArrow: !this.isReadOnlyMode,
      isRequired: !this.isReadOnlyMode,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    this.startTimeField = {
      isTimeField: true,
      label: this.translate.instant('START_TIME'),
      inputText: this.startTimeValue,
      startDateTimeValue: this.startDateTimeValue,
      endDateTimeValue: this.endDateTimeValue,
      fromViewPage: CurViewPageType.SurgeryOrder,
      id: DateTimeFieldType.StartTimeField,
      isReadOnly: true,
      isDisabled: (this.backgroundUploadInProgress || this.isWeeklyFlag) ? true : this.isReadOnlyMode,
      showArrow: !this.isReadOnlyMode,
      isRequired: !this.isReadOnlyMode,
    };
    this.endDateField = {
      isDateField: true,
      label: this.translate.instant('END_DATE'),
      inputText: this.endDateValue,
      startDateTimeValue: this.startDateTimeValue,
      endDateTimeValue: this.endDateTimeValue,
      fromViewPage: CurViewPageType.SurgeryOrder,
      id: DateTimeFieldType.EndDateField,
      isReadOnly: true,
      isDisabled: (this.isReadOnlyMode || this.isWeeklyFlag),
      showArrow: !this.isReadOnlyMode,
      isRequired: !this.isReadOnlyMode,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    this.endTimeField = {
      isTimeField: true,
      label: this.translate.instant('END_TIME'),
      inputText: this.endTimeValue,
      startDateTimeValue: this.startDateTimeValue,
      endDateTimeValue: this.endDateTimeValue,
      fromViewPage: CurViewPageType.SurgeryOrder,
      id: DateTimeFieldType.EndTimeField,
      isReadOnly: true,
      isDisabled: (this.backgroundUploadInProgress || this.isWeeklyFlag) ? true : this.isReadOnlyMode,
      showArrow: !this.isReadOnlyMode,
      isRequired: !this.isReadOnlyMode,
    };
  }

  private getSurgeryOrderTimeText = (): any => {
    if (!this.currentSurgeryOrderActivity) return;
    let startDateTimeValue: Date = new Date(this.currentSurgeryOrderActivity.scheduledStart);
    let endDateTimeValue: Date = new Date(this.currentSurgeryOrderActivity.scheduledEnd);
    let startDayValue = this.datePipe.transform(startDateTimeValue, this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
    let endDayValue = this.datePipe.transform(endDateTimeValue, this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
    let startTimeValue = startDateTimeValue.toLocaleTimeString('en-US', { hour12: this.dateTimeFormatsService.is12HourFormat, hour: '2-digit', minute: '2-digit' });
    let endTimeValue = endDateTimeValue.toLocaleTimeString('en-US', { hour12: this.dateTimeFormatsService.is12HourFormat, hour: '2-digit', minute: '2-digit' });
    let formattedDuration: string = this.dateTimeFormatsService.getFormattedTimeInterval(startDateTimeValue, endDateTimeValue);

    let dateTimeValue: any = {
      startDateTime: '',
      startDate: '',
      startTime: '',
      endDateTime: '',
      endDate: '',
      endTime: '',
      duration: '',
    };

    dateTimeValue = {
      startDateTime: startDateTimeValue,
      startDate: startDayValue,
      startTime: startTimeValue,
      endDateTime: endDateTimeValue,
      endDate: endDayValue,
      endTime: endTimeValue,
      duration: formattedDuration,
    };
    return dateTimeValue;
  }

  public getCustomerFormField() {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.globalCustomerText,
      inputText: this.currentSurgeryOrderActivity.customers.length !=0 ? this._currentCustomerString : '',
      customPlaceholderLabel: (this.isReadOnlyMode && this.currentSurgeryOrderActivity.customers.length==0) ? this.translate.instant('NO_CUSTOMER') : this.translate.instant('SELECT_WITH_GLOBALCUSTOMER', { globalCustomerText: this.globalCustomerText }),
      id:'customer-field',
      isReadOnly:true,
      isDisabled: false, //this.isReadOnlyMode,
      showArrow: true, //!this.isReadOnlyMode,
      isRequired: !this.isReadOnlyMode,
      errorMessage: this.activityService.getErrorMessageRequiredField(),
      isEmptyRequiredField: this._isClickedCustomer && this.currentSurgeryOrderActivity.customers && this.currentSurgeryOrderActivity.customers.length == 0 ? true : false,
      formFieldType: FormFieldType.NEW_PAGE_SELECT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id,event,eventName),
    };
    this.customerFormField = viewData;
  }

  private get _currentCustomerString(): string {
    let str = '';
    const currentCustomers = this.currentSurgeryOrderActivity.customers//.filter(a=> a.isActive);
    if (currentCustomers) {
        if (currentCustomers.length === 1) {
            str = `${currentCustomers[0].fullname}`;
            if(currentCustomers[0].statusCode !== 1 && currentCustomers[0].statusCode !== 548910000){
                str += ' (Inactive)';
            }
        } else if (currentCustomers.length >= 2) {
          currentCustomers.sort((a, b): number => {
                if (a.fullname > b.fullname) return 1;
                if (a.fullname < b.fullname) return -1;
                return 0;
            });
            let frstStr = '';
            let scndStr = '';
            if(currentCustomers[0].statusCode !== 1 && currentCustomers[0].statusCode !== 548910000){
                frstStr = ' (Inactive)';
            }else if(currentCustomers.some(a=> a.statusCode !== 1 && a.statusCode !== 548910000)){
                scndStr = ' (Inactive)';
            }
            str = `${currentCustomers[0].fullname}${frstStr} +${currentCustomers.length - 1}${scndStr}`;
        }
    }
    return str;
}

  public getAccountFormField() {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('ACCOUNT'),
      inputText: (this.currentSurgeryOrderActivity.accountNameString)?this.currentSurgeryOrderActivity.accountNameString+(this.currentSurgeryOrderActivity.isAccountActive?'':' (Inactive)'):'',
      placeholderLabel: (this.isReadOnlyMode && !this.currentSurgeryOrderActivity.accountId) ? this.translate.instant('NO_ACCOUNT') : this.translate.instant('ACCOUNT'),
      id:'account-field',
      isReadOnly:true,
      isDisabled:this.isReadOnlyMode,
      showArrow: !this.isReadOnlyMode,
      isRequired: !this.isReadOnlyMode,
      errorMessage: this.activityService.getErrorMessageRequiredField(),
      isEmptyRequiredField: this._isClickedAccount && !this.currentSurgeryOrderActivity.accountId ? true : false,
      formFieldType: FormFieldType.NEW_PAGE_SELECT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id,event,eventName),
    };
    this.accountFormField = viewData;
  }

  // public getLocationFormField() {
  //   let viewData: IndFormFieldViewDataModel;
  //   viewData = {
  //     label: this.translate.instant('ACTIVITY_DETAILS_LOCATION'),
  //     inputText: this.currentSurgeryOrderActivity.location ? this.currentSurgeryOrderActivity.location : '',
  //     placeholderLabel: (this.isReadOnlyMode && !this.currentSurgeryOrderActivity.location) ? this.translate.instant('NO_LOCATION') : this.translate.instant('ACTIVITY_DETAILS_LOCATION'),
  //     id:'location-field',
  //     isReadOnly:true,
  //     isDisabled:this.isReadOnlyMode,
  //     showArrow: !this.isReadOnlyMode,
  //     formFieldType: FormFieldType.NEW_PAGE_SELECT,
  //     eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id,event,eventName),
  //   };
  //   this.locationFormField = viewData;
  // }

  public getCoOwnersFormField() {
    const userNames = _.orderBy(this.currentSurgeryOrderActivity.coOwners?.filter(coOwner => coOwner['deleted'] != true )).map(coOwner => coOwner.userFullName);
    let viewData: IndFormFieldViewDataModel;
    // const isDisabled = _.isEmpty(userNames);
    viewData = {
      label: this.translate.instant('CO_OWNERS'),
      inputText: _.isEmpty(userNames) ? '' : userNames[0] + (userNames.length > 1 ? ' +' + (userNames.length - 1) : ''),
      customPlaceholderLabel: _.isEmpty(userNames) ? this.translate.instant('SELECT_CO_OWNERS') : this.translate.instant("NO_CO_OWNERS"),
      id: 'co-owner-field',
      isReadOnly: true,
      isDisabled: this.isReadOnlyMode,
      showArrow: !this.isReadOnlyMode,
      isHidden: _.isEmpty(userNames) && !this.authService.hasFeatureAction(FeatureActionsMap.PROCEDURE_LOG_CO_OWNER),
      formFieldType: FormFieldType.NEW_PAGE_SELECT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    this.coOwnersFormField = viewData;
  }

  public getSurgeryFormField() {
    let viewData: IndFormFieldViewDataModel;
    const isSurgeryDataAvailable = this.surgeryOrderDataService.productHierarchies.length > 0
    viewData = {
      label: this.translate.instant('PROCEDURE'),
      inputText: this.currentSurgeryOrderActivity.surgeryNameString,
      customPlaceholderLabel: !isSurgeryDataAvailable ? this.translate.instant('NO_PROCEDURE'):this.translate.instant('SELECT_PROCEDURE'),
      id:'surgery-field',
      isReadOnly: true,
      isDisabled: this.isReadOnlyMode || !isSurgeryDataAvailable,
      showArrow: (!this.isReadOnlyMode && isSurgeryDataAvailable),
      isRequired: !this.isReadOnlyMode,
      errorMessage: this.activityService.getErrorMessageRequiredField(),
      isEmptyRequiredField: this._isClickedSurgery && !this.currentSurgeryOrderActivity.surgeryNameString ? true : false,
      formFieldType: FormFieldType.NEW_PAGE_SELECT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id,event,eventName),
    };
    this.surgeryFormField = viewData;
  }

  public getProductCategoryFormField() {
    let viewData: IndFormFieldViewDataModel;
    const isSurgeryDataAvailable = this.surgeryOrderDataService.productHierarchies.length > 0
    viewData = {
      label: this.translate.instant('PRODUCT_CATEGORY'),
      inputText: this._currentProductCategoriesString,
      customPlaceholderLabel: !isSurgeryDataAvailable ? this.translate.instant('NO_PRODUCT_CATEGORY'):this.translate.instant('SELECT_PRODUCT_CATEGORY'),
      id:'product-category-field',
      isReadOnly: true,
      isDisabled: !isSurgeryDataAvailable || !this.currentSurgeryOrderActivity.surgeryId,
      showArrow: isSurgeryDataAvailable && !!this.currentSurgeryOrderActivity.surgeryId,
      isRequired: !this.isReadOnlyMode && isSurgeryDataAvailable && !!this.currentSurgeryOrderActivity.surgeryId,
      errorMessage: this.activityService.getErrorMessageRequiredField(),
      isEmptyRequiredField: this._isClickedProductCategory && this.currentSurgeryOrderActivity.surgeryId && !this._currentProductCategoriesString ? true : false,
      formFieldType: FormFieldType.NEW_PAGE_SELECT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id,event,eventName),
    };
    this.productCategoyFormField = viewData;
  }

  public getProductFormField() {
    let viewData: IndFormFieldViewDataModel;
    const isSurgeryDataAvailable = this.surgeryOrderDataService.productHierarchies.length > 0
    viewData = {
      label: this.translate.instant('PRODUCTS'),
      inputText: this._currentProductsString,
      customPlaceholderLabel: !isSurgeryDataAvailable ? this.translate.instant('NO_PRODUCTS'):this.translate.instant('SELECT_PRODUCTS'),
      id:'product-field',
      isReadOnly: true,
      isDisabled: this.isReadOnlyMode || !isSurgeryDataAvailable || this.currentSurgeryOrderActivity.productCategory.length==0,
      showArrow: (!this.isReadOnlyMode && isSurgeryDataAvailable && this.currentSurgeryOrderActivity.productCategory.length>0),
      isRequired: !this.isReadOnlyMode && isSurgeryDataAvailable && this.currentSurgeryOrderActivity.productCategory.length>0,
      errorMessage: this.activityService.getErrorMessageRequiredField(),
      isEmptyRequiredField: this._isClickedProduct && this.currentSurgeryOrderActivity.productCategory && this.currentSurgeryOrderActivity.productCategory.length > 0 && !this._currentProductsString ? true : false,
      formFieldType: FormFieldType.NEW_PAGE_SELECT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id,event,eventName),
    };
    this.productFormField = viewData;
  }

  private get _currentProductsString(): string {
    let str = '';
    let currentProducts = this.currentSurgeryOrderActivity.products.filter(a=> !a.isDeleted);
    // if(!this.isReadOnlyMode){
    //     currentProducts = currentProducts.filter(a=> a.statecode === 0);
    // }
    if (currentProducts) {
        if (currentProducts.length === 1) {
            str = `${currentProducts[0].productName}`;
        } else if (currentProducts.length >= 2) {
            currentProducts.sort((a, b): number => {
                if (a.productName > b.productName) return 1;
                if (a.productName < b.productName) return -1;
                return 0;
            });
            str = `${currentProducts[0].productName} +${currentProducts.length - 1}`;
        }
    }
    return str;
  }

  private get _currentProductCategoriesString(): string {
    let str = '';
    let currentProducts = this.currentSurgeryOrderActivity.productCategory;
    // if(!this.isReadOnlyMode){
    //     currentProducts = currentProducts.filter(a=> a.statecode === 0);
    // }
    if (currentProducts) {
        if (currentProducts.length === 1) {
            str = `${currentProducts[0].productCategoryName}`;
        } else if (currentProducts.length >= 2) {
            currentProducts.sort((a, b): number => {
                if (a.productCategoryName > b.productCategoryName) return 1;
                if (a.productCategoryName < b.productCategoryName) return -1;
                return 0;
            });
            str = `${currentProducts[0].productCategoryName} +${currentProducts.length - 1}`;
        }
    }
    return str;
  }

  public getSelectedProductsFormField() {
    this.selectedProductsFormFields = this.currentSurgeryOrderActivity.products.filter(prod=>!prod.isDeleted).map((product) => {
      let viewModel: IndFormFieldViewDataModel = {
          label: product.productName + ' - ' + product.productCategoryName,
          inputText: product.quantity? product.quantity + '' : '0',
          inputType: 'number',
          inputValue: product.quantity? product.quantity : 0,
          id: product.productId,
          parentID: product.productCategoryID,
          isReadOnly: false,
          isDisabled: this.backgroundUploadInProgress || this.isReadOnlyMode,
          placeholderLabel: this.translate.instant('QUANTITY'),
          showArrow: true,
          isRequired: !this.isReadOnlyMode,
          errorMessage: this.activityService.getErrorMessageRequiredField(),
          isEmptyRequiredField: product.isEmpty,
          formFieldType: FormFieldType.INLINE_INPUT,
          inputValidator:(id: string,value: any) => this._validateQuantityEntered(id,value),
          eventHandler: (id: string, event, eventName, data) => {
            if(eventName === 'input_value_confirm'){
            this.handleQuantityEntered(id, event.target, eventName, data);
            }
          }
      }
      return viewModel;
    });
  }

  private handleFormFieldEvent(id, event, eventName) {
    if (id) {
      if(this.backgroundUploadInProgress) return;
      switch (id) {
        case 'start-date-field':
          this.openStartDatePicker(event);
          break;
        case 'end-date-field':
          this.openEndDatePicker(event);
          break;
        case 'customer-field':
          this._isClickedCustomer = true;
          this.openCustomerSelect();
          break;
        case 'account-field':
          this._isClickedAccount = true;
          this.openAccountSelect();
          break;
        case 'location-field':
          //this.openLocationSelect();
          break;
        case 'surgery-field':
          this._isClickedSurgery = true;
          this._isClickedProductCategory = false;
          this._isClickedProduct = false;
          this.openSurgerySelect();
          break;
        case 'product-category-field':
          this._isClickedProductCategory = true;
          this._isClickedProduct = false;
          this.openProductCategorySelect();
          break;
        case 'product-field':
          this._isClickedProduct = true;
          this.openProductSelect();
          break;
        case 'co-owner-field':
          this.openCoOwnerSelectionPage();
          break;
        default:
          console.log('Unhandled switch case statement');
          break;
      }
    }
  }

  private async openCoOwnerSelectionPage() {
    let data = [];
    if (this.procedureTrackerDataService.users) {
      data = this.procedureTrackerDataService.users.map(user => {
        return {
          id: user.userId,
          title: user.userFullName,
          isSelected: this.currentSurgeryOrderActivity.coOwners?.some(coOwner => coOwner.userId === user.userId && coOwner['deleted'] != true)
        }
      })
    }
    if (this.currentSurgeryOrderActivity.coOwners?.some(coOwner => coOwner.userId === this.authService.user.systemUserID)) {
      data.push({
        id: this.authService.user.systemUserID,
        title: this.authService.user.displayName,
        isSelected: true,
        isReadOnly: true
      });
      data = _.orderBy(data, 'title');
    }
    const coOwnersModal = await this.modalCtrl.create({
      component: SelectListComponent,
      componentProps: {
        viewData: {
          data: data,
          isMultipleSelectionEnabled: true,
          title: this.translate.instant('USERS'),
          dividerTitle: this.translate.instant('ALL_USERS'),
          isSearchEnabled: true,
          isReadOnly: this.isReadOnlyMode
        }
      },
      backdropDismiss: false
    });
    coOwnersModal.present();
    coOwnersModal.onDidDismiss().then(async (obj) => {
      if (this.backgroundUploadInProgress) return;
      if (obj && obj.data && obj.data.isDone) {
        const data = obj.data;
        if (_.isEmpty(data.selectedItems)) {
          if (!_.isEmpty(this.currentSurgeryOrderActivity.coOwners)) {
            this.currentSurgeryOrderActivity.coOwners.forEach(coOwner => {
              coOwner['deleted'] = true;
            });
          }
        } else {
          this.currentSurgeryOrderActivity.coOwners?.forEach(coOwner => {
            const index = data.selectedItems.findIndex(item => item.id == coOwner.userId);
            if (index == -1) coOwner['deleted'] = true;
          });
          data.selectedItems.forEach(item => {
            const index = this.currentSurgeryOrderActivity.coOwners?.findIndex(coOwner => item.id == coOwner.userId);
            if (index == -1) {
              this.currentSurgeryOrderActivity.coOwners.push({
                userId: item.id,
                userFullName: item.title,
                indskr_procedurelogcoownerid: Guid.create().toString()
              })
            }else{
              this.currentSurgeryOrderActivity.coOwners[index]['deleted'] = false;
            }
          })
        }
        if (!_.isEmpty(this.currentSurgeryOrderActivity.coOwners)) {
          const action = {
            onDynamics: !this.device.isOffline,
            onLocalDatabase: true,
            onLocalCopy: true,
            operationDetail: {
              code: 'coOwnerUpdate',
              message: 'coOwnerUpdate',
            }
          };
          await this.uiService.displayLoader();
          let hasOfflineChanges = this.currentSurgeryOrderActivity.pendingPushToDynamics || false;
          this.currentSurgeryOrderActivity.pendingPushToDynamics = true;
          await this.surgeryOrderDataService.updateOrderActivity(action, [this.currentSurgeryOrderActivity], new Date().getTime(), hasOfflineChanges);
          await this.uiService.dismissLoader();
        }
        this.initAllDataModels();
      }
    })
  }

  private openCustomerSelect(): void {
    if(!this.isReadOnlyMode || this.currentSurgeryOrderActivity.customers.length !== 0) {
      const customerIds = [];
      const accountIds = [];
      if(this.currentSurgeryOrderActivity.customers){
          this.currentSurgeryOrderActivity.customers.forEach(con=> {
            customerIds.push({
                id: con.ID,
                title: con.title,
                firstName: con.firstName,
                middleName: con.middleName,
                lastName: con.lastName,
                fullname: con.fullname,
                isActive: con.isActive,
                statusCode: con.statusCode
            });
          });
      }
      if(this.currentSurgeryOrderActivity.accountId){
        accountIds.push({
          id: this.currentSurgeryOrderActivity.accountId
        });
      }
      this.trackingService.tracking('SelectCustomer', TrackingEventNames.SURGERY_ORDER);
      this.uiService.prevView = this.uiService.activeView;
      this.uiService.activeView = 'contactDetails';
      this.uiService.showNewActivity = false;
      this.contactService.accessedContactListFrom = PageName.SurgeryOrderDetailsComponent;
      if (this.isReadOnlyMode) {
        this.contactService.contactPageMode = ComponentViewMode.READONLY;
      } else {
        this.contactService.contactPageMode = ComponentViewMode.ADDNEW;
      }
      if (this.layoverViewPushedFrom && this.layoverViewPushedFrom === 'InMeetingProcedure') {
        if (!document.getElementsByClassName('modal-wrapper')[0].classList.contains('fullStretchView')) {
          document.getElementsByClassName('modal-wrapper')[0].classList.add('fullStretchView');
        }
        this.navCtrl.push(ContactPageComponent, { type: 'InMeetingProcedurePushedContactSelectionView',selectedContactIds: customerIds,
        selectedAccountIds:accountIds,
        callbackEvent: (data: any)=> this._handleContactComponentCallback(data) }, { progressAnimation: false });
        return;
      }else{
        this.navService.pushWithPageTracking(ContactPageComponent, PageName.ContactPageComponent,
          { type: 'PushedContactSelectionView',selectedContactIds: customerIds,
          selectedAccountIds:accountIds,
          callbackEvent: (data: any)=> this._handleContactComponentCallback(data) }, PageName.DynamicFormComponent, { animate: false }, null);
      }
    }
  }

  private async _handleContactComponentCallback(data) {
    if(!this.isReadOnlyMode && data && (data.selectedItems || data.clearValue)) {
        if(data.clearValue){
            data.selectedItems = [];
        }
        // To Add/Remove affiliated accounts for event
        let updateAccountsForEventFlag = false;
        let accountsToUpdate: Account[] = [];
        const alreadySelectedContacts: Contact[] = [];
        this.currentSurgeryOrderActivity.customers.forEach(ac=>{
            const foundCon = this.contactService.getContactByID(ac.ID);
            if(foundCon){
                alreadySelectedContacts.push(foundCon);
            }
        });
        //Check for Remove
        if(this.currentSurgeryOrderActivity.accountId && this.currentSurgeryOrderActivity.customers.length === 1){
            const affiliatedAccounts = await this.accountService.getAffiliatedAccountsFromSelectedContactsForMeeting(alreadySelectedContacts);
            if(affiliatedAccounts.length === 1 && affiliatedAccounts[0].id === this.currentSurgeryOrderActivity.accountId){
                // Remove the only added affiliated account
                updateAccountsForEventFlag = true;
                accountsToUpdate = [];
            }
        }
        //Check for add
        if(this.currentSurgeryOrderActivity.customers.length === 0 && data.selectedItems && data.selectedItems.length === 1 && !this.currentSurgeryOrderActivity.accountId){
            const nowSelectedContacts: Contact[] = [];
            data.selectedItems.forEach(ac=>{
                const foundCon = this.contactService.getContactByID(ac.id);
                if(foundCon){
                    nowSelectedContacts.push(foundCon);
                }
            });
            const affiliatedAccounts = await this.accountService.getAffiliatedAccountsFromSelectedContactsForMeeting(nowSelectedContacts);
            if(affiliatedAccounts.length === 1){
                // Add the only affiliated contact
                updateAccountsForEventFlag = true;
                accountsToUpdate = affiliatedAccounts;
            }
        }
        if(updateAccountsForEventFlag && accountsToUpdate[0]){
            this.currentSurgeryOrderActivity.accountId = accountsToUpdate[0].id;
            this.currentSurgeryOrderActivity.accountNameString = accountsToUpdate[0].accountName;
            this.currentSurgeryOrderActivity.isAccountActive = (accountsToUpdate[0].state === 2 || accountsToUpdate[0].state == 548910003 || accountsToUpdate[0].state == 548910010 || accountsToUpdate[0].state == 548910011 || accountsToUpdate[0].state == 548910012 || accountsToUpdate[0].state == 548910013) ? false : true;
        }

        this.currentSurgeryOrderActivity.customers = [];
        data.selectedItems.forEach(item=> {
          this.currentSurgeryOrderActivity.customers.push({
            ID: item.id,
            title: item.title,
            firstName: item.firstName,
            middleName: item.middleName,
            lastName: item.lastName,
            fullname: item.fullname,
            isActive: item.isActive,
            statusCode: item.statusCode,
          });
        });
        this.currentSurgeryOrderActivity.customers = this.currentSurgeryOrderActivity.customers.filter(c => c.isActive);
        this.currentSurgeryOrderActivity.customersNameString = this._currentCustomerString;
        let subject = this.currentSurgeryOrderActivity.subject;
        switch (this.currentSurgeryOrderActivity.customers.length) {
          case 0:
            subject = 'Procedure Log';
            break;
          case 1:
            subject = `Procedure Log - ${this.currentSurgeryOrderActivity.customers[0].fullname}`;
            break;
          default:
            if (this.currentSurgeryOrderActivity.customers.length > 1) {
              this.currentSurgeryOrderActivity.customers.sort((contactA, contactB): number => {
                let contactAFullName = (contactA.firstName.length > 0) ? contactA.firstName + " " + contactA.lastName : contactA.lastName;
                let contactBFullName = (contactB.firstName.length > 0) ? contactB.firstName + " " + contactB.lastName : contactB.lastName;
                if (contactAFullName.toLowerCase() > contactBFullName.toLowerCase()) return 1;
                if (contactAFullName.toLowerCase() < contactBFullName.toLowerCase()) return -1;
                return 0;
              });
              subject = `Procedure Log - ${this.currentSurgeryOrderActivity.customers[0].fullname} + ${this.currentSurgeryOrderActivity.customers.length - 1}`;
            }
        }
        this.currentSurgeryOrderActivity.subject = subject;
        let operationDetailCode = 'updatecustomers';
        this._updateSurgeryOrderActivity(operationDetailCode);
        if(this.from == "InMeetingProcedure" || this.isInMeetingProcedureLogFlow){
          this.events.publish('updateEmbeddedActivity', this.currentSurgeryOrderActivity);
        }
        this.initAllDataModels();
    }
}


  private openAccountSelect(): void {
    if (!this.isReadOnlyMode || this.currentSurgeryOrderActivity.accountId) {
        let accountId = '';
        const contactIds = [];
        if(this.currentSurgeryOrderActivity.accountId) accountId = this.currentSurgeryOrderActivity.accountId;
        if(this.currentSurgeryOrderActivity.customers){
            this.currentSurgeryOrderActivity.customers.forEach(cus=> {
                if(cus.ID && cus.isActive){
                  contactIds.push(cus.ID);
                }
            });
        }

        let mode: any;
        if(!this.isReadOnlyMode) mode = ComponentViewMode.ADDNEW;
        else mode = ComponentViewMode.READONLY;
        this.accountService.selectedFor = AccountSelectedFor.GENERAL_SINGLE_SELECTION;
        this.accountService.accessedAccountListFrom = PageName.SurgeryOrderDetailsComponent;
        if (this.layoverViewPushedFrom && this.layoverViewPushedFrom === 'InMeetingProcedure') {
          if (!document.getElementsByClassName('modal-wrapper')[0].classList.contains('fullStretchView')) {
            document.getElementsByClassName('modal-wrapper')[0].classList.add('fullStretchView');
          }
          this.navCtrl.push(AccountPageComponent,{ listMode: mode, type: 'InMeetingProcedurePushedAccountSelectionView',
          selectedAccountId: accountId, selectedContactIds: contactIds,
          callbackEvent: (data: any)=> this._handleAccountComponentCallback(data) }, { progressAnimation: false });
        } else {
          this.navService.pushWithPageTracking(AccountPageComponent, PageName.AccountPageComponent,
            { listMode: mode, type: 'PushedAccountSelectionView',
            selectedAccountId: accountId, selectedContactIds: contactIds,
            callbackEvent: (data: any)=> this._handleAccountComponentCallback(data) },
            PageName.SurgeryOrderDetailsComponent, { animate: false }, null);
        }
    }
  }

  private async _handleAccountComponentCallback(data) {
    if(!this.isReadOnlyMode && data && (data.selectedItem || data.clearValue)){
        if(data.clearValue){
            data.selectedItem = [];
        }
        // To Add/Remove affiliated contacts for event
        let updateContactsForEventFlag = false;
        let contactsToUpdate: Contact[] = [];
        const alreadySelectedAccounts: Account[] = [];
        const foundAcc = this.accountService.getAccountById(this.currentSurgeryOrderActivity.accountId);
        if(foundAcc) alreadySelectedAccounts.push(foundAcc);

        //Check for Remove
        if(this.currentSurgeryOrderActivity.customers.filter(a=> a.isActive).length === 1 && this.currentSurgeryOrderActivity.accountId){
            const affiliatedContacts = await this.contactService.getAffiliatedContactsFromAccountsForMeeting(alreadySelectedAccounts);
            if(affiliatedContacts.length === 1 && affiliatedContacts[0].ID === this.currentSurgeryOrderActivity.customers.filter(a=> a.isActive)[0].ID){
                // Remove the only added affiliated contact
                updateContactsForEventFlag = true;
                contactsToUpdate = [];
            }
        }
        //Check for add
        if(!this.currentSurgeryOrderActivity.accountId && data.selectedItem && this.currentSurgeryOrderActivity.customers.filter(a=> a.isActive).length === 0){
            const nowSelectedAccounts: Account[] = [];
            const foundAcc = this.accountService.getAccountById(data.selectedItem.id);
            if(foundAcc){
                nowSelectedAccounts.push(foundAcc);
            }
            const affiliatedContacts = await this.contactService.getAffiliatedContactsFromAccountsForMeeting(nowSelectedAccounts);
            if(affiliatedContacts.length === 1){
                // Add the only affiliated contact
                updateContactsForEventFlag = true;
                contactsToUpdate = affiliatedContacts;
                this.currentSurgeryOrderActivity.subject = `Procedure Log - ${affiliatedContacts[0].fullname}`;
            }
        }
        if(updateContactsForEventFlag){
          this.currentSurgeryOrderActivity.customers = [];
          contactsToUpdate.forEach(item=> {
            this.currentSurgeryOrderActivity.customers.push({
              ID: item.ID,
              title: item.title,
              firstName: item.firstName,
              middleName: item.middleName,
              lastName: item.lastName,
              fullname: item.fullname,
              isActive: item.isActive,
              statusCode: item.statusCode
            });
          });
        }

        this.currentSurgeryOrderActivity.accountId = data.selectedItem.id;
        this.currentSurgeryOrderActivity.accountNameString = data.selectedItem.name;
        this.currentSurgeryOrderActivity.isAccountActive = (data.statecode === 2 || data.statecode == 548910003 || data.statecode == 548910010 || data.statecode == 548910011 || data.statecode == 548910012 || data.statecode == 548910013)? false : true;

        let operationDetailCode = (contactsToUpdate.length == 0 && updateContactsForEventFlag) ? 'updateaccountswithaffliation' : 'updateaccounts';
        this._updateSurgeryOrderActivity(operationDetailCode);
        this.initFooterViewButtons();
        this.getCustomerFormField();
        this.getAccountFormField();
        this.getDetailsHeader();
        this.getPageTitle();
        if(this.from == "InMeetingProcedure" || this.isInMeetingProcedureLogFlow){
          this.events.publish('updateEmbeddedActivity', this.currentSurgeryOrderActivity);
        }
    }
}

  // private openLocationSelect(): void {
  //   if (!this.isReadOnlyMode || this.currentSurgeryOrderActivity.location) {
  //     let selectedAddress = '';
  //     let contactIds = [];
  //     if(this.currentSurgeryOrderActivity.location) selectedAddress = this.currentSurgeryOrderActivity.location;
  //     if(this.currentSurgeryOrderActivity.customers){
  //       this.currentSurgeryOrderActivity.customers.forEach(cus=> {
  //           if(cus.ID && cus.isActive){
  //             contactIds.push(cus.ID);
  //           }
  //       });
  //     }
  //     this.uiService.showNewActivity = false;
  //     if (this.isLayoverPushView && this.layoverViewPushedFrom) {
  //       this.navService.pushChildNavPageWithPageTracking(LocationComponent, PageName.LocationComponent, PageName.SurgeryOrderDetailsComponent,
  //                                                        { from: PageName.SurgeryOrderDetailsComponent,
  //                                                         for:'GENERAL_LOCATION_SELECTION',
  //                                                         accountIds: [this.currentSurgeryOrderActivity.accountId],
  //                                                         contactIds: contactIds,
  //                                                         selectedAddress:{id: selectedAddress, name: selectedAddress},
  //                                                         callbackEvent: (data: any)=> this._handleLocationComponentCallback(data) });
  //       // For Mobile display the user will view a seprate full view modal
  //     // } else if (this.device.isMobileDevice) {
  //     //   console.log("mobile device detected on location modal, mode is " + this.device.deviceOrientation);
  //     //   this.navService.pushWithPageTracking(LocationComponent, PageName.LocationComponent);
  //     } else {
  //       this.uiService.activeView = 'Location';
  //       this._CD.detectChanges();
  //     }
  //   }
  // }

  private async openSurgerySelect(): Promise<void> {
    if(!this.isReadOnlyMode){
      const listDetail: MainToolTemplateDetail = {
        title: this.translate.instant('PROCEDURE'),
        dividerTitle:this.translate.instant('ALL_PROCEDURE'),
        isSearchEnabled: !this.isReadOnlyMode,
        showLeftHeaderButton: true,
        leftHeaderBtnImgSrc: 'assets/imgs/header_cancel.svg',
        leftHeaderBtnText: this.translate.instant('CANCEL'),
        showRightHeaderButton: !this.isReadOnlyMode,
        rightHeaderBtnImgSrc: 'assets/imgs/header_complete.svg',
        rightHeaderBtnText: this.translate.instant('DONE'),
        orderByPropertyName: 'primaryTextRight',
        searchTitle: this.translate.instant('PROCEDURE'),
        hideAllItemsList: this.isReadOnlyMode,
        isListSelectionEnabled: !this.isReadOnlyMode,
        listSelectionType: MainToolTemplateListSelectionType.SINGLESELECTION,
        navOptions: { animate: false },
        eventsHandler: (data: any, eventTarget: string, refData: MainToolTemplateDetail)=> this._handleProductComponentEvent(data,eventTarget,refData, 'surgery'),
        //searchHandler:(text: string) => this._handleProductsComponentSearch(text, 'surgery'),
        data: this.getSurgeriesSelectionData(),
        // this.surgeryOrderDataService.productHierarchies && this.surgeryOrderDataService.productHierarchies.map(pro => {
        //   let isSelected = false;
        //   if(this.currentSurgeryOrderActivity && this.currentSurgeryOrderActivity.surgeryId && this.currentSurgeryOrderActivity.surgeryId == pro.surgeryId){
        //     isSelected = true;
        //   }
        //   return {
        //     id: pro.surgeryId,
        //     primaryTextLeft: '',
        //     secondaryTextLeft: '',
        //     showEndIcon: !this.isReadOnlyMode,
        //     mainItemCssClass: 'selector-item',
        //     isItemSelectedForSelectionView: isSelected,
        //     endIconType: isSelected?'indegene-selectors-checkmark-icon':'indegene-selectors-add-icon',
        //     endIconCssClass: isSelected?'checkmark-icon':'add-icon',
        //     primaryTextRight: pro.surgeryName,
        //     showArrow: false,
        //     arrowType: '',
        //     eventOwnerId: this.currentSurgeryOrderActivity.ownerId
        //   };
        // }),
        //data: await this.makeSurgeryData(),
      };
      if(this.isReadOnlyMode){
          this.currentSurgeryOrderActivity.products.forEach(pro=>{
              const idx = listDetail.data.findIndex(it=> it.id === pro.productId);
              if(idx<0){
                  const viewData: MainCardViewDataModel = {
                      id: pro.productId,
                      primaryTextLeft: '',
                      secondaryTextLeft: '',
                      showEndIcon: !this.isReadOnlyMode,
                      mainItemCssClass: 'selector-item',
                      isItemSelectedForSelectionView: true,
                      endIconType: 'indegene-selectors-checkmark-icon',
                      endIconCssClass: 'checkmark-icon',
                      primaryTextRight: pro.productName,
                      showArrow: false,
                      arrowType: '',
                    };
                  listDetail.data.push(viewData);
              }
          });
      }
      if (this.layoverViewPushedFrom && this.layoverViewPushedFrom === 'InMeetingProcedure') {
        if (!document.getElementsByClassName('modal-wrapper')[0].classList.contains('fullStretchView')) {
          document.getElementsByClassName('modal-wrapper')[0].classList.add('fullStretchView');
        }
      this.navCtrl.push(MainToolTemplateComponent, { viewData:listDetail, isNavWithinModalView: true, isHierarchyView: true });
      } else {
        this.navService.pushWithPageTracking(MainToolTemplateComponent,PageName.NothingSelectedView,{viewData:listDetail,isHierarchyView: true},PageName.MainToolTemplateComponent);
      }
    }
  }

  // async makeSurgeryData(): Promise<MainCardViewDataModel[]>{
  //   let cardData = [];
  //   if(this.surgeryOrderDataService.productHierarchies && this.surgeryOrderDataService.productHierarchies.length > 0){
  //     cardData = this.surgeryOrderDataService.productHierarchies.map(pro => {
  //       let isSelected = false;
  //       if(this.currentSurgeryOrderActivity && this.currentSurgeryOrderActivity.surgeryId && this.currentSurgeryOrderActivity.surgeryId == pro.surgeryId){
  //         isSelected = true;
  //       }
  //       return {
  //         id: pro.surgeryId,
  //         primaryTextLeft: '',
  //         secondaryTextLeft: '',
  //         showEndIcon: !this.isReadOnlyMode,
  //         mainItemCssClass: 'selector-item',
  //         isItemSelectedForSelectionView: isSelected,
  //         endIconType: isSelected?'indegene-selectors-checkmark-icon':'indegene-selectors-add-icon',
  //         endIconCssClass: isSelected?'checkmark-icon':'add-icon',
  //         primaryTextRight: pro.surgeryName,
  //         showArrow: false,
  //         arrowType: '',
  //         eventOwnerId: this.currentSurgeryOrderActivity.ownerId
  //       };
  //     })
  //   }

  //   if(this.currentSurgeryOrderActivity.surgeryId && !this.surgeryOrderDataService.productHierarchies.some(pro => this.currentSurgeryOrderActivity.surgeryId == pro.surgeryId)){
  //     console.log(this.currentSurgeryOrderActivity);
  //     cardData.push({
  //       id: this.currentSurgeryOrderActivity.surgeryId,
  //         primaryTextLeft: '',
  //         secondaryTextLeft: '',
  //         showEndIcon: !this.isReadOnlyMode,
  //         mainItemCssClass: 'selector-item',
  //         isItemSelectedForSelectionView: true,
  //         endIconType: 'indegene-selectors-checkmark-icon',
  //         endIconCssClass: 'checkmark-icon',
  //         primaryTextRight: this.currentSurgeryOrderActivity.surgeryNameString+'(Inactive)',
  //         showArrow: false,
  //         arrowType: '',
  //         eventOwnerId: this.currentSurgeryOrderActivity.ownerId
  //     })
  //   }

  //   return cardData;
  // }

  private openProductCategorySelect(): void {
    // if(!this.isReadOnlyMode){
      const listDetail: MainToolTemplateDetail = {
        title: this.translate.instant('PRODUCT_CATEGORY'),
        dividerTitle:this.translate.instant('ALL_PRODUCT_CATEGORY'),
        isSearchEnabled: !this.isReadOnlyMode,
        showLeftHeaderButton: true,
        leftHeaderBtnImgSrc: 'assets/imgs/header_cancel.svg',
        leftHeaderBtnText: this.translate.instant('CANCEL'),
        showRightHeaderButton: !this.isReadOnlyMode,
        rightHeaderBtnImgSrc: 'assets/imgs/header_complete.svg',
        rightHeaderBtnText: this.translate.instant('DONE'),
        orderByPropertyName: 'primaryTextRight',
        searchTitle: this.translate.instant('PRODUCT_CATEGORY'),
        hideAllItemsList: false, //this.isReadOnlyMode,
        isListSelectionEnabled: !this.isReadOnlyMode,
        listSelectionType: MainToolTemplateListSelectionType.MULTIPLESELECTION,
        navOptions: { animate: false },
        eventsHandler: (data: any, eventTarget: string, refData: MainToolTemplateDetail)=> this._handleProductComponentEvent(data,eventTarget,refData, 'productcategory'),
        searchHandler:(text: string) => this._handleProductsComponentSearch(text,'productcategory'),
        data: this.surgeryOrderDataService.productHierarchies.find(p=>p.surgeryId==this.currentSurgeryOrderActivity.surgeryId).productCategories.map(pro => {
          let isSelected = false;
          if(this.currentSurgeryOrderActivity && this.currentSurgeryOrderActivity.productCategory && this.currentSurgeryOrderActivity.productCategory.some(sopro => (sopro.productCategoryId === pro.productCategoryId))){
            isSelected = true;
          }
          return {
            id: pro.productCategoryId,
            primaryTextLeft: '',
            secondaryTextLeft: '',
            showEndIcon: !this.isReadOnlyMode,
            mainItemCssClass: 'selector-item',
            isItemSelectedForSelectionView: isSelected,
            endIconType: isSelected?'indegene-selectors-checkmark-icon':'indegene-selectors-add-icon',
            endIconCssClass: isSelected?'checkmark-icon':'add-icon',
            primaryTextRight: pro.productCategoryName,
            showArrow: false,
            arrowType: '',
            eventOwnerId: this.currentSurgeryOrderActivity.ownerId
          };
        }),
      };
      // if(this.isReadOnlyMode){
      //     this.currentSurgeryOrderActivity.products.forEach(pro=>{
      //         const idx = listDetail.data.findIndex(it=> it.id === pro.productId);
      //         if(idx<0){
      //             const viewData: MainCardViewDataModel = {
      //                 id: pro.productId,
      //                 primaryTextLeft: '',
      //                 secondaryTextLeft: '',
      //                 showEndIcon: !this.isReadOnlyMode,
      //                 mainItemCssClass: 'selector-item',
      //                 isItemSelectedForSelectionView: true,
      //                 endIconType: 'indegene-selectors-checkmark-icon',
      //                 endIconCssClass: 'checkmark-icon',
      //                 primaryTextRight: pro.productName,
      //                 showArrow: false,
      //                 arrowType: '',
      //               };
      //             listDetail.data.push(viewData);
      //         }
      //     });
      // }
      if (this.layoverViewPushedFrom && this.layoverViewPushedFrom === 'InMeetingProcedure') {
        if (!document.getElementsByClassName('modal-wrapper')[0].classList.contains('fullStretchView')) {
          document.getElementsByClassName('modal-wrapper')[0].classList.add('fullStretchView');
        }
        this.navCtrl.push(MainToolTemplateComponent, { viewData:listDetail, isNavWithinModalView: true});
      } else {
        this.navService.pushWithPageTracking(MainToolTemplateComponent,PageName.NothingSelectedView,{viewData:listDetail},PageName.MainToolTemplateComponent);
      }
    // }
  }

  private openProductSelect(): void {
    if(!this.isReadOnlyMode){
      let selectedProdCategories = this.surgeryOrderDataService.productHierarchies
                                      .find(p=>p.surgeryId==this.currentSurgeryOrderActivity.surgeryId)
                                      .productCategories.filter(c=>this.currentSurgeryOrderActivity.productCategory.some(o=>o.productCategoryId==c.productCategoryId))
      const listDetail: MainToolTemplateDetail = {
        title: this.translate.instant('PRODUCTS'),
        dividerTitle:this.translate.instant('ALL_PRODUCTS_CAP'),
        isSearchEnabled: !this.isReadOnlyMode,
        showLeftHeaderButton: true,
        leftHeaderBtnImgSrc: 'assets/imgs/header_cancel.svg',
        leftHeaderBtnText: this.translate.instant('CANCEL'),
        showRightHeaderButton: !this.isReadOnlyMode,
        rightHeaderBtnImgSrc: 'assets/imgs/header_complete.svg',
        rightHeaderBtnText: this.translate.instant('DONE'),
        orderByPropertyName: 'primaryTextRight',
        searchTitle: this.translate.instant('SEARCH_PRODUCTS'),
        hideAllItemsList: this.isReadOnlyMode,
        isListSelectionEnabled: !this.isReadOnlyMode,
        listSelectionType: MainToolTemplateListSelectionType.MULTIPLESELECTION,
        navOptions: { animate: false },
        eventsHandler: (data: any, eventTarget: string, refData: MainToolTemplateDetail)=> this._handleProductComponentEvent(data,eventTarget,refData, 'product'),
        searchHandler:(text: string) => this._handleProductsComponentSearch(text,'product'),
        data: this.getProductsSelectionData(selectedProdCategories)
      };
      if(this.isReadOnlyMode){
          this.currentSurgeryOrderActivity.products.forEach(pro=>{
              const idx = listDetail.data.findIndex(it=> it.id === pro.productId);
              if(idx<0){
                  const viewData: MainCardViewDataModel = {
                      id: pro.productId,
                      primaryTextLeft: '',
                      secondaryTextLeft: '',
                      showEndIcon: !this.isReadOnlyMode,
                      mainItemCssClass: 'selector-item',
                      isItemSelectedForSelectionView: true,
                      endIconType: 'indegene-selectors-checkmark-icon',
                      endIconCssClass: 'checkmark-icon',
                      primaryTextRight: pro.productName,
                      showArrow: false,
                      arrowType: '',
                    };
                  listDetail.data.push(viewData);
              }
          });
      }
      if (this.layoverViewPushedFrom && this.layoverViewPushedFrom === 'InMeetingProcedure') {
        if (!document.getElementsByClassName('modal-wrapper')[0].classList.contains('fullStretchView')) {
          document.getElementsByClassName('modal-wrapper')[0].classList.add('fullStretchView');
        }
        this.navCtrl.push(MainToolTemplateComponent, { viewData:listDetail, isNavWithinModalView: true, isGroupedView:true});
      } else {
        this.navService.pushWithPageTracking(MainToolTemplateComponent,PageName.NothingSelectedView,{viewData:listDetail, isGroupedView:true},PageName.MainToolTemplateComponent);
      }
    }
  }

  private _handleProductsComponentSearch(text: string, formField:string): string[] {
    let ids: Array<string> = [];
    switch (formField){
      case 'surgery':
        if (text.length >= 1) {
          ids = this.surgeryOrderDataService.productHierarchies && this.surgeryOrderDataService.productHierarchies.filter(pro => {
              return pro.surgeryName.trim().toLowerCase().includes(text.trim().toLowerCase());
          }).map(pro => pro.surgeryId);
        } else {
            ids = this.surgeryOrderDataService.productHierarchies && this.surgeryOrderDataService.productHierarchies.map(pro => pro.surgeryId);
        }
        break;
      case 'productcategory':
        if (text.length >= 1) {
          ids = this.surgeryOrderDataService.productHierarchies
                && this.surgeryOrderDataService.productHierarchies.find(p=>p.surgeryId==this.currentSurgeryOrderActivity.surgeryId).productCategories.filter(pro => {
              return pro.productCategoryName.trim().toLowerCase().includes(text.trim().toLowerCase());
          }).map(pro => pro.productCategoryId);
        } else {
            ids = this.surgeryOrderDataService.productHierarchies.find(p=>p.surgeryId==this.currentSurgeryOrderActivity.surgeryId).productCategories.map(pro => pro.productCategoryId);
        }
        break;
      case 'product':
        let selectedProdCategories = this.surgeryOrderDataService.productHierarchies
                                        .find(p=>p.surgeryId==this.currentSurgeryOrderActivity.surgeryId)
                                        .productCategories.filter(c=>this.currentSurgeryOrderActivity.productCategory.some(o=>o.productCategoryId==c.productCategoryId))
        let selectedProdCategoriesProducts = [];
        selectedProdCategories.forEach(pc=>{
          selectedProdCategoriesProducts = selectedProdCategoriesProducts.concat(pc.products);
        })
        if (text.length >= 1) {
          ids = selectedProdCategoriesProducts.filter(product => {
              return product.productName.trim().toLowerCase().includes(text.trim().toLowerCase());
          }).map(prod => prod.productId);
        }
        break;
    }
    return ids;
}

  private _handleProductComponentEvent(data: any, eventTarget: string, refData: MainToolTemplateDetail, fieldName:string) {
    if (eventTarget && eventTarget === 'RightHeaderButtonClick') {
        if (this.currentSurgeryOrderActivity.surgeryOrderStatusString !== 'Completed' && data && data.isDone) {
            let operationDetailCode: string;
            if (data.selectedItems && Array.isArray(data.selectedItems) && data.selectedItems.length > 0) {
                switch (fieldName){
                  case 'surgery':
                    let surgeryProd = this.surgeryOrderDataService.productHierarchies.find(p=>p.surgeryId == data.selectedItems[0].id)
                    if(surgeryProd && this.currentSurgeryOrderActivity.surgeryId !== surgeryProd.surgeryId){
                      this.currentSurgeryOrderActivity.surgeryId = surgeryProd.surgeryId;
                      this.currentSurgeryOrderActivity.surgeryNameString = surgeryProd.surgeryName;
                      this.currentSurgeryOrderActivity.productCategory = [];
                      this.currentSurgeryOrderActivity.products = [];
                      operationDetailCode = 'updatesurgery';
                      this._updateSurgeryOrderActivity(operationDetailCode);
                    }
                    break;
                  case 'productcategory':
                    this.currentSurgeryOrderActivity.productCategory = [];
                    let applicableProducts = [];
                    data.selectedItems.forEach(item => {
                      let category = this.surgeryOrderDataService.productHierarchies.find(p=>p.surgeryId == this.currentSurgeryOrderActivity.surgeryId).productCategories
                                              .find(c=>c.productCategoryId == item.id)
                      if(category){
                        this.currentSurgeryOrderActivity.productCategory.push({
                          productCategoryId:category.productCategoryId,
                          productCategoryName:category.productCategoryName
                        })
                        applicableProducts = applicableProducts.concat(category.products);
                      }
                    });
                    _.remove(this.currentSurgeryOrderActivity.products,(prod)=>{
                      return !applicableProducts.some(o=>o.productId == prod.productId && o.productCategoryId == prod.productCategoryID);
                    });
                    operationDetailCode = 'updateproductcategory';
                    this._updateSurgeryOrderActivity(operationDetailCode);
                    break;
                  case 'product':
                    let selectedProdCategories = this.surgeryOrderDataService.productHierarchies
                                        .find(p=>p.surgeryId==this.currentSurgeryOrderActivity.surgeryId)
                                        .productCategories.filter(c=>this.currentSurgeryOrderActivity.productCategory.some(o=>o.productCategoryId==c.productCategoryId))
                    let selectedProdCategoriesProducts = [];
                    selectedProdCategories.forEach(pc=>{
                      selectedProdCategoriesProducts = selectedProdCategoriesProducts.concat(pc.products);
                    })
                    let alreadySelectedProducts = this.currentSurgeryOrderActivity.products;
                    this.currentSurgeryOrderActivity.products = [];
                    data.selectedItems.forEach(item => {
                      let foundProduct = selectedProdCategoriesProducts.find(prod => prod.productId == item.id && prod.productCategoryId == item.parentID);
                      if (foundProduct) {
                        let alreadySelectedProd = alreadySelectedProducts.find(p=>p.productId==item.id && p.productCategoryID == item.parentID);
                        if(alreadySelectedProd){
                          this.currentSurgeryOrderActivity.products.push(alreadySelectedProd);
                        }
                        else{
                          this.currentSurgeryOrderActivity.products.push({
                            productId: foundProduct.productId,
                            productName: foundProduct.productName,
                            quantity: 1,
                            uomid: foundProduct.uomid,
                            isDeleted: false,
                            productCategoryID: foundProduct.productCategoryId,
                            productCategoryName: foundProduct.productCategoryName
                          });
                        }
                      }
                    });
                    this.getPageTitle();
                    this.getDetailsHeader();
                    this.initFooterViewButtons();
                    operationDetailCode = 'updateproducts';
                    this._updateSurgeryOrderActivity(operationDetailCode);
                }

            }
            else if(data.selectedItems.length ==0){
              switch (fieldName){
                case 'surgery':
                  this.currentSurgeryOrderActivity.surgeryId = undefined;
                  this.currentSurgeryOrderActivity.surgeryNameString = '';
                  this.currentSurgeryOrderActivity.productCategory = [];
                  this.currentSurgeryOrderActivity.products = [];
                  operationDetailCode = 'updatesurgery';
                  this._updateSurgeryOrderActivity(operationDetailCode);
                  break;
                case 'productcategory':
                  this.currentSurgeryOrderActivity.productCategory = [];
                  this.currentSurgeryOrderActivity.products = [];
                  operationDetailCode = 'updateproductcategory';
                  this._updateSurgeryOrderActivity(operationDetailCode);
                  break;
                case 'product':
                  this.currentSurgeryOrderActivity.products = [];
                  this.getPageTitle();
                  this.getDetailsHeader();
                  operationDetailCode = 'updateproducts';
                  this._updateSurgeryOrderActivity(operationDetailCode);
                  break;
              }
            }
            this.initAllDataModels();
        }
    }
  }

  getProductsSelectionData(selectedProdCategories){
    let data = [];
    selectedProdCategories.forEach(pro => {
      data.push({
        id: pro.productCategoryId,
        primaryTextLeft: '',
        secondaryTextLeft: '',
        showEndIcon: false,
        mainItemCssClass: 'selector-item',
        isItemSelectedForSelectionView: false,
        primaryTextRight: pro.productCategoryName,
        showArrow: false,
        eventOwnerId: this.currentSurgeryOrderActivity.ownerId
      });
      pro.products.forEach(prod=>{
        let isSelected = false;
        if(this.currentSurgeryOrderActivity && this.currentSurgeryOrderActivity.products
          && this.currentSurgeryOrderActivity.products.some(sopro => (sopro.productId === prod.productId && sopro.productCategoryID === prod.productCategoryId))){
          isSelected = true;
        }
        data.push({
          id: prod.productId,
          parentID: prod.productCategoryId,
          primaryTextLeft: '',
          secondaryTextLeft: '',
          showEndIcon: !this.isReadOnlyMode,
          mainItemCssClass: 'selector-item',
          isItemSelectedForSelectionView: isSelected,
          endIconType: isSelected?'indegene-selectors-checkmark-icon':'indegene-selectors-add-icon',
          endIconCssClass: isSelected?'checkmark-icon':'add-icon',
          primaryTextRight: prod.productName,
          showArrow: false,
          arrowType: '',
          eventOwnerId: this.currentSurgeryOrderActivity.ownerId
        });
      })
    })
    return data;
  }

  getSurgeriesSelectionData() {
    let data: Array<MainCardViewDataModel> = [];
    let masterList = _.cloneDeep(this.surgeryOrderDataService.productHierarchies); 
    masterList = masterList.sort((a, b): number => {
      if (a.surgeryName > b.surgeryName) return 1;
      if (a.surgeryName < b.surgeryName) return -1;
      return 0;
    });
    for (let i = 0; i < masterList.length; i++) {
      const item = masterList[i];
      let isSelected = item.surgeryId == this.currentSurgeryOrderActivity.surgeryId;
      let obj = {
        id: item.surgeryId,
        primaryTextLeft: '',
        secondaryTextLeft: '',
        showEndIcon: !this.isReadOnlyMode,
        mainItemCssClass: 'selector-item',
        isItemSelectedForSelectionView: isSelected,
        endIconType: isSelected ? 'indegene-selectors-checkmark-icon' : 'indegene-selectors-add-icon',
        endIconCssClass: isSelected ? 'checkmark-icon' : 'add-icon',
        primaryTextRight: item.surgeryName,
        showArrow: false,
        childItems: [],
        eventOwnerId: this.currentSurgeryOrderActivity.ownerId
      };

      if (item.subProducts) {
        for (let j = 0; j < item.subProducts.length; j++) {
          const subProduct = item.subProducts[j];
          let childSubProduct: any = getProductFromList(subProduct.productId, item.surgeryId,this.currentSurgeryOrderActivity.surgeryId,this.isReadOnlyMode,this.currentSurgeryOrderActivity.ownerId);
          if (childSubProduct) {
            obj.childItems.push(childSubProduct);
          }
        }
      }
      data.push(obj);
    }

    function getProductFromList(toBeAddedProductId: string, parentID: string,selectedId:string,isReadOnlyMode:boolean,ownerId:string) {
      const foundSurgeryIdx = masterList.findIndex(a => a.surgeryId == toBeAddedProductId);
      let foundSurgery = masterList[foundSurgeryIdx];
      let prodIdxInTree = data.findIndex(p => p.id == toBeAddedProductId);
      if (prodIdxInTree >= 0) {
        let tobeaddedproduct = data[prodIdxInTree];
        tobeaddedproduct.parentId = parentID;
        data.splice(prodIdxInTree, 1);
        return tobeaddedproduct;
      }
      if (foundSurgery) {
        let isSelected = foundSurgery.surgeryId == selectedId;
        let obj = {
          id: foundSurgery.surgeryId,
          parentID: parentID,
          primaryTextLeft: '',
          secondaryTextLeft: '',
          showEndIcon: !isReadOnlyMode,
          mainItemCssClass: 'selector-item',
          isItemSelectedForSelectionView: isSelected,
          endIconType: isSelected ? 'indegene-selectors-checkmark-icon' : 'indegene-selectors-add-icon',
          endIconCssClass: isSelected ? 'checkmark-icon' : 'add-icon',
          primaryTextRight: foundSurgery.surgeryName,
          showArrow: false,
          childItems: [],
          arrowType: '',
          eventOwnerId: ownerId,
        };
        let subProducts = foundSurgery.subProducts;
        masterList.splice(foundSurgeryIdx, 1);
        if (subProducts) {
          for (let j = 0; j < foundSurgery.subProducts.length; j++) {
            const subProduct = foundSurgery.subProducts[j];
            let childSubProduct: any = getProductFromList(subProduct.productId, foundSurgery.surgeryId,selectedId,isReadOnlyMode,ownerId);
            if (childSubProduct) {
              obj.childItems.push(childSubProduct);
            }
          }
        }
        return obj;
      } else {
        return null;
      }
    }

    // this.surgeryOrderDataService.productHierarchies.forEach(surgery => {

    //   let isSelected = surgery.surgeryId == this.currentSurgeryOrderActivity.surgeryId;
    //   let obj = {
    //     id: surgery.surgeryId,
    //     primaryTextLeft: '',
    //     secondaryTextLeft: '',
    //     showEndIcon: !this.isReadOnlyMode,
    //     mainItemCssClass: 'selector-item',
    //     isItemSelectedForSelectionView: isSelected,
    //     endIconType: isSelected ? 'indegene-selectors-checkmark-icon' : 'indegene-selectors-add-icon',
    //     endIconCssClass: isSelected ? 'checkmark-icon' : 'add-icon',
    //     primaryTextRight: surgery.surgeryName,
    //     showArrow: false,
    //     childItems: [],
    //     eventOwnerId: this.currentSurgeryOrderActivity.ownerId
    //   };

    //   isSelected = false;
    //   if(surgery.subProducts){
    //     surgery.subProducts.forEach(subProduct => {
    //       let idx = masterList.findIndex(a => a.surgeryId == subProduct.productId);
    //       isSelected = masterList[idx].surgeryId == this.currentSurgeryOrderActivity.surgeryId;
    //       if (idx >= 0) {
    //         obj.childItems.push({
    //           id: masterList[idx].surgeryId,
    //           parentID: masterList[idx].surgeryId,
    //           primaryTextLeft: '',
    //           secondaryTextLeft: '',
    //           showEndIcon: !this.isReadOnlyMode,
    //           mainItemCssClass: 'selector-item',
    //           isItemSelectedForSelectionView: isSelected,
    //           endIconType: isSelected ? 'indegene-selectors-checkmark-icon' : 'indegene-selectors-add-icon',
    //           endIconCssClass: isSelected ? 'checkmark-icon' : 'add-icon',
    //           primaryTextRight: masterList[idx].surgeryName,
    //           showArrow: false,
    //           childItems: [],
    //           arrowType: '',
    //           eventOwnerId: this.currentSurgeryOrderActivity.ownerId
    //         });
    //       }
    //     });
    //   }else{
    //     obj.childItems = [];
    //   }


    //   data.push(obj);
    // });

    if (this.currentSurgeryOrderActivity.surgeryId && !this.surgeryOrderDataService.productHierarchies.some(pro => this.currentSurgeryOrderActivity.surgeryId == pro.surgeryId)) {
      console.log(this.currentSurgeryOrderActivity);
      data.push({
        id: this.currentSurgeryOrderActivity.surgeryId,
        primaryTextLeft: '',
        secondaryTextLeft: '',
        showEndIcon: !this.isReadOnlyMode,
        mainItemCssClass: 'selector-item',
        isItemSelectedForSelectionView: true,
        endIconType: 'indegene-selectors-checkmark-icon',
        endIconCssClass: 'checkmark-icon',
        primaryTextRight: this.currentSurgeryOrderActivity.surgeryNameString + '(Inactive)',
        showArrow: false,
        arrowType: '',
        eventOwnerId: this.currentSurgeryOrderActivity.ownerId
      })
    }
    return data;
  }


  private _handleLocationComponentCallback(data){
    this.currentSurgeryOrderActivity.location = data;
    let operationDetailCode = 'updatelocation';
    this._updateSurgeryOrderActivity(operationDetailCode);
    //this.getLocationFormField();
    this.initFooterViewButtons();
  }

  private _validateQuantityEntered(id, value): boolean {
    try {
      value = parseInt(value);
    } catch (error) {

    }
    if (value === null || value === undefined || isNaN(value) || value < 1) {
      value = 1;
    }
    return value >=1;
  }

  public async handleQuantityEntered(id, event, target, data){
    if (event) {
      const product = this.currentSurgeryOrderActivity.products.find(p=>p.productId===id && p.productCategoryID == data.parentID);
      if (parseInt(event.value) && parseInt(event.value) > 0){
        product.quantity = parseInt(event.value);
        product.isEmpty = false;
      } else {
        if (product.quantity == 0) {
          product.isEmpty = true;
        }
      }
    }
    this._CD.detectChanges();
    this.initFooterViewButtons();
    this.getPageTitle();
    this.getDetailsHeader();
    let operationDetailCode = 'updatequantities';
    this._updateSurgeryOrderActivity(operationDetailCode);
    this.getSelectedProductsFormField();
  }

  public handleButtonClick(buttonId: string): void {
    let popupTitle = '';
    let popupMessage = '';
    let popupAction = '';
    if (buttonId != 'close' && this.backgroundUploadInProgress) return;
    switch (buttonId) {
      case 'close':
        this.onClosePage();
        return;
      case 'scrapsurgeryorderactivity': {
        if (!this.isScrapButtonEnabled) return;
        // if (this.layoverViewPushedFrom === 'OrderManagementTool') {
        //   this.trackingService.tracking('OrderManagementScrapOrder', TrackingEventNames.ORDERMANAGEMENT);
        // } else if (this.layoverViewPushedFrom === 'AgendaView') {
        //   this.trackingService.tracking('ScrapOrder', TrackingEventNames.ORDERMANAGEMENT);
        // }
        popupTitle = this.translate.instant('OM_SCRAP_LOG');
        popupMessage = this.translate.instant('R_U_SURE_SCRAP_SO');
        popupAction = this.translate.instant('SCRAP');
        break;
      }
      case 'markcomplete': {
        if (!this.isCompleteButtonEnabled) return;
        popupTitle = this.translate.instant('ACTIVITY_COMPLETE_LOG');
        popupMessage = this.translate.instant('OM_ARE_SURE_COMPLETE_PL');
        popupAction = this.translate.instant('COMPLETE');
        break;
      }
      case 'tagassets': {
        this._handleAddAssets();
        return;
      }
      case 'save_note': {
        this.handleSaveNote();
        return;
      }
      case 'reopensurgeryactivity' : {
        if (!this.isReopenButtonEnabled) return;
        popupTitle = this.translate.instant('REOPEN_PROCDURE_LOG');
        popupMessage = this.translate.instant('OM_ARE_SURE_REOPEN_PL');
        popupAction = this.translate.instant('REOPEN');
        break;
      }
      default:
        return;
    }
    if (this.currentSurgeryOrderActivity) {
      this.alertService.showAlert({
        title: popupTitle,
        message: popupMessage}, popupAction
      ).then (res => {
        if(res.role === 'ok') {
          this.handleButtonEventForSurgeryOrderActivityAfterConfirmation(buttonId);
        }
      });
    }
  }

  private _handleAddAssets() {
    const assets = [];
    if (this.currentSurgeryOrderActivity.assets.length) {
      this.currentSurgeryOrderActivity.assets.forEach(asset => {
        let customerAsset = this.fieldMaterialManagementService.customerAssets.find(e => e.msdyn_customerassetid == asset.msdyn_customerassetid);
        if(asset) {
          assets.push(customerAsset);
        } else {
          assets.push(new CustomerAsset(asset));
        }
      })
    }
    

    const opportuntiesSelectionViewData: AssetSelectionViewDataModel = {
      viewMode: !this.isScrapButtonEnabled || this.backgroundUploadInProgress ? ComponentViewMode.READONLY : ComponentViewMode.SELECTION,
      selectedAssets: assets,
      callbackFromSelectionComponent: (event: string, selectedAssets: CustomerAsset[], data: any) => this._handledAssetsSelection(event, selectedAssets, data),
    };
    this.navService.pushWithPageTracking(FieldMaterialManagementPageComponent, PageName.FieldMaterialManagementPageComponent, { viewData: opportuntiesSelectionViewData, from: 'procedure_log' }, PageName.FieldMaterialManagementPageComponent);
  }

  private async isAssetAlreadyInUse(selectedAssetIds: string[]): Promise<any> {
    let startDate = this.currentSurgeryOrderActivity.scheduledStart.getTime();
    let endDate = this.currentSurgeryOrderActivity.scheduledEnd.getTime();

    if (this.device.isOffline) {
      // * local check
      let uniqueAssetIds = [];
      let assetsAlreadyInUse = [];
      let procedureLogWithAssets = this.activityService.getProcedureActivties(startDate, endDate).filter((item) => item.assets.length > 0 && item.ID !== this.currentSurgeryOrderActivity.ID);
      procedureLogWithAssets.forEach(item => {
        uniqueAssetIds = [...uniqueAssetIds, ...item.assets.map((a) => a.msdyn_customerassetid)]
      })
      uniqueAssetIds = _.uniq(uniqueAssetIds);
      assetsAlreadyInUse = selectedAssetIds.filter(assetIds => uniqueAssetIds.includes(assetIds) );
      return assetsAlreadyInUse;
    } else {
      // * online
      const assetInUseIds = await this.surgeryOrderDataService.validateAssetInUse(selectedAssetIds, startDate, endDate, this.currentSurgeryOrderActivity.ID);
      return assetInUseIds;
    }
  }
  

  private async _handledAssetsSelection(event, selectedAssets: CustomerAsset[], data: any) {
    if (event && event == 'DONEBUTTONCLICK') {

      let prevSelectedAssets = [];
      if (this.currentSurgeryOrderActivity.assets.length) {
        this.currentSurgeryOrderActivity.assets.forEach(asset => {
          let foundAsset = this.fieldMaterialManagementService.getAssetById(asset.msdyn_customerassetid);
          if (foundAsset) {
            prevSelectedAssets.push(foundAsset);
          } else {
            prevSelectedAssets.push(new CustomerAsset(asset));
          }
        })
      }


      const selectedAssetIds = selectedAssets.map((selectedAsset) => selectedAsset.msdyn_customerassetid).filter((selectedAssetsId) => !prevSelectedAssets.find(e => e.msdyn_customerassetid == selectedAssetsId));
      if(selectedAssetIds.length){
        const assetInUseIds = await this.isAssetAlreadyInUse(selectedAssetIds);
        if(assetInUseIds.length > 0){
          this.notifications.notify(this.translate.instant('ASSET_IN_USE'), 'Order Details', 'top', ToastStyle.INFO);
          selectedAssets = selectedAssets.filter((selectedAsset) => !assetInUseIds.includes(selectedAsset.msdyn_customerassetid));
        }
      }

      selectedAssets.forEach(a => {
        let removedAsset = prevSelectedAssets.find(e => e.msdyn_customerassetid == a.msdyn_customerassetid);
        if (!removedAsset) {
          let surgeryAsset = {
            msdyn_customerassetid: a.msdyn_customerassetid,
            msdyn_name: a.msdyn_name,
            salesorderid: this.currentSurgeryOrderActivity.ID,
            ownerid: this.authService.user.xSystemUserID,
            ownerName: this.authService.user.displayName,
            createdon: new Date().getTime().toString()
          }
          this.currentSurgeryOrderActivity.assets.push(surgeryAsset);
        } else {
          let index = this.currentSurgeryOrderActivity.assets.findIndex(e => e.msdyn_customerassetid == removedAsset.msdyn_customerassetid);
          if (index >= 0) {
            this.currentSurgeryOrderActivity.assets.splice(index, 1);
          }
        }
      });
      this.currentSurgeryOrderActivity.assets = _.uniqBy(this.currentSurgeryOrderActivity.assets, 'msdyn_customerassetid');
      this.updateSurgeryAssets();
      // const action = {
      //   onDynamics: !this.device.isOffline,
      //   onLocalDatabase: true,
      //   onLocalCopy: true,
      //   operationDetail: {
      //     code: 'assetsUpdate',
      //     message: 'assetsUpdate',
      //   }
      // };
      // await this.uiService.displayLoader();
      // let hasOfflineChanges = this.currentSurgeryOrderActivity.pendingPushToDynamics || false;
      // this.currentSurgeryOrderActivity.pendingPushToDynamics = true;
      // await this.surgeryOrderDataService.updateOrderActivity(action, [this.currentSurgeryOrderActivity], new Date().getTime(), hasOfflineChanges);
      // await this.uiService.dismissLoader();
    }
  }

  async updateSurgeryAssets(){
    const action = {
      onDynamics: !this.device.isOffline,
      onLocalDatabase: true,
      onLocalCopy: true,
      operationDetail: {
        code: 'assetsUpdate',
        message: 'assetsUpdate',
      }
    };
    await this.uiService.displayLoader();
    let hasOfflineChanges = this.currentSurgeryOrderActivity.pendingPushToDynamics || false;
    this.currentSurgeryOrderActivity.pendingPushToDynamics = true;
    await this.surgeryOrderDataService.updateOrderActivity(action, [this.currentSurgeryOrderActivity], new Date().getTime(), hasOfflineChanges);
    await this.uiService.dismissLoader();
  }

  public onSectionHeaderControlClick(id: string){
    this.handleButtonClick(id);
  }

  private async handleButtonEventForSurgeryOrderActivityAfterConfirmation(buttonId: string) {
    if (this.backgroundUploadInProgress) return;
    const action = {
      onDynamics: !this.device.isOffline,
      onLocalDatabase: true,
      onLocalCopy: true,
      operationDetail: {
        code: buttonId,
        message: this.translate.instant('OM_UPDATE_O_ACTIVITY'),
      }
    };
    const loader = await this.loadingController.create({});
    loader.present();
    this.currentSurgeryOrderActivity.pendingPushToDynamics = true;
    this.surgeryOrderDataService.updateOrderActivityStatus(action,this.currentSurgeryOrderActivity).then((succ)=>{
      switch (buttonId) {
        case 'scrapsurgeryorderactivity': {
          //Check from where activity is deleted
          //this.onClosePage();
          //this.hardDisableFooterButtonsCheck = true;
          //this.events.publish('updateOrdersList');
          this.activityService.publishActivityEvent({action: "Delete", activity: this.currentSurgeryOrderActivity});
          const isLayovered = (this.layoverViewPushedFrom === 'InMeetingProcedure');
          this.events.publish('surgeryOrderActivityDeleted', this.currentSurgeryOrderActivity, isLayovered);
          if(this.layoverViewPushedFrom === 'InMeetingProcedure') {
            this.events.publish('deletedEmbeddedActivity', this.currentSurgeryOrderActivity);
            this.isInMeetingProcedureLogFlow = true;
          }
          if(this.isInMeetingProcedureLogFlow){
            this.onClosePage();
          }else{
            if (this.navService.getActiveChildNavName() === ChildNavNames.ActivitiesPageNavigation) {
              this.onClosePage();
              this.activityService.selectedActivity = null;
              this.activityService.selectedActivityAtHome = null;
              this.events.publish('refreshAgenda');
            } else if (this.navService.getActiveChildNavName() === ChildNavNames.ContactPageNavigation) {
              this.onClosePage();
            }else if (this.navService.getActiveChildNavName() === ChildNavNames.AccountPageNavigation) {
              this.onClosePage();
            }
            else{
              this.uiService.agendaRefreshRequired = true;
            }
            this.uiService.showRightPane = false;
          }
          break;
        }
        case 'markcomplete': {
          this.currentSurgeryOrderActivity.setYearMonthWeek();
          if (this.navService.getActiveChildNavName() === ChildNavNames.ActivitiesPageNavigation) {
            this.events.publish('refreshAgenda');
          }
          else{
            this.uiService.agendaRefreshRequired = true;
          }
          this.initAllDataModels();
          this.events.publish('updateEmbeddedActivity', this.currentSurgeryOrderActivity);
          this.events.publish('surgeryOrderActivityCompleted', this.currentSurgeryOrderActivity);
          this.initSurveyData();
          break;
        }
        case 'reopensurgeryactivity' : {
          if(this.layoverViewPushedFrom === 'InMeetingProcedure' || this.layoverViewPushedFrom === 'PresentationMeetingComponent') {
            this.events.publish('updateEmbeddedActivity', this.currentSurgeryOrderActivity);
            this.initAllDataModels();
          }else if(this.layoverViewPushedFrom && (this.layoverViewPushedFrom === 'AccountTimeline' || this.layoverViewPushedFrom === 'ContactTimeline')){
            this.initAllDataModels();
          }
          this.initSurveyData();
          break;
        }
        default:
          break;
      }
      loader.dismiss();

    }).catch(async err => {
      //Handle error scenario
      if (err && err.errorCode && err.errorCode === 'ONLINEONLYUPDATEFAILED') {
        this.notifications.notify(this.translate.instant('OM_WAS_A_PROBLEM_CONTACT_ADMINISTRATOR'), 'Order Details', 'top', ToastStyle.DANGER);
        // if (err.operationCode && (err.operationCode === 'approveorderactivity' || err.operationCode === 'notapproveorderactivity' || err.operationCode === 'sendorderactivityforreview' || err.operationCode === 'cancelorderactivityreview' || err.operationCode === 'autoapprovalflow' || err.operationCode === 'fulfillorderactivity' || err.operationCode === 'reopenorderactivity')) {
        //   // Fetch realtime order details
        //   await this.activityDataService.updateActivityDetails(this.currentOrderActivity);
        // }
      }
      switch (buttonId) {
        case 'scrapsurgeryorderactivity': {
          //Check from where activity is deleted
          break;
        }
        case 'markcomplete': {
          this.currentSurgeryOrderActivity.state = 0;
          this.currentSurgeryOrderActivity.status = 1;
          break;
        }
        case 'reopensurgeryactivity' :{
          this.currentSurgeryOrderActivity.state = 1;
          this.currentSurgeryOrderActivity.status = 548910001;
          break;
        }
      }
      loader.dismiss();
    });
  }

  private onClosePage() {
    const currentPage: PageName = this.navService.getCurrentPageName();
    this.surgeryOrderDataService.isInMeetingProcedureLogFlow = false;
    if (this.isLayoverPushView) {
      if (this.layoverViewPushedFrom === 'AccountTimeline') {
        this.navService.popChildNavPageWithPageTracking();
        this.footerService.initButtons(FooterViews.Contacts);
        this.activityService.selectedActivity = null;
        return;
      }else if(this.layoverViewPushedFrom === 'ContactTimeline') {
        this.activityService.selectedActivity = null;
        this.uiService.activeView = 'contactDetails';
        this.footerService.initButtons(FooterViews.Contacts);
        this.navService.popChildNavPageWithPageTracking();
        return;
      }else if(this.layoverViewPushedFrom === 'InMeetingProcedure') {
        this.modalCtrl.dismiss();
        if(currentPage === PageName.ActivitiesPageComponent) {
          this.uiService.activeView = 'ActivitiesPageRightPaneNav';
          this.events.publish("initTimePickerLimitedMinTime");
          this.footerService.initButtons(FooterViews.Activities);
        }
        this.surgeryOrderDataService.inMeetingSurgeryOrderActivity = null;
        return;
      }else if(this.layoverViewPushedFrom === 'PresentationMeetingComponent'){
        this.uiService.activeView = 'Meeting';
        this.events.publish('deletedEmbeddedActivity', this.currentSurgeryOrderActivity);
        this.events.publish("initTimePickerLimitedMinTime");
        this.footerService.initButtons(FooterViews.PreviewMeeting);
        this.surgeryOrderDataService.inMeetingSurgeryOrderActivity = null;
      } else if (this.layoverViewPushedFrom === 'ProcedureLogFromContactTool') {
        this.uiService.activeView = 'contactDetails';
        this.footerService.initButtons(FooterViews.Contacts);
        this.navService.popChildNavPageWithPageTracking();
        return;
      }else if (this.layoverViewPushedFrom === 'ProcedureLogFromAccountTool') {
        this.uiService.activeView = 'accountDetails';
        this.footerService.initButtons(FooterViews.Accounts);
        this.navService.popChildNavPageWithPageTracking();
        return;
      }
    }
    if (this.device.shouldBeMobileView) {
      if (this.agendaFooterService.actSegment === "week") {
        this.activityService.prevSelectedActivity = this.activityService.selectedActivity;
        this.activityService.selectedActivity = undefined;
        this.uiService.showRightPane = false;
        this.uiService.activeView = '';
        this.activityService.shouldScrollToPrevSelectedActivity = !this.activityService.didOpenDetailPageFromAgendaPage;
        this.activityService.didOpenDetailPageFromAgendaPage = false;
        // this.events.publish('weekview:RefreshUI');
        return;
      } else if (this.agendaFooterService.actSegment === "agenda") {
        if (this.uiService.activitiesPagePlanTabSelectedPlan === 'planTabTodo') {
          this.activityService.selectedActivity = undefined;
        }
      }
    }
    this.navService.setChildNavRoot(NothingSelectedView,PageName.NothingSelectedView,PageName.NothingSelectedView).then(()=>{
      this.navService.setChildNavRightPaneView(false);
      this.uiService.updateNothingSelectedScreenMessageFor(1);
      this.uiService.showRightPane = false;
    });
  }

  private async _updateSurgeryOrderActivity(operationDetailCode:string){
    let action: OperationDetail = {
      onDynamics: !this.device.isOffline,
      onLocalDatabase: true,
      onLocalCopy: true,
      operationDetail: {
        code: operationDetailCode,
        message: this.translate.instant('DELTA_SYNC_ORDERS_UPDATE')
      }
    };
    const newLastUpdatedTime = new Date().getTime();
    let hasOfflineChanges = this.currentSurgeryOrderActivity.pendingPushToDynamics || false;
    this.currentSurgeryOrderActivity.pendingPushToDynamics = true;
    if(!this.device.isOffline){
      this.uiService.displayLoader();
    }
    await this.surgeryOrderDataService.updateOrderActivity(action,[this.currentSurgeryOrderActivity],newLastUpdatedTime, hasOfflineChanges).then((succ)=>{
      this.getStatusFormField();
    }).catch((err)=>{
      this.getStatusFormField();
    })
    if(operationDetailCode == 'updatesurgery'){
      this._surveyTemplateLoaded = false;
      await this._initProcedureLogSurveyJsAttributes();
    }else if(operationDetailCode == 'updateSurveyResponse'){
      this._surveyTemplateLoaded = false;
    }
    if(!this.device.isOffline){
      this.uiService.dismissLoader();
    }
  }

  private initProcedureNotesModel(){
    this.notesHeaderModel = {
      id: 'task-notes-header',
      title: this.translate.instant('NOTES'),
      controls: [
        {
          id:'save_note',
          text: this.translate.instant('SAVE'),
          isDisabled: this.backgroundUploadInProgress ? true : (!this.isSaveNotesEnabled || this.isReadOnlyMode),
          isVisible: !this.isReadOnlyMode,
        }
      ]
    };
    this.notesPlaceholder = this.translate.instant('ENTER_NOTES');
  }

  private async handleSaveNote(){
    if (this.isSaveNotesEnabled && !this.backgroundUploadInProgress) {
      this.isSaveNotesEnabled = false;
      this.currentSurgeryOrderActivity.procedureNotes.push(new IONote({
            annotationid: "offline_"+new Date().getTime().toString(),
            notetext: this.tempNoteText ? this.tempNoteText : "",
            ownerid: this.authService.user.systemUserID,
            ownerName: this.authService.user.displayName,
            createdon: new Date().getTime().toString(),
            deleted: false,
      }))
      let operationDetailCode = 'updatenotes';
      await this._updateSurgeryOrderActivity(operationDetailCode);
      this._sortProcedureNotes();
      this.tempNoteText = '';
      this.isSaveNotesEnabled = false;
      this.initProcedureNotesModel();
    }
  }

  async openStartDatePicker(myEvent) {
    this.activityService.dateTimePickerType = DateTimeFieldType.StartDateField;
    let currEventStr = this.noProcedureDay ? 'NO_PROCEDURE_START_END_TIME_SELECTION' : 'GENERAL_START_AND_END_TIME_SELECTION';
    if (!this.isReadOnlyMode) {
      if (this.currentSurgeryOrderActivity.surgeryOrderStatusString !== 'Completed') {
        let popover = await this.popoverCtrl.create(
          {
            component: IndDatetimeFormComponent,
            componentProps: {
              currentViewPage: CurViewPageType.SurgeryOrder,
              currentEvent: currEventStr,
              startDateTimeValue: this.currentSurgeryOrderActivity.scheduledStart,
              endDateTimeValue: this.currentSurgeryOrderActivity.scheduledEnd
            },
            cssClass: "datetime-popover"
          }
        );
        popover.onDidDismiss().then(async (data: any) => {
          if (data !== null && !_.isEmpty(data.data) && data.data.startTime !='') {
            this.startTime = data.data.startTime;
            this.endTime = data.data.endTime;
            this.currentSurgeryOrderActivity.scheduledStart = new Date(this.startTime);
            this.currentSurgeryOrderActivity.scheduledEnd = new Date(this.endTime);
            this.currentSurgeryOrderActivity.resetScheduledStartLocale();
            this._updateDateTimeValue();

            if(this.currentSurgeryOrderActivity.assets){
              const selectedAssetIds = this.currentSurgeryOrderActivity.assets.map((item) => item.msdyn_customerassetid);
              const assetInUseIds = await this.isAssetAlreadyInUse(selectedAssetIds);
              if(assetInUseIds.length > 0){
                this.notifications.notify(this.translate.instant('ASSET_IN_USE'), 'Order Details', 'top', ToastStyle.INFO);
                this.currentSurgeryOrderActivity.assets = this.currentSurgeryOrderActivity.assets.filter((selectedAsset) => !assetInUseIds.includes(selectedAsset.msdyn_customerassetid));
                this.updateSurgeryAssets();
              }
            }
          }
        });
        popover.present();
      }
    }
  }

  private _sortProcedureNotes(){
    if(this.currentSurgeryOrderActivity.procedureNotes && this.currentSurgeryOrderActivity.procedureNotes.length > 1){
      this.currentSurgeryOrderActivity.procedureNotes = this.currentSurgeryOrderActivity.procedureNotes.sort((a,b)=>{
        return (isBefore(a.createdTime, b.createdTime) ? 1 : -1);
      });
    }
  }

  public notesChanged(ev): void {
    if(ev && ev.target){
      this.tempNoteText = ev.target.value;
    }else{
      this.tempNoteText = '';
    }
    if(this.tempNoteText.length > 0){
      this.isSaveNotesEnabled = true;
    }else{
      this.isSaveNotesEnabled = false;
    }
    this.initProcedureNotesModel();
  }

  public isNoteControlsEnabled(note: IONote): boolean {
    return !!(note.ownerId == this.authService.user.systemUserID) && note.noteId != 'description-note' && !this.isReadOnlyMode && !this.backgroundUploadInProgress;
  }

  public async updateNote(ev) {
    if(this.backgroundUploadInProgress) return;
    if (ev && ev.action) {
      let idx = this.currentSurgeryOrderActivity.procedureNotes.findIndex(note => note.noteId == ev.noteId);
      if (ev.action == 'DELETE' && idx >= 0) {
        this.currentSurgeryOrderActivity.procedureNotes[idx].isDeleted = true;
      } else if (ev.action == 'SAVE' && idx >= 0) {
        this.currentSurgeryOrderActivity.procedureNotes[idx].noteText = ev.updatedText;
        this.currentSurgeryOrderActivity.procedureNotes[idx].updated = true;
      }
      // Update Service
      let operationDetailCode = 'updatenotes';
      await this._updateSurgeryOrderActivity(operationDetailCode);
      this._sortProcedureNotes();
    }
  }

  async openEndDatePicker(myEvent) {
    this.activityService.dateTimePickerType = DateTimeFieldType.EndDateField;
    if (!this.isReadOnlyMode) {
      if (this.currentSurgeryOrderActivity.surgeryOrderStatusString !== 'Completed') {
        let popover = await this.popoverCtrl.create(
          {
            component: IndDatetimeFormComponent,
            componentProps: {
              currentViewPage: CurViewPageType.SurgeryOrder,
              currentEvent: 'GENERAL_START_AND_END_TIME_SELECTION',
              startDateTimeValue: this.currentSurgeryOrderActivity.scheduledStart,
              endDateTimeValue: this.currentSurgeryOrderActivity.scheduledEnd
            },
            cssClass: "datetime-popover"
          }
        );
        popover.onDidDismiss().then((data: any) => {
          if (data !== null && !_.isEmpty(data.data) && data.data.endTime !='') {
            this.startTime = data.data.startTime;
            this.endTime = data.data.endTime;
            this.currentSurgeryOrderActivity.scheduledStart = new Date(this.startTime);
            this.currentSurgeryOrderActivity.scheduledEnd = new Date(this.endTime);
            this.currentSurgeryOrderActivity.resetScheduledStartLocale();
            this._updateDateTimeValue();
          }
        });
        popover.present();
      }
    }
  }

  private handleStartDateTimeValue() {
    let m = moment(this.startTime);
    if (!m.isValid()) {
      this.notifications.notify(this.translate.instant('NEW_ACTIVITY_TOAST_INVALID_DATE'), 'New Activity', 'top', ToastStyle.DANGER);
      this.startTime = this.prevStartTime;
    } else {
      this.currentSurgeryOrderActivity.scheduledStart = new Date(this.startTime);
      this.endTime = format(addMinutes(this.startTime, 30));
      this.currentSurgeryOrderActivity.scheduledEnd = new Date(this.endTime);
      this.prevStartTime = this.startTime;
      this._updateDateTimeValue();
    }
  }

  private handleEndDateTimeValue() {
    let m = moment(this.endTime);
    if (!m.isValid()) {
      this.notifications.notify(this.translate.instant('NEW_ACTIVITY_TOAST_INVALID_DATE'), 'New Activity', 'top', ToastStyle.DANGER);
      this.endTime = this.prevEndTime;
    } else {
      this.prevEndTime = this.endTime;
      this.currentSurgeryOrderActivity.scheduledEnd = new Date(this.endTime);
      this._updateDateTimeValue();
    }
  }

  private _updateDateTimeValue() {
    this._updateSurgeryOrderActivity('updatetime');
    this.getPageTitle();
    this.getDetailsHeader();
    this.initFooterViewButtons();
    this.initDateTimeFormFields();
    this._CD.detectChanges();
    if (this.navService.getActiveChildNavName() === ChildNavNames.ActivitiesPageNavigation) {
      this.events.publish('refreshAgenda');
    }
    this.events.publish('surgeryOrderActivityTimeIsUpdated', this.currentSurgeryOrderActivity);
  }

  // Validate the Products against the retired Bundled Products

  private _updateMandatoryField(field: PageName) {
    if (!this.noProcedureDay) {
      if (field == PageName.ContactPageComponent) {
        this.getCustomerFormField();
      } else if (field == PageName.AccountPageComponent) {
        this.getAccountFormField();
      } else if (field == PageName.MainToolTemplateComponent) {
        this.getSurgeryFormField();
        this.getProductCategoryFormField();
        this.getProductFormField();
      }
    }
  }

  private async _initProcedureLogSurveyJsAttributes(hardFetch:boolean = false) {
    if (this.currentSurgeryOrderActivity.surgeryId && !this.device.isOffline && (!this._surveyJsAttributesFetched || hardFetch)) {
      const selectedSurgery = this.surgeryOrderDataService.productHierarchies.find(a => a.surgeryId == this.currentSurgeryOrderActivity.surgeryId);
      if (selectedSurgery && selectedSurgery.surveyTemplateId) {
        this._hasTagAssetsSurveyJS = selectedSurgery.hasTagAssets;
        const foundTemplate = this.surveyService.surveyForms.find(form => form.surveyFormId == selectedSurgery.surveyTemplateId);
        if (foundTemplate) {
          this.uiService.displayLoader();
          await this.surgeryOrderDataService.fetchSurveyJSAtrributesForProcedure(this.currentSurgeryOrderActivity, foundTemplate).then(() => {
            this.uiService.dismissLoader();
          });
          this._surveyJsAttributesFetched = true;
        }
      }
    }
    this.initSurveyData();
  }

  private initSurveyData() {
    if (this.currentSurgeryOrderActivity.surgeryId) {
      const selectedSurgery = this.surgeryOrderDataService.productHierarchies.find(a => a.surgeryId == this.currentSurgeryOrderActivity.surgeryId);
      if (selectedSurgery && selectedSurgery.surveyTemplateId) {
        this._hasTagAssetsSurveyJS = selectedSurgery.hasTagAssets;
        // Load Survey Response Data
        this.loadSurveyResults();
        this.loadSurveyTemplate();
        this.isSurveyConfigured = true;
        this.surveyService.surveyActiveFor = 'ProcedureLog';
        this.procedureSurveyHeaderModel = {
          id: 'survey-detail-header',
          title: this.translate.instant('ADDITIONAL_INFORMATION'),
          controls: []
        }
      }else{
        this.isSurveyConfigured = false;
      }
    }else{
      this.isSurveyConfigured = false;
    }
  }

  private loadSurveyResults() {
    if (this.currentSurgeryOrderActivity.surveyResponseData) {
      if (!_.isEqual(this.currentSurgeryOrderActivity.surveyResponseData, this.surveyService.surveyStaticResults)) {
        this.surveyService.surveyStaticResults = this.currentSurgeryOrderActivity.surveyResponseData;
      }
    } else {
      this.surveyService.surveyStaticResults = '';
    }
    if (this.currentSurgeryOrderActivity.surveyResponseLookupData && this.currentSurgeryOrderActivity.surveyResponseLookupData.length > 0) {
      if (!_.isEqual(this.currentSurgeryOrderActivity.surveyResponseLookupData, this.surveyLookupResults)) {
        this.surveyService.surveyLookupResults = this.surveyLookupResults = this.currentSurgeryOrderActivity.surveyResponseLookupData;
      }
    } else {
      if (this.surveyLookupResults.length != 0) {
        this.surveyService.surveyLookupResults = this.surveyLookupResults = [];
      }
    }
  }

  private loadSurveyTemplate() {
    if(!this._surveyTemplateLoaded){
      const selectedSurgery = this.surgeryOrderDataService.productHierarchies.find(a => a.surgeryId == this.currentSurgeryOrderActivity.surgeryId);
    let form = this.surveyService.surveyForms.find(form => form.surveyFormId == selectedSurgery.surveyTemplateId);
    if (form) {
      this.surveyTemplate = _.cloneDeep(form.surveyConfiguration);
      try {
        if (this.isReadOnlyMode) {
          this.surveyTemplate.pages[0].elements.forEach(ele => {
            ele.readOnly = true;
          });
        }
      } catch (error) {
        console.log('Error while marking fields readonly' + error);
      }
    }
    this.events.publish('loadSurveyTemplate', this.surveyTemplate);
    this._surveyTemplateLoaded = true;
    }
    
    if (this.surveyTemplate) this.validateSurveyForReview(this.surveyService.surveyStaticResults);
  }

  private validateSurveyForReview(surveyData) {
    this.isSurveyValid = true;
    this.surveyTemplate.pages[0].elements.forEach((question) => {
      if (question.isRequired) {
        this.isSurveyValid = this.isSurveyValid && surveyData.hasOwnProperty(question.name) ? true : false;
      }
    })
  }

  public async updateSurveyResult(surveyResponse) {
    this.currentSurgeryOrderActivity.surveyResponse = surveyResponse;
  }

  public async updateSurveyData(surveyData) {
    for (var propt in surveyData) {
      if (this.currentSurgeryOrderActivity.surveyResponseData && this.currentSurgeryOrderActivity.surveyResponseData.hasOwnProperty(propt)) {
        if (this.currentSurgeryOrderActivity.surveyResponseData[propt] != surveyData[propt]) {
          this.updateSurveyQuestion({ name: propt, value: surveyData[propt] });
        }
      } else {
        this.updateSurveyQuestion({ name: propt, value: surveyData[propt] });
      }
    }
    this.surveyService.surveyStaticResults = this.currentSurgeryOrderActivity.surveyResponseData = surveyData;
    this.validateSurveyForReview(surveyData);
    this.initSurveyData();
    this.updateFooterViewButtons();
  }

  public async updateSurveyQuestion(question) {
    try {
      if (question && question.name && this.surveyTemplate && this.surveyTemplate.pages && this.surveyTemplate.pages[0]) {
        const element = this.surveyTemplate.pages[0].elements.find(ele => ele.name == question.name);
        if (element && element.type && element.metadata) {
          if (element.type == "lookup" && question.value) {

            let idx;
            if (!this.currentSurgeryOrderActivity.surveyResponseDTO.hasOwnProperty('lookupfields')) {
              this.currentSurgeryOrderActivity.surveyResponseDTO['lookupfields'] = [];
            }
            idx = this.currentSurgeryOrderActivity.surveyResponseDTO['lookupfields'].findIndex(a => a.name == element.metadata.logicalName);
            if (idx >= 0) {
              if (question.value != this.currentSurgeryOrderActivity.surveyResponseDTO['lookupfields'][idx].id) {
                this.currentSurgeryOrderActivity.surveyResponseDTO['lookupfields'][idx] = {
                  name: element.metadata.logicalName,
                  entity: element.metadata.target.setName,
                  id: question.value,
                };
                this._updateSurgeryOrderActivity('updateSurveyResponse');
              }
            } else {
              this.currentSurgeryOrderActivity.surveyResponseDTO['lookupfields'].push({
                name: element.metadata.logicalName,
                entity: element.metadata.target.setName,
                id: question.value,
              });
              this._updateSurgeryOrderActivity('updateSurveyResponse');
            }
          } else {
            if (!this.currentSurgeryOrderActivity.surveyResponseDTO || (question.value != this.currentSurgeryOrderActivity.surveyResponseDTO[element.metadata.logicalName])) {
              this.currentSurgeryOrderActivity.surveyResponseDTO[element.metadata.logicalName] = question.value;
              this._updateSurgeryOrderActivity('updateSurveyResponse')
            }
          }
        }
      }
    } catch (error) {

    }

  }

}
