import { Component, ViewChild, Input, ChangeDetectorRef } from '@angular/core';
import { ScientificActivityService } from '../../../../services/scientific-activity/scientific-activity.service';
import { UIService, ComponentViewMode } from '../../../../services/ui/ui.service';
import { ScientificActivityPlan } from '../../../../classes/scientific-activity/scientific-activity.class';
import { TrackService, TrackingEventNames } from '../../../../services/logging/tracking.service';
import { NavigationService, PageName, ChildNavNames } from '../../../../services/navigation/navigation.service';
import { NewActivityComponent } from '../../new-activity/new-activity';
import { ContactDetailsComponent } from '../../../contact/contact-details/contact-details';
import { ContactOfflineService } from '../../../../services/contact/contact.service';
import { LoadingController } from '@ionic/angular';
import { RepServices } from '../../../../data-services/rep/rep.services';
import { DeviceService } from '../../../../services/device/device.service';
import { ActivityService } from '../../../../services/activity/activity.service';
import { ActivitiesDetailsPaneComponent } from '../../activities-details-pane/activities-details-pane';
import { ScientificPlanFollowUpTask } from '../scientific-plan-task/scientific-plan-task';
import { EmailActivityDetailComponent } from '../../email-activity-detail/email-activity-detail';
import { EmailService } from '../../../../services/email-templates/email.service';
import { EmailViewType, EmailActivity } from '../../../../classes/activity/email.activity.class';
import { ActivityDataService } from '../../../../data-services/activity/activity.service';
import { Contact } from '../../../../classes/contact/contact.class';
import { FooterViews, FooterService } from '../../../../services/footer/footer.service';
import { Activity } from '../../../../classes/activity/activity.class';
import {GlobalUtilityService} from "../../../../services/global-utility.service";
import { EventsService } from '../../../../services/events/events.service';
import { IndTabsDataModel } from '@omni/models/ind-tabs-data-model';
import { TranslateService } from '@ngx-translate/core';


@Component({
    selector: 'scientific-activity-detail',
    templateUrl: 'scientific-activity-detail.html',
    styleUrls:['scientific-activity-detail.scss']
})
export class ScientificActivityDetail {

    //@ViewChild(Content, {static:true}) content:Content;

    public scientificActivityDetailsSegment = 'info';
    @Input() selectedSAP: ScientificActivityPlan;
    // public showBackButton:boolean = false;
    public showCustomersTab:boolean = true;
    @Input() selectedContactId: string = '';

    @Input() from: string = null;
    tabsData: IndTabsDataModel[];
    public pageTitleControls = [];
    constructor(
        public sapService: ScientificActivityService,
        public uiService: UIService,
        private trackingService:TrackService,
        private navService:NavigationService,
        public contactService:ContactOfflineService,
        public repService:RepServices,
        private deviceService:DeviceService,
        private activityService:ActivityService,
        private activityDataService:ActivityDataService,
        private emailService:EmailService,
        public footerService:FooterService,
        public utilityService:GlobalUtilityService,
        private loadCtrl:LoadingController,
        private events: EventsService,
        private translate: TranslateService,
        private _cd: ChangeDetectorRef,
    ) {
    }


    ngOnInit() {
        // this.showBackButton = (this.navService.getActiveChildNavName() === ChildNavNames.ContactPageNavigation) || this.deviceService.isMobileDevicePortrait;
        this.showCustomersTab = (this.navService.getActiveChildNavName() !== ChildNavNames.ContactPageNavigation);
        if(this.showCustomersTab){
            this.contactService.contactInformation = undefined;
            this.uiService.contactDetailsSegment = undefined; //set selected contact to undefine when component not form contact profile
        }
        //this.content.resize();
        this.setTabsData();
        this.initPageTitleControls();

        this.events.unsubscribe('refresh-sci-callplan-activity');
        this.events.subscribe('refresh-sci-callplan-activity', () => {
          setTimeout(() => {
            this._cd.detectChanges();
          }, 100);
        });
    }

    ngOnDestroy() {
      this.events.unsubscribe('refresh-sci-callplan-activity');
    }

    segmentChanged(selectedTab: string) {
        if(selectedTab == "info") {
            this.trackingService.tracking('ScientificPlanInfo', TrackingEventNames.SCIENTIFICACTIVITYPLAN);
        } else if(selectedTab == "customer") {
            this.trackingService.tracking('ScientificPlanCustomers', TrackingEventNames.SCIENTIFICACTIVITYPLAN);
        } else if(selectedTab == "timeline") {
            this.trackingService.tracking('ScientificPlanTimeline', TrackingEventNames.SCIENTIFICACTIVITYPLAN);
        }
        this.scientificActivityDetailsSegment = selectedTab;
        if(this.uiService.activeView !=='ContactPageRightPaneNav') this.uiService.contactDetailsSegment = undefined;
    }

    poppage() {
        if(this.showBackButton()){
            this.uiService.showNewActivity = false;
            if(this.from === 'ContactTools') {
              this.navService.popChildNavPageWithPageTracking();
              this.uiService.showRightPane = true;
            }
        }
        if(this.uiService.activeView ==='ActivitiesPageRightPaneNav' && this.uiService.activitiesPagePlanTabSelectedPlan === 'scientificPlans') {
          this.navService.popToRootWithPageTracking();
          this.uiService.showRightPane = false;
        } else {
          this.sapService.isRightPaneNavActive = false;
        }
    }

    public handlePlusButton(): void {
        this.trackingService.tracking('ScientificPlanNewActivity', TrackingEventNames.SCIENTIFICACTIVITYPLAN);
        this.navService.pushChildNavPageWithPageTracking(NewActivityComponent, PageName.NewActivityComponent, PageName.ScientificActivityPage, { from: 'ScientificActivityPage', plan: this.selectedSAP })
    }

    public handleContactPlusButton(): void {
        // this.trackingService.tracking('ScientificPlanNewActivity', TrackingEventNames.SCIENTIFICACTIVITYPLAN);
        this.navService.pushChildNavPageWithPageTracking(NewActivityComponent, PageName.NewActivityComponent, PageName.ScientificActivityPage, { from: 'ScientificActivityPage', plan: this.selectedSAP })
    }

    openContactDetails(contact: Contact){
        this.contactService.contactInformation = contact;
        if(this.deviceService.isOffline) {
            this.contactService.contactInformation = this.contactService.contacts.find(x => x.ID == contact.ID);
        }
        this.uiService.contactDetailsSegment = 'info';
        this.contactService.contactPageMode = ComponentViewMode.LISTVIEW;
        this.navService.pushChildNavPageWithPageTracking(ContactDetailsComponent, PageName.ScientificActivityDetail, PageName.ScientificActivityPage,{contactListMode :ComponentViewMode.LISTVIEW, selectedSP : this.selectedSAP});
    }

    async onTimeLineEventClick(event){

        this.uiService.showNewActivity = false;
        const loading = await this.loadCtrl.create();

        if(event.type && event.type === "Appointment") {
            await loading.present();
            this.activityDataService.activityDetailsLoaded = true;
            this.activityService.selectedActivity = event;
            await this.activityDataService.updateActivityDetails(event as Activity);

            this.footerService.initButtons(FooterViews.Activities);
            await loading.dismiss();
            await this.navService.pushChildNavPageWithPageTracking(ActivitiesDetailsPaneComponent, PageName.ActivitiesDetailsPaneComponent, PageName.ScientificActivityPage, {from:'ScientificActivityPage'});
            this.events.publish("detectChangesOnActivityDetails");
        } else if(event.type && event.type === "followuptask") {
            this.activityService.selectedActivity = event;
            this.navService.pushChildNavPageWithPageTracking(ScientificPlanFollowUpTask, PageName.ScientificPlanFollowUpTask, PageName.ScientificActivityPage,{ from: 'ScientificActivityPage', activity: event });
        } else if(event.type && event.type === "Email") {
            await loading.present();
            this.activityService.selectedActivity = event;
            this.emailService.setCurrentEmail(this.activityService.selectedActivity);
            this.emailService.selectedActivity = <EmailActivity>this.activityService.selectedActivity;
            this.emailService.viewType = EmailViewType.FROM_SCIENTIFIC_PLAN;
            await this.navService.pushChildNavPageWithPageTracking(EmailActivityDetailComponent, PageName.EmailDetailsPageComponent, PageName.ScientificActivityPage,{ modalView: {}});
            setTimeout(() => {
              loading.dismiss();
            }, 500);
        }

    }

    onOpenFollowupTask(event){
        if(this.from == 'ContactTools') {
            this.navService.pushChildNavPageWithPageTracking(ScientificPlanFollowUpTask, PageName.ScientificPlanFollowUpTask, PageName.ScientificActivityPage,{ from: this.from, activity: event });
        } else if (this.navService.getActiveChildNavName() === ChildNavNames.ActivitiesPageNavigation && this.uiService.activitiesPageTab === 'plans' ) {
            this.navService.pushChildNavPageWithPageTracking(ScientificPlanFollowUpTask, PageName.ScientificPlanFollowUpTask, PageName.ActivitiesPageComponent,{ from: 'ScientificActivityPage', activity: event });
        } else {
            this.navService.pushChildNavPageWithPageTracking(ScientificPlanFollowUpTask, PageName.ScientificPlanFollowUpTask, PageName.ScientificActivityPage,{ from: 'ScientificActivityPage', activity: event });
        }

    }

    showBackButton() {
        return (this.navService.getActiveChildNavName() === ChildNavNames.ContactPageNavigation) || this.deviceService.isMobileDevicePortrait;
    }

    setTabsData() {
        this.tabsData = [
                            {
                                displayText: this.translate.instant('INFO'),
                                value: "info"
                            },
                            {
                                displayText: this.utilityService.globalCustomersText,
                                value: "customer",
                                hide: !this.showCustomersTab
                            },
                            {
                                displayText: this.translate.instant('TIMELINE'),
                                value: "timeline",
                            },
                        ];
    }

    private initPageTitleControls(): void {
      let buttons = [];
      if (this.showBackButton()) {
        buttons.push({
          id: "close",
          icon: "chevron-back-outline",
          isDisabled: false,
          align: "left"
        });
      }
      buttons.push({
        id: "pluse-icon",
        imgSrc: 'assets/imgs/ios_add_3x.svg',
        name: this.translate.instant('CREATE'),
        isDisabled: false,
        align: "right"
      })
      this.pageTitleControls = buttons
    }
    public onPageTitleControlClick(id: string) {
      switch (id) {
        case 'close':
          this.poppage();
          break;
        case 'pluse-icon':
          this.handlePlusButton();
          break;
        default:
          break;
      }
    }
}
