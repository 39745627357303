import { UserConfig } from './../../classes/authentication/user-config.class';
import { CRMInstance } from './../../classes/authentication/instance.class';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { InAppBrowser, InAppBrowserObject } from '@ionic-native/in-app-browser/ngx';
import { AuthenticationContext, AuthenticationResult, MSAdal, TokenCache } from '@ionic-native/ms-adal/ngx';
import {
  SecureStorage,
  SecureStorageObject
} from '@ionic-native/secure-storage/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import {
  AlertController,
  IonNav,
  LoadingController,
  NavController,
  NavParams,
  PopoverController
} from '@ionic/angular';
import { AppInsightsService } from '@markpieszak/ng-application-insights';
import { TranslateService } from '@ngx-translate/core';
import { Events } from '@omni/events';
import { isFuture } from 'date-fns';
import { forkJoin ,  Observable ,  pipe, Subscription } from 'rxjs';
import { AuthenticateUserRequest } from '../../classes/authentication/authenticate-user.request';
import { AuthenticationUserResponse } from '../../classes/authentication/authenticate-user.response';
import { FeatureActionsMap, User } from '../../classes/authentication/user.class';
import { Domain } from '../../classes/domain/domain.class';
import { AboutDetailsComponent } from '../../components/app-settings/about-page/about-details/about-details';
import { Endpoints } from '../../../config/endpoints.config';
import {
  AuthenticationDataService,
  OAuthTokenRequest
} from '../../data-services/authentication/authentication.service';
import { DomainDataService } from '../../data-services/domain/domain.service';
import { RepServices } from '../../data-services/rep/rep.services';
import { AlertService } from '../../services/alert/alert.service';
import { SettingsService } from '../../services/app-settings/settings.service';
import { AuthenticationService } from '../../services/authentication.service';
import { decrypt } from '../../services/crypto';
import { DeviceService } from '../../services/device/device.service';
import { DiskService } from '../../services/disk/disk.service';
import { LogService } from '../../services/logging/log-service';
import { NavigationService, PageName } from '../../services/navigation/navigation.service';
import { NotificationService, ToastStyle } from '../../services/notification/notification.service';
import { HomePage } from '../home/home';
import { InstanceSelectionComponent } from './../../components/instance-selection/instance-selection';
// import { LaunchDarklyProvider } from '@omni/providers/launch-darkly/launch-darkly';
import { GlobalUtilityService } from '@omni/services/global-utility.service';
import { CordovaPlugin } from '@omni/services/cordova';
import { FeatureActionsService } from '@omni/services/feature-actions/feature-actions.service';
import { UIService } from '@omni/services/ui/ui.service';


/**
 * Generated class for the LoginPage page.
 *
 * See https://ionicframework.com/docs/components/#navigation for more info on
 * Ionic pages and navigation.
 */

@Component({
  selector: 'page-login',
  templateUrl: 'login.html',
  styleUrls:['login.scss']
})
export class LoginPage implements OnInit {
  private _cordovaHelpers = new CordovaPlugin('CordovaHelpers');

  loginErrorMessage: string;
  public displayLoginError: boolean;
  selectedInstance: CRMInstance;

  @ViewChild('username', {static: true})
  usernameInput: ElementRef;
  public usernameText: string;
  public passwordText: string;

  public rememberPassword: boolean;
  public rememberEmail: boolean;
  private readonly rememberMe: string;
  public rememberMeCheckbox: any;
  private didJustLogoutWithOfflineStateAndRemeberMe = false;

  private readonly _redirectedSuccessfully = false;

  public loginStage: number;

  private readonly HTTP_ERR_500 = 500;
  private readonly HTTP_ERR_401 = 401;
  private readonly HTTP_ERR_400 = 400;
  private readonly HTTP_ERR_403 = 403;
  private readonly HTTP_NO_INTERNET = 0;

  private readonly INVALID_CREDENTIALS = 'ERR_IO_INVALID_INPUT_401';
  private INVALID_CREDENTIALS_MESSAGE: any;
  private readonly PWD_EXPIRED = 'ERR_IO_PWD_EXPIRED_400';
  private PWD_EXPIRED_MESSAGE: any;
  private readonly REFRESH_TOKEN_EXPIRED = 'ERR_IO_REFRESH_TOKEN_EXPIRED_400';
  private REFRESH_TOKEN_EXPIRED_MESSAGE: any;
  private readonly INVALID_EMAIL = 'ERR_IO_TNF_403';
  private INVALID_EMAIL_MESSAGE: any;
  private readonly ACCOUNT_LOCKED = 'ERR_IO_ACCOUNT_LOCKED_400';
  private ACCOUNT_LOCKED_MESSAGE: any;
  private NO_INSTANCES_MESSAGE: any;
  private OFFLINE_ERROR: any;

  private NO_DATABASE_OFFLINE_ERROR: any;
  _redirectInterval: number;
  private readonly browserRef: InAppBrowserObject;
  recognizer: any;
  private readonly popoverMessage: string;
  clientId: any;
  realmId: string;
  disableLoginButton = false;
  deviceNetworkServiceReadySubscription: Subscription;
  public region: string = "Global";

  constructor(
    public navCtrl: IonNav,
    private readonly domainService: DomainDataService,
    private readonly authenticationService: AuthenticationDataService,
    private readonly authenticationOfflineService: AuthenticationService,
    public loadingCtrl: LoadingController,
    private readonly disk: DiskService,
    private readonly logService: LogService,
    private readonly device: DeviceService,
    private readonly appInsightsService: AppInsightsService,
    private readonly repService: RepServices,
    private readonly navParams: NavParams,
    private readonly inAppBrowser: InAppBrowser,
    private readonly _secureStorage: SecureStorage,
    private readonly events: Events,
    public alertService: AlertService,
    private readonly alertCtrl: AlertController,
    private readonly popoverCtrl: PopoverController,
    public notificationService: NotificationService,
    public translate: TranslateService,
    private readonly settingsService: SettingsService,
    private readonly msAdal: MSAdal,
    private readonly statusBar: StatusBar,
    // private readonly launchDarkly: LaunchDarklyProvider,
    private readonly globalUtilityService: GlobalUtilityService,
    private faService: FeatureActionsService,
    private uiService: UIService,
  ) {
    this.initialMessagesTranslation();
    this.loginStage = 0;
    this.events.subscribe('login:noInstances', () => {
      this.loginErrorMessage = this.NO_INSTANCES_MESSAGE;
    });
    this.translate.instant('LOGIN_FETCH_OMNI_INSTANCES');
  }

  initialMessagesTranslation() {
    this.INVALID_EMAIL_MESSAGE = this.translate.instant('INVALID_EMAIL_MESSAGE');
    this.INVALID_CREDENTIALS_MESSAGE = this.translate.instant('LOGIN_WE_SIGN_YOU_IN');
    this.PWD_EXPIRED_MESSAGE = this.translate.instant('LOGIN_PASSWORD_EXPIRED');
    this.REFRESH_TOKEN_EXPIRED_MESSAGE = this.translate.instant('LOGIN_SESSION_TIMED_OUT');
    this.ACCOUNT_LOCKED_MESSAGE = this.translate.instant('LOGIN_UR_ACCOUNT_LOCKED');
    this.NO_INSTANCES_MESSAGE = this.translate.instant('LOGIN_NO_OMNI_INSTANCE_ASSIGNED');
    this.OFFLINE_ERROR = this.translate.instant('LOGIN_THERE_IS_NO_INTERNET_CONN_DETECTED');
    this.NO_DATABASE_OFFLINE_ERROR = this.translate.instant('LOGIN_NO_OFFLINE_DATA_AND_NO_INTERNET');
  }

  ngOnInit() {

    localStorage.setItem('isGeneeSpeakerMuted', 'false');

    if (this.device.isNativeApp && this.device.deviceFlags.ios) {
      this.statusBar.styleDefault();
      this.statusBar.backgroundColorByHexString('#065A9B');
    }
    if (this.device.isNativeApp && this.device.deviceFlags.android) {
      this.statusBar.styleDefault();
      this.statusBar.overlaysWebView(false);
      this.statusBar.backgroundColorByHexString('#204B8F');
    }
    this.rememberMeDetails();
    console.log(window.location.href);
    // this.settingsService.downloadSettings();
    if (!this.device.isNativeApp) {
      this.handleCallback();
      return;
    }

    this.deviceNetworkServiceReadySubscription = this.device.isDeviceNetworkServiceReady.subscribe(isReady => {
      if (isReady) {
        this.device.checkNetworkConnectivity()
        .then(hasNetwork => {
          const appLaunchedInOffline = !hasNetwork || this.device.isOffline;

          if (this.authenticationService.didJustLogoutAndBackToLogin()) {
            // User just logged out and came back to the login page
            this.authenticationService.clearLogoutAndBackToLoginFlag();
            this.didJustLogoutWithOfflineStateAndRemeberMe = this.repService.wasLastUserStateOffline();
          } else if ((this.rememberMeCheckbox && this.usernameText && !this.authenticationService.didLogout())
                      || (this.authenticationService.didLogout() && this.repService.wasLastUserStateOffline())) {
            if ((this.repService.wasLastUserStateOffline() || appLaunchedInOffline)) {
            // App was killed with offline state and "Remember Me" was enabled
            this.login(true);
            } else {
              this.login(false, true);
            }
          }
        })
        .catch(e =>
          console.error('login: ngOnInit: ', e)
        );
      }
    });
  }

  ngOnDestroy() {
    if (this.deviceNetworkServiceReadySubscription) {
      this.deviceNetworkServiceReadySubscription.unsubscribe();
    }
  }

  private async handleCallback() {
    if (!this.authenticationService.isCallback()) return;
    await this.uiService.displayLoader();
    try {
      if (await this.authenticationService.handleCallback()) {
        try {
          if (this.authenticationService.config.domain && this.authenticationService.config.domain.endsWith('.cn')) {
            this.region = 'China';
          }
          await this.authenticationService.getInstances(this.region);
          this.loginStage = 1;
         /*const crmInstance: CRMInstance = {
            id: '3759c30c-0d1b-412e-b208-d151bb595f55',
            appCenterSecret: '5ecb0018-03d6-4fa3-9aa5-6af02eb48606',
            appInsightsInstrumentationKey: '5bc601bc-e9b7-4a44-8581-bd4db385dca2',
            dnaDashboardUrl: 'https://analytics.dev.labs.global.omnipresence.io',
            entryPointUrl: 'https://entry.dev.3fcac93ecc0c4580af3d.chinanorth3.aksapp.azure.cn',
            urlName: 'tarrydemocn',
            friendlyName: 'China Sandbox',
            url: 'https://microsoftgraph.chinacloudapi.cn',
            geneeSecret: '',
            geneeVersion: '',
            OmnipresenceWfeUrl: '',
            veevaConfig: null,
            EdgeAnalyticsUrl: 'https://entry.dev.3fcac93ecc0c4580af3d.chinanorth3.aksapp.azure.cn/api/edg'
          };
          let jwt: { name, family_name,  given_name};
          try {
            jwt =
              this.authenticationOfflineService.access &&
              this.authenticationOfflineService.parseJwt(this.authenticationOfflineService.access)
          } catch (er) { console.error("Failed to decode token ", er) }
          // this.authenticationOfflineService.userConfig = new UserConfig({fullName: "Tarry Demo", familyName: "Tarry", givenName: "Demo"});
          this.authenticationOfflineService.userConfig = new UserConfig({fullName: jwt.name, familyName: jwt.family_name, givenName: jwt.given_name});
          this.authenticationOfflineService.userConfig.activeInstance = crmInstance;
          this.handleInstanceSelection(crmInstance);*/
          this.appInsightsService.config = {
            accountId: this.authenticationService.config.tenant
          };
        } catch (httpError) {
          this.loginStage = 0;
          if (this.alertService.currentAlert) {
            this.alertService.currentAlert.onDidDismiss().then(() => {
              window.location.href = window.location.origin;
            });
          }
        }
      }
    }
    finally {
      this.uiService.dismissLoader();
    }
  }

  private parseJwt(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  };

  /**
   * Loads our rememberMe value, and if applicable, our email and password.
   *
   * @private
   * @memberof LoginPage
   */
  private rememberMeDetails() {
    // console.log(localStorage.getItem("rememberMeCheckbox"));
    if (localStorage.getItem('rememberMeCheckbox') === '1') {
      this.rememberMeCheckbox = this.authenticationService.rememberMeChecked = true;
      this.usernameText = localStorage.getItem('loginEmail');
    }
  }

  /**
   * Attempts to retrieve everything we need for an offline login based on a previous user session
   *
   * @private
   * @memberof LoginPage
   */
  private async offlineLogin({offlineLoginFlow, deviceOffline, bypassLoginFlow}: {offlineLoginFlow: boolean, deviceOffline: boolean, bypassLoginFlow: boolean}) {
    let loginFailFlag = false;

    try {
      if (!offlineLoginFlow && deviceOffline && !this.didJustLogoutWithOfflineStateAndRemeberMe) {
        loginFailFlag = true;
        return false;
      }
      if (!await this.restoreOfflineLoginInfo(this.usernameText)) {
        if (!await this.device.checkNetworkConnectivity()) {
          this.device.isOffline = false;
        }
        this.authenticationService.isOfflineLoginFlow = this.disableLoginButton = false;
        return false;
      }

      // Set & reset flags
      if (this.repService.wasLastUserStateOffline()) {
        this.device.isOffline = this.device.isUserStateOffline = this.repService.isOfflineState = true;
      } else if (this.device.isDeviceRealOffline) {
        this.device.isOffline = this.repService.isOfflineState = true;
      }
      this.didJustLogoutWithOfflineStateAndRemeberMe = false;

      // Check for Remember Me change
      this.rememberMeChanged();
      await this.disk.setUserDB(this.authenticationOfflineService.userConfig);
      if (this.device.deviceFlags.native && (this.device.deviceFlags.ios || this.device.deviceFlags.android)) {
        this._cordovaHelpers.exec('setAppCenterUserId', this.authenticationOfflineService.user?.userPrincipalName ?? this.authenticationOfflineService.user?.displayName);
      }
    // this.appInsightsService.config = {
      //   accountId: this.authenticationService.config.tenant
      // };
      this.authenticationService.clearLogoutFlag();
      this.device._isDeviceOfflineFromLogin = true;
      this.authenticationOfflineService.shouldTrySyncingDuringAppLaunch = bypassLoginFlow;
      this.navCtrl.setRoot(HomePage);
      return true;
    }
    catch (err) {
      loginFailFlag = true;
      return false;
    }
    finally {
      if (loginFailFlag) {
        this.authenticationService.isOfflineLoginFlow = false;
        this.translate.get('init').subscribe(() => {
          this.alertCtrl.create({
            header: this.translate.instant('LOGIN_NO_NET_CONNECTIVITY'),
            message: this.translate.instant('LOGIN_WE_CANT_DETECT_NET'),
            backdropDismiss: false,
            buttons: [
              {
                text: this.translate.instant('OKAY'),
                handler: () => { }
              }
            ]
          })
          .then((alert)=> alert.present());
        });
      }
      this.disableLoginButton = false;
    }
  }

  private getRedirectUriForApp({ broker, fallbackUri }: { broker?: boolean, fallbackUri?: string } = { broker: false }) {
    if (!broker || !this.device.deviceFlags.nativeCordova) {
      return this.device.isNativeApp ? encodeURI('omnipresence://com.omnipresence.io/login') : window.location.origin + '/';
    }
    let redirectUri: string;
    if (this.device.deviceFlags.android) {
      const packageName = this.device.buildInfo.basePackageName;
      const base64Signature = this.device.buildInfo.base64Signature;
      if (packageName && base64Signature) {
        redirectUri = `msauth://${packageName}/${encodeURIComponent(base64Signature)}`;
      }
      redirectUri = redirectUri || fallbackUri;
    }
    if (this.device.deviceFlags.ios) {
      const packageName = this.device.buildInfo.basePackageName || '';
      const packageName_rfc1034identifier = packageName.replace(/\./g, '-');
      if (packageName && packageName_rfc1034identifier) {
        redirectUri = `x-msauth-${packageName_rfc1034identifier}://${packageName}`;
      }
      redirectUri = redirectUri || fallbackUri;
    }
    return redirectUri;
  }

  /**
   * Calls our authentication data service and posts our login information for a token response
   * Retrieves user info via this authentication token
   *
   * @private
   * @returns {boolean}
   *
   * @memberOf HomePage
   */
  async login(offlineLoginFlow = false, bypassLoginFlow = false) {
    this.disableLoginButton = true;
    //Create our AuthenticationUserRequest object from our Domain & login information
    if (!offlineLoginFlow && (!this.usernameText || this.usernameText.length === 0)) {
      this.displayLoginError = true;
      this.disableLoginButton = false;
      this.loginErrorMessage = this.translate.instant('LOGIN_ENTER_USERNAME');
      return;
    }

    this.device.checkNetworkConnectivity()
      .then(async (hasNetwork) => {

    const deviceOffline = !hasNetwork || this.device.isOffline;
    const lastLoginUserName = localStorage.getItem('loginEmail');
    const isDifferentUserFromLastLogin = (lastLoginUserName && this.usernameText === lastLoginUserName) ? false : true;
    this.authenticationService.isOfflineLoginFlow = offlineLoginFlow
                                                  || ((deviceOffline || this.didJustLogoutWithOfflineStateAndRemeberMe)
                                                      && (!lastLoginUserName || !isDifferentUserFromLastLogin));

    if (this.authenticationService.isOfflineLoginFlow) {

      await this.offlineLogin({offlineLoginFlow, deviceOffline, bypassLoginFlow});
      return;
    } else {
      this.displayLoginError = false;
      this.device._isDeviceOfflineFromLogin = false;
    }

    //Loading spinner
    // const loading = await this.loadingCtrl.create();
    // loading.present();
    await this.uiService.displayLoader();

    // In case LD not initialized (eg. arrived login page without network)
    // if (!this.launchDarkly.initialized) {
    //   await this.launchDarkly.initialize();
      this.globalUtilityService.setupSessionStack();
    // }

    //Split up our username
    const domain = this.usernameText.split('@')[1];

    //Okay boys, need to take our username, extract the domain and send that to our domain service.
    try {
      this.authenticationOfflineService.shouldTrySyncingDuringAppLaunch = false;
      const response = await this.domainService.getDomainDetailsForUsername(domain);
      console.log(response);
      if (response['region']) {
        this.region = response['region'];
        localStorage.setItem('region', this.region);
      }
      if (!response['client-id'] || !response['realm-id']) {
        console.error('Domain response didnt have a client or realm id for ADFS');
      }

      this.rememberMeChanged();
      this.realmId = response['realm-id'];
      this.clientId = response['client-id'];
      const keyPrefix = this.device.deviceFlags.nativeIOS ? 'hybrid-ios-' : this.device.deviceFlags.nativeAndroid ? 'android-' : '';
      const redirectUri = this.getRedirectUriForApp({
        broker: !!((+response[`${keyPrefix}use-broker`]) || 0),
        fallbackUri: response[`${keyPrefix}redirect-url`],
      });
      const clientId = response['client-id'];
      // redirectUri = 'x-msauth-rc-release://com.indegene.omnipresenceuat';
      // clientId = '2a49d9c0-8516-4cc6-a7e8-c6b01452b3ee';
      await this.authenticationService.init({
        tenant: response['realm-id'],
        clientId,
        domain,
        dynamicsAdminCentreUrl: response.dynamicsAdminCentreUrl,
        userId: this.usernameText,
        redirectUri,
      });
      if (await this.authenticationService.login(bypassLoginFlow, this.region)) {

        if (bypassLoginFlow) {
          // Auto login flow for app killed during online.
          await this.offlineLogin({offlineLoginFlow, deviceOffline, bypassLoginFlow});
          this.authenticationOfflineService.shouldTrySyncingDuringAppLaunch = true;
        } else {
        await this.authenticationService.getInstances(this.region);
        this.loginStage = 1;
        }
      }
    }
    catch (error) {
      console.error(error);
      if (
        error.status === this.HTTP_ERR_403 &&
        error.error.errorCode === this.INVALID_EMAIL
      ) {
        this.displayLoginError = true;
        this.loginErrorMessage = this.translate.instant('INVALID_EMAIL_MESSAGE');
      }
    }
    finally {
      this.disableLoginButton = false;
      // loading.dismiss();
      this.uiService.dismissLoader();
    }
    });
  }

  /**
   * Handles the various error login codes we receive from backend
   *
   * @private
   * @param {HttpErrorResponse} error
   * @memberof LoginPage
   */
  private handleLoginError(error: HttpErrorResponse) {
    switch (error.error.errorCode) {
      case this.INVALID_EMAIL:
        this.loginErrorMessage = this.INVALID_EMAIL_MESSAGE;
        break;
      case this.INVALID_CREDENTIALS:
        this.loginErrorMessage = this.INVALID_CREDENTIALS_MESSAGE;
        break;

      case this.PWD_EXPIRED:
        this.loginErrorMessage = this.PWD_EXPIRED_MESSAGE;
        break;

      case this.REFRESH_TOKEN_EXPIRED:
        this.loginErrorMessage = this.REFRESH_TOKEN_EXPIRED_MESSAGE;
        break;

      case this.ACCOUNT_LOCKED:
        this.loginErrorMessage = this.ACCOUNT_LOCKED_MESSAGE;
        break;

      default:
        console.error('Unhandled case for login error', error);
    }
  }

  /**
   * This needs to be encrypted
   *
   * @private
   * @param {Segment} event
   * @memberof LoginPage
   */
  private rememberMeChanged() {
    if (!this.rememberMeCheckbox) {
      this.authenticationService.flushOfflineSession();
      localStorage.setItem('loginEmail', '');
      localStorage.setItem(
        'rememberMeCheckbox',
        this.rememberMeCheckbox ? '1' : '0'
      );
      this.rememberEmail = this.authenticationService.rememberMeChecked = false;
      return;
    }

    localStorage.setItem('loginEmail', this.usernameText);
    localStorage.setItem(
      'rememberMeCheckbox',
      this.rememberMeCheckbox ? '1' : '0'
    );
    this.rememberEmail = this.authenticationService.rememberMeChecked = true;
  }

  private async saveOfflineLoginInfo(username: string) {
    try {
      if (!this.device.isNativeApp) return false;
      // If hybrid app, encrypt and save to secure storage
      const offlineConfiguration = {
        user: this.authenticationOfflineService.user,
        userConfig: this.authenticationOfflineService.userConfig,
        instance: this.authenticationOfflineService.userConfig.activeInstance,
        tentantId: this.authenticationService.config.tenant,
        refresh: this.authenticationOfflineService.refresh,
      };
      await this.authenticationOfflineService.saveOfflineLoginInfo(username, offlineConfiguration);
      return true;
    }
    catch (ex) {
      console.error(ex);
    }
    return false;
  }

  private async restoreOfflineLoginInfo(username) {
    try {
      const iv = await this.disk.getFromSecureStorage(username);
      const encBuffer = await this.disk.getFromSecureStorage(username + '-encBuffer');

      if (!iv || !encBuffer) {
        return false;
      }
      //Decrypt time
      const results = await decrypt(encBuffer, iv, username);
      const loginConfiguration = results && JSON.parse(results);
      if (loginConfiguration && loginConfiguration.user) {
        this.authenticationOfflineService.user = loginConfiguration.user;
        this.authenticationOfflineService.userConfig = loginConfiguration.userConfig;
        if (loginConfiguration.tentantId) {
          await this.authenticationService.init({
            tenant: loginConfiguration.tentantId,
            domain: undefined,
            redirectUri: undefined,
            clientId: undefined,
            dynamicsAdminCentreUrl: undefined,
            userId: undefined,
          });
          this.authenticationService.config.tenant = loginConfiguration.tentantId;
          this.authenticationOfflineService.refresh = loginConfiguration.refresh;
          this.authenticationOfflineService.expires = localStorage.getItem('expires');
        }

        // Restore FA values
        this.faService.updateFaValues();
        return true;
      }
    }
    catch (e) {
      console.error('restoreOfflineLoginInfo: ', e);
    }

    return false;
  }

  public async handleInstanceSelection(selection) {
    //Loading spinner
    await this.uiService.displayLoader();

    try {
      // if (!await this.authenticationService.getToken()) {
      //   throw new Error(this.translate.instant('LOGIN_WE_SIGN_YOU_IN'));
      // }
      try {
        const user = await this.authenticationService.getUser(true);
        await this.saveOfflineLoginInfo(this.usernameText);
        await this.disk.setUserDB(this.authenticationOfflineService.userConfig);

        if (this.device.deviceFlags.native && (this.device.deviceFlags.ios || this.device.deviceFlags.android)) {
          this._cordovaHelpers.exec('setAppCenterUserId', this.authenticationOfflineService.user?.userPrincipalName ?? this.authenticationOfflineService.user?.displayName);
        }

        await this.authenticationService.fetchNotesAssistantConfiguration();
        await this.authenticationService.fetchOKIntegrationConfig();
        this.uiService.dismissLoader();
        this.authenticationService.clearLogoutFlag();
        this.navCtrl.setRoot(HomePage);
        return true;
      } catch (error) {
        console.error('loginRefresh: getUser', error);
        this.logService.logError(
          'Error pulling user info after token refresh'
        );
        this.uiService.dismissLoader();
        return false;
      }
    }
    catch (error) {
      console.log('error from refresh itself', error);
      this.logService.logError('Error refreshing token', error);
      this.notificationService.notify(this.translate.instant('LOGIN_UR_AUTHORIZATION_EXPIRED'),
        'Login Service', 'top', '', 3000, true);
      setTimeout(() => {
        this.authenticationService.logout();
        this.uiService.dismissLoader();
        return false;
      }, 3000);
    }
  }

  // public onLoginBlur() {
  //   this.usernameInput.nativeElement.value = this.usernameInput.nativeElement.value.trim();
  // }

  onErrorMsgClose() {
    this.displayLoginError = false;
  }

  async openPrivacyPopover() {
    this.alertService.showModal(AboutDetailsComponent,{ aboutTitle: 'PRIVACY_POLICY' });
   }
  openTerms() {
    this.alertService.showModal(AboutDetailsComponent,{ aboutTitle: 'TERMS_AND_CONDITIONS' });
  }
  openCopyright() {
    this.alertService.showModal(AboutDetailsComponent,{ aboutTitle: 'COPYRIGHT_INFO' });
  }

}
