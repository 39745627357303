import { ChannelActivityType } from '@omni/classes/consent/channel.class';
import { NotificationService } from "@omni/services/notification/notification.service";
import { ProcedureKPI } from './../../enums/edge-analytics/surgery-order/surgery-order-report.enum';
import { SurgeryOrderMeasureData } from './../../../interfaces/edge-analytics/surgery-order-report.interface';
import { BaseMeasureData } from './../../../interfaces/edge-analytics/report.interface';
import { LocalizationService } from './../localization/localization.service';
import { MeasureType } from './../../enums/edge-analytics/edge-analytics.enum';
import { ReportDataManagementService } from './../reports/report-data-management.service';
import { ChangeRequestType, MDMType } from './../../classes/mdm/source-type-optionset.class';
import { CONTACT_FETCH_QUERIES, CONTACT_LIST_PERIOD_TAG, FETCH_CONTACTS_LINK_ENTITES_BY_CONTACTID } from './../../config/fetch-xml/contact-fetchXMLs';
import { ContactMedicalInsight, ContactStatusCode, DCRApprovalReq, SelectableLinkedEntity } from './../../classes/contact/contact.class';
import { Condition } from './../../components/contact/contact-list/contact-list';
import { ACCOUNT_ACCOUNT_AFFILIATIONS_REF_ENTITY, ACCOUNT_CONTACT_AFFILIATIONS_REF_ENTITY, CONTACT_CONTACT_AFFILIATIONS_REF_ENTITY } from './../../config/dynamic-forms/affiliations-contants';
import { EventsService, EventName } from './../events/events.service';
import { fetchQueries, getDeltaSyncFilter } from './../../config/dynamics-fetchQueries';
import { TranslateService } from '@ngx-translate/core';
import * as XML2JS from 'xml2js';
import { BehaviorSubject, Subject, Observable, combineLatest, of } from "rxjs";
import { Injectable, NgZone } from '@angular/core';
import { Contact, ContactMeetingState, ContactAddress, ContactDTO, TrackAction, Specialty, Language, Email, AccountRelation, ProfessionalDesignation, ProductSegmentation, ContactRepCallPlan } from '../../classes/contact/contact.class';
import { DiskService, OFFLINE_DB_LINKED_ENTITY_NAME, OFFLINE_DATA_COUNT_ENTITY_NAME } from '../disk/disk.service';
import { ActivityService } from '../activity/activity.service';
import { AppointmentActivity } from '../../classes/activity/appointment.activity.class';
import { PageName } from '../navigation/navigation.service';
import { Activity, ActivityTypeCodeRaw, ActivityType } from '../../classes/activity/activity.class';
import { format, isValid, differenceInHours, isFuture, isPast, isBefore } from 'date-fns';
import { ComponentViewMode, UIService } from '../ui/ui.service';
import { AuthenticationService } from '../authentication.service';
import { CustomerSampleAllocationDTO } from '../../models/sample-model';
import { SampleDataService } from '../../data-services/sample/sample.data.service';
import { EmailActivity } from '../../classes/activity/email.activity.class';
import { DeltaService, EntitySyncInfo, EntityNames } from '../../data-services/delta/delta.service';
import { DB_ALLDOCS_QUERY_OPTIONS, DB_KEY_PREFIXES, DB_SYNC_STATE_KEYS, PREFIX_SEARCH_ENDKEY_UNICODE } from '../../config/pouch-db.config';
import { DynamicsClientService } from '../../data-services/dynamics-client/dynamics-client.service';
import { CustomerEventsService } from '../customer-event/customer-events.service';
import { SampleActivity } from '../../classes/activity/sample.activity.class';
import { ContactEvent } from '../../classes/customer-event/customer-event.class';
import { Account } from '../../classes/account/account.class';
import { SearchConfigService } from './../search/search-config.service';
import { searchIndexDataModel, SelectedSuggestionPillDataModel, SuggestionPillType } from './../../models/search-config-data-model';
import _ from 'lodash';
import { DEFAULT_CONTACT_DISPLAY_FORM, RequiredContactAttributes, ConfigExcludedAttributes, multilingualLanguageToAttributeMapping, DEFAULT_CONTACT_CREATE_FORM } from '../../config/dynamic-forms/default-contact/default-contact-create';
import { FETCH_CONTACTS_LINK_ENTITES, PositionFilter } from '../../config/fetch-xml/contact-fetchXMLs';
import { DynamicFormType } from '../../models/dynamic-form-component.model';
import { DynamicForm, FormType, Control, DisplayText } from '../../classes/dynamic-form/dynamic-form.class';
import { DynamicFormsService } from '../dynamic-forms/dynamic-forms-service';
import { ControlDataType } from '../../classes/dynamic-form/dynamic-form.class';
import { PhoneActivity } from '../../classes/activity/phone.activity.class';
import { ScientificInfo } from '../../classes/contact/scientific-info.class';
import { Endpoints } from '../../../config/endpoints.config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { timeout, debounceTime } from 'rxjs/operators';
import { SecInfoConfigDataService } from '@omni/data-services/sec-info-config/sec-info-config-data-service';
import { SecondaryInfoEntityName } from '@omni/classes/sec-info-config/sec-info.class';
import { SurgeryOrderActivity } from '@omni/classes/activity/surgery-order.activity.class';
import { procedureUpdateTotalAccountContactCount } from '../reports/functions/surgery-order/surgery-order-report.functions';
import { DatePipe } from '@angular/common';
import { FeatureActionsMap } from "@omni/classes/authentication/user.class";
import { addLoaderCount } from '../reports/functions/report.functions';
import { DeviceService } from '../device/device.service';
import {MyAssistantService, NOTIFICATION} from "@omni/services/my-assistant/my-assistant.service";
import {IndNotificationDataModel} from "@omni/models/indNotificationDataModel";
import {GlobalUtilityService} from "@omni/services/global-utility.service";
import { LinkedEntity } from "@omni/classes/onekey/ok-searchkeys.class";
import { TagEntityType } from '../user-tag/user-tag.service';
import moment from 'moment';
import { FeatureActionsService } from '../feature-actions/feature-actions.service';

type MostRecentContactActivity = { isFetching: boolean, recentFetchTime: number, mostRecentActivityTitle: string };
@Injectable({
  providedIn: 'root'
})
export class ContactOfflineService {
    public activeConsents = [];
    public contacts: Contact[] = [];
    public contactsDF: Contact[] = [];
    public contactRolesValues = [];
    public searchContacts: Contact[];
    public followUpMeetingContacts: Contact[];
    private contactInformation$ = new BehaviorSubject<Contact>(null);
    private tempContactInformation$ = new BehaviorSubject<Contact>(null);
    public contactInformationObservable = this.contactInformation$.asObservable();
    public isSchedulerInvoked: boolean = false;
    public isCaseManagementInvoked: boolean = false;
    public isConsentInvoked: boolean = false;
    public titleOptionValues = [];
    private _consentFrom: string = '';
    private userTagDeleted = new BehaviorSubject<boolean>(false);
    public userTagDeletedObserver = this.userTagDeleted.asObservable();
    // public professionalDesignation: ProfessionalDesignation[] = []; //Not needed with new Dynamic Forms
    $addAccountAffiliation = new Subject<AccountRelation>();

    private selectedContacts = new BehaviorSubject<ContactSelectionObservableModel>(null);
    selectedContactObserver = this.selectedContacts.asObservable();

    public selectedOKContact = new BehaviorSubject<any>(null);
    selectedOKContactObserver = this.selectedOKContact.asObservable();

    public selectedOKContacts:any[] = [];
    XMLParser: any;

    private _activeLanguageCode:string = '1033';
    userTagClickFromSearchSuggestionPopover$: Subject<UserTagForContact> = new Subject<UserTagForContact>();
    linkEntityContactTo: any;
    linkEntityContactFrom: any;
    affiliationAccount: any;
    public get consentFrom(): string {
      return this._consentFrom;
    }

    public set consentFrom(value: string) {
      this._consentFrom = value;
    }

    public get contactInformation(): Contact {
        return this.contactInformation$.getValue();
    }

    public set contactInformation(value: Contact) {
        this.contactInformation$.next(value);
    }
    public rawContactInformation;

    public get tempContactInformation(): Contact {
        return this.tempContactInformation$.getValue();
    }

    public set tempContactInformation(value: Contact) {
        this.tempContactInformation$.next(value);
    }
    public eventsId: string;
    public contactPageMode: ComponentViewMode;
    public contactPrevPageMode: ComponentViewMode;
    public isCCRMode: boolean = false; //Temporary for disabling create button
    public isInGlobalSearch: boolean = false;
    public isOneKeySearching: boolean = false;
    public globalSearchResults: Contact[] = [];

    public accessedContactListFrom: PageName;
    public contactSentimentUpdateActivity: Activity;
    public contactDetails: Array<Object>;
    public configuredFields: Array<Object> = [];
    public isContactDetailsLoaded: boolean = false;
    public isContactProfileLoaded: boolean = false;
    public scientInfoModalOpen:boolean = false;
    public selectedContactOnTimeline: Contact;
    public updateActivityIdsForOfflineTimelineByContactId: Array<ContactTimelineActivityByContact> = [];
    public scrapActivityIdsForOfflineTimelineByContactId: Array<ContactTimelineActivityByContact> = [];
    public updateContactIdsForOfflineTimelineByActivityId: Array<ContactTimelineContactByActivity> = [];


    get loadedContacts(): boolean {
        return this.contacts.length > 0;
    }

    private matchedContactDataSource = new BehaviorSubject<Contact[]>(undefined);
    public matchedContactObservable = this.matchedContactDataSource.asObservable();

    schedulerDisplayContacts: Contact[] = [];

    public specialties: Specialty[] = [];
    public recentSearches: SelectedSuggestionPillDataModel[] = [];
    public consentRecentSearches: SelectedSuggestionPillDataModel[] = [];

    public _defaultLinkedEntityMappingData:Array<any> = [];

    private _advancedSearchConfigServiceWorker;
    private _defaultLinkedEntityDataMappingServiceWorker;

    // Should only be used to trigger UI refresh after real time details fetch
    public refreshUIofCurrentSelectedContactFlag:boolean = false;
    public contactUpdated: Subject<boolean> = new Subject<boolean>();

    // list of all linked entites from form
    public contactLinkEntities: Array<string> = [];

    public isOneKeyChangeRequest: boolean = false;
    public isOneKeyAffiliatedToContact: boolean = false;

    public contactTags : UserTagForContact[] = [];

    private contactsNotificationModel: IndNotificationDataModel;
    public isAffiliationEnabled:boolean=false;

    public isEnableGoToContactsTool: boolean = false;
    public isClickedGoToContactsTool: boolean = false;
    public prevSelected: Contact;
    public isNotInMyContactList: boolean = false;
    public errorMessageOpenContactDetails: string = '';

    public selectedContactCr: any;
    public accountIdsToAdd: Array<string> = [];
    public approvableDCRRequests: Array<DCRApprovalReq> = [];
    public selectedDCRApprovalReq: DCRApprovalReq;

    // --------------------------------Contacts Tool Settings-------------------------------- //
    public logoforContactsTool = '';
    public labelforContactsTool = '';
    public baseURLforContactsTool = '';
    public isGUIDEnabledforContactsTool = false;
    // --------------------------------Contacts Tool Settings-------------------------------- //


    public isOpenedSurveyTimelineFromContactProfile: boolean = false;
    public selectedActivitiesFromContactFilter: Array<string> = [];
    public selectedSubOptionsFromContactFilter: { meetingStatus: Array<number> } = { meetingStatus: [] };

    private mostRecentContactActivityData: Map<string, MostRecentContactActivity>;

    constructor(
        private disk: DiskService,
        private activityService: ActivityService,
        private authService: AuthenticationService,
        private _ngZone: NgZone,
        public sampleDataService: SampleDataService,
        public deltaService: DeltaService,
        private dynamics: DynamicsClientService,
        public customerEventsService: CustomerEventsService,
        private searchConfigService: SearchConfigService,
        //public translate : TranslateService,
        private ngZone: NgZone,
        private dynamicFormsService: DynamicFormsService,
        private http: HttpClient,
        public events: EventsService,
        private uiService:UIService,
        private secondaryInfoService: SecInfoConfigDataService,
        private translate: TranslateService,
        private reportDataMgmService: ReportDataManagementService,
        private datePipe: DatePipe,
        private localizationService: LocalizationService,
        private deviceService: DeviceService,
        public toast: NotificationService,
        private myAssistantService: MyAssistantService,
        private utilityService: GlobalUtilityService,
        private faService: FeatureActionsService,
    ) {
        this.contacts = [];
        this.searchContacts = [];
        //this._activeLanguageCode = this.localizationService.selectedLanguage.localeID;
        this._initAdvancedSearchServiceWorker();
        this._initDefaultLinkedEntityDataMappingServiceWorker();

        // For edge analytics
        combineLatest([reportDataMgmService.measures$, reportDataMgmService.reportDataRefreshRequest$])
        .pipe(
          debounceTime(0),
        )
        .subscribe(([measures, refreshRequestMeasureType]) => {
          if (measures.has(MeasureType.procedure) && refreshRequestMeasureType === MeasureType.procedure) {
            const measureData: BaseMeasureData = reportDataMgmService.getMeasure(refreshRequestMeasureType);
            const kpiData = measureData?.kpiMap?.get(ProcedureKPI.contacts);
            kpiData?.isDataLoading$?.next(true);
            addLoaderCount(measureData?.isTileDataLoading$);
            const count: number = this.contacts.filter(item => item.isguest === false && item.isActive && item.indskr_iseventparticipant != true).length ?? 0;
            procedureUpdateTotalAccountContactCount(measureData as SurgeryOrderMeasureData, 'contact', count);
          }
        });
      this.events.observe("sync:completed")
        .subscribe(() => {
          this.affiliationControl();
          this.removeOfflineUpdateContactActivitiesTimelineDB();
        });

      this.activityService.offlineActivityForTimeline$
        .subscribe((activity: Activity) => {
          if(this.deviceService.isOffline && !_.isEmpty(activity)) {
            this._offlineActivityTimelineHandler(activity);
          }
        });
    }

    setMostRecentActivityData(contactId: string, data: MostRecentContactActivity) {
      if (!this.mostRecentContactActivityData) {
        this.mostRecentContactActivityData = new Map<string, MostRecentContactActivity>();
      }
      this.mostRecentContactActivityData.set(contactId, data);
    }
    getMostRecentActivityDataById(contactId: string): MostRecentContactActivity {
      let data: MostRecentContactActivity;
      if (this.mostRecentContactActivityData?.has(contactId)) {
        data = this.mostRecentContactActivityData.get(contactId);
      }
      return data;
    }

    public setSelectedContacts(selectionData: ContactSelectionObservableModel) {
        this.selectedContacts.next(selectionData);
        this.selectedContacts.next(null);
    }

    resetContactConnectionStatus() {
        this.contacts.map(c => {
            c.connectionState = ContactMeetingState.NOTJOINED
        });
    }

    getRemoteMeetingAccessToken() {
        let url = this.activityService.selectedActivity.meetingURL;
        let idx = url.lastIndexOf("/");
        return url.substr(idx + 1);
    }

    getFullName(contact) {
        return contact['firstName'] + " " + (contact['lastName'] ? contact['lastName'] : '');
    }

    getFullNameByContactId(contactId: string): string {
      const foundContact = this.getContactByID(contactId);
      return this.getFullName(foundContact);
    }


    moveContactToSelected(contact: Contact) {
        if (this.activityService.selectedActivity instanceof AppointmentActivity) {
            let selectedIndex = this.activityService.selectedActivity.contacts.findIndex(sAppContact => sAppContact.ID === contact.ID);

            if (selectedIndex !== -1) return;
            this.activityService.selectedActivity.contacts.push(contact);
            if(this.authService.hasFeatureAction(FeatureActionsMap.MEETING_KEY_MESSAGE_SENTIMENT)){
              this._populateKeyMessageSentimentsForAContact(contact);
            }
            // this.contactInformation = contact;
        } else if (this.activityService.selectedActivity instanceof PhoneActivity) {
            this.activityService.selectedActivity.contacts = [];
            let selectedIndex = this.activityService.selectedActivity.contacts.findIndex(sAppContact => sAppContact.ID === contact.ID);

            if (selectedIndex !== -1) return;
            this.activityService.selectedActivity.contacts.push(contact);
            // this.contactInformation = contact;
        }
    }


    removeContactFromSelected(contact: Contact) {
        contact.isSelected = false;

        if (this.activityService.selectedActivity instanceof AppointmentActivity) {
            let selectedIndex = this.activityService.selectedActivity.contacts.findIndex(sAppContact => sAppContact.ID === contact.ID);

            if (selectedIndex < 0) return;
            this.activityService.selectedActivity.contacts.splice(selectedIndex, 1);
        }
    }

    filterContacts(query: string) {
        query = query.toUpperCase();
        this.searchContacts = this.contacts.filter(contact => {
            let name = ((contact.firstName) ? contact.firstName + ' ' : '') + ((contact.lastName) ? contact.lastName : '');
            return ((name.toUpperCase().includes(query) || contact.speciality.toUpperCase().includes(query)) && !contact.isguest);
            //return (name.toUpperCase().startsWith(query));
        });
    }

    public getContactByID(id: string): Contact {
        return this.contacts.find(c => c.ID === id);
    }

    replaceContact(newContact: Contact) {
        const idx = this.contacts.findIndex(a => a.ID === newContact.ID);
        if (idx >= 0) {
            this.contacts[idx] = newContact;
        }
    }

    replaceGlobalSearchContact(newContact: Contact) {
        const idx = this.globalSearchResults.findIndex(a => a.ID === newContact.ID);
        if (idx >= 0) {
            this.globalSearchResults[idx] = newContact;
        }
    }

    // async mapFullSyncedContacts(rawContacts, newLastSyncedTime: number, forceFullSync = false, interaction = []) {
    //     await this._ngZone.runOutsideAngular(async() => {
    //         if (rawContacts && Array.isArray(rawContacts)) {
    //             if (forceFullSync) {
    //                 this.clear();

    //                 try {
    //                     await this.disk.remove(DB_KEY_PREFIXES.CONTACT);
    //                 } catch (error) {
    //                     console.error('mapFullSyncedContacts: ', error);
    //                     // TODO: handle error..
    //                 }
    //             }
    //             // mapping all interaction date with contacts
    //             await this.mapContactWithInteraction(rawContacts, interaction);
    //             for (let i = 0; i < rawContacts.length; i++) {
    //                 const rawContact = rawContacts[i];
    //                 rawContact.lastSyncedTime = newLastSyncedTime;

    //                 if (rawContact.statuscode && rawContact.statuscode !== 0) {
    //                     const newContact = new Contact(rawContact);
    //                     newContact.configuredFields = this.authService.updateConfiguredFieldValues(rawContact, 'contact');
    //                     //this.mapContactFieldsToSearchIndex(newContact);
    //                     this.contacts.push(newContact);
    //                 }
    //             }
    //             // this.searchConfigService.contactsSearchIndexesConfig = this.searchConfigService.contactsSearchIndexesConfig.filter(config=>{
    //             //   return config.values.length>=1
    //             // })

    //             try {
    //                 await this.disk.updateOrInsert(DB_KEY_PREFIXES.CONTACT, doc => ({ raw: rawContacts }));
    //             } catch (error) {
    //                 console.error('mapFullSyncedContacts: ', error);
    //                 // TODO: handle error..
    //             }
    //         } else {
    //             console.error('mapFullSyncedContacts: Invalid raw data provided');
    //             // TODO: handle error..
    //         }
    //     });
    // }

    // async mapDeltaSyncedContacts(rawContacts: ContactDTO[], newLastSyncedTime: number, interaction = []) {
    //     if (rawContacts && Array.isArray(rawContacts)) { // length check as delta may be not happened for contact
    //         // adding interaction date and type in delta http call
    //         await this.mapContactWithInteraction(rawContacts, interaction);
    //         let dbContacts = [];
    //         dbContacts = await this.getOfflineSavedContacts(dbContacts);
    //          // adding interaction date and type in local db
    //          await this.mapContactWithInteraction(dbContacts, interaction);
    //          // adding interaction data to session conatcs
    //          await this.mapContactWithInteraction(this.contacts, interaction);

    //         if(rawContacts.length == 0) {
    //           if(dbContacts && dbContacts.length > 0) {
    //               if(interaction && interaction.length > 0){
    //                   // updating local db with modified interaction date
    //                   await this.disk.saveContactsToDB(dbContacts);
    //               }
    //             let newContacts =dbContacts.filter((offlineContact) =>{
    //             return offlineContact.isNew;
    //         });
    //         if(newContacts && newContacts.length > 0 ){
    //               console.log("contacts", newContacts);
    //               newContacts.forEach(contact => {
    //                 this.deltaService.deltaRecordsDTO.contacts.push(contact['contactid']);
    //               });
    //             }
    //           }
    //           return;
    //         } else {
    //           for (let i = 0; i < rawContacts.length; i++) {
    //               const rawContact = rawContacts[i];
    //               rawContact.lastSyncedTime = newLastSyncedTime;

    //               if (rawContact.track_action && rawContact.track_action === TrackAction.Deleted) {
    //                   // Hard Deleted
    //                   const idx = this.contacts.findIndex(a => a.ID === rawContact.contactid);
    //                   const dbIdx = dbContacts.length > 0 ? dbContacts.findIndex(a => a.contactid === rawContact.contactid) : -1;

    //                   if (idx >= 0) {
    //                       this.contacts.splice(idx, 1);
    //                   }
    //                   if (dbIdx >= 0) {
    //                       dbContacts.splice(dbIdx, 1);
    //                   }
    //               } else {
    //                   if (!isNaN(rawContact.statuscode)) {

    //                       const idx = this.contacts.findIndex(a => a.ID === rawContact.contactid);
    //                       const dbIdx = dbContacts.length > 0 ? dbContacts.findIndex(a => a.contactid === rawContact.contactid) : -1;

    //                       if (idx >= 0) {
    //                           // Update
    //                           if (rawContact.statuscode === 1 || rawContact.statuscode === 2 || rawContact.statuscode === 548910000) {
    //                               // Active / Inactive / Unverified
    //                               if ( dbIdx >= 0) {
    //                                   //updating delta contact with ineraction data from localdb
    //                                   let foundDbContact = dbContacts[dbIdx];
    //                                   rawContact.interactionDate = foundDbContact['interactionDate'];
    //                                   rawContact.interactionType = foundDbContact['interactionType'];
    //                               }
    //                               const contact = new Contact(rawContact);
    //                               contact.configuredFields = this.authService.updateConfiguredFieldValues(rawContact, 'contact');
    //                               //this.mapContactFieldsToSearchIndex(contact);
    //                               if (dbIdx >= 0) {
    //                                   dbContacts[dbIdx] = rawContact;
    //                               } else {
    //                                   // Shouldn't fall here..
    //                                   console.warn('mapDeltaSyncedContacts: Could not find a record from db.. Cannot Replace ', rawContact);
    //                               }
    //                               this.contacts[idx] = contact;
    //                               if(rawContact['isNew']){
    //                                 this.deltaService.deltaRecordsDTO.contacts.push(rawContact.contactid);
    //                               }
    //                           } else if (rawContact.statuscode === 0) {
    //                               // Removed
    //                               if (dbIdx >= 0) {
    //                                   dbContacts.splice(dbIdx, 1);
    //                               } else {
    //                                   // Shouldn't fall here..
    //                                   console.warn('mapDeltaSyncedContacts: Could not find a record from db.. Cannot Remove ', rawContact);
    //                               }
    //                               this.contacts.splice(idx, 1);
    //                           } else {
    //                               console.warn('mapDeltaSyncedContacts: Invalid statuscode ' + rawContact.statuscode, rawContact.contactid);
    //                           }
    //                       } else {
    //                           // New

    //                           if (rawContact.statuscode === 1 || rawContact.statuscode == 548910000) {
    //                               //Check if new data is added , then take those ids for displaying
    //                               this.deltaService.deltaRecordsDTO.contacts.push(rawContact.contactid);
    //                               rawContact['isNew'] = true;
    //                               const contact = new Contact(rawContact);
    //                               contact.configuredFields = this.authService.updateConfiguredFieldValues(rawContact, 'contact');
    //                               //this.mapContactFieldsToSearchIndex(contact);
    //                               dbContacts.push(rawContact);
    //                               this.contacts.push(contact);
    //                           }
    //                       }

    //                   } else {
    //                       console.error('mapDeltaSyncedContacts: statuscode NaN.. ' + rawContact);
    //                   }
    //               }
    //           }
    //         // this.searchConfigService.contactsSearchIndexesConfig = this.searchConfigService.contactsSearchIndexesConfig.filter(config=>{
    //         //   return config.values.length>=1
    //         // })
    //           await this.disk.saveContactsToDB(dbContacts);
    //         }
    //     } else {
    //         console.error('mapDeltaSyncedContacts: Invalid raw data provided');
    //     }
    // }

  // private async getOfflineSavedContacts(dbContacts: any[]) {
  //   try {
  //     let rawContactsDoc: any = await this.disk.retrieve(DB_KEY_PREFIXES.CONTACT);
  //     if (rawContactsDoc && rawContactsDoc.raw && Array.isArray(rawContactsDoc.raw)) {
  //       dbContacts = rawContactsDoc.raw;
  //     }
  //   }
  //   catch (error) {
  //     console.warn('mapDeltaSyncedContacts: No data to load');
  //   }
  //   return dbContacts;
  // }

    async mapAppCreatedContact(rawContact: ContactDTO) {
        if(rawContact) {
            let dbContacts = [];
            try {
                let rawContactsDoc: any = await this.disk.retrieve(DB_KEY_PREFIXES.CONTACT);
                if (rawContactsDoc && rawContactsDoc.raw && Array.isArray(rawContactsDoc.raw)) {
                    dbContacts = rawContactsDoc.raw;
                }
            } catch (error) {
                console.warn('mapAppCreatedContact: No data to load');
            }
            const contact = new Contact(rawContact)
            const index = this.contacts.findIndex(a => a.ID === contact.ID);

            if(index < 0) {

                dbContacts.push(rawContact);
                this.contacts.push(contact);

                this.sortContactsBySelectedSortOption();

                try {
                    await this.disk.updateOrInsert(DB_KEY_PREFIXES.CONTACT, (doc) => {
                        if (!doc || !doc.raw) {
                            doc = {
                                raw: []
                            };
                        }
                        doc.raw = dbContacts;
                        return doc;
                    });
                } catch (error) {
                    console.error('Adding Contact to Position: ', error);
                    // TODO: handle error..
                }
            } else {
                // If editing
                // if it alredy exists update

                // if (dbIdx >= 0) {
                //     dbContacts[dbIdx] = rawContact;
                // } else {
                //     // Shouldn't fall here..
                //     console.warn('mapDeltaSyncedContacts: Could not find a record from db.. Cannot Replace ', rawContact);
                // }
                // this.contacts[index] = contact;
                console.log("Contact is already in your position")
            }
        }
    }

    // async loadDataForOfflineLogin() {
    //     await this.loadContactsFromDBAndMap();
    //     this.sortContactsBySelectedSortOption();
    // }

    async loadContactsFromDBAndMap(forceReload = false) {
        if (this.contacts.length !== 0 && !forceReload) {
            return;
        }

        try {
            let rawContactsDoc: any = await this.disk.retrieve(DB_KEY_PREFIXES.CONTACT);
            if (rawContactsDoc && rawContactsDoc.raw && Array.isArray(rawContactsDoc.raw)) {
                for (let i = 0; i < rawContactsDoc.raw.length; i++) {
                    const rawContact = rawContactsDoc.raw[i];

                    if (rawContact.hasOwnProperty('track_action') && rawContact.track_action === TrackAction.Deleted) {
                        continue;
                    }

                    if (rawContact.statuscode && rawContact.statuscode !== 0) {
                        const newContact = new Contact(rawContact);
                        newContact.configuredFields = this.authService.updateConfiguredFieldValues(rawContact, 'contact');
                        //this.mapContactFieldsToSearchIndex(newContact);
                        this.contacts.push(newContact);
                    }
                }
                // this.searchConfigService.contactsSearchIndexesConfig = this.searchConfigService.contactsSearchIndexesConfig.filter(config=>{
                //   return config.values.length>=1
                // })
            } else {
                console.warn('loadContactsFromDBAndMap: No data to load');
                return;
            }
        } catch (error) {
            console.error('loadContactsFromDBAndMap: ', error);
        }
    }

    sortContactsBySelectedSortOption(sortBy?:string, asc?:boolean) {
      this.events.publish('sortContacts');
    }

    async upsertRawContactToLocalDB(rawContact, updateOnly = false, insertOnly = false) {
        if (rawContact) {
            await this.disk.updateOrInsert(DB_KEY_PREFIXES.CONTACT, (doc) => {
                if (!doc || !doc.raw) {
                    doc = {
                        raw: []
                    };
                }

                const dbIdx = doc.raw.length > 0 ? doc.raw.findIndex(a => a.contactid === rawContact.contactid) : -1;
                if (dbIdx >= 0) {
                    if (!insertOnly) {
                        doc.raw[dbIdx] = rawContact;
                    }
                } else {
                    if (!updateOnly) {
                        doc.raw.push(rawContact);
                    }
                }
                return doc;
            });
        }
    }

    getContactAddressObjById(contactId: string, addressId): ContactAddress {
        let contAdd: ContactAddress = null;
        let contact: Contact = this.getContactByID(contactId);

        if (contact) {
            const address: ContactAddress = contact.addressesList.find(item => item.customerAddressID === addressId);
            if (address) {
                contAdd = address;
            }
        }

        return contAdd;
    }
    getAllocationAddressById(contactID,addressId):string{
        let contAdd = '';
        let contact:Contact = this.getContactByID(contactID);
        if(contact){
            let address:ContactAddress = contact.addressesList.find(item => item.customerAddressID === addressId);
            if(address){
                contAdd = address.compositeAdd;
            }
        }

        return contAdd;

    }

    getContactsByEmailAddressId(email: EmailActivity): Contact[] {
        let selectedContacts: Contact[] = [];
        email.emailActivityParties.forEach(emailActivityParty => {
            let contact: Contact = _.cloneDeep(this.getContactByID(emailActivityParty.indskr_contactid));
            if (!contact) {
                //If Contact is deactivated/ removed
                let inActiveContact: ContactDTO = {
                    contactid: emailActivityParty.indskr_contactid,
                    firstname: emailActivityParty.contact_firstname,
                    lastname: emailActivityParty.contact_lastname
                };
                contact = new Contact(inActiveContact);
            } else {
              contact.emailAddressList = _.unionBy(contact.emailAddressList, "emailAddressId");
            }
            let emails: Email[] = [];
            emailActivityParty.emailAddresses.forEach(ea => {
                const emailAddress: Email = contact.emailAddressList.find(contactEmail => contactEmail.emailAddress == ea.emailAddress);
                if (emailAddress) {
                    emailAddress.isSelected = true;
                    if (email.status != 1) {
                        emails.push(emailAddress)
                    }
                } else {
                    const deletedEmail: Email = { emailAddressId: ea.email_addressid, emailAddress: ea.emailAddress, isPrimary: false, isVerified: true, isSelected: true };
                    emails.push(deletedEmail);
                }
            });
            if (email.status != 1) contact.emailAddressList = emails;
            selectedContacts.push(contact);
        });
        return selectedContacts;
    }

    deselectContactEmailAddress(contactId: string, emailAddressId: string) {
        this.getContactByID(contactId).emailAddressList.forEach(emailAddress => {
            if (emailAddress.emailAddressId === emailAddressId) {
                emailAddress.isSelected = false;
            }
        })
    }

    async mapContactProfile(contact: Contact, response: { activities: any[], repCallPlans: any[], eligibileAllocations: any[], eventRegistrations: ContactEvent }) {
        if (!contact) {
            console.error('mapContactProfile: No contact supplied');
            return;
        }
        if (response) {
            // Set Contact Activities
            contact.mapActivities(response.activities, true);
            // Update CallPlan
            //contact.mapCallPlans(response.repCallPlans);

            if (response['scientificInformation']) {
              contact.mapScientificInformation(response['scientificInformation']);
            }
            //Customer Events
            if(response['eventRegistrations']){
                if(this.contactInformation === null) {
                   this.contactInformation = contact;
                }
                await this.customerEventsService.syncIndividualContactEventFromContactProfile(response['eventRegistrations'], this.contactInformation.ID);
            }

            // Eligibility..
            if(response.eligibileAllocations && response.eligibileAllocations.length>0){
                response.eligibileAllocations.map((o)=>{
                    o.indskr_contactid = this.contactInformation.ID;
                    o.indskr_contactname = this.contactInformation.fullName;
                })
                this.mapContactallocationEligibilities(response.eligibileAllocations);
            }

        }
    }

    async mapContactallocationEligibilities(rawdata: CustomerSampleAllocationDTO[]){
        await this.sampleDataService.syncIndividualCustomerAllocationsFromContactProfile(rawdata);
    }

    async mapTimelineActivities(response, contact: Contact) {
      if(this.deviceService.isOffline) return;
      let rawActivities:any[] = response['activities'];
      let rawOrders:any[] = response['orders'];
      const contactActivities: Activity[] = [];
        if (Array.isArray(rawActivities)) {
            rawActivities.map(rawJSON => {
                rawJSON._id = DB_KEY_PREFIXES.MEETING_ACTIVITY + rawJSON.activityid;
                // All of the meetings we fetch from this service are all completed.
                // Since statecode value not included in the response, manually setting it here.
                // OMNI-24583 Added Open/Scheduled meeting activities on the timeline
                rawJSON.statecode = 1;

                let newActivity: Activity;

                if (rawJSON.activitytypecode === ActivityTypeCodeRaw.SampleDrop) {
                    newActivity = new SampleActivity(rawJSON);

                    if(!rawJSON['location'] && rawJSON['indskr_customeraddress']){
                        newActivity.location = this.getAllocationAddressById(contact.ID, rawJSON['indskr_customeraddress']);
                    }
                    if(isValid(new Date(rawJSON['actualstart']))){
                        newActivity.scheduledStart = new Date(rawJSON['actualstart']);
                    }else{
                        newActivity.scheduledStart = new Date(parseFloat(rawJSON['actualstart']));
                    }
                } else {
                    newActivity = new AppointmentActivity(rawJSON);

                    if (rawJSON.activitytypecode === ActivityTypeCodeRaw.Appointment) {
                        //if the statuscode is not 3(completed), the statecode is 0(open) to determine meetingowner label.
                        if(rawJSON.statuscode != 3) rawJSON.statecode = 0;
                        newActivity = new AppointmentActivity(rawJSON);
                        let liveMeet = this.activityService.activities.find(item => {
                            return (item.ID === rawJSON['activityid'] && item.location === 'LiveMeet');
                        });
                        if(liveMeet){
                            newActivity = liveMeet;
                        }
                    } else if (rawJSON.activitytypecode === ActivityTypeCodeRaw.Email) {
                        newActivity = new AppointmentActivity(rawJSON);
                        newActivity['parentemailid'] = rawJSON['parentemailid'];
                        const startDate = rawJSON["statuscode"] == 548910000 ? rawJSON["actualend"] : rawJSON['senton'] ? rawJSON['senton'] : rawJSON['scheduledend'];
                        if(isValid(new Date(startDate))){
                            newActivity.scheduledStart = new Date(startDate);
                        }else{
                            newActivity.scheduledStart = new Date(parseFloat(startDate));
                        }
                    }
                    else if(rawJSON.activitytypecode === ActivityTypeCodeRaw.PhoneCall){
                        newActivity = new PhoneActivity(rawJSON);
                        newActivity['phonecallphonenumber'] = rawJSON['phonecallphonenumber']
                        if(isValid(new Date(rawJSON['actualstart']))){
                            newActivity.scheduledStart = new Date(rawJSON['actualstart']);
                        }else{
                            newActivity.scheduledStart = new Date(parseFloat(rawJSON['actualstart']));
                        }
                    }
                }

                if (newActivity) {
                  //push activity except the cancelled meeting
                  if(!(newActivity['type'] == "Appointment" && newActivity['status'] == 4)) {
                    contactActivities.push(newActivity);
                  }
                }
            });
        }
        if(Array.isArray(rawOrders)){
          rawOrders.forEach(rawOrder => {
            if(rawOrder['indskr_ordertype'] && rawOrder['indskr_ordertype'] == 548910001){
              let orderObj = new SurgeryOrderActivity(rawOrder);
              orderObj.type = ActivityType.SurgeryOrder;
              if(orderObj.ownerId != this.authService.user.systemUserID){
                orderObj.isTeamOrder = true;
              }
              if(orderObj.offlineDBId && orderObj.ownerId && orderObj.surgeryOrderStatusString == 'Completed'){
                contactActivities.push(orderObj)
              }
            }
          });
        }
        //Save activities for contact timeline in DB
        const contactId: string = contact.ID;
        this.saveActivitiesContactTimelineDB(contactActivities, contactId);

        contact.mapActivities(contactActivities);
    }

    private async saveActivitiesContactTimelineDB(contactActivities: Activity[], contactId: string) {
      const id = DB_KEY_PREFIXES.CONTACT_ACTIVITIES_TIMELINE + contactId;
      const dateWithOfflineDataDuration = this.authService.getPastOfflineDataLimitDateInUTCMillisecond();
      if(!_.isEmpty(contactActivities) && contactActivities.length > 0) {
        let filteredOfflineData: Activity[] = [];
        filteredOfflineData = contactActivities.filter((activity)=> {
          return new Date(activity.startDate) > new Date(dateWithOfflineDataDuration);
        });
        try {
          await this.disk.updateOrInsert(id, (doc) => {
            if(!doc || !doc.raw) {
              doc= {
                raw:[]
              }
            }
            doc.raw = filteredOfflineData;
            console.log(`Saved activities for contact timeline in DB: ${filteredOfflineData.length}`);
            return doc;
          });
        } catch (error) {
          console.log("Error saving activities for contact timeline in DB", error);
        }
      }
    }

    public mapOfflineTimelineActivities(contactActivities: Activity[], contact: Contact) {
      if(!this.deviceService.isOffline) return;
      if(!_.isEmpty(contactActivities)) {
        contactActivities.map(conActivity => {
          if(conActivity.type === ActivityType.Email) {
            if(_.isEmpty(conActivity['meetingOwnerName'])) {
              if(this.authService.user && conActivity.meetingOwnerId == this.authService.user.systemUserID) {
                conActivity.meetingOwnerName = this.authService.user.displayName;
              }
            }
            if(!conActivity['channelTypeName']) {
              if(conActivity['channelActivityType'] == ChannelActivityType.SMS) {
                conActivity['channelTypeName'] = 'SMS';
              }else if(conActivity['channelActivityType'] == ChannelActivityType.WHATSAPP) {
                conActivity['channelTypeName'] = 'Whatsapp';
              }
            }
            if(conActivity.status == 548910000) {
              if(!conActivity['actualEnd']) {
                let sharedDate = new Date(conActivity['scheduledStart']);
                if (isValid(sharedDate)) {
                  conActivity['actualEnd'] = sharedDate;
                }
              }
            }else {
              if(!conActivity['senton']) {
                let sentOnDate = new Date(conActivity['scheduledStart']);
                if (isValid(sentOnDate)) {
                  conActivity['senton'] = sentOnDate;
                }
              }
            }
          }else if(conActivity.type === ActivityType.PhoneCall) {
            if(_.isEmpty(conActivity['phonecallphonenumber'])) {
              conActivity['phonecallphonenumber'] = conActivity['selectedMobileNumber'];
            }
          }
        });
      }
      contact.mapActivities(contactActivities);
    }

    getContactActivites(activities , mapParameters:boolean) {
        if (activities) {
            this.contactInformation.activitesByContact = [];
            if (Array.isArray(activities)) {
                if(mapParameters){
                    activities.map(rawJSON => {
                        this.contactInformation.activitesByContact.push(new AppointmentActivity(rawJSON));
                    });
                }else{
                    this.contactInformation.activitesByContact = activities;
                }
                this.contactInformation.activitesByContact.sort((a, b) => (b.scheduledStart.getTime()) - (a.scheduledStart.getTime()));
            } else {
                console.error('Unsupported data format for contacts!');
            }
        }
    }

    /*getContactRepCallPlans(repCallPlans) {
        let contactRepCallPlans = [];
        if (repCallPlans) {
            this.contactInformation.repCallPlansByContact = [];
            if (Array.isArray(repCallPlans)) {
                let now = new Date();
                repCallPlans.map(o => {
                    let callPlan: ContactRepCallPlan = {
                        name: o['indskr_name'],
                        createDate: o['createdon'],
                        productName: o['productName'] || '',
                        contactName: o['indskr_customerid'],
                        stateCode: o['statecode'],
                        emailGoals: o['indskr_hoemails'],
                        meetingGoals: o['indskr_hocalls'],
                        emailsComplete: o['indskr_actualemails'] || 0,
                        meetingComplete: o['indskr_actualcalls'] || 0,
                        emailCompletePercentage: o['indskr_actualemails'] / o['indskr_hoemails'] * 100 || 0,
                        meetingCompletePercentage: o['indskr_actualcalls'] / o['indskr_hocalls'] * 100 || 0,
                        startDate: o['indskr_startdate'],
                        endDate: o['indskr_enddate'],
                        callPlanID: o['indskr_customercallplanid'],
                        callPlanTimeFrame: isPast(new Date(parseInt(o['indskr_enddate']))) ? 'Past' : isFuture(new Date(parseInt(o['indskr_startdate']))) ? 'Future' : 'Present',
                        callPlanStatus: o["statuscode"]
                    };

                    this.contactInformation.repCallPlansByContact.push(callPlan);
                })
            }
        }
    }*/

    public getFormattedRepCallPlans() {
        let formattedCallPlanObj = {
            past: [],
            present: [],
            future: []
        };
        if (this.contactInformation.repCallPlansByContact.length > 0) {
            this.contactInformation.repCallPlansByContact.map(o => {
                if(o.positionId){
                  if(!this.authService.user.isManager &&
                    !this.authService.user.positions.some(p=> p.ID == o.positionId)) return
                  o.emailCompletePercentage = parseFloat(o.emailCompletePercentage.toFixed(2));
                  o.meetingCompletePercentage  = parseFloat(o.meetingCompletePercentage.toFixed(2));

                  if (o.callPlanTimeFrame == 'Past') {
                      formattedCallPlanObj.past.push(o);
                      return;
                  }
                  if (o.callPlanTimeFrame == 'Present') {
                      formattedCallPlanObj.present.push(o);
                      return;
                  }
                  formattedCallPlanObj.future.push(o)
                }
            })
        }

        return formattedCallPlanObj;
    }

    public getGroupedActivitiesByMonth(activities): Array<Object> {
        const events: any[] = [];
        const groupedElements: any = {};
        let groupByKey = 'scheduledStart';
        if (activities) {
            let value: any = activities; //this.contactInformation.activitesByContact;
            if (value.length > 0) {
              value.map(v => {
                if(v.hasOwnProperty(groupByKey) && v[groupByKey] != '' && v[groupByKey] != "Invalid Date" && v[groupByKey] != undefined) {
                  v[groupByKey] = new Date(v[groupByKey]);
                }
              });
              value.sort((a, b) => (b.scheduledStart.getTime()) - (a.scheduledStart.getTime()));
              value.forEach((obj: any) => {
                    if(obj.hasOwnProperty(groupByKey) && !isNaN(obj[groupByKey].valueOf())){
                    let item =  this.datePipe.transform(obj[groupByKey], 'MMMM y', undefined, this.translate.currentLang)//format(obj[groupByKey], 'MMMM YYYY');
                    if (!(item in groupedElements)) {
                        groupedElements[item] = [];
                    }
                    groupedElements[item].push(obj);
                  }
                });

                for (let prop in groupedElements) {
                    if (groupedElements.hasOwnProperty(prop)) {
                        //let meetingDay = isToday(prop) ? "Today" : isTomorrow(prop) ? "Tomorrow" : format(prop,'dddd MMM D');
                        events.push({
                            key: prop,
                            list: groupedElements[prop]
                        });
                    }
                }
            }
        }
        return events;
    }

    public clear() {
        this.contacts = [];
        this.searchContacts = [];
        this.contactInformation = undefined;
    }

    public setMatchedContacts(input: any) {
        this.matchedContactDataSource.next(input);
    }

    public async getContactRoles():Promise<any>{

        new Promise( async (resolve,reject)=>{
            try{
                let result = await this.dynamics.retrieveGlobalOptionSet("Name='indskr_contactrole'");
                this.contactRolesValues = this.mapContactRole(result['Options']);
               resolve('');
            }
            catch(err){
                console.error(err);
                reject();
            }
        });
    }

    public async getTitles():Promise<any>{

        new Promise( async (resolve,reject)=>{
            try{
                let result = await this.dynamics.retrieveGlobalOptionSet("Name='indskr_title'");
                this.titleOptionValues = this.mapTitleOptionSet(result['Options']);
               resolve('');
            }
            catch(err){
                console.error(err);
                reject();
            }
        });
    }

    mapContactRole(data):Array<any>{
        let roles = [];
        if(data && Array.isArray(data)){
            data.forEach(item=>{
                let role = {
                    "key" : item['Value'],
                    "value" : item['Label']['LocalizedLabels'][0].Label
                }
                roles.push(role);
            });
        }
        return roles;
    }

    mapTitleOptionSet(data):Array<any>{
        let titles = [];
        if(data && Array.isArray(data)){
            data.forEach(item=>{
                let role = {
                    "key" : item['Value'],
                    "value" : item['Label']['LocalizedLabels'][0].Label
                }
                titles.push(role);
            });
        }
        return titles;
    }

    public async mapSpecialties(rawSpecialty: any, newLastSyncedTime: number, forceFullSync = true) {
        await this._ngZone.runOutsideAngular(async() => {
            if (rawSpecialty && Array.isArray(rawSpecialty)) {
                if (forceFullSync) {
                    //this.clear();

                    try {
                        await this.disk.remove(DB_KEY_PREFIXES.SPECIALTY);
                    } catch (error) {
                        console.error('mapSpecialty: ', error);
                        // TODO: handle error..
                    }
                }
                this.specialties = [];
                for (let i = 0; i < rawSpecialty.length; i++) {
                    const raw = rawSpecialty[i];
                    raw.lastSyncedTime = newLastSyncedTime;
                    let specialty: Specialty = {
                        id: raw.indskr_lu_specialtyid,
                        name: raw.indskr_specialty,
                        code: raw.indskr_specialtycode,
                        createdon: raw.createdon,
                        indskr_sadeptnm: raw.indskr_sadeptnm
                    }
                    this.specialties.push(specialty);
                }

                try {
                    this.disk.updateOrInsert(DB_KEY_PREFIXES.SPECIALTY, doc => ({ raw: rawSpecialty }));
                } catch (error) {
                    console.error('mapSpecialty: ', error);
                    // TODO: handle error..
                }
            } else {
                console.error('mapSpecialty: Invalid raw data provided');
                // TODO: handle error..
            }
        });
    }

    selectedAccountAffiliation(selectedAddress){
        this.$addAccountAffiliation.next(selectedAddress);
    }

    getAddressSub():Observable<AccountRelation>{
        return this.$addAccountAffiliation.asObservable();
    }

    public async getAffiliatedContactsFromAccountsForMeeting(accounts:Account[]):Promise<Contact[]>{
      let affiliatedContacts:Contact[] = [];
      if(accounts.length != 0){
          let rawData = await this.disk.retrieve(DB_KEY_PREFIXES.ACCOUNT_LINKED_ENTITY+OFFLINE_DB_LINKED_ENTITY_NAME.ACCOUNT_CONTACT_AFFILIATION);
          if(rawData && rawData.raw && Array.isArray(rawData.raw) && rawData.raw.length != 0){
              rawData.raw.forEach(item => {
                  if(item.hasOwnProperty('accountid') && item.hasOwnProperty('indskr_accountcontactaffiliation.indskr_contactid') && item["indskr_accountcontactaffiliation.statecode"] === 0 && accounts.some(acc=> acc.id == item['accountid'])){
                      let foundContact = this.getContactByID(item['indskr_accountcontactaffiliation.indskr_contactid']);
                      if(foundContact && !affiliatedContacts.some(ac=> ac.ID == foundContact.ID && foundContact.isActive)){
                        affiliatedContacts.push(foundContact);
                      }
                  }
              });
          }
      }
      affiliatedContacts = affiliatedContacts.sort((a,b)=> {
        var nameA = a.fullName.toLowerCase(), nameB = b.fullName.toLowerCase();
        if (nameA < nameB)
            return -1;
        if (nameA > nameB)
            return 1;
        return 0;
    });
    return affiliatedContacts;
  }

  public async getAccountContactAffiliationsFromDB() {
    let rawData = await this.disk.retrieve(DB_KEY_PREFIXES.ACCOUNT_LINKED_ENTITY+OFFLINE_DB_LINKED_ENTITY_NAME.ACCOUNT_CONTACT_AFFILIATION);
    return rawData?.raw ? rawData.raw.filter(data => data['indskr_accountcontactaffiliation.statecode'] === 0) : [];
  }

    // public mapContactFieldsToSearchIndex(newContact:Contact){
    //   if(!this.searchConfigService.isConfigInitiated){
    //     this.searchConfigService.initSearchConfigs();
    //     this.searchConfigService.isConfigInitiated = true;
    //   }
    //   if(newContact.isguest || !newContact.isActive) return;
    //   newContact.addressesList.forEach(add=>{
    //     if(add.country && !this.searchConfigService.contactsSearchIndexesConfig.find(config=>config.categoryName=='Country').values.some(o => o== add.country))
    //         this.searchConfigService.contactsSearchIndexesConfig.find(config=>config.categoryName=='Country').values.push(add.country)
    //     if(add.region && !this.searchConfigService.contactsSearchIndexesConfig.find(config=>config.categoryName=='Regions').values.some(o => o==add.region))
    //         this.searchConfigService.contactsSearchIndexesConfig.find(config=>config.categoryName=='Regions').values.push(add.region);
    //     if(add.city && !this.searchConfigService.contactsSearchIndexesConfig.find(config=>config.categoryName=='City').values.some(o => o==add.city))
    //         this.searchConfigService.contactsSearchIndexesConfig.find(config=>config.categoryName=='City').values.push(add.city);
    //     if(add.state && !this.searchConfigService.contactsSearchIndexesConfig.find(config=>config.categoryName=='State').values.some(o => o==add.state))
    //         this.searchConfigService.contactsSearchIndexesConfig.find(config=>config.categoryName=='State').values.push(add.state);
    //     if(add.postal && !this.searchConfigService.contactsSearchIndexesConfig.find(config=>config.categoryName=='Postal Code').values.some(o => o==add.postal))
    //         this.searchConfigService.contactsSearchIndexesConfig.find(config=>config.categoryName=='Postal Code').values.push(add.postal);
    //   })
    //   newContact.accountRelationships.forEach(acc=>{
    //     if(acc.accountName &&!this.searchConfigService.contactsSearchIndexesConfig.find(config=>config.categoryName=='Accounts').values.some(o=> o==acc.accountName))
    //         this.searchConfigService.contactsSearchIndexesConfig.find(config=>config.categoryName=='Accounts').values.push(acc.accountName)
    //   })
    //   newContact.contactRelationships.forEach(cont=>{
    //     if(cont.contactName &&!this.searchConfigService.contactsSearchIndexesConfig.find(config=>config.categoryName=='Relationships').values.some(o=> o==cont.contactName))
    //         this.searchConfigService.contactsSearchIndexesConfig.find(config=>config.categoryName=='Relationships').values.push(cont.contactName)
    //   })
    //   newContact.customerPositions.forEach(position=>{
    //     if(position.position &&!this.searchConfigService.contactsSearchIndexesConfig.find(config=>config.categoryName=='Coverage Team').values.some(o=> o==position.position))
    //         this.searchConfigService.contactsSearchIndexesConfig.find(config=>config.categoryName=='Coverage Team').values.push(position.position);
    //   })
    //   newContact.repCallPlansByContact.forEach(CP=>{
    //     if(CP.segmentCallPlanName && !this.searchConfigService.contactsSearchIndexesConfig.find(config=> config.categoryName == 'Call Plans').values.some(o=>o == CP.segmentCallPlanName)){
    //       this.searchConfigService.contactsSearchIndexesConfig.find(config=> config.categoryName == 'Call Plans').values.push(CP.segmentCallPlanName)
    //     }
    //   })
    //   newContact.specialties.forEach(spec=>{
    //     if(spec.name && !this.searchConfigService.contactsSearchIndexesConfig.find(config=> config.categoryName == 'Specialty').values.some(o=>o == spec.name)){
    //       this.searchConfigService.contactsSearchIndexesConfig.find(config=> config.categoryName == 'Specialty').values.push(spec.name)
    //     }
    //   })
    //   newContact.configuredFields.forEach(confField=>{
    //     if(confField['fieldType'] && confField['fieldType']!='Boolean' && confField['fieldName'] && confField['fieldValue']){
    //       if(!this.searchConfigService.contactsSearchIndexesConfig.find(config=> config.categoryName == confField['fieldName'])){
    //         this.searchConfigService.contactsSearchIndexesConfig.push({
    //           categoryName:confField['fieldName'],
    //           categoryDisplayName:confField['fieldName'],
    //           categoryRelativePath:'configuredFields.fieldValue',
    //           values:[]
    //         })
    //       }
    //       if(!this.searchConfigService.contactsSearchIndexesConfig.find(config=> config.categoryName == confField['fieldName']).values.some(o=>o == confField['fieldValue'])){
    //         this.searchConfigService.contactsSearchIndexesConfig.find(config=> config.categoryName == confField['fieldName']).values.push(confField['fieldValue'])
    //       }
    //     }
    //     if(confField['fieldType'] && confField['fieldType']=='Boolean' && confField['fieldName'] && confField['fieldValue']){
    //       if(!this.searchConfigService.contactsSearchIndexesConfig.find(config=> config.categoryName == confField['fieldName'])){
    //         this.searchConfigService.contactsSearchIndexesConfig.push({
    //           categoryName:confField['fieldName'],
    //           categoryDisplayName:confField['fieldName'],
    //           categoryRelativePath:'configuredFields.fieldValue',
    //           isBooleanTypeCategory: true,
    //           values:['Yes','No']
    //         })
    //       }
    //     }
    //   })
    //   newContact.emailAddressList.forEach(email=>{
    //     if(email.emailAddress && !this.searchConfigService.contactsSearchIndexesConfig.find(config=> config.categoryName == 'Email Address').values.some(o=>o == email.emailAddress)){
    //       this.searchConfigService.contactsSearchIndexesConfig.find(config=> config.categoryName == 'Email Address').values.push(email.emailAddress)
    //     }
    //   })
    //   if(newContact.professionalDesignation && newContact.professionalDesignation.designation
    //       && !this.searchConfigService.contactsSearchIndexesConfig.find(config=> config.categoryName == 'Designation').values.some(o=>o == newContact.professionalDesignation.designation)){
    //     this.searchConfigService.contactsSearchIndexesConfig.find(config=> config.categoryName == 'Designation').values.push(newContact.professionalDesignation.designation)
    //   }
    //   if(newContact.mobilePhone && !this.searchConfigService.contactsSearchIndexesConfig.find(config=> config.categoryName == 'Phone Number').values.some(o=>o == newContact.mobilePhone)){
    //     this.searchConfigService.contactsSearchIndexesConfig.find(config=> config.categoryName == 'Phone Number').values.push(newContact.mobilePhone)
    //   }
    //   if(newContact.verificationStatus && !this.searchConfigService.contactsSearchIndexesConfig.find(config=> config.categoryName == 'Verification').values.some(o=>o == newContact.verificationStatus)){
    //     this.searchConfigService.contactsSearchIndexesConfig.find(config=> config.categoryName == 'Verification').values.push(newContact.verificationStatus)
    //   }
    //   if(newContact.telephone && !this.searchConfigService.contactsSearchIndexesConfig.find(config=> config.categoryName == 'Phone Number').values.some(o=>o == newContact.telephone)){
    //     this.searchConfigService.contactsSearchIndexesConfig.find(config=> config.categoryName == 'Phone Number').values.push(newContact.telephone)
    //   }
    //   if(newContact.indskr_alternatephone1 && !this.searchConfigService.contactsSearchIndexesConfig.find(config=> config.categoryName == 'Phone Number').values.some(o=>o == newContact.indskr_alternatephone1)){
    //     this.searchConfigService.contactsSearchIndexesConfig.find(config=> config.categoryName == 'Phone Number').values.push(newContact.indskr_alternatephone1)
    //   }
    //   if(newContact.indskr_alternatephone2 && !this.searchConfigService.contactsSearchIndexesConfig.find(config=> config.categoryName == 'Phone Number').values.some(o=>o == newContact.indskr_alternatephone2)){
    //     this.searchConfigService.contactsSearchIndexesConfig.find(config=> config.categoryName == 'Phone Number').values.push(newContact.indskr_alternatephone2)
    //   }
    //   if(newContact.indskr_alternatephone3 && !this.searchConfigService.contactsSearchIndexesConfig.find(config=> config.categoryName == 'Phone Number').values.some(o=>o == newContact.indskr_alternatephone3)){
    //     this.searchConfigService.contactsSearchIndexesConfig.find(config=> config.categoryName == 'Phone Number').values.push(newContact.indskr_alternatephone3)
    //   }
    //   if(newContact.language && !this.searchConfigService.contactsSearchIndexesConfig.find(config=> config.categoryName == 'Languages').values.some(o=>o == newContact.language)){
    //     this.searchConfigService.contactsSearchIndexesConfig.find(config=> config.categoryName == 'Languages').values.push(newContact.language)
    //   }
    //   // newContact.eventsAndRegistrationsByContact.forEach(event=>{
    //   //   if(event.eventName && !this.searchConfigService.contactsSearchIndexesConfig.find(config=> config.categoryName == 'Events').values.some(o=>o == event.eventName)){
    //   //     this.searchConfigService.contactsSearchIndexesConfig.find(config=> config.categoryName == 'Events').values.push(event.eventName)
    //   //   }
    //   // })
    //   newContact.productSegmentations.forEach(product=>{
    //     if(product.productName && !this.searchConfigService.contactsSearchIndexesConfig.find(config=> config.categoryName == 'Products').values.some(o=>o == product.productName)){
    //       this.searchConfigService.contactsSearchIndexesConfig.find(config=> config.categoryName == 'Products').values.push(product.productName)
    //     }
    //     if(product.segmentation && !this.searchConfigService.contactsSearchIndexesConfig.find(config=> config.categoryName == 'Segments').values.some(o=>o == product.segmentation)){
    //       this.searchConfigService.contactsSearchIndexesConfig.find(config=> config.categoryName == 'Segments').values.push(product.segmentation)
    //     }
    //   })
    //   // newContact.configuredFields.forEach(event=>{
    //   //   if(!this.searchConfigService.contactsSearchIndexesConfig.find(config=> config.categoryName == 'Additional Info').values.some(o=>o == event.eventName)){
    //   //     this.searchConfigService.contactsSearchIndexesConfig.find(config=> config.categoryName == 'Additional Info').values.push(event.eventName)
    //   //   }
    //   // })
    // }

    async updateNewContactStatus(updatedContacts: Contact[]) {
       if(updatedContacts && updatedContacts.length > 0) {
          let offlineSaved = await this.disk.retrieve(DB_KEY_PREFIXES.CONTACT, true);
          if(offlineSaved && offlineSaved.raw && Array.isArray(offlineSaved.raw)){
            updatedContacts.forEach(con =>{
                let foundSavedIndex = offlineSaved.raw.findIndex(savedContact => savedContact.contactid === con.ID);
                if (foundSavedIndex >= 0) {
                  offlineSaved.raw[foundSavedIndex]['isNew'] = false;
                }
            });
            await this.disk.saveContactsToDB(offlineSaved.raw);
        }
      }
    }

    /**
   * add interaction date and type to contacts raw
   * @param conatctsResponse All contacts raw data
   * @param interactionResponse  All interaction contact raw data
   */
    private async mapContactWithInteraction(conatctsResponse: any, interactionResponse: any): Promise<void> {
        try {
            if (Array.isArray(conatctsResponse) && Array.isArray(interactionResponse) && interactionResponse.length > 0 && conatctsResponse.length > 0) {
                for (let i = 0; i < interactionResponse.length; i++) {
                    const interactionContact = interactionResponse[i];
                    if(interactionContact) {
                      const foundInd = conatctsResponse.findIndex(a => a.contactid === interactionContact.contactId);
                      if (foundInd >= 0) {
                          let contact = conatctsResponse[foundInd];
                          contact['interactionDate'] = interactionContact['interactionDate'];
                          contact['interactionType'] = interactionContact['interactionType'];
                      }
                    }
                }
            }
        } catch (error) {
            console.error("In mapContactWithInteraction ", error);
        }
    }


    async fetchContactsForConfiguredDisplay(fullsync = false, loadFromDB = false, contactid = null, isForGlobalSearchedContact = false,isGlobalSearchAddedContact:boolean = false) {

        this._defaultLinkedEntityMappingData = [];
        this.contactLinkEntities = [];

        let contactForm: DynamicForm = await this.dynamicFormsService.getFormDefinitionForEntity("contact", FormType.DISPLAYFORM);
        let formType = DynamicFormType.CONFIGUREDFORM;
        const fetchMultilingualFields:boolean = this.authService.user.securityRoles.some(a=> a.name == 'iO OneKey User' || a.name == 'iO OneKey Admin');

        if (!contactForm) {
            contactForm = new DynamicForm(DEFAULT_CONTACT_DISPLAY_FORM['value'][0]);
            formType = DynamicFormType.DEFAULTFORM;
        }

        let formDef = contactForm;

        let offlineContactRawData = await this.disk.retrieve(DB_KEY_PREFIXES.CONTACT);
        if(fetchMultilingualFields && offlineContactRawData && offlineContactRawData.lastLocaleId && offlineContactRawData.lastLocaleId != this.authService.user.localeId){
          this.localizationService.fullSyncRequiredForOnekeyCodes = true;
        }
        if(offlineContactRawData && offlineContactRawData.formType && offlineContactRawData.formType == DynamicFormType.DEFAULTFORM && formType == DynamicFormType.CONFIGUREDFORM){
          fullsync = true;
          this.localizationService.fullSyncRequiredForOnekeyCodes = true;
          this.searchConfigService.resetConfiguredConfig('Contacts');
        }else if(offlineContactRawData && offlineContactRawData.formType && offlineContactRawData.formType == DynamicFormType.CONFIGUREDFORM && formType == DynamicFormType.DEFAULTFORM){
          fullsync = true;
          this.localizationService.fullSyncRequiredForOnekeyCodes = true;
          this.searchConfigService.resetConfiguredConfig('Contacts');
        }else if(offlineContactRawData && offlineContactRawData.formType &&
          offlineContactRawData.formType == DynamicFormType.CONFIGUREDFORM &&
          offlineContactRawData.lastInitialSyncTime &&
          isBefore(new Date(offlineContactRawData.lastInitialSyncTime),new Date(formDef.modiefiedOn))){
            fullsync = true;
            this.localizationService.fullSyncRequiredForOnekeyCodes = true;
            this.searchConfigService.resetConfiguredConfig('Contacts');
        }

        if(!contactid){
          fullsync = fullsync || this.secondaryInfoService.isContactSecInfoUpdated;
        }

        if(!this.searchConfigService.isConfigInitiated){
            this.searchConfigService.initSearchConfigs();
            this.searchConfigService.isConfigInitiated = true;
            this.searchConfigService.configUpdateRequired = true;
        }
        if((fullsync || !contactid)){
          this.searchConfigService.configUpdateRequired = true;
        }

        let parentEntityFetchXml = fetchQueries.configuredFormFetchXMLs.fetchConfiguredFormEntity;

        let parentEntityAttributesStr = [...(function* () {
          yield 'contactid';
          for (const attr of RequiredContactAttributes) {
            yield attr;
          }
          if (this.authService.hasFeatureAction(FeatureActionsMap.CONTACTS_VEEVA_SELECTION) || this.authService.hasFeatureAction(FeatureActionsMap.CONTACTS_VEEVA_GLOBAL_SEARCH)) {
            yield 'omniveev_isusedinonetimemeeting'
            yield 'omniveev_recordstate';
          }

          if (this.authService.user?.buConfigs && this.authService.user?.buConfigs?.indskr_businessline) {
            yield 'gendercode'
            yield 'indskr_primaryaccount';
          }

          if (this.authService.hasFeatureAction({ featureAction: FeatureActionsMap.ENABLE_PHONE_CALL_CONSENT_CHECK, skipLDCheck: true }) && this.authService.user?.buSettings?.indsyn_amasubscription) {
            yield 'omniveev_amadonotcontact';
          }
        }).call(this)].reduce((p, x) => `${p}<attribute name="${x}"/>`, '');


        //   parentEntityAttributesStr += '<attribute name="indskr_isguest"/>';
        //   parentEntityAttributesStr += '<attribute name="statuscode"/>';
        let linkEntityAttributesArray = [];
        formDef.metadata.forEach(tab => {
            tab.controls.forEach(control => {
                if (!control.dataType && control.subgrid) {
                    linkEntityAttributesArray.push(control);
                }
            })
        })
        const entityNameCurrentForm: string = formDef.entityName;
        formDef.metadata.forEach(tab => {
            tab.controls.forEach(control => {
                if (control.dataType) {
                    let linkedAttrFetchXML = '';
                    if(control.attributeName){
                      if(fetchMultilingualFields && control.lookupEntityPrimaryId && control.lookupEntityPrimaryId == "omnione_onekeycodeslabelsid"){
                        // linkedAttrFetchXML = `
                        //   <link-entity name="omnione_onekeycodeslabels" from="omnione_onekeycodeslabelsid" to="${control.attributeName}" visible="false" link-type="outer">
                        //     <attribute name="${multilingualLanguageToAttributeMapping["dynamics_language_code_"+this.authService.user.localeId]}" alias="${control.attributeName}_value"/>
                        //     ${this.authService.user.localeId != '1033' ? `<attribute name="omnione_en_long_label" alias="${control.attributeName}_fallbackvalue"/>`:''}
                        //     <attribute name="omnione_onekeycodeslabelsid" alias="_${control.attributeName}_value"/>
                        //   </link-entity>
                        // `;
                        // parentEntityAttributesStr += linkedAttrFetchXML;
                        parentEntityAttributesStr += '<attribute name="' + control.attributeName + '"/>';
                        this.localizationService.multiLingualAttributes.push(control.attributeName);
                        if(this.authService.user.localeId != '1033'){

              }
            } else {
              parentEntityAttributesStr += '<attribute name="' + control.attributeName + '"/>';
            }
          }

          // Short call requires certain hard-coded attributes
          if (this.faService.getFeatureAction(FeatureActionsMap.SHORT_CALL_LAUNCHER)?.getIsOn()) {
            if (!parentEntityAttributesStr.includes('department')) {
              parentEntityAttributesStr += '<attribute name="department"/>';
            }
            if (!parentEntityAttributesStr.includes('indskr_externalid')) {
              parentEntityAttributesStr += '<attribute name="indskr_externalid"/>';
            }
            if (!parentEntityAttributesStr.includes('indskr_speaker')) {
              parentEntityAttributesStr += '<attribute name="indskr_speaker"/>';
            }
          }

          if (control.dataType != ControlDataType.DateTimeType && control.dataType != ControlDataType.CustomerType
            && control.dataType != ControlDataType.OwnerType && control.dataType != ControlDataType.MemoType
            && control.dataType != ControlDataType.WebResource && control.dataType != ControlDataType.MultiSelectPicklistType
            && !this.searchConfigService.contactConfiguredSearchIndexConfig.some(conf => conf.categoryRelativePath == control.attributeName)
            && !ConfigExcludedAttributes.some(a => a == control.attributeName)) {
            let searchCategory: searchIndexDataModel = {
              categoryName: this.dynamicFormsService.getSearchConfigDisplayText(control.displayNames, formType, control.isCustom, control.attributeName, entityNameCurrentForm),
              categoryDisplayName: this.dynamicFormsService.getSearchConfigDisplayText(control.displayNames, formType, control.isCustom, control.attributeName, entityNameCurrentForm),
              categoryRelativePath: control.attributeName,
              controlDataType: control.dataType,
              values: [],
              mappingValues: [],
              parentEntity: 'contact',
              entity: "",
              linkEntityFetchXML: linkedAttrFetchXML,
              isBooleanTypeCategory: control.dataType == ControlDataType.BooleanType,
              numOfLinkEntity: 0,
              isMultilingualLookup: control.lookupEntityPrimaryId && control.lookupEntityPrimaryId == "omnione_onekeycodeslabelsid",
            }
            this.searchConfigService.contactConfiguredSearchIndexConfig.push(searchCategory);
          }
        }
      });
    });
    if (loadFromDB) {
      this.mapDFContact("", offlineContactRawData.raw, true);
    }

    parentEntityFetchXml = parentEntityFetchXml.replace('{parentLevelAttributes}', parentEntityAttributesStr);
    parentEntityFetchXml = parentEntityFetchXml.replace('{parentEntityName}', formDef.entityName);
    parentEntityFetchXml = parentEntityFetchXml.replace('{linkEntityPlaceholder}', '');
    parentEntityFetchXml = parentEntityFetchXml.replace('{secondaryInfoPlaceholder}', this.secondaryInfoService.SecondaryInfoFetchXML(SecondaryInfoEntityName.Contact));



        //create delta sync filter
        let syncState = await this.disk.getSyncState(DB_SYNC_STATE_KEYS.SYNC_CONTACT);
        const contactsSyncInfo: EntitySyncInfo = {
            entityName: EntityNames.contact,
            totalFailed: 0,
            totalSynced: 0,
            errors: [],
            syncStatus: true
        };

        let isInitialSync = !syncState || !syncState.lastUpdatedTime || fullsync;
        let hourDifference;
        let lastModifiedFilter = '';
        let contactidFilter = '';
        if (isInitialSync) parentEntityFetchXml = parentEntityFetchXml.replace('{deltasyncFilterLevel1}', '');
        else {
          let now = new Date();
          hourDifference = differenceInHours(
            now,
            new Date(syncState.lastUpdatedTime)
          )
          //add one to make sure we take care of fractional difference in hours
          hourDifference += 1;
            // let deltaSyncFilter = fetchQueries.deltaSyncFilter.split('{entityName}').join('contact');
            // deltaSyncFilter = deltaSyncFilter.replace('{hourDifference}',hourDifference);
            // deltaSyncFilter = deltaSyncFilter.replace('{entityID}','contactid');
            lastModifiedFilter = `<filter type="or">
              <condition attribute="modifiedon" operator="last-x-hours" value="`+ hourDifference + `" />
              <condition attribute="modifiedon" operator="last-x-hours" value="`+ hourDifference + `" entityname="indskr_customerpositionfilter"/>
            </filter>`

            if(contactid) {
                lastModifiedFilter = `<filter type="or">
                    <condition attribute="modifiedon" operator="last-x-hours" value="`+ hourDifference + `" />
                </filter>`
            }
            parentEntityFetchXml = parentEntityFetchXml.replace('{deltasyncFilterLevel1}', lastModifiedFilter);
        }
        let positionFilterString = '';
        let approvalStatusFilter = '';
        if(contactid) {
            contactidFilter = `<filter type="and"><condition attribute="contactid" operator="eq" value="` + contactid + `" /></filter>`
            parentEntityFetchXml = parentEntityFetchXml.replace('{positionFilterlevel1}', '');
            parentEntityFetchXml = parentEntityFetchXml.replace('{customFilterLevel1}', contactidFilter);
        } else {

            //create position filter
            let positionIds = this.authService.user.positions.map(o => {
                return o.ID
            });
            let positionIDValues = ''
            positionIds.forEach(p => {
                positionIDValues += '<value>' + p + '</value>'
            })
            positionFilterString = PositionFilter.split('{positionIDs}').join(positionIDValues);
            
            approvalStatusFilter = this.authService.hasFeatureAction(FeatureActionsMap.CUSTOMER_VISIBILITY_APPROVAL_BASED)
            ? `<filter type="or">
                <condition attribute="indskr_approvalstatus" operator="eq" value="548910002" />
                <condition attribute="indskr_approvalstatus" operator="null"/>
              </filter> `
            :"";
            positionFilterString = positionFilterString.replace('{approvalStatusFilter}',approvalStatusFilter);
            parentEntityFetchXml = parentEntityFetchXml.replace('{positionFilterlevel1}', positionFilterString);
            parentEntityFetchXml = parentEntityFetchXml.replace('{customFilterLevel1}', '');
        }
          //call dynamics

        let deletedContactRes;
        if(loadFromDB){
            await this.fetchContactsLinkedEntityData(linkEntityAttributesArray, positionFilterString, hourDifference, formType, contactid, loadFromDB,fetchMultilingualFields,isForGlobalSearchedContact);
            await this.fetchContactsDefaultLinkEntityData(positionFilterString, hourDifference, linkEntityAttributesArray, contactid, loadFromDB,isForGlobalSearchedContact);
            this._postMessageOnDefaultLinkedEntityDataMappingWorker({ type: 'mapDefaultLinkedEntityDataToContact', rawList: this._defaultLinkedEntityMappingData});
            if(this.searchConfigService.configUpdateRequired){
                this.searchConfigService.updateSearchConfigsForSelectedLanguage();
                this.searchConfigService.configUpdateRequired = false;
            }
        }else{
            parentEntityFetchXml = parentEntityFetchXml.replace(/\s+/g, ' ')
            return await this.dynamics.executeFetchQuery('contacts',parentEntityFetchXml).then(async (res)=>{
                if (this.dynamicFormsService.isNavAffiliatedContactFromAccount || isForGlobalSearchedContact) {
                  await this.fetchContactsLinkedEntityData(linkEntityAttributesArray, positionFilterString, hourDifference, formType, contactid, loadFromDB,fetchMultilingualFields);
                  await this.fetchContactsDefaultLinkEntityData(positionFilterString, hourDifference, linkEntityAttributesArray, contactid, loadFromDB);
                  this._postMessageOnDefaultLinkedEntityDataMappingWorker({ type: 'mapDefaultLinkedEntityDataToContact', rawList: this._defaultLinkedEntityMappingData});
                  return res;
                }
                let dataToSave = res;
                let lastInitialSyncTime;
                let interactionResponse;
                const contactListPeriodTags = _.isEmpty(res) ? null : await this.fetchContactListPeriodTags(positionFilterString, contactidFilter, lastModifiedFilter, approvalStatusFilter);
                if((!isInitialSync || contactid) && Array.isArray(res)) {

                    //let temp = await this.disk.retrieve(DB_KEY_PREFIXES.CONTACT);
                    let localRaw = [...offlineContactRawData.raw];

                    if(!contactid) {
                        let posID = this.authService.user.xPositionID
                        deletedContactRes = await this.fetchDeletedFromTrackChange("contact", hourDifference, posID);
                        if(deletedContactRes && Array.isArray(deletedContactRes)) {
                            deletedContactRes.forEach(dRes => {
                                if (dRes) {
                                    let idx = localRaw.findIndex(a => a && a.hasOwnProperty('contactid') && a['contactid'] == dRes["indskr_entityid"]);
                                    if (idx >= 0) {

                                        if(this.contactInformation && this.contactInformation.ID == localRaw[idx].contactid){
                                            this.refreshUIofCurrentSelectedContactFlag = true;
                                        }

                                        localRaw.splice(idx, 1);

                                    }
                                }
                            })
                        }
                    }

                    lastInitialSyncTime = offlineContactRawData.lastInitialSyncTime;
                    res.forEach( rawRes => {
                        if(rawRes){
                            let idx = localRaw.findIndex(a=> a.contactid == rawRes.contactid);
                            if(idx >= 0){
                                if(rawRes['statecode'] == 1 && (rawRes['statuscode'] == 2 || rawRes['statuscode'] == 548910003 || rawRes['statuscode'] == 548910010 || rawRes['statuscode'] == 548910011 || rawRes['statuscode'] == 548910012 || rawRes['statuscode'] == 548910013)) {
                                    localRaw.splice(idx,1);
                                } else {
                                    localRaw[idx] = rawRes;
                                    if(this.contactInformation && this.contactInformation.ID == rawRes.contactid){
                                        this.refreshUIofCurrentSelectedContactFlag = true;
                                    }
                                }
                            } else {
                                if(rawRes['statuscode'] != 2 && rawRes['statuscode'] != 548910003 && rawRes['statuscode'] != 548910010 && rawRes['statuscode'] != 548910011 && rawRes['statuscode'] != 548910012 && rawRes['statuscode'] != 548910013) {

                                  if (this.eventsId != undefined && this.eventsId != "") {
                                    rawRes['msevtmgt_eventid'] = this.eventsId;
                                  }
                                    localRaw.push(rawRes);

                                    if(!offlineContactRawData.raw.some(cont => cont.contactid === rawRes['contactid']) && !rawRes['indskr_isguest']){
                                      this.deltaService.deltaRecordsDTO.contacts.push(rawRes['contactid']);
                                    }
                                    this.uiService.contactDetailsSegment = "info";
                                }
                            }
                        }
                    });
                    if (this.deltaService.deltaRecordsDTO.contacts.length) {
                      let displayName = this.deltaService.deltaRecordsDTO.contacts.length == 1 ? this.globalCustomerText : this.globalCustomersText;
                      let showCount = this.deltaService.deltaRecordsDTO.contacts.length == 1 ? '' : this.deltaService.deltaRecordsDTO.contacts.length;
                      this.contactsNotificationModel = {
                        type: NOTIFICATION.NEW_CONTACTS_NOTIFICATION,
                        name: this.translate.instant("NEW_CONTACTS_NOTIFICATION", {count: showCount, globalText: displayName}),
                        DateTime: Date.now(),
                        id: NOTIFICATION.NEW_CONTACTS_NOTIFICATION + this.deltaService.deltaRecordsDTO.contacts[0],
                        data: {
                          data: this.deltaService.deltaRecordsDTO.contacts
                        },
                        icon: 'assets/imgs/contacts_notifications.svg',
                        isRed: false,
                        params: {count: showCount, globalText: displayName}
                      }
                      this.myAssistantService.saveNotificationToDisk(this.contactsNotificationModel);
                    }
                    if(!contactid){
                      interactionResponse = await this.syncInteractionDataForContacts(false, lastInitialSyncTime);
                      interactionResponse.forEach(interCon => {
                        if(!res.some(raw=>raw.contactid == interCon.contactId)){
                          let localExistingCon = localRaw.find(localCon=>localCon.contactid==interCon.contactId)
                          if(localExistingCon){
                            res.push(localExistingCon);
                          }
                        }
                      });
                    }
                    dataToSave = localRaw;

                } else {
                  interactionResponse = await this.syncInteractionDataForContacts(true);
                  lastInitialSyncTime = new Date().getTime();
                }
                if (!_.isEmpty(dataToSave) && contactListPeriodTags) this.mapContactTagLables(contactListPeriodTags, dataToSave);
                if (!_.isEmpty(res) && contactListPeriodTags) this.mapContactTagLables(contactListPeriodTags, res);

                
                if(interactionResponse && dataToSave && dataToSave.length>0) await this.mapContactWithInteraction(dataToSave,interactionResponse);
                if (dataToSave && dataToSave.length > 0) {
                  //If contact source is empty, mark it as business contacts
                  dataToSave = dataToSave.filter(con=> !(con['statecode'] == 1 && (con['statuscode'] == 2 || con['statuscode'] == 548910003 || con['statuscode'] == 548910010 || con['statuscode'] == 548910011 || con['statuscode'] == 548910012 || con['statuscode'] == 548910013)));
                  for (let data of dataToSave) {
                    if (data.indskr_contactsourcetype == null) {
                      data['indskr_contactsourcetype'] = 548910000;
                      data['indskr_contactsourcetype@OData.Community.Display.V1.FormattedValue'] = "Business";
                      data['indskr_contactsourcetype_Formatted'] = "Business";
                    }
                  }
                }
                await this.disk.updateOrInsert(DB_KEY_PREFIXES.CONTACT, doc => {
                    doc = {
                      raw: dataToSave,
                      lastInitialSyncTime: lastInitialSyncTime,
                      lastLocaleId: this.authService.user.localeId,
                      formType: formType,
                    };
                    return doc;
                  })
                  .catch(error => console.error('Save Forms to DB error: ', error));
                //this.mapContactDataToConfiguredSearchIndex(dataToSave);

        await this.fetchContactsLinkedEntityData(linkEntityAttributesArray, positionFilterString, hourDifference, formType, contactid, loadFromDB, fetchMultilingualFields, isGlobalSearchAddedContact);
        await this.fetchContactsDefaultLinkEntityData(positionFilterString, hourDifference, linkEntityAttributesArray, contactid, loadFromDB, isGlobalSearchAddedContact);
        this.purgeUnusedLinkEntity(this.contactLinkEntities);
        this.mapDFContact(res, dataToSave, isInitialSync && !contactid, deletedContactRes);
        this._postMessageOnDefaultLinkedEntityDataMappingWorker({ type: 'mapDefaultLinkedEntityDataToContact', rawList: this._defaultLinkedEntityMappingData });
        if (this.searchConfigService.configUpdateRequired) {
          this.searchConfigService.updateSearchConfigsForSelectedLanguage();
          this.searchConfigService.configUpdateRequired = false;
        }
        // this.fetchEmailsFromDBAndMapToContacts(this.contacts);
        //await this.fetchAddressesFromDBAndMapToContacts(this.contacts);
        if (contactsSyncInfo.syncStatus && !contactid) {

                    syncState.lastUpdatedTime = new Date().getTime();;
                    contactsSyncInfo.totalSynced = res.length;
                    await this.disk.updateSyncState(syncState);
                }
                return res;
            }).catch(err => {
                console.log(err);
                this.deltaService.addSyncErrorToEntitySyncInfo(contactsSyncInfo, 'contacts', err);
            })
        }
    }

    private mapContactTagLables(contactListPeriodTags: any[], raw: any[]) {
      try {
        console.log("Mapping customer position list contact tags");
        for (let data of raw) {
          if (contactListPeriodTags) {
            if (contactListPeriodTags[data['contactid']]) {
              const tagLabels = [];
              for (let tag of contactListPeriodTags[data['contactid']]) {
                if (tag['tagLabel']) {
                  tagLabels.push(tag['tagLabel']);
                }
              }
              data['tagLabels'] = tagLabels;
            }
          }
        }
      } catch (error) {
        console.error("Failed to map customer position list contact tags: ", error);
      }
    }

    async getCustomerSpecialtyId(contactId, specialityId) {
      if(!contactId || !specialityId) return null;
      const resp = await this.dynamics.retrieveAll('indskr_customerspecialties', ['indskr_customerspecialtyid'],
      `_indskr_specialtyid_value eq ${specialityId} and _indskr_customerid_value eq ${contactId}`);
      return !resp || _.isEmpty(resp.value) ? null : resp.value[0].indskr_customerspecialtyid;
    }

    private async fetchContactListPeriodTags(positionFilterString, contactidFilter, lastModifiedFilter, approvalStatusFilter) : Promise<any> {
      if (!this.authService.hasFeatureAction(FeatureActionsMap.CUSTOMER_LIST_MANAGEMENT)) return [];
      try {
        const todayDate = moment().format("YYYY-MM-DD");
        let fetchXml = CONTACT_LIST_PERIOD_TAG.replace('{positionFilterlevel1}', positionFilterString)
        .replace('{customFilterLevel1}', contactidFilter)
        .replace('{approvalStatusFilter}', approvalStatusFilter)
        .replace('{deltasyncFilterLevel1}', lastModifiedFilter)
        .split('{date}').join(todayDate)
        .replace('{positionId}', this.authService.user.xPositionID);
        const contactTags = await this.dynamics.executeFetchQuery('contacts',fetchXml);
        return _.groupBy(contactTags, (item) => item['contactid']);
      } catch (err) {
        console.error("Failed to fetch contact list period tag: ", err);
      }
    }

    async fetchContactsForConfiguredDisplayOffline(data:any,  contact: Contact) {
      let contactForm: DynamicForm = await this.dynamicFormsService.getFormDefinitionForEntity("contact", FormType.DISPLAYFORM);
      let formType = DynamicFormType.CONFIGUREDFORM;
      const fetchMultilingualFields:boolean = this.authService.user.securityRoles.some(a=> a.name == 'iO OneKey User' || a.name == 'iO OneKey Admin');

      if (!contactForm) {
          contactForm = new DynamicForm(DEFAULT_CONTACT_DISPLAY_FORM['value'][0]);
          formType = DynamicFormType.DEFAULTFORM;
      }

      let offlineContactRawData = await this.disk.retrieve(DB_KEY_PREFIXES.CONTACT);

      let lastInitialSyncTime = new Date().getTime();

      let localRaw = [...offlineContactRawData.raw];

      let idx = localRaw.findIndex(a=> a.contactid == contact.ID);

      let editedContactRawData = contact.raw;

      /*************Update Contact Display form for LookupFields*************/
      let offlineEditedContact = data.formStringValue;

      for (const key in offlineEditedContact) {
        let customKey = `_${key}_value@OData.Community.Display.V1.FormattedValue`;

        if (editedContactRawData.hasOwnProperty(customKey)) {
          console.log(`${key} : ${editedContactRawData[customKey]}`);
          console.warn(`${key} : ${offlineEditedContact[key]}`);

          editedContactRawData[customKey] = offlineEditedContact[key];
        }
        else {
          console.log(`New Key ${key} : ${editedContactRawData[customKey]}`);
          console.warn(`New Key ${key} : ${offlineEditedContact[key]}`);

          editedContactRawData[customKey] = offlineEditedContact[key];
        }

        customKey = `_${key}_value_Formatted`;

        if (editedContactRawData.hasOwnProperty(customKey)) {
          console.log(`${key} : ${editedContactRawData[customKey]}`);
          console.warn(`${key} : ${offlineEditedContact[key]}`);

          editedContactRawData[customKey] = offlineEditedContact[key];
        }
        else {
          console.log(`New Key ${key} : ${editedContactRawData[customKey]}`);
          console.warn(`New Key ${key} : ${offlineEditedContact[key]}`);

          editedContactRawData[customKey] = offlineEditedContact[key];
        }
      }

      let offlineEditedContactLookups = data.formValue.lookupfields;

      offlineEditedContactLookups.forEach((item) => {
        let customKey = `_${item.name.toLowerCase()}_value`;

        if (editedContactRawData.hasOwnProperty(customKey)) {
          console.log(`${customKey} : ${editedContactRawData[customKey]}`);
          console.warn(`${customKey} : ${item.id}`);

          editedContactRawData[customKey] = item.id;
        }
        else {
          console.log(`New Key ${customKey} : ${editedContactRawData[customKey]}`);
          console.warn(`New Key ${customKey} : ${item.id}`);

          editedContactRawData[customKey] = item.id;
        }
      });
      /*************Update Contact Display form for LookupFields*************/

      /*************Update Contact Display form for TextFields*************/
      let offlineEditedContactTextFields = data.formValue;

      for (const key in offlineEditedContactTextFields) {

        if (editedContactRawData.hasOwnProperty(key)) {
          console.log(`${key} : ${editedContactRawData[key]}`);
          console.warn(`${key} : ${offlineEditedContactTextFields[key]}`);

          editedContactRawData[key] = offlineEditedContactTextFields[key];
        }
        else {
          console.log(`New Key ${key} : ${editedContactRawData[key]}`);
          console.warn(`New Key ${key} : ${offlineEditedContactTextFields[key]}`);

          editedContactRawData[key] = offlineEditedContactTextFields[key];
        }
      }
      /*************Update Contact Display form for TextFields*************/

      //Disk save for Pouch DB entries
      if(idx >= 0){
        localRaw[idx] = editedContactRawData;
        if(this.contactInformation && this.contactInformation.ID == data.formValue.contactid){
            this.contactInformation.raw = editedContactRawData;
            this.refreshUIofCurrentSelectedContactFlag = true;
        }
      }

      //Contacts list find that contact and update
      let newIdx = this.contacts.findIndex(a=> a.ID == data.formValue.contactid);
      if(newIdx >= 0){
        this.contacts[newIdx].raw = editedContactRawData;
      }

      await this.disk.updateOrInsert(DB_KEY_PREFIXES.CONTACT, doc => {
        doc = {
          raw: localRaw,
          lastInitialSyncTime: lastInitialSyncTime,
          lastLocaleId: this.authService.user.localeId,
          formType: formType,
        };
        return doc;
      })
      .catch(error => console.error('Save Forms to DB error: ', error));

      // this.mapDFContact([editedContactRawData], localRaw, false && !contact.ID);

      return [editedContactRawData];
    }

    get globalCustomerText(): string {
      let customerText: string = "";
      switch (this.utilityService.globalCustomerText) {
        case 'Customer':
          customerText = this.translate.instant('CUSTOMER');
          break;
        case 'Stakeholder':
          customerText = this.translate.instant('STAKEHOLDER');
          break;
        default:
          customerText = this.utilityService.globalCustomerText;
          break;
      }
      return customerText;
    }

    get globalCustomersText(): string {
      let customersText: string = "";
      switch (this.utilityService.globalCustomersText) {
        case 'Customers':
          customersText = this.translate.instant('CUSTOMERS');
          break;
        case 'Stakeholders':
          customersText = this.translate.instant('STAKEHOLDERS');
          break;
        default:
          customersText = this.utilityService.globalCustomersText;
          break;
      }
      return customersText;
    }

    // private _getDisplayText(dtArray: DisplayText[],formType:DynamicFormType, isCustomControl: boolean = false) {
    //     if(!dtArray) return "";

    //     let displayText: string;
    //     let langCode = (formType == DynamicFormType.DEFAULTFORM || isCustomControl) ? "0000" : this._activeLanguageCode;
    //     let dt = dtArray.find(value => value.languagecode == langCode);

    //     if(dt) {
    //         displayText = dt.languagecode == "0000" ?  this.translate.instant(dt.description) : dt.description;
    //     } else {
    //         let en = dtArray.find(value => value.languagecode == "1033"); // default to english if no translation for the specific language;
    //         if(en) {
    //             displayText = en.description
    //         } else {
    //             displayText = "";
    //         }
    //     }
    //     return displayText;
    // }

    private async fetchContactsLinkedEntityData(linkedEntityDefArray: Control[], positionFilterString, hourDifference, dynamicFormType:DynamicFormType, contactid, loadFromDB,fetchMultilingualFields:boolean = false, isGlobalSearchAddedContact = false) {
        // let contactLinkEntities = this.contact
        return Promise.all(linkedEntityDefArray.map(async (linkEntity)=>{
        //for (var i = 0; i < linkedEntityDefArray.length; i++) {
            //let linkEntity = linkedEntityDefArray[i];
            let searchCategory: searchIndexDataModel;
            this.contactLinkEntities.push(linkEntity.subgrid.referencingEntity);
            //#region fetchxml replace
            let fetchXML = fetchQueries.configuredFormFetchXMLs.fetchConfiguredFormEntity;
            fetchXML = fetchXML.replace('{parentEntityName}', 'contact');
            fetchXML = fetchXML.replace('{parentLevelAttributes}', '<attribute name="contactid"/> <filter type="and" ><condition attribute="statecode" value="0" operator="eq" /></filter>');
            fetchXML = fetchXML.replace('{linkEntityPlaceholder}', fetchQueries.configuredFormFetchXMLs.linkEntityPlaceholder);
            fetchXML = fetchXML.replace('{linkEntityName}', linkEntity.subgrid.referencingEntity);
            fetchXML = fetchXML.replace('{linkEntityAttr}', linkEntity.subgrid.referencingAttribute);
            fetchXML = fetchXML.replace('{prentEntityAttr}', linkEntity.subgrid.parentAttribute);
            fetchXML = fetchXML.replace('{linkEntityAlias}', linkEntity.subgrid.referencingEntity);
            fetchXML = fetchXML.replace('{customFilterLevel2}', '');
            fetchXML = fetchXML.replace('{secondaryInfoPlaceholder}', '');
            //fetchXML = fetchXML.replace('{deltasyncFilterLevel1}', '');

            if(contactid) {
                let contactidFilter = `<filter type="and"><condition attribute="contactid" operator="eq" value="` + contactid + `" /></filter>`
                fetchXML = fetchXML.replace('{customFilterLevel1}', contactidFilter);
                fetchXML = fetchXML.replace('{positionFilterlevel1}', '');
            } else {
                fetchXML = fetchXML.replace('{positionFilterlevel1}', positionFilterString);
                fetchXML = fetchXML.replace('{customFilterLevel1}', '');
            }

            //remove modifiedon filter for global search mapped contact
            if(isGlobalSearchAddedContact){
              fetchXML = fetchXML.replace('{deltasyncFilterLevel1}', '');
              fetchXML = fetchXML.replace('{deltasyncFilterLevel2}', '');
            }
            // Modifield on filter for delta sync : Applied modified on fiter on customer position level as well as linked entity level
            else if (hourDifference && !contactid) {
                let lastModifiedFilter =
                    `<filter type="or">
                    <condition attribute="modifiedon" operator="last-x-hours" value="`+ hourDifference + `" entityname="`+linkEntity.subgrid.referencingEntity+`"/>
                    <condition attribute="modifiedon" operator="last-x-hours" value="`+ hourDifference + `" entityname="indskr_customerpositionfilter"/>
                  </filter>`
                fetchXML = fetchXML.replace('{deltasyncFilterLevel1}', lastModifiedFilter);
                fetchXML = fetchXML.replace('{deltasyncFilterLevel2}', '');
            }
            // No modified on filter for intial sync
            else if(!hourDifference && !contactid){
                fetchXML = fetchXML.replace('{deltasyncFilterLevel1}', '');
                fetchXML = fetchXML.replace('{deltasyncFilterLevel2}', '');
            }
            // Modified on filter for usual real time details fetch - filter at only linked entity level
            else if(hourDifference){
              let lastModifiedFilter =
                    `<filter type="or">
                    <condition attribute="modifiedon" operator="last-x-hours" value="`+ hourDifference + `"/>
                  </filter>`
                fetchXML = fetchXML.replace('{deltasyncFilterLevel1}', '');
                fetchXML = fetchXML.replace('{deltasyncFilterLevel2}', lastModifiedFilter);
            }else {
              fetchXML = fetchXML.replace('{deltasyncFilterLevel1}', '');
              fetchXML = fetchXML.replace('{deltasyncFilterLevel2}', '');
            }

            //#endregion


            //check if it linkentity is also a mandatory data
            let requiredLinkEntity = FETCH_CONTACTS_LINK_ENTITES.find(x => x.entityName == linkEntity.subgrid.referencingEntity)
            let shouldMerge = false;
            let requiredJSONQuery;
            if (requiredLinkEntity) {
                if (requiredLinkEntity.entityFetchXML) {
                    shouldMerge = true;
                    XML2JS.parseString(requiredLinkEntity.entityFetchXML, (err, data) => {
                        requiredJSONQuery = data;
                    })
                }
            }

            //generate attributes list and next level link-entities
            let queryString = linkEntity.subgrid.subgridQuery;
            let JSONQuery;
            let linkEntityAttributesStr = '';
            XML2JS.parseString(queryString, (err, data) => {
                JSONQuery = data;
            })

            let multilingualAttributes = [];
            if(fetchMultilingualFields && linkEntity.subgrid && linkEntity.subgrid.subgridLayout && linkEntity.subgrid.subgridLayout.length > 0){
              multilingualAttributes = linkEntity.subgrid.subgridLayout.filter(a=> a.targetEntity && a.targetEntity == 'omnione_onekeycodeslabels').map(a=> a.attribute);
            }
            JSONQuery.fetch.entity[0].attribute.forEach(attr => {
                let idx;
                if(multilingualAttributes.length > 0){
                  idx = multilingualAttributes.findIndex(a => a == attr.$.name);
                }
                if(idx >= 0){
                  // linkEntityAttributesStr += `
                  // <link-entity name="omnione_onekeycodeslabels" from="omnione_onekeycodeslabelsid" to="${attr.$.name}" visible="false" link-type="outer" >
                  //   <attribute name="${multilingualLanguageToAttributeMapping["dynamics_language_code_"+this.authService.user.localeId]}" alias="${JSONQuery.fetch.entity[0].$.name}.${attr.$.name}_value"/>
                  //   ${this.authService.user.localeId != '1033' ? `<attribute name="omnione_en_long_label" alias="${JSONQuery.fetch.entity[0].$.name}.${attr.$.name}_fallbackvalue"/>`:''}
                  //   <attribute name="omnione_onekeycodeslabelsid" alias="${JSONQuery.fetch.entity[0].$.name}.${attr.$.name}"/>
                  // </link-entity>
                  // `;
                  this.localizationService.multiLingualAttributes.push(attr.$.name)
                  linkEntityAttributesStr += '<attribute name="' + attr.$.name + '"/>'
                }else{
                  linkEntityAttributesStr += '<attribute name="' + attr.$.name + '"/>'
                }
            });

            let numOfLinkEntity = 0; //keep track the number of link entity. Needed for building global search fetchxml
            //Do it for the required data
            if (shouldMerge) {
                requiredJSONQuery.fetch.entity[0].attribute.forEach(attr => {
                    linkEntityAttributesStr += '<attribute name="' + attr.$.name + '"/>'
                });
                if (JSONQuery.fetch.entity[0]['link-entity'] && JSONQuery.fetch.entity[0]['link-entity'].length) {
                    JSONQuery.fetch.entity[0]['link-entity'].forEach(linEnt => {
                        numOfLinkEntity ++;
                        try {
                            linkEntityAttributesStr += "<link-entity name='" + linEnt.$.name + "' from='" + linEnt.$.from + "' to='"
                            + linEnt.$.to + "' link-type='outer' alias='" + linEnt.$.name + "'>";

                            linEnt.attribute.forEach(linEntAttr => {
                                linkEntityAttributesStr += '<attribute name="' + linEntAttr.$.name + '"/>'
                            });

                            if (Array.isArray(requiredJSONQuery.fetch.entity[0]['link-entity'])) {
                                let reqLE = requiredJSONQuery.fetch.entity[0]['link-entity'].find(x => x.$.name == linEnt.$.name);
                                if (reqLE) {
                                    reqLE.attribute.forEach(reqLinEntAttr => {
                                        if (!linEnt.attribute.some(x => x.$.name == reqLinEntAttr.$.name)) {
                                            linkEntityAttributesStr += '<attribute name="' + reqLinEntAttr.$.name + '"/>'
                                        }
                                    });
                                }
                            }
                            linkEntityAttributesStr += "</link-entity>"
                        } catch (error) {
                            console.log(error);
                        }
                    });
                }

                  //now iterate through the filter and append the sort criteria defined in the
          linkEntityAttributesStr = this.dynamicFormsService.appendSortCriteria(JSONQuery, linkEntityAttributesStr);
           //now iterate through the filter and append the filter criteria defined in the
          linkEntityAttributesStr = this.dynamicFormsService.appendFilterCriteria(JSONQuery, linkEntityAttributesStr);


                if (requiredJSONQuery.fetch.entity[0]['link-entity'] && requiredJSONQuery.fetch.entity[0]['link-entity'].length) {
                    requiredJSONQuery.fetch.entity[0]['link-entity'].forEach(linEnt => {
                        numOfLinkEntity ++;
                        try {
                          linkEntityAttributesStr = this.dynamicFormsService.appendFilterCriteria(linEnt, linkEntityAttributesStr,true);
                            if (Array.isArray(JSONQuery.fetch.entity[0]['link-entity']) && JSONQuery.fetch.entity[0]['link-entity'].some(x => x.$.name == linEnt.$.name)) return;

                            linkEntityAttributesStr += "<link-entity name='" + linEnt.$.name + "' from='" + linEnt.$.from + "' to='"
                                + linEnt.$.to + "' link-type='outer' alias='" + linEnt.$.name + "'>";

                            if(linEnt.attribute) {
                                linEnt.attribute.forEach(linEntAttr => {
                                    linkEntityAttributesStr += '<attribute name="' + linEntAttr.$.name + '"/>'
                                });
                            }

                            linkEntityAttributesStr += "</link-entity>"
                        } catch (error) {
                            console.log(error);
                        }
                    });
                }
            } else {
                // if no reqired data
                if (JSONQuery.fetch.entity[0]['link-entity'] && JSONQuery.fetch.entity[0]['link-entity'].length) {
                    JSONQuery.fetch.entity[0]['link-entity'].forEach(linEnt => {
                        numOfLinkEntity ++;
                        try {
                            linkEntityAttributesStr += "<link-entity name='" + linEnt.$.name + "' from='" + linEnt.$.from + "' to='"
                            + linEnt.$.to + "' link-type='outer' alias='" + linEnt.$.name + "'>";
                            if(linEnt.attribute) {
                                linEnt.attribute.forEach(linEntAttr => {
                                    linkEntityAttributesStr += '<attribute name="' + linEntAttr.$.name + '"/>'
                                });
                            }

                            linkEntityAttributesStr = this.dynamicFormsService.appendFilterCriteria(linEnt, linkEntityAttributesStr,true);

                            linkEntityAttributesStr += "</link-entity>"
                        } catch (error) {
                            console.log(error);
                        }

                    });
                }
          //now iterate through the filter and append the sort criteria defined in the
              linkEntityAttributesStr = this.dynamicFormsService.appendSortCriteria(JSONQuery, linkEntityAttributesStr);
              //now iterate through the filter and append the filter criteria defined in the
              linkEntityAttributesStr = this.dynamicFormsService.appendFilterCriteria(JSONQuery, linkEntityAttributesStr);
            }

            let globalSearchFetch = fetchQueries.configuredFormFetchXMLs.linkEntityPlaceholder;
            globalSearchFetch = globalSearchFetch.replace('{linkEntityName}', linkEntity.subgrid.referencingEntity);
            globalSearchFetch = globalSearchFetch.replace('{linkEntityAttr}', linkEntity.subgrid.referencingAttribute);
            globalSearchFetch = globalSearchFetch.replace('{prentEntityAttr}', linkEntity.subgrid.parentAttribute);
            globalSearchFetch = globalSearchFetch.replace('{linkEntityAlias}', linkEntity.subgrid.referencingEntity);
            globalSearchFetch = globalSearchFetch.replace('{customFilterLevel2}', '');
            globalSearchFetch = globalSearchFetch.replace('{deltasyncFilterLevel2}', '');
            globalSearchFetch = globalSearchFetch.replace('{linkEntityAttributes}', linkEntityAttributesStr);
            globalSearchFetch = globalSearchFetch.replace('{secondaryInfoPlaceholder}', this.secondaryInfoService.SecondaryInfoFetchXML(SecondaryInfoEntityName.Contact));

            if (linkEntity.isSearchable && linkEntity.searchAttributeName) {
                const refEntityNamesToBeAggregated = [
                  CONTACT_CONTACT_AFFILIATIONS_REF_ENTITY,
                ];
                let idx = this.searchConfigService.contactConfiguredSearchIndexConfig.findIndex(conf=> conf.categoryRelativePath == linkEntity.searchAttributeName);
                if(idx >= 0) {
                    let tempConfig = this.searchConfigService.contactConfiguredSearchIndexConfig[idx];
                    tempConfig.linkEntityFetchXML = globalSearchFetch;
                    searchCategory = this.searchConfigService.contactConfiguredSearchIndexConfig[idx];
                } else {
                    const isThisToBeAggregated = refEntityNamesToBeAggregated.includes(linkEntity.subgrid.referencingEntity);
                    let categoryName: string = isThisToBeAggregated
                      ? this.getAggregatedCategoryName(linkEntity)
                      : this.dynamicFormsService.getSearchConfigDisplayText(linkEntity.displayNames, dynamicFormType, linkEntity.isCustom);

                    let multiLingualSearchAttribute = linkEntity.searchAttributeName.split('.');
                    searchCategory = {
                      categoryName,
                      categoryDisplayName: categoryName,
                      controlDataType: 'LinkedEntity',
                      categoryRelativePath: linkEntity.searchAttributeName,
                      values: [],
                      mappingValues: [],
                      parentEntity: linkEntity.subgrid.referencingEntity,
                      entity: linkEntity.subgrid.referencingEntity,
                      linkEntityFetchXML: globalSearchFetch,
                      numOfLinkEntity:  numOfLinkEntity,
                      isThisToBeAggregated,
                      relationshipName: linkEntity.subgrid.relationshipName ?? '',
                      isMultilingualLookup: multilingualAttributes.some(a=> a == multiLingualSearchAttribute[1]),
                    };
                    this.searchConfigService.contactConfiguredSearchIndexConfig.push(searchCategory);
                }
            }

            linkEntityAttributesStr += `<attribute name="statecode"/><attribute name="statuscode"/>`;
            // if linkEntity.subgrid.subgridQuery has statecode condition, deactivated data does not update
            linkEntityAttributesStr = linkEntityAttributesStr.replace('<condition attribute=\"statecode\" operator=\"eq\" value=\"0\"/>', '');
            fetchXML = fetchXML.replace('{linkEntityAttributes}', linkEntityAttributesStr);
            if(loadFromDB) {
                let temp = await this.disk.retrieve(linkEntity.subgrid.referencingEntity);
                if(temp) {

                    this.mapLinkEntityToSearch(linkEntity, searchCategory, temp.raw);
                }
            }else{
              fetchXML = fetchXML.replace(/\s+/g, ' ');
              await this.dynamics.executeFetchQuery('contacts', fetchXML).then(async (res) => {
                    if(ACCOUNT_CONTACT_AFFILIATIONS_REF_ENTITY === linkEntity.subgrid.referencingEntity)
                    res.map(r => {r['indskr_accountcontactaffiliation.indskr_isprimaryaccount']=false; r['indskr_accountcontactaffiliation.indskr_isprimaryaccount_Formatted']="No"; r['indskr_accountcontactaffiliation.indskr_isprimaryaccount@OData.Community.Display.V1.FormattedValue']='No';})
                    let dataToSave = res;
                    let dbKey: string = '';
                    if (linkEntity.subgrid.referencingEntity === CONTACT_CONTACT_AFFILIATIONS_REF_ENTITY) {
                        dbKey = linkEntity.subgrid.relationshipName;
                    } else {
                      if(linkEntity.attributeName == "CloseUpAddresses"){
                        dbKey = linkEntity.subgrid.referencingEntity+"CloseUpAddresses";
                      }
                      else dbKey = linkEntity.subgrid.referencingEntity;
                    }

                    if ((hourDifference || contactid) && Array.isArray(res)) {

                        let temp = await this.disk.retrieve(dbKey);
                        let leIdAttrName = linkEntity.subgrid.referencingEntity + "." + linkEntity.subgrid.referencingEntity + "id";
                        let localRaw;
                        if (temp) {
                            localRaw = temp.raw
                            res.forEach(rawRes => {
                                if (rawRes && rawRes.hasOwnProperty('contactid') && rawRes.hasOwnProperty('contactid') && rawRes[leIdAttrName]) {
                                    if(this.contactInformation && this.contactInformation.ID == rawRes.contactid){
                                        this.refreshUIofCurrentSelectedContactFlag = true;
                                    }
                                    let idx = localRaw.findIndex(a => a && a.hasOwnProperty('contactid') && a[leIdAttrName] == rawRes[leIdAttrName]);
                                    if (idx >= 0) {
                                        if ((rawRes['statecode'] == 1 && rawRes['statuscode'] == 2) || (rawRes[linkEntity.subgrid.referencingEntity+'.statecode'] == 1 && (rawRes[linkEntity.subgrid.referencingEntity+'.statuscode'] == 2 || rawRes[linkEntity.subgrid.referencingEntity+'.statuscode'] == 548910009))) {
                                            localRaw.splice(idx, 1);
                                        } else {
                                            localRaw[idx] = rawRes;
                                        }
                                    } else {
                                        if (rawRes['statuscode'] != 2 && rawRes[linkEntity.subgrid.referencingEntity+'.statuscode'] != 2) {
                                            localRaw.push(rawRes);
                                        }
                                    }
                                }
                            });
                            dataToSave = localRaw;
                        }
                    }
                    dataToSave = dataToSave.filter((data) => data['statecode'] == 0 || data[linkEntity.subgrid.referencingEntity+'.statecode'] == 0);
                    await this.disk.updateOrInsert(dbKey, doc => {
                        doc = {
                            raw: dataToSave,
                        };
                        return doc;
                    }).catch(error => console.error('Save Forms LE to DB error: ', error));

                    this.mapLinkEntityToSearch(linkEntity, searchCategory, dataToSave);

                },
                    (err) => {
                        console.log(err);
                    })
            }
        }))
    }
    private getAggregatedCategoryName(linkEntity: Control): string {
      let categoryName: string = '';
      if (linkEntity) {
        if (linkEntity.subgrid?.referencingEntity === CONTACT_CONTACT_AFFILIATIONS_REF_ENTITY) {
          categoryName = 'CONTACT_AFFILIATIONS';
        }

        categoryName = this.translate.instant(categoryName);
      }
      return categoryName;
    }

    private mapLinkEntityToSearch(linkEntity, searchCategory, linkEntityData) {
        // Add to default Linked entity data for mapping to contact object
        if(linkEntity.subgrid.referencingEntity == 'indskr_email_address' || linkEntity.subgrid.referencingEntity == 'indskr_indskr_customeraddress_v2' || linkEntity.subgrid.referencingEntity == 'indskr_accountcontactaffiliation'){
            this._defaultLinkedEntityMappingData.push(...linkEntityData);
        }
        if (searchCategory) {
            // const myWorker = new Worker('./assets/workers/advanced-search-worker.js');
            // myWorker.onmessage = (event) => {
            //     this.ngZone.run(() => {
            //         //let result = event.data.join(',');
            //         let index = this.searchConfigService.contactConfiguredSearchIndexConfig.findIndex(o => o.categoryRelativePath == searchCategory.categoryRelativePath);
            //         if (index > -1) {
            //             this.searchConfigService.contactConfiguredSearchIndexConfig[index] = event.data
            //         }
            //         else this.searchConfigService.contactConfiguredSearchIndexConfig.push(event.data);
            //         console.log(this.searchConfigService.contactConfiguredSearchIndexConfig);
            //     })
            // };
            //let configIndex = this.searchConfigService.contactConfiguredSearchIndexConfig.find(config=> config.categoryRelativePath== searchCategory.categoryRelativePath)
            //myWorker.postMessage({ type: 'mapLinkEntitySearchIndex', rawList: dataToSave, configIndex: searchCategory });
            this._postMessageOnAdvancedSearchWorker({ type: 'mapLinkEntitySearchIndex', rawList: linkEntityData, configIndex: searchCategory });
            //this.mapContactDataToConfiguredSearchIndex(res);
        }
        if(linkEntity.subgrid.referencingEntity == 'indskr_indskr_customeraddress_v2'){
            this.searchConfigService.contactConfiguredFormDefaultConfigs.forEach(defaultConfig=>{
                let currentConfig;
                let idx = this.searchConfigService.contactConfiguredSearchIndexConfig.findIndex(conf=> conf.categoryRelativePath == defaultConfig.categoryRelativePath);
                if(idx >= 0){
                    currentConfig = this.searchConfigService.contactConfiguredSearchIndexConfig[idx];
                }else{
                    const clonedDefaultConfig: searchIndexDataModel = JSON.parse(JSON.stringify(defaultConfig));
                    if(clonedDefaultConfig.translationKey) {
                      clonedDefaultConfig.categoryDisplayName = this.translate.instant(clonedDefaultConfig.translationKey);
                    }
                    else {
                      clonedDefaultConfig.categoryDisplayName = clonedDefaultConfig.categoryName;
                    }

                    // Custom assignment for Address
                    clonedDefaultConfig.fromAttribute = 'indskr_addressid';
                    clonedDefaultConfig.toAttribute = 'indskr_address';
                    currentConfig = clonedDefaultConfig;
                    this.searchConfigService.contactConfiguredSearchIndexConfig.push(clonedDefaultConfig);
                }
                //Filter out inactive address from customer
                linkEntityData = linkEntityData.filter((data) => data['indskr_indskr_customeraddress_v2.statecode'] == 0);
                this._postMessageOnAdvancedSearchWorker({ type: 'mapLinkEntitySearchIndex', rawList: linkEntityData, configIndex: currentConfig });
            });
        }
    }

    private _initAdvancedSearchServiceWorker() {
        this._advancedSearchConfigServiceWorker = new Worker('./assets/workers/advanced-search-worker.js');
        this._advancedSearchConfigServiceWorker.onmessage = (event) => {
            this.ngZone.run(() => {
                let index = this.searchConfigService.contactConfiguredSearchIndexConfig.findIndex(o => o.categoryRelativePath == event.data.categoryRelativePath);
                if (index > -1) {
                    this.searchConfigService.contactConfiguredSearchIndexConfig[index] = event.data
                }
                else this.searchConfigService.contactConfiguredSearchIndexConfig.push(event.data);
            })
        };
    }

    private _postMessageOnAdvancedSearchWorker(data){
        this._advancedSearchConfigServiceWorker.postMessage(data);
    }

    private _initDefaultLinkedEntityDataMappingServiceWorker() {
        this._defaultLinkedEntityDataMappingServiceWorker = new Worker('./assets/workers/contact-linked-entity-mapping-worker.js');
        this._defaultLinkedEntityDataMappingServiceWorker.onmessage = (event) => {
            this.ngZone.run(() => {
                if(event && event.data && Array.isArray(event.data) && event.data.length != 0){
                    event.data.forEach(item => {
                        if(item.hasOwnProperty('contactid')){
                            let idx = this.contacts.findIndex(con => con.ID == item['contactid']);
                            if(idx >= 0){
                                if(item.hasOwnProperty('emailAddressList')){
                                    this.contacts[idx].emailAddressList = item['emailAddressList'];
                                }
                                if(item.hasOwnProperty('addressesList')){
                                    this.contacts[idx].addressesList = item['addressesList'];
                                }
                                if(item.hasOwnProperty('accountRelationships')){
                                    this.contacts[idx].accountRelationships = item['accountRelationships'];
                                }
                                if(this.contactInformation && this.contactInformation.ID == item['contactid'] && !this.uiService.isConsentFromToolDrawer){
                                    if(!_.isEqual(this.contactInformation,this.contacts[idx])){
                                      this.contactInformation = this.contacts[idx];
                                    }
                                }
                            }
                        }
                    })
                    console.log("OMNI-23311:Finished linked entity service worker mapping")
                    this.events.publish(EventName.FINISHEDSERVICEWORKERMAPPING);
                }
            })
        };
    }

    private _postMessageOnDefaultLinkedEntityDataMappingWorker(data){
        this._defaultLinkedEntityDataMappingServiceWorker.postMessage(data);
    }

    // mapContactDataToConfiguredSearchIndex(rawList){
    //   rawList.forEach(contact => {
    //     let contactDetailEntries = _.entries(contact);
    //     this.searchConfigService.contactConfiguredSearchIndexConfig.forEach(config=>{
    //         let suffix:string = '';
    //         if(config.controlDataType && (config.controlDataType == ControlDataType.LookupType || config.controlDataType == ControlDataType.PicklistType || config.controlDataType == ControlDataType.MultiSelectPicklistType || config.controlDataType == ControlDataType.BooleanType)){
    //             suffix = '@OData.Community.Display.V1.FormattedValue'
    //         }
    //         let idx = contactDetailEntries.findIndex(entry=> entry[0] == config.categoryRelativePath+suffix);
    //         if(idx >= 0){
    //             if(!config.values.find(a=> a == String(contactDetailEntries[idx][1]))){
    //                 config.values.push(String(contactDetailEntries[idx][1]));
    //             }
    //         }
    //     });

    //     // contactDetailEntries.forEach(entry => {
    //     //     let attribName:string = entry[0];
    //     //     let attribValue:string = String(entry[1]);
    //     //     if(attribName.includes('@OData.Community.Display.V1.FormattedValue')){
    //     //         attribName = attribName.replace('@OData.Community.Display.V1.FormattedValue','')
    //     //     }
    //     //     if(attribName && attribValue){
    //     //         if(this.searchConfigService.contactConfiguredSearchIndexConfig.find(config=> config.categoryRelativePath== attribName) && !this.searchConfigService.contactConfiguredSearchIndexConfig.find(config=> config.categoryRelativePath== attribName).values.some(o=>o == attribValue)){
    //     //         //@ts-ignore
    //     //         this.searchConfigService.contactConfiguredSearchIndexConfig.find(config=> config.categoryRelativePath == attribName).values.push(attribValue)
    //     //         }
    //     //     }
    //     // })
    //   });
    // }

    async fetchContactsDefaultLinkEntityData(positionFilter, hourDifference, linkedEntityFromForm:any[], contactid, loadFromDB,isForGlobalSearchedContact = false){
      return Promise.all(FETCH_CONTACTS_LINK_ENTITES.map(async (linkEntity)=>{
      //for(var i=0; i < FETCH_CONTACTS_LINK_ENTITES.length; i++) {
        //(linkEntity => {
          //let linkEntity = FETCH_CONTACTS_LINK_ENTITES[i];
          if(!linkEntity.isFetch || linkedEntityFromForm.find(l=> l.subgrid.referencingEntity == linkEntity.entityName)) return;
          if(linkEntity.featureAction && !this.authService.hasFeatureAction(linkEntity.featureAction)) return;
          let fetchXML = linkEntity.fetchXML;
          fetchXML = fetchXML.replace('{PositionFilterPH}', positionFilter);

          //modifiedon filter
          if(hourDifference && !isForGlobalSearchedContact) {
            let lastModifiedFilter = `
                <filter type="and">
                    <condition attribute="modifiedon" operator="last-x-hours" value="`+hourDifference+`" />
                    {ContactIdFilter}
                </filter>`;
            if(contactid){
              let contactidFilter = `<condition attribute="contactid" operator="eq" value="` + contactid + `"/>`
              if(linkEntity.entityName == 'indskr_kolstatus' || linkEntity.entityName == 'indskr_customerinteractioninsights'){
                contactidFilter = `<condition attribute="indskr_customer" operator="eq" value="` + contactid + `"/>`
              }
              lastModifiedFilter = lastModifiedFilter.replace('{ContactIdFilter}', contactidFilter);
            }else{
              lastModifiedFilter = lastModifiedFilter.replace('{ContactIdFilter}', '');
            }
            fetchXML = fetchXML.replace('{DeltaSyncFilter}', lastModifiedFilter);
          }
          else {
            if(contactid){
              let contactidFilter = `
                <filter type="and">
                  <condition attribute="contactid" operator="eq" value="` + contactid + `"/>
                </filter>`;
              fetchXML = fetchXML.replace('{DeltaSyncFilter}', contactidFilter);
            }else{
              fetchXML = fetchXML.replace('{DeltaSyncFilter}', '');
            }
          }

          if(loadFromDB){
            let dataToSave = await this.disk.retrieve(linkEntity.entityName);
              // Add to default Linked entity data for mapping to contact object
            if(linkEntity.entityName == 'indskr_email_address' || linkEntity.entityName == 'indskr_indskr_customeraddress_v2' || linkEntity.entityName == 'indskr_accountcontactaffiliation'){
              if(dataToSave && Array.isArray(dataToSave.raw))
                this._defaultLinkedEntityMappingData.push(...dataToSave.raw);
            }
          }else{
            fetchXML = fetchXML.replace(/\s+/g, ' ');
            await this.dynamics.executeFetchQuery("contacts", fetchXML).then( async (res) => {
                let dataToSave = res;
                if ((hourDifference || contactid) && Array.isArray(res)) {

                    let temp = await this.disk.retrieve(linkEntity.entityName);
                    let leIdAttrName = linkEntity.entityName + "." + linkEntity.entityName + "id";
                    let localRaw;
                    if (temp) {
                        localRaw = temp.raw
                        res.forEach(rawRes => {
                            if (rawRes && rawRes.hasOwnProperty('contactid') && rawRes.hasOwnProperty(leIdAttrName)) {
                                if(this.contactInformation && this.contactInformation.ID == rawRes.contactid){
                                    this.refreshUIofCurrentSelectedContactFlag = true;
                                }
                                let idx = localRaw.findIndex(a => a && a.hasOwnProperty('contactid') && a[leIdAttrName] == rawRes[leIdAttrName]);
                                if (idx >= 0) {
                                    if (rawRes['statecode'] == 1 && rawRes['statuscode'] == 2) {
                                        localRaw.splice(idx, 1);
                                    } else {
                                        localRaw[idx] = rawRes;
                                    }
                                } else {
                                    if (rawRes['statuscode'] != 2) {
                                        localRaw.push(rawRes);
                                    }
                                }
                            }
                        });
                        dataToSave = localRaw;
                    }

                    if(hourDifference && linkEntity.entityName == "annotation") {
                        let deletedRes = await this.fetchDeletedFromTrackChange(linkEntity.entityName, hourDifference);
                        if(deletedRes && Array.isArray(deletedRes)) {
                            deletedRes.forEach(dRes => {
                                if (dRes) {
                                    let idx = dataToSave.findIndex(a => a && a.hasOwnProperty('contactid') && a[leIdAttrName] == dRes["indskr_entityid"]);
                                    if (idx >= 0) {

                                        if(this.contactInformation && this.contactInformation.ID == dataToSave[idx].contactid){
                                            this.refreshUIofCurrentSelectedContactFlag = true;
                                        }
                                        dataToSave.splice(idx, 1);
                                    }
                                }
                            })
                        }
                    }
                }
                // Add to default Linked entity data for mapping to contact object
                if(linkEntity.entityName == 'indskr_email_address' || linkEntity.entityName == 'indskr_indskr_customeraddress_v2' || linkEntity.entityName == 'indskr_accountcontactaffiliation'){
                    this._defaultLinkedEntityMappingData.push(...dataToSave);
                }
                await this.disk.updateOrInsert(linkEntity.entityName, doc => {
                    doc = {
                      raw: dataToSave,
                    };
                    return doc;
                  })
                  .catch(error => console.error('Save Default LE to DB error: ', error));
              },
              (err) => {
                console.log(err);
              })
          }
        }));
    }

  async fetchContactsSpecificLinkEntityData(targetLinkedEntity: string, contactid) {
    return Promise.all(
      FETCH_CONTACTS_LINK_ENTITES_BY_CONTACTID.map(async (linkEntity) => {
        if (targetLinkedEntity != linkEntity.entityName) return;
        let contactFilter = `<filter><condition attribute="indskr_contactid" operator="eq" value="{0}" /></filter>`
        let fetchXML = linkEntity.fetchXML;
        fetchXML = fetchXML.replace('{ContactFilter}', contactFilter);
        fetchXML = fetchXML.replace('{0}', contactid);
        await this.dynamics.executeFetchQuery("contacts", fetchXML).then(async (res) => {
          let dataToSave = '';
          if ((contactid) && Array.isArray(res)) {
            let temp = await this.disk.retrieve(linkEntity.entityName);
            let leIdAttrName = linkEntity.entityName + "." + linkEntity.entityName + "id";
            let localRaw;
            if (!_.isEmpty(temp)) {
              localRaw = temp.raw;
              let isUpdated: boolean = false;
              res.forEach(rawRes => {
                if (rawRes && rawRes.hasOwnProperty('contactid') && rawRes.hasOwnProperty(leIdAttrName)) {
                  if (this.contactInformation && this.contactInformation.ID == rawRes.contactid) {
                    this.refreshUIofCurrentSelectedContactFlag = true;
                  }
                  let idx = localRaw.findIndex(a => a && a.hasOwnProperty('contactid') && a[leIdAttrName] == rawRes[leIdAttrName]);
                  if (idx >= 0) {
                    isUpdated = true;
                    if (rawRes['statecode'] == 1 && rawRes['statuscode'] == 2) {
                      localRaw.splice(idx, 1);
                    } else {
                      localRaw[idx] = rawRes;
                    }
                  } else {
                    if (rawRes['statuscode'] != 2) {
                      isUpdated = true;
                      localRaw.push(rawRes);
                    }
                  }
                }
              });
              dataToSave = localRaw;
              if (isUpdated && !_.isEmpty(dataToSave)) {
                this._defaultLinkedEntityMappingData.push(...dataToSave);
                await this.disk.updateOrInsert(linkEntity.entityName, doc => {
                  doc = { raw: dataToSave, };
                  return doc;
                })
                  .catch(error => console.error('Save Updated LE to DB error: ', error))
              }
            }
          }
        }, (err) => { console.log(err); })
      })
    );
  }

  public mapDFContact(raw, localData, isResetMemoryData, contactsToDelete?) {
        if(isResetMemoryData) {
            this.contacts = [];
        }
        else {
            if(contactsToDelete && Array.isArray(contactsToDelete)) {
              let MDConfig = this.searchConfigService.contactConfiguredSearchIndexConfig.find(o=>o.categoryName == 'MDM ID');
                contactsToDelete.forEach(con => {
                    if (con) {
                      let MDMID;
                        let idx = this.contacts.findIndex(a => a && a.hasOwnProperty('ID') && a['ID'] == con["indskr_entityid"]);
                        if (idx >= 0) {
                            MDMID = this.contacts[idx].indskr_mdmid
                            this.contacts.splice(idx, 1);
                        }
                        if(MDConfig && MDMID){
                          MDConfig.values = MDConfig.values.filter(v=>v!=MDMID)
                        }
                    }
                })
            }
        }



        let dataTomMap = Array.isArray(this.contacts) && this.contacts.length > 0 ? raw : localData;
        dataTomMap = _.uniqBy(dataTomMap,'contactid');
        dataTomMap.forEach(el => {
            if(el && el.hasOwnProperty('contactid')){
                let tempCon = new Contact(el);
                if(isResetMemoryData) {
                    this.contacts.push(tempCon);
                } else {
                    let idx = this.contacts.findIndex(a=> a.ID == el.contactid);
                    if(idx >= 0){
                        tempCon.accountRelationships = this.contacts[idx].accountRelationships;
                        tempCon.addressesList = this.contacts[idx].addressesList;
                        tempCon.emailAddressList = this.contacts[idx].emailAddressList;
                        tempCon.activitesByContact = this.contacts[idx].activitesByContact;
                        tempCon.activitesTimeline = this.contacts[idx].activitesTimeline;
                        tempCon.productSegmentations = this.contacts[idx].productSegmentations;
                        this.contacts[idx] = tempCon;
                    } else {
                        this.contacts.push(tempCon);
                    }
                }
                //this.mapContactFieldsToSearchIndex(tempCon);
                let contactDetailEntries = _.entries(el);
                this.searchConfigService.contactConfiguredSearchIndexConfig.forEach(config=>{
                    let suffix:string = '';
                    let prefix:string = '';
                    if(config.controlDataType){
                        if(config.controlDataType == ControlDataType.PicklistType || config.controlDataType == ControlDataType.MultiSelectPicklistType
                          || config.controlDataType == ControlDataType.BooleanType
                          || config.controlDataType == ControlDataType.StatusType || config.controlDataType == ControlDataType.StateType){
                            suffix = '@OData.Community.Display.V1.FormattedValue'
                        }else if(config.controlDataType == ControlDataType.LookupType){
                          if(config.isMultilingualLookup){
                            suffix = '_value';
                            prefix = '';
                          }else{
                            suffix = '_value@OData.Community.Display.V1.FormattedValue'
                            prefix = '_';
                          }
                        }
                    }
                    let idx = contactDetailEntries.findIndex(entry=> entry[0] == prefix+config.categoryRelativePath+suffix);
                    if(idx >= 0){
                        if(!config.values.find(a=> a == String(contactDetailEntries[idx][1]))){
                            config.values.push(String(contactDetailEntries[idx][1]));
                            let mappingValue = {
                              actualValue: el[config.categoryRelativePath],
                              formattedValue: String(contactDetailEntries[idx][1]),
                            };
                            if(config.controlDataType == ControlDataType.LookupType){
                              mappingValue = {
                                actualValue: el['_'+config.categoryRelativePath+'_value'],
                                formattedValue: String(contactDetailEntries[idx][1]),
                              };
                            }
                            config.mappingValues.push(mappingValue);
                        }
                    }
                });
            }
        });

        this.sortContactsBySelectedSortOption();
    }

    // public async fetchEmailsFromDBAndMapToContacts(contacts:Contact[]):Promise<Contact[]>{
    //     let rawData = await this.disk.retrieve(OFFLINE_DB_LINKED_ENTITY_NAME.EMAIL);
    //     if(rawData && rawData.raw && Array.isArray(rawData.raw) && rawData.raw.length != 0){
    //       rawData.raw.forEach(item => {
    //         if(item.hasOwnProperty('contactid') && item.hasOwnProperty('indskr_email_address.indskr_email_addressid') && item.hasOwnProperty('indskr_email_address.indskr_emailaddress') && item.contactid){
    //           let idx = contacts.findIndex(con => con.ID == item.contactid);
    //           if(idx >= 0){
    //             let currentEmail:Email = {
    //               emailAddressId: item['indskr_email_address.indskr_email_addressid'],
    //               emailAddress: item['indskr_email_address.indskr_emailaddress'],
    //               isPrimary: item['indskr_email_address.indskr_isprimary'],
    //               isVerified: (item['indskr_email_address.indskr_approvalstatus'] && item['indskr_email_address.indskr_approvalstatus'] == 548910000)?false:true,
    //               isSelected: false,
    //               isSelectable: false,
    //             };
    //             if(contacts[idx].emailAddressList){
    //               let emailIdx = contacts[idx].emailAddressList.findIndex(email => email.emailAddressId == item['indskr_email_address.indskr_email_addressid']);
    //               if(emailIdx >= 0){
    //                 contacts[idx].emailAddressList[emailIdx] = currentEmail;
    //               }else{
    //                 contacts[idx].emailAddressList.push(currentEmail);
    //               }
    //             }else{
    //               contacts[idx].emailAddressList.push(currentEmail);
    //             }
    //           }
    //         }
    //       });
    //     }
    //     return contacts;
    // }

    // public async fetchAddressesFromDBAndMapToContacts(contacts:Contact[]):Promise<Contact[]>{
    //     let rawData = await this.disk.retrieve(OFFLINE_DB_LINKED_ENTITY_NAME.ADDRESSES);
    //     if(rawData && rawData.raw && Array.isArray(rawData.raw) && rawData.raw.length != 0){
    //       rawData.raw.forEach(item => {
    //         if(item.hasOwnProperty('contactid') && item.hasOwnProperty('indskr_indskr_customeraddress_v2.indskr_address') && item.contactid){
    //           let idx = contacts.findIndex(con => con.ID == item.contactid);
    //           if(idx >= 0){
    //             let currentAddress:ContactAddress = {
    //                 city: item['indskr_address.indskr_city_lu@OData.Community.Display.V1.FormattedValue']||'',
    //                 state: item['indskr_address.indskr_state_lu@OData.Community.Display.V1.FormattedValue']||'',
    //                 postal: item['indskr_address.indskr_postalcode_lu@OData.Community.Display.V1.FormattedValue']||'',
    //                 country: item['indskr_address.indskr_country_lu@OData.Community.Display.V1.FormattedValue']||'',
    //                 countryCode: item['indskr_address.indskr_countrycode_lu@OData.Community.Display.V1.FormattedValue']||'',
    //                 street: item['indskr_address.indskr_line1']||'',
    //                 street2: item['indskr_address.indskr_line2']||'',
    //                 street3: item['indskr_address.indskr_line3']||'',
    //                 region: item['indskr_address.indskr_luregion@OData.Community.Display.V1.FormattedValue']||'',
    //                 postOfficeBox: item['indskr_address.indskr_postofficebox@OData.Community.Display.V1.FormattedValue']||'',
    //                 isPrimary: item['indskr_indskr_customeraddress_v2.indskr_isprimary']||'',
    //                 addressId: item['indskr_indskr_customeraddress_v2.indskr_address']||'',
    //                 compositeAdd: item['indskr_indskr_customeraddress_v2.indskr_address@OData.Community.Display.V1.FormattedValue']||'',
    //                 primaryContact: item['indskr_indskr_customeraddress_v2.indskr_primarycontact@OData.Community.Display.V1.FormattedValue']||'',
    //                 fax: item['indskr_indskr_customeraddress_v2.indskr_fax@OData.Community.Display.V1.FormattedValue']||'',
    //                 fax2: item['indskr_indskr_customeraddress_v2.indskr_fax2@OData.Community.Display.V1.FormattedValue']||'',
    //                 telephone1: item['indskr_indskr_customeraddress_v2.indskr_telephone1@OData.Community.Display.V1.FormattedValue']||'',
    //                 telephone2: item['indskr_indskr_customeraddress_v2.indskr_telephone2@OData.Community.Display.V1.FormattedValue']||'',
    //                 isSampleEligible: item['indskr_indskr_customeraddress_v2.indskr_samplingeligible'],
    //                 customerAddressID: item['indskr_indskr_customeraddress_v2.indskr_address']||'',
    //                 concatanatedAddress: item['indskr_indskr_customeraddress_v2.indskr_address@OData.Community.Display.V1.FormattedValue']||'',
    //                 latitude: item['indskr_address.indskr_latitude']||'',
    //                 longitude: item['indskr_address.indskr_longitude:']||'',
    //             };
    //             if(contacts[idx].addressesList){
    //               let addIdx = contacts[idx].addressesList.findIndex(address => address.addressId == item['indskr_indskr_customeraddress_v2.indskr_address']);
    //               if(addIdx >= 0){
    //                 contacts[idx].addressesList[addIdx] = currentAddress;
    //               }else{
    //                 contacts[idx].addressesList.push(currentAddress);
    //               }
    //             }else{
    //               contacts[idx].addressesList.push(currentAddress);
    //             }
    //           }
    //         }
    //       });
    //     }
    //     return contacts;
    // }


    async purgeUnusedLinkEntity(contactLinkEntities) {
        //Todo: Purge linked entites that's not in the form anymorwe


        await this.disk.updateOrInsert("contactLinkEntities", doc => {
            doc = {
                raw: contactLinkEntities,
            };
            return doc;
        }).catch(error => console.error('Saving list of link entities ', error));

    }

    async mapProductSegmentationsToContactsForShortCallHome(contactsToMap: Contact[]) {
      try {
        const offlineData = await this.disk.retrieve(DB_KEY_PREFIXES.CONTACT_PROFILE_OFFLINE_DATA);
        const rawProductSegmentations = offlineData?.raw?.hasOwnProperty('productSegmentations') && Array.isArray(offlineData.raw.productSegmentations)
          ? offlineData.raw.productSegmentations : [];

        for (let i = 0; i < contactsToMap.length; i++) {
          const contact = contactsToMap[i];
          contact.productSegmentations = [];
        }

        for (let i = 0; i < rawProductSegmentations.length; i++) {
          try {
            const rawProductSegmentation = rawProductSegmentations[i];
            const contact = contactsToMap.find(c => c.ID === rawProductSegmentation.contactId);
            if (contact) {
              const productSegmentation: ProductSegmentation = {
                intimacy: rawProductSegmentation.intimacy || null,
                intimacyId: rawProductSegmentation.intimacyid || null,
                segmentation: rawProductSegmentation.segmentation || null,
                segmentationId: rawProductSegmentation.segmentationid || null,
                productName: rawProductSegmentation.productname || null,
                productId: rawProductSegmentation._indskr_product_value || null,
                productRatingId: rawProductSegmentation.indskr_productratingid || null,
                marketPotential: rawProductSegmentation._indskr_marketpotential_new_value || null
              };
              contact.productSegmentations.push(productSegmentation);
            }
          } catch (error) {
            console.error('mapProductSegmentationsToContactsForShortCallHome: forLoop: ', error);
          }
        }
      } catch (error) {
        console.error('mapProductSegmentationsToContactsForShortCallHome: ', error);
      }
    }

    public async mapContactProfileOfflineDataToContacts() {
        let offlineData = await this.disk.retrieve(DB_KEY_PREFIXES.CONTACT_PROFILE_OFFLINE_DATA);

        let myCallplanData = await this.disk.retrieve(DB_KEY_PREFIXES.MY_POSITON_CALL_PLANS);
        //let otherCallPlanData = await this.disk.retrieve(DB_KEY_PREFIXES.OTHER_POSITON_CALL_PLANS);
        this.contacts.forEach(con => {
            con.productSegmentations = [];
            con.repCallPlansByContact = [];
            con.scientificInfo = new ScientificInfo([],[],[],[],[])
        })
        if(offlineData && offlineData.raw){
            if(offlineData.raw['productSegmentations']){
                offlineData.raw['productSegmentations'].forEach(item=>{
                    if(item && item.hasOwnProperty('contactId')){
                        let addObj: ProductSegmentation = {
                            intimacy: item.intimacy || null,
                            intimacyId: item.intimacyid || null,
                            segmentation: item.segmentation || null,
                            segmentationId: item.segmentationid || null,
                            productName: item.productname || null,
                            productId: item._indskr_product_value || null,
                            productRatingId: item.indskr_productratingid || null,
                            marketPotential: item._indskr_marketpotential_new_value || null
                        };
                        let contact = this.getContactByID(item.contactId);
                        if(contact){
                            contact.productSegmentations.push(addObj);
                            const foundProduct = this.searchConfigService.contactConfiguredSearchIndexConfig?.find(config=> config.categoryRelativePath == 'productSegmentations.productName');
                            if(foundProduct && foundProduct.values) {
                              let checkProductName = foundProduct.values.some(o=>o == addObj.productName);
                              if(!checkProductName && addObj.productId != null && addObj.productName != null) {
                                this.searchConfigService.contactConfiguredSearchIndexConfig.find(config=> config.categoryRelativePath == 'productSegmentations.productName').values.push(addObj.productName);
                              }
                            }
                            const foundSegmentation = this.searchConfigService.contactConfiguredSearchIndexConfig?.find(config=> config.categoryRelativePath == 'productSegmentations.segmentation');
                            if(foundSegmentation && foundSegmentation.values) {
                              let checkSegmentation = foundSegmentation.values.some(o=>o == addObj.segmentation);
                              if(!checkSegmentation && addObj.segmentationId != null && addObj.segmentation != null) {
                                this.searchConfigService.contactConfiguredSearchIndexConfig.find(config=> config.categoryRelativePath == 'productSegmentations.segmentation').values.push(addObj.segmentation);
                              }
                            }
                        }
                    }
                });
            }
            if(offlineData.raw['scientificInformation']){
                const scientificInfo = offlineData.raw['scientificInformation'];
                if (scientificInfo.publications && Array.isArray(scientificInfo.publications)) {
                    scientificInfo.publications.forEach(publication => {
                        if (publication.hasOwnProperty('indskr_contactid')) {
                            let contact = this.getContactByID(publication.indskr_contactid);
                            if (contact) {
                                contact.scientificInfo.publications.push(publication);
                            }
                        }
                    });
                }
                if (scientificInfo.researches && Array.isArray(scientificInfo.researches)) {
                    scientificInfo.researches.forEach(research => {
                        if (research.hasOwnProperty('indskr_contactid')) {
                            let contact = this.getContactByID(research.indskr_contactid);
                            if (contact) {
                                contact.scientificInfo.researches.push(research);
                            }
                        }
                    });
                }
                if (scientificInfo.speakerEngagements && Array.isArray(scientificInfo.speakerEngagements)) {
                    scientificInfo.speakerEngagements.forEach(speakerEngagement => {
                        if (speakerEngagement.hasOwnProperty('indskr_contactid')) {
                            let contact = this.getContactByID(speakerEngagement.indskr_contactid);
                            if (contact) {
                                contact.scientificInfo.speakerEngagements.push(speakerEngagement);
                            }
                        }
                    });
                }
                if (scientificInfo.eventHistory && Array.isArray(scientificInfo.eventHistory)) {
                    scientificInfo.eventHistory.forEach(event => {
                        if (event.hasOwnProperty('indskr_contactid')) {
                            let contact = this.getContactByID(event.indskr_contactid);
                            if (contact) {
                                contact.scientificInfo.eventHistory.push(event);
                            }
                        }
                    });
                }
                if (scientificInfo.sessionHistory && Array.isArray(scientificInfo.sessionHistory)) {
                    scientificInfo.sessionHistory.forEach(session => {
                        if (session.hasOwnProperty('indskr_contactid')) {
                            let contact = this.getContactByID(session.indskr_contactid);
                            if (contact) {
                                contact.scientificInfo.sessionHistory.push(session);
                            }
                        }
                    });
                }
            }
        }
        if(myCallplanData && myCallplanData.raw){
          if(Array.isArray(myCallplanData.raw)){
            myCallplanData.raw.forEach(item=>{
                if (item && item.hasOwnProperty('contactId') && item.hasOwnProperty('indskr_hoemails') && item.hasOwnProperty('indskr_hocalls')) {
                    let addObj: ContactRepCallPlan = {
                        name: item.indskr_name || '',
                        createDate: item.createdon || null,
                        productName: item.productid_Formatted || '',
                        firstName: item.contactFirstName || '',
                        lastName: item.contactLastName || '',
                        stateCode: item.statecode || '',
                        emailGoals: item.indskr_hoemails || '',
                        meetingGoals: item.indskr_hocalls || '',
                        emailsComplete: item.indskr_actualemails || 0,
                        meetingComplete: item.indskr_actualcalls || 0,
                        emailCompletePercentage: item.indskr_actualemails / item.indskr_hoemails * 100 || 0,
                        meetingCompletePercentage: item.indskr_actualcalls / item.indskr_hocalls * 100 || 0,
                        startDate: item.indskr_startdate || null,
                        endDate: item.indskr_enddate || null,
                        callPlanID: item.indskr_customercallplanid,
                        callPlanTimeFrame: isPast(new Date(item.indskr_enddate)) ? 'Past' : isFuture(new Date (item.indskr_startdate)) ? 'Future' : 'Present',
                        callPlanStatus: item.statuscode,
                        segmentCallPlanName: item.cycleplanname,
                        // segmentName: item.segmentName,
                        // suggestedCalls: item.indskr_suggestedcalls || 0,
                        // suggestedMails: item.indskr_suggestedemails || 0,
                        // totalCompletedEmails: item.indskr_totalcompletedemails || 0,
                        // totalCompletedMeetings: item.indskr_totalcompletedmeetings || 0,
                        // totalPositionEmailGoals: item.indskr_totalpositionemailgoals || 0,
                        // totalPositionMeetingGoals: item.indskr_totalpositionmeetinggoals || 0,
                        // timerRemaining: item.indskr_timeremaining,
                        positionId: item.positionId,
                        positionName: item.positionName || ''
                    };
                    let contact = this.getContactByID(item.contactId);
                    if(contact){
                        if(!this.searchConfigService.contactConfiguredSearchIndexConfig.find(config=> config.categoryRelativePath == 'repCallPlansByContact.segmentCallPlanName').values.some(o=>o == addObj.segmentCallPlanName)){
                            this.searchConfigService.contactConfiguredSearchIndexConfig.find(config=> config.categoryRelativePath == 'repCallPlansByContact.segmentCallPlanName').values.push(addObj.segmentCallPlanName)
                        }
                        if(contact.repCallPlansByContact){
                            let idx = contact.repCallPlansByContact.findIndex(a=> a.callPlanID == item.indskr_customercallplanid);
                            if(idx >= 0){
                                contact.repCallPlansByContact[idx] = addObj;
                            }else{
                                contact.repCallPlansByContact.push(addObj);
                            }
                        }else{
                            contact.repCallPlansByContact.push(addObj);
                        }
                    }
                }
            });

        }
        }
    }

    async fetchDeletedFromTrackChange(entity, hourDifference: any, primaryPositionID?:string){
        try{
            let res = [];
            let fetchXML = `
                <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
                    <entity name="indskr_trackchange">
                        <attribute name="createdon"/>
                        <attribute name="indskr_action"/>
                        <attribute name="indskr_entityid"/>
                        <filter type="and">
                            <condition attribute="createdon" operator="last-x-hours" value="`+ hourDifference+`" />
                            <condition value="` + entity  + `" attribute="indskr_entityname" operator="eq" />
                            <condition value="548910001" attribute="indskr_action" operator="eq" />
                            {positionIDFilter}
                        </filter>
                    </entity>
                </fetch>`
              if(!primaryPositionID){
                fetchXML = fetchXML.replace('{positionIDFilter}','')
              }
              else{
                let positionCondition = '<condition value="'+primaryPositionID+'" attribute="indskr_positionid" operator="eq" />'
                fetchXML = fetchXML.replace('{positionIDFilter}',positionCondition);
              }
            res = await this.dynamics.executeFetchQuery('indskr_trackchanges',fetchXML)
            return res
        } catch (error){
            console.log(error)
        }
    }

    private async syncInteractionDataForContacts(doFullSyn: boolean, lastUpdatedTime?): Promise<any> {
      let url = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.contacts.GET_CONTACT_INTERACTION;
      url = url.replace('{{positionIDs}}', this.authService.getLoggedInUserPositions().toString());
      url = url.replace('{startDate}', this.authService.getFromToDateRangeInUTCMiliSec(undefined, undefined).from);
      url = (doFullSyn) ? url : url + '&lastUpdatedTime=' + lastUpdatedTime;
      const contactInteractionSyncInfo: EntitySyncInfo = {
          entityName: EntityNames.contactInteraction,
          totalFailed: 0,
          totalSynced: 0,
          errors: [],
          syncStatus: true
      };
      let response: any;
      try {
          response = await this.http.get(url, Endpoints.GLOBAL_SYNC_HEADER).pipe(timeout(25000)).toPromise();;
      } catch (error) {
          console.error('syncContactsInteraction: ', error);
          this.deltaService.addSyncErrorToEntitySyncInfo(contactInteractionSyncInfo, url, error);
          response = [];
      }
      return response;
  }

  public async createVeevaContact(entity: any): Promise<any> {
    const url = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.contacts.CREATE_VEEVA_CONTACT;
    let headers = new HttpHeaders();
    if (!entity.isNew) {
      headers = headers.set("X-PositionId", this.authService.user.xPositionID);
    }
    return await this.http.post(url, [entity], { headers }).toPromise();
  }

  createGlobalSearchFetchXML(selectedSuggestionsData: SelectedSuggestionPillDataModel[], donotAddBUFilter:boolean = false): string[] {
    let fetchXMLs: string[] = [];

    if (Array.isArray(selectedSuggestionsData) && selectedSuggestionsData.length > 0) {
      let positionCon: Condition;
      let fullnameFilter = "";
      let linkedEntity = [{lk: "", weight: 0, filter:"", entity:[]}]; //use to create fetchxml could be 1 or more
      let searchQueries: Condition[] = [];

      // always add entities thats needs to be displayed
      for(let i of ["indskr_customerposition"]) {
        let searchConfig = this.searchConfigService.contactsSearchIndexesConfig.find(x => x.entity == i);
        let con: Condition = {
          categoryName: searchConfig.categoryName,
          categoryRelativePath: searchConfig.categoryRelativePath,
          charSearchtext: "",
          value: "",
          attributeName: searchConfig.attributeName,
          parentEntity: searchConfig.parentEntity,
          entity: searchConfig.entity,
          searchType: null
        }
        if(i == "indskr_customerposition") {
          positionCon = con;
        }
        else {
          searchQueries.push(con);
        }

        let ew = this.searchConfigService.linkedEntityWeight.find(x => x.entity == con.parentEntity);
        linkedEntity[0].weight += ew.weight;
        if(i != "indskr_customerposition") linkedEntity[0].lk += CONTACT_FETCH_QUERIES[con.parentEntity];
        linkedEntity[0].entity.push(con.parentEntity);
      }

      // Loop through all the search criteria
      for (let data of selectedSuggestionsData) {

        let tempCon = searchQueries.find(x => x.categoryRelativePath == this.getAttributeName(data.categoryPath));

        if(!tempCon && data.type != SuggestionPillType.ENTITY_LEVEL_CHARACTERSEARCH) {
          let searchConfig = this.searchConfigService.contactConfiguredSearchIndexConfig.find(x => x.categoryRelativePath == data.categoryPath);
          if(searchConfig && (searchConfig.controlDataType == ControlDataType.PicklistType
            || ((searchConfig.controlDataType == ControlDataType.LookupType || searchConfig.controlDataType == ControlDataType.LinkedEntity) && data.type == SuggestionPillType.CATEGORY_VALUESEARCH))) {
            data = JSON.parse(JSON.stringify(data));
            let mappingValue = searchConfig.mappingValues.find(mv=>mv.formattedValue==data.selectedFacet);
            data.selectedFacet = mappingValue.actualValue;
          }
          let con = {
            categoryName: searchConfig ? searchConfig.categoryName : "",
            categoryRelativePath: searchConfig ? searchConfig.categoryRelativePath : "",
            charSearchtext: "",
            value: "",
            attributeName: (searchConfig && searchConfig.isMultilingualLookup) ? this.getAttributeNameOneKeyCodeLabels(searchConfig.categoryRelativePath, data.type) : (searchConfig ? this.getAttributeName(searchConfig.categoryRelativePath, data.type) : ""),
            parentEntity: searchConfig ? searchConfig.parentEntity : "",
            entity: (searchConfig && searchConfig.isMultilingualLookup) ? ('omnione_onekeycodeslabels') : (searchConfig ? searchConfig.entity : ""),
            searchType: data.type,
            controlType: searchConfig && searchConfig.controlDataType ? searchConfig.controlDataType : '',
          }
          searchQueries.push(con);
          tempCon = searchQueries.find(x => x.categoryRelativePath == searchConfig.categoryRelativePath);

          // let ew = this.searchConfigService.linkedEntityWeight.find(x => x.entity == tempCon.entity);
          // if(!ew) {
          //   this.notificationService.notify(con.categoryName + " is not supported for global search", "");
          //   return;
          // };

          //Keep track if we need to create new fetch xml
          let isMaxedLinkedEntity = true;
          for(let l of linkedEntity) {
            if(l.entity.some(x => x == searchConfig.parentEntity)) {
              isMaxedLinkedEntity = false;
              break;
            }
            if(l.weight < 10 && l.weight + searchConfig.numOfLinkEntity < 10) {
              l.weight += searchConfig.numOfLinkEntity;
              l.lk += searchConfig.linkEntityFetchXML;
              l.entity.push(searchConfig.parentEntity);
              isMaxedLinkedEntity = false;
            }
          }

          // if first xml reaches the maximum linked entity create a new one
          if(isMaxedLinkedEntity) {
            let temp = {
              lk: searchConfig.linkEntityFetchXML,
              weight: searchConfig.numOfLinkEntity,
              filter:"",
              entity:[searchConfig.parentEntity]
            }
            linkedEntity.push(temp);
          }

        }

        // create the value for filters
        // one attribute could have multiple search values
        if(data.type == SuggestionPillType.ENTITY_LEVEL_CHARACTERSEARCH) {
          fullnameFilter = this.createCondition("like", "fullname", data.charSearchText);
        } else if (data.type == SuggestionPillType.CATEGORY_CHARACTERSEARCH || data.type == SuggestionPillType.BOOLEAN_FIELD) {
          if(data.categoryName == "Coverage Team") {
            positionCon.charSearchtext = data.charSearchText;
          } else {
            tempCon.charSearchtext = data.charSearchText;
          }
        } else if (data.type == SuggestionPillType.CATEGORY_VALUESEARCH) {
          if(data.categoryName == "Coverage Team") {
            positionCon.value += '<value>' + data.selectedFacet + '</value>'
          } else if (tempCon.entity == 'omnione_onekeycodeslabels') {
            tempCon.value += data.selectedFacet;
          } else {
            tempCon.value += '<value>' + data.selectedFacet + '</value>';
          }
        }

      }

      for(let q of searchQueries) {
        let temp = linkedEntity.find(x => x.entity.some(y => y == q.parentEntity))
        if(temp) {
          //Special case for phone number since we have to search 3 attributes for 1 category
          if(q.entity == "phoneNumber") {
            temp.filter += `<filter type="or">`
            for(let num of ["mobilephone", "indskr_alternatephone1", "indskr_alternatephone2"]) {
              q.attributeName = num;
              temp.filter += this.createFilter(q);
            }
            temp.filter += `</filter>`
          } else {
            temp.filter += this.createFilter(q);
          }
        }
      }

      let positionFilter
      if(!(positionCon.charSearchtext || positionCon.value)){
        let positionIds = this.authService.user.positions.map(o => {
          return o.ID
        });

        let positionString = '';
        positionIds.forEach(p => {
          positionString += '<value>' + p + '</value>'
        })

        positionCon.value = positionString;
        positionFilter = this.createCondition("not-in", "indskr_positionidname", positionCon.value, positionCon.entity)
      } else {
        positionFilter = this.createFilter(positionCon);
      }

      let globalSearchConfigFilter = '';
      if(this.authService.user.contactsCriteriaFetchxml) {
        globalSearchConfigFilter = this.authService.user.contactsCriteriaFetchxml;
      }

      for(let le of linkedEntity) {
        let fetchXML = CONTACT_FETCH_QUERIES.searchAllContacts;

        fetchXML = fetchXML.replace('{{FullNameCondition}}', fullnameFilter);
        fetchXML = fetchXML.replace('{{PositionCondition}}', positionFilter);
        fetchXML = fetchXML.replace('{{GlobalSearchConfigFilter}}', globalSearchConfigFilter);

        fetchXML = fetchXML.replace('{{SearchConditions}}', le.filter);
        fetchXML = fetchXML.replace('{{LinkEntity}}', le.lk);

        fetchXML = fetchXML.replace('{{secondaryinfoFetchXml}}', this.secondaryInfoService.SecondaryInfoFetchXML(SecondaryInfoEntityName.Contact));
        if(!donotAddBUFilter){
          let BUFilter =   '<filter type="and"><condition attribute="owningbusinessunit" operator="eq" value="'+this.authService.user.xBusinessUnitId+'" /></filter>'
          fetchXML = fetchXML.replace('{{businessUnitCondition}}', BUFilter);
        }
        else fetchXML = fetchXML.replace('{{businessUnitCondition}}', '');

        fetchXMLs.push(fetchXML);
      }
    }

    return fetchXMLs;
  }
  private getAttributeName(name: string, dataType?: SuggestionPillType) {
    let attrName = name;
    if(attrName.indexOf('.') !== -1) {
      attrName = attrName.split('.').pop()
    }
    let lastTwo = attrName.slice(-2);
    if (attrName !== 'indskr_mdmid' && attrName !== 'indskr_externalid' && (lastTwo == "id" || lastTwo == "lu" || attrName == "indskr_address" || attrName === 'omnione_role'
      || (dataType == SuggestionPillType.CATEGORY_CHARACTERSEARCH && attrName === 'indskr_primaryaccount'))) {
      attrName = attrName + "name";
    }
    return attrName;
  }
  private getAttributeNameOneKeyCodeLabels(name, dataType?: SuggestionPillType) {
    let attrName = name;
    if(attrName.indexOf('.') !== -1) {
      attrName = attrName.split('.').pop()
    }
    if(dataType == SuggestionPillType.CATEGORY_CHARACTERSEARCH) {
      attrName = attrName + "name";
    }
    return attrName;
  }
  private createCondition(operator, attribute, value, entityName?) {
    if(!value) return "";
    if(operator == "not-in" || operator == "in") {
      return `<condition attribute="`+ attribute + `" operator="`+ operator + `" ` + (entityName? `entityname="` + entityName + `"` : "") + `>` + value + `</condition>`;
    } else if(operator == "like" || operator == "not-like"){
      return ` <condition attribute="`+ attribute + `" operator="`+ operator +`" value="%` + value + `%" ` + (entityName? `entityname="` + entityName + `"` : "") +`/>`;
    } else if(operator == "eq"){
      let val = value == 'Yes' ? '1' : (value == 'No' ? '0' : value);
      return ` <condition attribute="`+ attribute + `" operator="`+ operator +`" value="` + val + `" ` + (entityName? `entityname="` + entityName + `"` : "") +`/>`;
    } else {
      return ` <condition attribute="`+ attribute + `" operator="`+ operator +`" value="` + value + `" ` + (entityName? `entityname="` + entityName + `"` : "") +`/>`;
    }
  }
  private createConditionOneKeyCodeLabels(operator, attribute, value, entityName?) {
    if(!value) return "";
    if(operator == "eq") {
      return ` <condition attribute="`+ attribute + `" operator="`+ operator +`" value="` + value + `" ` + (entityName? `entityname="` + entityName + `" ` : "") + `/>`;
    } else if(operator == "like") {
      return ` <condition attribute="`+ attribute + `" operator="`+ operator +`" value="%` + value + `%" ` + (entityName? `entityname="` + entityName + `" ` : "") + `/>`;
    } else {
      return ` <condition attribute="`+ attribute + `" operator="`+ operator +`" value="` + value + `"` + `/>`;
    }
  }
  private createFilter(con: Condition) {
    if(!con.attributeName) return "";
    if (con.entity != "omnione_onekeycodeslabels") {
      if(con.charSearchtext && con.value) {
        return `<filter type="or">` +
                  this.createCondition("like", con.attributeName, con.charSearchtext, con.entity) +
                  this.createCondition("in", con.attributeName, con.value, con.entity) +
              `</filter>`
      } else {
        if(con.charSearchtext) {
          if (con.searchType == SuggestionPillType.BOOLEAN_FIELD) {
            return this.createCondition("eq", con.attributeName, con.charSearchtext, con.entity);
          } else {
            return this.createCondition("like", con.attributeName, con.charSearchtext, con.entity);
          }
        } else if(con.value) {
          return this.createCondition("in", con.attributeName, con.value, con.entity);
        } else {
          return "";
        }
      }
    }
    else {
      // filter - OneKeyCodeLables
      const isLinkedEntity: boolean = con.controlType == ControlDataType.LinkedEntity;
      const entityName = isLinkedEntity && con.parentEntity ? con.parentEntity : ''
      if(con.charSearchtext && con.value) {
        return `<filter type="or">` +
                    this.createConditionOneKeyCodeLabels("like", con.attributeName, con.charSearchtext, entityName) +
                    this.createConditionOneKeyCodeLabels("eq", con.attributeName, con.value, entityName) +
                `</filter>`
      } else {
        if(con.charSearchtext) {
          return this.createConditionOneKeyCodeLabels("like", con.attributeName, con.charSearchtext, entityName);
        } else if(con.value) {
          return this.createConditionOneKeyCodeLabels("eq", con.attributeName, con.value, entityName);
        } else {
          return "";
        }
      }
    }
  }


  saveContactTags(reqBody: UserTagForContact): Observable<any> {
    if (this.deviceService.isOffline) {
      if (reqBody[0].pendingPushToDynamics) {
        this.disk.addOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.CONTACT_TAG, 1);
        reqBody[0].pendingPushToDynamics = true;
      }
    } else {
      let headers = new HttpHeaders();
      let url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.contacts.CONTACT_TAGGING;
      let payload = _.cloneDeep(reqBody);
      payload['indskr_taggedfor'] = 'CONTACT';
      return this.http.post(url, [payload], { headers });
    }
  }

  async deleteContactTag(userTagpayload: UserTagForContact) {
    this.uiService.displayLoader();
    if (this.deviceService.isOffline) {
      // offline create and offline delete
      if (!userTagpayload.indskr_usertagid) {
      await  this.disk.remove(DB_KEY_PREFIXES.CONTACT_TAG + userTagpayload.indskr_externalid)
      } else {
        // online create and offline delete
        userTagpayload.pendingPushToDynamics = true;
        this.disk.addOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.CONTACT_TAG, 1);
        await  this.disk.upsertUserTag(userTagpayload,TagEntityType.CONTACT);
      }
      let selIndex = this.contactTags.findIndex(tag => userTagpayload.indskr_externalid === tag.indskr_externalid);
      if (selIndex >= 0) {
        this.contactTags.splice(selIndex, 1);
      }
      this.toastNotificationForContactTags(userTagpayload , false , true);
      this.uiService.dismissLoader();
    } else {
      await this.saveContactTags(userTagpayload).subscribe(async response => {
        console.log(response);
        if (response) {
          userTagpayload.pendingPushToDynamics = false;
          await  this.disk.remove(DB_KEY_PREFIXES.CONTACT_TAG + userTagpayload.indskr_externalid);
          let selIndex = this.contactTags.findIndex(tag => userTagpayload.indskr_externalid === tag.indskr_externalid);
          if (selIndex >= 0) {
            this.contactTags.splice(selIndex, 1);
          }
          this.toastNotificationForContactTags(userTagpayload , false , true);
          this.userTagDeleted.next(true);
          this.uiService.dismissLoader();
        } else{
          this.uiService.dismissLoader();
        }
      });
    }
  }

  async createOrUpdateContactTag(tagData: UserTagForContact, create: boolean = false ) : Promise<any>{
    this.uiService.displayLoader();
   return  new Promise(async (resolve , reject )=> {
     tagData.pendingPushToDynamics = true;
     if(create){
      tagData.indskr_externalid = `offlineUserTag_${new Date().getTime()}`;
     }
     tagData.stateCode = CustomerTagStateCode.Active
     console.log("requestObj", tagData)
     if (this.deviceService.isOffline) {
       this.disk.addOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.CONTACT_TAG, 1);
       await this.disk.upsertUserTag(tagData,TagEntityType.CONTACT);
       this.uiService.dismissLoader();
       this.toastNotificationForContactTags(tagData , create);
       resolve([{
        indskr_usertagid : tagData.indskr_usertagid,
        indskr_externalid : tagData.indskr_externalid
       }]);
     } else {
       await this.saveContactTags(tagData).subscribe(async response => {
         console.log("data", response);
         response.forEach(async res => {
           tagData.pendingPushToDynamics = false;
           await this.disk.upsertUserTag(tagData,TagEntityType.CONTACT);
           if (tagData.indskr_externalid === res.indskr_externalid) {
             tagData.indskr_usertagid = res.indskr_usertagid;
             this.toastNotificationForContactTags(tagData , create);
             if(res["indskr_usertagid"]) {
              this.uiService.dismissLoader();
               resolve(response);
             } else {
              this.uiService.dismissLoader();
               reject(response);
             }
           }
         });
       }, async error => {
         console.log("Fails to create tag due to", error);
         tagData.pendingPushToDynamics = true;
         this.disk.addOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.CONTACT_TAG, 1);
         await this.disk.upsertUserTag(tagData,TagEntityType.CONTACT);
         // error toast notification
         reject(error);
       });
     }
   });
  }

  uploadOfflineData(tagaDatList : UserTagForContact []) : Observable<any>{
    let headers = new HttpHeaders();
      let url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.contacts.CONTACT_TAGGING;
      let payload = _.cloneDeep(tagaDatList);
      payload.forEach(a=> {
        a['indskr_taggedfor'] = 'CONTACT';
      })
      return this.http.post(url, payload, { headers });
  }

  toastNotificationForContactTags(tagData : UserTagForContact , create : boolean , deleteTag = false){
      if(deleteTag) {
        this.toast.notify(`'${tagData.indskr_name}' ${this.translate.instant("DELETED_SUCCESSFULLY")}`, "");
        return;
      }
    if(create) {
      this.toast.notify(`'${tagData.indskr_name}' ${this.translate.instant("SAVED_SUCCESSFULLY")}`, "");
    } else {
      this.toast.notify(`'${tagData.indskr_name}' ${this.translate.instant("UPDATED_SUCCESSFULLY")}`, "");
    }
  }
  async getContactRelatedToByContactId(contactid) {
    const contactRelationshipTo = await this.disk.retrieve("indskr_contact_contactrelationship_contact")
    const linkEntityContactTo = contactRelationshipTo ? (contactRelationshipTo.raw).filter(x => x["indskr_contactrelationship.indskr_contactid"] == contactid && x["indskr_contactrelationship.statecode"] === 0) : []
    this.linkEntityContactTo = linkEntityContactTo
    const contactRelatedToContact = linkEntityContactTo
    return contactRelatedToContact
  }

  async getContactRelatedFromByContactId(contactid) {
    const contactRelationshipForm = await this.disk.retrieve("indskr_contact_contactrelationship_relatedcontactid")
    const linkEntityContactFrom = contactRelationshipForm ? (contactRelationshipForm.raw).filter(x => x["indskr_contactrelationship.indskr_relatedcontactid"] == contactid && x["indskr_contactrelationship.statecode"] === 0) : []
    this.linkEntityContactFrom = linkEntityContactFrom
    const contactRelatedFromContact = linkEntityContactFrom
    return contactRelatedFromContact
  }
  async getContactToAccountByContactId(contactid) {
    const account = await this.disk.retrieve("indskr_accountcontactaffiliation")
    const affiliationAccount = account ? (account.raw).filter(x => x["contactid"] == contactid && x["indskr_accountcontactaffiliation.statecode"] === 0) : []
    this.affiliationAccount = affiliationAccount
    const contactToAccount = affiliationAccount
    return contactToAccount
  }

  async affiliationControl() {
    let contactForm: DynamicForm = await this.dynamicFormsService.getFormDefinitionForEntity("contact", FormType.DISPLAYFORM);
    let formType = DynamicFormType.CONFIGUREDFORM;
    if (!contactForm) {
      contactForm = new DynamicForm(DEFAULT_CONTACT_CREATE_FORM['value'][0]);
      formType = DynamicFormType.DEFAULTFORM;
    }
    const affiliationsRefEntityNames = [
      ACCOUNT_ACCOUNT_AFFILIATIONS_REF_ENTITY,
      CONTACT_CONTACT_AFFILIATIONS_REF_ENTITY,
      ACCOUNT_CONTACT_AFFILIATIONS_REF_ENTITY
    ];
    for (let res of contactForm.metadata) {
      for (let control of res.controls) {
        this.isAffiliationEnabled = affiliationsRefEntityNames.includes(control.subgrid?.referencingEntity);
        if (this.isAffiliationEnabled) {
          break;
        }
      }
      if (this.isAffiliationEnabled) {
        break;
      }
    }
  }

  public checkIfContactIsAMADoNotContact(contact?: Contact) {
    contact ??= this.contactInformation;
    return this.authService.user?.buSettings?.indsyn_amasubscription && !contact.raw?.omniveev_amadonotcontact
  }

  public setErrorMessageOpenContact() {
    let toolNameStr: string = '';
    let pocNameStr: string = '';
    switch (this.utilityService.globalCustomerText) {
      case 'Stakeholder':
        toolNameStr = this.translate.instant('STAKEHOLDER');
        pocNameStr = this.translate.instant("SUPPORT");
        break;
      case 'Contact':
        toolNameStr = this.translate.instant('CONTACT_SINGULAR');
        pocNameStr = this.translate.instant("ADMINISTRATOR");
        break;
      case 'Customer':
        toolNameStr = this.translate.instant('CUSTOMER');
        pocNameStr = this.translate.instant("ADMINISTRATOR");
        break;
      default:
        toolNameStr = this.utilityService.globalCustomerText;
        pocNameStr = this.translate.instant("ADMINISTRATOR");
        break;
    }
    this.errorMessageOpenContactDetails = this.translate.instant('YOU_DO_NOT_HAVE_ACCESS_TO_WITH_TEXT', {text: toolNameStr.toLowerCase(), pocName: pocNameStr.toLowerCase()});
  }

  public async setLocationField(contacts) {
    let selectedContact = []
    if(Array.isArray(contacts)) selectedContact = contacts;
    else selectedContact.push(contacts);
    // customer primary location will be selected if available.
    // this.activityService.selectedActivity.location = '';
    if (selectedContact && selectedContact.length
      && (_.isEmpty(this.activityService.selectedActivity.location)
          || (this.activityService.selectedActivity.location
              && (this.activityService.selectedActivity.location == 'No Location' || this.activityService.selectedActivity.location == this.translate.instant('NO_LOCATION'))))) {
      // Mark Favourite Address
      let preferredAddressData = await this.disk.retrieve(DB_KEY_PREFIXES.PREFERRED_ADDRESS);

      let accountAddress = [];
      if (preferredAddressData && preferredAddressData.raw) {
        selectedContact.forEach((contact) => {
          if(contact.addressesList && contact.addressesList.length) {
            contact.addressesList.forEach((data) => {
              data['isFavourite'] = false;
              preferredAddressData.raw.forEach(p => {
                if(data['customerAddressID'] && data['customerAddressID'] == p['customerAddressId']) data['isFavourite'] = true;
              })
            })
          }
        });
      }
      if((this.activityService.selectedActivity as AppointmentActivity).accounts && (this.activityService.selectedActivity as AppointmentActivity).accounts.length>0){
        let rawData = await this.disk.retrieve(DB_KEY_PREFIXES.ACCOUNT_LINKED_ENTITY+OFFLINE_DB_LINKED_ENTITY_NAME.ADDRESSES);
        if(rawData && rawData.raw && Array.isArray(rawData.raw) && rawData.raw.length != 0){
          rawData.raw.forEach(item => {
            if(item.hasOwnProperty('accountid') && (this.activityService.selectedActivity as AppointmentActivity).accounts.some(account=> account['id'] == item['accountid'])){
              let foundAddress = {
                compositeAdd: item['indskr_address.indskr_composite']||'',
                customerAddressID: item['indskr_indskr_customeraddress_v2.indskr_indskr_customeraddress_v2id']||'',
                addressId: item['indskr_indskr_customeraddress_v2.indskr_address'] || ''
              };
              if(!_.isEmpty(foundAddress.customerAddressID)){
                const idx = accountAddress.findIndex(a => a.customerAddressID === foundAddress.customerAddressID);
                if (idx < 0) accountAddress.push(foundAddress);
              }
            }
          });
          if (preferredAddressData && preferredAddressData.raw) {
            if(accountAddress && accountAddress.length>0) {
              accountAddress.forEach((account) => {
                preferredAddressData.raw.forEach(p => {
                  if(account['customerAddressID'] && account['customerAddressID'] == p['customerAddressId']) account['isFavourite'] = true;
                });
              })
            }
          }
        }
      }
      // if there is only one customer selected,
      // the location will be auto-populated only when there is only one favourite address. if there are mutiple favourite addresses, the user will select the location manually.
      // if there is no favourite addresses set, the primary address will be auto selected if applicable.
      if(selectedContact.length == 1 && (!accountAddress || accountAddress && accountAddress.length==0)) {
        let contact = selectedContact[0];
        if(contact.addressesList && contact.addressesList.length) {
          let favouriteAdd = (<any[]>contact.addressesList).filter((address) => address['isFavourite']);
          if(favouriteAdd && favouriteAdd.length >0) {
            if(favouriteAdd.length == 1) {
              let address = favouriteAdd[0];
              if(address && address.compositeAdd && address.compositeAdd.length) this.activityService.selectedActivity.location = address.compositeAdd;
            } else {
              this.activityService.selectedActivity.location = '';
            }
          } else {
            let primaryAdd = (<any[]>contact.addressesList).find((address) => address.isPrimary);
            if(primaryAdd && primaryAdd.compositeAdd) {
              this.activityService.selectedActivity.location = primaryAdd.compositeAdd;
            } else if (contact.addressesList.length == 1){
              this.activityService.selectedActivity.location = contact.addressesList[0].compositeAdd;
            }
          }
        }
      }
      // if there are more than one customer selected,
      // Only if the favourite address is all the same for selected accounts/contacts, it should appear on the location
      else {
        this.activityService.selectedActivity.location = '';
        let favAddIds = [];
        let isFavAddressEmpty = true;
        selectedContact.forEach((contact) => {
          let favouriteAdds = [];
          if(contact.addressesList && contact.addressesList.length>0) {
            favouriteAdds = (<any[]>contact.addressesList).filter((address) => address['isFavourite']);
            if(favouriteAdds && favouriteAdds.length>0) {
              let ids = favouriteAdds.map(add => {return add['addressId']});
              favAddIds.push(ids);
              isFavAddressEmpty = false;
            } else {
              favAddIds.push([]);
            }
          }
        });
        if(accountAddress && accountAddress.length>0) {
          let favouriteAccountAdds = accountAddress.filter((address) => address['isFavourite']);
          if(favouriteAccountAdds && favouriteAccountAdds.length>0) {
            let ids = favouriteAccountAdds.map(add => {return add['addressId']});
            favAddIds.push(ids);
            isFavAddressEmpty = false;
          } else {
            favAddIds.push([]);
          }
        }
        if (isFavAddressEmpty) {
          let compositeAddList = (contact) => {
            if (contact.addressesList && contact.addressesList.length) {
              return (<any[]>contact.addressesList).find((address) => address.isPrimary);
            } else {
              return;
            }
          }
          let unique =  new Set (selectedContact.map(compositeAddList));
          if (unique) {
          let array = Array.from(unique);
            if (Array.from(unique).length == 1) {
              let first = array[0];
              if (first && first.compositeAdd && first.compositeAdd.length) {
                this.activityService.selectedActivity.location = Array.from(unique)[0].compositeAdd;
              }
            } else{
              let uniqueAddress = new Set (array.map(addres =>{
                if(addres){
                  return addres.compositeAdd;
                }
              }));
              let uniqueAddressList = Array.from(uniqueAddress);
              if(Array.from(uniqueAddress).length == 1){
                this.activityService.selectedActivity.location = uniqueAddressList[0];
              }
            }
          }
        } else {
          let commonFavAdd = _.intersection(...favAddIds);
          if(commonFavAdd && commonFavAdd.length==1){
            this.activityService.selectedActivity.location = selectedContact[0].addressesList.find(add => add['addressId'] == commonFavAdd[0]).compositeAdd;
          }
        }
      }
    }
  }
  /** Filter lineked Entity data to use affiliated contact selection */
  public async getSelectableLinkedEntity(dbKey:string, referenceEntity:string, selectedId:string, loadFromDB:boolean): Promise<SelectableLinkedEntity[]> {
    let selectableLinkedEntity: Array<SelectableLinkedEntity> = [];
    if(!loadFromDB) {
      await this.fetchContactsSpecificLinkEntityData(dbKey, selectedId);
      this._postMessageOnDefaultLinkedEntityDataMappingWorker({ type: 'mapDefaultLinkedEntityDataToContact', rawList: this._defaultLinkedEntityMappingData});
    }
    let rawLEData = await this.disk.retrieve(dbKey);
    //set attribute name based on the referneceEntity
    const attrStatecodeStr: string = dbKey + '.statecode';
    const attrSelIdStr: string = dbKey + '.indskr_accountid';
    const attrIsPrimaryStr: string = dbKey + '.indskr_isprimaryaccount';
    const attrSelNameStr: string = dbKey + '.indskr_accountid_Formatted';
    if(!_.isEmpty(rawLEData) && rawLEData.raw) {
      let linkedEntityData = rawLEData.raw.filter(x=>x[referenceEntity] == selectedId && (!x.hasOwnProperty(attrStatecodeStr) || (x.hasOwnProperty(attrStatecodeStr) && x[attrStatecodeStr] ==0)));
      linkedEntityData.forEach(le=>{
        selectableLinkedEntity.push({
          selId: le[attrSelIdStr] || '',
          isPrimary: le[attrIsPrimaryStr] || false,
          selName: le[attrSelNameStr] || '',
          isDisableChkBox: false,
          isChecked: false,
        });
      });
    }
    return selectableLinkedEntity;
  }

  disableEditForVeevaContactOnly(): boolean {
    let isDisabled = false;

    return isDisabled;
  }

  public async getAllPositionByOwnerId(ownerId: string) {
    let ownerPosition = [];
    if(_.isEmpty(ownerId)) return ownerPosition;
    try {
      let fetchXML = `
      <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
        <entity name="position">
          <attribute name="positionid"/>
          <order attribute="name" descending="false"/>
          <link-entity name="indskr_position_systemuser" from="positionid" to="positionid" visible="false" intersect="true">
            <link-entity name="systemuser" from="systemuserid" to="systemuserid" alias="an">
              <filter type="and">
                <condition attribute="systemuserid" operator="eq" value="{ownerIdValue}"/>
              </filter>
            </link-entity>
          </link-entity>
        </entity>
      </fetch>`
      fetchXML = fetchXML.replace("{ownerIdValue}", ownerId);
      await this.dynamics.executeFetchQuery('positions',fetchXML).then(async (res)=>{
        ownerPosition = res;
      });
    } catch (error) {
      console.log(error);
    }
    return ownerPosition;
  }

  private async removeOfflineUpdateContactActivitiesTimelineDB() {
    try {
      await Promise.all([
        this.disk.remove(DB_KEY_PREFIXES.CONTACT_TIMELINE_OFFLINE_UPDATE_ACTIVITIES_BY_CONTACT),
        this.disk.remove(DB_KEY_PREFIXES.CONTACT_TIMELINE_OFFLINE_DELETE_ACTIVITIES_BY_CONTACT),
        this.disk.remove(DB_KEY_PREFIXES.CONTACT_TIMELINE_OFFLINE_UPDATE_CONTACTS_BY_ACTIVITY),
      ]);
    }catch(error) {
      console.log(error);
    }
  }

  public async removeContactActivitiesTimelineDB(contactId: string) {
    const dbKeyActivitesTimelineByContact: string = DB_KEY_PREFIXES.CONTACT_ACTIVITIES_TIMELINE + contactId;
    const dbKeyRegistrationEventsTimelineByContact: string = DB_KEY_PREFIXES.CONTACT_REGISTRATION_EVENTS_TIMELINE + contactId;
    const dbKeyCheckInEventsTimelineByContact: string = DB_KEY_PREFIXES.CONTACT_CHECKIN_EVENTS_TIMELINE + contactId;
    const dbKeyCompletedEventsTimelineByContact: string = DB_KEY_PREFIXES.CONTACT_COMPLETED_EVENTS_TIMELINE + contactId;
    try {
      await Promise.all([
        this.disk.remove(dbKeyActivitesTimelineByContact),
        this.disk.remove(dbKeyRegistrationEventsTimelineByContact),
        this.disk.remove(dbKeyCheckInEventsTimelineByContact),
        this.disk.remove(dbKeyCompletedEventsTimelineByContact),
      ]);
    }catch(error) {
      console.log(error);
    }
  }

  private async _offlineActivityTimelineHandler(activity: Activity) {
    const dbKeyUpdateActivitiesByContact: string = DB_KEY_PREFIXES.CONTACT_TIMELINE_OFFLINE_UPDATE_ACTIVITIES_BY_CONTACT;
    const dbKeyDeleteActivitiesByContact: string = DB_KEY_PREFIXES.CONTACT_TIMELINE_OFFLINE_DELETE_ACTIVITIES_BY_CONTACT;
    const dbKeyUpdateContactsByActivity: string = DB_KEY_PREFIXES.CONTACT_TIMELINE_OFFLINE_UPDATE_CONTACTS_BY_ACTIVITY;

    //update the offline activity list for timeline - scrap activity
    if(activity && activity.state == 2) {
      if(!_.isEmpty(activity['contacts'])){
        activity['contacts'].forEach((contact)=>{
          const conIdx = this.scrapActivityIdsForOfflineTimelineByContactId.findIndex(o=>o['contactId'] == contact.ID);
          if(conIdx > -1) {
            const actIdx = this.scrapActivityIdsForOfflineTimelineByContactId[conIdx]['activityIds'].findIndex((actId) => actId == activity.ID);
            if(actIdx == -1) {
              this.scrapActivityIdsForOfflineTimelineByContactId[conIdx]['activityIds'].push(activity.ID);
            }
          }else {
            const updateObj: ContactTimelineActivityByContact = {
              contactId: contact.ID,
              activityIds: [activity.ID],
            }
            this.scrapActivityIdsForOfflineTimelineByContactId.push(updateObj);
          }
        });
        //scrap activity IDs - contact ID for contact timeline in DB
        try{
          await this.disk.updateOrInsert(dbKeyDeleteActivitiesByContact, (doc) => {
            if(!doc || !doc.raw) { doc= { raw:[] }}
            doc.raw = this.scrapActivityIdsForOfflineTimelineByContactId;
            this.uiService.timelineRefreshRequired = true;
            console.log("Saved offline scrap Activity/Contact IDs for contact timeline in DB");
            return doc;
          });
        }catch(error) { console.log(error); }
      }
    }
    //update the offline activity list for timeline - update/create activity
    else {
      if(activity.type == "Email" || activity instanceof EmailActivity) {
        let contactIdsList: Array<string> = [];
        if(!_.isEmpty(activity['emailActivityParties'])){
          activity['emailActivityParties'].forEach((emailActivityParty)=>{
            let contactId = emailActivityParty.indskr_contactid || '';
            if(contactId) {
              contactIdsList.push(contactId);
              const conIdx = this.updateActivityIdsForOfflineTimelineByContactId.findIndex(o=>o['contactId'] == contactId);
              if(conIdx > -1) {
                const actIdx = this.updateActivityIdsForOfflineTimelineByContactId[conIdx]['activityIds'].findIndex((actId) => actId == activity.ID);
                if(actIdx == -1) {
                  this.updateActivityIdsForOfflineTimelineByContactId[conIdx]['activityIds'].push(activity.ID);
                }
              }else {
                const updateObj: ContactTimelineActivityByContact = {
                  contactId: contactId,
                  activityIds: [activity.ID],
                }
                this.updateActivityIdsForOfflineTimelineByContactId.push(updateObj);
                //recheck non-contact list
                const nonConIdx = this.updateActivityIdsForOfflineTimelineByContactId.findIndex(o=>o['contactId'] == '');
                if(nonConIdx > -1) {
                  const actIdx = this.updateActivityIdsForOfflineTimelineByContactId[nonConIdx]['activityIds'].findIndex(actId => actId == activity.ID);
                  if(actIdx > -1) {
                    this.updateActivityIdsForOfflineTimelineByContactId[nonConIdx]['activityIds'].splice(actIdx,1);
                  }
                }
              }
            }
          });
          //Update contact IDs by Activity ID to track the deleted contact from activity
          const actIdx = this.updateContactIdsForOfflineTimelineByActivityId.findIndex(o=>o['activityId'] == activity.ID);
          if(actIdx > -1) {
            this.updateContactIdsForOfflineTimelineByActivityId[actIdx]['contactIds'] = contactIdsList;
          }else {
            const updateObj: ContactTimelineContactByActivity = {
              activityId: activity.ID,
              contactIds: contactIdsList,
            }
            this.updateContactIdsForOfflineTimelineByActivityId.push(updateObj);
          }
          //update activity IDs - contact ID for contact timeline in DB
          try{
            await Promise.all([
              this.disk.updateOrInsert(dbKeyUpdateActivitiesByContact, (doc) => {
                if(!doc || !doc.raw) { doc= { raw:[] }}
                doc.raw = this.updateActivityIdsForOfflineTimelineByContactId;
                this.uiService.timelineRefreshRequired = true;
                console.log("Saved offline update Activity/Contact IDs for contact timeline in DB: ", this.updateActivityIdsForOfflineTimelineByContactId.length);
                return doc;
              }),
              this.disk.updateOrInsert(dbKeyUpdateContactsByActivity, (doc) => {
                if(!doc || !doc.raw) { doc= { raw:[] }}
                doc.raw = this.updateContactIdsForOfflineTimelineByActivityId;
                this.uiService.timelineRefreshRequired = true;
                console.log("Saved offline track Activity/Contact IDs for contact timeline in DB: ", this.updateContactIdsForOfflineTimelineByActivityId.length);
                return doc;
              }),
            ]);
          }catch(error) { console.log(error); }
        }
        else{
         //non selected contacts - cannot send the message
        }
      }
      // meeting, live meet, call-plan, allocation order activities
      else {
        let contactIdsList: Array<string> = [];
        if(!_.isEmpty(activity['contacts'])){
          activity['contacts'].forEach((contact)=>{
            contactIdsList.push(contact.ID);
            const conIdx = this.updateActivityIdsForOfflineTimelineByContactId.findIndex(o=>o['contactId'] == contact.ID);
            if(conIdx > -1) {
              const actIdx = this.updateActivityIdsForOfflineTimelineByContactId[conIdx]['activityIds'].findIndex((actId) => actId == activity.ID);
              if(actIdx == -1) {
                this.updateActivityIdsForOfflineTimelineByContactId[conIdx]['activityIds'].push(activity.ID);
              }
            }else {
              const updateObj: ContactTimelineActivityByContact = {
                contactId: contact.ID,
                activityIds: [activity.ID],
              }
              this.updateActivityIdsForOfflineTimelineByContactId.push(updateObj);
              //recheck non-contact list
              const nonConIdx = this.updateActivityIdsForOfflineTimelineByContactId.findIndex(o=>o['contactId'] == '');
              if(nonConIdx > -1) {
                const actIdx = this.updateActivityIdsForOfflineTimelineByContactId[nonConIdx]['activityIds'].findIndex(actId => actId == activity.ID);
                if(actIdx > -1) {
                  this.updateActivityIdsForOfflineTimelineByContactId[nonConIdx]['activityIds'].splice(actIdx,1);
                }
              }
            }
          });
          //Update contact IDs by Activity ID to track the deleted contact from activity
          const actIdx = this.updateContactIdsForOfflineTimelineByActivityId.findIndex(o=>o['activityId'] == activity.ID);
          if(actIdx > -1) {
            this.updateContactIdsForOfflineTimelineByActivityId[actIdx]['contactIds'] = contactIdsList;
          }else {
            const updateObj: ContactTimelineContactByActivity = {
              activityId: activity.ID,
              contactIds: contactIdsList,
            }
            this.updateContactIdsForOfflineTimelineByActivityId.push(updateObj);
          }
          //update activity IDs - contact ID for contact timeline in DB
          try{
            await Promise.all([
              this.disk.updateOrInsert(dbKeyUpdateActivitiesByContact, (doc) => {
                if(!doc || !doc.raw) { doc= { raw:[] }}
                doc.raw = this.updateActivityIdsForOfflineTimelineByContactId;
                this.uiService.timelineRefreshRequired = true;
                console.log("Saved offline update Activity/Contact IDs for contact timeline in DB: ", this.updateActivityIdsForOfflineTimelineByContactId.length);
                return doc;
              }),
              this.disk.updateOrInsert(dbKeyUpdateContactsByActivity, (doc) => {
                if(!doc || !doc.raw) { doc= { raw:[] }}
                doc.raw = this.updateContactIdsForOfflineTimelineByActivityId;
                this.uiService.timelineRefreshRequired = true;
                console.log("Saved offline track Activity/Contact IDs for contact timeline in DB: ", this.updateContactIdsForOfflineTimelineByActivityId.length);
                return doc;
              }),
            ]);
          }catch(error) { console.log(error); }
        }
        //non selected contacts
        else{
          if(activity.type === ActivityType.Sample && activity.status == 2) {
            const sampleContactId = activity['contactID'];
            const conIdx = this.updateActivityIdsForOfflineTimelineByContactId.findIndex(o=>o['contactId'] == sampleContactId);
            if(conIdx > -1) {
              const actIdx = this.updateActivityIdsForOfflineTimelineByContactId[conIdx]['activityIds'].findIndex((actId) => actId == activity.ID);
              if(actIdx == -1) {
                this.updateActivityIdsForOfflineTimelineByContactId[conIdx]['activityIds'].push(activity.ID);
              }
            }else {
              const updateObj: ContactTimelineActivityByContact = {
                contactId: sampleContactId,
                activityIds: [activity.ID],
              }
              this.updateActivityIdsForOfflineTimelineByContactId.push(updateObj);
            }
          }else {
            const nonConIdx = this.updateActivityIdsForOfflineTimelineByContactId.findIndex(o=>o['contactId'] == '');
            if(nonConIdx > -1) {
              let foundActId = this.updateActivityIdsForOfflineTimelineByContactId[nonConIdx]['activityIds'].some(actId => actId == activity.ID);
              if(!foundActId) this.updateActivityIdsForOfflineTimelineByContactId[nonConIdx]['activityIds'].push(activity.ID);
            }else {
              const updateObj: ContactTimelineActivityByContact = {
                contactId: '',
                activityIds: [activity.ID],
              }
              this.updateActivityIdsForOfflineTimelineByContactId.push(updateObj);
            }
          }

          //update activity IDs - contact ID for contact timeline in DB
          try{
            await this.disk.updateOrInsert(dbKeyUpdateActivitiesByContact, (doc) => {
              if(!doc || !doc.raw) { doc= { raw:[] }}
              doc.raw = this.updateActivityIdsForOfflineTimelineByContactId;
              this.uiService.timelineRefreshRequired = true;
              console.log("Saved offline update Activity/Contact IDs for contact timeline in DB: ", this.updateActivityIdsForOfflineTimelineByContactId.length);
              return doc;
            });
          }catch(error) { console.log(error); }
        }
      }
    }
  }

  
  public async updateDCRApprovalStatusOnline(payload,approvalReq:DCRApprovalReq):Promise<any>{
    return new Promise(async (resolve, reject) => {
      let headers = Endpoints.headers.content_type.json;
      let url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.mdm.UPDATE_APPROVAL_ACTIVITY;
      url = url.replace('{approvalActivityId}',approvalReq.approvalActivityId);
      let response =  await this.http.patch(url, payload, headers).toPromise().catch(err=>{
        reject(err);
      });
      // Update Approval Req
      let idx = this.approvableDCRRequests.findIndex(a => a.ID == approvalReq.ID);
      if (idx >= 0) {
        this.approvableDCRRequests[idx].approvalStatus = (payload.statuscode == 548910001 ? 'Approved' : 'Rejected');
        approvalReq.statusString = (payload.statuscode == 548910001 ? 'Approved' : 'Rejected');
        
        let raw = await this.disk.retrieve(DB_KEY_PREFIXES.DCR_APPROVAL_REQ);
        if (raw?.data && !_.isEmpty(raw?.data)) {
          let index = raw.data.findIndex(e => e.approvalActivityId == approvalReq.approvalActivityId);
          if (index >= -1) {
            raw?.data.splice(index, 1);
            await this.disk.updateOrInsert(DB_KEY_PREFIXES.DCR_APPROVAL_REQ, (doc) => {
              doc = {
                data: [],
                lastModified: new Date().getTime().toString()
              };
              doc.data = raw?.data;
              return doc;
            });
          }
        }
      }
      resolve(response);
    });
    
  }

  private _populateKeyMessageSentimentsForAContact(contact:Contact){
    if(contact){
      let foundIdx = (this.activityService.selectedActivity as AppointmentActivity).activityContactSentiments.findIndex(c=> c.indskr_contactid == contact.ID);
      if(foundIdx < 0){
        let uniqueKeyMessagesList = [];
        if((this.activityService.selectedActivity as AppointmentActivity).products){
          (this.activityService.selectedActivity as AppointmentActivity).products.forEach(p=> {
            if((p.isSelected || p.isAutoSelected) && p.keyMessages){
              p.keyMessages.forEach(k=> {
                if(k.isSelected || k.isAutoSelected){
                  if(!uniqueKeyMessagesList.some(a=> a.indskr_keymessageid == k.ID)){
                    uniqueKeyMessagesList.push({
                      indskr_keymessageid: k.ID,
                      indskr_name: k.name,
                      indskr_keymessagesentiment: 'NEUTRAL',
                    });
                  }
                }
              })
            }
          })
        }
        if((this.activityService.selectedActivity as AppointmentActivity).activityProductIndications){
          (this.activityService.selectedActivity as AppointmentActivity).activityProductIndications.forEach(p=> {
            if(p.activityProductIndicationKeyMessages){
              p.activityProductIndicationKeyMessages.forEach(k=> {
                  if(!uniqueKeyMessagesList.some(a=> a.indskr_keymessageid == k.indskr_keymessageid)){
                    uniqueKeyMessagesList.push({
                      indskr_keymessageid: k.indskr_keymessageid,
                      indskr_name: k.indskr_name,
                      indskr_keymessagesentiment: 'NEUTRAL',
                    });
                  }
              })
            }
          })
        }
        uniqueKeyMessagesList = uniqueKeyMessagesList.sort((a,b) => {
          var a = a['indskr_name'].toLowerCase(), b = b['indskr_name'].toLowerCase();
          if (a < b)
              return -1;
          if (a > b)
              return 1;
          return 0;
        });
        (this.activityService.selectedActivity as AppointmentActivity).activityContactSentiments.push({
          indskr_contactid: contact.ID,
          activityKeyMessageSentiments: uniqueKeyMessagesList,
        })
      }
    }
  }

  public convertOptionValueToActivityType(optionValue: string): ActivityType {
    let convertedValue: ActivityType;
    if(optionValue == this.translate.instant("MEETINGS")) {
      convertedValue = ActivityType.Appointment;
    }else if(optionValue == this.translate.instant("LIVE_MEETING")) {
      convertedValue = ActivityType.LiveMeet;
    }else if(optionValue == this.translate.instant("PHONE_CALLS")) {
      convertedValue = ActivityType.PhoneCall;
    }else if(optionValue == this.translate.instant("MESSAGES")) {
      convertedValue = ActivityType.Email;
    }else if(optionValue == this.translate.instant("ALLOCATION_ORDER")) {
      convertedValue = ActivityType.Sample;
    }else if(optionValue == this.translate.instant('CUSTOMER_INQUIRIES', { globalCustomerText: this.utilityService.globalCustomerText })) {
      convertedValue = ActivityType.CaseIntake;
    }else if(optionValue == this.translate.instant('EVENTS')) {
      convertedValue = ActivityType.Event;
    }
    return convertedValue;
  }

}

export interface ContactSelectionObservableModel {
    for:string;
    id:string;
    contacts: Array<Contact>;
}


export class UserTagForContact {
    constructor( public indskr_externalid : string,
     public indskr_usertagid: string,
     public deleted: boolean = false,
     public indskr_name: string,
     public contacts: ContactTag[],
     public pendingPushToDynamics : boolean = false,
     public stateCode : number = CustomerTagStateCode.Active,
     public visibility: string
     ){
    }

  }

export interface ContactTimelineActivityByContact {
  contactId: string,
  activityIds: Array<string>,
}

export interface ContactTimelineContactByActivity {
  activityId: string,
  contactIds: Array<string>,
}
export class ContactTag {
  constructor(
    public contactId: string,
    public deleted: boolean = false) {
  }
}

export enum CustomerTagStateCode {
  Active = 0,
  InActive = 1
}
