import { ActivityService } from '../../../services/activity/activity.service';
import { UIService } from '../../../services/ui/ui.service';
import { Component } from "@angular/core";
import { AgendaFooterService } from '../../../services/footer/agenda-footer.service';
import { EventsService } from '../../../services/events/events.service';
import { IndHeaderLeftDataModel } from "@omni/models/indHeaderLeftDataModel";
import { TranslateService } from "@ngx-translate/core";
import { IndTabsDataModel } from "@omni/models/ind-tabs-data-model";
import { IndSectionHeaderViewDataModel } from "@omni/models/indSectionHeaderDataModel";
import { MyAssistantService, NOTIFICATION } from "@omni/services/my-assistant/my-assistant.service";
import { DateTimeFormatsService } from "@omni/services/date-time-formats/date-time-formats.service";
import { MultiSelectPopover } from "@omni/components/multi-select-popover/multi-select-popover";
import { PopoverController } from "@ionic/angular";
import { TrackingEventNames, TrackService } from "@omni/services/logging/tracking.service";
import _ from "lodash";
import { IndNotificationDataModel } from "@omni/models/indNotificationDataModel";
import { NotificationDetailsComponent } from "@omni/components/notification/notification-details/notification-details";
import { NavigationService, PageName } from "@omni/services/navigation/navigation.service";
import { Subscription } from "rxjs";
import { NothingSelectedView } from "@omni/components/shared/nothing-selected-view/nothing-selected-view";
import { EmailActivity, EmailViewType } from '@omni/classes/activity/email.activity.class';
import { EmailActivityDetailComponent } from '@omni/components/activity/email-activity-detail/email-activity-detail';
import { EmailService } from '@omni/services/email-templates/email.service';
import { NotificationService, ToastStyle } from '@omni/services/notification/notification.service';
import { ActivitiesDetailsPaneComponent } from '@omni/components/activity/activities-details-pane/activities-details-pane';
import { AppointmentActivity } from '@omni/classes/activity/appointment.activity.class';
import { AccesingMode, CaseManagementService } from '@omni/services/case-management/case-management.service';
import { CaseActivity } from '@omni/classes/case-intake/case-activity.class';
import { CaseManagementDetailComponent } from '@omni/components/case-management/case-management-detail/case-management-detail';
import { FooterService, FooterViews } from '@omni/services/footer/footer.service';
import { CrDetailsComponent } from '@omni/components/mdm/contact/cr-details/cr-details';
import { DynamicFormType } from '@omni/models/dynamic-form-component.model';
import { MdmService } from '@omni/services/mdm/mdm.service';
import { DynamicForm, FormType } from '@omni/classes/dynamic-form/dynamic-form.class';
import { DynamicFormsService } from '@omni/services/dynamic-forms/dynamic-forms-service';
import { AccountCrDetailsComponent } from '@omni/components/mdm/account/account-cr-details/account-cr-details';
import { AllocationFeatureService } from '@omni/services/sample/allocation.feature.service';
import { TransferType, AdjustmentStatus } from '@omni/enums/allocation/allocation.enum';
import { IAllocationAdjustment } from '@omni/interfaces/allocation/allocation-adjustment.interface';
import { AllocationShipmentService } from '@omni/services/sample/allocation-shipment.service';
import { AllocationAdjustComponent } from '@omni/components/sample/allocation-adjust/allocation-adjust';
import { AllocationShipmentDetailsComponent } from '@omni/components/sample/allocation-shipment-details/allocation-shipment-details';
import { ContactOfflineService } from '@omni/services/contact/contact.service';
import { AccountOfflineService } from '@omni/services/account/account.offline.service';
import { FollowUpActionDetailComponent } from '@omni/components/activity/follow-up-action-detail/follow-up-action-detail';
import { FollowUpActivity, FOLLOW_UP_TYPE } from '@omni/classes/activity/follow-up-action.activity.class';
import { ScientificPlanFollowUpTask } from '@omni/components/activity/scientific-activity/scientific-plan-task/scientific-plan-task';
import { AuthenticationService } from '@omni/services/authentication.service';
import { ActivityDataService } from '@omni/data-services/activity/activity.service';
import { DatePipe } from '@angular/common';
import { OrderDetailsComponent } from '@omni/components/order-management/order-details/order-details';
import { DeviceService } from '@omni/services/device/device.service';
import { OrderManagementService } from '@omni/services/order-management/order-management.service';
import { OrderActivity } from '@omni/classes/activity/order.activity.class';
import { CustomNotificationDetails } from '@omni/components/custom-notification/custom-notification-details';
import { FeatureActionsService } from '@omni/services/feature-actions/feature-actions.service';

@Component({
  selector: 'notification-list',
  templateUrl: 'notification-list.html',
  styleUrls: ['notification-list.scss'],
})
export class NotificationListComponent {

  tabs: IndTabsDataModel[] = [];
  public rawNotifications: Array<IndNotificationDataModel> = new Array<IndNotificationDataModel>();
  public notifications: Array<IndNotificationDataModel> = new Array<IndNotificationDataModel>();
  public filteredNotifications: Array<IndNotificationDataModel> = new Array<IndNotificationDataModel>();
  public visibleNotifications: Array<IndNotificationDataModel> = new Array<IndNotificationDataModel>();
  allNotificationsSectionHeader: IndSectionHeaderViewDataModel;
  private itemExpandHeight: number = 25;
  private visibleCount: number = 0;
  actionPopoverData: ({ text: string; expanded: boolean; value: string; items: { text: string; value: string; asc: boolean; }[]; handler: (selectedItem: any, item: any) => void; })[] | ({ text: string; value: string; items: { text: String; value: String; }[]; handler: (selectedItem: any, item: any) => void; expanded?: undefined; })[];
  private filterPopoverData;
  private IallNotificationsSubscription: Subscription;
  private IcurrentNotifSubscription: Subscription;
  private itemSelected: IndNotificationDataModel = null;
  private selectedFilterByValue = 'all';
  private selectedCustomFilterByValue = 'all';
  private filterActived = false;

  constructor(
    public uiService: UIService,
    public activityService: ActivityService,
    public agendaFooterService: AgendaFooterService,
    public eventService: EventsService,
    public translate: TranslateService,
    public myAssistantService: MyAssistantService,
    public dateTimeFormatsService: DateTimeFormatsService,
    private popoverCtrl: PopoverController,
    private trackingService: TrackService,
    public navService: NavigationService,
    public emailService: EmailService,
    private contactService: ContactOfflineService,
    private accountService: AccountOfflineService,
    private notificationService: NotificationService,
    public events: EventsService,
    private caseManagementService: CaseManagementService,
    public footerService: FooterService,
    public mdmService: MdmService,
    private dynamicFormsService: DynamicFormsService,
    public allocFeatureService: AllocationFeatureService,
    public allocationShipmentService: AllocationShipmentService,
    public authenticationService: AuthenticationService,
    public activityDataService: ActivityDataService,
    private datePipe: DatePipe,
    private readonly device: DeviceService,
    private readonly orderManagementService: OrderManagementService,
    public assistantService: MyAssistantService,
    public faService: FeatureActionsService,
  ) { }

  ngOnInit() {
    this.IallNotificationsSubscription = this.myAssistantService.notificationsObs$.subscribe((data: IndNotificationDataModel[]) => {
      if (data) {
        this.notifications = [];
        this.rawNotifications = data;
        data.forEach(d => {
          this.notifications.push(d);
        });
        this.myAssistantService.hasAnyUnRedNotifications = _.some(data, ['isRed', false]);
        if (!this.filterActived) {
          this.filteredNotifications = this.visibleNotifications = this.notifications;
        }
        if (!this.filterActived) {
          this.sortNotifications(this.visibleNotifications);
        }
        this.initAllNotificationsHeader();
      }
    });
    this.updateVisibleRecords();
    this.IcurrentNotifSubscription = this.myAssistantService.selectedNotificationObs$.subscribe((model: IndNotificationDataModel) => {
      this.itemSelected = model;
    });
    this.tabs = this.uiService.getAgendaTabs();
    this.translate.onLangChange.subscribe(data => {
      this.tabs = this.uiService.getAgendaTabs();
    });
    this.initAllNotificationsHeader();
    this.actionPopoverData = [
      {
        text: "",
        expanded: true,
        value: "title",
        items: [
          { text: this.translate.instant('MARK_ALL_AS_READ'), value: "markallread", asc: false },
          { text: this.translate.instant('CLEAR_ALL'), value: "clearall", asc: false }
        ],
        handler: async (selectedItem, item) => {
          console.log(selectedItem.value);
          item.value = selectedItem.value;
          if (item.value == 'clearall') {
            this.trackingService.tracking('NotificationClearAll', TrackingEventNames.NOTIFICATIONS);
            await this.clearAllNotifications();
          } else {
            this.trackingService.tracking('NotificationReadAll', TrackingEventNames.NOTIFICATIONS);
            await this.markAllRedNotifications();
          }
          // this.sortBy = selectedItem;
          // this.sectionHeaderData.controls[0].text = selectedItem.text;
          this.updateVisibleRecords();
          this.initAllNotificationsHeader();
          this.popoverCtrl.dismiss();
        }
      }
    ];
    this.filterPopoverData = [
      {
        text: "",
        expanded: true,
        value: "all",
        selectedValues: ["all"],
        items: [
          { text: this.translate.instant('ALL'), value: "all" },
          { text: this.translate.instant('SYSTEM_NOTIFICATION'), value: "system-notification" },
          {
            text: this.translate.instant('FEED'),
            expanded: true,
            value: '',
            items: [
              { text: this.translate.instant('ALL_FEEDS'), value: "all-feeds" },
              { text: this.translate.instant('R_WEB'), value: 'web' },
              { text: this.translate.instant('App'), value: 'app' },
              { text: this.translate.instant('EVENT'), value: 'event' },
              { text: this.translate.instant('E_LEARNING'), value: 'learning' },
            ],
            handler: (selectedItem, item) => {
              item.value = selectedItem.value;
              this.selectedCustomFilterByValue = selectedItem.value;
              if (selectedItem.value) {
                this.applyCustomNotificationFiler(this.notifications, this.selectedFilterByValue, this.selectedCustomFilterByValue)
              }
              this.updateVisibleRecords();
              this.initAllNotificationsHeader();
              this.popoverCtrl.dismiss();
            }
          }
        ],
        handler: (selectedItem, item, itemRef, selected?: boolean) => {
          item.value = selectedItem.value;
          this.selectedFilterByValue = selectedItem.value;
          if (selectedItem.value) {
            this.applyCustomNotificationFiler(this.notifications, this.selectedFilterByValue, this.selectedCustomFilterByValue)
          }
          this.updateVisibleRecords();
          this.initAllNotificationsHeader();
          this.popoverCtrl.dismiss();
        }
      }

    ]
    this.activityService.selectedActivity = undefined;
  }

  ngOnDestroy() {
    if (this.IallNotificationsSubscription) this.IallNotificationsSubscription.unsubscribe();
    if (this.IcurrentNotifSubscription) this.IcurrentNotifSubscription.unsubscribe();
  }

  private _getFormattedDate(date) {
    return this.datePipe.transform(date, this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
  }

  private _getFormattedCustomNotificationDate(date) {
    return this.datePipe.transform(date, "dd-MM-yyyy , h:mm a");
  }

  mapNotificationMainCard(data) {
    let clickHandler = async (id: string, event, specificTarget) => {
      await this.openActivityDetails(id);
    };
    return {
      id: data.dbKey,
      fixedHeight: true,
      isExpandable: true,
      isExpanded: true,
      expandableViewType: 'notificationList',
      expandableData: data.data,
      expandHeight: this.itemExpandHeight,
      noSeparationLine: false,
      isSelected: (this.itemSelected && this.itemSelected.dbKey == data.dbKey),
      primaryTextRight: this.translate.instant(data.type, data.params),
      secondaryTextRight: this._getFormattedDate(new Date(data.DateTime)),//format(data.DateTime, this.dateTimeFormatsService.dateTimeToUpper),
      showArrow: false,
      showIcon: true,
      iconName: data.icon ? 'notification-' + data.icon : null,
      clickHandler: clickHandler,
      date: new Date(data.DateTime),//this._getFormattedDate(new Date(data.DateTime)),
      showStartIcon: !data.isRed,
      startIconName: "indegene-assets/imgs/notification_bluedot.svg"
    };
  }

  async openActivityDetails(id: string) {
    let notification = this.notifications.find(e => e.dbKey === id);
    await this.myAssistantService.markReadNotification(notification);
    this.myAssistantService.selectedNotification.next(notification);
    let data = notification.data;
    console.log(data);
    if (notification.type === NOTIFICATION.NEW_CONTACTS_NOTIFICATION) {
      let newContacts = _.filter(this.contactService.contacts, (con) => {
        return _.findIndex(data.data, (e) => { return con.ID == e }) >= 0;
      });
      if (!newContacts.length) {
        this.showNoDataBanner();
        return;
      }
    }
    if (notification.type === NOTIFICATION.NEW_ACCOUNTS_NOTIFICATION) {
      let newAccounts = _.filter(this.accountService.accounts, (acc) => {
        return _.findIndex(data.data, (e) => { return acc.id == e }) >= 0;
      });
      if (!newAccounts.length) {
        this.showNoDataBanner();
        return;
      }
    }
    switch (notification.type) {
      case NOTIFICATION.NEW_SEARCH_ADV_SEARCH:
      case NOTIFICATION.SERVICE_ERR:
      case NOTIFICATION.SYNC_FAILED:
      case NOTIFICATION.DATA_UPLOAD_ERR_FOLLOWUP:
      case NOTIFICATION.NEW_CONTACTS_NOTIFICATION:
      case NOTIFICATION.NEW_ACCOUNTS_NOTIFICATION:
      case NOTIFICATION.NEW_RESOURCES_NOTIFICATION:
      case NOTIFICATION.MISSED_CALL:
      case NOTIFICATION.NEW_PRESENTATIONS_NOTIFICATION:
      case NOTIFICATION.ASSET_REMOVED:
        console.log("text based details views");
        this.uiService.showNewActivity = false;
        this.uiService.showRightPane = true;
        if (this.navService.getActiveChildNavViewPageName() != PageName.NotificationDetailsComponent) {
          await this.navService.pushChildNavPageWithPageTracking(NotificationDetailsComponent, PageName.NotificationDetailsComponent, PageName.NotificationListComponent);
        }
        break;

      case NOTIFICATION.CONTACT_CHANGE_REQUEST_STATUS:
      case NOTIFICATION.BUSINESS_CHANGE_REQUEST_SUBMITTED:
      case NOTIFICATION.BUSINESS_ACCOUNT_CHANGE_REQUEST_SUBMITTED:
      case NOTIFICATION.BUSINESS_CHANGE_REQUEST_OPEN:
      case NOTIFICATION.BUSINESS_CHANGE_REQUEST_APPROVED:
      case NOTIFICATION.BUSINESS_CHNAGE_REUEST_REJECTED:
      case NOTIFICATION.BUSINESS_ACCOUNT_CHANGE_REQUEST_OPEN:
      case NOTIFICATION.BUSINESS_ACCOUNT_CHANGE_REQUEST_APPROVED:
      case NOTIFICATION.BUSINESS_ACCOUNT_CHANGE_REQUEST_REJECTED:
        console.log("business change request based details views");
        this.uiService.showNewActivity = false;
        this.uiService.showRightPane = true;
        await this.navService.setChildNavRoot(NotificationDetailsComponent, PageName.NotificationDetailsComponent, PageName.ActivitiesPageComponent, { from: PageName.ActivitiesPageComponent });
        break;
      case NOTIFICATION.DCR_FOR_APPROVAL:
        const dcr = this.contactService.approvableDCRRequests.find(dcr => dcr.ID === data?.data.ID);
        if (!dcr || dcr.approvalStatus != 'Pending') {
          this.notificationService.notify(this.translate.instant('DATA_NO_LONGER_EXIST'), 'Notification Details', 'top', ToastStyle.DANGER);
          return;
        }
        this.uiService.showNewActivity = false;
        this.uiService.showRightPane = true;
        await this.navService.setChildNavRoot(NotificationDetailsComponent, PageName.NotificationDetailsComponent, PageName.ActivitiesPageComponent, { from: PageName.ActivitiesPageComponent });
        break;
      case NOTIFICATION.ONE_KEY_CHANGE_REQUEST_STAGED:
      case NOTIFICATION.ONECRM_DCR_REQUEST_STAGED:
      case NOTIFICATION.ONECRM_UPDATE_DCR_REQUEST_STAGED:
      case NOTIFICATION.ONE_KEY_CHANGE_REQUEST_SUBMITTED:
      case NOTIFICATION.ONE_KEY_APPROVAL_AND_NEW_CONTACT_ADDEDD:
      case NOTIFICATION.ONE_KEY_REJECTED_SEE_COMMENTS:
      case NOTIFICATION.ONE_KEY_CHANGE_REQUEST_UPDATE_STAGED:
      case NOTIFICATION.ONE_KEY_CHNAGE_REQUEST_UPDATE_REQUEST_SUBMITTED:
      case NOTIFICATION.ONE_KEY_CHANGE_REQUEST_UPDATE_APPROVED_CONTACT_BE_UPDATED:
      case NOTIFICATION.ONECRM_DCR_APPROVED:
      case NOTIFICATION.ONECRM_DCR_REJECTED:
      case NOTIFICATION.ONE_KEY_CHANGE_REQUEST_UPDATE_REJECTED_SEE_COMMENTS:
      case NOTIFICATION.ONE_KEY_CHANGE_REQUEST_IN_PROCESS:
      case NOTIFICATION.ONE_KEY_CHANGE_REQUEST_SUCCESSFULLY_SUBMITTED:
        console.log("onekey change request based details views");
        this.uiService.showNewActivity = false;
        this.uiService.showRightPane = true;
        await this.navService.setChildNavRoot(NotificationDetailsComponent, PageName.NotificationDetailsComponent, PageName.ActivitiesPageComponent, { from: PageName.ActivitiesPageComponent });
        break;
      case NOTIFICATION.MESSAGE_OPENED:
      case NOTIFICATION.MESSAGE_ATTACHMENT_CLICKED:
      case NOTIFICATION.MESSAGE_SENT:
      case NOTIFICATION.MESSAGE_NOT_DELEVERED:
      case NOTIFICATION.DATA_UPLOAD_ERR_MESSAGE:
        console.log("open message details view");
        this.uiService.activeView = 'ActivitiesPageRightPaneNav';
        this.emailService.viewType = EmailViewType.FROM_AGENDA;
        this.activityService.selectedActivity = this.activityService.getActivityByID(data['activityid'] || data['offlineActivityId']);
        this.showNoDataBanner();
        this.emailService.selectedActivity = <EmailActivity>this.activityService.selectedActivity;
        await this.navService.setChildNavRoot(EmailActivityDetailComponent, PageName.EmailDetailsPageComponent, PageName.ActivitiesPageComponent, { from: PageName.ActivitiesPageComponent });
        break;
      case NOTIFICATION.DATA_UPLOAD_ERR_MEETING:
        this.uiService.activeView = 'ActivitiesPageRightPaneNav';
        this.uiService.showNewActivity = false;
        this.uiService.showRightPane = true;
        this.activityService.selected = (this.activityService.getActivityByID(data.offlineMeetingId) as AppointmentActivity);
        this.showNoDataBanner();
        await this.navService.setChildNavRoot(ActivitiesDetailsPaneComponent, PageName.ActivitiesDetailsPaneComponent, PageName.ActivitiesPageComponent);
        break;
      case NOTIFICATION.NEW_FOLLOW_UP_NOTIFICATION:
        this.uiService.activeView = "ActivitiesPageRightPaneNav";
        this.uiService.showNewActivity = false;
        this.uiService.showRightPane = true;
        this.activityService.selectedActivity = (this.activityService.getActivityByID(data.ID) as FollowUpActivity);
        this.showNoDataBanner();
        const followupActivity: FollowUpActivity = (this.activityService.getActivityByID(data.ID) as FollowUpActivity);
        if (followupActivity?.scientificPlanId || followupActivity?.planType === FOLLOW_UP_TYPE.SCIENTIFIC_PLAN) {
          await this.navService.setChildNavRoot(ScientificPlanFollowUpTask, PageName.ScientificPlanFollowUpTask, PageName.ActivitiesPageComponent, { from: 'AgendaView', activity: followupActivity });
        } else {
          await this.navService.setChildNavRoot(FollowUpActionDetailComponent, PageName.FollowUpActionDetailComponent, PageName.ActivitiesPageComponent, { from: 'AgendaView' });
        }
        this.footerService.initButtons(FooterViews.FollowUpDetails);
        break;
      case NOTIFICATION.INQUIRY_STATUS:
        let caseActivity: CaseActivity = this.caseManagementService.myCases.find(a => a.ID == data.incidentid);
        if (!caseActivity) {
          this.notificationService.notify(this.translate.instant('DATA_NO_LONGER_EXIST'), 'Notification Details', 'top', ToastStyle.DANGER);
          return;
        }
        try {
          this.caseManagementService.accessedFrom = AccesingMode.AGENDA;
          this.caseManagementService.assignSelectedCase(caseActivity);
        } catch (error) {
          console.log("failed to fetch incident from notifications");
        }
        this.uiService.activeView = "ActivitiesPageRightPaneNav";
        await this.navService.setChildNavRoot(CaseManagementDetailComponent, PageName.CaseManagementDetailComponent, PageName.ActivitiesPageComponent);
        this.footerService.initButtons(FooterViews.CUSTOMER_INQUIRY);
        break;
      case NOTIFICATION.CONTACT_CHANGE_REQUEST_STATUS:
        let contactForm: DynamicForm = await this.dynamicFormsService.getFormDefinitionForEntity("indskr_contactcr", FormType.DISPLAYFORM);
        this.mdmService.currentSelected.next(data);
        this.uiService.activeView = 'ContactPageRightPaneNav';
        this.uiService.showNewActivity = false;
        this.navService.setChildNavRightPaneView(true);
        await this.navService.pushChildNavPageWithPageTracking(CrDetailsComponent, PageName.CrDetailsComponent, PageName.ContactPageComponent, {
          from: 'Contact',
          displayFormDetails: contactForm,
          dynamicFormType: DynamicFormType.CONFIGUREDFORM
        });
        break;
      case NOTIFICATION.ACCOUNT_CHANGE_REQUEST_STATUS:
        this.mdmService.currentSelected.next(data);
        this.navService.setChildNavRightPaneView(true);
        this.uiService.showNewActivity = false;
        await this.navService.pushChildNavPageWithPageTracking(AccountCrDetailsComponent, PageName.AccountCrDetailsComponent, PageName.AccountPageComponent, {
          from: 'Account'
        });
        break;
      case NOTIFICATION.CUSTOMER_ALLOCATION_TRANSFER:
        let transfer = data;
        this.allocFeatureService.selectedShipment = transfer;
        this.allocFeatureService.selectedShipmentAtHome = this.allocFeatureService.selectedShipment;
        this.uiService.activeView = "ActivitiesPageRightPaneNav";
        this.uiService.prevView = this.uiService.activeView;
        this.uiService.showNewActivity = false;
        this.uiService.showRightPane = true;
        this.allocationShipmentService.fromAgendaPlanTab = true;
        if (transfer.indskr_transfertype == TransferType.AllocationTransfer) {

          this.allocationShipmentService.newShipmentSelectedFromList$.next(PageName.AllocationComponent);
          this.navService.setChildNavRoot(AllocationShipmentDetailsComponent, PageName.AllocationShipmentDetailsComponent, PageName.ActivitiesPageComponent);
        }
        break;
      case NOTIFICATION.CUSTOM_NOTIFICATION:
        let customNotifications = data;
        this.uiService.activeView = "ActivitiesPageRightPaneNav";
        this.uiService.showNewActivity = false;
        this.uiService.showRightPane = true;
        this.navService.setChildNavRoot(CustomNotificationDetails, PageName.CustomNotificationDetails, PageName.ActivitiesPageComponent, { from: 'AgendaView', activity: customNotifications });
        break;
      case NOTIFICATION.NOTIFICATION_TO_COVISITOR:
        let activity = data;
        this.uiService.activeView = "ActivitiesPageRightPaneNav";
        this.uiService.prevView = this.uiService.activeView;
        this.uiService.showNewActivity = false;
        this.uiService.showRightPane = true;
        this.activityService.selectedActivity = this.activityService.getActivityByID(activity.ID) as AppointmentActivity
        this.activityDataService.updateActivityDetails(activity);
        if (this.activityService.selectedActivity) {
          await this.navService.setChildNavRoot(ActivitiesDetailsPaneComponent, PageName.ActivitiesDetailsPaneComponent, PageName.ActivitiesPageComponent);
        } else {
          await this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView, PageName.NotificationListComponent)
          this.showMeetingNoDataBanner(activity);

        }
        break;
      case NOTIFICATION.ADJUSTMENT_REJECTED:
        let shipment = data;
        this.allocFeatureService.selectedShipment = shipment;
        this.uiService.activeView = "ActivitiesPageRightPaneNav";
        if (shipment.indskr_transfertype === TransferType.AllocationAdjustment) {
          // Adjustment
          if ((shipment as IAllocationAdjustment).indskr_adjustedstatus === AdjustmentStatus.Open) {
            this.allocationShipmentService.isNewAdjustment = false;
            this.allocationShipmentService.newAdjustmentSelectedFromList$.next(true);
            await this.navService.setChildNavRoot(AllocationAdjustComponent, PageName.AllocationShipmentDetailsComponent, PageName.ActivitiesPageComponent);
          } else {
            this.allocationShipmentService.newShipmentSelectedFromList$.next(PageName.AllocationComponent);
            await this.navService.setChildNavRoot(AllocationShipmentDetailsComponent, PageName.AllocationShipmentDetailsComponent, PageName.ActivitiesPageComponent);
          }
        } else {
          // Shipment or Transfer
          this.allocationShipmentService.newShipmentSelectedFromList$.next(PageName.AllocationComponent);
          await this.navService.setChildNavRoot(AllocationShipmentDetailsComponent, PageName.AllocationShipmentDetailsComponent, PageName.ActivitiesPageComponent);
        }
        break;
      case NOTIFICATION.NEW_TEAM_ORDER_NOTIFICATION:
        if (!this.device.isOffline) {
          this.uiService.displayLoader();
          let foundOrder = new OrderActivity({
            salesorderid: data.id,
            ordernumber: data.data,
            activitytypecode: 'indskr_order'
          })
          await this.activityDataService.getRealTimeActivityDetails(foundOrder).then(newOrder => {
            this.orderManagementService.selectedOrder = newOrder as OrderActivity;
            this.activityService.selectedActivity = newOrder as OrderActivity;
            this.uiService.activeView = "ActivitiesPageRightPaneNav";
            this.uiService.showNewActivity = false;
            this.uiService.showRightPane = true;
            this.navService.setChildNavRoot(OrderDetailsComponent, PageName.OrderDetailsComponent, PageName.ActivitiesPageComponent, { from: 'AgendaView', activity: newOrder });
          });
          this.uiService.dismissLoader();

        } else {
          this.notificationService.notify(this.translate.instant('TEAM_SALES_ORDER_OFFLINE_NOTIFICATION'), 'Notification Details', 'top', ToastStyle.DANGER);
        }
        break;
      case NOTIFICATION.CUSTOMER_POSITION_LIST_SUBMITTED:
      case NOTIFICATION.CUSTOMER_POSITION_LIST_APPROVED:
      case NOTIFICATION.CUSTOMER_POSITION_LIST_REJECTED:
        this.uiService.showNewActivity = false;
        this.uiService.showRightPane = true;
        await this.navService.pushChildNavPageWithPageTracking(NotificationDetailsComponent, PageName.NotificationDetailsComponent, PageName.NotificationListComponent);
        break;
      case NOTIFICATION.APPEAL_STATUS_SUBMITTED:
      case NOTIFICATION.APPEAL_STATUS_APPROVED:
      case NOTIFICATION.APPEAL_STATUS_REJECTED:
      case NOTIFICATION.APPEAL_STATUS_PENDING_FOR_SUBMISSION:
        this.uiService.showNewActivity = false;
        this.uiService.showRightPane = true;
        await this.navService.pushChildNavPageWithPageTracking(NotificationDetailsComponent, PageName.NotificationDetailsComponent, PageName.NotificationListComponent);
        break;
      default:
        console.log(`open notification - Unhandled case : ${notification.type}`);
    }
  }

  showNoDataBanner() {
    if (!this.activityService.selectedActivity) {
      this.notificationService.notify(this.translate.instant('DATA_NO_LONGER_EXIST'), 'Notification Details', 'top', ToastStyle.DANGER);
      return;
    }
  }

  showMeetingNoDataBanner(activity) {
    if (!this.activityService.selected || (_.isEmpty(activity['accompaniedUserList']) || !activity['accompaniedUserList'].find(ac => ac.indskr_user === this.authenticationService.user.systemUserID))) {
      this.notificationService.notify(this.translate.instant('THIS_MEETING_NO_LONGER_EXIST'), 'Notification Details', 'top', ToastStyle.DANGER);
      return;
    }
  }
  async clearAllNotifications() {
    if (!this.notifications.length) return;
    await this.myAssistantService.clearAllNotifications();
    await this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView, PageName.NotificationListComponent);
  }

  async markAllRedNotifications() {
    if (_.every(this.notifications, ['showStartIcon', false])) return;
    await this.myAssistantService.markAllRedNotifications();
  }

  sortNotifications(notifications: IndNotificationDataModel[]) {
    return notifications.sort((a, b) => {
      if (new Date(a.DateTime) < new Date(b.DateTime))
        return 1;
      else
        return -1;
    });
  }

  get indHeaderLeftModel(): IndHeaderLeftDataModel {
    return {
      id: 'notification-list-header-left',
      cssClass: 'main-tool-header-title',
      title: this.translate.instant('HOME'),
      mode: true,
      isOnActPage: true,
      controls: [],
    };
  }

  initAllNotificationsHeader() {
    let titleText = `${this.translate.instant('ALL') + ' ' + this.translate.instant('NOTIFICATIONS')} (${this.filteredNotifications.length})`;
    if (this.translate.currentLang == 'es') {
      titleText = `${this.translate.instant('ALL_NOTIFICATIONS')} (${this.filteredNotifications.length})`;
    } else if (this.translate.currentLang == 'it') {
      titleText = `${this.translate.instant('NOTIFICATIONS')} (${this.filteredNotifications.length})`;
    }
    this.allNotificationsSectionHeader = {
      id: 'all-notifications-header',
      title: titleText,
      controls: [{
        id: 'clean-read-notifications',
        icon: 'assets/imgs/notifications-threedots.svg',
        isDisabled: false
      }],
    }
  }

  clearNotification(data: IndNotificationDataModel) {
    this.myAssistantService.clearNotification(data);
    this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView, PageName.NotificationListComponent);
  }

  markReadNotification(data: IndNotificationDataModel) {
    if (data.isRed) return;
    this.myAssistantService.markReadNotification(data);
  }

  markUnReadNotification(data: IndNotificationDataModel) {
    if (!data.isRed) return;
    this.myAssistantService.markUnReadNotification(data);
  }

  onInput(event) {
    if (event && event.target) {
      const val = event.target.value;
      if (val && val.trim() != '') {
        this.filteredNotifications = this.notifications.filter(data => {
          return ((data.name).toLowerCase()).includes(val.toLowerCase());
        }) as Array<IndNotificationDataModel>;
      } else {
        this.filteredNotifications = this.notifications;
      }
    }
    this.sortNotifications(this.filteredNotifications);
    this.updateVisibleRecords();
    this.initAllNotificationsHeader();
  }

  onCancel(event) {
    this.filteredNotifications = this.notifications;
    this.sortNotifications(this.filteredNotifications);
    this.updateVisibleRecords();
    this.initAllNotificationsHeader();
  }

  onSelectedTabChange(selectedTab: string) {
    this.uiService.setAgendaTab(selectedTab);
  }

  public onSectionHeaderControlClick(id) {
    if (id == 'clean-read-notifications') {
      this.showSortPopover();
    }
  }

  async showSortPopover() {
    this.actionPopoverData[0].value = '';
    const popover = await this.popoverCtrl
      .create({ component: MultiSelectPopover, componentProps: { root: this.actionPopoverData }, event: event }).then((data) => {
        data.present();
      });
  }

  public doInfinite(event) {
    this.visibleCount = this.visibleNotifications.length;
    this.visibleNotifications.push(...this.filteredNotifications.slice(this.visibleCount, this.visibleCount + 20));
    event.target.complete();
  }

  private updateVisibleRecords() {
    this.visibleCount = 20;
    if (this.filteredNotifications.length > 20) {
      this.visibleNotifications = [...this.filteredNotifications].slice(0, this.visibleCount);
    } else {
      this.visibleNotifications = this.filteredNotifications;
    }
  }

  async openCustomNotificationFilterPopup(event) {
    this.popoverCtrl.create({ component: MultiSelectPopover, componentProps: { root: this.filterPopoverData }, cssClass: 'dropdown-list-view', event: event }).then((data) => data.present())
  }

  applyCustomNotificationFiler(filteredNotifications, slectedFilterValue: string, selectedCustomFilterByValue: string) {
    this.filterActived = true;
    if (selectedCustomFilterByValue == 'app') {
      this.filteredNotifications = filteredNotifications.filter(el => el.data['indskr_channel'] == 600000001)
    } else if (selectedCustomFilterByValue == 'web') {
      this.filteredNotifications = filteredNotifications.filter(el => el.data['indskr_channel'] == 600000000)
    } else if (selectedCustomFilterByValue == 'event') {
      this.filteredNotifications = filteredNotifications.filter(el => el.data['indskr_channel'] == 600000002)
    } else if (selectedCustomFilterByValue == 'learning') {
      this.filteredNotifications = filteredNotifications.filter(el => el.data['indskr_channel'] == 600000003)
    } else if (selectedCustomFilterByValue == 'all-feeds') {
      this.filteredNotifications = filteredNotifications.filter(el => el.type == NOTIFICATION.CUSTOM_NOTIFICATION)
    }
    if (slectedFilterValue == 'all') {
      this.filterActived = false;
      this.filteredNotifications = filteredNotifications
    } else if (slectedFilterValue == "system-notification") {
      this.filteredNotifications = filteredNotifications.filter(el => el.type != NOTIFICATION.CUSTOM_NOTIFICATION)
    }
  }



}
