import { Endpoints } from "../../../config/endpoints.config";
/**
 * A class for handling domain related tasks and getting configuration from realm/domain
 * https://entrypoint-devps-na.io.indegene.com/api/tnt/azuretenantdetails/domain?domain=dynamics.dev.indegene.com
 *
 * @export
 * @class Domain
 */
export class Domain {
    private ID: string;
    private region: 'china' | 'global';
    public entryPointURL: string;
    public realm: string;
    public realmLogoURL: string;
    public themeColor: string;
    public resourceURL: string;
    public realmID: string;
    public clientID: string;
    public idleTimeoutValue: number;
    public forgotPasswordURL: number;
    public weChatSupport: boolean;
    public authority: string;

    /**
     * Creates an instance of Domain.
     *
     * @param {string} rawJSON
     *
     * @memberOf Domain
     */
    constructor (raw: object) {
        this.ID = raw['id'];
        this.entryPointURL = raw['entry-point-url'];
        this.forgotPasswordURL = raw['forgot-password-url'];
        this.clientID = raw['client-id'];
        this.idleTimeoutValue = raw['offline-session-idle-timeout'];
        this.realm = raw['realm'];
        this.realmID = raw['realm-id'];
        this.realmLogoURL = raw['realm-logo'];
        this.region = (raw['region'] || '')?.toLowerCase() || 'global';
        this.resourceURL = raw['resource'];
        this.themeColor = raw['theme-color'];
        this.weChatSupport = raw['we-chat-support'];
        this.authority = raw['authority-url'];
        if (!this.authority) {
          let microsoftBaseUrl = Endpoints.authentication.MICROSOFT_BASE_URL_GLOBAL;
          if (this.region === 'china') {
            microsoftBaseUrl = Endpoints.authentication.MICROSOFT_BASE_URL_CHINA;
          }
          this.authority = `${microsoftBaseUrl}/${this.realmID}`;
        }
    }
}
