import { Subject, BehaviorSubject } from 'rxjs';
import { Opportunity, Quote, Agreement, LookupSearchResult, OpportunityCollaborators } from '../../classes/opportunity-management/opportunity.class';
import { Injectable } from '@angular/core';
import { AuthenticationService } from '../authentication.service';
import { Account } from '../../classes/account/account.class';
import { DiskService, OFFLINE_DATA_COUNT_ENTITY_NAME } from '../disk/disk.service';
import { TranslateService } from '@ngx-translate/core';
import { SKUProduct } from '@omni/classes/activity/surgery-order.activity.class';
import { format } from 'date-fns';
import { DateTimeFormatsService } from '../date-time-formats/date-time-formats.service';
@Injectable({
  providedIn: 'root'
})
export class OpportunityManagementService {
  public opportunities: Opportunity[] = [];
  public opportunities$: Subject<Opportunity[]> = new Subject();
  public qoutes: Quote[];
  public agreements: Agreement[];
  public stakeholderRoles: any = [];
  public collaboratorRoles: any = [];
  public selectedCollaboratorList: any = [];
  public selectedOpportunityOwnerID: string = '';

  public newOpportunity$ = new BehaviorSubject<Opportunity>(null);
  public newOpportunityObserver = this.newOpportunity$.asObservable();

  private selectedCollaborators = new BehaviorSubject<OpportunityCollaborators>(null);
  public selectedCollaboratorsObserver = this.selectedCollaborators.asObservable();

  public purchaseProcessOptions: Array<any> = [
    {
      text: 'Individual',
      value: 0,
    },
    {
      text: 'Committee',
      value: 1,
    },
    {
      text: 'Unknown',
      value: 2,
    }
  ];
  opportunityTypes: any = [];
  opportunityCompetitors: any = [];
  //opportunityOptionSets: any = [];
  opportunityReasons:any=[];

  public productsHierarchyByPosition : Array<{
      productCategoryId: string,
      productCategoryName: string,
      productCategoryStatecode: number,
      products:SKUProduct[]
    }> = [];

  // public preSelectedAccount: Account;
  // public preSelectedopportunity: Opportunity

  constructor(
    private authService: AuthenticationService,
    private disk: DiskService,
    private translate: TranslateService,
    public readonly dateTimeFormatsService: DateTimeFormatsService,
  ) { }

  public getOpportunityDetailsbyId(id: string): Opportunity {
    if (this.opportunities && this.opportunities.length > 0) {
      return this.opportunities.find(opportunity => opportunity.ID == id
                                                    || (opportunity.offlineId
                                                    && opportunity.offlineId == id));
    } else {
      return null;
    }
  }

  public getOpportunitiesByAccountId(id: string): Array<Opportunity> {
    if (this.opportunities && this.opportunities.length > 0) {
      return this.opportunities.filter(opportunity => opportunity.accountID == id);
    } else {
      return [];
    }
  }

  public addUpdateOpportuntiy(opportunity: Opportunity) {
    return new Promise(async (resolve, reject) => {
      if (opportunity.ID) {
        let idx = this.opportunities.findIndex(value => value.ID === opportunity.ID
                                                        || (opportunity.offlineId
                                                        && opportunity.offlineId == value.offlineId));
        if (idx >= 0) {
          this.opportunities[idx] = opportunity;
          this.opportunities$.next(this.opportunities);
          resolve("Successfuly updated new opportunity");
        } else {
          this.opportunities.push(opportunity);
          this.opportunities$.next(this.opportunities);
          resolve("Successfuly added new opportunity");
        }
      } else {
        reject("Cannot add data with no id");
      }
    });
  }

  public removeOpportuntiy(opportunity: Opportunity) {
    return new Promise(async (resolve, reject) => {
      if (opportunity.ID) {
        let idx = this.opportunities.findIndex(value => value.ID === opportunity.ID
                                                        || (opportunity.offlineId
                                                        && opportunity.offlineId == value.offlineId));
        if (idx >= 0) {
          this.opportunities.splice(idx, 1);
          this.opportunities$.next(this.opportunities);
          resolve("Successfuly removed opportunity");
        } else {
          reject("No opportunity found with given id");
        }
      } else {
        reject("Cannot remove data with no id passed");
      }
    });
  }

  public getNewOpportunityObject(): Opportunity {
    let curTime:Date = new Date();
    let payload = {
      opportunityid: 'offline_opportunity_'+curTime.getTime().toString(),
      indskr_externalid: 'offline_opportunity_'+curTime.getTime().toString(),
      createdby: this.authService.user.xSystemUserID,
      createdbyusername: this.authService.user.displayName,
      ownerid: this.authService.user.xSystemUserID,
      ownername: this.authService.user.displayName,
      createdon: curTime.getTime(),
      name: this.translate.instant('OPPORTUNITY'),
    };
    return new Opportunity(payload);
  }

  public getStakeholderStringForOpportunity(opportunity):string{
    let stakeholderString:string = '';
    //find first alphabetical account
    opportunity.stakeholders.sort((contactA, contactB): number => {
        let contactAFullName = (contactA.firstName.length > 0) ? contactA.firstName + " " + contactA.lastName : contactA.lastName;
        let contactBFullName = (contactB.firstName.length > 0) ? contactB.firstName + " " + contactB.lastName : contactB.lastName;
        if (contactAFullName.toLowerCase() > contactBFullName.toLowerCase()) return 1;
        if (contactAFullName.toLowerCase() < contactBFullName.toLowerCase()) return -1;
        return 0;
    });
    //Only one contact, return his name
    if (opportunity.stakeholders.length === 1) {
        stakeholderString = `${opportunity.stakeholders[0].fullName}`;
        if(!opportunity.stakeholders[0].isActive && String(opportunity.stakeholders[0].fullName).trim() != ''){
            stakeholderString += ' (Inactive)';
        }
    } else if (opportunity.stakeholders.length >= 2) {
        stakeholderString =  `${opportunity.stakeholders[0].fullName}`;
        if(!opportunity.stakeholders[0].isActive){
            stakeholderString += ` (Inactive) +${opportunity.stakeholders.length-1}`;
        }else if(opportunity.stakeholders.some((con,index) =>{
            if(index == 0) return false;
            return (!con.isActive);
        })){
            stakeholderString =  `${opportunity.stakeholders[0].fullName} +${opportunity.stakeholders.length-1} (Inactive)`;
        }else{
            stakeholderString =  `${opportunity.stakeholders[0].fullName} +${opportunity.stakeholders.length-1}`;
        }
    }
    if(!stakeholderString || stakeholderString == undefined || stakeholderString == 'undefined ') {
      return "";
    }
    else
    return stakeholderString;
  }

  trackOfflineOpportunityDataCount() {
    const offlineDataCount = this.opportunities.filter(o => o.pendingPushToDynamics === true).length;
    this.disk.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.OPPORTUNITY, offlineDataCount);
  }

  public setSelectedCollaborators(selectedData: OpportunityCollaborators) {
    this.selectedCollaborators.next(selectedData);
    this.selectedCollaborators.next(null);
  }

   setOpportunityDescription(opportunity){
    let opportunityName = '';
    if(opportunity.estimatedDate){
      opportunityName = new Date(parseInt(opportunity.estimatedDate)).getFullYear().toString();
    }

    if(opportunity.products.length > 0){
      opportunityName = opportunityName === '' ? opportunity.products[0].productName  :`${opportunityName} - ${opportunity.products[0].productName}`;
    }

    if(opportunity.accountName){
      opportunityName = opportunityName === '' ? opportunity.accountName : `${opportunityName} - ${opportunity.accountName}`;
    }

    if(opportunity.stakeholders.length > 0){
      opportunityName = opportunityName === '' ? opportunity.stakeholders[0].firstName  : `${opportunityName} - ${opportunity.stakeholders[0].firstName}`;
    }

    opportunityName = opportunityName == '' ? opportunity.ownerName  : `${opportunityName} - ${opportunity.ownerName}`;
    return opportunityName;
  }

}
