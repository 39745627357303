import { Injectable } from "@angular/core";

declare var Microsoft: any;

@Injectable({
  providedIn: 'root'
})
export class MsalService {
  private _context: any;
  private _initialized = false;
  constructor() {

  }

  async init({ clientId, authority, redirectUri }) {
    if (this._initialized) return;
    this._initialized = true;
    this._context = await Microsoft?.ADAL?.AuthenticationContext?.create({
      auth: {
        clientId: clientId,
        authority,
        redirectUri: redirectUri,
      }
    })
  }

  async login(loginHint) {
    return await this._context.loginPopup({ loginHint })
  }

  async logout(loginHint) {
    return await this._context.logoutPopup({ loginHint })
  }

  async acquireToken({ resource, loginHint, scopes }: { resource?: string, loginHint?: string, scopes?: string[] }, interactive = false) {
    if (!this._context) return;
    if (!scopes?.length) {
      scopes = resource ? [`${resource.endsWith('/') ? resource : (resource + '/')}.default`] : ['openid', 'profile'];
    }
    let res = await this._context.acquireTokenSilent({ scopes, loginHint }).catch(err => {
      console.error(err);
      return undefined;
    })
    if (!res && interactive) {
      res = await this._context.acquireTokenPopup({ scopes: ['openid', 'profile'], loginHint }).catch(err => {
        console.error(err);
        return undefined;
      })
      if (res) {
        return this.acquireToken({ resource, loginHint, scopes });
      }
    }
    return res;
  }

  async getToken(resource: string, loginHint?: string) {
    const res = await this.acquireToken({ resource, loginHint })
    return res.accessToken;
  }

  async isLoggedIn() {

  }
}
