import { FilterConditionOperators } from '../../../enums/fetch-xml/fetch-xml.enum';
import { AttributeXMLTag, FilterConditionXMLTag, FilterXMLTag, LinkedEntityXMLTag, OrderXMLTag } from '../../../interfaces/fetch-xml/fetch-xml.interface';

export const AccountGlobalSearchEntityLevelCharacterSearchAttributeName: string = 'name';
export const AccountGlobalSearchAttributes: AttributeXMLTag[] = [
  { name: 'accountid' }, { name: 'name' }, { name: 'createdon' },
  { name: 'modifiedon' }
];

export const AccountGlobalSearchPositionLink: LinkedEntityXMLTag = {
  name: 'indskr_customerposition',
  from: 'indskr_customerid',
  to: 'accountid',
  linkType: 'outer',
  alias: 'cp',
  linkedEntities: [
    {
      name: 'position',
      from: 'positionid',
      to: 'indskr_positionid',
      linkType: 'outer',
      alias: 'position',
      filters: [
        {
          type: 'or',
          conditions: [
            {
              attribute: 'positionid',
              operator: FilterConditionOperators.in,
              valueList: []
            },
            {
              attribute: 'parentpositionid',
              operator: FilterConditionOperators.in,
              valueList: []
            },
          ]
        }
      ]
    }
  ]
};

export const AccountGlobalSearchDefaultFilterConditions: FilterConditionXMLTag[] = [
  { attribute: 'statecode', operator: FilterConditionOperators.eq, value: '0' },
];

export const AccountGlobalSearchDefaultPositionFilterCondition: FilterConditionXMLTag = { 
  entityname: 'position',
  attribute: 'positionid',
  operator: FilterConditionOperators.null
};

export const AccountGlobalSearchDefaultFilter: FilterXMLTag = {
  conditions: AccountGlobalSearchDefaultFilterConditions,
  nestedFilters: [],
}

export const AccountGlobalSearchDefaultOrder: OrderXMLTag = {
  attribute: 'name',
  descending: false
}
