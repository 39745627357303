import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Presentation } from '@omni/classes/presentation/presentation.class';
import { IndFormFieldViewDataModel } from '@omni/models/indFormFieldDataModel';
import { IndPageTitleViewDataModel } from '@omni/models/indPageTitleDataModel';
import { DateTimeFormatsService } from '@omni/services/date-time-formats/date-time-formats.service';
import { DeviceService } from '@omni/services/device/device.service';
import { NavigationService } from '@omni/services/navigation/navigation.service';
import { PresentationService } from '@omni/services/presentation/presentation.service';
import { isValid } from 'date-fns';
import moment from 'moment';

@Component({
  selector: 'presentation-detail',
  templateUrl: './presentation-detail.html',
  styleUrls: ['./presentation-detail.scss'],
})
export class PresentationDetailComponent implements OnInit {

  public titleBar: IndPageTitleViewDataModel;
  public presentationTitle: IndFormFieldViewDataModel;
  public presentationDescription: IndFormFieldViewDataModel;
  public presentationAvailableFrom: IndFormFieldViewDataModel;
  public presentationAvailableTo: IndFormFieldViewDataModel;
  public presentationProduct: IndFormFieldViewDataModel;
  public presentationPageCount: IndFormFieldViewDataModel;
  public presentationSpeciality: IndFormFieldViewDataModel;
  public presentationLanguge: IndFormFieldViewDataModel;
  public description: boolean = false;
  public availableFrom: boolean = false;
  public availableTo: boolean = false;
  public product: boolean = false;
  public pageNumber: boolean = false;
  public languageName: boolean =false;
  public specialityName: boolean =false;

  @Input() selectedPresentation: Presentation;
  @Input() from: any;

  constructor(
    private readonly navService: NavigationService,
    public device: DeviceService,
    public translate: TranslateService,
    public presentationService: PresentationService,
    private datePipe: DatePipe,
    public dateTimeFormatsService: DateTimeFormatsService,
  ) { }

  ngOnInit() {
    this.initPresentationStaticViewData();
    this.getPageTitle();
    this.getFormData();
  }

  public getFormData() {
    if (this.selectedPresentation?.description) {
      this.description = true;
    }
    if (this.selectedPresentation?.availableFrom && isValid(this.selectedPresentation.availableFrom)) {
      this.availableFrom = true;
    }
    if (this.selectedPresentation?.availableUntil && isValid(this.selectedPresentation.availableUntil)) {
      this.availableTo = true;
    }
    if (this.selectedPresentation?.brands?.length) {
      this.product = true;
    }
    if (this.selectedPresentation?.presentationPages?.length) {
      this.pageNumber = true;
    }
    if (this.selectedPresentation?.languageName) {
      this.languageName = true;
    }
    if (this.selectedPresentation?.specialties?.length) {
      this.specialityName = true;
    }
  }
  public getPageTitle() {
    let buttons = [];
    if (this._enableBackButton) {
      buttons = [{
        id: 'close',
        icon: 'chevron-back-outline',
        isDisabled: false,
        align: 'left'
      }];
    }
    this.titleBar = {
      id: "id",
      //title: this.translate.instant('PRESENTATION') + ' ' + this.translate.instant('DETAILS'),
      title: this.selectedPresentation?.name ? this.selectedPresentation.name : "",
      controls: buttons
    }
  }

  private get _enableBackButton(): boolean {
    return window.innerWidth < 500 || (this.from && (this.from === 'PresentationPreview'));
  }

  public onPageTitleControlClick(id: string) {
    switch (id) {
      case 'close':
        this.closePage();
        break;
      default:
        console.log('Unhandled switch case statement');
        break;
    }
  }

  private initPresentationStaticViewData() {
    // this.presentationTitle = {
    //   label: this.translate.instant('TITLE'),
    //   inputText: this.selectedPresentation?.name ? this.selectedPresentation.name : "",
    //   id: 'presentation-title',
    //   isReadOnly: true,
    //   isDisabled: true,
    // };
    this.presentationDescription = {
      label: this.translate.instant('DESCRIPTION'),
      inputText: this.selectedPresentation?.description ? this.selectedPresentation.description : "",
      id: 'presentation-description',
      isReadOnly: true,
      isDisabled: true,
    };
    this.presentationAvailableFrom = {
      label: this.translate.instant('AVAILABLE') + ' ' + this.translate.instant('FROM'),
      inputText: this.formatDate(this.selectedPresentation?.availableFrom),
      id: 'presentation-availblefrom',
      isReadOnly: true,
      isDisabled: true,
    };
    this.presentationAvailableTo = {
      label: this.translate.instant('AVAILABLE') + ' ' + this.translate.instant('TO'),
      inputText: this.formatDate(this.selectedPresentation?.availableUntil),
      id: 'presentation-availbleto',
      isReadOnly: true,
      isDisabled: true,
    };
    this.presentationProduct = {
      label: this.translate.instant('PRODUCT'),
      inputText: this.getProductName(this.selectedPresentation),
      id: 'presentation-product',
      isReadOnly: true,
      isDisabled: true,
    };
    this.presentationPageCount = {
      label: this.translate.instant('NUMBER_OF_PAGES'),
      inputText: this.selectedPresentation.presentationPages?.length ? "" + this.selectedPresentation.presentationPages?.length : "",
      id: 'presentation-pagecount',
      isReadOnly: true,
      isDisabled: true,
    };
    this.presentationLanguge = {
      label: this.translate.instant('LANGUAGE'),
      inputText: this.selectedPresentation?.languageName ? this.selectedPresentation.languageName : "",
      id: 'presentation-title',
      isReadOnly: true,
      isDisabled: true,
    };
    this.presentationSpeciality = {
      label: this.translate.instant('SPECIALITY'),
      inputText: this.getSpecialtyName(this.selectedPresentation),
      id: 'presentation-title',
      isReadOnly: true,
      isDisabled: true,
    };
  }

  private closePage() {
    this.navService.popChildNavPageWithPageTracking();
    this.presentationService.setCurrentSelectedPres(this.selectedPresentation);
    this.presentationService.presShowRightPane = true;
  }

  private formatDate(date: Date): string {
    if (date && isValid(date)) {
     // return moment(date).format('MMM DD, YYYY');
     return this.datePipe.transform(date, this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
    }
    return "";
  }

  private getProductName(presentation: Presentation): string {
    if (presentation && presentation.brands && presentation.brands.length) {
      return presentation.brands[0].name;
    } else {
      return "";
    }
  }

  private getSpecialtyName(presentation: Presentation): string {
    if (presentation && presentation.specialties && presentation.specialties.length) {
      return presentation.specialties[0].name;
    } else {
      return "";
    }
  }

}
