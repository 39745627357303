import { IonNav, NavParams } from '@ionic/angular';
import { ActivityService } from '@omni/services/activity/activity.service';
import { IndSectionHeaderViewDataModel } from '@omni/models/indSectionHeaderDataModel';
import { TranslateService } from '@ngx-translate/core';
import { IndHeaderLeftDataModel } from '@omni/models/indHeaderLeftDataModel';
import { EmailTemplateType } from './../../../classes/email-templates/email-template.class';
import { EmailService } from '@omni/services/email-templates/email.service';
import { cloneDeep } from 'lodash';
import { ResourceEmailTemplate } from '@omni/classes/email-templates/email-template.class';
import { Component, OnInit, EventEmitter } from '@angular/core';

@Component({
  selector: 'calendar-invite-template-list',
  templateUrl: 'calendar-invite-template-list.html',
  styleUrls: ['../../email/email-template-list/email-template-list.scss']
})
export class CalendarInviteTemplateListComponent implements OnInit {
  selectedTemplate: ResourceEmailTemplate;
  templates: ResourceEmailTemplate[] = [];
  searchedTemplates: ResourceEmailTemplate[] = [];
  headerData: IndHeaderLeftDataModel;
  selectedTemplateSectionHeaderData: IndSectionHeaderViewDataModel;
  templateListSectionHeaderData: IndSectionHeaderViewDataModel;
  searchResultSectionHeaderData: IndSectionHeaderViewDataModel;
  doneButtonRef: any;
  searchText: string = '';
  isSearching: boolean = false;
  highlightedTemplateId: string = '';
  sortBy: { text: string; value: string; asc: boolean; };
  sortPopoverData: (
    { text: string; expanded: boolean; value: string; items: { text: string; value: string; asc: boolean; }[]; handler: (selectedItem: any, item: any) => void; }
    | { text: string; value: string; items: { text: string; value: string; asc: boolean; }[]; handler: (selectedItem: any, item: any) => void; expanded?: undefined; })[];
  private evEmitter: EventEmitter<string>;

  constructor(
    private navCtrl: IonNav,
    private navParams: NavParams,
    private activityService: ActivityService,
    private emailService: EmailService,
    public translate: TranslateService,
  ) {
    if (this.navParams && this.navParams.get('evEmitter')) {
      this.evEmitter = this.navParams.get('evEmitter');
    }
  }

  ngOnInit() {
    this.templates = cloneDeep(
      this.emailService.emailTemplates
        .filter(template => template.indskr_type === EmailTemplateType.CalendarInvite)
    );
    this.selectedTemplate = this.emailService.selectedCalendarInviteTemplate;
    this.initSortData();
    this.initHeader();
    this.initSectionHeader();
  }

  private initSortData() {
    this.sortPopoverData = [
      {
        text: "",
        expanded: true,
        value: "indskr_name",
        items: [
          { text: this.translate.instant('NAME'), value: "indskr_name", asc: true },
          { text: this.translate.instant('LAST_MODIFIED_DATE'), value: "modifiedon", asc: false }
        ],
        handler: (selectedItem, item) => {
          item.value = selectedItem.value;
          this.sortBy = selectedItem;
          this.initSectionHeader();
        }
      }
    ];
    this.sortBy = this.sortPopoverData[0].items[0];
  }
  private initHeader() {
    const buttons = [
      {
        id: 'cancel',
        cssClass: 'seventyPercentWidth',
        imgSrc: 'assets/imgs/header_cancel.svg',
        isDisabled: false,
        align: 'left'
      },
      {
        id: 'done',
        cssClass: 'seventyPercentWidth',
        imgSrc: 'assets/imgs/header_complete.svg',
        isDisabled: this.isDoneButtonDisabled(),
        align: 'right'
      }
    ];

    this.headerData = {
      id: 'calendar-invite-template-list-header-left',
      title: this.translate.instant('TEMPLATES'),
      mode: true,
      controls: buttons
    };
    this.doneButtonRef = this.headerData.controls[1];
  }
  private initSectionHeader() {
    this.selectedTemplateSectionHeaderData = {
      id: 'selected-template-section',
      title: `${this.translate.instant("SELECTED_CAP")} (1)`,
      controls: []
    };

    this.templateListSectionHeaderData = {
      id: 'calendar-invite-template-list-section',
      title: this.translate.instant('ALL_MESSAGE_TEMPLATES') + " (" + this.templates.length + ")",
      controls: []
    };

    this.searchResultSectionHeaderData = {
      id: 'calendar-invite-template-search-result-section',
      title: this.getSearchSectionHeaderTitle(),
      controls: []
    };
  }
  private isDoneButtonDisabled() {
    return !this.selectedTemplate;
  }
  private getSearchSectionHeaderTitle(): string {
    return `${this.translate.instant('AL_RESULTS')} (${this.searchedTemplates.length})`;
  }

  onHeaderControlClick(id: string) {
    if (id === 'cancel') {
      this.goBack();
    } else if (id === 'done') {
      this.onDone();
    }
  }

  onSearchInput(ev: any) {
    if (ev && ev.target) {
      if (ev.target.value && ev.target.value !== '') {
        this.isSearching = true;
        this.searchedTemplates = this.templates.filter(template => template.indskr_name.toLowerCase().includes(this.searchText.toLowerCase()));
      } else {
        this.isSearching = false;
        this.searchedTemplates = [];
      }
      this.searchResultSectionHeaderData.title = this.getSearchSectionHeaderTitle();
    }
  }

  previewTemplate(template: ResourceEmailTemplate) {
    this.highlightedTemplateId = template.indskr_emailtemplateid;
    this.emailService.selectTemplate(template);
  }
  selectTemplate(template: ResourceEmailTemplate) {
    this.selectedTemplate = template;
    if (this.doneButtonRef) {
      this.doneButtonRef.isDisabled = this.isDoneButtonDisabled();
    }
  }
  deSelectTemplate(template: ResourceEmailTemplate) {
    this.selectedTemplate = undefined;
    if (this.doneButtonRef) {
      this.doneButtonRef.isDisabled = this.isDoneButtonDisabled();
    }
  }

  private onDone() {
    const body = this.emailService.personalizeCalendarInviteTemplate(this.activityService.selectedActivity, this.selectedTemplate);

    this.activityService.calendarInviteTemplateSelection$.next({
      body,
      isEditable: this.selectedTemplate.indskr_editablebyuser
    });

    this.goBack();
  }

  private goBack() {
    if (document.getElementsByClassName('modal-wrapper')[0].classList.contains('fullStretchView')) {
      document.getElementsByClassName('modal-wrapper')[0].classList.remove('fullStretchView');
    }
    if (this.evEmitter) {
      this.evEmitter.emit('template-page');
    }

    this.navCtrl.pop({ progressAnimation: false });
  }
}
