import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { merge, of } from 'rxjs';
import { Endpoints } from '../../../config/endpoints.config';
import {
  map,
  flatMap,
  switchMap,
  first,
  filter,
} from 'rxjs/operators';
import {FeatureActionsMap, User} from '../../classes/authentication/user.class';
import { AuthenticationService } from '../../services/authentication.service';
import { DiskService } from '../../services/disk/disk.service';
import { DeviceService } from '../../services/device/device.service';
import {
  InAppBrowser,
  InAppBrowserObject,
} from '@ionic-native/in-app-browser/ngx';
import {
  DB_KEY_PREFIXES,
} from '../../config/pouch-db.config';
import {
  SecureStorage,
  SecureStorageObject,
} from '@ionic-native/secure-storage/ngx';
import {
  LOGGED_OUT,
  LOGGED_OUT_AND_BACK_TO_LOGIN,
} from '../../models/rep-status-model';
import { isPast } from 'date-fns';
import { InterceptorSkipHeader } from '../../interceptors/token.interceptor';
import {OKConfig, OkIntegration} from "@omni/classes/onekey/ok-integration.class";
import DynamicsWebApi from 'dynamics-web-api'
import { TeamsConfig } from '@omni/classes/authentication/teams.config.class';
import { MsalService } from '@omni/services/msal/msal.service';
import { LocationOfflineService } from '@omni/services/location/location.service';
import { FeatureActionsService } from '@omni/services/feature-actions/feature-actions.service';

export interface OAuthTokenRequest {
  tentantID: string;
  clientID: string;
  grantType: string;
  resource: string;
  code: string;
  redirectURI: string;
}

declare var Microsoft: any;

/**
 * This service provides http requests for various user authentication endpoints
 *
 * @export
 * @class AuthenticationDataService
 */
@Injectable({
  providedIn: 'root',
})
export class AuthenticationDataService {
  private _inAppBrowserRef: InAppBrowserObject;
  private lastUser;
  lastUserLastModified: any;
  isOfflineLoginFlow: boolean = false;
  public config: {
    tenant;
    clientId;
    dynamicsAdminCentreUrl;
    userId;
    redirectUri;
    domain;
  };
  rememberMeChecked: boolean = false;
  private browserRef: InAppBrowserObject;
  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    private disk: DiskService,
    private deviceService: DeviceService,
    private inAppBrowser: InAppBrowser,
    private _secureStorage: SecureStorage,
    private msal: MsalService,
    private locationService: LocationOfflineService,
    private faService: FeatureActionsService,
  ) {
    this.deviceService.onResumeObservable.subscribe(() => {
      if (this.locationService.leftToLocationSettingFrom === 'authService') {
        this.fetchUserCurrentLocation(true);
      }
    });
  }

  public async logout(isOfflineState = false) {
    if (isOfflineState && this.rememberMeChecked) {
      await this.removeLoginStateAndRedirectToLogin(false, true);
    } else if (this.deviceService.isDeviceRealOffline) {
      await this.removeLoginStateAndRedirectToLogin(true);
    } else {
      if (this.config && !this.deviceService.deviceFlags.nativeCordova) {
        //We got our tenant id, manually redirect to logout
        const url = `${this.getAuthorityUrl()}/oauth2/logout?post_logout_redirect_uri=${this.config.redirectUri}`;
        this._inAppBrowserRef = this.inAppBrowser.create(
          url,
          this.deviceService.isNativeApp ? '_blank' : '_self',
          {
            location: 'yes',
            width: 483,
            height: 600,
          }
        );

        this._inAppBrowserRef.on('loadstop').subscribe(async (event) => {
          this._inAppBrowserRef.close();
          await this.removeLoginStateAndRedirectToLogin(true);
        });
      } else {
        // Case when user logged in offline
        await this.removeLoginStateAndRedirectToLogin(true);
      }
      //Lets kill what we got on disk
      localStorage.removeItem(this.config.domain);
      localStorage.removeItem('omni.adal.domain');
    }
  }

  public async removeLoginStateAndRedirectToLogin(
    flushOfflineSession: boolean = false,
    isOfflineState = false
  ) {
    if (flushOfflineSession) {
      (navigator as any)?.splashscreen?.show();
      if (this.config && this.deviceService.deviceFlags.nativeCordova) {
        await this.ensureMsalInitialized();
        await this.msal.logout(this.config.userId);
      }
      await this.flushOfflineSession();
    }
    if (this.deviceService.isNativeApp) {
      this.markLogout();
      this.markLogoutAndBackToLogin();
      if (
        window.location.protocol.startsWith('file') &&
        !window.location.href.endsWith('.html')
      ) {
        window.location.href += `${
          !window.location.href.endsWith('/') ? '/' : ''
        }index.html`;
      } else {
        window.location.reload();
      }
    } else {
      window.location.href = window.location.origin;
    }
  }

  async flushOfflineSession() {
    if (localStorage.getItem('expiresOn')) {
      localStorage.removeItem('expiresOn');
      if (this.deviceService.isNativeApp) {
        await this._secureStorage
          .create('offlineConfigurations')
          .then(async (storage: SecureStorageObject) => {
            storage.clear();
          })
          .catch((err) => {
            //this.logService.logWarning('Unsecure Device');
          });
      }
    }
  }

  // clearCachedUserData() {
  //   if (this.deviceService.deviceFlags.nativeCordova && this.config) {
  //     let context = this.msAdal.createAuthenticationContext(
  //       `https://login.microsoftonline.com/common`
  //     );
  //     <TokenCache>context.tokenCache &&
  //       (<TokenCache>context.tokenCache).clear();
  //   }
  // }

  public async getInstances(region: string) {
    const url: string = region != "China" ? Endpoints.authentication.GET_INSTANCES : Endpoints.authentication.GET_CHINA_INSTANCES;

    let response = await this.http.get(url).toPromise();
    this.authenticationService.mapLoginResponse(response);
  }

  public async init(config?: {
    tenant;
    clientId;
    dynamicsAdminCentreUrl;
    userId;
    domain;
    redirectUri;
  }) {
    if (config && config.domain) {
      localStorage.setItem('omni.adal.domain', config.domain);
      localStorage.setItem(config.domain, JSON.stringify(config));
      this.config = config;
    } else if (config && config.tenant && !config.domain) {
      let domain = localStorage.getItem('omni.adal.domain');
      let _config = await (async () =>
        JSON.parse(localStorage.getItem(domain)))().catch(() => {});
      if (_config && _config.tenant === config.tenant) {
        this.config = _config;
        await this.refreshAdalToken(this.config.dynamicsAdminCentreUrl);
      }
    }
  }

  isCallback() {
    let url = new URL(window.location.href);
    return !!url.searchParams.get('code') && !!url.searchParams.get('state');
  }

  async handleCallback(code?: string, state?: string) {
    if (this.deviceService.deviceFlags.nativeCordova) return;
    try {
      let url = new URL(window.location.href);
      code = code || url.searchParams.get('code');
      state =
        state ||
        (this.config && this.config.domain) ||
        url.searchParams.get('state');
      if (state) {
        this.config = await (async () =>
          JSON.parse(localStorage.getItem(state)))().catch(() => {});
      }
      if (code && state && this.config) {
        let url: string = Endpoints.authentication.REFRESH_RESOURCE.replace(
          '{realm_id}',
          this.config.tenant
        );
        let headers: HttpHeaders = new HttpHeaders();
        headers = headers
          .set('Content-Type', 'application/x-www-form-urlencoded')
          .set('X-Skip-Interceptor', 'true');
        let payload = {
          client_id: this.config.clientId,
          grant_type: 'authorization_code',
          resource: this.config.dynamicsAdminCentreUrl,
          // resource: 'https://tarrydemocn.crm.dynamics.cn',
          code: code,
          redirect_uri: this.config.redirectUri,
          realm_id: this.config.tenant,
        };

        let httpPayload = new HttpParams();
        httpPayload = httpPayload.set('client_id', payload.client_id);
        httpPayload = httpPayload.set('grant_type', payload.grant_type);
        httpPayload = httpPayload.set('resource', payload.resource);
        httpPayload = httpPayload.set('code', payload.code);
        httpPayload = httpPayload.set('redirect_uri', payload.redirect_uri);

        let response = await this.http
          .post(url, httpPayload, { headers: headers })
          .toPromise();
        this.authenticationService.access = response["access_token"] as string;
        this.authenticationService.refresh = response["refresh_token"] as string;
        this.authenticationService.expires = response["expires_in"];
        this.authenticationService.expiresOn = response['expires_on'];
        localStorage.setItem('expiresOn', this.authenticationService.expiresOn);
        return true;
      }
    } catch (err) {
      console.error(err);
    }
  }

  private getAuthorityUrl(region?: string) {
    let loginPartner = Endpoints.authentication.MICROSOFT_BASE_URL_GLOBAL;
    region = (region ?? localStorage.getItem('region'))?.toLowerCase();
    if (region == 'china') {
      loginPartner = Endpoints.authentication.MICROSOFT_BASE_URL_CHINA;
    }
    return `${loginPartner}/${this.config.tenant}`;
  }

  async login(bypassLoginFlow = false, region: string) {
    if (this.deviceService.deviceFlags.nativeCordova) {
      return await this.refreshAdalToken(this.config.dynamicsAdminCentreUrl, true);
    }
    let authorizeUrl = `${this.getAuthorityUrl(region)}/oauth2/authorize?client_id=${this.config.clientId}`;
    authorizeUrl = `${authorizeUrl}&response_type=code`;
    authorizeUrl = `${authorizeUrl}&redirect_uri=${this.config.redirectUri}`;
    authorizeUrl = `${authorizeUrl}&response_mode=query`;
    authorizeUrl = `${authorizeUrl}&resource=${this.config.dynamicsAdminCentreUrl}`;
    // authorizeUrl = `${authorizeUrl}&resource=https://tarrydemocn.crm.dynamics.cn`;

    authorizeUrl = `${authorizeUrl}&state=${this.config.domain}`;
    authorizeUrl = `${authorizeUrl}&domain_hint=${this.config.domain}`;
    authorizeUrl = `${authorizeUrl}&login_hint=${this.config.userId}`;
    authorizeUrl = `${authorizeUrl}&scope=openid groups.read.all`;

    if (this.deviceService.isNativeApp) {
      if (this.deviceService.deviceFlags.electron && bypassLoginFlow) {
        return true;
      }
      this.browserRef = this.inAppBrowser.create(
        authorizeUrl,
        this.deviceService.deviceFlags.electron ? '_blank' : '_system',
        {
          location: 'yes',
          width: 483,
          height: 600,
        }
      );
      // lets use in app browser on android for more control?

      return merge(
        merge(
          this.browserRef.on('loadstop'),
          this.browserRef.on('loaderror')
        ).pipe(
          switchMap((event) => {
            if (event?.url?.startsWith('omnipresence:')) {
              const url = new URL(event.url);
              if (
                url?.searchParams?.get('code') &&
                url?.searchParams?.get('state')
              ) {
                return of(url?.searchParams?.get('code'));
              }
            }
            return of(undefined as string);
          }),
          filter((code) => !!code)
        ),
        this.browserRef.on('exit').pipe(map(() => 'exit'))
      )
        .pipe(
          first(),
          switchMap((code) => {
            if (code === 'exit') return of(false);
            this.browserRef.close();
            return this.handleCallback(code, this.config.domain);
          })
        )
        .toPromise();
    } else {
      window.location.href = authorizeUrl;
    }
  }

  private async ensureMsalInitialized() {
    await this.msal.init({
      clientId: this.config.clientId,
      authority: this.getAuthorityUrl(),
      redirectUri: this.config.redirectUri,
    });
  }

  private async refreshAdalToken(resource, interactive = false) {
    if (this.deviceService.deviceFlags.nativeCordova) {
      console.info('Trying to acquire token silently');
      await this.ensureMsalInitialized();
      let authResponse = await this.msal.acquireToken({ resource, loginHint: this.config.userId }, interactive);
      if (!authResponse) {
        return false;
      }
      console.log('Token is: ', authResponse.accessToken);
      console.log('Token will expire on', authResponse.expiresOn);
      this.authenticationService.access = authResponse.accessToken;
      const expiresOn = authResponse.expiresOn.getTime() / 1000;
      this.authenticationService.expiresOn = '' + expiresOn;
      this.authenticationService.expires =
        '' + (expiresOn - new Date().getTime() / 1000);
      localStorage.setItem('expiresOn', this.authenticationService.expiresOn);
      return true;
    }
  }

  async getToken(resource?) {
    let shouldRefresh = false;
    if (!this.config) {
      return;
    }
    if (
      !this.authenticationService.access &&
      !(
        this.deviceService.deviceFlags.nativeCordova ||
        this.deviceService.deviceFlags.electron
      )
    ) {
      return;
    }
    shouldRefresh = !this.authenticationService.access;
    let jwt: { aud };
    try {
      jwt =
        this.authenticationService.access &&
        this.parseJwt(this.authenticationService.access);
    } catch { }
    let currentResource =
      this.authenticationService.userConfig &&
      this.authenticationService.userConfig.activeInstance &&
      this.authenticationService.userConfig.activeInstance.url;
    if (!resource) {
      resource = currentResource || this.config.dynamicsAdminCentreUrl;
    }
    let resource1: string = (resource || '').toLowerCase();
    let resource2: string = ((jwt && jwt.aud) || '').toLowerCase();
    if (!resource1.endsWith('/')) resource1 += '/';
    if (!resource2.endsWith('/')) resource2 += '/';
    if (!jwt || resource1 !== resource2) {
      shouldRefresh = true;
    }

    if (!shouldRefresh) {
      const expireOnInSeconds = this.authenticationService.expiresOn
        ? +this.authenticationService.expiresOn
        : null;
      if (
        expireOnInSeconds &&
        typeof expireOnInSeconds === 'number' &&
        expireOnInSeconds > 0
      ) {
        // Give one minute
        const interval = Math.abs(
          parseInt(this.authenticationService.expires, 10) / 4
        );
        const expiresOnMinusIntervalInMilliSeconds: number =
          (expireOnInSeconds - interval) * 1000;
        shouldRefresh = isPast(new Date(expiresOnMinusIntervalInMilliSeconds));
      }
    }

    if (shouldRefresh) {
      console.log('Token expired, refreshing...');
      if (this.deviceService.deviceFlags.nativeCordova) {
        if (!(await this.refreshAdalToken(resource))) {
          return undefined;
        }
      } else if (!this.deviceService.isDeviceRealOffline) {
        let url: string = Endpoints.authentication.REFRESH_RESOURCE;
        url = url.replace('{realm_id}', this.config.tenant);
        let payload: HttpParams = new HttpParams();
        payload = payload.set('resource', resource);
        payload = payload.set(
          'refresh_token',
          this.authenticationService.refresh
        );
        payload = payload.set('grant_type', 'refresh_token');
        let headers: HttpHeaders = new HttpHeaders();
        headers = headers
          .set('Content-Type', 'application/x-www-form-urlencoded')
          .set('X-Skip-Interceptor', 'true');
        let response = await this.http
          .post(url, payload, { headers })
          .toPromise();
        this.authenticationService.access = response["access_token"] as string;
        this.authenticationService.refresh = response["refresh_token"] as string;
        this.authenticationService.expires = response["expires_in"];
        this.authenticationService.expiresOn = response['expires_on'];
        localStorage.setItem('expiresOn', this.authenticationService.expiresOn);
        localStorage.setItem('expires', this.authenticationService.expires);
      }
      if (this.authenticationService.access) await this.encryptAccessToken();
    }
    return this.authenticationService.access;
  }

  private parseJwt(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  };

  async getGraphToken() {
    let shouldRefresh = !this.authenticationService.graphAccessToken;
    if (!shouldRefresh) {
      const expiresOn = this.authenticationService.graphTokenexpiresOn;
      const expiresIn = this.authenticationService.graphTokenexpires;
      const expireOnInSeconds = expiresOn ? +expiresOn : null;
      if (expireOnInSeconds && typeof expireOnInSeconds === 'number' && expireOnInSeconds > 0) {
        // Give one minute
        const interval = Math.abs(parseInt(expiresIn, 10) / 4);
        const expiresOnMinusIntervalInMilliSeconds: number =(expireOnInSeconds - interval) * 1000;
        shouldRefresh = isPast(new Date(expiresOnMinusIntervalInMilliSeconds));
      }
    }

    if (shouldRefresh) {
      console.log('Token expired, refreshing...');
      let url: string = Endpoints.authentication.REFRESH_RESOURCE;
      url = url.replace('{realm_id}', this.config.tenant);
      let payload: HttpParams = new HttpParams();
      payload = payload.set('resource', 'https://graph.microsoft.com/');
      payload = payload.set('refresh_token', this.authenticationService.refresh);
      payload = payload.set('grant_type', 'refresh_token');
      let headers: HttpHeaders = new HttpHeaders();
      headers = headers.set('Content-Type', 'application/x-www-form-urlencoded').set('X-Skip-Interceptor', 'true');
      const response = await this.http.post(url, payload, { headers }).toPromise();
      this.authenticationService.graphAccessToken = response["access_token"] as string;
      this.authenticationService.graphTokenexpires = response["expires_in"];
      this.authenticationService.graphTokenexpiresOn = response['expires_on'];
    }
    return this.authenticationService.graphAccessToken;
  }

  private async fetchFeatureActions() {
    let dwa = new DynamicsWebApi({
      webApiUrl: `${this.authenticationService.userConfig.activeInstance.url}/api/data/v9.1/`,
      onTokenRefresh: tokenRefresh => tokenRefresh(this.authenticationService.access),
    });
    let fetchXml1 = `<?xml version="1.0" encoding="UTF-8"?>`
      + `<fetch distinct="true" mapping="logical" output-format="xml-platform" version="1.0">`
      + `<entity name="indskr_featureaction">`
      + `<attribute name="indskr_name" />`
      + `<order descending="false" attribute="indskr_name" />`
      + `<filter type="and">`
      + `<condition attribute="statecode" value="0" operator="eq" />`
      + `</filter>`
      + `<link-entity name="indskr_featureactionrole" alias="bm" to="indskr_featureactionid" from="indskr_featureactionid">`
      + `<filter type="and">`
      + `<condition attribute="statecode" value="0" operator="eq" />`
      + `</filter>`
      + `<link-entity name="role" to="indskr_securityroleid" from="roleid">`

    let fetchXml2 = `</link-entity></link-entity></entity></fetch>`

    let userFeatureFetchXml = fetchXml1
      + `<link-entity name="systemuserroles" to="roleid" from="roleid" intersect="true" visible="false">`
      + `<filter type="and">`
      + `<condition attribute="systemuserid" operator="eq-userid" />`
      + `</filter>`
      + `</link-entity>`
      + fetchXml2;

    let teamFeatureFetchXml = fetchXml1
      + `<link-entity name="teamroles" to="roleid" from="roleid">`
      + `<filter type="and">`
      + `<condition attribute="teamid" operator="eq-userteams" />`
      + `</filter>`
      + `</link-entity>`
      + fetchXml2;
    let [userFeatureActions, teamFeatureActions] = await Promise.all([
      dwa.executeFetchXmlAll('indskr_featureactions', userFeatureFetchXml),
      dwa.executeFetchXmlAll('indskr_featureactions', teamFeatureFetchXml)]);
    return [...new Set([...userFeatureActions?.value?.map(x => x.indskr_name as string) || [], ...teamFeatureActions?.value?.map(x => x.indskr_name as string) || []])].map(x => ({indskr_name: x}));
  }

  private async fetchTeams() {
    let dwa = new DynamicsWebApi({
      webApiUrl: `${this.authenticationService.userConfig.activeInstance.url}/api/data/v9.1/`,
      onTokenRefresh: tokenRefresh => tokenRefresh(this.authenticationService.access),
    });
    let teamFetchXml = `<?xml version="1.0" encoding="UTF-8"?>`
      + `<fetch distinct="true" mapping="logical" output-format="xml-platform" version="1.0">`
      + `<entity name="team">`
      + `<attribute name="name" />`
      + `<attribute name="isdefault" />`
      + `<attribute name="teamid" />`
      + `<attribute name="indskr_excludefromomninotes" />`
      + `<order descending="false" attribute="name" />`
      + `<filter type="and">`
      + `<condition attribute="businessunitid" operator="eq-businessid" />`
      + `<condition attribute="isdefault" value="0" operator="eq" />`
      + `<condition attribute="teamtype" operator="eq" value="0" />`
      + `</filter>`
      + `<link-entity name="teammembership" intersect="true" visible="false" to="teamid" from="teamid">`
      + `<link-entity name="systemuser" to="systemuserid" from="systemuserid" alias="mb">`
      + `<attribute name="systemuserid" />`
      + `<attribute name="fullname" />`
      + `</link-entity></link-entity></entity></fetch>`

    let teams = await dwa.executeFetchXmlAll('teams', teamFetchXml);
    return (this.authenticationService.aggregateTeams(teams?.value) || []);
  }

  private async fetchBUSettings() {
    // let select = [
    //   'indsyn_amasubscription',
    //   'indsyn_onetimeveevameeting',
    // ];
    let select = [];
    let dwa = new DynamicsWebApi({
      webApiUrl: `${this.authenticationService.userConfig.activeInstance.url}/api/data/v9.1/`,
      onTokenRefresh: tokenRefresh => tokenRefresh(this.authenticationService.access),
    });
    // const data = await dwa.retrieveAttributes('LogicalName=\'businessunit\'', undefined, ['LogicalName'], `Microsoft.Dynamics.CRM.In(PropertyName='LogicalName',PropertyValues=[${select.map(s => `'${s}'`).join(',')}])`);
    // if (!data!.value?.length) return;
    // select = data.value.map(a => a?.LogicalName);
    const buSettings = await dwa.retrieveAll('businessunits', select, `Microsoft.Dynamics.CRM.EqualBusinessId(PropertyName='businessunitid')`).catch(() => undefined);
    return (buSettings?.value || [])[0];
  }

  private async fetchBUConfigurations() {
    let select = ['indskr_callplanprogress', 'indskr_displaygoalsbeyond100', 'indskr_displayallactivitiesprogress', 'indskr_retroactivemeetingcompletionlimit','indskr_allowconsentedhcptoaccountvisit',
      'indskr_businessline', 'indskr_followupactionsubjecteditable', 'indskr_messageactivitysubjecteditable', 'indskr_showcontactconsents','indskr_populateaccountaddress', 'indskr_retroactivemeetingcompletionlimitindays',
      'indskr_futurecoaching', 'indskr_timeoffreopen', 'indskr_approvalsubmissionperiod', 'indskr_approvalsongoapp', 'indskr_globalsearchlimittobulevel', 'indskr_offtakecheckpreviousmonthlimit', 'indskr_capturehcpinfoforaccountsurvey', 'indskr_applyabaccountlevelfilter',
      'indskr_disableproductsection'];
    let dwa = new DynamicsWebApi({
      webApiUrl: `${this.authenticationService.userConfig.activeInstance.url}/api/data/v9.1/`,
      onTokenRefresh: tokenRefresh => tokenRefresh(this.authenticationService.access),
    });
    let buConfig = await dwa.retrieveAll('indskr_bulevelconfigurations', select, `Microsoft.Dynamics.CRM.EqualBusinessId(PropertyName='indskr_bid')`).catch(() => undefined);
    return (buConfig?.value || [])[0];
  }

  /**
   * Get endpoint to get user information once we have our access token
   *
   * @returns {Observable<User>}
   *
   * @memberOf AuthenticationDataService
   */
  public async getUser(
    doNotSave: boolean = false,
    isForSync: boolean = false,
    didOfflineDataUploadFail: boolean = false
  ): Promise<User> {
    let url: string = Endpoints.authentication.GET_USER;
    let lastSavedUserData;
    if (isForSync) {
      lastSavedUserData = await this.authenticationService.loadLastSavedUserData();
      if (
        lastSavedUserData.lastModified &&
        !this.authenticationService.shouldFullSync
      ) {
        url = url + '?lastUpdatedTime=' + lastSavedUserData.lastModified;
      }
    }
    let httpHeaders: HttpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set('Sync-Service', 'true');

    if (!this.deviceService.isNativeApp) {
      httpHeaders = httpHeaders.set(
        'X-Refresh-Token',
        this.authenticationService.refresh
      );
    }

    return await this.http
      .get(url, { headers: httpHeaders })
      .pipe(
        flatMap(async (res: any) => {
          let featureActionsFuture = this.fetchFeatureActions();
          let buSettingsFuture = this.fetchBUSettings();
          let buConigFuture = this.fetchBUConfigurations();
          // let buSettingsFuture = this.fetchBUSettings(res.businessUnitId, res.givenName);
          let teamsFuture = this.fetchTeams();
          let [featureActions, buSettings, buConfigs, teams] = await Promise.all([featureActionsFuture, buSettingsFuture, buConigFuture, teamsFuture]);
          res.featureActions = featureActions;
          res.buSettings = buSettings;
          res.buConfigs = buConfigs;
          res.teams = teams;
          return res;
        }),
        flatMap((res: any) => {
          let url = Endpoints.authentication.GET_CHILD_USERS;
          return this.http
            .get(url, Endpoints.GLOBAL_SYNC_HEADER)
            .toPromise()
            .catch((err) => [])
            .then((res2: any) => {
              res.childUsers = res2.users;
              return res;
            });
        }),
        flatMap((res: any) => {
          const defaultCurrencyUrl = this.authenticationService.userConfig.activeInstance.url + Endpoints.accountManagement.DEFAULT_CURRENCY_SYMBOL.replace("{userId}", res.systemUserId);
          return this.http
            .get<any[]>(defaultCurrencyUrl)
            .toPromise()
            .catch((err) => [])
            .then(async (res2: any) => {
            res.currencysymbol = res2.currencysymbol;
            if (res2['_transactioncurrencyid_value']) {
              //If user has personalized currecy set
              const personalCurrecyUrl = this.authenticationService.userConfig.activeInstance.url + Endpoints.accountManagement.PERSONAL_SETTING_CURRENCY_SYMBOL.replace("{transactioncurrencyid}", res2['_transactioncurrencyid_value']);
              return this.http.get<any[]>(personalCurrecyUrl)
              .toPromise()
              .catch((err) => [])
              .then((res3: any) => {
                res.currencysymbol = res3.currencysymbol;
                res['_transactioncurrencyid_value'] = res2['_transactioncurrencyid_value'];
                return res;
              });
            }
            return res;
          });
        }),
        switchMap(res => this.authenticationService.userDataChangeDetection(res, isForSync, didOfflineDataUploadFail, lastSavedUserData)),
        map((res) => {
          const users = this.authenticationService.mapUser(res)
          // Run FA value update on the FA master service
          this.faService.updateFaValues();
          return users;
        }),
        switchMap(() => this.fetchUserCurrentLocation()),
        switchMap((response) => {
          this.authenticationService.saveOfflineLoginInfo(
            this.authenticationService.user.mail,
            {
              user: this.authenticationService.user,
              userConfig: this.authenticationService.userConfig,
              instance: this.authenticationService.userConfig.activeInstance,
              tentantId: this.config.tenant,
              refresh: this.authenticationService.refresh,
            }
          );
          return this.authenticationService.saveUser(doNotSave);
        }),
        switchMap(() => this.fetchTeamsConfig())
      )
      .toPromise();
  }

  async fetchUserCurrentLocation(isRetry = false) {
    if(this.faService.isShortCallLauncherEnabled && (!this.locationService.isFetchingLocationAlready || isRetry)) {
      this.locationService.isFetchingLocationAlready = true;
      this.authenticationService.user.currentLocation = await this.locationService.getCurrentLocationName('authService');
    } else this.authenticationService.user.currentLocation = null;
  }

  fetchTeamsConfig():Promise<User> {
    if (this.authenticationService.hasFeatureAction(FeatureActionsMap.TEAMS_MEETING)) {
      let url = Endpoints.domain.GET_TEAMS_CONFIG;
      url = url.replace('{tenantid}', this.config.tenant);
      url = url.replace('{clientid}', this.config.clientId);
      this.http.get(url, Endpoints.GLOBAL_SYNC_HEADER).toPromise().then((teamsconfig: TeamsConfig) => {
        this.authenticationService.userConfig.teamsConfig = teamsconfig;
      });
    }
    return Promise.resolve(this.authenticationService.user);
  }

  encryptAccessToken() {
    const url: string = Endpoints.authentication.ENCRYPT_CLIENT_SECRET;
    let payload: HttpParams = new HttpParams();
    payload = payload.set('client_Secret', this.authenticationService.access);
    const option = Endpoints.authentication.AUTHENTICATE_USER_HEADERS;
    option.headers = option.headers.set(InterceptorSkipHeader, 'true');

    return this.http
      .post(url, payload, option)
      .toPromise()
      .then((response: { encryptClientSecret: string }) => {
        if (!response || !response.encryptClientSecret) {
          console.error('encryptAccessToken: invalid response', response);
          return;
        }
        this.authenticationService.encryptedAccess =
          response.encryptClientSecret;
      })
      .catch((err) => {
        console.error('encryptAccessToken: ', err);
      });
  }

  async fetchNotesAssistantConfiguration(loadFromDbOnly = false) {
    if (loadFromDbOnly) {
      const dbData = await this.disk.retrieve(
        DB_KEY_PREFIXES.NOTE_ASSISTANT_CONFIG,
        true
      );
      if (dbData && dbData.raw) {
        this.mapConfiguration(dbData.raw);
      }
    } else {
      let url = Endpoints.notes_assisstant.configuration;
      try {
        this.http
          .get(url)
          .toPromise()
          .catch((err) => [])
          .then((res) => {
            this.mapConfiguration(res);
            this.disk.updateOrInsert(
              DB_KEY_PREFIXES.NOTE_ASSISTANT_CONFIG,
              (doc) => ({ raw: res })
            );
          });
      } catch (error) {
        console.log('error in notes assitant confg service', error);
      }
    }
  }

  private mapConfiguration(raw: any) {
    this.authenticationService.notesAssistantConfig = {
      speechSDKSubscriptionID: raw['speechSubscriptionKey'] || '',
      speechRegion: raw['speechRegion'] || '',
      speechLanguage: raw['speechLanguage'] || '',
      speechCID: raw['speechCustomEndpointId'] || '',
      LUISAppID: raw['luisAppId'] || '',
      LUISRegion: raw['luisRegion'] || '',
      LUISSubscriptionKey: raw['luisSubscriptionKey'] || '',
    };
    (<any>window).bing_config = {
      cid: this.authenticationService.notesAssistantConfig.speechCID,
      subscriptionKey: this.authenticationService.notesAssistantConfig
        .speechSDKSubscriptionID,
      region: this.authenticationService.notesAssistantConfig.speechRegion,
    };
  }

  async fetchOKIntegrationConfig(loadFromDbOnly = false) {
    if (this.authenticationService.hasFeatureAction(FeatureActionsMap.ONEKEY_ACCOUNT_SEARCH)
    || this.authenticationService.hasFeatureAction(FeatureActionsMap.ONEKEY_CONTACT_SEARCH)
      || this.authenticationService.hasFeatureAction(FeatureActionsMap.CONTACTS_CREATE_REQUEST)
      || this.authenticationService.hasFeatureAction(FeatureActionsMap.CONTACTS_EDIT_REQUEST)
      || this.authenticationService.hasFeatureAction(FeatureActionsMap.CONTACTS_ONEKEY_SELECTION)
      || this.authenticationService.hasFeatureAction(FeatureActionsMap.ACCOUNTS_CREATE_REQUEST)
      || this.authenticationService.hasFeatureAction(FeatureActionsMap.ACCOUNTS_EDIT_REQUEST)
      || this.authenticationService.hasFeatureAction(FeatureActionsMap.ACCOUNTS_ONEKEY_SELECTION)) {
      if (loadFromDbOnly) {
        const dbData = await this.disk.retrieve(
          DB_KEY_PREFIXES.OK_API_CONFIG,
          true
        );
        if (dbData && dbData.raw) {
          if (dbData.raw.okConfigDTO) dbData.raw.okConfigDTO = new OKConfig(dbData.raw.okConfigDTO)
          this.authenticationService.okIntegrationSettings = new OkIntegration(dbData.raw);
        }
      } else {
        let url = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.authentication.GET_OK_API_SETTINGS;
        try {
          this.http
            .get(url)
            .toPromise()
            .catch((err) => [])
            .then((res: OkIntegration) => {
              if (res.okConfigDTO) res.okConfigDTO = new OKConfig(res.okConfigDTO);
              this.authenticationService.okIntegrationSettings = new OkIntegration(res);
              this.disk.updateOrInsert(
                DB_KEY_PREFIXES.OK_API_CONFIG,
                (doc) => ({ raw: res })
              );
            });
        } catch (error) {
          console.log('error in OK API config service', error);
        }
      }
    }
  }

  didLogout(): boolean {
    return window.localStorage.getItem(LOGGED_OUT) === 'true';
  }
  markLogout() {
    if (this.deviceService.isNativeApp && this.rememberMeChecked) {
      window.localStorage.setItem(LOGGED_OUT, 'true');
    }
  }
  clearLogoutFlag() {
    window.localStorage.removeItem(LOGGED_OUT);
  }

  didJustLogoutAndBackToLogin(): boolean {
    return window.localStorage.getItem(LOGGED_OUT_AND_BACK_TO_LOGIN) === 'true';
  }
  markLogoutAndBackToLogin() {
    if (this.deviceService.isNativeApp && this.rememberMeChecked) {
      window.localStorage.setItem(LOGGED_OUT_AND_BACK_TO_LOGIN, 'true');
    }
  }
  clearLogoutAndBackToLoginFlag() {
    window.localStorage.removeItem(LOGGED_OUT_AND_BACK_TO_LOGIN);
  }
}
