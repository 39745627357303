import { ConfirmationAlertData } from "../classes/shared/confirmation-alert-data.class";
import { DynamicForm } from "../classes/dynamic-form/dynamic-form.class";
import { IndPageTitleViewDataModel } from "./indPageTitleDataModel";

export enum DynamicFormType {
    CONFIGUREDFORM = 'configured-form',
    DEFAULTFORM = 'default-form',
}

export interface DynamicFormComponentModel {
    formMetadata:DynamicForm;
    formEntityObject:any;
    mainEntityObjectId:any;
    title:string;
    LookupEntitySetName:string;
    showSearchBar?:boolean;
    enableSubgridDataEditing?:boolean;
    showLeftHeaderButton?:boolean;
    leftHeaderBtnImgSrc?: string;
    leftHeaderBtnIcon?:string;
    leftHeaderBtnText?:string;
    isEditFormWithLinkedData?:boolean;
    isUpdatedLinkedEnityFormData?: boolean;
    linkedEntityExistingDataId?: string;
    linkedEntityExistingDataAddressId?: string;
    linkedEntityAddedNewDataId?: string;
    linkedEntityValue?: any[];
    targetControlAttribName?: string;
    showClearButton?: boolean;
    showRightHeaderButton?:boolean;
    rightHeaderImgSrc?: string;
    rightHeaderBtnText?:string;
    dynamicFormType?:DynamicFormType;
    linkedEntityParentData?: any;
    customFields?: any;
    confirmationCheckRequired?:boolean;
    confirmationAlertData?:ConfirmationAlertData;
    navOptions?: any;
    eventsHandler?:(data:any, eventTarget:string, refData?:DynamicFormComponentModel)=>void;
}
