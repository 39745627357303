import { TranslateService } from '@ngx-translate/core';
import { CallPlanOfflineService } from '@omni/services/call-plan/call-plan.offline.service';
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';
import {
  PageName,
  NavigationService,
} from '@omni/services/navigation/navigation.service';
import { DeviceService } from '@omni/services/device/device.service';
import { UIService } from '@omni/services/ui/ui.service';
import { LoadingController, PopoverController } from '@ionic/angular';
import { FooterService ,  FooterViews} from '@omni/services/footer/footer.service';
import { GlobalUtilityService } from '@omni/services/global-utility.service';
import _ from 'lodash';
import { IndTabsDataModel } from '@omni/models/ind-tabs-data-model';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { TrackingEventNames, TrackService } from '@omni/services/logging/tracking.service';
import { AppointmentActivity, OFFLINE_ID_PREFIX } from '@omni/classes/activity/appointment.activity.class';
import { NewActivityComponent } from '@omni/components/activity/new-activity/new-activity';
import { FeatureActionsMap } from "../../../classes/authentication/user.class";
import { addMinutes } from "date-fns";
import { InitiateMeetingPayload, MeetingDataService } from '@omni/data-services/meeting/meeting.data.service';
import { AuthenticationService } from '@omni/services/authentication.service';
import { ActivityService } from '@omni/services/activity/activity.service';
import { ActivityDataService } from '@omni/data-services/activity/activity.service';
import { ContentMatchingService } from '@omni/services/content-matching/content-matching.service';
import { NotificationService } from '@omni/services/notification/notification.service';
import { ContactOfflineService } from '@omni/services/contact/contact.service';
import { Events } from '@omni/events';



@Component({
  selector: 'customer-call-plan-details',
  templateUrl: './customer-call-plan-details.html',
  styleUrls: ['./customer-call-plan-details.scss'],
})
export class CustomerCallPlanDetails implements OnInit {
  @Output() closeDetails = new EventEmitter<boolean>(false);
  @Input() from: PageName;
  public goals: any[] = [];
  public plan;
  private scrollBehaviorSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  public readonly scrollObservable: Observable<any> = this.scrollBehaviorSubject.asObservable().pipe(debounceTime(100));
  accessedFrom: string;
  public pageTitleControls = [];
  public customerCallPlanSegment: string = '';

  constructor(
    public callPlanService: CallPlanOfflineService,
    private device: DeviceService,
    public uiService: UIService,
    private navService: NavigationService,
    public translate: TranslateService,
    public popover: PopoverController,
    public footerService: FooterService,
    public utilityService: GlobalUtilityService,
    public callPlanOfflineService: CallPlanOfflineService,
    private trackingService: TrackService,
    private authenticationService: AuthenticationService,
    private loadingCtrl: LoadingController,
    private meetingService: MeetingDataService,
    private activityService: ActivityService,
    private activityDataService: ActivityDataService,
    private contactService: ContactOfflineService,
    private notificationService: NotificationService,
    private contentMatchService: ContentMatchingService,
    private events: Events,
    
  ) {
  }

  ngOnInit() {
    this.plan = this.callPlanService.selectedCallPlan;
    this.initializeSectionHeader();
    this.setTabsData();
    this.customerCallPlanSegment = this.uiService.tabsData[0].value;
    this.events.unsubscribe('call-plan-details:confirmedNewActivity');
    this.events.subscribe("call-plan-details:confirmedNewActivity", () => {
      this.callPlanService.callPlanPageView = "CallPlanDetail";
      this.uiService.activeView = 'CallPlanDetails'
      this.activityService.selectedActivity = undefined;
      this.uiService.showCancelDoneOnActivityDetails = false;
      this.uiService.showNewActivity = false;
    });
  }

  closepage() {
    if (
      this.navService.getActiveChildNavViewPageName() ==
      PageName.SchedulerDetailComponent
    ) {
      //used for closing details view via scheduler details selection flow
      try {
        this.closeDetails.emit(true);
        if (
          this.device.isMobileDevice &&
          this.device.deviceOrientation === 'PORTRAIT'
        ) {
          this.navService.popWithPageTracking().then(() => {
            this.callPlanService.selectedCallPlan = undefined;
          });
        } else this.callPlanService.selectedCallPlan = undefined;
      } catch (error) {
        console.log(error);
      }
    } else if (
      this.uiService.activeView === 'ActivitiesPageRightPaneNav' &&
      this.uiService.activitiesPagePlanTabSelectedPlan === 'goalsPlansTab'
    ) {
      this.navService.popToRootWithPageTracking();
      this.uiService.showRightPane = false;
      this.callPlanService.selectedCallPlan = undefined;
    } else {
      this.callPlanService.selectedCallPlan = undefined;
    }
  }

  async segmentChanged(selectedTab: string) {
    this.customerCallPlanSegment = selectedTab;
  }


  initializeSectionHeader() {
  let buttons = [];
  buttons.push(
    {
      id: "pluse-icon",
      imgSrc: 'assets/imgs/ios_add_3x.svg',
      name: this.translate.instant('CREATE'),
      // isDisabled: this.contactService.isOneKeySearching,
      align: "right",
      tooltip: "New Activity"
    })
    this.pageTitleControls = buttons
  }

  public onPageTitleControlClick(id: string) {
    switch (id) {
      case 'pluse-icon':
        this.createNewActivityForRepCallPlan(event);
        break;
      case 'close':
        this.closepage();
        break;
      default:
        console.log("Unhandled switch case statement");
        break;
    }
  }

  public async createNewActivityForRepCallPlan(event) {
    if (this.authenticationService.hasFeatureAction(FeatureActionsMap.MESSAGE_ACTIVITY) || this.authenticationService.hasFeatureAction(FeatureActionsMap.PHONECALL_ACTIVITY)) {
      this.openNewActivityComponent(event)
    } else {
      event.stopPropagation();
      this.trackingService.tracking(' MyCallPlan+Clicked', TrackingEventNames.CALLPLAN)
      //We don't show the new activity screen, we just insta create it.
      //Start a loader while we wait for new meeting
      let loader = await this.loadingCtrl.create();
      loader.present();
      //Going to need a payload for initiation
      let startTime, endTime;
      startTime = new Date();
      endTime = new Date();
      endTime = addMinutes(startTime, 30);
      //Add contact to new appointment activity
      let contact = this.contactService.getContactByID(
        this.callPlanOfflineService.selectedRepCallPlan["contactId"]
      );

      let subject: string = (contact && contact.fullName) ? `${contact.fullName} - ` + this.translate.instant('MEETING') : this.translate.instant('MEETING_CREATED_FROM_CALL_PLAN');
      if (this.authenticationService.hasFeatureAction(FeatureActionsMap.VISIT_AUTO_SUBJECT)) {
        subject = (contact && contact.fullName) ? this.translate.instant('VISIT') + ` - ${contact.fullName}` : this.translate.instant('VISIT')
      }
      let payload: InitiateMeetingPayload = new InitiateMeetingPayload(
        subject,
        "",
        startTime.getTime(),
        endTime.getTime(),
        "",
        OFFLINE_ID_PREFIX + new Date().getTime()
      );

      let response = await this.meetingService.createNewMeeting(payload, true);
      this.activityService.selectedActivity = response;
      if (contact) {
        if (
          this.activityService.selectedActivity instanceof AppointmentActivity
        ) {
          this.activityService.selectedActivity.contacts.push(contact);

          const requestSuccess = await this.meetingService.addContactsToMeeting(this.activityService.selectedActivity);
          this.contentMatchService.isNewContactAddedToMeeting = true;
          // TODO: Only hybrid app does offline stuff in future
          if (!requestSuccess/* && this.device.isHybridApp*/) {
            await this.activityService.upsertMeetingsOfflineData(this.activityService.selectedActivity);
          }
        }
      } else {
        this.notificationService.notify(this.translate.instant('ERROR_FINDING_CONTACT_TO_ADD_TO_MEETING'), 'Call Plan Detail');
      }
      loader.dismiss();

      //We've made an activity, we just need to display the right hand details
      this.uiService.activeView = "Appointment";
      await this.activityDataService._appendMeetingDetailsToActivity(this.activityService.selectedActivity);
      this.uiService.prevView = 'callPlanDetails';
      this.uiService.activeView = 'activityTimelineDetails';
      this.callPlanService.callPlanPageView = "CallPlanNewMeetingDetails";
      this.footerService.initButtons(FooterViews.Activities);
      this.activityDataService.activityDetailsLoaded = true;
      this.uiService.showCancelDoneOnActivityDetails = true;
    }
  }

  openNewActivityComponent(event) {
    let contact = this.contactService.getContactByID(this.plan['contactId']);
    this.contactService.contactInformation = contact;
    this.uiService.showNewActivity = true;
    if (this.navService.getCurrentMasterPageName() == PageName.ActivitiesPageComponent && this.uiService.activitiesPagePlanTabSelectedPlan === 'goalsPlansTab') {
      this.navService.pushChildNavPageWithPageTracking(NewActivityComponent, PageName.NewActivityComponent, PageName.ActivitiesPageComponent, { from: PageName.CustomerCallPlanDetails });
    } else {
      this.navService.pushChildNavPageWithPageTracking(NewActivityComponent, PageName.NewActivityComponent, PageName.CustomerCallPlanPage, { from: PageName.CustomerCallPlanDetails });
    }
    event.stopPropagation();
  }

  setTabsData() {
    this.uiService.tabsData = [
      {
        displayText: this.translate.instant('PLANS'),
        value: 'plans',
        disable: false,
      },
      {
        displayText: this.translate.instant('INFO'),
        value: 'info'
      },
    ];
  }
}
