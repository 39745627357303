import { MeetingStructureService } from './../../../services/meeting-structure/meeting-structure.service';
import { PresentationService } from './../../../services/presentation/presentation.service';
import { UIService } from '@omni/services/ui/ui.service';
import { NotificationService } from '@omni/services/notification/notification.service';
import {Component, ElementRef, Input, ViewChild} from "@angular/core";
import {
  FooterService,
  FooterViews
} from "@omni/services/footer/footer.service";
import {DeviceService} from "@omni/services/device/device.service";
import {Channel} from "../../../classes/consent/channel.class";
import {ConsentTerm} from "../../../classes/consent/consent-term.class";
import {ConsentService} from "@omni/services/consent/consent.service";
import {BrandOfflineService} from "@omni/services/brand/brand.service";
import {
  NavigationService,
  PageName
} from "@omni/services/navigation/navigation.service";
import {ConsentDetailsComponent} from "../consent-details/consent-details";
import {Product} from "../../../classes/consent/product.class";
import {Subscription} from "rxjs";
import {EventsService} from "@omni/services/events/events.service";
import {ConsentTermGenerateService} from "@omni/services/consent/consent-term-generate.service";
import {TranslateService} from "@ngx-translate/core";
import {EmailService} from "@omni/services/email-templates/email.service";
import {ContactOfflineService} from "@omni/services/contact/contact.service";
import { ActivityService } from '@omni/services/activity/activity.service';
import { AuthenticationService } from "@omni/services/authentication.service";
import { FeatureActionsMap} from '../../../classes/authentication/user.class';
import { IndSectionHeaderViewDataModel } from '@omni/models/indSectionHeaderDataModel';
import { NavParams } from '@ionic/angular';
import { EmbeddedInteraction, EmbeddedInteractionType } from '@omni/classes/activity/activity.class';

/**
 * Generated class for the ContactConsentCaptureComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: "contact-consent-capture",
  templateUrl: "contact-consent-capture.html",
  styleUrls:['contact-consent-capture.scss']
})
export class ContactConsentCaptureComponent {
  consentChannels: Channel[] = [];
  generatedTerms: ConsentTerm[];
  products: Product[] = [];
  private channelSubscription: Subscription;
  private contactSubscription: Subscription;
  selectAllEnabled: boolean = false;
  productLableText: string = "Deselect All";
  channelLableText: string = "Deselect All";

  isExpanded: boolean = false;
  expandOrCollapse: string = "Show All";

  public limitProds: number = 3;
  @Input() from: any;
  @ViewChild('scrollTop', { read: ElementRef, static: false }) scrollTop;
  productsHeaderModel: IndSectionHeaderViewDataModel;
  channelsHeaderModel: IndSectionHeaderViewDataModel;
  contactConsentTermsModel: IndSectionHeaderViewDataModel;

  public footerHandler: (eventName: string) => void = (eventName: string) => {
    switch (eventName) {
      case "generateconsents":
        this.generateTerms();
        break;
      case "consentFlip":
        if (this.navParams && this.navParams.data && this.navParams.data.from == 'MESSAGE_ACTIVITY') {
          this.navService.popWithPageTracking().then(() => this.footerService.initButtons(FooterViews.ConsentDetails));
        }
        else if (this.generatedTerms.length) {
          this.generatedTerms.sort((a, b) =>
            a.indskr_consentTermName
              .toString()
              .localeCompare(b.indskr_consentTermName.toString())
          );
          this.openConsentDetails(this.generatedTerms[0]);
        }
        break;
      default:
    }
  };
  IcurrentMeetingActivitySubscription: Subscription;
  selectedMeetingActivity: EmbeddedInteraction;

  constructor(
    public footerService: FooterService,
    public device: DeviceService,
    private consentService: ConsentService,
    private consentTermGenerateService: ConsentTermGenerateService,
    private contactOfflineService: ContactOfflineService,
    private brandOfflineService: BrandOfflineService,
    private navService: NavigationService,
    private events: EventsService,
    public translate: TranslateService,
    private emailService: EmailService,
    public activityService: ActivityService,
    public notify: NotificationService,
    public uiService: UIService,
    public authenticationService: AuthenticationService,
    private navParams: NavParams,
    private readonly presentationService: PresentationService,
    private readonly meetingStructureService: MeetingStructureService
  ) {}

  ngOnInit() {
    if (!this.from) {
      this.consentService.allGeneratedTerms.next([]);
    } else {
      this.generatedTerms = this.consentService.allGeneratedTerms.getValue();
    }
    this.channelSubscription = this.consentService.allConsentChannelSubject.subscribe(
      (response: Channel[]) => {
        
        let channels = (Array.isArray(response) && response.length) ? response.filter(dt => dt.indskr_displayasconsentchannel && dt.indskr_displayasconsentchannel === true) : [];

        (this.consentService.businessunitChannels.filter((buChannel) => buChannel.statusCode === 1)).forEach((buChannel) => {
          let index = channels.findIndex((item) => item.indskr_consenttypeid === buChannel.channelID)
          if (index >= 0) {
            let channelIndex = this.consentChannels.findIndex((channel => channel.indskr_consenttypeid === channels[index].indskr_consenttypeid))
            if (channelIndex < 0) {
              this.consentChannels.push({...channels[index]});
            }
          }
        });
      });

    this.contactSubscription = this.contactOfflineService.contactInformationObservable.subscribe((value) => {
      if (value) {
        this.products = [];
        if (this.brandOfflineService.brands.length) {
          this.brandOfflineService.brands.map(brand => {
            if (brand.status === "A") {
              let product: Product;
              if (this.emailService.selectedActivity) {
                product = new Product(brand.ID, brand.name, (brand.ID == this.emailService.selectedActivity.templateRefProductId));
              } else if (this.from == 'ConsentTool' || this.from=='InMeetingConsent') {
                product = new Product(brand.ID, brand.name, true);
              } else {
                product = new Product(brand.ID, brand.name, false);
              }
              this.products.push(product);
            }
            this.checkIfTermsAlreadyGenerated('products');
          });
        }
        //if product consent flag is false, select all products
        if(!this.authenticationService.user.isProductConsent) this.consentTermGenerateService.selectedProducts = []//[...this.products.map(product => ({...product, isChecked : true}))];

        if (this.consentChannels.length) {
          this.consentChannels.map(channel => {
            if(this.emailService.selectedActivity) {
              channel.isChecked = channel.indskr_consentType.toLowerCase() == this.emailService.selectedActivity.channelType.toLowerCase();
            }
            else if (this.from == 'ConsentTool' || this.from == 'InMeetingConsent') {
              channel.isChecked = true;
            }
          });
          this.checkIfTermsAlreadyGenerated('channels');
        }
        if (this.from == 'ConsentTool' || this.from == 'InMeetingConsent') {
          this.generatedTerms = [];
        }
      }
    });
    if(this.navService.getCurrentMasterPageName()==PageName.PresentationMeetingComponent){
      this.consentService.inMeetingConsentFlow = true;
      this.footerService.initButtons(FooterViews.PREVIEW_MEETING_ACTIVITY);
      this.IcurrentMeetingActivitySubscription = this.meetingStructureService.selectedMeetingActivityObserver.subscribe(activity => {
        this.selectedMeetingActivity = activity;
      });
    }
    else{
      this.consentService.inMeetingConsentFlow = false;
      this.footerService.initButtons(FooterViews.ConsentCapture);
    }
    // this.events.subscribe("consent:footerEvents", this.footerHandler);

    this.events.subscribe('remote_meeting_participant_Joined_left',this.handleContactConnectionLost.bind(this))

    this.initSectionHeader();
  }
  public handleContactConnectionLost(data){
    if (data.joinedStatus === 'left') {
      if (this.consentService.inMeetingConsentFlow && data.contactID && data.contactID == this.contactOfflineService.contactInformation.ID) {
        if(this.navService.getActiveChildNavViewPageName() == PageName.ContactConsentCaptureComponent) this.closePage();
      }
    }
  }

  initSectionHeader(){
    this.getProducsHeader();
    this.getChannelsHeader();
    this.getConsentTermsHeader();
  }

  onSectionHeaderControlClick(id) {
    if (id === 'products-select-deselect-btn') {
      this.selectAll('products');
    } else if (id === 'channels-select-deselect-btn') {
      this.selectAll('channels');
    } else if (id === 'generate-terms-btn') {
      this.generateTerms();
    }
    else if(id=== 'cancel-btn'){
      this.closePage()
    }
    this.initSectionHeader();
  }

  public getProducsHeader() {

    let detailsHeaderControls = [];
    if(this.consentService.inMeetingConsentFlow && this.authenticationService.user.isProductConsent){
      detailsHeaderControls.push({
        id: 'cancel-btn',
        text: this.translate.instant('SCRAP'),
        isDisabled:false,
      });
    }
    detailsHeaderControls.push({
        id: 'products-select-deselect-btn',
        text: this.productLableText==this.translate.instant('Deselect All')? this.translate.instant('DESELECT_ALL'):this.translate.instant('SELECT_ALL'),
        isDisabled:false,
    });


    this.productsHeaderModel = {
      id: 'products-header',
      title: this.translate.instant('PRODUCTS_CAP'),
      controls: detailsHeaderControls,
    };
  }

  public getChannelsHeader() {

    let detailsHeaderControls = [];
    if(this.consentService.inMeetingConsentFlow && !this.authenticationService.user.isProductConsent){
      detailsHeaderControls.push({
        id: 'cancel-btn',
        text: this.translate.instant('SCRAP'),
        isDisabled:false,
      });
    }

    detailsHeaderControls.push({
        id: 'channels-select-deselect-btn',
        text: this.channelLableText==this.translate.instant('Deselect All')? this.translate.instant('DESELECT_ALL'):this.translate.instant('SELECT_ALL'),
        isDisabled: this.consentChannels?.length===0,
    });

    this.channelsHeaderModel = {
      id: 'channels-header',
      title: this.translate.instant('CHANNELS_CAP'),
      controls: detailsHeaderControls,
    };
  }

  public getConsentTermsHeader() {

    let detailsHeaderControls = [];

    detailsHeaderControls.push({
        id: 'generate-terms-btn',
        text: this.translate.instant('GENERATE'),
        isDisabled: this.atleastOneProductChannelChecked()
    });

    this.contactConsentTermsModel = {
      id: 'contact-consent-terms-header',
      title: this.translate.instant('CONTACT_CONSENT_TERMS'),
      controls: detailsHeaderControls,
    };
  }

  displayChannelLabels(label: string) {
    let translateddisplayName: string = "";
   switch (label) {
      case 'Email':
        translateddisplayName = this.translate.instant('EMAIL');
        break;
      case 'Address':
        translateddisplayName = this.translate.instant('ADDRESS');
        break;
      case 'Phone':
        translateddisplayName = this.translate.instant('PHONE');
        break;
      case 'SMS':
        translateddisplayName = this.translate.instant('SMS');
        break;
      case 'Visit':
        translateddisplayName = this.translate.instant('VISIT');
        break;
      case 'WhatsApp':
        translateddisplayName = this.translate.instant('WHATSAPP');
        break;
      case 'Facebook':
        translateddisplayName = this.translate.instant('FACEBOOK');
        break;
      case 'Fax':
        translateddisplayName = this.translate.instant('FAX');
        break;
     case 'Campaign':
       translateddisplayName = this.translate.instant('CAMPAIGN');
       break;
      default:
        translateddisplayName = label;
        break;
    }

    return translateddisplayName;
  }

  ngAfterViewInit(){
    if(this.scrollTop) this.scrollTop.nativeElement.scrollIntoView(false);
  }

  ngOnDestroy() {
    this.events.unsubscribe("consent:footerEvents", this.footerHandler);
    this.events.unsubscribe('remote_meeting_participant_Joined_left',this.handleContactConnectionLost)
    if (this.channelSubscription) this.channelSubscription.unsubscribe();
    if (this.contactSubscription) this.contactSubscription.unsubscribe();
  }

  atleastOneProductChannelChecked(): boolean {
  
    if(!this.authenticationService.user.isProductConsent)
    {
      this.consentTermGenerateService.selectedProducts = []//this.products.map(product => ({...product, isChecked:true}));
    }else{
      this.consentTermGenerateService.selectedProducts = this.products.filter(product => product.isChecked);
    }

    this.consentTermGenerateService.selectedChannels = this.consentChannels.filter(
      channel => channel.isChecked
    );
    if (this.consentTermGenerateService.selectedProducts.length && this.consentTermGenerateService.selectedChannels.length)
      this.footerService.enableButtons(["generateconsents"]);
      if (!this.authenticationService.user.isProductConsent) {
        return !(this.consentTermGenerateService.selectedChannels.length);
      } else {
        return !(this.consentTermGenerateService.selectedProducts.length && this.consentTermGenerateService.selectedChannels.length);
      }
    
  }

  checkIfTermsAlreadyGenerated(label: string) {
    this.checkIfProductOrChannelUnselected(label);
    if (this.generatedTerms) {
      if (this.generatedTerms.length) {
        this.footerService.enableButtons(["consentFlip"]);
      }else{

         if(this.atleastOneProductChannelChecked()) {
            this.footerService.disableButton(["generateconsents"]);
         }
         else {
            this.footerService.enableButtons(["generateconsents"]);
         }
      }
    }
    this.initSectionHeader();
  }

  checkIfProductOrChannelUnselected(label: string) {
    let allProductSelected: boolean = this.products.every(
      product => product.isChecked
    );
    let allChannelSelected: boolean = this.consentChannels.every(
      channel => channel.isChecked
    );

    let someProductSelected: boolean = this.products.some(
      product => product.isChecked
    );
    let someChannelSelected: boolean = this.consentChannels.some(
      channel => channel.isChecked
    );

    if (!someProductSelected || !someChannelSelected) {
      if(!this.consentService.inMeetingConsentFlow) this.footerService.disableButton(["generateconsents", "consentFlip"]);
    }

    if (label == 'products') {
      if (allProductSelected) {
        this.selectAllEnabled = true;
        this.productLableText = "Deselect All";
      } else {
        this.selectAllEnabled = false;
        this.productLableText = "Select All";
      }
    } else {
      if (allChannelSelected) {
        this.selectAllEnabled = true;
        this.channelLableText = "Deselect All";
      } else {
        this.selectAllEnabled = false;
        this.channelLableText = "Select All";
      }
    }
  }

  generateTerms() {
    this.generatedTerms = this.consentTermGenerateService.generateConsentTerms();
  }

  expandCollapse() {
    if (this.expandOrCollapse === "Show All") {
      this.isExpanded = true;
      this.limitProds = this.products.length;
    } else if (this.expandOrCollapse === "Show Less") {
      this.isExpanded = false;
      this.limitProds = 3;
    }
    this.expandOrCollapse = this.isExpanded ? "Show Less" : "Show All";
  }

  selectAll(label: string) {
    if (label == 'products') {
      if (this.productLableText === "Select All") {
        this.selectAllEnabled = true;
      } else if (this.productLableText === "Deselect All") {
        this.selectAllEnabled = false;
        this.footerActions();
      }
      this.productLableText = this.selectAllEnabled ? "Deselect All" : "Select All";
      this.products.forEach(brand => brand.isChecked = this.selectAllEnabled);
    } else {
      if (this.channelLableText === "Select All") {
        this.selectAllEnabled = true;
      } else if (this.channelLableText === "Deselect All") {
        this.selectAllEnabled = false;
        this.footerActions();
      }
      this.channelLableText = this.selectAllEnabled ? "Deselect All" : "Select All";
      this.consentChannels.forEach(channel => channel.isChecked = this.selectAllEnabled);
    }
  }

  footerActions() {
    if(!this.consentService.inMeetingConsentFlow){
      this.footerService.disableButton(["generateconsents", "consentFlip"]);
      if (this.generatedTerms && this.generatedTerms.length)
        this.footerService.enableButtons(["consentFlip"]);
    }
  }

  openConsentDetails(term: ConsentTerm) {
    term.isSelectedTerm = true;
    this.consentService.selectedConsentTerm.next(term);
    this.consentService.allGeneratedTerms.next(this.generatedTerms);
    this.consentService.consentTab = FooterViews.ConsentCapture;
    this.generatedTerms = [];
    if(!this.consentService.inMeetingConsentFlow){
      // this.navService.popWithPageTracking();
      this.navService.pushWithPageTracking(
        ConsentDetailsComponent,
        PageName.ConsentDetailsComponent
      );
    }
    else{
      this.navService.pushChildNavPageWithPageTracking(
        ConsentDetailsComponent,
        PageName.ConsentDetailsComponent
      );
    }
  }

  closePage(){
    if(this.consentService.inMeetingConsentFlow){
      this.consentService.inMeetingConsentFlow = false;
      this.presentationService.showNewActivity = false;
      if ((!this.selectedMeetingActivity) || (this.selectedMeetingActivity && this.selectedMeetingActivity.type === EmbeddedInteractionType.Content)) {
        this.footerService.initButtons(FooterViews.PreviewMeeting);
      }
      this.navService.popChildNavPageWithPageTracking();
    }
    else{
      this.uiService.showNewActivity = false;
      this.navService.popChildNavPageWithPageTracking();
    }
  }

}
