
import {from as observableFrom,  Observable } from 'rxjs';

import {mergeMap} from 'rxjs/operators';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Endpoints } from '../../config/endpoints.config';
import { LogService } from '../services/logging/log-service';
import { AuthenticationDataService } from '../data-services/authentication/authentication.service';
import {AuthenticationService} from "@omni/services/authentication.service";
export const InterceptorSkipHeader = 'X-Skip-Interceptor';
export const MsGraphTokenHeader = 'X-MS-Graph-Token';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptor implements HttpInterceptor {
    constructor(private injector: Injector) {}

    /**
     * Simply intercept all outgoing requests (if post?) and add our authorization header
     *
     * @param {HttpRequest<any>} request
     * @param {HttpHandler} next
     * @returns {Observable<HttpEvent<any>>}
     *
     * @memberOf TokenInterceptor
     */
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (request.headers.has(InterceptorSkipHeader)) {
            const headers = request.headers.delete(InterceptorSkipHeader);
            return next.handle(request.clone({ headers }));
          }
          if (request.url.includes('botframework')) {
            request = request.clone({
                setHeaders: {
                    Authorization: Endpoints.ogenie.BEARER_TOKEN
                }
            });
        } else if (request.url.includes(Endpoints.OneKey.ServiceProvider)) {
          let authService = this.injector.get(AuthenticationService);
          request = request.clone({
            setHeaders: {
              Authorization: 'Basic ' + window.btoa(authService.okIntegrationSettings.okConfigDTO.apiUserName + ':' + authService.okIntegrationSettings.okConfigDTO.apiPassword)
            }
          });
        } else {
          const authService = this.injector.get(AuthenticationDataService);
          if (request.headers.has(MsGraphTokenHeader)) {
            return observableFrom(authService.getGraphToken()).pipe(
              mergeMap(token => {
                let headers = request.headers.delete(MsGraphTokenHeader);
                headers = headers.set('Authorization', `Bearer ${token}`)
                return next.handle(request.clone({headers}));
              }));
          } else {
            return observableFrom(authService.getToken()).pipe(
              mergeMap(token => {
                request = request.clone({
                  setHeaders: {
                      Authorization: `Bearer ${token}`
                  }
                });
              return next.handle(request);
            }));
          }
        }
        if (Endpoints.network.debug) {
          let logService = this.injector.get(LogService);
          logService.logDebug('Intercepted http request: ' + request.url);
        }

        return next.handle(request);
    }
}
