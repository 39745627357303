import { EventActivity } from '@omni/classes/events-tool/event.class';
import { Injectable } from "@angular/core";
import { AuthenticationService } from "../authentication.service";
import { FeatureActionsMap } from "../../classes/authentication/user.class";
import { DB_SYNC_STATE_KEYS, DB_KEY_PREFIXES, DB_ALLDOCS_QUERY_OPTIONS, PREFIX_SEARCH_ENDKEY_UNICODE } from "../../config/pouch-db.config";
import { EntitySyncInfo, EntityNames, DeltaService } from "../../data-services/delta/delta.service";
import { Endpoints } from "../../../config/endpoints.config";
import { DiskService, OFFLINE_DATA_COUNT_ENTITY_NAME } from "../disk/disk.service";
import { CustomerEventsDataService } from "../../data-services/customer-event/customer-events.data.service";
import { ContactEvent, EventRegistration, EventRegistrationResponse } from "../../classes/customer-event/customer-event.class";
import { EventsService } from "../events/events.service";
import _, { isEmpty } from "lodash";
import { DeviceService } from "../device/device.service";
import { LoadingController } from "@ionic/angular/";
import { UIService } from "../ui/ui.service";
import { TrackAction } from "../../classes/contact/contact.class";

import { concat } from 'rxjs/operators';
import { EventRegistrationStatus } from "@omni/enums/event/event.enum";
import { EventsToolService } from '../events-tool/events-tool.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerEventsService {
    public contactEvents: ContactEvent[] = [];

    constructor(private authService: AuthenticationService,
        private disk: DiskService,
        private customerEventsDatsService: CustomerEventsDataService,
        private deltaService: DeltaService,
        private eventService: EventsService,
        private deviceService: DeviceService,
        private loadingCtrl: LoadingController,
        private uiService: UIService,
        private eventsToolService: EventsToolService) {
        this.eventService
            .observe("sync:completed")
            .subscribe(() => {
                if (this.authService.hasFeatureAction(FeatureActionsMap.EVENT_REGISTRATION)) {
                    this.disk.batchFetch(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_CONTACT_EVENTS).then((data: ContactEvent[]) => {
                        this.contactEvents = data;
                        console.log(`Contact Events from disk : ${data ? data.length : 0}`);
                    });
                }
            });
    }

    public async syncContactEvents(loadFromDbOnly = false) {
        if (this.authService.hasFeatureAction(FeatureActionsMap.EVENT_REGISTRATION)) {
            if (loadFromDbOnly) {
                await this.disk.batchFetch(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_CONTACT_EVENTS).then((data: ContactEvent[]) => {
                    this.contactEvents = data;
                    console.log(`Contact Events from disk : ${data ? data.length : 0}`);
                });
            } else {
            const syncState = await this.disk.getSyncState(DB_SYNC_STATE_KEYS.SYNC_CONTACT_EVENT);
            const isInitialSync = !syncState || !syncState.lastUpdatedTime
            const contactEventSyncInfo: EntitySyncInfo = {
                entityName: EntityNames.contactEvent,
                totalFailed: 0,
                totalSynced: 0,
                errors: [],
                syncStatus: true
            };
            if (isInitialSync) {
                await this.disk.deleteAllFromDbUsingAlldocsQuery(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_CONTACT_EVENTS);
                this.disk.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.CONTACT_EVENT, 0);
            }
            let url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.customerEvents.FETCH_CONTACT_EVENTS;
            url = isInitialSync ? url : url + '&lastUpdatedTime=' + syncState.lastUpdatedTime;
            await this.customerEventsDatsService.getContactEventRegistrations(url).then(
                async (data) => {
                    const newLastUpdatedTime = new Date().getTime();
                    console.log("Saving contact events to disk...");
                    if (isInitialSync)
                        await this.mapFullSyncedContactEvents(data, newLastUpdatedTime);
                    else
                        await this.mapDeltaSyncedContactEvents(data, newLastUpdatedTime);
                    syncState.lastUpdatedTime = newLastUpdatedTime;
                    await this.disk.updateSyncState(syncState);
                    if (Array.isArray(data)) {
                        contactEventSyncInfo.totalSynced = data.length;
                    }
                    this.deltaService.addEntitySyncInfo(contactEventSyncInfo);
                    console.log(`Sync status : ${JSON.stringify(syncState)} initial sync : ${isInitialSync}`);
                }).catch(err => {
                    console.error("Error occurred while fetching contact events...")
                    this.deltaService.addSyncErrorToEntitySyncInfo(contactEventSyncInfo, this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.customerEvents.FETCH_CONTACT_EVENTS, err);
                    this.deltaService.addEntitySyncInfo(contactEventSyncInfo);
                })
            }
        }
    }

    private async mapFullSyncedContactEvents(rawContactEventdata, newLastUpdatedTime) {
        if (rawContactEventdata && Array.isArray(rawContactEventdata)) {
            for (let i = 0; i < rawContactEventdata.length; i++) {
                const rawContactEvent = rawContactEventdata[i];
                rawContactEvent._id = DB_KEY_PREFIXES.CONTACT_EVENT + rawContactEvent.contactId;
                rawContactEvent.lastUpdatedTime = newLastUpdatedTime;
            }
            try {
                await this.disk.bulk(rawContactEventdata);
            } catch (error) {
                console.error('mapFullSyncedContactEvents: ', error);
            }
        } else {
            console.error('mapFullSyncedContactEvents: Invalid raw data provided');
        }
    }

    public getEventsForContact(contactId: string): ContactEvent {
        let availableEvents: EventRegistration[] = [];
        let upcomingEvents: EventRegistration[] = [];
        let contactEvent: ContactEvent = {contactId: contactId, availableEvents: [], upcomingEvents: []};

        let index = this.contactEvents.findIndex(contactEvent => {
          return contactEvent.contactId === contactId;
        });
        if (index >= 0) {
          if(this.contactEvents[index].availableEvents) {
            this.contactEvents[index].availableEvents.map(eventReg => {
                if (eventReg.eventEndDate >= new Date()) {
                    availableEvents.push(eventReg);
                }
                eventReg['isExpanded'] = false;
                eventReg['eventExpandIcon'] = "accordion-add";
            });
          }
          if(this.contactEvents[index].upcomingEvents) {
            this.contactEvents[index].upcomingEvents.map(eventReg => {
                if (eventReg.eventEndDate >= new Date()) {
                    upcomingEvents.push(eventReg);
                }
                eventReg['isExpanded'] = false;
                eventReg['eventExpandIcon'] = "accordion-add";
            });
          }
        }
        contactEvent.availableEvents = _.orderBy([...availableEvents, ...upcomingEvents], ['eventStartDate'], ['desc']);
         // contactEvent.availableEvents = _.orderBy(availableEvents, ['eventStartDate'], ['desc']);;
         // contactEvent.upcomingEvents =  _.orderBy(upcomingEvents, ['eventStartDate'], ['desc']);;
         return contactEvent
    }

    async syncIndividualContactEventFromContactProfile(rawContactEvents: ContactEvent, contactId: string) {
        //If event is signed up in offline, but not yet synced to Dynamics. Don't show it in App from service response as well
        rawContactEvents['availableEvents'] = await this.verifyIfEventIsRegisteredOfflineAndNotSyncedToDynamics(rawContactEvents['availableEvents']);
        let index = -1;
        if (this.contactEvents.length > 0) {
            index = this.contactEvents.findIndex(contactEvent => contactEvent.contactId === contactId);
        }
        //if contact event is already mapped
        if (index >= 0) {
            if(rawContactEvents['upcomingEvents']) {
              this.contactEvents[index].upcomingEvents = rawContactEvents['upcomingEvents'];
            }
             if(rawContactEvents['availableEvents']) {
              this.contactEvents[index].availableEvents = rawContactEvents['availableEvents'];
            }
        }
        else {
            //if contact has no events, add it
            let contactEvent = { contactId: contactId, availableEvents: rawContactEvents['availableEvents'], upcomingEvents: rawContactEvents['upcomingEvents']  };
            this.contactEvents.push(contactEvent)
        }
        //update db with latest data
        let contactEvents = { contactId: contactId, availableEvents: rawContactEvents['availableEvents'], upcomingEvents: rawContactEvents['upcomingEvents'], lastUpdatedTime: new Date().getTime() };
        await this.disk.updateOrInsert(DB_KEY_PREFIXES.CONTACT_EVENT + contactId, doc => contactEvents)
            .catch(error => console.error('syncIndividualContactEventFromContactProfile: ', error));
    }

    private async verifyIfEventIsRegisteredOfflineAndNotSyncedToDynamics(rawContactEvents: EventRegistration[]) {
        const offlineEventResponse = await this.disk.loadOfflineCapturedEventRegResponse();
        if (offlineEventResponse && offlineEventResponse['eventRegResponse'] && Array.isArray(offlineEventResponse['eventRegResponse']) && offlineEventResponse['eventRegResponse'].length > 0) {
            const eventRegIdResponded = offlineEventResponse['eventRegResponse'].map(eventReg => eventReg.eventRegistrationId);
            rawContactEvents = rawContactEvents.filter(rawContactEvent =>
                !eventRegIdResponded.includes(rawContactEvent.eventRegistrationId)
            );
        }
        return rawContactEvents;
    }

    private async mapDeltaSyncedContactEvents(rawContactEventdata, newLastUpdatedTime) {
        if (rawContactEventdata && Array.isArray(rawContactEventdata)) {
            for (let i = 0; i < rawContactEventdata.length; i++) {
                const rawContactEvent = rawContactEventdata[i];
                rawContactEvent._id = DB_KEY_PREFIXES.CONTACT_EVENT + rawContactEvent.contactId;
                rawContactEvent.lastUpdatedTime = newLastUpdatedTime;
                let ele = await this.disk.retrieve(rawContactEvent._id);
                if (ele) {
                  //mapping available events
                    if (!Array.isArray(ele.availableEvents)) {
                      ele.availableEvents = [];
                    }
                    let existingAvailableER = [...ele['availableEvents']];
                    if (existingAvailableER && rawContactEvent['availableEvents']) {
                        rawContactEvent['availableEvents'].forEach(async er => {
                            let index = _.findIndex(existingAvailableER, function (eR) { return eR['eventRegistrationId'] === er['eventRegistrationId'] });
                            if (index) {
                              if(index < 0) {
                                if(er['track_action'] != TrackAction.Deleted) {
                                  existingAvailableER.push(er);
                                }
                              } else {
                                if(er['track_action'] != TrackAction.Download) {
                                  existingAvailableER.splice(index, 1);
                                }
                              }
                            }
                        });
                        ele['availableEvents'] = existingAvailableER;
                    }

                     //mpping upcoming events
                     if (!Array.isArray(ele.upcomingEvents)) {
                      ele.upcomingEvents = [];
                    }
                     let existingUpcomingER = [...ele['upcomingEvents']];
                    if (existingUpcomingER && rawContactEvent['upcomingEvents']) {
                        rawContactEvent['upcomingEvents'].forEach(async er => {
                            let index = _.findIndex(existingUpcomingER, function (eR) { return eR['eventRegistrationId'] === er['eventRegistrationId'] });
                            if (index) {
                               if(index < 0) {
                                if(er['track_action'] != TrackAction.Deleted) {
                                  existingUpcomingER.push(er);
                                }
                              } else {
                                if(er['track_action'] != TrackAction.Download) {
                                  existingUpcomingER.splice(index, 1);
                                }
                              }
                            }
                        });
                        ele['upcomingEvents'] = existingUpcomingER;
                    }

                } else {
                    rawContactEvent['availableEvents'] = rawContactEvent['availableEvents'].filter(er => er['track_action'] !== TrackAction.Deleted);
                    rawContactEvent['upcomingEvents'] = rawContactEvent['upcomingEvents'].filter(er => er['track_action'] !== TrackAction.Deleted);
                    ele = rawContactEvent;
                }
                await this.disk.updateOrInsert(rawContactEvent._id, doc => ele)
                    .catch(error => console.error('mapDeltaSyncedContactEvents: ', error));
            }
        } else {
            console.error('mapDeltaSyncedContactEvents: Invalid raw data provided');
        }

        // For offline data count tracking...
        this.disk.loadOfflineCapturedEventRegResponse();
    }

    public async purgeData(maxEndDateUnixTimestamp) {
        let option = {
            selector: {
                '_id': {
                    $gte: DB_KEY_PREFIXES.CONTACT_EVENT,
                    $lte: DB_KEY_PREFIXES.CONTACT_EVENT + PREFIX_SEARCH_ENDKEY_UNICODE
                },
                'availableEvents': {
                    '$elemMatch': {
                        'eventEndDate': { $lt: maxEndDateUnixTimestamp.toString() }
                    }
                },
                'upcomingEvents': {
                    '$elemMatch': {
                        'eventEndDate': { $lt: maxEndDateUnixTimestamp.toString() }
                    }
                }
            }
        };

        let deletedContactEvents;
        try {
            // Fetch from DB and delete
            const rawContactEvents: any[] = await this.disk.find(option);

            if (rawContactEvents && Array.isArray(rawContactEvents)) {
                deletedContactEvents = rawContactEvents.map(raw => {
                    const idx = this.contactEvents.findIndex(a => a.contactId === raw.contactId);
                    if (idx >= 0) {
                        this.contactEvents.splice(idx, 1);
                    }
                    return { _id: raw._id, _rev: raw._rev, _deleted: true };
                });
            }
        } catch (error) {
            console.error('purgeData Customer Events: ', error);
        }

        try {
            // Bulk save/update docs to DB
            await this.disk.bulk(deletedContactEvents);
        } catch (error) {
            // TODO: handle error..
            console.error('purgeData Customer Events: ', error);
        }
    }

    public async captureEventRegistrationResponse(response: boolean, eventRegistrationId: string, contactId: string, eventId: string) {
        const offlineEventId = 'offline_event_reg_response_' + new Date().getTime();
        const regResponse = response ? 'yes' : 'no';

        const eventRegResponse: EventRegistrationResponse = new EventRegistrationResponse(
          offlineEventId,
          eventRegistrationId,
          regResponse,
          contactId,
          regResponse === 'yes' ? EventRegistrationStatus.Accepted : EventRegistrationStatus.Declined,
        );
        eventRegResponse.eventId = eventId;
        if (this.deviceService.isOffline) {
            await this.disk.captureEventRegResponse(eventRegResponse);
            this.removeRespondedEvent(eventRegistrationId, contactId, response);
        } else {
            this.loadingCtrl.create({})
            .then(async (loader)=>{
              loader.present();
              let eventRegResponses = [];
              eventRegResponses.push(eventRegResponse);
              await this.customerEventsDatsService.captureEventRegistrationResponse(eventRegResponses).then(async capturedResponse => {
                  console.log("Captured response: " + response + " : for eventRegistrationId : " + eventRegistrationId);
                  loader.dismiss();
                 await this.removeRespondedEvent(eventRegistrationId, contactId, response);
              }).catch(httpError => {
                  loader.dismiss();
                  console.log("Error occurred in capturing event registration response: ", httpError);
                  throw new Error(httpError);
              });
            })
        }
    }

     private async removeRespondedEvent(eventRegistrationId: string, contactId: string, accepted: boolean) {
        const existingEventKey =  DB_KEY_PREFIXES.CONTACT_EVENT + contactId;
        let contactEvent = await this.disk.retrieve(existingEventKey);

        if(contactEvent && contactEvent.availableEvents) {
          this.contactEvents = this.contactEvents.filter(ce => {
              return ce.contactId !== contactEvent.contactId;
          });

          let respondedEvent = contactEvent.upcomingEvents.find(event => {
            return event.eventRegistrationId === eventRegistrationId;
          });
          if(respondedEvent && accepted) {
            contactEvent.availableEvents.push(respondedEvent);
          }
          contactEvent.availableEvents = contactEvent.availableEvents.filter(ae => {
            return ae.eventRegistrationId !== eventRegistrationId;
          });
          await this.disk.updateOrInsert(existingEventKey, doc => contactEvent);
           this.contactEvents.push(contactEvent);
        }
    }

    public async uploadOfflineEventRegistrationResponse(isPartialUpload = false, maxRecordCountForPartialUpload = 10) {
        if (!isPartialUpload && this.deviceService.isOffline) return;
        const offlineEventResponse = await this.disk.loadOfflineCapturedEventRegResponse();
        const eventResponseSyncInfo: EntitySyncInfo = {
            entityName: EntityNames.contactEvent,
            totalFailed: 0,
            totalSynced: 0,
            errors: [],
            syncStatus: true
        };
        if (offlineEventResponse && offlineEventResponse['eventRegResponse'] && Array.isArray(offlineEventResponse['eventRegResponse']) && offlineEventResponse['eventRegResponse'].length > 0) {
            let payload = offlineEventResponse['eventRegResponse'];

            if (isPartialUpload) {
                if (offlineEventResponse['eventRegResponse'].length > maxRecordCountForPartialUpload) {
                    payload = JSON.parse(JSON.stringify(offlineEventResponse['eventRegResponse']));
                    payload = payload.splice(0, maxRecordCountForPartialUpload);
                }
            }
            try {
                // Update the event reg id for offline events
                payload.filter(eventRegResp => eventRegResp['eventRegistrationId'] == null).forEach(eventRegResp => {
                  const event = this.eventsToolService.getEventDetailsbyId(eventRegResp.eventId);
                  if (event) {
                    const participant = event.participants.find(p => p.id == eventRegResp.indskr_customer);
                    if (participant) {
                      eventRegResp['eventRegistrationId'] = participant.registrationId;
                    }
                  }
                })
                const capturedResponse = await this.customerEventsDatsService.captureEventRegistrationResponse(payload);
                capturedResponse.forEach(cResponse => {
                    if (!cResponse['errorId']) {
                        let idx;
                        offlineEventResponse['eventRegResponse'].findIndex((a, index) => {
                            if (a.offlineEventId && a.offlineEventId == cResponse['offlineEventId']) {
                                idx = index;
                            }
                        });
                        if (idx >= 0) {
                            offlineEventResponse['eventRegResponse'].splice(idx, 1);
                            offlineEventResponse['count']--
                        }
                        else {
                            console.warn(`uploadOfflineEventRegistrationResponse: ID ${cResponse.offlineActivityId} is not found from local db.`);
                        }
                        eventResponseSyncInfo.totalSynced++;
                    }
                });
            } catch (httpError) {
                this.deltaService.addSyncErrorToEntitySyncInfo(eventResponseSyncInfo, this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.customerEvents.CAPTURE_EVENT_REGISTRATION_RESPONSE, httpError);
                eventResponseSyncInfo.totalFailed += offlineEventResponse['count'];
            }
            this.deltaService.addEntitySyncInfo(eventResponseSyncInfo, true);
            await this.disk.updateDocWithIdAndRev(offlineEventResponse);

            // Track offline data count
            const newCount = offlineEventResponse['eventRegResponse'].length - eventResponseSyncInfo.totalSynced + eventResponseSyncInfo.totalFailed;
            this.disk.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.CONTACT_EVENT, newCount > 0 ? newCount : 0);
        }
    }

    public async addCustomerEventsForAddedEventParticipants(event: EventActivity, addedParticipantIds: string[]) {
      if (
        Array.isArray(event?.participants)
        && Array.isArray(addedParticipantIds)
      ) {
        const eventParticipants = event.participants;
        const bulkData = [];
        for (const addedParticipantId of addedParticipantIds) {
          const addedParticipant = eventParticipants.find(participant => participant.id === addedParticipantId);
          if (addedParticipant) {
            let contactEvent = this.contactEvents.find(ce => ce.contactId === addedParticipant.id);
            if (!contactEvent) {
              contactEvent = {
                contactId: addedParticipant.id,
                availableEvents: [],
                upcomingEvents: [],
              };
              contactEvent['_id'] = DB_KEY_PREFIXES.CONTACT_EVENT + contactEvent.contactId;
              contactEvent['new'] = true;
            }

            if (_.isEmpty(contactEvent.availableEvents)) {
              contactEvent.availableEvents = [];

            }
            if (_.isEmpty(contactEvent.upcomingEvents)) {
              contactEvent.upcomingEvents = [];
            }
            contactEvent.availableEvents.push(
              {
                eventRegistrationId: addedParticipant.registrationId,
                eventId: event.ID ? event.ID : event.offlineId,
                eventName: event.name,
                eventStartDate: event._startDate.getTime().toString(),
                eventEndDate: event.endDate.getTime().toString(),
                indskr_reasons: EventRegistrationStatus.Proposed,
                registeredBy: this.authService.user.displayName,
              }
            );

            contactEvent.availableEvents = _.orderBy(contactEvent.availableEvents, ['eventStartDate'], ['desc']);
            contactEvent['lastUpdatedTime'] = new Date().getTime();

            if (
              contactEvent.hasOwnProperty('_id')
              && (
                contactEvent.hasOwnProperty('_rev')
                || contactEvent.hasOwnProperty('new')
              )
            ) {
              if (contactEvent['new']) {
                this.contactEvents.push(contactEvent);
                delete contactEvent['new'];
              }
              bulkData.push(contactEvent);
            } else {
              await this.disk.updateOrInsert(
                DB_KEY_PREFIXES.CONTACT_EVENT + contactEvent.contactId,
                doc => contactEvent
              );
            }
          }
        }
        await this.bulkSaveAndUpdateRev(bulkData);
      }
    }
    public async removeCustomerEventsForRemovedEventParticipants(event: EventActivity, removedParticipantIds: string[]) {
      if (
        Array.isArray(event?.participants)
        && Array.isArray(removedParticipantIds)
      ) {
        const eventParticipants = event.participants;
        const bulkData = [];
        for (const removedParticipantId of removedParticipantIds) {
          const removedParticipant = eventParticipants.find(participant => participant.id === removedParticipantId);
          if (removedParticipant) {
            const contactEvent = this.contactEvents.find(ce => ce.contactId === removedParticipant.id);
            if (contactEvent) {
              if (Array.isArray(contactEvent.availableEvents)) {
                let flag = false;
                const availEventIdx = contactEvent.availableEvents.findIndex(ae => ae.eventRegistrationId === removedParticipant.registrationId);
                if (availEventIdx >= 0) {
                  contactEvent.availableEvents.splice(availEventIdx, 1);
                  flag = true;
                }
                const upcomingEventIdx = contactEvent.upcomingEvents?.findIndex(ue => ue.eventRegistrationId === removedParticipant.registrationId);
                if (upcomingEventIdx >= 0) {
                  contactEvent.upcomingEvents.splice(upcomingEventIdx, 1);
                  flag = true;
                }

                if (flag) {
                  if (
                    contactEvent.hasOwnProperty('_id')
                    && contactEvent.hasOwnProperty('_rev')
                  ) {
                    bulkData.push(contactEvent);
                  } else {
                    await this.disk.updateOrInsert(
                      DB_KEY_PREFIXES.CONTACT_EVENT + contactEvent.contactId,
                      doc => contactEvent
                    );
                  }
                }
              }
            } else {
              console.warn('removeCustomerEventsForRemovedEventParticipants: Contact event is not found for removed participant: ', removedParticipant);
            }
          }
        }
        await this.bulkSaveAndUpdateRev(bulkData);
      }
    }

    public async deleteCustomerEventsForDeletedEvent(event: EventActivity) {
      if (Array.isArray(event?.participants)) {
        const bulkData = [];
        for (const participant of event.participants) {
          const contactEvent = this.contactEvents.find(ce => ce.contactId === participant.id);
          if (contactEvent) {
            const availableEventIdx = contactEvent.availableEvents.findIndex(ae => ae.eventId === event.ID);
            if (availableEventIdx >= 0) {
              contactEvent.availableEvents.splice(availableEventIdx, 1);
            }
            const upcomingEventIdx = contactEvent.upcomingEvents.findIndex(ue => ue.eventId === event.ID);
            if (upcomingEventIdx >= 0) {
              contactEvent.upcomingEvents.splice(upcomingEventIdx, 1);
            }
            contactEvent['lastUpdatedTime'] = new Date().getTime();

            if (
              contactEvent.hasOwnProperty('_id')
              && contactEvent.hasOwnProperty('_rev')
            ) {
              bulkData.push(contactEvent);
            } else {
              this.disk.updateOrInsert(
                DB_KEY_PREFIXES.CONTACT_EVENT + contactEvent.contactId,
                doc => contactEvent
              );
            }
          }
        }
        await this.bulkSaveAndUpdateRev(bulkData);
      }
    }

    private async bulkSaveAndUpdateRev(bulkData: any[]) {
      try {
        if (bulkData.length > 0) {
          const responses = await this.disk.bulk(bulkData);
          for (const response of responses) {
            const data = bulkData.find(d => d._id === response.id);
            if (data && response.ok) {
              data._rev = response.rev;
            }
          }
        }
      } catch (error) {
        console.error('bulkSaveAndUpdateRev: ', error);
      }
    }
}
