import { CustomerCallPlanList } from './../customer-call-plan-list/customer-call-plan-list';
import { Component, OnInit, ViewChild, HostBinding } from '@angular/core';
import { IonNav } from '@ionic/angular';
import { CallPlanOfflineService } from '@omni/services/call-plan/call-plan.offline.service';
import { UIService } from '@omni/services/ui/ui.service';
import { ActivityService } from '@omni/services/activity/activity.service';
import { ContactDataService } from '@omni/data-services/contact/contact.data.service';
import { AccountOfflineService } from '@omni/services/account/account.offline.service';
import { DeviceService } from '@omni/services/device/device.service';
import { NavigationService, PageName, ChildNavNames } from '@omni/services/navigation/navigation.service';
import { Events } from '@omni/events';
import { CustomerCallPlanDetails } from '../customer-call-plan-details/customer-call-plan-details';
import { AgendaFooterService, AgendaFooterView } from '@omni/services/footer/agenda-footer.service';
import { CallPlanDetails } from '@omni/components/call-plans/call-plan-details/call-plan-details';


@Component({
  selector: 'customer-call-plan-page',
  templateUrl: './customer-call-plan-page.html',
  styleUrls: ['./customer-call-plan-page.scss'],
})
export class CustomerCallPlanPage implements OnInit {

  @ViewChild('rightPaneNav', { static: true }) nav: IonNav;

  constructor(public callPlanService: CallPlanOfflineService,
    public uiService: UIService,
    public activityService: ActivityService,
    public contactService: ContactDataService,
    public accountOfflineService: AccountOfflineService,
    private events: Events,
    public device: DeviceService,
    private navService: NavigationService,
    private readonly agendaFooterService: AgendaFooterService,
  ) {
  }

  @HostBinding("class.callPlan-selected")
  public get callPlanSelected(): boolean {
    return (!!this.callPlanService.selectedCallPlan);
  }

  onCancelNewActivity() {
    this.uiService.activeView === 'CallPlanDetails'
    this.callPlanService.callPlanPageView === 'CallPlanDetail';
  }
  ngOnDestroy() {
    this.agendaFooterService.initButtons(this.agendaFooterService.footerViewAtHome);
    this.agendaFooterService.footerViewAtHome = null;
    if (this.uiService.activitiesPageTab === "plans" && this.agendaFooterService.planTabSegment === "goalsPlansTab") {
      this.callPlanService.segmentCallPlansList$.next({ hasUpdatedData: true });
      if (this.callPlanService.selectedCallPlanAtHome) {
        this.callPlanService.selectedCallPlan = this.callPlanService.selectedCallPlanAtHome;
      } else { 
        this.callPlanService.selectedCallPlan = null;
      }
    } else { 
      this.callPlanService.selectedCallPlan = null;
    }
    this.callPlanService.selectedCallPlanAtHome = null;
    this.events.publish("tools-back-clicked", PageName.CustomerCallPlanPage);
    this.navService.popChildNavCtrlFromStack(this.navService.getActiveChildNavName());
  }

  ionViewDidEnter() {
    if (this.uiService.isFlipButtonClicked) {
      this.uiService.isFlipButtonClicked = false;
    }
  }

  ngOnInit() {
    if (this.uiService.activitiesPageTab === "plans" && this.agendaFooterService.planTabSegment === "goalsPlansTab" && this.callPlanService.selectedCallPlan) { 
      this.callPlanService.selectedCallPlanAtHome = this.callPlanService.selectedCallPlan;
    }
    this.callPlanService.selectedCallPlan = null;
    this.navService.initRightPaneChildNav(this.nav, ChildNavNames.CallPlanPageNavigation, PageName.CustomerCallPlanPage, false, { deviceString: this.device.deviceFlags.ios ? 'ios' : 'other' });
  }

  onCallPlanSelected(callPlan) {
    if (callPlan) {
      this.navService.setChildNavRoot(CustomerCallPlanDetails, PageName.CustomerCallPlanDetails, PageName.CustomerCallPlanPage, { selectedCallPlan: callPlan });
    } else {
      this.navService.popToRootChildNavPageWithPageTracking();
    }
  }
}

