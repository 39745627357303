export const ERROR_MSG_MAP = {
    ERR_IO_INVALID_INPUT_401 : 'Invalid user credentials',
    ERR_IO_PWD_EXPIRED_400 : 'Password expired : {errorId}',
    ERR_IO_REFRESH_TOKEN_EXPIRED_400 : 'Refresh token / autorization code is expired : {errorId}',
    ERR_IO_ME06 : 'CRM Process Failed : {errorId}',
    ERR_IO_ME05 : 'Exceeded Maximum limit of note value',
    ERR_IO_ME04 : 'Invalid brand details',
    ERR_IO_ME03 : 'Another presenter has already accepted the meeting request)',
    ERR_IO_ME02 : 'Invalid Access Key',
    ERR_IO_ME01 : 'There are currently no reps available',
    ERR_IO_500 : 'Temporarily Unavailable, Could not process the request : {errorId}', 
    ERR_IO_403 : 'Forbidden Access : {errorId}',
    ERR_IO_401 : 'Unauthorized User : {errorId}',
    ERR_IO_400 : 'Bad Request/ Invalid Input : {errorId}',
    ERR_IO_ME07 : 'Requested rep is unavailable : {errorId}',
    ERR_IO_ACCOUNT_LOCKED_400 : 'Account is locked because user tried to sign in too many times with an incorrect user ID or password',
    ERR_IO_TNF_403 : 'Email is not configured. Please contact admin/support.',
    ERR_IO_ACCOUNT_DISABLED_400 : 'User account is disabled : {errorId}',
    RECORD_NOT_FOUND_404 : 'Requested record does not exist : {errorId}',
    ERR_IO_COACHING_400: 'For the specified period coaching already exists for the user',
    NO_COACHING_METRICS_CONFIGURED_400: 'For the selected user no coaching template exists.Please contact the administrator.',
    MORE_THAN_ONE_COACHING_METRICS_FOUND_400: 'This user is associated to multiple coaching templates. Please contact the administrator.',
    EXCLUDE_ERRORS : [
        'ERR_IO_TNF_403', 'ERR_IO_SR01', 'ERR_IO_REFRESH_TOKEN_EXPIRED_400'
    ]
}

