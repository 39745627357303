import { OpportunityManagementDataService } from './../../../data-services/opportunity-management/opportunity-management.data.service';
import { UIService } from './../../../services/ui/ui.service';
import { NewActivityComponent } from './../../activity/new-activity/new-activity';
import { Component, Input } from "@angular/core";
import { RepServices } from "../../../data-services/rep/rep.services";
import { DeviceService } from "../../../services/device/device.service";
import { NavigationService, PageName } from "../../../services/navigation/navigation.service";
import { AuthenticationService } from "../../../services/authentication.service";
import { TrackService, TrackingEventNames } from "../../../services/logging/tracking.service";
import { CollaboratorType, Opportunity } from "../../../classes/opportunity-management/opportunity.class";
import { FeatureActionsMap } from '../../../classes/authentication/user.class';
import { TranslateService } from '@ngx-translate/core';
import { IndPageTitleViewDataModel } from '@omni/models/indPageTitleDataModel';
import { EventName, EventsService } from '@omni/services/events/events.service';
import { takeUntil } from 'rxjs/operators';
import { AlertService } from '@omni/services/alert/alert.service';
import { Subject } from 'rxjs';
import { OpportunityManagementService } from '@omni/services/opportunity-management/opportunity-management.service';
import { FooterService } from '@omni/services/footer/footer.service';
import { NothingSelectedView } from '@omni/components/shared/nothing-selected-view/nothing-selected-view';
import { AccountManagementDataService } from '@omni/data-services/account-management/account-management.data.service';
import { SurveyService } from '@omni/services/survey/survey.service';
import _ from 'lodash';
import { AlertWithInput } from '@omni/components/shared/alert-with-input/alert-with-input'
import { PopoverController } from '@ionic/angular';
import { NotificationService, ToastStyle } from '@omni/services/notification/notification.service';


@Component({
  selector: 'opportunity-details',
  templateUrl: 'opportunity-details.html',
  styleUrls: ['opportunity-details.scss']
})
export class OpportunityDetailsComponent {

  public isLayoverPushView: boolean = false;
  public layoverViewPushedFrom: string = '';
  public opportunityDetailsSegment: string = 'info';
  public selectedOpportunity: Opportunity;
  public isPlusButtonDisabled: boolean = true;
  private isReadOnlyMode = true;
  private isConfirmFlowEnabled: boolean = false;
  private isSurveyValid: boolean = false;
  private _lookupPopover: HTMLIonPopoverElement;
  @Input() from: any
  @Input() opportunity: any;
  @Input() refreshOpportunityEvent: any;
  tabsData = [
    {
      displayText: this.translate.instant('INFO'),
      value: "info"
    },
    {
      displayText: this.translate.instant('OM_QOUTES'),
      value: "quotes",
    },
    {
      displayText: this.translate.instant('OM_AGREEMENTS'),
      value: "agreement",
    },
  ]
  public pageTitle: IndPageTitleViewDataModel;
  private readonly ngDestroy$ = new Subject<boolean>();
  private isCancelFlowEnabled: boolean = false;

  constructor(
    public device: DeviceService,
    public repService: RepServices,
    public authService: AuthenticationService,
    private navService: NavigationService,
    public trackingService: TrackService,
    private uiService: UIService,
    private opportunityDataService: OpportunityManagementDataService,
    private translate: TranslateService,
    private opportunityService: OpportunityManagementService,
    private readonly eventService: EventsService,
    private readonly alertService: AlertService,
    public readonly footerService: FooterService,
    private readonly accountManagementDataService: AccountManagementDataService,
    private readonly surveyService: SurveyService,
    private readonly popoverCtrl: PopoverController,
    private notificationService: NotificationService
  ) {
    this.eventService.observe(EventName.OPPORTUNITYSCRAPBUTTON).pipe(takeUntil(this.ngDestroy$)).subscribe((data) => {
      this.handleOpportunityStatusChange(EventName.OPPORTUNITYSCRAPBUTTON);
    });
    this.eventService.observe(EventName.OPPORTUNITYMARKCONFIRMED).pipe(takeUntil(this.ngDestroy$)).subscribe((data) => {
      this.handleOpportunityStatusChange(EventName.OPPORTUNITYMARKCONFIRMED);
    });
    this.eventService.observe(EventName.OPPORTUNITYCLOSEASWON).pipe(takeUntil(this.ngDestroy$)).subscribe((event) => {
      this.handleOpportunityReasonStatusChange(EventName.OPPORTUNITYCLOSEASWON, event);
    });
    this.eventService.observe(EventName.OPPORTUNITYCLOSEASLOST).pipe(takeUntil(this.ngDestroy$)).subscribe((event) => {
      this.handleOpportunityReasonStatusChange(EventName.OPPORTUNITYCLOSEASLOST, event);
    });
    this.eventService.observe(EventName.OPPORTUNITYCLOSEASUSERCANCELED).pipe(takeUntil(this.ngDestroy$)).subscribe((event) => {
      this.handleOpportunityReasonStatusChange(EventName.OPPORTUNITYCLOSEASUSERCANCELED, event);
    });
    this.eventService.observe(EventName.OPPORTUNITYERROR_CLOSE_TO_OPEN).pipe(takeUntil(this.ngDestroy$)).subscribe((data) => {
      this.opportunityDataService.getOpportunitiesData(false, this.selectedOpportunity.accountID);
      this.onClosePage();
    });
  }

  ngOnInit() {
    if (this.opportunity) {
      this.initOpportunityDetails();
      let isOrdersTabEnabled = false;
      if (this.authService.hasFeatureAction(FeatureActionsMap.ORDER_MANAGEMENT)) {
        isOrdersTabEnabled = true;
      }
      if (!isOrdersTabEnabled) {
        const selectedOppurtunityType = this.opportunityService.opportunityTypes.find(ot => ot.indskr_opportunitytypeid == this.opportunity.opportunityTypeId);
        if (selectedOppurtunityType && selectedOppurtunityType.indskr_ordercreation) {
          isOrdersTabEnabled = true;
        }
      }
      if (isOrdersTabEnabled) {
        this.tabsData.push({
          displayText: this.translate.instant('ORDERS'),
          value: "orders",
        });
      }
      if (this.authService.hasFeatureAction(FeatureActionsMap.DISCONTINUE_OPPORTUNTIY)) this.isCancelFlowEnabled = true;
      if (this.authService.hasFeatureAction(FeatureActionsMap.CONFIRM_OPPORTUNTIY)) this.isConfirmFlowEnabled = true;
    }
    if (this.from) {
      this.isLayoverPushView = true;
      this.layoverViewPushedFrom = this.from;
    }
    if (this.opportunity) {
      this.selectedOpportunity = this.opportunity;
      if (this.selectedOpportunity.state == 'Open' && this.selectedOpportunity.collaboratorType != CollaboratorType.BEHOLDER) {
        this.isPlusButtonDisabled = false;
      }
    }
    if (this.from == 'OpportunitiesSelectionPage') {
      this.isReadOnlyMode = true;
      this.isPlusButtonDisabled = true;
    }
    this.initPageTitle();

    this.device.isOfflineObservable.pipe(takeUntil(this.ngDestroy$)).subscribe((data) => {
      if (!this.opportunity.isrevenuesystemcalculated && this.opportunity.collaboratorType != CollaboratorType.BEHOLDER && this.from != 'OpportunitiesSelectionPage') {
        if (this.device.isOffline) {
          this.isReadOnlyMode = true;
        } else {
          this.isReadOnlyMode = false;
        }
        this.initPageTitle();
      }
    });
  }

  private initOpportunityDetails() {
    if (this.opportunity.state == 'Open' && this.opportunity.collaboratorType != CollaboratorType.BEHOLDER) {
      this.isReadOnlyMode = false;
    }
    if (!this.opportunity.isrevenuesystemcalculated && this.opportunity.collaboratorType != CollaboratorType.BEHOLDER) {
      if (this.device.isOffline) {
        this.isReadOnlyMode = true;
      } else {
        this.isReadOnlyMode = false;
      }
    }
    if (this.from == 'OpportunitiesSelectionPage') {
      this.isReadOnlyMode = true;
      this.isPlusButtonDisabled = true;
    }
  }

  ngOnDestroy() {
    this.ngDestroy$.next(true);
  }

  ionViewDidEnter() {
    if (this.selectedOpportunity) {
      this.opportunityDataService.isSelectedOpportunityUpdated = true;
      if (this.opportunityDetailsSegment != 'info') {
        this.footerService.hideAllButtons();
      }
    }
  }

  public segmentClicked(selectedTab: string) {
    switch (selectedTab) {
      case 'info':
        this.opportunityDataService.isSelectedOpportunityUpdated = true;
        this.trackingService.tracking('SelectInfoTab', TrackingEventNames.OPPORTUNITYMANAGEMENT)
        break;
      case 'quotes':
        this.trackingService.tracking('SelectQuotesTab', TrackingEventNames.OPPORTUNITYMANAGEMENT)
        break;
      case 'agreement':
        this.trackingService.tracking('SelectAgreementTab', TrackingEventNames.OPPORTUNITYMANAGEMENT)
        break;
      case 'orders':
        this.trackingService.tracking('SelectOrderTab', TrackingEventNames.OPPORTUNITYMANAGEMENT)
        break;
    }
  }

  public handlePlusButton(): void {
    this.trackingService.tracking('Select+button', TrackingEventNames.OPPORTUNITYMANAGEMENT)
    let fromIdentifier: string = (this.layoverViewPushedFrom == 'OpportunityTool') ? 'OpportunityManagementTool' :
      (this.layoverViewPushedFrom == 'AccountProfileOpportunities' ? 'AccountProfileOpportunities' : 'AccountPlanOpportunities');
    let masterPage = this.navService.getCurrentMasterPageName();
    let opp = this.opportunityService.getOpportunityDetailsbyId(this.selectedOpportunity.ID);
    this.navService.pushChildNavPageWithPageTracking(NewActivityComponent, PageName.NewActivityComponent,
      masterPage, { from: fromIdentifier, opportunity: opp });
  }

  public onClosePage(): void {
    if (this.from == 'OpportunitiesSelectionPage') {
      this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView, PageName.OpportunitySelectionPage);
      this.navService.setChildNavRightPaneView(false);
    } else {
      this.navService.popChildNavPageWithPageTracking();
    }
  }

  private initPageTitle(): void {
    this.opportunity = this.selectedOpportunity = this.opportunityService.getOpportunityDetailsbyId(this.opportunity.ID);
    let buttons = [];
    if (this.from != 'OpportunitiesSelectionPage') {
      buttons.push({
        id: "close",
        icon: "chevron-back-outline",
        isDisabled: false,
        align: "left"
      });
    }

    buttons.push({
      id: "refresh",
      imgSrc: 'assets/imgs/refreshIcon.svg',
      name: this.translate.instant('SYNC'),
      isDisabled: this.device.isOffline,
      align: "right"
    })
    if (!this.isPlusButtonDisabled) {
      buttons.push({
        id: "pluse-icon",
        imgSrc: 'assets/imgs/ios_add_3x.svg',
        name: this.translate.instant('CREATE'),
        isDisabled: this.isPlusButtonDisabled || this.selectedOpportunity.state != "Open" || this.device.isOffline,
        align: "right"
      })
    }
    if (this.isConfirmFlowEnabled && !this.selectedOpportunity.isrevenuesystemcalculated && this.selectedOpportunity.statusCode == 1) {
      buttons.push({
        id: "mark_confirm_opportunity",
        imgSrc: 'assets/imgs/header_complete.svg',
        name: this.translate.instant('CONFIRM'),
        isDisabled: this.isReadOnlyMode || this.device.isOffline || !this.selectedOpportunity.estimatedValue || !this.selectedOpportunity.stakeholders.length || !this.isSurveyValid,
        align: "right"
      })
    }
    else {
      buttons.push({
        id: "closeaslost",
        imgSrc: 'assets/imgs/Lost.svg',
        name: this.translate.instant('OPPORTUNITY_STATE_LOST'),
        isDisabled: this.isReadOnlyMode || this.device.isOffline || this.selectedOpportunity.state != "Open",
        align: "right"
      })
      buttons.push({
        id: "closeaswon",
        imgSrc: 'assets/imgs/Won.svg',
        name: this.translate.instant('WON'),
        isDisabled: this.isReadOnlyMode || this.device.isOffline || this.selectedOpportunity.state != "Open",
        align: "right"
      })
    }

    this.pageTitle = {
      id: 'opportunity-details',
      title: this.selectedOpportunity ? this.selectedOpportunity.opportunityName : '',
      controls: buttons,
    };
  }

  updateDescription(description) {
    if (description) this.selectedOpportunity.opportunityName = description;
    this.initPageTitle();
  }

  captureIsSurveyValidEvent(flag) {
    this.isSurveyValid = flag;
    this.initPageTitle();
  }

  public onPageTitleControlClick(id: string) {
    switch (id) {
      case 'refresh':
        this.refreshOpportunity();
        break;
      case 'close':
        this.onClosePage();
        break;
      case 'pluse-icon':
        this.handlePlusButton();
        break;
      case 'opportunity_scrap':
      case 'mark_confirm_opportunity':
        this.handleOpportunityStatusChange(id);
        break;
      case 'closeaswon':
      case 'closeaslost':
      case 'closeasusercanceled':
        this.handleOpportunityReasonStatusChange(id);
        break;
      default:
        console.log("Unhandled switch case statement");
        break;
    }
  }
  private async handleOpportunityReasonStatusChange(id: string, myEvent?) {
    this.opportunity = this.opportunityService.getOpportunityDetailsbyId(this.opportunity.ID);
    let popoverOptions: {}
    let altCancelText: string = this.translate.instant('CANCEL');
    switch (id) {
      case EventName.OPPORTUNITYCLOSEASWON:
        popoverOptions = {
          header: this.translate.instant('CLOSE_AS_WON'),
          message: this.translate.instant('CLOSE_OPPORTUNITY_SUB_TEXT'),
          inputs: this.fetchOpportunityReasonsWon(),
          cancelText: altCancelText,
          confirmText: this.translate.instant('CONFIRM'),
          Placeholder: this.translate.instant('ENTER_DESCRIPTION')
        }
        break;
      case EventName.OPPORTUNITYCLOSEASLOST:
        popoverOptions = {
          header: this.translate.instant('CLOSE_AS_LOST'),
          message: this.translate.instant('CLOSE_OPPORTUNITY_SUB_TEXT'),
          inputs: this.fetchOpportunityReasonsLost(),
          cancelText: altCancelText,
          confirmText: this.translate.instant('CONFIRM'),
          Placeholder: this.translate.instant('ENTER_DESCRIPTION')

        }
        break;
      case EventName.OPPORTUNITYCLOSEASUSERCANCELED:
        popoverOptions = {
          header: this.translate.instant('DISCONTINUE_OPPORTUNITY'),
          message: this.translate.instant('DISCONITNUE_OPPORTNUITY_MESAGE'),
          inputs: this.fetchOpportunityReasonsDiscontinue(),
          cancelText: altCancelText,
          confirmText: this.translate.instant('CONFIRM'),
          Placeholder: this.translate.instant('ENTER_DESCRIPTION')
        }
        // altConfirmText = this.translate.instant('CONFIRM')
        break;
    }
    this._lookupPopover = await this.popoverCtrl.create({
      component: AlertWithInput,
      componentProps: popoverOptions,
      cssClass: this.device.isNativeApp ? 'native-alert-with-input-list-view' : 'alert-with-input-list-view',
      backdropDismiss: true,
      event: this.device.isNativeApp ? myEvent ? myEvent : event : undefined
    });
    this._lookupPopover.onDidDismiss().then(async (res) => {
      if (res.data && res.data.role && res.data.role == 'ok') {
        this.opportunity.closedDate = new Date().getTime().toString();
        this.opportunity.stateCode = (id == 'closeaslost' || id == 'closeasusercanceled') ? 2 : 1;
        this.opportunity.state = this.opportunity.stateCode === 0 ? 'Open' : this.opportunity.stateCode === 1 ? 'Won' : 'Lost'
        this.opportunity.statusCode = id == 'closeaslost' ? 4 : (id == 'closeasusercanceled') ? 548910002 : 3;
        this.opportunity.status = this.opportunity.state;
        this.isReadOnlyMode = true;
        this.opportunity.pendingPushToDynamics = true;
        if (res.data && (res.data.inputs || res.data.selectedOtherItem)) {
          this.opportunity.closeDescriptionText = res.data.selectedOtherItem || '';
          this.opportunity.opportunityReasonID = res.data.inputs?.value;
          this.opportunity.opportunityReason = res.data.inputs?.name;
        } else {
          this.opportunity.closeDescriptionText = '';
          this.opportunity.opportunityReasonID = null;
          this.opportunity.opportunityReason = null;
        }
        this.uiService.displayLoader();
        await this.opportunityDataService.updateOpportunity({
          onDynamics: !this.device.isOffline, onLocalDatabase: true, onLocalCopy: true,
          operationDetail: { code: id, message: 'Update_opportunity_status' }
        }, [this.opportunity]).then(success => {
          this.uiService.dismissLoader();
          if (id == EventName.OPPORTUNITYSCRAPBUTTON) {
            this.onClosePage();
          }
          else this.initPageTitle();
        }).catch(async error => {
          // Handle error occured
          this.uiService.dismissLoader();
        });

      }

    });
    this._lookupPopover.present();
  }

  private async refreshOpportunity() {
    if (this.device.isOffline) return;
    await this.uiService.displayLoader();
    await this.opportunityDataService.getOpportunitiesData(false, this.opportunity.accountID, false, false, this.opportunity.ID);
    if (this.selectedOpportunity.opportunityTypeId && !this.device.isOffline) {
      const selectedOpportunityType = this.opportunityService.opportunityTypes.find(a => a.indskr_opportunitytypeid == this.selectedOpportunity.opportunityTypeId);
      if (selectedOpportunityType && selectedOpportunityType.surveyTemplateId) {
        const foundTemplate = this.surveyService.surveyForms.find(form => form.surveyFormId == selectedOpportunityType.surveyTemplateId);
        if (foundTemplate) {
          await this.opportunityDataService.fetchSurveyJSAtrributesForOpportunity(this.selectedOpportunity, foundTemplate);
          const idx = this.opportunityService.opportunities.findIndex(value => value.ID === this.selectedOpportunity.ID
            || (this.selectedOpportunity.offlineId
              && this.selectedOpportunity.offlineId == value.offlineId));
          if (idx >= 0) {
            this.opportunityService.opportunities[idx].surveyResponse = this.selectedOpportunity.surveyResponse;
            this.opportunityService.opportunities[idx].surveyResponseData = this.selectedOpportunity.surveyResponseData;
            this.opportunityService.opportunities[idx].surveyResponseAdditionalData = this.selectedOpportunity.surveyResponseAdditionalData;
            this.opportunityService.opportunities[idx].surveyResponseLookupData = this.selectedOpportunity.surveyResponseLookupData;
          }
        }
      }
    }
    this.opportunityDataService.isSelectedOpportunityUpdated = true;
    await this.uiService.dismissLoader();
    this.initPageTitle();
    this.initOpportunityDetails();
    this.refreshOpportunityEvent(this.opportunity);
  }

  private async handleOpportunityStatusChange(id) {
    this.opportunity = this.opportunityService.getOpportunityDetailsbyId(this.opportunity.ID);
    const captitalSalesType = this.opportunityService.opportunityTypes.find(ot => ot.indskr_description == 'CAPITAL_SALES');
    const isCapitalSalesType = this.opportunity.opportunityTypename == 'Capital Sales'
      || (captitalSalesType && captitalSalesType.indskr_opportunitytypeid == this.opportunity.opportunityTypeId);
    if (id === EventName.OPPORTUNITYMARKCONFIRMED && this.opportunity.stakeholders.length > 0
      && this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_OPTIONSET_FIELDS)
      && isCapitalSalesType) {
      await this.uiService.displayLoader();
      if (!await this.opportunityDataService.isCustomerAssessmentResponseAvailable(this.opportunity.stakeholders)) {
        await this.uiService.dismissLoader();
        this.notificationService.notify(this.translate.instant("FILL_PROCEDURE_ASSESSMENT"), ToastStyle.INFO);
        return;
      }
      await this.uiService.dismissLoader();
    }
    let alertOptions: {
      header?: string,
      cssClass?: string,
      message: string,
      inputs?: any[]
    }
    let altConfirmText: string;
    let altCancelText: string = this.translate.instant('CANCEL');
    switch (id) {
      case EventName.OPPORTUNITYSCRAPBUTTON:
        alertOptions = {
          header: this.translate.instant('OPPORTUNITY_MGMT_SCRAP_TITLE'),
          message: this.translate.instant('OPPORTUNITY_MGMT_SCRAP_MESSAGE')
        }
        altConfirmText = this.translate.instant('SCRAP')
        break;
      case EventName.OPPORTUNITYMARKCONFIRMED:
        alertOptions = {
          header: this.translate.instant('CONFIRM_OPPORTUNITY'),
          message: this.translate.instant('CONFIRM_OPPORTUNITY_SUB_TEXT'),
        }
        altConfirmText = this.translate.instant('CONFIRM')
        break;
    }
    this.alertService.showAlert(alertOptions, altConfirmText, altCancelText)
      .then(async (res) => {
        if (res.role == 'ok') {
          if (id == EventName.OPPORTUNITYSCRAPBUTTON) {
            this.opportunity.stateCode = 2;
            this.opportunity.state = 'Cancelled';
            this.opportunity.statusCode = 548910000;
            this.opportunity.status = this.opportunity.state;
            this.isReadOnlyMode = true;
            this.opportunity.opportunityReasonID = null;
          }
          else if (id == EventName.OPPORTUNITYMARKCONFIRMED) {
            this.opportunity.statusCode = 548910001;
          }
          else {
            this.opportunity.closeDescriptionText = res.data?.values?.description || '';
            this.opportunity.closedDate = new Date().getTime().toString();
            this.opportunity.stateCode = (id == 'closeaslost' || id == 'closeasusercanceled') ? 2 : 1;
            this.opportunity.state = this.opportunity.stateCode === 0 ? 'Open' : this.opportunity.stateCode === 1 ? 'Won' : 'Lost'
            this.opportunity.statusCode = id == 'closeaslost' ? 4 : (id == 'closeasusercanceled') ? 548910002 : 3;
            this.opportunity.status = this.opportunity.state;
            this.isReadOnlyMode = true;
          }
          this.opportunity.pendingPushToDynamics = true;
          this.uiService.displayLoader();
          if (id == EventName.OPPORTUNITYMARKCONFIRMED) {
            const selectedOpportunityType = this.opportunityService.opportunityTypes.find(a => a.indskr_opportunitytypeid == this.opportunity.opportunityTypeId);
            if (selectedOpportunityType && selectedOpportunityType.surveyTemplateId) {
              const foundTemplate = this.surveyService.surveyForms.find(form => form.surveyFormId == selectedOpportunityType.surveyTemplateId);
              if (foundTemplate) {
                await this.opportunityDataService.fetchSurveyJSAtrributesForOpportunity(this.opportunity, foundTemplate);
              }
            }
          }
          await this.opportunityDataService.updateOpportunity({
            onDynamics: !this.device.isOffline, onLocalDatabase: true, onLocalCopy: true,
            operationDetail: { code: id, message: 'Update_opportunity_status' }
          }, [this.opportunity]).then(success => {
            //Handle successfull response
            // Calculation not required on app side // Account Plan Actual Revenue Calculation For Offline View Details
            // if(this.opportunity.accountPlanID && id == EventName.OPPORTUNITYCLOSEASWON){
            //   this.accountManagementDataService.updateEstimatedAndActualRevenueOfAccountPlans();
            // }
            this.uiService.dismissLoader();
            if (id == EventName.OPPORTUNITYSCRAPBUTTON) {
              this.onClosePage();
            }
            else this.initPageTitle();
          }).catch(async error => {
            // Handle error occured
            this.uiService.dismissLoader();
          });
        }

      })
  }


  private fetchOpportunityReasonsWon() {
    const opportunityReasonWon = this.opportunityService.opportunityReasons.filter(res => res.indskr_statusreason === "Won" && res.indskr_opportunitytypeid == this.opportunity.opportunityTypeId);
    if (_.isEmpty(opportunityReasonWon)) return []
    return opportunityReasonWon.map(res => {
      return {
        type: 'radio',
        name: res.indskr_reason,
        label: res.indskr_reason,
        value: res.indskr_opportunityreasonid
      }
    })
  }

  private fetchOpportunityReasonsLost() {
    const opportunityReasonLost = this.opportunityService.opportunityReasons.filter(res => res.indskr_statusreason === "Canceled" && res.indskr_opportunitytypeid == this.opportunity.opportunityTypeId);
    if (_.isEmpty(opportunityReasonLost)) return []
    return opportunityReasonLost.map(res => {
      return {
        type: 'radio',
        name: res.indskr_reason,
        label: res.indskr_reason,
        value: res.indskr_opportunityreasonid
      }
    })
  }
  private fetchOpportunityReasonsDiscontinue() {
    const opportunityReasonDiscontinue = this.opportunityService.opportunityReasons.filter(res => res.indskr_statusreason === "Discontinued" && res.indskr_opportunitytypeid == this.opportunity.opportunityTypeId);
    if (_.isEmpty(opportunityReasonDiscontinue)) return []
    return opportunityReasonDiscontinue.map(res => {
      return {
        type: 'radio',
        name: res.indskr_reason,
        label: res.indskr_reason,
        value: res.indskr_opportunityreasonid
      }
    })
  }

}
