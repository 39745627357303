
import { takeUntil } from 'rxjs/operators';
import { Contact } from './../../classes/contact/contact.class';
import { ContentMatchingService } from './../../services/content-matching/content-matching.service';
import { AppointmentActivity } from './../../classes/activity/appointment.activity.class';
import { MeetingDataService, UpdateMeetingPayload } from './../../data-services/meeting/meeting.data.service';
import { ContactOfflineService } from './../../services/contact/contact.service';
import { EventsService } from './../../services/events/events.service';

import { IonNav, IonContent } from '@ionic/angular';
import { Events } from '@omni/events';
import { DeviceService } from './../../services/device/device.service';
import { ContactPageComponent } from '../contact/contact-page/contact-page';

import { MyAssistantDataService, ConversationResult, CreateMeetingType } from './../../services/my-assistant/my-assistant.data.service';
import { Component, ViewChild, HostListener, ChangeDetectorRef, ElementRef, Injector } from '@angular/core';
import { MyAssistantService } from '../../services/my-assistant/my-assistant.service';
import { format, isPast, isTomorrow, isFuture, isToday, isYesterday, addMinutes } from "date-fns";
import { NavigationService, PageName } from '../../services/navigation/navigation.service';
import { AuthenticationService } from '../../services/authentication.service';
import { RepServices } from '../../data-services/rep/rep.services';
import { FooterService, FooterViews } from '../../services/footer/footer.service';
import { NavParams, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import * as AdaptiveCards from "adaptivecards";
import { SpeechRecognitionListeningOptionsAndroid } from '@ionic-native/speech-recognition/ngx';
import { DomSanitizer } from '@angular/platform-browser';
import { TextToSpeech } from '@ionic-native/text-to-speech/ngx';
import { FeatureActionsMap } from '../../classes/authentication/user.class';
import { Subject, from } from 'rxjs';
import { DateTimeFormatsService } from "../../services/date-time-formats/date-time-formats.service";
import { ActivityService } from "../../services/activity/activity.service";
import { UIService, PresentationView } from "../../services/ui/ui.service";
import { ActivityType, Activity } from "../../classes/activity/activity.class";
import { ActivityDataService } from './../../data-services/activity/activity.service';
import { Geolocation } from "@ionic-native/geolocation/ngx";
import _, { isEmpty } from 'lodash';
import { InitiateMeetingPayload } from '../../data-services/meeting/meeting.data.service';
import { OFFLINE_ID_PREFIX } from '../../classes/activity/appointment.activity.class';
import { DistanceUnits } from '../../classes/my-assistant/my-assistant.class';
import { GeneeInfoComponent } from './genee-info/genee-info';
import { GeneeNotificationComponent } from './genee-notification/genee-notification';
import { ModalController } from '@ionic/angular';
import { PresentationService } from '../../services/presentation/presentation.service';
import { Presentation } from '../../classes/presentation/presentation.class';
import { Resource } from '../../classes/resource/resource.class';
import { PresentationPageComponent } from '../../pages/presentation/presentation';
import { TrackService, TrackingEventNames, TrackingGeneeEventProperties } from '../../services/logging/tracking.service';
import { EmailService } from '../../services/email-templates/email.service';
import { EmailViewType, EmailAddress, EmailActivityParty } from '../../classes/activity/email.activity.class';
import { Cordova, IonicNativePlugin, Plugin } from '@ionic-native/core';
import { trigger, transition, animate, style, state } from "@angular/animations";
import { MeetingStructureService } from '@omni/services/meeting-structure/meeting-structure.service';
import { AgendaFooterService, AgendaFooterView } from './../../services/footer/agenda-footer.service';
import { Account } from '@omni/classes/account/account.class';
import { ActivitiesDetailsPaneComponent } from '../activity/activities-details-pane/activities-details-pane';
import { EmailActivityDetailComponent } from '../activity/email-activity-detail/email-activity-detail';
import { CoachingComponent } from '../coaching/coaching-home/coaching-home';
import { EventsToolPageComponent } from '../events-tool/event-tool-page/events-tools-page';
import { EventsToolService } from '@omni/services/events-tool/events-tool.service';

const scrollOffset = 150

/**
 * Generated class for the MyAccountComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'my-assistant[base-page]',
  templateUrl: 'my-assistant.html',
  styleUrls: ['my-assistant.scss'],
  animations: [
    trigger("fadeInAndOut", [
      transition(":enter", [
        style({ opacity: 0 }),
        animate("200ms", style({ opacity: 1 }))
      ]),
      transition(":leave", [animate("200ms", style({ opacity: 0 }))])
    ]),
    trigger('slideUpGenee', [
      // route 'enter' transition
      transition('void => *', [
        // css styles at start of transition
        style({ transform: 'translateY(30px)', opacity: 0 }),
        // animation and styles at end of transition
        animate('200ms ease-in', style({ transform: 'translateY(0)', opacity: 1 }))
      ])
    ])
  ],
  host: { '[@slideUpGenee]': '' }
})

export class MyAssistantComponent {
  GroupedData: any;
  noDataToDisplay: boolean = true;
  assitantViewType: string = 'KPIAssistantKeyboard';
  userName: string = this.authService.user.displayName;
  userId = this.authService.user.systemUserID;
  speechList: Array<string>;
  androidOptions: SpeechRecognitionListeningOptionsAndroid;
  finalText: string;
  isGeneeSpeaking: boolean;
  adaptiveCardHtml: any;
  isRecording: boolean;
  stack = [];
  auth: ConversationResult = new ConversationResult();
  userInitials: string;
  @ViewChild('chatScroll', { static: false }) chatScroll: IonContent;
  //@ViewChild('widgetsContent', {static:true}) widgetsContent: ElementRef;
  hasKPIEnabled: boolean;
  showOfflineMsg: boolean = false;
  $ngDestroy: any = new Subject<boolean>();
  meetingActivity: Activity;
  activityType: ActivityType;
  selected: Account;
  nearByCustomerEnabled: boolean = false;
  suggestionsForEngagementResposne: { value: string, title: string }[] = [{ value: 'Schedule meeting', title: 'Schedule meeting' },{ value: 'Send an email', title: 'Send an email' }];
  geneeInfoJSON: any = '';
  geneeNotificationJSON: any = '';
  isSpeakerMuted = false;
  @ViewChild('scrollableContent', { read: ElementRef }) contentChild: ElementRef;
  showRightButton = false;
  showLeftButton = false;
  @ViewChild('leftButton', { read: ElementRef }) leftButton: ElementRef;
  scrollElementsOffsetLeft: number;
  navCtrl: IonNav;
  private speechSubscription;
  private scrollThisElementTimeInterval: number = 100;
  private scrollToLastMessageTimeInterval: number = 200;
  private directLineSubscription;
  private featureActionAvailable = false;

  constructor(private injector: Injector,
    public repService: RepServices,
    public assistantService: MyAssistantService,
    private navService: NavigationService,
    private modalCtrl: ModalController,
    private authService: AuthenticationService,
    private footerService: FooterService,
    public translate: TranslateService,
    private sanitizer: DomSanitizer,
    private tts: TextToSpeech,
    public myAssistantDataService: MyAssistantDataService,
    private platform: Platform,
    private device: DeviceService,
    private events: EventsService,
    public _cd: ChangeDetectorRef,
    public dateTimeFormatsService: DateTimeFormatsService,
    public activityService: ActivityService,
    public activityDataService: ActivityDataService,
    public uiService: UIService,
    public geoLocation: Geolocation,
    public contactService: ContactOfflineService,
    public meetingService: MeetingDataService,
    public contentMatchService: ContentMatchingService,
    public meetingDataService: MeetingDataService,
    public presentationService: PresentationService,
    private trackingService: TrackService,
    private emailService: EmailService,
    private meetingStructureService: MeetingStructureService,
    private agendaFooterService: AgendaFooterService,
    private eventsToolService: EventsToolService
  ) {
    console.log('Hello MyAccountComponent Component');
    const userNames = this.userName.split(' ');
    this.userInitials = userNames[0].charAt(0) + userNames[userNames.length - 1].charAt(0);
    this.nearByCustomerEnabled = this.authService.hasFeatureAction(FeatureActionsMap.CUSTOMER_NEAR_ME);
  }

  private scrollToLastMessage() {
    // console.warn('scrollToLastMessage');

    // const length = this.myAssistantDataService.geneeBotMessages.length;
    // if (length > 0) {
    //   const lastSavedChatMessage = this.myAssistantDataService.geneeBotMessages[length - 1];
    //   const elmnt = document.getElementById(`${lastSavedChatMessage.id}`);
    //   if (elmnt) elmnt.scrollIntoView();
    // }
    // this.chatScroll.scrollToBottom(0);
    this.scrollTo('bottom');
  }

  private scrollThisElement(element: HTMLElement) {
    // console.warn('scrollThisElement');

    const length = this.myAssistantDataService.geneeBotMessages.length;
    if (length > 0) {
      const lastChatMessage = this.myAssistantDataService.geneeBotMessages[length - 1];
      let lastChatMessageIsFocusable = lastChatMessage.isFocusable ?? false;
      //
      if (element) {
        let elementId = element.id;
        let isAdaptiveCard = false;
        if (elementId) {
          if (elementId.indexOf('-presID') > -1 || elementId.indexOf('-ContactsNearBy') > -1 || elementId.indexOf('-Polls') > -1) {
            isAdaptiveCard = true;
          } else if (lastChatMessage.contentType && lastChatMessage.contentType === 'adaptive') {
            isAdaptiveCard = true;
          } else {
            isAdaptiveCard = lastChatMessage.isFocusable ?? false;
          }
        }
        if (isAdaptiveCard) {
          setTimeout(() => { this.chatScroll.scrollToPoint(0, element.offsetTop, 1000); }, 200);
        } else {
          if (length > 1) {
            const secondLastChatMessage = this.myAssistantDataService.geneeBotMessages[length - 2];
            let secondLastChatIsFocusable = secondLastChatMessage.isFocusable ?? false;
            if (!secondLastChatIsFocusable) {
              setTimeout(() => { this.scrollToLastMessage(); }, 500);
            } else if (this.myAssistantDataService.isGeneeRelaunched) {
              this.myAssistantDataService.isGeneeRelaunched = false;
              setTimeout(() => { this.scrollToLastMessage(); }, 500);
            } else {
              if (secondLastChatMessage.from != 'UIPresenations' && secondLastChatMessage.from != 'UIContactsSearch' && secondLastChatMessage.from != 'UIPollsSearch') {
                if (secondLastChatIsFocusable && !lastChatMessageIsFocusable) {
                  // 2nd last message is focusable.
                  // Help >> What is Omnipresence? >> returning two response messages in a shortest duration
                  // Causing lost focus on the second last card.
                  if (secondLastChatMessage.id) {
                    let secondLastElement = document.getElementById(secondLastChatMessage.id);
                    if (secondLastElement) {
                      setTimeout(() => { this.chatScroll.scrollToPoint(0, secondLastElement.offsetTop, 2000); }, 1000);
                    }
                  }
                }
              }
            }
          } else {
            setTimeout(() => { this.scrollToLastMessage(); }, 1000);
          }
        }
      }
      //
    }
  }

  private scrollTo(where: string) {
    if (where === 'bottom') {
      this.chatScroll.scrollToBottom(500);
    } else {
      this.chatScroll.scrollToTop(500);
    }
  }

  async loadSavedRenderedCards() {
    if (this.myAssistantDataService.renderedCardMap.size > 0) {
      setTimeout(() => {
        //
        const length = this.myAssistantDataService.geneeBotMessages.length;
        if (length > 0) {
          this.myAssistantDataService.renderedCardMap.forEach((renderedCard, id) => {
            const element = document.getElementById(id);
            if (element) {
              element.appendChild(renderedCard);
            }
          });
          this.scrollToLastMessage();
        }
        //
      }, 500);
    }
  }

  private isFocusableCard(message): boolean {
    let isCardFocusable = false;
    if (message && message.attachments && message.attachments.length) {
      let attachments = message['attachments'][0];
      if (attachments.contentType == 'application/vnd.microsoft.card.adaptive') {
        isCardFocusable = true;
      } else if (attachments.contentType == 'application/vnd.microsoft.card.hero') {
        isCardFocusable = true;
      } else if (attachments.contentType == 'application/json') {
        isCardFocusable = true;
      }
    } else if (message["inputHint"] && message["inputHint"] === 'scrollMessage') {
      isCardFocusable = true;
    } else if (message["inputHint"] && message["inputHint"] === 'qnaCard') {
      isCardFocusable = true;
    }
    return isCardFocusable;
  }

  ngAfterViewInit() {

  }

  private initialHeightOfGeneeIllust = 0;

  private getGeneeIllustHeight(setGeneeIllustHeightTimeInterval:number) {
    setTimeout(timer => {
      const genee_illust = document.getElementById('genee-illust');
      if (genee_illust) {
        this.initialHeightOfGeneeIllust = genee_illust.clientHeight * 3;
      }
    }, setGeneeIllustHeightTimeInterval);
  }

  ngOnInit() {

    if (!this.myAssistantDataService.isGeneeInitialized) {
      this.getGeneeIllustHeight(2000);
    }
    else {
      this.getGeneeIllustHeight(10);
      setTimeout(() => {
        this.scrollToLastMessage();
      }, 500);
    }

    this.keepListeningForSpeech();

    let isGeneeSpeakerMuted = localStorage.getItem("isGeneeSpeakerMuted");

    if (isGeneeSpeakerMuted === "true") {
      this.isSpeakerMuted = true;
      this.myAssistantDataService.isSpeakerMuted = true;
    } else {
      this.isSpeakerMuted = false;
      this.myAssistantDataService.isSpeakerMuted = false;
    }

    this.trackingService.tracking(TrackingGeneeEventProperties.GENEE_START, TrackingEventNames.GENEE);

    this.featureActionAvailable = this.authService.hasFeatureAction(FeatureActionsMap.KPI_ASSISTANT);
    if (!this.device.isOffline) {

      this.loadSavedRenderedCards();

      this.hasKPIEnabled = true;

      //To check Feature action `KPI Assistant` is avialable
      if (this.featureActionAvailable) {
        /* To check if Genee bot is not Initialized
        If yes then initializeGeneeBotConnection
        else resumeGeneeBotConnection
        */
        if (!this.myAssistantDataService.isGeneeInitialized) {
          this.directLineSubscription = this.myAssistantDataService.initializeGeneeBotConnection().subscribe((activity) => {
            this.processBotActivity(activity);
          }, error => {
            console.log(error);
            this.myAssistantDataService.showTypingLoader = false;
          });
        }
        else {
          if (this.myAssistantDataService.suggestedActions.length === 0) {
            this.myAssistantDataService.suggestedActions = this.myAssistantDataService.suggestionsToRemember.slice();
          }
          setTimeout(() => {
            this.setScrollArrows();
          }, 100);

          this.directLineSubscription = this.myAssistantDataService.resumeGeneeBotConnection().subscribe((activity) => {
            this.processBotActivity(activity);
          }, error => {
            console.log(error);
            this.myAssistantDataService.showTypingLoader = false;
          });
        }
      } else {
        const notificationObj = { type: "imBack", title: "Notification", value: "Notification", isNotification: true };
        this.myAssistantDataService.suggestedActions = [];
        this.myAssistantDataService.suggestedActions.push(notificationObj);
      }
    } else if (this.device.isOffline) {
      this.hasKPIEnabled = true;
      this.assitantViewType = 'KPIAssistantMic';
      const textMessage = 'You are currently offline. I am only available when online';
      this.sayText(textMessage.replace(/[**]/g, ""));
      this.showOfflineMsg = true;
    } else {
      this.hasKPIEnabled = false;
      this.assitantViewType = 'notifications';
      this.getGroupedData();
    }

    this.events.observe('device:deviceIsOffline').pipe(takeUntil(this.$ngDestroy)).subscribe(() => {
      if (this.assitantViewType !== 'notifications') {
        const textMessage = 'You are currently offline. I am only available when online';
        this.sayText(textMessage.replace(/[**]/g, ""));
        this.showOfflineMsg = true;
      }
      else {
        this.showOfflineMsg = false;
      }
    });
    this.events.observe('device:deviceIsOnline').pipe(takeUntil(this.$ngDestroy)).subscribe(() => {
      this.showOfflineMsg = false;
    });
  }

  ngOnDestroy() {
    if (this.speechSubscription) {
      this.speechSubscription.unsubscribe();
    }
    if (this.directLineSubscription) {
      this.directLineSubscription.unsubscribe();
    }
  }
  /**
  * This method helps to check scrollWidth and clientWidth
  *
  * @memberOf MyAssistantComponent
  */
  setScrollArrows() {
    if (this.myAssistantDataService.suggestedActions.length) {
      if (this.contentChild) {
        if ((this.contentChild.nativeElement as HTMLElement).clientWidth === 0) {
          setTimeout(() => {
            this.setScrollArrows();
          }, 100);
        }
        this.showRightButton = false;
        if ((this.contentChild.nativeElement as HTMLElement).scrollWidth > (this.contentChild.nativeElement as HTMLElement).clientWidth) {
          this.showLeftButton = false;
          this.showRightButton = true;
        }
      }
    }
  }

  /**
  * In this method we make left arrow visible according to the scroll width
  *
  * @memberOf MyAssistantComponent
  */
  scrollLeft() {
    this.showRightButton = true;
    if (scrollOffset >= (this.contentChild.nativeElement as HTMLElement).scrollLeft) {
      (this.contentChild.nativeElement as HTMLElement).scrollLeft = 0;
      this.showRightButton = true;
      this.showLeftButton = false;
    }
    else {
      (this.contentChild.nativeElement as HTMLElement).scrollLeft -= scrollOffset;
    }
  }
  /**
    * In this method we make right arrow visible according to the scroll width
    *
    * @memberOf MyAssistantComponent
    */
  scrollRight() {
    this.showLeftButton = true;
    if (Math.ceil((this.contentChild.nativeElement as HTMLElement).scrollLeft) + (this.contentChild.nativeElement as HTMLElement).clientWidth + scrollOffset >=
      (this.contentChild.nativeElement as HTMLElement).scrollWidth) {
      (this.contentChild.nativeElement as HTMLElement).scrollLeft = (this.contentChild.nativeElement as HTMLElement).scrollWidth;
      this.showRightButton = false;
      this.showLeftButton = true;
    }
    else {
      (this.contentChild.nativeElement as HTMLElement).scrollLeft += scrollOffset;
    }
  }

  processBotActivity(activity) {
    console.log('Bot Activity Type:', activity.type);
    switch (activity.type) {
      case 'message':
        const message = activity;
        //check if msg is from bot side and not user side
        if (message.from.name && message.from.name !== this.userName) {
          this.myAssistantDataService.showTypingLoader = false;
          //adaptive card type msg
          if (message['attachments'] && message['attachments'].length > 0) {
            this.processAdaptiveCardTypeMessage(message['attachments'][0], message);
          }
          if (message['suggestedActions']) {
            console.log(`Message inputHint - ${message['inputHint']}`);

            this.myAssistantDataService.suggestedActions = message['suggestedActions'].actions;
            console.log(`SuggestedActions length - ${this.myAssistantDataService.suggestedActions.length}`);

            if (message['inputHint'] && message['inputHint'] == 'suggestionActionsForCustomers' && this.nearByCustomerEnabled) {
              if (this.myAssistantDataService.suggestedActions.length > 0) this.myAssistantDataService.suggestedActions.splice(1, 0, { value: 'Customers near me', title: 'Near me' });
            }
            if (message['inputHint'] && (message['inputHint'] == 'suggestionActionsForSkills' || message['inputHint'] == 'suggestionActionsForWelcome') && this.presentationService.presentation.length > 0) {
              this.myAssistantDataService.suggestedActions.splice(this.myAssistantDataService.suggestedActions.length, 0, { value: 'Presentations', title: 'Presentations' });
            }
            //Add Notification object at the array’s 0th position.
            var notificationObj = { type: "imBack", title: "Notification", value: "Notification", isNotification: true };
            let suggePill = this.myAssistantDataService.suggestedActions.find((action) => {
              return ((action["value"] == 'Survey' || action["value"] == 'KPIs' || action["value"] == 'Activities' || action["value"] == 'List'));
            })
            if (suggePill) {
              this.myAssistantDataService.suggestedActions.unshift(notificationObj);
            }

            const textMessage = message['text'];
            //Check if genee feature action is available then notifications will be visible with other pills
            if (textMessage && this.featureActionAvailable) {
              //Check added if speak attribute is present for suggestedActions then exec SayText
              if (message.speak) {
                this.sayText(message.speak.replace(/[**]/g, ""));
              }
              this.myAssistantDataService.geneeBotMessages.push({
                id: message.id,
                from: message.from.id,
                text: textMessage.replace(/[**]/g, ""), //Replace \n with <b> for web version
                cards: null,
                timestamp: message.timestamp,
                localTimestamp: message['localTimestamp'],
                isFocusable: this.isFocusableCard(message)
              });
              this.myAssistantDataService.populate();

              setTimeout(() => {
                // Bot responses should  be in focus mode
                const elmnt = document.getElementById(`${message.id}`);
                this.scrollThisElement(elmnt);
              }, this.scrollThisElementTimeInterval);

            }

            if (message['inputHint'] == 'suggestionActionsForWelcome') {
              this.myAssistantDataService.isGeneeInitialized = true;
              this.myAssistantDataService.existingConversationId = message.conversation.id;
              // console.warn(`existingConversationId: ${this.myAssistantDataService.existingConversationId}`);
            }
          }
          else if (message['text']) {
            const textMessage = message['text'];
            let speakMessage = textMessage;
            if (message['speak']) {
              speakMessage = message['speak'];
              this.sayText(speakMessage.replace(/[**]/g, ""));
            }

            this.myAssistantDataService.geneeBotMessages.push({
              id: message.id,
              from: message.from.id,
              text: textMessage.replace(/[**]/g, ""), //Replace \n with <b> for web version
              cards: null,
              timestamp: message.timestamp,
              localTimestamp: message['localTimestamp'],
              isFocusable: this.isFocusableCard(message)
            });
            this.myAssistantDataService.populate();

            setTimeout(() => {
              // Bot responses should  be in focus mode
              const elmnt = document.getElementById(`${message.id}`);
              this.scrollThisElement(elmnt);
            }, this.scrollThisElementTimeInterval);

          }
        }
        break;

      case 'typing':
        this.myAssistantDataService.showTypingLoader = true;
        this.scrollToLastMessage();
        break;

      case 'event':
        const eventName = activity.name;
        console.log(`Event name - ${eventName}`);

        //As a user when I say or type different variations for view presentations(show me my presentations, show me the list of presentations, view my presentations,
        // presentations list, list of presentations, my presentations, presentations), the list of presentations should show up.
        if (eventName === 'View Presentations') {
          this.getViewPresentations();
        }
        this.myAssistantDataService.showTypingLoader = false;
        break;

      default:
        console.log(`Unknown ActivityType - ${activity}`);
        this.myAssistantDataService.showTypingLoader = false;
        break;
    }
    setTimeout(() => {
      this.setScrollArrows();
    }, 100);
  }


  processAdaptiveCardTypeMessage(attachments, message) {
    if (attachments.contentType == 'application/vnd.microsoft.card.adaptive') {
      console.warn(`Attachments.contentType - adaptive`);
      let card = attachments['content'];
      if (card['speak']) {
        this.sayText(card.speak);
      }
      let adaptiveCard = new AdaptiveCards.AdaptiveCard();
      adaptiveCard.hostConfig = new AdaptiveCards.HostConfig({
        fontFamily: "Segoe UI, Helvetica Neue, sans-serif"
      });
      adaptiveCard.onExecuteAction = (action: AdaptiveCards.Action) => {
        if (action instanceof AdaptiveCards.SubmitAction) {
          this.myAssistantDataService.createMeetingType = CreateMeetingType.other;
          const hasUserFeedback = action.data["userFeedback"];
          switch (hasUserFeedback) {
            case 'positive':
              this.trackingService.tracking(TrackingGeneeEventProperties.GENEE_FEEDBACK_THUMBS_UP, TrackingEventNames.GENEE);
              break;
            case 'negative':
              this.trackingService.tracking(TrackingGeneeEventProperties.GENEE_FEEDBACK_THUMBS_DOWN, TrackingEventNames.GENEE);
              break;
          }
          const hasOtherSuggestion = action.data["otherSuggestion"];
          const hasUserExperience = action.data["userExperience"];
          const hasMeetingId = action.data["meetingId"];
          const hasGeneeSurvey = action.data["geneeSurvey"];
          const hasCustomerId = action.data["customerId"];
          const hasActionName = action.data['name'];
          const hasCoachingId = action.data['coachingId'];
          const hasEventId = action.data['eventId'];

          switch (this.lastSelectedAction) {
            case 'Incomplete meetings':
              this.trackingService.tracking(TrackingGeneeEventProperties.GENEE_CLICK_ON_INCOMPLETE_MEETINGG, TrackingEventNames.GENEE);
              break;
            case 'Customers':
              this.trackingService.tracking(TrackingGeneeEventProperties.GENEE_CUSTOMER_LIST_CUSTOMER_CLICK, TrackingEventNames.GENEE);
              break;
          }

          if (hasMeetingId) {
            // this.events.publish('hideNewActivityScreen');
            let foundActivity = this.getActivity(hasMeetingId);
            if (this.navService.getCurrentPageName() != PageName.ActivitiesPageComponent) {
              this.navService.popWithPageTracking();
            }
            this.activityService.selectedActivityOnGenee = foundActivity;
            if (this.uiService.activitiesPageTab != 'agenda') {
              this.uiService.setAgendaTab('agenda');
              this.uiService.activitiesPageTab = 'agenda';
            } else if (this.agendaFooterService.actSegment != 'agenda') {
              this.agendaFooterService.initButtons(AgendaFooterView.Act);
            }
            this.uiService.showNewActivity = false;
            this.activityService.selected = foundActivity;
            this.activityService.selectedActivityOnAgenda = this.activityService.selectedActivity;
            this.activityDataService.updateActivityDetails(foundActivity);
            if (this.device.shouldBeMobileView) {
              this.uiService.showRightPane = true;
            }
            this.navService.setChildNavRoot(ActivitiesDetailsPaneComponent, PageName.ActivitiesDetailsPaneComponent, PageName.ActivitiesPageComponent);
            this.footerService.initButtons(FooterViews.Activities);
            this.closeTools();
          }

          if (hasCoachingId) {
              const isTeamCallPlan: boolean = action.data['forTeam'];
              this.goToCoaching(hasCoachingId.toLowerCase(), isTeamCallPlan);
          }

          if (hasEventId) {
            this.goToEvents(hasEventId.toLowerCase());
          }

          if (hasUserFeedback) {
            if (hasUserFeedback === 'positive') {
              this.myAssistantDataService.geneeBotMessages.push({
                from: this.userName,
                text: null,
                cards: null,
                feedback: 'positive'
              });
              this.myAssistantDataService.populate();
            }
            else {
              this.myAssistantDataService.geneeBotMessages.push({
                from: this.userName,
                text: null,
                cards: null,
                feedback: 'negative'
              });
              this.myAssistantDataService.populate();
            }
            //Submit action to Bot Service
            this.sendAction(action.data);
          }

          if (hasGeneeSurvey || hasOtherSuggestion || hasUserExperience) {
            //Submit action to Bot Service
            this.sendAction(action.data);
          }

          if (hasCustomerId || hasActionName == 'showMoreForCustomers') {
            //this.trackingService.tracking(TrackingGeneeEventProperties.GENEE_SHOW_MORE, TrackingEventNames.GENEE);
            //redirect to customer detail/list
            this.goToCustomers(hasCustomerId ? hasCustomerId.toLowerCase() : undefined);
          }
        }
      };

      adaptiveCard.parse(card);
      let renderedCard = adaptiveCard.render();
      var elementHtml = renderedCard.outerHTML;
      this.adaptiveCardHtml = this.sanitizer.bypassSecurityTrustHtml(elementHtml)
      this.myAssistantDataService.geneeBotMessages.push({
        id: message.id,
        from: message.from.id,
        text: null,
        hasAttachments: true,
        contentType: 'adaptive',
        cards: this.adaptiveCardHtml,
        timestamp: message.timestamp,
        localTimestamp: message['localTimestamp'],
        isFocusable: this.isFocusableCard(message)
      });
      this.myAssistantDataService.populate();
      setTimeout(() => {
        renderedCard.className = 'description-container-for-genee-box adaptive-card-for-genee-box';
        this.myAssistantDataService.renderedCardMap.set(`${message.id}`, renderedCard);
        document.getElementById(`${message.id}`).appendChild(renderedCard);
        // this.scrollToLastMessage();
        // this code is Added for Adaptive card to focus on Start of the card
        var elmnt = document.getElementById(`${message.id}`);
        this.scrollThisElement(elmnt);
      }, this.scrollThisElementTimeInterval)
    }

    else if (attachments.contentType == 'application/vnd.microsoft.card.hero') {
      console.warn(`Attachments.contentType - hero`);
      let heroCard = attachments['content'];

      if (heroCard['text']) {
        const textMessage = heroCard['text'];

        let adaptiveContent = {
          type: "AdaptiveCard",
          version: "1.0",
          speak: heroCard['text'],
          body: []
        }

        adaptiveContent.body.push(
          {
            type: "TextBlock",
            text: textMessage,
            wrap: true
          }
        );

        if (heroCard['buttons']) {
          const buttons = heroCard['buttons'];

          buttons.forEach((button, index) => {
            let btnTitle = button['text'];
            let btnValue = button['value'];

            const submitValue = {
              text: btnTitle,
              value: btnValue
            };

            let columnData = {
              horizontalAlignment: "center",
              type: "ColumnSet",
              columns: [
                {
                  minHeight: "30px",
                  type: "Column",
                  width: "auto",
                  separator: true,
                  items: [
                    {
                      type: "ActionSet",
                      actions: [
                        {
                          title: btnTitle,
                          type: "Action.Submit",
                          "data": submitValue
                        }
                      ]
                    }
                  ]
                }
              ]
            }

            adaptiveContent.body.push(columnData);
          })
        }

        let card = adaptiveContent;
        if (card['speak']) {
          this.sayText(card.speak);
        }
        let adaptiveCard = new AdaptiveCards.AdaptiveCard();
        adaptiveCard.hostConfig = new AdaptiveCards.HostConfig({
          fontFamily: "Segoe UI, Helvetica Neue, sans-serif"
        });
        // added click action for each prsenation names
        adaptiveCard.onExecuteAction = (action: AdaptiveCards.Action) => {
          if (action instanceof AdaptiveCards.SubmitAction) {
            this.myAssistantDataService.createMeetingType = CreateMeetingType.other;
            const hasQnaId = action.data["value"];

            if (hasQnaId) {
              //Submit action to Bot Service
              this.sendQnAAction(hasQnaId, action.data["text"]);
            }
          }
        };
        adaptiveCard.parse(card);
        let renderedCard = adaptiveCard.render();
        var elementHtml = renderedCard.outerHTML;
        this.adaptiveCardHtml = this.sanitizer.bypassSecurityTrustHtml(elementHtml)
        this.myAssistantDataService.geneeBotMessages.push({
          id: message.id,
          from: message.from.id,
          text: null,
          hasAttachments: true,
          contentType: 'adaptive',
          cards: this.adaptiveCardHtml,
          timestamp: message.timestamp,
          localTimestamp: message['localTimestamp'],
          isFocusable: this.isFocusableCard(message)
        });
        this.myAssistantDataService.populate();
        // this._cd.detectChanges();
        setTimeout(() => {
          renderedCard.className = 'description-container-for-genee-box adaptive-card-for-genee-box';
          this.myAssistantDataService.renderedCardMap.set(`${message.id}`, renderedCard);
          document.getElementById(`${message.id}`).appendChild(renderedCard);
          // this.scrollToLastMessage();
          // this code is Added for Adaptive card to focus on Start of the card
          var elmnt = document.getElementById(`${message.id}`);
          this.scrollThisElement(elmnt);
        }, this.scrollThisElementTimeInterval)
      }
    }
    else if (attachments.contentType = 'application/json') {
      if (attachments.name === 'Customers Not Met') {
        // When only 'Customers Not Met' command given, we would get contentUrl as `null` string.
        if (attachments.contentUrl === 'null') {
          this.createCustomersAdaptiveCard(attachments['content'], this.myAssistantDataService.suggestedActions.slice());
        } else if (attachments.contentUrl !== 'null') {
          this.createCustomersNotMetByCard(attachments['content'], this.myAssistantDataService.suggestedActions.slice(), attachments.contentUrl);
        }
      } else if (attachments.name === 'Active Polls') {
        this.createPollsAdaptiveCard(attachments['content'], this.myAssistantDataService.suggestedActions.slice());
      } else if (attachments.name === 'Customers Not Surveyed') {
        this.createCustomersNotSurveyedAdaptiveCard(attachments['content'], this.myAssistantDataService.suggestedActions.slice());
      } else if (attachments.name === 'Genee Info') {
        this.geneeInfoJSON = attachments['content'];
      } else {
        console.warn(`Unknown Attachment name`);
        console.debug(attachments.name);
      }
    }
    else {
      console.warn('unknown Attachment of activity received');
      console.debug(attachments.contentType);
    }
  }

  //this method is for clicking on customer name it should navigate to contacts screen and open up the detail of contact by id passed
  goToCustomers(customerId: string) {
    this.closeTools();
    this.navService.pushWithPageTracking(
      ContactPageComponent,
      PageName.ContactPageComponent
      , { customerDeatilsPreview: customerId ? true : false, customerId: customerId }
    );
  }

  private async checkToPopFromTheCoachingInTheNavFlow() {
    if (this.navService.getCurrentPageName() === PageName.CoachingComponent) {
      this.navService.popWithPageTracking();
    }
  }

  async goToCoaching(coachingId: string, isTeamCoaching: boolean) {
    await this.checkToPopFromTheCoachingInTheNavFlow();
    this.closeTools();
    this.navService.pushWithPageTracking(
      CoachingComponent,
      PageName.CoachingComponent
      , { coachingId: coachingId, isTeamCoaching: isTeamCoaching, isOpenCoachingFromGenee: true }
    );
  }

  async goToEvents(eventId: string) {
    this.uiService.displayLoader();
    await this.eventsToolService.initEventsToolData().then(()=> {
      this.closeTools();
      this.navService.pushWithPageTracking(EventsToolPageComponent, PageName.EventsToolPageComponent, { eventId: eventId, isOpenEventFromGenee: true }, PageName.EventsToolPageComponent);
      this.uiService.dismissLoader();
    });
  }

  @HostListener('window:click', ['$event.target'])
  onClick(targetElement: string) {
    if (this.isRecording) this.myAssistantDataService.stopSpeechRecording();
    this.isRecording = false;
    this._cd.detectChanges();
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'ArrowDown') {
      this.scrollTo('bottom');
    }
    else if (event.key === 'ArrowUp') {
      this.scrollTo('top');
    }
  }

  getGroupedData() {
    this.assistantService.getGroupedDataByMonth('My Assistant').then((data) => {
      if (data.length && data.length > 0) {
        this.noDataToDisplay = false;
        this.GroupedData = data;

      }
      else {
        this.noDataToDisplay = true;
        this.GroupedData = [];
      }
      console.log(this.GroupedData)
    })
  }

  onInputChange(ev) {
    this.getGroupedData();
  }

  closeTools() {
    this.myAssistantDataService.isGeneeRelaunched = true;
    if (this.footerService.samplingInModalViewActive) {
      let navOptions = {
        progressAnimation: false,
      }
      if (!this.navCtrl) {
        this.navCtrl = this.injector.get(IonNav)
      }
      this.navCtrl.pop(navOptions);
      return;
    }
    else {
      this.modalCtrl.dismiss();
    }
    this.myAssistantDataService.endKPIBotConnection();
    this.myAssistantDataService.updateChatHistory();
    this.myAssistantDataService.stopSpeechRecording();
    this.isRecording = false;
    this.stack = [];
    //this.navService.popWithPageTracking();
    this.$ngDestroy.next(true);
    this.$ngDestroy.complete();
  }

  actionGoBack() {
    this.modalCtrl.dismiss();
  }

  formatDate(date) {
    if (isToday(date)) {
      return 'Today - ' + format(date, this.dateTimeFormatsService.dateToUpper);
    }
    if (isYesterday(date)) {
      return 'Yesterday - ' + format(date, this.dateTimeFormatsService.dateToUpper);
    }
    else {
      return format(date, 'ddd, MMM D - ' + this.dateTimeFormatsService.dateToUpper);
    }
  }

  openGeneeInfo() {
    // let geeneeModal = this.modalCtrl.create(GeneeInfoComponent, { 'info': this.geneeInfoJSON, 'closeToolsFunctionRef': this.closeTools.bind(this) }, { cssClass: '' })
    // geeneeModal.present();
    // this.trackingService.tracking(TrackingGeneeEventProperties.GENEE_QUICK_INFO, TrackingEventNames.GENEE);
  }

  async openGeneeNotification() {
    const geeneeModal = await this.modalCtrl.create({ component: GeneeNotificationComponent, componentProps: { 'closeToolsFunctionRef': this.closeTools.bind(this) }, cssClass: 'fullscreenOnModal' });
    geeneeModal.present();
    geeneeModal.onDidDismiss().then(async (data) => {
      if (data.data.isClosed) {
        setTimeout(() => {
          this.closeTools();
        }, 100);
      }
    });
    this.trackingService.tracking(TrackingGeneeEventProperties.GENEE_NOTIFICATION_PILL, TrackingEventNames.GENEE);
  }

  clearChatHistory() {
    this.trackingService.tracking(TrackingGeneeEventProperties.GENEE_CLEAR_CHAT, TrackingEventNames.GENEE);
    this.myAssistantDataService.chatHistoryReset();
    this.myAssistantDataService.showTypingLoader = false;
    this.myAssistantDataService.suggestedActions = [];
    this.myAssistantDataService.suggestionsToRemember = [];
    this.showInitialSetOfOptions();
  }

  clearNotifications() {
    this.assistantService.myAssistantData = [];
    this.noDataToDisplay = true;
    this.GroupedData = [];
    this.assistantService.saveMyAssistantData();
  }


  public shoudUseRunTimeStyle() {
    const containerDiv = document.getElementById('outer_div');
    const parentDiv = document.getElementById('inner_parent_div');

    let totalHeight = 100 + this.initialHeightOfGeneeIllust;

    if (parentDiv.children.length) {
      Array.from(parentDiv.children).map(element => { totalHeight += element.clientHeight; });
    }
    return (totalHeight >= containerDiv.offsetHeight) ? true : false;
  }

  onMyAssistanttViewChange(viewType) {
    this.assistantService.getGroupedDataByMonth(viewType).then((data) => {
      if (data.length && data.length > 0) {
        this.noDataToDisplay = false;
        this.GroupedData = data;
      }
      else {
        this.noDataToDisplay = true;
        this.GroupedData = [];
      }
    })
  }
  footerButtonHandler(type: string, event: any) {
    switch (type) {
      case 'notifications':
        if (this.assitantViewType == 'notifications') {
          this.assitantViewType = 'KPIAssistantKeyboard';
          setTimeout(() => {
            this.scrollToLastMessage();
          }, 100)
        }
        else {
          this.assitantViewType = 'notifications'
          this.getGroupedData()
          this.assistantService.markAllAsRead();
        }
        break;
      case 'mic':
        this.trackingService.tracking(TrackingGeneeEventProperties.GENEE_VOICE_CLICK, TrackingEventNames.GENEE);
        if (event) event.stopPropagation();
        this.assitantViewType = 'KPIAssistantMic';
        this.listenForSpeech()
        // if (this.device.isNativeApp && !this.device.deviceFlags.electron) {
        // this.myAssistantDataService.startListeningToSpeech().subscribe((data)=>{
        //   console.log(data)
        // })
        // }
        break;
      case 'keyboard':
        this.assitantViewType = 'KPIAssistantKeyboard';
        break;
      case 'speaker':
        this.trackingService.tracking(TrackingGeneeEventProperties.GENEE_MUTE_BUTTON, TrackingEventNames.GENEE);
        console.log("Speaker Button Tapped");
        this.onSpeakerToggleButtonAction();
        break;
    }
    this._cd.detectChanges();
  }

  onSpeakerToggleButtonAction() {
    this.isSpeakerMuted = !this.isSpeakerMuted;

    localStorage.setItem("isGeneeSpeakerMuted", this.isSpeakerMuted.toString());

    if (!this.isSpeakerMuted) {
      console.log("Speaker Unmuted");;
      this.myAssistantDataService.isSpeakerMuted = this.isSpeakerMuted;
    } else {
      console.log("Speaker Muted");
      this.myAssistantDataService.isSpeakerMuted = this.isSpeakerMuted;

      //To stop speaking immediately
      this.myAssistantDataService.stopSpeaking();
    }
  }

  private trackAction(message: string) {
    switch (message) {
      case 'KPIs':
        this.trackingService.tracking(TrackingGeneeEventProperties.GENEE_KPI_SUGGESTION, TrackingEventNames.GENEE);
        break;
      case 'Lists':
        this.trackingService.tracking(TrackingGeneeEventProperties.GENEE_LIST_SUGGESTION, TrackingEventNames.GENEE);
        break;
      case 'Activities':
        this.trackingService.tracking(TrackingGeneeEventProperties.GENEE_ACTIVITIES_SUGGESTION, TrackingEventNames.GENEE);
        break;
      case 'Schedule meeting':
        switch (this.lastSelectedAction) {
          case 'Customers near me':
            this.trackingService.tracking(TrackingGeneeEventProperties.GENEE_CUSTOMERS_NEAR_ME_SCHEDULE_MEETING, TrackingEventNames.GENEE);
            break;
          case 'Customers not met':
            this.trackingService.tracking(TrackingGeneeEventProperties.GENEE_CUSTOMERS_NOT_MET_SCHEDULE_MEETING, TrackingEventNames.GENEE);
            break;
          case 'Customers not met by call plan':
            this.trackingService.tracking(TrackingGeneeEventProperties.GENEE_CUSTOMERS_NOT_MET_SCHEDULE_MEETING, TrackingEventNames.GENEE);
            break;
          default:
            this.trackingService.tracking(TrackingGeneeEventProperties.GENEE_SCHEDULE_MEETING, TrackingEventNames.GENEE);
            break;
        }
        break;
      case 'Send an email':
        switch (this.lastSelectedAction) {
          case 'Customers near me':
            this.trackingService.tracking(TrackingGeneeEventProperties.GENEE_CUSTOMERS_NEAR_ME_SEND_MESSAGE, TrackingEventNames.GENEE);
            break;
          case 'Customers not met':
            this.trackingService.tracking(TrackingGeneeEventProperties.GENEE_CUSTOMERS_NOT_MET_SEND_MESSAGE, TrackingEventNames.GENEE);
            break;
          case 'Customers not met by call plan':
            this.trackingService.tracking(TrackingGeneeEventProperties.GENEE_CUSTOMERS_NOT_MET_SEND_MESSAGE, TrackingEventNames.GENEE);
            break;
        }
        break;
      case 'Incomplete meetings':
        this.lastSelectedAction = message;
        this.trackingService.tracking(TrackingGeneeEventProperties.GENEE_INCOMPLETE_MEETING_SUGGESTION, TrackingEventNames.GENEE);
        break;
      case 'Customers':
        this.lastSelectedAction = message;
        this.trackingService.tracking(TrackingGeneeEventProperties.GENEE_CUSTOMER_LIST_SUGGESTION, TrackingEventNames.GENEE);
        break;
      case 'Presentations':
        this.trackingService.tracking(TrackingGeneeEventProperties.GENEE_SUGGESTED_ACTION_CLICK, TrackingEventNames.GENEE);
        break;
      case 'Customers near me':
        this.lastSelectedAction = message;
        this.trackingService.tracking(TrackingGeneeEventProperties.GENEE_CUSTOMERS_NEAR_ME_SUGGESTION, TrackingEventNames.GENEE);
        break;
      case 'Customers not met':
        this.lastSelectedAction = message;
        this.trackingService.tracking(TrackingGeneeEventProperties.GENEE_CUSTOMERS_NOT_MET_SUGGESTION, TrackingEventNames.GENEE);
        break;
      case 'Customers not met by call plan':
        this.trackingService.tracking(TrackingGeneeEventProperties.GENEE_CUSTOMER_NOT_MET_BY_CALLPLAN, TrackingEventNames.GENEE);
        break;
      case 'Working days by team':
        this.trackingService.tracking(TrackingGeneeEventProperties.GENEE_WORKING_DAYS, TrackingEventNames.GENEE);
        break;
      case 'Total meetings completed by team':
        this.trackingService.tracking(TrackingGeneeEventProperties.GENEE_TOTAL_MEETING_COMPLETED, TrackingEventNames.GENEE);
        break;
      case 'Time off by team':
        this.trackingService.tracking(TrackingGeneeEventProperties.GENEE_TIMEOFF_BY_USER, TrackingEventNames.GENEE);
        break;
      case 'Time off for last month by team':
        this.trackingService.tracking(TrackingGeneeEventProperties.GENEE_TIMEOFF, TrackingEventNames.GENEE);
        break;
      case 'Call plan goal for this month by team':
        this.trackingService.tracking(TrackingGeneeEventProperties.GENEE_CALLPLAN_GOALS, TrackingEventNames.GENEE);
        break;
      case 'Customer reach by team':
        this.trackingService.tracking(TrackingGeneeEventProperties.GENEE_MEETINGS_REACH, TrackingEventNames.GENEE);
        break;
      case 'Meetings without content by team':
        this.trackingService.tracking(TrackingGeneeEventProperties.GENEE_MEETING_WITHOUT_PRESENTATION, TrackingEventNames.GENEE);
        break;
      case 'Meetings with content by team':
        this.trackingService.tracking(TrackingGeneeEventProperties.GENEE_MEETINGS_WITH_PRESENTATION, TrackingEventNames.GENEE);
        break;
      case 'Customers by city':
        this.trackingService.tracking(TrackingGeneeEventProperties.GENEE_CUSTOMER_LIST_BY_CITY_SUGGESTION, TrackingEventNames.GENEE);
        break;
      default:
        this.trackingService.tracking(TrackingGeneeEventProperties.GENEE_SEND_MESSAGE, TrackingEventNames.GENEE);
        break;
    }
  }

  // KPI bot functionality
  lastSelectedAction = '';
  sendMessage(message?: string) {
    // this.showRightButton = false;
    // this.showLeftButton = false;
    if (this.myAssistantDataService.showTypingLoader && this.myAssistantDataService.suggestedActions.length === 0) return;
    let textToSend = message ? message : this.finalText;
    if (!textToSend || textToSend.length == 0) return
    this.trackAction(textToSend);

    this.myAssistantDataService.suggestedActions = [];
    this.finalText = ''
    if (textToSend.toLowerCase().indexOf('customers near me') > -1 || textToSend.toLowerCase().indexOf('customer near me') > -1) {
      this.myAssistantDataService.geneeBotMessages.push({
        from: this.userName,
        text: textToSend,
        cards: null,
      });
      this.myAssistantDataService.populate();
      this._cd.detectChanges();
      setTimeout(() => {
        this.scrollToLastMessage();
      }, this.scrollToLastMessageTimeInterval);
      this.getCustomersNearMe();
      return;
    }
    // if (textToSend.toLowerCase().indexOf('presentations') > -1 || textToSend.toLowerCase().indexOf('list of presentations') > -1) {
    //   this.myAssistantDataService.geneeBotMessages.push({
    //     from: this.userName,
    //     text: textToSend,
    //     cards: null,
    //   });
    //   this.myAssistantDataService.populate();
    //   this._cd.detectChanges();
    //   setTimeout(() => {
    //     this.scrollToLastMessage();
    //   }, this.scrollToLastMessageTimeInterval);
    //   this.getViewPresentations();
    //   return;
    // }
    if (textToSend == 'Schedule meeting') {

      if (this.myAssistantDataService.createMeetingType === CreateMeetingType.other) {
        // Normal meeting flow.
        this.createNewMeetingAndNavigate();
      } else if (this.myAssistantDataService.createMeetingType === CreateMeetingType.polls) {
        // This will create a new meeting with presentations but without any contacts.
        this.createNewMeetingAndNavigateWithoutContact();
      } else if (this.myAssistantDataService.createMeetingType === CreateMeetingType.customersNotSurveyed) {
        // This will create a new meeting with presentations and contacts.
        this.createNewMeetingAndNavigateWithContact();
      }
      return
    }
    if (textToSend == 'Send an email') {
      this.createNewMessage();
      return
    }
    try {
      this.myAssistantDataService.geneeBotMessages.push({
        from: this.userName,
        text: textToSend,
        cards: null,
      });
      this.myAssistantDataService.populate();
      this._cd.detectChanges();
      setTimeout(() => {
        this.scrollToLastMessage();
      }, this.scrollToLastMessageTimeInterval)
      this.myAssistantDataService.sendMessage(textToSend).subscribe(id => {
        console.info("Message sent successfully.");
        this.finalText = '';
      }, error => {
        console.log(error);
        this.myAssistantDataService.showTypingLoader = false;
      });
    } catch (error) {
      console.error(error);
      this.myAssistantDataService.showTypingLoader = false;
    }
  }

  //
  async createNewMeetingAndNavigateWithContact() {

    // this.events.publish('hideNewActivityScreen');
    this.uiService.displayLoader();
    //Going to need a payload for initiation
    let startTime, endTime;
    startTime = new Date();
    endTime = new Date();
    let meetingDuration = this.authService.user.meeting_duration || 30;
    endTime = addMinutes(startTime, meetingDuration);
    let subject: string = this.translate.instant('MEETING');
    const isAcinoBaseUser = this.authService.hasFeatureAction(FeatureActionsMap.VISIT_AUTO_SUBJECT);
    if (isAcinoBaseUser) {
      subject = this.translate.instant('VISIT');
    }
    let selectedPresentations: Presentation[] = [];
    let selectedContacts: Contact[] = [];
    if (this.myAssistantDataService.contactPresentationCombination && this.myAssistantDataService.contactPresentationCombination.length > 0) {
      this.myAssistantDataService.contactPresentationCombination.forEach(combo => {
        let array = combo.split(":");
        if (array.length > 1) { //  safe check for presentation ID.
          // Though It will always have teh string combined with :

          let presentation = this.presentationService.getPresentationById(array[1].toLowerCase());
          if (presentation) selectedPresentations.push(presentation);

          let contact = this.contactService.getContactByID(array[0].toLowerCase());
          if (contact) selectedContacts.push(contact);
        }
      })
    } else if (this.myAssistantDataService.selectedCustomerIdsForMeeting && this.myAssistantDataService.selectedCustomerIdsForMeeting.length > 0) {
      this.myAssistantDataService.selectedCustomerIdsForMeeting.forEach(id => {
        let contact = this.contactService.getContactByID(id.toLowerCase());
        if (contact) selectedContacts.push(contact);
      })
    }

    if (this.authService.hasFeatureAction(FeatureActionsMap.MEETING_AUTO_SUBJECT) || isAcinoBaseUser) {
      let subPrefix = `- ${this.translate.instant('MEETING')} - ${this.authService.user.displayName}`;
      switch (selectedContacts.length) {
        case 0:
          break;
        case 1:
          subject = `${selectedContacts[0].fullName}${subPrefix}`;
          if (isAcinoBaseUser) {
            subject = this.translate.instant('VISIT') + ` - ${selectedContacts[0].fullName}`;
          }
          break;
        default:
          if (selectedContacts.length > 1) {
            selectedContacts.sort((contactA, contactB): number => {
              let contactAFullName = (contactA.firstName.length > 0) ? contactA.firstName + " " + contactA.lastName : contactA.lastName;
              let contactBFullName = (contactB.firstName.length > 0) ? contactB.firstName + " " + contactB.lastName : contactB.lastName;
              if (contactAFullName.toLowerCase() > contactBFullName.toLowerCase()) return 1;
              if (contactAFullName.toLowerCase() < contactBFullName.toLowerCase()) return -1;

              return 0;
            });
            subject = `${selectedContacts[0].fullName} + ${selectedContacts.length - 1}${subPrefix}`;
            if (isAcinoBaseUser) {
              subject = this.translate.instant('VISIT') + ` - ${selectedContacts[0].fullName}`;
            }
          }
      }
    }

    let payload: InitiateMeetingPayload = new InitiateMeetingPayload(
      subject,
      "",
      startTime.getTime(),
      endTime.getTime(),
      "",
      OFFLINE_ID_PREFIX + new Date().getTime()
    );

    let response = await this.meetingService.createNewMeeting(payload, true);
    this.activityService.selectedActivity = response;
    this.activityService.selectedActivityAtHome = this.activityService.selectedActivity;

    (this.activityService.selectedActivity as AppointmentActivity).presentations = selectedPresentations;
    (this.activityService.getActivityByID(this.activityService.selectedActivity.ID) as AppointmentActivity).presentations = selectedPresentations;

    let updatePayload;
    if (selectedContacts.length == 1) {
      updatePayload = new UpdateMeetingPayload(
        response.subject,
        selectedContacts[0].getPrimaryAddress,
        response.scheduledStart,
        response.scheduledEnd,
        response.notes
      );
      this.activityService.selectedActivity.location = selectedContacts[0].getPrimaryAddress;
      this.activityService.getActivityByID(this.activityService.selectedActivity.ID).location = selectedContacts[0].getPrimaryAddress;
      await this.meetingDataService.updateMeeting((this.activityService.selectedActivity as AppointmentActivity), updatePayload);
    } else {
      updatePayload = new UpdateMeetingPayload(
        response.subject,
        '',
        response.scheduledStart,
        response.scheduledEnd
      );
      await this.meetingDataService.updateMeeting((this.activityService.selectedActivity as AppointmentActivity), updatePayload);
    }

    if (selectedContacts.length > 0) (this.activityService.selectedActivity as AppointmentActivity).contacts.push(...selectedContacts);

    this.meetingDataService.addContentToMeeting((this.activityService.selectedActivity as AppointmentActivity).presentations, this.activityService.selectedActivity as AppointmentActivity)
      .then(() => {
        this.meetingStructureService.updateContents((this.activityService.selectedActivity as AppointmentActivity).presentations);
        this.uiService.dismissLoader();
      })
      .catch((er) => {
        this.uiService.dismissLoader();
      });

    //We've made an activity, we just need to display the right hand details on agenda
    this.uiService.activeView = "Appointment";
    await this.activityDataService._appendMeetingDetailsToActivity(this.activityService.selectedActivity);
    this.activityDataService.activityDetailsLoaded = true;
    if (this.navService.getCurrentPageName() != PageName.ActivitiesPageComponent) {
      await this.navService.popWithPageTracking();
    }
    if (this.device.shouldBeMobileView) {
      this.uiService.showRightPane = true;
    }

    this.navService.setChildNavRoot(ActivitiesDetailsPaneComponent, PageName.ActivitiesDetailsPaneComponent, PageName.ActivitiesPageComponent);
    this.footerService.initButtons(FooterViews.Activities);

    setTimeout(() => {
      this.events.publish('ContactsSelected', true);
      this.closeTools();
    }, 200);

    if (!this.uiService.toolsActivityActive) {
      this.events.publish('refreshAgenda');
    } else this.uiService.agendaRefreshRequired = true;
  }

  async createNewMeetingAndNavigateWithoutContact() {

    // this.events.publish('hideNewActivityScreen');
    this.uiService.displayLoader();
    //Going to need a payload for initiation
    let startTime, endTime;
    startTime = new Date();
    endTime = new Date();
    let meetingDuration = this.authService.user.meeting_duration || 30;
    endTime = addMinutes(startTime, meetingDuration);
    let subject: string = this.translate.instant('MEETING');
    if (this.authService.hasFeatureAction(FeatureActionsMap.VISIT_AUTO_SUBJECT)) {
      subject = this.translate.instant('VISIT');
    }
    let selectedPresentations: Presentation[] = [];
    if (this.myAssistantDataService.selectedPollsIdsForMeeting && this.myAssistantDataService.selectedPollsIdsForMeeting.length > 0) {
      this.myAssistantDataService.selectedPollsIdsForMeeting.forEach(id => {
        let presentation = this.presentationService.getPresentationById(id.toLowerCase());
        if (presentation) {
          presentation.isAutoLoaded = false;
          selectedPresentations.push(presentation)
        };
      })
    }

    let payload: InitiateMeetingPayload = new InitiateMeetingPayload(
      subject,
      "",
      startTime.getTime(),
      endTime.getTime(),
      "",
      OFFLINE_ID_PREFIX + new Date().getTime()
    );

    let response = await this.meetingService.createNewMeeting(payload, true);
    this.activityService.selectedActivity = response;
    this.activityService.selectedActivityAtHome = this.activityService.selectedActivity;

    if (selectedPresentations.length) {
      let updatePayload = new UpdateMeetingPayload(
        response.subject,
        '',
        response.scheduledStart,
        response.scheduledEnd,
      );

      (this.activityService.selectedActivity as AppointmentActivity).presentations = selectedPresentations;
      (this.activityService.getActivityByID(this.activityService.selectedActivity.ID) as AppointmentActivity).presentations = selectedPresentations;
      await this.meetingDataService.updateMeeting((this.activityService.selectedActivity as AppointmentActivity), updatePayload);

      this.meetingDataService.addContentToMeeting((this.activityService.selectedActivity as AppointmentActivity).presentations, this.activityService.selectedActivity as AppointmentActivity)
        .then(() => {
          this.meetingStructureService.updateContents((this.activityService.selectedActivity as AppointmentActivity).presentations);
          this.uiService.dismissLoader();
        })
        .catch((er) => {
          this.uiService.dismissLoader();
        });
    }

    //We've made an activity, we just need to display the right hand details on agenda
    this.uiService.activeView = "Appointment";
    await this.activityDataService._appendMeetingDetailsToActivity(this.activityService.selectedActivity);
    this.activityDataService.activityDetailsLoaded = true;
    if (this.navService.getCurrentPageName() != PageName.ActivitiesPageComponent) {
      await this.navService.popWithPageTracking();
    }
    if (this.device.shouldBeMobileView) {
      this.uiService.showRightPane = true;
    }
    this.navService.setChildNavRoot(ActivitiesDetailsPaneComponent, PageName.ActivitiesDetailsPaneComponent, PageName.ActivitiesPageComponent);
    this.footerService.initButtons(FooterViews.Activities);
    setTimeout(() => {
      this.closeTools();
    }, 200);
    if (!this.uiService.toolsActivityActive) {
      this.events.publish('refreshAgenda');
    } else this.uiService.agendaRefreshRequired = true;
  }
  //

  async createNewMeetingAndNavigate() {
    // this.events.publish('hideNewActivityScreen');
    this.uiService.displayLoader();
    //Going to need a payload for initiation
    let startTime, endTime;
    startTime = new Date();
    endTime = new Date();
    let meetingDuration = this.authService.user.meeting_duration || 30;
    endTime = addMinutes(startTime, meetingDuration);
     let subject: string = this.translate.instant('MEETING');
     const isAcinoBaseUser = this.authService.hasFeatureAction(FeatureActionsMap.VISIT_AUTO_SUBJECT);
     if (isAcinoBaseUser) {
      subject = this.translate.instant('VISIT');
    }
    let selectedContacts: Contact[] = [];
    if (this.myAssistantDataService.selectedCustomerIdsForMeeting.length > 0) {
      this.myAssistantDataService.selectedCustomerIdsForMeeting.forEach(id => {
        let contact = this.contactService.getContactByID(id.toLowerCase());
        if (contact) selectedContacts.push(contact);
      })
    }
    if (this.authService.hasFeatureAction(FeatureActionsMap.MEETING_AUTO_SUBJECT) || isAcinoBaseUser) {
      let subPrefix = `- ${this.translate.instant('MEETING')} - ${this.authService.user.displayName}`;
      switch (selectedContacts.length) {
        case 0:
          break;
        case 1:
          subject = `${selectedContacts[0].fullName}${subPrefix}`;
          if (isAcinoBaseUser) {
            subject = this.translate.instant('VISIT') + ` - ${selectedContacts[0].fullName}`;
          }
          break;
        default:
          if (this.myAssistantDataService.selectedCustomerIdsForMeeting.length > 1) {
            selectedContacts.sort((contactA, contactB): number => {
              let contactAFullName = (contactA.firstName.length > 0) ? contactA.firstName + " " + contactA.lastName : contactA.lastName;
              let contactBFullName = (contactB.firstName.length > 0) ? contactB.firstName + " " + contactB.lastName : contactB.lastName;
              if (contactAFullName.toLowerCase() > contactBFullName.toLowerCase()) return 1;
              if (contactAFullName.toLowerCase() < contactBFullName.toLowerCase()) return -1;

              return 0;
            });
            subject = `${selectedContacts[0].fullName} + ${selectedContacts.length - 1}${subPrefix}`;
            if (isAcinoBaseUser) {
              subject = this.translate.instant('VISIT') + ` - ${selectedContacts[0].fullName}`;
            }
          }
      }
    }
    let payload: InitiateMeetingPayload = new InitiateMeetingPayload(
      subject,
      "",
      startTime.getTime(),
      endTime.getTime(),
      "",
      OFFLINE_ID_PREFIX + new Date().getTime()
    );

    let response = await this.meetingService.createNewMeeting(payload, true);
    this.activityService.selectedActivity = response;
    this.activityService.selectedActivityAtHome = this.activityService.selectedActivity;
    this.uiService.dismissLoader();
    let updatePayload;
    if (selectedContacts.length == 1) {
      updatePayload = new UpdateMeetingPayload(
        response.subject,
        selectedContacts[0].getPrimaryAddress,
        response.scheduledStart,
        response.scheduledEnd,
        response.notes
      );
      this.activityService.selectedActivity.location = selectedContacts[0].getPrimaryAddress;
      this.activityService.getActivityByID(this.activityService.selectedActivity.ID).location = selectedContacts[0].getPrimaryAddress;
      await this.meetingDataService.updateMeeting((this.activityService.selectedActivity as AppointmentActivity), updatePayload);
    }
    if (selectedContacts.length > 0) (this.activityService.selectedActivity as AppointmentActivity).contacts.push(...selectedContacts);
    //We've made an activity, we just need to display the right hand details on agenda
    this.uiService.activeView = "Appointment";
    await this.activityDataService._appendMeetingDetailsToActivity(this.activityService.selectedActivity);
    this.activityDataService.activityDetailsLoaded = true;
    if (this.navService.getCurrentPageName() != PageName.ActivitiesPageComponent) {
      await this.navService.popWithPageTracking();
    }
    if (this.device.shouldBeMobileView) {
      this.uiService.showRightPane = true;
    }
    this.navService.setChildNavRoot(ActivitiesDetailsPaneComponent, PageName.ActivitiesDetailsPaneComponent, PageName.ActivitiesPageComponent);
    this.footerService.initButtons(FooterViews.Activities);

    setTimeout(() => {
      this.events.publish('ContactsSelected', true);
      this.closeTools();
    }, 200)

    if (!this.uiService.toolsActivityActive) {
      this.events.publish('refreshAgenda');
    } else this.uiService.agendaRefreshRequired = true;
  }

  /**
  * This helps to create an email  after clicking on  'Send an email' pill in genee
  *
  * @memberOf MyAssistantComponent
  */
  private async createNewMessage() {
    // this.events.publish('hideNewActivityScreen');
    this.uiService.displayLoader();
    let scheduleTime = new Date().getTime().toString();
    let request: any
    let activity: any;
    request = { scheduledstart: scheduleTime, scheduledend: scheduleTime, subject: 'New Message', statuscode: 1, channelType: 'Email' }
    activity = await this.emailService.createNewEmailActivity(request);
    await this.activityDataService.getRealTimeActivityDetails(activity).then((email) => {
      if (!email) {
        return;
      }
    })
    let emailParties: EmailActivityParty[] = [];
    const contacts: Contact[] = []
    if (this.myAssistantDataService.selectedCustomerIdsForMeeting.length > 0) {
      this.myAssistantDataService.selectedCustomerIdsForMeeting.forEach(id => {
        let contact = this.contactService.getContactByID(id.toLowerCase());
        if (contact) contacts.push(contact);
      })
    }
    emailParties = await this.getEmailParties(contacts)
    this.activityService.selectedActivity = activity;
    this.emailService.selectedActivity = activity;
    this.activityService.addActivity(activity);
    this.emailService.setCurrentEmail(this.emailService.selectedActivity);
    this.emailService.viewType = EmailViewType.FROM_AGENDA;
    this.uiService.showNewActivity = false;
    this.activityService.activityDetailsLoaded = true;
    this.emailService.selectedActivity = activity;
    this.uiService.activeView = 'Email';
    //
    this.uiService.dismissLoader();
    if (this.navService.getCurrentPageName() != PageName.ActivitiesPageComponent) {
      this.navService.popWithPageTracking();
    }
    if (this.device.shouldBeMobileView) {
      this.uiService.showRightPane = true;
    }
    this.navService.setChildNavRoot(EmailActivityDetailComponent, PageName.EmailDetailsPageComponent, PageName.ActivitiesPageComponent);
    this.footerService.initButtons(FooterViews.EmailDetails);
    this.closeTools();
    //
    if (!this.uiService.toolsActivityActive) {
      this.events.publish('refreshAgenda');
    } else this.uiService.agendaRefreshRequired = true;
    if (!_.isEmpty(emailParties)) {
      await this.emailService.updateEmailActivityParties(emailParties);
    }
  }

  /**
    * This helps to set all the email details  and finally returns all the email details ,
    *
    * @memberOf MyAssistantComponent
    */
  async getEmailParties(contacts: Contact[]) {
    let emailParties: EmailActivityParty[] = [];
    this.emailService.emailFromGeneeMessage.message = 'Primary email address of the customer is added';
    this.emailService.emailFromGeneeMessage.messageFlag = true;
    for (let contact of contacts) {
      let emailParty: EmailActivityParty = new EmailActivityParty();
      emailParty.allowToRemove = true;
      emailParty.contact_firstname = contact.firstName;
      emailParty.contact_lastname = contact.lastName;
      emailParty.contact_mobilephone = contact.mobilePhone;
      emailParty.contact_indskr_facebookpsid = contact.indskr_facebookpsid;
      emailParty.indskr_contactid = contact.ID;
      emailParty.emailAddresses = [];
      for (let email of contact.emailAddressList) {
        if (email.isPrimary) {
          email.isSelected = true;
          //emailAddresses.push(email.emailAddressId);
          //emailAddress.push(new EmailAddress(email.emailAddressId, email.emailAddress));
          let emailAddressDetails: EmailAddress = new EmailAddress();
          emailAddressDetails.email_addressid = email.emailAddressId;
          emailAddressDetails.emailAddress = email.emailAddress
          emailParty.emailAddresses.push(emailAddressDetails);
        } else {
          email.isSelected = false;
        }
      }

      if (emailParty.emailAddresses.length != 0) {
        emailParties.push(emailParty);
      }
      else {
        this.emailService.emailFromGeneeMessage.message = '1 or more customers were not added, since they didn’t have a primary email address';
      }
    }
    return emailParties;
  }

  /**
  * Here we validate selected contacts with email address,
  *
  * @memberOf MyAssistantComponent
  */
  private validateSelectedContactsEmail(contactIds) {
    let primaryEmailPresentFlag = false;
    if (contactIds.length > 0) {
      contactIds.forEach(id => {
        let contact = this.contactService.getContactByID(id.toLowerCase());
        if (contact) {
          if (contact.emailAddressList && contact.emailAddressList.length > 0) {
            contact.emailAddressList.forEach(email => {
              if (email.isPrimary) {
                primaryEmailPresentFlag = true;
              }
            });
          }
        }
      })
    }
    return primaryEmailPresentFlag;
  }

  private maximumAllowedRecords(): number {
    if (this.device.isMobileDevice || this.device.isMobileDevicePortrait) {
      const outerDiv = document.getElementById('outer_div');
      if (outerDiv) {
        if (outerDiv.offsetHeight <= 300) {
          return 3;
        } else if (outerDiv.offsetHeight <= 350) {
          return 4;
        } else if (outerDiv.offsetHeight <= 400) {
          return 5;
        } else if (outerDiv.offsetHeight <= 800) {
          return 8;
        }
      }
    }
    return 10;
  }

  //this method is for  getting all prsentations from presentationService
  getViewPresentations() {
    let viewPresentaionsList: { presname: string, presId: string, }[] = [];
    this.myAssistantDataService.showTypingLoader = true;
    this.presentationService.presentation.forEach(content => {
      viewPresentaionsList.push({
        presname: content.name,
        presId: content.contentId,
      })
    })
    //Here we sorting the all presentations list by presenations name
    viewPresentaionsList.sort((a, b) => {
      if (a.presname.trim().toLowerCase() < b.presname.trim().toLowerCase()) return -1;
      else return 1;
    });
    let suggestedActionsCopy = this.myAssistantDataService.suggestedActions.slice();
    this.viewPresentationsAdaptiveCard(viewPresentaionsList, suggestedActionsCopy);
  }

  //this method is for set adaptivecard  Content according to the requirement
  viewPresentationsAdaptiveCard(viewPresentaionsList, suggestedActionsCopy) {
    if (viewPresentaionsList.length == 0) {
      let text = "I couldn't find any presentation for you. Is there anything else I could help you with?";
      this.sayText(text);
      this.myAssistantDataService.geneeBotMessages.push({
        from: 'UIPresantaionSearch',
        text: text,
        cards: null,
      });
      this.myAssistantDataService.populate();
      this._cd.detectChanges();
      setTimeout(() => {
        this.scrollToLastMessage();
      }, this.scrollToLastMessageTimeInterval)
      this.myAssistantDataService.showTypingLoader = false;
      this.myAssistantDataService.suggestedActions = suggestedActionsCopy.slice();
      this.showInitialSetOfOptions();
      return;
    }
    let adaptiveContent = {
      type: "AdaptiveCard",
      version: "1.0",
      speak: "Here is the list of Presentations associated with you",
      body: []
    }
    adaptiveContent.body.push(
      {
        type: "TextBlock",
        text: "Presentations List",
        weight: "bolder",
        wrap: true
      }
    );
    let visibilitySwitches = [];
    viewPresentaionsList.forEach((pres, index) => {
      let columnData = {
        type: "ColumnSet",
        columns: [
          {
            type: "Column", width: "80", items: [
              {
                type: "TextBlock",
                text: pres.presname,
                color: "accent",
                wrap: false,
                spacing: "none",
                id: pres.presId,
              },
            ]
          },
        ],
        id: 'presRow' + index,
        separator: true,
        isVisible: index < this.maximumAllowedRecords(),
        "selectAction": {
          "type": "Action.Submit",

          "data": {
            "presenetationID": pres.presId
          }
        }
      }
      //here if index is more than or equal to 10 than only we are showing Action buttons like 'Show More' and 'Show Less'
      if (index >= this.maximumAllowedRecords()) visibilitySwitches.push(columnData.id);
      adaptiveContent.body.push(columnData);
    })
    if (visibilitySwitches.length > 0) {
      visibilitySwitches.push('btnShowLess', 'btnShowMore');
      adaptiveContent.body.push(
        {
          horizontalAlignment: "center",
          type: "ColumnSet",
          columns: [
            {
              minHeight: "30px",
              type: "Column",
              width: "auto",
              items: [
                {
                  horizontalAlignment: "Left",
                  id: "btnShowLess",
                  isVisible: false,
                  type: "ActionSet",
                  actions: [
                    {
                      title: "Show Less",
                      type: "Action.ToggleVisibility",
                      targetElements: visibilitySwitches
                    }
                  ]
                },
                {
                  horizontalAlignment: "Left",
                  id: "btnShowMore",
                  isVisible: true,
                  type: "ActionSet",
                  actions: [
                    {
                      title: "Show More",
                      type: "Action.ToggleVisibility",
                      targetElements: visibilitySwitches
                    }
                  ]
                }
              ]
            }
          ]
        }
      )
    }
    this.renderPresentationAdaptiveCard(adaptiveContent, suggestedActionsCopy);
  }

  //this method is for rendering the prsentation card
  renderPresentationAdaptiveCard(adaptiveContent, suggestedActionsCopy) {
    let card = adaptiveContent;
    if (card['speak']) {
      this.sayText(card.speak);
    }
    let adaptiveCard = new AdaptiveCards.AdaptiveCard();
    adaptiveCard.hostConfig = new AdaptiveCards.HostConfig({
      fontFamily: "Segoe UI, Helvetica Neue, sans-serif"
    });
    // added click action for each prsenation names
    adaptiveCard.onExecuteAction = (action: AdaptiveCards.Action) => {
      if (action instanceof AdaptiveCards.SubmitAction) {
        this.myAssistantDataService.createMeetingType = CreateMeetingType.other;
        const presentationId = action.data["presenetationID"];
        if (presentationId) {
          //this line is for clicking on prsentation name it should navigate to prsenation screen and open up the detail of presenation which is clicked on
          this.presentationService.viewMode = PresentationView.MENU;
          this.popEarliestLoadedPage(PageName.PresentationPageComponent);
          this.navService.pushWithPageTracking(
            PresentationPageComponent,
            PageName.PresentationPageComponent, { presPreview: true, presId: presentationId }
          );
          // this.viewCtrl.dismiss();
          //this method is added to close the genee popup after clicking  on the presenation name
          this.closeTools();
        }
      }
    };
    adaptiveCard.parse(card);
    let renderedCard = adaptiveCard.render();
    var elementHtml = renderedCard.outerHTML;
    this.adaptiveCardHtml = this.sanitizer.bypassSecurityTrustHtml(elementHtml)
    let currentTimeStamp = (new Date()).getTime().toString()
    this.myAssistantDataService.geneeBotMessages.push({

      id: currentTimeStamp + '-presID',
      from: 'UIPresenations',
      text: null,
      hasAttachments: true,
      contentType: 'adaptive',
      cards: this.adaptiveCardHtml,
      timestamp: currentTimeStamp,
      isFocusable: true
    });
    this.myAssistantDataService.populate();
    this._cd.detectChanges();
    setTimeout(() => {
      renderedCard.className = 'description-container-for-genee-box adaptive-card-for-genee-box';
      this.myAssistantDataService.renderedCardMap.set(`${currentTimeStamp + '-presID'}`, renderedCard);
      document.getElementById(`${currentTimeStamp + '-presID'}`).appendChild(renderedCard);
      // this.scrollToLastMessage();
      //
      var elmnt = document.getElementById(`${currentTimeStamp + '-presID'}`);
      this.scrollThisElement(elmnt);
      //
    }, this.scrollThisElementTimeInterval)
    this.myAssistantDataService.showTypingLoader = false;
    this.myAssistantDataService.suggestedActions = [];
    //this method is to show the intial suggetions
    this.showInitialSetOfOptions(false);
  }

  private popEarliestLoadedPage(pageName: PageName) {
    const currentPageStack = this.navService.getCurrentPageStack();
    if (currentPageStack) {
      if (currentPageStack.pageName === pageName) {
        // This is the same page request from the Genee
        // Need to close previously opened.
        this.navService.popWithPageTracking();
        // if we will ever require to pop all the pages then we can implement below.
        // const allPageStack = this.navService.getAllPageStack();
        // if (allPageStack && !isEmpty(allPageStack) && allPageStack.length > 1) {
        //   for (var index = allPageStack.length - 1; index > 0; index++) {
        //       this.navService.popWithPageTracking();
        //   }
        // }
      }
    }
  }

  getCustomersNearMe() {
    let nearByContacts: {
      customerId: string,
      customer: string,
      distance: number
      address: string
    }[] = []
    this.myAssistantDataService.showTypingLoader = true;
    let suggestedActionsCopy = this.myAssistantDataService.suggestedActions.slice();
    this.myAssistantDataService.suggestedActions = [];
    this.geoLocation.getCurrentPosition({ maximumAge: 0, enableHighAccuracy: true, timeout: 5000 }).then((posData) => {
      if (posData) {
        let currentCoords = {
          latitude: posData.coords.latitude,
          longitude: posData.coords.longitude
        }
        // console.log('Current Coords', currentCoords);
        this.contactService.contacts.forEach(contact => {
          if (contact.addressesList && contact.addressesList.length) {
            contact.addressesList.forEach(address => {
              if (address.isPrimary && address.latitude && address.longitude) {
                let unit = this.authService.user.distanceUnit == DistanceUnits.KM ? 'KM' : 'Miles';
                let dist = getDistance(currentCoords.latitude, currentCoords.longitude,
                  address.latitude, address.longitude, unit
                )
                // console.log('Comparing with:', address.latitude, address.longitude)
                // console.log('Distance:', dist);
                if (dist <= this.authService.user.distanceConfigurationForGenee) nearByContacts.push({
                  customerId: contact.ID,
                  customer: contact.fullName,
                  distance: dist,
                  address: address.street + ' ' + address.street2 + ' ' + address.street3
                })
              }
            })
          }
        })
        this.createCustomersAdaptiveCard(nearByContacts, suggestedActionsCopy, true);
      }
    }, (error) => {
      console.log('geolocation error myassistant', error);
      let text
      text = 'Unable to access your location. Please switch on your device location or else check your location permission settings.'
      this.sayText(text);
      this.myAssistantDataService.geneeBotMessages.push({
        from: 'UIContactsSearch',
        text: text,
        cards: null,
      });
      this.myAssistantDataService.populate();
      this._cd.detectChanges();
      this.myAssistantDataService.showTypingLoader = false;
      this.myAssistantDataService.suggestedActions = suggestedActionsCopy.slice();
      this.showInitialSetOfOptions();
    })
  }

  private async showInitialSetOfOptions(shouldScrollToLastMessage: boolean = true) {

    this.myAssistantDataService.showInitialSetOfOptions().subscribe(result => {
      this.myAssistantDataService.showTypingLoader = false;
      if (shouldScrollToLastMessage) {
        this.scrollToLastMessage();
      }
    }, error => {
      console.log(error);
      this.myAssistantDataService.showTypingLoader = false;
      if (shouldScrollToLastMessage) {
        this.scrollToLastMessage();
      }
    });
  }

  createCustomersNotMetByCard(contactsData: any, suggestedActionsCopy, contentUrl: string) {
    if (contactsData.length == 0) {
      let text = 'No customers found';
      this.sayText(text);
      this.myAssistantDataService.geneeBotMessages.push({
        from: 'UIContactsSearch',
        text: text,
        cards: null,
      });
      this.myAssistantDataService.populate();
      this._cd.detectChanges();
      setTimeout(() => {
        this.scrollToLastMessage();
      }, this.scrollToLastMessageTimeInterval)
      this.myAssistantDataService.showTypingLoader = false;
      this.myAssistantDataService.suggestedActions = suggestedActionsCopy.slice();
      this.showInitialSetOfOptions();
      return;
    }

    let speakText = "I found " + contactsData.length + " customers you haven't met";
    let length = this.myAssistantDataService.geneeBotMessages.length;
    if (length > 0) {
      const lastUserMessage = this.myAssistantDataService.geneeBotMessages[length - 1].text;
      if (lastUserMessage && lastUserMessage.indexOf('call plan') > -1) {
        speakText = "I found " + contactsData.length + " customers you haven't met by call plan";
      }
    }

    let adaptiveContent = {
      type: "AdaptiveCard",
      version: "1.0",
      speak: speakText,
      body: []
    }

    adaptiveContent.body.push(
      {
        type: "TextBlock",
        text: speakText + ". Please select at least one to schedule an activity",
        wrap: true
      },
      {
        type: "ColumnSet",
        columns: [{
          type: "Column", width: "45", items: [
            {
              type: "TextBlock",
              text: "Name",
              wrap: true,
              spacing: "none",
              weight: "bolder",
            }
          ]
        }, {
          type: "Column", width: "60", items: [
            {
              type: "TextBlock",
              text: contentUrl,
              wrap: true,
              spacing: "none",
              weight: "bolder",
            }
          ]
        }]
      }
    );
    let visibilitySwitches = [];
    contactsData.forEach((contact, index) => {
      let columnData = {
        type: "ColumnSet",
        columns: [
          {
            type: "Column", width: "80", items: [
              {
                type: "TextBlock",
                text: contact.customer,
                wrap: true,
                spacing: "none",
                id: "contactName" + contact.customerId
              }
            ]
          },
          {
            type: "Column", width: "80", items: [
              {
                type: "TextBlock",
                text: contact.dimensionValue,
                wrap: true,
                spacing: "none",
                isVisible: true //customerNearByFlow
              }
            ]
          },
          {
            type: "Column", width: "20",
            items: [
              {
                "type": "Image",
                "url": "assets/imgs/selectors_add.svg",
                "isVisible": true,
                "height": "25px",
                "width": "25px",
                "id": "selectImage" + contact.customerId,
                "horizontalAlignment": "right",
                "selectAction": {
                  "type": "Action.Submit",
                  "data": {
                    "contactID": contact.customerId
                  }
                }
              }
            ]
          }
        ],
        id: 'customerRow' + index,
        separator: true,
        isVisible: index < this.maximumAllowedRecords()
      }
      if (index >= this.maximumAllowedRecords()) visibilitySwitches.push(columnData.id);
      adaptiveContent.body.push(columnData);
    })
    if (visibilitySwitches.length > 0) {
      visibilitySwitches.push('btnShowLess', 'btnShowMore');
      adaptiveContent.body.push(
        {
          horizontalAlignment: "center",
          type: "ColumnSet",
          columns: [
            {
              minHeight: "30px",
              type: "Column",
              width: "auto",
              items: [
                {
                  horizontalAlignment: "Left",
                  id: "btnShowLess",
                  isVisible: false,
                  type: "ActionSet",
                  actions: [
                    {
                      title: "Show Less",
                      type: "Action.ToggleVisibility",
                      targetElements: visibilitySwitches
                    }
                  ]
                },
                {
                  horizontalAlignment: "Left",
                  id: "btnShowMore",
                  isVisible: true,
                  type: "ActionSet",
                  actions: [
                    {
                      title: "Show More",
                      type: "Action.ToggleVisibility",
                      targetElements: visibilitySwitches
                    }
                  ]
                }
              ]
            }
          ]
        }
      )
    }
    this.renderCustomerAdaptiveCard(adaptiveContent, suggestedActionsCopy);
  }

  //
  createCustomersNotSurveyedAdaptiveCard(pollsData: any, suggestedActionsCopy) {

    if (pollsData.length == 0) {
      let text = 'No customers found';
      this.sayText(text);
      this.myAssistantDataService.geneeBotMessages.push({
        from: 'UIPollsSearch',
        text: text,
        cards: null,
      });
      this.myAssistantDataService.populate();
      this._cd.detectChanges();
      setTimeout(() => {
        this.scrollToLastMessage();
      }, this.scrollToLastMessageTimeInterval)
      this.myAssistantDataService.showTypingLoader = false;
      this.myAssistantDataService.suggestedActions = suggestedActionsCopy.slice();
      return;
    }

    let speakText = "Here is the list of customers who have not responded for polls";

    let adaptiveContent = {
      type: "AdaptiveCard",
      version: "1.0",
      speak: speakText,
      body: []
    }

    adaptiveContent.body.push(
      {
        type: "TextBlock",
        text: speakText + ". Please select at least one to schedule an activity",
        wrap: true
      }
    );

    let visibilitySwitches = [];
    pollsData.forEach((poll, index) => {
      let columnData = {
        type: "ColumnSet",
        columns: [
          {
            type: "Column", width: "80", items: [
              {
                type: "TextBlock",
                text: poll.customerName,
                wrap: true,
                weight: "bolder",
                spacing: "none",
                id: "contactName" + poll.customerId
              },
              {
                type: "TextBlock",
                text: poll.presentationName,
                wrap: true,
                isSubtle: true,
                spacing: "none",
                isVisible: true
              },
              {
                type: "TextBlock",
                text: `Expiry ${poll.expiryDate}`,
                wrap: true,
                isSubtle: true,
                spacing: "none",
                isVisible: true
              }
            ]
          },
          {
            type: "Column", width: "20",
            items: [
              {
                "type": "Image",
                "url": "assets/imgs/selectors_add.svg",
                "isVisible": true,
                "height": "25px",
                "width": "25px",
                "id": "selectImage" + poll.customerId,
                "horizontalAlignment": "right",
                "selectAction": {
                  "type": "Action.Submit",
                  "data": {
                    "presentationID": poll.presentationId,
                    "contactID": poll.customerId
                  }
                }
              }
            ]
          }
        ],
        id: 'customerRow' + index,
        separator: true,
        isVisible: index < this.maximumAllowedRecords()
      }
      if (index >= this.maximumAllowedRecords()) visibilitySwitches.push(columnData.id);
      adaptiveContent.body.push(columnData);
    })
    if (visibilitySwitches.length > 0) {
      visibilitySwitches.push('btnShowLess', 'btnShowMore');
      adaptiveContent.body.push(
        {
          horizontalAlignment: "center",
          type: "ColumnSet",
          columns: [
            {
              minHeight: "30px",
              type: "Column",
              width: "auto",
              items: [
                {
                  horizontalAlignment: "Left",
                  id: "btnShowLess",
                  isVisible: false,
                  type: "ActionSet",
                  actions: [
                    {
                      title: "Show Less",
                      type: "Action.ToggleVisibility",
                      targetElements: visibilitySwitches
                    }
                  ]
                },
                {
                  horizontalAlignment: "Left",
                  id: "btnShowMore",
                  isVisible: true,
                  type: "ActionSet",
                  actions: [
                    {
                      title: "Show More",
                      type: "Action.ToggleVisibility",
                      targetElements: visibilitySwitches
                    }
                  ]
                }
              ]
            }
          ]
        }
      )
    }
    this.renderPollsAdaptiveCard(adaptiveContent, suggestedActionsCopy);
  }

  createPollsAdaptiveCard(pollsData: any, suggestedActionsCopy) {

    if (pollsData.length == 0) {
      let text = 'No polls found';
      this.sayText(text);
      this.myAssistantDataService.geneeBotMessages.push({
        from: 'UIPollsSearch',
        text: text,
        cards: null,
      });
      this.myAssistantDataService.populate();
      this._cd.detectChanges();
      setTimeout(() => {
        this.scrollToLastMessage();
      }, this.scrollToLastMessageTimeInterval)
      this.myAssistantDataService.showTypingLoader = false;
      this.myAssistantDataService.suggestedActions = suggestedActionsCopy.slice();
      return;
    }

    let speakText = "Total number of active polls are " + pollsData.length;

    let adaptiveContent = {
      type: "AdaptiveCard",
      version: "1.0",
      speak: speakText,
      body: []
    }

    adaptiveContent.body.push(
      {
        type: "TextBlock",
        text: speakText + ". Please select at least one to schedule an activity",
        wrap: true
      }
    );

    let visibilitySwitches = [];
    pollsData.forEach((poll, index) => {
      let columnData = {
        type: "ColumnSet",
        columns: [
          {
            type: "Column", width: "80", items: [
              {
                type: "TextBlock",
                text: poll.presentationName,
                wrap: true,
                weight: "bolder",
                spacing: "none",
                id: "presentationName" + poll.presentationId
              }, {
                type: "TextBlock",
                text: `Expiry ${poll.expiryDate}`,
                wrap: true,
                spacing: "none",
                isSubtle: true,
                isVisible: true
              },
            ]
          },
          {
            type: "Column", width: "20",
            items: [
              {
                "type": "Image",
                "url": "assets/imgs/selectors_add.svg",
                "isVisible": true,
                "height": "25px",
                "width": "25px",
                "id": "selectImage" + poll.customerId,
                "horizontalAlignment": "right",
                "selectAction": {
                  "type": "Action.Submit",
                  "data": {
                    "presentationID": poll.presentationId
                  }
                }
              }
            ]
          }
        ],
        id: 'customerRow' + index,
        separator: true,
        isVisible: index < this.maximumAllowedRecords()
      }
      if (index >= this.maximumAllowedRecords()) visibilitySwitches.push(columnData.id);
      adaptiveContent.body.push(columnData);
    })
    if (visibilitySwitches.length > 0) {
      visibilitySwitches.push('btnShowLess', 'btnShowMore');
      adaptiveContent.body.push(
        {
          horizontalAlignment: "center",
          type: "ColumnSet",
          columns: [
            {
              minHeight: "30px",
              type: "Column",
              width: "auto",
              items: [
                {
                  horizontalAlignment: "Left",
                  id: "btnShowLess",
                  isVisible: false,
                  type: "ActionSet",
                  actions: [
                    {
                      title: "Show Less",
                      type: "Action.ToggleVisibility",
                      targetElements: visibilitySwitches
                    }
                  ]
                },
                {
                  horizontalAlignment: "Left",
                  id: "btnShowMore",
                  isVisible: true,
                  type: "ActionSet",
                  actions: [
                    {
                      title: "Show More",
                      type: "Action.ToggleVisibility",
                      targetElements: visibilitySwitches
                    }
                  ]
                }
              ]
            }
          ]
        }
      )
    }
    this.renderPollsAdaptiveCard(adaptiveContent, suggestedActionsCopy);
  }
  //

  createCustomersAdaptiveCard(contactsData: any, suggestedActionsCopy, customerNearByFlow: boolean = false) {

    if (customerNearByFlow) {
      this.showInitialSetOfOptions();
    }

    if (contactsData.length == 0) {
      let text = customerNearByFlow ? 'No customers found within '
        + this.authService.user.distanceConfigurationForGenee
        + (this.authService.user.distanceUnit == DistanceUnits.KM ? ' km radius' : ' mi radius')
        : 'No customers found';
      this.sayText(text);
      this.myAssistantDataService.geneeBotMessages.push({
        from: 'UIContactsSearch',
        text: text,
        cards: null,
      });
      this.myAssistantDataService.populate();
      this._cd.detectChanges();
      setTimeout(() => {
        this.scrollToLastMessage();
      }, this.scrollToLastMessageTimeInterval)
      this.myAssistantDataService.showTypingLoader = false;
      this.myAssistantDataService.suggestedActions = suggestedActionsCopy.slice();
      return;
    }

    let speakText = "I found " + contactsData.length + (customerNearByFlow ? " customers near you" : " customers you haven't met")
    if (!customerNearByFlow) {
      let length = this.myAssistantDataService.geneeBotMessages.length;
      if (length > 0) {
        const lastUserMessage = this.myAssistantDataService.geneeBotMessages[length - 1].text;
        if (lastUserMessage && lastUserMessage.indexOf('call plan') > -1) {
          speakText = "I found " + contactsData.length + " customers you haven't met by call plan";
        }
      }
    }

    let adaptiveContent = {
      type: "AdaptiveCard",
      version: "1.0",
      speak: speakText,
      body: []
    }

    adaptiveContent.body.push(
      {
        type: "TextBlock",
        text: speakText + ". Please select at least one to schedule an activity",
        wrap: true
      }
    );

    let visibilitySwitches = [];
    contactsData.forEach((contact, index) => {
      let columnData = {
        type: "ColumnSet",
        columns: [
          {
            type: "Column", width: "80", items: [
              {
                type: "TextBlock",
                text: contact.customer,
                wrap: true,
                spacing: "none",
                id: "contactName" + contact.customerId
              },
              {
                type: "TextBlock",
                text: contact.address,
                wrap: true,
                spacing: "none",
                isVisible: customerNearByFlow
              }
            ]
          },
          {
            type: "Column", width: "20",
            items: [
              {
                "type": "Image",
                "url": "assets/imgs/selectors_add.svg",
                "isVisible": true,
                "height": "25px",
                "width": "25px",
                "id": "selectImage" + contact.customerId,
                "horizontalAlignment": "right",
                "selectAction": {
                  "type": "Action.Submit",
                  "data": {
                    "contactID": contact.customerId
                  }
                }
              }
            ]
          }
        ],
        id: 'customerRow' + index,
        separator: true,
        isVisible: index < this.maximumAllowedRecords()
      }
      if (index >= this.maximumAllowedRecords()) visibilitySwitches.push(columnData.id);
      adaptiveContent.body.push(columnData);
    })
    if (visibilitySwitches.length > 0) {
      visibilitySwitches.push('btnShowLess', 'btnShowMore');
      adaptiveContent.body.push(
        {
          horizontalAlignment: "center",
          type: "ColumnSet",
          columns: [
            {
              minHeight: "30px",
              type: "Column",
              width: "auto",
              items: [
                {
                  horizontalAlignment: "Left",
                  id: "btnShowLess",
                  isVisible: false,
                  type: "ActionSet",
                  actions: [
                    {
                      title: "Show Less",
                      type: "Action.ToggleVisibility",
                      targetElements: visibilitySwitches
                    }
                  ]
                },
                {
                  horizontalAlignment: "Left",
                  id: "btnShowMore",
                  isVisible: true,
                  type: "ActionSet",
                  actions: [
                    {
                      title: "Show More",
                      type: "Action.ToggleVisibility",
                      targetElements: visibilitySwitches
                    }
                  ]
                }
              ]
            }
          ]
        }
      )
    }
    this.renderCustomerAdaptiveCard(adaptiveContent, suggestedActionsCopy);
  }

  //
  renderPollsAdaptiveCard(adaptiveContent, suggestedActionsCopy) {
    this.myAssistantDataService.selectedPollsIdsForMeeting = [];
    this.myAssistantDataService.selectedCustomerIdsForMeeting = [];
    let card = adaptiveContent;
    if (card['speak']) {
      this.sayText(card.speak);
    }
    let adaptiveCard = new AdaptiveCards.AdaptiveCard();
    adaptiveCard.hostConfig = new AdaptiveCards.HostConfig({
      fontFamily: "Segoe UI, Helvetica Neue, sans-serif"
    });
    adaptiveCard.onExecuteAction = (action: AdaptiveCards.Action) => {
      if (action instanceof AdaptiveCards.SubmitAction) {

        this.myAssistantDataService.createMeetingType = CreateMeetingType.polls;

        const presentationId = action.data["presentationID"];
        const contactId = action.data["contactID"];

        if (presentationId) {
          if (contactId) {
            this.myAssistantDataService.createMeetingType = CreateMeetingType.customersNotSurveyed;
            // let contactId = action.data["contactID"];
            // this.myAssistantDataService.contactPresentationCombination.filter(combo => {
            //   combo[0] ===
            // })
            if (this.myAssistantDataService.contactPresentationCombination.indexOf(`${contactId}:${presentationId}`) < 0) {
              this.myAssistantDataService.contactPresentationCombination.push(`${contactId}:${presentationId}`);
              let src = action.parent.renderedElement.firstElementChild['src'];
              src = src.replace('selectors_add', 'action_button_confirmchanges');
              action.parent.renderedElement.firstElementChild['src'] = src;

            } else {
              _.remove(this.myAssistantDataService.contactPresentationCombination, o => o == `${contactId}:${presentationId}`)
              let src = action.parent.renderedElement.firstElementChild['src'];
              src = src.replace('action_button_confirmchanges', 'selectors_add');
              action.parent.renderedElement.firstElementChild['src'] = src;
            }

            if (this.myAssistantDataService.contactPresentationCombination.length > 0) {
              if (this.myAssistantDataService.suggestionsToRemember.length === 0) {
                this.myAssistantDataService.suggestionsToRemember = this.myAssistantDataService.suggestedActions.slice();
              }
              this.myAssistantDataService.suggestedActions = [];
              if (this.suggestionsForEngagementResposne.length > 0) {
                this.myAssistantDataService.suggestedActions.push(this.suggestionsForEngagementResposne[0]);
              }
            }
            else if (this.myAssistantDataService.contactPresentationCombination.length == 0) {
              this.myAssistantDataService.suggestedActions = [];
              this.myAssistantDataService.suggestedActions = this.myAssistantDataService.suggestionsToRemember.slice();
              this.myAssistantDataService.suggestionsToRemember = [];
            }
          } else {
            if (this.myAssistantDataService.selectedPollsIdsForMeeting.indexOf(presentationId) < 0) {
              this.myAssistantDataService.selectedPollsIdsForMeeting.push(presentationId);
              let src = action.parent.renderedElement.firstElementChild['src'];
              src = src.replace('selectors_add', 'action_button_confirmchanges');
              action.parent.renderedElement.firstElementChild['src'] = src;
            }
            else {
              _.remove(this.myAssistantDataService.selectedPollsIdsForMeeting, o => o == presentationId)
              let src = action.parent.renderedElement.firstElementChild['src'];
              src = src.replace('action_button_confirmchanges', 'selectors_add');
              action.parent.renderedElement.firstElementChild['src'] = src;
            }


            if (this.myAssistantDataService.selectedPollsIdsForMeeting.length > 0) {
              if (this.myAssistantDataService.suggestionsToRemember.length === 0) {
                this.myAssistantDataService.suggestionsToRemember = this.myAssistantDataService.suggestedActions.slice();
              }
              this.myAssistantDataService.suggestedActions = [];
              if (this.suggestionsForEngagementResposne.length > 0) {
                this.myAssistantDataService.suggestedActions.push(this.suggestionsForEngagementResposne[0]);
              }
            }
            else if (this.myAssistantDataService.contactPresentationCombination.length == 0) {
              this.myAssistantDataService.suggestedActions = [];
              this.myAssistantDataService.suggestedActions = this.myAssistantDataService.suggestionsToRemember.slice();
              this.myAssistantDataService.suggestionsToRemember = [];
            }
          }
        }
        setTimeout(() => {
          this.setScrollArrows();
        }, 100);
      }
    };
    adaptiveCard.parse(card);
    let renderedCard = adaptiveCard.render();
    var elementHtml = renderedCard.outerHTML;
    this.adaptiveCardHtml = this.sanitizer.bypassSecurityTrustHtml(elementHtml)
    let currentTimeStamp = (new Date()).getTime().toString()
    this.myAssistantDataService.geneeBotMessages.push({
      id: currentTimeStamp + '-Polls',
      from: 'UIPollsSearch',
      text: null,
      hasAttachments: true,
      contentType: 'adaptive',
      cards: this.adaptiveCardHtml,
      timestamp: currentTimeStamp,
      isFocusable: true
    });
    this.myAssistantDataService.populate();
    this._cd.detectChanges();
    setTimeout(() => {
      renderedCard.className = 'description-container-for-genee-box adaptive-card-for-genee-box';
      this.myAssistantDataService.renderedCardMap.set(`${currentTimeStamp + '-Polls'}`, renderedCard);
      document.getElementById(`${currentTimeStamp + '-Polls'}`).appendChild(renderedCard);
      // this.scrollToLastMessage();
      var elmnt = document.getElementById(`${currentTimeStamp + '-Polls'}`);
      this.scrollThisElement(elmnt);
    }, this.scrollThisElementTimeInterval)
    this.myAssistantDataService.showTypingLoader = false;
    this.myAssistantDataService.suggestedActions = [];
    //this.myAssistantDataService.suggestedActions.push({value: 'Schedule meeting'})
  }
  //

  renderCustomerAdaptiveCard(adaptiveContent, suggestedActionsCopy) {
    this.myAssistantDataService.selectedCustomerIdsForMeeting = []
    let card = adaptiveContent;
    if (card['speak']) {
      this.sayText(card.speak);
    }
    let adaptiveCard = new AdaptiveCards.AdaptiveCard();
    adaptiveCard.hostConfig = new AdaptiveCards.HostConfig({
      fontFamily: "Segoe UI, Helvetica Neue, sans-serif"
    });
    adaptiveCard.onExecuteAction = (action: AdaptiveCards.Action) => {
      if (action instanceof AdaptiveCards.SubmitAction) {
        this.myAssistantDataService.createMeetingType = CreateMeetingType.other;
        const contactId = action.data["contactID"];
        if (contactId) {
          if (this.myAssistantDataService.selectedCustomerIdsForMeeting.indexOf(contactId) < 0) {
            switch (this.lastSelectedAction) {
              case 'Customers near me':
                this.trackingService.tracking(TrackingGeneeEventProperties.GENEE_CUSTOMERS_NEAR_ME_CLICK_CUSTOMERS, TrackingEventNames.GENEE);
                break;
              case 'Customers not met':
                this.trackingService.tracking(TrackingGeneeEventProperties.GENEE_CUSTOMERS_NOT_MET_CLICK_CUSTOMERS, TrackingEventNames.GENEE);
                break;
            }
            this.myAssistantDataService.selectedCustomerIdsForMeeting.push(contactId);
            let src = action.parent.renderedElement.firstElementChild['src'];
            src = src.replace('selectors_add', 'action_button_confirmchanges');
            action.parent.renderedElement.firstElementChild['src'] = src;
          }
          else {
            _.remove(this.myAssistantDataService.selectedCustomerIdsForMeeting, o => o == contactId)
            let src = action.parent.renderedElement.firstElementChild['src'];
            src = src.replace('action_button_confirmchanges', 'selectors_add');
            action.parent.renderedElement.firstElementChild['src'] = src;
          }

          if (this.myAssistantDataService.selectedCustomerIdsForMeeting.length > 0) {

            if (this.myAssistantDataService.suggestionsToRemember.length === 0) {
              this.myAssistantDataService.suggestionsToRemember = this.myAssistantDataService.suggestedActions.slice();
            }

            this.myAssistantDataService.suggestedActions = [];

            if (this.suggestionsForEngagementResposne.length > 0) {
              this.myAssistantDataService.suggestedActions.push(this.suggestionsForEngagementResposne[0])
            }

            if (this.validateSelectedContactsEmail(this.myAssistantDataService.selectedCustomerIdsForMeeting)) {
              if (this.suggestionsForEngagementResposne.length > 1) {
                this.myAssistantDataService.suggestedActions.push(this.suggestionsForEngagementResposne[1]);
              }
            }
          }
          else if (this.myAssistantDataService.selectedCustomerIdsForMeeting.length == 0) {
            this.myAssistantDataService.suggestedActions = [];
            this.myAssistantDataService.suggestedActions = this.myAssistantDataService.suggestionsToRemember.slice();
            this.myAssistantDataService.suggestionsToRemember = [];
          }
        }
        setTimeout(() => {
          this.setScrollArrows()
        }, 100);
      }
    };
    adaptiveCard.parse(card);
    let renderedCard = adaptiveCard.render();
    var elementHtml = renderedCard.outerHTML;
    this.adaptiveCardHtml = this.sanitizer.bypassSecurityTrustHtml(elementHtml)
    let currentTimeStamp = (new Date()).getTime().toString()
    this.myAssistantDataService.geneeBotMessages.push({
      id: currentTimeStamp + '-ContactsNearBy',
      from: 'UIContactsSearch',
      text: null,
      hasAttachments: true,
      contentType: 'adaptive',
      cards: this.adaptiveCardHtml,
      timestamp: currentTimeStamp,
      isFocusable: true
    });
    this.myAssistantDataService.populate();
    this._cd.detectChanges();
    setTimeout(() => {
      renderedCard.className = 'description-container-for-genee-box adaptive-card-for-genee-box';
      this.myAssistantDataService.renderedCardMap.set(`${currentTimeStamp + '-ContactsNearBy'}`, renderedCard);
      document.getElementById(`${currentTimeStamp + '-ContactsNearBy'}`).appendChild(renderedCard);
      // this.scrollToLastMessage();
      var elmnt = document.getElementById(`${currentTimeStamp + '-ContactsNearBy'}`);
      this.scrollThisElement(elmnt);
    }, this.scrollThisElementTimeInterval)
    this.myAssistantDataService.showTypingLoader = false;
    this.myAssistantDataService.suggestedActions = [];
    //this.myAssistantDataService.suggestedActions.push({value: 'Schedule meeting'})
  }

  sendAction(action: Object, actionText?: string) {
    if (this.myAssistantDataService.showTypingLoader && this.myAssistantDataService.suggestedActions.length === 0) return;
    try {
      console.info(`SENDING Action`);
      console.log(action);

      this.myAssistantDataService.suggestedActions = [];

      //To check if ActionText is empty i.e case of SuggestionActions with ActionType object
      if (actionText) {
        this.myAssistantDataService.geneeBotMessages.push({
          from: this.userName,
          text: actionText,
          cards: null,
        });
        this.myAssistantDataService.populate();
      }

      this._cd.detectChanges();
      setTimeout(() => {
        this.scrollToLastMessage();
      }, this.scrollToLastMessageTimeInterval)

      this.myAssistantDataService.submitAction(action).subscribe(id => {
        console.info("Action sent successfully.");
        this.finalText = '';
      }, error => {
        console.log(error);
        this.myAssistantDataService.showTypingLoader = false;
      });
    } catch (error) {
      console.error(error);
      this.myAssistantDataService.showTypingLoader = false;
    }
  }

  sendQnAAction(action: Object, actionText: string) {
    if (this.myAssistantDataService.showTypingLoader && this.myAssistantDataService.suggestedActions.length === 0) return;
    try {
      console.info(`SENDING QnA Action`);
      console.log(action);

      this.myAssistantDataService.suggestedActions = [];

      //To check if ActionText is empty i.e case of SuggestionActions with ActionType object
      if (actionText) {
        this.myAssistantDataService.geneeBotMessages.push({
          from: this.userName,
          text: actionText,
          cards: null,
        });
        this.myAssistantDataService.populate();
      }

      this._cd.detectChanges();
      setTimeout(() => {
        this.scrollToLastMessage();
      }, this.scrollToLastMessageTimeInterval)

      this.myAssistantDataService.submitQnAAction(actionText, action).subscribe(id => {
        console.info("QnA Action sent successfully.");
        this.finalText = '';
      }, error => {
        console.log(error);
        this.myAssistantDataService.showTypingLoader = false;
      });
    } catch (error) {
      console.error(error);
      this.myAssistantDataService.showTypingLoader = false;
    }
  }

  async sayText(currentMessage?: string) {
    try {
      if (currentMessage) this.stack.push(currentMessage);
      if (this.stack.length > 0 && !this.isGeneeSpeaking) {
        currentMessage = this.stack[0];
        this.isGeneeSpeaking = true;
        // this.tts.speak({
        //   text: currentMessage,
        //   locale: "en-US",
        //   ...this.device.deviceFlags.ios ? { rate: 1.5 } : {},
        // })
        //   .then(() => {
        this.myAssistantDataService.speak(currentMessage).then((res) => {
          console.log(res);
          this.stack.shift();
          this.myAssistantDataService.showTypingLoader = false;
          this.isGeneeSpeaking = false;
          if (this.stack.length > 0) {
            this.sayText();
          }
          this._cd.detectChanges()
        }).catch((err) => {
          console.log(err);
          this.stack.shift();
          this.myAssistantDataService.showTypingLoader = false;
          this.isGeneeSpeaking = false;
          if (this.stack.length > 0) {
            this.sayText();
          }
          this._cd.detectChanges()
        });
      }
    } catch (error) {
      console.error(error);
      this.myAssistantDataService.showTypingLoader = false;
    }
  }

  private keepListeningForSpeech() {
    this.speechSubscription = this.myAssistantDataService.getUserInputSpeech().subscribe(response => {
      this._cd.detectChanges();

      switch (response.status) {
        case 'error':
          this.isRecording = false;
          this.myAssistantDataService.stopSpeechRecording();
          break;
        case 'cancelled':
          this.isRecording = false;
          this.myAssistantDataService.stopSpeechRecording();
          break;
        case 'no-text':
          break;
        case 'speech':
          break;
        case 'session':
          if (response.value === 'stopped') {
            this.isRecording = false;
            this.myAssistantDataService.stopSpeechRecording();
          }
          break;
        case 'recognizing':
          break;
        case 'result':
          if (this.isRecording) {
            this.isRecording = false;
            this.finalText = response.value;
            if (this.finalText.length) {
              if (this.finalText.toLowerCase().indexOf('kp eyes') > -1) {
                this.finalText = this.finalText.replace(/kp eyes/ig, 'KPIs');
              }
            }
            this.sendMessage(this.finalText);
            this.myAssistantDataService.stopSpeechRecording();
          }
          break;
      }
    });
  }

  private listen() {
    this.isRecording = true;
    this.myAssistantDataService.startSpeechRecording();
  }

  listenForSpeech(): void {
    this.listen();
  }

  public scrollToTop(scroll) {
    scroll.scrollTop = 0;
  }

  public isObject(val) {
    return typeof val === 'object';
  }

  public getActivity(id) {
    var foundActivity = this.activityService.activities.find(function (activity) {
      return activity.ID.toUpperCase() == id;
    });
    return foundActivity;
  }

  getCountUnreadNotifications() {
    return this.assistantService.countUnreadNotifications
  }
}

function getDistance(lat1, lon1, lat2, lon2, unit: string = 'meters') {
  if ((lat1 == lat2) && (lon1 == lon2)) {
    return 0;
  }
  else {
    var radlat1 = Math.PI * lat1 / 180;
    var radlat2 = Math.PI * lat2 / 180;
    var theta = lon1 - lon2;
    var radtheta = Math.PI * theta / 180;
    var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = dist * 180 / Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit == "KM") { dist = dist * 1.609344 }
    if (unit == "meters") { dist = dist * 1.609344 * 1000 }
    return dist;
  }
}
