import { debounceTime, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonContent, NavController, NavParams, } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Events } from '@omni/events';
import { IndTabsDataModel } from '@omni/models/ind-tabs-data-model';
import { IndPageTitleViewDataModel } from '@omni/models/indPageTitleDataModel';
import { DynamicFormsService } from '@omni/services/dynamic-forms/dynamic-forms-service';
import { FeatureActionsMap } from '../../../classes/authentication/user.class';
import { RepServices } from '../../../data-services/rep/rep.services';
import { AccountOfflineService } from '../../../services/account/account.offline.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { DeviceService } from '../../../services/device/device.service';
import { FooterService, FooterViews } from '../../../services/footer/footer.service';
import { TrackingEventNames, TrackService } from '../../../services/logging/tracking.service';
import { NavigationService, PageName } from '../../../services/navigation/navigation.service';
import { NewActivityComponent } from '../../activity/new-activity/new-activity';
import { NothingSelectedView } from '../../shared/nothing-selected-view/nothing-selected-view';
import { ComponentViewMode, UIService } from './../../../services/ui/ui.service';
import { EventName, EventsService } from '@omni/services/events/events.service';
import { AffiliationExplorerComponent } from '@omni/components/shared/affiliation-explorer/affiliation-explorer';
import { AssessmentTemplate, EntityOptions, TemplateType } from '@omni/classes/customer-assessment/assessment-template.class';
import { CustomerAssessment } from '@omni/classes/customer-assessment/customer-assessment.class';
import { CustomerAssessService } from '@omni/services/customer-assess/customer-assess.service';
import { AccountPageComponent } from './../account-page/account-page';
import { ContactOfflineService } from '@omni/services/contact/contact.service';
import { NotificationService, ToastStyle } from '@omni/services/notification/notification.service';
import { TerritoryManagementService } from '@omni/services/territory-management/territory-management.service';
import { ContactDataService } from '@omni/data-services/contact/contact.data.service';
import _ from 'lodash';

/**
 * Generated class for the AccountDetailsComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'account-details',
  templateUrl: 'account-details.html',
  styleUrls:['account-details.scss']
})
export class AccountDetailsComponent implements OnInit {
  public isFollowupTaskEnabled = false;
  private isLayoverPushView = false;
  private layoverViewPushedFrom: string;
  public isNewActivityCreationEnabled = false;
  @Input() from: any;
  @Input() callbackEvent: any;
  tabsData: IndTabsDataModel[] = [
    {
       displayText: this.translate.instant('INFO'),
       value: 'info'
    },
    {
      displayText: this.translate.instant('ASSESS'),
      value: "customer-assess"
    },
    {
     displayText: this.translate.instant('PROFILE'),
     value: 'profile',
      disable: this.accountService.isOneKeySearching,
    },
    {
      displayText: this.translate.instant('TIMELINE'),
      value: 'timeline',
      disable: this.accountService.isOneKeySearching,
     }
  ];
  public accountDetailSegment: string = this.tabsData[0].value;
  public pageTitle = '';
  public pageTitleControls = [];
  public okPageTitleControls = [];
  public isPopPageBtnEnabled:boolean = false;
  @ViewChild(IonContent) ionContent: IonContent;
  private scrollBehaviorSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  public readonly scrollObservable: Observable<any> = this.scrollBehaviorSubject.asObservable().pipe(debounceTime(100));
  private refreshAccountNotesBehaviorSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public readonly refreshAccountNotesObservable: Observable<boolean> = this.refreshAccountNotesBehaviorSubject.asObservable();
  public hasCustomerAssessFA: boolean = false;
  public accountAssessmentTemplates: AssessmentTemplate[] = [];
  public accountAssessmentTemplatesTeamview: AssessmentTemplate[] = [];
  public assessRawData: CustomerAssessment;
  public customerAssessmentIsLoading: boolean = true;
  public customerAssessmentTeamviewIsLoading: boolean = true;
  @ViewChild('accountAssessment') accountAssessment;

  private _isDisabledGoToAccountsToolBtn: boolean = false;
  public isGoToAccountBtnEnabled: boolean = false;
  private ngDestroy$ = new Subject<boolean>();
  private isTeamViewEnabled: boolean = false;

  constructor(
    public accountService: AccountOfflineService,
    public device: DeviceService,
    public navCtrl: NavController,
    public uiService: UIService,
    public repService: RepServices,
    private readonly events: EventsService,
    private readonly navService: NavigationService,
    public trackingService: TrackService,
    private readonly authenticationService: AuthenticationService,
    public readonly footerService: FooterService,
    private readonly translate: TranslateService,
    private dynamicFormService: DynamicFormsService,
    private customerAssessService: CustomerAssessService,
    private contactService: ContactOfflineService,
    public notificationService: NotificationService,
    private navParams: NavParams,
    private territoryService: TerritoryManagementService,
    private contactDataService: ContactDataService,
  ) {
    this.events.subscribe('device:deviceIsOffline', (status) => {
      this.device.isOffline = true;
    });

  }


  async closepage() {
    if (this.from == 'NotificationDetails') {
      this.navService.popChildNavPageWithPageTracking();
      return;
    }
    if(this.customerAssessService.isAssessmentDirty && !(await this.customerAssessService.discardChagesForAssess())) return;
    this.accountService.selected = undefined;
    this.navService.setChildNavRoot(NothingSelectedView,PageName.NothingSelectedView,PageName.AccountPageComponent, {
        deviceString: this.device.deviceFlags.ios ? 'ios' : 'other',
      });
    this.navService.setChildNavRightPaneView(false);
    setTimeout(()=>{
      this.events.publish('accountListContentResize');
    },0);
  }

  ngOnInit() {
    if (this.from) {
      this.isLayoverPushView = true;
      if (this.from === 'AccountManagementTool') {
        this.layoverViewPushedFrom = 'AccountManagementTool';
      } else if (this.from === 'AccountTool') {
        this.layoverViewPushedFrom = 'AccountTool';
      }
      else if(this.from == PageName.TerritoryManagementDetailsComponent){
        this.tabsData[2].disable = true;
        this.tabsData[3].disable = true;
      }
    }
    if (this.uiService.accountDataSegment !== '') {
      this.accountDetailSegment = this.uiService.accountDataSegment;
    }
    this.isFollowupTaskEnabled = this.authenticationService.hasFeatureAction(FeatureActionsMap.FOLLOW_UP_ACTION_ACTIVITY);
    this.isTeamViewEnabled = this.authenticationService.hasFeatureAction(FeatureActionsMap.ASSESSMENTS_TEAM_VIEW);
    this.isNewActivityCreationEnabled = this.accountService.accessedAccountListFrom === PageName.ActivitiesPageComponent;
    if (this.navParams && this.navParams.data && this.navParams.get('listMode') && (this.navParams.get('listMode') == ComponentViewMode.ADDNEW || this.navParams.get('listMode') == ComponentViewMode.SELECTION)) {
      this.isNewActivityCreationEnabled = false;
    }
    if ((this.dynamicFormService.isOpenedAffiliatedAccountOnContact && this.navService.getActiveChildNavViewPageName() === PageName.ContactDetailsComponent)
    || (this.dynamicFormService.isOpenedAffiliatedAccountOnAccount && this.navService.getActiveChildNavViewPageName() === PageName.AccountDetailsComponent)) {
      this.accountService.isEnableGoToAccountsTool = true;
      this.isPopPageBtnEnabled = true;
    }
    this.hasCustomerAssessFA = this.authenticationService.hasFeatureAction(FeatureActionsMap.CUSTOMER_ASSESS);
    this.tabsData.find(tab => tab.value == "customer-assess").hide = !this.hasCustomerAssessFA;
    this.initPageTitle();
    this.initOKPageTitle();
    this.initCustomerAssessData();
    
    this.dynamicFormService.affiliatedContacts = [];

    this.events.observe('refreshAccountDetailPageTitle').pipe(takeUntil(this.ngDestroy$)).subscribe(() => {
      if ((this.dynamicFormService.isOpenedAffiliatedAccountOnContact && this.navService.getActiveChildNavViewPageName() === PageName.ContactDetailsComponent)
      || (this.dynamicFormService.isOpenedAffiliatedAccountOnAccount && this.navService.getActiveChildNavViewPageName() === PageName.AccountDetailsComponent)) {
        this.accountService.isEnableGoToAccountsTool = true;
        this.isPopPageBtnEnabled = true;
      } else {
        this.accountService.isEnableGoToAccountsTool = false;
        this.isPopPageBtnEnabled = false;
      }
      this.initPageTitle();
    });

    this.events.observe('refershAccountDetailSegment').pipe(takeUntil(this.ngDestroy$)).subscribe(() => {
      this.accountDetailSegment = this.uiService.accountDataSegment;
      if (this.uiService.accountDataSegment != 'timeline') {
        this.accountService.isOpenedSurveyTimelineFromAccountProfile = false;
      }
    });
  }

  ionViewDidEnter(){
    if(this.navService.getActiveChildNavViewPageName() === PageName.AccountDetailsComponent){
      this.refreshAccountNotesBehaviorSubject.next(true);
      this.footerService.initButtons(FooterViews.Accounts);
    }else if(this.navService.getActiveChildNavViewPageName() === PageName.ActivitiesDetailsPaneComponent){
      this.footerService.initButtons(FooterViews.Activities);
    }else if(this.navService.getActiveChildNavViewPageName() === PageName.CaseManagementDetailComponent){
      this.footerService.initButtons(FooterViews.CUSTOMER_INQUIRY);
    }
  }

  ngAfterViewInit(){
    this.accountDetailSegment = this.uiService.accountDataSegment ||'info';
  }

  ngOnDestroy() {
    if (this.accountService.accountPageMode === ComponentViewMode.PREVIEW) this.accountService.accountPageMode = ComponentViewMode.LISTVIEW;
    if (this.contactService.contactPageMode === ComponentViewMode.PREVIEW) this.contactService.contactPageMode = ComponentViewMode.LISTVIEW;
    this.dynamicFormService.isOpenedAffiliatedContactOnAccount = false;
    this.dynamicFormService.isOpenedAffiliatedAccountOnAccount = false;
    this.dynamicFormService.isOpenedAffiliatedAccountOnContact = false;
    this.dynamicFormService.isOpenedAffiliatedContactOnContact = false;
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }

  initCustomerAssessData() {
    if (this.hasCustomerAssessFA) {
      this.accountAssessmentTemplates = this.customerAssessService.getTemplatesForEntity(TemplateType.ASSESSMENT, EntityOptions.ACCOUNT);
      if(this.isTeamViewEnabled) this.accountAssessmentTemplatesTeamview = this.customerAssessService.getTemplatesTeamviewForEntity(TemplateType.ASSESSMENT, EntityOptions.ACCOUNT);
      if (this.accountAssessmentTemplates.length == 1) {
        this.customerAssessService.fetchAccountAssessment(this.accountService.selected.id, this.accountAssessmentTemplates[0].indskr_assessmenttemplateid).then((data: CustomerAssessment) => {
          this.assessRawData = data;
          this.customerAssessmentIsLoading = false;
          this.customerAssessmentTeamviewIsLoading = false;
        });
      } else {
        this.customerAssessmentIsLoading = false;
        this.customerAssessmentTeamviewIsLoading = false;
      }
    }
  }

  public handlePlusButton(){
    this.navService.pushChildNavPageWithPageTracking(NewActivityComponent,PageName.NewActivityComponent,PageName.AccountPageComponent,{from:'AccountTool',account:this.accountService.selected});
  }

  public savedCustomerAssessment(data: CustomerAssessment) {
    this.assessRawData = data;
  }

  handleAffiliationExplorer(){
    this.navService.pushChildNavPageWithPageTracking(AffiliationExplorerComponent,PageName.AffiliationExplorerComponent,PageName.AccountPageComponent ,
      { from: 'AccountPageComponent',parent:this.accountService.selected,
      relatedFrom:this.accountService.linkEntityAccountFrom,
      relatedTo: this.accountService.linkEntityAccountTo,
      contactAccountAffiliation:this.accountService.linkedAccountContact,
      accountToBrand:this.accountService.brandAffiliationByAccountId
    });
  }

  async segmentChanged(slectedTab: string) {
    if (this.hasCustomerAssessFA) {
      if (this.uiService.accountDataSegment == "customer-assess" && this.customerAssessService.isAssessmentDirty && !(await this.customerAssessService.discardChagesForAssess())) {
        return;
      }
    }
    if (this.ionContent) {
    this.ionContent.scrollToTop();
    }
    this.uiService.accountDataSegment = this.accountDetailSegment = slectedTab;
    if (slectedTab == this.tabsData[2].value) {
      this.trackingService.tracking('AccountTimeline', TrackingEventNames.ACCOUNTS);
    }
    if (slectedTab == this.tabsData[0].value) {
      this.trackingService.tracking('AccountInfo', TrackingEventNames.ACCOUNTS);
    }
    if (slectedTab != 'timeline') {
      this.accountService.isOpenedSurveyTimelineFromAccountProfile = false;
    }
    this.initPageTitle();
  }

  isSplitView() {
    return (window.innerWidth > 620);
  }

  public isAccountAssessmentFormDirty(isDirty: boolean) {
    const index = this.pageTitleControls.findIndex(button => button.id == 'save');
    if (index > -1) {
      this.pageTitleControls[index].isDisabled = this.device.isOffline ? true : !isDirty;
      this.customerAssessService.isAssessmentDirty = isDirty;
    }
  }

  poppage() {
    if (this.navService.getActiveChildNavViewPageName() === PageName.ContactDetailsComponent) {
      if (this.dynamicFormService.isOpenedAffiliatedAccountOnContact) {
        if (this.accountService.accountPrevPageMode === ComponentViewMode.ADDNEW || this.accountService.accountPrevPageMode === ComponentViewMode.SELECTION) {
          this.accountService.accountPageMode = this.accountService.accountPrevPageMode;
        } else {
          this.accountService.accountPageMode = ComponentViewMode.LISTVIEW;
        }
        this.dynamicFormService.isOpenedAffiliatedAccountOnContact = false;
        this._isDisabledGoToAccountsToolBtn = false;
        this.events.publish('refreshDisplayForm');
      }
      // this.uiService.activeView = 'contactDetails';
      this.accountService.selected = undefined;
      this.footerService.initButtons(FooterViews.Contacts);
      this.navService.popChildNavPageWithPageTracking();
    } else if (this.dynamicFormService.isOpenedAffiliatedAccountOnContact) {
      if (this.accountService.accountPrevPageMode === ComponentViewMode.ADDNEW || this.accountService.accountPrevPageMode === ComponentViewMode.SELECTION) {
        this.accountService.accountPageMode = this.accountService.accountPrevPageMode;
      } else {
        this.accountService.accountPageMode = ComponentViewMode.LISTVIEW;
      }
        this.dynamicFormService.isOpenedAffiliatedAccountOnContact = false;
        this._isDisabledGoToAccountsToolBtn = false;
        this.events.publish('refreshDisplayForm');
        this.navService.popChildNavPageWithPageTracking();
    } else if (this.dynamicFormService.isOpenedAffiliatedAccountOnAccount) {
      if (this.accountService.accountPrevPageMode === ComponentViewMode.ADDNEW || this.accountService.accountPrevPageMode === ComponentViewMode.SELECTION) {
        this.accountService.accountPageMode = this.accountService.accountPrevPageMode;
      } else {
        this.accountService.accountPageMode = ComponentViewMode.LISTVIEW;
      }
        this.dynamicFormService.isOpenedAffiliatedAccountOnAccount = false;
        this._isDisabledGoToAccountsToolBtn = false;
        this.events.publish('refreshDisplayForm');
        if (this.accountService.prevSelected) this.accountService.selected = this.accountService.prevSelected;
        this.events.publish('highlightAccount', this.accountService.selected);
    } else {
      this.navService.popWithPageTracking();
    }
  }

  private initPageTitle(): void {
    let buttons = [];
    if (!this.isSplitView() || this.isPopPageBtnEnabled) {
      buttons.push({
        id: 'close',
        icon: 'chevron-back-outline',
        isDisabled: false,
        align: 'left'
      });
    }
    if (this.accountDetailSegment === 'customer-assess') {
      buttons.push({
        id: 'save',
        imgSrc: 'assets/imgs/header_save.svg',
        name: this.translate.instant('SAVE'),
        isDisabled: true,
        align: 'right'
      })
    } else if (this.isNewActivityCreationEnabled) {
      if (this.accountService.isAffiliationEnabled) {
        buttons.push({
          id: "affiliation-explorer",
          imgSrc: 'assets/imgs/affiliation-explorer.svg',
          name: this.translate.instant('AFFILIATION_EXPLORER'),
          isDisabled: !((this.accountService.linkEntityAccountFrom && this.accountService.linkEntityAccountFrom.length) || (this.accountService.linkEntityAccountTo && this.accountService.linkEntityAccountTo.length)
            || (this.accountService.brandAffiliationByAccountId && this.accountService.brandAffiliationByAccountId.length) || (this.accountService.linkedAccountContact && this.accountService.linkedAccountContact.length)),
          align: "right"
        })
      }
      buttons.push({
        id: 'pluse-icon',
        imgSrc: 'assets/imgs/ios_add_3x.svg',
        name: this.translate.instant('CREATE'),
        isDisabled: this.accountService.isOneKeySearching,
        align: 'right'
      });
    }
    this.isGoToAccountBtnEnabled = this.accountDetailSegment != 'customer-assess'
                                                && (this.contactService.contactPageMode == ComponentViewMode.PREVIEW || this.accountService.accountPageMode == ComponentViewMode.PREVIEW)
                                                && (this.uiService.isAccountsFromToolDrawer || this.uiService.isContactsFromToolDrawer)
                                                && this.isPopPageBtnEnabled && this.accountService.isEnableGoToAccountsTool 
                                                && (this.dynamicFormService.isOpenedAffiliatedAccountOnContact || this.dynamicFormService.isOpenedAffiliatedAccountOnAccount)
                                                && !this.uiService.isForcedHideGoToToolBtn;
    if (this.isGoToAccountBtnEnabled || (this.from == 'NotificationDetails' && this.accountDetailSegment != 'customer-assess')) {
      buttons = [];
      buttons.push({
          id: 'close',
          icon: 'chevron-back-outline',
          isDisabled: false,
          align: 'left'
      });
      buttons.push({
        id: "goToAccountsTool",
        imgSrc: 'assets/imgs/open_in_new_white.svg',
        name: this.translate.instant('OPEN'),
        isDisabled: this._isDisabledGoToAccountsToolBtn,
        align: "right"
      });
    }
    const isAssessTeamview: boolean = this.uiService.accountDataSegment =='customer-assess-teamview';
    if(isAssessTeamview) {
      buttons = [{
        id: "close-teamview",
        icon: "chevron-back-outline",
        isDisabled: false,
        align: "left"
      }];
    }
    if (this.from && this.from == PageName.TerritoryManagementDetailsComponent) {
      buttons = [];
      buttons.push({
          id: 'gotoTerritoryMgmtDetails',
          icon: 'chevron-back-outline',
          isDisabled: false,
          align: 'left'
      });
    }
    this.pageTitleControls = buttons;
  }

  private initOKPageTitle(): void {
    let buttons = [];
    if(!this.isSplitView() || this.isPopPageBtnEnabled) {
      buttons.push({
        id: 'close',
        icon: 'chevron-back-outline',
        isDisabled: false,
        align: 'left'
      });
    }
    const isAssessTeamview: boolean = this.uiService.accountDataSegment =='customer-assess-teamview';
    if(isAssessTeamview) {
      buttons = [{
        id: "close-teamview",
          icon: "chevron-back-outline",
          isDisabled: false,
          align: "left"
      }];
    }
    this.okPageTitleControls = buttons;
  }

  public onPageTitleControlClick(id: string) {
    switch (id) {
      case 'save':
        this.saveContactAssessment();
        break;
      case 'close':
        if (this.isPopPageBtnEnabled) {
          this.poppage();
        } else {
          this.closepage();
        }
        break;
      case 'close-teamview':
        this.uiService.accountDataSegment = 'customer-assess';
        this.initOKPageTitle();
        this.initPageTitle();
        break;
      case 'pluse-icon':
        this.handlePlusButton();
        break;
      case 'affiliation-explorer':
        this.handleAffiliationExplorer();
        break;
      case 'goToAccountsTool':
        this.handleGoToAccountsTool();
        break;
        case 'gotoTerritoryMgmtDetails':
        this.handleGoToTerritoryMgmtDetails();
        break;
      default:
        console.log('Unhandled switch case statement');
        break;
    }
  }

  private saveContactAssessment() {
    if (this.accountAssessment && !this.device.isOffline) {
      this.accountAssessment.saveCustomerAssessment({ entity: 'account', id: this.accountService.selected.id });
    }
  }

  onScroll(ev) {
    this.scrollBehaviorSubject.next(ev);
  }

  handleGoToAccountsTool() {
    this.accountService.isClickedGoToAccountsTool = true;
    if (!this.accountService.isNotInMyAccountList) {
      this.uiService.isAccountsFromToolDrawer = true;
      this.navService.popWithPageTracking();
      if (this.from == 'NotificationDetails') this.navService.popChildNavPageWithPageTracking();
      this.navService.pushWithPageTracking(AccountPageComponent, PageName.AccountPageComponent, { from: 'AccountTool', listMode: ComponentViewMode.LISTVIEW, openedToolFromAccountDetails: true }, PageName.AccountPageComponent);
      this.navService.setChildNavRightPaneView(true);
    } else if (this.accountService.errorMessageOpenAccountDetails) {
      this.accountService.isClickedGoToAccountsTool = false;
      this.notificationService.notify(this.accountService.errorMessageOpenAccountDetails, 'Account Details', 'top', ToastStyle.INFO);
      this._isDisabledGoToAccountsToolBtn = true;
      this.initPageTitle();
    }
  }

  handleGoToTerritoryMgmtDetails() {
    this.navService.popChildNavPageWithPageTracking().then(() => {
      this.callbackEvent();
    });
    this.navService.setChildNavRightPaneView(true);
  } 
}
