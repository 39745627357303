import { Component, Output, EventEmitter, OnInit, OnDestroy, Input, ViewChild, ElementRef, HostListener, ChangeDetectorRef } from '@angular/core';
import { Presentation, Page, ContentMode } from '../../../classes/presentation/presentation.class';
import { PopoverController } from '@ionic/angular';
import { Events } from '@omni/events';
import { PresentationService } from '../../../services/presentation/presentation.service';
import { PresentationView } from '../../../services/ui/ui.service';
import { TrackService, TrackingEventNames } from '../../../services/logging/tracking.service';
import { CarouselPopover } from './io-carousel-popover'
import { DeviceService } from '../../../services/device/device.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { IoFileService } from '../../../services/io-file-service/io-file-service';
import { NavigationService, PageName } from '../../../services/navigation/navigation.service';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { SafePipe } from '../../../pipes/safe.pipe';
import { Resource } from '../../../classes/resource/resource.class';
import { AuthenticationService } from '@omni/services/authentication.service';
import { FeatureActionsMap } from '@omni/classes/authentication/user.class';


const DEFAULT_SLIDES_PER_VIEW = 6;

@Component({
    selector: 'io-carousel',
    templateUrl: 'io-carousel.html',
  styleUrls:['io-carousel.scss'],
    providers: [SafePipe]
})
export class IOCarousel implements OnInit, OnDestroy {

    private _presPages: Page[]; //untouched pages
    public showGallery: boolean = false;
    public presPages: Page[];
    @Input()
    set pages(arg: Array<Page>) {
        this.presPages = arg;
        this._presPages = arg;
    }
    @Output() onButtonClick = new EventEmitter();

    @ViewChild('Slides', {static:true}) slides: HTMLIonSlidesElement;
    @ViewChild('carousel', { read: ElementRef, static:true }) carousel: ElementRef;
    @Input() viewMode: string;
    @Input() showGalleryBtn: boolean = false;
    @Input() selectedPageUrl: string;
    @Output() changeCarousel = new EventEmitter<any>(); //Emit when presentation is changed
    @Output() pageSelected = new EventEmitter<any>();   //Emit when new page is selected

    @Input() briefcase: Presentation[];

    public selectedView: any;
    public presView = PresentationView;
    public activePage: Page;
    public currentPageIndex: number;

    public selPageNumber: string = "1";
    public totalPages: number;
    public slidesPerView: number = DEFAULT_SLIDES_PER_VIEW;

    public keyMessages: any = [];
    public key: any = {};
    private allKeyMessages = {
        keyMessageId: '',
        keyMessageName: this.translate.instant('IO_CAROUSEL_ALL_KEY_MESSAGES'),
    };

    public thumbnailSrc: String = "";

    public contentMode = ContentMode;

    private _carouselSlideWidth: number;
    private _carouselToggleHandler = () => {
        // When un-hiding carousel, re-calculate slides per view and update the view.
        if (!this.presentationService.hideCarousel) {
            this.resizeCarousel();
            this.slides.update();

            //this.slides..resize();
        }
    };
    private _curPageForThisCarousel: number;


    private curSelPresSubscription: Subscription;
    private curSelPageSubscription: Subscription;

    private _changePresentation: (pres) => void;

    constructor(
        public presentationService: PresentationService,
        private events: Events,
        private trackingService: TrackService,
        public popoverCtrl: PopoverController,
        public deviceService: DeviceService,
        private sanitizer: DomSanitizer,
        private ioFile: IoFileService,
        private navService: NavigationService,
        public translate: TranslateService,
        private safePipe: SafePipe,
        private readonly authenticationService: AuthenticationService,
        public _cd: ChangeDetectorRef,
    ) {
        this.currentPageIndex = 1;
        this.curSelPresSubscription = this.presentationService.currentSelectedPres.subscribe(pres => {
            if (pres) {
                if (pres instanceof Presentation) {
                    if (this.selectedView != pres) {
                        //reset page number on new selected presentation
                        this.selPageNumber = "1";
                        if (pres) {
                            this.keyMessages = pres.keyMessages.slice();
                            this.key = this.allKeyMessages;
                            this.keyMessages.unshift(this.key);
                        }
                    }
                    this.selectedView = pres;
                    this.presPages = this.presentationService.presPages;
                    this.totalPages = this.presPages.length;
                } else {
                    this.selPageNumber = "1";
                    this.selectedView = pres;
                    this.presPages = this.presentationService.presPages;
                    this.totalPages = 1;

                }
            }
        });

        this.curSelPageSubscription = this.presentationService.currentSelectedPresPage.subscribe(page => {
            this.activePage = page;
            if (page) {
                const sequence: number = Number(page.pageSequence);
                if (sequence !== 0 && sequence === this.presentationService.presPages.length) {
                    setTimeout(() => {
                        this.slides.lockSwipes(false);
                        this.slides.slideTo(sequence);
                        this.slides.lockSwipes(true);
                    }, 700);
                } else {
                  setTimeout(() => {
                    this.slideTo(sequence);
                  }, 700);
                }
                this.selPageNumber = page.pageSequence;
                this.currentPageIndex = sequence;
            }
        });

        this.events.subscribe('pageSwipeChangeLeft', () => {
            console.log('Received event for page swipe left');

            let pageNum = this.currentPageIndex - 1;
            if (pageNum < 0) pageNum = this.totalPages - 1;
            this.setPageUrl(this.getPage(pageNum));

            console.log('currentPageIndex: ', this.currentPageIndex);
            console.log('totalPages: ', this.totalPages);
        });

        this.events.subscribe('pageSwipeChangeRight', () => {
            console.log('Received event for page swipe right');
            let pageNum = this.currentPageIndex + 1;
            this.setPageUrl(this.getPage(pageNum));

            console.log('currentPageIndex: ', this.currentPageIndex);
            console.log('totalPages: ', this.totalPages);
        });


        this.events.subscribe('footer-toolbar:carousel-toggle', this._carouselToggleHandler);
    }

    ngOnInit() {
        // if(this.viewMode === this.presView.MEETING) {
        //     this.slidesPerView = 10;
        // } else {
        //     this.slidesPerView = 6;
        // }

        if(this.authenticationService.hasFeatureAction(FeatureActionsMap.VIDEO_BUTTON) && this.showGalleryBtn){
          this.showGallery = true;
        }

        this._changePresentation = (pres, isPrevious?: boolean) => {
            console.log("Events Carousel: Change Presentation");
            if (pres) this.changeView(pres, isPrevious);
        }
        this.events.subscribe('changePresentation', this._changePresentation);

        this._curPageForThisCarousel = this.navService.getCurrentPageName();
        this.resizeCarousel();
    }

    ngOnDestroy() {
        this.events.unsubscribe('footer-toolbar:carousel-toggle', this._carouselToggleHandler);
        this.events.unsubscribe('pageSwipeChangeLeft');
        this.events.unsubscribe('pageSwipeChangeRight');
        // this.events.unsubscribe('changePresentation');
        this.events.unsubscribe('changePresentation', this._changePresentation);

        this.curSelPresSubscription.unsubscribe();
        this.curSelPageSubscription.unsubscribe();
    }

    @HostListener('window:resize', ["event"])
    onResize(event) {
        this.resizeCarousel();
    }

    resizeCarousel() {

        let width = window.innerWidth;
        let numSlidesPerView = DEFAULT_SLIDES_PER_VIEW;

        switch (this.viewMode) {
            case this.presView.FULLSCREEN:
            case this.presView.MEETING:
                break;
            case this.presView.ADDTOMEETING:
            case this.presView.ADDTOEVENT:
            case this.presView.MENU:
                width = window.innerWidth * 0.60 - 20;
                numSlidesPerView = DEFAULT_SLIDES_PER_VIEW - Math.floor(DEFAULT_SLIDES_PER_VIEW * 0.4);
                break;
            default:
                break;
        }

        this._setCarouselSlideWidth(numSlidesPerView);
        this.slidesPerView = Math.ceil(width / this._carouselSlideWidth) + (this.deviceService.isOrientationPortrait() ? 0 : 1);
        this._cd.detectChanges();
    }

    private _setCarouselSlideWidth(slidesPerView: number) {
        // Set the width of one slide in the carousel.
        //
        let shorterSideSize;
        if (window.innerWidth < window.innerHeight) {
            // portrait
            shorterSideSize = window.innerWidth;
        } else {
            // landscape
            if (this._curPageForThisCarousel === PageName.PresentationPreviewComponent) {
                shorterSideSize = window.innerHeight + 80;
            } else {
                shorterSideSize = window.innerHeight;
            }
        }

        this._carouselSlideWidth = Math.ceil(shorterSideSize / slidesPerView);
    }

    /**
     * Initialize swiper
     */
    init() {
        // let slidePerView = this.viewMode == "meeting" ? 10 : 6;
        // console.log("init swiper");
        // // setTimeout(() => {
        //     let slideCount = window.innerWidth;

        //     let swiperOptions = {
        //         slidesPerView: slidePerView,
        //         spaceBetween: 10,
        //         observer: true,
        //         speed: 600,
        //         navigation: {
        //             nextEl: '.swiper-button-next',
        //             prevEl: '.swiper-button-prev',
        //         },
        //     }
        //     this.carouselSwiper = new Swiper('.swiper-container', swiperOptions);
        // // })
    }

    /*
     *  Call this function after doing changes to swiper child
     */
    updateCarousel() {
        setTimeout(() => {
            this.slides.update();
            // this.slides.lockSwipes(true); // put a lock on the swiper on init
            this.slides.slideTo(0);
        }, 300);
    }

    public slideTo(num: number) {
        if (!this.slides) return;

        this.slides.lockSwipes(false); //CWD-1487
        setTimeout(() => {
            this.slides.slideTo(num - 1); // num -1 due to the change in the page sequence by ckm , //CWD-1487
            // this.slides.lockSwipes(true); //CWD-1487
        }, 100);
    }

    public async setPageUrl(page: Page) {
      if (await this.presentationService.canChangeSlideOrPresentation()) {
        this.pageSelected.emit(page);
        this.selPageNumber = page.pageSequence ? page.pageSequence : "1";
        this.presentationService.setCurrentSelectedPresPage(page);

        //to detected non-tracing events on pdf
        this.events.publish("pdf-page-change-from-carousel");
        //CWD-1459
        this.slides.lockSwipes(false); // release the lock on the swiper
        // this.slides.lockSwipes(true); // put the swiper lock back on so it doesn't shift
      }
    }
    public removeSwiperLock(){
        //CWD-1459
        this.slides.lockSwipes(false); // on drag event unlock swiper
    }

    public changeView(view: Presentation | Resource, isPrevious?: boolean) {
        //get the slider to the first slide view on change
        this.slides.lockSwipes(false);
        this.slides.slideTo(0);
        // this.slides.lockSwipes(true);

        // if(view.value === "page") {
        //     this.presPages = this.presentationService.presPages;
        // }
        // else if (view.value === "resource") {
        //     this.presPages = this.presentationService.presResources;
        // } else if (view.value == "briefcase") {
        //     this.presPages = this.presentationService.presentation;
        // }
        this.trackingService.tracking('PresentationsFilter', TrackingEventNames.PRESENTATIONS);
        // this.selPageNumber = "1";
        this.selectedView = view;

        this.presentationService.setCurrentSelectedPres(view);
        if (this.presentationService.contentMode === ContentMode.PRESENTATION) {
            this.keyMessages = (<Presentation>view).keyMessages.slice();
            this.key = this.allKeyMessages;
            this.keyMessages.unshift(this.key);
            if (this.presentationService.presPages && this.presentationService.presPages.length > 0) {
                if (isPrevious) {
                    const len = this.presentationService.presPages.length;
                    this.presentationService.setCurrentSelectedPresPage(this.presentationService.presPages[len - 1]);
                } else {
                    this.presentationService.setCurrentSelectedPresPage(this.presentationService.presPages[0]);
                }
            }
        } else {
            this.presentationService.setCurrentSelectedPresPage(this.presentationService.presPages[0]);
        }
        this.updateCarousel();
        this.changeCarousel.emit(view);
    }

    public filterPages(key) {
        if (this.navService.getCurrentPageName() == PageName.PresentationMeetingComponent) this.trackingService.tracking('KeyMessagesFilterClicked', TrackingEventNames.DIGITAL)
        else this.trackingService.tracking('PresentationKeyMessage', TrackingEventNames.PRESENTATIONS)
        if (key.keyMessageName === this.translate.instant('IO_CAROUSEL_ALL_KEY_MESSAGES')) {
            this.presPages = this._presPages;
        } else {
            // if(!key) return;
            this.presPages = this._presPages.filter(page => {
                if (page.keyMessage) {
                    if (page.keyMessage.some(x => x.keyMessageId === key.keyMessageId)) {
                        return page;
                    }
                }
            });
            this.updateCarousel();
        }
    }

    public async openDropdown(event) {
        let popover = await this.popoverCtrl.create({component: CarouselPopover, event:event});
        popover.present();
        this.trackingService.tracking('PresentationsFilterClicked', TrackingEventNames.DIGITAL)
    }

    private getPage(index: any) {
        return this.selectedView.presentationPages.find(x => x.pageSequence === index);
    }

    private updateCurrentPageNumber(x: any) {
        this.currentPageIndex = x;
    }

    public thumbURL(presPage: Page) {
        let pres = this.presentationService.activePresentation;
        if (pres && this.deviceService.isOffline && pres.downloaded) {
            let thumbFileName = presPage.thumbnail;
            let url = null;
            if (presPage.contentTypeResource) {
                thumbFileName = thumbFileName.substr(thumbFileName.lastIndexOf('/') + 1);
                url = this.ioFile.getLocalURLForResource(presPage.id, "thumbnail/" + thumbFileName);
            } else {
                if (thumbFileName) {
                    var pos = thumbFileName.lastIndexOf('/');
                    thumbFileName = thumbFileName.substring(pos + 1);
                } else {
                    thumbFileName = `${presPage.pageSequence}.png`;
                }
                url = this.ioFile.getLocalURL("thumbnail/" + this.presentationService.getThumbFileName() + '/' + thumbFileName);
            }
            return this.safePipe.transform(url, 'url');
        }
        return presPage.thumbnail;
    }

    public imgLoadCheck(ev: any, presPage: any) {
      const thumbnailId = presPage && presPage.thumbnail ? 'thumbnail'+ presPage.id : '';
      if(ev && ev.target && thumbnailId) {
        const currentThumbnail = document.getElementById(thumbnailId);
        const isDefaultImg = ev.target.currentSrc && ev.target.currentSrc.includes('thumbnail_default');
        if(!isDefaultImg && currentThumbnail) {
          presPage.isLoaded = true;
        }

      }
    }
}
