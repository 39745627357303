import { Component, Input, Output, EventEmitter, HostBinding } from '@angular/core';
import { MainCardViewDataModel } from '../../../../models/MainCardViewDataModel';
import { TranslateService } from '@ngx-translate/core';

const DefaultPrimaryTxtWidth = '40%';
const DefaultFirstSecondaryTxtWidth = '60%';
const DefaultSecondSecondaryTxtWidth = '80%';

@Component({
  selector: 'main-card',
  templateUrl: 'main-card.html',
  styleUrls:['main-card.scss']
})
export class MainCardComponent {
  defaultPrimaryTxtWidth = DefaultPrimaryTxtWidth;
  defaultFirstSecondaryTxtWidth = DefaultFirstSecondaryTxtWidth;
  defaultSecondSecondaryTxtWidth = DefaultSecondSecondaryTxtWidth;

  @Input() isSkeleton: boolean = false;
  @Input() skeletonConfig: {
                              primaryTxtWidth: string,
                              firstSecondaryTxtWidth: string,
                              secondSecondaryTxtWidth: string
                            }
                          = {
                              primaryTxtWidth: DefaultPrimaryTxtWidth,
                              firstSecondaryTxtWidth: DefaultFirstSecondaryTxtWidth,
                              secondSecondaryTxtWidth: DefaultSecondSecondaryTxtWidth
                          };
  @Input() viewData: MainCardViewDataModel;
  @Input() isDeviceOnline: boolean = true;
  @Output() onEndIconClick = new EventEmitter<any>();
  labelClassName: string;

  constructor(public translate: TranslateService) {
  }

  public ngOnInit() {
    this.labelClassName = (this.viewData && this.viewData.enableCopyPaste)?(this.viewData && !this.viewData.isAccordionForm)?'text-wrapper enableCopyPaste':'accordion-text-wrapper enableCopyPaste':(this.viewData && !this.viewData.isAccordionForm)?'text-wrapper':'accordion-text-wrapper'
    if(this.viewData && this.viewData.noLeftMarginLabel) this.labelClassName += ' noLeftMargin';
  }

  public handleMainItemClick(event, specificClickTarget?: string) {
    if (this.viewData.clickHandler) {
      this.viewData.clickHandler(this.viewData.id, event, specificClickTarget, this.viewData);
      try {
        if (event) {
          event.stopPropagation();
        }
      } catch (error) {
        // Handle Error To Do
      }
    }
  }
}
