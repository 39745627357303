import { transition } from '@angular/animations';
import { IndSectionHeaderViewDataModel } from './../../models/indSectionHeaderDataModel';
import { AuthenticationService } from './../authentication.service';
import { TranslateService } from '@ngx-translate/core';
import fuse from 'fuse.js';
import { Injectable } from "@angular/core";
import { searchIndexDataModel, SelectedSuggestionPillDataModel, SuggestionPillType, UserSavedSearchTypes, UserSearchOfflineDTO } from "./../../models/search-config-data-model";
import _ from "lodash";
import { IndAdvancedSearchResultSectionHeader } from '../../models/indSectionHeaderDataModel';
import { MainCardViewDataModel } from '../../models/MainCardViewDataModel';
import { ConsentService } from '../consent/consent.service';
import { ScientificActivityService } from '../scientific-activity/scientific-activity.service';
import { LocalizationService } from '../localization/localization.service';
import { FeatureActionsMap } from '../../classes/authentication/user.class';
import { DiskService, OFFLINE_DATA_COUNT_ENTITY_NAME } from '../disk/disk.service';
import {MyAssistantService, NOTIFICATION} from "../../services/my-assistant/my-assistant.service";
import { AttributeXMLTag, EntityXMLTag, FetchXMLTag, FilterConditionXMLTag, FilterXMLTag, LinkedEntityXMLTag, OrderXMLTag } from '../../interfaces/fetch-xml/fetch-xml.interface';
import { FilterConditionOperators } from '../../enums/fetch-xml/fetch-xml.enum';
import {IndNotificationDataModel} from "@omni/models/indNotificationDataModel";
import { ActivityType } from '@omni/classes/activity/activity.class';
import { GlobalUtilityService } from '../global-utility.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearchConfigService {
  public contactsSearchIndexesConfig: searchIndexDataModel[];
  public accountsSearchIndexesConfig: searchIndexDataModel[];
  public resourcesSearchIndexesConfig: searchIndexDataModel[];
  public presentationsSearchIndexesConfig: searchIndexDataModel[];
  public contentsSearchIndexesConfig: searchIndexDataModel[];
  public emailTemplatesSearchIndexesConfig: searchIndexDataModel[];
  public myTimeOffSearchIndexesConfig: searchIndexDataModel[];
  public teamTimeOffSearchIndexesConfig: searchIndexDataModel[];
  public myCallPlansSearchIndexesConfig: searchIndexDataModel[];
  public myGoalsPlansSearchIndexesConfig: searchIndexDataModel[];
  public teamCallPlansSearchIndexesConfig: searchIndexDataModel[];
  public myCoachingSearchIndexesConfig: searchIndexDataModel[];
  public teamCoachingSearchIndexesConfig: searchIndexDataModel[];
  public medicalInsightsSearchIndexsConfig : searchIndexDataModel[];
  public isAllocationsMappedToContacts: boolean = false;
  public isConsentsMappedToContacts: boolean = false;
  public isSAPActivitiesMappedToContacts: boolean = false;
  public isOpportunitesMappedToAccounts: boolean = false;
  public isTherapeuticAreasMappedToPresentations: boolean = false;
  public isContactProfileDataMappedToContacts:boolean = false;
  public isConfigInitiated: boolean = false;
  public configUpdateRequired: boolean = true;
  public toolNames:{
    toolName:string,
    searchToolNameID:string
  }[] = [];
  public savedSearches: UserSearchOfflineDTO[] = [];
  private _selectedSavedSharedSuggestionId: string = '';
  private _searchToolID:string = '';

  public linkedEntityWeight: {
    entity: string,
    weight: number,
  }[] = []
  public contactConfiguredSearchIndexConfig: searchIndexDataModel[] = [];
  public contactConfiguredFormDefaultConfigs: searchIndexDataModel[] = [];
  public accountConfiguredSearchIndexConfig: searchIndexDataModel[] = [];
  public customerPositionListContactsConfiguredSearchIndexConfig: searchIndexDataModel[] = [];
  private searchNotificationModel: IndNotificationDataModel;
  public timelineFilterMenuList: any= [];
  public savedSearchesBehaviorSubject = new BehaviorSubject<UserSearchOfflineDTO[]>(null);
  public savedSearchesObserver = this.savedSearchesBehaviorSubject.asObservable();

  public onToggleChildItemBehaviorSubject = new BehaviorSubject<any>(null);
  public onToggleChildItemObserver = this.onToggleChildItemBehaviorSubject.asObservable();
  public childUsersWithPositionsViewData;
  public isTargetContactsPillRemoved: boolean = false;

  constructor(
    public translate : TranslateService,
    public authService: AuthenticationService,
    private disk: DiskService,
    public myAssistantService:MyAssistantService,
    public utilityService: GlobalUtilityService,
  ){

  }

  initSearchConfigs(){
    this.contactConfiguredSearchIndexConfig = [
      {
        categoryName: 'My_Activities',
        translationKey: 'MY_ACTIVITIES',
        categoryRelativePath:'myActivitiesByContact',
        controlDataType: 'DefaultConfig',
        values:[],
        mappingValues: [],
        isFixedFilter: true,
        filterOrder: '1'
      },
      {
        categoryName: 'Meetings_Status',
        translationKey: 'FILTER_MEETINGS_STATUS',
        categoryRelativePath:'filterMeetingsStatus',
        controlDataType: 'DefaultConfig',
        values:[],
        mappingValues: [],
        isFixedFilter: true,
        filterOrder: '2',
        isForcedHide: true,
        isSubCategory: true,
      },
      {
        categoryName: 'Messages_Status',
        translationKey: 'FILTER_MESSAGES_STATUS',
        categoryRelativePath:'filterMessagesStatus',
        controlDataType: 'DefaultConfig',
        values:[],
        mappingValues: [],
        isFixedFilter: true,
        filterOrder: '3',
        isForcedHide: true,
        isSubCategory: true,
      },
      {
        categoryName: 'Messages_Attachment',
        translationKey: 'FILTER_MESSAGES_ATTACHMENT',
        categoryRelativePath:'filterMessagesAttachment',
        controlDataType: 'DefaultConfig',
        values:[],
        mappingValues: [],
        isFixedFilter: true,
        filterOrder: '4',
        isForcedHide: true,
        isSubCategory: true,
      },
      {
        categoryName: 'Call Plans',
        translationKey: 'CALL_PLANS',
        categoryRelativePath:'repCallPlansByContact.segmentCallPlanName',
        controlDataType: 'DefaultConfig',
        values:[],
        mappingValues: [],
        customizedBypassGateEnabled: true,
        attributeName: 'indskr_segment_v2name',
        entity: 'indskr_customercallplan',
        parentEntity: 'indskr_customercallplan',
      },
      {
        categoryName: 'Scientific Plans',
        translationKey: 'SCIENTIFIC_PLANS',
        categoryRelativePath:'scientificActivityPlanName',
        controlDataType: 'DefaultConfig',
        values:[],
        mappingValues: [],
        customizedBypassGateEnabled: true,
        attributeName: 'indskr_name',
        parentEntity: "indskr_indskr_scientificplan_contact",
        entity: 'indskr_scientificplan',
        featureActionDependant: true,
        featureActionName: FeatureActionsMap.SCIENTIFIC_TOOL_PLAN
      },
      {
        categoryName: 'Products',
        translationKey: 'PRODUCTS',
        categoryRelativePath:'productSegmentations.productName',
        controlDataType: 'DefaultConfig',
        values:[],
        mappingValues: [],
        customizedBypassGateEnabled: true,
        attributeName: 'indskr_productname',
        entity: 'indskr_productrating',
        parentEntity: 'indskr_productrating'
      },
      {
        categoryName: 'Segments',
        translationKey: 'SEGMENTS',
        categoryRelativePath:'productSegmentations.segmentation',
        controlDataType: 'DefaultConfig',
        values:[],
        mappingValues: [],
        customizedBypassGateEnabled: true,
        attributeName: 'indskr_customersegmentationidname',
        entity: 'indskr_productrating',
        parentEntity: 'indskr_productrating'
      },
      {
        categoryName: 'Allocations',
        translationKey: 'ACTIVITY_ALLOCATIONS',
        categoryRelativePath:'contactAllocations.sampleSKUName',
        controlDataType: 'DefaultConfig',
        values:[],
        mappingValues: [],
        customizedBypassGateEnabled: true,
        attributeName: 'indskr_sampleproductidname',
        entity: 'indskr_customersampleproduct',
        parentEntity: 'indskr_customersampleproduct',
        featureActionDependant: true,
        featureActionName : FeatureActionsMap.ALLOCATION_TOOL
      },
      {
        categoryName: 'Consent Channels',
        translationKey: 'CONSENT_CHANNELS',
        categoryRelativePath:'activeConsents.channels.indskr_consentType',
        controlDataType: 'DefaultConfig',
        values:[],
        mappingValues: [],
        attributeName: 'indskr_consenttype',
        entity: 'indskr_consent',
        parentEntity: 'indskr_consent',
        featureActionDependant: true,
        featureActionName: FeatureActionsMap.CONSENT_TOOL
      },
      {
        categoryName: 'Consent Terms',
        translationKey: 'CONTACT_CONSENT_TERMS',
        categoryRelativePath:'activeConsents.indskr_consentTermName',
        controlDataType: 'DefaultConfig',
        values:[],
        mappingValues: [],
        attributeName: 'indskr_consenttermsname',
        entity: 'indskr_consent',
        parentEntity: 'indskr_consent',
        featureActionDependant: true,
        featureActionName: FeatureActionsMap.CONSENT_TOOL
      },
      {
        categoryName: 'Consent Products',
        translationKey: 'CONSENT_PRODUCTS',
        categoryRelativePath:'activeConsents.products.indskr_productname',
        controlDataType: 'DefaultConfig',
        values:[],
        mappingValues: [],
        attributeName: 'indskr_productname',
        entity: 'indskr_consent',
        parentEntity: 'indskr_consent',
        featureActionDependant: true,
        featureActionName: FeatureActionsMap.CONSENT_TOOL
      },
      {
        categoryName: 'Active Consents',
        translationKey: 'CONTACT_ACTIVE_CONSENTS',
        categoryRelativePath:'hasActiveConsent',
        controlDataType: 'DefaultConfig',
        values:[],
        mappingValues: [],
        isBooleanTypeCategory:true,
        attributeName: 'statecode',
        entity: 'indskr_consent',
        parentEntity: 'indskr_consent',
        featureActionDependant: true,
        featureActionName: FeatureActionsMap.CONSENT_TOOL
      },
      {
        categoryName: 'Teamusers',
        translationKey: 'TEAM_USERS',
        categoryRelativePath:'hasTeamusers',
        controlDataType: 'DefaultConfig',
        values:[],
        mappingValues: [],
        isBooleanTypeCategory:true,
        isSingleSelect:true
      },
    ];
    this.contactConfiguredFormDefaultConfigs = [
      {
        categoryName:'indskr_indskr_customeraddress_v2',
        translationKey:'STREET_1_ADDRESS',
        controlDataType: 'LinkedEntity',
        categoryRelativePath:'indskr_address.indskr_line1',
        values:[],
        mappingValues: [],
        entity: 'indskr_address',
        parentEntity: 'indskr_indskr_customeraddress_v2',
        numOfLinkEntity:3
      },
      {
        categoryName:'indskr_indskr_customeraddress_v2',
        translationKey:'CITY',
        controlDataType: 'LinkedEntity',
        categoryRelativePath:'indskr_address.indskr_city_lu',
        values:[],
        mappingValues: [],
        entity: 'indskr_address',
        parentEntity: 'indskr_indskr_customeraddress_v2',
        numOfLinkEntity:3
      },
      {
        categoryName:'indskr_indskr_customeraddress_v2',
        translationKey:'STATE_OR_PROVINCE',
        controlDataType: 'LinkedEntity',
        categoryRelativePath:'indskr_address.indskr_state_lu',
        values:[],
        mappingValues: [],
        entity: 'indskr_address',
        parentEntity: 'indskr_indskr_customeraddress_v2',
        numOfLinkEntity:3
      },
      {
        categoryName:'indskr_indskr_customeraddress_v2',
        translationKey:'POSTAL_CODE',
        controlDataType: 'LinkedEntity',
        categoryRelativePath:'indskr_address.indskr_postalcode_lu',
        values:[],
        mappingValues: [],
        entity: 'indskr_address',
        parentEntity: 'indskr_indskr_customeraddress_v2',
        numOfLinkEntity:3
      },
      {
        categoryName:'indskr_indskr_customeraddress_v2',
        translationKey:'COUNTRY',
        controlDataType: 'LinkedEntity',
        categoryRelativePath:'indskr_address.indskr_country_lu',
        values:[],
        mappingValues: [],
        entity: 'indskr_address',
        parentEntity: 'indskr_indskr_customeraddress_v2',
        numOfLinkEntity:3
      },
    ]
    this.contactsSearchIndexesConfig = [
      {
        categoryName:'City',
        translationKey:'CITY',
        categoryRelativePath:'addressesList.city',
        values:[],
        attributeName: 'indskr_city_luname',
        entity: 'indskr_address',
        parentEntity: 'indskr_indskr_customeraddress_v2'
      },
      {
        categoryName:'State',
        translationKey:'STATE',
        categoryRelativePath:'addressesList.state',
        values:[],
        entity: 'indskr_address',
        attributeName: 'indskr_state_luname',
        parentEntity: 'indskr_indskr_customeraddress_v2'
      },
      {
        categoryName:'Postal Code',
        translationKey:'POSTAL_CODE',
        categoryRelativePath:'addressesList.postal',
        values:[],
        entity: 'indskr_address',
        attributeName: 'indskr_postalcode_luname',
        parentEntity: 'indskr_indskr_customeraddress_v2'
      },
      {
        categoryName:'Country',
        categoryRelativePath:'addressesList.country',
        translationKey: "COUNTRY",
        values:[],
        entity: 'indskr_lu_country',
        attributeName: 'indskr_countryname',
        parentEntity: 'indskr_indskr_customeraddress_v2'
      },
      {
        categoryName:'Regions',
        translationKey: 'REGIONS',
        categoryRelativePath:'addressesList.region',
        values:[],
        entity: 'indskr_address',
        attributeName: 'indskr_luregionname',
        parentEntity: 'indskr_indskr_customeraddress_v2'
      },
      {
        categoryName:'Phone Number',
        translationKey: "ACCOUNT_PHONE_NUMBER",
        categoryRelativePath:'allPhoneNumbers',
        values:[],
        attributeName: 'phone_number',
        entity: 'phoneNumber',
        parentEntity: '',
      },
      {
        categoryName:'Verification',
        translationKey: "VERIFICATION",
        categoryRelativePath:'verificationStatus',
        values:[],
        entity: '',
        attributeName: 'statuscode',
        parentEntity: 'contact'
      },
      {
        categoryName: 'Accounts',
        translationKey: "ACCOUNTS",
        categoryRelativePath:'accountRelationships.accountName',
        values:[],
        attributeName: 'name',
        entity: 'account',
        parentEntity: 'indskr_accountcontactaffiliation'
      },
      {
        categoryName: 'Coverage Team',
        translationKey: 'COVERAGE_TEAM',
        categoryRelativePath:'customerPositions.position',
        values:[],
        attributeName: 'indskr_positionidname',
        entity: 'indskr_customerposition',
        parentEntity: 'indskr_customerposition',
      },
      {
        categoryName: 'Relationships',
        translationKey: 'XPERIENCES_RELATIONSHIPS',
        categoryRelativePath:'contactRelationships.contactName',
        values:[],
        attributeName: 'indskr_relatedcontactidname',
        entity: 'indskr_contactrelationship',
        parentEntity: 'indskr_contactrelationship',
      },
      {
        categoryName: 'Call Plans',
        translationKey: 'CALL_PLANS',
        categoryRelativePath:'repCallPlansByContact.segmentCallPlanName',
        values:[],
        customizedBypassGateEnabled: true,
        attributeName: 'indskr_segment_v2name',
        entity: 'indskr_customercallplan',
        parentEntity: 'indskr_customercallplan',
      },
      {
        categoryName: 'Specialty',
        translationKey: 'SPECIALITY',
        categoryRelativePath:'specialties.name',
        values:[],
        attributeName: 'indskr_specialtyidname',
        entity: 'indskr_customerspecialty',
        parentEntity: 'indskr_customerspecialty',
      },
      {
        categoryName: 'Designation',
        translationKey: 'DESIGNATION',
        categoryRelativePath:'professionalDesignation.designation',
        values:[],
        attributeName: 'indskr_professionaldesignation',
        entity: 'designation',
        parentEntity: 'indskr_lu_professional_designation',
      },
      {
        categoryName: 'Products',
        translationKey: 'PRODUCTS',
        categoryRelativePath:'productSegmentations.productName',
        values:[],
        customizedBypassGateEnabled: true,
        attributeName: 'indskr_productname',
        entity: 'indskr_productrating',
        parentEntity: 'indskr_productrating',
      },
      {
        categoryName: 'Segments',
        translationKey: 'SEGMENTS',
        categoryRelativePath:'productSegmentations.segmentation',
        values:[],
        customizedBypassGateEnabled: true,
        attributeName: 'indskr_customersegmentationidname',
        entity: 'indskr_productrating',
        parentEntity: 'indskr_productrating',
      },
      {
        categoryName: 'Scientific Plans',
        translationKey: 'SCIENTIFIC_PLANS',
        categoryRelativePath:'scientificActivityPlanName',
        values:[],
        customizedBypassGateEnabled: true,
        attributeName: 'indskr_name',
        entity: 'indskr_scientificplan',
        parentEntity: 'indskr_indskr_scientificplan_contact',
        featureActionDependant: true,
        featureActionName: FeatureActionsMap.SCIENTIFIC_TOOL_PLAN
      },
      {
        categoryName: 'Languages',
        translationKey: 'LANGUAGES',
        categoryRelativePath:'language',
        values:[],
        attributeName: 'indskr_languagename',
        entity: 'indskr_lu_language',
        parentEntity: 'indskr_lu_language',
      },
      {
        categoryName: 'Allocations',
        translationKey: 'ACTIVITY_ALLOCATIONS',
        categoryRelativePath:'contactAllocations.sampleSKUName',
        values:[],
        customizedBypassGateEnabled: true,
        attributeName: 'indskr_sampleproductidname',
        entity: 'indskr_customersampleproduct',
        parentEntity: 'indskr_customersampleproduct',
        featureActionDependant: true,
        featureActionName : FeatureActionsMap.ALLOCATION_TOOL
      },
      {
        categoryName: 'Consent Channels',
        translationKey: 'CONSENT_CHANNELS',
        categoryRelativePath:'activeConsents.channels.indskr_consentType',
        values:[],
        attributeName: 'indskr_consenttype',
        entity: 'indskr_consent',
        parentEntity: 'indskr_consent',
        featureActionDependant: true,
        featureActionName: FeatureActionsMap.CONSENT_TOOL
      },
      {
        categoryName: 'Consent Terms',
        translationKey: 'CONTACT_CONSENT_TERMS',
        categoryRelativePath:'activeConsents.indskr_consentTermName',
        values:[],
        attributeName: 'indskr_consenttermsname',
        entity: 'indskr_consent',
        parentEntity: 'indskr_consent',
        featureActionDependant: true,
        featureActionName: FeatureActionsMap.CONSENT_TOOL
      },
      {
        categoryName: 'Consent Products',
        translationKey: 'CONSENT_PRODUCTS',
        categoryRelativePath:'activeConsents.products.indskr_productname',
        values:[],
        attributeName: 'indskr_productname',
        entity: 'indskr_consent',
        parentEntity: 'indskr_consent',
        featureActionDependant: true,
        featureActionName: FeatureActionsMap.CONSENT_TOOL
      },
      {
        categoryName: 'Active Consents',
        translationKey: 'CONTACT_ACTIVE_CONSENTS',
        categoryRelativePath:'hasActiveConsent',
        values:['Yes', 'No'],
        isBooleanTypeCategory:true,
        attributeName: 'statecode',
        entity: 'indskr_consent',
        parentEntity: 'indskr_consent',
        featureActionDependant: true,
        featureActionName: FeatureActionsMap.CONSENT_TOOL
      },
      {
        categoryName:'KOL',
        translationKey: 'CONTACT_KOL_CAP',
        categoryRelativePath:'isKOL',
        values:['Yes','No'],
        isBooleanTypeCategory:true,
        attributeName: 'indskr_kol',
        entity: '',
        parentEntity: 'contact',
      },
      {
        categoryName:'Speaker',
        translationKey: 'SPEAKER',
        categoryRelativePath:'isSpeaker',
        values:['Yes','No'],
        isBooleanTypeCategory:true,
        attributeName: 'indskr_speaker',
        entity: '',
        parentEntity: 'contact',
      },
      {
        categoryName:'Investigator',
        translationKey:'CONTACT_INVESTIGATOR',
        categoryRelativePath:'investigatorString',
        values:['Yes','No'],
        isBooleanTypeCategory:true,
        attributeName: 'ind_investigator',
        entity: '',
        parentEntity: 'contact',
      },
      {
        categoryName:'Grant Recipient',
        translationKey: 'CONTACT_GRANT_RECIPIENT',
        categoryRelativePath:'grantReceipientString',
        values:['Yes','No'],
        isBooleanTypeCategory:true,
        attributeName: 'ind_grantrecipient',
        entity: '',
        parentEntity: 'contact',
      },
      {
        categoryName:'Email Address',
        translationKey: 'EMAIL_ADDRESS',
        categoryRelativePath:'emailAddressList.emailAddress',
        values:[],
        attributeName: 'indskr_emailaddress',
        entity: 'indskr_email_address',
        parentEntity: 'indskr_email_address',
      },
      {
        categoryName: 'Contact Affiliations',
        translationKey: 'CONTACT_AFFILIATIONS',
        categoryRelativePath: '',
        values:[],
        isThisToBeAggregated: true,
        searchAttributeNames: [
          'indskr_contactrelationship.indskr_contactid',
          'indskr_contactrelationship.indskr_relatedcontactid'
        ]
      },
      {
        categoryName: 'Account Affiliations',
        translationKey: 'ACCOUNT_AFFILIATIONS',
        categoryRelativePath: '',
        values:[],
      },
    ];
    this.accountConfiguredSearchIndexConfig = [
      {
        categoryName: 'Opportunities',
        translationKey: 'OPPORTUNITIES',
        categoryRelativePath:'opportunityName',
        values:[],
        mappingValues: [],
        featureActionDependant: true,
        featureActionName: FeatureActionsMap.OPPORTUNITY_MANAGEMENT
      },
      {
        categoryName: 'Teamusers',
        translationKey: 'TEAM_USERS',
        categoryRelativePath:'hasTeamusers',
        controlDataType: 'DefaultConfig',
        values:[],
        mappingValues: [],
        isBooleanTypeCategory:true,
        isSingleSelect:true
      }
    ];
    this.accountsSearchIndexesConfig = [
      {
        categoryName: 'Products',
        translationKey: 'PRODUCTS',
        categoryRelativePath:'products[0].productName',
        values:[],
      },
      {
        categoryName:'City',
        translationKey:'CITY',
        categoryRelativePath:'accountAddressList.city',
        values:[],
      },
      {
        categoryName:'State',
        translationKey:'STATE',
        categoryRelativePath:'accountAddressList.state',
        values:[],
      },
      {
        categoryName:'Postal Code',
        translationKey:'POSTAL_CODE',
        categoryRelativePath:'accountAddressList.postalCode',
        values:[],
      },
      {
        categoryName:'Country',
        translationKey: "COUNTRY",
        categoryRelativePath:'accountAddressList.country',
        values:[],
      },
      {
        categoryName:'Regions',
        translationKey: 'REGIONS',
        categoryRelativePath:'accountAddressList.region',
        values:[],
      },
      {
        categoryName:'Phone Number',
        translationKey: "ACCOUNT_PHONE_NUMBER",
        categoryRelativePath:'telephone',
        values:[],
      },
      {
        categoryName:'Email Address',
        translationKey: 'EMAIL_ADDRESS',
        categoryRelativePath:'EmailAddresses.emailAddress',
        values:[],
      },
      {
        categoryName: 'Related Accounts',
        translationKey: 'ACCOUNT_RELATED_ACCOUNTS_CAMELCASE',
        categoryRelativePath:'affiliatedAccounts.accountName',
        values:[],
      },
      {
        categoryName: 'Coverage Team',
        translationKey: 'COVERAGE_TEAM',
        categoryRelativePath:'coveragePosistionList.positionName',
        values:[],
      },
      {
        categoryName: 'Customer Affiliations',
        translationKey: 'ACCOUNT_CUSTOMER_AFFILIATIONS_CAMELCASE',
        categoryRelativePath:'relatedContacts.contactName',
        values:[],
      },
      {
        categoryName:'Sole Community Hospital',
        translationKey: 'ACCOUNT_SOLE_COMMUNITY_HOSPITAL',
        categoryRelativePath:'extendedDetails.soleCommunityHospital',
        values:['Yes', 'No'],
        isBooleanTypeCategory: true
      },
      {
        categoryName: 'Opportunities',
        translationKey: 'OPPORTUNITIES',
        categoryRelativePath:'opportunityName',
        values:[],
        featureActionDependant: true,
        featureActionName: FeatureActionsMap.OPPORTUNITY_MANAGEMENT
      },
      {
        categoryName: 'Account Affiliations',
        translationKey: 'ACCOUNT_AFFILIATIONS',
        categoryRelativePath: '',
        values:[],
        isThisToBeAggregated: true,
        searchAttributeNames: [
          'indskr_accountaccountaffiliation.indskr_affiliatedfromaccountid',
          'indskr_accountaccountaffiliation.indskr_affiliatedtoaccountid'
        ]
      },
      {
        categoryName: 'Contact Affiliations',
        translationKey: 'CONTACT_AFFILIATIONS',
        categoryRelativePath: '',
        values:[],
      },
    ];
    this.resourcesSearchIndexesConfig = [
      {
        categoryName: 'Products',
        translationKey: 'PRODUCTS',
        categoryRelativePath: 'brands',
        values:[]
      },
      {
        categoryName: 'Therapeutic Areas',
        translationKey: 'THERAPEUTIC_AREAS',
        categoryRelativePath: 'therapeuticAreas.therapeuticAreaName',  //'raw.brands.therapeuticAreas.therapeuticAreaName',
        values:[],
        featureActionDependant: true,
        featureActionName: FeatureActionsMap.THERAPEUTIC_AREA
      },
      {
        categoryName: 'Specialties',
        translationKey: 'SPECIALTIES',
        categoryRelativePath: 'specialties.name',
        values:[]
      },
      {
        categoryName: 'Types',
        translationKey: 'TYPES',
        categoryRelativePath: 'assetType',
        values:[]
      },
      {
        categoryName: 'Disease State',
        translationKey: 'DISEASE_STATE',
        categoryRelativePath: 'diseaseStates.diseaseStateName',
        values:[]
      },
    ];
    this.presentationsSearchIndexesConfig = [
      {
        categoryName: 'Products',
        translationKey: 'PRODUCTS',
        categoryRelativePath: 'brands.name',
        values:[]
      },
      {
        categoryName: 'Therapeutic Areas',
        translationKey: 'THERAPEUTIC_AREAS',
        categoryRelativePath: 'presentationTherapeuticAreas.indskr_name',
        values:[],
        featureActionDependant: true,
        featureActionName: FeatureActionsMap.THERAPEUTIC_AREA
      },
      {
        categoryName: 'Specialties',
        translationKey: 'SPECIALTIES',
        categoryRelativePath: 'specialties.name',
        values:[]
      },
      {
        categoryName: 'Languages',
        translationKey: 'LANGUAGES',
        categoryRelativePath:'languageName',
        values:[],
      },
      {
        categoryName: 'Disease State',
        translationKey: 'DISEASE_STATE',
        categoryRelativePath: 'diseaseStates.indskr_name',
        values:[]
      },
      {
        categoryName: 'Key Messages',
        translationKey: 'KEY_MESSAGES_LOWERCASE',
        categoryRelativePath: 'keyMessages.keyMessageName',
        values:[]
      },
      {
        categoryName: 'description',
        translationKey: 'DESCRIPTION',
        categoryRelativePath:'description',
        values:[],
      },
      {
        categoryName: 'Pages Name',
        translationKey: 'Pages Name',
        categoryRelativePath:'presentationPages.name',
        values:[],
      },
      {
        categoryName: 'Category',
        translationKey: 'CATEGORY',
        categoryRelativePath:'categoryName',
        values:[],
      },
    ];
    this.contentsSearchIndexesConfig = [
      {
        categoryName: 'Content Category',
        translationKey: 'CONTENT_CATEGORY',
        categoryRelativePath: 'category',
        values:[]
      },
      {
        categoryName: 'Products',
        translationKey: 'PRODUCTS',
        categoryRelativePath: 'brands.name' || 'brands',
        values:[]
      },
      {
        categoryName: 'Therapeutic Areas',
        translationKey: 'THERAPEUTIC_AREAS',
        categoryRelativePath: 'presentationTherapeuticAreas.indskr_name' || 'raw.brands.therapeuticAreas.therapeuticAreaName',
        values:[],
        featureActionDependant: true,
        featureActionName: FeatureActionsMap.THERAPEUTIC_AREA
      },
      {
        categoryName: 'Specialties',
        translationKey: 'SPECIALTIES',
        categoryRelativePath: 'specialties.name',
        values:[]
      },
      {
        categoryName: 'Languages',
        translationKey: 'LANGUAGES',
        categoryRelativePath:'languageName',
        values:[],
      },
      {
        categoryName: 'Disease State',
        translationKey: 'DISEASE_STATE',
        categoryRelativePath: 'diseaseStates.indskr_name',
        values:[]
      },
      {
        categoryName: 'Key Messages',
        translationKey: 'KEY_MESSAGES',
        categoryRelativePath: 'keyMessages.keyMessageName',
        values:[]
      },
      {
        categoryName: 'Types',
        translationKey: 'TYPES',
        categoryRelativePath: 'assetType',
        values:[]
      }
    ];
    this.emailTemplatesSearchIndexesConfig = [
      {
        categoryName: 'Products',
        translationKey: 'PRODUCTS',
        categoryRelativePath: 'productName',
        values:[]
      },
      {
        categoryName: 'Therapeutic Areas',
        translationKey: 'THERAPEUTIC_AREAS',
        categoryRelativePath: 'therapeuticAreaName',
        values:[],
        featureActionDependant: true,
        featureActionName: FeatureActionsMap.THERAPEUTIC_AREA
      },
    ];
    this.myTimeOffSearchIndexesConfig = [
      {
        categoryName: 'Status',
        translationKey: 'STATUS',
        categoryRelativePath:'statusValue',
        values:[],
      },
      {
        categoryName: 'Reasons',
        translationKey: 'REASONS',
        categoryRelativePath:'reason',
        values:[],
      },
      {
        categoryName: 'Types',
        translationKey: 'TYPES',
        categoryRelativePath:'durationType',
        values:[],
      },
    ];
    this.teamTimeOffSearchIndexesConfig = [
      {
        categoryName: 'Status',
        translationKey: 'STATUS',
        categoryRelativePath:'statusValue',
        values:[],
      },
      {
        categoryName: 'Reasons',
        translationKey: 'REASONS',
        categoryRelativePath:'reason',
        values:[],
      },
      {
        categoryName: 'Types',
        translationKey: 'TYPES',
        categoryRelativePath:'durationType',
        values:[],
      },
      {
        categoryName: 'Users',
        translationKey: 'USERS',
        categoryRelativePath:'totOwnerValue',
        values:[],
      }
    ]
    this.myCallPlansSearchIndexesConfig = [
      {
        categoryName: 'Products',
        translationKey: 'PRODUCTS',
        categoryRelativePath:'repPlans.productid_Formatted',
        values:[],
      },
      {
        categoryName: 'Customers',
        translationKey: 'CUSTOMERS',
        categoryRelativePath:'repPlans.customerName',
        values:[],
      },
      {
        categoryName: 'Accounts',
        translationKey: "ACCOUNTS",
        categoryRelativePath:'accountRelationships.accountName',
        values:[],
      attributeName: 'name',
      entity: 'account',
      parentEntity: 'indskr_accountcontactaffiliation'
      },
      {
        categoryName: 'Specialty',
        translationKey: 'SPECIALITY',
       categoryRelativePath: 'repPlans.specialtyid_Formatted',
       values:[],
      },
    ];
    this.myGoalsPlansSearchIndexesConfig = [
      {
        categoryName: 'Products',
        translationKey: 'PRODUCTS',
        categoryRelativePath: 'productid_Formatted',
        values: [],
      },
      {
        categoryName: 'Accounts',
        translationKey: "ACCOUNTS",
        categoryRelativePath: 'repPlans.accounts.accountName',
        values: [],
      },
    ];
    this.teamCallPlansSearchIndexesConfig = [
      {
        categoryName: 'Products',
        translationKey: 'PRODUCTS',
        categoryRelativePath:'repPlans.productid_Formatted',
        values:[],
      },
      {
        categoryName: 'Segments',
        translationKey: 'SEGMENTS',
        categoryRelativePath:'repPlans.segmentid_Formatted',
        values:[],
      },
      {
        categoryName: 'Customers',
        translationKey: 'CUSTOMERS',
        categoryRelativePath:'repPlans.customerName',
        values:[],
      },
      {
         categoryName: 'Accounts',
         translationKey: "ACCOUNTS",
         categoryRelativePath:'repPlans.accountName',
         values:[],
          },
      {
        categoryName: 'Users',
        translationKey: 'USERS',
        categoryRelativePath:'repPlans.users.fullName',
        values:[],
      },
      {
        categoryName: 'Positions',
        translationKey: 'POSITIONS',
        categoryRelativePath: 'repPlans.positionName',
        values:[],
      },
    ];
    this.myCoachingSearchIndexesConfig = [
      {
        categoryName: 'Status',
        translationKey: 'STATUS',
        categoryRelativePath:'statusName',
        values:[],
      },
      {
        categoryName: 'Coaching By',
        translationKey: 'COACHING_SEARCH_COACHING_BY',
        categoryRelativePath:'created_by',
        values:[],
      },
    ]
    this.teamCoachingSearchIndexesConfig = [
      {
        categoryName: 'Status',
        translationKey: 'STATUS',
        categoryRelativePath:'statusName',
        values:[],
      },
      {
        categoryName: 'Users',
        translationKey: 'COACHING_SEARCH_COACHING_FOR_USER',
        categoryRelativePath:'created_for',
        values:[],
      },
    ];
    this.medicalInsightsSearchIndexsConfig = [
      {
        categoryName: 'Owners',
        translationKey: 'OWNERS',
        categoryRelativePath: 'ownerNameString',
        values: [],
      },
    ]


    this.updateSearchWithConfiguredFields();
    this.initLinkedEntityWeight();
    //this.updateSearchConfigsForSelectedLanguage();
    this._initTimelineFilterMenuConfig();
  }

  updateSearchConfigsForSelectedLanguage(){
    this.contactConfiguredSearchIndexConfig.forEach(config=>{
      if(config.translationKey) config.categoryDisplayName = this.translate.instant(config.translationKey);
      else config.categoryDisplayName = config.categoryName;
    })
    this.accountConfiguredSearchIndexConfig.forEach(config=>{
      if(config.translationKey) config.categoryDisplayName = this.translate.instant(config.translationKey);
      else config.categoryDisplayName = config.categoryName;
    })
    // this.accountsSearchIndexesConfig.forEach(config=>{
    //   if(config.translationKey) config.categoryDisplayName = this.translate.instant(config.translationKey);
    //   else config.categoryDisplayName = config.categoryName;
    // })
    this.resourcesSearchIndexesConfig.forEach(config=>{
      if(config.translationKey) config.categoryDisplayName = this.translate.instant(config.translationKey);
      else config.categoryDisplayName = config.categoryName;
    })
    this.presentationsSearchIndexesConfig.forEach(config=>{
      if(config.translationKey) config.categoryDisplayName = this.translate.instant(config.translationKey);
      else config.categoryDisplayName = config.categoryName;
    })
    this.emailTemplatesSearchIndexesConfig.forEach(config=>{
      if(config.translationKey) config.categoryDisplayName = this.translate.instant(config.translationKey);
      else config.categoryDisplayName = config.categoryName;
    })
    this.contentsSearchIndexesConfig.forEach(config=>{
      if(config.translationKey) config.categoryDisplayName = this.translate.instant(config.translationKey);
      else config.categoryDisplayName = config.categoryName;
    })
    this.myTimeOffSearchIndexesConfig.forEach(config=>{
      if(config.translationKey) config.categoryDisplayName = this.translate.instant(config.translationKey);
      else config.categoryDisplayName = config.categoryName;
    })
    this.teamTimeOffSearchIndexesConfig.forEach(config=>{
      if(config.translationKey) config.categoryDisplayName = this.translate.instant(config.translationKey);
      else config.categoryDisplayName = config.categoryName;
    })
    this.myCallPlansSearchIndexesConfig.forEach(config=>{
      if(config.translationKey) config.categoryDisplayName = this.translate.instant(config.translationKey);
      else config.categoryDisplayName = config.categoryName;
    })
    // this.callPlanConfiguredSearchIndexConfig.forEach(config=>{
    //   if(config.translationKey) config.categoryDisplayName = this.translate.instant(config.translationKey);
    //   else config.categoryDisplayName = config.categoryName;
    // })
    this.myGoalsPlansSearchIndexesConfig.forEach(config => {
      if (config.translationKey) config.categoryDisplayName = this.translate.instant(config.translationKey);
      else config.categoryDisplayName = config.categoryName;
    })
    this.teamCallPlansSearchIndexesConfig.forEach(config=>{
      if(config.translationKey) config.categoryDisplayName = this.translate.instant(config.translationKey);
      else config.categoryDisplayName = config.categoryName;
    })
    this.myCoachingSearchIndexesConfig.forEach(config=>{
      if(config.translationKey) config.categoryDisplayName = this.translate.instant(config.translationKey);
      else config.categoryDisplayName = config.categoryName;
    })
    this.teamCoachingSearchIndexesConfig.forEach(config=>{
      if(config.translationKey) config.categoryDisplayName = this.translate.instant(config.translationKey);
      else config.categoryDisplayName = config.categoryName;
    })
  }

  ngOnInit() {
    console.log('search config service init')
  }


  mapToolNameData(raw){
    raw.forEach(toolName=>{
      if(!this.toolNames.some(o=>o.searchToolNameID == toolName['value'])){
        this.toolNames.push({
          toolName:toolName['response'] || '',
          searchToolNameID:toolName['value'] || ''
        })
      }
    })
  }
  mapUserSavedSearches(rawData){
    this.savedSearches = [];
    rawData.forEach(savedSearch=>{
      // if(!this.savedSearches.some(o=>o.searchID == savedSearch['indskr_usersearchesid']) && savedSearch['indskr_searchtool']){
      let categoryValuesPairs:SelectedSuggestionPillDataModel[] = [];
      if(savedSearch['usersearchparameter'] && savedSearch['usersearchparameter'].length){
        savedSearch['usersearchparameter'].forEach(catValPair=>{
          let tool = this.toolNames.find(tool=>tool.searchToolNameID == savedSearch.indskr_searchtool)
          let catRalativeConfig;
          if(tool && tool.toolName){
            switch(tool.toolName){
              case 'Accounts':
                catRalativeConfig = this.accountConfiguredSearchIndexConfig.find(config=>config.categoryDisplayName?config.categoryDisplayName == catValPair['indskr_category']:config.categoryName == catValPair['indskr_category']);
                break;
              case 'Consents':
              case 'Contacts':
                catRalativeConfig = this.contactConfiguredSearchIndexConfig.find(config=>config.categoryDisplayName?config.categoryDisplayName == catValPair['indskr_category']:config.categoryName == catValPair['indskr_category']);
                break;
              case 'Resources':
                catRalativeConfig = this.resourcesSearchIndexesConfig.find(config=>config.categoryName == catValPair['indskr_category']);
                break;
              case 'Presentations':
                catRalativeConfig = this.presentationsSearchIndexesConfig.find(config=>config.categoryName == catValPair['indskr_category']);
                break;
              case 'Email Templates':
                catRalativeConfig = this.emailTemplatesSearchIndexesConfig.find(config=>config.categoryName == catValPair['indskr_category']);
                break;
              case 'Contents':
                  catRalativeConfig = this.contentsSearchIndexesConfig.find(config=>config.categoryName == catValPair['indskr_category']);
                  break;
              case 'My Time Off':
                  catRalativeConfig = this.myTimeOffSearchIndexesConfig.find(config=>config.categoryName == catValPair['indskr_category']);
                  break;
              case 'Team Time Off':
                  catRalativeConfig = this.teamTimeOffSearchIndexesConfig.find(config=>config.categoryName == catValPair['indskr_category']);
                  break;
              case 'My Call Plans':
                catRalativeConfig = this.myCallPlansSearchIndexesConfig.find(config=>config.categoryName == catValPair['indskr_category']);
                break;
              case 'Team Call Plans':
                catRalativeConfig = this.teamCallPlansSearchIndexesConfig.find(config=>config.categoryName == catValPair['indskr_category']);
                break;
              case 'My Coaching':
                  catRalativeConfig = this.myCoachingSearchIndexesConfig.find(config=>config.categoryName == catValPair['indskr_category']);
                  break;
              case 'Team Coaching':
                  catRalativeConfig = this.teamCoachingSearchIndexesConfig.find(config=>config.categoryName == catValPair['indskr_category']);
                  break;
            }
          }
          //if(catRalativeConfig){
            let dataModel:SelectedSuggestionPillDataModel = {
              selectedFacet: catValPair['indskr_name']||'',
              categoryName: catRalativeConfig?.categoryName || catValPair['indskr_category'] || '',
              categoryDisplayName: catRalativeConfig ? catRalativeConfig.translationKey?this.translate.instant(catRalativeConfig.translationKey):catValPair['indskr_category'] : '',
              categoryPath: catRalativeConfig? catRalativeConfig.categoryRelativePath : '',
              type:catValPair['indskr_datatype'],
              createdOn:catValPair['createdon'] || 0,
              isAggregatedSection: !!catRalativeConfig?.isThisToBeAggregated,
              searchAttributeNames: catRalativeConfig?.isThisToBeAggregated ? catRalativeConfig.searchAttributeNames ?? undefined : undefined
            }
            if(dataModel.type == SuggestionPillType.ENTITY_LEVEL_CHARACTERSEARCH){
              let fullText = dataModel.selectedFacet.split(' ');
              let withWordIndex = fullText.findIndex(o=> o == 'with');
              let actualCharSearchText = _.remove(fullText, (text, index)=>{
                return index > withWordIndex
              }).join(' ')
              dataModel.charSearchText = actualCharSearchText
            }
            if(dataModel.type == SuggestionPillType.CATEGORY_CHARACTERSEARCH){
              let fullText = dataModel.selectedFacet.split(' ');
              let withWordIndex = fullText.findIndex(o=> o == 'with');
              let actualCharSearchText = _.remove(fullText, (text, index)=>{
                return index > withWordIndex
              }).join(' ')
              dataModel.charSearchText = actualCharSearchText
            }
            if(dataModel.type == SuggestionPillType.BOOLEAN_FIELD){
              let fullText = dataModel.selectedFacet.split('-');
              let actualCharSearchText = fullText[fullText.length-1];
              dataModel.charSearchText = actualCharSearchText.trim();
            }
            categoryValuesPairs.push(dataModel)
          //}
        })
      }
      let search: UserSearchOfflineDTO = {
        searchID:savedSearch['indskr_usersearchesid']||'',
        searchName:savedSearch['indskr_name']||'',
        searchToolName:savedSearch['indskr_searchtool']||'',
        categoryValuePairs:categoryValuesPairs,
        offlineID:savedSearch['indskr_externalid']||'',
        createdon:savedSearch['createdon'] || 0,
        searchType:savedSearch['indskr_usersearchtype'] || UserSavedSearchTypes.OWNED,
        modifiedon : savedSearch['modifiedon'],
        createdby : savedSearch['createdby'],
        createdbyValue : savedSearch['createdbyValue']
      }
      if(savedSearch['indskr_searchtool']) {
        let index = _.findIndex(this.savedSearches, {searchID: savedSearch['indskr_usersearchesid']});
        if(index == -1) {
          this.savedSearches.push(search);
        }
        else {
          this.savedSearches[index] = search;
        }
      }
    // }
    })
    this.savedSearches.sort((a,b)=>{
      if(a.createdon > b.createdon) return -1;
      else return 1;
    })
    this.savedSearches.sort((a,b)=>{
      if(a.modifiedon > b.modifiedon) return -1;
      else return 1;
    })
  }

  fetchSuggestions(searchConfig:searchIndexDataModel[], selectedSuggestionsData:SelectedSuggestionPillDataModel[],
    toolNameForEntityCharacterSearch:string, searchText:string, showAllCategoriesCharSearch:boolean = false,
    addCustomerCharSearchOption: boolean = false, customNameForEntityCharacterSearch?: string){
      searchText = searchText.trim()
      let level1Keys = ['categoryDisplayName', 'values']
      var options1 = {
        keys: level1Keys,
        threshold: 0.1,
        includeMatches: true,
        includeScore: true,
        shouldSort: true,
        distance:1000
      }
      searchConfig = searchConfig.filter(config=>{
        return  config.values.length>0 && (!config.featureActionDependant
                                          || (config.featureActionDependant && this.authService.hasFeatureAction(config.featureActionName)))
      })
      let suggestionsData = [];
      //#1.replace with the formatted values for matching characters with strokes and diacritics
      searchConfig.forEach(sc => {
        if (sc.categoryDisplayName) {
          sc.categoryDisplayNameRaw = sc.categoryDisplayName;
          sc.categoryDisplayName = this.convertFormattedString(sc.categoryDisplayName);
        }
        if (sc.values && sc.values.length > 0) {
          sc.valuesRaw = [];
          sc.values.forEach((value,idx) => {
            sc.valuesRaw.push(sc.values[idx]);
            sc.values[idx] = this.convertFormattedString(value);;
          });
        }
      });
      const formattedSearchText = this.convertFormattedString(searchText);
      //#2.set fuse search library and search with formatted value
      let newFuseLevel1 = new fuse(searchConfig,options1);
      let resultsLevel1 = newFuseLevel1.search(formattedSearchText);
      //#3.replace with the original raw values that will be displayed in the suggestion pop-up. And values in searchConfig are replaced.
      searchConfig.forEach(sc => {
        sc.categoryDisplayName = sc.categoryDisplayNameRaw;
        sc.values = sc.valuesRaw;
      });
      resultsLevel1.forEach(resultMatch => {
        if (resultMatch.matches && resultMatch.matches.length > 0) {
          resultMatch.matches.forEach(match => {
            if (match.key == 'values') {
              let matchIdx = match.arrayIndex;
              if (matchIdx && resultMatch.item && resultMatch.item.values && resultMatch.item.values.length > 0) {
                match.value = resultMatch.item.values[matchIdx];
              }
            } else if (match.key == 'categoryDisplayName') {
              match.value = resultMatch.item.categoryDisplayNameRaw;
            }
          });
        }
      });
      //filter category type matches
      //@ts-ignore
      let categoryOnlyMatches = _.filter(resultsLevel1,_.conforms({ 'matches': function(n){
        return (n.length==1 && (n[0].key=='categoryDisplayName'))
                || (n[0].key=='categoryDisplayName' && n[0].value.toLowerCase() == searchText.toLowerCase());
      }}))
      //filter value type matches
      let valueSuggestions;
      if(categoryOnlyMatches.length) {
        //@ts-ignore
        valueSuggestions = _.difference(resultsLevel1, categoryOnlyMatches)
      }
      else{
        valueSuggestions = resultsLevel1
      }
      //add first option as entity level character search
      let isEntitySearchSelected = selectedSuggestionsData.some(o=> o.type== SuggestionPillType.ENTITY_LEVEL_CHARACTERSEARCH
                                          && o.charSearchText==searchText);
      suggestionsData.push({
        header:undefined,
        listData:[{
          id:'entityCharacterSearch',
          primaryTextLeft:toolNameForEntityCharacterSearch+' '+this.translate.instant("WITH")+' '+searchText,
          isSelected:isEntitySearchSelected,
          showArrow:isEntitySearchSelected,
          arrowType: 'indegene-selectors-checkmark-icon',
          sugestionType:SuggestionPillType.ENTITY_LEVEL_CHARACTERSEARCH,
          charSearchText: searchText
        }]
      })
      if(addCustomerCharSearchOption && customNameForEntityCharacterSearch){
        let isCustomEntitySearchSelected = selectedSuggestionsData.some(o=> o.type== SuggestionPillType.CUSTOM_ENTITY_LEVEL_CHARACTERSEARCH
          && o.charSearchText==searchText);
        suggestionsData.push({
          header:undefined,
          listData:[{
            id:'customEntityCharacterSearch',
            primaryTextLeft:customNameForEntityCharacterSearch+' '+this.translate.instant("WITH")+' '+searchText,
            isSelected:isCustomEntitySearchSelected,
            showArrow:isCustomEntitySearchSelected,
            arrowType: 'indegene-selectors-checkmark-icon',
            sugestionType:SuggestionPillType.CUSTOM_ENTITY_LEVEL_CHARACTERSEARCH,
            charSearchText: searchText
          }]
        })
      }
      //add options from category type matches
      categoryOnlyMatches && categoryOnlyMatches.length && categoryOnlyMatches.sort((a:any,b:any)=>{
        if(a.item.categoryDisplayName > b.item.categoryDisplayName) return 1
        else return -1;
      })
      categoryOnlyMatches && categoryOnlyMatches.length && categoryOnlyMatches.forEach((match:any)=>{
        const isThisToBeAggregated = !!match.item.isThisToBeAggregated;
        let dataAlreadyExists: boolean = false;
        let dataModel : {
          header: IndAdvancedSearchResultSectionHeader,
          listData: MainCardViewDataModel[]
        };

        if (isThisToBeAggregated) {
          dataModel = suggestionsData.find(data => data.header?.logicalTitle === match.item.categoryName);
        }

        if (!dataModel) {
          dataModel = {
            header:{
              id:match.item.categoryRelativePath,
              title:match.item.categoryDisplayName || match.item.categoryName,
              logicalTitle:match.item.categoryName,
              searchPopoverHeader: true,
              controls:[],
              isAggregatedSection: isThisToBeAggregated,
              searchAttributeNames: isThisToBeAggregated ? [match.item.categoryRelativePath] : undefined,
              configuredFrom: match.item.configuredFrom ?  match.item.configuredFrom : undefined,
            },
            listData:[]
          }
        } else {
          dataAlreadyExists = true;
          if (Array.isArray(dataModel.header?.searchAttributeNames) && dataModel.header?.isAggregatedSection) {
            dataModel.header.searchAttributeNames.push(match.item.categoryRelativePath);
          }
        }

        match.item.values.forEach((value,index)=>{
            let isSelected = selectedSuggestionsData.some(o=>o.selectedFacet==value && o.categoryName== match.item.categoryName);
            dataModel.listData.push({
              id:value,
              primaryTextLeft: value,
              showArrow: false,
              isSelected: isSelected,
              arrowType: 'indegene-selectors-checkmark-icon',
              showEndIcon: isSelected,
              mainItemCssClass: 'selector-item',
              endIconType: 'indegene-selectors-checkmark-icon',
              endIconCssClass: 'checkmark-icon',
              sugestionType:match.item.isBooleanTypeCategory?SuggestionPillType.BOOLEAN_FIELD:SuggestionPillType.CATEGORY_VALUESEARCH,
            })
        })

        if (dataAlreadyExists) {
          // Remove duplicates
          dataModel.listData = _.uniqBy(dataModel.listData, 'id');
        } else {
          suggestionsData.push(dataModel)
        }
      })
      //add options from value type matches
      valueSuggestions && valueSuggestions.length && valueSuggestions.sort((a,b)=>{
        if(a.item.categoryDisplayName > b.item.categoryDisplayName) return 1
        else return -1;
      })
      valueSuggestions && valueSuggestions.length && valueSuggestions.forEach(match=>{
        const isThisToBeAggregated = !!match.item.isThisToBeAggregated;
        let dataAlreadyExists: boolean = false;
        let dataModel : {
          header: IndAdvancedSearchResultSectionHeader,
          listData: MainCardViewDataModel[]
        };

        if (isThisToBeAggregated) {
          dataModel = suggestionsData.find(data => data.header?.logicalTitle === match.item.categoryName);
        }

        if (!dataModel) {
          dataModel = {
            header:{
              id:match.item.categoryRelativePath,
              title:match.item.categoryDisplayName || match.item.categoryName,
              logicalTitle:match.item.categoryName,
              searchPopoverHeader: true,
              controls:[],
              isAggregatedSection: isThisToBeAggregated,
              searchAttributeNames: isThisToBeAggregated ? [match.item.categoryRelativePath] : undefined,
              configuredFrom: match.item.configuredFrom ?  match.item.configuredFrom : undefined,
            },
            listData:[]
          }
        } else {
          dataAlreadyExists = true;
          if (Array.isArray(dataModel.header?.searchAttributeNames) && dataModel.header?.isAggregatedSection) {
            dataModel.header.searchAttributeNames.push(match.item.categoryRelativePath);
          }
        }

        //add first option within a section for character search on category level
        let numberOfMatchedOptions = match.matches.filter(val=>val.key == 'values').length;
        if(!match.item.isBooleanTypeCategory && showAllCategoriesCharSearch?true:numberOfMatchedOptions>1 && match.item.categoryRelativePath!='verificationStatus' && !match.item.isMultilingualLookup){
          let isCategorySearchSelected = selectedSuggestionsData.some(o=>o.type== SuggestionPillType.CATEGORY_CHARACTERSEARCH
                                          && o.charSearchText==searchText && o.categoryName==match.item.categoryName);
          dataModel.listData.push({
            id:'categoryCharacterSearch',
            primaryTextLeft: match.item.categoryDisplayName+' '+this.translate.instant("WITH")+' '+searchText,
            showArrow: isCategorySearchSelected,
            isSelected: isCategorySearchSelected,
            arrowType: 'indegene-selectors-checkmark-icon',
            sugestionType:SuggestionPillType.CATEGORY_CHARACTERSEARCH,
            charSearchText: searchText
          })
        }
        //add rest of the options within a section for value search on category level
        match.matches.forEach(value=>{
          //@ts-ignore
          let isSelected = selectedSuggestionsData.some(o=>o.selectedFacet==value.value && o.categoryName== match.item.categoryName);
          if(value.key == 'values') dataModel.listData.push({
            id:value.value,
            primaryTextLeft: value.value,
            showArrow: false,
            isSelected: isSelected,
            arrowType: 'indegene-selectors-checkmark-icon',
            showEndIcon: isSelected,
            mainItemCssClass: 'selector-item',
            endIconType: 'indegene-selectors-checkmark-icon',
            endIconCssClass: 'checkmark-icon',
            sugestionType:match.item.isBooleanTypeCategory?SuggestionPillType.BOOLEAN_FIELD:SuggestionPillType.CATEGORY_VALUESEARCH,
          })
        })

        if (dataAlreadyExists) {
          // Remove duplicates
          dataModel.listData = _.uniqBy(dataModel.listData, 'id');
        } else {
          suggestionsData.push(dataModel)
        }
      })
    //add code for pushing remaining categories character search suggestion
    if(showAllCategoriesCharSearch){
      let remainingCategories = searchConfig.filter(cat=>{
        return !cat.isMultilingualLookup && !resultsLevel1.some(res=> res.item.categoryDisplayName == cat.categoryDisplayName && !cat.isBooleanTypeCategory)
      })
      remainingCategories.length && remainingCategories.forEach((category:any)=>{
        if(suggestionsData.some(suggestion=> suggestion.header?.logicalTitle == category.categoryName
                                              && suggestion.header?.title == (category.categoryDisplayName || category.categoryName))) return;
        let dataModel : {
          header: IndSectionHeaderViewDataModel,
          listData: MainCardViewDataModel[]
        } ={
          header:{
            id:category.categoryRelativePath,
            title:category.categoryDisplayName || category.categoryName,
            logicalTitle:category.categoryName,
            searchPopoverHeader: true,
            controls:[]
          },
          listData:[]
        }
        let isCategorySearchSelected = selectedSuggestionsData.some(o=>o.type== SuggestionPillType.CATEGORY_CHARACTERSEARCH
          && o.charSearchText==searchText && o.categoryName==category.categoryName);
        dataModel.listData.push({
          id:'categoryCharacterSearch',
          primaryTextLeft: category.categoryDisplayName+' '+this.translate.instant("WITH")+' '+searchText,
          showArrow: isCategorySearchSelected,
          isSelected: isCategorySearchSelected,
          arrowType: 'indegene-selectors-checkmark-icon',
          sugestionType:SuggestionPillType.CATEGORY_CHARACTERSEARCH,
          charSearchText: searchText
        })
        suggestionsData.push(dataModel);
      })
    }
    return suggestionsData;
  }

  fetchFilteredListBasedOnSuggestions(selectedSuggestionsDataCopy: SelectedSuggestionPillDataModel[],listToBeFiltered:any){
    let currentList: any = listToBeFiltered;
    let groupedSelectedSuggestions: {
      category:string,
      suggestions:SelectedSuggestionPillDataModel[]
    }[] = [];
    selectedSuggestionsDataCopy.forEach(data=>{
      if(data && data.categoryName){
        let groupIndex = groupedSelectedSuggestions.findIndex(group=>group.category==data.categoryName)
        if(groupIndex>-1){
          groupedSelectedSuggestions[groupIndex].suggestions.push(data);
        }
        else{
          groupedSelectedSuggestions.push({
            category:data.categoryName,
            suggestions:[data]
          })
        }
      }
    })
    groupedSelectedSuggestions.forEach(groupConfig=>{
      let filteredContactsWithinSameCategory: any = [];
      groupConfig.suggestions.forEach(config=>{
            let key = [config.categoryPath]
            var options2 = {
              keys: key,
              threshold: 0,
              distance: 100,
              includeScore: true
            }
            let searchText: string;
            if(config.type && (config.type==SuggestionPillType.CATEGORY_CHARACTERSEARCH || config.type==SuggestionPillType.BOOLEAN_FIELD)){
              searchText = config.charSearchText;
              options2.threshold = 0.1;
              options2.distance = 1000;
            }
            else{
              searchText = config.selectedFacet;
            }
            let newFuse = new fuse(currentList,options2);
            let results = newFuse.search(searchText)
            let exactMatchResults = [];
            //@ts-ignore
            results.forEach((result:any)=>{
              if(config.type && config.type==SuggestionPillType.CATEGORY_CHARACTERSEARCH ){
                exactMatchResults.push(result.item);
              }
              else if(result.score < 0.1){
                exactMatchResults.push(result.item);
              }
            })
            filteredContactsWithinSameCategory = _.uniq(_.concat(filteredContactsWithinSameCategory, exactMatchResults))
      })
      currentList = filteredContactsWithinSameCategory;
    })
  return currentList;
  }

  manageSelectedSuggestions(data:SelectedSuggestionPillDataModel,selectedSuggestionsData:SelectedSuggestionPillDataModel[]):SelectedSuggestionPillDataModel[]{
    if(data.type == SuggestionPillType.ENTITY_LEVEL_CHARACTERSEARCH){
      let index = _.findIndex(selectedSuggestionsData, o=>o.type == SuggestionPillType.ENTITY_LEVEL_CHARACTERSEARCH)
      if(index>-1){
        selectedSuggestionsData[index] = data;
      }
      else{
        selectedSuggestionsData.unshift(data)
      }
    }
    else if(data.type == SuggestionPillType.BOOLEAN_FIELD){
      let index = _.findIndex(selectedSuggestionsData, o=>o.categoryName==data.categoryName && o.type == SuggestionPillType.BOOLEAN_FIELD)
      if(index>-1){
        selectedSuggestionsData[index] = data;
      }
      else{
        selectedSuggestionsData.unshift(data);
      }
    }
    else if(data.type==SuggestionPillType.CATEGORY_CHARACTERSEARCH){
      let index = _.findIndex(selectedSuggestionsData, o=> o.categoryName==data.categoryName && o.type == SuggestionPillType.CATEGORY_CHARACTERSEARCH)
      if(index>-1){
        selectedSuggestionsData[index] = data;
      }
      else{
        selectedSuggestionsData.unshift(data)
      }
    }
    else{
      if(!selectedSuggestionsData.some(o=> o.categoryName==data.categoryName && o.selectedFacet == data.selectedFacet))
            selectedSuggestionsData.unshift(data);
    }
    return selectedSuggestionsData;
  }

  initLinkedEntityWeight() {

    this.linkedEntityWeight = [
      {
        entity: "indskr_indskr_customeraddress_v2",
        weight: 4
      },
      {
        entity: "indskr_productrating",
        weight: 1
      },
      {
        entity: "indskr_lu_professional_designation",
        weight: 1
      },
      {
        entity: "indskr_customerspecialty",
        weight: 1
      },
      {
        entity: "indskr_lu_language",
        weight: 1
      },
      {
        entity: "indskr_accountcontactaffiliation",
        weight: 2
      },
      {
        entity: "phoneNumber",
        weight: 0
      },
      {
        entity: "contact",
        weight: 0
      },
      {
        entity: "indskr_indskr_scientificplan_contact",
        weight: 2
      },
      {
        entity: "indskr_consent",
        weight: 1
      },
      {
        entity: "indskr_customerposition",
        weight: 2
      },
      {
        entity: "indskr_contactrelationship",
        weight: 1
      },
      {
        entity: "indskr_customercallplan",
        weight: 1
      },
      {
        entity: "indskr_email_address",
        weight: 1
      },
      {
        entity: "indskr_customersampleproduct",
        weight: 1
      },

    ]
  }

  updateSearchWithConfiguredFields(){
    let appConfigFields = this.authService.user.configuredFields.filter(field => field['entityName'] === 'contact') || [];

    for(let configField of appConfigFields) {

      if(configField['fieldType'] && configField['fieldName'] && configField['fieldLabel']){
        if(!this.contactsSearchIndexesConfig.find(config=> config.categoryName == configField['fieldName'])){
          this.contactsSearchIndexesConfig.push({
            categoryName:configField['fieldLabel'],
            categoryDisplayName: configField['fieldLabel'],
            categoryRelativePath:'configuredFields.fieldValue',
            values: configField['fieldType']=='Boolean' ? ['Yes','No'] : [],
            attributeName: configField['fieldName'],
            entity: 'contact',

          })
        }
      }
    }
  }

  get selectedSavedSharedSuggestionId() {
    return this._selectedSavedSharedSuggestionId;
  }
  set selectedSavedSharedSuggestionId(searchId : string) {
    this._selectedSavedSharedSuggestionId = searchId;
  }
  get searchToolID() {
    return this._searchToolID;
  }
  set searchToolID(searchId : string) {
    this._searchToolID = searchId;
  }

  trackOfflineSavedSearchDataCount() {
    const offlineDataCount: number = this.savedSearches.filter(s => s.pendingSync === true).length;
    this.disk.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.SAVED_SEARCH, offlineDataCount);
  }


  /**
   * @description This method add new sharedSearchNotification by calling addNewNotification of myAssistantService class.
   *  Notification are only shown for seaches shared by manager, uses systemUserID to differentiate the manager and user
   * @param {any=} response from getSavedSearchFornotification service method which contain the search filter by lastupdated time
   * @returns
   */
  public async sharedSearchNotification(response: any) {
    response.forEach(element => {
      if(element['createdbyValue'] && element['createdbyValue'] != this.authService.user.systemUserID) {
        let searchToolName = _.find(this.toolNames, {searchToolNameID : parseInt(element['indskr_searchtool']) as any})['toolName']
        let managerName = element['createdby'];
        this.searchNotificationModel = {
          type: NOTIFICATION.NEW_SEARCH_ADV_SEARCH,
          name: this.translate.instant("NEW_SEARCH_ADV_SEARCH", {searchedParam: element['indskr_name'], tool: searchToolName}),
          DateTime: Date.now(),
          id: NOTIFICATION.NEW_SEARCH_ADV_SEARCH + element['indskr_externalid'],
          data: {data: this.translate.instant("NEW_SEARCH_ADV_SEARCH_DETAILS", {searchedParam: element['indskr_name'], tool: searchToolName, manager: managerName})},
          icon: 'assets/imgs/shared_search.svg',
          isRed: false,
          params: {searchedParam: element['indskr_name'], tool: searchToolName, manager: managerName}
        };
        this.myAssistantService.saveNotificationToDisk(this.searchNotificationModel);
        // this.myAssistantService.addNewNotification(this.translate.instant('THERE_IS_NEW_SEARCH_SHARED', {'searchToolNametext' : searchToolName, 'managerNametext' : managerName}), modifiedonDate);
      }
    });
  }

  createCustomFetchXML(data: FetchXMLTag) {
    return this._createFetchXMLTag(data);
  }

  wrapFetchXMLTag(data: FetchXMLTag, stringToWrap: string): string {
    let wrappedString = '';
    if (data && stringToWrap) {
      wrappedString = this._generateFetchOpenTag(data) + stringToWrap + '</fetch>';
    }
    return wrappedString;
  }
  private _createFetchXMLTag(data: FetchXMLTag): string {
    let fetchXML = '';
    if (data && data.entity) {
      fetchXML = this._generateFetchOpenTag(data);

      if (data.entity) {
        fetchXML = fetchXML + this._createEntityXMLTag(data.entity);
      }

      fetchXML = fetchXML + '</fetch>';
    }
    return fetchXML;
  }
  private _generateFetchOpenTag(data: FetchXMLTag) {
    let tag = '';
    if (data) {
      tag = `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="${data.distinct ? 'true' : 'false'}"`;
      if (data.returnTotalRecordCount) {
        tag = tag + ` returntotalrecordcount="${data.returnTotalRecordCount ? 'true': 'false'}"`;
      }
      if (data.count) {
        tag = tag + ` count="${data.count}"`;
      }
      if (data.page) {
        tag = tag + ` page="${data.page}"`;
      }
      tag = tag + ' >';
    }
    return tag;
  }

  private _createEntityXMLTag(data: EntityXMLTag): string {
    let fetchXML = '';
    if (data) {
      fetchXML = `<entity name="${data.name}">`;

      // Top level attributes
      if (Array.isArray(data.attributes)) {
        for (let i = 0; i < data.attributes.length; i++) {
          const attribute = data.attributes[i];
          fetchXML = fetchXML + this._createAttributeXMLTag(attribute);
        }
      }

      if (Array.isArray(data.linkedEntities)) {
        for (let i = 0; i < data.linkedEntities.length; i++) {
          const linkedEntity = data.linkedEntities[i];
          fetchXML = fetchXML + this._createLinkedEntityXMLTag(linkedEntity);
        }
      }

      if (Array.isArray(data.filters)) {
        for (let i = 0; i < data.filters.length; i++) {
          const filter = data.filters[i];
          fetchXML = fetchXML + this._createFilterXMLTag(filter);
        }
      }

      if(this.authService.user.accountsCriteriaFetchxml) {
        fetchXML = fetchXML + this.authService.user.accountsCriteriaFetchxml;
      }

      if (Array.isArray(data.replaceStrings)) {
        for (let i = 0; i < data.replaceStrings.length; i++) {
          const replaceString = data.replaceStrings[i];
          fetchXML = fetchXML + replaceString;
        }
      }

      if (Array.isArray(data.orders)) {
        for (let i = 0; i < data.orders.length; i++) {
          const order = data.orders[i];
          fetchXML = fetchXML + this._createOrderXMLTag(order);
        }
      }

      fetchXML = fetchXML + '</entity>';
    }

    return fetchXML;
  }
  private _createLinkedEntityXMLTag(data: Partial<LinkedEntityXMLTag>) {
    let linkedEntityString = '';
    if (data) {
      if (data.wholeTagTextToBePasted) {
        linkedEntityString = data.wholeTagTextToBePasted;
      } else {
        linkedEntityString = `<link-entity name="${data.name}" from="${data.from}" to="${data.to}" link-type="${data.linkType}" ${data.alias ? 'alias="' + data.alias + '"' : ''}>`;

        if (data.innerTagsToBePasted) {
          linkedEntityString = linkedEntityString + data.innerTagsToBePasted;
        } else {
          if (Array.isArray(data.attributes)) {
            for (let i = 0; i < data.attributes.length; i++) {
              const attribute = data.attributes[i];
              linkedEntityString = linkedEntityString + this._createAttributeXMLTag(attribute);
            }
          }

          if (Array.isArray(data.linkedEntities)) {
            for (let i = 0; i < data.linkedEntities.length; i++) {
              const nestedLinkedEntity = data.linkedEntities[i];
              linkedEntityString = linkedEntityString + this._createLinkedEntityXMLTag(nestedLinkedEntity);
            }
          }

          if (Array.isArray(data.filters)) {
            for (let i = 0; i < data.filters.length; i++) {
              const filter = data.filters[i];
              linkedEntityString = linkedEntityString + this._createFilterXMLTag(filter);
            }
          }

          if (Array.isArray(data.orders)) {
            for (let i = 0; i < data.orders.length; i++) {
              const order = data.orders[i];
              linkedEntityString = linkedEntityString + this._createOrderXMLTag(order);
            }
          }
        }

        linkedEntityString = linkedEntityString + '</link-entity>';
      }
    }
    return linkedEntityString;
  }
  private _createFilterXMLTag(data: Partial<FilterXMLTag>) {
    let filterString = '';
    if (data) {
      if (data.wholeTagTextToBePasted) {
        filterString = data.wholeTagTextToBePasted;
      } else {
        filterString = `<filter${data.type ? ' type="' + data.type + '"' : ''}>`;

        if (data.innerTagsToBePasted) {
          filterString = filterString + data.innerTagsToBePasted;
        } else {
          if (Array.isArray(data.conditions)) {
            for (let i = 0; i < data.conditions.length; i++) {
              const condition = data.conditions[i];
              filterString = filterString + this._createFilterConditionXMLTag(condition);
            }
          }

          if (Array.isArray(data.nestedFilters)) {
            for (let i = 0; i < data.nestedFilters.length; i++) {
              const nestedFilter = data.nestedFilters[i];
              filterString = filterString + this._createFilterXMLTag(nestedFilter);
            }
          }
        }

        filterString = filterString + '</filter>';
      }
    }
    return filterString;
  }
  private _createFilterConditionXMLTag(data: FilterConditionXMLTag) {
    let filterConditionString = '';
    if (data) {
      filterConditionString = `<condition attribute="${data.attribute}" operator="${data.operator}" ${data.entityname ? 'entityname="' + data.entityname + '" ' : '' }`;

      if (Array.isArray(data.valueList) && (data.operator === FilterConditionOperators.in || data.operator === FilterConditionOperators.notIn)) {
        filterConditionString = filterConditionString + '>';

        for (let i = 0; i < data.valueList.length; i++) {
          const value = data.valueList[i];
          filterConditionString = `${filterConditionString} <value>${value}</value>`;
        }
        filterConditionString = filterConditionString + '</condition>';
      } else if (data.operator === FilterConditionOperators.null || data.operator === FilterConditionOperators.notNull) {
        filterConditionString = filterConditionString + '/>';
      } else if (data.value) {
        filterConditionString = `${filterConditionString} value="${data.value}" />`;
      } else {
        console.warn('_createFilterConditionXML: Possibly incorrect data', data);
      }
    }
    return filterConditionString;
  }
  private _createAttributeXMLTag(data: AttributeXMLTag): string {
    let attributeString = '';
    if (data) {
      attributeString = data.all ? '<all-attributes />' : `<attribute name="${data.name}" ${data.alias ? 'alias="' + data.alias + '"' : ''} />`;
    }
    return attributeString;
  }

  private _createOrderXMLTag(data: OrderXMLTag): string {
    let orderString = '';
    if (data) {
      orderString = `<order attribute="${data.attribute}" descending="${data.descending ? 'true' : 'false'}" />`;
    }
    return orderString;
  }

  public resetConfiguredConfig(entity:string){
    switch(entity){
      case 'Contacts':{
        this.contactConfiguredSearchIndexConfig = [
          {
            categoryName: 'My_Activities',
            translationKey: 'MY_ACTIVITIES',
            categoryRelativePath:'myActivitiesByContact',
            controlDataType: 'DefaultConfig',
            values:[],
            mappingValues: [],
            isFixedFilter: true,
            filterOrder: '1'
          },
          {
            categoryName: 'Meetings_Status',
            translationKey: 'FILTER_MEETINGS_STATUS',
            categoryRelativePath:'filterMeetingsStatus',
            controlDataType: 'DefaultConfig',
            values:[],
            mappingValues: [],
            isFixedFilter: true,
            filterOrder: '2',
            isForcedHide: true,
            isSubCategory: true,
          },
          {
            categoryName: 'Messages_Status',
            translationKey: 'FILTER_MESSAGES_STATUS',
            categoryRelativePath:'filterMessagesStatus',
            controlDataType: 'DefaultConfig',
            values:[],
            mappingValues: [],
            isFixedFilter: true,
            filterOrder: '3',
            isForcedHide: true,
            isSubCategory: true,
          },
          {
            categoryName: 'Messages_Attachment',
            translationKey: 'FILTER_MESSAGES_ATTACHMENT',
            categoryRelativePath:'filterMessagesAttachment',
            controlDataType: 'DefaultConfig',
            values:[],
            mappingValues: [],
            isFixedFilter: true,
            filterOrder: '4',
            isForcedHide: true,
            isSubCategory: true,
          },
          {
            categoryName: 'Call Plans',
            translationKey: 'CALL_PLANS',
            categoryRelativePath:'repCallPlansByContact.segmentCallPlanName',
            controlDataType: 'DefaultConfig',
            values:[],
            mappingValues: [],
            customizedBypassGateEnabled: true,
            attributeName: 'indskr_segment_v2name',
            entity: 'indskr_customercallplan',
            parentEntity: 'indskr_customercallplan',
          },
          {
            categoryName: 'Scientific Plans',
            translationKey: 'SCIENTIFIC_PLANS',
            categoryRelativePath:'scientificActivityPlanName',
            controlDataType: 'DefaultConfig',
            values:[],
            mappingValues: [],
            customizedBypassGateEnabled: true,
            attributeName: 'indskr_name',
            parentEntity: "indskr_indskr_scientificplan_contact",
            entity: 'indskr_scientificplan',
            featureActionDependant: true,
            featureActionName: FeatureActionsMap.SCIENTIFIC_TOOL_PLAN
          },
          {
            categoryName: 'Products',
            translationKey: 'PRODUCTS',
            categoryRelativePath:'productSegmentations.productName',
            controlDataType: 'DefaultConfig',
            values:[],
            mappingValues: [],
            customizedBypassGateEnabled: true,
            attributeName: 'indskr_productname',
            entity: 'indskr_productrating',
            parentEntity: 'indskr_productrating'
          },
          {
            categoryName: 'Segments',
            translationKey: 'SEGMENTS',
            categoryRelativePath:'productSegmentations.segmentation',
            controlDataType: 'DefaultConfig',
            values:[],
            mappingValues: [],
            customizedBypassGateEnabled: true,
            attributeName: 'indskr_customersegmentationidname',
            entity: 'indskr_productrating',
            parentEntity: 'indskr_productrating'
          },
          {
            categoryName: 'Allocations',
            translationKey: 'ACTIVITY_ALLOCATIONS',
            categoryRelativePath:'contactAllocations.sampleSKUName',
            controlDataType: 'DefaultConfig',
            values:[],
            mappingValues: [],
            customizedBypassGateEnabled: true,
            attributeName: 'indskr_sampleproductidname',
            entity: 'indskr_customersampleproduct',
            parentEntity: 'indskr_customersampleproduct',
            featureActionDependant: true,
            featureActionName : FeatureActionsMap.ALLOCATION_TOOL
          },
          {
            categoryName: 'Consent Channels',
            translationKey: 'CONSENT_CHANNELS',
            categoryRelativePath:'activeConsents.channels.indskr_consentType',
            controlDataType: 'DefaultConfig',
            values:[],
            mappingValues: [],
            attributeName: 'indskr_consenttype',
            entity: 'indskr_consent',
            parentEntity: 'indskr_consent',
            featureActionDependant: true,
            featureActionName: FeatureActionsMap.CONSENT_TOOL
          },
          {
            categoryName: 'Consent Terms',
            translationKey: 'CONTACT_CONSENT_TERMS',
            categoryRelativePath:'activeConsents.indskr_consentTermName',
            controlDataType: 'DefaultConfig',
            values:[],
            mappingValues: [],
            attributeName: 'indskr_consenttermsname',
            entity: 'indskr_consent',
            parentEntity: 'indskr_consent',
            featureActionDependant: true,
            featureActionName: FeatureActionsMap.CONSENT_TOOL
          },
          {
            categoryName: 'Consent Products',
            translationKey: 'CONSENT_PRODUCTS',
            categoryRelativePath:'activeConsents.products.indskr_productname',
            controlDataType: 'DefaultConfig',
            values:[],
            mappingValues: [],
            attributeName: 'indskr_productname',
            entity: 'indskr_consent',
            parentEntity: 'indskr_consent',
            featureActionDependant: true,
            featureActionName: FeatureActionsMap.CONSENT_TOOL
          },
          {
            categoryName: 'Active Consents',
            translationKey: 'CONTACT_ACTIVE_CONSENTS',
            categoryRelativePath:'hasActiveConsent',
            controlDataType: 'DefaultConfig',
            values:[],
            mappingValues: [],
            isBooleanTypeCategory:true,
            attributeName: 'statecode',
            entity: 'indskr_consent',
            parentEntity: 'indskr_consent',
            featureActionDependant: true,
            featureActionName: FeatureActionsMap.CONSENT_TOOL
          },
        ];
        this.contactConfiguredFormDefaultConfigs = [
          {
            categoryName:'indskr_indskr_customeraddress_v2',
            translationKey:'STREET_1_ADDRESS',
            controlDataType: 'LinkedEntity',
            categoryRelativePath:'indskr_address.indskr_line1',
            values:[],
            mappingValues: [],
            entity: 'indskr_address',
            parentEntity: 'indskr_indskr_customeraddress_v2',
            numOfLinkEntity:3
          },
          {
            categoryName:'indskr_indskr_customeraddress_v2',
            translationKey:'CITY',
            controlDataType: 'LinkedEntity',
            categoryRelativePath:'indskr_address.indskr_city_lu',
            values:[],
            mappingValues: [],
            entity: 'indskr_address',
            parentEntity: 'indskr_indskr_customeraddress_v2',
            numOfLinkEntity:3
          },
          {
            categoryName:'indskr_indskr_customeraddress_v2',
            translationKey:'STATE_OR_PROVINCE',
            controlDataType: 'LinkedEntity',
            categoryRelativePath:'indskr_address.indskr_state_lu',
            values:[],
            mappingValues: [],
            entity: 'indskr_address',
            parentEntity: 'indskr_indskr_customeraddress_v2',
            numOfLinkEntity:3
          },
          {
            categoryName:'indskr_indskr_customeraddress_v2',
            translationKey:'POSTAL_CODE',
            controlDataType: 'LinkedEntity',
            categoryRelativePath:'indskr_address.indskr_postalcode_lu',
            values:[],
            mappingValues: [],
            entity: 'indskr_address',
            parentEntity: 'indskr_indskr_customeraddress_v2',
            numOfLinkEntity:3
          },
          {
            categoryName:'indskr_indskr_customeraddress_v2',
            translationKey:'COUNTRY',
            controlDataType: 'LinkedEntity',
            categoryRelativePath:'indskr_address.indskr_country_lu',
            values:[],
            mappingValues: [],
            entity: 'indskr_address',
            parentEntity: 'indskr_indskr_customeraddress_v2',
            numOfLinkEntity:3
          },
        ]
        break;
      }
      case 'Accounts':{
        this.accountConfiguredSearchIndexConfig = [
          {
            categoryName: 'Opportunities',
            translationKey: 'OPPORTUNITIES',
            categoryRelativePath:'opportunityName',
            values:[],
            mappingValues: [],
            featureActionDependant: true,
            featureActionName: FeatureActionsMap.OPPORTUNITY_MANAGEMENT
          },
        ];
        break;
      }
      default:
        break;
    }
  }

  private _initTimelineFilterMenuConfig() {
    this.timelineFilterMenuList = [
      {
        id: "meeting-main",
        parentId: undefined,
        label: this.translate.instant("MEETINGS"),
        value: this.translate.instant("MEETINGS"),
        isChecked: true,
        type: 'filter-main',
        category: ActivityType.Appointment,
      },
      {
        id: "liveMeeting-main",
        parentId: undefined,
        label: this.translate.instant("LIVE_MEETING"),
        value: this.translate.instant("LIVE_MEETING"),
        isChecked: true,
        type: 'filter-main',
        category: ActivityType.LiveMeet,
      },
      {
        id: "phoneCall-main",
        parentId: undefined,
        label: this.translate.instant("PHONE_CALLS"),
        value: this.translate.instant("PHONE_CALLS"),
        isChecked: true,
        type: 'filter-main',
        category: ActivityType.PhoneCall,
      },
      {
        id: "email-main",
        parentId: undefined,
        label: this.translate.instant("MESSAGES"),
        value: this.translate.instant("MESSAGES"),
        isChecked: true,
        type: 'filter-main',
        category: ActivityType.Email,
      },
      {
        id: "sample-main",
        parentId: undefined,
        label: this.translate.instant("ALLOCATION_ORDER"),
        value: this.translate.instant("ALLOCATION_ORDER"),
        isChecked: true,
        type: 'filter-main',
        category: ActivityType.Sample,
      },
      {
        id: "customerInquiries-main",
        parentId: undefined,
        label: this.translate.instant('CUSTOMER_INQUIRIES', { globalCustomerText: this.utilityService.globalCustomerText }),
        value: this.translate.instant('CUSTOMER_INQUIRIES', { globalCustomerText: this.utilityService.globalCustomerText }),
        isChecked: true,
        type: 'filter-main',
        category: ActivityType.CaseIntake,
      },
      {
        id: "event-main",
        parentId: undefined,
        label: this.translate.instant("EVENTS"),
        value: this.translate.instant("EVENTS"),
        isChecked: true,
        type: 'filter-main',
        category: ActivityType.Event,
      },
      /**************************Customer Journey**************************/
      {
        id: "customerJourney-meeting-main",
        parentId: undefined,
        label: `${this.translate.instant('CUSTOMER_JOURNEY')} - ${this.translate.instant('MEETINGS')}`,
        value: `${this.translate.instant('CUSTOMER_JOURNEY')} - ${this.translate.instant('MEETINGS')}`,
        isChecked: true,
        type: 'filter-main',
        category: ActivityType.CustomerJourneyAppointment,
      },
      {
        id: "customerJourney-phoneCall-main",
        parentId: undefined,
        label: `${this.translate.instant('CUSTOMER_JOURNEY')} - ${this.translate.instant('PHONE_CALLS')}`,
        value: `${this.translate.instant('CUSTOMER_JOURNEY')} - ${this.translate.instant('PHONE_CALLS')}`,
        isChecked: true,
        type: 'filter-main',
        category: ActivityType.CustomerJourneyPhoneCall,
      },
      /**************************Customer Journey**************************/
      {
        id: "Appointment_Completed",
        parentId: 'Appointment',
        label: this.translate.instant("COMPLETED"),
        value: this.translate.instant("COMPLETED"),
        isChecked: true,
        type: 'appointment-status',
        category:'Status',
      },
      {
        id: "Appointment_Open",
        parentId: 'Appointment',
        label: this.translate.instant("OPEN"),
        value: this.translate.instant("OPEN"),
        isChecked: true,
        type: 'appointment-status',
        category:'Status',
      }
    ];
  }
  /**
   * Convert formatted text - Characters with strokes, and diacritics matching
   * lodash _deburr string by converting Latin-1 Supplement and Latin Extended-A letters to basic Latin letters and removing combining diacritical marks.
   */
  public convertFormattedString(targetText: string): string {
    let formattedText: string = targetText;
    formattedText = _.deburr(targetText);
    // ToDo: If necessary, add unsupported parts for characters with strokes and diacritics
    return formattedText;
  }
}
