import { Injectable } from "@angular/core";
import jsPDF from 'jspdf';
import autoTable, { jsPDFDocument } from 'jspdf-autotable';
import { BehaviorSubject, Observable } from "rxjs";
import { DeviceService } from "./device/device.service";

import { IoFileService } from "./io-file-service/io-file-service";
import { EventActivity, EventParticipant } from "../classes/events-tool/event.class";
import { DateTimeFormatsService } from "./date-time-formats/date-time-formats.service";
import { format } from "date-fns";

export const SIGNATURE_DOC_FILE_NAME_SUFFIX: string = ' - Signature List.pdf';

@Injectable({
  providedIn:'root'
})
export class PdfService {
    private readonly _MIN_TIMEOUT_VAL: number = 50;
    private readonly _DOC_OPTIONS: any[] = ['p', 'pt', 'a4'];
    private readonly _DOC_TITLE_COORDINATE_IN_PT: { x: number, y: number } = { x: 40, y: 50 };
    private readonly _DOC_TABLE_BEGIN_Y_COORDINATE_IN_PT: number = 60;
    private readonly _SIGNATURE_IMG_WIDTH_HEIGHT_RATIO: number = 2;
    private _DOC_INFO_TABLE_BASE_OPTIONS: {} = {
        startY: this._DOC_TABLE_BEGIN_Y_COORDINATE_IN_PT,
        columnStyles: {
            0: { halign: 'right', fontStyle: 'bold', minCellWidth: 80 },
            1: { minCellWidth: 180 }
        },
        tableLineColor: 10,
        tableLineWidth: 0.1,
        headStyles: {
            fillColor: [50, 142, 209],
            textColor: 240,
        },
        footStyles: {
            fillColor: [50, 142, 209],
            textColor: 240,
        },
        head: [
            [
                {
                    content: 'Event Information',
                    colSpan: 4,
                    styles: { halign: 'left' },
                }
            ]
        ],
        foot: [
            [
                {
                    content: 'Participants',
                    colSpan: 4,
                    styles: { halign: 'left' },
                }
            ]
        ],
        theme: 'plain',
    };
    private _DOC_SIGN_TABLE_BASE_OPTIONS: {} = {
        head: [{
            customerId: 'Customer ID',
            title: 'Title',
            name: 'Name',
            signature: 'Signature',
            account: 'Account',
            specialty: 'Specialty',
        }],
        rowPageBreak: 'avoid',
        styles: { lineColor: 10, lineWidth: 0.1, },
        theme: 'plain',
        headStyles: {
            fillColor: 235,
        },
        columnStyles: {
            signature: {
                minCellHeight: 50,
                minCellWidth: 100,
            }
        },
    };

    private _isProcessing: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private _isExported: BehaviorSubject<boolean> = new BehaviorSubject(false);

    readonly isProcessing: Observable<boolean> = this._isProcessing.asObservable();
    readonly isExported: Observable<boolean> = this._isExported.asObservable();

    constructor(private deviceService: DeviceService,
        private fileService: IoFileService,
        private dateTimeFormatsService: DateTimeFormatsService) {}

    generateEventSignatureListPdfDoc(event: EventActivity): Promise<jsPDF> {
        return new Promise((resolve, reject) => {
            this._isExported.next(false);

            if (event) {
                this._isProcessing.next(true);
                setTimeout(() => {
                    let doc = new jsPDF(...this._DOC_OPTIONS);
                    doc.text('Signature List', this._DOC_TITLE_COORDINATE_IN_PT.x, this._DOC_TITLE_COORDINATE_IN_PT.y);

                    // Event info table
                    this._DOC_INFO_TABLE_BASE_OPTIONS['body'] = this.generateInfoBody(event);
                    try {
                        autoTable(doc, this._DOC_INFO_TABLE_BASE_OPTIONS);
                    } catch (error) {
                        console.error('generatePdfDoc: 1st autoTable: ', error);
                    }

                    // Participants list table
                    const finalY = (doc as jsPDFDocument).lastAutoTable.finalY;
                    const totalPagesExp = '{total_pages_count_string}';
                    const participants: EventParticipant[] = Array.isArray(event.participants) ? event.participants.filter(p => !p.isDeleted && p.statecode === 0) : null;

                    this._DOC_SIGN_TABLE_BASE_OPTIONS['startY'] = finalY;
                    this._DOC_SIGN_TABLE_BASE_OPTIONS['body'] = this.generateSignatureListBody(participants);
                    this._DOC_SIGN_TABLE_BASE_OPTIONS['didDrawCell'] = (data) => {
                        if (data && data.row && data.row.section === 'body' && data.column.dataKey === 'signature') {
                            if (participants[data.row.index] && participants[data.row.index].signature) {
                                // Library throws an error for this. Let's use hardcoded ratio value for now since it's pretty much fixed and got no time..
                                //let imgData = (doc as jsPDFDocument).getImageProperties(participants[data.row.index].signature);
                                //const ratio = imgData.width / imgData.height;
                                const width = data.cell.height * 0.8 * this._SIGNATURE_IMG_WIDTH_HEIGHT_RATIO;
                                const height = data.cell.height * 0.8;
                                const xOff = (data.cell.width - width) / 2;
                                const yOff = (data.cell.height - height) / 2;

                                //console.log(`cell #${data.row.index} -> width: ${data.cell.width} height: ${data.cell.height}`, imgData, data);
                                doc.addImage(
                                    participants[data.row.index].signature,
                                    'PNG',
                                    data.cell.x + xOff,
                                    data.cell.y + yOff,
                                    width,
                                    height
                                );
                            }
                        }
                    };
                    this._DOC_SIGN_TABLE_BASE_OPTIONS['didDrawPage'] = (data) => {
                        // Add Page count to Footer
                        let str = `Page ${doc.internal.getNumberOfPages()}`;
                        if (typeof doc.putTotalPages === 'function') {
                            str = `${str} of ${totalPagesExp}`
                        }

                        doc.setFontSize(10);

                        const pageSize = doc.internal.pageSize;
                        const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
                        doc.text(str, data.settings.margin.left, pageHeight - 25);
                    };

                    try {
                        autoTable(doc, this._DOC_SIGN_TABLE_BASE_OPTIONS);
                    } catch (error) {
                        console.error('generatePdfDoc: 2nd autoTable: ', error);
                    }

                    // Insert total page count value to the placeholder
                    if (typeof doc.putTotalPages === 'function') {
                        doc.putTotalPages(totalPagesExp);
                    }

                    this._isProcessing.next(false);
                    resolve(doc);
                }, this._MIN_TIMEOUT_VAL);
            } else {
                resolve();
            }
        });
    }
    private generateInfoBody(event: EventActivity): { label: string, value: string }[] {
        const body: any = [];
        let formattedEventDate = '';
        if (event._startDate) {
            const dateFormat: string = `${this.dateTimeFormatsService.selected.dateFnFormat} ${this.dateTimeFormatsService.selectedTimeFormat.dateFnFormat}`;
            try {
                formattedEventDate = format(event._startDate, dateFormat);
            } catch (error) {
                console.error(`generateInfoBody: format: ${dateFormat} date: `, event._startDate);
            }
        }
        const data: { label: string, value: string }[] = event ? [
            {
                label: 'Name:',
                value: event.name ? event.name : 'Event'
            },
            {
                label: 'Event Type:',
                value: event.eventTypeFormatted
            },
            {
                label: 'Event Date:',
                value: formattedEventDate
            },
            {
                label: 'Products:',
                value: this.getProductsString(event.products)
            },
            {
              label: 'Speaker:',
              value: this.getSpeakerString(event.speakers)
            },
            {
                label: 'Location:',
                value: event.locationFormatted ? event.locationFormatted : event.location
            }
        ] : [];

        for (let i = 0; i < data.length; i++) {
            const element = data[i];
            const row = [];

            for (let key in element) {
                let rowContent: string | object;
                if (key === 'value'
                        && (element['label'] !== 'Name:'
                            && element['label'] !== 'Event Type:'
                            && element['label'] !== 'Event Date:')) {
                    rowContent = {
                        colSpan: 3,
                        content: element[key],
                    };

                    // Bold for Notes
                    /*if (element['label'] === 'Notes:') {
                        rowContent['styles'] = { fontStyle: 'bold' };
                    }*/
                } else {
                    rowContent = element[key];
                }
                row.push(rowContent);
            }
            if (i < 3) {
                row.push({
                    rowSpan: 3,
                    content: 'Organized By:',
                    styles: { fontStyle: 'bold', valign: 'top', halign: 'right', minCellWidth: 80 }
                });
                row.push({
                    rowSpan: 3,
                    content: event.meetingOwnerName,
                    styles: { valign: 'top', halign: 'left', minCellWidth: 100 }
                });
            }
            body.push(row);
        }

        return body;
    }
    private getProductsString(products: { id: string, isDeleted: boolean, name: string, statecode: number }[]): string {
        let productsStr = '';
        if (Array.isArray(products)) {
            products
                .filter(p => !p.isDeleted && p.statecode === 0)
                .map(product => {
                    if (productsStr !== '') {
                        productsStr = `${productsStr}, ${product.name}`;
                    } else {
                        productsStr = product.name;
                    }
                });
        }
        return productsStr;
    }

    private getSpeakerString(speaker: any[]): string {
      let speakerStr = '';
      if (Array.isArray(speaker)) {
        speaker
              .filter(p => !p.isDeleted && p.statecode === 0)
              .map(speaker => {
                  if (speakerStr !== '') {
                     speakerStr = `${speakerStr}, ${speaker.name}`;
                  } else {
                    speakerStr = speaker.name;
                  }
              });
      }
      return speakerStr;
  }

    private generateSignatureListBody(participants: EventParticipant[]): { title: string, name: string, signature: string, account: string, specialty: string }[] {
        const body = [];
        for (let i = 0; i < participants.length; i++) {
            const participant = participants[i];
            body.push({
                customerId: this.stringSanitization(participant.customerId),
                title: this.stringSanitization(participant.title),
                name: this.stringSanitization(this.getParticipantFullName(participant)),
                signature: '',
                account: this.stringSanitization(participant.primaryAccount),
                specialty: this.stringSanitization(participant.primarySpecialty),
            });
        }

        return body;
    }
    private getParticipantFullName(participant: EventParticipant): string {
        let fullName = '';

        if (participant.firstName) {
            fullName = participant.firstName
        }
        if (participant.middleName) {
            fullName = `${fullName} ${participant.middleName}`;
        }
        if (participant.lastName) {
            fullName = `${fullName} ${participant.lastName}`;
        }

        return fullName;
    }

    exportPDF(doc: jsPDF, fileName: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this._isExported.next(false);

            if (doc) {
                this._isProcessing.next(true);

                if (this.deviceService.deviceFlags.ios || this.deviceService.deviceFlags.android) {
                    // iOS & Android
                    setTimeout(() => {
                        let pdfOutput = doc.output();
                        let buffer = new ArrayBuffer(pdfOutput.length);
                        let array = new Uint8Array(buffer);

                        for (let i = 0; i < pdfOutput.length; i++) {
                            array[i] = pdfOutput.charCodeAt(i);
                        }

                        if (buffer) {
                            this.fileService.writeFileBufferToSharedDirectory(fileName, buffer, { replace: true })
                                .then(success => {
                                    this._isProcessing.next(false);
                                    if (success) {
                                        this._isExported.next(true);
                                        resolve(true);
                                    } else {
                                        resolve(false);
                                    }
                                })
                                .catch(e => {
                                    this._isProcessing.next(false);
                                    if (e){// && e.code === FileError.PATH_EXISTS_ERR) {
                                        reject(e);
                                    } else {
                                        resolve(false);
                                    }
                                });
                        } else {
                            this._isProcessing.next(false);
                            resolve(false);
                        }
                    }, this._MIN_TIMEOUT_VAL);
                } else {
                    // Web & Electron
                    doc.save(fileName, { returnPromise: true })
                        .then(s => {
                            this._isExported.next(true);
                            this._isProcessing.next(false);
                            resolve(true);
                        })
                        .catch(e => {
                            this._isProcessing.next(false);
                            resolve(false);
                        });
                }
            } else {
                resolve(false);
            }
        });
    }

    private stringSanitization(str: string) {
        // Sometimes data string has 'Zero Width Space' and that messes up the document.
        // Run sanitization just in case..
        return str && str.length ? [].filter.call(str, c => c.charCodeAt() !== 8203).join('') : '';
    }
}
