import { AfterViewInit, Component, EventEmitter, Output } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { CRMInstance } from '../../classes/authentication/instance.class';
import { AuthenticationDataService } from '../../data-services/authentication/authentication.service';
import { AuthenticationService } from '../../services/authentication.service';
import { NotificationService, ToastStyle } from '../../services/notification/notification.service';
import {AboutDetailsComponent} from '../app-settings/about-page/about-details/about-details';
import { AlertService } from './../../services/alert/alert.service';


/**
 * Generated class for the InstanceSelectionComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'instance-selection',
  templateUrl: 'instance-selection.html',
  styleUrls:['instance-selection.scss']
})
export class InstanceSelectionComponent implements AfterViewInit {
  @Output() onContinueClick: EventEmitter<any> = new EventEmitter();
  selectedInstance: CRMInstance;
  errorMsg: string;

  constructor(
    public authenticationOfflineService: AuthenticationService,
    private readonly authenticationDataService: AuthenticationDataService,
    public loadingCtrl: LoadingController,
    public notificationService: NotificationService,
    private readonly translate: TranslateService,
    public alertService: AlertService

  ) {

  }

  ngAfterViewInit() {
    //If we only have one instance...
    if (this.authenticationOfflineService.userConfig.CRMInstances.length === 1) {
      this.authenticationOfflineService.userConfig.activeInstance = this.authenticationOfflineService.userConfig.CRMInstances[0];
      this.onContinueClick.emit(this.authenticationOfflineService.userConfig.activeInstance);
    } else if (this.authenticationOfflineService.userConfig.CRMInstances.length === 0) {
      //this.events.publish('login:noInstances');
      //We dont have any instances, logout
      this.notificationService.notify(this.translate.instant('SELECT_INSTANCE_NOTIFICATION'),'','top',ToastStyle.DANGER);
      setTimeout(() => {
        this.authenticationDataService.logout();
      }, 3000);
    }

  }



  continueButtonClick() {
    this.authenticationOfflineService.userConfig.activeInstance = this.selectedInstance;
    this.onContinueClick.emit(this.selectedInstance);
  }

  async openPrivacyPopover() {
    this.alertService.showModal(AboutDetailsComponent,{ aboutTitle: 'PRIVACY_POLICY' });
   }
  openTerms() {
    this.alertService.showModal(AboutDetailsComponent,{ aboutTitle: 'TERMS_AND_CONDITIONS' });
  }
  openCopyright() {
    this.alertService.showModal(AboutDetailsComponent,{ aboutTitle: 'COPYRIGHT_INFO' });
  }

}
