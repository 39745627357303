import { IonContent, IonList, IonSearchbar } from '@ionic/angular';

import {takeUntil} from 'rxjs/operators';
import { Component, AfterViewInit, ChangeDetectionStrategy, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { ActivityService } from '../../../services/activity/activity.service';
import { NavController, PopoverController,  ToastController } from '@ionic/angular';
import { Events } from '@omni/events';
import { FooterService, FooterViews } from '../../../services/footer/footer.service';
import { DeviceService } from '../../../services/device/device.service';
import { EventsService } from '../../../services/events/events.service';
import { UIService } from '../../../services/ui/ui.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { TrackService, TrackingEventNames } from '../../../services/logging/tracking.service';
import { NavigationService, PageName } from '../../../services/navigation/navigation.service';
import { RepServices } from '../../../data-services/rep/rep.services';
import { DeltaService } from '../../../data-services/delta/delta.service';
import {
  TrendingCustomer,
  XpCustomer,
  OptionSet,
  Interests,
  GeneeAction,
  TrendingAccount,
  AccountHealth, ContactPotential, TrendingFeed, ImpactDetails, AccountPotential
} from '../../../classes/xperiences/trending.customer.class';
import { XperiencesService, IMPACT_SEGMENT } from '../../../services/xperiences/xperiences.service';
import { NothingSelectedView } from '../../shared/nothing-selected-view/nothing-selected-view';
import { CustomerXperiencesComponent } from '../../customer-xperiences/customer-xperiences';
import { CustomerInsightComponent } from '../../contact/customer-insight/customer-insight';
import { ActivityType, Activity } from '../../../classes/activity/activity.class';
import { ContactOfflineService } from '../../../services/contact/contact.service';
import { ActivityDataService } from '../../../data-services/activity/activity.service';
import { Subject } from 'rxjs';
import { WebsocketDataService } from '../../../data-services/websocket/websocket.data.service';
import { AppointmentActivity } from '../../../classes/activity/appointment.activity.class';
import { GlobalUtilityService } from "../../../services/global-utility.service";
import { TranslateService } from '@ngx-translate/core';
import { GeneeNotificationPopoverComponent } from '../../genee-notification-popover/genee-notification-popover';
import * as _ from 'lodash';
import { SampleService } from '../../../services/sample/sample.service';
import { Platform } from '@ionic/angular';
import { Contact, ContactAddress } from '../../../classes/contact/contact.class';
import { ChannelType } from '../../../classes/consent/channel.class';
import { EmailActivityParty, EmailAddress } from '../../../classes/activity/email.activity.class';
import { NotificationService, ToastStyle } from '../../../services/notification/notification.service';
import { IndChipsDataModel } from '../../../models/ind-chips-data-model';
import { AlertService } from '../../../services/alert/alert.service';
import { MapsAPILoader } from '@agm/core';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { InsightsPlusComponent } from '../../../components/inisghts-plus/insights-plus';
import { FeatureActionsMap } from '../../../classes/authentication/user.class';
import { MultiSelectPopover } from '../../../components/multi-select-popover/multi-select-popover';
import { TrendingAccountsComponent } from '../../../components/trending-accounts/trending-accounts';
import { TrendingFeedsComponent } from '../../../components/trending-feeds/trending-feeds';
import { IndSectionHeaderViewDataModel } from '@omni/models/indSectionHeaderDataModel';
import { IndTabsDataModel } from '@omni/models/ind-tabs-data-model';
import { AgendaFooterService, AgendaFooterView } from '@omni/services/footer/agenda-footer.service';
import { ImpactDetailsComponent } from '../impact-details-pane/impact-details-pane';
import { MyAssistantService } from '@omni/services/my-assistant/my-assistant.service';
import { Utility } from '@omni/utility/util';



/**
 * Generated class for the SrcComponentsComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'trending-customers',
  templateUrl: 'trending-customers.html',
  styleUrls:['trending-customers.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class TrendingCustomersComponent implements AfterViewInit {

  private hasBaselineEnabled: boolean = false;
  //@ViewChild(Content, {static: true}) content: Content;
  @ViewChild('scroll') scroll: any;
  @ViewChild('List' ) list: HTMLIonListElement;
  @ViewChild('googleMapContainer') googleMapsContainer: ElementRef
  public filteredCustomers: XpCustomer[] = [];
  public allXpCustomers: XpCustomer[] = [];
  public ngDestroy$ = new Subject<boolean>();
  public searchText: string;
  public placeholder = "Search Xperiences...";
  private locationTab: IndTabsDataModel = {
    displayText: this.translate.instant('ACTIVITY_DETAILS_LOCATION'),
    value: 'location',
    disable: this.device.isOffline
  };
  private accountsTab: IndTabsDataModel = {
    displayText: this.translate.instant('ACCOUNTS'),
    value: 'accounts',
    disable: this.device.isOffline
  };
  private allTab: IndTabsDataModel = {
    displayText: 'Feed',
    value: 'all',
    disable: this.device.isOffline
  };


  tabsData: IndTabsDataModel[] = [
    {
       displayText: this.translate.instant('CUSTOMERS'),
       value: 'trending',
    },
    {
     displayText: this.translate.instant('SAVED'),
     value: 'saved',
    },
    this.locationTab
  ];

  tabs: IndTabsDataModel[] = [];
  public selectedFilter: string;
  public allLocationCustomers: XpCustomer[] = [];
  public focusedCustomer: XpCustomer;
  public googleMap: google.maps.Map;
  public locationMap: Map<String, XpCustomer[]> = new Map<String, XpCustomer[]>();
  public trendingAccounts: TrendingAccount[] = [];
  public filteredTrendingAccounts = [];
  sortOptions = [
    { text: this.translate.instant('XPERIENCES_SORT_HIGH_TO_LOW'), value: "high" },
    { text: this.translate.instant('XPERIENCES_SORT_LOW_TO_HIGH'), value: "low" },
  ];
  sortByValue = this.sortOptions[0].text; // sets default value
  sortPopoverData = [
    {
      text: "",
      expanded: true,
      value: "high",
      items: this.sortOptions,
      handler: (selectedItem, item) => {
        item.value = selectedItem.value;
        this.sortByValue = selectedItem.text;
        if (item.value === this.sortOptions[0].value) {
          if (this.selectedFilter!='location' && this.selectedFilter!='accounts') {
            this.trendingCustomerHeaderModel.controls[0].text = selectedItem.text;
            this.filteredCustomers.sort((a,b) => b.score - a.score );
          } else if (this.selectedFilter === 'accounts') {
            this.trendingAccountHeaderModel.controls[0].text = selectedItem.text;
            this.filteredTrendingAccounts.sort((a,b) => b.score - a.score );
          }
        } else {
          if (this.selectedFilter!='location' && this.selectedFilter!='accounts') {
            this.trendingCustomerHeaderModel.controls[0].text = selectedItem.text;
            this.filteredCustomers.sort((a,b) => a.score - b.score );
          } else if (this.selectedFilter === 'accounts') {
            this.trendingAccountHeaderModel.controls[0].text = selectedItem.text;
            this.filteredTrendingAccounts.sort((a,b) => a.score - b.score );
          }
        }
        if(this.content)
        this.content.scrollToTop();
      }
    }
  ];
  accountHealth = AccountHealth;
  accountPotential = AccountPotential;
  contactPotential = ContactPotential;
  public trendingFeeds: TrendingFeed[] = [];
  public filteredTrendingFeeds = [];
  @ViewChild('content') content: IonContent;
  public trendingCustomerHeaderModel : IndSectionHeaderViewDataModel;
  public savedCustomerHeaderModel : IndSectionHeaderViewDataModel;
  public trendingAccountHeaderModel : IndSectionHeaderViewDataModel;
  public trendingFeedHeaderModel : IndSectionHeaderViewDataModel;
  @ViewChild('CustomerList') customerList: IonList;
  isAndroid: boolean = false;
  @ViewChild(IonSearchbar) ionSearchbar: IonSearchbar;
  public backgroundUploadInProgress: boolean = false;
  public syncInProgress: boolean = false;

  constructor(
    public activityService: ActivityService,
    public activityDataService: ActivityDataService,
    public navCtrl: NavController,
    public footerService: FooterService,
    public device: DeviceService,
    public uiService: UIService,
    private notificationService: NotificationService,
    private navService: NavigationService,
    public repService: RepServices,
    public popoverCtrl: PopoverController,
    public deltaService: DeltaService,
    public xperiencesService: XperiencesService,
    private alertService: AlertService,
    private contactService: ContactOfflineService,
    private events: Events,
    public eventService: EventsService,
    private websocket: WebsocketDataService,
    public utilityService: GlobalUtilityService,
    private translate: TranslateService,
    public samplingOfflineService: SampleService,
    public platform: Platform,
    public authenticationOfflineService: AuthenticationService,
    private trackingService: TrackService,
    private mapsApiLoader: MapsAPILoader,
    private iab: InAppBrowser,
    private agendaFooterService: AgendaFooterService,
    private _cd: ChangeDetectorRef,
    public assistantService: MyAssistantService,
  ) {
  }

  ngOnInit() {
    this.tabs = this.uiService.getAgendaTabs()
    this.isAndroid = this.device.isAndroid();
    this.enableDisableFeedFeeds();

    this.xperiencesService.impactTabObserver.pipe(
      takeUntil(this.ngDestroy$)).subscribe((tab: string) => {
      this.selectedFilter = tab;
      this.applyFilter(tab);
    });
    // this.xperiencesService.isSyncing.subscribe(async(data) => {
    //     if(data) {
    //       await this.uiService.displayLoader();
    //     } else {
    //       await this.uiService.dismissLoader();
    //     }
    // });
    this.xperiencesService.resetViewToDefault();
    this.xperiencesService.contactDetailsSegment = "journeys";
    this.xperiencesService.enableInsight = true;
    this.xperiencesService.selectedXpContact = null;
    this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView, PageName.ActivitiesPageComponent);
    this.xperiencesService.xpCustomersObserver$.subscribe((data: XpCustomer[]) => {
      if (data && data.length) {
        this.allXpCustomers = data;
        this.setGeocode();
      }
      else {
        this.allXpCustomers = [];
      }
      this.selectedFilter = this.tabsData[0].value;
      this.filteredCustomers = this.allXpCustomers.filter(c => c.xperienceFlag == this.selectedFilter).sort((a,b) => b.score - a.score );
      //this.content.resize();
      this.searchText = "";
    });
    this.events.subscribe("tools-back-clicked", this.handleToolsBack);
    this.events.subscribe("go-back-to-xperiences", this.initializeXperiencesPage);
    this.eventService.observe("start-websocket-subscription").pipe(
      takeUntil(this.ngDestroy$))
      .subscribe(() => {
        if (!this.device.isOffline && this.navService.getPreviousPageName() !== PageName.CoachingActivitiesComponent
          && this.navService.getCurrentPageName() !== PageName.CoachingActivitiesComponent) {
          if (this.activityService.selectedActivity instanceof AppointmentActivity) {
            (async () => this.websocket.subscribeToMeetingTopic(this.activityService.selectedActivity))();
          }
        }
      });
    this.events.subscribe('insertNewActivity', (activity) => {
      if (activity && activity.type == ActivityType.Appointment) {
        this.activityDataService._appendMeetingDetailsToActivity(activity);
      }
    });

    this.eventService.observe("sync:completed")
      .subscribe(() => {
        //this.content.resize();
        const index = this.tabsData.findIndex(filter => filter.value == this.accountsTab.value);
        if (!this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.ACCOUNTS_TRENDING_PILL)) {
          if (index >= 0) {
            this.tabsData.splice(index, 1);
          }
        } else {
          if (index < 0) {
            this.tabsData.splice(1, 0, this.accountsTab);
          }
        }

        // To check if XPERIENCES_FEED Dynamics Feature action is present and set All pill as default after SYNC completed
        const allChipIndex = this.tabsData.findIndex(filter => filter.value == this.allTab.value);
        if (!this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.XPERIENCES_FEED)) {
          // To check if All pill is present then Remove the all pill and set Customer pill as default
          if (allChipIndex >= 0) {
            this.tabsData.splice(allChipIndex, 1);
            this.selectedFilter = this.tabsData[0].value;
            this.filteredCustomers = this.allXpCustomers.filter(c => c.xperienceFlag == this.selectedFilter).sort((a,b) => b.score - a.score );
            this.searchText = "";
          }
        } else {
          // To check if All pill is not present then Add it and set it as default
          if (allChipIndex < 0) {
            this.tabsData.splice(0, 0, this.allTab);
            this.selectedFilter = this.tabsData[0].value;
          }
        }
         this.enableDisableFeedFeeds();

         this.syncInProgress = false;
        this._cd.detectChanges();
        this.initializeSectionHeader();

         // Update tabs in case feature action changed
         this.tabs = this.uiService.getAgendaTabs();
      });//end of this.eventService.observe("sync:completed")

      if(this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.ACCOUNTS_TRENDING_PILL)) {
        this.tabsData.splice(1, 0, this.accountsTab);
      }
      // To check if XPERIENCES_FEED Dynamics Feature action is present and set All pill as default
      if(this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.XPERIENCES_FEED)) {
        this.tabsData.splice(0, 0, this.allTab);
        this.selectedFilter = this.tabsData[0].value;
      }
    this.xperiencesService.xpTrendingAccountsObserver$.subscribe((data: TrendingAccount[]) => {
        this.trendingAccounts = data;
    });
    this.xperiencesService.xpTrendingFeedsObserver$.subscribe((data: TrendingFeed[]) => {
        this.trendingFeeds = data;
        this.filteredTrendingFeeds = this.trendingFeeds;
        this.initializeTrendigFeedsHeader();
    });
    this.initializeSectionHeader();
    this.device.isBackgroundUploadInProgressObservable.pipe(takeUntil(this.ngDestroy$)).subscribe(inProgress => {
      this.backgroundUploadInProgress = inProgress;
      this._cd.detectChanges();
    });
    this.device.syncInProgress$.pipe(takeUntil(this.ngDestroy$)).subscribe(inProgress => {
      this.syncInProgress = inProgress;
      this._cd.detectChanges();
    });

  }

  private enableDisableFeedFeeds() {
    if (this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.XPERIENCES_FEED)) {
      this.xperiencesService.segmentType = IMPACT_SEGMENT.FEED;
      if (this.uiService.activitiesPageTab === 'Impact') {
        this.agendaFooterService.initButtons(AgendaFooterView.ImpactFeed);
      }
    } else {
      this.xperiencesService.segmentType = IMPACT_SEGMENT.CONTACT;
      if (this.uiService.activitiesPageTab === 'Impact') {
        this.agendaFooterService.initButtons(AgendaFooterView.ImpactContacts);
      }
    }
  }

  initializeSectionHeader() {

    let title = '';

    if (this.searchText && this.searchText.trim().length > 0) {
      title = this.translate.instant('ACCOUNT_RESULTS');
    } else {
      if (this.selectedFilter === 'saved') {
        title =
          this.translate.instant('SAVED') +
          ' ' +
          this.translate.instant('XPERIENCES_TRENDING_CUSTOMERS', {
            globalCustomerText: this.utilityService.globalCustomerText ?? Utility.globalCustomerText,
          });
      } else {
        title = this.translate.instant('XPERIENCES_TRENDING_CUSTOMERS', {
          globalCustomerText: this.utilityService.globalCustomerText ?? Utility.globalCustomersText,
        });
      }
    }

    this.trendingCustomerHeaderModel = {
      id: 'trending',
      // title: `${this.translate.instant('ACCOUNT_ALL_ACCOUNTS')}(${this.allAccounts?.length})`,
      title : `${title} (${this.filteredCustomers.length})`,
      controls: [
        {
          id: 'trending-account-sort',
          text: this.translate.instant('HIGHT_TO_LOW'),
          isDisabled: false,
          img: "assets/imgs/sort_with_double_arrows.svg"
        }
      ]
    };

    this.savedCustomerHeaderModel = {
      id: 'trending',
      // title: `${this.translate.instant('ACCOUNT_ALL_ACCOUNTS')}(${this.allAccounts?.length})`,
      title : `${(this.searchText && this.searchText.trim().length > 0) ?
      this.translate.instant('ACCOUNT_RESULTS') :  this.translate.instant('SAVED')+' '+ this.translate.instant('XPERIENCES_TRENDING_CUSTOMERS', { globalCustomerText: this.utilityService.globalCustomerText })} (${this.filteredCustomers.length})`,
      controls: [
        {
          id: 'trending-account-sort',
          text: this.translate.instant('HIGHT_TO_LOW'),
          isDisabled: false,
          img: "assets/imgs/sort_with_double_arrows.svg"
        }
      ]
    };

    this.trendingAccountHeaderModel = {
      id: 'trending-account',
      title: `${(this.searchText && this.searchText.trim().length > 0) ?
        this.translate.instant('ACCOUNT_RESULTS') :
        this.translate.instant('ACCOUNTS')} (${this.filteredTrendingAccounts.length})`,
      controls: [
        {
          id: 'trending-account-sort',
          text: this.translate.instant('HIGHT_TO_LOW'),
          isDisabled: false,
          img: "assets/imgs/sort_with_double_arrows.svg"
        }
      ]
    }
    this.initializeTrendigFeedsHeader();
  }

  initializeTrendigFeedsHeader() {
    this.trendingFeedHeaderModel = {
      id: 'trending',
      title : `${(this.searchText && this.searchText.trim().length > 0) ?
      this.translate.instant('ACCOUNT_RESULTS') :this.translate.instant('ALL')} (${this.filteredTrendingFeeds.length})`,
      controls: []
    }
  }

  onAccountHeaderControlClick(id : string){
   if(id == 'trending-account-sort'){
     this.sortTrendingAccounts();
   }
  }


  async setGeocode() {
    await this.platform.ready();
    await this.mapsApiLoader.load();
    console.log('Setting the contacts geocode');
    try {
      const geocoder: google.maps.Geocoder = new google.maps.Geocoder();
      this.allXpCustomers.forEach(contact => {
        setTimeout(() => {
          this.setContactAddressGeocode(contact, geocoder);
        }, 1000);
      });
    } catch (err) {
      console.error('Error while computing Geocode', err);
    }
  }

  setContactAddressGeocode(xpCustomer: XpCustomer, geocoder: google.maps.Geocoder) {
    let contact: Contact = this.contactService.getContactByID(xpCustomer.contactid);
    if (contact && contact.getPrimaryAddress != null) {
      geocoder.geocode({ address: contact.getPrimaryAddress }, (results, status) => {
        if (status == google.maps.GeocoderStatus.OK) {
          let geometryLocation = results[0].geometry.location;
          xpCustomer.location = { lat: geometryLocation.lat(), lng: geometryLocation.lng() };
          xpCustomer.primaryAddress = contact.conacatanatedAddress;
          this.allLocationCustomers.push(xpCustomer);
          //building <location, contacts> to track available contacts in a location
          if (this.locationMap.has(xpCustomer.primaryAddress)) {
            this.locationMap.get(xpCustomer.primaryAddress).push(xpCustomer);
          } else {
            this.locationMap.set(xpCustomer.primaryAddress, [xpCustomer])
          }
        } else {
          console.log('Geocode was not successful for the following reason: ' + status);
        }
      });
    }
    console.log('Location Map', this.locationMap);
    return;
  }

  async applyFilter(filter: string) {
    this.sortByValue = this.sortOptions[0].text;
    this.sortPopoverData[0].value = this.sortOptions[0].value;
    if (filter == 'location' && (this.device.isOffline || this.device.isUserStateOffline)) return;
    this.xperiencesService.selectedTrendingAccount = null;
    this.selectedFilter = filter;
    this.filterCustomers();
    if (filter != 'location') {
      if(this.list) this.list.closeSlidingItems();
      if(this.customerList)  this.customerList.closeSlidingItems();
    }
    if(this.content) {
      this.content.scrollToTop();
    }
  }

  filterCustomers() {
    if (this.searchText && this.searchText.trim().length > 0) {
      if(this.selectedFilter == this.accountsTab.value) {
        this.filteredTrendingAccounts = this.trendingAccounts.filter((account)=> account.accountName &&  account.accountName.toLowerCase().includes(this.searchText.toLowerCase())).sort((a,b) => {
          if(this.sortPopoverData[0].value === this.sortOptions[0].value) {
            return b.score - a.score;
          } else {
            return a.score - b.score;
          }
        });
      }
      else if(this.selectedFilter == this.allTab.value) {
        this.filteredTrendingFeeds = this.trendingFeeds.filter((feed)=> feed.description &&  feed.description.toLowerCase().includes(this.searchText.toLowerCase()));
      } else {
        this.filteredCustomers = this.allXpCustomers.filter(c => c.fullname && c.fullname.toLowerCase().includes(this.searchText.toLowerCase()) &&
        (this.selectedFilter == 'saved' ? c.savedFlag : c.xperienceFlag == this.selectedFilter)).sort((a,b) => {
          if(this.sortPopoverData[0].value === this.sortOptions[0].value) {
            return b.score - a.score;
          } else {
            return a.score - b.score;
          }
        });
      }
      //this.content.resize();
    } else {
      if (this.selectedFilter == 'location') {
        if (this.device.isOffline || this.device.isUserStateOffline) return;
        this.platform.ready().then(async () => {
          await this.uiService.displayLoader();
          setTimeout(async () => {
            await this.uiService.dismissLoader();
            this.loadMap();
          }, 2000);
        });
      } else if(this.selectedFilter == this.accountsTab.value) {
        this.filteredTrendingAccounts = this.trendingAccounts.sort((a,b) => b.score - a.score );
      } else if(this.selectedFilter == this.allTab.value) {
        this.filteredTrendingFeeds = this.trendingFeeds;
        //this.content.resize();
      } else {
        this.filteredCustomers = this.allXpCustomers.filter(c => this.selectedFilter == 'saved' ? c.savedFlag : c.xperienceFlag == this.selectedFilter).sort((a,b) => b.score - a.score );
        //this.content.resize();
      }
    }
    this.initializeSectionHeader();
  }

  async loadMap() {
    if (this.allLocationCustomers && this.allLocationCustomers.length > 0) {
      this.allLocationCustomers = _.uniqBy(this.allLocationCustomers, 'contactid');
      const map = await this.createMap();
      this.googleMap = map;
      this.setMarkersOnMap(map);
      //this.content.resize();
      this.attachEventListenerForMapsHyperlink();
    }
  }

  setMarkersOnMap(map: google.maps.Map) {
    if (this.locationMap) {
      let markerIcon: any;
      this.locationMap.forEach((contacts: XpCustomer[], address: string) => {
        let street = this.extractStreetOfContactAddress(contacts[0].contactid);
        let concatanatedAddress = this.extractConcatanatedAddress(contacts[0].contactid);
        console.log('Street Name', street);
        //Check if multiple contacts contain same address
        markerIcon = this.getMarkerIcon();
        if (contacts.length > 1) {
          let title = contacts.map(cont => cont.fullname).join(", ");
          contacts.forEach(contact => {
            this.plotMarkerOnMap(map, street, contact, markerIcon, concatanatedAddress, title);
          });
        } else {
          let lable: string = contacts[0].fullname ? contacts[0].fullname.trim() : street;
          this.plotMarkerOnMap(map, lable, contacts[0], markerIcon, concatanatedAddress, lable);
        }
      });
    }
  }

  private async createMap() {
    this.focusCustomerDetails(this.allLocationCustomers[0]);
    const markerLatLng = this.allLocationCustomers[0].location;
    const mapOptions = {
      zoom: 16,
      tilt: 45,
      rotateControl: true,
      center: markerLatLng,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      mapTypeControl: false,
      streetViewControl: false,
      zoomControl: false,
      fullscreenControl: false
    };
    const map = new google.maps.Map(document.getElementById('map_canvas'), mapOptions);
    this.setMapOptions(map);
    this.focusCustomer(this.allLocationCustomers[0]);
    return map;
  }

  private setMapOptions(map: google.maps.Map) {
    if (this.googleMap) {
      this.googleMap.setOptions({
        styles: [
          {
            featureType: 'poi',
            elementType: 'all',
            stylers: [{ visibility: 'off' }]
          },
          {
            featureType: 'road',
            elementType: 'all',
            stylers: [{ visibility: 'off' }]
          },
          {
            featureType: 'transit',
            elementType: 'all',
            stylers: [{ visibility: 'off' }]
          },
          {
            featureType: "landscape",
            elementType: "labels",
            stylers: [
              { "visibility": "off" }
            ]
          },
          {
            featureType: "administrative",
            elementType: "labels",
            stylers: [
              { "visibility": "off" }
            ]
          }
        ]
      });
    }
  }

  private plotMarkerOnMap(map: google.maps.Map, lable: string, customer: XpCustomer, markerIcon: google.maps.Icon, address: string, title: string) {
    const marker = new google.maps.Marker({
      map,
      animation: google.maps.Animation.DROP,
      position: customer.location,
      icon: markerIcon,
      label: {
        text: lable,
        color: '#333333',
        fontSize: '15px',
        fontWeight: 'bold'
      }
    });

    marker.addListener('click', () => {
      if (this.isNetworkDisconnected()) {
        this.notificationService.notify(this.translate.instant('NETWORK_ISSUES_WHILE_COMPLETE_REQUEST'), 'Trending Customer', 'top', ToastStyle.DANGER);
        return;
      }
      //Displaying all the contacts in a location on marker click
      let infowindow = new google.maps.InfoWindow();
      let element = document.createElement('div');
      element.setAttribute('class', 'info-box')
      element.innerHTML = `<b>${title}</b><br>
                  <div id="address" style="white-space: pre; margin-top: 2px">${address.replace(/,/g, ',\n')}</div>
                  <a id="viewMaps" style="text-decoration: none; color: #427fed" href="#"> View on Google Maps </a>`;

      infowindow.setContent(element);
      infowindow.open(map, marker);
      let anchorTag = element.lastChild;
      anchorTag.addEventListener("click", (e) => {
        const val = element.children[2].innerHTML.replace(/,/g, '+').replace('/ /g',',');
        this.openMapInAppBrowser("https://google.com/maps?q=" + val);
        e.preventDefault();
        return false;
      });
      anchorTag.addEventListener('mouseover', () => {
        (anchorTag as HTMLElement).style.textDecoration = 'underline';
      });
      anchorTag.addEventListener('mouseout', () => {
        (anchorTag as HTMLElement).style.textDecoration = 'none';
      });
      this.focusedCustomer = customer;
      let customerRef: HTMLElement = this.focusCustomerDetails(customer);
      this.scroll._scrollContent.nativeElement.scrollTo({ left: customerRef.offsetLeft, top: 0, behavior: 'smooth' });
    });
  }

  private focusCustomerDetails(customer: XpCustomer) {
    let customerRef: HTMLElement = document.getElementById(customer.contactid);
    return customerRef;
  }

  private getMarkerIcon() {
    return {
      url: 'assets/imgs/Map_Marker.png',
      origin: new google.maps.Point(0, 0),
      labelOrigin: new google.maps.Point(10, -10),
    };
  }

  focusCustomer(customer: XpCustomer) {
    if (this.isNetworkDisconnected()) {
      this.notificationService.notify(this.translate.instant('NETWORK_ISSUES_WHILE_COMPLETE_REQUEST'), 'Trending Customer', 'top', ToastStyle.DANGER);
      return;
    }
    this.focusedCustomer = customer;
    if (this.googleMap && customer.location) {
      this.googleMap.setCenter(customer.location);
      this.googleMap.setZoom(17);
    }
  }

  onInput(ev) {
    if(ev && ev.target){
      this.searchText = ev.target.value;
    }
    this.trackingService.tracking('XperiencesSearch', TrackingEventNames.XPERIENCES);
    this.filterCustomers();
  }

  onFocus(event: any) {
    if (this.xperiencesService.unSavedChanges) {
      this.agendaFooterService.showDiscardPopup().then((res) => {
        if(res.role === "ok") {
          this.xperiencesService.newInsight.contactid = '';
          this.xperiencesService.newInsight.journeys.length = 0;
          this.xperiencesService.newInsight.interests.length = 0;
          this.xperiencesService.newInsight.relationships.length = 0;
          this.xperiencesService.enableInsight = true;
          this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView, PageName.ActivitiesPageComponent);
          this.ionSearchbar.setFocus();
        } else {
          event.target.blur();
        }
      });
    } else if (!_.isEqual(this.xperiencesService.generalInsight, this.xperiencesService.tempInsight)) {
      this.xperiencesService.discardChangesPopup().then (res => {
        if(res.role === 'ok') {
          this.xperiencesService.enableInsight = true;
          this.xperiencesService.selectedTrendingFeed = null;
          this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView, PageName.ActivitiesPageComponent);
          if (this.xperiencesService.tempInsight) this.xperiencesService.generalInsight = this.xperiencesService.tempInsight;
          this.ionSearchbar.setFocus();
        } else {
          event.target.blur();
        }
      });
    }
  }

  async saveCustomer(customer: XpCustomer, save: boolean) {
    if (this.device.isOffline) return;
    const action: string = save ? 'save' : 'unsave';
    this.uiService.displayLoader();
    await this.xperiencesService.saveCustomer(customer, action).then(() => {
      if (save) {
        customer.savedFlag = true;
      } else {
        customer.savedFlag = false;
        if (this.selectedFilter == 'saved') {
          this.filterCustomers();
        }
      }
      this.uiService.dismissLoader();
    }).catch((err) => {
      console.log("Error occurred --> ", err);
      this.uiService.dismissLoader();
      this.notificationService.notify("Error occurred while processing the request", 'Trending Customer', 'top', ToastStyle.DANGER);
    });

  }

  async showGeneePopover(customer: XpCustomer) {
    if (this.device.isOffline) return;
    const contact = this.contactService.contacts.find(ele => ele.ID === customer.contactid);
    if (!contact) {
      this.notificationService.notify("Contact details not found", 'Trending Customer', 'top', ToastStyle.DANGER);
      return;
    }
    this.contactService.contactInformation = contact;
    this.uiService.activeView = "ActivitiesPageRightPaneNav";
    this.uiService.showNewActivity = false;
    let defaultAction: string;
    let channel: ChannelType = null;
    let channelId: string = null;
    if (customer.geneeActions && customer.geneeActions.length > 0) {
      const actionsCopy: GeneeAction[] = _.cloneDeep(customer.geneeActions);
      actionsCopy.forEach(a => {
        if (a.activitytypecode == 'indskr_sampledrop') {
          const contactCustomeAllocations = this.samplingOfflineService.contactCustomerSampleAllocations.find(o => {
            return o.contactId == customer.contactid
          });
          if (!contactCustomeAllocations || !contactCustomeAllocations.currentCustomerSampleAllocations ||
            contactCustomeAllocations.currentCustomerSampleAllocations.length == 0) {
            customer.geneeActions = customer.geneeActions.filter(ga => ga.activitytypecode != 'indskr_sampledrop');
          }
        } else if (a.activitytypecode == 'email') {
          contact.emailAddressList = contact.emailAddressList || [];
          channel = this.xperiencesService.getChannelType(a.channeltype);
          channelId = a.channeltypeid;
          if (channel === ChannelType.EMAIL && (!contact.emailAddressList || contact.emailAddressList.length === 0)) {
            customer.geneeActions = customer.geneeActions.filter(ga => ga.channeltype != 'email');
          } else if (channel === ChannelType.FACEBOOK && (contact.indskr_facebookpsid || '') === '') {
            customer.geneeActions = customer.geneeActions.filter(ga => ga.channeltype != 'facebook');
          } else if ((channel === ChannelType.WHATSAPP || channel === ChannelType.SMS) && (contact.mobilePhone || '') === '') {
            if (channel === ChannelType.SMS) customer.geneeActions = customer.geneeActions.filter(ga => ga.channeltype != 'sms');
            else customer.geneeActions = customer.geneeActions.filter(ga => ga.channeltype != 'whatsapp');
          }
        } else if (a.activitytypecode == 'task') {
          customer.geneeActions = customer.geneeActions.filter(ga => ga.activitytypecode != 'task');
        }
      });
      const defaultActivity = _.maxBy(customer.geneeActions, "score");
      defaultAction = defaultActivity.activitytypecode;
      const defaultActionIndex = customer.geneeActions.findIndex((ga) => ga.activitytypecode === defaultAction);
      customer.geneeActions.splice(defaultActionIndex, 1);
      customer.geneeActions.unshift(defaultActivity);
      customer.geneeActions = customer.geneeActions.map(action => { return { ...action, name: this.getActionName(action.activitytypecode, action.channeltype) } })
    }

    let data = {
      heading: 'GENEE PREFERRED ACTION CONFIRMATION',
      notificationText: `${(customer.geneeActions && customer.geneeActions.length > 0) ? this.translate.instant('XPERIENCES_PREDICTIONS_MESSAGE') : this.translate.instant('XPERIENCES_NO_PREDICTIONS_MESSAGE')} ${customer.fullname}`,
      actions: customer.geneeActions,
      defaultAction: defaultAction,
      closeBtn: true,
      from: PageName.TrendingCustomersComponent
    }
    if (!this.uiService.geneeSyncPopover) {
      this.uiService.geneeSyncPopover = await this.popoverCtrl.create({component: GeneeNotificationPopoverComponent,componentProps: data,
         cssClass: 'geneeActionConfirmation', showBackdrop: true, backdropDismiss: false })
    }
    else {
      this.uiService.geneeSyncPopover = undefined;
      this.uiService.geneeSyncPopover = await this.popoverCtrl.create({component: GeneeNotificationPopoverComponent,componentProps: data,
         cssClass: 'geneeActionConfirmation', showBackdrop: true, backdropDismiss: false })
    }
    this.uiService.geneeSyncPopover.onDidDismiss().then(async (obj:any) => {
      const data = obj.data;
      if (data && data.preferredAction) {
        console.log(data.preferredAction);
        const selectedAction: GeneeAction = customer.geneeActions.find(ga => ga.activitytypecode == data.preferredAction.activitytypecode);
        switch (selectedAction.activitytypecode) {
          case 'appointment':
            this.xperiencesService.createMeeting([contact], selectedAction.date);
            this.footerService.initButtons(FooterViews.Activities);
            this.events.publish("detectChangesOnActivityDetails");
            break;
          case 'email': {
            this.xperiencesService.createEmail(selectedAction.date, channelId, channel, [contact]);
          }
            break;
          case 'indskr_sampledrop': {
            await this.xperiencesService.createAllocationOrder(selectedAction.date, contact);
            if (this.activityService.selectedActivity) {
              this.footerService.initButtons(FooterViews.SampleDropDetails);
              this.footerService.disableButton(['samplingeligibilities', 'signature'])
            }
          }
            break;
          default:
            break;
        }
      }
    })
    this.uiService.geneeSyncPopover.present();
  }

  getActionName(activityCode: string, channeltype: string): string {
    let activityName = '';
    switch (activityCode) {
      case "indskr_sampledrop":
        activityName = this.translate.instant('XPERIENCES_GENEE_ALLOCATION_LABEL');
        break;
      case "medical_inquiry":
        activityName = this.translate.instant('XPERIENCES_GENEE_MEDICAL_INQUIRY');
        break;
      case "appointment":
        activityName = this.translate.instant('XPERIENCES_GENEE_MEETING_LABEL');
        break;
      case "email":
        if (channeltype == 'email') activityName = this.translate.instant('XPERIENCES_GENEE_EMAIL_LABEL');
        else {
          let channel: string = "";
          if (channeltype == 'facebook') channel = this.translate.instant('XPERIENCES_GENEE_FACEBOOK_LABEL');
          else if (channeltype == 'whatsapp') channel = this.translate.instant('XPERIENCES_GENEE_WHATSAPP_LABEL');
          else if (channeltype == 'sms') channel = this.translate.instant('XPERIENCES_GENEE_SMS_LABEL');
          activityName = channel;
        }
        break;
      default:
        break;
    }
    return activityName;
  }




  initializeXperiencesPage = (component, createdActivity) => {
    if (this.xperiencesService.selectedXpContact || this.xperiencesService.newInsight || this.xperiencesService.selectedTrendingFeed) {
      if (this.xperiencesService.trackActivityCreation) {
        const activeTab = this.xperiencesService.contactDetailsSegment == 'journeys' ? 'journey' : this.xperiencesService.contactDetailsSegment;
        const index = this.xperiencesService.selectedXpContact.plans.findIndex(plan => plan.id == this.xperiencesService.trackActivityCreation.topicId);
        if (index > -1) {
          if (createdActivity && this.xperiencesService.trackActivityCreation.activity.ID == createdActivity['ID']) {
            let plan = this.xperiencesService.selectedXpContact.plans[index];
          } else {
            this.xperiencesService.selectedXpContact.plans.splice(index, 1);
          }
        }
        this.xperiencesService.trackActivityCreation = null;
      }
      this.footerService.initButtons(FooterViews.Contacts);
      this.xperiencesService.resetViewToDefault(true);
    } else {
      this.xperiencesService.resetViewToDefault();
      this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView, PageName.ActivitiesPageComponent);
    }
  }

  handleToolsBack = (component) => {
    if (this.activityService.selectedActivity) {
      // Make the email details page stay after adding an attachment
      if (this.activityService.selectedActivity.type === 'Email') {
        return;
      } else {
        setTimeout(() => {
          this.initializeXperiencesPage(component, null);
        }, 200);
      }
    } else {
      this.initializeXperiencesPage(component, null);
    }
  }

  trackInsightEvent(type: string) {
  }

  public createInsight() {
    this.activityService.selectedActivity = null;
    this.activityService.selectedActivityAtHome = null;
    this.uiService.showRightPane = true;
    this.xperiencesService.enableInsight = false;
    this.navService.pushChildNavPageWithPageTracking(InsightsPlusComponent, PageName.InisghtsPlusComponent);
  }

  public async openXperiences(customer: XpCustomer) {
    const details: ImpactDetails = {
      segment: IMPACT_SEGMENT.CONTACT,
      title: customer.fullname,
      data: { contactId : customer.contactid },
      plans: customer.plans,
      predictions: customer.predictions,
      insights: {journey: customer.journey? customer.journey.insights : [], relationship: customer.relationships? customer.relationships.insights : [], interest: customer.interests ? customer.interests.insights: []}
    }
    if (this.xperiencesService.unSavedChanges) {
      this.alertService.showAlert({
        title: this.translate.instant('DISCARD_CHANGES'),
        message: this.translate.instant('CI_R_U_WANT_DISCARD_CHANGES')
      }, this.translate.instant('DISCARD')
      ).then(res => {
        if (res.role == "ok") {
          this.trackCustomerClick();
          this.uiService.showRightPane = true;
          this.xperiencesService.selectedXpContact = customer;
          this.xperiencesService.enableInsight = true;
          this.xperiencesService.customerType = this.selectedFilter;
          this.xperiencesService.resetFilters();
          // this.navService.setChildNavRoot(CustomerXperiencesComponent, PageName.CustomerXperiencesComponent,
          //   PageName.ActivitiesPageComponent, { customer: customer }).then(() => {
          //     setTimeout(() => {
          //       this.xperiencesService.newInsight = null;
          //     }, 500);
          //   });

          this.navService.setChildNavRoot(ImpactDetailsComponent, PageName.ImpactDetailsComponent, PageName.ActivitiesPageComponent, { imapactDetails: details }).then(() => {
              setTimeout(() => {
                this.xperiencesService.newInsight = null;
              }, 500);
            });
        }
      });
    } else {
      this.trackCustomerClick();
      this.xperiencesService.customerType = this.selectedFilter;
      this.xperiencesService.selectedXpContact = customer;
      this.xperiencesService.enableInsight = true;
      this.xperiencesService.resetFilters();
      await this.navService.setChildNavRoot(ImpactDetailsComponent, PageName.ImpactDetailsComponent, PageName.ActivitiesPageComponent, { imapactDetails: details });
      this.xperiencesService.resetViewToDefault(true);
    }
  }

  private isNetworkDisconnected() {
    return this.device.isOffline || this.device.isUserStateOffline;
  }


  extractStreetOfContactAddress(contactId: string) {
    let contact: Contact = this.contactService.getContactByID(contactId);
    let streetName = this.getStreetName(contact.addressesList);
    if (streetName.length == 0) {
      return contact.getPrimaryAddress;
    }
    return streetName;
  }

  getStreetName(addressesList: ContactAddress[]): string {
    if (addressesList.length == 0) return '';

    let primaryAddFound: ContactAddress;
    //For each address, check if it is primary
    addressesList.forEach(add => {
      if (add.isPrimary) primaryAddFound = add;
    });

    //Do we have a first choice?
    if (primaryAddFound) {
      return this.extractStreetName(primaryAddFound);
    } else {
      //Fallback to any choice
      return this.extractStreetName(addressesList[0]);
    }
  }

  extractStreetName(contactAddress: ContactAddress) {
    let street = '';
    if (contactAddress.street) {
      street = contactAddress.street;
    } else if (contactAddress.street2) {
      street = contactAddress.street2;
    } else if (contactAddress.street3) {
      street = contactAddress.street3;
    }
    return street;
  }

  extractConcatanatedAddress(id: string) {
    let contact: Contact = this.contactService.getContactByID(id);
    return contact.conacatanatedAddress;
  }

  ngAfterViewInit() {
    //this.content.resize();
  }

  ngOnDestroy() {
    this.uiService.showCancelDoneOnActivityDetails = false;
    this.xperiencesService.segmentType = null;
    this.events.unsubscribe("tools-back-clicked");
    this.events.unsubscribe("go-back-to-xperiences");
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }

  trackCustomerClick() {
    if (this.selectedFilter === 'trending') {
      this.trackingService.tracking('XperiencesTrendingCustomerNameClick', TrackingEventNames.XPERIENCES);
    } else if (this.selectedFilter === 'inactive') {
      this.trackingService.tracking('XperiencesInactiveCustomerNameClick', TrackingEventNames.XPERIENCES);
    } else if (this.selectedFilter === 'saved') {
      this.trackingService.tracking('XperiencesSavedCustomerNameClick', TrackingEventNames.XPERIENCES);
    }
  }

  attachEventListenerForMapsHyperlink() {
    const  that  = this;
      setTimeout(() => {
        google.maps.event.addListener(this.googleMap, 'tilesloaded', function(){
        //Prevent anchors in google maps from breaking App by launching these anchors using InAppBrowser
            const googleMap = that.googleMapsContainer;
            let anchors = googleMap.nativeElement.getElementsByTagName("a");
            for (let i=0; i < anchors.length; i++) {
                anchors[i].addEventListener("click", function openInAppBrowser(e) {
                  that.openMapInAppBrowser(this.href);
                  e.preventDefault();
                  return false;
                });
            }
         });

      }, 1000);
  }

  openMapInAppBrowser(url: string) {
    if(!this.device.isOffline) {
      if(this.device.isNativeApp){
        if(this.device.deviceFlags.ios){
          this.iab.create( url , '_blank', { location:'no', hideurlbar: 'yes', hidenavigationbuttons: 'yes' });
        }
        else{
          this.iab.create( url , '_blank', { location:'yes', hideurlbar: 'yes', hidenavigationbuttons: 'yes' });
        }
      } else{
        window.open( url , '_system');
        return false;
      }
    }
  }

  sortTrendingAccounts() {
    this.popoverCtrl
    .create({component: MultiSelectPopover,componentProps: { root: this.sortPopoverData }, event:event})
    .then((data)=> data.present());
  }

  async openTrendingAccountDetails(account) {
    if (this.xperiencesService.unSavedChanges) {
      this.agendaFooterService.showDiscardPopup().then(async (res) => {
        if(res.role === "ok") {
          this.xperiencesService.dicardChangesForXperiences();
          await this.showTrendingAccount(account);
        }
      });
    } else if (!_.isEqual(this.xperiencesService.generalInsight, this.xperiencesService.tempInsight)) {
      this.xperiencesService.discardChangesPopup().then (async(res) => {
        if(res.role === 'ok') {
          if (this.xperiencesService.tempInsight) this.xperiencesService.generalInsight = this.xperiencesService.tempInsight;
          await this.showTrendingAccount(account);
        }
      });
    } else {
      await this.showTrendingAccount(account);
    }
  }

  async showTrendingAccount(account) {
    this.xperiencesService.enableInsight = true;
    this.xperiencesService.selectedXpContact = null;
    this.xperiencesService.selectedTrendingAccount = account;
    this.uiService.showRightPane = true;
    await this.navService.pushChildNavPageWithPageTracking(TrendingAccountsComponent,
      PageName.CustomerXperiencesComponent, PageName.ActivitiesPageComponent);
  }


  async likeFeed(feed: TrendingFeed, like: boolean) {
    if (this.device.isOffline) return;

    // this.uiService.displayLoader();

    this.trendingFeeds.filter((item)=>{
      if(item.feed_id === feed.feed_id){
        if (like) {
          ++item.likeCount;
          item.likedFlag = true;
        } else if(item.likedFlag) {
          --item.likeCount;
          item.likedFlag = false;
        }
      }
    });

    // this.uiService.dismissLoader();
}

  async openTrendingFeedDetails(feed) {
    if (this.xperiencesService.unSavedChanges) {
      this.agendaFooterService.showDiscardPopup().then(async (res) => {
        if(res.role === "ok") {
         this.xperiencesService.dicardChangesForXperiences();
         await this.showTredingFeed(feed);
        }
      });
    } else if (!_.isEqual(this.xperiencesService.generalInsight, this.xperiencesService.tempInsight)) {
      this.xperiencesService.discardChangesPopup().then (async (res) => {
        if(res.role === 'ok') {
          if (this.xperiencesService.tempInsight) this.xperiencesService.generalInsight = this.xperiencesService.tempInsight;
          await this.showTredingFeed(feed);
        }
      });
    } else {
      await this.showTredingFeed(feed);
    }
  }

  async showTredingFeed(feed: TrendingFeed) {
    this.xperiencesService.selectedTrendingFeed = feed;
    this.xperiencesService.enableInsight = true;
    this.xperiencesService.selectedXpContact = null;
    this.xperiencesService.resetFilters();
    const details: ImpactDetails = {
      segment: IMPACT_SEGMENT.FEED,
      title: feed.description,
      data: null,
      plans: feed.plans,
      predictions: feed.predictions,
      insights: {journey: feed.journey? feed.journey.insights : [], relationship: feed.relationships? feed.relationships.insights : [], interest: feed.interests ? feed.interests.insights: []}
    }
    await this.navService.setChildNavRoot(ImpactDetailsComponent, PageName.ImpactDetailsComponent, PageName.ActivitiesPageComponent, { imapactDetails: details });
    this.xperiencesService.resetViewToDefault(true);
  }

  public scrollToTop(scroll) {
    scroll.scrollTop = 0;
  }

  tabChangeEvent(selectedTab: string) {​​​​​
    this.uiService.setAgendaTab(selectedTab);
    this.xperiencesService.selectedTrendingFeed = null;
    this.uiService.activitiesPageTab = selectedTab; 
  }

  localisedAccounthealth(health: string) {
    let accountHealthString = ''
    switch (health) {
      case AccountHealth.low:
        accountHealthString = this.translate.instant('XPERINECES_LOW_HEALTH');
        break;
      case AccountHealth.medium :
        accountHealthString = this.translate.instant('XPERINECES_MEDIUM_HEALTH');
        break;
      case AccountHealth.high:
      accountHealthString = this.translate.instant('XPERINECES_HIGH_HEALTH');
    }
    return accountHealthString;
  }


  localisedAccountPotential(potential: string) {
    let accountPotentialString = ''
    switch (potential) {
      case AccountPotential.low:
        accountPotentialString = this.translate.instant('XPERINECES_LOW_POTENTIAL');
        break;
      case AccountPotential.medium :
        accountPotentialString = this.translate.instant('XPERINECES_MEDIUM_POTENTIAL');
        break;
      case AccountPotential.high:
      accountPotentialString = this.translate.instant('XPERINECES_HIGH_POTENTIAL');
    }
    return accountPotentialString;
  }​​​​​



}
